import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { connect } from "react-redux";
import EditProductDetails from "../../../layouts/modals/editProductDetails";
import {
  getListingProductDetail,
  productDetails,
  listingProductDetailReceived,
  updateProductDetails,
  updateComparablePrice,
} from "../../../store/listingProduct";
import _ from "lodash";
import { toast } from "react-toastify";
import AlertError from "../../../common/alerts/alertError";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import moment from "moment";
import { getLatestBids, getPriceInfo } from "../../../store/latestActivity";
import { detailListing } from "../../../store/listings";

const TransportationStorage = (props) => {
  const [editModal, setEditModal] = useState(false);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [comparablePriceModal, setComparablePriceModal] = useState(false);

  const val = localStorage.getItem("data");
  const session = JSON.parse(val);

  const pipeCategories = [1115, 1295, 1321, 1322, 1323, 1400, 1404, 1442, 1515, 1754];

  const toggleEditModal = () => {
    setEditModal(!editModal);
  };

  const toggleComparablePrice = () => {
    setComparablePriceModal(!comparablePriceModal);
  };

  const data =
    (props.getListingProductDetail &&
      props.getListingProductDetail.detail &&
      props.getListingProductDetail.detail.detail) ||
    {};

  return (
    <div class="details-page-widget-block" id={props.id}>
      <div class="page-widget-head d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <div
            class="page-widget-label text-capitalize cursor-pointer"
            onClick={() => !props.loadingMain && toggleEditModal()}
          >
            Transportation & Storage
          </div>
        </div>
        {/* <div class="btn-block">
          {props.loadingMain ? (
            <Skeleton height="30px" width="90px" />
          ) : (
            <a href="javascript:void(0)" class="btn btn-primary btn-sm" onClick={() => toggleEditModal()}>
              Edit
            </a>
          )}
        </div> */}
      </div>
      <div class="page-widget-body mt-3">
        <div class="table-responsive">
          <table class="table mb-0  product-table">
            {props.loadingMain ? (
              <>
                <colgroup>
                  <col style={{ width: "35%" }} />
                  <col />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                </tbody>
              </>
            ) : (
              <>
                <colgroup>
                  <col style={{ width: "35%" }} />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <td>Loading Costs</td>
                    <td>
                      {data.lodingCost
                        ? !_.isNaN(+data.lodingCost)
                          ? "$" +
                            new Intl.NumberFormat("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(data.lodingCost)
                          : data.lodingCost
                        : "$0.00"}
                    </td>
                  </tr>
                  <tr>
                    <td>Amount Quoted to Salvex:</td>
                    <td>
                      {data.desctruction_amt_salvex
                        ? "$" +
                          new Intl.NumberFormat("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }).format(data.desctruction_amt_salvex)
                        : "$0.00"}
                    </td>
                  </tr>
                  <tr>
                    <td>Amount Quoted to Seller:</td>
                    <td>
                      {data.desctruction_amt_seller
                        ? "$" +
                          new Intl.NumberFormat("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }).format(data.desctruction_amt_seller)
                        : "$0.00"}
                    </td>
                  </tr>
                  <tr>
                    <td>Proof of Destruction</td>
                    <td>{data.proof_of_destruction ? "Yes" : "N/A"}</td>
                  </tr>
                  {/* <tr>
                    <td>Pickup Location</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Dropoff Location</td>
                    <td></td>
                    <td></td>
                  </tr> */}
                  <tr>
                    <td>Storage Costs</td>
                    <td>
                      {data.storage_cost
                        ? "$" +
                          new Intl.NumberFormat("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }).format(data.storage_cost)
                        : "$0.00"}
                    </td>
                  </tr>
                  <tr>
                    <td>Buyer is responsible to pay for Storage Costs?</td>
                    <td>{data.b_storage_costs ? "Yes" : "N/A"}</td>
                  </tr>
                  <tr>
                    <td>Date Storage Costs Begin</td>
                    <td>
                      {data.storage_cost_date ? moment(data.storage_cost_date).format("MMM-DD-YYYY") + " CST" : "N/A"}
                    </td>
                  </tr>
                  {/* <tr>
                    <td>How often charged</td>
                    <td></td>
                    <td></td>
                  </tr> */}
                  <tr>
                    <td>Customs Duties</td>
                    <td>
                      {data.custom_duty
                        ? "$" +
                          new Intl.NumberFormat("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }).format(data.custom_duty)
                        : "$0.00"}
                    </td>
                  </tr>
                  {/* <tr>
                    <td>Buyer is responsible to pay?</td>
                    <td>{data.b_custom_duty ? "Yes" : "No"}</td>
                    <td></td>
                  </tr> */}
                  <tr>
                    <td>Taxes</td>
                    <td>
                      {data.tax_detail
                        ? "$" +
                          new Intl.NumberFormat("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }).format(data.tax_detail)
                        : "$0.00"}
                    </td>
                  </tr>
                </tbody>
              </>
            )}
          </table>
          {pipeCategories.includes(props.categoryId) && session.sec_list != 100107 && (
            <>
              <div className="dividor"></div>
              <div class="d-flex align-items-center justify-content-between">
                <div class="d-title">Pipe Related Details</div>
              </div>
              <div class="table-responsive table-custom mt-2">
                <table class="table table-bordered">
                  <colgroup>
                    <col style={{ width: "40%" }} />
                    <col />
                  </colgroup>
                  <tbody>
                    <tr>
                      <td style={{ background: "#f5f5f5" }}>High Bid / MT</td>
                      <td>
                        {data.highBidPerMt
                          ? "$" +
                            new Intl.NumberFormat("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(data.highBidPerMt)
                          : "$0.00"}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ background: "#f5f5f5" }}>Estimated Scrap Price</td>
                      <td>
                        {data.estScrapValue
                          ? "$" +
                            new Intl.NumberFormat("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(data.estScrapValue)
                          : "$0.00"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
        <EditProductDetails
          section="trasportation"
          transportation={true}
          show={editModal}
          toggleEditModal={() => toggleEditModal()}
          updateProductDetails={props.updateProductDetails}
          listingProductDetailReceived={props.listingProductDetailReceived}
          auctionId={props.auctionId}
          setLoadingDetails={setLoadingDetails}
          loadingDetails={loadingDetails}
          data={data}
          refStart={() => props.refStart()}
          refStop={() => props.refStop()}
          getLatestBids={props.getLatestBids}
          getPriceInfo={props.getPriceInfo}
          productDetails={props.productDetails}
          detailListing={props.detailListing}
        />
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  //global state for storing api value
  updateProductDetails: (params, data, callback) => dispatch(updateProductDetails(params, data, callback)),
  //global state managed
  listingProductDetailReceived: (data) => dispatch(listingProductDetailReceived(data)),
  updateComparablePrice: (params, data, callback) => dispatch(updateComparablePrice(params, data, callback)),

  getLatestBids: (params, callback) => dispatch(getLatestBids(params, callback)),
  getPriceInfo: (params, callback) => dispatch(getPriceInfo(params, callback)),
  productDetails: (params, callback) => dispatch(productDetails(params, callback)),
  detailListing: (params, callback) => dispatch(detailListing(params, callback)),
});

const mapStateToProps = (state) => ({
  getListingProductDetail: getListingProductDetail(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(TransportationStorage));
