import React, { useEffect, useState } from "react";
import { getOtherListings, otherListingsDetails } from "../../../store/otherListings";
import { connect } from "react-redux";
import more from "../../../include/images/more-icon.svg";
import moment from "moment";
import { toast } from "react-toastify";
import AlertError from "../../../common/alerts/alertError";
import NextButton from "../../../common/form/nextButton";
import Skeleton from "react-loading-skeleton";
import extLink from "../../../include/images/external-link.svg";

import greenEmail from "../../../include/images/green-mail.svg";
import notVerified from "../../../include/images/mail-not-verified.svg";
import invalid from "../../../include/images/mail-invalid.svg";
import spamEmail from "../../../include/images/mail-spammed-blocked.svg";
import purpleEmail from "../../../include/images/mail-purple.svg";

import DateFormat from "../../../common/DateFormat/dateFormat";
import icon1 from "../../../include/images/icon___1.svg";
import icon2 from "../../../include/images/icon__2.svg";
import icon3 from "../../../include/images/red-hand.png";
import MoreButton from "../../../common/buttons/moreButton";

const OtherListings = (props) => {
  const [listFetching, setListFetching] = useState(false);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [listingData, setListingData] = useState([]);
  const [fileterCount, setFileterCount] = useState(0);
  const [showMore, setShowMore] = useState(false);
  const [auctionId, setAuctionId] = useState("");

  const otherListingCount = props?.count?.otherListing;

  const fetchOtherListings = () => {
    setLoading(true);
    props.refStart();
    props.getOtherListings(props.auctionId, props.categoryId, page, (res) => {
      if (res && res?.status === 200) {
        setListingData(res?.data?.data?.list);
        setFileterCount(res?.data?.data?.filteredCount);
        props.refStop();
        setLoading(false);
        setListFetching(true);
      } else {
        toast(<AlertError message={res?.data?.message ? res?.data?.message : "Something Went Wrong"} />);
        props.refStop();
        setLoading(false);
      }
    });
  };
  function formatDateToMMDDYY(dateString) {
    if (!dateString) return "";

    const dateParts = dateString.slice(5, 10).split("-");
    return `${dateParts[0]}/${dateParts[1]}/${dateString.slice(2, 4)}`;
  }

  useEffect(() => {
    if (listFetching) {
      fetchOtherListings();
    }
  }, [page]);
  const fetchMore = (aucId) => {
    if (auctionId === aucId) {
      setAuctionId(null);
      setShowMore(false);
    } else {
      setAuctionId(aucId);
      setShowMore(true);
    }
  };
  const count =
    props &&
    props.otherListingsDetails &&
    props.otherListingsDetails.count1 &&
    props.otherListingsDetails.count1.data &&
    props.otherListingsDetails.count1.data.listOL_count;
  return (
    <div class="details-page-widget-block" id={props.id}>
      <div class="page-widget-head d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <div class="d-flex align-items-center">
            <div
              class={
                !listFetching && !props.loadingMain
                  ? "page-widget-label text-capitalize cursor-pointer"
                  : "page-widget-label text-capitalize cursor-pointer"
              }
              onClick={() => {
                if (!listFetching && !props.loadingMain) {
                  fetchOtherListings();
                } else {
                  setListFetching(false);
                }
              }}
            >
              {props.loadingMain
                ? "Other Listings"
                : `Other Listings ${props.categoryName ? "In" : ""} ${props.categoryName ? props.categoryName : ""} ${
                    otherListingCount > 0 ? `(${otherListingCount})` : "(0)"
                  }`}
            </div>
          </div>
          <li
            className="badge-item cursor-pointer"
            onClick={() => {
              if (!listFetching && !props.loadingMain) {
                fetchOtherListings();
              } else {
                setListFetching(false);
              }
            }}
            style={{ marginLeft: "10px" }}
          >
            <div className="badge-box" style={{ background: "#DADEE0" }}>
              {listFetching ? "Hide" : "Show"}
            </div>
          </li>
        </div>
      </div>
      {props.loadingMain ? (
        <Skeleton height="250px" width="100%" />
      ) : (
        <div class={listingData?.length > 0 && "page-widget-body mt-3"}>
          <div class={listingData?.length > 0 && "table"}>
            {listFetching && (
              <div class="row">
                {listingData.length > 0 && (
                  <div className="d-flex text-right">
                    Page {page} of{" "}
                    {fileterCount % 10 === 0 ? Math.floor(fileterCount / 10) : Math.floor(fileterCount / 10) + 1}
                    {`(${fileterCount} records)`}
                  </div>
                )}
                <div class="col-md-12">
                  <div class={listingData?.length > 0 && "table-responsive position-relative mt-3"}>
                    <table class="table table-bordered custom-table mb-0">
                      {/* <thead>
                      <tr>
                        <th colspan="4" style={{ width: "15.2%" }}>
                          <div class="form-check tick-checkbox d-flex align-items-center">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="checkOne"
                            />
                            <label class="form-check-label" for="checkOne">
                              Actions
                            </label>
                          </div>
                        </th>
                        <th style={{ width: "18.5%" }}>
                          <a href="javascript:void(0)" class="sort-by">
                            Listing
                            <div class="ascending-icon"></div>
                            <div class="descending-icon"></div>
                          </a>
                        </th>
                        <th style={{ width: "200px", minWidth: "195px" }}>
                          <a href="javascript:void(0)" class="sort-by">
                            Trader
                            <div class="ascending-icon"></div>
                            <div class="descending-icon"></div>
                          </a>
                        </th>
                        <th style={{ width: "200px", minWidth: "195px" }}>
                          <a href="javascript:void(0)" class="sort-by">
                            Title
                            <div class="ascending-icon"></div>
                            <div class="descending-icon"></div>
                          </a>
                        </th>
                        <th style={{ width: "200px", minWidth: "195px" }}>
                          <a href="javascript:void(0)" class="sort-by">
                            Title
                            <div class="ascending-icon"></div>
                            <div class="descending-icon"></div>
                          </a>
                        </th>
                        <th style={{ width: " 70px" }} class="text-end">
                          <a href="javascript:void(0)" class="sort-by">
                            Title
                            <div class="ascending-icon"></div>
                            <div class="descending-icon"></div>
                          </a>
                        </th>
                        <th style={{ width: "5.5%" }} class="text-end">
                          <a href="javascript:void(0)" class="sort-by">
                            Title
                            <div class="ascending-icon"></div>
                            <div class="descending-icon"></div>
                          </a>
                        </th>
                        <th style={{ width: "8%" }} class="text-end">
                          <a href="javascript:void(0)" class="sort-by">
                            Title
                            <div class="ascending-icon"></div>
                            <div class="descending-icon"></div>
                          </a>
                        </th>
                        <th class="text-end">Title</th>
                        <th class="text-end">Title</th>
                        <th>
                          <a href="javascript:void(0)" class="sort-by">
                            Title
                            <div class="ascending-icon"></div>
                            <div class="descending-icon"></div>
                          </a>
                        </th>
                        <th style={{ minWidth: "100px", width: "100px" }}>
                          <a href="javascript:void(0)" class="sort-by">
                            Title
                            <div class="ascending-icon"></div>
                            <div class="descending-icon"></div>
                          </a>
                        </th>
                      </tr>
                    </thead> */}
                      <tbody>
                        {listingData.length > 0 &&
                          listingData?.map((val) => (
                            <React.Fragment>
                              <tr>
                                {/* <td>
                            <div class="d-flex align-items-center">
                              <div class="form-check tick-checkbox">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="checkTwo"
                                />
                                <label
                                  class="form-check-label"
                                  for="checkTwo"
                                ></label>
                              </div>
                              <div class="td-action-btn-group d-flex align-items-center justify-content-between">
                                <div class="dropdown">
                                  <a
                                    class="td-a-icon dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <img src={more} alt="" />
                                  </a>
                                  <ul class="dropdown-menu">
                                    <li>
                                      <a class="dropdown-item" href="javascript:void(0)">
                                        Option 1
                                      </a>
                                    </li>
                                    <li>
                                      <a class="dropdown-item" href="javascript:void(0)">
                                        Option 2
                                      </a>
                                    </li>
                                    <li>
                                      <a class="dropdown-item" href="javascript:void(0)">
                                        Option 3
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </td> */}
                                <td>
                                  <div class="td-image-td">
                                    <a href={`/listing-detail?id=${val?.auction_id}`} target="_blank">
                                      <img src={val?.auction?.primary_picture} alt="" />
                                    </a>
                                  </div>
                                </td>
                                <td>
                                  <div class="fw-medium">
                                    <span>{val?.auction?.status_description}</span>
                                  </div>
                                  {val?.auction?.bigdeal && <div class="td-tag">BD</div>}
                                  {val?.auction?.hotlist && <div class="td-tag">PL</div>}
                                </td>
                                <td>
                                  <a
                                    href="javascript:void(0)"
                                    class="btn btn-primary btn-sm"
                                    onClick={() => fetchMore(val?.auction_id)}
                                  >
                                    {auctionId === val?.auction_id ? "Less" : "More"}
                                  </a>
                                </td>
                                {/* <td>
                              <a href="javascript:void(0)" class="btn btn-primary btn-sm">
                                More
                              </a>
                            </td> */}
                                <td>
                                  <div class="fw-medium green-text">
                                    <a href={`/listing-detail?id=${val?.auction_id}`} target="_blank">
                                      {val?.auction?.proxibid > 0 && (
                                        <span class="tag-label blue" style={{ marginRight: "5px" }}>
                                          PB
                                        </span>
                                      )}
                                      <img src={extLink} alt="external-link" />
                                      {val?.auction?.title}
                                    </a>
                                  </div>
                                  <p>
                                    {val?.auction?.quantity.toLocaleString()} {val?.auction?.unit}
                                  </p>
                                </td>
                                <td>
                                  <div
                                    class="fw-medium green-text cursor-pointer"
                                    onClick={() => {
                                      if (val?.auction?.seller_name) {
                                        if (process.env.REACT_APP_MODE == "development") {
                                          window.open(
                                            `https://admin-dev.salvex.com/admin/_user/_edit.cfm?user_id=${val?.auction?.s_user_id}`,
                                          );
                                        } else {
                                          window.open(
                                            `https://admin.salvex.com/admin/_user/_edit.cfm?user_id=${val?.auction?.s_user_id}`,
                                          );
                                        }
                                      }
                                    }}
                                  >
                                    <img src={extLink} alt="external-link" />
                                    {val?.auction?.seller_name}
                                  </div>
                                  <div
                                    className="cursor-pointer"
                                    onClick={() => {
                                      if (val?.auction?.seller_name) {
                                        if (process.env.REACT_APP_MODE == "development") {
                                          window.open(
                                            `https://admin-dev.salvex.com/admin/_user/_edit.cfm?user_id=${val?.auction?.s_user_id}`,
                                          );
                                        } else {
                                          window.open(
                                            `https://admin.salvex.com/admin/_user/_edit.cfm?user_id=${val?.auction?.s_user_id}`,
                                          );
                                        }
                                      }
                                    }}
                                  >
                                    {val?.auction?.seller_company}
                                  </div>
                                  <div class="d-text-list d-flex align-items-center">
                                    <div class="d-text-item">
                                      <span>{val?.auction?.success_rate?.toFixed(2)}%</span>
                                    </div>
                                    <div class="d-text-item">
                                      <span>
                                        {val?.auction?.rate1}/{val?.auction?.rate2}
                                      </span>
                                    </div>
                                    <div class="d-text-item">
                                      {val?.auction?.emailStatusCode === 0 ? (
                                        <img src={greenEmail} alt="" title="Email OK" />
                                      ) : val?.auction?.emailStatusCode === 1 ? (
                                        <img
                                          src={purpleEmail}
                                          alt=""
                                          title="Email Temporarily Suspended/Unreachable or Undetermined (Soft Bounce)"
                                        />
                                      ) : val?.auction?.emailStatusCode === 2 ? (
                                        <img src={invalid} alt="" title="Email Invalid" />
                                      ) : val?.auction?.emailStatusCode === 3 ? (
                                        <img src={spamEmail} alt="" title="Email Blocked by Spam or Firewall" />
                                      ) : (
                                        <img src={notVerified} alt="" title="Email Not Verified" />
                                      )}
                                    </div>
                                  </div>
                                  <div class="td-button-group d-flex">
                                    <img
                                      src={`https://us-central1-salvex-mail.cloudfunctions.net/requestRecipientStats?email=${val?.auction?.seller_email}`}
                                      alt=""
                                    />
                                  </div>
                                  <div>{val?.auction?.industry_description}</div>
                                  <div class="td-icon-list d-flex flex-wrap align-items-center">
                                    {val?.auction?.seller_dhs_approved ? (
                                      <div class="td-icon-item">
                                        <div class="td-icon-box">
                                          <img src={icon1} alt="" />
                                        </div>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    {val?.auction?.seller_okay ? (
                                      <div class="td-icon-item">
                                        <div class="td-icon-box">
                                          <img src={icon2} alt="" />
                                        </div>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    {val?.auction?.seller_stop_working ? (
                                      <div class="td-icon-item">
                                        <div class="td-icon-box">
                                          <img src={icon3} alt="" width="30px" height="30px" />
                                        </div>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </td>
                                <table className="table table-borderless fw-medium mt-3 no-padd-table">
                                  {val?.auction?.trader_name ? (
                                    <tr>
                                      <td className="text-nowrap text-center">T</td>
                                      <td className="text-right">:</td>
                                      <td className="text-right">
                                        <span className="text-truncate text-right">{val?.auction?.trader_name}</span>
                                      </td>
                                    </tr>
                                  ) : (
                                    ""
                                  )}
                                  {val?.auction?.internal_referral ? (
                                    <tr>
                                      <td className="text-nowrap text-center">BD</td>
                                      <td className="text-right">:</td>
                                      <td className="text-right">
                                        <span className="text-truncate text-right">
                                          {val?.auction?.internal_referral}
                                        </span>
                                      </td>
                                    </tr>
                                  ) : (
                                    ""
                                  )}
                                  {val?.auction?.mgr_name ? (
                                    <tr>
                                      <td className="text-nowrap text-center">TM</td>
                                      <td className="text-right">:</td>
                                      <td className="text-right">
                                        <span className="text-truncate text-right">{val?.auction?.mgr_name}</span>
                                      </td>
                                    </tr>
                                  ) : (
                                    ""
                                  )}
                                  {val?.auction?.admin_name ? (
                                    <tr>
                                      <td className="text-nowrap text-center">BDM</td>
                                      <td className="text-right">:</td>
                                      <td className="text-right">
                                        <span className="text-truncate text-right">{val?.auction?.admin_name}</span>
                                      </td>
                                    </tr>
                                  ) : (
                                    ""
                                  )}
                                </table>
                                <td>
                                  {val?.auction?.topbidders[0]?.name ? (
                                    <>
                                      <div class="td-icon-list d-flex flex-wrap align-items-center">
                                        {val?.auction?.topbidders[0]?.dhs_approved ? (
                                          <div class="td-icon-item">
                                            <div class="td-icon-box">
                                              <img src={icon1} alt="" />
                                            </div>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                        {val?.auction?.topbidders[0]?.okay ? (
                                          <div class="td-icon-item">
                                            <div class="td-icon-box">
                                              <img src={icon2} alt="" />
                                            </div>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                        {val?.auction?.topbidders[0]?.stop_working ? (
                                          <div class="td-icon-item">
                                            <div class="td-icon-box">
                                              <img src={icon3} alt="" width="30px" height="30px" />
                                            </div>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      <div
                                        class="fw-medium green-text cursor-pointer"
                                        onClick={() => {
                                          if (val?.auction?.topbidders[0]?.name) {
                                            if (process.env.REACT_APP_MODE == "development") {
                                              window.open(
                                                `https://admin-dev.salvex.com/admin/_user/_edit.cfm?user_id=${val?.auction?.topbidders[0]?.b_user_id}`,
                                              );
                                            } else {
                                              window.open(
                                                `https://admin.salvex.com/admin/_user/_edit.cfm?user_id=${val?.auction?.topbidders[0]?.b_user_id}`,
                                              );
                                            }
                                          }
                                        }}
                                      >
                                        {val?.auction?.topbidders[0]?.name}
                                      </div>
                                      <div class="d-text-list d-flex align-items-center justify-center">
                                        <div class="d-text-item fw-medium green-text-label">
                                          <span
                                            className="text-nowrap text-success"
                                            style={{ color: "#47AD1D !important" }}
                                          >
                                            {val?.auction?.topbidders[0].ct2 && val?.auction?.topbidders[0]?.ct3
                                              ? Math.round(
                                                  (val?.auction?.topbidders[0]?.ct2 /
                                                    val?.auction?.topbidders[0]?.ct3) *
                                                    100,
                                                )
                                              : "0"}
                                            % (
                                            {val?.auction?.topbidders[0]?.ct2 ? val?.auction?.topbidders[0]?.ct2 : "0"})
                                          </span>
                                        </div>
                                        <div class="d-text-item fw-medium grey-text-label">
                                          <span className="text-nowrap text-secondary">
                                            {val?.auction?.topbidders[0]?.ct1 && val?.auction?.topbidders[0]?.ct3
                                              ? Math.round(
                                                  (val?.auction?.topbidders[0]?.ct1 /
                                                    val?.auction?.topbidders[0]?.ct3) *
                                                    100,
                                                )
                                              : "0"}
                                            % (
                                            {val?.auction?.topbidders[0]?.ct1 ? val?.auction?.topbidders[0]?.ct1 : "0"})
                                          </span>
                                        </div>
                                        <div class="d-text-item fw-medium red-text-label">
                                          <span className="text-nowrap text-danger">
                                            {val?.auction?.topbidders[0]?.ct0 && val?.auction?.topbidders[0]?.ct3
                                              ? Math.round(
                                                  (val?.auction?.topbidders[0]?.ct0 /
                                                    val?.auction?.topbidders[0]?.ct3) *
                                                    100,
                                                )
                                              : "0"}
                                            % (
                                            {val?.auction?.topbidders[0]?.ct0 ? val?.auction?.topbidders[0]?.ct0 : "0"})
                                          </span>
                                        </div>
                                        <div class="d-text-item fw-medium" style={{ width: "24px" }}>
                                          {val?.auction?.topbidders[0]?.emailStatusCode === 0 ? (
                                            <img src={greenEmail} alt="" title="Email OK" className="img-fluid" />
                                          ) : val?.auction?.topbidders[0]?.emailStatusCode === 1 ? (
                                            <img
                                              src={purpleEmail}
                                              alt=""
                                              title="Email Temporarily Suspended/Unreachable or Undetermined (Soft Bounce)"
                                              className="img-fluid"
                                            />
                                          ) : val?.auction?.topbidders[0]?.emailStatusCode === 2 ? (
                                            <img src={invalid} alt="" title="Email Invalid" className="img-fluid" />
                                          ) : val?.auction?.topbidders[0]?.emailStatusCode === 3 ? (
                                            <img
                                              src={spamEmail}
                                              alt=""
                                              title="Email Blocked by Spam or Firewall"
                                              className="img-fluid"
                                            />
                                          ) : (
                                            <img
                                              src={notVerified}
                                              alt=""
                                              title="Email Not Verified"
                                              className="img-fluid"
                                            />
                                          )}
                                        </div>
                                      </div>

                                      <div class="td-button-group d-flex">
                                        <img
                                          src={`https://us-central1-salvex-mail.cloudfunctions.net/requestRecipientStats?email=${val?.auction?.topbidders[0]?.email}`}
                                          alt=""
                                        />
                                      </div>
                                      <div>
                                        <i>{val?.auction?.topbidders[0]?.location}</i>
                                      </div>
                                    </>
                                  ) : (
                                    "N/A"
                                  )}
                                </td>
                                <td class="text-end">{val?.auction?.bid_count}</td>
                                <td class="text-end">
                                  {val?.auction?.topbidders.length > 0 &&
                                  val?.auction?.topbidders[0].total_bid > val?.auction?.seller_reserve
                                    ? "$" +
                                      new Intl.NumberFormat("en-US", {
                                        style: "decimal",
                                        minimumFractionDigits: 2,
                                      }).format(val?.auction?.topbidders[0].total_bid - val?.auction?.seller_reserve)
                                    : "N/A"}
                                </td>
                                <td>
                                  <div class="text-end">
                                    {val?.auction?.seller_bid_date ? (
                                      <>
                                        <div class="fw-medium">
                                          {"$" +
                                            new Intl.NumberFormat("en-US", {
                                              style: "decimal",
                                              minimumFractionDigits: 2,
                                            }).format(val?.auction?.seller_bid)}
                                        </div>
                                        <div class="td-light-text">
                                          {formatDateToMMDDYY(val?.auction?.seller_bid_date)}
                                        </div>
                                      </>
                                    ) : (
                                      "N/A"
                                    )}
                                  </div>
                                </td>
                                <td>
                                  <div class="flex-table-row">
                                    {val?.auction?.topbidders[0]?.total_bid ? (
                                      <div class="flex-table-item d-flex flex-nowrap align-items-start">
                                        <div class="ft-txt-start">HB</div>
                                        <div class="ft-dot">:</div>
                                        <div class="ft-txt-end">
                                          <div class="text-end w-100 d-flex flex-column">
                                            <div class="fw-medium">
                                              {val?.auction?.topbidders[0]?.total_bid
                                                ? "$" +
                                                  new Intl.NumberFormat("en-US", {
                                                    style: "decimal",
                                                    minimumFractionDigits: 2,
                                                  }).format(val?.auction?.topbidders[0]?.total_bid)
                                                : "N/A"}
                                            </div>
                                            <div class="td-light-text">
                                              {val?.auction?.topbidders[0]?.total_bid
                                                ? formatDateToMMDDYY(val?.auction?.topbidders[0]?.bid_time)
                                                : ""}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    {val?.auction?.seller_reserve ? (
                                      <div class="flex-table-item d-flex flex-nowrap align-items-start">
                                        <div class="ft-txt-start">SR</div>
                                        <div class="ft-dot">:</div>
                                        <div class="ft-txt-end">
                                          <div class="text-end w-100 d-flex flex-column">
                                            <div class="fw-medium">
                                              {val?.auction?.seller_reserve
                                                ? "$" +
                                                  new Intl.NumberFormat("en-US", {
                                                    style: "decimal",
                                                    minimumFractionDigits: 2,
                                                  }).format(val?.auction?.seller_reserve)
                                                : "N/A"}
                                            </div>
                                            <div class="td-light-text">
                                              {val?.auction?.due_date ? formatDateToMMDDYY(val.auction.due_date) : ""}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    {val?.auction?.orig_wholesale_value ? (
                                      <div class="flex-table-item d-flex flex-nowrap align-items-start">
                                        <div class="ft-txt-start">WV</div>
                                        <div class="ft-dot">:</div>
                                        <div class="ft-txt-end">
                                          <div class="text-end w-100 d-flex flex-column">
                                            <div class="fw-medium">
                                              {val?.auction?.orig_wholesale_value
                                                ? "$" +
                                                  new Intl.NumberFormat("en-US", {
                                                    style: "decimal",
                                                    minimumFractionDigits: 2,
                                                  }).format(val?.auction?.orig_wholesale_value)
                                                : "N/A"}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    {val?.auction?.public_reserve ? (
                                      <div class="flex-table-item d-flex flex-nowrap align-items-start">
                                        <div class="ft-txt-start">AP</div>
                                        <div class="ft-dot">:</div>
                                        <div class="ft-txt-end">
                                          <div class="text-end w-100 d-flex flex-column">
                                            <div class="fw-medium">
                                              {val?.auction?.public_reserve
                                                ? "$" +
                                                  new Intl.NumberFormat("en-US", {
                                                    style: "decimal",
                                                    minimumFractionDigits: 2,
                                                  }).format(val?.auction?.public_reserve)
                                                : "N/A"}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    {val?.auction?.recycle_value ? (
                                      <div class="flex-table-item d-flex flex-nowrap align-items-start">
                                        <div class="ft-txt-start">RV</div>
                                        <div class="ft-dot">:</div>
                                        <div class="ft-txt-end">
                                          <div class="text-end w-100 d-flex flex-column">
                                            <div class="fw-medium">
                                              {val?.auction?.recycle_value
                                                ? "$" +
                                                  new Intl.NumberFormat("en-US", {
                                                    style: "decimal",
                                                    minimumFractionDigits: 2,
                                                  }).format(val?.auction?.recycle_value)
                                                : "N/A"}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </td>
                                <td class="text-end">
                                  {val?.auction?.seller_reserve > 0
                                    ? `${Math.round(val?.auction?.seller_bid / val?.auction?.seller_reserve)}%`
                                    : "N/A"}
                                </td>
                                <td>
                                  {val?.auction?.auction_state === "International" ||
                                  val?.auction?.auction_state === null ? (
                                    <>
                                      <div class="fw-medium">
                                        {val?.auction?.auction_city && val?.auction?.auction_country
                                          ? `${val.auction.auction_city},`
                                          : val?.auction?.auction_city
                                          ? val?.auction?.auction_city
                                          : ""}
                                      </div>
                                      <div class="td-light-text">
                                        <a href="javascript:void(0)" className="green-btn-text">
                                          {val?.auction?.auction_country ? val?.auction?.auction_country : ""}
                                        </a>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div class="fw-medium">
                                        {val?.auction?.auction_city && val?.auction?.auction_state
                                          ? `${val?.auction?.auction_city},`
                                          : val?.auction?.auction_city
                                          ? val?.auction?.auction_city
                                          : ""}
                                      </div>
                                      <div class="td-light-text">
                                        <a href="javascript:void(0)" className="green-btn-text">
                                          {val?.auction?.auction_state ? val?.auction?.auction_state : ""}
                                        </a>
                                      </div>
                                    </>
                                  )}
                                </td>
                                <td>
                                  <div class="fw-medium">{val?.auction?.category_description}</div>
                                </td>
                                {showMore && <td colSpan={2}></td>}
                              </tr>
                              {auctionId === val?.auction_id && (
                                <MoreButton
                                  parentprops={props}
                                  showMore={showMore}
                                  auctionId={auctionId}
                                  refStart={props.refStart}
                                  refStop={props.refStop}
                                />
                              )}
                            </React.Fragment>
                          ))}
                      </tbody>
                    </table>
                  </div>
                  {listingData.length > 0 && (
                    <div class="table-pagination mt-5">
                      <ul class="pagination  justify-content-end">
                        <li class="page-item">
                          <a
                            class={`page-link ${page === 1 ? "disabled" : ""}`}
                            href="javascript:void(0)"
                            aria-label="Previous"
                            onClick={() => {
                              if (page !== 1) {
                                setPage(page - 1);
                              }
                            }}
                          >
                            <svg
                              width="8"
                              height="14"
                              viewBox="0 0 8 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M7 13L1 7L7 1" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                          </a>
                        </li>
                        <li class="page-item active">
                          <a class="page-link" href="javascript:void(0)">
                            {page}
                          </a>
                        </li>
                        <li class="page-item">
                          {page <
                            (fileterCount % 10 === 0
                              ? Math.floor(fileterCount / 10)
                              : Math.floor(fileterCount / 10) + 1) && (
                            <a
                              class={`page-link ${
                                page >=
                                (fileterCount % 10 === 0
                                  ? Math.floor(fileterCount / 10)
                                  : Math.floor(fileterCount / 10) + 1)
                                  ? "disabled"
                                  : ""
                              }`}
                              href="javascript:void(0)"
                              onClick={() => setPage(page + 1)}
                            >
                              {page + 1}
                            </a>
                          )}
                        </li>
                        <li class="page-item">
                          {page <
                            (fileterCount % 10 === 0
                              ? Math.floor(fileterCount / 10)
                              : Math.floor(fileterCount / 10) + 1) && (
                            <a
                              class={`page-link ${
                                page >=
                                (fileterCount % 10 === 0
                                  ? Math.floor(fileterCount / 10)
                                  : Math.floor(fileterCount / 10) + 1)
                                  ? "disabled"
                                  : ""
                              }`}
                              href="javascript:void(0)"
                              aria-label="Next"
                              onClick={() => setPage(page + 1)}
                            >
                              <svg
                                width="8"
                                height="14"
                                viewBox="0 0 8 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M1 13L7 7L1 1" stroke-linecap="round" stroke-linejoin="round" />
                              </svg>
                            </a>
                          )}
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getOtherListings: (params, catId, page, callback) => dispatch(getOtherListings(params, catId, page, callback)),
});
const mapStateToProps = (state) => ({
  otherListingsDetails: otherListingsDetails(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(OtherListings));
