import React from "react";
import { Offcanvas } from "react-bootstrap";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { customStyles } from "../../../../common/customStyles/reactSelectStyle";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const FilterContract = (props) => {
  return (
    <Offcanvas
      show={props.show}
      onHide={() => {
        props.setSelectedFilter({
          enterprise: [],
          contractScope: "",
          contractType: "",
          contractStatus: "",
          startDate: { startFrom: "", startTo: "" },
          expirationDate: { expirationFrom: "", expirationTo: "" },
          automaticRenewal: "",
          businessUnit: "",
          exclusivity: "",
          buyerPremiumOrSellerFee: "",
          salvexEntity: "",
          confidentiality: "",
          nonCircumvention: "",
          registrationAsVendor: "",
        });
        props.onHide();
      }}
      placement="end"
      className="offcanvas offcanvas-end custom-offcanvas-sm "
      backdropClassName="upload-images-backdrop"
      tabindex="-1"
      id="userFilter"
      enforceFocus={false}
    >
      <Offcanvas.Header className="offcanvas-header ">
        <h5 class="offcanvas-title" style={{ marginLeft: "-9px" }}>
          Filters
        </h5>
        <button
          type="button"
          className="btn-close text-reset"
          onClick={() => {
            props.setSelectedFilter({
              enterprise: [],
              contractScope: "",
              contractType: "",
              contractStatus: "",
              startDate: { startFrom: "", startTo: "" },
              expirationDate: { expirationFrom: "", expirationTo: "" },
              automaticRenewal: "",
              businessUnit: "",
              exclusivity: "",
              buyerPremiumOrSellerFee: "",
              salvexEntity: "",
              confidentiality: "",
              nonCircumvention: "",
              registrationAsVendor: "",
            });
            props.onHide();
          }}
        ></button>
      </Offcanvas.Header>
      <Offcanvas.Body className="offcanvas-body co-body">
        <div class="form-group">
          <label for="keyword" class="form-label">
            Contract Scope
          </label>
          <Select
            value={props.selectedFilter?.contractScope}
            options={[
              { name: "Select Contract Scope", value: "-1" },
              ...props.contractScopeOption,
            ]}
            className="basic-single country-margin select-background "
            classNamePrefix="select-search country-margin select-background"
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.value}
            onChange={(e) => {
              const data = { ...props.selectedFilter };
              data.contractScope = e;
              props.setSelectedFilter(data);
            }}
            name="planePart4"
            placeholder="Select Contract Scope"
            styles={customStyles}
          />
        </div>
        <div class="form-group">
          <label for="keyword" class="form-label">
            Contract Type
          </label>
          <Select
            value={props.selectedFilter?.contractType}
            options={[
              { name: "Select Contract Type", value: "-1" },
              ...props.contractTypeOptions,
            ]}
            className="basic-single country-margin select-background "
            classNamePrefix="select-search country-margin select-background"
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.value}
            onChange={(e) => {
              const data = { ...props.selectedFilter };
              data.contractType = e;
              props.setSelectedFilter(data);
            }}
            name="planePart3"
            placeholder="Select Contract Type"
            styles={customStyles}
          />
        </div>
        <div class="form-group">
          <label for="keyword" class="form-label">
            Enterprise
          </label>
          <AsyncSelect
            cacheOptions
            defaultOptions
            value={props.selectedFilter?.enterprise}
            className="basic-single country-margin select-background"
            classNamePrefix="select-search country-margin select-background"
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            loadOptions={props.loadEnterpriseOptions}
            name="enterprise"
            placeholder="Select Enterprise"
            isDisabled={props.selectedItem ? true : false}
            styles={customStyles}
            onChange={(e) => {
              const data = { ...props.selectedFilter };
              data.enterprise = e;
              props.setSelectedFilter(data);
            }}
          />
        </div>
        <div class="form-group">
          <label for="keyword" class="form-label">
            Business Unit
          </label>
          <AsyncSelect
            cacheOptions
            defaultOptions={props.buOptions}
            value={props.selectedFilter?.businessUnit}
            className="basic-single country-margin contract-type-dropdown select-background"
            classNamePrefix="select-search country-margin select-background"
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            loadOptions={props.loadBuOptions}
            name="businessUnits"
            placeholder="Select Business Unit"
            isDisabled={props.selectedFilter?.enterprise?.id ? false : true}
            styles={customStyles}
            onChange={(e) => {
              const d = { ...props.selectedFilter };
              d.businessUnit = e;
              props.setSelectedFilter(d);
            }}
          />
        </div>
        <div class="form-group">
          <label for="keyword" class="form-label">
            Contract Status
          </label>
          <Select
            value={props.selectedFilter?.contractStatus}
            options={[
              { name: "Select Contract Status", value: "-1" },
              ...props.contractStatusOptions,
            ]}
            className="basic-single country-margin select-background "
            classNamePrefix="select-search country-margin select-background"
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.value}
            onChange={(e) => {
              const data = { ...props.selectedFilter };
              data.contractStatus = e;
              props.setSelectedFilter(data);
            }}
            name="planePart2"
            placeholder="Select Contract Status"
            styles={customStyles}
          />
        </div>

        <div class="form-group filter-user-input-width d-flex ">
          <div style={{ width: "47%" }}>
            <label for="keyword" class="form-label">
              Start Date (From)
            </label>
            <DatePicker
              className="form-control filter-user-input-width "
              selected={props.selectedFilter?.startDate?.startFrom}
              onChange={(date) => {
                const data = { ...props.selectedFilter };
                data.startDate["startFrom"] = Date.parse(date);
                props.setSelectedFilter(data);
              }}
            />
            {props.selectedFilter?.startDate?.startTo &&
            !props.selectedFilter?.startDate?.startFrom ? (
              <p className="error-text">Start Date (From) is required</p>
            ) : (
              ""
            )}
          </div>
          <div style={{ width: "47%", marginLeft: "30px" }}>
            {" "}
            <label for="keyword" class="form-label">
              Start Date (To)
            </label>
            <DatePicker
              className="form-control filter-user-input-width "
              selected={props.selectedFilter?.startDate?.startTo}
              onChange={(date) => {
                const data = { ...props.selectedFilter };
                data.startDate["startTo"] = Date.parse(date);
                props.setSelectedFilter(data);
              }}
            />
            {!props.selectedFilter?.startDate?.startTo &&
            props.selectedFilter?.startDate?.startFrom ? (
              <p className="error-text">Start Date (To) is required</p>
            ) : (
              ""
            )}
          </div>
        </div>
        <div class="form-group filter-user-input-width d-flex ">
          <div style={{ width: "47%" }}>
            <label for="keyword" class="form-label">
              Expiration Date (From)
            </label>
            <DatePicker
              className="form-control filter-user-input-width "
              selected={props.selectedFilter?.expirationDate?.expirationFrom}
              onChange={(date) => {
                const data = { ...props.selectedFilter };
                data.expirationDate["expirationFrom"] = Date.parse(date);
                props.setSelectedFilter(data);
              }}
            />
            {props.selectedFilter?.expirationDate?.expirationTo &&
            !props.selectedFilter?.expirationDate?.expirationFrom ? (
              <p className="error-text">Expiration Date (From) is required</p>
            ) : (
              ""
            )}
          </div>
          <div style={{ width: "47%", marginLeft: "30px" }}>
            <label for="keyword" class="form-label">
              Expiration Date (To)
            </label>
            <DatePicker
              className="form-control filter-user-input-width "
              selected={props.selectedFilter?.expirationDate?.expirationTo}
              onChange={(date) => {
                const data = { ...props.selectedFilter };
                data.expirationDate["expirationTo"] = Date.parse(date);
                props.setSelectedFilter(data);
              }}
            />
            {!props.selectedFilter?.expirationDate?.expirationTo &&
            props.selectedFilter?.expirationDate?.expirationFrom ? (
              <p className="error-text">Expiration Date (To) is required</p>
            ) : (
              ""
            )}
          </div>
        </div>

        <div style={{ marginTop: "30px" }}>
          <label class="fw-normal mb-14">Automatic Renewal</label>
          <div class="four-column-radio-group mb-4 gap-user ">
            <div class="fcrg-item  payment-info-main-option">
              <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
                <input
                  class="form-check-input cursor-pointer"
                  type="radio"
                  name="WTflexRadioDefault12013"
                  id="WTflexRadio__120001"
                  checked={props.selectedFilter?.automaticRenewal == "true"}
                  onChange={(e) => {
                    const value = { ...props.selectedFilter };
                    value.automaticRenewal = "true";
                    props.setSelectedFilter(value);
                  }}
                />
                <label
                  class="form-check-label cursor-pointer"
                  for="WTflexRadio__120001"
                >
                  Yes
                </label>
              </div>
            </div>
            <div class="fcrg-item  payment-info-main-option">
              <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
                <input
                  class="form-check-input cursor-pointer"
                  type="radio"
                  name="WTflexRadioDefault12013"
                  id="WTflexRadio__210001"
                  checked={props.selectedFilter?.automaticRenewal == "false"}
                  onChange={(e) => {
                    const value = { ...props.selectedFilter };
                    value.automaticRenewal = "false";
                    props.setSelectedFilter(value);
                  }}
                />

                <label
                  class="form-check-label cursor-pointer"
                  for="WTflexRadio__210001"
                >
                  No
                </label>
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginTop: "30px" }}>
          <label class="fw-normal mb-14">Exclusivity</label>
          <div class="four-column-radio-group mb-4 gap-user ">
            <div class="fcrg-item  payment-info-main-option">
              <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
                <input
                  class="form-check-input cursor-pointer"
                  type="radio"
                  name="WTflexRadioDefault12014"
                  id="WTflexRadio__120002"
                  checked={props.selectedFilter?.exclusivity == "true"}
                  onChange={(e) => {
                    const value = { ...props.selectedFilter };
                    value.exclusivity = "true";
                    props.setSelectedFilter(value);
                  }}
                />
                <label
                  class="form-check-label cursor-pointer"
                  for="WTflexRadio__120002"
                >
                  Yes
                </label>
              </div>
            </div>
            <div class="fcrg-item  payment-info-main-option">
              <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
                <input
                  class="form-check-input cursor-pointer"
                  type="radio"
                  name="WTflexRadioDefault12014"
                  id="WTflexRadio__210002"
                  checked={props.selectedFilter?.exclusivity == "false"}
                  onChange={(e) => {
                    const value = { ...props.selectedFilter };
                    value.exclusivity = "false";
                    props.setSelectedFilter(value);
                  }}
                />

                <label
                  class="form-check-label cursor-pointer"
                  for="WTflexRadio__210002"
                >
                  No
                </label>
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginTop: "30px" }}>
          <label class="fw-normal mb-14">Buyer Premium/ Seller Fee</label>
          <div class="four-column-radio-group mb-4 gap-user ">
            <div class="fcrg-item  payment-info-main-option">
              <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
                <input
                  class="form-check-input cursor-pointer"
                  type="radio"
                  name="WTflexRadioDefault12015"
                  id="WTflexRadio__120004"
                  checked={
                    props.selectedFilter?.buyerPremiumOrSellerFee == "true"
                  }
                  onChange={(e) => {
                    const value = { ...props.selectedFilter };
                    value.buyerPremiumOrSellerFee = "true";
                    props.setSelectedFilter(value);
                  }}
                />
                <label
                  class="form-check-label cursor-pointer"
                  for="WTflexRadio__120004"
                >
                  Yes
                </label>
              </div>
            </div>
            <div class="fcrg-item  payment-info-main-option">
              <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
                <input
                  class="form-check-input cursor-pointer"
                  type="radio"
                  name="WTflexRadioDefault12015"
                  id="WTflexRadio__210004"
                  checked={
                    props.selectedFilter?.buyerPremiumOrSellerFee == "false"
                  }
                  onChange={(e) => {
                    const value = { ...props.selectedFilter };
                    value.buyerPremiumOrSellerFee = "false";
                    props.setSelectedFilter(value);
                  }}
                />

                <label
                  class="form-check-label cursor-pointer"
                  for="WTflexRadio__210004"
                >
                  No
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="keyword" class="form-label">
            Salvex Entity
          </label>
          <Select
            value={props.selectedFilter?.salvexEntity}
            options={[
              { name: "Select Salvex Entity", value: "-1" },
              ...props.entityOptions,
            ]}
            className="basic-single country-margin select-background "
            classNamePrefix="select-search country-margin select-background"
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.value}
            onChange={(e) => {
              const data = { ...props.selectedFilter };
              data.salvexEntity = e;
              props.setSelectedFilter(data);
            }}
            name="planePart"
            placeholder="Select Salvex Entity"
            styles={customStyles}
          />
        </div>
        <div style={{ marginTop: "30px" }}>
          <label class="fw-normal mb-14">Confidentiality</label>
          <div class="four-column-radio-group mb-4 gap-user ">
            <div class="fcrg-item  payment-info-main-option">
              <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
                <input
                  class="form-check-input cursor-pointer"
                  type="radio"
                  name="WTflexRadioDefault12016"
                  id="WTflexRadio__120006"
                  checked={props.selectedFilter?.confidentiality == "true"}
                  onChange={(e) => {
                    const value = { ...props.selectedFilter };
                    value.confidentiality = "true";
                    props.setSelectedFilter(value);
                  }}
                />
                <label
                  class="form-check-label cursor-pointer"
                  for="WTflexRadio__120006"
                >
                  Yes
                </label>
              </div>
            </div>
            <div class="fcrg-item  payment-info-main-option">
              <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
                <input
                  class="form-check-input cursor-pointer"
                  type="radio"
                  name="WTflexRadioDefault12016"
                  id="WTflexRadio__210006"
                  checked={props.selectedFilter?.confidentiality == "false"}
                  onChange={(e) => {
                    const value = { ...props.selectedFilter };
                    value.confidentiality = "false";
                    props.setSelectedFilter(value);
                  }}
                />

                <label
                  class="form-check-label cursor-pointer"
                  for="WTflexRadio__210006"
                >
                  No
                </label>
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginTop: "30px" }}>
          <label class="fw-normal mb-14">Non-Circumvention</label>
          <div class="four-column-radio-group mb-4 gap-user ">
            <div class="fcrg-item  payment-info-main-option">
              <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
                <input
                  class="form-check-input cursor-pointer"
                  type="radio"
                  name="WTflexRadioDefault12017"
                  id="WTflexRadio__120007"
                  checked={props.selectedFilter?.nonCircumvention == "true"}
                  onChange={(e) => {
                    const value = { ...props.selectedFilter };
                    value.nonCircumvention = "true";
                    props.setSelectedFilter(value);
                  }}
                />
                <label
                  class="form-check-label cursor-pointer"
                  for="WTflexRadio__120007"
                >
                  Yes
                </label>
              </div>
            </div>
            <div class="fcrg-item  payment-info-main-option">
              <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
                <input
                  class="form-check-input cursor-pointer"
                  type="radio"
                  name="WTflexRadioDefault12017"
                  id="WTflexRadio__210007"
                  checked={props.selectedFilter?.nonCircumvention == "false"}
                  onChange={(e) => {
                    const value = { ...props.selectedFilter };
                    value.nonCircumvention = "false";
                    props.setSelectedFilter(value);
                  }}
                />

                <label
                  class="form-check-label cursor-pointer"
                  for="WTflexRadio__210007"
                >
                  No
                </label>
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginTop: "30px" }}>
          <label class="fw-normal mb-14">Registration as a Vendor</label>
          <div class="four-column-radio-group mb-4 gap-user ">
            <div class="fcrg-item  payment-info-main-option">
              <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
                <input
                  class="form-check-input cursor-pointer"
                  type="radio"
                  name="WTflexRadioDefault12018"
                  id="WTflexRadio__120008"
                  checked={props.selectedFilter?.registrationAsVendor == "true"}
                  onChange={(e) => {
                    const value = { ...props.selectedFilter };
                    value.registrationAsVendor = "true";
                    props.setSelectedFilter(value);
                  }}
                />
                <label
                  class="form-check-label cursor-pointer"
                  for="WTflexRadio__120008"
                >
                  Yes
                </label>
              </div>
            </div>
            <div class="fcrg-item  payment-info-main-option">
              <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
                <input
                  class="form-check-input cursor-pointer"
                  type="radio"
                  name="WTflexRadioDefault12018"
                  id="WTflexRadio__210008"
                  checked={
                    props.selectedFilter?.registrationAsVendor == "false"
                  }
                  onChange={(e) => {
                    const value = { ...props.selectedFilter };
                    value.registrationAsVendor = "false";
                    props.setSelectedFilter(value);
                  }}
                />

                <label
                  class="form-check-label cursor-pointer"
                  for="WTflexRadio__210008"
                >
                  No
                </label>
              </div>
            </div>
          </div>
        </div>
      </Offcanvas.Body>
      <div class="offcanvas-footer">
        <a
          href="javascript:void(0)"
          class={`btn btn-default btn-block ${
            props.loadingFilter ||
            (!props.selectedFilter?.expirationDate?.expirationTo &&
              props.selectedFilter?.expirationDate?.expirationFrom) ||
            (props.selectedFilter?.expirationDate?.expirationTo &&
              !props.selectedFilter?.expirationDate?.expirationFrom) ||
            (props.selectedFilter?.startDate?.startTo &&
              !props.selectedFilter?.startDate?.startFrom) ||
            (!props.selectedFilter?.startDate?.startTo &&
              props.selectedFilter?.startDate?.startFrom)
              ? "disabled"
              : ""
          }`}
          onClick={() => props.handleFilter()}
        >
          Apply Filters
        </a>
      </div>
    </Offcanvas>
  );
};

export default React.memo(FilterContract);
