import React from "react";

const Pagination = (props) => {
  return (
    <div class="table-pagination">
      <ul class="pagination  justify-content-end">
        <li class="page-item">
          <a
            class={`page-link ${props.page === 1 ? "disabled" : ""}`}
            href="#"
            aria-label="Previous"
            onClick={() => {
              if (props.page !== 1) {
                props.setPage(props.page - 1);
              }
            }}
          >
            <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7 13L1 7L7 1" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </a>
        </li>
        <li class="page-item active">
          <a class="page-link" href="javascript:void(0)">
            {props.page}
          </a>
        </li>
        {props?.totalPages > 1 && props.page + 1 <= props?.totalPages && (
          <li class="page-item">
            <a class="page-link" href="#" onClick={() => props.setPage(props.page + 1)}>
              {props.page + 1}
            </a>
          </li>
        )}
        <li class="page-item">
          <a
            class={`page-link ${props.page + 1 >= props?.totalPages ? "disabled" : ""}`}
            href="#"
            aria-label="Next"
            onClick={() => props.setPage(props.page + 1)}
          >
            <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 13L7 7L1 1" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Pagination;
