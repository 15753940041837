import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import close from "../../../include/images/close.svg";
import greenEmail from "../../../include/images/green-mail.svg";
import notVerified from "../../../include/images/mail-not-verified.svg";
import invalid from "../../../include/images/mail-invalid.svg";
import spamEmail from "../../../include/images/mail-spammed-blocked.svg";
import purpleEmail from "../../../include/images/mail-purple.svg";
import DateFormat from "../../../common/DateFormat/dateFormat";
import { toast } from "react-toastify";
import AlertError from "../../../common/alerts/alertError";
import { connect } from "react-redux";
import { buyerInterestsData, getBuyerInterestList } from "../../../store/buyerInterests";

const BuyerInterestList = (props) => {
  const listingData = props?.buyerInterestsData?.buyerInterestList?.data;
  const Count = props?.buyerInterestsData?.buyerInterestList?.total_records;

  const [page, setPage] = useState(1);

  useEffect(() => {
    if (props.show) {
      props.refStart();
      props.getBuyerInterestList(props.auctionId, page, (res) => {
        if (res.status === 200) {
          props.refStop();
        } else {
          props.refStop();
          toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        }
      });
    }
  }, [page]);
  return (
    <Modal
      className="modal fade custom-modal"
      id="moreEmailClicker"
      show={props.show}
      onHide={props.onHide}
      dialogClassName="modal-dialog modal-dialog-centered modal-xxl"
    >
      <div class="modal-content d-flex flex-column">
        <div class="custom-modal-header d-flex align-items-center justify-content-between">
          <h5 class="mb-0 text-capitalize">Buyer Interest</h5>
          <button
            type="button"
            class="close-btn d-flex align-items-center justify-content-center"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={props.onHide}
          >
            <img src={close} alt="" />
          </button>
        </div>
        <Modal.Body className="custom-modal-body flex-grow-1 w-100 overflow-hidden pb-0">
          <div className="d-flex text-right">
            Page {page} of {Count % 10 === 0 ? Math.floor(Count / 10) : Math.floor(Count / 10) + 1}
            {`(${Count} records)`}
          </div>
          <div class="table-responsive position-relative mt-3">
            <table class="table table-bordered custom-table mb-0">
              <thead>
                <tr>
                  <th style={{ width: "165px", minWidth: "150px" }}>
                    <a href="javascript:void(0)" class="sort-by">
                      Customer
                    </a>
                  </th>
                  <th style={{ width: "200px", minWidth: "150px" }}>
                    <a href="javascript:void(0)" class="sort-by">
                      Notes
                    </a>
                  </th>
                  <th style={{ width: "200px", minWidth: "150px" }}>
                    <a href="javascript:void(0)" class="sort-by">
                      Status
                    </a>
                  </th>
                  <th style={{ width: "200px", minWidth: "150px" }}>
                    <a href="javascript:void(0)" class="sort-by">
                      Buyer Motivation
                    </a>
                  </th>
                  <th style={{ width: "190px", minWidth: "185px" }}>
                    <a href="javascript:void(0)" class="sort-by">
                      Feedback
                    </a>
                  </th>
                  <th style={{ width: "190px", minWidth: "185px" }}>
                    <a href="javascript:void(0)" class="sort-by">
                      Company
                    </a>
                  </th>
                  <th style={{ width: "190px", minWidth: "185px" }}>
                    <a href="javascript:void(0)" class="sort-by">
                      Email
                    </a>
                  </th>
                  <th style={{ width: "140px", minWidth: "130px" }}>
                    <a href="javascript:void(0)" class="sort-by">
                      Phone
                    </a>
                  </th>
                  <th style={{ width: "200px", minWidth: "195px" }}>
                    <a href="javascript:void(0)" class="sort-by">
                      Location
                    </a>
                  </th>
                  <th style={{ width: "8%" }}>
                    <a href="javascript:void(0)" class="sort-by">
                      Profit
                    </a>
                  </th>
                  <th></th>
                  <th style={{ width: "8%" }}>
                    <a href="javascript:void(0)" class="sort-by">
                      Created
                    </a>
                  </th>
                </tr>
              </thead>
              <tbody>
                {listingData &&
                  listingData.map((val) => (
                    <tr>
                      <td>
                        <div class="fw-medium green-text">
                          {val?.customer?.customer_name ? val?.customer?.customer_name : "N/A"}
                          <div className="text-danger">
                            {val?.customer?.credit > 0 &&
                              `($${new Intl.NumberFormat("en-US", {
                                style: "decimal",
                                minimumFractionDigits: 2,
                              }).format(val?.customer?.credit)})`}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div dangerouslySetInnerHTML={{ __html: val.notes }} />
                        <div className="mt-2">
                          {val.updated_notes && "Updated at"} <DateFormat date={val.updated_notes} />
                        </div>
                      </td>
                      <td>
                        <div>{val.status}</div>
                        <div className="mt-2">
                          {val.updated_status && "Updated at"} <DateFormat date={val.updated_status} />
                        </div>
                      </td>
                      <td>{val.buyer_motivation}</td>
                      <td>
                        <div className="fw-medium">
                          Feedback:{" "}
                          <span className="green-text">
                            {val?.customer?.buyerFeedback?.good === 0
                              ? "0% "
                              : `${Math.round(
                                (val?.customer?.buyerFeedback?.good / val?.customer?.buyerFeedback?.total) * 100,
                              )}% `}
                          </span>
                          <span>
                            {val?.customer?.buyerFeedback?.good === 0
                              ? "0% "
                              : `${Math.round(
                                (val?.customer?.buyerFeedback?.neutral / val?.customer?.buyerFeedback?.total) * 100,
                              )}% `}
                          </span>
                          <span className="text-danger">
                            {val?.customer?.buyerFeedback?.good === 0
                              ? "0% "
                              : `${Math.round(
                                (val?.customer?.buyerFeedback?.bad / val?.customer?.buyerFeedback?.total) * 100,
                              )}% `}
                          </span>
                        </div>
                      </td>
                      <td>{val?.customer?.customer_company ? val?.customer?.customer_company : "N/A"}</td>
                      <td>
                        <div className="d-flex flex-column">
                          <div class="fw-medium green-text">
                            <a href={`mailto:${val?.customer?.customer_email}`}>{val?.customer?.customer_email}</a>
                          </div>
                          <div class="d-text-item fw-medium">
                            {val?.customer?.emailstatuscode === 0 ? (
                              <img src={greenEmail} alt="" title="Email OK" />
                            ) : val?.customer?.emailstatuscode === 1 ? (
                              <img src={purpleEmail} alt="" title="purple" />
                            ) : val?.customer?.emailstatuscode === 2 ? (
                              <img src={invalid} alt="" title="Email Invalid" />
                            ) : val?.customer?.emailstatuscode === 3 ? (
                              <img src={spamEmail} alt="" title="Email Blocked by Spam or Firewall" />
                            ) : (
                              <img src={notVerified} alt="" title="Email Not Verified" />
                            )}
                          </div>
                        </div>

                        <div class="td-button-group">
                          <img
                            src={`https://us-central1-salvex-mail.cloudfunctions.net/requestRecipientStats?email=${val?.customer?.customer_email}`}
                            alt=""
                          />
                        </div>
                      </td>
                      <td>
                        <div className="fw-medium">
                          {val?.customer?.customer_phone ? val?.customer?.customer_phone : "N/A"}
                        </div>
                      </td>
                      <td class="text-start">
                        <div className="fw-medium">
                          {val?.customer?.customer_city && val?.customer?.customer_city + ", "}

                          {val?.customer?.customer_state === "International"
                            ? val?.customer?.customer_country
                            : val?.customer?.customer_state
                              ? val?.customer?.customer_state
                              : "N/A"}
                        </div>
                      </td>
                      <td class="text-start">
                        <div class="fw-medium">
                          {val?.customer?.profitBuying
                            ? "$" +
                            new Intl.NumberFormat("en-US", {
                              style: "decimal",
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(val?.customer?.profitBuying)
                            : "$0.00"}
                        </div>
                        {/* <div class="fw-medium">$1,400,000.00</div> */}
                      </td>
                      <td>
                        <div class="flex-table-row">
                          <div class="flex-table-item d-flex flex-nowrap align-items-center justify-content-center">
                            <button
                              className="btn btn-primary btn-xs-size btn-auto"
                              onClick={() => {
                                props.fetchUserDetail(val.user_id);
                              }}
                            >
                              MORE
                            </button>
                          </div>
                          <div class="flex-table-item d-flex flex-nowrap align-items-center justify-content-center">
                            {props.auctionId > 0 && val?.phoned ? (
                              <div className="fw-medium">
                                Call at: <DateFormat date={val.phoned} />
                              </div>
                            ) : (
                              <button
                                className="btn btn-primary btn-xs-size btn-auto"
                                onClick={() => props.handleCallEmailClicker(val.user_id)}
                              >
                                Call
                              </button>
                            )}
                          </div>
                          <div class="flex-table-item d-flex flex-nowrap align-items-center justify-content-center">
                            {val?.buyer_intrest ? (
                              <div className="fw-medium">
                                Buyer Interest added at:
                                <DateFormat date={val?.buyer_intrest} />
                              </div>
                            ) : (
                              <button
                                className="btn btn-primary btn-xs-size btn-auto"
                                onClick={() => props.handleBuyingInterest(val?.user_id)}
                              >
                                Buyer Interest
                              </button>
                            )}
                          </div>
                        </div>
                      </td>
                      <td>
                        <DateFormat date={val.created} />
                      </td>
                      {/* <td>
                              <div className="d-flex justify-content-center align-items-center">
                                <button className="btn btn-sm btn-primary btn-push-email">
                                  Push
                                </button>
                              </div>
                            </td> */}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div class="table-pagination mt-3 mb-3">
            <ul class="pagination  justify-content-end">
              <li class="page-item">
                <a
                  class={`page-link   ${page === 1 ? "disabled" : "cursor-pointer"}`}
                  aria-label="Previous"
                  onClick={() => {
                    if (page !== 1) {
                      setPage(page - 1);
                    }
                  }}
                >
                  <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 13L1 7L7 1" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </a>
              </li>
              <li class="page-item active">
                <a class="page-link" href="#">
                  {page}
                </a>
              </li>
              <li class="page-item">
                {page < (Count % 10 === 0 ? Math.floor(Count / 10) : Math.floor(Count / 10) + 1) && (
                  <a
                    class={`page-link ${page >= (Count % 10 === 0 ? Math.floor(Count / 10) : Math.floor(Count / 10) + 1)
                        ? "disabled"
                        : "cursor-pointer"
                      }`}
                    onClick={() => setPage(page + 1)}
                  >
                    {page + 1}
                  </a>
                )}
              </li>
              <li class="page-item">
                {page < (Count % 10 === 0 ? Math.floor(Count / 10) : Math.floor(Count / 10) + 1) && (
                  <a
                    class={`page-link ${page >= (Count % 10 === 0 ? Math.floor(Count / 10) : Math.floor(Count / 10) + 1)
                        ? "disabled"
                        : "cursor-pointer"
                      }`}
                    aria-label="Next"
                    onClick={() => setPage(page + 1)}
                  >
                    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 13L7 7L1 1" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </a>
                )}
              </li>
            </ul>
          </div>
        </Modal.Body>
        <div class="custom-modal-dividor"></div>
        <div class="custom-modal-footer d-grid gap-2 d-md-flex align-items-center justify-content-md-end">
          <button
            class="btn btn-link me-md-2"
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={props.onHide}
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getBuyerInterestList: (params, page, callback) => dispatch(getBuyerInterestList(params, page, callback)),
});
const mapStateToProps = (state) => ({
  buyerInterestsData: buyerInterestsData(state),
});
export default connect(mapStateToProps, mapDispatchToProps)(React.memo(BuyerInterestList));
