import { createSlice, createSelector } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
const slice = createSlice({
  name: "payables",
  initialState: {
    loading: false,
    payables: [],
    status: [],
    requestor: [],
    states: [],
    expenseType: [],
    paymentMethod: [],
    paymentTerm: [],
    deliveryMethod: [],
    deliveryService: [],
    deliveryInformation: [],
    payable: {},
  },
  reducers: {
    payableListsRequested: (state, action) => {
      state.loading = true;
    },
    payableListsRecieved: (state, action) => {
      state.payables = action.payload;
      state.loading = false;
    },
    payableListsFailed: (state, action) => {
      state.loading = false;
    },
    statusRequested: (state, action) => {
      state.loading = true;
    },
    statusRecieved: (state, action) => {
      state.status = action.payload;
      state.loading = false;
    },
    statusFailed: (state, action) => {
      state.loading = false;
    },
    requestorRequested: (state, action) => {
      state.loading = true;
    },
    requestorRecieved: (state, action) => {
      state.requestor = action.payload;
      state.loading = false;
    },
    requestorFailed: (state, action) => {
      state.loading = false;
    },
    stateRequested: (state, action) => {
      state.loading = true;
    },
    stateRecieved: (state, action) => {
      state.states = action.payload;
      state.loading = false;
    },
    stateFailed: (state, action) => {
      state.loading = false;
    },
    expenseRequested: (state, action) => {
      state.loading = true;
    },
    expenseRecieved: (state, action) => {
      state.expenseType = action.payload;
      state.loading = false;
    },
    expenseFailed: (state, action) => {
      state.loading = false;
    },
    payMethodRequested: (state, action) => {
      state.loading = true;
    },
    payMethodRecieved: (state, action) => {
      state.paymentMethod = action.payload;
      state.loading = false;
    },
    payMethodFailed: (state, action) => {
      state.loading = false;
    },
    payTermRequested: (state, action) => {
      state.loading = true;
    },
    payTermRecieved: (state, action) => {
      state.paymentTerm = action.payload;
      state.loading = false;
    },
    payTermFailed: (state, action) => {
      state.loading = false;
    },
    deliveryMethodRequested: (state, action) => {
      state.loading = true;
    },
    deliveryMethodRecieved: (state, action) => {
      state.deliveryMethod = action.payload;
      state.loading = false;
    },
    deliveryMethodFailed: (state, action) => {
      state.loading = false;
    },
    deliveryServiceRequested: (state, action) => {
      state.loading = true;
    },
    deliveryServiceRecieved: (state, action) => {
      state.deliveryService = action.payload;
      state.loading = false;
    },
    deliveryServiceFailed: (state, action) => {
      state.loading = false;
    },
    deliveryInformationRequested: (state, action) => {
      state.loading = true;
    },
    deliveryInformationRecieved: (state, action) => {
      state.deliveryInformation = action.payload;
      state.loading = false;
    },
    deliveryInformationFailed: (state, action) => {
      state.loading = false;
    },
    payableRequested: (state, action) => {
      state.loading = true;
    },
    payableRecieved: (state, action) => {
      state.payable = action.payload;
      state.loading = false;
    },
    payableFailed: (state, action) => {
      state.loading = false;
    },
  },
});

export const {
  payableListsRequested,
  payableListsRecieved,
  payableListsFailed,
  statusRequested,
  statusRecieved,
  statusFailed,
  requestorRequested,
  requestorRecieved,
  requestorFailed,
  stateRequested,
  stateRecieved,
  stateFailed,
  expenseRequested,
  expenseRecieved,
  expenseFailed,
  payMethodRequested,
  payMethodRecieved,
  payMethodFailed,
  payTermRequested,
  payTermRecieved,
  payTermFailed,
  deliveryMethodRequested,
  deliveryMethodRecieved,
  deliveryMethodFailed,
  deliveryServiceRequested,
  deliveryServiceRecieved,
  deliveryServiceFailed,
  deliveryInformationRequested,
  deliveryInformationRecieved,
  deliveryInformationFailed,
  payableRequested,
  payableRecieved,
  payableFailed,
} = slice.actions;
export default slice.reducer;

//Action Creators
const baseUrl = "auctionAccounting/payable";

//Get Payable Lists
export const getPayableLists = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/list/${params}`,
      method: "get",
      callback,
      onStart: payableListsRequested.type,
      onSuccess: payableListsRecieved.type,
      onError: payableListsFailed.type,
    })
  );
};

//Get Status
export const getStatus = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/status`,
      method: "get",
      callback,
      onStart: statusRequested.type,
      onSuccess: statusRecieved.type,
      onError: statusFailed.type,
    })
  );
};

//Get Requestor
export const getRequestor = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/requestor`,
      method: "get",
      callback,
      onStart: requestorRequested.type,
      onSuccess: requestorRecieved.type,
      onError: requestorFailed.type,
    })
  );
};

//Get States
export const getStates = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/state`,
      method: "get",
      callback,
      onStart: stateRequested.type,
      onSuccess: stateRecieved.type,
      onError: stateFailed.type,
    })
  );
};

//Get ExpenseType
export const getExpenseType = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/expenseType`,
      method: "get",
      callback,
      onStart: expenseRequested.type,
      onSuccess: expenseRecieved.type,
      onError: expenseFailed.type,
    })
  );
};

//Get Payment Method
export const getPaymentMethod = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/paymentMethod`,
      method: "get",
      callback,
      onStart: payMethodRequested.type,
      onSuccess: payMethodRecieved.type,
      onError: payMethodFailed.type,
    })
  );
};

//Get Payment Term
export const getPaymentTerm = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/paymentTerm`,
      method: "get",
      callback,
      onStart: payTermRequested.type,
      onSuccess: payTermRecieved.type,
      onError: payTermFailed.type,
    })
  );
};

//Get Delivery Method
export const getDeliveryMethod = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/deliveryMethod`,
      method: "get",
      callback,
      onStart: deliveryMethodRequested.type,
      onSuccess: deliveryMethodRecieved.type,
      onError: deliveryMethodFailed.type,
    })
  );
};

//Get Delivery Service
export const getDeliveryService = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/deliveryService`,
      method: "get",
      callback,
      onStart: deliveryServiceRequested.type,
      onSuccess: deliveryServiceRecieved.type,
      onError: deliveryServiceFailed.type,
    })
  );
};

//Get Delivery Information
export const getDeliveryInformation = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/deliveryInformation`,
      method: "get",
      callback,
      onStart: deliveryInformationRequested.type,
      onSuccess: deliveryInformationRecieved.type,
      onError: deliveryInformationFailed.type,
    })
  );
};

//Get Payable (pre fields)
export const getPayable = (params, payableId, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/${params}?payable_id=${payableId}`,
      method: "get",
      callback,
      onStart: payableRequested.type,
      onSuccess: payableRecieved.type,
      onError: payableFailed.type,
    })
  );
};

//Add New Payable
export const addNewPayable = (params, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/${params}`,
      method: "post",
      data,
      callback,
    })
  );
};

//Delete Payable
export const deletePayable = (params, payableId, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/${params}?payable_id=${payableId}`,
      method: "delete",
      callback,
    })
  );
};

export const payableDetails = createSelector(
  (state) => state.entities.payables,
  (payables) => payables
);
