import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getTasks,
  getTask,
  addNewTask,
  getAssignTo,
  getAssignToForEdit,
  updateTask,
  getTaskStatus,
  deleteTask,
  taskDataReceived,
  getTaskDetail,
} from "../../../store/tasks";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import parse from "html-react-parser";
import AlertError from "../../../common/alerts/alertError";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import { toast } from "react-toastify";
import AddTaskModal from "../../../layouts/modals/taskModal/addTaskModal";
import edit from "../../../include/images/edit-18x18.svg";
import deletebtn from "../../../include/images/trash-18x18.svg";
import EditTaskModal from "../../../layouts/modals/taskModal/editTaskModal";
import DeleteModal from "../../../layouts/modals/deleteModal";
import DateFormat from "../../../common/DateFormat/dateFormat";
import helpCircle from "../../../include/images/help-circle.svg";
import ViewTaskModal from "../../../layouts/modals/taskModal/viewTaskModal";
import { getProductCount } from "../../../store/listingProduct";
const Task = (props) => {
  const [addTaskToggle, setAddTaskToggle] = useState(false);
  const [editTaskToggle, setEditTaskToggle] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [loadingTask, setLoadingTask] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [selectAssign, setAssign] = useState({ name: "", member_id: -1 });
  const [assignData, setAssignData] = useState(null);
  const [taskDescription, setTaskDescription] = useState(null);
  const [status, setStatus] = useState({ des: "", sid: -1 });
  const [taskTitle, setTaskTitle] = useState("");
  const [statusList, setStatusList] = useState([]);
  const [taskId, setTaskId] = useState("");
  const [viewModalData, setViewModalData] = useState();
  const [showTaskDetailModal, setShowtaskDetailModal] = useState(false);
  const [showTask, setShowTask] = useState(false);

  const count = props?.count?.allTask;
  //Show Tasks
  const showTasks = () => {
    props.refStart();
    props.getTask(props.auctionId, (res) => {
      if (res.status === 200) {
        props.refStop();
        setShowTask(true);
      } else {
        props.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  // states for edit
  const [editAssignData, setEditAssignData] = useState(null);

  const taskData = (props.getTasks && props.getTasks.taskData && props.getTasks.taskData.data) || {};

  const toggleAddTaskModal = () => {
    setAddTaskToggle(!addTaskToggle);
  };

  const toggleEditTaskModal = (item) => {
    if (item !== undefined) {
      setEditTaskToggle(item.task_id);
      setTaskDescription(item.description?.replace(/\r\n/g, "<br/>"));
      setTaskTitle(item.title);
      setStatus({ des: item.ST_DES, sid: item.status_id });
      setAssign({ name: item.ASSIGNED, member_id: item.assigned_to });
    } else {
      setEditTaskToggle("");
    }
  };

  const toggleViewTaskModal = (taskId) => {
    props.refStart();
    props.getTaskDetail(props.auctionId, taskId, (res) => {
      if (res.status === 200) {
        props.refStop();
        setViewModalData(res.data.data);
        setShowtaskDetailModal(!showTaskDetailModal);
      } else {
        props.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const hideViewTaskModal = () => {
    setShowtaskDetailModal(!showTaskDetailModal);
  };
  //Togle Delete Modal
  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  useEffect(() => {
    if (addTaskToggle) {
      props.getAssignTo((res) => {
        if (res && res.status === 200) {
          const data =
            res.data &&
            res.data.data.length > 0 &&
            res.data.data.map((item) => ({
              name: item.name,
              member_id: item.member_id,
            }));
          setAssignData(data);
        }
      });
    }
  }, [addTaskToggle]);

  // for edit

  useEffect(() => {
    if (editTaskToggle) {
      props.getAssignToForEdit((res) => {
        if (res && res.status === 200) {
          const data =
            res.data &&
            res.data.data.length > 0 &&
            res.data.data.map((item) => ({
              name: item.name01,
              member_id: item.member_id,
            }));
          setEditAssignData(data);
        }
      });
    }
  }, [editTaskToggle]);

  //Get Task Status
  useEffect(() => {
    if (editTaskToggle) {
      props.getTaskStatus((res) => {
        if (res.status === 200) {
          setStatusList(res?.data?.data);
        }
      });
    }
  }, [editTaskToggle]);

  const handleSubmit = () => {
    setLoadingTask(true);
    props.refStart();
    const id = props.auctionId;
    const data = {
      title: "",
      assigned_to: selectAssign.member_id,
      description: taskDescription,
    };
    props.addNewTask(id, data, (res) => {
      if (res.status === 200) {
        props.getTask(props.auctionId, (res) => {
          if (res.status === 200) {
            props?.getProductCount(props?.auctionId, (res) => {});
            setLoadingTask(false);
            toggleAddTaskModal();
            props.refStop();
            toast(<AlertSuccess message={"Information Saved"} />);
          } else {
            setLoadingTask(false);
            props.refStop();
            toast(
              <AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />,
            );
          }
        });
      } else {
        setLoadingTask(false);
        props.refStop();
        toast(<AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const task_id =
    taskData &&
    taskData.length > 0 &&
    taskData.map((item) => {
      return item.task_id;
    });

  //Edit Handler
  const editHandler = () => {
    setLoadingTask(true);
    props.refStart();

    const params = {
      auction: props.auctionId,
      task_id: editTaskToggle,
    };
    const data = {
      title: taskTitle ? taskTitle : "",
      assigned_to: selectAssign.member_id,
      description: taskDescription,
      status_id: status.sid,
    };

    props.updateTask(params, data, (res) => {
      if (res.status === 200) {
        props.getTask(props.auctionId, (res) => {
          if (res.status === 200) {
            setLoadingTask(false);
            toggleEditTaskModal();
            props.refStop();
            toast(<AlertSuccess message={"Information Saved"} />);
          } else {
            setLoadingTask(false);
            props.refStop();
            toast(
              <AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />,
            );
          }
        });
      } else {
        setLoadingTask(false);
        props.refStop();
        toast(<AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const deleteHandler = () => {
    setLoadingDelete(true);
    props.refStart();
    const id = props.auctionId;
    const t_id = taskId;
    props.deleteTask(id, t_id, (res) => {
      if (res.status === 200) {
        const data = [...taskData.filter((item) => item.task_id !== t_id)];
        props.tasks({ data });
        props?.getProductCount(props?.auctionId, (res) => {});
        props.refStop();
        setLoadingDelete(false);
        toggleDeleteModal();
        toast(<AlertSuccess message={"Information Saved"} />);
      } else {
        props.refStop();
        setLoadingDelete(false);
        toggleDeleteModal();
        toast(<AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  return (
    <div class="details-page-widget-block" id={props.id}>
      <div class="page-widget-head d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <div class="d-flex align-items-center">
            <div
              class="page-widget-label text-capitalize cursor-pointer"
              onClick={() => (showTask ? toggleAddTaskModal() : showTasks())}
            >
              Tasks {!props?.loadingMain && (count > 0 ? `(${count})` : "(0)")}
            </div>
          </div>
          <li
            className="badge-item cursor-pointer"
            onClick={() => (showTask ? setShowTask(false) : showTasks())}
            style={{ marginLeft: "10px" }}
          >
            <div className="badge-box" style={{ background: "#DADEE0" }}>
              {showTask ? "Hide" : "Show"}
            </div>
          </li>
        </div>
        <div class="btn-block">
          {props.loadingMain ? (
            <Skeleton />
          ) : (
            <>
              {/* <a href="javascript:void(0)" class="btn btn-primary btn-sm" onClick={() => setShowTask(!showTask)}>
                {!showTask ? "Show Tasks" : "Hide Tasks"}
              </a> */}
              <a href="javascript:void(0)" class="btn btn-primary btn-sm" onClick={() => toggleAddTaskModal()}>
                Add New
              </a>
            </>
          )}
        </div>
      </div>
      {props.loadingMain && showTask ? (
        <div class="page-widget-body mt-3">
          <div class="table-responsive">
            <table class="large-table align-left">
              <colgroup>
                <col style={{ width: "28px" }} />
                <col />
                <col />
                <col />
                <col />
                <col />
                <col style={{ width: "28px" }} />
                <col style={{ width: "28px" }} />
              </colgroup>
              <thead>
                <tr>
                  <th>
                    <Skeleton />
                  </th>
                  <th>
                    <Skeleton />
                  </th>
                  <th>
                    <Skeleton />
                  </th>
                  <th>
                    <Skeleton />
                  </th>
                  <th>
                    <Skeleton />
                  </th>
                  <th>
                    <Skeleton />
                  </th>
                  <th>
                    <Skeleton />
                  </th>
                  <th>
                    <Skeleton />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        showTask &&
        taskData?.length > 0 && (
          <div class="page-widget-body mt-3">
            <div class="table-responsive">
              <table class="large-table align-left">
                <colgroup>
                  <col style={{ width: "28px" }} />
                  <col />
                  <col />
                  <col />
                  <col />
                  <col />
                  <col style={{ width: "28px" }} />
                  <col style={{ width: "28px" }} />
                </colgroup>
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Assigned To</th>
                    <th>Task</th>
                    <th>Date Posted</th>
                    <th>Posted By</th>
                    <th>Status</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {taskData &&
                    taskData.length > 0 &&
                    taskData.map((item, idx) => {
                      return (
                        <>
                          <tr>
                            <td className="cursor-pointer" onClick={() => toggleViewTaskModal(item.task_id)}>
                              <span class="fw-medium">{idx + 1}</span>
                            </td>
                            <td className="cursor-pointer" onClick={() => toggleViewTaskModal(item.task_id)}>
                              <span class="fw-medium">{item && item.ASSIGNED ? item.ASSIGNED : "N/A"}</span>
                            </td>
                            <td
                              className="cursor-pointer"
                              onClick={() => toggleViewTaskModal(item.task_id)}
                              style={{
                                whiteSpace: "normal",
                                width: "400px",
                              }}
                            >
                              {item && item.description ? (
                                <div
                                  class="tcbc-text-row div-comm-notes-sec"
                                  dangerouslySetInnerHTML={{
                                    __html: item?.description,
                                  }}
                                ></div>
                              ) : (
                                "N/A"
                              )}
                            </td>
                            <td className="cursor-pointer" onClick={() => toggleViewTaskModal(item.task_id)}>
                              {item && item.date_submitted ? <DateFormat date={item.date_submitted} /> : "N/A"}
                            </td>
                            <td className="cursor-pointer" onClick={() => toggleViewTaskModal(item.task_id)}>
                              <span class="fw-medium">{item && item.NAME ? item.NAME : "N/A"}</span>
                            </td>
                            <td className="cursor-pointer" onClick={() => toggleViewTaskModal(item.task_id)}>
                              <span class="fw-medium">{item && item.ST_DES ? item.ST_DES : "N/A"}</span>
                            </td>
                            <EditTaskModal
                              show={editTaskToggle === item.task_id}
                              onHide={toggleEditTaskModal}
                              loadingTask={loadingTask}
                              assignData={assignData}
                              handleSubmit={editHandler}
                              editAssignData={editAssignData}
                              selectAssign={selectAssign}
                              setAssign={setAssign}
                              taskDescription={taskDescription}
                              setTaskDescription={setTaskDescription}
                              taskTitle={taskTitle}
                              setTaskTitle={setTaskTitle}
                              status={status}
                              setStatus={setStatus}
                              statusList={statusList}
                            />

                            <td>
                              <button type="button" onClick={() => toggleEditTaskModal(item)}>
                                <img src={edit} alt="Edit" />
                              </button>
                            </td>
                            <td>
                              <button
                                type="button"
                                onClick={() => {
                                  setTaskId(item.task_id);
                                  toggleDeleteModal();
                                }}
                              >
                                <img src={deletebtn} alt="Delete" />
                              </button>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        )
      )}
      <AddTaskModal
        show={addTaskToggle}
        onHide={toggleAddTaskModal}
        loadingTask={loadingTask}
        handleSubmit={handleSubmit}
        assignData={assignData}
        selectAssign={selectAssign}
        setAssign={setAssign}
        taskDescription={taskDescription}
        setTaskDescription={setTaskDescription}
      />
      <DeleteModal
        show={showDeleteModal}
        onHide={toggleDeleteModal}
        loadingDelete={loadingDelete}
        deleteHandler={deleteHandler}
      />
      <ViewTaskModal show={showTaskDetailModal} onHide={hideViewTaskModal} viewModalData={viewModalData} />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getTask: (params, data, callback) => dispatch(getTask(params, data, callback)),
  addNewTask: (params, data, callback) => dispatch(addNewTask(params, data, callback)),
  getAssignTo: (params, data, callback) => dispatch(getAssignTo(params, data, callback)),
  getAssignToForEdit: (params, data, callback) => dispatch(getAssignToForEdit(params, data, callback)),
  updateTask: (params, data, callback) => dispatch(updateTask(params, data, callback)),
  getTaskStatus: (callback) => dispatch(getTaskStatus(callback)),
  deleteTask: (params, taskId, callback) => dispatch(deleteTask(params, taskId, callback)),
  getTaskDetail: (params, taskId, callback) => dispatch(getTaskDetail(params, taskId, callback)),
  getProductCount: (id, callback) => dispatch(getProductCount(id, callback)),

  //Managing the global data
  tasks: (data) => dispatch(taskDataReceived(data)),
});

const mapStateToProps = (state) => ({
  getTasks: getTasks(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Task));
