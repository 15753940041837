import React, { useEffect, useRef, useState } from "react";
import Header from "../../layouts/header";
import LoadingBar from "react-top-loading-bar";
import ReferralTable from "../../components/referrals/referralTable";
import { brokerData, getBrokerActivity } from "../../store/broker";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import AlertError from "../../common/alerts/alertError";
import _ from "lodash";
import FilterModal from "../../layouts/modals/broker/filterModal";

const Referrals = (props) => {
  const ref = useRef(null);
  const refData = props?.brokerData?.brokerActivity?.data;
  const totalCount = props?.brokerData?.brokerActivity?.total_records;
  const totalPages = props?.brokerData?.brokerActivity?.max_pages;
  const filteredCount = props?.brokerData?.brokerActivity?.data?.length;
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState("desc");
  const [sort, setSort] = useState("createdAt");
  const [keyword, setKeyword] = useState("");
  const [action, setAction] = useState("");
  const [response, setResponse] = useState({
    keyword: "",
    action: "",
  });

  const [showFilter, setShowFilter] = useState(false);
  const toggleFilter = () => {
    setShowFilter(!showFilter);
    setKeyword(response.keyword);
    setAction(response.action);
  };

  useEffect(() => {
    const payload = {
      page: 1,
      order,
      sort,
    };
    ref.current.continuousStart();
    setLoading(true);
    props?.getBrokerActivity(payload, (res) => {
      if (res.status === 200) {
        ref.current.complete();
        setLoading(false);
      } else {
        ref.current.complete();
        setLoading(false);
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  }, []);

  useEffect(() => {
    const payload = {
      page: page,
      order,
      sort,
    };
    ref.current.continuousStart();
    setLoading(true);
    props?.getBrokerActivity(payload, (res) => {
      if (res.status === 200) {
        ref.current.complete();
        setLoading(false);
      } else {
        ref.current.complete();
        setLoading(false);
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  }, [page]);
  useEffect(() => {
    const payload = {
      page: 1,
      order,
      sort,
    };
    ref.current.continuousStart();
    setLoading(true);
    setPage(1);
    props?.getBrokerActivity(payload, (res) => {
      if (res.status === 200) {
        ref.current.complete();
        setLoading(false);
      } else {
        ref.current.complete();
        setLoading(false);
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  }, [sort, order]);

  const handleFilter = () => {
    ref.current.continuousStart();
    setLoading(true);
    const payload = { page: 1, sort, order, keyword: keyword, action: action };
    const param = _.pickBy(payload, function (value, key) {
      return !(value === "" || value == {} || value === undefined || value === null);
    });
    setPage(1);
    props?.getBrokerActivity(param, (res) => {
      if (res.status === 200) {
        ref.current.complete();
        setLoading(false);
        setResponse({
          keyword: keyword,
          action: action,
        });
        toggleFilter();
      } else {
        ref.current.complete();
        setLoading(false);
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const clearFilter = (filter) => {
    ref.current.continuousStart();
    setLoading(true);
    const payload = {
      page: 1,
      sort,
      order,
      keyword: filter === "keyword" ? "" : response?.keyword,
      action: filter === "action" ? "" : response?.action,
    };
    const param = _.pickBy(payload, function (value, key) {
      return !(value === "" || value == {} || value === undefined || value === null);
    });
    setPage(1);
    props?.getBrokerActivity(param, (res) => {
      if (res.status === 200) {
        ref.current.complete();
        setLoading(false);
      } else {
        ref.current.complete();
        setLoading(false);
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  return (
    <>
      <LoadingBar height={5} color="#47AD1D" ref={ref} />
      <Header />
      <ReferralTable
        data={refData}
        loading={loading}
        page={page}
        setPage={setPage}
        order={order}
        setOrder={setOrder}
        sort={sort}
        setSort={setSort}
        totalCount={totalCount}
        totalPages={totalPages}
        toggleFilter={toggleFilter}
        clearFilter={clearFilter}
        response={response}
        setResponse={setResponse}
        filteredCount={filteredCount}
      />
      <FilterModal
        show={showFilter}
        onHide={toggleFilter}
        handleFilter={handleFilter}
        loading={loading}
        keyword={keyword}
        setKeyword={setKeyword}
        action={action}
        setAction={setAction}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getBrokerActivity: (params, callback) => dispatch(getBrokerActivity(params, callback)),
});

const mapStateToProps = (state) => ({
  brokerData: brokerData(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Referrals));
