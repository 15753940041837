import React, { useEffect, useRef, useState } from "react";

//Components
import Header from "../layouts/header";
import ComingSoonCard from "../layouts/comingSoonCard";
import { connect, useDispatch } from "react-redux";
import LoadingBar from "react-top-loading-bar";
import _ from "lodash";

//Images

import publicPicturesHoc from "../components/listingDetail/higherOrderComponents/publicPictures";
import dot from "../include/images/h-v-dot.svg";

import { Dropdown } from "react-bootstrap";
import PublicPictures from "../components/listingDetail/publicPictures/publicPictures";
import { useHistory, useLocation } from "react-router-dom";
import { checkToken, getProfile, setRedirectUrl } from "../utils.js/localStorageServices";
import queryString from "query-string";
import {
  detailListing,
  getCategoryData,
  getCategoryPreferenaces,
  getListings,
  getSellerMotivation,
} from "../store/listings";
import { listingDocuments, listingImages, listingPrivateDocuments, primaryPicture } from "../store/listingsMedia";
import PublicDocuments from "../components/listingDetail/publicDocuments/publicDocuments";
import TransportationStorage from "../components/listingDetail/transportationStorage/transportationStorage";
import {
  getAccounting,
  getComparablePrice,
  getMfrAndModel,
  getMoreDetails,
  getOtherDescp,
  productCondition,
  productDescriptionReceived,
  productDetails,
  sustainabilityReport,
  getBuyerPushHistory,
  getProductCount,
  getListingProductDetail,
  getPipeDetails,
} from "../store/listingProduct";
import { getPendingTasks, getTask } from "../store/tasks";
import ProductDescription from "../components/listingDetail/productDescription.jsx/productDescription";
import ProductCondition from "../components/listingDetail/productCondition/productCondition";
import productDescription from "../components/listingDetail/productDescription.jsx/productDescription";
import SustainabilityReport from "../components/listingDetail/sustainabilityReport/sustainabilityReport";
import MoreDetails from "../components/listingDetail/moreDetails/moreDetails";
import Manufacturer from "../components/listingDetail/manufacturerAndModal/manufacturer";
import StrategicTeam from "../components/listingDetail/strategicTeam/strategicTeam";
import { getStrategicTeamDetails } from "../store/strategicTeam";
import AIDescription from "../components/listingDetail/aiDescription/aiDescription";
import ManufactureDescription from "../components/listingDetail/manufactureDescription/manufactureDescription";
import CarbonFootprint from "../components/listingDetail/carbonFootprint/carbonFootprint";
import Exclusivity from "../components/listingDetail/exclusivity/exclusivity";
import PrivateDocuments from "../components/listingDetail/privateDocuments/privateDocuments";
import Accounting from "../components/listingDetail/accounting/accounting";
import ListingOverview from "../components/listingDetail/listingOverview/listingOverview";
import SellerMotivation from "../components/listingDetail/sellerMotivation/sellerMotivation";
import BuyerPushHistory from "../components/listingDetail/buyerPushHistory/buyerPushHistory";
import ProductLocation from "../components/listingDetail/productLocation/productLocation";
import { getProductLocation } from "../store/productLocation";
import CommentsAndNotes from "../components/listingDetail/commentsAndNotes/commentsAndNotes";
import { getCommentsAndNotes } from "../store/commentsAndNotes";
import OptimizationSites from "../components/listingDetail/optimizationSites/optimizationSites";
import { getOptimizationSiteDetails } from "../store/optimizationSites";
import Questions from "../components/listingDetail/questions/questions";
import { getAuctionConversationList, getConversation, getQuestions, questionDetails } from "../store/questions";
import CategoryPreferenaces from "../components/listingDetail/categoryPreferences/categoryPreferences";
import OtherListings from "../components/listingDetail/otherListings/otherListings";
import {
  getObservers,
  getOtherListingCount1,
  getOtherListingCount2,
  getOtherListings,
  otherListingsDetails,
} from "../store/otherListings";
import { getDealProgress, getGAData, getOwnerAccessLog } from "../store/misc";
import OwnerAccessLog from "../components/listingDetail/ownerAccessLog/ownerAccessLog";
import Task from "../components/listingDetail/task/task";
import PushEmailClickers from "../components/listingDetail/pushEmailClickers/pushEmailClickers";
import { getEmailClickersCount, getPushEmailClickers } from "../store/emailClickers";
import CompletedTasks from "../components/listingDetail/completedTasks/completedTasks";
import PendingTasks from "../components/listingDetail/pendingTasks/pendingTasks";
import Payables from "../components/listingDetail/payables/payables";
import { getPayable, getPayableLists } from "../store/payables";
import Invoices from "../components/listingDetail/invoices/invoices";
import { getInvoiceList, getUserAccess } from "../store/invoices";
import CustomerChats from "../components/listingDetail/customerChats/customerChats";
import Sidebar from "../layouts/sidebar";
import BuyerInterests from "../components/listingDetail/buyerInterests/buyerInterests";
import Bids from "../components/listingDetail/bids/bids";
import { getBids } from "../store/bids";
import { getBuyerInterests, getBuyerInterstList } from "../store/buyerInterests";
// import AviationParts from "../components/listingDetail/aviationParts/aviationParts";
import { getAviationParts, getOrderRequests } from "../store/aviation";
import LatestActivity from "../components/listingDetail/latestActivity/latestActivity";
import { getBidLog, getLatestBids, getPriceInfo } from "../store/latestActivity";
import PrimarySeller from "../components/listingDetail/primarySeller/primarySeller";
import { getPrimarySeller, getSellerOtherListings } from "../store/primarySeller";
import DealProgress from "../components/listingDetail/dealProgress/dealProgress";
import { getListingContracts } from "../store/contract";
import ListingContract from "../layouts/modals/contract/listingContract";
import AviationParts from "../components/listingDetail/aviationParts/aviationParts";
import OrderRequests from "../components/listingDetail/aviationParts/orderRequests";
import BuyerInterest from "../components/listingDetail/buyerInterest/buyerInterest";
import OtherDeals from "../components/listingDetail/otherDeals/otherDeals";
import ActiveListing from "../components/listingDetail/activeListing/activeListing";
import TitleBar from "../components/listingDetail/titleBar/titleBar";
import TopBids from "../components/listingDetail/bids/topBids";
import Pricing from "../components/listingDetail/pricing/pricing";
import { getModals, setCategoryModal } from "../store/modal";
import NewInvoices from "../components/listingDetail/invoices/newInvoices";
import NewPayables from "../components/listingDetail/payables/newPayables";
import PrimarySellerNew from "../components/listingDetail/primarySeller/primarySellerNew";
import GoogleAnalytics from "../components/listingDetail/googleAnalytics/googleAnalytics";
import { getGoogleAnalytics, getListingAgreement, getListingCampaign } from "../store/dashBoard";
import EmailCampaigns from "../components/listingDetail/emailCampaigns/emailCampaigns";
import ChatMessages from "../layouts/modals/customerChat/chatMessages";
import CustomerChat from "../layouts/modals/customerChat/customerChat";
import { getChats, loadAuctionChat, loadChatMassages } from "../store/chats";
import SellerOtherListings from "../components/listingDetail/sellerOtherListings/sellerOtherListings";
import ScrollButton from "../common/scrollTop/scrollTop";
import AlertSuccess from "../common/alerts/alertSuccess";
import { toast } from "react-toastify";
import { getBrokerActivity } from "../store/broker";
import Brokers from "../components/listingDetail/brokers/brokers";
import Animal from "../components/listingDetail/animals/animal";
import { getAuctionSpecies } from "../store/ei";
import { getPaymentIfo, getPaymentIform } from "../store/users";
import HelmetComponent from "../common/helmetComponent/helmetComponent";
import PushEmailClickersNew from "../components/listingDetail/pushEmailClickers/pushEmailClickersNew";
import QuestionLists from "../components/listingDetail/questions/questionLists";
import Observers from "../components/listingDetail/observers/observers";
import PipeDetails from "../components/listingDetail/pipeDetails/pipeDetails";
import { implementLogRocket } from "../common/logRocket/logRocket";
import AiLeads from "../components/listingDetail/aiLeads/aiLeads";
import Parts from "../components/listingDetail/parts/parts";
import KeyBuyersInCategory from "../components/listingDetail/otherBuyerListings/keyBuyersInCategory";
import KeyBuyerInCategoryFromRegion from "../components/listingDetail/otherBuyerListings/keyBuyerInCategoryFromRegion";
import PurchasedFromCategoryInRegion from "../components/listingDetail/otherBuyerListings/purchasedFromCategoryInRegion";
import BuyerPreferencesInCategoryInRegion from "../components/listingDetail/otherBuyerListings/buyerPreferencesInCategoryInRegion";
import PurchasedFromCategory from "../components/listingDetail/otherBuyerListings/purchasedFromCategory";
import BuyerPurchasedOrBidFromPushCategoryInRegion from "../components/listingDetail/otherBuyerListings/buyerPurchasedOrBidFromPushCategoryInRegion";
import ManufacturersCompanyPeopleInRegion from "../components/listingDetail/otherBuyerListings/manufacturersCompanyPeopleInRegion";
import ManufacturerPreferencesInRegion from "../components/listingDetail/otherBuyerListings/manufacturerPreferencesInRegion";
import ManufacturersCompanyPeopleGlobally from "../components/listingDetail/otherBuyerListings/manufacturersCompanyPeopleGlobally";
import ManufacturerPreferencesGlobally from "../components/listingDetail/otherBuyerListings/manufacturerPreferencesGlobally";
import BuyersWhoHaveBidOnCategory from "../components/listingDetail/otherBuyerListings/buyersWhoHaveBidOnCategory";
import BrokersOfCategory from "../components/listingDetail/otherBuyerListings/brokersOfCategory";
import EndUsersOfCategory from "../components/listingDetail/otherBuyerListings/endUsersOfCategory";
import MembersWhoHaveViewedTheListing from "../components/listingDetail/otherBuyerListings/membersWhoHaveViewedTheListing";
import PushEmailOpened from "../components/listingDetail/pushEmailClickers/pushEmailOpened";

const ListingDetail = (props) => {
  var ref = useRef(null);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const categoryToggle = props?.getModals?.categoryModal;
  const productDetail = props?.getSearchListings?.detail?.detail;

  //Modals State
  const toggleCategoryPreferenaces = () => {
    // setCategoryToggle(!categoryToggle);
    dispatch(setCategoryModal(!categoryToggle));
  };

  //loading Document states
  const [auctionId, setAuctionId] = useState("");
  const [loadingImages, setLoadingImages] = useState(false);
  const [loadingDocument, setLoadingDocument] = useState(false);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [loadLocation, setLoadLocation] = useState(false);
  const [loadingProductDetails, setLoadingProductDetails] = useState(false);
  const [loadingProductCondition, setLoadingProductCondition] = useState(false);
  const [loadingMoreDetails, setLoadingMoreDetails] = useState(false);
  const [loadigSustaiabilityReport, setLoadingSustaianabilityReport] = useState(false);
  const [loadingMfrAndModel, setLoadingMfrAndModel] = useState(false);
  const [loadingStrategicTeam, setLoadingStrategicTeam] = useState(false);
  const [loadingExclusivity, setLoadingExclusivity] = useState(false);
  const [loadingPrivateDocuments, setLoadingPrivateDocuments] = useState(false);
  const [loadingOverview, setListingOverview] = useState(false);
  const [loadingSeller, setLoadingSeller] = useState(false);
  const [loadingMessageChat, setLoadingMessageChat] = useState(false);
  const [loadingBuyerPush, setLoadingBuyerPush] = useState(false);
  const [loadingProductLocation, setLoadingProductLocation] = useState(false);
  const [loadingCommentsAndNotes, setLoadingCommentsAndNotes] = useState(false);
  const [loadingSites, setLoadingSites] = useState(false);
  const [loadingQuestions, setLoadingQuestions] = useState(false);
  const [loadingCategory, setLoadingCategory] = useState(false);
  const [loadingCategoryData, setLoadingCategoryData] = useState(false);
  const [loadingOtherListings, setLoadingOtherListings] = useState(false);
  const [loadingAccessLog, setLoadingAccessLog] = useState(false);
  const [loadingTask, setLoadingTask] = useState(false);
  const [loadingPendingTask, setLoadingPendingTask] = useState(false);
  const [loadingPayables, setLoadingPayables] = useState(false);
  const [loadingInvoices, setLoadingInvoices] = useState(false);
  const [loadingEmailClickers, setLoadingEmailClickers] = useState(false);
  const [loadingBids, setLoadingBids] = useState(false);
  const [loadingAviationParts, setLoadingAviationParts] = useState(false);
  const [loadingPrimarySeller, setLoadingPrimarySeller] = useState(true);
  const [loadingSpecies, setLoadingSpecies] = useState(false);
  const [loadingObservers, setLoadingObservers] = useState(false);
  const [primarySellerParams, setPrimarySellerParams] = useState({
    sellerId: 0,
    memberId: 0,
    s_id: "",
  });
  const [overrideBid, setOverrideBid] = useState(false);
  const [loadPrimarySeller, setLoadPrimarySeller] = useState(false);
  const [loadingBuyerInterests, setLoadingBuyerInterests] = useState(false);
  const [loadingDealProgress, setLoadingDealProgress] = useState(false);

  const [loadingMain, setLoadingMain] = useState(false);
  const [listingDetail, setListingDetail] = useState({});
  const [lisitingData, setListingData] = useState({});
  const [loadingDescp, setLoadingDescp] = useState(false);
  const [section, setSection] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [otherListing, setOtherListing] = useState(false);
  const [showPendingTask, setShowPendingTask] = useState(true);
  const [filterData, setFilterData] = useState(["Interested"]);
  const [qtyConfirmation, setQtyConfirmation] = useState(false);

  //Latest Activity
  const [loadingLatActBids, setLoadingLatActBids] = useState(false);
  const [loadingPriceInfo, setLoadingPriceInfo] = useState(false);
  const [loadingBidLog, setLoadingBidLog] = useState(false);

  //For SideNav
  const [showMenu, setShowMenu] = useState(false);
  const [menuList, setMenuList] = useState("");

  const [isOnListing, setIsOnListing] = useState(false);
  const [listingContract, setListingContract] = useState(false);
  const [showContractListing, setShowContractListing] = useState(false);
  const [loadingOrderRequests, setLoadingOrderRequests] = useState(false);
  const [loadingGoogleAnalytics, setLoadingGoogleAnalytics] = useState(false);
  const [loadingListingCampaign, setLoadingListingCampaign] = useState(false);
  const [loadingAccounting, setLoadingAccounting] = useState(false);
  const [loadingPipe, setLoadingPipe] = useState(false);
  const [loadingCount, setLoadingCount] = useState(false);
  const [loadingSellerOtherListings, setLoadingSellerOtherListings] = useState(false);
  const [saleType, setSaleType] = useState(0);
  const [noSeller, setNoSeller] = useState(false);

  //Authorization to Pay Seller Params
  const [sellerVerificationStatus, setSellerVerificationStatus] = useState("");
  const [payableCount, setPayableCount] = useState(0);
  const [locationFilled, setLocationFilled] = useState(false);

  const session = getProfile();
  const details = props.getSearchListings && props.getSearchListings.detail;
  const { detail } = details;
  const isAviationCategory = detail?.isAviationCategory ? true : false;
  const count = props?.getListingProductDetail?.count?.data;
  const count1 = props?.otherListingsDetails?.count1?.other;
  const count2 = props?.otherListingsDetails?.count2?.other;
  // const chatList = (props.getChatList && props.getChatList.auctionChats) || [];
  const chatList = props?.questionDetails?.auctionConversationList?.data || [];
  const [showChats, setShowchats] = useState(false);
  const [showMessages, setShowMessages] = useState(false);
  const [loadingChats, setLoadingChats] = useState(false);
  const [selectedChat, setSelectedChat] = useState(null);
  const [refreshMessage, setRefreshMessage] = useState(false);
  const [messageSection, setMessageSection] = useState(false);
  const [loadCount, setLoadCount] = useState(false);
  const handleRefreshMessage = () => {
    setRefreshMessage(!refreshMessage);
  };

  const toggleChat = () => {
    setShowchats(!showChats);
  };

  const toggleMessages = (x) => {
    if (messageSection || x === "questions") {
      setShowchats(false);
      handleRefreshMessage();
    } else {
      setShowchats(!showChats);
    }
    setShowMessages(!showMessages);
  };

  const toggleContractListing = () => {
    setShowContractListing(!showContractListing);
  };

  useEffect(() => {
    if (!showMessages) {
      if (location && location.search) {
        const id = queryString.parse(location.search).id.trim();
        props.getProductCount(id, (res) => {
          if (res.status === 200) {
          }
        });
      }
    }
  }, [showMessages]);

  useEffect(() => {
    if (showChats) {
      ref.current.continuousStart();
      setLoadingChats(true);
      const id = auctionId;
      props.getAuctionConversationList(id, (res) => {
        if (res && res.status == 200) {
          setLoadingChats(false);
          ref.current.complete();
        }
      });
    }
  }, [showChats]);

  useEffect(() => {
    if (selectedChat) {
      ref.current.continuousStart();
      setLoadingChats(true);
      props.getConversation(selectedChat.buyers_conversation_on_auction_key, (res) => {
        if (res.status === 200) {
          setLoadingChats(false);
          ref.current.complete();
        }
      });
    }
  }, [selectedChat]);

  //local state
  useEffect(() => {
    document.body.classList.add("pt-0");
    document.body.classList.remove("pt-80");
    if (!checkToken()) {
      ref && ref.current && ref.current.complete();
      const a = window.location.href;
      setRedirectUrl(a);
      props.history.push("/signin");
    }
    return () => {
      document.body.classList.add("pt-80");
      document.body.classList.remove("pt-0");
    };
  }, []);

  useEffect(() => {
    if (location && location.search) {
      const id = queryString.parse(location.search).id.trim();
      setAuctionId(id);
      setLoadingDetail(true);
      setLoadCount(false);
      ref.current.continuousStart();
      props.detailListings(id, (res) => {
        if (res && res.status === 200) {
          const data = res.data.detail;
          setLoadCount(true);
          setPrimarySellerParams({
            sellerId: data?.seller_id ? data?.seller_id : 0,
            memberId: data?.s_member_id ? data?.s_member_id : 0,
            s_id: data?.sid ? data?.sid : "",
          });
          setQtyConfirmation(data.qty_confirmed);
          setOverrideBid(data.override_bid);
          setLoadPrimarySeller(true);
          props.primaryPicture(res.data.detail.picture_id);
          props.productDescriptionReceived(res.data.detail.details);
          setLoadingDetail(false);
          setListingDetail(res.data);
          setListingData(res.data);
          ref.current.complete();
        } else if (res && res.status === 500) {
          setLoadingDetail(false);
          ref.current.complete();
          history.replace("/404");
        }
      });
      setLoadingImages(true);
      ref.current.continuousStart();
      props.listingImages(id, (res) => {
        if (res && res.status === 200) {
          setLoadingImages(false);
          ref.current.complete();
        } else if (res && res.status === 400) {
          setLoadingImages(false);
          ref.current.complete();

          history.replace("/signin");
        }
      });
    }
  }, []);

  useEffect(() => {
    if (location && location.search) {
      setLoadingDocument(true);
      const id = queryString.parse(location.search).id.trim();
      props.listingDocuments(id, (res) => {
        if (res.status === 200) {
          setLoadingDocument(false);
        }
      });
    }
  }, []);
  useEffect(() => {
    if (location && location.search) {
      setLoadingLatActBids(true);
      const id = queryString.parse(location.search).id.trim();
      props.getLatestBids(id, (res) => {
        if (res.status === 200) {
          setLoadingLatActBids(false);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (location && location.search) {
      setLoadingDealProgress(true);
      const id = queryString.parse(location.search).id.trim();
      props.getDealProgress(id, (res) => {
        if (res.status === 200) {
          setLoadingDealProgress(false);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (location && location.search) {
      setLoadingPriceInfo(true);
      const id = queryString.parse(location.search).id.trim();

      props.getPriceInfo(id, (res) => {
        if (res.status === 200) {
          setLoadingPriceInfo(false);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (location && location.search) {
      const id = queryString.parse(location.search).id.trim();
      const payload = {
        auctionId: 183009312,
        startDate: "200daysAgo",
      };
      props.getGAData(payload, (res) => {
        if (res.status === 200) {
        }
      });
    }
  }, []);

  useEffect(() => {
    if (location && location.search && isAviationCategory) {
      setLoadingAviationParts(true);
      const id = queryString.parse(location.search).id.trim();

      props.getAviationParts(
        {
          page: 1,
          auctionId: id,
          sort: "createdAt",
          order: "desc",
        },
        (res) => {
          if (res.status === 200) {
            setLoadingAviationParts(false);
          }
        },
      );
    }
  }, [categoryId]);

  useEffect(() => {
    if (location && location.search && isAviationCategory) {
      setLoadingOrderRequests(true);
      const id = queryString.parse(location.search).id.trim();

      props.getOrderRequests(
        {
          page: 1,
          sort: "createdAt",
          order: "asc",
          auctionId: id,
        },
        (res) => {
          if (res.status === 200) {
            setLoadingOrderRequests(false);
          }
        },
      );
    }
  }, [categoryId]);

  //Get Bid Log
  useEffect(() => {
    if (location && location.search) {
      setLoadingBidLog(true);
      const id = queryString.parse(location.search).id.trim();
      props.getBidLog(id, (res) => {
        if (res.status === 200) {
          setLoadingBidLog(false);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (location && location.search) {
      setLoadingPrivateDocuments(true);
      const id = queryString.parse(location.search).id.trim();
      props.listingPrivateDocuments(id, (res) => {
        if (res.status === 200) {
          setLoadingPrivateDocuments(false);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (location && location.search) {
      setLoadingProductDetails(true);
      const id = queryString.parse(location.search).id.trim();
      props.productDetails(id, (res) => {
        if (res.status === 200) {
          setLoadingProductDetails(false);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (location && location.search) {
      setLoadingProductCondition(true);
      const id = queryString.parse(location.search).id.trim();
      props.productCondition(id, (res) => {
        if (res.status === 200) {
          setLoadingProductCondition(false);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (location && location.search) {
      setLoadingSustaianabilityReport(true);
      const id = queryString.parse(location.search).id.trim();
      props.sustainabilityReport(id, (res) => {
        if (res.status === 200) {
          setLoadingSustaianabilityReport(false);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (location && location.search) {
      setLoadingMoreDetails(true);
      const id = queryString.parse(location.search).id.trim();
      props.getMoreDetails(id, (res) => {
        if (res.status === 200) {
          const data = res.data.data;
          setSaleType(data.at_id);
          setLoadingMoreDetails(false);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (location && location.search) {
      setLoadingMfrAndModel(true);
      const id = queryString.parse(location.search).id.trim();
      props.getMfrAndModel(id, (res) => {
        if (res.status === 200) {
          setLoadingMfrAndModel(false);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (location && location.search) {
      setLoadingStrategicTeam(true);
      const id = queryString.parse(location.search).id.trim();
      props.getStrategicTeamDetails(id, (res) => {
        if (res.status === 200) {
          setLoadingStrategicTeam(false);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (location && location.search) {
      setLoadingDescp(true);
      const id = queryString.parse(location.search).id.trim();
      props.getOtherDescp(id, (res) => {
        if (res.status === 200) {
          setLoadingDescp(false);
        }
      });
    }
  }, []);

  // seller motivation
  useEffect(() => {
    if (location && location.search) {
      setLoadingSeller(true);
      const id = queryString.parse(location.search).id.trim();
      props.getSellerMotivation(id, (res) => {
        if (res.status === 200) {
          setLoadingSeller(false);
        }
      });
    }
  }, []);

  // Observers
  useEffect(() => {
    if (location && location.search) {
      setLoadingObservers(true);
      const id = queryString.parse(location.search).id.trim();
      props.getObservers(id, (res) => {
        if (res.status === 200) {
          setLoadingObservers(false);
        }
      });
    }
  }, []);

  //Pipe Details
  useEffect(() => {
    if (location && location.search) {
      setLoadingPipe(true);
      const id = queryString.parse(location.search).id.trim();
      props.getPipeDetails(id, (res) => {
        if (res.status === 200) {
          setLoadingPipe(false);
        }
      });
    }
  }, []);

  //Product Location
  useEffect(() => {
    if (location && location.search) {
      setLoadingProductLocation(true);
      const id = queryString.parse(location.search).id.trim();
      props.getProductLocation(id, (res) => {
        if (res.status === 200) {
          const data = res?.data?.data;
          if (data.location1 && data.location2 && data.location3 && data.location4) {
            setLocationFilled(true);
          } else {
            setLocationFilled(false);
          }
          setLoadLocation(true);
          setLoadingProductLocation(false);
        }
      });
    }
  }, []);

  //Messages
  useEffect(() => {
    if (location && location.search) {
      setLoadingChats(true);
      const id = queryString.parse(location.search).id.trim();
      props.getAuctionConversationList(id, (res) => {
        if (res.status === 200) {
          setLoadingChats(false);
        }
      });
    }
  }, [refreshMessage]);

  useEffect(() => {
    if (location && location.search) {
      setLoadingAccounting(true);
      const id = queryString.parse(location.search).id.trim();
      props.getAccounting(id, (res) => {
        if (res.status === 200) {
          setLoadingAccounting(false);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (location && location.search) {
      setListingDetail(true);
      const id = queryString.parse(location.search).id.trim();
      props.getComparablePrice(id, (res) => {
        if (res.status === 200) {
          setListingDetail(false);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (location && location.search) {
      setLoadingBuyerPush(true);
      const id = queryString.parse(location.search).id.trim();
      props.getBuyerPushHistory(id, (res) => {
        if (res.status === 200) {
          setLoadingBuyerPush(false);
        }
      });
    }
  }, []);

  //Comments and Notes
  useEffect(() => {
    if (location && location.search) {
      setLoadingCommentsAndNotes(true);
      const id = queryString.parse(location.search).id.trim();
      props.getCommentsAndNotes(id, (res) => {
        if (res.status === 200) {
          setLoadingCommentsAndNotes(false);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (location && location.search) {
      setLoadingCategory(true);
      const id = queryString.parse(location.search).id.trim();
      props.getCategoryPreferenaces(id, (res) => {
        if (res.status === 200) {
          setLoadingCategory(false);
          setCategoryId(res?.data?.data?.category_id);
          setCategoryName(res?.data?.data?.cat_des);
          setOtherListing(true);
        }
      });
    }
  }, []);

  //Category Prferences
  useEffect(() => {
    if (location && location.search) {
      setLoadingCategoryData(true);
      const id = queryString.parse(location.search).id.trim();
      props.getCategoryData(id, (res) => {
        if (res.status === 200) {
          setLoadingCategoryData(false);
        }
      });
    }
  }, []);

  //Other Listings Count
  useEffect(() => {
    if (loadCount) {
      if (location && location.search) {
        const payload = {
          category_id: productDetail?.category_id ? productDetail?.category_id : 0,
          sid: productDetail?.sid ? productDetail?.sid : "",
          owner_category_id: productDetail?.category_id ? productDetail?.category_id : 0,
          mfr: productDetail?.mfr ? productDetail?.mfr : 0,
          state_id: productDetail?.state_id ? productDetail?.state_id : 0,
          country_id: productDetail?.country_id ? productDetail?.country_id : 0,
          call_list_categories: productDetail?.category_id_push
            ? productDetail?.category_id_push
            : productDetail?.category_id
            ? productDetail?.category_id
            : 0,
          type: 1,
        };
        setLoadingOtherListings(true);
        const id = queryString.parse(location.search).id.trim();
        props.getOtherListingCount1(id, payload, (res) => {
          if (res.status === 200) {
            setLoadingOtherListings(false);
          }
        });
      }
    }
  }, [loadCount]);

  useEffect(() => {
    if (loadCount) {
      if (location && location.search) {
        const payload = {
          category_id: productDetail?.category_id ? productDetail?.category_id : 0,
          sid: productDetail?.sid ? productDetail?.sid : "",
          owner_category_id: productDetail?.category_id ? productDetail?.category_id : 0,
          mfr: productDetail?.mfr ? productDetail?.mfr : 0,
          state_id: productDetail?.state_id ? productDetail?.state_id : 0,
          country_id: productDetail?.country_id ? productDetail?.country_id : 0,
          call_list_categories: productDetail?.category_id_push
            ? productDetail?.category_id_push
            : productDetail?.category_id
            ? productDetail?.category_id
            : 0,
          type: 2,
        };
        setLoadingOtherListings(true);
        const id = queryString.parse(location.search).id.trim();
        props.getOtherListingCount2(id, payload, (res) => {
          if (res.status === 200) {
            setLoadingOtherListings(false);
          }
        });
      }
    }
  }, [loadCount]);
  //Owner Access Log
  useEffect(() => {
    if (location && location.search) {
      setLoadingAccessLog(true);
      const id = queryString.parse(location.search).id.trim();
      props.getOwnerAccessLog(id, (res) => {
        if (res.status === 200) {
          setLoadingAccessLog(false);
        }
      });
    }
  }, []);

  //Push Email Clickers Count
  useEffect(() => {
    if (location && location.search) {
      setLoadingEmailClickers(true);
      const id = queryString.parse(location.search).id.trim();
      props.getEmailClickersCount(id, (res) => {
        if (res.status === 200) {
          setLoadingEmailClickers(false);
        }
      });
    }
  }, []);
  //Pending Tasks
  useEffect(() => {
    if (location && location.search) {
      setLoadingPendingTask(true);
      const id = queryString.parse(location.search).id.trim();
      props.getPendingTasks(id, (res) => {
        if (res.status === 200) {
          if (res?.data?.data?.length === 0) {
            setShowPendingTask(false);
          }
          setLoadingPendingTask(false);
        }
      });
    }
  }, []);

  //Payables
  useEffect(() => {
    if (location && location.search) {
      setLoadingPayables(true);
      const id = queryString.parse(location.search).id.trim();
      props.getPayableLists(id, (res) => {
        if (res.status === 200) {
          setPayableCount(res?.data?.data?.length);
          setLoadingPayables(false);
        }
      });
    }
  }, []);
  //Invoices
  useEffect(() => {
    if (location && location.search) {
      setLoadingInvoices(true);
      const id = queryString.parse(location.search).id.trim();
      props.getInvoiceList(id, (res) => {
        if (res.status === 200) {
          props.getUserAccess((res) => {
            if (res.status === 200) {
              setLoadingInvoices(false);
            }
          });
        }
      });
    }
  }, []);

  //Bids
  useEffect(() => {
    if (location && location.search) {
      setLoadingBids(true);
      const id = queryString.parse(location.search).id.trim();
      props.getBids(id, (res) => {
        if (res.status === 200) {
          props.getQuestions(id, (res) => {});
          setLoadingBids(false);
        }
      });
    }
  }, []);

  //Google Analytics
  useEffect(() => {
    if (location && location.search) {
      setLoadingGoogleAnalytics(true);
      const id = queryString.parse(location.search).id.trim();
      props.getGoogleAnalytics(id, 200, (res) => {
        if (res.status === 200) {
          setLoadingGoogleAnalytics(false);
        }
      });
    }
  }, []);

  //Product Detail Count
  useEffect(() => {
    if (location && location.search) {
      setLoadingCount(true);
      const id = queryString.parse(location.search).id.trim();
      props.getProductCount(id, (res) => {
        if (res.status === 200) {
          setLoadingCount(false);
        }
      });

      const countData = setInterval(
        () =>
          props.getProductCount(id, (res) => {
            if (res.status === 200) {
              setLoadingCount(false);
            }
          }),
        5000 * 60,
      );
      return () => clearInterval(countData);
    }
  }, [location?.search]);

  //Get Auction Species
  useEffect(() => {
    if (location && location.search) {
      setLoadingSpecies(true);
      const id = queryString.parse(location.search).id.trim();
      props.getAuctionSpecies(id, (res) => {
        if (res.status === 200) {
          setLoadingSpecies(false);
        }
      });
    }
  }, []);

  //Primary Seller
  useEffect(() => {
    if (location && location.search) {
      setLoadingPrimarySeller(true);
      setLoadingSellerOtherListings(true);
      const id = queryString.parse(location.search).id.trim();
      const sellerId = primarySellerParams.sellerId;
      const memberId = primarySellerParams.memberId;
      const s_id = primarySellerParams.s_id;
      if (loadPrimarySeller) {
        if (sellerId == 0 && memberId == 0) {
          setNoSeller(true);
          setLoadingPrimarySeller(false);
        } else {
          props.getPrimarySeller(id, sellerId, memberId, s_id, (res) => {
            if (res && res.status === 200) {
              setNoSeller(false);
              setSellerVerificationStatus(res?.data?.data?.verification?.verification_status);
              setLoadingMessageChat(true);
              setLoadingPrimarySeller(false);
              props.getListingContracts(
                {
                  auctionId: id,
                  sellerUserId: res?.data?.data?.user?.s_user_id,
                  sort: "createdAt",
                  order: "desc",
                },
                (res1) => {
                  if (res1 && res1.status == 200) {
                    setLoadingMessageChat(false);
                    setListingContract({
                      sellerUserId: res?.data?.data?.user?.s_user_id,
                      ...res1.data,
                    });
                  }
                },
              );
              props.getSellerOtherListings(
                "all",
                res?.data?.data?.user?.s_user_id,
                { auction_id: id, sort: "title", order: "asc" },
                (res2) => {
                  if (res2.status === 200) {
                    setLoadingSellerOtherListings(false);
                  }
                },
              );
              props?.getPaymentInfo(res?.data?.data?.user?.s_user_id, (res) => {});
              props?.getPaymentInform(res?.data?.data?.user?.s_user_id, (res3) => {
                if (res3 === 200) {
                }
              });
            } else {
              setLoadingPrimarySeller(false);
            }
          });
        }
      }
    }
  }, [loadPrimarySeller, primarySellerParams]);
  //Buyer Interests
  useEffect(() => {
    const filter = filterData.length > 0 ? filterData.join(",") : "";
    if (location && location.search) {
      setLoadingBuyerInterests(true);
      const id = queryString.parse(location.search).id.trim();
      props.getBuyerInterests(id, 1, filter, (res) => {
        if (res.status === 200) {
          setLoadingBuyerInterests(false);
        }
      });
    }
  }, []);

  //Listing Agreement
  useEffect(() => {
    if (location && location.search) {
      // setLoadingPrimarySeller(true);
      const id = queryString.parse(location.search).id.trim();
      const params = {
        auctionId: id,
        sort: "createdAt",
        order: "desc",
      };
      props.getListingAgreement(params, (res) => {
        if (res.status === 200) {
          // setLoadingPrimarySeller(false);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (
      loadingDocument ||
      loadingImages ||
      loadingDetail ||
      loadingProductDetails ||
      loadingProductCondition ||
      loadigSustaiabilityReport ||
      loadingMoreDetails ||
      loadingMfrAndModel ||
      loadingStrategicTeam ||
      loadingDescp ||
      loadingExclusivity ||
      loadingSeller ||
      loadingPrivateDocuments ||
      loadingOverview ||
      loadingBuyerPush ||
      loadingProductLocation ||
      loadingSites ||
      loadingCategory ||
      loadingCategoryData ||
      loadingAccessLog ||
      loadingTask ||
      loadingPayables ||
      loadingBids ||
      loadingLatActBids ||
      loadingPriceInfo ||
      loadingPrimarySeller ||
      loadingBuyerInterests ||
      loadingDealProgress ||
      loadingAviationParts ||
      loadingOrderRequests ||
      loadingGoogleAnalytics ||
      loadingListingCampaign
      // ||
      // loadingAccounting
    ) {
      setLoadingMain(true);
    } else {
      setLoadingMain(false);
    }
  }, [
    loadingDocument,
    loadingLatActBids,
    loadingPriceInfo,
    loadingImages,
    loadingDetail,
    loadingProductDetails,
    loadingProductCondition,
    loadigSustaiabilityReport,
    loadingMoreDetails,
    loadingMfrAndModel,
    loadingStrategicTeam,
    loadingDescp,
    loadingExclusivity,
    loadingSeller,
    loadingOverview,
    loadingBuyerPush,
    loadingDealProgress,
    // loadingAccounting,
    loadingPrivateDocuments,
    loadingProductLocation,
    loadingSites,
    loadingAccessLog,
    loadingCategory,
    loadingCategoryData,
    loadingTask,
    loadingPayables,
    loadingBids,
    loadingPrimarySeller,
    loadingBuyerInterests,
    loadingAviationParts,
    loadingOrderRequests,
    loadingGoogleAnalytics,
    loadingListingCampaign,
  ]);

  const scrollToView = (part) => {
    const id = part;
    const yOffset = -225;
    const element = document.getElementById(id);
    // const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    if (element) {
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
      //Reset section
      if (part === "bidsRef") {
        setTimeout(() => {
          setSection("");
        }, 500);
      }
    } else {
      console.log(`Element with id "${id}" not found.`);
    }
  };

  //Implementing Log Rocket on basis of Script
  useEffect(() => {
    if (
      session?.sec_list > 0 &&
      session?.sec_list !== 100107 &&
      session?.user_id !== 12210968 &&
      session?.user_id !== 10012798 &&
      process.env.REACT_APP_MODE !== "development"
    ) {
      implementLogRocket(session);
    }
  }, []);

  const aviationType = detail?.aviationType;

  const pipeCategories = [1115, 1295, 1321, 1322, 1323, 1400, 1404, 1442, 1515, 1754];
  const pipeDetailView = pipeCategories.includes(detail?.category_id) && session.sec_list !== 100107 ? true : false;

  const Images = publicPicturesHoc(PublicPictures);
  const TitleBarEdit = publicPicturesHoc(TitleBar);

  const region =
    productDetail?.ccountry === "United States"
      ? productDetail?.state
        ? productDetail?.state
        : productDetail?.ccountry
      : productDetail?.ccountry;

  const accessArr = [
    12210968, 10011993, 10012798, 12011359, 12271715, 12191311, 12213057, 10853838, 10011994, 11352012, 12213056,
  ]; //13077718- Add this user_id if required

  return (
    <>
      <LoadingBar height={5} color="#47AD1D" ref={ref} />
      <HelmetComponent
        title={auctionId && detail?.title ? `${auctionId} - ${detail?.title}` : ""}
        desription={auctionId && detail?.title ? `${auctionId} - ${detail?.title}` : ""}
      />
      <ScrollButton />
      <div className={`page-wrapper-outer grey-bg `}>
        {/* <Header /> */}

        <div class="dashboard-insider position-relative d-lg-flex w-100 ">
          <Sidebar
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            menuList={menuList}
            setMenuList={setMenuList}
            page="listing"
          />

          <div
            className={
              showMenu
                ? ` main-menu-expanded listing-page-padding-top `
                : "main-menu-compressed listing-page-padding-top"
            }
            style={{ backgroundColor: "#F5F5F5" }}
          >
            {/* <div class="filter-container d-flex align-items-center sticky-listing-top">
              <div class="container-fluid container-titlebar" style={{ marginLeft: "20px" }}>
                <div class="row">
                  <div
                    class="col-md-12 d-flex align-items-center flex-wrap justify-content-between"
                    style={{ marginLeft: "-13px", paddingRight: "6px" }}
                  >
                    <TitleBarEdit
                      auctionId={auctionId}
                      loadingMain={loadingMain}
                      refStart={ref && ref.current && ref.current.continuousStart}
                      refStop={ref && ref.current && ref.current.complete}
                      isAviationCategory={isAviationCategory}
                      aviationType={aviationType}
                      scrollToView={scrollToView}
                      categoryName={categoryName}
                    />
                  </div>
                </div>
              </div>
              <a
                href={
                  process.env.REACT_APP_MODE == "production"
                    ? `https://admin.salvex.com/admin/_listings/views/_full.cfm?aucID=${auctionId}`
                    : `https://admin-dev.salvex.com/admin/_listings/views/_full.cfm?aucID=${auctionId}`
                }
                target="_blank"
                className="btn btn-primary btn-sm h-100"
                style={{ lineHeight: "1.6" }}
              >
                Old
                <br /> Admin
                <br /> Panel
              </a>{" "}
            </div> */}
            <div
              class={`
page-top-wrapper  ${showMenu ? ` main-menu-expanded ` : "main-menu-compressed"}
                w-100 d-flex flex-wrap justify-content-end`}
              style={{ minHeight: "157.5px", maxHeight: "157.5px" }}
            >
              <TitleBarEdit
                auctionId={auctionId}
                loadingMain={loadingMain}
                refStart={ref && ref.current && ref.current.continuousStart}
                refStop={ref && ref.current && ref.current.complete}
                isAviationCategory={isAviationCategory}
                aviationType={aviationType}
                scrollToView={scrollToView}
                categoryName={categoryName}
                locationFilled={locationFilled}
                saleType={saleType}
                count={count}
                showMessages={showMessages}
                toggleMessages={toggleMessages}
                toggleChat={toggleChat}
                loadingChats={loadingChats}
                selectedChat={selectedChat}
                showChats={showChats}
                setSelectedChat={setSelectedChat}
              />
            </div>

            <div
              className="sticky-menu-wrapper sticky-top detail-menu "
              style={{ marginTop: "1px", paddingRight: "0px" }}
            >
              <div className="submenu-wrapper h-60">
                <div className="container-fluid listing-container listing-container-listing h-60">
                  <div className="row h-60">
                    <div
                      className="col-md-12 d-flex align-items-center justify-content-between"
                      style={{ marginLeft: "7px", paddingRight: "0px" }}
                    >
                      <ul className="sub-menu-list text-nowrap hide-scrollbar d-flex flex-nowrap align-items-center flex-grow-1">
                        <li className={`sml-item ${section === "picturesRef" ? "active" : ""}`}>
                          <a
                            className="sml-link"
                            href="javascript:void(0)"
                            onClick={() => {
                              scrollToView("picturesRef");
                              setSection("picturesRef");
                            }}
                          >
                            <span>Public Pictures</span>
                          </a>
                        </li>
                        <li className={`sml-item ${section === "docsRef" ? "active" : ""}`}>
                          <a
                            className="sml-link"
                            href="javascript:void(0)"
                            onClick={() => {
                              scrollToView("docsRef");
                              setSection("docsRef");
                            }}
                          >
                            <span>Public Documents</span>
                          </a>
                        </li>
                        <li className={`sml-item ${section === "detailsRef" ? "active" : ""}`}>
                          <a
                            className="sml-link"
                            href="javascript:void(0)"
                            onClick={() => {
                              scrollToView("detailsRef");
                              setSection("detailsRef");
                            }}
                          >
                            <span>Product Details</span>
                          </a>
                        </li>
                        <li className={`sml-item ${section === "sustainabilityRef" ? "active" : ""}`}>
                          <a
                            className="sml-link"
                            href="javascript:void(0)"
                            onClick={() => {
                              scrollToView("sustainabilityRef");
                              setSection("sustainabilityRef");
                            }}
                          >
                            <span>Sustainability Report</span>
                          </a>
                        </li>
                        <li className={`sml-item ${section === "descpRef" ? "active" : ""}`}>
                          <a
                            className="sml-link"
                            href="javascript:void(0)"
                            onClick={() => {
                              scrollToView("descpRef");
                              setSection("descpRef");
                            }}
                          >
                            <span>Product Description</span>
                          </a>
                        </li>
                        {/* <li className={`sml-item ${section === "conditionRef" ? "active" : ""}`}>
                          <a
                            className="sml-link"
                            href="javascript:void(0)"
                            onClick={() => {
                              scrollToView("conditionRef");
                              setSection("conditionRef");
                            }}
                          >
                            <span>Condition</span>
                          </a>
                        </li> */}
                        <li className={`sml-item ${section === "moreDetailsRef" ? "active" : ""}`}>
                          <a
                            className="sml-link"
                            href="javascript:void(0)"
                            onClick={() => {
                              scrollToView("moreDetailsRef");
                              setSection("moreDetailsRef");
                            }}
                          >
                            <span>More Details</span>
                          </a>
                        </li>

                        <li className={`sml-item ${section === "sellerMotivationRef" ? "active" : ""}`}>
                          <a
                            className="sml-link"
                            href="javascript:void(0)"
                            onClick={() => {
                              scrollToView("sellerMotivationRef");
                              setSection("sellerMotivationRef");
                            }}
                          >
                            <span>Seller Motivation</span>
                          </a>
                        </li>
                        {/* <li className={`sml-item ${section === "otherDealsRef" ? "active" : ""}`}>
                          <a
                            className="sml-link"
                            href="javascript:void(0)"
                            onClick={() => {
                              scrollToView("otherDealsRef");
                              setSection("otherDealsRef");
                            }}
                          >
                            <span>Other Deals</span>
                          </a>
                        </li> */}
                        {/* <li className={`sml-item ${section === "latestActivity" ? "active" : ""}`}>
                          <a
                            className="sml-link"
                            href="javascript:void(0)"
                            onClick={() => {
                              scrollToView("latestActivity");
                              setSection("latestActivity");
                            }}
                          >
                            <span>Latest Activity</span>
                          </a>
                        </li> */}
                        {/* <li className={`sml-item ${section === "buyerInterest" ? "active" : ""}`}>
                          <a
                            className="sml-link"
                            href="javascript:void(0)"
                            onClick={() => {
                              scrollToView("buyerInterest");
                              setSection("buyerInterest");
                            }}
                          >
                            <span>Buyer Interests</span>
                          </a>
                        </li> */}
                        <li className={`sml-item ${section === "dealProgress" ? "active" : ""}`}>
                          <a
                            className="sml-link"
                            href="javascript:void(0)"
                            onClick={() => {
                              scrollToView("dealProgress");
                              setSection("dealProgress");
                            }}
                          >
                            <span>Deal Progress</span>
                          </a>
                        </li>

                        <li className={`sml-item ${section === "buyerPushRef" ? "active" : ""}`}>
                          <a
                            className="sml-link"
                            href="javascript:void(0)"
                            onClick={() => {
                              scrollToView("buyerPushRef");
                              setSection("buyerPushRef");
                            }}
                          >
                            <span>Buyer Push History</span>
                          </a>
                        </li>
                        <li className={`sml-item ${section === "newInvoiceRef" ? "active" : ""}`}>
                          <a
                            className="sml-link"
                            href="javascript:void(0)"
                            onClick={() => {
                              scrollToView("newInvoiceRef");
                              setSection("newInvoiceRef");
                            }}
                          >
                            <span>Invoices</span>
                          </a>
                        </li>
                      </ul>
                      <Dropdown className="right-toggle-button h-100">
                        <Dropdown.Toggle className="dropdown-toggle h-100 listing-page_dropDown_width" type="button">
                          <img src={dot} alt="" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu dropdown-menu-end ">
                          <div className="dropdown-menu-list d-flex flex-wrap">
                            <div className="dml-item w-50">
                              {/* <a
                                href="javascript:void(0)"
                                className={`dml-link d-block ${section === "primarySeller" ? "active" : ""}`}
                                onClick={() => {
                                  scrollToView("primarySeller");
                                  setSection("primarySeller");
                                }}
                              >
                                Primary Seller
                              </a> */}
                              {/* <a href="javascript:void(0)" className="dml-link d-block">
                                Observers
                              </a> */}
                              <a
                                href="javascript:void(0)"
                                className={`dml-link d-block ${section === "exclusiveRef" ? "active" : ""}`}
                                onClick={() => {
                                  scrollToView("exclusiveRef");
                                  setSection("exclusiveRef");
                                }}
                              >
                                Exclusivity
                              </a>
                              <a
                                href="javascript:void(0)"
                                className={`dml-link d-block ${section === "accountingRef" ? "active" : ""}`}
                                onClick={() => {
                                  scrollToView("accountingRef");
                                  setSection("accountingRef");
                                }}
                              >
                                Accounting
                              </a>
                              {/* <a href="javascript:void(0)" className="dml-link d-block">
                                Charity
                              </a>
                              <a href="javascript:void(0)" className="dml-link d-block">
                                Sales Terms
                              </a> */}
                              <a
                                href="javascript:void(0)"
                                className={`dml-link d-block ${section === "customerChatsRef" ? "active" : ""}`}
                                onClick={() => toggleChat()}
                              >
                                Customer Chats
                              </a>
                              {showPendingTask && (
                                <a
                                  href="javascript:void(0)"
                                  className={`dml-link d-block ${section === "pendingTasks" ? "active" : ""}`}
                                  onClick={() => {
                                    scrollToView("pendingTasks");
                                    setSection("pendingTasks");
                                  }}
                                >
                                  Pending Tasks
                                </a>
                              )}
                              <a
                                href="javascript:void(0)"
                                className={`dml-link d-block ${section === "completedTasks" ? "active" : ""}`}
                                onClick={() => {
                                  scrollToView("completedTasks");
                                  setSection("completedTasks");
                                }}
                              >
                                Completed Tasks
                              </a>
                              <a
                                href="javascript:void(0)"
                                className={`dml-link d-block ${section === "commentsAndNotes" ? "active" : ""}`}
                                onClick={() => {
                                  scrollToView("commentsAndNotes");
                                  setSection("commentsAndNotes");
                                }}
                              >
                                Comments and Notes
                              </a>
                              <a
                                href="javascript:void(0)"
                                className={`dml-link d-block ${section === "bidsRef" ? "active" : ""}`}
                                onClick={() => {
                                  scrollToView("bidsRef");
                                  setSection("bidsRef");
                                }}
                              >
                                Bids
                              </a>
                              <a
                                href="javascript:void(0)"
                                className={`dml-link d-block ${section === "questionRef" ? "active" : ""}`}
                                onClick={() => {
                                  scrollToView("questionRef");
                                  setSection("questionRef");
                                }}
                              >
                                Questions
                              </a>
                            </div>
                            <div className="dml-item w-50">
                              <a
                                href="javascript:void(0)"
                                className={`dml-link d-block ${section === "taskRef" ? "active" : ""}`}
                                onClick={() => {
                                  scrollToView("taskRef");
                                  setSection("taskRef");
                                }}
                              >
                                Tasks
                              </a>
                              <a
                                href="javascript:void(0)"
                                className={`dml-link d-block ${section === "primarySeller" ? "active" : ""}`}
                                onClick={() => {
                                  scrollToView("primarySeller");
                                  setSection("primarySeller");
                                }}
                              >
                                Primary Seller
                              </a>
                              <a
                                href="javascript:void(0)"
                                className={`dml-link d-block ${section === "payableRef" ? "active" : ""}`}
                                onClick={() => {
                                  scrollToView("payableRef");
                                  setSection("payableRef");
                                }}
                              >
                                Payables
                              </a>
                              <a
                                href="javascript:void(0)"
                                className={`dml-link d-block ${section === "pushEmailRef" ? "active" : ""}`}
                                onClick={() => {
                                  scrollToView("pushEmailRef");
                                  setSection("pushEmailRef");
                                }}
                              >
                                Push Email Clickers
                              </a>
                              {categoryId !== null && (
                                <a
                                  href="javascript:void(0)"
                                  className={`dml-link d-block ${section === "sellerOtherListings" ? "active" : ""}`}
                                  onClick={() => {
                                    scrollToView("sellerOtherListings");
                                    setSection("otherListingRef");
                                  }}
                                >
                                  Other Listings
                                </a>
                              )}
                            </div>
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* {listingContract && listingContract?.data?.length > 0 && (
              <div class="progress-row d-flex align-items-center justify-content-between ">
                <div class="row-progress-label" role="progressbar">
                  <span className="link-item-contract-listing cursor-pointer" onClick={() => toggleContractListing()}>
                    This listing is under contract. Click here to view the details.
                  </span>{" "}
                </div>
              </div>
            )} */}

            {/* <div className="col-row-container">
              <div className="container-fluid listing-container-investment">
                <div className="row">
                  <div className="col-md-12">
                    <div className="col-row-list d-flex flex-wrap">
                      <Overview
                        auctionId={auctionId}
                        loadingMain={loadingMain}
                        refStart={ref && ref.current && ref.current.continuousStart}
                        refStop={ref && ref.current && ref.current.complete}
                        id="listingOverviewRef"
                        categoryName={categoryName}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="sticky-menu-wrapper sticky-top">
              <div className="menu-wrapper d-flex align-items-center">
                <div className="container-fluid listing-container">
                  <div className="row">
                    <div className="col-md-12">
                      <ul className="menu-list d-flex flex-nowrap text-nowrap align-items-center text-white hide-scrollbar">
                        <li className="ml-item">
                          <div className="ml-link d-flex align-items-center justify-content-center">
                            <button type="button" className="add-new-btn">
                              + New
                            </button>
                          </div>
                        </li>
                        <li className="ml-item  active">
                          <a
                            className="ml-link d-flex align-items-center justify-content-center"
                            href="javascript:void(0)"
                          >
                            <span>Duplicate</span>
                          </a>
                        </li>
                        <li className="ml-item">
                          <a
                            className="ml-link d-flex align-items-center justify-content-center"
                            href="javascript:void(0)"
                          >
                            <span>Clear Cache</span>
                          </a>
                        </li>
                        <li className="ml-item">
                          <a
                            className="ml-link d-flex align-items-center justify-content-center"
                            href="javascript:void(0)"
                          >
                            <span>Optimize</span>
                          </a>
                        </li>
                        <li className="ml-item">
                          <a
                            className="ml-link d-flex align-items-center justify-content-center"
                            href="javascript:void(0)"
                          >
                            <span>Re-Optimize</span>
                          </a>
                        </li>
                        <li className="ml-item">
                          <a
                            className="ml-link d-flex align-items-center justify-content-center"
                            href="javascript:void(0)"
                          >
                            <span>Re-Push to All</span>
                          </a>
                        </li>
                        <li className="ml-item">
                          <a
                            className="ml-link d-flex align-items-center justify-content-center"
                            href="javascript:void(0)"
                          >
                            <span>Re-Push to New Emails</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="submenu-wrapper">
                <div className="container-fluid listing-container">
                  <div className="row">
                    <div className="col-md-12 d-flex align-items-center justify-content-between">
                      <ul className="sub-menu-list text-nowrap hide-scrollbar d-flex flex-nowrap align-items-center flex-grow-1">
                        <li
                          className={`sml-item ${
                            section === "picturesRef" ? "active" : ""
                          }`}
                        >
                          <a
                            className="sml-link"
                            href="javascript:void(0)"
                            onClick={() => setSection("picturesRef")}
                          >
                            <span>Public Pictures</span>
                          </a>
                        </li>
                        <li
                          className={`sml-item ${
                            section === "docsRef" ? "active" : ""
                          }`}
                        >
                          <a
                            className="sml-link"
                            href="javascript:void(0)"
                            onClick={() => setSection("docsRef")}
                          >
                            <span>Public Documents</span>
                          </a>
                        </li>
                        <li
                          className={`sml-item ${
                            section === "detailsRef" ? "active" : ""
                          }`}
                        >
                          <a
                            className="sml-link"
                            href="javascript:void(0)"
                            onClick={() => setSection("detailsRef")}
                          >
                            <span>Product Details</span>
                          </a>
                        </li>
                        <li
                          className={`sml-item ${
                            section === "sustainabilityRef" ? "active" : ""
                          }`}
                        >
                          <a
                            className="sml-link"
                            href="javascript:void(0)"
                            onClick={() => setSection("sustainabilityRef")}
                          >
                            <span>Sustainability Report</span>
                          </a>
                        </li>
                        <li
                          className={`sml-item ${
                            section === "descpRef" ? "active" : ""
                          }`}
                        >
                          <a
                            className="sml-link"
                            href="javascript:void(0)"
                            onClick={() => setSection("descpRef")}
                          >
                            <span>Product Description</span>
                          </a>
                        </li>
                        <li
                          className={`sml-item ${
                            section === "conditionRef" ? "active" : ""
                          }`}
                        >
                          <a
                            className="sml-link"
                            href="javascript:void(0)"
                            onClick={() => setSection("conditionRef")}
                          >
                            <span>Condition</span>
                          </a>
                        </li>
                        <li
                          className={`sml-item ${
                            section === "moreDetailsRef" ? "active" : ""
                          }`}
                        >
                          <a
                            className="sml-link"
                            href="javascript:void(0)"
                            onClick={() => setSection("moreDetailsRef")}
                          >
                            <span>More Details</span>
                          </a>
                        </li>

                        <li
                          className={`sml-item ${
                            section === "sellerMotivationRef" ? "active" : ""
                          }`}
                        >
                          <a
                            className="sml-link"
                            href="javascript:void(0)"
                            onClick={() => setSection("sellerMotivationRef")}
                          >
                            <span>Seller Motivation</span>
                          </a>
                        </li>
                        <li
                          className={`sml-item ${
                            section === "latestActivity" ? "active" : ""
                          }`}
                        >
                          <a
                            className="sml-link"
                            href="javascript:void(0)"
                            onClick={() => setSection("latestActivity")}
                          >
                            <span>Latest Activity</span>
                          </a>
                        </li>
                        <li
                          className={`sml-item ${
                            section === "buyerInterest" ? "active" : ""
                          }`}
                        >
                          <a
                            className="sml-link"
                            href="javascript:void(0)"
                            onClick={() => setSection("buyerInterest")}
                          >
                            <span>Buyer Interests</span>
                          </a>
                        </li>
                        <li
                          className={`sml-item ${
                            section === "dealProgress" ? "active" : ""
                          }`}
                        >
                          <a
                            className="sml-link"
                            href="javascript:void(0)"
                            onClick={() => setSection("dealProgress")}
                          >
                            <span>Deal Progress</span>
                          </a>
                        </li>

                        <li
                          className={`sml-item ${
                            section === "buyerPushRef" ? "active" : ""
                          }`}
                        >
                          <a
                            className="sml-link"
                            href="javascript:void(0)"
                            onClick={() => setSection("buyerPushRef")}
                          >
                            <span>Buyer Push History</span>
                          </a>
                        </li>
                      </ul>
                      <Dropdown className="right-toggle-button h-100">
                        <Dropdown.Toggle
                          className="dropdown-toggle h-100 listing-page_dropDown_width"
                          type="button"
                        >
                          <img src={dot} alt="" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu dropdown-menu-end ">
                          <div className="dropdown-menu-list d-flex flex-wrap">
                            <div className="dml-item w-50">
                              <a
                                href="javascript:void(0)"
                                className={`dml-link d-block ${
                                  section === "primarySeller" ? "active" : ""
                                }`}
                                onClick={() => setSection("primarySeller")}
                              >
                                Primary Seller
                              </a>
                              <a href="javascript:void(0)" className="dml-link d-block">
                                Observers
                              </a>
                              <a
                                href="javascript:void(0)"
                                className={`dml-link d-block ${
                                  section === "exclusiveRef" ? "active" : ""
                                }`}
                                onClick={() => setSection("exclusiveRef")}
                              >
                                Exclusivity
                              </a>
                              <a
                                href="javascript:void(0)"
                                className={`dml-link d-block ${
                                  section === "accountingRef" ? "active" : ""
                                }`}
                                onClick={() => setSection("accountingRef")}
                              >
                                Accounting
                              </a>
                              <a href="javascript:void(0)" className="dml-link d-block">
                                Charity
                              </a>
                              <a href="javascript:void(0)" className="dml-link d-block">
                                Sales Terms
                              </a>
                              <a
                                href="javascript:void(0)"
                                className={`dml-link d-block ${
                                  section === "customerChatsRef" ? "active" : ""
                                }`}
                                onClick={() => setSection("customerChatsRef")}
                              >
                                Customer Chats
                              </a>
                              {showPendingTask && (
                                <a
                                  href="javascript:void(0)"
                                  className={`dml-link d-block ${
                                    section === "pendingTasks" ? "active" : ""
                                  }`}
                                  onClick={() => setSection("pendingTasks")}
                                >
                                  Pending Tasks
                                </a>
                              )}
                              <a
                                href="javascript:void(0)"
                                className={`dml-link d-block ${
                                  section === "completedTasks" ? "active" : ""
                                }`}
                                onClick={() => setSection("completedTasks")}
                              >
                                Completed Tasks
                              </a>
                              <a
                                href="javascript:void(0)"
                                className={`dml-link d-block ${
                                  section === "exclusiveRef" ? "active" : ""
                                }`}
                                onClick={() => setSection("commentsAndNotes")}
                              >
                                Comments and Notes
                              </a>
                            </div>
                            <div className="dml-item w-50">
                              <a
                                href="javascript:void(0)"
                                className={`dml-link d-block ${
                                  section === "optimizationSitesRef"
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() =>
                                  setSection("optimizationSitesRef")
                                }
                              >
                                Optimization Sites
                              </a>
                              <a
                                href="javascript:void(0)"
                                className={`dml-link d-block ${
                                  section === "bidsRef" ? "active" : ""
                                }`}
                                onClick={() => setSection("bidsRef")}
                              >
                                Bids
                              </a>
                              <a
                                href="javascript:void(0)"
                                className={`dml-link d-block ${
                                  section === "questionRef" ? "active" : ""
                                }`}
                                onClick={() => setSection("questionRef")}
                              >
                                Questions
                              </a>
                              <a
                                href="javascript:void(0)"
                                className={`dml-link d-block ${
                                  section === "taskRef" ? "active" : ""
                                }`}
                                onClick={() => setSection("taskRef")}
                              >
                                Tasks
                              </a>
                              <a
                                href="javascript:void(0)"
                                className={`dml-link d-block ${
                                  section === "invoiceRef" ? "active" : ""
                                }`}
                                onClick={() => setSection("invoiceRef")}
                              >
                                Invoices
                              </a>
                              <a
                                href="javascript:void(0)"
                                className={`dml-link d-block ${
                                  section === "payableRef" ? "active" : ""
                                }`}
                                onClick={() => setSection("payableRef")}
                              >
                                Payables
                              </a>
                              <a
                                href="javascript:void(0)"
                                className={`dml-link d-block ${
                                  section === "pushEmailRef" ? "active" : ""
                                }`}
                                onClick={() => setSection("pushEmailRef")}
                              >
                                Push Email Clickers
                              </a>
                              {categoryId !== null && (
                                <a
                                  href="javascript:void(0)"
                                  className={`dml-link d-block ${
                                    section === "otherListingRef"
                                      ? "active"
                                      : ""
                                  }`}
                                  onClick={() => setSection("otherListingRef")}
                                >
                                  Other Listings
                                </a>
                              )}
                            </div>
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="detail-page-container pb-5">
              <div className="container-fluid listing-container listing-container-listing">
                <PrimarySellerNew
                  auctionId={auctionId}
                  refStart={ref && ref.current && ref.current.continuousStart}
                  refStop={ref && ref.current && ref.current.complete}
                  loadingMain={loadingPrimarySeller}
                  loadingBuyerPush={loadingBuyerPush}
                  listingContract={listingContract}
                  toggleContractListing={toggleContractListing}
                  setSection={setSection}
                  scrollToView={scrollToView}
                  noSeller={noSeller}
                  setNoSeller={setNoSeller}
                  sellerId={primarySellerParams.sellerId}
                  memberId={primarySellerParams.memberId}
                  s_id={primarySellerParams.s_id}
                />
                <div className="row">
                  <div className="col-md-12">
                    <div className="dpc-col-row  d-flex flex-wrap">
                      <div className="dpc-col-column-custom1">
                        <div>
                          <TopBids
                            auctionId={auctionId}
                            loadingMain={loadingBids}
                            refStart={ref && ref.current && ref.current.continuousStart}
                            refStop={ref && ref.current && ref.current.complete}
                            setSection={setSection}
                            scrollToView={scrollToView}
                          />
                          <QuestionLists
                            auctionId={auctionId}
                            count={count}
                            loadingMain={loadingChats}
                            refStart={ref && ref.current && ref.current.continuousStart}
                            refStop={ref && ref.current && ref.current.complete}
                            toggleMessages={toggleMessages}
                            setMessageSection={setMessageSection}
                            setSelectedChat={setSelectedChat}
                            id="questionRef"
                            isRedirect={queryString?.parse(location?.search)?.redirect?.trim() || ""}
                          />

                          <PushEmailClickersNew
                            auctionId={auctionId}
                            count={count}
                            loadingMain={loadingEmailClickers}
                            refStart={ref && ref?.current && ref?.current?.continuousStart}
                            refStop={ref && ref?.current && ref?.current?.complete}
                            id={"pushEmailRef"}
                          />
                          <PushEmailOpened
                            auctionId={auctionId}
                            count={count2?.pushEmailOpened}
                            loadingMain={loadingEmailClickers}
                            refStart={ref && ref?.current && ref?.current?.continuousStart}
                            refStop={ref && ref?.current && ref?.current?.complete}
                            categoryId={categoryId}
                            id={"pushEmailRef"}
                          />
                          {!noSeller && (
                            <SellerOtherListings
                              auctionId={auctionId}
                              loadingMain={loadingSellerOtherListings}
                              setLoading={setLoadingSellerOtherListings}
                              refStart={ref && ref.current && ref.current.continuousStart}
                              refStop={ref && ref.current && ref.current.complete}
                              setSection={setSection}
                              scrollToView={scrollToView}
                              id={"sellerOtherListings"}
                            />
                          )}
                          <div className="dpc-col-row  d-flex flex-wrap">
                            <div className="dpc-col-column-half">
                              {/* <ComingSoonCard size="small" title="Latest Activity" /> */}

                              {/* <LatestActivity
                            auctionId={auctionId}
                            refStart={ref && ref.current && ref.current.continuousStart}
                            refStop={ref && ref.current && ref.current.complete}
                            loadingMain={loadingMain}
                            id={"primarySeller"}
                          /> */}
                              {/* <ComingSoonCard size="small" title="Primary Seller" /> */}
                              <NewInvoices
                                auctionId={auctionId}
                                count={count}
                                loadingMain={loadingInvoices}
                                refStart={ref && ref.current && ref.current.continuousStart}
                                refStop={ref && ref.current && ref.current.complete}
                                setSection={setSection}
                                scrollToView={scrollToView}
                                sellerVerificationStatus={sellerVerificationStatus}
                                payableCount={payableCount}
                                qtyConfirmed={qtyConfirmation}
                                locationFilled={locationFilled}
                                id={"newInvoiceRef"}
                              />
                              <NewPayables
                                auctionId={auctionId}
                                count={count}
                                loadingMain={loadingPayables}
                                refStart={ref && ref.current && ref.current.continuousStart}
                                refStop={ref && ref.current && ref.current.complete}
                                id={"payableRef"}
                              />
                              <Accounting
                                auctionId={auctionId}
                                loadingMain={loadingAccounting}
                                refStart={ref && ref.current && ref.current.continuousStart}
                                refStop={ref && ref.current && ref.current.complete}
                                id={"accountingRef"}
                              />
                              <div className="details-page-widget-block" id={"primarySeller"}>
                                <PrimarySeller
                                  auctionId={auctionId}
                                  sellerId={primarySellerParams.sellerId}
                                  memberId={primarySellerParams.memberId}
                                  s_id={primarySellerParams.s_id}
                                  refStart={ref && ref.current && ref.current.continuousStart}
                                  refStop={ref && ref.current && ref.current.complete}
                                  loadingMain={loadingPrimarySeller}
                                  noSeller={noSeller}
                                  setNoSeller={setNoSeller}
                                />
                              </div>

                              <div class="page-widget-body details-page-widget-block " id={"sellerMotivationRef"}>
                                <SellerMotivation
                                  auctionId={auctionId}
                                  loadingMain={loadingSeller}
                                  refStart={ref && ref.current && ref.current.continuousStart}
                                  refStop={ref && ref.current && ref.current.complete}
                                />
                              </div>
                              {loadingMessageChat && (
                                <div class="page-widget-body details-page-widget-block ">
                                  <OtherDeals
                                    auctionId={auctionId}
                                    loadingMain={loadingMain}
                                    lisitingData={lisitingData}
                                    refStart={ref && ref.current && ref.current.continuousStart}
                                    refStop={ref && ref.current && ref.current.complete}
                                    id={"otherDealsRef"}
                                    primarySellerParams={primarySellerParams}
                                  />
                                </div>
                              )}
                              <Observers
                                auctionId={auctionId}
                                loadingMain={loadingObservers}
                                refStart={ref && ref.current && ref.current.continuousStart}
                                refStop={ref && ref.current && ref.current.complete}
                                sId={primarySellerParams?.s_id}
                                id={"observersRef"}
                              />
                              <Exclusivity
                                auctionId={auctionId}
                                loadingMain={loadingExclusivity}
                                refStart={ref && ref.current && ref.current.continuousStart}
                                refStop={ref && ref.current && ref.current.complete}
                                id={"exclusiveRef"}
                              />
                            </div>
                            <div className="dpc-col-column-half">
                              <Pricing
                                auctionId={auctionId}
                                loadingMain={loadingPriceInfo}
                                refStart={ref && ref.current && ref.current.continuousStart}
                                refStop={ref && ref.current && ref.current.complete}
                                id={"productLocation"}
                                isOnListing={isOnListing}
                                qtyConfirmation={qtyConfirmation}
                              />
                              {/* <NewInvoices
                          auctionId={auctionId}
                          loadingMain={loadingMain}
                          refStart={ref && ref.current && ref.current.continuousStart}
                          refStop={ref && ref.current && ref.current.complete}
                          id={"productLocation"}
                        /> */}

                              <ProductLocation
                                auctionId={auctionId}
                                loadLocation={loadLocation}
                                loadingMain={loadingProductLocation}
                                refStart={ref && ref.current && ref.current.continuousStart}
                                refStop={ref && ref.current && ref.current.complete}
                                id={"productLocation"}
                              />
                              {/* <BuyerInterest
                          loadingMain={loadingMain}
                          refStart={ref && ref.current && ref.current.continuousStart}
                          refStop={ref && ref.current && ref.current.complete}
                          auctionId={auctionId}
                          listingDetail={listingDetail}
                          id={"buyerInterest"}
                        /> */}
                              {/* <ComingSoonCard size="small" title="Deal Progress" /> */}
                              <DealProgress
                                loadingMain={loadingDealProgress}
                                refStart={ref && ref.current && ref.current.continuousStart}
                                refStop={ref && ref.current && ref.current.complete}
                                auctionId={auctionId}
                                setShowPendingTask={setShowPendingTask}
                                listingDetail={listingDetail}
                                id={"dealProgress"}
                              />
                              <Images
                                loadingMain={loadingImages}
                                refStart={ref && ref.current && ref.current.continuousStart}
                                refStop={ref && ref.current && ref.current.complete}
                                auctionId={auctionId}
                                listingDetail={listingDetail}
                                id={"picturesRef"}
                              />
                              <PublicDocuments
                                auctionId={auctionId}
                                refStart={ref && ref.current && ref.current.continuousStart}
                                refStop={ref && ref.current && ref.current.complete}
                                loadingDocument={loadingDocument}
                                id={"docsRef"}
                              />
                              <PrivateDocuments
                                auctionId={auctionId}
                                loadingDocument={loadingPrivateDocuments}
                                refStart={ref && ref.current && ref.current.continuousStart}
                                refStop={ref && ref.current && ref.current.complete}
                                id={"pvtDocsRef"}
                              />

                              <TransportationStorage
                                auctionId={auctionId}
                                loadingMain={loadingProductDetails}
                                refStart={ref && ref.current && ref.current.continuousStart}
                                refStop={ref && ref.current && ref.current.complete}
                                categoryId={categoryId}
                                id={"detailsRef"}
                              />

                              <ProductDescription
                                auctionId={auctionId}
                                loadingMain={loadingProductCondition}
                                refStart={ref && ref.current && ref.current.continuousStart}
                                refStop={ref && ref.current && ref.current.complete}
                                id={"descpRef"}
                              />

                              {pipeDetailView && (
                                <PipeDetails
                                  auctionId={auctionId}
                                  loadingMain={loadingPipe}
                                  refStart={ref && ref.current && ref.current.continuousStart}
                                  refStop={ref && ref.current && ref.current.complete}
                                  id={"pipeDetailRef"}
                                />
                              )}

                              <MoreDetails
                                auctionId={auctionId}
                                loadingMain={loadingMoreDetails}
                                refStart={ref && ref.current && ref.current.continuousStart}
                                refStop={ref && ref.current && ref.current.complete}
                                id={"moreDetailsRef"}
                              />
                              <AIDescription
                                auctionId={auctionId}
                                loadingMain={loadingDescp}
                                refStart={ref && ref.current && ref.current.continuousStart}
                                refStop={ref && ref.current && ref.current.complete}
                                id="aiDescp"
                              />
                              <ManufactureDescription
                                auctionId={auctionId}
                                loadingMain={loadingMfrAndModel}
                                refStart={ref && ref.current && ref.current.continuousStart}
                                refStop={ref && ref.current && ref.current.complete}
                                id="mfrDescp"
                              />
                              <CarbonFootprint
                                auctionId={auctionId}
                                loadingMain={loadingDescp}
                                refStart={ref && ref.current && ref.current.continuousStart}
                                refStop={ref && ref.current && ref.current.complete}
                                id="carbonFootprint"
                              />
                              <AiLeads
                                auctionId={auctionId}
                                loadingMain={loadingDescp}
                                refStart={ref && ref.current && ref.current.continuousStart}
                                refStop={ref && ref.current && ref.current.complete}
                                id="aiLeads"
                              />
                              <SustainabilityReport
                                auctionId={auctionId}
                                loadingMain={loadigSustaiabilityReport}
                                loadingSpecies={loadingSpecies}
                                refStart={ref && ref.current && ref.current.continuousStart}
                                refStop={ref && ref.current && ref.current.complete}
                                id={"sustainabilityRef"}
                              />
                              {/* <Animal
                                auctionId={auctionId}
                                loadingMain={loadingSpecies}
                                refStart={ref && ref.current && ref.current.continuousStart}
                                refStop={ref && ref.current && ref.current.complete}
                                id={"animalRef"}
                              /> */}
                              {/* <OwnerAccessLog
                          auctionId={auctionId}
                          loadingMain={loadingMain}
                          refStart={
                            ref && ref.current && ref.current.continuousStart
                          }
                          refStop={ref && ref.current && ref.current.complete}
                          id="carbonFootprint"
                        /> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="dpc-col-column-custom2">
                        <div>
                          {/* <StrategicTeam
                            auctionId={auctionId}
                            loadingMain={loadingStrategicTeam}
                            refStart={ref && ref.current && ref.current.continuousStart}
                            refStop={ref && ref.current && ref.current.complete}
                            id="strategicRef"
                          /> */}
                          <CommentsAndNotes
                            auctionId={auctionId}
                            loadingMain={loadingCommentsAndNotes}
                            refStart={ref && ref.current && ref.current.continuousStart}
                            refStop={ref && ref.current && ref.current.complete}
                            setShowPendingTask={setShowPendingTask}
                            id={"commentsAndNotes"}
                          />
                          <CategoryPreferenaces
                            auctionId={auctionId}
                            loadingMain={loadingCategory}
                            refStart={ref && ref.current && ref.current.continuousStart}
                            refStop={ref && ref.current && ref.current.complete}
                            id={"categoryRef"}
                            isAviationCategory={isAviationCategory}
                            aviationType={aviationType}
                          />
                          {/* <ProductCondition
                            auctionId={auctionId}
                            loadingMain={loadingProductCondition}
                            refStart={ref && ref.current && ref.current.continuousStart}
                            refStop={ref && ref.current && ref.current.complete}
                            id={"conditionRef"}
                          /> */}
                          {/* <Manufacturer
                            auctionId={auctionId}
                            loadingMain={loadingMfrAndModel}
                            refStart={ref && ref.current && ref.current.continuousStart}
                            refStop={ref && ref.current && ref.current.complete}
                            id={"manufacturerRef"}
                          /> */}
                          <GoogleAnalytics
                            auctionId={auctionId}
                            loadingMain={loadingGoogleAnalytics}
                            refStart={ref && ref.current && ref.current.continuousStart}
                            refStop={ref && ref.current && ref.current.complete}
                            id={"googleAnalytics"}
                          />

                          <BuyerPushHistory
                            auctionId={auctionId}
                            loadingMain={loadingBuyerPush}
                            setLoadingMain={setLoadingBuyerPush}
                            refStart={ref && ref.current && ref.current.continuousStart}
                            refStop={ref && ref.current && ref.current.complete}
                            id={"buyerPushRef"}
                          />

                          {lisitingData?.detail?.size && (
                            <ActiveListing
                              auctionId={auctionId}
                              loadingMain={loadingMain}
                              size={lisitingData.detail.size}
                              refStart={ref && ref.current && ref.current.continuousStart}
                              refStop={ref && ref.current && ref.current.complete}
                              id={"activeListingRef"}
                            />
                          )}

                          {/* ////======= UnComment if needed =======////// */}
                          {/* <CustomerChats
                            auctionId={auctionId}
                            loadingMain={loadingMain}
                            refStart={ref && ref.current && ref.current.continuousStart}
                            detail={detail}
                            refStop={ref && ref.current && ref.current.complete}
                            id={"customerChatsRef"}
                          /> */}

                          <CompletedTasks
                            auctionId={auctionId}
                            count={count}
                            loadingMain={false}
                            refStart={ref && ref.current && ref.current.continuousStart}
                            refStop={ref && ref.current && ref.current.complete}
                            id={"completedTasks"}
                          />

                          {showPendingTask && (
                            <PendingTasks
                              auctionId={auctionId}
                              count={count}
                              loadingMain={loadingPendingTask}
                              refStart={ref && ref.current && ref.current.continuousStart}
                              refStop={ref && ref.current && ref.current.complete}
                              toggleMessages={toggleMessages}
                              setMessageSection={setMessageSection}
                              setSelectedChat={setSelectedChat}
                              id={"pendingTasks"}
                            />
                          )}

                          <OptimizationSites
                            auctionId={auctionId}
                            loadingMain={loadingSites}
                            refStart={ref && ref.current && ref.current.continuousStart}
                            refStop={ref && ref.current && ref.current.complete}
                            id={"optimizationSitesRef"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <Bids
                  auctionId={auctionId}
                  loadingMain={loadingBids}
                  refStart={ref && ref.current && ref.current.continuousStart}
                  refStop={ref && ref.current && ref.current.complete}
                  overridBid={overrideBid}
                  section={section}
                  id="bidsRef"
                />
                <Invoices
                  auctionId={auctionId}
                  count={count}
                  loadingMain={loadingInvoices}
                  refStart={ref && ref.current && ref.current.continuousStart}
                  refStop={ref && ref.current && ref.current.complete}
                  id={"invoiceRef"}
                  sellerVerificationStatus={sellerVerificationStatus}
                  payableCount={payableCount}
                  qtyConfirmed={qtyConfirmation}
                  locationFilled={locationFilled}
                />
                <EmailCampaigns
                  auctionId={auctionId}
                  count={count}
                  loadingMain={loadingListingCampaign}
                  refStart={ref && ref.current && ref.current.continuousStart}
                  refStop={ref && ref.current && ref.current.complete}
                  id={"emailCampaigns"}
                />
                <Brokers
                  auctionId={auctionId}
                  count={count}
                  refStart={ref && ref.current && ref.current.continuousStart}
                  refStop={ref && ref.current && ref.current.complete}
                  id="brokerRef"
                />

                {/* <Questions
                  auctionId={auctionId}
                  count={count}
                  loadingMain={loadingQuestions}
                  refStart={ref && ref.current && ref.current.continuousStart}
                  refStop={ref && ref.current && ref.current.complete}
                  id="questionRef"
                /> */}
                {/* <BuyerInterests
                  auctionId={auctionId}
                  loadingMain={loadingMain}
                  refStart={ref && ref.current && ref.current.continuousStart}
                  refStop={ref && ref.current && ref.current.complete}
                  id={"buyerInterest"}
                  filterData={filterData}
                  setFilterData={setFilterData}
                /> */}

                <Task
                  auctionId={auctionId}
                  count={count}
                  loadingMain={loadingTask}
                  refStart={ref && ref.current && ref.current.continuousStart}
                  refStop={ref && ref.current && ref.current.complete}
                  id={"taskRef"}
                />

                {/* <PushEmailClickers
                  auctionId={auctionId}
                  count={count}
                  loadingMain={loadingEmailClickers}
                  refStart={ref && ref.current && ref.current.continuousStart}
                  refStop={ref && ref.current && ref.current.complete}
                  id={"pushEmailRef"}
                /> */}

                {/* <Payables
                  auctionId={auctionId}
                  loadingMain={loadingPayables}
                  refStart={ref && ref.current && ref.current.continuousStart}
                  refStop={ref && ref.current && ref.current.complete}
                  id={"payableRef"}
                /> */}

                {categoryId !== null && (
                  <OtherListings
                    auctionId={auctionId}
                    count={count}
                    loadingMain={loadingOtherListings}
                    refStart={ref && ref.current && ref.current.continuousStart}
                    refStop={ref && ref.current && ref.current.complete}
                    categoryName={categoryName}
                    categoryId={categoryId}
                    id={"otherListingRef"}
                  />
                )}
                {productDetail?.categoryAssigned || accessArr.includes(session?.user_id) ? (
                  <>
                    {categoryId !== null && (
                      <KeyBuyersInCategory
                        auctionId={auctionId}
                        count={count2?.keyBuyerInCategory}
                        loadingMain={loadingOtherListings}
                        refStart={ref && ref.current && ref.current.continuousStart}
                        refStop={ref && ref.current && ref.current.complete}
                        categoryName={categoryName}
                        categoryId={categoryId}
                        id={"otherListingRef"}
                      />
                    )}
                    {categoryId !== null && region && (
                      <KeyBuyerInCategoryFromRegion
                        auctionId={auctionId}
                        count={count2?.keyBuyerInCategoryFromRegion}
                        loadingMain={loadingOtherListings}
                        refStart={ref && ref.current && ref.current.continuousStart}
                        refStop={ref && ref.current && ref.current.complete}
                        categoryName={categoryName}
                        region={region}
                        categoryId={categoryId}
                        id={"otherListingRef"}
                      />
                    )}
                    {categoryId !== null && region && (
                      <PurchasedFromCategoryInRegion
                        auctionId={auctionId}
                        count={count2?.purchasedFromCategoryInRegion}
                        loadingMain={loadingOtherListings}
                        refStart={ref && ref.current && ref.current.continuousStart}
                        refStop={ref && ref.current && ref.current.complete}
                        categoryName={categoryName}
                        region={region}
                        categoryId={categoryId}
                        id={"otherListingRef"}
                      />
                    )}
                    {categoryId !== null && region && (
                      <BuyerPreferencesInCategoryInRegion
                        auctionId={auctionId}
                        count={count2?.buyerPreferenceInCategoryInRegion}
                        loadingMain={loadingOtherListings}
                        refStart={ref && ref.current && ref.current.continuousStart}
                        refStop={ref && ref.current && ref.current.complete}
                        categoryName={categoryName}
                        region={region}
                        categoryId={categoryId}
                        id={"otherListingRef"}
                      />
                    )}
                    {categoryId !== null && (
                      <PurchasedFromCategory
                        auctionId={auctionId}
                        count={count2?.purchasedFromCategory}
                        loadingMain={loadingOtherListings}
                        refStart={ref && ref.current && ref.current.continuousStart}
                        refStop={ref && ref.current && ref.current.complete}
                        categoryName={categoryName}
                        categoryId={categoryId}
                        id={"otherListingRef"}
                      />
                    )}
                    {categoryId !== null && region && (
                      <BuyerPurchasedOrBidFromPushCategoryInRegion
                        auctionId={auctionId}
                        count={count2?.buyerWhoPurchasedOrBidFromPushCategoryInRegion}
                        loadingMain={loadingOtherListings}
                        refStart={ref && ref.current && ref.current.continuousStart}
                        refStop={ref && ref.current && ref.current.complete}
                        categoryName={categoryName}
                        region={region}
                        categoryId={categoryId}
                        id={"otherListingRef"}
                      />
                    )}
                    {categoryId !== null && region && (
                      <ManufacturersCompanyPeopleInRegion
                        auctionId={auctionId}
                        count={count2?.manufacturerCompanyPeopleInRegion}
                        loadingMain={loadingOtherListings}
                        refStart={ref && ref.current && ref.current.continuousStart}
                        refStop={ref && ref.current && ref.current.complete}
                        categoryName={categoryName}
                        region={region}
                        categoryId={categoryId}
                        id={"otherListingRef"}
                      />
                    )}
                    {categoryId !== null && region && (
                      <ManufacturerPreferencesInRegion
                        auctionId={auctionId}
                        count={count1?.manufacturerPreferenceInRegion}
                        loadingMain={loadingOtherListings}
                        refStart={ref && ref.current && ref.current.continuousStart}
                        refStop={ref && ref.current && ref.current.complete}
                        categoryName={categoryName}
                        region={region}
                        categoryId={categoryId}
                        id={"otherListingRef"}
                      />
                    )}
                    {categoryId !== null && (
                      <ManufacturersCompanyPeopleGlobally
                        auctionId={auctionId}
                        count={count1?.manufacturerCompanyPeopleGlobally}
                        loadingMain={loadingOtherListings}
                        refStart={ref && ref.current && ref.current.continuousStart}
                        refStop={ref && ref.current && ref.current.complete}
                        categoryName={categoryName}
                        categoryId={categoryId}
                        id={"otherListingRef"}
                      />
                    )}
                    {categoryId !== null && (
                      <ManufacturerPreferencesGlobally
                        auctionId={auctionId}
                        count={count1?.manufacturerPreferenceGlobally}
                        loadingMain={loadingOtherListings}
                        refStart={ref && ref.current && ref.current.continuousStart}
                        refStop={ref && ref.current && ref.current.complete}
                        categoryName={categoryName}
                        categoryId={categoryId}
                        id={"otherListingRef"}
                      />
                    )}
                    {categoryId !== null && (
                      <BuyersWhoHaveBidOnCategory
                        auctionId={auctionId}
                        count={count1?.buyerBidOnCategory}
                        loadingMain={loadingOtherListings}
                        refStart={ref && ref.current && ref.current.continuousStart}
                        refStop={ref && ref.current && ref.current.complete}
                        categoryName={categoryName}
                        categoryId={categoryId}
                        id={"otherListingRef"}
                      />
                    )}

                    {categoryId !== null && (
                      <BrokersOfCategory
                        auctionId={auctionId}
                        count={count1?.brokerOfCategory}
                        loadingMain={loadingOtherListings}
                        refStart={ref && ref.current && ref.current.continuousStart}
                        refStop={ref && ref.current && ref.current.complete}
                        categoryName={categoryName}
                        categoryId={categoryId}
                        id={"otherListingRef"}
                      />
                    )}
                    <EndUsersOfCategory
                      auctionId={auctionId}
                      count={count1?.endUserOfCategory}
                      loadingMain={loadingOtherListings}
                      refStart={ref && ref.current && ref.current.continuousStart}
                      refStop={ref && ref.current && ref.current.complete}
                      categoryName={categoryName}
                      categoryId={categoryId}
                      id={"otherListingRef"}
                    />
                    <MembersWhoHaveViewedTheListing
                      auctionId={auctionId}
                      count={count1?.memberViewedListing}
                      loadingMain={loadingOtherListings}
                      refStart={ref && ref.current && ref.current.continuousStart}
                      refStop={ref && ref.current && ref.current.complete}
                      categoryName={categoryName}
                      categoryId={categoryId}
                      id={"otherListingRef"}
                    />
                  </>
                ) : (
                  ""
                )}
                {isAviationCategory && (
                  <AviationParts
                    auctionId={auctionId}
                    refStart={ref && ref.current && ref.current.continuousStart}
                    refStop={ref && ref.current && ref.current.complete}
                    loadingMain={loadingAviationParts}
                    pagination={"required"}
                    id={"aviationRef"}
                    section={section}
                    scrollToView={scrollToView}
                    setSection={setSection}
                  />
                )}
                {!isAviationCategory && (
                  <Parts
                    auctionId={auctionId}
                    refStart={ref && ref.current && ref.current.continuousStart}
                    refStop={ref && ref.current && ref.current.complete}
                    loadingMain={loadingAviationParts}
                    pagination={"required"}
                    id={"aviationRef"}
                    section={section}
                    scrollToView={scrollToView}
                    setSection={setSection}
                  />
                )}
                {/* UnComment if Needed */}
                {/* {isAviationCategory && (
                  <OrderRequests
                    auctionId={auctionId}
                    refStart={ref && ref.current && ref.current.continuousStart}
                    refStop={ref && ref.current && ref.current.complete}
                    loadingMain={loadingOrderRequests}
                    id={"orderReqRef"}
                    setSection={setSection}
                  />
                )} */}
              </div>
            </div>
          </div>
        </div>
        <ListingContract
          show={showContractListing}
          onHide={toggleContractListing}
          data={listingContract}
          refStart={ref && ref.current && ref.current.continuousStart}
          refStop={ref && ref.current && ref.current.complete}
          auctionId={auctionId}
        />
      </div>
      {showMessages && (
        <ChatMessages
          auctionId={auctionId}
          show={showMessages}
          onHide={toggleMessages}
          selectedChat={selectedChat}
          setMessageSection={setMessageSection}
          refStart={ref && ref.current && ref.current.continuousStart}
          refStop={ref && ref.current && ref.current.complete}
          isRedirect={queryString?.parse(location?.search)?.redirect?.trim() || ""}
        />
      )}
      {showChats && (
        <CustomerChat
          show={showChats}
          onHide={toggleChat}
          chatList={chatList}
          loadingChats={loadingChats}
          toggleMessages={toggleMessages}
          setSelectedChat={setSelectedChat}
          detail={detail}
          refStart={ref && ref.current && ref.current.continuousStart}
          refStop={ref && ref.current && ref.current.complete}
          auctionId={auctionId}
        />
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  //global states for storing api data
  detailListings: (params, callback) => dispatch(detailListing(params, callback)),
  listingImages: (params, callback) => dispatch(listingImages(params, callback)),
  listingDocuments: (params, callback) => dispatch(listingDocuments(params, callback)),
  listingPrivateDocuments: (params, callback) => dispatch(listingPrivateDocuments(params, callback)),
  primaryPicture: (payload) => dispatch(primaryPicture(payload)),
  productDetails: (params, callback) => dispatch(productDetails(params, callback)),
  productCondition: (params, callback) => dispatch(productCondition(params, callback)),
  productDescriptionReceived: (data) => dispatch(productDescriptionReceived(data)),
  sustainabilityReport: (params, callback) => dispatch(sustainabilityReport(params, callback)),
  getMoreDetails: (params, callback) => dispatch(getMoreDetails(params, callback)),
  getMfrAndModel: (data, callback) => dispatch(getMfrAndModel(data, callback)),
  getStrategicTeamDetails: (data, callback) => dispatch(getStrategicTeamDetails(data, callback)),
  getOtherDescp: (params, callback) => dispatch(getOtherDescp(params, callback)),
  getAccounting: (params, callback) => dispatch(getAccounting(params, callback)),
  getSellerMotivation: (params, callback) => dispatch(getSellerMotivation(params, callback)),
  getComparablePrice: (params, callback) => dispatch(getComparablePrice(params, callback)),

  getBuyerPushHistory: (params, callback) => dispatch(getBuyerPushHistory(params, callback)),

  getProductLocation: (params, callback) => dispatch(getProductLocation(params, callback)),

  getCommentsAndNotes: (params, callback) => dispatch(getCommentsAndNotes(params, callback)),
  getOptimizationSiteDetails: (params, callback) => dispatch(getOptimizationSiteDetails(params, callback)),
  getQuestions: (params, callback) => dispatch(getQuestions(params, callback)),
  getCategoryPreferenaces: (params, callback) => dispatch(getCategoryPreferenaces(params, callback)),
  getCategoryData: (params, callback) => dispatch(getCategoryData(params, callback)),
  getOtherListings: (params, categoryId, callback) => dispatch(getOtherListings(params, categoryId, callback)),
  getOtherListingCount1: (id, params, callback) => dispatch(getOtherListingCount1(id, params, callback)),
  getOtherListingCount2: (id, params, callback) => dispatch(getOtherListingCount2(id, params, callback)),
  getOwnerAccessLog: (params, callback) => dispatch(getOwnerAccessLog(params, callback)),
  getTask: (params, callback) => dispatch(getTask(params, callback)),
  getEmailClickersCount: (params, callback) => dispatch(getEmailClickersCount(params, callback)),
  getPushEmailClickers: (id, params, callback) => dispatch(getPushEmailClickers(id, params, callback)),
  getPendingTasks: (params, callback) => dispatch(getPendingTasks(params, callback)),
  getPayableLists: (params, callback) => dispatch(getPayableLists(params, callback)),
  getPayable: (params, payable_id, callback) => dispatch(getPayable(params, payable_id, callback)),
  getInvoiceList: (params, callback) => dispatch(getInvoiceList(params, callback)),
  getUserAccess: (callback) => dispatch(getUserAccess(callback)),
  getBids: (params, callback) => dispatch(getBids(params, callback)),
  getAviationParts: (params, callback) => dispatch(getAviationParts(params, callback)),
  getLatestBids: (params, callback) => dispatch(getLatestBids(params, callback)),
  getPriceInfo: (params, callback) => dispatch(getPriceInfo(params, callback)),
  getBidLog: (params, callback) => dispatch(getBidLog(params, callback)),
  getPrimarySeller: (params, sellerId, memberId, s_id, callback) =>
    dispatch(getPrimarySeller(params, sellerId, memberId, s_id, callback)),
  getBuyerInterests: (params, page, filter, callback) => dispatch(getBuyerInterests(params, page, filter, callback)),
  getDealProgress: (data, callback) => dispatch(getDealProgress(data, callback)),
  getListingContracts: (params, callback) => dispatch(getListingContracts(params, callback)),
  getOrderRequests: (params, callback) => dispatch(getOrderRequests(params, callback)),
  getGAData: (data, callback) => dispatch(getGAData(data, callback)),
  getGoogleAnalytics: (params, day, callback) => dispatch(getGoogleAnalytics(params, day, callback)),
  getListingCampaign: (params, limit, skip, callback) => dispatch(getListingCampaign(params, limit, skip, callback)),
  getProductCount: (params, callback) => dispatch(getProductCount(params, callback)),
  getAuctionChats: (id, callback) => dispatch(loadAuctionChat(id, callback)),
  getAuctionConversationList: (params, callback) => dispatch(getAuctionConversationList(params, callback)),
  getConversation: (id, callback) => dispatch(getConversation(id, callback)),
  loadMassages: (userId, auctionId, callback) => dispatch(loadChatMassages(userId, auctionId, callback)),
  getSellerOtherListings: (option, id, params, callback) =>
    dispatch(getSellerOtherListings(option, id, params, callback)),
  getListingAgreement: (params, callback) => dispatch(getListingAgreement(params, callback)),
  getAuctionSpecies: (id, callback) => dispatch(getAuctionSpecies(id, callback)),
  getObservers: (id, callback) => dispatch(getObservers(id, callback)),
  getPaymentInfo: (userId, callback) => dispatch(getPaymentIfo(userId, callback)),
  getPaymentInform: (userId, callback) => dispatch(getPaymentIform(userId, callback)),
  getPipeDetails: (id, callback) => dispatch(getPipeDetails(id, callback)),
});
const mapStateToProps = (state) => ({
  getSearchListings: getListings(state),
  getListingProductDetail: getListingProductDetail(state),
  getChatList: getChats(state),
  getModals: getModals(state),
  questionDetails: questionDetails(state),
  otherListingsDetails: otherListingsDetails(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(ListingDetail));
