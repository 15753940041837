import React, { useEffect, useState } from "react";
import { deleteTask, getPendingTasks, getTasks, updateTaskStatus } from "../../../store/tasks";
import { connect } from "react-redux";
import DateFormat from "../../../common/DateFormat/dateFormat";
import moment from "moment";
import { toast } from "react-toastify";
import More from "../../../include/images/more-icon.svg";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import AlertError from "../../../common/alerts/alertError";
import DeleteModal from "../../../layouts/modals/deleteModal";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextField } from "@mui/material";
import closeButton from "../../../include/images/close.svg";
import NextButton from "../../../common/form/nextButton";
import CompleteTaskModal from "../../../layouts/modals/taskModal/completeTaskModal";
import InProgressModal from "../../../layouts/modals/taskModal/inProgressModal";
import Skeleton from "react-loading-skeleton";
import { getProfile } from "../../../utils.js/localStorageServices";
import RevisitModal from "../../../layouts/modals/taskModal/revisitModal";
import QuestionModal from "../../../layouts/modals/questionModal/questionModal";
import {
  deleteQuestion,
  getQuestionDetail,
  getQuestions,
  questionDetails,
  questionFetched,
  updateQuestion,
} from "../../../store/questions";
import { getProductCount } from "../../../store/listingProduct";
import { getCommentsAndNotes } from "../../../store/commentsAndNotes";

const PendingTasks = (props) => {
  const [statusData, setStatusData] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [showProgressModal, setShowProgressModal] = useState(false);
  const [showRevisitModal, setShowRevisitModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [revisit, setRevisit] = useState(false);
  const [revisitId, setRevisitId] = useState(null);
  const [taskId, setTaskId] = useState("");
  const [questionId, setQuestionId] = useState("");
  const [showQuestionModal, setShowQuestionModal] = useState(false);
  const [question, setQuestion] = useState({
    auction_id: "",
    title: "",
    name01: "",
    a_email: "",
    q_id: null,
    message: "",
    seller_response: "",
    name: "",
    company: "",
    email: "",
    phone: "",
    member_id: "",
    response: "",
    response01: "",
  });
  const listingData = props && props.getTasks && props.getTasks.pendingTasks && props.getTasks.pendingTasks.data;
  const count = props?.count?.pendingTask;
  //Togle Delete Modal
  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  //Toggle Complete modal
  const toggleCompleteModal = () => {
    setShowCompleteModal(!showCompleteModal);
  };

  //Toggle Progress Modal
  const toggleProgressModal = () => {
    setShowProgressModal(!showProgressModal);
  };

  //Toggle Revisit Modal
  const toggleRevisitModal = () => {
    setShowRevisitModal(!showRevisitModal);
  };

  //Handle Processes
  const handleProcess = () => {
    props.refStart();
    setLoading(true);
    props.updateTaskStatus(props.auctionId, statusData, (res) => {
      if (res.status === 200) {
        props.getPendingTasks(props.auctionId, (res) => {
          if (res.status == 200) {
            setLoading(false);
            props.refStop();
            setRevisit(false);
            if (statusData.status_id === 3) {
              toast(<AlertSuccess message={"Information Saved"} />);
              toggleCompleteModal();
            }
            if (statusData.status_id === 4) {
              toast(<AlertSuccess message={"Information Saved"} />);
              toggleProgressModal();
            }
            if (statusData.status_id === 5) {
              setLoading(false);
              toast(<AlertSuccess message={"Information Saved"} />);
              toggleRevisitModal();
            }
          } else {
            setLoading(false);
            props.refStop();
          }
        });
      } else {
        toast(<AlertError message={res?.data?.message ? res.data.message : "Something Went Wrong"} />);
        setLoading(false);
        props.refStop();
      }
    });
  };

  //Hanlde Delete
  const deleteHandler = () => {
    setLoadingDelete(true);
    props.refStart();
    const id = props.auctionId;
    const t_id = taskId;
    props.deleteTask(id, t_id, (res) => {
      if (res.status === 200) {
        props.getPendingTasks(id, (res) => {
          if (res.status === 200) {
            props.refStop();
            setLoadingDelete(false);
            toggleDeleteModal();
            toast(<AlertSuccess message={"Information Saved"} />);
          } else {
            props.refStop();
            setLoadingDelete(false);
            toggleDeleteModal();
            toast(
              <AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />,
            );
          }
        });
      } else {
        props.refStop();
        setLoadingDelete(false);
        toggleDeleteModal();
        toast(<AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  //Toggle Question Modal
  const toggleQuestionsModal = () => {
    setShowQuestionModal(!showQuestionModal);
  };

  const reloadQuestions = () => {
    props.getQuestions(props.auctionId, (res) => {
      if (res?.status === 200) {
      } else {
        toast(<AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  //Get Question Detail
  const getQuestionDetail = (id) => {
    props?.refStart();
    props.getQuestionDetail(props.auctionId, id, (res) => {
      if (res.status === 200) {
        setQuestion({
          auction_id: res?.data?.data?.auction_id,
          title: res?.data?.data?.title,
          name01: res?.data?.data?.name01,
          a_email: res?.data?.data?.a_email,
          q_id: res?.data?.data?.q_id,
          message: res?.data?.data?.message,
          seller_response: res?.data?.data?.seller_response,
          name: res?.data?.data?.name,
          company: res?.data?.data?.company,
          email: res?.data?.data?.email,
          phone: res?.data?.data?.phone,
          member_id: res?.data?.data?.member_id,
        });
        props?.refStop();
        setShowQuestionModal(true);
      } else {
        props?.refStop();
        toast(<AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  //Forward to seller
  const forwardToSeller = () => {
    props.refStart();
    const data = {};
    data.question = question.message;
    data.q_id = question.q_id;
    props.updateQuestion(props.auctionId, data, (res) => {
      if (res.status === 200) {
        props?.getPendingTasks(props?.auctionId, (res) => {});
        props?.getProductCount(props?.auctionId, (res) => {});
        props.refStop();
        setShowQuestionModal(false);
        reloadQuestions();
        toast(<AlertSuccess message={"Information Saved"} />);
      } else {
        props.refStop();
        setShowQuestionModal(false);
        toast(<AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  //Mark Answered
  const markAnswered = () => {
    props.refStart();
    const data = {};
    data.answer_question = question.q_id;
    props.updateQuestion(props.auctionId, data, (res) => {
      if (res.status === 200) {
        props?.getPendingTasks(props?.auctionId, (res) => {});
        props?.getProductCount(props?.auctionId, (res) => {});
        props.refStop();
        setShowQuestionModal(false);
        reloadQuestions();
        toast(<AlertSuccess message={"Information Saved"} />);
      } else {
        props.refStop();
        setShowQuestionModal(false);
        toast(<AlertError message={res && res.data && res.data.messgae ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  //Send Market Maker Response
  const sendResponse = () => {
    props.refStart();
    const data = {};
    data.response = question.response ? question.response : "";
    data.q_id = question.q_id ? question.q_id : "";
    data.mm_name = question.name01 ? question.name01 : "";
    data.mm_email = question.a_email ? question.a_email : "";
    data.email = question.email ? question.email : "";
    props.updateQuestion(props.auctionId, data, (res) => {
      if (res.status === 200) {
        props.getCommentsAndNotes(props.auctionId, (res) => {});
        props.getPendingTasks(props.auctionId, (res) => {});
        props.getQuestions(props.auctionId, (res) => {});
        props.refStop();
        setShowQuestionModal(false);
        toast(<AlertSuccess message={"Information Saved"} />);
      } else {
        props.refStop();
        toast(<AlertError message="Message Shouldn't be Empty" />);
      }
    });
  };

  //Send Seller Response
  const sendResponseSeller = () => {
    props.refStart();
    const data = {};
    data.response = question.response01 ? question.response01 : "";
    data.q_id = question.q_id ? question.q_id : "";
    data.mm_name = question.name01 ? question.name01 : "";
    data.mm_email = question.a_email ? question.a_email : "";
    data.email = question.email ? (question.email == "undefined" ? "N/A" : question.email) : "N/A";
    props.updateQuestion(props.auctionId, data, (res) => {
      if (res.status === 200) {
        props.refStop();
        setShowQuestionModal(false);
        toast(<AlertSuccess message={"Information Saved"} />);
      } else {
        props.refStop();
        toast(<AlertError message="Message Shouldn't be Empty" />);
      }
    });
  };
  //Delete Handler
  const handleDelete = () => {
    setLoadingDelete(true);
    props.refStart();
    const id = props.auctionId;
    const q_id = questionId;
    props.deleteQuestion(id, q_id, (res) => {
      if (res.status === 200) {
        reloadQuestions();
        props?.getProductCount(id, (res) => {});
        props.refStop();
        setLoadingDelete(false);
        toggleDeleteModal();
        setShowQuestionModal(false);
        toast(<AlertSuccess message={"Information Saved"} />);
      } else {
        props.refStop();
        setLoadingDelete(false);
        toggleDeleteModal();
        toast(<AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const session = getProfile();

  return (
    <div class="details-page-widget-block" id={props.id}>
      <div class="page-widget-head d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <div class="page-widget-label text-capitalize">
            Pending Tasks {!props?.loadingMain && (count > 0 ? `(${count})` : "(0)")}
          </div>
        </div>
      </div>
      <div class={listingData && listingData?.length > 0 ? "page-widget-body mt-3" : ""}>
        <div class="text-card-block-row mt-4">
          {props.loadingMain ? (
            <>
              <div className="text-card-block-column skeleton">
                <div className="tcbc-header d-flex flex-wrap align-items-center justify-content-between">
                  <div className="tcbc-header-label d-flex flex-wrap align-items-center">
                    <div className="fw-medium skeleton-line">
                      <Skeleton width={100} />
                    </div>
                    <span className="skeleton-line"></span>
                  </div>
                  <div className="tcbc-right-box">
                    <Skeleton width={100} />
                  </div>
                </div>
                <div className="tcbc-text-row">
                  <p className="skeleton-line">
                    <Skeleton count={3} />
                  </p>
                </div>
              </div>
              <div className="text-card-block-column skeleton">
                <div className="tcbc-header d-flex flex-wrap align-items-center justify-content-between">
                  <div className="tcbc-header-label d-flex flex-wrap align-items-center">
                    <div className="fw-medium skeleton-line">
                      <Skeleton width={100} />
                    </div>
                    <span className="skeleton-line"></span>
                  </div>
                  <div className="tcbc-right-box">
                    <Skeleton width={100} />
                  </div>
                </div>
                <div className="tcbc-text-row">
                  <p className="skeleton-line">
                    <Skeleton count={3} />
                  </p>
                </div>
              </div>
              <div className="text-card-block-column skeleton">
                <div className="tcbc-header d-flex flex-wrap align-items-center justify-content-between">
                  <div className="tcbc-header-label d-flex flex-wrap align-items-center">
                    <div className="fw-medium skeleton-line">
                      <Skeleton width={100} />
                    </div>
                    <span className="skeleton-line"></span>
                  </div>
                  <div className="tcbc-right-box">
                    <Skeleton width={100} />
                  </div>
                </div>
                <div className="tcbc-text-row">
                  <p className="skeleton-line">
                    <Skeleton count={3} />
                  </p>
                </div>
              </div>
            </>
          ) : (
            listingData?.map((val) => (
              <div class="text-card-block-column">
                <div class="tcbc-header d-flex justify-content-between">
                  <div class="tcbc-header-label d-flex flex-column align-items-start">
                    <div
                      class="tcbc-header-label d-flex ps-2 flex-column align-items-start justify-content-center text-white"
                      style={{
                        // unComment if condition needed
                        // backgroundColor: val?.task_id > 0 ? "#137c10" : "#104b7c",
                        backgroundColor: val?.task_type === 97 ? "#ff4242" : "#137c10",
                        width: "250px",
                        minHeight: "50px",
                        maxHeight: "90px",
                      }}
                    >
                      {val?.chat_id === 0 ? <div class="fw-medium">Unanswered Question</div> : ""}
                      <div class="fw-medium">
                        {val?.sent_name ? `${val?.name01 ? val?.name01 : ""} to ${val?.sent_name}` : val?.name01}
                      </div>
                      <div>{val?.created && <DateFormat date={val?.created} />}</div>
                    </div>
                  </div>

                  {(val?.task_status_id === 1 || (val?.task_id > 0 && val?.task_status_id === 4)) && (
                    <div class="dropdown">
                      <a class="td-a-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                        <img src={More} alt="" />
                      </a>
                      <ul
                        class="dropdown-menu"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasRight"
                        aria-controls="offcanvasRight"
                      >
                        {val?.task_status_id === 1 ? (
                          <div class="tcbc-right-box">
                            {val.member_id == session.member_id || session.member_id == 1 ? (
                              <li className="menu-item">
                                <a
                                  class="dropdown-item"
                                  onClick={() => {
                                    setTaskId(val?.task_id);
                                    toggleDeleteModal();
                                  }}
                                >
                                  Delete
                                </a>
                              </li>
                            ) : (
                              ""
                            )}
                            {val?.task_id > 0 && val?.task_status_id == 1 && val?.task_type == 169 ? (
                              <li className="menu-item">
                                <a
                                  class="dropdown-item"
                                  // onClick={() => {
                                  //   setStatusData({
                                  //     chat_id: val.chat_id,
                                  //     status_id: 4,
                                  //   });
                                  //   toggleProgressModal();
                                  // }}
                                >
                                  &raquo; Respond
                                </a>
                              </li>
                            ) : (
                              ""
                            )}
                            {val?.task_id > 0 && val?.task_status_id == 1 && (
                              <li className="menu-item">
                                <a
                                  class="dropdown-item"
                                  onClick={() => {
                                    setStatusData({
                                      chat_id: val.chat_id,
                                      status_id: 4,
                                    });
                                    toggleProgressModal();
                                  }}
                                >
                                  In Progress
                                </a>
                              </li>
                            )}
                            {val?.task_id > 0 && val?.task_status_id == 1 && (
                              <li className="menu-item">
                                <a
                                  type="button"
                                  class="dropdown-item"
                                  onClick={() => {
                                    setRevisitId(val?.chat_id);
                                    setStatusData({
                                      chat_id: val.chat_id,
                                      status_id: 5,
                                      revisit_date: val.revisit_date
                                        ? val.revisit_date
                                        : moment(Date.now()).format("MM/DD/YYYY"),
                                      revisit_email: true,
                                    });
                                    setRevisit(true);
                                  }}
                                >
                                  Revisit
                                </a>
                              </li>
                            )}
                            {val?.task_id > 0 && val?.task_status_id == 1 && (
                              <li className="menu-item">
                                <a
                                  class="dropdown-item"
                                  onClick={() => {
                                    setStatusData({
                                      chat_id: val.chat_id,
                                      status_id: 3,
                                    });
                                    toggleCompleteModal();
                                  }}
                                >
                                  Complete
                                </a>
                              </li>
                            )}
                          </div>
                        ) : val?.task_id > 0 && val?.task_status_id === 4 ? (
                          <div class="tcbc-right-box">
                            {val.member_id == session.member_id || session.member_id == 1 ? (
                              <li className="menu-item">
                                <a
                                  class="dropdown-item"
                                  onClick={() => {
                                    setTaskId(val?.task_id);
                                    toggleDeleteModal();
                                  }}
                                >
                                  Delete
                                </a>
                              </li>
                            ) : (
                              ""
                            )}
                            {val?.task_id > 0 && val?.task_status_id === 4 ? (
                              <li className="menu-item ">
                                <a class="dropdown-item disabled" style={{ color: "##137c10" }}>
                                  In Progress
                                </a>
                              </li>
                            ) : (
                              ""
                            )}
                            {val?.task_id > 0 && val?.task_status_id === 4 ? (
                              <li className="menu-item">
                                <a
                                  class="dropdown-item"
                                  onClick={() => {
                                    setRevisitId(val?.chat_id);
                                    setRevisit(true);
                                    setStatusData({
                                      chat_id: val?.chat_id,
                                      status_id: 5,
                                      revisit_date: val.revisit_date
                                        ? val.revisit_date
                                        : moment(Date.now()).format("MM/DD/YYYY"),
                                      revisit_email: true,
                                    });
                                  }}
                                >
                                  Revisit
                                </a>
                              </li>
                            ) : (
                              ""
                            )}
                            {val?.task_id > 0 && val?.task_status_id === 4 ? (
                              <li className="menu-item">
                                <a
                                  class="dropdown-item"
                                  onClick={() => {
                                    setStatusData({
                                      chat_id: val.chat_id,
                                      status_id: 3,
                                    });
                                    toggleCompleteModal();
                                  }}
                                >
                                  Complete
                                </a>
                              </li>
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                      </ul>
                    </div>
                  )}
                </div>

                <div
                  class="tcbc-text-row div-comm-notes-sec"
                  dangerouslySetInnerHTML={{ __html: val?.message?.replace(/null/g, "") }}
                />

                <div className="mb-0 mt-5">
                  {val?.chat_id === 0 ? (
                    <>
                      <span style={{ color: "#47AD1D" }}>Unanswered</span>
                      <span
                        className="cursor-pointer"
                        onClick={() => {
                          const id = `${val?.member_id}_${props?.auctionId}`;
                          props.setMessageSection(true);
                          props.setSelectedChat({
                            ...val,
                            buyers_conversation_on_auction_key: id,
                            user_name: val?.name01,
                            auction_id: props?.auctionId,
                            user_email: val?.email,
                            user_phone: val?.phone,
                          });
                          props.toggleMessages("questions");
                        }}
                      >
                        {" "}
                        » Respond
                      </span>
                    </>
                  ) : (
                    ""
                  )}
                </div>
                <div className="d-flex flex-column  ">
                  {val?.date_completed && val?.task_status_id == 1 && (
                    <>
                      <div style={{ marginTop: "6px", color: "#47AD1D" }}>
                        <span>Completed on </span>
                        <DateFormat date={val?.date_completed} />
                      </div>
                    </>
                  )}
                  {val?.date_completed && val?.task_status_id == 4 && (
                    <>
                      <div style={{ marginTop: "6px", color: "#47AD1D" }}>
                        <span>In Progress </span>
                      </div>
                    </>
                  )}

                  {revisitId === val.chat_id && revisit ? (
                    <>
                      <div className="d-flex justify-content-end mt-5 cursor-pointer" onClick={() => setRevisit(false)}>
                        <img src={closeButton} alt="" />
                      </div>
                      <div className="d-flex flex-column ">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            disableHighlightToday={true}
                            className="form-control"
                            value={statusData.revisit_date}
                            format="MM/DD/YYYY"
                            onChange={(e) => {
                              const values = { ...statusData };
                              const formatted = moment(e).format("MM/DD/YYYY");
                              values.revisit_date = formatted;
                              setStatusData(values);
                            }}
                            renderInput={(params) => <TextField {...params} error={false} />}
                            inputFormat="MM/dd/yyyy"
                          />
                        </LocalizationProvider>
                        <div className="d-flex justify-content-between mt-2">
                          <div class="form-check dark-tick-checkbox">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              checked={statusData.revisit_email}
                              id="tick2"
                              onChange={(e) => {
                                const values = { ...statusData };
                                values.revisit_email = e.target.checked;
                                setStatusData(values);
                              }}
                            />
                            <label class="form-check-label" for="tick2"></label>
                            <span>Email Task on revisit Date?</span>
                          </div>
                          <button className="btn btn-default" onClick={toggleRevisitModal}>
                            Save
                          </button>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ))
          )}
        </div>
      </div>
      <DeleteModal
        show={showDeleteModal}
        onHide={toggleDeleteModal}
        loadingDelete={loadingDelete}
        deleteHandler={deleteHandler}
      />
      <CompleteTaskModal
        show={showCompleteModal}
        onHide={toggleCompleteModal}
        loadingDelete={loading}
        submitHandler={handleProcess}
      />
      <InProgressModal
        show={showProgressModal}
        onHide={toggleProgressModal}
        loadingDelete={loading}
        submitHandler={handleProcess}
      />
      <RevisitModal
        show={showRevisitModal}
        onHide={toggleRevisitModal}
        loadingDelete={loading}
        empty={statusData.revisit_date}
        submitHandler={handleProcess}
      />
      {/* <QuestionModal
        getQuestionDetail={props.getQuestionDetail}
        show={showQuestionModal}
        onHide={toggleQuestionsModal}
        question={question}
        setQuestion={setQuestion}
        questionId={questionId}
        setQuestionId={setQuestionId}
        forwardToSeller={forwardToSeller}
        markAnswered={markAnswered}
        sendResponse={sendResponse}
        sendResponseSeller={sendResponseSeller}
        showDeleteModal={showDeleteModal}
        toggleDeleteModal={toggleDeleteModal}
        loadingDelete={loadingDelete}
        deleteHandler={handleDelete}
      /> */}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getPendingTasks: (params, callback) => dispatch(getPendingTasks(params, callback)),
  getCommentsAndNotes: (params, callback) => dispatch(getCommentsAndNotes(params, callback)),
  updateTaskStatus: (params, data, callback) => dispatch(updateTaskStatus(params, data, callback)),
  deleteTask: (params, taskId, callback) => dispatch(deleteTask(params, taskId, callback)),
  getQuestions: (params, callback) => dispatch(getQuestions(params, callback)),
  deleteQuestion: (params, questionId, callback) => dispatch(deleteQuestion(params, questionId, callback)),
  getQuestionDetail: (params, questionId, callback) => dispatch(getQuestionDetail(params, questionId, callback)),
  updateQuestion: (params, data, callback) => dispatch(updateQuestion(params, data, callback)),
  getProductCount: (id, callback) => dispatch(getProductCount(id, callback)),

  //global state for manipulating data
  questionFetched: (data) => dispatch(questionFetched(data)),
});
const mapStateToProps = (state) => ({
  getTasks: getTasks(state),
  questionDetails: questionDetails(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(PendingTasks));
