import moment from "moment";
import "moment-timezone";

export const ConvertTime = (cstDateTime) => {
  if (cstDateTime && cstDateTime?.split(".")?.length > 0) {
    const equivalentCST = cstDateTime?.split(".")?.[0];

    let cstDate = moment.tz(equivalentCST, "America/Chicago");

    let localDate = cstDate.clone().tz(moment.tz.guess());

    let localDateTime = localDate.format("MM-DD-YYYY HH:mm");

    return localDateTime;
  } else {
    return moment().format("MM-DD-YYYY HH:mm");
  }
};

export const ConvertLocalToCST = (timestamp) => {
  // Parse the timestamp as a local date-time
  let localDate = moment.tz(timestamp, moment.tz.guess());

  // Convert to Central Standard Time
  let cstDate = localDate.clone().tz("America/Chicago");

  // Format the date-time correctly
  let cstDateTime = cstDate.format("YYYY-MM-DDTHH:mm:ss.SSS");

  return cstDateTime;
};
