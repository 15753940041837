import React, { useEffect, useState } from "react";
import close from "../../../include/images/close.svg";
import Trash from "../../../include/images/trash-18x18.svg";
import { Modal } from "react-bootstrap";
import DateFormat from "../../../common/DateFormat/dateFormat";
import { twoDecimals } from "../../../utils.js/conversion";
import Select from "react-select";
import { toast } from "react-toastify";
import AlertError from "../../../common/alerts/alertError";
import NextButton from "../../../common/form/nextButton";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import extLink from "../../../include/images/external-link.svg";
import greenEmail from "../../../include/images/green-mail.svg";
import notVerified from "../../../include/images/mail-not-verified.svg";
import invalid from "../../../include/images/mail-invalid.svg";
import spamEmail from "../../../include/images/mail-spammed-blocked.svg";
import purpleEmail from "../../../include/images/mail-purple.svg";
import linkedin from "../../../include/images/Linkedin-logo.svg";
const customStyles = {
  indicatorSeparator: (styles) => ({ display: "none" }),
  option: (provided, state) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "#47ad1d",
      color: "#fff",
    },
    display: "-webkit-box",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    color: state.isSelected ? "#fff" : "black",
    backgroundColor: state.isSelected ? "#47ad1d" : provided.backgroundColor,
    // maxHeight: "84px",
  }),
  control: (base, state) => ({
    ...base,
    // height: 60,
    minHeight: 42,
    borderColor: state.isFocused ? "#47AD1D" : "#e4e4e4",
    boxShadow: state.isFocused ? "0 0 0 0.5px #47AD1D" : "0",
    border: state.isHovered ? "0" : "1px solid #e4e4e4",

    "&:hover": {
      borderColor: state.isFocused ? "#47AD1D" : "0",
      boxShadow: state.isFocused ? "0 0 0 0.5px #47AD1D" : "0",
    },
  }),
  multiValueRemove: (base, state) => ({
    ...base,
    "&:hover": {
      backgroundColor: "rgb(95, 50, 187, 10%)",
      color: "#6119c0",
    },
  }),
};

const MoreModal = (props) => {
  const recipientOptions = props?.recipientList;
  const mediaUrl = process.env.REACT_APP_MEDIA_URL;
  const [recipientValue, setRecipientValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [enterprise, setEnterprise] = useState(false);
  const [additionalData, setAdditionalData] = useState({
    user_id: "props?.userDetailData?.user_id",
    to_id: [],
    message: "",
    send_email: true,
    cc_emails_list: [],
  });
  const [unSubscribe, setunSubscribe] = useState({
    enterprise: props?.userDetailData?.proc_unsubscribe > 0,
    businessUnit: props?.userDetailData?.bu_unsubscribe > 0,
    individual: props?.userDetailData?.unsubscribe > 0,
  });
  useEffect(() => {
    setRecipientValue(null);
    setAdditionalData({
      user_id: props?.userDetailData?.user_id,
      to_id: [],
      message: "",
      send_email: true,
      cc_emails_list: [],
    });
  }, [props.show]);

  const postMessage = () => {
    if (additionalData.message) {
      props.refStart();
      setLoading(true);
      props.addMessage(props?.userDetailData?.user_id, additionalData, (res) => {
        if (res.status === 200) {
          props.refStop();
          setLoading(false);
          toast(<AlertSuccess message={"Information Saved"} />);
          setRecipientValue(null);
          setAdditionalData({
            user_id: props?.userDetailData?.user_id,
            to_id: [],
            message: "",
            send_email: true,
            cc_emails_list: [],
          });
          props.getUserDetail(props.userDetailData.user_id, (res) => {});
        } else {
          props.refStop();
          setLoading(false);
          toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        }
      });
    } else {
      toast(<AlertError message={"Please enter a message."} />);
    }
  };

  const deleteMessage = (chatId) => {
    props.refStart();
    props.deleteMessage(chatId, (res) => {
      if (res.status === 200) {
        props.refStop();
        toast(<AlertSuccess message={"Information Saved"} />);
        props.getUserDetail(props.userDetailData.user_id, (res) => {});
      } else {
        props.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };
  const handleUnsubscribe = (name, id) => {
    let payload;
    if (name === "company_id") {
      setunSubscribe({ ...unSubscribe, enterprise: !unSubscribe.enterprise });
      payload = {
        [name]: id,
        unsubscribe: unSubscribe.enterprise ? 0 : 1,
      };
    } else if (name === "project_id") {
      setunSubscribe({ ...unSubscribe, businessUnit: !unSubscribe.businessUnit });
      payload = {
        [name]: id,
        unsubscribe: unSubscribe.businessUnit ? 0 : 1,
      };
    } else if (name === "user_id") {
      setunSubscribe({ ...unSubscribe, individual: !unSubscribe.individual });
      payload = {
        [name]: id,
        unsubscribe: unSubscribe.individual ? 0 : 1,
      };
    }
    props.changeUnsubscribe(props.userDetailData.user_id, payload, (res) => {});
  };
  return (
    <Modal
      className="modal fade custom-modal"
      id="moreEmailClicker"
      show={props.show}
      onHide={props.onHide}
      dialogClassName="modal-dialog modal-dialog-centered modal-xxl"
    >
      <div class="modal-content d-flex flex-column">
        <div class="custom-modal-header d-flex align-items-center justify-content-between">
          <h5 class="mb-0 text-capitalize">More</h5>
          <button
            type="button"
            class="close-btn d-flex align-items-center justify-content-center"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={props.onHide}
          >
            <img src={close} alt="" />
          </button>
        </div>
        <Modal.Body className="custom-modal-body flex-grow-1 w-100 overflow-hidden pb-0">
          <div class="detail-row-container d-flex flex-wrap">
            <div class="drc-left w-50">
              <div class="page-widget-label text-capitalize">
                <u>Unsubscribe</u>
              </div>
              <table class="table table-borderless align-middle table-left-align">
                <colgroup>
                  <col style={{ width: "10px" }} />
                  <col />
                </colgroup>
                <tbody>
                  {props?.userDetailData?.proc_company_id > 0 && (
                    <tr>
                      <td>
                        <div className="form-check tick-checkbox mb-3">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            name="company_id"
                            value={props?.userDetailData?.proc_company_id}
                            checked={unSubscribe.enterprise}
                            id={`${props?.userDetailData?.user_id}-1`}
                            onChange={(e) => {
                              handleUnsubscribe(e.target.name, props?.userDetailData?.proc_company_id);
                            }}
                          />
                          <label class="form-check-label fw-medium" for={`${props?.userDetailData?.user_id}-1`}></label>
                        </div>
                      </td>
                      <td>Enterprise</td>
                    </tr>
                  )}

                  {props?.userDetailData?.bu_project_id > 0 && (
                    <tr>
                      <td>
                        <div className="form-check tick-checkbox mb-3">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            name="project_id"
                            value={props?.userDetailData?.bu_project_id}
                            checked={unSubscribe.businessUnit}
                            id={`${props?.userDetailData?.user_id}-2`}
                            onChange={(e) => {
                              handleUnsubscribe(e.target.name, props?.userDetailData?.bu_project_id);
                            }}
                          />
                          <label class="form-check-label fw-medium" for={`${props?.userDetailData?.user_id}-2`}></label>
                        </div>
                      </td>
                      <td>Business Unit</td>
                    </tr>
                  )}

                  <tr>
                    <td>
                      <div className="form-check tick-checkbox mb-3">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          name="user_id"
                          value={props?.userDetailData?.user_id}
                          checked={unSubscribe.individual}
                          id={`${props?.userDetailData?.user_id}-3`}
                          onChange={(e) => {
                            handleUnsubscribe(e.target.name, props?.userDetailData?.user_id);
                          }}
                        />
                        <label class="form-check-label fw-medium" for={`${props?.userDetailData?.user_id}-3`}></label>
                      </div>
                    </td>
                    <td>Individual</td>
                  </tr>
                </tbody>
              </table>
              <div class="dividerTable"></div>
              <div class="page-widget-label text-capitalize">
                <u>BD Followup</u>
              </div>
              <div className="mt-3">
                {props?.userDetailData?.bd_followup_date ? (
                  <DateFormat date={props?.userDetailData?.bd_followup_date} />
                ) : (
                  <span>None Specified</span>
                )}
              </div>
              {!enterprise && (
                <div className="mt-1">
                  {props?.userDetailData?.proc_logo && (
                    <div>
                      <div>
                        <a
                          href="javascript:void(0)"
                          target="_blank"
                          onClick={() => {
                            if (props?.userDetailData?.customer_name) {
                              if (process.env.REACT_APP_MODE == "development") {
                                window.open(
                                  `https://admin-dev.salvex.com/admin/_user/_edit.cfm?user_id=${props?.userDetailData?.user_id}`,
                                );
                              } else {
                                window.open(
                                  `https://admin.salvex.com/admin/_user/_edit.cfm?user_id=${props?.userDetailData?.user_id}`,
                                );
                              }
                            }
                          }}
                        >
                          <img
                            src={`${mediaUrl}/co/logos/${props?.userDetailData?.proc_logo}`}
                            // width="150"
                            alt=""
                            style={{ width: "150px" }}
                            // style={{ border: "5px solid #dfdfdf" }}
                          />
                        </a>
                        <br />
                        <br />
                      </div>
                    </div>
                  )}
                </div>
              )}
              <div className="mt-2">
                {props?.userDetailData?.customer_name && (
                  <span>
                    <img src={extLink} alt="External Link" />
                    <a
                      href="javascript:void(0)"
                      className={`green-btn-text ${props?.userDetailData?.customer_name ? "cursor-pointer" : ""}`}
                      onClick={() => {
                        if (props?.userDetailData?.customer_name) {
                          if (process.env.REACT_APP_MODE == "development") {
                            window.open(
                              `https://admin-dev.salvex.com/admin/_user/_edit.cfm?user_id=${props?.userDetailData?.user_id}`,
                            );
                          } else {
                            window.open(
                              `https://admin.salvex.com/admin/_user/_edit.cfm?user_id=${props?.userDetailData?.user_id}`,
                            );
                          }
                        }
                      }}
                    >
                      {props?.userDetailData?.customer_name}
                    </a>
                  </span>
                )}
              </div>
              <div className="mt-1">
                {props?.userDetailData?.linkedin_url && (
                  <div>
                    <a target="_blank" rel="noreferrer" href={`https://${props?.userDetailData?.linkedin_url}`}>
                      <img src={linkedin} width="26" height="26" border="0" alt="LinkedIn Logo" />
                    </a>
                  </div>
                )}
              </div>
              <div className="mt-2">
                {props?.userDetailData?.customer_job_title && <span>{props?.userDetailData?.customer_job_title}</span>}
              </div>
              <div className="mt-2">
                {props?.userDetailData?.customer_company && <span>{props?.userDetailData?.customer_company}</span>}
              </div>
              <div className="mt-2">
                <span>
                  {props?.userDetailData?.customer_city &&
                    `${props.userDetailData.customer_city}${
                      props?.userDetailData?.customer_state && props?.userDetailData?.customer_country
                        ? ", "
                        : props?.userDetailData?.customer_state || props?.userDetailData?.customer_country
                        ? ""
                        : ""
                    }`}
                  {props?.userDetailData?.customer_state &&
                    `${props.userDetailData.customer_state}${props?.userDetailData?.customer_country ? ", " : ""}`}
                  {props?.userDetailData?.customer_country && props.userDetailData.customer_country}
                </span>
              </div>
              <div className="mt-2">
                {props?.userDetailData?.customer_phone && <span>W: {props?.userDetailData?.customer_phone}</span>}
              </div>
              {props?.userDetailData?.customer_cell && (
                <div>
                  <span>M: {props?.userDetailData?.customer_cell}</span>
                </div>
              )}
              <div className="d-flex align-items-center">
                <a href={`mailto:${props?.userDetailData?.customer_email}`} className="me-2">
                  {props?.userDetailData?.customer_email}
                </a>
                <span className="d-text-item">
                  {props?.userDetailData?.emailstatuscode === 0 ? (
                    <img src={greenEmail} alt="" title="Email OK" />
                  ) : props?.userDetailData?.emailstatuscode === 1 ? (
                    <img
                      src={purpleEmail}
                      alt=""
                      title="Email Temporarily Suspended/Unreachable or Undetermined (Soft Bounce)"
                    />
                  ) : props?.userDetailData?.emailstatuscode === 2 ? (
                    <img src={invalid} alt="" title="Email Invalid" />
                  ) : props?.userDetailData?.emailstatuscode === 3 ? (
                    <img src={spamEmail} alt="" title="Email Blocked by Spam or Firewall" />
                  ) : (
                    <img src={notVerified} alt="" title="Email Not Verified" />
                  )}
                </span>
              </div>
              <div class="td-button-group mt-2">
                <img
                  src={`https://us-central1-salvex-mail.cloudfunctions.net/requestRecipientStats?email=${props?.userDetailData?.customer_email}`}
                  alt=""
                />
              </div>

              <div className="mt-3 mb-4">
                {props?.userDetailData?.customer_date_created && (
                  <div>
                    <span>Create On: </span>
                    <span className="fw-medium">
                      <DateFormat date={props?.userDetailData?.customer_date_created} />
                    </span>
                  </div>
                )}
                {props?.userDetailData?.customer_created_by && (
                  <div className="mt-2">
                    <span>Created By: </span>
                    <span className="fw-medium">{props?.userDetailData?.customer_created_by}</span>
                  </div>
                )}
                {props?.userDetailData?.last_login && (
                  <div className="mt-2">
                    <span>Last Login: </span>
                    <span className="fw-medium">
                      <DateFormat date={props?.userDetailData?.last_login} />
                    </span>
                  </div>
                )}
              </div>
              <span className="fw-bold">{props?.userDetailData?.unsubscribe ? <em>Unsubscribed</em> : ""}</span>
              <div class="dividerTable mb-3"></div>
              <button className="btn btn-primary btn-xs-size btn-auto" onClick={props.toggleContactInformationModal}>
                Quick Edit
              </button>
              <div class="dividerTable mt-3"></div>
              {props?.userDetailData?.active_bids?.length > 0 && (
                <ul>
                  {props?.userDetailData?.active_bids?.map((val) => (
                    <li className="mb-4">
                      <div>
                        <a
                          className="link-item"
                          href={`listing-detail?id=${val?.auction_id}`}
                          rel="noreferrer"
                          target="_blank"
                        >
                          {val?.title}
                        </a>
                      </div>
                      <span>
                        {val?.total_bid ? "$" + new Intl.NumberFormat("en-US").format(val?.total_bid) : ""} -{" "}
                        {val?.bid_time ? <DateFormat date={val?.bid_time} /> : ""}
                      </span>
                    </li>
                  ))}
                </ul>
              )}
              {props?.userDetailData?.active_bids?.length > 0 && <div class="dividerTable"></div>}

              {props?.userDetailData?.latest_views?.length > 0 && (
                <ul>
                  {props?.userDetailData?.latest_views?.map((val) => (
                    <li className="mt-4 mb-4">
                      <div>
                        <a
                          className="link-item"
                          href={`listing-detail?id=${val?.auction_id}`}
                          rel="noreferrer"
                          target="_blank"
                        >
                          {val?.title}
                        </a>
                      </div>
                      <span>
                        <DateFormat date={val?.last_view} />
                      </span>
                    </li>
                  ))}
                </ul>
              )}
              {props?.userDetailData?.latest_views?.length > 0 && <div class="dividerTable"></div>}

              {props?.userDetailData?.latest_questions?.length > 0 && (
                <ul>
                  {props?.userDetailData?.latest_questions?.map((val) => (
                    <li className="mt-4 mb-4">
                      <div>
                        <a
                          className="link-item"
                          href={`listing-detail?id=${val?.auction_id}`}
                          rel="noreferrer"
                          target="_blank"
                        >
                          {val?.title}
                        </a>
                      </div>
                      <span>
                        <DateFormat date={val?.date_created} />
                      </span>
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div class="drc-right w-50">
              <div class="page-widget-label text-capitalize">
                <u>Buying Stats</u>
              </div>
              <table class="table table-borderless align-middle table-left-align">
                <colgroup>
                  <col />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <td>Total Bids</td>
                    <td>
                      <div className="fw-medium" style={{ marginTop: "12px" }}>
                        {props?.userDetailData?.total_bids ? props?.userDetailData?.total_bids : "0"}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Profit</td>
                    <td>
                      <div className="fw-medium" style={{ marginTop: "12px" }}>
                        {props?.userDetailData?.profitBuying
                          ? "$" +
                            new Intl.NumberFormat("en-US", {
                              style: "decimal",
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(props?.userDetailData?.profitBuying)
                          : "$0.00"}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Last Bid</td>
                    <td>
                      <div style={{ marginTop: "12px" }}>
                        <a
                          href={`/listing-detail?id=${props?.userDetailData?.lastBid?.auction_id}`}
                          rel="noreferrer"
                          target="_blank"
                        >
                          {props?.userDetailData?.lastBid?.title}{" "}
                        </a>{" "}
                        on <DateFormat date={props?.userDetailData?.lastBid?.date} />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Last purchased</td>
                    <td>
                      <div style={{ marginTop: "12px" }}>
                        <a
                          href={`/listing-detail?id=${props?.userDetailData?.lastPurchase?.auction_id}`}
                          rel="noreferrer"
                          target="_blank"
                        >
                          {props?.userDetailData?.lastPurchase.auction_title}{" "}
                        </a>{" "}
                        on <DateFormat date={props?.userDetailData?.lastPurchase?.date} />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Feedback</td>
                    <td>
                      <div className="fw-medium" style={{ marginTop: "12px" }}>
                        <span className="text-success me-2">
                          {props?.userDetailData?.buyerFeedback?.total !== 0 &&
                          props?.userDetailData?.buyerFeedback?.good !== 0
                            ? Math.round(
                                (props?.userDetailData?.buyerFeedback?.good /
                                  props?.userDetailData?.buyerFeedback?.total) *
                                  100,
                              )
                            : "0"}
                          %
                        </span>
                        <span className="me-2">
                          {props?.userDetailData?.buyerFeedback?.total != 0 &&
                          props?.userDetailData?.buyerFeedback?.neutral !== 0
                            ? Math.round(
                                (props?.userDetailData?.buyerFeedback?.neutral /
                                  props?.userDetailData?.buyerFeedback?.total) *
                                  100,
                              )
                            : "0"}
                          %
                        </span>
                        <span className="text-danger me-2">
                          {props?.userDetailData?.buyerFeedback?.total != 0 &&
                          props?.userDetailData?.buyerFeedback?.bad !== 0
                            ? Math.round(
                                (props?.userDetailData?.buyerFeedback?.bad /
                                  props?.userDetailData?.buyerFeedback?.total) *
                                  100,
                              )
                            : "0"}
                          %
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="dividerTable"></div>
              <div class="page-widget-label text-capitalize">
                <u>Selling Stats</u>
              </div>
              <table class="table table-borderless align-middle table-left-align">
                <colgroup>
                  <col />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <td>Total Profit</td>
                    <td>
                      <div className="fw-medium" style={{ marginTop: "12px" }}>
                        {props?.userDetailData?.profitSelling
                          ? "$" +
                            new Intl.NumberFormat("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(props?.userDetailData?.profitSelling)
                          : "$0.00"}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Average Profit</td>
                    <td>
                      <div className="fw-medium" style={{ marginTop: "12px" }}>
                        {props?.userDetailData?.sellingCounts?.sold === 0
                          ? "0"
                          : (
                              props?.userDetailData?.profitSelling / props?.userDetailData?.sellingCounts?.sold
                            ).toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Success</td>
                    <td>
                      <div className="fw-medium" style={{ marginTop: "12px" }}>
                        {props?.userDetailData?.sellingCounts?.sold ? props?.userDetailData?.sellingCounts?.sold : "0"}{" "}
                        sold /{" "}
                        {props?.userDetailData?.sellingCounts?.all ? props?.userDetailData?.sellingCounts?.all : "0"}{" "}
                        total =
                        {props?.userDetailData?.sellingCounts?.sold && props?.userDetailData?.sellingCounts?.all
                          ? twoDecimals(
                              (props?.userDetailData?.sellingCounts?.sold / props?.userDetailData?.sellingCounts?.all) *
                                100,
                            ) + "%"
                          : "0.00%"}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Pending Listings</td>
                    <td>
                      <div className="fw-medium" style={{ marginTop: "12px" }}>
                        {props?.userDetailData?.sellingCounts?.pending
                          ? props?.userDetailData?.sellingCounts?.pending
                          : "0"}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Active Listings</td>
                    <td>
                      <div className="fw-medium" style={{ marginTop: "12px" }}>
                        {props?.userDetailData?.sellingCounts?.active
                          ? props?.userDetailData?.sellingCounts?.active
                          : "0"}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Closed Listings</td>
                    <td>
                      <div className="fw-medium" style={{ marginTop: "12px" }}>
                        {props?.userDetailData?.sellingCounts?.closed
                          ? props?.userDetailData?.sellingCounts?.closed
                          : "0"}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Invoiced Listings</td>
                    <td>
                      <div className="fw-medium" style={{ marginTop: "12px" }}>
                        {props?.userDetailData?.sellingCounts?.invoiced
                          ? props?.userDetailData?.sellingCounts?.invoiced
                          : "0"}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Archieved Listings</td>
                    <td>
                      <div className="fw-medium" style={{ marginTop: "12px" }}>
                        {props?.userDetailData?.sellingCounts?.archived
                          ? props?.userDetailData?.sellingCounts?.archived
                          : "0"}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="dividerTable"></div>
              {props?.userDetailData?.lastContact?.length > 0 && (
                <ul>
                  {props?.userDetailData?.lastContact?.map((val) => (
                    <li className="mt-4 mb-4">
                      <div className="fw-medium">
                        <DateFormat date={val?.date_contacted} />:
                      </div>
                      <div>
                        Contacted by <span className="fw-medium">{val?.contacted_by}</span>-{val?.notes}
                      </div>
                    </li>
                  ))}
                </ul>
              )}
              <div class="dividerTable"></div>
              <div class="widget-form-widget mb-3">
                <div class="wfw-label-row w-100 d-flex flex-wrap justify-content-between">
                  <div class="wfw-label w-50 pr-3">
                    <div class="wfw-txt-bold fw-medium">Additional Recipients</div>
                  </div>
                </div>
                <div class="form-group-15">
                  {/* <Select
                    className="basic-single"
                    isMulti
                    classNamePrefix="select-search "
                    value={recipientValue}
                    options={recipientOptions}
                    placeholder="Please Select Options"
                    getOptionLabel={(option) => option.name01}
                    getOptionValue={(option) => option.member_id}
                    onChange={(e) => {
                      setRecipientValue(e);
                      const data = { ...additionalData };
                      data.to_id = e?.map((val) => val.member_id);
                      setAdditionalData(data);
                    }}
                    styles={customStyles}
                  /> */}
                  <select
                    name="admin_comments"
                    id="admin_comments"
                    multiple
                    style={{ height: "116px", width: "100%" }}
                    onChange={(e) => {
                      const selectedOptions = Array.from(e.target.selectedOptions);

                      const selectedValues = selectedOptions.map((option) => option.value);

                      setRecipientValue(selectedValues);
                      const data = { ...additionalData, to_id: selectedValues };
                      setAdditionalData(data);
                    }}
                  >
                    {recipientOptions?.map((val) => (
                      <option key={val?.member_id} value={val?.member_id}>
                        {val?.name01}
                      </option>
                    ))}
                  </select>
                </div>
                <div class="form-group-30">
                  <label for="cc" className="form-label fw-medium">
                    CC:
                  </label>
                  <input
                    type="text"
                    placeholder="yourmail@salvex.com"
                    value={additionalData.cc_emails_list}
                    onChange={(e) => {
                      const data = { ...additionalData };
                      data.cc_emails_list = e.target.value.split(" ");
                      setAdditionalData(data);
                    }}
                    name="cc"
                    class="form-control white"
                  />
                </div>
                <div class="form-group-15">
                  <label for="" class="form-label fw-medium">
                    Message
                  </label>
                  <textarea
                    class="form-control white"
                    value={additionalData.message}
                    onChange={(e) => {
                      const data = { ...additionalData };
                      data.message = e.target.value;
                      setAdditionalData(data);
                    }}
                    id="textarea130x130"
                  ></textarea>
                </div>
                <div class="form-checkbox-group-row d-flex flex-wrap">
                  <div class="form-checkbox-item">
                    <div class="form-check dark-tick-checkbox">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        checked={additionalData.send_email}
                        value={additionalData.send_email}
                        onChange={(e) => {
                          const data = { ...additionalData };
                          data.send_email = e.target.checked;
                          setAdditionalData(data);
                        }}
                        id={props?.userDetailData?.user_id}
                      />
                      <label class="form-check-label fw-medium text-nowrap" for={props?.userDetailData?.user_id}>
                        Send Email?
                      </label>
                    </div>
                  </div>
                </div>
                <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                  <NextButton
                    label="Post Message"
                    classData={"btn btn-default"}
                    handleSubmit={postMessage}
                    loading={loading}
                  />
                </div>
              </div>

              {props?.userDetailData?.chat?.map((val) => (
                <div class="text-card-block-column mb-4">
                  <div class="tcbc-header d-flex flex-wrap align-items-center justify-content-between">
                    <div class="tcbc-header-label d-flex flex-column align-items-start">
                      <div class="fw-medium">
                        {val?.sent_name ? `${val?.name01} to ${val?.sent_name}` : val?.name01}
                      </div>
                      <div>{val?.created && <DateFormat date={val?.created} />}</div>
                    </div>
                    <div class="tcbc-right-box">
                      <button
                        type="button"
                        class="delete-button d-flex align-items-center"
                        onClick={() => deleteMessage(val?.chat_id)}
                      >
                        <img src={Trash} alt="" />
                        Delete
                      </button>
                    </div>
                  </div>

                  <div class="tcbc-text-row div-comm-notes-sec" dangerouslySetInnerHTML={{ __html: val?.message }} />
                  <div className="d-flex flex-column">
                    {val?.date_completed && (
                      <>
                        <div style={{ marginTop: "6px", color: "#47AD1D" }}>
                          <span>Completed on </span>
                          <DateFormat date={val?.date_completed} />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>
        <div class="custom-modal-dividor"></div>
        <div class="custom-modal-footer d-grid gap-2 d-md-flex align-items-center justify-content-md-end">
          <button
            class="btn btn-link me-md-2"
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={props.onHide}
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default MoreModal;
