import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";

const slice = createSlice({
  name: "strategicTeam",
  initialState: {
    methodOrigin: {},
    member: {},
    loading: false,
    inspector: {},
    outsideAgent: {},
    recommendedView: {},
    strategicTeam: {},
    optimizer: {},
  },
  reducers: {
    methodOriginRequested: (misc, action) => {
      misc.loading = true;
    },
    methodOriginReceived: (misc, action) => {
      misc.methodOrigin = action.payload;
      misc.loading = false;
    },
    methodOriginRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    memberRequested: (misc, action) => {
      misc.loading = true;
    },
    memberReceived: (misc, action) => {
      misc.member = action.payload;
      misc.loading = false;
    },
    memberRequestFailed: (misc, action) => {
      misc.loading = false;
    },

    inspectorRequested: (misc, action) => {
      misc.loading = true;
    },
    inspectorReceived: (misc, action) => {
      misc.inspector = action.payload;
      misc.loading = false;
    },
    inspectorRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    optimizerRequested: (misc, action) => {
      misc.loading = true;
    },
    optimizerReceived: (misc, action) => {
      misc.optimizer = action.payload;
      misc.loading = false;
    },
    optimizerRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    outsideAgentRequested: (misc, action) => {
      misc.loading = true;
    },
    outsideAgentReceived: (misc, action) => {
      misc.outsideAgent = action.payload;
      misc.loading = false;
    },
    outsideAgentRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    recommendedViewRequested: (misc, action) => {
      misc.loading = true;
    },
    recommendedViewReceived: (misc, action) => {
      misc.recommendedView = action.payload;
      misc.loading = false;
    },
    recommendedViewRequestFailed: (misc, action) => {
      misc.loading = false;
    },

    strategicTeamRequested: (misc, action) => {
      misc.loading = true;
    },
    strategicTeamReceived: (misc, action) => {
      misc.strategicTeam = action.payload;
      misc.loading = false;
    },
    strategicTeamRequestFailed: (misc, action) => {
      misc.loading = false;
    },
  },
});

export const {
  methodOriginRequested,
  methodOriginReceived,
  methodOriginRequestFailed,
  memberRequested,
  memberReceived,
  memberRequestFailed,
  inspectorRequested,
  inspectorReceived,
  inspectorRequestFailed,
  outsideAgentRequested,
  outsideAgentReceived,
  outsideAgentRequestFailed,
  recommendedViewRequested,
  recommendedViewReceived,
  recommendedViewRequestFailed,
  strategicTeamRequested,
  strategicTeamReceived,
  strategicTeamRequestFailed,
  optimizerRequested,
  optimizerReceived,
  optimizerRequestFailed,
} = slice.actions;
export default slice.reducer;

// Action Creators
const baseUrlCommon = "common";
const strategicTeamUrl = "strategicTeam";

export const getMethodOrigin = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrlCommon}/methodOrigin`,
      method: "get",
      callback,
      onStart: methodOriginRequested.type,
      onSuccess: methodOriginReceived.type,
      onError: methodOriginRequestFailed.type,
    })
  );
};

export const getMember = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrlCommon}/member`,
      method: "get",
      callback,
      onStart: memberRequested.type,
      onSuccess: memberReceived.type,
      onError: memberRequestFailed.type,
    })
  );
};

export const getInspector = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrlCommon}/inspector`,
      method: "get",
      callback,
      onStart: inspectorRequested.type,
      onSuccess: inspectorReceived.type,
      onError: inspectorRequestFailed.type,
    })
  );
};

export const getOutsideAgent = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrlCommon}/agent`,
      method: "get",
      callback,
      onStart: outsideAgentRequested.type,
      onSuccess: outsideAgentReceived.type,
      onError: outsideAgentRequestFailed.type,
    })
  );
};

export const getRecommendedView = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrlCommon}/recommendView`,
      method: "get",
      callback,
      onStart: recommendedViewRequested.type,
      onSuccess: recommendedViewReceived.type,
      onError: recommendedViewRequestFailed.type,
    })
  );
};

export const getOptimizer = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrlCommon}/optimizer`,
      method: "get",
      callback,
      onStart: optimizerRequested.type,
      onSuccess: optimizerReceived.type,
      onError: optimizerRequestFailed.type,
    })
  );
};

export const getStrategicTeamDetails = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${strategicTeamUrl}/${data}`,
      method: "get",
      callback,
      onStart: strategicTeamRequested.type,
      onSuccess: strategicTeamReceived.type,
      onError: strategicTeamRequestFailed.type,
    })
  );
};

export const updateStrategicTeamDetails =
  (params, data, callback) => (dispatch) => {
    return dispatch(
      apiCallBegan({
        url: `${strategicTeamUrl}/${params}`,
        method: "PUT",
        data,
        callback,
        onStart: strategicTeamRequested.type,
        onSuccess: strategicTeamReceived.type,
        onError: strategicTeamRequestFailed.type,
      })
    );
  };

export const getStrategicTeam = createSelector(
  (state) => state.entities.strategicTeam,
  (strategicTeam) => strategicTeam
);
