import React, { useEffect, useState } from "react";
import close from "../../../include/images/close.svg";
import { Modal } from "react-bootstrap";
import parse from "html-react-parser";
import NextButton from "../../../common/form/nextButton";
import { connect } from "react-redux";
import { updateReminderQuestion } from "../../../store/questions";

const ReminderMessages = (props) => {
  const readReminders = (id) => {
    props?.updateReminderQuestion({ reminder_ids: [id] }, (res) => {
      if (res && res?.status === 200) {
      }
    });
  };

  return (
    <Modal
      className="modal fade custom-modal"
      id="userContactInformation"
      show={props.show}
      onHide={props.onHide}
      dialogClassName="modal-dialog modal-dialog-centered modal-lg"
    >
      <div class="modal-content h-100 d-flex flex-column">
        <div class="custom-modal-header d-flex align-items-center justify-content-between">
          <h5 class="mb-0 text-capitalize">Reminder Messages</h5>
          <button
            type="button"
            class="close-btn d-flex align-items-center justify-content-center"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={props.onHide}
          >
            <img src={close} alt="" />
          </button>
        </div>
        <Modal.Body class="custom-modal-body flex-grow-1 hide-scrollbar">
          <div className="mb-0 d-flex flex-column  justify-content-start">
            {props?.data?.length > 0
              ? props?.data?.map((fw, i) => (
                  <div className="d-flex flex-column reminder-msg-container mb-3">
                    <p className="reminder-count">Reminder Message {i + 1}:</p>
                    <p> {parse(fw?.message)}</p>
                    <a
                      href={`/listing-detail?id=${fw?.auction_id}`}
                      target="_blank"
                      style={{ color: "black", marginBottom: "10px" }}
                    >
                      {fw?.auction_id} - {fw?.title}
                    </a>
                    <p>
                      {" "}
                      <a
                        className="link-item"
                        href={`/listing-detail?id=${fw?.auction_id}&redirect=${fw?.buyers_conversation_on_auction_key}`}
                        target="_blank"
                        onClick={() => readReminders(fw?.id)}
                      >
                        Click here to respond
                      </a>
                    </p>
                  </div>
                ))
              : ""}
          </div>
        </Modal.Body>
        <div class="custom-modal-dividor"></div>
        <div class="custom-modal-footer d-grid gap-2 d-md-flex align-items-center justify-content-md-end">
          <button
            class="btn btn-link me-md-2"
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={props.onHide}
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateReminderQuestion: (data, callback) => dispatch(updateReminderQuestion(data, callback)),
});

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ReminderMessages);
