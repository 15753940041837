import React, { useEffect, useState } from "react";
import NextButton from "../../common/form/nextButton";
import close from "../../include/images/close.svg";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import AlertError from "../../common/alerts/alertError";

const Editpricing = (props) => {
  const data = props.data;
  const [updateData, setUpdateData] = useState({
    seller_reserve: 0,
    scrap_value: 0,
    seller_bid: 0,
    last_seller_bid: 0,
    price_per_watt: "",
    f_1001: false,
    p_1001: "0",
    f_1002: false,
    p_1002: "0",
    f_1003: false,
    p_1003: "0",
    f_1004: false,
    p_1004: "0",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const value = {};
    value.seller_reserve = data.seller_reserve ? data.seller_reserve : 0;
    value.scrap_value = data.scrap_value ? data.scrap_value : 0;
    value.seller_bid = data.seller_bid ? data.seller_bid : 0;
    value.price_per_watt = data.price_per_watt ? data.price_per_watt : 0;
    value.f_1001 = data?.price?.find((item) => item.pt_id === 1001).feature ? true : false;
    value.p_1001 = data?.price?.find((item) => item.pt_id === 1001).p_value
      ? data?.price?.find((item) => item.pt_id === 1001).p_value.toString()
      : 0;
    value.f_1002 = data?.price?.find((item) => item.pt_id === 1002).feature ? true : false;
    value.p_1002 = data?.price?.find((item) => item.pt_id === 1002).p_value
      ? data?.price?.find((item) => item.pt_id === 1002).p_value.toString()
      : 0;
    value.f_1003 = data?.price?.find((item) => item.pt_id === 1003).feature ? true : false;
    value.p_1003 = data?.price?.find((item) => item.pt_id === 1003).p_value
      ? data?.price?.find((item) => item.pt_id === 1003).p_value.toString()
      : 0;
    value.f_1004 = data?.price?.find((item) => item.pt_id === 1004).feature ? true : false;
    value.p_1004 = data?.price?.find((item) => item.pt_id === 1004).p_value
      ? data?.price?.find((item) => item.pt_id === 1004).p_value.toString()
      : 0;
    setUpdateData(value);
  }, []);

  //Handle Update
  const handleUpdate = () => {
    setLoading(true);
    props.refStart();
    props.updatePricing(props.auctionId, updateData, (res) => {
      if (res.status === 200) {
        props.getAuctionDetails(props.auctionId, (res) => {});
        props.getPricing(props.auctionId, (res) => {
          if (res && res.status == 200) {
            props.refStop();
            setLoading(false);
            props.toggleEditModal();
          } else {
            props.refStop();
            setLoading(false);
            toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
          }
        });
      } else {
        props.refStop();
        setLoading(false);
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  return (
    <Modal
      show={props.show}
      onHide={() => props.toggleEditModal()}
      className="modal fade custom-modal"
      id="editPricing"
      dialogClassName="modal-dialog modal-dialog-centered modal-lg"
    >
      <div class="modal-content d-flex flex-column">
        <div class="custom-modal-header d-flex align-items-center justify-content-between">
          <h5 class="mb-0 text-capitalize">Product Details</h5>
          <button
            type="button"
            class="close-btn d-flex align-items-center justify-content-center"
            onClick={() => props.toggleEditModal()}
          >
            <img src={close} alt="" />
          </button>
        </div>

        <Modal.Body className="custom-modal-body flex-grow-1 w-100 overflow-hidden">
          <div class="two-row-column d-flex flex-wrap">
            <div class="tr-column w-100 position-relative">
              <div class="tr-column-content w-100 h-100 d-flex flex-column">
                {/* Three row table */}
                <table class="table modal-product-detail-table mb-0">
                  <colgroup>
                    <col style={{ width: "32%" }} />
                    <col style={{ width: "43%" }} />
                    <col style={{ width: "25%" }} />
                  </colgroup>
                  <tr>
                    <td>Asking Price</td>
                    <td>
                      <div className="padding-0">
                        <input
                          type="number"
                          className="form-control"
                          value={updateData.p_1001}
                          onChange={(e) => {
                            const val = { ...updateData };
                            val.p_1001 = e.target.value;
                            setUpdateData(val);
                          }}
                        />
                      </div>
                    </td>
                    <td>
                      <div class="form-check tick-checkbox d-flex align-items-center">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="tick1"
                          checked={updateData.f_1001}
                          onChange={(e) => {
                            const val = { ...updateData };
                            val.f_1001 = e.target.checked ? true : false;
                            setUpdateData(val);
                          }}
                        />
                        <label class="form-check-label fw-medium" for="tick1">
                          Feature?
                        </label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Retail Value</td>
                    <td>
                      <div className="padding-0">
                        <input
                          type="number"
                          className="form-control"
                          value={updateData.p_1002}
                          onChange={(e) => {
                            const val = { ...updateData };
                            val.p_1002 = e.target.value;
                            setUpdateData(val);
                          }}
                        />
                      </div>
                    </td>
                    <td>
                      <div class="form-check tick-checkbox d-flex align-items-center">
                        <input class="form-check-input" type="checkbox" value="" id="tick2" />
                        <label
                          class="form-check-label fw-medium"
                          for="tick2"
                          checked={updateData.f_1002}
                          onChange={(e) => {
                            const val = { ...updateData };
                            val.f_1002 = e.target.checked ? true : false;
                            setUpdateData(val);
                          }}
                        >
                          Feature?
                        </label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Original Wholesale Value</td>
                    <td>
                      <div className="padding-0">
                        <input
                          type="number"
                          className="form-control"
                          value={updateData.p_1003}
                          onChange={(e) => {
                            const val = { ...updateData };
                            val.p_1003 = e.target.value;
                            setUpdateData(val);
                          }}
                        />
                      </div>
                    </td>
                    <td>
                      <div class="form-check tick-checkbox d-flex align-items-center">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="tick3"
                          checked={updateData.f_1003}
                          onChange={(e) => {
                            const val = { ...updateData };
                            val.f_1003 = e.target.checked ? true : false;
                            setUpdateData(val);
                          }}
                        />
                        <label class="form-check-label fw-medium" for="tick3">
                          Feature?
                        </label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Claim Value</td>
                    <td>
                      <div className="padding-0">
                        <input
                          type="number"
                          className="form-control"
                          value={updateData.p_1004}
                          onChange={(e) => {
                            const val = { ...updateData };
                            val.p_1004 = e.target.value;
                            setUpdateData(val);
                          }}
                        />
                      </div>
                    </td>
                    <td>
                      <div class="form-check tick-checkbox d-flex align-items-center">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="tick4"
                          checked={updateData.f_1004}
                          onChange={(e) => {
                            const val = { ...updateData };
                            val.f_1004 = e.target.checked ? true : false;
                            setUpdateData(val);
                          }}
                        />
                        <label class="form-check-label fw-medium" for="tick4">
                          Feature?
                        </label>
                      </div>
                    </td>
                  </tr>
                </table>

                <table class="table modal-product-detail-table mb-0">
                  <colgroup>
                    <col style={{ width: "32%" }} />
                    <col />
                  </colgroup>
                  <tr>
                    <td>Seller Asking Price</td>
                    <td>
                      <div className="padding-0">
                        <input
                          type="number"
                          className="form-control"
                          value={updateData.seller_reserve}
                          onChange={(e) => {
                            const val = { ...updateData };
                            val.seller_reserve = e.target.value;
                            setUpdateData(val);
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Recycle Value</td>
                    <td>
                      <div className="padding-0">
                        <input
                          type="number"
                          className="form-control"
                          value={updateData.scrap_value}
                          onChange={(e) => {
                            const val = { ...updateData };
                            val.scrap_value = e.target.value;
                            setUpdateData(val);
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Seller Bid</td>
                    <td>
                      <div className="padding-0">
                        <input
                          type="number"
                          className="form-control"
                          value={updateData.seller_bid}
                          onChange={(e) => {
                            const val = { ...updateData };
                            val.seller_bid = e.target.value;
                            setUpdateData(val);
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </Modal.Body>
        <div class="custom-modal-dividor"></div>
        <div class="custom-modal-footer d-grid gap-2 d-md-flex align-items-center justify-content-md-end">
          <button
            class="btn btn-link global-cancel-button me-md-2"
            type="button"
            onClick={() => props.toggleEditModal()}
          >
            Cancel
          </button>
          <NextButton classData="btn btn-default" handleSubmit={handleUpdate} label={"Save"} loading={loading} />
        </div>
      </div>
    </Modal>
  );
};

export default Editpricing;
