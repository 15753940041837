import React, { useEffect, useState } from "react";
import radioIcon1 from "../../../include/images/add.svg";
import radioIcon2 from "../../../include/images/icon.svg";
import radioIcon3 from "../../../include/images/minus.svg";
import flag0 from "../../../include/images/flag-0.svg";
import tdIcon1 from "../../../include/images/tdIcon1.svg";
import tdIcon3 from "../../../include/images/td-icon-03.svg";
import tdIcon4 from "../../../include/images/td-icon-04.svg";
import tdIcon5 from "../../../include/images/td-icon-05.svg";
import tdIcon6 from "../../../include/images/td-icon-06.svg";
import tdIcon7 from "../../../include/images/td-icon-07.svg";
import tdIcon8 from "../../../include/images/td-icon-08.svg";
import tbrIcon1 from "../../../include/images/tbr-icon-1.svg";
import tbrIcon2 from "../../../include/images/tbr-icon-2.svg";
import tbrIcon3 from "../../../include/images/tbr-icon-3.svg";
import tbrIcon4 from "../../../include/images/tbr-icon-4.svg";
import tbrIcon5 from "../../../include/images/tbr-icon-5.svg";
import trash from "../../../include/images/trash-18x18.svg";
//temp image
import reactivate from "../../../include/images/reactive.png";

import greenEmail from "../../../include/images/green-mail.svg";
import notVerified from "../../../include/images/mail-not-verified.svg";
import invalid from "../../../include/images/mail-invalid.svg";
import spamEmail from "../../../include/images/mail-spammed-blocked.svg";
import purpleEmail from "../../../include/images/mail-purple.svg";

import icon1 from "../../../include/images/icon___1.svg";
import icon2 from "../../../include/images/icon__2.svg";
import icon3 from "../../../include/images/red-hand.png";

import { Link } from "react-router-dom";

import {
  addNewBid,
  bidsData,
  declineBid,
  deleteBid,
  editBid,
  getBidDetail,
  getBids,
  getFeedback,
  getRating,
  searchBuyer,
  updateStatus,
} from "../../../store/bids";
import { connect } from "react-redux";
import DateFormat from "../../../common/DateFormat/dateFormat";
import Skeleton from "react-loading-skeleton";
import ShowBidsModal from "../../../layouts/modals/bids/showBidsModal";
import { toast } from "react-toastify";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import AlertError from "../../../common/alerts/alertError";
import NewBidModal from "../../../layouts/modals/bids/newBidModal";
import DeleteModal from "../../../layouts/modals/bids/deleteModal";
import DeclineModal from "../../../layouts/modals/bids/declineModal";
import FeedbackModal from "../../../layouts/modals/bids/feedbackModal";
import ActivationModal from "../../../layouts/modals/bids/activationModal";
import MaxBidModal from "../../../layouts/modals/bids/maxBidModal";
import { availableFlags } from "../../../utils.js/conversion";
import { getCountries, getStates } from "../../../store/productLocation";
import Rating from "../../../layouts/modals/bids/rating";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { getLatestBids } from "../../../store/latestActivity";
import moment from "moment";
import FindUser from "../../../common/findUser/findUser";

const Bids = (props) => {
  const mode = process.env.REACT_APP_MODE;
  const accessArr = [12210968, 10011993, 10012798, 12230160];
  const session = localStorage.getItem("data");
  const data = JSON.parse(session);
  const accessLevels = data?.accessLevels?.bid_delete_access;
  const [userId, setUserId] = useState("");
  const [superAdmin, setSuperAdmin] = useState(false);
  useEffect(() => {
    setUserId(data.user_id);
  }, []);

  useEffect(() => {
    if (accessArr.includes(userId)) {
      setSuperAdmin(true);
    } else {
      setSuperAdmin(false);
    }
  }, [userId]);

  const bidsList = props && props.bidsData && props.bidsData.bids && props.bidsData.bids.data;
  const top3 = props && props.bidsData && props.bidsData.bids && props.bidsData.bids.top3;
  const lastBid = props && props.bidsData && props.bidsData.bids && props.bidsData.bids.lastBid;
  const rating = props && props.bidsData && props.bidsData.rating;

  const [showBidsModal, setShowBidsModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [bidDetail, setBidDetail] = useState({});
  const [loadingNewBid, setLoadingNewBid] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [bidId, setBidId] = useState("");
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const [loadingDecline, setLoadingDecline] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [showActivationModal, setShowActivationModal] = useState(false);
  const [loadingActivation, setLoadingActivation] = useState(false);
  const [activation, setActivation] = useState("");
  const [showMaxBidModal, setShowMaxBidModal] = useState(false);
  const [loadingMaxBid, setLoadingMaxBid] = useState(false);
  const [feedbackData, setFeedbackData] = useState({
    action: "feedback",
    posted_by: "",
    b_id: "",
    auc_title: "",
    member_id: "",
    auction_id: "",
    buyer_id: "",
    rating: "",
    review: "",
  });
  const [loadingFeedback, setLoadingFeedback] = useState(false);
  const [newBidData, setNewBidData] = useState({
    b_id: -1,
    user_id: process.env.REACT_APP_MODE == "development" ? 12820693 : 10012048,
    unit_bid: "",
    total_bid: "0.00",
    terms: "",
    seller_bid: "",
  });
  const [updateData, setUpdateData] = useState({
    b_id: 0,
    seller_bid: 0,
    buyer_premium: 0,
  });
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [declineData, setDeclineData] = useState({
    decline: 0,
    new_message: "",
  });

  const [showNewBuyer, setShowNewBuyer] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [response, setResponse] = useState(false);
  const [buyerName, setBuyerName] = useState("");
  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [resData, setResData] = useState([]);
  const [buyer, setBuyer] = useState({});
  const [searchData, setSearchData] = useState({
    auction_id: props.auctionId,
    ext_ref: 0,
    outside_agent: 0,
    inspector: 0,
    recommend_id: 0,
    buyer: 1,

    name: "",
    company_name: "",
    city: "",
    st_des: "",
    zip: "",
    c_des: "",
    phone: "",
    fax: "",
    cell: "",
    email: "",
    setObserver: "",
    removeObserver: "",
  });

  const [showRating, setShowRating] = useState(false);
  const [showBids, setShowBids] = useState(false);
  const toggleShowRating = () => {
    setShowRating(!showRating);
  };
  const handleViewRating = (userId) => {
    props.refStart();
    props.getRating(userId, (res) => {
      if (res.status === 200) {
        toggleShowRating();
        props.refStop();
      } else {
        props.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  useEffect(() => {
    setBuyerName("Buyer / Offline Reserve");
  }, []);
  useEffect(() => {
    if (props.section === "bidsRef") {
      setShowBids(true);
    }
  }, [props.section]);

  useEffect(() => {
    if (showNewBuyer) {
      props.getCountries((res) => {
        if (res.status === 200) {
          setCountry(res.data.data);
        }
      });
      props.getStates((res) => {
        if (res.status === 200) {
          setState(res.data.data);
        }
      });
      setResponse(false);
      setResData([]);
      setBuyerName("");
      const data = { ...newBidData };
      data.user_id = 0;
      setNewBidData(data);
      setSearchData({
        auction_id: props.auctionId,
        ext_ref: 0,
        outside_agent: 0,
        inspector: 0,
        recommend_id: 0,
        buyer: 1,

        name: "",
        company_name: "",
        city: "",
        st_des: "",
        zip: "",
        c_des: "",
        phone: "",
        fax: "",
        cell: "",
        email: "",
        setObserver: "",
        removeObserver: "",
      });
    }
  }, [showNewBuyer]);

  const toggleNewBuyer = () => {
    setShowAddModal(!showAddModal);
    setShowNewBuyer(!showNewBuyer);
  };

  const handleNewSearch = () => {
    setResponse(false);
    setResData([]);
    setBuyerName("");
    setSearchData({
      auction_id: props.auctionId,
      ext_ref: 0,
      outside_agent: 0,
      inspector: 0,
      recommend_id: 0,
      buyer: 1,

      name: "",
      company_name: "",
      city: "",
      st_des: "",
      zip: "",
      c_des: "",
      phone: "",
      fax: "",
      cell: "",
      email: "",
      setObserver: "",
      removeObserver: "",
    });
  };

  const handleSelectBuyer = () => {
    setBuyerName(buyer.name);
    const data = { ...newBidData };
    data.user_id = buyer.user_id;
    setNewBidData(data);
    toggleNewBuyer();
  };

  const handleSearch = () => {
    setLoadingSearch(true);
    props.refStart();
    props.searchBuyer(props.auctionId, searchData, (res) => {
      if (res.status === 200) {
        setResData(res.data.data);
        props.refStop();
        setLoadingSearch(false);
        setResponse(true);
      } else {
        setLoadingSearch(false);
        props.refStop();
        setResponse(false);
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const toggleShowBidsModal = (bId) => {
    props.refStart();
    props.getBidDetail(props.auctionId, bId, (res) => {
      if (res.status === 200) {
        const data = res.data.data;
        const d = {};
        setBidDetail(res.data.data);
        d.b_id = bId;
        d.seller_bid = data.seller_bid ? data.seller_bid : 0;
        d.buyer_premium = data.buyer_premium ? data.buyer_premium : 0;
        setUpdateData(d);
        props.refStop();
        setShowBidsModal(!showBidsModal);
      } else {
        props.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const toggleHideShowBidModal = () => {
    setShowBidsModal(!showBidsModal);
  };
  const toggleAddNewModal = () => {
    setNewBidData({
      b_id: -1,
      user_id: process.env.REACT_APP_MODE == "development" ? 12820693 : 10012048,
      unit_bid: "",
      total_bid: "0.00",
      terms: "",
    });
    setBuyerName("Buyer / Offline Reserve");
    setShowAddModal(!showAddModal);
  };
  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };
  const toggleDeclineModal = () => {
    setShowDeclineModal(!showDeclineModal);
  };
  const toggleActivationModal = () => {
    setShowActivationModal(!showActivationModal);
  };
  const toggleMaxBidModal = () => {
    setShowMaxBidModal(!showMaxBidModal);
  };

  //Show Feedback Modal
  const toggleFeedbackModal = (bId) => {
    props.refStart();
    props.getFeedback(props.auctionId, bId, (res) => {
      if (res.status === 200) {
        const data = res.data.data;
        setFeedbackData({
          action: "feedback",
          posted_by: data.posted_by ? data.posted_by : "",
          b_id: data.b_id ? data.b_id : "",
          auc_title: data.auc_title ? data.auc_title : "",
          member_id: data.member_id ? data.member_id : "",
          auction_id: data.auction_id ? data.auction_id : "",
          buyer_id: data.buyer_id ? data.buyer_id : "",
          rating: data.rating ? data.rating : "",
          review: data.review ? data.review : "",
        });
        props.refStop();
        setShowFeedbackModal(!showBidsModal);
      } else {
        props.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };
  //Hide Feedback Modal
  const hideFeedbackModal = () => {
    setShowFeedbackModal(!showFeedbackModal);
  };

  const handleAddNew = () => {
    setLoadingNewBid(true);
    props.refStart();
    props.addNewBid(props.auctionId, newBidData, (res) => {
      if (res.status === 200) {
        props.getBids(props.auctionId, (res) => {});
        props?.getLatestBids(props?.auctionId, (res) => {});
        setLoadingNewBid(false);
        props.refStop();
        toggleAddNewModal();
        toast(<AlertSuccess message={"Information Saved"} />);
      } else {
        setLoadingNewBid(false);
        props.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  //Edit Bid
  const handleUpdate = () => {
    setLoadingUpdate(true);
    props.refStart();
    props.editBid(props.auctionId, updateData, (res) => {
      if (res.status === 200) {
        props.getBids(props.auctionId, (res) => {
          if (res.status === 200) {
            props.refStop();
            props?.getLatestBids(props?.auctionId, (res) => {});
            setLoadingUpdate(false);
            toggleHideShowBidModal();
            toast(<AlertSuccess message={"Information Saved"} />);
          } else {
            props.refStop();
            setLoadingUpdate(false);
            toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
          }
        });
      } else {
        props.refStop();
        setLoadingUpdate(false);
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  //Delete Bid
  const handleDelete = () => {
    setLoadingDelete(true);
    props.refStart();
    props.deleteBid(props.auctionId, bidId, (res) => {
      if (res.status === 200) {
        props.getBids(props.auctionId, (res) => {});
        props?.getLatestBids(props?.auctionId, (res) => {});
        setLoadingDelete(false);
        props.refStop();
        toggleDeleteModal();
        toast(<AlertSuccess message={"Information Saved"} />);
      } else {
        setLoadingDelete(false);
        props.refStop();
        toast(<AlertError message={res.data.message ? res.data.massage : "Something Went Wrong"} />);
      }
    });
  };

  //Handle Decline
  const handleDecline = () => {
    setLoadingDecline(true);
    props.refStart();
    props.declineBid(props.auctionId, declineData, (res) => {
      if (res.status === 200) {
        props.getBids(props.auctionId, (res) => {});
        props?.getLatestBids(props?.auctionId, (res) => {});
        setLoadingDecline(false);
        props.refStop();
        toggleDeclineModal();
        toast(<AlertSuccess message={"Information Saved"} />);
      } else {
        setLoadingDecline(false);
        props.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  //Post Feedback
  const postFeedback = () => {
    setLoadingFeedback(true);
    props.refStart();
    const data = {};
    data.action = feedbackData.action ? feedbackData.action : "feedback";
    data.b_id = feedbackData.b_id ? feedbackData.b_id : "";
    data.rating = feedbackData.rating ? feedbackData.rating : 0;
    data.review = feedbackData.review ? feedbackData.review : "";
    data.member_id = feedbackData.member_id ? feedbackData.member_id : "";
    data.posted_by = feedbackData.posted_by ? feedbackData.posted_by : "";
    props.updateStatus(props.auctionId, data, (res) => {
      if (res.status === 200) {
        setLoadingFeedback(false);
        props.refStop();
        hideFeedbackModal();
        toast(<AlertSuccess message={"Information Saved"} />);
      } else {
        setLoadingFeedback(false);
        props.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  //Handle Activation
  const handleActivation = () => {
    setLoadingActivation(true);
    props.refStart();
    const data = {};
    if (activation) {
      data.action = "reactive";
      data.b_id = bidId;
    } else {
      data.action = "deactive";
      data.b_id = bidId;
    }
    props.updateStatus(props.auctionId, data, (res) => {
      if (res.status === 200) {
        setLoadingActivation(false);
        props.refStop();
        toggleActivationModal();
        props.getBids(props.auctionId, (res) => {});
        props?.getLatestBids(props?.auctionId, (res) => {});
        // toast(<AlertSuccess message={activation ? "Bid Reactivated" : "Bid Deactivated"} />);
        toast(<AlertSuccess message={"Information Saved"} />);
      } else {
        setLoadingActivation(false);
        props.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  //Handle MaxBid
  const handleMaxBid = () => {
    setLoadingMaxBid(true);
    props.refStart();
    const data = {};
    data.action = "max_bid_achieved";
    data.b_id = bidId;
    props.updateStatus(props.auctionId, data, (res) => {
      if (res.status === 200) {
        props.getBids(props.auctionId, (res) => {});
        props?.getLatestBids(props?.auctionId, (res) => {});
        setLoadingMaxBid(false);
        props.refStop();
        toggleMaxBidModal();
        toast(<AlertSuccess message="Information Saved" />);
      } else {
        setLoadingMaxBid(false);
        props.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };
  return (
    <div class="details-page-widget-block" id={props.id}>
      <div class="page-widget-head d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <div class="d-flex align-items-center" onClick={() => (showBids ? toggleAddNewModal() : setShowBids(true))}>
            <div class="page-widget-label text-capitalize  cursor-pointer">
              Bids {bidsList && bidsList?.length > 0 ? `(${bidsList?.length})` : !props.loadingMain && "(0)"}
            </div>
          </div>
          <li
            className="badge-item cursor-pointer"
            onClick={() => setShowBids(!showBids)}
            style={{ marginLeft: "10px" }}
          >
            <div className="badge-box" style={{ background: "#DADEE0" }}>
              {showBids ? "Hide" : "Show"}
            </div>
          </li>
        </div>
        <div class="btn-block">
          {props.loadingMain ? (
            <Skeleton />
          ) : (
            <a href="javascript:void(0)" class="btn btn-primary btn-sm" onClick={toggleAddNewModal}>
              Add New
            </a>
          )}
        </div>
      </div>
      {showBids ? (
        <div class={bidsList && bidsList?.length > 0 && "page-widget-body mt-3"}>
          {!props.loadingMain && lastBid && lastBid?.seller_bid_date && (
            <div class="bids-content-row">
              <p>
                Last bid sent to seller on{" "}
                <b>
                  <DateFormat date={lastBid?.seller_bid_date} />
                </b>{" "}
                in the amount of{" "}
                <b>
                  {lastBid?.seller_bid > 0
                    ? "$" +
                      new Intl.NumberFormat("en-US", {
                        style: "decimal",
                        minimumFractionDigits: 2,
                      }).format(lastBid?.seller_bid) +
                      "."
                    : "$0.00."}
                </b>
              </p>
            </div>
          )}

          {props.loadingMain ? (
            <div class="table-responsive">
              <table class="large-table">
                <colgroup>
                  <col style={{ minWidth: "28px" }} />
                  <col style={{ minWidth: "86px" }} />
                  <col />
                  <col />
                  <col />
                  <col />

                  <col style={{ minWidth: "44px" }} />

                  <col style={{ minWidth: "44px" }} />
                  <col style={{ minWidth: "44px" }} />
                  <col style={{ minWidth: "44px" }} />
                  <col style={{ minWidth: "44px" }} />

                  <col />
                  <col />
                  <col />
                  <col />
                  <col />
                  <col />
                  <col />
                  <col />

                  <col style={{ minWidth: "24px" }} />
                  <col style={{ minWidth: "24px" }} />
                  <col style={{ minWidth: "24px" }} />
                  <col style={{ minWidth: "24px" }} />
                  <col style={{ minWidth: "24px" }} />
                  <col style={{ minWidth: "24px" }} />
                  <col style={{ minWidth: "24px" }} />
                  {accessLevels && <col style={{ minWidth: "24px" }} />}
                </colgroup>
                <thead>
                  <tr>
                    <th>
                      <Skeleton />
                    </th>
                    <th>
                      <Skeleton />
                    </th>
                    <th>
                      <Skeleton />
                    </th>
                    <th>
                      <Skeleton />
                    </th>
                    <th>
                      <Skeleton />
                    </th>
                    <th>
                      <Skeleton />
                    </th>
                    <th>
                      <Skeleton />
                    </th>
                    <th>
                      <Skeleton />
                    </th>
                    <th>
                      <Skeleton />
                    </th>
                    <th>
                      <Skeleton />
                    </th>
                    <th>
                      <Skeleton />
                    </th>
                    <th>
                      <Skeleton />
                    </th>
                    <th>
                      <Skeleton />
                    </th>
                    <th>
                      <Skeleton />
                    </th>
                    <th>
                      <Skeleton />
                    </th>
                    <th>
                      <Skeleton />
                    </th>
                    <th>
                      <Skeleton />
                    </th>
                    <th>
                      <Skeleton />
                    </th>
                    <th>
                      <Skeleton />
                    </th>
                    <th>
                      <Skeleton />
                    </th>
                    <th>
                      <Skeleton />
                    </th>
                    <th>
                      <Skeleton />
                    </th>
                    <th>
                      <Skeleton />
                    </th>
                    <th>
                      <Skeleton />
                    </th>
                    <th>
                      <Skeleton />
                    </th>
                    <th>
                      <Skeleton />
                    </th>
                    <th>
                      <Skeleton />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {[1, 2, 3].map(() => (
                    <tr>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            bidsList?.length > 0 && (
              <div class="table-responsive">
                <table class="large-table">
                  <colgroup>
                    <col style={{ minWidth: "28px" }} />
                    <col style={{ minWidth: "86px" }} />
                    <col />
                    <col />
                    <col />
                    <col />

                    <col style={{ minWidth: "44px" }} />

                    <col style={{ minWidth: "44px" }} />
                    <col style={{ minWidth: "44px" }} />
                    <col style={{ minWidth: "44px" }} />
                    <col style={{ minWidth: "44px" }} />

                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />

                    <col style={{ minWidth: "24px" }} />
                    <col style={{ minWidth: "24px" }} />
                    <col style={{ minWidth: "24px" }} />
                    <col style={{ minWidth: "24px" }} />
                    <col style={{ minWidth: "24px" }} />
                    <col style={{ minWidth: "24px" }} />
                    <col style={{ minWidth: "24px" }} />
                    {accessLevels && <col style={{ minWidth: "24px" }} />}
                  </colgroup>
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th></th>
                      <th>Buyer</th>
                      <th>Company</th>
                      <th>Created By</th>
                      <th>
                        Date
                        <br /> Created
                      </th>
                      <th></th>
                      <th>
                        <img src={radioIcon1} alt="" />
                      </th>
                      <th>
                        <img src={radioIcon2} alt="" />
                      </th>
                      <th>
                        <img src={radioIcon3} alt="" />
                      </th>
                      <th>Date</th>
                      <th>Status</th>
                      <th>
                        Unit
                        <br /> Bid
                      </th>
                      <th>
                        Original
                        <br /> Bid
                      </th>
                      <th>Credit</th>
                      <th>
                        Buyer
                        <br /> Premium
                        <br />
                        {bidsList?.[0]?.exclusive_percentage_buyer > 0 &&
                          props.overridBid &&
                          `(${new Intl.NumberFormat("en-US", {
                            style: "decimal",
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                          }).format(bidsList?.[0]?.exclusive_percentage_buyer * 100)}%)`}
                      </th>
                      <th style={{ color: "#3d77cb" }}>
                        Total
                        <br /> Buyer Bid
                      </th>
                      <th>
                        Submitted / <br /> Seller Bid{" "}
                        {bidsList?.[0]?.exclusive_percentage_seller > 0 &&
                          props.overridBid &&
                          `(${new Intl.NumberFormat("en-US", {
                            style: "decimal",
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                          }).format(bidsList?.[0]?.exclusive_percentage_seller * 100)}%)`}
                      </th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      {accessLevels && <th></th>}
                    </tr>
                  </thead>
                  <tbody>
                    {bidsList.map((val, i) => {
                      return (
                        <tr
                          style={{ cursor: "pointer" }}
                          className={val?.deactive ? "tr-disabled" : top3?.includes(val?.b_id) ? "tr-top3" : ""}
                        >
                          <td onClick={() => toggleShowBidsModal(val?.b_id)}>
                            <span class="fw-medium">{i + 1}.</span>
                          </td>
                          <td onClick={() => val?.max_bid_status !== "maxBid" && toggleShowBidsModal(val?.b_id)}>
                            <div className="d-flex flex-column">
                              {val?.max_bid_status === "maxBid" ? (
                                <button
                                  type="button"
                                  class="btn btn-primary btn-auto btn-xs-size"
                                  onClick={() => {
                                    setBidId(val?.b_id);
                                    toggleMaxBidModal();
                                  }}
                                >
                                  Max Bid?
                                </button>
                              ) : val?.max_bid_status === "MAX BID ACHIEVED" ? (
                                <b>MAX BID ACHIEVED</b>
                              ) : (
                                ""
                              )}
                              <div className="td-icon-item">
                                {val?.dhs_approved && <img src={icon1} alt="" />}

                                {val?.okay && <img src={icon2} alt="" />}

                                {val?.stop_working && <img src={icon3} alt="" width="30px" height="30px" />}
                              </div>
                            </div>
                          </td>
                          <td>
                            <span
                              className="green-btn-text"
                              onClick={() => {
                                if (process.env.REACT_APP_MODE == "development") {
                                  window.open(
                                    `https://admin-dev.salvex.com/admin/_user/_edit.cfm?user_id=${val.user_id}`,
                                  );
                                } else {
                                  window.open(`https://admin.salvex.com/admin/_user/_edit.cfm?user_id=${val.user_id}`);
                                }
                              }}
                            >
                              {val?.name ? val?.name : "N/A"}{" "}
                            </span>
                            {val?.buyer_type ? ` - ${val?.buyer_type}` : ""}
                            <span className="d-block">
                              {val?.customer_ranking ? `Customer Ranking : ${val?.customer_ranking}` : ""}
                            </span>
                          </td>
                          <td onClick={() => toggleShowBidsModal(val?.b_id)}>
                            {val?.company_name ? val?.company_name : "N/A"}
                          </td>
                          <td onClick={() => toggleShowBidsModal(val?.b_id)}>
                            {val?.created_by ? val?.created_by : "N/A"}
                          </td>
                          <td onClick={() => toggleShowBidsModal(val?.b_id)}>
                            {val?.date_created ? <DateFormat date={val?.date_created} /> : "N/A"}
                          </td>

                          <td onClick={() => toggleShowBidsModal(val?.b_id)}>
                            {val?.c_abb && availableFlags(`${val?.c_abb}.gif`) ? (
                              <img
                                class="td-flag-0"
                                style={{ objectFit: "cover" }}
                                src={require(`../../../include/images/flags/${val?.c_abb}.gif`)}
                                alt=""
                              />
                            ) : (
                              ""
                            )}
                          </td>
                          <td onClick={() => handleViewRating(val.user_id)}>
                            <span class="green-btn-text text-decoration-none fw-medium">
                              {val?.CT3 > 0 ? <span>{Math.round((val?.CT2 / val?.CT3) * 100)}%</span> : "0%"}
                            </span>
                          </td>
                          <td onClick={() => handleViewRating(val.user_id)}>
                            <span class="grey-color fw-medium">
                              {val?.CT3 > 0 ? Math.round((val?.CT1 / val?.CT3) * 100) + "%" : "0%"}
                            </span>
                          </td>
                          <td onClick={() => handleViewRating(val.user_id)}>
                            <span class="red-text-label fw-medium">
                              {val?.CT3 > 0 ? Math.round((val?.CT0 / val?.CT3) * 100) + "%" : "0%"}
                            </span>
                          </td>
                          <td onClick={() => toggleShowBidsModal(val?.b_id)}>
                            {val?.bid_time ? <DateFormat date={val?.bid_time} /> : "N/A"}
                          </td>
                          <td onClick={() => toggleShowBidsModal(val?.b_id)}>
                            {val?.bs_id === 1
                              ? "Pending"
                              : val?.bs_id === 2
                              ? "Submitted"
                              : val?.bs_id === 3
                              ? "Accepted"
                              : val?.bs_id === 4
                              ? "Declined"
                              : val?.bs_id === 5
                              ? "Invoiced"
                              : "N/A"}
                          </td>
                          <td onClick={() => toggleShowBidsModal(val?.b_id)}>
                            {val?.unit_bid ? val?.unit_bid : "0.00"}
                          </td>
                          <td onClick={() => toggleShowBidsModal(val?.b_id)}>
                            <span class="fw-medium">
                              {val?.total_bid
                                ? new Intl.NumberFormat("en-US", {
                                    style: "decimal",
                                    maximumFractionDigits: 0,
                                    minimumFractionDigits: 0,
                                  }).format(val?.total_bid)
                                : "0"}
                            </span>
                          </td>
                          <td onClick={() => toggleShowBidsModal(val?.b_id)}>
                            <span class="fw-medium">
                              {val?.user_credit_amount > 0
                                ? "$" +
                                  new Intl.NumberFormat("en-US", {
                                    style: "decimal",
                                    minimumFractionDigits: 2,
                                  }).format(val?.user_credit_amount)
                                : "$0.00"}
                            </span>
                          </td>
                          <td onClick={() => toggleShowBidsModal(val?.b_id)}>
                            {props.overridBid
                              ? (val?.total_bid * val?.exclusive_percentage_buyer).toLocaleString("en-US", {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 0,
                                })
                              : val?.buyer_premium
                              ? val?.buyer_premium?.toLocaleString("en-US", {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 0,
                                })
                              : "0"}
                          </td>
                          <td align="right" onClick={() => toggleShowBidsModal(val?.b_id)}>
                            <span class="fw-medium" style={{ color: "#3d77cb" }}>
                              {new Intl.NumberFormat("en-US", {
                                style: "decimal",
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              }).format(
                                props.overridBid
                                  ? val?.total_bid + val?.total_bid * val?.exclusive_percentage_buyer
                                  : val?.total_bid + val?.buyer_premium,
                              )}
                            </span>
                          </td>
                          <td onClick={() => toggleShowBidsModal(val?.b_id)}>
                            <span class="fw-medium">
                              {props.overridBid
                                ? new Intl.NumberFormat("en-US", {
                                    style: "decimal",
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0,
                                  }).format(val?.total_bid * (1 - val?.exclusive_percentage_seller))
                                : new Intl.NumberFormat("en-US", {
                                    style: "decimal",
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0,
                                  }).format(val?.seller_bid)}
                            </span>
                          </td>
                          <td style={{ minWidth: "24px" }} onClick={() => toggleFeedbackModal(val?.b_id)}>
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip style={{ position: "fixed" }}>
                                  <span>Feedback</span>
                                </Tooltip>
                              }
                            >
                              <img
                                class="td-icon--0"
                                style={{ minWidth: "16px", padding: "2px" }}
                                src={tdIcon1}
                                alt=""
                              />
                            </OverlayTrigger>
                          </td>
                          <td>
                            <a
                              href={
                                mode === "development"
                                  ? `https://admin-dev.salvex.com/admin/_sellers/_mrk_doc_auc/_create.cfm?aucID=${val.auction_id}&s_user_id=${val.user_id}`
                                  : `https://admin.salvex.com/admin/_sellers/_mrk_doc_auc/_create.cfm?aucID=${val.auction_id}&s_user_id=${val.user_id}`
                              }
                              target="_blank"
                            >
                              {val?.emailstatuscode === 0 ? (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip style={{ position: "fixed" }}>
                                      <span>Email OK</span>
                                    </Tooltip>
                                  }
                                >
                                  <img src={greenEmail} alt="" title="Email OK" />
                                </OverlayTrigger>
                              ) : val?.emailstatuscode === 1 ? (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip style={{ position: "fixed" }}>
                                      <span>Email Temporarily Suspended/Unreachable or Undetermined (Soft Bounce)</span>
                                    </Tooltip>
                                  }
                                >
                                  <img src={purpleEmail} alt="" />
                                </OverlayTrigger>
                              ) : val?.emailstatuscode === 2 ? (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip style={{ position: "fixed" }}>
                                      <span>Email Invalid</span>
                                    </Tooltip>
                                  }
                                >
                                  <img src={invalid} alt="" />
                                </OverlayTrigger>
                              ) : val?.emailstatuscode === 3 ? (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip style={{ position: "fixed" }}>
                                      <span>Email Blocked by Spam or Firewall</span>
                                    </Tooltip>
                                  }
                                >
                                  <img src={spamEmail} alt="" />
                                </OverlayTrigger>
                              ) : (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip style={{ position: "fixed" }}>
                                      <span>Email Not Verified</span>
                                    </Tooltip>
                                  }
                                >
                                  <img src={notVerified} alt="" title="" />
                                </OverlayTrigger>
                              )}
                            </a>
                          </td>
                          <td>
                            <a
                              href={
                                mode === "development"
                                  ? `https://admin-dev.salvex.com/listings/reportsAR/index.cfm?sid=${val.sid}`
                                  : `https://admin.salvex.com/listings/reportsAR/index.cfm?sid=${val.sid}`
                              }
                              target="_blank"
                            >
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip style={{ position: "fixed" }}>
                                    <span>Grouped Bid Report</span>
                                  </Tooltip>
                                }
                              >
                                <img class="td-icon--0" src={tdIcon3} alt="" title="" />
                              </OverlayTrigger>
                            </a>
                          </td>
                          <td>
                            <a
                              href={
                                mode === "development"
                                  ? `https://admin-dev.salvex.com/listings/reportsAR/index.cfm?aucID=${val.auction_id}&b_id=${val.b_id}`
                                  : `https://admin.salvex.com/listings/reportsAR/index.cfm?aucID=${val.auction_id}&b_id=${val.b_id}`
                              }
                              target="_blank"
                            >
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip style={{ position: "fixed" }}>
                                    <span>Bid Report</span>
                                  </Tooltip>
                                }
                              >
                                <img class="td-icon--0" src={tdIcon4} alt="" title="Bid Report" />
                              </OverlayTrigger>
                            </a>
                          </td>
                          <td>
                            <Link
                              to={`/invoice/create-invoice?id=${props.auctionId}&b_id=${val.b_id}`}
                              state={{ a: "dk" }}
                              target="_blank"
                            >
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip style={{ position: "fixed" }}>
                                    <span>Invoice Bid</span>
                                  </Tooltip>
                                }
                              >
                                <img class="td-icon--0" src={tdIcon5} alt="" title="" />
                              </OverlayTrigger>
                            </Link>
                          </td>
                          <td>
                            <a
                              href={
                                mode === "development"
                                  ? `https://admin-dev.salvex.com/admin/_listings/views/edit_invoices.cfm?b_id=${val.b_id}&deposit=1`
                                  : `https://admin.salvex.com/admin/_listings/views/edit_invoices.cfm?b_id=${val.b_id}&deposit=1`
                              }
                              target="_blank"
                            >
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip style={{ position: "fixed" }}>
                                    <span>Create Bid Deposit</span>
                                  </Tooltip>
                                }
                              >
                                <img class="td-icon--0" src={tdIcon6} alt="" title="" />
                              </OverlayTrigger>
                            </a>
                          </td>
                          <td
                            onClick={() => {
                              setDeclineData({
                                decline: val?.b_id,
                                new_message: `The offer you have submitted of ${
                                  val?.total_bid > 0
                                    ? "$" +
                                      new Intl.NumberFormat("en-US", {
                                        style: "decimal",
                                        minimumFractionDigits: 2,
                                      }).format(val?.total_bid)
                                    : "$0.00"
                                } has been declined.`,
                              });
                              toggleDeclineModal();
                            }}
                          >
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip style={{ position: "fixed" }}>
                                  <span>
                                    {val?.date_declined
                                      ? `Bid Declined ${moment(val?.date_declined)?.format("MM/DD/YY")} ${val?.name01}`
                                      : "Decline"}
                                  </span>
                                </Tooltip>
                              }
                            >
                              <img class="td-icon--0" title="" src={tdIcon7} alt="" />
                            </OverlayTrigger>
                          </td>
                          <td
                            onClick={() => {
                              setBidId(val?.b_id);
                              val?.deactive ? setActivation(true) : setActivation(false);
                              toggleActivationModal();
                            }}
                          >
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip style={{ position: "fixed" }}>
                                  <span>Deactivate</span>
                                </Tooltip>
                              }
                            >
                              <img class="td-icon--0" src={val?.deactive ? reactivate : tdIcon8} alt="" />
                            </OverlayTrigger>
                          </td>
                          {accessLevels && (
                            <td
                              onClick={() => {
                                setBidId(val?.b_id);
                                toggleDeleteModal();
                              }}
                            >
                              <a href="javascript:void(0)">
                                <img class="td-icon--0" src={trash} alt="" />
                              </a>
                            </td>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )
          )}

          {!props.loadingMain && bidsList?.length > 0 && (
            <div class="table-bottom-row">
              <div class="tbr-item-list d-flex flex-wrap">
                <div class="tbr-item-box">
                  <div class="w-100 h-100 d-flex flex-wrap">
                    <div class="tbr-item-img">
                      <img src={tdIcon3} alt="" />
                    </div>
                    <div class="tbr-item-txt">Grouped Bid Report</div>
                  </div>
                </div>
                <div class="tbr-item-box">
                  <div class="w-100 h-100 d-flex flex-wrap">
                    <div class="tbr-item-img">
                      <img src={tdIcon4} alt="" />
                    </div>
                    <div class="tbr-item-txt">Bid Report</div>
                  </div>
                </div>
                <div class="tbr-item-box">
                  <div class="w-100 h-100 d-flex flex-wrap">
                    <div class="tbr-item-img">
                      <img src={tdIcon5} alt="" />
                    </div>
                    <div class="tbr-item-txt">Invoice Bid</div>
                  </div>
                </div>
                <div class="tbr-item-box">
                  <div class="w-100 h-100 d-flex flex-wrap">
                    <div class="tbr-item-img">
                      <img src={tdIcon6} alt="" />
                    </div>
                    <div class="tbr-item-txt">Create Bid Deposit</div>
                  </div>
                </div>
                <div class="tbr-item-box">
                  <div class="w-100 h-100 d-flex flex-wrap">
                    <div class="tbr-item-img">
                      <img src={tdIcon7} alt="" />
                    </div>
                    <div class="tbr-item-txt">Decline</div>
                  </div>
                </div>
                <div class="tbr-item-box">
                  <div class="w-100 h-100 d-flex flex-wrap">
                    <div class="tbr-item-img">
                      <img src={tdIcon8} alt="" />
                    </div>
                    <div class="tbr-item-txt">Deactivate</div>
                  </div>
                </div>
                <div class="tbr-item-box">
                  <div class="w-100 h-100 d-flex flex-wrap">
                    <div class="tbr-item-img">
                      <img src={reactivate} alt="" />
                    </div>
                    <div class="tbr-item-txt">Reactivate</div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        ""
      )}
      <ShowBidsModal
        show={showBidsModal}
        onHide={toggleHideShowBidModal}
        bidDetail={bidDetail}
        setBidDetail={setBidDetail}
        updateData={updateData}
        setUpdateData={setUpdateData}
        handleUpdate={handleUpdate}
        loading={loadingUpdate}
      />

      <FindUser
        title={"Buyer"}
        show={showNewBuyer}
        onHide={toggleNewBuyer}
        searchData={searchData}
        setSearchData={setSearchData}
        handleSubmit={handleSearch}
        loading={loadingSearch}
        response={response}
        resData={resData}
        country={country}
        state={state}
        handleNewSearch={handleNewSearch}
        setUser={setBuyer}
        handleSelectUser={handleSelectBuyer}
        buyer={true}
      />

      <NewBidModal
        show={showAddModal}
        auctionId={props.auctionId}
        refStart={props.refStart}
        refStop={props.refStop}
        onHide={toggleAddNewModal}
        newBidData={newBidData}
        setNewBidData={setNewBidData}
        handleAddNew={handleAddNew}
        loading={loadingNewBid}
        searchBuyer={props.searchBuyer}
        getCountries={props.getCountries}
        getStates={props.getStates}
        toggleNewBuyer={toggleNewBuyer}
        buyerName={buyerName}
      />
      <DeleteModal
        show={showDeleteModal}
        onHide={toggleDeleteModal}
        loadingDelete={loadingDelete}
        deleteHandler={handleDelete}
      />
      <DeclineModal
        show={showDeclineModal}
        onHide={toggleDeclineModal}
        declineData={declineData}
        setDeclineData={setDeclineData}
        submitHandler={handleDecline}
        loading={loadingDecline}
      />
      <FeedbackModal
        show={showFeedbackModal}
        onHide={hideFeedbackModal}
        feedbackData={feedbackData}
        setFeedbackData={setFeedbackData}
        loading={loadingFeedback}
        postFeedback={postFeedback}
      />
      <ActivationModal
        show={showActivationModal}
        onHide={toggleActivationModal}
        activation={activation}
        submitHandler={handleActivation}
        loading={loadingActivation}
      />
      <MaxBidModal
        show={showMaxBidModal}
        onHide={toggleMaxBidModal}
        submitHandler={handleMaxBid}
        loading={loadingMaxBid}
      />
      <Rating show={showRating} onHide={toggleShowRating} rating={rating} />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getBids: (params, callback) => dispatch(getBids(params, callback)),
  getBidDetail: (params, bId, callback) => dispatch(getBidDetail(params, bId, callback)),
  addNewBid: (params, data, callback) => dispatch(addNewBid(params, data, callback)),
  editBid: (params, data, callback) => dispatch(editBid(params, data, callback)),
  deleteBid: (params, bidId, callback) => dispatch(deleteBid(params, bidId, callback)),
  declineBid: (params, data, callback) => dispatch(declineBid(params, data, callback)),
  getFeedback: (params, bidId, callback) => dispatch(getFeedback(params, bidId, callback)),
  updateStatus: (params, data, callback) => dispatch(updateStatus(params, data, callback)),
  searchBuyer: (params, data, callback) => dispatch(searchBuyer(params, data, callback)),
  getRating: (userId, callback) => dispatch(getRating(userId, callback)),
  getCountries: (callback) => dispatch(getCountries(callback)),
  getStates: (callback) => dispatch(getStates(callback)),
  getLatestBids: (params, callback) => dispatch(getLatestBids(params, callback)),
});

const mapStateToProps = (state) => ({
  bidsData: bidsData(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Bids));
