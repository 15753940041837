import React from "react";

const PaymentTypeOne = ({
  setError1,
  error1,
  option1Data,
  setOption1Data,
  member,
}) => {
  return (
    <>
      {" "}
      <p>
        <strong>ACH/EFT (U.S. Only)</strong>
      </p>
      <div class="form-group payment-info-input-margin">
        <label for="" class="form-label">
          Company or Individual Name (as is appears on the account)
          <sup style={{ color: "red" }}>*</sup>
        </label>
        <input
          type="text"
          class="form-control "
          value={option1Data.account_name}
          onChange={(e) => {
            const error = { ...error1 };
            if (error.account_name) {
              error.account_name = "";
              setError1(error);
            }

            const data = { ...option1Data };
            data.account_name = e.target.value.trim();
            setOption1Data(data);
          }}
        />
        {error1.account_name ? (
          <p style={{ color: "red" }}>{error1.account_name}</p>
        ) : (
          ""
        )}
      </div>
      <div class="form-group payment-info-input-margin">
        <label for="" class="form-label">
          Complete Address associated with account{" "}
          <sup style={{ color: "red" }}>*</sup>
        </label>
        <input
          type="text"
          class="form-control "
          value={option1Data.account_address}
          onChange={(e) => {
            const error = { ...error1 };
            if (error.account_address) {
              error.account_address = "";
              setError1(error);
            }

            const data = { ...option1Data };
            data.account_address = e.target.value.trim();
            setOption1Data(data);
          }}
        />
        {error1.account_address ? (
          <p style={{ color: "red" }}>{error1.account_address}</p>
        ) : (
          ""
        )}
      </div>
      <div class="form-group payment-info-input-margin">
        <label for="" class="form-label">
          Name of Bank <sup style={{ color: "red" }}>*</sup>
        </label>
        <input
          type="text"
          class="form-control "
          value={option1Data.bank_name}
          onChange={(e) => {
            const error = { ...error1 };
            if (error.bank_name) {
              error.bank_name = "";
              setError1(error);
            }

            const data = { ...option1Data };
            data.bank_name = e.target.value.trim();
            setOption1Data(data);
          }}
        />
        {error1.bank_name ? (
          <p style={{ color: "red" }}>{error1.bank_name}</p>
        ) : (
          ""
        )}
      </div>
      <div class="form-group payment-info-input-margin">
        <label for="" class="form-label">
          Account Number <sup style={{ color: "red" }}>*</sup>
        </label>
        <input
          type="number"
          class="form-control "
          value={option1Data.account_iban_no}
          onChange={(e) => {
            const error = { ...error1 };
            if (error.account_iban_no) {
              error.account_iban_no = "";
              setError1(error);
            }

            const data = { ...option1Data };
            data.account_iban_no = e.target.value.trim();
            setOption1Data(data);
          }}
        />
        {error1.account_iban_no ? (
          <p style={{ color: "red" }}>{error1.account_iban_no}</p>
        ) : (
          ""
        )}
      </div>
      <div class="form-group mt-0 payment-info-input-margin">
        <label for="" class="form-label">
          ACH Routing Number <sup style={{ color: "red" }}>*</sup>
        </label>
        <input
          type="number"
          class="form-control "
          value={option1Data.ach_routing_no}
          onChange={(e) => {
            const error = { ...error1 };
            if (error.ach_routing_no) {
              error.ach_routing_no = "";
              setError1(error);
            }
            const data = { ...option1Data };
            data.ach_routing_no = e.target.value.trim();
            setOption1Data(data);
          }}
        />
        {error1.ach_routing_no ? (
          <p style={{ color: "red" }}>{error1.ach_routing_no}</p>
        ) : (
          ""
        )}
      </div>
      <div class="form-group mt-0 payment-info-input-margin">
        <label for="" class="form-label">
          Additional email address to be sent confirmation of payment.
        </label>
        <textarea
          type="text"
          class="w-100 form-control "
          value={option1Data.other_email}
          onChange={(e) => {
            const data = { ...option1Data };
            data.other_email = e.target.value.trim();
            setOption1Data(data);
          }}
        />
      </div>
      <div class="form-group mt-0 payment-info-input-margin">
        <label for="" class="form-label">
          Do you have any additional instructions?
        </label>
        <textarea
          type="text"
          class="w-100 form-control "
          value={option1Data.additional_info}
          onChange={(e) => {
            const data = { ...option1Data };
            data.additional_info = e.target.value.trim();
            setOption1Data(data);
          }}
        />
      </div>
    </>
  );
};

export default PaymentTypeOne;