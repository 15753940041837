import React, { useEffect, useRef, useState } from "react";
import Header from "../../layouts/header";
import { connect } from "react-redux";
import LoadingBar from "react-top-loading-bar";
import { toast } from "react-toastify";
import AlertError from "../../common/alerts/alertError";
import { getAviationDetails, getPart } from "../../store/aviation";
import LogTable from "../../components/aviation/logs/logTable";
import ViewDetails from "../../components/listingDetail/aviationParts/viewDetails";
import ImageLargeModal from "../../layouts/modals/imageGalleryModal";
import { getLogById, getLogs, getPartsDetails } from "../../store/parts";
import LogModal from "../../components/parts/modals/logModal";
import ViewPartDetails from "../../components/parts/modals/viewDetails";

const PartLogs = (props) => {
  const ref = useRef(null);
  const [loading, setLoading] = useState(false);

  //params state
  const [page, setPage] = useState(1);

  const [applyFilter, setApplyFilter] = useState(false);
  //filter
  const [showFilter, setShowFilter] = useState(false);
  const [filteredValues, setFilteredValues] = useState({
    userId: "",
    genericPart: "",
    keyword: "",
  });
  const [response, setResponse] = useState({
    res: false,
    keyword: "",
  });
  const [sort, setSort] = useState("createdAt");
  const [order, setOrder] = useState("desc");
  const [showLogModal, setShowLogModal] = useState(false);
  const [tableData, setTableData] = useState([]);

  const toggleLogModal = () => {
    setShowLogModal(!showLogModal);
  };

  //   const part = props.getPartsDetails?.part?.data?.map((item) => ({
  //     name: item.title,
  //     value: item._id,
  //   }));
  const [viewModal, setViewModal] = useState(false);
  const [selectedValue, setSelectedValue] = useState({});

  const toggleViewModal = () => {
    setViewModal(!viewModal);
    setShowLogModal(!showLogModal);
  };
  const toggleFilter = () => {
    setFilteredValues({
      userId: "",
      genericPart: "",
      keyword: response?.keyword,
    });
    setShowFilter(!showFilter);
  };

  useEffect(() => {
    ref.current.continuousStart();
    setLoading(true);
    const params = {
      page: 1,
      sort,
      order,
    };
    props.getLogs(params, (res) => {
      if (res && res.status === 200) {
        ref.current.complete();
        setLoading(false);
      } else {
        ref.current.complete();
        setLoading(false);
      }
    });
  }, []);

  useEffect(() => {
    props.getParts({}, () => {});
  }, []);

  useEffect(() => {
    ref.current.continuousStart();
    setLoading(true);
    const params = {
      page: page,
      genericPart: filteredValues?.genericPart ? filteredValues.genericPart?.value : "",
      keyword: response?.keyword ? response.keyword : "",
      sort,
      order,
    };
    props.getLogs(params, (res) => {
      if (res && res.status === 200) {
        ref.current.complete();
        setLoading(false);
      } else {
        ref.current.complete();
        setLoading(false);
      }
    });
  }, [page]);

  const getSortedData = (sortBy, orderBy) => {
    ref.current.continuousStart();
    setSort(sortBy);
    setOrder(orderBy);
    setLoading(true);
    const params = {
      page: 1,
      genericPart: filteredValues?.genericPart ? filteredValues.genericPart?.value : "",
      keyword: response?.keyword ? response.keyword : "",
      sort,
      order,
    };
    setPage(1);
    props.getLogs(params, (res) => {
      if (res && res.status === 200) {
        ref.current.complete();
        setLoading(false);
      } else {
        ref.current.complete();
        setLoading(false);
      }
    });
  };

  const handleViewLogs = (id) => {
    ref.current.continuousStart();
    props?.getLogsById(id, (res) => {
      if (res.status === 200) {
        toggleLogModal();
        ref.current.complete();
      } else {
        ref.current.complete();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const logData = props.getPartsDetails && props.getPartsDetails.logs && props.getPartsDetails.logs.data;
  const logsById = props?.getPartsDetails?.log?.data;

  const totalCount =
    props.getPartsDetails && props.getPartsDetails.logs && props.getPartsDetails.logs?.pagination?.totalCount;

  const filteredCount =
    props.getPartsDetails && props.getPartsDetails.logs && props.getPartsDetails.logs.pagination?.filteredCount;

  const totalPages = Math.ceil(totalCount / 20);

  const handleFilter = () => {
    ref.current.continuousStart();
    setLoading(true);
    const params = {
      page: 1,
      genericPart: filteredValues?.genericPart ? filteredValues.genericPart?.value : "",
      keyword: filteredValues?.keyword ? filteredValues.keyword : "",
      userId: filteredValues?.userId ? filteredValues.userId : "",
    };

    if (params?.genericPart || params?.keyword || params?.userId) {
      setApplyFilter(true);
    }
    setPage(1);
    props.getLogs(params, (res) => {
      if (res && res.status === 200) {
        setResponse({
          res: true,
          keyword: filteredValues?.keyword ? filteredValues?.keyword : "",
        });
        if (showFilter) {
          toggleFilter();
        }

        ref.current.complete();
        setLoading(false);
      } else {
        ref.current.complete();
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    const data = { ...response };
    data.res = true;
    setResponse(data);
    if (!showFilter && applyFilter && !response?.res) {
      if (!response?.genericPart?.value && !response?.keyword && !response?.userId) {
        setApplyFilter(false);
      }
      ref.current.continuousStart();
      setLoading(true);
      const params = {
        page: 1,
        genericPart: filteredValues?.genericPart ? filteredValues.genericPart?.value : "",
        keyword: response?.keyword ? response.keyword : "",
        userId: filteredValues?.userId ? filteredValues.userId : "",
      };

      if (params?.genericPart || params?.keyword || params?.userId) {
        setApplyFilter(true);
      }
      setPage(1);
      props.getLogs(params, (res) => {
        if (res && res.status === 200) {
          if (showFilter) {
            toggleFilter();
          }

          ref.current.complete();
          setLoading(false);
        } else {
          ref.current.complete();
          setLoading(false);
        }
      });
    }
  }, [response?.res]);

  const [selectedImage, setSelecetedImage] = useState([]);
  const [imageGalleryModal, setImageGalleryModal] = useState(false);

  const toggleImageGalleryModal = () => {
    toggleLogModal();
    setImageGalleryModal(!imageGalleryModal);
  };
  const checkIfImageExists = (url, callback) => {
    const img = new Image();
    img.src = url;

    if (img.complete) {
      callback(true);
    } else {
      img.onload = () => {
        callback(true);
      };

      img.onerror = () => {
        callback(false);
      };
    }
  };

  return (
    <>
      <LoadingBar height={5} color="#47AD1D" ref={ref} />
      <Header />
      <LogTable
        loading={loading}
        logData={logData}
        filteredCount={filteredCount}
        totalCount={totalCount}
        totalPages={totalPages}
        page={page}
        setPage={setPage}
        toggleFilter={toggleFilter}
        filteredValues={filteredValues}
        setFilteredValues={setFilteredValues}
        showFilter={showFilter}
        handleFilter={handleFilter}
        applyFilter={applyFilter}
        part={true}
        setSelectedValue={setSelectedValue}
        toggleViewModal={toggleViewModal}
        sort={sort}
        order={order}
        setSort={setSort}
        setOrder={setOrder}
        getSortedData={getSortedData}
        toggleLogModal={toggleLogModal}
        setTableData={setTableData}
        handleViewLogs={handleViewLogs}
        response={response}
        setResponse={setResponse}
      />
      <LogModal
        show={showLogModal}
        onHide={toggleLogModal}
        loading={loading}
        tableData={tableData}
        data={logsById}
        viewAllModal={viewModal}
        toggleViewAllModal={toggleViewModal}
        setSelectedValue={setSelectedValue}
        log={true}
        imageGalleryModal={imageGalleryModal}
        setImageGalleryModal={setImageGalleryModal}
        selectedImage={selectedImage}
        setSelecetedImage={setSelecetedImage}
        toggleImageGalleryModal={toggleImageGalleryModal}
        checkIfImageExists={checkIfImageExists}
      />
      <ImageLargeModal
        show={imageGalleryModal}
        onHide={toggleImageGalleryModal}
        items={selectedImage}
        startIndex={0}
        title={""}
      />
      <ViewPartDetails show={viewModal} onHide={toggleViewModal} selectedValue={selectedValue?.genericPart} />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getLogs: (params, callback) => dispatch(getLogs(params, callback)),
  getLogsById: (id, callback) => dispatch(getLogById(id, callback)),
  getParts: (params, callback) => dispatch(getPart(params, callback)),
});

const mapStateToProps = (state) => ({
  getPartsDetails: getPartsDetails(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(PartLogs));
