import React from "react";
import { Modal } from "react-bootstrap";
import NextButton from "../../../common/form/nextButton";
import close from "../../../include/images/close.svg";
import trash from "../../../include/images/trash-20x20.svg";

const ExpenseAllocationModal = (props) => {
  return (
    <Modal
      className="modal fade custom-modal"
      show={props.show}
      onHide={() => {
        props.onHide();
      }}
      dialogClassName="modal-dialog modal-dialog-centered modal-xxl"
    >
      <div class="modal-content d-flex flex-column">
        <div class="custom-modal-header d-flex align-items-center justify-content-between">
          <h5 class="mb-0 text-capitalize">Expense Allocation</h5>
          <button
            type="button"
            class="close-btn d-flex align-items-center justify-content-center"
            onClick={() => {
              props.onHide();
            }}
          >
            <img src={close} alt="" />
          </button>
        </div>
        <div class="comparable-price-body">
          <div class="table-responsive table-custom">
            <table class="table table-bordered">
              <colgroup>
                <col />
                <col />
                <col style={{ width: "4%" }} />
              </colgroup>
              <thead>
                <tr>
                  <td>Expense</td>
                  <td>Amount</td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                {props.expenseData && props.expenseData.length > 0 ? (
                  props.expenseData.map((item, i) => (
                    <tr>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          value={item.title ? item.title : ""}
                          onChange={(e) => {
                            const value = [...props.expenseData];
                            const data = { ...item };
                            data.title = e.target.value;
                            value[i] = data;
                            props.setExpenseData(value);
                          }}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control"
                          value={item && item.amount ? item.amount : ""}
                          onChange={(e) => {
                            const value = [...props.expenseData];
                            const data = { ...item };
                            data.amount = e.target.value;
                            value[i] = data;
                            props.setExpenseData(value);
                          }}
                        />
                      </td>
                      <td>
                        <img
                          className="m-1 mt-2 cursor-pointer"
                          src={trash}
                          alt=""
                          onClick={() => {
                            props?.handleDeleteExpense(item.id);
                            const data = [...props?.expenseData];
                            const remData = data.filter((_, index) => index !== i);
                            props.setExpenseData(remData);
                          }}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5">No Records Found</td>
                  </tr>
                )}
                {props?.expenseData?.length > 0 && (
                  <tr>
                    <td align="right">Total</td>
                    <td colSpan={2} align="right">
                      <span className="fw-medium">
                        {"$" +
                          new Intl.NumberFormat("en-US", {
                            style: "decimal",
                            minimumFractionDigits: 2,
                          }).format(props?.expenseData?.reduce((acc, exp) => acc + Number(exp?.amount), 0))}
                      </span>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <Modal.Body className="custom-modal-body flex-grow-1 w-100 overflow-hidden"></Modal.Body>
        <div class="custom-modal-dividor"></div>
        <div class="custom-modal-footer d-grid gap-2 d-md-flex align-items-center justify-content-md-end">
          <button
            class="btn btn-link me-md-2 global-cancel-button"
            type="button"
            onClick={() => {
              props.onHide();
            }}
          >
            Cancel
          </button>
          <button
            class="btn btn-default"
            type="button"
            onClick={() => {
              const data = [...props.expenseData];
              const value = {
                id: "0",
                title: "",
                amount: 0,
              };
              props?.setExpenseData([...data, value]);
            }}
          >
            Add New
          </button>
          <NextButton
            classData="btn btn-default"
            handleSubmit={() => props.saveHandler()}
            label={"Save"}
            loading={props.loadingSave}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ExpenseAllocationModal;
