import React, { useEffect, useRef, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import Trash from "../../../include/images/trash-24x24.svg";
import Pdf from "../../../include/images/pdf-file-icon.svg";
import csvFile from "../../../include/images/csv-latest.svg";
import XLSX from "../../../include/images/XLSX.svg";
import XLS from "../../../include/images/XLS.svg";
import DOCX from "../../../include/images/Docx.svg";
import DOC from "../../../include/images/Doc.svg";
import PNG from "../../../include/images/PNG.svg";
import JPG from "../../../include/images/JPG.svg";
import JPEG from "../../../include/images/JPEG.svg";
import Close from "../../../include/images/close-icon.svg";
import close from "../../../include/images/close-12x12.svg";
import crossIcon from "../../../include/images/cross-icon.svg";
import emptyChat from "../../../include/images/chat-empty.svg";
import { getProfile } from "../../../utils.js/localStorageServices";
import { connect } from "react-redux";
import { getUser, getVerificationDoc, uploadVerificationDocs } from "../../../store/users";
import Skeleton from "react-loading-skeleton";
import LoadingBar from "react-top-loading-bar";
import { toast } from "react-toastify";
import AlertError from "../../../common/alerts/alertError";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import { Amplify, Storage } from "aws-amplify";
import { Auth } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    region: "us-east-1",
  },
  Storage: {
    bucket: process.env.REACT_APP_S3_UPLOAD_BUCKET,
    region: "us-east-1",
  },
});

const VerifyDocModal = (props) => {
  const ref = useRef();
  const [docArray, setDocArray] = useState({});
  const [error, setError] = useState({});
  const [loadingUpload, setLoadingUpload] = useState(false);

  useEffect(() => {
    setDocArray({});
  }, [props?.show]);

  const verificationData = props && props.verificationDoc;

  const idetificationProofArray = verificationData?.identification_proof?.split(".");
  const indetificatinProofType =
    idetificationProofArray && idetificationProofArray[idetificationProofArray?.length - 1];

  const companyRegistrationProofArray = verificationData?.company_registration_proof?.split(".");
  const companyRegistrationProofType =
    companyRegistrationProofArray && companyRegistrationProofArray[companyRegistrationProofArray?.length - 1];

  const companyTaxIdetificationProofArray = verificationData?.company_tax_identification_proof?.split(".");
  const companyTaxIdetificationProofType =
    companyTaxIdetificationProofArray &&
    companyTaxIdetificationProofArray[companyTaxIdetificationProofArray?.length - 1];

  const validateError = () => {
    const errors = {};
    if (props?.isCompany && !docArray?.registartion_proof?.name && !docArray?.tax_identification_proof?.name) {
      if (!docArray?.registartion_proof?.name) {
        errors.registartion_proof = "Company Registration Proof is required";
      }
      if (!docArray?.tax_identification_proof?.name) {
        errors.tax_identification_proof = "Company Tax Identification Proof is required";
      }
    } else if (!props?.isCompany && !docArray?.identification_proof?.name) {
      errors.identification_proof = "Identification Proof is required";
    }
    setError(errors);
    const isValid =
      errors?.tax_identification_proof || errors?.registartion_proof || errors?.identification_proof ? false : true;
    return isValid;
  };

  const handleSubmitDocs = () => {
    if (!validateError()) return;

    if (props?.isCompany) {
      setLoadingUpload(true);
      ref?.current?.continuousStart();
      const file = docArray?.registartion_proof || "";
      const fSize = Math.round(file?.size / 1048576) || "2";
      const fType = file?.type || "image/jpg";
      const ext = file?.name?.split(".").pop() || ".jpg";
      const file1 = docArray?.tax_identification_proof || "";
      const fSize1 = Math.round(file1?.size / 1048576) || "2";
      const fType1 = file1?.type || "image/jpg";
      const ext1 = file1?.name?.split(".").pop() || ".jpg";
      if (fSize > 25 && fSize1 > 25) {
        props.refStop();
        toast(<AlertError message="Size exceeds maximum allowable size. Maximum allowable size is 25MB." />);
        return setLoadingUpload(false);
      } else if (
        ![
          "application/pdf",
          "text/csv",
          "application/vnd.ms-excel",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          "image/jpeg",
          "image/jpg",
          "image/png",
        ].includes(fType) &&
        ![
          "application/pdf",
          "text/csv",
          "application/vnd.ms-excel",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          "image/jpeg",
          "image/jpg",
          "image/png",
        ].includes(fType1)
      ) {
        ref?.current?.complete();
        toast(
          <AlertError message="File is not of correct format and hence cannot be uploaded. Valid document formats are pdf, xlsx, xls, doc, docx, jpeg, jpg, png and csv." />,
        );
        return setLoadingUpload(false);
      } else {
        const fileName = "newSalvex/doc/" + uuidv4() + "." + ext;
        const fileName1 = "newSalvex/doc/" + uuidv4() + "." + ext1;
        const a = [];
        if (docArray?.registartion_proof) {
          a.push(
            Storage.put(fileName, file, {
              completeCallback: (event) => {},
              progressCallback: (progress) => {},
              errorCallback: (err) => {},
            }),
          );
        }
        if (docArray?.tax_identification_proof) {
          a.push(
            Storage.put(fileName1, file1, {
              completeCallback: (event) => {},
              progressCallback: (progress) => {},
              errorCallback: (err) => {},
            }),
          );
        }
        const allPromise = Promise.all([...a]);
        allPromise
          .then((values) => {
            const result = values;
            const payload = {};

            if (docArray?.registartion_proof && !docArray?.tax_identification_proof) {
              payload.registrationProof = `public/${result[0]?.key}`;
            }
            if (!docArray?.registartion_proof && docArray?.tax_identification_proof) {
              payload.taxProof = `public/${result[0]?.key}`;
            }
            if (docArray?.registartion_proof && docArray?.tax_identification_proof) {
              payload.registrationProof = `public/${result[0]?.key}`;
              payload.taxProof = `public/${result[1]?.key}`;
            }

            props?.uploadVerificationDocs(props?.userId, payload, (res) => {
              if (res?.status === 200) {
                setDocArray({});
                props?.updatePrimarySeller();
                props?.onHide();
                ref?.current?.complete();
                setLoadingUpload(false);
                toast(<AlertSuccess message="Information Saved" />);
                setLoadingUpload(false);
              } else {
                setLoadingUpload(false);
                ref?.current?.complete();
                toast(<AlertError message={"Something Went Wrong"} />);
              }
            });
          })
          .catch((error) => {
            console.log(error); // rejectReason of any first rejected promise
          });
      }
    } else {
      setLoadingUpload(true);
      const file = docArray?.identification_proof;
      ref?.current?.continuousStart();
      const fSize = Math.round(file.size / 1048576);
      const fType = file.type;
      const ext = file.name.split(".").pop();
      if (fSize > 25) {
        props.refStop();
        toast(<AlertError message="Size exceeds maximum allowable size. Maximum allowable size is 25MB." />);
        return setLoadingUpload(false);
      } else if (
        ![
          "application/pdf",
          "text/csv",
          "application/vnd.ms-excel",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          "image/jpeg",
          "image/jpg",
          "image/png",
        ].includes(fType)
      ) {
        ref?.current?.complete();
        toast(
          <AlertError message="File is not of correct format and hence cannot be uploaded. Valid document formats are pdf, xlsx, xls, doc, docx, jpeg, jpg, png and csv." />,
        );
        return setLoadingUpload(false);
      } else {
        const fileName = "newSalvex/doc/" + uuidv4() + "." + ext;
        Storage.put(fileName, file, {
          completeCallback: (event) => {},
          progressCallback: (progress) => {},
          errorCallback: (err) => {
            return (
              setLoadingUpload(false), ref?.current?.complete(), toast(<AlertError message={"Something Went Wrong"} />)
            );
          },
        }).then((result) => {
          props.uploadVerificationDocs(
            props.userId,
            {
              identificationProof: "public/" + result.key,
            },
            (res) => {
              if (res.status === 200) {
                setDocArray({});
                props?.updatePrimarySeller();
                props?.onHide();
                ref?.current?.complete();
                setLoadingUpload(false);
                toast(<AlertSuccess message="Information Saved" />);
                setLoadingUpload(false);
              } else {
                setLoadingUpload(false);
                ref?.current?.complete();
                toast(<AlertError message={"Something Went Wrong"} />);
              }
            },
          );
        });
      }
    }
  };

  return (
    <>
      <LoadingBar height={5} color="#47AD1D" ref={ref} />
      <Offcanvas
        placement="end"
        backdropClassName="upload-documnets-backdrop"
        show={props.show}
        onHide={props.onHide}
        className="offcanvas offcanvas-end border-0"
        id="verifyDocsOffcanvas"
        enforceFocus={false}
      >
        <Offcanvas.Header className="offcanvas-header">
          <h5 class="offcanvas-title">Verification Documents</h5>
          <button
            type="button"
            class="offcanvas-close-btn d-flex align-items-center justify-content-center"
            data-bs-dismiss="offcanvas"
            onClick={props.onHide}
          >
            <img src={close} alt="" />
          </button>
        </Offcanvas.Header>
        <Offcanvas.Body className="offcanvas-body p-0">
          <div class="offcanvas-widget-row pt-0">
            <div class="table-responsive mb-3">
              {props.loading ? (
                <>
                  {[1, 2, 3].map((item) => (
                    <div class="table-responsive mb-3">
                      <table class="table align-middle table-borderless publicDocTable mb-0">
                        <colgroup>
                          <col style={{ width: "50px" }} />
                          <col />
                          <col style={{ width: "50px" }} />
                        </colgroup>
                        <tbody>
                          <tr>
                            <td>
                              <Skeleton height="40px" />
                            </td>
                            <td>
                              <Skeleton height="30px" />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ))}
                </>
              ) : props?.isUploading ? (
                <>
                  <div class="offcanvas-widget-row pt-0" style={{ paddingLeft: "0px" }}>
                    <p>
                      Documents must be in PDF, XLS, XLSX, DOC, DOCX, jpg, jpeg, png or CSV format. You must specify a
                      title for each document.
                    </p>
                    {props?.isCompany ? (
                      <>
                        {" "}
                        <div class="mb-20">
                          <label for="slectDocument" class="form-label">
                            Company Registration Proof
                          </label>
                          <div class="upload-lg-button position-relative">
                            <span class="file-input">
                              <input
                                type="file"
                                id="inputGroupFile02"
                                accept=".jpg,.jpeg,.png,.csv,.xls,.xlsx,.pdf,.doc"
                                onChange={(e) => {
                                  const value = { ...docArray };
                                  value.registartion_proof = e.target.files[0];

                                  setDocArray(value);
                                }}
                                onClick={(e) => (e.target.value = null)}
                              />
                              <span id="upload-file-name" class="d-flex align-items-center">
                                {docArray?.registartion_proof?.name
                                  ? docArray?.registartion_proof?.name?.slice(0, 70)
                                  : ""}
                                {docArray?.registartion_proof?.name ? (
                                  <img
                                    class="cross-icon-box"
                                    src={crossIcon}
                                    alt=""
                                    onClick={() => {
                                      const value = { ...docArray };
                                      delete value?.registartion_proof;
                                      setDocArray(value);
                                    }}
                                  />
                                ) : (
                                  <p>Please select any document</p>
                                )}
                              </span>
                            </span>
                          </div>
                          <p style={{ color: "red" }}> {error?.registartion_proof ? error?.registartion_proof : ""}</p>
                        </div>
                        <div class="mb-20">
                          <label for="slectDocument" class="form-label">
                            Company Tax Identification Proof
                          </label>
                          <div class="upload-lg-button position-relative">
                            <span class="file-input">
                              <input
                                type="file"
                                id="inputGroupFile02"
                                accept=".jpg,.jpeg,.png,.csv,.xls,.xlsx,.pdf,.doc"
                                onChange={(e) => {
                                  const value = { ...docArray };
                                  value.tax_identification_proof = e.target.files[0];

                                  setDocArray(value);
                                }}
                                onClick={(e) => (e.target.value = null)}
                              />
                              <span id="upload-file-name" class="d-flex align-items-center">
                                {docArray?.tax_identification_proof?.name
                                  ? docArray?.tax_identification_proof?.name?.slice(0, 70)
                                  : ""}
                                {docArray?.tax_identification_proof?.name ? (
                                  <img
                                    class="cross-icon-box"
                                    src={crossIcon}
                                    alt=""
                                    onClick={() => {
                                      const value = { ...docArray };
                                      delete value?.tax_identification_proof;
                                      setDocArray(value);
                                    }}
                                  />
                                ) : (
                                  <p>Please select any document</p>
                                )}
                              </span>
                            </span>
                          </div>
                          <p style={{ color: "red" }}>
                            {" "}
                            {error?.tax_identification_proof ? error?.tax_identification_proof : ""}
                          </p>
                        </div>
                      </>
                    ) : (
                      <div class="mb-20">
                        <label for="slectDocument" class="form-label">
                          Identification Proof
                        </label>
                        <div class="upload-lg-button position-relative">
                          <span class="file-input">
                            <input
                              type="file"
                              id="inputGroupFile02"
                              accept=".jpg,.jpeg,.png,.csv,.xls,.xlsx,.pdf,.doc"
                              onChange={(e) => {
                                const value = { ...docArray };
                                value.identification_proof = e.target.files[0];
                                setDocArray(value);
                              }}
                              onClick={(e) => (e.target.value = null)}
                            />
                            <span id="upload-file-name" class="d-flex align-items-center">
                              {docArray?.identification_proof?.name
                                ? docArray?.identification_proof?.name?.slice(0, 70)
                                : ""}
                              {docArray?.identification_proof?.name ? (
                                <img
                                  class="cross-icon-box"
                                  src={crossIcon}
                                  alt=""
                                  onClick={() => {
                                    const value = { ...docArray };
                                    delete value.identification_proof;
                                    setDocArray(value);
                                  }}
                                />
                              ) : (
                                <p>Please select any document</p>
                              )}
                            </span>
                          </span>
                        </div>
                        <p style={{ color: "red" }}>
                          {" "}
                          {error?.identification_proof ? error?.identification_proof : ""}
                        </p>
                      </div>
                    )}

                    <div class="d-grid-button d-grid gap-2 mt-30">
                      <button
                        class={`btn btn-default ${loadingUpload ? "btn-disabled" : ""}`}
                        type="button"
                        onClick={(e) => {
                          handleSubmitDocs();
                        }}
                      >
                        {false ? "Please Wait" : "Save"}
                      </button>
                    </div>
                  </div>
                  {(verificationData?.identification_proof ||
                    verificationData?.company_registration_proof ||
                    verificationData?.company_tax_identification_proof) && (
                    <div class="offcanvas-widget-row" style={{ paddingLeft: "0px" }}>
                      <div class="page-title mb-3">Verification Documents</div>
                      <div class="table-responsive mb-3">
                        <table class="table align-middle table-borderless publicDocTable mb-0">
                          <colgroup>
                            <col style={{ width: "50px" }} />
                            <col />
                            <col style={{ width: "24px" }} />
                          </colgroup>
                          <tbody>
                            {verificationData?.identification_proof ? (
                              <tr>
                                <td>
                                  <div class="td-pdf-img-box">
                                    <img
                                      src={
                                        indetificatinProofType === "csv"
                                          ? csvFile
                                          : indetificatinProofType === "xlsx"
                                          ? XLSX
                                          : indetificatinProofType === "xls"
                                          ? XLS
                                          : indetificatinProofType === "doc"
                                          ? DOC
                                          : indetificatinProofType === "docx"
                                          ? DOCX
                                          : indetificatinProofType === "png"
                                          ? PNG
                                          : indetificatinProofType === "jpg"
                                          ? JPG
                                          : indetificatinProofType === "jpeg"
                                          ? JPEG
                                          : Pdf
                                      }
                                      alt=""
                                    />
                                  </div>
                                </td>
                                <td>
                                  <a
                                    href={
                                      verificationData?.identification_proof.substring(0, 5) === "https"
                                        ? `${verificationData?.identification_proof}`
                                        : `${process.env.REACT_APP_S3_PRIVATE_DOC_BUCKET}/${verificationData?.identification_proof}`
                                    }
                                    target="_blank"
                                    download={true}
                                    className="deafult-black-hover-green"
                                  >
                                    Identification Proof.
                                    {indetificatinProofType === "csv"
                                      ? "csv"
                                      : indetificatinProofType === "xlsx"
                                      ? "xlsx"
                                      : indetificatinProofType === "xls"
                                      ? "xls"
                                      : indetificatinProofType === "doc"
                                      ? "doc"
                                      : indetificatinProofType === "docx"
                                      ? "docx"
                                      : indetificatinProofType === "png"
                                      ? "png"
                                      : indetificatinProofType === "jpg"
                                      ? "jpg"
                                      : indetificatinProofType === "jpeg"
                                      ? "jpeg"
                                      : "pdf"}
                                  </a>
                                </td>
                                <td>
                                  {/* <button
                      type="button"
                      class="delete-td-icon d-flex align-items-center justify-content-center"
                    >
                      <img src={Trash} alt="" />
                    </button> */}
                                </td>
                              </tr>
                            ) : (
                              ""
                            )}
                            {verificationData?.company_registration_proof ? (
                              <tr>
                                <td>
                                  <div class="td-pdf-img-box">
                                    <img
                                      src={
                                        companyRegistrationProofType === "csv"
                                          ? csvFile
                                          : companyRegistrationProofType === "xlsx"
                                          ? XLSX
                                          : companyRegistrationProofType === "xls"
                                          ? XLS
                                          : companyRegistrationProofType === "doc"
                                          ? DOC
                                          : companyRegistrationProofType === "docx"
                                          ? DOCX
                                          : companyRegistrationProofType === "png"
                                          ? PNG
                                          : companyRegistrationProofType === "jpg"
                                          ? JPG
                                          : companyRegistrationProofType === "jpeg"
                                          ? JPEG
                                          : Pdf
                                      }
                                      alt=""
                                    />
                                  </div>
                                </td>
                                <td>
                                  <a
                                    href={
                                      verificationData?.company_registration_proof.substring(0, 5) === "https"
                                        ? `${verificationData?.company_registration_proof}`
                                        : `${process.env.REACT_APP_S3_PRIVATE_DOC_BUCKET}/${verificationData?.company_registration_proof}`
                                    }
                                    target="_blank"
                                    download={true}
                                    className="deafult-black-hover-green"
                                  >
                                    Company Registration Proof.
                                    {companyRegistrationProofType === "csv"
                                      ? "csv"
                                      : companyRegistrationProofType === "xlsx"
                                      ? "xlsx"
                                      : companyRegistrationProofType === "xls"
                                      ? "xls"
                                      : companyRegistrationProofType === "doc"
                                      ? "doc"
                                      : companyRegistrationProofType === "docx"
                                      ? "docx"
                                      : companyRegistrationProofType === "png"
                                      ? "png"
                                      : companyRegistrationProofType === "jpg"
                                      ? "jpg"
                                      : companyRegistrationProofType === "jpeg"
                                      ? "jpeg"
                                      : "pdf"}
                                  </a>
                                </td>
                                <td></td>
                              </tr>
                            ) : (
                              ""
                            )}
                            {verificationData?.company_tax_identification_proof ? (
                              <tr>
                                <td>
                                  <div class="td-pdf-img-box">
                                    <img
                                      src={
                                        companyTaxIdetificationProofType === "csv"
                                          ? csvFile
                                          : companyTaxIdetificationProofType === "xlsx"
                                          ? XLSX
                                          : companyTaxIdetificationProofType === "xls"
                                          ? XLS
                                          : companyTaxIdetificationProofType === "doc"
                                          ? DOC
                                          : companyTaxIdetificationProofType === "docx"
                                          ? DOCX
                                          : companyTaxIdetificationProofType === "png"
                                          ? PNG
                                          : companyTaxIdetificationProofType === "jpg"
                                          ? JPG
                                          : companyTaxIdetificationProofType === "jpeg"
                                          ? JPEG
                                          : Pdf
                                      }
                                      alt=""
                                    />
                                  </div>
                                </td>
                                <td>
                                  <a
                                    href={
                                      verificationData?.company_tax_identification_proof.substring(0, 5) === "https"
                                        ? `${verificationData?.company_tax_identification_proof}`
                                        : `${process.env.REACT_APP_S3_PRIVATE_DOC_BUCKET}/${verificationData?.company_tax_identification_proof}`
                                    }
                                    target="_blank"
                                    download={true}
                                    className="deafult-black-hover-green"
                                  >
                                    Company Tax Identification Proof.
                                    {companyTaxIdetificationProofType === "csv"
                                      ? "csv"
                                      : companyTaxIdetificationProofType === "xlsx"
                                      ? "xlsx"
                                      : companyTaxIdetificationProofType === "xls"
                                      ? "xls"
                                      : companyTaxIdetificationProofType === "doc"
                                      ? "doc"
                                      : companyTaxIdetificationProofType === "docx"
                                      ? "docx"
                                      : companyTaxIdetificationProofType === "png"
                                      ? "png"
                                      : companyTaxIdetificationProofType === "jpg"
                                      ? "jpg"
                                      : companyTaxIdetificationProofType === "jpeg"
                                      ? "jpeg"
                                      : "pdf"}
                                  </a>
                                </td>
                                <td>
                                  {/* <button
                      type="button"
                      class="delete-td-icon d-flex align-items-center justify-content-center"
                    >
                      <img src={Trash} alt="" />
                    </button> */}
                                </td>
                              </tr>
                            ) : (
                              ""
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </>
              ) : verificationData?.identification_proof ||
                verificationData?.company_registration_proof ||
                verificationData?.company_tax_identification_proof ? (
                <table class="table align-middle table-borderless publicDocTable mb-0">
                  <colgroup>
                    <col style={{ width: "50px" }} />
                    <col />
                    <col style={{ width: "24px" }} />
                  </colgroup>
                  <tbody>
                    {verificationData?.identification_proof ? (
                      <tr>
                        <td>
                          <div class="td-pdf-img-box">
                            <img
                              src={
                                indetificatinProofType === "csv"
                                  ? csvFile
                                  : indetificatinProofType === "xlsx"
                                  ? XLSX
                                  : indetificatinProofType === "xls"
                                  ? XLS
                                  : indetificatinProofType === "doc"
                                  ? DOC
                                  : indetificatinProofType === "docx"
                                  ? DOCX
                                  : indetificatinProofType === "png"
                                  ? PNG
                                  : indetificatinProofType === "jpg"
                                  ? JPG
                                  : indetificatinProofType === "jpeg"
                                  ? JPEG
                                  : Pdf
                              }
                              alt=""
                            />
                          </div>
                        </td>
                        <td>
                          <a
                            href={
                              verificationData?.identification_proof.substring(0, 5) === "https"
                                ? `${verificationData?.identification_proof}`
                                : `${process.env.REACT_APP_S3_PRIVATE_DOC_BUCKET}/${verificationData?.identification_proof}`
                            }
                            target="_blank"
                            download={true}
                            className="deafult-black-hover-green"
                          >
                            Identification Proof.
                            {indetificatinProofType === "csv"
                              ? "csv"
                              : indetificatinProofType === "xlsx"
                              ? "xlsx"
                              : indetificatinProofType === "xls"
                              ? "xls"
                              : indetificatinProofType === "doc"
                              ? "doc"
                              : indetificatinProofType === "docx"
                              ? "docx"
                              : indetificatinProofType === "png"
                              ? "png"
                              : indetificatinProofType === "jpg"
                              ? "jpg"
                              : indetificatinProofType === "jpeg"
                              ? "jpeg"
                              : "pdf"}
                          </a>
                        </td>
                        <td>
                          {/* <button
                      type="button"
                      class="delete-td-icon d-flex align-items-center justify-content-center"
                    >
                      <img src={Trash} alt="" />
                    </button> */}
                        </td>
                      </tr>
                    ) : (
                      ""
                    )}
                    {verificationData?.company_registration_proof ? (
                      <tr>
                        <td>
                          <div class="td-pdf-img-box">
                            <img
                              src={
                                companyRegistrationProofType === "csv"
                                  ? csvFile
                                  : companyRegistrationProofType === "xlsx"
                                  ? XLSX
                                  : companyRegistrationProofType === "xls"
                                  ? XLS
                                  : companyRegistrationProofType === "doc"
                                  ? DOC
                                  : companyRegistrationProofType === "docx"
                                  ? DOCX
                                  : companyRegistrationProofType === "png"
                                  ? PNG
                                  : companyRegistrationProofType === "jpg"
                                  ? JPG
                                  : companyRegistrationProofType === "jpeg"
                                  ? JPEG
                                  : Pdf
                              }
                              alt=""
                            />
                          </div>
                        </td>
                        <td>
                          <a
                            href={
                              verificationData?.company_registration_proof.substring(0, 5) === "https"
                                ? `${verificationData?.company_registration_proof}`
                                : `${process.env.REACT_APP_S3_PRIVATE_DOC_BUCKET}/${verificationData?.company_registration_proof}`
                            }
                            target="_blank"
                            download={true}
                            className="deafult-black-hover-green"
                          >
                            Company Registration Proof.
                            {companyRegistrationProofType === "csv"
                              ? "csv"
                              : companyRegistrationProofType === "xlsx"
                              ? "xlsx"
                              : companyRegistrationProofType === "xls"
                              ? "xls"
                              : companyRegistrationProofType === "doc"
                              ? "doc"
                              : companyRegistrationProofType === "docx"
                              ? "docx"
                              : companyRegistrationProofType === "png"
                              ? "png"
                              : companyRegistrationProofType === "jpg"
                              ? "jpg"
                              : companyRegistrationProofType === "jpeg"
                              ? "jpeg"
                              : "pdf"}
                          </a>
                        </td>
                        <td></td>
                      </tr>
                    ) : (
                      ""
                    )}
                    {verificationData?.company_tax_identification_proof ? (
                      <tr>
                        <td>
                          <div class="td-pdf-img-box">
                            <img
                              src={
                                companyTaxIdetificationProofType === "csv"
                                  ? csvFile
                                  : companyTaxIdetificationProofType === "xlsx"
                                  ? XLSX
                                  : companyTaxIdetificationProofType === "xls"
                                  ? XLS
                                  : companyTaxIdetificationProofType === "doc"
                                  ? DOC
                                  : companyTaxIdetificationProofType === "docx"
                                  ? DOCX
                                  : companyTaxIdetificationProofType === "png"
                                  ? PNG
                                  : companyTaxIdetificationProofType === "jpg"
                                  ? JPG
                                  : companyTaxIdetificationProofType === "jpeg"
                                  ? JPEG
                                  : Pdf
                              }
                              alt=""
                            />
                          </div>
                        </td>
                        <td>
                          <a
                            href={
                              verificationData?.company_tax_identification_proof.substring(0, 5) === "https"
                                ? `${verificationData?.company_tax_identification_proof}`
                                : `${process.env.REACT_APP_S3_PRIVATE_DOC_BUCKET}/${verificationData?.company_tax_identification_proof}`
                            }
                            target="_blank"
                            download={true}
                            className="deafult-black-hover-green"
                          >
                            Company Tax Identification Proof.
                            {companyTaxIdetificationProofType === "csv"
                              ? "csv"
                              : companyTaxIdetificationProofType === "xlsx"
                              ? "xlsx"
                              : companyTaxIdetificationProofType === "xls"
                              ? "xls"
                              : companyTaxIdetificationProofType === "doc"
                              ? "doc"
                              : companyTaxIdetificationProofType === "docx"
                              ? "docx"
                              : companyTaxIdetificationProofType === "png"
                              ? "png"
                              : companyTaxIdetificationProofType === "jpg"
                              ? "jpg"
                              : companyTaxIdetificationProofType === "jpeg"
                              ? "jpeg"
                              : "pdf"}
                          </a>
                        </td>
                        <td>
                          {/* <button
                      type="button"
                      class="delete-td-icon d-flex align-items-center justify-content-center"
                    >
                      <img src={Trash} alt="" />
                    </button> */}
                        </td>
                      </tr>
                    ) : (
                      ""
                    )}
                  </tbody>
                </table>
              ) : (
                <>
                  <div class="pannel-body d-flex align-items-center justify-content-center empty-verification-documents">
                    <div class="empty-pannel-box">
                      <img src={emptyChat} alt="" />
                      <h4>No Documents Found!</h4>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};
const mapDispatchToProps = (dispatch) => ({
  getVerificationDoc: (params, callback) => dispatch(getVerificationDoc(params, callback)),
  uploadVerificationDocs: (id, data, callback) => dispatch(uploadVerificationDocs(id, data, callback)),
});
const mapStateToProps = (state) => ({
  getUser: getUser(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(VerifyDocModal));
