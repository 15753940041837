import React, { useState } from "react";
import { Offcanvas } from "react-bootstrap";
import close from "../../../include/images/close.svg";
import DateFormat from "../../../common/DateFormat/dateFormat";
import Trash from "../../../include/images/trash-18x18.svg";
import selectedIcon from "../../../include/images/yellow-star-icon.svg";
import darkStarIcon from "../../../include/images/dark-star-icon.svg";
import { getProfile } from "../../../utils.js/localStorageServices";
import emptyChat from "../../../include/images/chat-empty.svg";
import { toast } from "react-toastify";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import AlertError from "../../../common/alerts/alertError";
import moment from "moment";
import More from "../../../include/images/more-icon.svg";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextField } from "@mui/material";
import closeButton from "../../../include/images/close.svg";
import RevisitModal from "./revisitModal";
import InProgressModal from "./inProgressModal";
import CompleteTaskModal from "./completeTaskModal";
import DeleteModal from "../deleteModal";

const PendingTasks = (props) => {
  const [statusData, setStatusData] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [showProgressModal, setShowProgressModal] = useState(false);
  const [showRevisitModal, setShowRevisitModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [revisit, setRevisit] = useState(false);
  const [revisitId, setRevisitId] = useState(null);
  const [taskId, setTaskId] = useState("");
  const listingData = props && props.getTasks && props.getTasks.pendingTasks && props.getTasks.pendingTasks.data;
  const count = props?.count?.pendingTask;
  //Togle Delete Modal
  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  //Toggle Complete modal
  const toggleCompleteModal = () => {
    setShowCompleteModal(!showCompleteModal);
  };

  //Toggle Progress Modal
  const toggleProgressModal = () => {
    setShowProgressModal(!showProgressModal);
  };

  //Toggle Revisit Modal
  const toggleRevisitModal = () => {
    setShowRevisitModal(!showRevisitModal);
  };

  //Handle Processes
  const handleProcess = () => {
    props.refStart();
    setLoading(true);
    props.updateTaskStatus(props.auctionId, statusData, (res) => {
      if (res.status === 200) {
        props.getPendingTasks(props.auctionId, (res) => {
          if (res.status == 200) {
            props?.getProductCount(props?.auctionId, (res) => {});
            setLoading(false);
            props.refStop();
            setRevisit(false);
            if (statusData.status_id === 3) {
              toast(<AlertSuccess message={"Information Saved"} />);
              toggleCompleteModal();
            }
            if (statusData.status_id === 4) {
              toast(<AlertSuccess message={"Information Saved"} />);
              toggleProgressModal();
            }
            if (statusData.status_id === 5) {
              setLoading(false);
              toast(<AlertSuccess message={"Information Saved"} />);
              toggleRevisitModal();
            }
          } else {
            setLoading(false);
            props.refStop();
          }
        });
      } else {
        toast(<AlertError message={res?.data?.message ? res.data.message : "Something Went Wrong"} />);
        setLoading(false);
        props.refStop();
      }
    });
  };

  //Hanlde Delete
  const deleteHandler = () => {
    setLoadingDelete(true);
    props.refStart();
    const id = props.auctionId;
    const t_id = taskId;
    props.deleteTask(id, t_id, (res) => {
      if (res.status === 200) {
        props.getPendingTasks(id, (res) => {
          if (res.status === 200) {
            props?.getProductCount(props?.auctionId, (res) => {});
            props.refStop();
            setLoadingDelete(false);
            toggleDeleteModal();
            toast(<AlertSuccess message={"Information Saved"} />);
          } else {
            props.refStop();
            setLoadingDelete(false);
            toggleDeleteModal();
            toast(
              <AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />,
            );
          }
        });
      } else {
        props.refStop();
        setLoadingDelete(false);
        toggleDeleteModal();
        toast(<AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const session = getProfile();
  return (
    <Offcanvas
      placement="end"
      backdropClassName="upload-documnets-backdrop"
      show={props.show}
      onHide={props?.onHide}
      className=" right-collapse fade modal-lg  offcanvas-pannel-container"
      dialogClassName="modal-dialog"
    >
      <Offcanvas.Header className="offcanvas-header">
        <h5 class="offcanvas-title">Pending Tasks</h5>
        <button
          type="button"
          class="offcanvas-close-btn d-flex align-items-center justify-content-center"
          onClick={props?.onHide}
        >
          <img src={close} alt="" />
        </button>
      </Offcanvas.Header>
      <Offcanvas.Body className="custom-modal-body flex-grow-1 w-100 overflow-scroll" data-simplebar>
        {props?.data?.length > 0 ? (
          props?.data?.map((val) => (
            <div class="text-card-block-column">
              <div class="tcbc-header d-flex justify-content-between">
                <div class="tcbc-header-label d-flex flex-column align-items-start">
                  <div
                    class="tcbc-header-label d-flex ps-2 flex-column align-items-start justify-content-center text-white"
                    style={{
                      // unComment if condition needed
                      // backgroundColor: val?.task_id > 0 ? "#137c10" : "#104b7c",
                      backgroundColor: "#137c10",
                      width: "250px",
                      minHeight: "50px",
                      maxHeight: "70px",
                    }}
                  >
                    {val?.chat_id === 0 ? <div class="fw-medium">Unanswered Question</div> : ""}
                    <div class="fw-medium">
                      {val?.sent_name ? `${val?.name01 ? val?.name01 : ""} to ${val?.sent_name}` : val?.name01}
                    </div>
                    <div>{val?.created && <DateFormat date={val?.created} />}</div>
                  </div>
                </div>

                {(val?.task_status_id === 1 || (val?.task_id > 0 && val?.task_status_id === 4)) && (
                  <div class="dropdown">
                    <a class="td-a-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                      <img src={More} alt="" />
                    </a>
                    <ul
                      class="dropdown-menu"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasRight"
                      aria-controls="offcanvasRight"
                    >
                      {val?.task_status_id === 1 ? (
                        <div class="tcbc-right-box">
                          {val.member_id == session.member_id || session.member_id == 1 ? (
                            <li className="menu-item">
                              <a
                                class="dropdown-item"
                                onClick={() => {
                                  setTaskId(val?.task_id);
                                  toggleDeleteModal();
                                }}
                              >
                                Delete
                              </a>
                            </li>
                          ) : (
                            ""
                          )}
                          {val?.task_id > 0 && val?.task_status_id == 1 && val?.task_type == 169 ? (
                            <li className="menu-item">
                              <a
                                class="dropdown-item"
                                // onClick={() => {
                                //   setStatusData({
                                //     chat_id: val.chat_id,
                                //     status_id: 4,
                                //   });
                                //   toggleProgressModal();
                                // }}
                              >
                                &raquo; Respond
                              </a>
                            </li>
                          ) : (
                            ""
                          )}
                          {val?.task_id > 0 && val?.task_status_id == 1 && (
                            <li className="menu-item">
                              <a
                                class="dropdown-item"
                                onClick={() => {
                                  setStatusData({
                                    chat_id: val.chat_id,
                                    status_id: 4,
                                  });
                                  toggleProgressModal();
                                }}
                              >
                                In Progress
                              </a>
                            </li>
                          )}
                          {val?.task_id > 0 && val?.task_status_id == 1 && (
                            <li className="menu-item">
                              <a
                                type="button"
                                class="dropdown-item"
                                onClick={() => {
                                  setRevisitId(val?.chat_id);
                                  setStatusData({
                                    chat_id: val.chat_id,
                                    status_id: 5,
                                    revisit_date: val.revisit_date
                                      ? val.revisit_date
                                      : moment(Date.now()).format("MM/DD/YYYY"),
                                    revisit_email: true,
                                  });
                                  setRevisit(true);
                                }}
                              >
                                Revisit
                              </a>
                            </li>
                          )}
                          {val?.task_id > 0 && val?.task_status_id == 1 && (
                            <li className="menu-item">
                              <a
                                class="dropdown-item"
                                onClick={() => {
                                  setStatusData({
                                    chat_id: val.chat_id,
                                    status_id: 3,
                                  });
                                  toggleCompleteModal();
                                }}
                              >
                                Complete
                              </a>
                            </li>
                          )}
                        </div>
                      ) : val?.task_id > 0 && val?.task_status_id === 4 ? (
                        <div class="tcbc-right-box">
                          {val.member_id == session.member_id || session.member_id == 1 ? (
                            <li className="menu-item">
                              <a
                                class="dropdown-item"
                                onClick={() => {
                                  setTaskId(val?.task_id);
                                  toggleDeleteModal();
                                }}
                              >
                                Delete
                              </a>
                            </li>
                          ) : (
                            ""
                          )}
                          {val?.task_id > 0 && val?.task_status_id === 4 ? (
                            <li className="menu-item ">
                              <a class="dropdown-item disabled" style={{ color: "##137c10" }}>
                                In Progress
                              </a>
                            </li>
                          ) : (
                            ""
                          )}
                          {val?.task_id > 0 && val?.task_status_id === 4 ? (
                            <li className="menu-item">
                              <a
                                class="dropdown-item"
                                onClick={() => {
                                  setRevisitId(val?.chat_id);
                                  setRevisit(true);
                                  setStatusData({
                                    chat_id: val?.chat_id,
                                    status_id: 5,
                                    revisit_date: val.revisit_date
                                      ? val.revisit_date
                                      : moment(Date.now()).format("MM/DD/YYYY"),
                                    revisit_email: true,
                                  });
                                }}
                              >
                                Revisit
                              </a>
                            </li>
                          ) : (
                            ""
                          )}
                          {val?.task_id > 0 && val?.task_status_id === 4 ? (
                            <li className="menu-item">
                              <a
                                class="dropdown-item"
                                onClick={() => {
                                  setStatusData({
                                    chat_id: val.chat_id,
                                    status_id: 3,
                                  });
                                  toggleCompleteModal();
                                }}
                              >
                                Complete
                              </a>
                            </li>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </ul>
                  </div>
                )}
              </div>

              <div
                class="tcbc-text-row div-comm-notes-sec"
                dangerouslySetInnerHTML={{ __html: val?.message?.replace(/null/g, "") }}
              />

              <div className="mb-0 mt-5">
                {val?.chat_id === 0 ? (
                  <>
                    <span style={{ color: "#47AD1D" }}>Unanswered</span>
                    <span> » Respond</span>
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="d-flex flex-column  ">
                {val?.date_completed && val?.task_status_id == 1 && (
                  <>
                    <div style={{ marginTop: "6px", color: "#47AD1D" }}>
                      <span>Completed on </span>
                      <DateFormat date={val?.date_completed} />
                    </div>
                  </>
                )}
                {val?.date_completed && val?.task_status_id == 4 && (
                  <>
                    <div style={{ marginTop: "6px", color: "#47AD1D" }}>
                      <span>In Progress </span>
                    </div>
                  </>
                )}

                {revisitId === val.chat_id && revisit ? (
                  <>
                    <div className="d-flex justify-content-end mt-5 cursor-pointer" onClick={() => setRevisit(false)}>
                      <img src={closeButton} alt="" />
                    </div>
                    <div className="d-flex flex-column ">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          disableHighlightToday={true}
                          className="form-control"
                          value={statusData.revisit_date}
                          format="MM/DD/YYYY"
                          onChange={(e) => {
                            const values = { ...statusData };
                            const formatted = moment(e).format("MM/DD/YYYY");
                            values.revisit_date = formatted;
                            setStatusData(values);
                          }}
                          renderInput={(params) => <TextField {...params} error={false} />}
                          inputFormat="MM/dd/yyyy"
                        />
                      </LocalizationProvider>
                      <div className="d-flex justify-content-between mt-2">
                        <div class="form-check dark-tick-checkbox">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            checked={statusData.revisit_email}
                            id="tick2"
                            onChange={(e) => {
                              const values = { ...statusData };
                              values.revisit_email = e.target.checked;
                              setStatusData(values);
                            }}
                          />
                          <label class="form-check-label" for="tick2"></label>
                          <span>Email Task on revisit Date?</span>
                        </div>
                        <button className="btn btn-default" onClick={toggleRevisitModal}>
                          Save
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          ))
        ) : (
          <div class="d-flex align-items-center justify-content-center empty-verification-documents">
            <div class="empty-pannel-box">
              <img src={emptyChat} alt="" />
              <h4>No Pending Tasks Found!</h4>
            </div>
          </div>
        )}
      </Offcanvas.Body>
      <DeleteModal
        show={showDeleteModal}
        onHide={toggleDeleteModal}
        loadingDelete={loadingDelete}
        deleteHandler={deleteHandler}
      />
      <CompleteTaskModal
        show={showCompleteModal}
        onHide={toggleCompleteModal}
        loadingDelete={loading}
        submitHandler={handleProcess}
      />
      <InProgressModal
        show={showProgressModal}
        onHide={toggleProgressModal}
        loadingDelete={loading}
        submitHandler={handleProcess}
      />
      <RevisitModal
        show={showRevisitModal}
        onHide={toggleRevisitModal}
        loadingDelete={loading}
        empty={statusData.revisit_date}
        submitHandler={handleProcess}
      />
    </Offcanvas>
  );
};

export default PendingTasks;
