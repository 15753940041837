import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";

const slice = createSlice({
  name: "productLocation",
  initialState: {
    loading: false,
    productLocation: {},
    populateLocation: {},
    mapViaZip: {},
    states: [],
    countries: [],
    locationSecurity: {},
  },
  reducers: {
    productLocationFetching: (state, action) => {
      state.loading = true;
    },
    productLocationFetched: (state, action) => {
      state.loading = false;
      state.productLocation = action.payload;
    },
    productLocationFailed: (state, action) => {
      state.loading = false;
    },
    mapViaZipFetching: (state, action) => {
      state.loading = true;
    },
    mapViaZipFetched: (state, action) => {
      state.loading = false;
      state.mapViaZip = action.payload;
    },
    mapViaZipFailed: (state, action) => {
      state.loading = false;
    },
    statesFetching: (state, action) => {
      state.loading = true;
    },
    statesFetched: (state, action) => {
      state.loading = false;
      state.states = action.payload;
    },
    statesFailed: (state, action) => {
      state.loading = false;
    },
    countryFetching: (state, action) => {
      state.loading = true;
    },
    countryFetched: (state, action) => {
      state.loading = false;
      state.countries = action.payload;
    },
    countryFailed: (state, action) => {
      state.loading = false;
    },
    locSecFetching: (state, action) => {
      state.loading = true;
    },
    locSecFetched: (state, action) => {
      state.loading = false;
      state.locationSecurity = action.payload;
    },
    locSecFailed: (state, action) => {
      state.loading = false;
    },
    populateFetching: (state, action) => {
      state.loading = true;
    },
    populateFetched: (state, action) => {
      state.loading = false;
      state.populateLocation = action.payload;
    },
    populateFailed: (state, action) => {
      state.loading = false;
    },
  },
});

export const {
  productLocationFetching,
  productLocationFetched,
  productLocationFailed,
  mapViaZipFetching,
  mapViaZipFetched,
  mapViaZipFailed,
  statesFetching,
  statesFetched,
  statesFailed,
  countryFetching,
  countryFetched,
  countryFailed,
  locSecFetching,
  locSecFetched,
  locSecFailed,
  populateFetching,
  populateFetched,
  populateFailed,
} = slice.actions;
export default slice.reducer;

//Action Creators

const commonUrl = "common";
const locationUrl = "auctionOther/location/getLocation";
const mapViaZipUrl = "auctionOther/location/locationZipMap";
const locSecUrl = "auctionOther/location/locationSecurity";
const imageUrl = "auctionOther/location/locationPicture";
const populateUrl = "auctionOther/location/populateFromSeller";
const updateLocation = "auctionOther/location/updateLocation";

//Get Product location
export const getProductLocation = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${locationUrl}/${params}`,
      method: "get",
      callback,
      onStart: productLocationFetching.type,
      onSuccess: productLocationFetched.type,
      onError: productLocationFailed.type,
    })
  );
};

//Update product location
export const updateProductLocation = (params, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${updateLocation}/${params}`,
      method: "put",
      data,
      callback,
    })
  );
};

//Get map via zip
export const getMapViaZip = (params, zip, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${mapViaZipUrl}/${params}?zip=${zip}`,
      method: "get",
      callback,
      onStart: mapViaZipFetching.type,
      onSuccess: mapViaZipFetched.type,
      onError: mapViaZipFailed.type,
    })
  );
};

//listing states
export const getStates = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${commonUrl}/state`,
      method: "get",
      callback,
      onStart: statesFetching.type,
      onSuccess: statesFetched.type,
      onError: statesFailed.type,
    })
  );
};

//listing countries
export const getCountries = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${commonUrl}/country`,
      method: "get",
      callback,
      onStart: countryFetching.type,
      onSuccess: countryFetched.type,
      onError: countryFailed.type,
    })
  );
};

//Location Security

//update location security
export const updateLocationSecurity =
  (params, data, callback) => (dispatch) => {
    return dispatch(
      apiCallBegan({
        url: `${locSecUrl}/${params}`,
        method: "put",
        data,
        callback,
      })
    );
  };

//Upload Product Location Image
export const uploadLocationImage = (params, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${imageUrl}/${params}`,
      method: "post",
      data,
      callback,
    })
  );
};
//Delete Location Image
export const deleteLocationImage = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${imageUrl}/${params}`,
      method: "delete",
      callback,
    })
  );
};

//Populate From Seller
export const populateFromSeller = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${populateUrl}/${params}`,
      method: "get",
      callback,
      onStart: populateFetching.type,
      onSuccess: populateFetched.type,
      onError: populateFailed.type,
    })
  );
};


export const productLocationDetails = createSelector(
  (state) => state.entities.productLocation,
  (productLocation) => productLocation
);
