import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { dashboardData, getCampaignAggregate, getListingCampaign } from "../../../store/dashBoard";
import { map } from "lodash";
import { object } from "joi";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";
import AlertError from "../../../common/alerts/alertError";
import { Pie } from "react-chartjs-2";

const EmailCampaigns = (props) => {
  const data = props?.dashboardData?.listingCampaign?.data;
  const count = props?.count?.emailCampaign;

  const campaignData = props?.dashboardData?.campaignAggregate?.data;
  const totalCount = props?.dashboardData?.listingCampaign?.paginationObj?.totalCount;
  const [page, setPage] = useState(1);
  const [enable, setEnable] = useState(false);
  const [show, setShow] = useState(false);

  const chartData01 = {
    labels: ["Key Buyer", "Assignment Push", "Major Category"],
    datasets: [
      {
        label: "Count",
        data: [campaignData?.totalKeyBuyer, campaignData?.totalAssignmentPush, campaignData?.totalMajorCategory],
        backgroundColor: ["rgba(255, 99, 132, 0.2)", "rgba(54, 162, 235, 0.2)", "rgba(255, 206, 86, 0.2)"],
        borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)", "rgba(255, 206, 86, 1)"],
        borderWidth: 1,
      },
    ],
  };
  const chartData02 = {
    labels: ["Delivered", "Opened", "Not Delivered"],
    datasets: [
      {
        label: "Count",
        data: [
          campaignData?.totalDelivered,
          campaignData?.totalOpened,
          campaignData?.totalAll - (campaignData?.totalOpened + campaignData?.totalDelivered),
        ],
        backgroundColor: ["rgba(75, 192, 192, 0.2)", "rgba(153, 102, 255, 0.2)", "rgba(255, 159, 64, 0.2)"],
        borderColor: ["rgba(75, 192, 192, 1)", "rgba(153, 102, 255, 1)", "rgba(255, 159, 64, 1)"],
        borderWidth: 1,
      },
    ],
  };

  const showEmailCampaign = () => {
    props.refStart();
    props.getListingCampaign(props.auctionId, 10, 0, (res) => {
      if (res.status === 200) {
        props.refStop();
        setShow(true);
      } else {
        props.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const showCampaignAggregate = () => {
    props.refStart();
    props.getCampaignAggregate(props.auctionId, (res) => {
      if (res.status === 200) {
        props.refStop();
        setShow(true);
      } else {
        props.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  //Pagination
  useEffect(() => {
    const pagination = () => {
      props?.refStart();
      props.getListingCampaign(props.auctionId, 10, (page - 1) * 10, (res) => {
        if (res.status === 200) {
          props?.refStop();
        } else {
          props?.refStop();
          toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        }
      });
    };
    if (enable) {
      pagination();
    }
  }, [page]);

  return (
    <div class="details-page-widget-block">
      <div class="page-widget-head d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <div class="d-flex align-items-center">
            <div
              class="page-widget-label text-capitalize cursor-pointer"
              onClick={() => {
                show ? setShow(false) : showEmailCampaign();
              }}
            >
              Email Campaigns {!props?.loadingMain && (count > 0 ? `(${count})` : "(0)")}
            </div>
          </div>
          <li
            className="badge-item cursor-pointer"
            onClick={() => {
              if (show) {
                setShow(false);
              } else {
                showCampaignAggregate();
                showEmailCampaign();
              }
            }}
            style={{ marginLeft: "10px" }}
          >
            <div className="badge-box" style={{ background: "#DADEE0" }}>
              {show ? "Hide" : "Show"}
            </div>
          </li>
        </div>
      </div>
      {show && (props?.loadingMain || data?.length > 0) && (
        <div class="page-widget-body mt-3 ">
          <div className="d-flex">
            <div class="table-responsive table-custom">
              <table class="table table-bordered">
                <colgroup>
                  <col />
                  <col />
                  <col />
                  <col />
                  <col />
                  <col />
                </colgroup>
                {!props?.loadingMain && (
                  <thead>
                    <tr>
                      <th>Campaign</th>
                      <th>Sender</th>
                      <th>Regions</th>
                      <th>Industries</th>
                      <th>Manufactures</th>
                      <th>Categories</th>
                    </tr>
                  </thead>
                )}
                {props?.loadingMain ? (
                  <tbody>
                    <tr>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {data &&
                      data?.length > 0 &&
                      data?.map((val) => (
                        <tr>
                          <td>
                            <span>{val.campaign ? val.campaign : "N/A"}</span>
                            <div className="d-flex flex-column mt-2" style={{ width: "fit-content" }}>
                              <span>
                                <li className="badge-item ">
                                  <div className={`badge-box gsp`}>
                                    {`Delivered: ${val.totalDelivered ? val.totalDelivered : 0}`}
                                  </div>
                                </li>{" "}
                              </span>
                              <span>
                                <li className="badge-item ">
                                  <div className={`badge-box rsp`}>
                                    {`Opened: ${val.totalOpened ? val.totalOpened : 0}`}
                                  </div>
                                </li>{" "}
                              </span>
                            </div>
                          </td>
                          <td>
                            {val?.sender && val?.sender?.name && val?.sender?.email ? (
                              <div>
                                <span
                                  className="green-btn-text cursor-pointer"
                                  onClick={() => {
                                    if (process.env.REACT_APP_MODE == "development") {
                                      window.open(
                                        `https://admin-dev.salvex.com/admin/_user/_edit.cfm?user_id=${val?.sender?.user_ID}`,
                                      );
                                    } else {
                                      window.open(
                                        `https://admin.salvex.com/admin/_user/_edit.cfm?user_id=${val?.sender?.user_ID}`,
                                      );
                                    }
                                  }}
                                >
                                  {val?.sender?.name}
                                </span>
                                {/* <span>{val?.sender?.name}</span> */}
                                <br />
                                <a href={`mailto:${val?.sender?.email}`}>{val?.sender?.email}</a>
                              </div>
                            ) : (
                              "N/A"
                            )}
                          </td>

                          <td>
                            <ul>
                              {val?.regions && val.regions.length > 0
                                ? val?.regions?.map((region) => <li>{region}</li>)
                                : "N/A"}
                            </ul>
                          </td>
                          <td>
                            <ul>
                              {val?.industries && val?.industries?.length > 0
                                ? val?.industries?.map((industry) => <li>{industry}</li>)
                                : "N/A"}
                            </ul>
                          </td>
                          <td>
                            <ul>
                              {val?.mfrs && val?.mfrs?.length > 0 ? val?.mfrs?.map((mfr) => <li>{mfr}</li>) : "N/A"}
                            </ul>
                          </td>

                          <td>
                            <ul>
                              {val?.categories && val?.categories?.length > 0
                                ? val?.categories?.map((cat) => {
                                    const Category = Object.keys(cat)[0];
                                    const sub = Object.values(cat);
                                    const subArray = sub && sub?.length > 0 && Object.values(sub[0]);
                                    return (
                                      subArray &&
                                      subArray?.length > 0 &&
                                      subArray.map((subCat) => <li>{Category + " > " + subCat}</li>)
                                    );
                                  })
                                : "N/A"}
                            </ul>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                )}
              </table>
            </div>
            <div class="table-responsive dpc-col-column-custom2">
              <table class="table mb-0  product-table ">
                <colgroup>
                  <col style={{ width: "35%" }} />
                  <col />
                </colgroup>
                <tbody>
                  {props.loadingMain ? (
                    <>
                      <tr>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                      </tr>
                    </>
                  ) : (
                    <>
                      <tr>
                        <td>Key Buyer</td>
                        <td>{campaignData?.totalKeyBuyer ? campaignData?.totalKeyBuyer : "N/A"}</td>
                      </tr>
                      <tr>
                        <td>Assignment Push</td>
                        <td>{campaignData?.totalAssignmentPush ? campaignData?.totalAssignmentPush : "N/A"}</td>
                      </tr>
                      <tr>
                        <td>Major Category</td>
                        <td>{campaignData?.totalMajorCategory ? campaignData?.totalMajorCategory : "N/A"}</td>
                      </tr>
                      <tr>
                        <td>Delivered</td>
                        <td>{campaignData?.totalDelivered ? campaignData?.totalDelivered : "N/A"}</td>
                      </tr>
                      <tr>
                        <td>Opened</td>
                        <td>{campaignData?.totalOpened ? campaignData?.totalOpened : "N/A"}</td>
                      </tr>
                      <tr>
                        <td>All</td>
                        <td>{campaignData?.totalAll ? campaignData?.totalAll : "N/A"}</td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
              <div className="d-flex mt-4">
                <div style={{ width: "50%" }}>
                  <Pie
                    data={chartData01}
                    options={{
                      plugins: {
                        legend: {
                          display: false,
                        },
                        // title: {
                        //   display: true,
                        //   text: "Bids Count",
                        //   position: "bottom",
                        // },
                      },
                    }}
                  />
                </div>
                <div style={{ width: "50%" }}>
                  <Pie
                    data={chartData02}
                    options={{
                      plugins: {
                        legend: {
                          display: false,
                        },
                        // title: {
                        //   display: true,
                        //   text: "Bids Count",
                        //   position: "bottom",
                        // },
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          {!props.loadingMain && data && data?.length > 0 && (
            <div class="table-pagination mt-3">
              <ul class="pagination  justify-content-end">
                <li class="page-item">
                  <a
                    class={`page-link ${page === 1 ? "disabled" : ""}`}
                    href="javascript:void(0)"
                    aria-label="Previous"
                    onClick={() => {
                      if (page !== 1) {
                        setEnable(true);
                        setPage(page - 1);
                      }
                    }}
                  >
                    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7 13L1 7L7 1" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </a>
                </li>
                <li class="page-item active">
                  <a class="page-link" href="javascript:void(0)">
                    {page}
                  </a>
                </li>
                <li class="page-item">
                  {page < (totalCount % 10 === 0 ? Math.floor(totalCount / 10) : Math.floor(totalCount / 10) + 1) && (
                    <a
                      class={`page-link ${
                        page >= (totalCount % 10 === 0 ? Math.floor(totalCount / 10) : Math.floor(totalCount / 10) + 1)
                          ? "disabled"
                          : ""
                      }`}
                      href="javascript:void(0)"
                      onClick={() => {
                        setEnable(true);
                        setPage(page + 1);
                      }}
                    >
                      {page + 1}
                    </a>
                  )}
                </li>
                <li class="page-item">
                  {page < (totalCount % 10 === 0 ? Math.floor(totalCount / 10) : Math.floor(totalCount / 10) + 1) && (
                    <a
                      class={`page-link ${
                        page >= (totalCount % 10 === 0 ? Math.floor(totalCount / 10) : Math.floor(totalCount / 10) + 1)
                          ? "disabled"
                          : ""
                      }`}
                      href="javascript:void(0)"
                      aria-label="Next"
                      onClick={() => {
                        setEnable(true);
                        setPage(page + 1);
                      }}
                    >
                      <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 13L7 7L1 1" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </a>
                  )}
                </li>
              </ul>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getListingCampaign: (params, limit, skip, callback) => dispatch(getListingCampaign(params, limit, skip, callback)),
  getCampaignAggregate: (params, callback) => dispatch(getCampaignAggregate(params, callback)),
});

const mapStateToProps = (state) => ({
  dashboardData: dashboardData(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(EmailCampaigns));
