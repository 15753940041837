import React from "react";
import close from "../../../include/images/close-12x12.svg";
import { Modal } from "react-bootstrap";
import DateFormat from "../../../common/DateFormat/dateFormat";
import NextButton from "../../../common/form/nextButton";
import moment from "moment";
import { isBoolean } from "lodash";

const ViewDetails = (props) => {
  const d = new Date();
  let diff = d.getTimezoneOffset();
  return (
    <Modal
      show={props.show}
      onHide={() => props.onHide()}
      className="modal fade salvex-modal2 av_viewall_modal"
      id="aviationParts"
      dialogClassName="modal-dialog  modal-xl modal-dialog-centered"
      contentClassName="modal-content"
    >
      <div class="custom-modal-header d-flex align-items-center justify-content-between">
        <h5 class="mb-0 text-capitalize">Part Details</h5>
        <button
          type="button"
          class="close-btn d-flex align-items-center justify-content-center"
          onClick={() => props.onHide()}
        >
          <img src={close} alt="" />
        </button>
      </div>

      <Modal.Body className="modal-body aviation-view-body">
        <table class={` table  table-borderless aviation-viewall-table`}>
          <tr>
            <td className="aviation-view-modal aviation-view-direction">
              <span>Listing ID</span>
            </td>
            <td>:</td>
            <td className="aviation-view-modal1 aviation-view-direction">
              <span>
                {props.selectedValue?.auctionId ? (
                  <a
                    className="aviation-auction link-item"
                    href={`/listing-detail?id=${props?.selectedValue?.auctionId}`}
                    target="_blank"
                  >
                    {props?.selectedValue?.auctionId}
                  </a>
                ) : (
                  "N/A"
                )}
              </span>
            </td>
          </tr>
          <tr>
            <td className="aviation-view-modal aviation-view-direction">
              <span>Part Number</span>
            </td>
            <td>:</td>
            <td className="aviation-view-modal1 aviation-view-direction">
              <span>{props.selectedValue?.partNumber ? props.selectedValue?.partNumber : "N/A"}</span>
            </td>
          </tr>
          <tr>
            <td className="aviation-view-modal aviation-view-direction">
              <span>Part Description</span>
            </td>
            <td>:</td>
            <td className="aviation-view-modal1 aviation-view-direction">
              <span>{props.selectedValue?.partDescription ? props.selectedValue?.partDescription : "N/A"}</span>
            </td>
          </tr>
          <tr>
            <td className="aviation-view-modal aviation-view-direction">
              <span>Condition</span>
            </td>
            <td>:</td>
            <td className="aviation-view-modal1 aviation-view-direction">
              <span>{props.selectedValue?.condition ? props.selectedValue?.condition : "N/A"}</span>
            </td>
          </tr>
          <tr>
            <td className="aviation-view-modal aviation-view-direction">
              <span>Quantity</span>
            </td>
            <td>:</td>
            <td className="aviation-view-modal1 aviation-view-direction">
              <span>{props.selectedValue?.quantity ? props.selectedValue?.quantity : "N/A"}</span>
            </td>
          </tr>
          <tr>
            <td className="aviation-view-modal aviation-view-direction">
              <span>Location</span>
            </td>
            <td>:</td>
            <td className="aviation-view-modal1 aviation-view-direction">
              <span>{props?.selectedValue?.location ? props?.selectedValue?.location : "N/A"}</span>
            </td>
          </tr>
          <tr>
            <td className="aviation-view-modal aviation-view-direction">
              <span>Certificate</span>
            </td>
            <td>:</td>
            <td className="aviation-view-modal1 aviation-view-direction">
              <span>
                {isBoolean(props?.selectedValue?.certificate)
                  ? props.selectedValue?.certificate
                    ? "Yes"
                    : "No"
                  : "N/A"}
              </span>
            </td>
          </tr>

          {/* <tr>
            <td className="aviation-view-modal aviation-view-direction">
              <span>Alt. Part Number</span>
            </td>
            <td>:</td>
            <td className="aviation-view-modal1 aviation-view-direction">
              <span>
                {props?.selectedValue?.alternatePartNumber ? props?.selectedValue?.alternatePartNumber : "N/A"}
              </span>
            </td>
          </tr> */}
          <tr>
            <td className="aviation-view-modal aviation-view-direction">
              <span>Part Type</span>
            </td>
            <td>:</td>
            <td className="aviation-view-modal1 aviation-view-direction">
              <span>{props?.selectedValue?.partType ? props?.selectedValue?.partType : "N/A"}</span>
            </td>
          </tr>
          <tr>
            <td className="aviation-view-modal aviation-view-direction">
              <span>Plane Model</span>
            </td>
            <td>:</td>
            <td className="aviation-view-modal1 aviation-view-direction">
              <span>
                {props?.selectedValue?.planeModels?.length > 0
                  ? props?.selectedValue?.planeModels?.map((val) => val).join(", ")
                  : props?.selectedValue?.planeModels?.length != 0
                  ? props?.selectedValue?.planeModels
                  : "N/A"}
              </span>
            </td>
          </tr>
          <tr>
            <td className="aviation-view-modal aviation-view-direction">
              <span>Plane Manufacturer</span>
            </td>
            <td>:</td>
            <td className="aviation-view-modal1 aviation-view-direction">
              <span>{props?.selectedValue?.planeManufacturer ? props?.selectedValue?.planeManufacturer : "N/A"}</span>
            </td>
          </tr>
          <tr>
            <td className="aviation-view-modal aviation-view-direction">
              <span>Part Manufacturer</span>
            </td>
            <td>:</td>
            <td className="aviation-view-modal1 aviation-view-direction">
              <span>{props?.selectedValue?.partManufacturer ? props?.selectedValue?.partManufacturer : "N/A"}</span>
            </td>
          </tr>

          {/* <tr>
            <td className="aviation-view-modal aviation-view-direction">
              <span>Current Selling Price</span>
            </td>
            <td>:</td>
            <td className="aviation-view-modal1 aviation-view-direction">
              <span>
                {props.selectedValue?.availablePrice
                  ? "$" + new Intl.NumberFormat("en-US").format(props.selectedValue?.availablePrice)
                  : "$0"}
              </span>{" "}
            </td>
          </tr>
          <tr>
            <td className="aviation-view-modal aviation-view-direction">
              <span>Wholesale Price</span>
            </td>
            <td>:</td>
            <td className="aviation-view-modal1 aviation-view-direction">
              <span>
                {props.selectedValue?.wholesalePrice
                  ? "$" + new Intl.NumberFormat("en-US").format(props.selectedValue?.wholesalePrice)
                  : "$0"}
              </span>
            </td>
          </tr>
          <tr>
            <td className="aviation-view-modal aviation-view-direction">
              <span>Part Manufacturer</span>
            </td>
            <td>:</td>
            <td className="aviation-view-modal1 aviation-view-direction">
              <span>{props.selectedValue?.partManufacturer ? props.selectedValue?.partManufacturer : "N/A"}</span>
            </td>
          </tr>
          <tr>
            <td className="aviation-view-modal aviation-view-direction">
              <span>Plane Model</span>
            </td>
            <td>:</td>
            <td className="aviation-view-modal1 aviation-view-direction">
              <span>
                {props.selectedValue?.planeModels && props.selectedValue?.planeModels?.length > 0
                  ? props.selectedValue?.planeModels?.map((item) => item).join(",")
                  : "N/A"}
              </span>
            </td>
          </tr>
          <tr>
            <td className="aviation-view-modal aviation-view-direction">
              <span>Part of the Plane</span>
            </td>
            <td>:</td>
            <td className="aviation-view-modal1 aviation-view-direction">
              <span>{props.selectedValue?.planePart ? props.selectedValue?.planePart : "N/A"}</span>
            </td>
          </tr> */}
          {/* <tr>
            <td className="aviation-view-modal aviation-view-direction">
              <span>Tag Date</span>
            </td>
            <td>:</td>
            <td className="aviation-view-modal1 aviation-view-direction">
              <span>
                {props.selectedValue?.tagDate ? moment(props.selectedValue?.tagDate).format("MM/DD/YYYY") : "N/A"}
              </span>
            </td>
          </tr> */}
          {/* <tr>
            <td className="aviation-view-modal aviation-view-direction">
              <span>Location/Region</span>
            </td>
            <td>:</td>
            <td className="aviation-view-modal1 aviation-view-direction">
              <span>{props.selectedValue?.location ? props.selectedValue?.location : "N/A"}</span>
            </td>
          </tr> */}
          {/* <tr>
            <td className="aviation-view-modal aviation-view-direction">
              <span>Lead Time (In Days)</span>{" "}
            </td>
            <td>:</td>
            <td className="aviation-view-modal1 aviation-view-direction">
              <span>{props.selectedValue?.leadTime ? props.selectedValue?.leadTime : "N/A"}</span>
            </td>
          </tr>
          <tr>
            <td className="aviation-view-modal aviation-view-direction">
              <span>Insurance</span>
            </td>
            <td>:</td>
            <td className="aviation-view-modal1 aviation-view-direction">
              <span>
                {isBoolean(props?.selectedValue?.insurance) ? (props.selectedValue?.insurance ? "Yes" : "No") : "N/A"}
              </span>
            </td>
          </tr>
          <tr>
            <td className="aviation-view-modal aviation-view-direction">
              <span>Shipping</span>
            </td>
            <td>:</td>
            <td className="aviation-view-modal1 aviation-view-direction">
              <span>
                {props.selectedValue?.shipping == "Yes" ? "Yes" : props.selectedValue?.shipping == "No" ? "No" : "N/A"}
              </span>
            </td>
          </tr>
          <tr>
            <td className="aviation-view-modal aviation-view-direction">
              <span>Warranty</span>
            </td>
            <td>:</td>
            <td className="aviation-view-modal1 aviation-view-direction">
              <span>
                {isBoolean(props?.selectedValue?.warranty) ? (props.selectedValue?.warranty ? "Yes" : "No") : "N/A"}
              </span>
            </td>
          </tr> */}
        </table>
      </Modal.Body>
    </Modal>
  );
};

export default ViewDetails;
