import moment from "moment";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  getListings,
  getMotivationLog,
  getPreMotivation,
  getSellerMotivation,
  updateQuickSummary,
} from "../../../store/listings";
import Skeleton from "react-loading-skeleton";
import SellerMotivationModal from "../../../layouts/modals/sellerMotivationModal.jsx/sellerMotivationModal";
import AlertError from "../../../common/alerts/alertError";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import { toast } from "react-toastify";
import HTMLReactParser from "html-react-parser";
import { isNull } from "lodash";
import { updateSellerMotivationCheckbox } from "../../../store/buyerInterests";
import DateFormat from "../../../common/DateFormat/dateFormat";
import PreSellerMotivationModal from "../../../layouts/modals/sellerMotivationModal.jsx/preSellerMotivationModal";
import { primarySellerData } from "../../../store/primarySeller";

const SellerMotivation = (props) => {
  const data =
    (props.getListings && props.getListings.sellerMotivation && props.getListings.sellerMotivation.data) || {};
  const userId = props?.primarySellerData?.primarySeller?.data?.user?.s_user_id;

  const [showQuickSummary, setShowQuickSummary] = useState(false);
  const [loadingQuickSummary, setLoadingQuickSummary] = useState(false);

  const [showPreMotivation, setShowPreMotivation] = useState(false);
  const [preMotivaitonData, setPreMotivationData] = useState({});
  const togglePreMotivation = () => {
    setShowPreMotivation(!showPreMotivation);
  };

  //Log State
  const [log1, setLog1] = useState({});
  const [log2, setLog2] = useState({});
  const [log3, setLog3] = useState({});
  const [log4, setLog4] = useState({});
  const [log5, setLog5] = useState({});
  const [log6, setLog6] = useState({});
  const [log7, setLog7] = useState({});
  const [log8, setLog8] = useState({});
  const [log9, setLog9] = useState({});
  const [log10, setLog10] = useState({});
  const [log11, setLog11] = useState({});

  const [values, setValues] = useState({
    information_text_1: "",
    information_text_2: "",
    information_text_3: "",
    information_text_4: "",
    information_text_5: "",
    information_text_6: "",
    information_text_7: "",
    information_text_8: "",
    information_text_9: "",
    information_text_10: "",
    information_text_11: "",
    inventory_followup: "",
    timeline_followup: "",
  });

  //Checkbox State.
  const [updateCheckbox, setUpdateCheckbox] = useState({
    action: "save_checkbox_user_seller_motivation",
    value: "",
    check: 1,
    len: 1,
    dontName: "",
    date: "",
  });
  //Deadline Date State.
  const [date, setDate] = useState({
    action: "save_sales_deadline_date",
    value: "",
    check: "",
    len: "",
    dontName: "",
    date: data?.variables?.find((item) => item.type === "date_1")?.motivation
      ? data?.variables?.find((item) => item.type === "date_1")?.motivation
      : Date.now(),
  });
  useEffect(() => {
    setDate({
      action: "save_sales_deadline_date",
      value: "",
      check: "",
      len: "",
      dontName: "",
      date: data?.variables?.find((item) => item.type === "date_1")?.motivation
        ? new Date(data?.variables?.find((item) => item.type === "date_1")?.motivation)
        : Date.now(),
    });
  }, []);
  const [show, setShow] = useState(false);

  const detail =
    (props.getListings && props.getListings.sellerMotivation && props.getListings.sellerMotivation.data) || {};

  const s_ID =
    props.getListings &&
    props.getListings.sellerMotivation &&
    props.getListings.sellerMotivation.data &&
    props.getListings.sellerMotivation.data.s_user_id;

  const toggleQuickSummaryModal = () => {
    setShowQuickSummary(!showQuickSummary);
  };

  const handleSubmit = () => {
    setLoadingQuickSummary(true);
    props.refStart();
    const id = s_ID;
    const data = { ...values };
    data.inventory_followup = new Date(data?.inventory_followup);
    data.information_id = [
      "Ownership/Seller Broker",
      "Sale Deadlines/Timelines",
      "Product Line Discussion",
      "Geographic Market Sale Options",
      "Location Loading and Logistics",
      "Product Markets Discussion",
      "Asset Pricing Discussion",
      "Vendor/Exclusive Contracts",
      "Insured Bidding",
      "Seller Communications",
      "Other Information",
    ];
    props.updateQuickSummary(userId, data, (res) => {
      if (res.status === 200) {
        const response = { response: data };
        props.getSellerMotivation(props.auctionId, (res) => {
          if (res.status === 200) {
            setLoadingQuickSummary(false);
            props.refStop();
            toast(<AlertSuccess message={"Information Saved"} />);
            toggleQuickSummaryModal();
          } else {
            setLoadingQuickSummary(false);
            props.refStop();
            toast(
              <AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />,
            );
          }
        });
      } else {
        setLoadingQuickSummary(false);
        props.refStop();
        toast(<AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  //Update Seller Motivation Checkbox
  useEffect(() => {
    if (props.auctionId) {
      props.refStart();
      props.updateSellerMotivationCheckbox(userId, updateCheckbox, (res) => {
        if (res.status === 200) {
          props.getSellerMotivation(props.auctionId, (res) => {
            if (res.status === 200) {
              props.refStop();
            } else {
              props.refStop();
              toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
            }
          });
        } else {
          props.refStop();
          toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        }
      });
    }
  }, [updateCheckbox]);

  //Update Date
  useEffect(() => {
    if ((props.auctionId, showQuickSummary)) {
      props.refStart();
      props.updateSellerMotivationCheckbox(userId, date, (res) => {
        if (res.status === 200) {
          props.getSellerMotivation(props.auctionId, (res) => {
            if (res.status === 200) {
              props.refStop();
            } else {
              props.refStop();
              toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
            }
          });
        } else {
          props.refStop();
          toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        }
      });
    }
  }, [date]);

  useEffect(() => {
    if (showQuickSummary) {
      const summaryValue = {};
      summaryValue.information_text_1 = data.information_text_1 ? data.information_text_1 : "";
      summaryValue.information_text_2 = data.information_text_2 ? data.information_text_2 : "";
      summaryValue.information_text_3 = data.information_text_3 ? data.information_text_3 : "";
      summaryValue.information_text_4 = data?.variables?.find((item) => item.type === "Salvex_Control_of_Sale")
        ?.motivation
        ? data?.variables?.find((item) => item.type === "Salvex_Control_of_Sale")?.motivation
        : "";
      summaryValue.information_text_5 = data.information_text_5 ? data.information_text_5 : "";
      summaryValue.information_text_6 = data.information_text_6 ? data.information_text_6 : "";
      summaryValue.information_text_7 = data.information_text_11 ? data.information_text_11 : "";
      summaryValue.information_text_8 = data.information_text_8 ? data.information_text_8 : "";
      summaryValue.information_text_9 = data.information_text_9 ? data.information_text_9 : "";
      summaryValue.information_text_10 = data.information_text_10 ? data.information_text_10 : "";
      summaryValue.information_text_11 = data?.variables?.find((item) => item.type === "Other_Information")?.motivation
        ? data?.variables?.find((item) => item.type === "Other_Information")?.motivation
        : "";
      summaryValue.inventory_followup = data?.variables?.find((item) => item.type === "inventory_followup")?.motivation
        ? new Date(data?.variables?.find((item) => item.type === "inventory_followup")?.motivation)
        : Date.now();
      summaryValue.timeline_followup = data?.variables?.find((item) => item.type === "timeline_followup")?.motivation
        ? new Date(data?.variables?.find((item) => item.type === "timeline_followup")?.motivation)
        : data?.variables
            ?.find((item) => item.type === "timeline_followup")
            ?.motivation?.setDate(
              data?.variables?.find((item) => item.type === "timeline_followup")?.motivation?.getDate() + 60,
            );
      setValues({ ...summaryValue });
    }
  }, [showQuickSummary]);

  const preMotivationFetch = (date) => {
    props.refStart();
    props.getPreMotivation(props.auctionId, userId, date, (res) => {
      if (res.status === 200) {
        setPreMotivationData(res.data.data);
        props.refStop();
        togglePreMotivation();
      } else {
        props.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const data1 = {
    type: ["checkbox_2,checkbox_3,checkbox_26,checkbox_28,checkbox_29,checkbox_30,checkbox_66,checkbox_49"],
  };
  const data2 = {
    type: ["checkbox_5,checkbox_6,checkbox_7,checkbox_8"],
  };
  const data3 = {
    type: ["checkbox_10,checkbox_11,checkbox_12"],
  };
  const data4 = {
    type: ["checkbox_68,checkbox_69,checkbox_70,checkbox_71,checkbox_72,checkbox_73"],
  };
  const data5 = {
    type: ["checkbox_61,checkbox_32,checkbox_62,checkbox_63,checkbox_33,checkbox_64"],
  };
  const data6 = {
    type: ["checkbox_42,checkbox_43,checkbox_44,checkbox_45"],
  };
  const data7 = {
    type: ["checkbox_27,checkbox_47,checkbox_48,checkbox_58,checkbox_59,checkbox_60"],
  };
  const data8 = {
    type: ["checkbox_25,checkbox_14,checkbox_15,checkbox_16"],
  };
  const data9 = {
    type: ["checkbox_18,checkbox_54,checkbox_19,checkbox_20,checkbox_21,checkbox_55,checkbox_56"],
  };
  const data10 = {
    type: ["checkbox_22,checkbox_23,checkbox_24,checkbox_51,checkbox_52"],
  };
  const data11 = {
    type: ["checkbox_35,checkbox_36,checkbox_37,checkbox_38,checkbox_39,checkbox_40,checkbox_50"],
  };

  useEffect(() => {
    if (userId) {
      // props.getMotivationLog(data.s_user_id, data1, (res) => {
      //   if (res.status === 200) {
      //     setLog1(res.data.data);
      //   }
      // });
      // props.getMotivationLog(data.s_user_id, data2, (res) => {
      //   if (res.status === 200) {
      //     setLog2(res.data.data);
      //   }
      // });
      // props.getMotivationLog(data.s_user_id, data3, (res) => {
      //   if (res.status === 200) {
      //     setLog3(res.data.data);
      //   }
      // });
      // props.getMotivationLog(data.s_user_id, data4, (res) => {
      //   if (res.status === 200) {
      //     setLog4(res.data.data);
      //   }
      // });
      props.getMotivationLog(userId, data5, (res) => {
        if (res.status === 200) {
          setLog5(res.data.data);
        }
      });
      // props.getMotivationLog(data.s_user_id, data6, (res) => {
      //   if (res.status === 200) {
      //     setLog6(res.data.data);
      //   }
      // });
      // props.getMotivationLog(data.s_user_id, data7, (res) => {
      //   if (res.status === 200) {
      //     setLog7(res.data.data);
      //   }
      // });
      // props.getMotivationLog(data.s_user_id, data8, (res) => {
      //   if (res.status === 200) {
      //     setLog8(res.data.data);
      //   }
      // });
      // props.getMotivationLog(data.s_user_id, data9, (res) => {
      //   if (res.status === 200) {
      //     setLog9(res.data.data);
      //   }
      // });
      // props.getMotivationLog(data.s_user_id, data10, (res) => {
      //   if (res.status === 200) {
      //     setLog10(res.data.data);
      //   }
      // });
      // props.getMotivationLog(data.s_user_id, data11, (res) => {
      //   if (res.status === 200) {
      //     setLog11(res.data.data);
      //   }
      // });
    }
  }, [data]);
  return (
    <>
      <div class="page-widget-head d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <div class="d-flex align-items-center">
            <div
              class="page-widget-label text-capitalize cursor-pointer"
              onClick={() => {
                if (show && !props.loadingMain) {
                  toggleQuickSummaryModal();
                } else if (!show && !props.loadingMain) {
                  setShow(!show);
                }
              }}
            >
              Seller Motivation
            </div>
          </div>
          <li className="badge-item cursor-pointer" onClick={() => setShow(!show)} style={{ marginLeft: "10px" }}>
            <div className="badge-box" style={{ background: "#DADEE0" }}>
              {show ? "Hide" : "Show"}
            </div>
          </li>
        </div>
      </div>
      {show ? (
        <div class="table-responsive" style={{ marginTop: "1rem" }}>
          <table class="table align-middle product-table">
            <colgroup>
              <col style={{ width: "36%" }} />
              <col />
            </colgroup>
            <tbody>
              {props.loadingMain ? (
                <>
                  <tr>
                    <td>
                      <span class="fw-medium">
                        <Skeleton />
                      </span>
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span class="fw-medium">
                        <Skeleton />
                      </span>
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span class="fw-medium">
                        <Skeleton />
                      </span>
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span class="fw-medium">
                        <Skeleton />
                      </span>
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span class="fw-medium">
                        <Skeleton />
                      </span>
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span class="fw-medium">
                        <Skeleton />
                      </span>
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span class="fw-medium">
                        <Skeleton />
                      </span>
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span class="fw-medium">
                        <Skeleton />
                      </span>
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span class="fw-medium">
                        <Skeleton />
                      </span>
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span class="fw-medium">
                        <Skeleton />
                      </span>
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span class="fw-medium">
                        <Skeleton />
                      </span>
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span class="fw-medium">
                        <Skeleton />
                      </span>
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span class="fw-medium">
                        <Skeleton />
                      </span>
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span class="fw-medium">
                        <Skeleton />
                      </span>
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                </>
              ) : (
                <>
                  <tr
                    className={
                      data?.variables?.find((item) => item.type === "checkbox_3")?.motivation == 1 &&
                      "bg-danger text-white"
                    }
                  >
                    <td
                      className={
                        data?.variables?.find((item) => item.type === "checkbox_3")?.motivation == 1 && "bg-danger"
                      }
                    >
                      <span class="fw-medium">Ownership Investigation</span>
                    </td>
                    <td>
                      <div>
                        {!(
                          data?.variables?.find((item) => item.type === "checkbox_2")?.motivation == 1 ||
                          data?.variables?.find((item) => item.type === "checkbox_3")?.motivation == 1 ||
                          data?.variables?.find((item) => item.type === "checkbox_26")?.motivation == 1 ||
                          data?.variables?.find((item) => item.type === "checkbox_28")?.motivation == 1 ||
                          data?.variables?.find((item) => item.type === "checkbox_29")?.motivation == 1 ||
                          data?.variables?.find((item) => item.type === "checkbox_30")?.motivation == 1 ||
                          data?.variables?.find((item) => item.type === "checkbox_66")?.motivation == 1 ||
                          data?.variables?.find((item) => item.type === "checkbox_49")?.motivation == 1
                        ) && "Don't Know"}
                      </div>
                      <div>
                        {data?.variables?.find((item) => item.type === "checkbox_2")?.motivation == 1 &&
                          "Owner (Asset Disposal)"}
                      </div>
                      <div>
                        {data?.variables?.find((item) => item.type === "checkbox_26")?.motivation == 1 &&
                          "Owner (Reseller)"}
                      </div>
                      <div>
                        {data?.variables?.find((item) => item.type === "checkbox_3")?.motivation == 1 &&
                          "No Ownership (Seller Broker)"}
                      </div>
                      <div>
                        {data?.variables?.find((item) => item.type === "checkbox_28")?.motivation == 1 &&
                          "Insurance Represetative (Salvage)"}
                      </div>
                      <div>
                        {data?.variables?.find((item) => item.type === "checkbox_29")?.motivation == 1 &&
                          "Owner (Salvage)"}
                      </div>
                      <div>
                        {data?.variables?.find((item) => item.type === "checkbox_30")?.motivation == 1 &&
                          "Warehouse/Location (Abandoned Cargo)"}
                      </div>
                      <div>
                        {data?.variables?.find((item) => item.type === "checkbox_66")?.motivation == 1 &&
                          "Freight Forwarder (Abandoned / Damaged Cargo)"}
                      </div>
                      <div>
                        {data?.variables?.find((item) => item.type === "checkbox_49")?.motivation == 1 &&
                          "Owner (Manufacturer)"}
                      </div>
                      {data?.variables?.find((item) => item.type === "Ownership_Seller_Broker")?.motivation
                        ? data?.variables?.find((item) => item.type === "Ownership_Seller_Broker")?.motivation
                        : "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span class="fw-medium">Timeline for Sale</span>
                    </td>
                    <td>
                      <div>
                        {!(
                          data?.variables?.find((item) => item.type === "checkbox_5")?.motivation == 1 ||
                          data?.variables?.find((item) => item.type === "checkbox_6")?.motivation == 1 ||
                          data?.variables?.find((item) => item.type === "checkbox_7")?.motivation == 1 ||
                          data?.variables?.find((item) => item.type === "checkbox_8")?.motivation == 1 ||
                          data?.variables?.find((item) => item.type === "checkbox_65")?.motivation == 1
                        ) && "Don't Know"}
                      </div>
                      <div>
                        {data?.variables?.find((item) => item.type === "checkbox_5")?.motivation == 1 && "Immediately"}
                      </div>
                      <div>
                        {data?.variables?.find((item) => item.type === "checkbox_6")?.motivation == 1 &&
                          "Less Than 1 Month"}
                      </div>
                      <div>
                        {data?.variables?.find((item) => item.type === "checkbox_7")?.motivation == 1 &&
                          "Less Than 3 Months"}
                      </div>
                      <div>
                        {data?.variables?.find((item) => item.type === "checkbox_8")?.motivation == 1 && "No Timeline"}
                      </div>
                      <div>
                        {data?.variables?.find((item) => item.type === "checkbox_29")?.motivation == 1 &&
                          "Owner (Salvage)"}
                      </div>
                      <div>
                        {data?.variables?.find((item) => item.type === "checkbox_30")?.motivation == 1 &&
                          "Warehouse/Location (Abandoned Cargo)"}
                      </div>
                      {data?.variables?.find((item) => item.type === "date_1")?.motivation &&
                        data?.variables?.find((item) => item.type === "date_1")?.motivation != "" && (
                          <div>
                            Date:{" "}
                            {moment(data?.variables?.find((item) => item.type === "date_1")?.motivation).format(
                              "YYYY-MM-DD",
                            )}
                          </div>
                        )}

                      {data?.variables?.find((item) => item.type === "Sale_Deadlines_Timelines")?.motivation
                        ? data?.variables?.find((item) => item.type === "Sale_Deadlines_Timelines")?.motivation
                        : "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span class="fw-medium">Inventory Investigation</span>
                    </td>
                    <td>
                      <div>
                        {!(
                          data?.variables?.find((item) => item.type === "checkbox_10")?.motivation == 1 ||
                          data?.variables?.find((item) => item.type === "checkbox_11")?.motivation == 1 ||
                          data?.variables?.find((item) => item.type === "checkbox_12")?.motivation == 1 ||
                          data?.variables?.find((item) => item.type === "checkbox_53")?.motivation == 1
                        ) || data?.variables?.find((item) => item.type === "checkbox_9")?.motivation == 1
                          ? "Don't Know"
                          : ""}
                      </div>
                      <div>
                        {data?.variables?.find((item) => item.type === "checkbox_10")?.motivation == 1 &&
                          "Pilot Listing Given Only"}
                      </div>
                      <div>
                        {data?.variables?.find((item) => item.type === "checkbox_11")?.motivation == 1 &&
                          "Partial Inventory Given"}
                      </div>
                      <div>
                        {data?.variables?.find((item) => item.type === "checkbox_12")?.motivation == 1 &&
                          "All Inventory Given"}
                      </div>
                      {/* <div>
                      {data?.variables?.find((item) => item.type ===
                        "checkbox_8")?.motivation ==1 && "No Timeline"}
                    </div> */}
                      {data?.variables?.find((item) => item.type === "timeline_followup")?.motivation &&
                        data?.variables?.find((item) => item.type === "timeline_followup")?.motivation != "" && (
                          <div>
                            Inventory Follow Up Date:{" "}
                            {moment(
                              data?.variables?.find((item) => item.type === "timeline_followup")?.motivation,
                            ).format("YYYY-MM-DD")}
                          </div>
                        )}
                      {data?.variables?.find((item) => item.type === "Product_Line_Discussion")?.motivation
                        ? data?.variables?.find((item) => item.type === "Product_Line_Discussion")?.motivation
                        : "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span class="fw-medium">Salvex Control of Sale</span>
                    </td>
                    <td>
                      <div>
                        {!(
                          // data?.variables?.find((item) => item.type === "checkbox_67")?.motivation == 1 ||
                          (
                            data?.variables?.find((item) => item.type === "checkbox_68")?.motivation == 1 ||
                            data?.variables?.find((item) => item.type === "checkbox_69")?.motivation == 1 ||
                            data?.variables?.find((item) => item.type === "checkbox_70")?.motivation == 1 ||
                            data?.variables?.find((item) => item.type === "checkbox_71")?.motivation == 1 ||
                            data?.variables?.find((item) => item.type === "checkbox_72")?.motivation == 1 ||
                            data?.variables?.find((item) => item.type === "checkbox_73")?.motivation == 1
                          )
                        ) && "Don't Know"}
                      </div>
                      <div>
                        {data?.variables?.find((item) => item.type === "checkbox_68")?.motivation == 1 && "Quick Sale"}
                      </div>
                      <div>
                        {data?.variables?.find((item) => item.type === "checkbox_69")?.motivation == 1 &&
                          "Open Marketplace Buyer Control"}
                      </div>
                      <div>
                        {data?.variables?.find((item) => item.type === "checkbox_70")?.motivation == 1 &&
                          "Tender Software Buyer Control"}
                      </div>
                      <div>
                        {data?.variables?.find((item) => item.type === "checkbox_71")?.motivation == 1 &&
                          "Exclusive Contract Buyer Control"}
                      </div>
                      <div>
                        {data?.variables?.find((item) => item.type === "checkbox_72")?.motivation == 1 &&
                          "No Buyer Control"}
                      </div>
                      {data?.variables?.find((item) => item.type === "Salvex_Control_of_Sale")?.motivation
                        ? data?.variables?.find((item) => item.type === "Salvex_Control_of_Sale")?.motivation
                        : "N/A"}
                    </td>
                  </tr>
                  <tr
                    className={
                      (isNull(log5?.value?.length && log5?.value[0]) ||
                        data?.variables?.find((item) => item.type === "checkbox_31")?.motivation == 1) &&
                      "bg-danger text-white"
                    }
                  >
                    <td
                      className={
                        (isNull(log5?.value?.length && log5?.value[0]) ||
                          data?.variables?.find((item) => item.type === "checkbox_31")?.motivation == 1) &&
                        "bg-danger"
                      }
                    >
                      <span class="fw-medium">Seller Communications</span>
                    </td>
                    <td>
                      <div>
                        {(isNull(log5?.value?.length && log5?.value[0]) ||
                          data?.variables?.find((item) => item.type === "checkbox_31")?.motivation == 1) &&
                          "Don't Know"}
                      </div>
                      <div>
                        {data?.variables?.find((item) => item.type === "checkbox_61")?.motivation == 1 &&
                          "Priority Seller"}
                      </div>
                      <div>
                        {data?.variables?.find((item) => item.type === "checkbox_32")?.motivation == 1 &&
                          "Good Communication"}
                      </div>
                      <div>
                        {data?.variables?.find((item) => item.type === "checkbox_62")?.motivation == 1 &&
                          "Phone Preference"}
                      </div>
                      <div>
                        {data?.variables?.find((item) => item.type === "checkbox_63")?.motivation == 1 &&
                          "Email Preference"}
                      </div>
                      <div>
                        {data?.variables?.find((item) => item.type === "checkbox_64")?.motivation == 1 &&
                          "Text Preferencel"}
                      </div>
                      <div>
                        {data?.variables?.find((item) => item.type === "checkbox_33")?.motivation == 1 &&
                          "No Communication"}
                      </div>
                      {data?.variables?.find((item) => item.type === "Seller_Communications")?.motivation
                        ? data?.variables?.find((item) => item.type === "Seller_Communications").motivation
                        : "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span class="fw-medium">Competition</span>
                    </td>
                    <td>
                      <div>
                        {!(
                          data?.variables?.find((item) => item.type === "checkbox_42")?.motivation == 1 ||
                          data?.variables?.find((item) => item.type === "checkbox_43")?.motivation == 1 ||
                          data?.variables?.find((item) => item.type === "checkbox_44")?.motivation == 1 ||
                          data?.variables?.find((item) => item.type === "checkbox_45")?.motivation == 1
                        ) && "Don't Know"}
                      </div>
                      <div>
                        {data?.variables?.find((item) => item.type === "checkbox_42")?.motivation == 1 &&
                          "Insured Bidding"}
                      </div>
                      <div>
                        {data?.variables?.find((item) => item.type === "checkbox_43")?.motivation == 1 &&
                          "Insured Not Bidding"}
                      </div>
                      <div>
                        {data?.variables?.find((item) => item.type === "checkbox_44")?.motivation == 1 &&
                          "Distribution List"}
                      </div>
                      <div>
                        {data?.variables?.find((item) => item.type === "checkbox_45")?.motivation == 1 &&
                          "Other Websites"}
                      </div>
                      {data?.variables?.find((item) => item.type === "Insured_Bidding")?.motivation
                        ? data?.variables?.find((item) => item.type === "Insured_Bidding")?.motivation
                        : "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span class="fw-medium">Seller Legal & Contracts</span>
                    </td>
                    <td>
                      <div>
                        {!(
                          data?.variables?.find((item) => item.type === "checkbox_27")?.motivation == 1 ||
                          data?.variables?.find((item) => item.type === "checkbox_47")?.motivation == 1 ||
                          data?.variables?.find((item) => item.type === "checkbox_48")?.motivation == 1 ||
                          data?.variables?.find((item) => item.type === "checkbox_57")?.motivation == 1 ||
                          data?.variables?.find((item) => item.type === "checkbox_58")?.motivation == 1 ||
                          data?.variables?.find((item) => item.type === "checkbox_59")?.motivation == 1 ||
                          data?.variables?.find((item) => item.type === "checkbox_60")?.motivation == 1
                        ) && "Don't Know"}
                      </div>
                      <div>
                        {data?.variables?.find((item) => item.type === "checkbox_27")?.motivation == 1 && "Tender"}
                      </div>
                      <div>
                        {data?.variables?.find((item) => item.type === "checkbox_47")?.motivation == 1 &&
                          "Vendor Agreement Required"}
                      </div>
                      <div>
                        {data?.variables?.find((item) => item.type === "checkbox_57")?.motivation == 1 &&
                          "Vendor Agreement Signed"}
                      </div>
                      <div>
                        {data?.variables?.find((item) => item.type === "checkbox_48")?.motivation == 1 &&
                          "Service Contract Required"}
                      </div>
                      <div>
                        {data?.variables?.find((item) => item.type === "checkbox_58")?.motivation == 1 &&
                          "Service Contract Signed"}
                      </div>
                      <div>
                        {data?.variables?.find((item) => item.type === "checkbox_59")?.motivation == 1 &&
                          "Seller Verification Required"}
                      </div>
                      <div>
                        {data?.variables?.find((item) => item.type === "checkbox_60")?.motivation == 1 &&
                          "Seller Verification Complete"}
                      </div>

                      {data?.variables?.find((item) => item.type === "Vendor_Exclusive_Contracts")?.motivation
                        ? data?.variables?.find((item) => item.type === "Vendor_Exclusive_Contracts")?.motivation
                        : "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span class="fw-medium">Geographic Market Sale Options</span>
                    </td>
                    <td>
                      <div>
                        {!(
                          data?.variables?.find((item) => item.type === "checkbox_25")?.motivation == 1 ||
                          data?.variables?.find((item) => item.type === "checkbox_14")?.motivation == 1 ||
                          data?.variables?.find((item) => item.type === "checkbox_15")?.motivation == 1 ||
                          data?.variables?.find((item) => item.type === "checkbox_16")?.motivation == 1
                        ) && "Don't Know"}
                      </div>
                      <div>
                        {data?.variables?.find((item) => item.type === "checkbox_25")?.motivation == 1 &&
                          "All Locations"}
                      </div>
                      <div>
                        {data?.variables?.find((item) => item.type === "checkbox_14")?.motivation == 1 &&
                          "All Locations Some Restrictions"}
                      </div>
                      <div>
                        {data?.variables?.find((item) => item.type === "checkbox_15")?.motivation == 1 && "Local Only"}
                      </div>
                      <div>
                        {data?.variables?.find((item) => item.type === "checkbox_16")?.motivation == 1 && "Export Only"}
                      </div>
                      {data?.variables?.find((item) => item.type === "Geographic_Market_Sale_Options")?.motivation
                        ? data?.variables?.find((item) => item.type === "Geographic_Market_Sale_Options")?.motivation
                        : "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span class="fw-medium">Location, Loading and Logistics</span>
                    </td>
                    <td>
                      <div>
                        {!(
                          data?.variables?.find((item) => item.type === "checkbox_18")?.motivation == 1 ||
                          data?.variables?.find((item) => item.type === "checkbox_19")?.motivation == 1 ||
                          data?.variables?.find((item) => item.type === "checkbox_20")?.motivation == 1 ||
                          data?.variables?.find((item) => item.type === "checkbox_21")?.motivation == 1 ||
                          data?.variables?.find((item) => item.type === "checkbox_54")?.motivation == 1 ||
                          data?.variables?.find((item) => item.type === "checkbox_55")?.motivation == 1 ||
                          data?.variables?.find((item) => item.type === "checkbox_56")?.motivation == 1
                        ) && "Don't Know"}
                      </div>
                      <div>
                        {data?.variables?.find((item) => item.type === "checkbox_18")?.motivation == 1 && "Ex Works"}
                      </div>
                      <div>
                        {data?.variables?.find((item) => item.type === "checkbox_54")?.motivation == 1 &&
                          "Location Loading (By Seller)"}
                      </div>
                      <div>
                        {data?.variables?.find((item) => item.type === "checkbox_19")?.motivation == 1 &&
                          "Location Loading (Buyer Cost)"}
                      </div>
                      <div>
                        {data?.variables?.find((item) => item.type === "checkbox_20")?.motivation == 1 &&
                          "No Location Loading Support"}
                      </div>
                      <div>
                        {data?.variables?.find((item) => item.type === "checkbox_55")?.motivation == 1 &&
                          "Trucking (By Seller)"}
                      </div>
                      <div>
                        {data?.variables?.find((item) => item.type === "checkbox_56")?.motivation == 1 &&
                          "Customs Clearance (By Seller)"}
                      </div>
                      <div>
                        {data?.variables?.find((item) => item.type === "checkbox_21")?.motivation == 1 &&
                          "Multiple Locations"}
                      </div>

                      {data?.variables?.find((item) => item.type === "Location_Loading_and_Logistics")?.motivation
                        ? data?.variables?.find((item) => item.type === "Location_Loading_and_Logistics")?.motivation
                        : "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span class="fw-medium">Product Markets Investigation</span>
                    </td>
                    <td>
                      <div>
                        {!(
                          data?.variables?.find((item) => item.type === "checkbox_23")?.motivation == 1 ||
                          data?.variables?.find((item) => item.type === "checkbox_24")?.motivation == 1 ||
                          data?.variables?.find((item) => item.type === "checkbox_22")?.motivation == 1
                        ) && "Don't Know"}
                      </div>
                      <div>
                        {data?.variables?.find((item) => item.type === "checkbox_23")?.motivation == 1 &&
                          "Original Use"}
                      </div>
                      <div>
                        {data?.variables?.find((item) => item.type === "checkbox_24")?.motivation == 1 &&
                          "Repurposing Use"}
                      </div>
                      <div>
                        {data?.variables?.find((item) => item.type === "checkbox_51")?.motivation == 1 && "Lot Sale"}
                      </div>
                      <div>
                        {data?.variables?.find((item) => item.type === "checkbox_52")?.motivation == 1 &&
                          "Seller as Individual Listing"}
                      </div>

                      {data?.variables?.find((item) => item.type === "Product_Markets_Discussion")?.motivation
                        ? data?.variables?.find((item) => item.type === "Product_Markets_Discussion")?.motivation
                        : "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <span class="fw-medium">Asset Pricing Discussion</span>
                    </td>
                    <td>
                      <div>
                        {!(
                          data?.variables?.find((item) => item.type === "checkbox_35")?.motivation == 1 ||
                          data?.variables?.find((item) => item.type === "checkbox_36")?.motivation == 1 ||
                          data?.variables?.find((item) => item.type === "checkbox_37")?.motivation == 1 ||
                          data?.variables?.find((item) => item.type === "checkbox_38")?.motivation == 1 ||
                          data?.variables?.find((item) => item.type === "checkbox_39")?.motivation == 1 ||
                          data?.variables?.find((item) => item.type === "checkbox_40")?.motivation == 1
                        ) && "Don't Know"}
                      </div>
                      <div>
                        {data?.variables?.find((item) => item.type === "checkbox_35")?.motivation == 1 &&
                          "Less than 20% Wholesale Value"}
                      </div>
                      <div>
                        {data?.variables?.find((item) => item.type === "checkbox_36")?.motivation == 1 &&
                          "20%-40% Wholesale Value"}
                      </div>
                      <div>
                        {data?.variables?.find((item) => item.type === "checkbox_37")?.motivation == 1 &&
                          "40%-60% Wholesale Value"}
                      </div>
                      <div>
                        {data?.variables?.find((item) => item.type === "checkbox_38")?.motivation == 1 &&
                          "60%-80% Wholesale Value"}
                      </div>
                      <div>
                        {data?.variables?.find((item) => item.type === "checkbox_39")?.motivation == 1 &&
                          "80%-100% Wholesale Value"}
                      </div>
                      <div>
                        {data?.variables?.find((item) => item.type === "checkbox_40")?.motivation == 1 &&
                          "100% + Wholesale Value"}
                      </div>
                      <div>
                        {data?.variables?.find((item) => item.type === "checkbox_50")?.motivation == 1 && "No Pricing"}
                      </div>

                      {data?.variables?.find((item) => item.type === "Asset_Pricing_Discussion")?.motivation
                        ? data?.variables?.find((item) => item.type === "Asset_Pricing_Discussion")?.motivation
                        : "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span class="fw-medium">Other Information</span>
                    </td>
                    <td>
                      {data?.variables?.find((item) => item.type === "Other_Information")?.motivation ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: data?.variables?.find((item) => item.type === "Other_Information")?.motivation,
                          }}
                        />
                      ) : (
                        "N/A"
                      )}
                    </td>
                    {/* <td>{data.information_text_11 ? HTMLReactParser(data.information_text_11) : "N/A"}</td> */}
                  </tr>

                  <tr>
                    <td>
                      <span class="fw-medium">Last Updated At</span>
                    </td>
                    <td>
                      {data.prev_seller_motivation_updates &&
                      data.prev_seller_motivation_updates.length > 0 &&
                      data.prev_seller_motivation_updates[0]?.updatedAt
                        ? moment(data.prev_seller_motivation_updates[0]?.updatedAt).format("MM/DD/YYYY hh:mm A") +
                          " CST"
                        : "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span class="fw-medium">Last Updated By</span>
                    </td>
                    <td>
                      {data.prev_seller_motivation_updates &&
                      data.prev_seller_motivation_updates.length > 0 &&
                      data.prev_seller_motivation_updates[0]?.updateBy
                        ? data.prev_seller_motivation_updates[0]?.updateBy
                        : "N/A"}
                    </td>
                  </tr>
                  {data.prev_seller_motivation_updates && data.prev_seller_motivation_updates.length == 0 ? (
                    <>
                      <tr>
                        <td>
                          <span class="fw-medium">Previous Seller Motivation</span>
                        </td>
                        <td>{"N/A"}</td>
                      </tr>
                    </>
                  ) : (
                    ""
                  )}
                </>
              )}
            </tbody>
          </table>
          {data.prev_seller_motivation_updates && data.prev_seller_motivation_updates.length > 0 ? (
            <>
              <div class="page-widget-label pb-1 "> Previous Seller Motivation Updates :</div>
              <div className="table-responsive seller-motivation-updates">
                <table className="table align-middle product-table">
                  <colgroup>
                    <col style={{ width: "65%" }} />
                    <col />
                    <col />
                  </colgroup>
                  <tbody>
                    {props.loadingMain ? (
                      <>
                        <tr>
                          <td>
                            <Skeleton />
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Skeleton />
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Skeleton />
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                        </tr>
                      </>
                    ) : data.prev_seller_motivation_updates && data.prev_seller_motivation_updates.length > 0 ? (
                      data.prev_seller_motivation_updates.map((val, idx) => {
                        return (
                          <>
                            <tr>
                              <td>{val.updatedAt && moment(val.updatedAt).format("MMM-DD-YYYY hh:mm A") + " CST"}</td>
                              <td>
                                {" "}
                                <p className="mt-2">{val.updateBy}</p>
                              </td>
                              <td>
                                <button className="green-btn-text" onClick={() => preMotivationFetch(val.updatedAt)}>
                                  More
                                </button>
                              </td>
                            </tr>
                          </>
                        );
                      })
                    ) : (
                      ""
                    )}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            ""
          )}

          <SellerMotivationModal
            show={showQuickSummary}
            onHide={toggleQuickSummaryModal}
            loadingQuickSummary={loadingQuickSummary}
            data={data}
            values={values}
            setValues={setValues}
            handleSubmit={handleSubmit}
            updateCheckbox={updateCheckbox}
            setUpdateCheckbox={setUpdateCheckbox}
            updateSellerMotivationCheckbox={updateSellerMotivationCheckbox}
            date={date}
            setDate={setDate}
            log1={log1}
            log2={log2}
            log3={log3}
            log4={log4}
            log5={log5}
            log6={log6}
            log7={log7}
            log8={log8}
            log9={log9}
            log10={log10}
            log11={log11}
          />
          <PreSellerMotivationModal
            show={showPreMotivation}
            onHide={togglePreMotivation}
            data={preMotivaitonData}
            values={preMotivaitonData}
            log1={log1}
            log2={log2}
            log3={log3}
            log4={log4}
            log5={log5}
            log6={log6}
            log7={log7}
            log8={log8}
            log9={log9}
            log10={log10}
            log11={log11}
          />
        </div>
      ) : (
        ""
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getSellerMotivation: (params, callback) => dispatch(getSellerMotivation(params, callback)),
  updateQuickSummary: (params, data, callback) => dispatch(updateQuickSummary(params, data, callback)),
  getMotivationLog: (params, data, callback) => dispatch(getMotivationLog(params, data, callback)),
  updateSellerMotivationCheckbox: (params, data, callback) =>
    dispatch(updateSellerMotivationCheckbox(params, data, callback)),
  getPreMotivation: (params, userId, date, callback) => dispatch(getPreMotivation(params, userId, date, callback)),
});

const mapStateToProps = (state) => ({
  getListings: getListings(state),
  primarySellerData: primarySellerData(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(SellerMotivation));
