import { createSelector, createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";

const slice = createSlice({
    name: "bids",
    initialState: {
        loading: false,
        brokerActivity: ""
    },
    reducers: {
        brokerActivityRequested: (state, action) => {
            state.loading = true;
        },
        brokerActivityRecieved: (state, action) => {
            state.brokerActivity = action.payload;
            state.loading = false;
        },
        brokerActivityFailed: (state, action) => {
            state.loading = false;
        }
    }
});

export const {
    brokerActivityRequested,
    brokerActivityRecieved,
    brokerActivityFailed,
} = slice.actions;
export default slice.reducer;

//Action Creator
const baseUrl = 'broker/broker';

//Get Broker Activity
export const getBrokerActivity = (params, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: `${baseUrl}/activity`,
            params,
            method: "get",
            callback,
            onStart: brokerActivityRequested.type,
            onSuccess: brokerActivityRecieved.type,
            onError: brokerActivityFailed.type,
        })
    )
}

export const brokerData = createSelector(
    (state) => state.entities.broker,
    (broker) => (broker)
)