import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";

const slice = createSlice({
  name: "listings",
  initialState: {
    loading: false,
    listings: [],
    detail: {},
    sellerMotivation: {},
    preSellerMotivaiton: {},
    category: {},
    categoryData: {},
    otherDeals: {},
    categoryDrpDown: {},
    aviationModel: ''
  },
  reducers: {
    listingsRequested: (misc, action) => {
      misc.loading = true;
    },
    listingsReceived: (misc, action) => {
      misc.listings = action.payload;
      misc.loading = false;
    },
    listingsRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    listingDetailRequested: (misc, action) => {
      misc.loading = true;
    },
    listingDetailReceived: (misc, action) => {
      misc.detail = action.payload;
      misc.loading = false;
    },
    listingDetailRequestFailed: (misc, action) => {
      misc.loading = false;
    },

    sellerMotivationRequested: (misc, action) => {
      misc.loading = true;
    },
    sellerMotivationReceived: (misc, action) => {
      misc.sellerMotivation = action.payload;
      misc.loading = false;
    },
    sellerMotivationFailed: (misc, action) => {
      misc.loading = false;
    },
    categoryPrefRequested: (misc, action) => {
      misc.loading = true;
    },
    categoryPrefReceived: (misc, action) => {
      misc.category = action.payload;
      misc.loading = false;
    },
    categoryPrefFailed: (misc, action) => {
      misc.loading = false;
    },
    categoryDrpDownRequested: (misc, action) => {
      misc.loading = true;
    },
    categoryDrpDownReceived: (misc, action) => {
      misc.categoryDrpDown = action.payload;
      misc.loading = false;
    },
    categoryDrpDownFailed: (misc, action) => {
      misc.loading = false;
    },
    categoryRequested: (misc, action) => {
      misc.loading = true;
    },
    categoryReceived: (misc, action) => {
      misc.categoryData = action.payload;
      misc.loading = false;
    },
    categoryFailed: (misc, action) => {
      misc.loading = false;
    },
    otherDealRequested: (misc, action) => {
      misc.loading = true;
    },
    otherDealReceived: (misc, action) => {
      misc.otherDeals = action.payload;
      misc.loading = false;
    },
    otherDealFailed: (misc, action) => {
      misc.loading = true;
    },
    activeListingRequested: (misc, action) => {
      misc.loading = true;
    },
    activeListingReceived: (misc, action) => {
      misc.otherDeals = action.payload;
      misc.loading = false;
    },
    activeListingFailed: (misc, action) => {
      misc.loading = true;
    },
    preSellerMotivaitonRequested: (misc, action) => {
      misc.loading = true;
    },
    preSellerMotivaitonReceived: (misc, action) => {
      misc.preSellerMotivaiton = action.payload;
      misc.loading = false;
    },
    preSellerMotivaitonFailed: (misc, action) => {
      misc.loading = true;
    },
    setAviationModel: (misc, action) => {
      misc.aviationModel = action.payload;
    }
  },
});

export const {
  listingsRequested,
  listingsReceived,
  listingsRequestFailed,
  listingDetailRequested,
  listingDetailReceived,
  listingDetailRequestFailed,
  sellerMotivationRequested,
  sellerMotivationReceived,
  sellerMotivationFailed,
  categoryPrefRequested,
  categoryPrefReceived,
  categoryPrefFailed,
  categoryRequested,
  categoryReceived,
  categoryFailed,
  otherDealRequested,
  otherDealReceived,
  otherDealFailed,
  activeListingRequested,
  activeListingFailed,
  activeListingReceived,
  preSellerMotivaitonRequested,
  preSellerMotivaitonFailed,
  preSellerMotivaitonReceived,
  categoryDrpDownRequested,
  categoryDrpDownReceived,
  categoryDrpDownFailed,
  setAviationModel
} = slice.actions;
export default slice.reducer;

// Action Creators
const searchListingsUrl = "auctionOther/auction/search";
const detailListingUrl = "auctionOther/auction/detail";
const sellerMotivationUrl = "sellerMotivation";
const baseUrl = "auctionOther";
const commonUrl = "common";
export const searchListings = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: searchListingsUrl,
      method: "get",
      params,
      callback,
      onStart: listingsRequested.type,
      onSuccess: listingsReceived.type,
      onError: listingsRequestFailed.type,
    }),
  );
};

export const detailListing = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${detailListingUrl}/${data}`,
      method: "get",
      callback,
      onStart: listingDetailRequested.type,
      onSuccess: listingDetailReceived.type,
      onError: listingDetailRequestFailed.type,
    }),
  );
};

export const getSellerMotivation = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${sellerMotivationUrl}/${data}`,
      method: "get",
      callback,
      onStart: sellerMotivationRequested.type,
      onSuccess: sellerMotivationReceived.type,
      onError: sellerMotivationFailed.type,
    }),
  );
};

export const updateQuickSummary = (params, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${sellerMotivationUrl}/${params}`,
      method: "PUT",
      data,
      callback,
    }),
  );
};

export const getCategoryPreferenacesDropdown = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `dashboard/other/listing/category/${data}`,
      method: "get",
      callback,
      onStart: categoryDrpDownRequested.type,
      onSuccess: categoryDrpDownReceived.type,
      onError: categoryDrpDownFailed.type,
    }),
  );
};

export const getCategoryPreferenaces = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `auctionOther/categoryPreference/${data}`,
      method: "get",
      callback,
      onStart: categoryPrefRequested.type,
      onSuccess: categoryPrefReceived.type,
      onError: categoryPrefFailed.type,
    }),
  );
};

export const getCategoryData = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${commonUrl}/category?auction_id=${data}`,
      method: "get",
      callback,
      onStart: categoryRequested.type,
      onSuccess: categoryReceived.type,
      onError: categoryFailed.type,
    }),
  );
};

export const updateCategoryPreference = (params, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/categoryPreference/${params}`,
      method: "PUT",
      data,
      callback,
    }),
  );
};

export const updateTitle = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `auctionDetail/detail/${id}`,
      method: "put",
      data,
      callback,
    }),
  );
};

//Get Motivation Log
export const getMotivationLog = (params, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${sellerMotivationUrl}/log/${params}`,
      method: "post",
      data,
      callback,
    }),
  );
};
//Other deal post message
export const otherDealsPostMsg = (params, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${sellerMotivationUrl}/otherDeal/message/${params}`,
      method: "post",
      data,
      callback,
    }),
  );
};
//get other deals
export const getOtherDeals = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${sellerMotivationUrl}/otherDeal/${data.auctionId}/${data.sId}`,
      method: "get",
      callback,
      onStart: otherDealRequested.type,
      onSuccess: otherDealReceived.type,
      onError: otherDealFailed.type,
    }),
  );
};
//get other deals
export const getActiveListing = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/auction/other/${params}`,
      method: "get",
      callback,
      onStart: activeListingRequested.type,
      onSuccess: activeListingReceived.type,
      onError: activeListingFailed.type,
    }),
  );
};

//Get Previous Motivation
export const getPreMotivation = (params, userId, date, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `sellerMotivation/previous/${params}?user_id=${userId}&dated=${date}`,
      method: "get",
      callback,
      onStart: preSellerMotivaitonRequested.type,
      onSuccess: preSellerMotivaitonReceived.type,
      onError: preSellerMotivaitonFailed.type,
    }),
  );
};




export const getListings = createSelector(
  (state) => state.entities.listings,
  (listings) => listings,
);
