import { createSlice, current } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";

const slice = createSlice({
  name: "avaition",
  initialState: {
    loading: false,
    aviationParts: [],
    mfr: [],
    model: [],
    condition: [],
    part: [],
    procurement: [],
    logs: [],
    log: [],
    orderRequests: [],
    rfqRequests: [],
    partType: [],
    location: [],
    partMfr: [],
    allAviationPart: [],
    reminderParts: [],

    //To include in new file
    parts: [],
  },
  reducers: {
    aviationRequested: (misc, action) => {
      misc.loading = true;
    },
    aviationReceived: (misc, action) => {
      misc.aviation = action.payload;
      misc.loading = false;
    },
    aviationRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    allAviationRequested: (misc, action) => {
      misc.loading = true;
    },
    allAviationReceived: (misc, action) => {
      misc.allAviationPart = action.payload;
      misc.loading = false;
    },
    allAviationRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    mfrRequested: (misc, action) => {
      misc.loading = true;
    },
    mfrReceived: (misc, action) => {
      misc.mfr = action.payload;
      misc.loading = false;
    },
    mfrRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    partMfrRequested: (misc, action) => {
      misc.loading = true;
    },
    partMfrReceived: (misc, action) => {
      misc.partMfr = action.payload;
      misc.loading = false;
    },
    partMfrRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    modelRequested: (misc, action) => {
      misc.loading = true;
    },
    modelReceived: (misc, action) => {
      misc.model = action.payload;
      misc.loading = false;
    },
    modelRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    conditionRequested: (misc, action) => {
      misc.loading = true;
    },
    conditionReceived: (misc, action) => {
      misc.condition = action.payload;
      misc.loading = false;
    },
    conditionRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    partRequested: (misc, action) => {
      misc.loading = true;
    },
    partReceived: (misc, action) => {
      misc.part = action.payload;
      misc.loading = false;
    },
    partRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    procuremnetRequested: (misc, action) => {
      misc.loading = true;
    },
    procuremnetReceived: (misc, action) => {
      misc.procurement = action.payload;
      misc.loading = false;
    },
    procuremnetRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    logsRequested: (misc, action) => {
      misc.loading = true;
    },
    logsReceived: (misc, action) => {
      misc.logs = action.payload;
      misc.loading = false;
    },
    logsRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    logRequested: (misc, action) => {
      misc.loading = true;
    },
    logReceived: (misc, action) => {
      misc.log = action.payload;
      misc.loading = false;
    },
    logRequestFailed: (misc, action) => {
      misc.loading = false;
    },

    orderRequestsRequested: (misc, action) => {
      misc.loading = true;
    },
    orderRequestsReceived: (misc, action) => {
      misc.orderRequests = action.payload;
      misc.loading = false;
    },
    orderRequestsRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    rfqRequestsRequested: (misc, action) => {
      misc.loading = true;
    },
    rfqRequestsReceived: (misc, action) => {
      misc.rfqRequests = action.payload;
      misc.loading = false;
    },
    rfqRequestsFailed: (misc, action) => {
      misc.loading = false;
    },
    partTypeRequested: (misc, action) => {
      misc.loading = true;
    },
    partTypeReceived: (misc, action) => {
      misc.partType = action.payload;
      misc.loading = false;
    },
    partTypeFailed: (misc, action) => {
      misc.loading = false;
    },
    locationRequestsRequested: (misc, action) => {
      misc.loading = true;
    },
    locationRequestsReceived: (misc, action) => {
      misc.location = action.payload;
      misc.loading = false;
    },
    locationRequestsFailed: (misc, action) => {
      misc.loading = false;
    },
    partsRequestsRequested: (misc, action) => {
      misc.loading = true;
    },
    partsRequestsReceived: (misc, action) => {
      misc.parts = action.payload;
      misc.loading = false;
    },
    partsRequestsFailed: (misc, action) => {
      misc.loading = false;
    },
    reminderPartsRequested: (misc, action) => {
      misc.loading = true;
    },
    reminderPartsReceived: (misc, action) => {
      misc.reminderParts = action.payload;
      misc.loading = false;
    },
    reminderPartsFailed: (misc, action) => {
      misc.loading = false;
    },
  },
});

export const {
  aviationRequested,
  aviationReceived,
  aviationRequestFailed,
  mfrRequested,
  mfrReceived,
  mfrRequestFailed,
  modelRequested,
  modelReceived,
  modelRequestFailed,
  conditionRequested,
  conditionReceived,
  conditionRequestFailed,
  partRequested,
  partRequestFailed,
  partReceived,
  procuremnetRequested,
  procuremnetRequestFailed,
  procuremnetReceived,
  logsRequested,
  logsRequestFailed,
  logsReceived,
  logRequested,
  logRequestFailed,
  logReceived,
  orderRequestsRequested,
  orderRequestsRequestFailed,
  orderRequestsReceived,
  rfqRequestsRequested,
  rfqRequestsFailed,
  rfqRequestsReceived,
  partTypeRequested,
  partTypeFailed,
  partTypeReceived,
  locationRequestsRequested,
  locationRequestsReceived,
  locationRequestsFailed,
  partMfrRequested,
  partMfrReceived,
  partMfrRequestFailed,
  allAviationRequested,
  allAviationReceived,
  allAviationRequestFailed,
  partsRequestsRequested,
  partsRequestsReceived,
  partsRequestsFailed,
  reminderPartsRequested,
  reminderPartsReceived,
  reminderPartsFailed,
} = slice.actions;
export default slice.reducer;

// Action Creators

const baseUrl = "aviation";

export const getAviationParts = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: baseUrl + "/aviationPart",
      method: "get",
      params,
      callback,
      onStart: aviationRequested.type,
      onSuccess: aviationReceived.type,
      onError: aviationRequestFailed.type,
    }),
  );
};

export const getAllAviationParts = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: baseUrl + "/aviationPart",
      method: "get",
      callback,
      params,
      onStart: allAviationRequested.type,
      onSuccess: allAviationReceived.type,
      onError: allAviationRequestFailed.type,
    }),
  );
};

export const updateAviationPartBulk = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: baseUrl + "/aviationPart/bulk/" + id,
      method: "put",
      data,
      callback,
    }),
  );
};

export const getMfr = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: baseUrl + "/other/planeManufacturer",
      params,
      method: "get",
      callback,
      onStart: mfrRequested.type,
      onSuccess: mfrReceived.type,
      onError: mfrRequestFailed.type,
    }),
  );
};

export const addMfr = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: baseUrl + "/other/planeManufacturer",
      method: "post",
      data,
      callback,
    }),
  );
};

export const editMfr = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: baseUrl + "/other/planeManufacturer/" + id,
      method: "put",
      data,
      callback,
    }),
  );
};

export const getPartMfr = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: baseUrl + "/other/partManufacturer",
      params,
      method: "get",
      callback,
      onStart: partMfrRequested.type,
      onSuccess: partMfrReceived.type,
      onError: partMfrRequestFailed.type,
    }),
  );
};

export const addPartMfr = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: baseUrl + "/other/partManufacturer",
      method: "post",
      data,
      callback,
    }),
  );
};

export const editPartMfr = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: baseUrl + "/other/partManufacturer/" + id,
      method: "put",
      data,
      callback,
    }),
  );
};

export const getModels = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: baseUrl + "/other/planeModel",
      params,
      method: "get",
      callback,
      onStart: modelRequested.type,
      onSuccess: modelReceived.type,
      onError: modelRequestFailed.type,
    }),
  );
};

export const addModels = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: baseUrl + "/other/planeModel",
      method: "post",
      data,
      callback,
    }),
  );
};

export const editModels = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: baseUrl + "/other/planeModel/" + id,
      method: "put",
      data,
      callback,
    }),
  );
};

export const getCondition = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: baseUrl + "/other/planeCondition",
      params,
      method: "get",
      callback,
      onStart: conditionRequested.type,
      onSuccess: conditionReceived.type,
      onError: conditionRequestFailed.type,
    }),
  );
};

export const addCondition = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: baseUrl + "/other/planeCondition",
      method: "post",
      data,
      callback,
    }),
  );
};
export const updateCondition = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: baseUrl + "/other/planeCondition/" + id,
      method: "put",
      data,
      callback,
    }),
  );
};

export const getPart = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: baseUrl + "/other/partType",
      params,
      method: "get",
      callback,
      onStart: partRequested.type,
      onSuccess: partReceived.type,
      onError: partRequestFailed.type,
    }),
  );
};

export const uploadAviationParts = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: baseUrl + `/aviationPart/bulk/${id}`,
      method: "post",
      data,
      callback,
    }),
  );
};

export const addAviationType = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `auctionDetail/aviationType/${id}`,
      method: "put",
      data,
      callback,
    }),
  );
};

export const deleteAviationParts = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: baseUrl + `/aviationPart/` + id,
      method: "delete",
      callback,
    }),
  );
};

export const deleteBulkAviationParts = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: baseUrl + "/aviationPart/bulk",
      method: "delete",
      data,
      callback,
    }),
  );
};

export const updateAviationParts = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: baseUrl + `/aviationPart/` + id,
      method: "put",
      data,
      callback,
    }),
  );
};

export const getProcurementRequests = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: baseUrl + "/aviationPart/procurementRequest",
      params,
      method: "get",
      callback,
      onStart: procuremnetRequested.type,
      onSuccess: procuremnetReceived.type,
      onError: procuremnetRequestFailed.type,
    }),
  );
};

export const getLogs = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: baseUrl + "/aviationPart/log",
      params,
      method: "get",
      callback,
      onStart: logsRequested.type,
      onSuccess: logsReceived.type,
      onError: logsRequestFailed.type,
    }),
  );
};
export const getLogById = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: baseUrl + "/aviationPart/log/" + id,
      method: "get",
      callback,
      onStart: logRequested.type,
      onSuccess: logReceived.type,
      onError: logRequestFailed.type,
    }),
  );
};

export const getOrderRequests = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: baseUrl + "/aviationPart/orderRequest",
      params,
      method: "get",
      callback,
      onStart: orderRequestsRequested.type,
      onSuccess: orderRequestsReceived.type,
      onError: orderRequestsRequestFailed.type,
    }),
  );
};

//Bulk Update RFQ Archive Status
export const updateRfqArchiveStatus = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: baseUrl + "/aviationPart/rfqRequest/updateArchiveStatus",
      method: "put",
      data,
      callback,
    }),
  );
};

export const getRfqRequests = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: baseUrl + "/aviationPart/rfqRequest",
      params,
      method: "get",
      callback,
      onStart: rfqRequestsRequested.type,
      onSuccess: rfqRequestsReceived.type,
      onError: rfqRequestsFailed.type,
    }),
  );
};

export const getPartType = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: baseUrl + "/other/partType",
      params,
      method: "get",
      callback,
      onStart: partTypeRequested.type,
      onSuccess: partTypeReceived.type,
      onError: partTypeFailed.type,
    }),
  );
};

export const addPartType = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: baseUrl + "/other/partType",
      method: "post",
      data,
      callback,
    }),
  );
};

export const editPartType = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: baseUrl + "/other/partType/" + id,
      method: "put",
      data,
      callback,
    }),
  );
};

export const getAviationLocations = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: baseUrl + "/other/location",
      params,
      method: "get",
      callback,
      onStart: locationRequestsRequested.type,
      onSuccess: locationRequestsReceived.type,
      onError: locationRequestsFailed.type,
    }),
  );
};

export const addAviationLocation = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: baseUrl + "/other/location",
      method: "post",
      data,
      callback,
    }),
  );
};

export const editAviationLocation = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: baseUrl + "/other/location/" + id,
      method: "put",
      data,
      callback,
    }),
  );
};

export const addBulkParts = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `aviation/genericPart/bulk/${id}`,
      method: "post",
      data,
      callback,
    }),
  );
};

export const updateBulkParts = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `aviation/genericPart/bulk/${id}`,
      method: "post",
      data,
      callback,
    }),
  );
};

export const getBulkParts = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `aviation/genericPart`,
      method: "get",
      params,
      callback,
      onStart: partsRequestsRequested.type,
      onSuccess: partsRequestsReceived.type,
      onError: partsRequestsFailed.type,
    }),
  );
};

export const deleteParts = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `aviation/genericPart/bulk`,
      method: "DELETE",
      data,
      callback,
    }),
  );
};

export const updateGenericParts = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `aviation/genericPart/update/` + id,
      method: "put",
      data,
      callback,
    }),
  );
};

export const setAviationPartReminder = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `aviation/other/partReminder`,
      method: "post",
      data,
      callback,
    }),
  );
};

export const deletePartReminder = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `aviation/other/partReminder/delete`,
      method: 'put',
      data,
      callback,
    })
  )
};

export const getReminderParts = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `aviation/other/partReminder`,
      method: "get",
      params,
      callback,
      onStart: reminderPartsRequested.type,
      onSuccess: reminderPartsReceived.type,
      onError: reminderPartsFailed.type,
    }),
  );
};

export const updatePartReminderStatus = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `aviation/other/partStatus/${id}`,
      method: "put",
      data,
      callback,
    })
  )
}

export const getAviationDetails = createSelector(
  (state) => state.entities.aviation,
  (aviation) => aviation,
);
