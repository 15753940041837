import React, { useEffect, useRef, useState } from "react";
import close from "../../../include/images/close-12x12.svg";
import { Offcanvas } from "react-bootstrap";
import NextButton from "../../../common/form/nextButton";
import Select from "react-select";
import { customStyles } from "../../../common/customStyles/reactSelectStyle";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";
import { addUser, editUser, getUser } from "../../../store/users";
import { connect } from "react-redux";
import AlertError from "../../../common/alerts/alertError";
import LoadingBar from "react-top-loading-bar";
import _ from "lodash";
import { getUserList } from "../../../store/userList";
import { validateErrorMessage } from "../../../common/validation/validation";
import Joi from "joi";

const AddUserModal = (props) => {
  const ref = useRef(null);

  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    mobile: "",
    email: "",
    user_type: "1",
    member_user_type: "0",
    company: "",
    industry: "",
    address: "",
    city: "",
    zip: "",
    country: "",
    state: { name: "", value: "" },
    ext: "",
    phone: "",
    website: "",
    password: "",
  });
  const [errors, setErrors] = useState("");

  const [loading, setLoading] = useState(false);

  const industryOptions = props.industryList.map((industry) => ({
    value: industry.industry_id,
    name: industry.description,
  }));
  const countryOptions = props.countryList.map((country) => ({
    value: country.country_id,
    name: country.description,
  }));
  const stateOptions = props.stateList.map((state) => ({
    value: state.state_id,
    name: state.description,
  }));

  const schema = Joi.object({
    firstName: Joi.string()
      .label("First Name")
      .trim()
      // .required()
      .error((errors) => validateErrorMessage(errors)),
    user_type: Joi.string()
      .label("Entity")
      .required()
      .error((errors) => validateErrorMessage(errors)),
    member_user_type: Joi.string()
      .label("Type")
      .required()
      .error((errors) => validateErrorMessage(errors)),
    lastName: Joi.string()
      .label("Last Name")
      .trim()
      .error((errors) => validateErrorMessage(errors)),
    password: Joi.string()
      .required()
      .error((errors) => validateErrorMessage(errors)),
    email: Joi.string()
      .label("Email")
      .trim()
      .required()
      .error((errors) => validateErrorMessage(errors)),
    mobile: Joi.string()
      .label("Mobile")
      .trim()
      .error((errors) => validateErrorMessage(errors)),
    company: Joi.string()
      .label("Company ")
      .trim()
      .when("user_type", {
        is: "1",
        // then: Joi.required(),
        otherwise: Joi.optional(),
      })
      .error((errors) => validateErrorMessage(errors)),
    address: Joi.string()
      .label("Address")
      .trim()
      // .required()
      .error((errors) => validateErrorMessage(errors)),
    industry: Joi.object()
      .label("Industry")
      // .required()
      .error((errors) => validateErrorMessage(errors)),
    country: Joi.object()
      .label("Country")
      // .required()
      .error((errors) => validateErrorMessage(errors)),
    state: Joi.object()
      .allow("")
      .error((errors) => validateErrorMessage(errors)),
    zip: Joi.string()
      .min(4)
      .max(10)
      .label("Zip Code")
      .allow("")
      .error((errors) => validateErrorMessage(errors)),
    phone: Joi.string()
      .label("Phone Number")
      .max(30)
      .allow("")
      .error((errors) => validateErrorMessage(errors)),
    ext: Joi.string()
      .label("Alt Phone")
      .max(15)
      .allow("")
      .error((errors) => validateErrorMessage(errors)),
    city: Joi.string()
      .label("city")
      .trim()
      // .required()
      .error((errors) => validateErrorMessage(errors)),
    website: Joi.string()
      .allow("")
      .error((errors) => validateErrorMessage(errors)),
  });

  const validate = (type = false) => {
    const error = schema.validate(_.pickBy(data, _.identity), {
      abortEarly: type,
    });
    if (error.error && error.error.details) {
      const errors = {};
      error.error.details.forEach((element) => {
        errors[element.context.key] = element.message;
      });
      setErrors(errors);
      return false;
    }
    setErrors("");
    return true;
  };

  const editSchema = Joi.object({
    fullName: Joi.string()
      .label("First Name")
      .trim()
      // .required()
      .error((errors) => validateErrorMessage(errors)),
    user_type: Joi.string()
      .label("Entity")
      .required()
      .error((errors) => validateErrorMessage(errors)),
    member_user_type: Joi.string()
      .label("Type")
      .required()
      .error((errors) => validateErrorMessage(errors)),
    email: Joi.string()
      .label("Email")
      .trim()
      .required()
      .error((errors) => validateErrorMessage(errors)),
    mobile: Joi.string()
      .label("Mobile")
      .trim()
      .error((errors) => validateErrorMessage(errors)),
    company: Joi.string()
      .label("Company ")
      .trim()
      .when("user_type", {
        is: "1",
        // then: Joi.required(),
        otherwise: Joi.optional(),
      })
      .error((errors) => validateErrorMessage(errors)),
    address: Joi.string()
      .label("Address")
      .trim()
      // .required()
      .error((errors) => validateErrorMessage(errors)),
    industry: Joi.object()
      .label("Industry")
      // .required()
      .error((errors) => validateErrorMessage(errors)),
    country: Joi.object()
      .label("Country")
      // .required()
      .error((errors) => validateErrorMessage(errors)),
    state: Joi.object()
      .allow("")
      .error((errors) => validateErrorMessage(errors)),
    zip: Joi.string()
      .min(4)
      .max(10)
      .label("Zip Code")
      .allow("")
      .error((errors) => validateErrorMessage(errors)),
    phone: Joi.string()
      .label("Phone Number")
      .max(30)
      .allow("")
      .error((errors) => validateErrorMessage(errors)),
    ext: Joi.string()
      .label("Alt Phone")
      .max(15)
      .allow("")
      .error((errors) => validateErrorMessage(errors)),
    city: Joi.string()
      .label("city")
      .trim()
      // .required()
      .error((errors) => validateErrorMessage(errors)),
    website: Joi.string()
      .allow("")
      .error((errors) => validateErrorMessage(errors)),
    password: Joi.string()
      .allow("")
      .error((errors) => validateErrorMessage(errors)),
  });

  const editValidate = (type = false) => {
    const error = editSchema.validate(_.pickBy(data, _.identity), {
      abortEarly: type,
    });
    if (error.error && error.error.details) {
      const errors = {};
      error.error.details.forEach((element) => {
        errors[element.context.key] = element.message;
      });
      setErrors(errors);
      return false;
    }
    setErrors("");
    return true;
  };

  const generatePassword = () => {
    const length = 8;
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let password = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset[randomIndex];
    }

    const values = { ...data };
    values.password = password;
    setData(values);
  };

  const handleAddUser = () => {
    if (validate()) {
      setLoading(true);
      ref?.current?.continuousStart();

      const payload = {
        firstName: data.firstName ? data.firstName : "",
        lastName: data.lastName ? data.lastName : "",
        mobile: data.mobile ? data.mobile : "",
        email: data.email ? data.email : "",
        userType: data.user_type ? (data.user_type == "1" ? "company" : "individual") : "",
        memberUserType: data.member_user_type ? data.member_user_type : 0,
        companyName: data.company ? data.company : "",
        industryId: data.industry?.value ? data.industry?.value : "",
        addressLine1: data.address ? data.address : "",
        city: data.city ? data.city : "",
        zipCode: data.zip ? data.zip : "",
        countryID: data.country?.value ? data.country?.value : 0,
        stateId: data.state?.value ? data.state?.value : "",
        extNumber: data.ext ? data.ext : "",
        phone: data.phone ? data.phone : "",
        webSite: data.website ? data.website : "",
        password: data.password ? data.password : "",
      };
      const param = _.pickBy(payload, function (value, key) {
        if (key != "phone" || key != "lastName") {
          return !(value === "" || value == {} || value === undefined || value === null);
        }
      });
      props.addUser(payload, (res) => {
        if (res.status === 200) {
          const params = {
            limit: 50,
            page: 1,
            keyword: data.email ? data?.email : "",
            company_access: null,
          };

          props.getUserList(params, (res) => {
            if (res.status === 200) {
              const response = res?.data?.data?.list?.length > 0 && res?.data?.data?.list[0];
              setLoading(false);
              ref?.current?.complete();
              if (data.member_user_type == 1 || data.member_user_type == 0) {
                props?.setBuyer(true);
                props.onHide();
                toast(<AlertSuccess message={"Information Saved"} />);
                props?.toggleCategoryPreferenceModal(response.user_id);
                props?.setUserId(response.user_id);
                setData({
                  firstName: "",
                  lastName: "",
                  mobile: "",
                  email: "",
                  user_type: "1",
                  member_user_type: "0",
                  company: "",
                  industry: { name: "", value: "" },
                  address: "",
                  city: "",
                  zip: "",
                  country: { name: "", value: "" },
                  state: { name: "", value: "" },
                  ext: "",
                  phone: "",
                  website: "",
                });
              } else {
                setData({
                  firstName: "",
                  lastName: "",
                  mobile: "",
                  email: "",
                  user_type: "1",
                  member_user_type: "0",
                  company: "",
                  industry: { name: "", value: "" },
                  address: "",
                  city: "",
                  zip: "",
                  country: { name: "", value: "" },
                  state: { name: "", value: "" },
                  ext: "",
                  phone: "",
                  website: "",
                });
                props.onHide();
                toast(<AlertSuccess message={"Information Saved"} />);
              }
            }
          });
        } else {
          setLoading(false);
          ref?.current?.complete();
          toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        }
      });
    }
  };

  const editHandler = () => {
    if (editValidate()) {
      setLoading(true);
      ref?.current?.continuousStart();

      const payload = {
        name01: data.fullName ? data.fullName : "",
        mobile: data.mobile ? data.mobile : "",
        email: data.email ? data.email : "",
        userType: data.user_type ? (data.user_type == "1" ? "company" : "individual") : "",
        memberUserType: data.member_user_type ? data.member_user_type : 0,
        companyName: data.company ? data.company : "",
        industryId: data.industry?.value ? data.industry?.value : "",
        address: data.address ? data.address : "",
        city: data.city ? data.city : "",
        zipCode: data.zip ? data.zip : "",
        countryID: data.country?.value ? data.country?.value : 0,
        stateId: data.state?.value ? data.state?.value : "",
        extNumber: data.ext ? data.ext : "",
        phone: data.phone ? data.phone : "",
        webSite: data.website ? data.website : "",
      };
      const param = _.pickBy(payload, function (value, key) {
        if (key != "phone" || key != "lastName") {
          return !(value === "" || value == {} || value === undefined || value === null);
        }
      });
      props.editUser(props?.userId, payload, (res) => {
        if (res.status === 200) {
          const params = {
            limit: 50,
            page: 1,
            keyword: data.email ? data?.email : "",
            company_access: null,
          };

          props.getUserList(params, (res) => {
            if (res.status === 200) {
              const response = res?.data?.data?.list?.length > 0 && res?.data?.data?.list[0];
              setLoading(false);
              ref?.current?.complete();
              if (data.member_user_type == 1 || data.member_user_type == 0) {
                props?.setBuyer(true);
                props.onHide();
                toast(<AlertSuccess message={"Information Saved"} />);
                props?.toggleCategoryPreferenceModal(response.user_id);
                props?.setUserId(response.user_id);
                setData({
                  firstName: "",
                  lastName: "",
                  mobile: "",
                  email: "",
                  user_type: "1",
                  member_user_type: "0",
                  company: "",
                  industry: { name: "", value: "" },
                  address: "",
                  city: "",
                  zip: "",
                  country: { name: "", value: "" },
                  state: { name: "", value: "" },
                  ext: "",
                  phone: "",
                  website: "",
                });
              } else {
                setData({
                  firstName: "",
                  lastName: "",
                  mobile: "",
                  email: "",
                  user_type: "1",
                  member_user_type: "0",
                  company: "",
                  industry: { name: "", value: "" },
                  address: "",
                  city: "",
                  zip: "",
                  country: { name: "", value: "" },
                  state: { name: "", value: "" },
                  ext: "",
                  phone: "",
                  website: "",
                });
                props.onHide();
                toast(<AlertSuccess message={"Information Saved"} />);
              }
            }
          });
        } else {
          setLoading(false);
          ref?.current?.complete();
          toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        }
      });
    }
  };

  useEffect(() => {
    if (props.show) {
      generatePassword();
    }
    if (!props.show) {
      setData({
        firstName: "",
        lastName: "",
        mobile: "",
        email: "",
        user_type: "1",
        member_user_type: "0",
        company: "",
        industry: "",
        address: "",
        city: "",
        zip: "",
        country: "",
        state: { name: "", value: "" },
        ext: "",
        phone: "",
        website: "",
        password: "",
      });
    }
  }, [props.show]);

  useEffect(() => {
    if (props?.show) {
      setData({ ...props?.userData });
    }
  }, [props?.userData]);

  return (
    <>
      <LoadingBar height={5} color="#47AD1D" ref={ref} />
      <Offcanvas
        placement="end"
        backdropClassName="upload-documnets-backdrop"
        show={props.show}
        onHide={() => {
          props.onHide();
          props?.setBuyer(false);
        }}
        className="offcanvas offcanvas-end border-0 width-950"
        id="verifyDocsOffcanvas"
        enforceFocus={false}
      >
        <Offcanvas.Header className="offcanvas-header">
          <h5 class="offcanvas-title">{props?.edit ? "Edit" : "Add"} User</h5>
          <button
            type="button"
            class="offcanvas-close-btn d-flex align-items-center justify-content-center"
            data-bs-dismiss="offcanvas"
            onClick={() => {
              props.onHide();
              props?.setBuyer(false);
            }}
          >
            <img src={close} alt="" />
          </button>
        </Offcanvas.Header>
        <Offcanvas.Body className="offcanvas-body p-0">
          <div class="offcanvas-widget-row">
            <div class="table-responsive hide-scrollbar mb-3">
              {props?.edit ? (
                <div className="mb-4 ">
                  <label class="form-label" for="firstName">
                    {" "}
                    Full Name
                    {/* <sup style={{ color: "Red" }}>*</sup> */}
                  </label>
                  <input
                    name="firstName"
                    type="text"
                    value={data?.fullName}
                    onChange={(e) => {
                      const value = { ...data };
                      value.fullName = e.target.value;
                      setData(value);
                    }}
                    className="form-control"
                  />
                  {errors && errors.fullName ? <p className="error-text">{errors.fullName}</p> : ""}
                </div>
              ) : (
                <div className="d-flex add-user-flex">
                  <div className="mb-4 add-user-input-width">
                    <label class="form-label" for="firstName">
                      {" "}
                      First Name
                      {/* <sup style={{ color: "Red" }}>*</sup> */}
                    </label>
                    <input
                      name="firstName"
                      type="text"
                      value={data?.firstName}
                      onChange={(e) => {
                        const value = { ...data };
                        value.firstName = e.target.value;
                        setData(value);
                      }}
                      className="form-control"
                    />
                    {errors && errors.firstName ? <p className="error-text">{errors.firstName}</p> : ""}
                  </div>
                  <div className="mb-4 add-user-input-width">
                    <label class="form-label" for="lastName">
                      Last Name
                      {/* <sup style={{ color: "Red" }}>*</sup> */}
                    </label>
                    <input
                      type="text"
                      name="lastName"
                      value={data?.lastName}
                      onChange={(e) => {
                        const value = { ...data };
                        value.lastName = e.target.value;
                        setData(value);
                      }}
                      className="form-control"
                    />
                    {errors && errors.lastName ? <p className="error-text">{errors.lastName}</p> : ""}
                  </div>
                </div>
              )}

              <div className="d-flex add-user-flex">
                <div className="mb-4 add-user-input-width">
                  <label class="form-label" for="mobile">
                    Mobile Number
                    {/* <sup style={{ color: "Red" }}>*</sup> */}
                  </label>
                  <input
                    type="text"
                    name="mobile"
                    value={data?.mobile}
                    onChange={(e) => {
                      const value = { ...data };
                      value.mobile = e.target.value;
                      setData(value);
                    }}
                    className="form-control"
                  />
                  {errors && errors.mobile ? <p className="error-text">{errors.mobile}</p> : ""}
                </div>
                <div className="mb-4 add-user-input-width">
                  <label class="form-label" for="email">
                    Email<sup style={{ color: "Red" }}>*</sup>
                  </label>
                  <input
                    name="email"
                    type="text"
                    disabled={props?.edit ? true : false}
                    value={data?.email}
                    onChange={(e) => {
                      const value = { ...data };
                      value.email = e.target.value;
                      setData(value);
                    }}
                    className="form-control"
                  />
                  {errors && errors.email ? <p className="error-text">{errors.email}</p> : ""}
                </div>
              </div>

              <div class="dividerTable mt-4  mb-45"></div>
              <div className="d-flex add-user-flex">
                <div className="mb-4 add-user-input-width">
                  <label class="fw-normal mb-14">
                    Entity Type
                    {/* <sup style={{ color: "Red" }}>*</sup> */}
                  </label>
                  <div class="four-column-radio-group mb-4 gap-user ">
                    <div class="fcrg-item  payment-info-main-option">
                      <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
                        <input
                          class="form-check-input cursor-pointer"
                          type="radio"
                          name="WTflexRadioDefault"
                          id="WTflexRadio__12"
                          checked={data?.user_type == "1"}
                          onChange={(e) => {
                            const value = { ...data };
                            value.user_type = "1";
                            setData(value);
                          }}
                        />
                        <label class="form-check-label cursor-pointer" for="WTflexRadio__12">
                          Company
                        </label>
                      </div>
                    </div>
                    <div class="fcrg-item  payment-info-main-option">
                      <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
                        <input
                          class="form-check-input cursor-pointer"
                          type="radio"
                          name="WTflexRadioDefault"
                          id="WTflexRadio__21"
                          checked={data?.user_type == "2"}
                          onChange={(e) => {
                            const value = { ...data };
                            value.user_type = "2";
                            setData(value);
                          }}
                        />

                        <label class="form-check-label cursor-pointer" for="WTflexRadio__22">
                          Individual
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-4 add-user-input-width">
                  <label class="fw-normal mb-14">
                    User Type
                    {/* <sup style={{ color: "Red" }}>*</sup> */}
                  </label>
                  <div class="four-column-radio-group mb-4 gap-user ">
                    <div class="fcrg-item  payment-info-main-option">
                      <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
                        <input
                          class="form-check-input cursor-pointer"
                          type="radio"
                          name="UTflexRadioDefault"
                          id="UTflexRadio__01"
                          checked={data?.member_user_type == "1"}
                          onChange={(e) => {
                            const value = { ...data };
                            value.member_user_type = "1";
                            setData(value);
                          }}
                        />
                        <label class="form-check-label cursor-pointer" for="WTflexRadio__12">
                          Buyer
                        </label>
                      </div>
                    </div>
                    <div class="fcrg-item  payment-info-main-option">
                      <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
                        <input
                          class="form-check-input cursor-pointer"
                          type="radio"
                          name="UTflexRadioDefault"
                          id="UTflexRadio__02"
                          checked={data?.member_user_type == "2"}
                          onChange={(e) => {
                            const value = { ...data };
                            value.member_user_type = "2";
                            setData(value);
                          }}
                        />

                        <label class="form-check-label cursor-pointer" for="WTflexRadio__22">
                          Seller
                        </label>
                      </div>
                    </div>
                    <div class="fcrg-item  payment-info-main-option">
                      <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
                        <input
                          class="form-check-input cursor-pointer"
                          type="radio"
                          name="UTflexRadioDefault"
                          id="UTflexRadio__03"
                          checked={data?.member_user_type == "0"}
                          onChange={(e) => {
                            const value = { ...data };
                            value.member_user_type = "0";
                            setData(value);
                          }}
                        />

                        <label class="form-check-label cursor-pointer" for="WTflexRadio__22">
                          Both
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {data?.user_type == "1" ? (
                <div className="d-flex add-user-flex">
                  <div className="mb-4 add-user-input-width">
                    <label class="form-label" for="industry">
                      Industry
                      {/* <sup style={{ color: "Red" }}>*</sup> */}
                    </label>
                    <Select
                      value={data?.industry}
                      options={industryOptions}
                      className="basic-single country-margin select-background"
                      classNamePrefix="select-search country-margin select-background"
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.value}
                      onChange={(e) => {
                        const values = { ...data };
                        values.industry = e;
                        setData(values);
                      }}
                      name="industry"
                      placeholder="Select Industry"
                      styles={customStyles}
                    />
                    {errors && errors.industry ? <p className="error-text">{errors.industry}</p> : ""}
                  </div>
                  <div className="mb-4 add-user-input-width">
                    <label class="form-label" for="company">
                      Company
                      {/* <sup style={{ color: "Red" }}>*</sup> */}
                    </label>
                    <input
                      name="company"
                      type="text"
                      value={data?.company}
                      onChange={(e) => {
                        const value = { ...data };
                        value.company = e.target.value;
                        setData(value);
                      }}
                      className="form-control"
                    />
                    {errors && errors.company ? <p className="error-text">{errors.company}</p> : ""}
                  </div>
                </div>
              ) : (
                <div className="mb-4 ">
                  <label class="form-label" for="industry">
                    Industry
                    {/* <sup style={{ color: "Red" }}>*</sup> */}
                  </label>
                  <Select
                    value={data?.industry}
                    options={industryOptions}
                    className="basic-single country-margin select-background"
                    classNamePrefix="select-search country-margin select-background"
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.value}
                    onChange={(e) => {
                      const values = { ...data };
                      values.industry = e;
                      setData(values);
                    }}
                    name="industry"
                    placeholder="Select Industry"
                    styles={customStyles}
                  />
                  {errors && errors.industry ? <p className="error-text">{errors.industry}</p> : ""}
                </div>
              )}

              <div className="mb-4">
                <label class="form-label" for="address">
                  Address
                  {/* <sup style={{ color: "Red" }}>*</sup> */}
                </label>
                <input
                  name="address"
                  type="text"
                  value={data?.address}
                  onChange={(e) => {
                    const value = { ...data };
                    value.address = e.target.value;
                    setData(value);
                  }}
                  className="form-control"
                />
                {errors && errors.address ? <p className="error-text">{errors.address}</p> : ""}
              </div>
              <div className="d-flex add-user-flex">
                <div className="mb-4 add-user-input-width">
                  <label class="form-label" for="city">
                    City
                    {/* <sup style={{ color: "Red" }}>*</sup> */}
                  </label>
                  <input
                    name="city"
                    type="text"
                    value={data?.city}
                    onChange={(e) => {
                      const value = { ...data };
                      value.city = e.target.value;
                      setData(value);
                    }}
                    className="form-control"
                  />
                  {errors && errors.city ? <p className="error-text">{errors.city}</p> : ""}
                </div>
                <div className="mb-4 add-user-input-width">
                  <label class="form-label" for="zip">
                    Zip Code
                  </label>
                  <input
                    name="zip"
                    type="text"
                    value={data?.zip}
                    onChange={(e) => {
                      const value = { ...data };
                      value.zip = e.target.value;
                      setData(value);
                    }}
                    className="form-control"
                  />
                  {errors && errors.zip ? <p className="error-text">{errors.zip}</p> : ""}
                </div>
              </div>
              <div className="d-flex add-user-flex">
                <div className="mb-4 add-user-input-width">
                  <label class="form-label" for="country">
                    Country
                    {/* <sup style={{ color: "Red" }}>*</sup> */}
                  </label>
                  <Select
                    value={data?.country}
                    options={countryOptions}
                    className="basic-single country-margin select-background"
                    classNamePrefix="select-search country-margin select-background"
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.value}
                    placeholder="Select Country"
                    onChange={(e) => {
                      const values = { ...data };
                      values.country = e;
                      values.state = {};
                      setData(values);
                    }}
                    name="country"
                    styles={customStyles}
                  />
                  {errors && errors.country ? <p className="error-text">{errors.country}</p> : ""}
                </div>
                <div className="mb-4 add-user-input-width">
                  <label class="form-label" for="state">
                    State
                  </label>
                  <Select
                    value={data?.state}
                    options={
                      data?.country && data?.country?.value == 1001
                        ? stateOptions.filter((item) => item.value != 1001)
                        : [{ name: "International", value: 1001 }]
                    }
                    className="basic-single select-background"
                    classNamePrefix="select-search select-background"
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.value}
                    placeholder="Select State"
                    isDisabled={data?.country?.value ? false : true}
                    onChange={(e) => {
                      const values = { ...data };
                      values.state = e;
                      setData(values);
                    }}
                    name="state"
                    styles={customStyles}
                  />
                  {errors && errors.state ? <p className="error-text">{errors.state}</p> : ""}
                </div>
              </div>
              <div className="d-flex add-user-flex">
                <div className="d-flex mb-4 add-user-input-width">
                  <div className="w-70 me-3" style={{ width: "70%" }}>
                    <label class="form-label" for="phone">
                      Business Phone
                    </label>
                    <input
                      name="phone"
                      type="text"
                      value={data?.phone}
                      onChange={(e) => {
                        const value = { ...data };
                        value.phone = e.target.value;
                        setData(value);
                      }}
                      className="form-control"
                    />
                    {errors && errors.phone ? <p className="error-text">{errors.phone}</p> : ""}
                  </div>
                  <div className="w-30" style={{ width: "30%" }}>
                    <label class="form-label" for="ext">
                      Ext
                    </label>
                    <input
                      name="ext"
                      type="text"
                      value={data?.ext}
                      onChange={(e) => {
                        const value = { ...data };
                        value.ext = e.target.value;
                        setData(value);
                      }}
                      className="form-control"
                    />
                    {errors && errors.ext ? <p className="error-text">{errors.ext}</p> : ""}
                  </div>
                </div>
                {data?.user_type == "1" && (
                  <div className="mb-4 add-user-input-width">
                    <label class="form-label" for="website">
                      Website
                    </label>
                    <input
                      name="website"
                      type="text"
                      value={data?.website}
                      onChange={(e) => {
                        const value = { ...data };
                        value.website = e.target.value;
                        setData(value);
                      }}
                      className="form-control"
                    />
                  </div>
                )}
              </div>

              <div class="dividerTable mt-4"></div>
            </div>
            <div className="d-flex justify-content-end">
              <button
                class="btn btn-link me-md-2 global-cancel-button"
                type="button"
                onClick={() => {
                  props.onHide();
                  props?.setBuyer(false);
                }}
              >
                Cancel
              </button>
              <NextButton
                class="btn btn-default"
                handleSubmit={() => (props?.edit ? editHandler() : handleAddUser())}
                type="button"
                label={"Submit"}
                loading={loading}
              />
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  addUser: (data, callback) => dispatch(addUser(data, callback)),
  editUser: (id, data, callback) => dispatch(editUser(id, data, callback)),
  getUserList: (params, callback) => dispatch(getUserList(params, callback)),
});
const mapStateToProps = (state) => ({
  getUser: getUser(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(AddUserModal));
