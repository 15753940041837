import React from "react";
import { Modal } from "react-bootstrap";
import close from "../../../include/images/close.svg";
import NextButton from "../../../common/form/nextButton";

const EditBuyerMotivationModal = (props) => {
  const data = props.data;
  return (
    <Modal
      className="modal fade custom-modal"
      id="sellerMotivation"
      show={props.show}
      onHide={props.onHide}
      dialogClassName="modal-dialog modal-dialog-centered modal-lg"
    >
      <div class="modal-content h-100 d-flex flex-column">
        <div class="custom-modal-header d-flex align-items-center justify-content-between">
          <h5 class="mb-0 text-capitalize">Buyer Motivation</h5>
          <button
            type="button"
            class="close-btn d-flex align-items-center justify-content-center"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => props.onHide()}
          >
            <img src={close} alt="" />
          </button>
        </div>
        <Modal.Body className="custom-modal-body flex-grow-1 hide-scrollbar padding-bottom-0">
          <div class="page-widget-label text-capitalize">Buying Interest</div>
          <div>
            {!(
              data?.motivation?.find((item) => item.type === "checkbox_02")
                ?.motivation == 1 ||
              data?.motivation?.find((item) => item.type === "checkbox_03")
                ?.motivation == 1 ||
              data?.motivation?.find((item) => item.type === "checkbox_04")
                ?.motivation == 1 ||
              data?.motivation?.find((item) => item.type === "checkbox_05")
                ?.motivation == 1 ||
              data?.motivation?.find((item) => item.type === "checkbox_06")
                ?.motivation == 1 ||
              data?.motivation?.find((item) => item.type === "checkbox_07")
                ?.motivation == 1
            ) && (
              <div class="form-check d-flex align-items-center mt-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="checkbox_01"
                  value="checkbox_01"
                  id="checkbox_01"
                  defaultChecked={
                    data?.motivation?.find(
                      (item) => item.type === "checkbox_01"
                    )?.motivation == 1
                  }
                  onChange={(e) => {
                    const data = { ...props.updateCheckbox };
                    if (e.target.checked) {
                      data.value = e.target.value;
                      data.check = 1;
                    } else {
                      data.value = e.target.value;
                      data.check = 0;
                    }
                    props.setUpdateCheckbox(data);
                  }}
                />
                <label
                  class="form-check-label fw-medium"
                  for="checkbox_01"
                ></label>
                <span>Don't Know</span>
              </div>
            )}
            <div class="form-check d-flex align-items-center mt-2">
              <input
                className="form-check-input"
                type="checkbox"
                name="checkbox_02"
                value="checkbox_02"
                defaultChecked={
                  data?.motivation?.find((item) => item.type === "checkbox_02")
                    ?.motivation == 1
                }
                id="checkbox_02"
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label
                class="form-check-label fw-medium"
                for="checkbox_02"
              ></label>
              <span>Purchased</span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                className="form-check-input"
                type="checkbox"
                name="checkbox_03"
                value="checkbox_03"
                id="checkbox_03"
                defaultChecked={
                  data?.motivation?.find((item) => item.type === "checkbox_03")
                    ?.motivation == 1
                }
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label
                class="form-check-label fw-medium"
                for="checkbox_03"
              ></label>
              <span>Bid</span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                className="form-check-input"
                type="checkbox"
                name="checkbox_04"
                value="checkbox_05"
                id="checkbox_04"
                defaultChecked={
                  data?.motivation?.find((item) => item.type === "checkbox_04")
                    ?.motivation == 1
                }
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label
                class="form-check-label fw-medium"
                for="checkbox_04"
              ></label>
              <span>Other Buyer Interest </span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                className="form-check-input"
                type="checkbox"
                name="checkbox_05"
                id="checkbox_05"
                value="checkbox_05"
                defaultChecked={
                  data?.motivation?.find((item) => item.type === "checkbox_05")
                    ?.motivation == 1
                }
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label
                class="form-check-label fw-medium"
                for="checkbox_05"
              ></label>
              <span>Interested in Surplus/Salvage</span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                className="form-check-input"
                type="checkbox"
                name="checkbox_06"
                id="checkbox_06"
                value="checkbox_06"
                defaultChecked={
                  data?.motivation?.find((item) => item.type === "checkbox_06")
                    ?.motivation == 1
                }
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label
                class="form-check-label fw-medium"
                for="checkbox_06"
              ></label>
              <span>No Interest in Buying</span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                className="form-check-input"
                type="checkbox"
                name="checkbox_07"
                id="checkbox_07"
                value="checkbox_07"
                defaultChecked={
                  data?.motivation?.find((item) => item.type === "checkbox_07")
                    ?.motivation == 1
                }
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label
                class="form-check-label fw-medium"
                for="checkbox_07"
              ></label>
              <span>Bad Salvex Experience</span>
            </div>
            <div className="d-flex align-items-center mt-2">
              <span className="fw-medium">Completed By:</span>
              <span className="ms-2">{"Super Admin  07/17/2023 08:34 AM"}</span>
            </div>
            <div className="d-flex align-items-center mt-2">
              <textarea
                className="form-control"
                name="Buying Interesy"
                cols="30"
                rows="3"
                value={props?.updateData?.information_text_1}
                onChange={(e) => {
                  const data = { ...props.updateData };
                  data.information_text_1 = e.target.value;
                  props.setUpdateData(data);
                }}
              ></textarea>
            </div>
          </div>
          <div className="dividerTable mt-3"></div>
          <div class="page-widget-label text-capitalize">Product Condition</div>
          <div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                class="form-check-input"
                type="checkbox"
                name="checkbox_08"
                id="checkbox_08"
                value="checkbox_08"
                defaultChecked={
                  data?.motivation?.find((item) => item.type === "checkbox_08")
                    ?.motivation == 1
                }
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label
                class="form-check-label fw-medium"
                for="checkbox_08"
              ></label>
              <span>Prime</span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                class="form-check-input"
                type="checkbox"
                name="checkbox_09"
                id="checkbox_09"
                value="checkbox_09"
                defaultChecked={
                  data?.motivation?.find((item) => item.type === "checkbox_09")
                    ?.motivation == 1
                }
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label
                class="form-check-label fw-medium"
                for="checkbox_09"
              ></label>
              <span>Unused</span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                class="form-check-input"
                type="checkbox"
                name="checkbox_010"
                id="checkbox_010"
                value="checkbox_010"
                defaultChecked={
                  data?.motivation?.find((item) => item.type === "checkbox_010")
                    ?.motivation == 1
                }
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label
                class="form-check-label fw-medium"
                for="checkbox_010"
              ></label>
              <span>Used</span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                class="form-check-input"
                type="checkbox"
                name="checkbox_011"
                id="checkbox_011"
                value="checkbox_011"
                defaultChecked={
                  data?.motivation?.find((item) => item.type === "checkbox_011")
                    ?.motivation == 1
                }
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label
                class="form-check-label fw-medium"
                for="checkbox_011"
              ></label>
              <span>Damaged/Defect</span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                class="form-check-input"
                type="checkbox"
                name="checkbox_012"
                id="checkbox_012"
                value="checkbox_012"
                defaultChecked={
                  data?.motivation?.find((item) => item.type === "checkbox_012")
                    ?.motivation == 1
                }
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label
                class="form-check-label fw-medium"
                for="checkbox_012"
              ></label>
              <span>Scrap</span>
            </div>
            <div className="d-flex align-items-center mt-2">
              <span className="fw-medium">Completed By:</span>
              <span className="ms-2">{"Super Admin  07/17/2023 08:34 AM"}</span>
            </div>
            <div className="d-flex align-items-center mt-2">
              <textarea
                className="form-control"
                name="Product_Condition"
                value={props?.updateData?.information_text_2}
                cols="30"
                rows="3"
                onChange={(e) => {
                  const data = { ...props.updateData };
                  data.information_text_2 = e.target.value;
                  props.setUpdateData(data);
                }}
              ></textarea>
            </div>
          </div>
          <div className="dividerTable"></div>
          <div class="page-widget-label text-capitalize">
            Control of Purchase
          </div>
          <div>
            {!(
              data?.motivation?.find((item) => item.type === "checkbox_019")
                ?.motivation == 1 ||
              data?.motivation?.find((item) => item.type === "checkbox_020")
                ?.motivation == 1 ||
              data?.motivation?.find((item) => item.type === "checkbox_021")
                ?.motivation == 1 ||
              data?.motivation?.find((item) => item.type === "checkbox_022")
                ?.motivation == 1 ||
              data?.motivation?.find((item) => item.type === "checkbox_023")
                ?.motivation == 1 ||
              data?.motivation?.find((item) => item.type === "checkbox_041")
                ?.motivation == 1
            ) && (
              <div class="form-check d-flex align-items-center mt-2">
                <input
                  class="form-check-input"
                  type="checkbox"
                  name="checkbox_018"
                  value="checkbox_018"
                  id="checkbox_018"
                  defaultChecked={
                    data?.motivation?.find(
                      (item) => item.type === "checkbox_018"
                    )?.motivation == 1
                  }
                  onChange={(e) => {
                    const data = { ...props.updateCheckbox };
                    if (e.target.checked) {
                      data.value = e.target.value;
                      data.check = 1;
                    } else {
                      data.value = e.target.value;
                      data.check = 0;
                    }
                    props.setUpdateCheckbox(data);
                  }}
                />
                <label
                  class="form-check-label fw-medium"
                  for="checkbox_018"
                ></label>
                <span>Don't Know</span>
              </div>
            )}
            <div class="form-check d-flex align-items-center mt-2">
              <input
                class="form-check-input"
                type="checkbox"
                name="checkbox_019"
                id="checkbox_019"
                value="checkbox_019"
                defaultChecked={
                  data?.motivation?.find((item) => item.type === "checkbox_019")
                    ?.motivation == 1
                }
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label
                class="form-check-label fw-medium"
                for="checkbox_019"
              ></label>
              <span>Ownership (Operations Use)</span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                class="form-check-input"
                type="checkbox"
                name="checkbox_020"
                id="checkbox_020"
                value="checkbox_020"
                defaultChecked={
                  data?.motivation?.find((item) => item.type === "checkbox_020")
                    ?.motivation == 1
                }
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label
                class="form-check-label fw-medium"
                for="checkbox_020"
              ></label>
              <span>Ownership (Inventory)</span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                class="form-check-input"
                type="checkbox"
                name="checkbox_021"
                id="checkbox_021"
                value="checkbox_021"
                defaultChecked={
                  data?.motivation?.find((item) => item.type === "checkbox_021")
                    ?.motivation == 1
                }
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label
                class="form-check-label fw-medium"
                for="checkbox_021"
              ></label>
              <span>Ownership (Reseller)</span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                class="form-check-input"
                type="checkbox"
                name="checkbox_022"
                id="checkbox_022"
                value="checkbox_022"
                defaultChecked={
                  data?.motivation?.find((item) => item.type === "checkbox_022")
                    ?.motivation == 1
                }
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label
                class="form-check-label fw-medium"
                for="checkbox_022"
              ></label>
              <span>Non-Ownership (Buyer Broker) </span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                class="form-check-input"
                type="checkbox"
                name="checkbox_023"
                id="checkbox_023"
                value="checkbox_023"
                defaultChecked={
                  data?.motivation?.find((item) => item.type === "checkbox_023")
                    ?.motivation == 1
                }
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label
                class="form-check-label fw-medium"
                for="checkbox_023"
              ></label>
              <span>Non-Ownership (RFI/RFQ) </span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                class="form-check-input"
                type="checkbox"
                name="checkbox_041"
                id="checkbox_041"
                value="checkbox_041"
                defaultChecked={
                  data?.motivation?.find((item) => item.type === "checkbox_041")
                    ?.motivation == 1
                }
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label
                class="form-check-label fw-medium"
                for="checkbox_041"
              ></label>
              <span>Non-Ownership (Contract with End User) </span>
            </div>
            <div className="d-flex align-items-center mt-2">
              <span className="fw-medium">Completed By:</span>
              <span className="ms-2">{"Super Admin  07/17/2023 08:34 AM"}</span>
            </div>
            <div className="d-flex align-items-center mt-2">
              <textarea
                className="form-control"
                name="Purchase_Reason"
                cols="30"
                rows="3"
                value={props?.updateData?.information_text_3}
                onChange={(e) => {
                  const data = { ...props.updateData };
                  data.information_text_3 = e.target.value;
                  props.setUpdateData(data);
                }}
              ></textarea>
            </div>
          </div>
          <div className="dividerTable"></div>
          <div class="page-widget-label text-capitalize">
            Timeline for Purchase
          </div>
          <div>
            <textarea
              name="Timeline_for_Purchase"
              cols="30"
              rows="3"
              className="form-control"
              value={props?.updateData?.information_text_4}
              onChange={(e) => {
                const data = { ...props.updateData };
                data.information_text_4 = e.target.value;
                props.setUpdateData(data);
              }}
            ></textarea>
          </div>
          <div className="dividerTable"></div>
          <div class="page-widget-label text-capitalize">
            Fund Transfer Preference
          </div>
          <div>
            {!(
              data?.motivation?.find((item) => item.type === "checkbox_025")
                ?.motivation == 1 ||
              data?.motivation?.find((item) => item.type === "checkbox_026")
                ?.motivation == 1 ||
              data?.motivation?.find((item) => item.type === "checkbox_027")
                ?.motivation == 1 ||
              data?.motivation?.find((item) => item.type === "checkbox_028")
                ?.motivation == 1
            ) && (
              <div class="form-check d-flex align-items-center mt-2">
                <input
                  class="form-check-input"
                  type="checkbox"
                  name="checkbox_024"
                  id="checkbox_024"
                  value="checkbox_024"
                  defaultChecked={
                    data?.motivation?.find(
                      (item) => item.type === "checkbox_024"
                    )?.motivation == 1
                  }
                  onChange={(e) => {
                    const data = { ...props.updateCheckbox };
                    if (e.target.checked) {
                      data.value = e.target.value;
                      data.check = 1;
                    } else {
                      data.value = e.target.value;
                      data.check = 0;
                    }
                    props.setUpdateCheckbox(data);
                  }}
                />
                <label
                  class="form-check-label fw-medium"
                  for="checkbox_024"
                ></label>
                <span>Don't Know</span>
              </div>
            )}
            <div class="form-check d-flex align-items-center mt-2">
              <input
                class="form-check-input"
                type="checkbox"
                name="checkbox_025"
                id="checkbox_025"
                value="checkbox_025"
                defaultChecked={
                  data?.motivation?.find((item) => item.type === "checkbox_025")
                    ?.motivation == 1
                }
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label
                class="form-check-label fw-medium"
                for="checkbox_025"
              ></label>
              <span>Wire Transfer</span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                class="form-check-input"
                type="checkbox"
                name="checkbox_026"
                id="checkbox_026"
                value="checkbox_026"
                defaultChecked={
                  data?.motivation?.find((item) => item.type === "checkbox_026")
                    ?.motivation == 1
                }
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label
                class="form-check-label fw-medium"
                for="checkbox_026"
              ></label>
              <span>Net Payment Terms</span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                class="form-check-input"
                type="checkbox"
                name="checkbox_027"
                id="checkbox_027"
                value="checkbox_027"
                defaultChecked={
                  data?.motivation?.find((item) => item.type === "checkbox_027")
                    ?.motivation == 1
                }
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label
                class="form-check-label fw-medium"
                for="checkbox_027"
              ></label>
              <span>Payment Upon Delivery </span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                class="form-check-input"
                type="checkbox"
                name="checkbox_028"
                id="checkbox_028"
                value="checkbox_028"
                defaultChecked={
                  data?.motivation?.find((item) => item.type === "checkbox_028")
                    ?.motivation == 1
                }
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label
                class="form-check-label fw-medium"
                for="checkbox_028"
              ></label>
              <span>Letter of Credit </span>
            </div>
            <div className="d-flex align-items-center mt-2">
              <span className="fw-medium">Completed By:</span>
              <span className="ms-2">{"Super Admin  07/17/2023 08:34 AM"}</span>
            </div>
            <div className="d-flex align-items-center mt-2">
              <textarea
                className="form-control"
                name="Fund_Transfer_Preference"
                cols="30"
                rows="3"
                value={props?.updateData?.information_text_5}
                onChange={(e) => {
                  const data = { ...props.updateData };
                  data.information_text_5 = e.target.value;
                  props.setUpdateData(data);
                }}
              ></textarea>
            </div>
          </div>
          <div className="dividerTable"></div>
          <div class="page-widget-label text-capitalize">Max Budget Given</div>
          <div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                class="form-check-input"
                type="checkbox"
                name="checkbox_029"
                id="checkbox_029"
                value="checkbox_029"
                defaultChecked={
                  data?.motivation?.find((item) => item.type === "checkbox_029")
                    ?.motivation == 1
                }
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label
                class="form-check-label fw-medium"
                for="checkbox_029"
              ></label>
              <span>$0</span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                class="form-check-input"
                type="checkbox"
                name="checkbox_030"
                id="checkbox_030"
                value="checkbox_030"
                defaultChecked={
                  data?.motivation?.find((item) => item.type === "checkbox_030")
                    ?.motivation == 1
                }
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label
                class="form-check-label fw-medium"
                for="checkbox_030"
              ></label>
              <span>$0-$20,000</span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                class="form-check-input"
                type="checkbox"
                name="checkbox_031"
                id="checkbox_031"
                value="checkbox_031"
                defaultChecked={
                  data?.motivation?.find((item) => item.type === "checkbox_031")
                    ?.motivation == 1
                }
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label
                class="form-check-label fw-medium"
                for="checkbox_031"
              ></label>
              <span>$20,000-$100,000</span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                class="form-check-input"
                type="checkbox"
                name="checkbox_032"
                id="checkbox_032"
                value="checkbox_032"
                defaultChecked={
                  data?.motivation?.find((item) => item.type === "checkbox_032")
                    ?.motivation == 1
                }
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label
                class="form-check-label fw-medium"
                for="checkbox_032"
              ></label>
              <span>$100,000-$500,000</span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                class="form-check-input"
                type="checkbox"
                name="checkbox_033"
                id="checkbox_033"
                value="checkbox_033"
                defaultChecked={
                  data?.motivation?.find((item) => item.type === "checkbox_033")
                    ?.motivation == 1
                }
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label
                class="form-check-label fw-medium"
                for="checkbox_033"
              ></label>
              <span>$500,000- $1m </span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                class="form-check-input"
                type="checkbox"
                name="checkbox_034"
                id="checkbox_034"
                value="checkbox_034"
                defaultChecked={
                  data?.motivation?.find((item) => item.type === "checkbox_034")
                    ?.motivation == 1
                }
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label
                class="form-check-label fw-medium"
                for="checkbox_034"
              ></label>
              <span>$1m+</span>
            </div>
            <div className="d-flex align-items-center mt-2">
              <span className="fw-medium">Completed By:</span>
              <span className="ms-2">{"Super Admin  07/17/2023 08:34 AM"}</span>
            </div>
            <div className="d-flex align-items-center mt-2">
              <textarea
                className="form-control"
                name="Max_Budget_Given"
                cols="30"
                rows="3"
                value={props?.updateData?.information_text_6}
                onChange={(e) => {
                  const data = { ...props.updateData };
                  data.information_text_6 = e.target.value;
                  props.setUpdateData(data);
                }}
              ></textarea>
            </div>
          </div>
          <div className="dividerTable"></div>
          <div class="page-widget-label text-capitalize">Cumulative Sales</div>
          <div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                class="form-check-input"
                type="checkbox"
                name="checkbox_035"
                id="checkbox_035"
                value="checkbox_035"
                defaultChecked={
                  data?.motivation?.find((item) => item.type === "checkbox_035")
                    ?.motivation == 1
                }
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label
                class="form-check-label fw-medium"
                for="checkbox_035"
              ></label>
              <span>$0</span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                class="form-check-input"
                type="checkbox"
                name="checkbox_036"
                id="checkbox_036"
                value="checkbox_036"
                defaultChecked={
                  data?.motivation?.find((item) => item.type === "checkbox_036")
                    ?.motivation == 1
                }
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label
                class="form-check-label fw-medium"
                for="checkbox_036"
              ></label>
              <span>$0-$20,000</span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                class="form-check-input"
                type="checkbox"
                name="checkbox_037"
                id="checkbox_037"
                value="checkbox_037"
                defaultChecked={
                  data?.motivation?.find((item) => item.type === "checkbox_037")
                    ?.motivation == 1
                }
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label
                class="form-check-label fw-medium"
                for="checkbox_037"
              ></label>
              <span>$20,000-$100,000</span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                class="form-check-input"
                type="checkbox"
                name="checkbox_038"
                id="checkbox_038"
                value="checkbox_038"
                defaultChecked={
                  data?.motivation?.find((item) => item.type === "checkbox_038")
                    ?.motivation == 1
                }
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label
                class="form-check-label fw-medium"
                for="checkbox_038"
              ></label>
              <span>$100,000-$500,000</span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                class="form-check-input"
                type="checkbox"
                name="checkbox_039"
                id="checkbox_039"
                value="checkbox_039"
                defaultChecked={
                  data?.motivation?.find((item) => item.type === "checkbox_039")
                    ?.motivation == 1
                }
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label
                class="form-check-label fw-medium"
                for="checkbox_039"
              ></label>
              <span>$500,000- $1m </span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                class="form-check-input"
                type="checkbox"
                name="checkbox_040"
                id="checkbox_040"
                value="checkbox_040"
                defaultChecked={
                  data?.motivation?.find((item) => item.type === "checkbox_040")
                    ?.motivation == 1
                }
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label
                class="form-check-label fw-medium"
                for="checkbox_040"
              ></label>
              <span>$1m+</span>
            </div>
            <div className="d-flex align-items-center mt-2">
              <span className="fw-medium">Completed By:</span>
              <span className="ms-2">{"Super Admin  07/17/2023 08:34 AM"}</span>
            </div>
            <div className="d-flex align-items-center mt-2">
              <textarea
                className="form-control"
                name="Cumulative_Sales"
                cols="30"
                rows="3"
                value={props?.updateData?.information_text_7}
                onChange={(e) => {
                  const data = { ...props.updateData };
                  data.information_text_7 = e.target.value;
                  props.setUpdateData(data);
                }}
              ></textarea>
            </div>
          </div>
          <div className="dividerTable"></div>
          <div class="page-widget-label text-capitalize">
            Buyer Communications
          </div>
          <div>
            <div className="d-flex align-items-center mt-2">
              <textarea
                className="form-control"
                name="Buyer_Communications"
                cols="30"
                rows="3"
                value={props?.updateData?.information_text_8}
                onChange={(e) => {
                  const data = { ...props.updateData };
                  data.information_text_8 = e.target.value;
                  props.setUpdateData(data);
                }}
              ></textarea>
            </div>
          </div>
          <div className="dividerTable"></div>
          <div class="page-widget-label text-capitalize">Other Information</div>
          <div>
            <div className="d-flex align-items-center mt-2">
              <textarea
                className="form-control"
                name="Buyer_Motivation_Other_Information"
                cols="30"
                rows="3"
                value={props?.updateData?.information_text_9}
                onChange={(e) => {
                  const data = { ...props.updateData };
                  data.information_text_9 = e.target.value;
                  props.setUpdateData(data);
                }}
              ></textarea>
            </div>
          </div>
        </Modal.Body>
        <div class="custom-modal-dividor"></div>
        <div className="custom-modal-footer d-grid gap-2 d-md-flex align-items-center justify-content-md-end">
          <button
            class="btn btn-link me-md-2 global-cancel-button"
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => props.onHide()}
          >
            Cancel
          </button>
          <NextButton
            label="Save"
            classData="btn btn-default"
            handleSubmit={props.handleSubmit}
            loading={props.loading}
          />
        </div>
      </div>
    </Modal>
  );
};

export default EditBuyerMotivationModal;
