import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { dashboardData, getGoogleAnalytics } from "../../../store/dashBoard";
import moment from "moment";
import { toast } from "react-toastify";
import AlertError from "../../../common/alerts/alertError";
import calendar from "../../../include/images/calendar.svg";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import Select from "react-select";
import { customStyles } from "../../../common/customStyles/reactSelectStyle";
const GoogleAnalytics = (props) => {
  ChartJS.register(ArcElement, Tooltip, Legend);
  const colorArr = [
    "rgba(255, 99, 132, 0.2)",
    "rgba(54, 162, 235, 0.2)",
    "rgba(255, 206, 86, 0.2)",
    "rgba(75, 192, 192, 0.2)",
    "rgba(153, 102, 255, 0.2)",
    "rgba(255, 159, 64, 0.2)",
    "rgba(255, 99, 132, 0.2)",
    "rgba(54, 162, 235, 0.2)",
    "rgba(255, 206, 86, 0.2)",
    "rgba(75, 192, 192, 0.2)",
    "rgba(153, 102, 255, 0.2)",
    "rgba(255, 159, 64, 0.2)",
    "rgba(255, 99, 132, 0.2)",
    "rgba(54, 162, 235, 0.2)",
    "rgba(255, 206, 86, 0.2)",
    "rgba(75, 192, 192, 0.2)",
    "rgba(153, 102, 255, 0.2)",
    "rgba(255, 159, 64, 0.2)",
  ];
  const borderArr = [
    "rgba(255, 99, 132, 1)",
    "rgba(54, 162, 235, 1)",
    "rgba(255, 206, 86, 1)",
    "rgba(75, 192, 192, 1)",
    "rgba(153, 102, 255, 1)",
    "rgba(255, 159, 64, 1)",
    "rgba(255, 99, 132, 1)",
    "rgba(54, 162, 235, 1)",
    "rgba(255, 206, 86, 1)",
    "rgba(75, 192, 192, 1)",
    "rgba(153, 102, 255, 1)",
    "rgba(255, 159, 64, 1)",
    "rgba(255, 99, 132, 1)",
    "rgba(54, 162, 235, 1)",
    "rgba(255, 206, 86, 1)",
    "rgba(75, 192, 192, 1)",
    "rgba(153, 102, 255, 1)",
    "rgba(255, 159, 64, 1)",
  ];
  const [newData, setNewData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const data = props?.dashboardData?.googleAnalytics?.data;
  useEffect(() => {
    setNewData([]);
    data?.rows?.map((val) => {
      const obj = {
        campaign_name: val?.dimensionValues?.[1]?.value,
        page_views: val?.metricValues?.[0]?.value,
        bids_count: val?.metricValues?.[1]?.value,
        bids_amount: val?.metricValues?.[2]?.value,
      };
      setNewData((pre) => [...pre, obj]);
    });
  }, [data]);
  useEffect(() => {
    setFilteredData([]);
    const result = newData.reduce((accumulator, current) => {
      const existingEntry = accumulator.find((entry) => entry.campaign_name === current.campaign_name);

      if (existingEntry) {
        // If the entry already exists, add the values

        existingEntry.bids_count = (parseInt(existingEntry.bids_count) + parseInt(current.bids_count)).toString();
        existingEntry.bids_amount = (parseInt(existingEntry.bids_amount) + parseInt(current.bids_amount)).toString();
        existingEntry.page_views = (parseInt(existingEntry.page_views) + parseInt(current.page_views)).toString();
      } else {
        // If the entry does not exist, add a new entry
        accumulator.push({ ...current });
      }

      return accumulator;
    }, []);
    setFilteredData(result);
  }, [newData]);

  const chartPageView = {
    labels: filteredData?.map((val) => val?.campaign_name),
    datasets: [
      {
        label: "Page Views",
        data: filteredData?.map((val) => val?.page_views),
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const chartBidsCount = {
    labels: filteredData?.map((val) => val?.campaign_name),
    datasets: [
      {
        label: "Bids Count",
        data: filteredData?.map((val) => val?.bids_count),
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const chartBidsAmount = {
    labels: filteredData?.map((val) => val?.campaign_name),
    datasets: [
      {
        label: "Bids Amount",
        data: filteredData?.map((val) => val?.bids_amount),
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const [day, setDay] = useState(30);
  const [date, setDate] = useState(new Date(moment().subtract(30, "days")));
  const [fetch, setFetch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [range, setRange] = useState({ name: "Last 1 year", value: "365" });

  useEffect(() => {
    if (props.auctionId) {
      props?.refStart();
      setLoading(true);
      props.getGoogleAnalytics(props.auctionId, day, (res) => {
        if (res?.status === 200) {
          setLoading(false);
          props?.refStop();
        } else {
          setLoading(false);
          props?.refStop();
        }
      });
    }
  }, [day, props.auctionId]);

  return (
    <div class="details-page-widget-block" id={props.id}>
      <div class="page-widget-head d-flex   google-analytics">
        <div class="d-flex  google-analytic-heading">
          <div class="page-widget-label text-capitalize cursor-pointer">Google Analytics</div>
        </div>
        <div className="google-analytic-select">
          <Select
            className="basic-single"
            classNamePrefix="select-search "
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.value}
            name={"googleAnalytics"}
            options={[
              { name: "Yesterday", value: "1" },
              { name: "Last 7 days", value: "7" },
              { name: "Last 14 days", value: "14" },
              { name: "Last 30 days", value: "30" },
              { name: "Last 3 months", value: "90" },
              { name: "Last 6 months", value: "180" },
              { name: "Last 1 year", value: "365" },
              { name: "Custom Date", value: "custom" },
            ]}
            placeholder="Select Sale Type"
            styles={customStyles}
            value={range}
            onChange={(e) => {
              if (e?.value != "custom") {
                setDay(e?.value);
              }
              setRange(e);
            }}
          />
        </div>
        <div className="ml-15">
          {range?.name == "Custom Date" || range?.name == "" ? (
            <DatePicker
              className="form-control ml-auto"
              selected={date}
              onChange={(e) => {
                setFetch(true);
                setDay(Math.abs(moment(e).diff(moment(), "days")));
                setDate(e);
              }}
            />
          ) : (
            ""
          )}
        </div>
      </div>
      <div class="page-widget-body mt-3">
        <div class="table-responsive table-custom mt-2">
          <table class="table table-bordered horizontal-table">
            <colgroup>
              <col style={{ width: "36%" }} />
              <col />
            </colgroup>
            <thead>
              <tr>
                <th>Campaign Name</th>
                <th>Page Views</th>
                <th>Bids Count</th>
                <th>Bids Amount</th>
              </tr>
            </thead>
            <tbody>
              {props.loadingMain || loading ? (
                <>
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                </>
              ) : (
                <>
                  {filteredData?.map((item, i) => (
                    <tr>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="me-2">{item?.campaign_name}</span>
                          <div
                            style={{
                              backgroundColor: colorArr[i],
                              border: `1px solid ${borderArr[i]}`,
                              width: "30px",
                              height: "10px",
                            }}
                          />
                        </div>
                      </td>
                      <td>{item?.page_views}</td>
                      <td>{item?.bids_count}</td>
                      <td>
                        {item?.bids_amount
                          ? "$" +
                            new Intl.NumberFormat("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(item?.bids_amount)
                          : "$0"}
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </div>
        {filteredData?.length > 0 ? (
          <div className="d-flex">
            <div style={{ width: "33%" }}>
              <Pie
                data={chartPageView}
                options={{
                  plugins: {
                    legend: {
                      display: false,
                    },
                    title: {
                      display: true,
                      text: "Page Views",
                      position: "bottom",
                    },
                  },
                }}
              />
            </div>
            <div style={{ width: "33%" }}>
              <Pie
                data={chartBidsCount}
                options={{
                  plugins: {
                    legend: {
                      display: false,
                    },
                    title: {
                      display: true,
                      text: "Bids Count",
                      position: "bottom",
                    },
                  },
                }}
              />
            </div>
            <div style={{ width: "33%" }}>
              <Pie
                data={chartBidsAmount}
                options={{
                  plugins: {
                    legend: {
                      display: false,
                    },
                    title: {
                      display: true,
                      text: "Bids Amount",
                      position: "bottom",
                    },
                  },
                }}
              />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getGoogleAnalytics: (params, day, callback) => dispatch(getGoogleAnalytics(params, day, callback)),
});

const mapStateToProps = (state) => ({
  dashboardData: dashboardData(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(GoogleAnalytics));
