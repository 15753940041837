import React from "react";

//Images
import addButton from "../../../include/images/add-button-image.png";
import helpIcon from "../../../include/images/help-circle.svg";
import star from "../../../include/images/star.svg";
import selectedIcon from "../../../include/images/yellow-star-icon.svg";
import edit from "../../../include/images/edit-3.svg";
import maximize from "../../../include/images/maximize-2.svg";
import scribble from "../../../include/images/Scribble-white.svg";
import crop from "../../../include/images/crop.svg";
import rotate from "../../../include/images/rotate-cw.svg";
import trash from "../../../include/images/trash-13x13.svg";
import DeleteModal from "../../../layouts/modals/deleteModalPics";
import save from "../../../include/images/save.svg";
import { OverlayTrigger, Popover } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ImageLargeModal from "../../../layouts/modals/imageGalleryModal";
import UploadImages from "../../../layouts/modals/uploadImages";

const PublicPictures = ({
  refStart,
  refStop,
  loadingMain,
  auctionId,
  listingDetail,
  primaryPicture,
  getMediaData,
  componentRef2,
  primaryImage,
  setSelectedImage,
  setStartIndex,
  handlePrimaryPicture,
  popoverClick,
  setOptionType,
  optionType,
  loadingTitleUpdate,
  setTitle,
  setOptionTypeId,
  setUploadImageModal,
  newListingImages,
  toggleImageGalleryModal,
  uploadImageModal,
  data,
  onChangeImageUpload,
  handleSaveTitle,
  title,
  optionTypeId,
  handleImageUploader,
  imageUploaded,
  deleteHandler,
  progress,
  loadingUpload,
  setEditedFile,
  loadingDelete,
  imageGalleryModal,
  toggleUploadImageModal,
  imgData,
  selectedImage,
  handleEditImageUploader,
  setImageUploaded,
  setProgress,
  componentRef,
  startIndex,
  ...props
}) => {
  return (
    <>
      <div className="details-page-widget-block" id={props.id}>
        <div className="page-widget-head d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <div
              className="page-widget-label text-capitalize cursor-pointer"
              onClick={() => !loadingMain && toggleUploadImageModal()}
            >
              Public Pictures
            </div>
          </div>
          {/* <div className="btn-block">
            {loadingMain ? (
              <div style={{ width: "10rem" }}>
                <Skeleton height="30px" width="100%" />
              </div>
            ) : (
              <a href="javascript:void(0)" className="btn btn-primary btn-sm" onClick={() => toggleUploadImageModal()}>
                Upload
              </a>
            )}
          </div> */}
        </div>
        <div className="page-widget-body mt-3">
          {loadingMain ? (
            <>
              <div className="grid-image-list-block mb-3">
                <div className="grid-image-list d-flex flex-wrap">
                  {[1, 2, 3, 4, 5, 6].map((item, i) => (
                    <div className="grid-image-item">
                      <div className="grid-image-box w-100 h-100 overflow-hidden position-relative">
                        <div className="grid-image">
                          <Skeleton height="100%" />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="page-widget-button">
                <Skeleton width="100%" height="40px" />
              </div>
            </>
          ) : getMediaData &&
            getMediaData.listingImages &&
            getMediaData.listingImages.pics &&
            getMediaData.listingImages.pics.length > 0 ? (
            <>
              <div className="grid-image-list-block mb-3">
                <div className="grid-image-list d-flex flex-wrap" ref={componentRef2}>
                  {primaryImage && (
                    <div
                      className="grid-image-item"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setSelectedImage(null);
                        setStartIndex(0);
                        toggleImageGalleryModal();
                      }}
                    >
                      <div className="grid-image-box w-100 h-100 overflow-hidden position-relative">
                        <img
                          className="grid-image"
                          src={
                            primaryImage.s_path.substring(0, 5) === "https"
                              ? `${primaryImage.s_path}${primaryImage.filename}_lp.${primaryImage.suffix.toLowerCase()}`
                              : `${process.env.REACT_APP_MEDIA_URL}${primaryImage.s_path}${
                                  primaryImage.filename
                                }_lp.${primaryImage.suffix.toLowerCase()}`
                          }
                          alt=""
                        />
                        <div className="grid-hover d-flex flex-column align-items-center justify-content-center">
                          <div className="d-inline-block">
                            <div className="grid-hover-label">{primaryImage.caption ? primaryImage.caption : ""}</div>
                            <div className="grid-hover-list d-flex flex-wrap align-items-center justify-content-between">
                              <div className="grid-hover-item">
                                <div className="d-flex-img d-flex flex-wrap align-items-center justify-content-center overflow-hidden">
                                  <img
                                    src={primaryPicture === primaryImage.picture_id ? selectedIcon : star}
                                    alt=""
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      if (primaryPicture === primaryImage.picture_id) {
                                        return;
                                      } else {
                                        handlePrimaryPicture(primaryImage);
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="grid-hover-item">
                                <div className="d-flex-img d-flex flex-wrap align-items-center justify-content-center overflow-hidden">
                                  <OverlayTrigger
                                    trigger="click"
                                    placement="top"
                                    rootClose
                                    overlay={popoverClick}
                                    bsPrefix="show"
                                    onToggle={() => setOptionType(!optionType)}
                                  >
                                    <img
                                      src={edit}
                                      alt=""
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        if (!loadingTitleUpdate) {
                                          setOptionType("edit");
                                          // setShowBackdrop(true);
                                          setTitle(primaryImage.caption ? primaryImage.caption : "");
                                          setOptionTypeId(primaryImage.picture_id);
                                        }
                                      }}
                                    />
                                  </OverlayTrigger>
                                </div>
                              </div>
                              <div className="grid-hover-item">
                                <div className="d-flex-img d-flex flex-wrap align-items-center justify-content-center overflow-hidden">
                                  <img
                                    src={scribble}
                                    alt=""
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setUploadImageModal(true);
                                      setOptionType("scribble");

                                      // setShowBackdrop(true);
                                      setSelectedImage(primaryImage);
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="grid-hover-item">
                                <div className="d-flex-img d-flex flex-wrap align-items-center justify-content-center overflow-hidden">
                                  <img
                                    src={crop}
                                    alt=""
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setUploadImageModal(true);
                                      setOptionType("crop");

                                      // setShowBackdrop(true);
                                      setSelectedImage(primaryImage);
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="grid-hover-item">
                                <div className="d-flex-img d-flex flex-wrap align-items-center justify-content-center overflow-hidden">
                                  <img
                                    src={rotate}
                                    alt=""
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setUploadImageModal(true);
                                      setOptionType("rotate");
                                      // setShowBackdrop(true);
                                      setSelectedImage(primaryImage);
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="grid-hover-item">
                                <div className="d-flex-img d-flex flex-wrap align-items-center justify-content-center overflow-hidden">
                                  <img
                                    src={trash}
                                    alt=""
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setOptionType("delete");
                                      setSelectedImage(primaryImage);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {newListingImages &&
                    newListingImages.length > 0 &&
                    newListingImages.map(
                      (item, i) =>
                        ((primaryImage && i < 4) || (!primaryImage && i <= 4)) && (
                          <div
                            className="grid-image-item cursor-pointer"
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedImage(null);
                              setStartIndex(i);
                              toggleImageGalleryModal();
                            }}
                          >
                            <div className="grid-image-box w-100 h-100 overflow-hidden position-relative">
                              <img
                                className="grid-image"
                                src={
                                  item.s_path.substring(0, 5) === "https"
                                    ? `${item.s_path}${item.filename}_lp.${item.suffix.toLowerCase()}`
                                    : `${process.env.REACT_APP_MEDIA_URL}${item.s_path}${
                                        item.filename
                                      }_lp.${item.suffix.toLowerCase()}`
                                }
                                alt=""
                              />
                              <div className="grid-hover d-flex flex-column align-items-center justify-content-center">
                                <div className="d-inline-block">
                                  <div className="grid-hover-label">{item.caption ? item.caption : ""}</div>
                                  <div className="grid-hover-list d-flex flex-wrap align-items-center justify-content-between">
                                    <div className="grid-hover-item">
                                      <div className="d-flex-img d-flex flex-wrap align-items-center justify-content-center overflow-hidden">
                                        <img
                                          src={primaryPicture === item.picture_id ? selectedIcon : star}
                                          alt=""
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            if (primaryPicture === item.picture_id) {
                                              return;
                                            } else {
                                              handlePrimaryPicture(item);
                                            }
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="grid-hover-item">
                                      <div className="d-flex-img d-flex flex-wrap align-items-center justify-content-center overflow-hidden">
                                        <OverlayTrigger
                                          trigger="click"
                                          placement="top"
                                          rootClose
                                          overlay={popoverClick}
                                          bsPrefix="show"
                                          onToggle={() => setOptionType(!optionType)}
                                        >
                                          <img
                                            src={edit}
                                            alt=""
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              if (!loadingTitleUpdate) {
                                                setOptionType("edit");
                                                setTitle(item.caption ? item.caption : "");
                                                // setShowBackdrop(true);
                                                setOptionTypeId(item.picture_id);
                                              }
                                            }}
                                          />
                                        </OverlayTrigger>
                                      </div>
                                    </div>
                                    <div className="grid-hover-item">
                                      <div
                                        className="d-flex-img d-flex flex-wrap align-items-center justify-content-center overflow-hidden"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setUploadImageModal(true);
                                          setOptionType("scribble");

                                          // setShowBackdrop(true);
                                          setSelectedImage(item);
                                        }}
                                      >
                                        <img src={scribble} alt="" />
                                      </div>
                                    </div>
                                    <div className="grid-hover-item">
                                      <div className="d-flex-img d-flex flex-wrap align-items-center justify-content-center overflow-hidden">
                                        <img
                                          src={crop}
                                          alt=""
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            setUploadImageModal(true);
                                            setOptionType("crop");

                                            // setShowBackdrop(true);
                                            setSelectedImage(item);
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="grid-hover-item">
                                      <div className="d-flex-img d-flex flex-wrap align-items-center justify-content-center overflow-hidden">
                                        <img
                                          src={rotate}
                                          alt=""
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            setUploadImageModal(true);
                                            setOptionType("rotate");
                                            // setShowBackdrop(true);
                                            setSelectedImage(item);
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="grid-hover-item">
                                      <div className="d-flex-img d-flex flex-wrap align-items-center justify-content-center overflow-hidden">
                                        <img
                                          src={trash}
                                          alt=""
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            setOptionType("delete");
                                            setSelectedImage(item);
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ),
                    )}

                  <div className="grid-image-item">
                    <div className="add-more-image-btn w-100 h-100 overflow-hidden position-relative">
                      <img className="grid-image" src={addButton} alt="" onClick={() => toggleUploadImageModal()} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="page-widget-button">
                <div className="btn btn-default btn-block" onClick={() => toggleUploadImageModal()}>
                  View All Images
                </div>
              </div>
            </>
          ) : (
            <div class="document-upload-block d-flex flex-column align-items-center justify-content-center">
              <div class="dub-label">No Images are currently associated with this listing.</div>
              <div class="dub-group-input mt-2 position-relative">
                <button type="button" class="file-upload-button" onClick={() => toggleUploadImageModal()}>
                  Click here to upload images
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      {optionType === "edit" && (
        <div class={` ${optionType === "edit" ? " modal-backdrop show backdrop-scroll-fix" : ""}`}></div>
      )}
      <UploadImages
        uploadImageModal={uploadImageModal}
        setUploadImageModal={setUploadImageModal}
        listingImages={data}
        setSelectedImage={setSelectedImage}
        onChangeImageUpload={onChangeImageUpload}
        setOptionType={setOptionType}
        setOptionTypeId={setOptionTypeId}
        setTitle={setTitle}
        title={title}
        handleSaveTitle={handleSaveTitle}
        optionType={optionType}
        selectedImage={selectedImage}
        toggleUploadImageModal={toggleUploadImageModal}
        optionTypeId={optionTypeId}
        handleImageUploader={handleImageUploader}
        imageUploaded={imageUploaded}
        deleteHandler={deleteHandler}
        progress={progress}
        loadingMain={false}
        loadingUpload={loadingUpload}
        loadingDelete={loadingDelete}
        loadingTitleUpdate={loadingTitleUpdate}
        setEditedFile={setEditedFile}
        handleEditImageUploader={handleEditImageUploader}
        setImageUploaded={setImageUploaded}
        handlePrimaryPicture={handlePrimaryPicture}
        setProgress={setProgress}
        toggleImageGalleryModal={toggleImageGalleryModal}
        setStartIndex={setStartIndex}
        primaryPicture={primaryPicture}
        componentRef={componentRef}
      />
      <ImageLargeModal
        show={imageGalleryModal}
        onHide={toggleImageGalleryModal}
        items={imgData}
        startIndex={startIndex}
        title={listingDetail && listingDetail.detail && listingDetail.detail.title}
      />
      <DeleteModal
        optionType={optionType}
        selectedImage={selectedImage}
        setOptionType={setOptionType}
        deleteHandler={deleteHandler}
        uploadImageModal={uploadImageModal}
        loadingDelete={loadingDelete}
      />
    </>
  );
};

export default React.memo(PublicPictures);
