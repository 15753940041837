import React from "react";
import { Modal } from "react-bootstrap";
import NextButton from "../../../../common/form/nextButton";
import close from "../../../../include/images/close.svg";
import Select from "react-select";
import { customStyles } from "../../../../common/customStyles/reactSelectStyle";

const AssignModal = (props) => {
  const data = props?.modalData?.data;
  const projects = props?.modalData?.projects ? props?.modalData?.projects : [];
  return (
    <Modal
      className="modal fade custom-modal"
      id="editSustainabilityReport"
      show={props.show}
      onHide={() => props.onHide()}
      dialogClassName="modal-dialog modal-dialog-centered modal-lg"
    >
      <div class="modal-content h-100 d-flex flex-column">
        <div class="custom-modal-header d-flex align-items-center justify-content-between">
          <h5 class="mb-0 text-capitalize">Assign {data?.length > 0 ? data[0]?.title : ""}</h5>
          <button
            type="button"
            class="close-btn d-flex align-items-center justify-content-center"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => props.onHide()}
          >
            <img src={close} alt="" />
          </button>
        </div>
        <Modal.Body className="custom-modal-body flex-grow-1 hide-scrollbar padding-bottom-0">
          <table class="table modal-product-detail-table mb-0">
            <colgroup>
              <col style={{ width: "30%" }} />
              <col style={{ width: "70%" }} />
            </colgroup>
            <tr>
              <td>Assign To</td>
              <td>
                <div className="padding-0">
                  <Select
                    menuPosition="fixed"
                    menuShouldScrollIntoView
                    className="basic-single  "
                    value={props.assignData}
                    styles={customStyles}
                    getOptionLabel={(val) => val.title}
                    getOptionValue={(val) => val.project_id}
                    onChange={(e) => props.setAssignData(e)}
                    options={[
                      { title: "-- No Assignement --", project_id: 0 },
                      ...projects?.filter((val) => val?.project_id !== data[0]?.project_id),
                    ]}
                    classNamePrefix="select-search "
                  />
                </div>
              </td>
            </tr>
          </table>
        </Modal.Body>
        <div class="custom-modal-dividor"></div>
        <div className="custom-modal-footer d-grid gap-2 d-md-flex align-items-center justify-content-md-end">
          <button
            class="btn btn-link me-md-2 global-cancel-button"
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => props.onHide()}
          >
            Cancel
          </button>
          <NextButton
            label="Assign Business Unit"
            classData="btn btn-default"
            handleSubmit={props.handleSubmit}
            loading={props.loading}
          />
        </div>
      </div>
    </Modal>
  );
};

export default AssignModal;
