export const implementLogRocket = (session) => {
  const script = document.createElement("script");
  script.src = "https://cdn.lr-ingest.io/LogRocket.min.js";
  script.crossOrigin = "anonymous";
  script.onload = () => {
    if (window.LogRocket) {
      window.LogRocket.init("z6fl3h/salvex", { network: { isEnabled: false } });
      window.LogRocket.identify(session.user_id, {
        name: session.name01.replace(/[^A-Za-z0-9]/g, ""),
        email: session.email,
      });
    }
  };
  document.head.appendChild(script);
};
