import React from "react";
import { Modal } from "react-bootstrap";
import NextButton from "../../../common/form/nextButton";
import close from "../../../include/images/close.svg";

const NcndaModal = (props) => {
  const onChange = (e) => {
    const data = { ...props.data };
    data[e.target.name] = e.target.value;
    props.setData(data);
  };



  return (
    <Modal
      className="modal fade custom-modal"
      id="moreEmailClicker"
      show={props.show}
      onHide={props.onHide}
      dialogClassName="modal-dialog modal-dialog-centered modal-lg"
    >
      <div class="modal-content d-flex flex-column">
        <div class="custom-modal-header d-flex align-items-center justify-content-between">
          <h5 class="mb-0 text-capitalize">Send Non-Circumvention Non-Disclosure Agreement</h5>
          <button
            type="button"
            class="close-btn d-flex align-items-center justify-content-center"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={props.onHide}
          >
            <img src={close} alt="close" />
          </button>
        </div>
        <div class="custom-modal-body flex-grow-1 pb-0">
          <div class="mb-20">
            <label for="documentTitle" class="form-label">
              Email Address
            </label>
            <input
              type="text"
              name="email"
              class="form-control"
              value={props.data.email}
              onChange={(e) => onChange(e)}
            />
            {props.errors && props.errors.email ? <p className="error-text">{props.errors.email}</p> : ""}
          </div>
          <div class="mb-20">
            <label for="documentTitle" class="form-label">
              Name
            </label>
            <input type="text" name="name" class="form-control" value={props.data.name} onChange={(e) => onChange(e)} />
            {props.errors && props.errors.name ? <p className="error-text">{props.errors.name}</p> : ""}
          </div>
          <div class="mb-20">
            <label for="documentTitle" class="form-label">
              Country
            </label>
            <input
              type="text"
              name="country"
              class="form-control"
              value={props.data.country}
              onChange={(e) => onChange(e)}
            />
            {props.errors && props.errors.country ? <p className="error-text">{props.errors.country}</p> : ""}
          </div>
          <div class="mb-20">
            <label for="documentTitle" class="form-label">
              Company
            </label>
            <input
              type="text"
              name="company"
              class="form-control"
              value={props.data.company}
              onChange={(e) => onChange(e)}
            />
            {props.errors && props.errors.company ? <p className="error-text">{props.errors.company}</p> : ""}
          </div>
          {/* <div class="mb-20">
            <label for="documentTitle" class="form-label">
              Salvex Name
            </label>
            <input
              type="text"
              name="salvex_name"
              class="form-control"
              value={props.data.salvex_name}
              onChange={(e) => onChange(e)}
            />
            {props.errors && props.errors.salvex_name ? <p className="error-text">{props.errors.salvex_name}</p> : ""}
          </div>
          <div class="mb-20">
            <label for="documentTitle" class="form-label">
              Salvex Company
            </label>
            <input
              type="text"
              name="salvex_company"
              class="form-control"
              value={props.data.salvex_company}
              onChange={(e) => onChange(e)}
            />
            {props.errors && props.errors.salvex_company ? (
              <p className="error-text">{props.errors.salvex_company}</p>
            ) : (
              ""
            )}
          </div> */}
        </div>
        <div class="custom-modal-dividor"></div>
        <div class="custom-modal-footer d-grid gap-2 d-md-flex align-items-center justify-content-md-end">
          <a
            href={"javascript:void(0)"}
            class={`btn btn-default sa_preview_btn`}
            onClick={(e) => {
              if (!props.validateNda()) return;
              const urlParams = new URLSearchParams(props.data).toString();
              window.open(`/ncnd-agreement/view?${urlParams}`);
            }}
          >
            Preview
          </a>
          <NextButton
            label="Send Email"
            classData="btn btn-default"
            loading={props.loading}
            handleSubmit={props.handleSubmit}
          />
        </div>
      </div>
    </Modal>
  );
};

export default NcndaModal;
