import React from "react";
import { Modal } from "react-bootstrap";
import close from "../../../include/images/close.svg";
import emptyFolder from "../../../include/images/folder.svg";
import Skeleton from "react-loading-skeleton";
import moment from "moment";

const OrdersModal = (props) => {
  return (
    <Modal
      className="modal fade custom-modal"
      id="editProductDescription"
      dialogClassName="modal-dialog modal-dialog-centered modal-xxl modal-wide"
      show={props.show}
      onHide={() => props.onHide()}
    >
      <div class="modal-content d-flex flex-column">
        <div
          class="custom-modal-header d-flex align-items-center justify-content-between"
          style={{ paddingBottom: "0px" }}
        >
          <h5 class="mb-0 text-capitalize" style={{ marginLeft: "30px" }}>
            <span className="mb-3"> Order Id : {props.tableData?.orderId ? props.tableData?.orderId : ""}</span>
            <br />
            <span className="gray-color-text gray-color-text-xl mt-3" style={{ fontSize: "1rem" }}>
              {props.tableData?.name ? props.tableData?.name : ""}
            </span>
            <br />
            <span className="gray-color-text gray-color-text-xl mt-3">
              {props.tableData?.email ? props.tableData?.email : ""}
            </span>
            <br />
            <span className="gray-color-text gray-color-text-xl mt-3">
              {props?.tableData?.createdAt ? moment(props?.tableData?.createdAt).format("MMM-DD-YYYY") : "N/A"}
            </span>
          </h5>
          <button
            type="button"
            class="close-btn d-flex align-items-center justify-content-center"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => props.onHide()}
          >
            <img src={close} alt="" />
          </button>
        </div>
        <Modal.Body className="custom-modal-body flex-grow-1 w-100 overflow-hidden">
          <div class="table-container">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12">
                  <div class="selected-table-row d-flex justify-content-between"></div>
                  <div
                    class={`table-responsive position-relative ${
                      props.orderRequestData && props.orderRequestData.length > 0 ? "min-user-table-height" : ""
                    }`}
                  >
                    <div class="table-selected-count d-none align-items-center justify-content-between">
                      <div class="tsc-check-label d-flex align-items-center">
                        <div class="tsc-checkbox">
                          <div class="form-check tick-checkbox">
                            <input class="form-check-input" type="checkbox" value="" id="checkAll" />
                            <label class="form-check-label" for="checkAll"></label>
                          </div>
                        </div>
                        <div class="link-label fw-medium">2 Selected</div>
                        <div class="link-clear fw-medium">Clear all</div>
                      </div>
                    </div>
                    <div class="table-responsive table-custom">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th style={{ width: "9%", minWidth: "133px" }}>
                              <a className="sort-by"> Auction Id </a>{" "}
                            </th>
                            <th style={{ width: "9%", minWidth: "133px" }}>
                              <a className={`sort-by`}> Part Number </a>{" "}
                            </th>
                            <th style={{ width: "9%", minWidth: "180px" }}>
                              <a className={`sort-by`}> Part Description </a>{" "}
                            </th>
                            <th style={{ width: "9%" }}>
                              <a className={`sort-by`}>QTY </a>
                            </th>
                            <th style={{ width: "9%" }}>
                              <a className={`sort-by`}>Condition </a>{" "}
                            </th>
                            <th style={{ width: "9%", minWidth: "175px" }}>
                              <a className={`sort-by`}>
                                <span> Part Manufacturer</span>{" "}
                              </a>{" "}
                            </th>
                            <th style={{ width: "9%", minWidth: "130px" }}>
                              <a className={`sort-by`}>Plane Model </a>
                            </th>
                            <th style={{ width: "9%", minWidth: "160px" }}>
                              <a className={`sort-by`}> Part of the Plane </a>
                            </th>
                            <th style={{ width: "5%" }}>
                              <a className={`sort-by`}>Certificate </a>{" "}
                            </th>
                            <th style={{ width: "9%" }}>
                              {" "}
                              <a className={`sort-by`}>Warranty </a>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {props.loading
                            ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2, 3, 4].map((item) => (
                                <tr>
                                  <td style={{ width: "9%", minWidth: "133px" }}>
                                    <Skeleton />
                                  </td>
                                  <td style={{ width: "9%" }}>
                                    <Skeleton />
                                  </td>
                                  <td style={{ width: "9%" }}>
                                    <Skeleton />
                                  </td>
                                  <td style={{ width: "9%" }}>
                                    <Skeleton />
                                  </td>
                                  <td style={{ width: "9%" }}>
                                    <Skeleton />
                                  </td>
                                  <td style={{ width: "9%", minWidth: "175px" }}>
                                    <Skeleton />
                                  </td>
                                  <td style={{ width: "9%", minWidth: "130px" }}>
                                    <Skeleton />
                                  </td>

                                  <td style={{ width: "9%", minWidth: "210px" }}>
                                    <Skeleton />
                                  </td>
                                  <td style={{ width: "9%", minWidth: "211px" }}>
                                    <Skeleton />
                                  </td>
                                  <td style={{ width: "9%" }}>
                                    <Skeleton />
                                  </td>
                                </tr>
                              ))
                            : props.tableData &&
                              props.tableData?.orders?.length > 0 &&
                              props.tableData?.orders?.map((item) => {
                                return (
                                  <tr>
                                    <td> {item?.aviationPart?.auctionId ? item?.aviationPart?.auctionId : "N/A"}</td>
                                    <td style={{ minWidth: "130px" }}>
                                      <span
                                        className="aviation-auction link-item"
                                        onClick={() => {
                                          props.setSelectedValue(item);
                                          props.toggleViewAllModal();
                                        }}
                                      >
                                        {" "}
                                        {item?.aviationPart?.partNumber ? item?.aviationPart?.partNumber : "N/A"}
                                      </span>
                                    </td>
                                    <td>
                                      {" "}
                                      {item?.aviationPart?.partDescription
                                        ? item?.aviationPart?.partDescription
                                        : "N/A"}
                                    </td>

                                    <td> {item?.quantity ? item?.quantity : "N/A"}</td>
                                    <td> {item?.aviationPart?.condition ? item?.aviationPart?.condition : "N/A"}</td>
                                    <td>
                                      {item?.aviationPart?.partManufacturer
                                        ? item?.aviationPart?.partManufacturer
                                        : "N/A"}
                                    </td>
                                    <td>
                                      {item?.aviationPart?.planeModels && item?.aviationPart?.planeModels.length
                                        ? item?.aviationPart?.planeModels?.map((item) => item).join(",")
                                        : "N/A"}
                                    </td>
                                    <td>{item?.aviationPart?.planePart ? item?.aviationPart?.planePart : "N/A"}</td>
                                    <td> {item?.aviationPart?.certificate ? "Yes" : "No"}</td>

                                    <td>{item?.aviationPart?.warranty ? "Yes" : "No"}</td>
                                  </tr>
                                );
                              })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {!props.loading && props.tableData?.orders && props.tableData?.orders?.length === 0 && (
                    <>
                      <div class="table-empty">
                        <div class="table-empty-inner">
                          <img src={emptyFolder} alt="" />
                          <p>No Items Found</p>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default OrdersModal;
