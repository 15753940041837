import React from "react";
import { Modal } from "react-bootstrap";

const RuleArchiveModal = (props) => {
  return (
    <Modal
      className=" fade custom-modal-popup"
      show={props.show}
      onHide={() => props.onHide()}
      enforceFocus={true}
      backdropClassName={"delete-backdrop"}
      centered
    >
      <Modal.Body>
        <div class="modal-alert-box">
          <h4>Alert! </h4>
          <p>Please enter an archive reason before archiving.</p>
        </div>
        <div class="modal-action-btn">
          <a href="javascript:void(0)" class={`btn btn-delete btn-md btn-red`} onClick={() => props.onHide()}>
            Ok
          </a>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RuleArchiveModal;
