import { createSelector, createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";

const slice = createSlice({
    name: "buyerInterests",
    initialState: {
        loading: false,
        buyerinterests: [],
        taskType: [],
        buyerMotivation: {},
        sellerMotivation: {},
        buyerInterestList: [],
    },
    reducers: {
        buyerinterestsRequested: (state, action) => {
            state.loading = true;
        },
        buyerinterestsRecieved: (state, action) => {
            state.buyerinterests = action.payload;
            state.loading = false;
        },
        buyerinterestsFailed: (state, action) => {
            state.loading = false;
        },
        taskTypeRequested: (state, action) => {
            state.loading = true;
        },
        taskTypeReceived: (state, action) => {
            state.taskType = action.payload;
            state.loading = false;
        },
        taskTypeFailed: (state, action) => {
            state.loading = false;
        },
        buyerMotivationRequested: (state, action) => {
            state.loading = true;
        },
        buyerMotivationReceived: (state, action) => {
            state.buyerMotivation = action.payload;
            state.loading = false;
        },
        buyerMotivationFailed: (state, action) => {
            state.loading = false;
        },
        sellerMotivationRequested: (state, action) => {
            state.loading = true;
        },
        sellerMotivationReceived: (state, action) => {
            state.sellerMotivation = action.payload;
            state.loading = false;
        },
        sellerMotivationFailed: (state, action) => {
            state.loading = false;
        },
        buyerInterestListRequested: (state, action) => {
            state.loading = true;
        },
        buyerInterestListReceived: (state, action) => {
            state.buyerInterestList = action.payload;
            state.loading = false;
        },
        buyerInterestListFailed: (state, action) => {
            state.loading = false;
        },
    }
});

export const {
    buyerinterestsRequested,
    buyerinterestsRecieved,
    buyerinterestsFailed,
    taskTypeRequested,
    taskTypeReceived,
    taskTypeFailed,
    buyerMotivationRequested,
    buyerMotivationReceived,
    buyerMotivationFailed,
    sellerMotivationRequested,
    sellerMotivationReceived,
    sellerMotivationFailed,
    buyerInterestListRequested,
    buyerInterestListReceived,
    buyerInterestListFailed,
} = slice.actions;
export default slice.reducer;

//Action Creator
const baseUrl = "buyerInterest";

//Get Buyer Interests Lists
export const getBuyerInterests = (params, page, filter, callback) => (disaptch) => {
    return disaptch(
        apiCallBegan({
            url: `${baseUrl}/list/${params}?${filter == '' ? `filter_interest=""` : `filter_interest=${filter}`}&page=${page}&viewall=false`,
            method: 'get',
            callback,
            onStart: buyerinterestsRequested.type,
            onSuccess: buyerinterestsRecieved.type,
            onError: buyerinterestsFailed.type,
        })
    )
};

//Update Bids Status
export const updateBidStatus = (params, data, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: `${baseUrl}/bidStatus/${params}`,
            method: 'put',
            data,
            callback,
        })
    )
}
//Update Buyer Budget 
export const updateBuyerBudget = (params, data, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: `${baseUrl}/buyerBudget/${params}`,
            method: "put",
            data,
            callback,
        })
    )
}
//Update Max Bids Status
export const updateMaxBidStatus = (params, data, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: `${baseUrl}/maxBidStatus/${params}`,
            method: "put",
            data,
            callback,
        })
    )
}
//Save Note
export const saveNote = (params, data, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: `${baseUrl}/saveNote/${params}`,
            method: "post",
            data,
            callback
        })
    )
}

//Get Task Type
export const getTaskType = (callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: `${baseUrl}/taskType`,
            method: "get",
            callback,
            onStart: taskTypeRequested.type,
            onSuccess: taskTypeReceived.type,
            onError: taskTypeFailed.type,
        })
    )
}

//Get Buyer Motivation
export const getBuyerMotivation = (params, userId, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: `${baseUrl}/buyerMotivation/${params}?user_id=${userId}`,
            method: "get",
            callback,
            onStart: buyerMotivationRequested.type,
            onSuccess: buyerMotivationReceived.type,
            onError: buyerMotivationFailed.type,
        })
    )
}

//Get Seller Motivation
export const getSellerMotivation = (params, userId, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: `${baseUrl}/sellerMotivation/${params}?user_id=${userId}`,
            method: "get",
            callback,
            onStart: sellerMotivationRequested.type,
            onSuccess: sellerMotivationReceived.type,
            onError: sellerMotivationFailed.type,
        })
    )
}

//Send Product Info Request
export const sendProductInfoRequest = (params, data, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: `${baseUrl}/sendProductInfoRequest/${params}`,
            method: "post",
            data,
            callback,
        })
    )
}

//Send Inspection Support Request
export const sendInspectionSupportRequest = (params, data, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: `${baseUrl}/sendInspectionSupportRequest/${params}`,
            method: 'post',
            data,
            callback,
        })
    )
}

//Send Logistic Support Request
export const sendLogisticSupportRequest = (params, data, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: `${baseUrl}/sendLogisticSupportRequest/${params}`,
            method: "post",
            data,
            callback,
        })
    )
}

//Update Buyer Motivation 
export const updateBuyerMotivation = (params, data, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: `${baseUrl}/buyerMotivation/${params}`,
            method: 'put',
            data,
            callback,
        })
    )
}
//Update Buyer Motivation CheckBox
export const updateBuyerMotivationCheckbox = (params, data, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: `${baseUrl}/buyerMotivationCheckbox/${params}`,
            method: 'put',
            data,
            callback,
        })
    )
};

//Update Seller Motivation Checkbox
export const updateSellerMotivationCheckbox = (params, data, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: `sellerMotivation/sellerMotivationCheckbox/${params}`,
            method: "put",
            data,
            callback,
        })  
    )
}

//Get Buyer Interest List
export const getBuyerInterestList = (params, page, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: `${baseUrl}/${params}?page=${page}`,
            method: "get",
            callback,
            onStart: buyerInterestListRequested.type,
            onSuccess: buyerInterestListReceived.type,
            onError: buyerInterestListFailed.type,
        })
    )
}
export const buyerInterestsData = createSelector(
    (state) => state.entities.buyerInterests,
    (buyerInterests) => (buyerInterests)
)