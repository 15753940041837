import React, { useEffect } from "react";
import { Offcanvas } from "react-bootstrap";
import close from "../../../include/images/close-12x12.svg";
import emptyChat from "../../../include/images/chat-empty.svg";

import moment from "moment";
import Skeleton from "react-loading-skeleton";
import { ConvertTime } from "../../../common/DateFormat/cstToLocalTime";
import { getProductCount } from "../../../store/listingProduct";
import { getAllUnreadCount } from "../../../store/questions";
import { connect } from "react-redux";
import { removeTags } from "../../../utils.js/conversion";

const CustomerChat = (props) => {
  const title = props.detail && props.detail.title ? props.detail.title : "";
  const auctionId = props.detail && props.detail.auction_id ? props.detail.auction_id : "";

  useEffect(() => {
    props.getProductCount(props?.auctionId, (res) => {
      if (res.status === 200) {
      }
    });
    props?.getAllUnreadCount((res) => {});
  }, [props?.show]);

  return (
    <Offcanvas
      placement="end"
      backdropClassName="upload-documnets-backdrop"
      show={props.show}
      onHide={() => props.onHide()}
      className=" right-collapse fade modal-lg chat-screen  offcanvas-pannel-container"
      dialogClassName="modal-dialog"
    >
      <Offcanvas.Header className="offcanvas-header">
        <h5 class="offcanvas-title">{`${auctionId}-${
          title ? (title.length > 40 ? title.substring(0, 40) + "..." : title) : ""
        }`}</h5>
        <button
          type="button"
          class="offcanvas-close-btn d-flex align-items-center justify-content-center"
          onClick={() => props.onHide()}
        >
          <img src={close} alt="" />
        </button>
      </Offcanvas.Header>
      <Offcanvas.Body className="pannel-body py-0" data-simplebar>
        <div class="message-block">
          {props.loadingChats ? (
            [1, 2, 3, 4, 5, 6, 7].map((item) => (
              <>
                <div className="p-3">
                  <Skeleton height="40px" />
                </div>
              </>
            ))
          ) : props.chatList && props.chatList.length > 0 ? (
            props.chatList.map((item) => (
              <div
                class="message-block-item has-notify d-flex flex-wrap align-items-center"
                onClick={() => {
                  props.toggleMessages();
                  props.setSelectedChat(item);
                }}
              >
                <div
                  class="mb-img-box user-img-box custom-profile "
                  style={{ backgroundColor: item?.chat_color ? item?.chat_color : "#47ad1d" }}
                >
                  <p className="chat-dp">
                    {item?.user_name?.split(" ")[0] && item?.user_name?.split(" ")[0][0]}
                    {item?.user_name?.split(" ")[1] && item?.user_name?.split(" ")[1][0]}
                  </p>
                </div>
                <div class="mb-text-box">
                  <h5 style={{ fontWeight: "600" }}>{item?.user_name}</h5>
                  <p>
                    {item.message
                      ? item.message.length > 60
                        ? removeTags(item.message.substring(0, 60)) + "..."
                        : removeTags(item.message)
                      : ""}
                  </p>
                </div>
                <div class="notify-box chat-date">
                  <div class="mb-days-box">
                    {item.date_created && moment(ConvertTime(item?.date_created)).format("MM-DD-YYYY HH:mm")}
                  </div>
                  {item?.unread_ids?.length > 0 ? <div class="mb-notify-box">{item.unread_ids?.length}</div> : ""}
                </div>
              </div>
            ))
          ) : (
            <>
              <div class="pannel-body d-flex align-items-center justify-content-center empty-verification-documents">
                <div class="empty-pannel-box">
                  <img src={emptyChat} alt="" />
                  <h4>No Chats Found!</h4>
                </div>
              </div>
            </>
          )}
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getProductCount: (id, callback) => dispatch(getProductCount(id, callback)),
  getAllUnreadCount: (callback) => dispatch(getAllUnreadCount(callback)),
});

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(CustomerChat));
