import React, { useState } from "react";
import Sidebar from "../../../layouts/sidebar";
import emptyFolder from "../../../include/images/folder.svg";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import DateFormat from "../../../common/DateFormat/dateFormat";
import close from "../../../include/images/close.svg";

const ListingAgreementsTable = (props) => {
  //For SideNav
  const [showMenu, setShowMenu] = useState(false);
  const [menuList, setMenuList] = useState("");
  return (
    <>
      <div class="dashboard-insider position-relative d-lg-flex w-100 ">
        <Sidebar
          showMenu={showMenu}
          setShowMenu={setShowMenu}
          menuList={menuList}
          setMenuList={setMenuList}
          page="legal"
          pageCat="agreement"
        />
        <div className={showMenu ? ` main-menu-expanded ` : "main-menu-compressed"}>
          <div class="filter-container d-flex align-items-center sticky-top">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12 d-flex align-items-center flex-wrap justify-content-between">
                  <div class="filter-title fw-medium text-capitalize">Listing Agreements</div>
                  <div class="filter-widget-right d-flex align-items-center flex-wrap">
                    <div class="fw-item">
                      {props.loading ? (
                        <Skeleton width="50px" height="30px" />
                      ) : (
                        <div class="filter-row d-flex align-items-center" onClick={() => props.toggleFilter()}>
                          <svg viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M2.39767 16.5C2.07751 16.5 1.81796 16.2357 1.81796 15.9097V1.09032C1.81796 0.764295 2.07751 0.5 2.39767 0.5C2.71784 0.5 2.97738 0.764295 2.97738 1.09032V15.9097C2.97738 16.2357 2.71784 16.5 2.39767 16.5Z"
                              fill="currentColor"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M13.0226 15.9097C13.0226 16.2357 13.2822 16.5 13.6023 16.5C13.9225 16.5 14.182 16.2357 14.182 15.9097V1.09032C14.182 0.764295 13.9225 0.5 13.6023 0.5C13.2822 0.5 13.0226 0.764295 13.0226 1.09032V15.9097ZM0.57971 13.18H4.21565C4.53582 13.18 4.79536 12.9158 4.79536 12.5897C4.79536 12.2637 4.53582 11.9994 4.21565 11.9994H0.57971C0.259545 11.9994 0 12.2637 0 12.5897C0 12.9158 0.259545 13.18 0.57971 13.18Z"
                              fill="currentColor"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M7.42029 15.9097C7.42029 16.2357 7.67983 16.5 8 16.5C8.32016 16.5 8.57971 16.2357 8.57971 15.9097V1.09032C8.57971 0.764295 8.32016 0.5 8 0.5C7.67983 0.5 7.42029 0.764295 7.42029 1.09032V15.9097ZM11.7843 13.18H15.4203C15.7405 13.18 16 12.9158 16 12.5897C16 12.2637 15.7405 11.9994 15.4203 11.9994H11.7843C11.4642 11.9994 11.2046 12.2637 11.2046 12.5897C11.2046 12.9158 11.4642 13.18 11.7843 13.18Z"
                              fill="currentColor"
                            />
                            <path
                              d="M9.81798 5.00058H6.18204C5.86187 5.00058 5.60233 4.73629 5.60233 4.41027C5.60233 4.08424 5.86187 3.81995 6.18204 3.81995H9.81798C10.1381 3.81995 10.3977 4.08424 10.3977 4.41027C10.3977 4.73629 10.1381 5.00058 9.81798 5.00058Z"
                              fill="currentColor"
                            />
                          </svg>
                          <span>Filters</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="table-container">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12">
                  <div class="tb-filter-row d-flex align-items-start justify-content-between">
                    <div class="filter-tags d-flex align-items-start">
                      {!props.loading && (props?.response?.auctionId || props?.response?.keyword) && (
                        <>
                          <div class="filter-tag-title">Filters Applied :</div>

                          <div class="tags">
                            {props.response && props.response?.auctionId && (
                              <span class="badge">
                                Listing ID : <span>{props.response && props.response.auctionId}</span>
                                <img
                                  class="tag-close-icon"
                                  src={close}
                                  alt=""
                                  onClick={() => {
                                    const data = { ...props.response };
                                    data.auctionId = "";
                                    props.setResponse(data);
                                  }}
                                />
                              </span>
                            )}
                            {props.response && props.response?.keyword && (
                              <span class="badge">
                                Keyword : <span>{props.response && props.response.keyword}</span>
                                <img
                                  class="tag-close-icon"
                                  src={close}
                                  alt=""
                                  onClick={() => {
                                    const data = { ...props.response };
                                    data.keyword = "";
                                    props.setResponse(data);
                                  }}
                                />
                              </span>
                            )}
                          </div>
                        </>
                      )}{" "}
                      {props.loading && (
                        <div class="filter-tags d-flex align-items-start">
                          <div class="filter-tag-title">
                            <div class="tags d-flex align-items-start">
                              <span class="badge">
                                <Skeleton />
                              </span>
                              <span class="badge">
                                <Skeleton />
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div class="tb-filter-right d-flex align-items-center justify-content-end">
                      <div class="showing-result-text">
                        {!props.loading ? (
                          props?.totalCount > 0 &&
                          `Showing ${20 * (props.page - 1) + props.data?.length} of ${props.totalCount} total results`
                        ) : (
                          <Skeleton width="300px" height="25px" />
                        )}
                      </div>
                    </div>
                  </div>
                  <div class="selected-table-row d-flex justify-content-between"></div>
                  <div
                    class={`table-responsive position-relative ${props?.data?.length > 0 && "min-user-table-height"}
                    }`}
                  >
                    <div class="table-selected-count d-none align-items-center justify-content-between">
                      <div class="tsc-check-label d-flex align-items-center">
                        <div class="tsc-checkbox">
                          <div class="form-check tick-checkbox">
                            <input class="form-check-input" type="checkbox" value="" id="checkAll" />
                            <label class="form-check-label" for="checkAll"></label>
                          </div>
                        </div>
                        <div class="link-label fw-medium">2 Selected</div>
                        <div class="link-clear fw-medium">Clear all</div>
                      </div>
                    </div>
                    <table class="table align-middle table-collapse-table ">
                      <thead>
                        <tr>
                          <th style={{ width: "14.28%" }}>
                            <a className="sort-by"> Listing ID</a>{" "}
                          </th>

                          <th style={{ width: "14.28%" }}>
                            <a className={`sort-by ${props.sort === "auctionTitle" ? "active-column" : ""}`}>
                              Listing Title{" "}
                              <div
                                class={`ascending-icon cursor-pointer ${
                                  props.order == "asc" && props.sort == "auctionTitle" ? "sort-active" : ""
                                }`}
                                onClick={() => {
                                  props.setSort("auctionTitle");
                                  props.setOrder("asc");
                                }}
                              ></div>
                              <div
                                class={`descending-icon cursor-pointer ${
                                  props.order == "desc" && props.sort == "auctionTitle" ? "sort-active1" : ""
                                }`}
                                onClick={() => {
                                  props.setSort("auctionTitle");
                                  props.setOrder("desc");
                                }}
                              ></div>
                            </a>
                          </th>

                          <th style={{ width: "14.28%" }}>
                            <a className={`sort-by ${props.sort === "name" ? "active-column" : ""}`}>
                              Name
                              <div
                                class={`ascending-icon cursor-pointer ${
                                  props.order == "asc" && props.sort == "name" ? "sort-active" : ""
                                }`}
                                onClick={() => {
                                  props.setSort("name");
                                  props.setOrder("asc");
                                }}
                              ></div>
                              <div
                                class={`descending-icon cursor-pointer ${
                                  props.order == "desc" && props.sort == "name" ? "sort-active1" : ""
                                }`}
                                onClick={() => {
                                  props.setSort("name");
                                  props.setOrder("desc");
                                }}
                              ></div>
                            </a>
                          </th>
                          <th style={{ width: "14.28%" }}>
                            <a className="sort-by">Agreement Type</a>
                          </th>
                          <th style={{ width: "14.28%" }}>
                            <a className={`sort-by ${props.sort === "createdAt" ? "active-column" : ""}`}>
                              Sent Date{" "}
                              <div
                                class={`ascending-icon cursor-pointer ${
                                  props.order == "asc" && props.sort == "createdAt" ? "sort-active" : ""
                                }`}
                                onClick={() => {
                                  props.setSort("createdAt");
                                  props.setOrder("asc");
                                }}
                              ></div>
                              <div
                                class={`descending-icon cursor-pointer ${
                                  props.order == "desc" && props.sort == "createdAt" ? "sort-active1" : ""
                                }`}
                                onClick={() => {
                                  props.setSort("createdAt");
                                  props.setOrder("desc");
                                }}
                              ></div>
                            </a>
                          </th>
                          <th style={{ width: "14.28%" }}>
                            <a className={`sort-by ${props.sort === "agreedAt" ? "active-column" : ""}`}>
                              Status{" "}
                              <div
                                class={`ascending-icon cursor-pointer ${
                                  props.order == "asc" && props.sort == "agreedAt" ? "sort-active" : ""
                                }`}
                                onClick={() => {
                                  props.setSort("agreedAt");
                                  props.setOrder("asc");
                                }}
                              ></div>
                              <div
                                class={`descending-icon cursor-pointer ${
                                  props.order == "desc" && props.sort == "agreedAt" ? "sort-active1" : ""
                                }`}
                                onClick={() => {
                                  props.setSort("agreedAt");
                                  props.setOrder("desc");
                                }}
                              ></div>
                            </a>
                          </th>
                          <th style={{ width: "14.28%" }}>
                            <a className="sort-by">Download Authorization PDF</a>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {props.loading
                          ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2, 3, 4].map((item) => (
                              <tr>
                                <td style={{ width: "14.28%" }}>
                                  <Skeleton />
                                </td>
                                <td style={{ width: "14.28%" }}>
                                  <Skeleton />
                                </td>
                                <td style={{ width: "14.28%" }}>
                                  <Skeleton />
                                </td>
                                <td style={{ width: "14.28%" }}>
                                  <Skeleton />
                                </td>
                                <td style={{ width: "14.28%" }}>
                                  <Skeleton />
                                </td>
                                <td style={{ width: "14.28%" }}>
                                  <Skeleton />
                                </td>
                                <td style={{ width: "14.28%" }}>
                                  <Skeleton />
                                </td>
                              </tr>
                            ))
                          : props.data &&
                            props.data?.length > 0 &&
                            props.data?.map((val) => (
                              <tr>
                                <td>
                                  {val.auction_id ? (
                                    <a
                                      className="green-text"
                                      target="_blank"
                                      href={`/listing-detail?id=${val?.auction_id}`}
                                    >
                                      {val.auction_id}
                                    </a>
                                  ) : val?.uniqueKey ? (
                                    "--"
                                  ) : (
                                    "N/A"
                                  )}
                                </td>
                                <td>
                                  {val.auction_title ? (
                                    <a
                                      className="green-btn-text"
                                      target="_blank"
                                      href={`/listing-detail?id=${val?.auction_id}`}
                                    >
                                      {val.auction_title}
                                    </a>
                                  ) : val?.uniqueKey ? (
                                    "--"
                                  ) : (
                                    "N/A"
                                  )}
                                </td>
                                <td>{val.name}</td>
                                <td>{val?.uniqueKey ? "Multiple" : "Single"}</td>
                                <td>{val?.created ? <DateFormat date={val?.created} /> : "N/A"}</td>
                                <td>
                                  {val?.agreed ? (
                                    <span>
                                      Agreed on <DateFormat date={val?.agreed} />
                                    </span>
                                  ) : (
                                    <span className="fw-medium text-danger">Pending</span>
                                  )}
                                </td>
                                <td>
                                  {val?.hash ? (
                                    <a
                                      className="green-btn-text"
                                      target="_blank"
                                      href={process.env.REACT_APP_S3_PRIVATE_DOC_BUCKET + "/" + val?.hash + ".pdf"}
                                    >
                                      Download
                                    </a>
                                  ) : (
                                    "N/A"
                                  )}
                                </td>
                              </tr>
                            ))}
                      </tbody>
                    </table>
                  </div>

                  {!props.loading && props.data && props.data.length === 0 && (
                    <>
                      <div class="table-empty">
                        <div class="table-empty-inner">
                          <img src={emptyFolder} alt="" />
                          <p>No Items Found</p>
                        </div>
                      </div>
                    </>
                  )}

                  {props?.data?.length > 0 && (
                    <div class="table-pagination mt-2">
                      <ul class="pagination  justify-content-end">
                        <li class="page-item">
                          <a
                            class={`page-link ${props?.page === 1 ? "disabled" : ""}`}
                            href="javascript:void(0)"
                            aria-label="Previous"
                            onClick={() => {
                              if (props?.page !== 1) {
                                props?.setPage(props?.page - 1);
                              }
                            }}
                          >
                            <svg
                              width="8"
                              height="14"
                              viewBox="0 0 8 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M7 13L1 7L7 1" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                          </a>
                        </li>
                        <li class="page-item active">
                          <a class="page-link" href="javascript:void(0)">
                            {props?.page}
                          </a>
                        </li>
                        <li class="page-item">
                          {props?.page <
                            (props?.totalCount % 20 === 0
                              ? Math.floor(props?.totalCount / 20)
                              : Math.floor(props?.totalCount / 20) + 1) && (
                            <a
                              class={`page-link ${
                                props?.page >=
                                (props?.totalCount % 20 === 0
                                  ? Math.floor(props?.totalCount / 20)
                                  : Math.floor(props?.totalCount / 20) + 1)
                                  ? "disabled"
                                  : ""
                              }`}
                              href="javascript:void(0)"
                              onClick={() => {
                                props?.setPage(props?.page + 1);
                              }}
                            >
                              {props?.page + 1}
                            </a>
                          )}
                        </li>
                        <li
                          class={`page-item ${
                            props?.page <
                            (props?.totalCount % 20 === 0
                              ? Math.floor(props?.totalCount / 20)
                              : Math.floor(props?.totalCount / 20) + 1)
                              ? ""
                              : "disabled"
                          }`}
                        >
                          <a
                            class={`page-link ${
                              props?.page >=
                              (props?.totalCount % 20 === 0
                                ? Math.floor(props?.totalCount / 20)
                                : Math.floor(props?.totalCount / 20) + 1)
                                ? "disabled"
                                : ""
                            }`}
                            href="javascript:void(0)"
                            aria-label="Next"
                            onClick={() => {
                              props?.setPage(props?.page + 1);
                            }}
                          >
                            <svg
                              width="8"
                              height="14"
                              viewBox="0 0 8 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M1 13L7 7L1 1" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                          </a>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListingAgreementsTable;
