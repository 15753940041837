import React from "react";
import { Offcanvas } from "react-bootstrap";
import { customStyles } from "../../../common/customStyles/reactSelectStyle";
import Select from "react-select";

const FilterUser = (props) => {
  return (
    <Offcanvas
      show={props.show}
      onHide={() => props.onHide()}
      placement="end"
      className="offcanvas offcanvas-end custom-offcanvas-sm "
      backdropClassName="upload-images-backdrop"
      tabindex="-1"
      id="userFilter"
      enforceFocus={false}
    >
      <Offcanvas.Header className="offcanvas-header ps-4" style={{ marginLeft: "7px" }}>
        <h5 class="offcanvas-title">Filters</h5>
        <button type="button" className="btn-close text-reset" onClick={() => props.onHide()}></button>
      </Offcanvas.Header>
      <Offcanvas.Body className="offcanvas-body co-body">
        {/* <div class="form-group">
          <label for="keyword" class="form-label">
            Keyword
          </label>
          <input
            type="text"
            class="form-control"
            id="keyword"
            value={props.searchKeyword}
            onChange={(e) => props.setSearchKeyword(e.target.value)}
          />
        </div> */}
        <div class="form-group">
          <label for="keyword" class="form-label">
            Name
          </label>
          <input
            type="text"
            class="form-control"
            id="name"
            value={props.name}
            onChange={(e) => props.setName(e.target.value)}
          />
        </div>
        <div class="form-group">
          <label for="keyword" class="form-label">
            Email
          </label>
          <input
            type="text"
            class="form-control"
            id="email"
            value={props.email}
            onChange={(e) => props.setEmail(e.target.value)}
          />
        </div>
        <div class="form-group">
          <label for="keyword" class="form-label">
            Phone Number
          </label>
          <input
            type="text"
            class="form-control"
            id="phone"
            value={props.phone}
            onChange={(e) => props.setPhone(e.target.value)}
          />
        </div>
        {/* <div class="form-group">
          <label for="keyword" class="form-label">
            Location
          </label>
          <input
            type="text"
            class="form-control"
            id="location"
            value={props.location}
            onChange={(e) => props.setLocation(e.target.value)}
          />
        </div> */}
        {/* <div class="form-group">
          <label for="type" class="form-label">
            Company Access
          </label>
          <Select
            value={props.accessType}
            className="basic-single "
            classNamePrefix="select-search"
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.value}
            name="companyAccess"
            options={props.options}
            placeholder="Select Company Access"
            styles={customStyles}
            onChange={(a) => {
              props.setAccessType(a);
            }}
          />
        </div> */}
      </Offcanvas.Body>
      <div class="offcanvas-footer">
        <a
          href="javascript:void(0)"
          class={`btn btn-default btn-block ${props.loadingFilter && "disabled"}`}
          onClick={() => props.handleFilter()}
        >
          Apply Filters
        </a>
      </div>
    </Offcanvas>
  );
};

export default React.memo(FilterUser);
