import React from "react";
import close from "../../../include/images/close.svg";
import { Modal } from "react-bootstrap";
import DateFormat from "../../../common/DateFormat/dateFormat";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";

const ViewTaskModal = (props) => {
  const d = new Date();
  let diff = d.getTimezoneOffset();
  return (
    <Modal
      className="modal fade custom-modal"
      id="addTaskModal"
      show={props.show}
      onHide={() => props.onHide()}
      dialogClassName="modal-dialog modal-dialog-centered modal-xl"
    >
      <div class="modal-content h-100 d-flex flex-column">
        <div class="custom-modal-header d-flex align-items-center justify-content-between">
          <h5 class="mb-0 text-capitalize">Show Task</h5>
          <button
            type="button"
            class="close-btn d-flex align-items-center justify-content-center"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => props.onHide()}
          >
            <img src={close} alt="" />
          </button>
        </div>
        <Modal.Body className="custom-modal-body flex-grow-1 hide-scrollbar padding-bottom-0">
          <table
            class="table modal-product-detail-table mb-0"
            style={{ height: "400px" }}
          >
            <tr>
              <td>Title</td>
              <td>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    readOnly
                    value={props?.viewModalData?.title}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>Posted By</td>
              <td>
                <div>
                  <input
                    className="form-control"
                    type="text"
                    readOnly
                    value={props?.viewModalData?.NAME}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>Date Submitted</td>
              <td>
                <div>
                  <input
                    type="text"
                    readOnly
                    value={
                      moment(props.viewModalData?.date_submitted)
                        .add({ minute: diff })
                        .format("MMM-D-YYYY, h:mm:ss A") + " CST"
                    }
                    className="form-control"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>Description</td>
              <td>
                <div className="padding-0">
                  <div
                    className="form-control ms-2"
                    style={{ minHeight: "40px", maxWidth: "98%" }}
                    dangerouslySetInnerHTML={{
                      __html: props?.viewModalData?.description?.replace(
                        /\r\n/g,
                        "<br/>"
                      ),
                    }}
                  />
                </div>
              </td>
            </tr>
          </table>
        </Modal.Body>
        <div class="custom-modal-dividor"></div>
        <div className="custom-modal-footer d-grid gap-2 d-md-flex align-items-center justify-content-md-end">
          <button
            class="btn btn-link me-md-2 global-cancel-button"
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => props.onHide()}
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ViewTaskModal;
