import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";

const slice = createSlice({
  name: "misc",
  initialState: {
    manufacturers: {},
    loadingManufacturers: false,
    loadingModel: false,
    model: {},
    auctionType: {},
    auctionStatus: {},
    project: {},
    loading: false,
    ownerAccessLog: {},
    dealProgres: {},
    manufacturersList: {},
    modelList: {},
    aviationModelList: {},
    aviationModel: {},
    overallDetail: {},
    askingPriceData: {},
  },
  reducers: {
    manufacturersRequested: (misc, action) => {
      misc.loadingManufacturers = true;
    },
    manufacturersReceived: (misc, action) => {
      misc.manufacturers = action.payload;
      misc.loadingManufacturers = false;
    },
    manufacturersRequestFailed: (misc, action) => {
      misc.loadingManufacturers = false;
    },
    manufacturersListRequested: (misc, action) => {
      misc.loadingManufacturers = true;
    },
    manufacturersListReceived: (misc, action) => {
      misc.manufacturersList = action.payload;
      misc.loadingManufacturers = false;
    },
    manufacturersListRequestFailed: (misc, action) => {
      misc.loadingManufacturers = false;
    },
    modelRequested: (misc, action) => {
      misc.loadingModel = true;
    },
    modelReceived: (misc, action) => {
      misc.model = action.payload;
      misc.loadingModel = false;
    },
    modelRequestFailed: (misc, action) => {
      misc.loadingModel = false;
    },
    modelListRequested: (misc, action) => {
      misc.loadingModel = true;
    },
    modelListReceived: (misc, action) => {
      misc.modelList = action.payload;
      misc.loadingModel = false;
    },
    modelListRequestFailed: (misc, action) => {
      misc.loadingModel = false;
    },
    auctionStatusRequested: (misc, action) => {
      misc.loading = true;
    },
    auctionStatusReceived: (misc, action) => {
      misc.auctionStatus = action.payload;
      misc.loading = false;
    },
    auctionStatusRequestFailed: (misc, action) => {
      misc.loading = false;
    },

    auctionTypeRequested: (misc, action) => {
      misc.loading = true;
    },
    auctionTypeReceived: (misc, action) => {
      misc.auctionType = action.payload;
      misc.loading = false;
    },
    auctionTypeRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    projectRequested: (misc, action) => {
      misc.loading = true;
    },
    projectReceived: (misc, action) => {
      misc.project = action.payload;
      misc.loading = false;
    },
    projectRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    accessLogRequested: (misc, action) => {
      misc.loading = true;
    },
    accessLogReceived: (misc, action) => {
      misc.ownerAccessLog = action.payload;
      misc.loading = false;
    },
    accessLogRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    dealProgressRequested: (misc, action) => {
      misc.loading = true;
    },
    dealProgressReceived: (misc, action) => {
      misc.dealProgres = action.payload;
      misc.loading = false;
    },
    dealProgressRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    aviationModelListRequested: (misc, action) => {
      misc.loading = true;
    },
    aviationModelListReceived: (misc, action) => {
      misc.aviationModelList = action.payload;
      misc.loading = false;
    },
    aviationModelListFailed: (misc, action) => {
      misc.loading = false;
    },
    aviationModelRequested: (misc, action) => {
      misc.loading = true;
    },
    aviationModelReceived: (misc, action) => {
      misc.aviationModel = action.payload;
      misc.loading = false;
    },
    aviationModelFailed: (misc, action) => {
      misc.loading = false;
    },
    overallDetailRequested: (misc, action) => {
      misc.loading = true;
    },
    overallDetailReceived: (misc, action) => {
      misc.overallDetail = action.payload;
      misc.loading = false;
    },
    overallDetailFailed: (misc, action) => {
      misc.loading = true;
    },
    askingPriceRequested: (misc, action) => {
      misc.loading = true;
    },
    askingPriceReceived: (misc, action) => {
      misc.askingPriceData = action.payload;
      misc.loading = false;
    },
    askingPriceFailed: (misc, action) => {
      misc.loading = true;
    },
  },
});

export const {
  manufacturersRequested,
  manufacturersReceived,
  manufacturersRequestFailed,
  modelRequested,
  modelReceived,
  modelRequestFailed,
  projectRequested,
  projectReceived,
  projectRequestFailed,
  auctionStatusRequested,
  auctionStatusReceived,
  auctionStatusRequestFailed,
  auctionTypeRequested,
  auctionTypeReceived,
  auctionTypeRequestFailed,
  accessLogRequested,
  accessLogReceived,
  accessLogRequestFailed,
  dealProgressRequested,
  dealProgressReceived,
  dealProgressRequestFailed,
  manufacturersListRequested,
  manufacturersListReceived,
  manufacturersListRequestFailed,
  modelListRequested,
  modelListReceived,
  modelListRequestFailed,
  aviationModelListRequested,
  aviationModelListReceived,
  aviationModelListFailed,
  aviationModelRequested,
  aviationModelReceived,
  aviationModelFailed,
  overallDetailRequested,
  overallDetailReceived,
  overallDetailFailed,
  askingPriceRequested,
  askingPriceReceived,
  askingPriceFailed,
} = slice.actions;
export default slice.reducer;

// Action Creators
const baseUrl = "common";

export const getManufacturers = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/manufacturer`,
      method: "get",
      callback,
      onStart: manufacturersRequested.type,
      onSuccess: manufacturersReceived.type,
      onError: manufacturersRequestFailed.type,
    }),
  );
};

export const getManufacturersList = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/manufacturer/list`,
      params,
      method: "get",
      callback,
      onStart: manufacturersListRequested.type,
      onSuccess: manufacturersListReceived.type,
      onError: manufacturersListRequestFailed.type,
    }),
  );
};
export const getManufacturersFiltered = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/manufacturer/list`,
      params,
      method: "get",
      callback,
    }),
  );
};

export const getModel = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/model?mfr_id=${data}`,
      method: "get",
      callback,
      onStart: modelRequested.type,
      onSuccess: modelReceived.type,
      onError: modelRequestFailed.type,
    }),
  );
};

export const getModelList = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/model/list`,
      params,
      method: "get",
      callback,
      onStart: modelListRequested.type,
      onSuccess: modelListReceived.type,
      onError: modelListRequestFailed.type,
    }),
  );
};
export const getModelListFiltered = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/model/list`,
      params,
      method: "get",
      callback,
    }),
  );
};

export const getAuctionStatus = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/auctionStatus`,
      method: "get",
      callback,
      onStart: auctionStatusRequested.type,
      onSuccess: auctionStatusReceived.type,
      onError: auctionStatusRequestFailed.type,
    }),
  );
};

export const getAuctionType = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/auctionType`,
      method: "get",
      callback,
      onStart: auctionTypeRequested.type,
      onSuccess: auctionTypeReceived.type,
      onError: auctionTypeRequestFailed.type,
    }),
  );
};

export const getProjects = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/project?auction_id=${data}`,
      method: "get",
      callback,
      onStart: projectRequested.type,
      onSuccess: projectReceived.type,
      onError: projectRequestFailed.type,
    }),
  );
};

export const getOwnerAccessLog = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `auctionOther/other/ownerAccessLog/${data}`,
      method: "get",
      callback,
      onStart: accessLogRequested.type,
      onSuccess: accessLogReceived.type,
      onError: accessLogRequestFailed.type,
    }),
  );
};

export const getGAData = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `dashboard/listing/analyticsFromGA`,
      method: "get",
      data,
      callback,
      onStart: accessLogRequested.type,
      onSuccess: accessLogReceived.type,
      onError: accessLogRequestFailed.type,
    }),
  );
};

export const getDealProgress = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `auctionOther/auction/dealProgress/${data}`,
      method: "get",
      callback,
      onStart: dealProgressRequested.type,
      onSuccess: dealProgressReceived.type,
      onError: dealProgressRequestFailed.type,
    }),
  );
};

//Get Aviation Model List
export const getAviationModelList = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/aviationModel/list`,
      params,
      method: "get",
      callback,
      onStart: aviationModelListRequested.type,
      onSuccess: aviationModelListReceived.type,
      onError: aviationModelListFailed.type,
    }),
  );
};

export const getAviationModel = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/aviationModel`,
      method: "get",
      callback,
      onStart: aviationModelRequested.type,
      onSuccess: aviationModelReceived.type,
      onError: aviationModelFailed.type,
    }),
  );
};

//Add or Update Aviation Model
export const addAviationModel = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `common/aviationModel`,
      method: "POST",
      data,
      callback,
    }),
  );
};

//Get Overall Detail
export const getOverallDetail = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `auctionDetail/productDetail/overall/${params}`,
      method: "get",
      callback,
      onStart: overallDetailRequested.type,
      onSuccess: overallDetailReceived.type,
      onError: overallDetailFailed.type,
    }),
  );
};
//Update Overall Detail
export const updateOverall = (params, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `auctionDetail/productDetail/overall/${params}`,
      method: "put",
      data,
      callback,
    }),
  );
};

export const updateDealProgress = (params, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `auctionOther/auction/dealProgress/${params}`,
      method: "put",
      data,
      callback,
    }),
  );
};

export const updatePriceRange = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `auctionDetail/productDetail/priceRange/${id}`,
      method: "put",
      data,
      callback,
    }),
  );
};

export const getAskingPriceHistory = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `auctionDetail/productDetail/askingPriceHistory/${id}`,
      method: "get",
      callback,
      onStart: askingPriceRequested.type,
      onSuccess: askingPriceReceived.type,
      onError: askingPriceFailed.type,
    }),
  );
};

export const getMisc = createSelector(
  (state) => state.entities.misc,
  (misc) => misc,
);
