import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";

const slice = createSlice({
  name: "listingProduct",
  initialState: {
    loading: false,
    count: {},
    detail: {},
    loadingCondition: false,
    condition: {},
    description: "",
    sustainabilityReport: {},
    envOrganization: {},
    loadigSustainabilityReport: false,
    moreDetails: {},
    loadingMoreDetails: false,
    mfrAndModel: {},
    loadingMfrAndModel: false,
    otherDescription: {},
    exclusivity: {},
    accounting: {},
    comparablePrice: {},
    buyerPush: {},
    keyBuyerCount: {},
    keyBuyer: {},
    asking_price_feature: false,
    claim_value_feature: false,
    wholesale_value_feature: false,
    reatil_value_feature: false,
    pipeData: {},
  },
  reducers: {
    listingProductDetailRequested: (misc, action) => {
      misc.loading = true;
    },
    listingProductDetailReceived: (misc, action) => {
      misc.detail = action.payload;
      const value = action.payload.data;
      misc.loading = false;
    },
    listingProductDetailRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    featuredValueChanger: (misc, action) => {
      if (action.payload == "asking") {
        misc.asking_price_feature = !misc.asking_price_feature;
      }
      if (action.payload == "claim") {
        misc.claim_value_feature = !misc.claim_value_feature;
      }
      if (action.payload == "asking") {
        misc.wholesale_value_feature = !misc.wholesale_value_feature;
      }
      if (action.payload == "asking") {
        misc.reatil_value_feature = !misc.reatil_value_feature;
      }
    },
    productConditionRequested: (misc, action) => {
      misc.loadingCondition = true;
    },
    productConditionReceived: (misc, action) => {
      misc.condition = action.payload;
      misc.loadingCondition = false;
    },
    productConditionRequestFailed: (misc, action) => {
      misc.loadingCondition = false;
    },
    productDescriptionReceived: (misc, action) => {
      misc.description = action.payload;
    },

    sustainabilityReportRequested: (misc, action) => {
      misc.loadigSustainabilityReport = true;
    },
    sustainabilityReportReceived: (misc, action) => {
      misc.sustainabilityReport = action.payload;
      misc.loadigSustainabilityReport = false;
    },
    sustainabilityReportFailed: (misc, action) => {
      misc.loadigSustainabilityReport = false;
    },
    moreDetailsRequested: (misc, action) => {
      misc.loadingMoreDetails = true;
    },
    moreDetailsReceived: (misc, action) => {
      misc.moreDetails = action.payload;
      misc.loadingMoreDetails = false;
    },
    moreDetailsFailed: (misc, action) => {
      misc.loadingMoreDetails = false;
    },

    otherDescpRequested: (misc, action) => {
      misc.loadingMoreDetails = true;
    },
    otherDescpReceived: (misc, action) => {
      misc.otherDescription = action.payload;
      misc.loadingMoreDetails = false;
    },
    otherDescpFailed: (misc, action) => {
      misc.loadingMoreDetails = false;
    },

    mfrAndModelRequested: (misc, action) => {
      misc.loadingMfrAndModel = true;
    },
    mfrAndModelReceived: (misc, action) => {
      misc.mfrAndModel = action.payload;
      misc.loadingMfrAndModel = false;
    },
    mfrAndModelFailed: (misc, action) => {
      misc.loadingMfrAndModel = false;
    },
    exclusivityFetching: (misc, action) => {
      misc.loading = true;
    },
    exclusivityFetched: (misc, action) => {
      misc.exclusivity = action.payload;
      misc.loading = false;
    },
    exclusivityFailed: (misc, action) => {
      misc.loading = false;
    },
    accoutingFetching: (misc, action) => {
      misc.loading = true;
    },
    accountingFetched: (misc, action) => {
      misc.accounting = action.payload;
      misc.loading = false;
    },
    accountingFailed: (misc, action) => {
      misc.loading = false;
    },
    comparablePriceRequested: (misc, action) => {
      misc.loadingMfrAndModel = true;
    },
    comparablePriceReceived: (misc, action) => {
      misc.comparablePrice = action.payload;
      misc.loadingMfrAndModel = false;
    },
    comparablePriceFailed: (misc, action) => {
      misc.loadingMfrAndModel = false;
    },

    buyerPushHistoryFetching: (misc, action) => {
      misc.loading = true;
    },
    buyerPushHistoryFetched: (misc, action) => {
      misc.buyerPush = action.payload;
      misc.loading = false;
    },
    buyerPushHistoryFailed: (misc, action) => {
      misc.loading = false;
    },
    envOrganizationFetching: (misc, action) => {
      misc.loading = true;
    },
    envOrganizationFetched: (misc, action) => {
      misc.envOrganization = action.payload;
      misc.loading = false;
    },
    envOrganizationFailed: (misc, action) => {
      misc.loading = false;
    },
    countFetching: (misc, action) => {
      misc.loading = true;
    },
    countFetched: (misc, action) => {
      misc.count = action.payload;
      misc.loading = false;
    },
    countFailed: (misc, action) => {
      misc.loading = false;
    },
    keyBuyerCountFetching: (misc, action) => {
      misc.loading = true;
    },
    keyBuyerCountFetched: (misc, action) => {
      misc.keyBuyerCount = action.payload;
      misc.loading = false;
    },
    keyBuyerCountFailed: (misc, action) => {
      misc.loading = false;
    },
    keyBuyerFetching: (misc, action) => {
      misc.loading = true;
    },
    keyBuyerFetched: (misc, action) => {
      misc.keyBuyer = action.payload;
      misc.loading = false;
    },
    keyBuyerFailed: (misc, action) => {
      misc.loading = false;
    },
    pipeDataFetching: (misc, action) => {
      misc.loading = true;
    },
    pipeDataFetched: (misc, action) => {
      misc.pipeData = action.payload;
      misc.loading = false;
    },
    pipeDataFailed: (misc, action) => {
      misc.loading = false;
    },
  },
});

export const {
  listingProductDetailRequested,
  listingProductDetailReceived,
  listingProductDetailRequestFailed,
  productConditionRequested,
  productConditionReceived,
  productConditionRequestFailed,
  productDescriptionReceived,
  sustainabilityReportRequested,
  sustainabilityReportReceived,
  sustainabilityReportFailed,
  moreDetailsRequested,
  moreDetailsReceived,
  moreDetailsFailed,
  mfrAndModelRequested,
  mfrAndModelReceived,
  mfrAndModelFailed,
  otherDescpRequested,
  otherDescpReceived,
  otherDescpFailed,
  exclusivityFetching,
  exclusivityFetched,
  exclusivityFailed,
  accoutingFetching,
  accountingFetched,
  accountingFailed,
  comparablePriceRequested,
  comparablePriceReceived,
  comparablePriceFailed,
  buyerPushHistoryFetching,
  buyerPushHistoryFetched,
  buyerPushHistoryFailed,
  envOrganizationFetching,
  envOrganizationFetched,
  envOrganizationFailed,
  featuredValueChanger,
  countFetching,
  countFetched,
  countFailed,
  keyBuyerCountFetching,
  keyBuyerCountFetched,
  keyBuyerCountFailed,
  keyBuyerFetching,
  keyBuyerFetched,
  keyBuyerFailed,
  pipeDataFetching,
  pipeDataFetched,
  pipeDataFailed,
} = slice.actions;
export default slice.reducer;

// Action Creators
const baseUrl = "auctionOther";
const accountUrl = "auctionAccounting/accounting";
const productDetailUrl = "auctionDetail";

export const productDetails = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${productDetailUrl}/productDetail/${params}`,
      method: "get",
      callback,
      onStart: listingProductDetailRequested.type,
      onSuccess: listingProductDetailReceived.type,
      onError: listingProductDetailRequestFailed.type,
    }),
  );
};

export const getComparablePrice = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${productDetailUrl}/productDetail/comparablePriceList/${params}`,
      method: "get",
      callback,
      onStart: comparablePriceRequested.type,
      onSuccess: comparablePriceReceived.type,
      onError: comparablePriceFailed.type,
    }),
  );
};

export const updateComparablePrice = (params, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${productDetailUrl}/productDetail/comparablePrice/${params}`,
      method: "POST",
      data,
      callback,
    }),
  );
};

export const updateProductDetails = (params, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${productDetailUrl}/productDetail/${params}`,
      method: "PUT",
      data,
      callback,
    }),
  );
};

export const productCondition = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${productDetailUrl}/productCondition/${params}`,
      method: "get",
      callback,
      onStart: productConditionRequested.type,
      onSuccess: productConditionReceived.type,
      onError: productConditionRequestFailed.type,
    }),
  );
};

export const updateProductCondition = (params, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${productDetailUrl}/productCondition/${params}`,
      method: "PUT",
      data,
      callback,
    }),
  );
};

export const updateProductDescription = (params, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${productDetailUrl}/productDescription/${params}`,
      method: "PUT",
      data,
      callback,
    }),
  );
};

export const sustainabilityReport = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `sustainabilityReport/${params}`,
      method: "get",
      callback,
      onStart: sustainabilityReportRequested.type,
      onSuccess: sustainabilityReportReceived.type,
      onError: sustainabilityReportFailed.type,
    }),
  );
};

export const updateSustainabilityReport = (params, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `sustainabilityReport/${params}`,
      method: "PUT",
      data,
      callback,
    }),
  );
};

export const getMoreDetails = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `editMore/${params}`,
      method: "get",
      callback,
      onStart: moreDetailsRequested.type,
      onSuccess: moreDetailsReceived.type,
      onError: moreDetailsFailed.type,
    }),
  );
};

export const getOtherDescp = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${productDetailUrl}/description/${params}`,
      method: "get",
      callback,
      onStart: otherDescpRequested.type,
      onSuccess: otherDescpReceived.type,
      onError: otherDescpFailed.type,
    }),
  );
};

export const updateMoreDetails = (params, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `editMore/${params}`,
      method: "PUT",
      data,
      callback,
    }),
  );
};

export const getMfrAndModel = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `manufactureAndModel/${params}`,
      method: "get",
      callback,
      onStart: mfrAndModelRequested.type,
      onSuccess: mfrAndModelReceived.type,
      onError: mfrAndModelFailed.type,
    }),
  );
};

export const addMfr = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `common/manufacturer`,
      method: "POST",
      data,
      callback,
    }),
  );
};

export const addModel = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `common/model`,
      method: "POST",
      data,
      callback,
    }),
  );
};

export const updateMfrAndModel = (params, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `manufactureAndModel/${params}`,
      method: "PUT",
      data,
      callback,
    }),
  );
};

export const updateOtherDescription = (params, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `auctionDetail/otherDescription/${params}`,
      method: "PUT",
      data,
      callback,
    }),
  );
};

//Exclusivity
export const getExclusivity = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `exclusivity/${params}`,
      method: "get",
      callback,
      onStart: exclusivityFetching.type,
      onSuccess: exclusivityFetched.type,
      onError: exclusivityFailed.type,
    }),
  );
};

export const updateExclusivity = (params, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `exclusivity/${params}`,
      method: "PUT",
      data,
      callback,
    }),
  );
};

//Accounting
export const getAccounting = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${accountUrl}/${params}`,
      method: "get",
      callback,
      onStart: accoutingFetching.type,
      onSuccess: accountingFetched.type,
      onError: accountingFailed.type,
    }),
  );
};

export const updateAccounting = (params, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${accountUrl}/${params}`,
      method: "put",
      data,
      callback,
    }),
  );
};

//Update Auction Expenses
export const updateAuctionExpense = (params, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${accountUrl}/expenseAllocation/${params}`,
      method: "put",
      data,
      callback,
    }),
  );
};

//Delete Auction Expenses
export const deleteAuctionExpenses = (id, params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${accountUrl}/expenseAllocation/${id}`,
      params,
      method: "delete",
      callback,
    }),
  );
};

export const getBuyerPushHistory = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/buyerPushHistory/${params}`,
      method: "get",
      callback,
      onStart: buyerPushHistoryFetching.type,
      onSuccess: buyerPushHistoryFetched.type,
      onError: buyerPushHistoryFailed.type,
    }),
  );
};

//Get Environmental Organization
export const getEnvironmentalOrganization = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `sustainabilityReport/environmentalOrganization`,
      params,
      method: "get",
      callback,
      onStart: envOrganizationFetching.type,
      onSuccess: envOrganizationFetched.type,
      onError: envOrganizationFailed.type,
    }),
  );
};

//Get Product Count Details
export const getProductCount = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${productDetailUrl}/productDetail/count/${params}`,
      method: "get",
      callback,
      onStart: countFetching.type,
      onSuccess: countFetched.type,
      onError: countFailed.type,
    }),
  );
};

//Get Key Buyer Count
export const getKeyBuyerCount = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `auctionKeyBuyer/keyBuyer/regionType/count/${params}`,
      method: "get",
      callback,
      onStart: keyBuyerCountFetching.type,
      onSuccess: keyBuyerCountFetched.type,
      onError: keyBuyerCountFailed.type,
    }),
  );
};

//Get Key Buyer
export const getKeyBuyer = (params, region, page, keyword, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `auctionKeyBuyer/keyBuyer/regionType/${params}?regionType=${region}&page=${page}&keyword=${keyword}`,
      method: "get",
      callback,
      onStart: keyBuyerFetching.type,
      onSuccess: keyBuyerFetched.type,
      onError: keyBuyerFailed.type,
    }),
  );
};
export const duplicateListing = (params, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `duplicateAuction/${params}`,
      method: "post",
      data,
      callback,
    }),
  );
};

export const getPipeDetails = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `auctionDetail/productPipeDetail/${id}`,
      method: "get",
      callback,
      onStart: pipeDataFetching.type,
      onSuccess: pipeDataFetched.type,
      onError: pipeDataFailed.type,
    }),
  );
};

export const updatePipeDetails = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `auctionDetail/productPipeDetail/${id}`,
      method: "put",
      data,
      callback,
    }),
  );
};

export const deleteAuction = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `deleteAuction/${id}`,
      method: "delete",
      callback,
    }),
  );
};

export const getListingProductDetail = createSelector(
  (state) => state.entities.listingProduct,
  (listingProduct) => listingProduct,
);
