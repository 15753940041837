import React from "react";
import { useState } from "react";
import Sidebar from "../../../layouts/sidebar";
import close from "../../../include/images/close-icon.svg";
import emptyFolder from "../../../include/images/folder.svg";
import More from "../../../include/images/more-icon.svg";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import { Accordion, OverlayTrigger, Tooltip } from "react-bootstrap";
import pdfIcon from "../../../include/images/pdf-file-icon.svg";
import DOC from "../../../include/images/Doc.svg";
import XLS from "../../../include/images/XLS.svg";

import Pagination from "../../../common/pagination/pagination";
const EmployeeContractsTable = (props) => {
  //For SideNav
  const [showMenu, setShowMenu] = useState(false);
  const [menuList, setMenuList] = useState("");

  const contractData = props.contractData;

  return (
    <div class="dashboard-insider position-relative d-lg-flex w-100 ">
      <Sidebar
        showMenu={showMenu}
        setShowMenu={setShowMenu}
        menuList={menuList}
        setMenuList={setMenuList}
        page="legal"
        pageCat="enterprise"
      />
      <div className={showMenu ? ` main-menu-expanded ` : "main-menu-compressed"}>
        <div class="filter-container d-flex align-items-center sticky-top">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12 d-flex align-items-center flex-wrap justify-content-between">
                <div class="filter-title fw-medium text-capitalize">Enterprise Contracts</div>
                <div class="filter-widget-right d-flex align-items-center flex-wrap">
                  <div class="fw-item">
                    <div className="d-flex ">
                      <div>
                        <button className="btn btn-sm btn-primary" onClick={() => props.toggleAddContract()}>
                          Add Enterprise Contract
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="table-container">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div
                  class={`table-responsive position-relative ${
                    contractData && contractData.length > 0 ? "min-user-table-height" : ""
                  }`}
                >
                  <Accordion className="accordion new-custom-accor" id="accorParentElementTwo">
                    <Accordion.Item
                      className="accordion-item"
                      eventKey="0"
                      onClick={() => {
                        props?.setActiveStatus(0);
                        props?.loadEnterpriseContracts(0);
                      }}
                    >
                      <Accordion.Button class="accordion-button ">
                        <div class="accordion-button-insider">
                          <h3>No Contract ({props?.count?.noContract ? props?.count?.noContract : 0})</h3>
                        </div>
                      </Accordion.Button>
                      <Accordion.Body>
                        <div class="accor-table-row w-100">
                          <div class="table-selected-count d-none align-items-center justify-content-between"></div>
                          {props?.loading ? (
                            <p>Loading...</p>
                          ) : (
                            <table class="table align-middle table-collapse-table">
                              <thead>
                                <tr>
                                  <th style={{ width: "3%" }}></th>
                                  <th style={{ width: "20%" }}>
                                    <a href="javascript:void(0)" class="sort-by ">
                                      Logo
                                    </a>
                                  </th>
                                  <th style={{ width: "22%" }}>
                                    <a
                                      href="javascript:void(0)"
                                      className={`sort-by ${props.sort === "name" ? "active-column" : ""}`}
                                    >
                                      <div
                                        class={`ascending-icon cursor-pointer ${
                                          props.sort == "name" && props.order == "asc" ? "sort-active" : ""
                                        }`}
                                        onClick={() => {
                                          props.setOrder("asc");
                                          props.setSort("name");
                                          props?.sortContractData(0, "name", "asc");
                                        }}
                                      ></div>
                                      <div
                                        class={`descending-icon cursor-pointer ${
                                          props.sort == "name" && props.order == "desc" ? "sort-active" : ""
                                        }`}
                                        onClick={() => {
                                          props.setOrder("desc");
                                          props.setSort("name");
                                          props?.sortContractData(0, "name", "desc");
                                        }}
                                      ></div>
                                      Enterprise
                                    </a>
                                  </th>

                                  <th style={{ width: "20%" }}>
                                    <a href="javascript:void(0)" class="sort-by">
                                      Seller Commission
                                    </a>
                                  </th>

                                  <th style={{ width: "14%" }}>
                                    <a
                                      href="javascript:void(0)"
                                      className={`sort-by ${props.sort === "start_date" ? "active-column" : ""}`}
                                    >
                                      Start Date
                                      <div
                                        class={`ascending-icon cursor-pointer ${
                                          props.sort == "start_date" && props.order == "asc" ? "sort-active" : ""
                                        }`}
                                        onClick={() => {
                                          props.setOrder("asc");
                                          props.setSort("start_date");
                                          props?.sortContractData(0, "start_date", "asc");
                                        }}
                                      ></div>
                                      <div
                                        class={`descending-icon cursor-pointer ${
                                          props.sort == "start_date" && props.order == "desc" ? "sort-active" : ""
                                        }`}
                                        onClick={() => {
                                          props.setOrder("desc");
                                          props.setSort("start_date");
                                          props?.sortContractData(0, "start_date", "desc");
                                        }}
                                      ></div>
                                    </a>
                                  </th>
                                  <th style={{ width: "13%" }}>
                                    <a
                                      href="javascript:void(0)"
                                      className={`sort-by ${props.sort === "end_date" ? "active-column" : ""}`}
                                    >
                                      End Date
                                      <div
                                        class={`ascending-icon cursor-pointer ${
                                          props.sort == "end_date" && props.order == "asc" ? "sort-active" : ""
                                        }`}
                                        onClick={() => {
                                          props.setOrder("asc");
                                          props.setSort("end_date");
                                          props?.sortContractData(0, "end_date", "asc");
                                        }}
                                      ></div>
                                      <div
                                        class={`descending-icon cursor-pointer ${
                                          props.sort == "end_date" && props.order == "desc" ? "sort-active" : ""
                                        }`}
                                        onClick={() => {
                                          props.setOrder("desc");
                                          props.setSort("end_date");
                                          props?.sortContractData(0, "end_date", "desc");
                                        }}
                                      ></div>
                                    </a>
                                  </th>
                                  <th style={{ width: "5%" }}>
                                    <a href="javascript:void(0)" class="sort-by">
                                      Contract
                                    </a>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {contractData && contractData?.["0"]?.length > 0 ? (
                                  contractData?.["0"]?.map((item) => {
                                    const ext = item?.short_path?.split(".")[1];
                                    return (
                                      <tr>
                                        <td>
                                          <div class="td-action-btn-group d-flex align-items-center justify-content-between">
                                            <div class="dropdown">
                                              <a
                                                class="td-a-icon dropdown-toggle"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                              >
                                                <img src={More} alt="" />
                                              </a>
                                              <ul class="dropdown-menu ms-1">
                                                <li className="menu-item">
                                                  <a
                                                    class="dropdown-item customized-drpdown-btn"
                                                    href="javascript:void(0)"
                                                    onClick={() => {
                                                      props?.toggleAddContract();
                                                      props?.setSelectedItem(item);
                                                    }}
                                                  >
                                                    Edit
                                                  </a>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        </td>
                                        <td style={{ width: "20%" }}>
                                          {" "}
                                          {item?.proc_logo ? (
                                            <img
                                              className="enterprise-logo-img cursor-pointer"
                                              src={`https://media.salvex.com/co/logos/` + item?.proc_logo}
                                              onClick={() => {
                                                props?.toggleAddContract();
                                                props?.setSelectedItem(item);
                                              }}
                                            />
                                          ) : (
                                            ""
                                          )}
                                        </td>
                                        <td
                                          style={{ width: "22%" }}
                                          className="cursor-pointer"
                                          onClick={() => {
                                            props?.toggleAddContract();
                                            props?.setSelectedItem(item);
                                          }}
                                        >
                                          {item?.c_name} <br />
                                        </td>

                                        <td style={{ width: "20%" }}>
                                          <span class="fw-medium d-block ">
                                            {item?.percentage ? parseFloat(item?.percentage).toFixed(2) : "N/A"}
                                          </span>{" "}
                                          <span class="fw-medium d-block gray-color-text">
                                            Buyer Premium :{" "}
                                            {item?.comm_buyer ? parseFloat(item?.comm_buyer).toFixed(2) : "N/A"}
                                          </span>
                                        </td>

                                        <td style={{ width: "14%" }}>
                                          {item?.start_date ? moment(item?.start_date).format("MM/DD/YYYY") : "N/A"}
                                        </td>
                                        <td style={{ width: "13%" }}>
                                          {item?.end_date ? moment(item?.end_date).format("MM/DD/YYYY") : "N/A"}
                                        </td>
                                        <td style={{ width: "5%" }}>
                                          {item?.short_path ? (
                                            <a
                                              href={process.env.REACT_APP_MEDIA_URL + "/company/c/" + item?.short_path}
                                              target="_blank"
                                            >
                                              {" "}
                                              <img
                                                src={
                                                  ext && ext == "pdf"
                                                    ? pdfIcon
                                                    : ext == "xls"
                                                    ? XLS
                                                    : ext == "doc"
                                                    ? DOC
                                                    : ""
                                                }
                                                alt=""
                                              />
                                            </a>
                                          ) : (
                                            "N/A"
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    <td colSpan="8">
                                      <div className="d-flex justify-content-center">
                                        {" "}
                                        <div>
                                          <img src={emptyFolder} alt="" />
                                          <p>No Items Found</p>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          )}
                          {!props?.loading && (
                            <Pagination
                              page={props?.contractPage?.["0"]?.page}
                              totalPages={props?.contractPage?.["0"]?.totalPages}
                              setPage={props?.setPage}
                            />
                          )}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item
                      className="accordion-item"
                      eventKey="1"
                      onClick={() => {
                        props?.setActiveStatus(1);
                        props?.loadEnterpriseContracts(1);
                      }}
                    >
                      <Accordion.Button class="accordion-button ">
                        <div class="accordion-button-insider">
                          <h3>Targeting ({props?.count?.targeting ? props?.count?.targeting : 0})</h3>
                        </div>
                      </Accordion.Button>
                      <Accordion.Body>
                        <div class="accor-table-row w-100">
                          <div class="table-selected-count d-none align-items-center justify-content-between"></div>
                          {props?.loading ? (
                            <p>Loading...</p>
                          ) : (
                            <table class="table align-middle table-collapse-table">
                              <thead>
                                <tr>
                                  <th style={{ width: "3%" }}></th>
                                  <th style={{ width: "20%" }}>
                                    <a href="javascript:void(0)" class="sort-by ">
                                      Logo
                                    </a>
                                  </th>
                                  <th style={{ width: "22%" }}>
                                    <a
                                      href="javascript:void(0)"
                                      className={`sort-by ${props.sort === "name" ? "active-column" : ""}`}
                                    >
                                      <div
                                        class={`ascending-icon cursor-pointer ${
                                          props.sort == "name" && props.order == "asc" ? "sort-active" : ""
                                        }`}
                                        onClick={() => {
                                          props.setOrder("asc");
                                          props.setSort("name");
                                          props?.sortContractData(1, "name", "asc");
                                        }}
                                      ></div>
                                      <div
                                        class={`descending-icon cursor-pointer ${
                                          props.sort == "name" && props.order == "desc" ? "sort-active" : ""
                                        }`}
                                        onClick={() => {
                                          props.setOrder("desc");
                                          props.setSort("name");
                                          props?.sortContractData(1, "name", "desc");
                                        }}
                                      ></div>
                                      Enterprise
                                    </a>
                                  </th>

                                  <th style={{ width: "20%" }}>
                                    <a href="javascript:void(0)" class="sort-by">
                                      Seller Commission
                                    </a>
                                  </th>

                                  <th style={{ width: "14%" }}>
                                    <a
                                      href="javascript:void(0)"
                                      className={`sort-by ${props.sort === "start_date" ? "active-column" : ""}`}
                                    >
                                      Start Date
                                      <div
                                        class={`ascending-icon cursor-pointer ${
                                          props.sort == "start_date" && props.order == "asc" ? "sort-active" : ""
                                        }`}
                                        onClick={() => {
                                          props.setOrder("asc");
                                          props.setSort("start_date");
                                          props?.sortContractData(1, "start_date", "asc");
                                        }}
                                      ></div>
                                      <div
                                        class={`descending-icon cursor-pointer ${
                                          props.sort == "start_date" && props.order == "desc" ? "sort-active" : ""
                                        }`}
                                        onClick={() => {
                                          props.setOrder("desc");
                                          props.setSort("start_date");
                                          props?.sortContractData(1, "start_date", "desc");
                                        }}
                                      ></div>
                                    </a>
                                  </th>
                                  <th style={{ width: "13%" }}>
                                    <a
                                      href="javascript:void(0)"
                                      className={`sort-by ${props.sort === "end_date" ? "active-column" : ""}`}
                                    >
                                      End Date
                                      <div
                                        class={`ascending-icon cursor-pointer ${
                                          props.sort == "end_date" && props.order == "asc" ? "sort-active" : ""
                                        }`}
                                        onClick={() => {
                                          props.setOrder("asc");
                                          props.setSort("end_date");
                                          props?.sortContractData(1, "end_date", "asc");
                                        }}
                                      ></div>
                                      <div
                                        class={`descending-icon cursor-pointer ${
                                          props.sort == "end_date" && props.order == "desc" ? "sort-active" : ""
                                        }`}
                                        onClick={() => {
                                          props.setOrder("desc");
                                          props.setSort("end_date");
                                          props?.sortContractData(1, "end_date", "desc");
                                        }}
                                      ></div>
                                    </a>
                                  </th>
                                  <th style={{ width: "5%" }}>
                                    <a href="javascript:void(0)" class="sort-by">
                                      Contract
                                    </a>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {contractData && contractData?.["1"]?.length > 0 ? (
                                  contractData?.["1"]?.map((item) => {
                                    const ext = item?.short_path?.split(".")[1];
                                    return (
                                      <tr>
                                        <td>
                                          <div class="td-action-btn-group d-flex align-items-center justify-content-between">
                                            <div class="dropdown">
                                              <a
                                                class="td-a-icon dropdown-toggle"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                              >
                                                <img src={More} alt="" />
                                              </a>
                                              <ul class="dropdown-menu ms-1">
                                                <li className="menu-item">
                                                  <a
                                                    class="dropdown-item customized-drpdown-btn"
                                                    href="javascript:void(0)"
                                                    onClick={() => {
                                                      props?.toggleAddContract();
                                                      props?.setSelectedItem(item);
                                                    }}
                                                  >
                                                    Edit
                                                  </a>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        </td>
                                        <td style={{ width: "20%" }}>
                                          {" "}
                                          {item?.proc_logo ? (
                                            <img
                                              className="enterprise-logo-img cursor-pointer"
                                              src={`https://media.salvex.com/co/logos/` + item?.proc_logo}
                                              onClick={() => {
                                                props?.toggleAddContract();
                                                props?.setSelectedItem(item);
                                              }}
                                            />
                                          ) : (
                                            ""
                                          )}
                                        </td>
                                        <td
                                          style={{ width: "22%" }}
                                          className="cursor-pointer"
                                          onClick={() => {
                                            props?.toggleAddContract();
                                            props?.setSelectedItem(item);
                                          }}
                                        >
                                          {item?.c_name} <br />
                                        </td>

                                        <td style={{ width: "20%" }}>
                                          <span class="fw-medium d-block ">
                                            {item?.percentage ? parseFloat(item?.percentage).toFixed(2) : "N/A"}
                                          </span>{" "}
                                          <span class="fw-medium d-block gray-color-text">
                                            Buyer Premium :{" "}
                                            {item?.comm_buyer ? parseFloat(item?.comm_buyer).toFixed(2) : "N/A"}
                                          </span>
                                        </td>

                                        <td style={{ width: "14%" }}>
                                          {item?.start_date ? moment(item?.start_date).format("MM/DD/YYYY") : "N/A"}
                                        </td>
                                        <td style={{ width: "13%" }}>
                                          {item?.end_date ? moment(item?.end_date).format("MM/DD/YYYY") : "N/A"}
                                        </td>
                                        <td style={{ width: "5%" }}>
                                          {item?.short_path ? (
                                            <a
                                              href={process.env.REACT_APP_MEDIA_URL + "/company/c/" + item?.short_path}
                                              target="_blank"
                                            >
                                              {" "}
                                              <img
                                                src={
                                                  ext && ext == "pdf"
                                                    ? pdfIcon
                                                    : ext == "xls"
                                                    ? XLS
                                                    : ext == "doc"
                                                    ? DOC
                                                    : ""
                                                }
                                                alt=""
                                              />
                                            </a>
                                          ) : (
                                            "N/A"
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    <td colSpan="8">
                                      <div className="d-flex justify-content-center">
                                        {" "}
                                        <div>
                                          <img src={emptyFolder} alt="" />
                                          <p>No Items Found</p>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          )}
                          {!props?.loading && (
                            <Pagination
                              page={props?.contractPage?.["1"]?.page}
                              totalPages={props?.contractPage?.["1"]?.totalPages}
                              setPage={props?.setPage}
                            />
                          )}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item
                      className="accordion-item"
                      eventKey="2"
                      onClick={() => {
                        props?.setActiveStatus(2);
                        props?.loadEnterpriseContracts(2);
                      }}
                    >
                      <Accordion.Button class="accordion-button ">
                        <div class="accordion-button-insider">
                          <h3>Pending ({props?.count?.pending ? props?.count?.pending : 0})</h3>
                        </div>
                      </Accordion.Button>
                      <Accordion.Body>
                        <div class="accor-table-row w-100">
                          <div class="table-selected-count d-none align-items-center justify-content-between"></div>
                          {props?.loading ? (
                            <p>Loading...</p>
                          ) : (
                            <table class="table align-middle table-collapse-table">
                              <thead>
                                <tr>
                                  <th style={{ width: "3%" }}></th>
                                  <th style={{ width: "20%" }}>
                                    <a href="javascript:void(0)" class="sort-by ">
                                      Logo
                                    </a>
                                  </th>
                                  <th style={{ width: "22%" }}>
                                    <a
                                      href="javascript:void(0)"
                                      className={`sort-by ${props.sort === "name" ? "active-column" : ""}`}
                                    >
                                      <div
                                        class={`ascending-icon cursor-pointer ${
                                          props.sort == "name" && props.order == "asc" ? "sort-active" : ""
                                        }`}
                                        onClick={() => {
                                          props.setOrder("asc");
                                          props.setSort("name");
                                          props?.sortContractData(2, "name", "asc");
                                        }}
                                      ></div>
                                      <div
                                        class={`descending-icon cursor-pointer ${
                                          props.sort == "name" && props.order == "desc" ? "sort-active" : ""
                                        }`}
                                        onClick={() => {
                                          props.setOrder("desc");
                                          props.setSort("name");
                                          props?.sortContractData(2, "name", "desc");
                                        }}
                                      ></div>
                                      Enterprise
                                    </a>
                                  </th>

                                  <th style={{ width: "20%" }}>
                                    <a href="javascript:void(0)" class="sort-by">
                                      Seller Commission
                                    </a>
                                  </th>

                                  <th style={{ width: "14%" }}>
                                    <a
                                      href="javascript:void(0)"
                                      className={`sort-by ${props.sort === "start_date" ? "active-column" : ""}`}
                                    >
                                      Start Date
                                      <div
                                        class={`ascending-icon cursor-pointer ${
                                          props.sort == "start_date" && props.order == "asc" ? "sort-active" : ""
                                        }`}
                                        onClick={() => {
                                          props.setOrder("asc");
                                          props.setSort("start_date");
                                          props?.sortContractData(2, "start_date", "asc");
                                        }}
                                      ></div>
                                      <div
                                        class={`descending-icon cursor-pointer ${
                                          props.sort == "start_date" && props.order == "desc" ? "sort-active" : ""
                                        }`}
                                        onClick={() => {
                                          props.setOrder("desc");
                                          props.setSort("start_date");
                                          props?.sortContractData(2, "start_date", "desc");
                                        }}
                                      ></div>
                                    </a>
                                  </th>
                                  <th style={{ width: "13%" }}>
                                    <a
                                      href="javascript:void(0)"
                                      className={`sort-by ${props.sort === "end_date" ? "active-column" : ""}`}
                                    >
                                      End Date
                                      <div
                                        class={`ascending-icon cursor-pointer ${
                                          props.sort == "end_date" && props.order == "asc" ? "sort-active" : ""
                                        }`}
                                        onClick={() => {
                                          props.setOrder("asc");
                                          props.setSort("end_date");
                                          props?.sortContractData(2, "end_date", "asc");
                                        }}
                                      ></div>
                                      <div
                                        class={`descending-icon cursor-pointer ${
                                          props.sort == "end_date" && props.order == "desc" ? "sort-active" : ""
                                        }`}
                                        onClick={() => {
                                          props.setOrder("desc");
                                          props.setSort("end_date");
                                          props?.sortContractData(2, "end_date", "desc");
                                        }}
                                      ></div>
                                    </a>
                                  </th>
                                  <th style={{ width: "5%" }}>
                                    <a href="javascript:void(0)" class="sort-by">
                                      Contract
                                    </a>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {contractData && contractData?.["2"]?.length > 0 ? (
                                  contractData?.["2"]?.map((item) => {
                                    const ext = item?.short_path?.split(".")[1];
                                    return (
                                      <tr>
                                        <td>
                                          <div class="td-action-btn-group d-flex align-items-center justify-content-between">
                                            <div class="dropdown">
                                              <a
                                                class="td-a-icon dropdown-toggle"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                              >
                                                <img src={More} alt="" />
                                              </a>
                                              <ul class="dropdown-menu ms-1">
                                                <li className="menu-item">
                                                  <a
                                                    class="dropdown-item customized-drpdown-btn"
                                                    href="javascript:void(0)"
                                                    onClick={() => {
                                                      props?.toggleAddContract();
                                                      props?.setSelectedItem(item);
                                                    }}
                                                  >
                                                    Edit
                                                  </a>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        </td>
                                        <td style={{ width: "20%" }}>
                                          {" "}
                                          {item?.proc_logo ? (
                                            <img
                                              className="enterprise-logo-img cursor-pointer"
                                              src={`https://media.salvex.com/co/logos/` + item?.proc_logo}
                                              onClick={() => {
                                                props?.toggleAddContract();
                                                props?.setSelectedItem(item);
                                              }}
                                            />
                                          ) : (
                                            ""
                                          )}
                                        </td>
                                        <td
                                          style={{ width: "22%" }}
                                          className="cursor-pointer"
                                          onClick={() => {
                                            props?.toggleAddContract();
                                            props?.setSelectedItem(item);
                                          }}
                                        >
                                          {item?.c_name} <br />
                                        </td>

                                        <td style={{ width: "20%" }}>
                                          <span class="fw-medium d-block ">
                                            {item?.percentage ? parseFloat(item?.percentage).toFixed(2) : "N/A"}
                                          </span>{" "}
                                          <span class="fw-medium d-block gray-color-text">
                                            Buyer Premium :{" "}
                                            {item?.comm_buyer ? parseFloat(item?.comm_buyer).toFixed(2) : "N/A"}
                                          </span>
                                        </td>

                                        <td style={{ width: "14%" }}>
                                          {item?.start_date ? moment(item?.start_date).format("MM/DD/YYYY") : "N/A"}
                                        </td>
                                        <td style={{ width: "13%" }}>
                                          {item?.end_date ? moment(item?.end_date).format("MM/DD/YYYY") : "N/A"}
                                        </td>
                                        <td style={{ width: "5%" }}>
                                          {item?.short_path ? (
                                            <a
                                              href={process.env.REACT_APP_MEDIA_URL + "/company/c/" + item?.short_path}
                                              target="_blank"
                                            >
                                              {" "}
                                              <img
                                                src={
                                                  ext && ext == "pdf"
                                                    ? pdfIcon
                                                    : ext == "xls"
                                                    ? XLS
                                                    : ext == "doc"
                                                    ? DOC
                                                    : ""
                                                }
                                                alt=""
                                              />
                                            </a>
                                          ) : (
                                            "N/A"
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    <td colSpan="8">
                                      <div className="d-flex justify-content-center">
                                        {" "}
                                        <div>
                                          <img src={emptyFolder} alt="" />
                                          <p>No Items Found</p>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          )}{" "}
                          {!props?.loading && (
                            <Pagination
                              page={props?.contractPage?.["2"]?.page}
                              totalPages={props?.contractPage?.["2"]?.totalPages}
                              setPage={props?.setPage}
                            />
                          )}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item
                      className="accordion-item"
                      eventKey="3"
                      onClick={() => {
                        props?.setActiveStatus(3);
                        props?.loadEnterpriseContracts(3);
                      }}
                    >
                      <Accordion.Button class="accordion-button ">
                        <div class="accordion-button-insider">
                          <h3>Signed ({props?.count?.signed ? props?.count?.signed : 0})</h3>
                        </div>
                      </Accordion.Button>
                      <Accordion.Body>
                        <div class="accor-table-row w-100">
                          <div class="table-selected-count d-none align-items-center justify-content-between"></div>
                          {props?.loading ? (
                            <p>Loading...</p>
                          ) : (
                            <table class="table align-middle table-collapse-table">
                              <thead>
                                <tr>
                                  <th style={{ width: "3%" }}></th>
                                  <th style={{ width: "20%" }}>
                                    <a href="javascript:void(0)" class="sort-by ">
                                      Logo
                                    </a>
                                  </th>
                                  <th style={{ width: "22%" }}>
                                    <a
                                      href="javascript:void(0)"
                                      className={`sort-by ${props.sort === "name" ? "active-column" : ""}`}
                                    >
                                      <div
                                        class={`ascending-icon cursor-pointer ${
                                          props.sort == "name" && props.order == "asc" ? "sort-active" : ""
                                        }`}
                                        onClick={() => {
                                          props.setOrder("asc");
                                          props.setSort("name");
                                          props?.sortContractData(3, "name", "asc");
                                        }}
                                      ></div>
                                      <div
                                        class={`descending-icon cursor-pointer ${
                                          props.sort == "name" && props.order == "desc" ? "sort-active" : ""
                                        }`}
                                        onClick={() => {
                                          props.setOrder("desc");
                                          props.setSort("name");
                                          props?.sortContractData(3, "name", "desc");
                                        }}
                                      ></div>
                                      Enterprise
                                    </a>
                                  </th>

                                  <th style={{ width: "20%" }}>
                                    <a href="javascript:void(0)" class="sort-by">
                                      Seller Commission
                                    </a>
                                  </th>

                                  <th style={{ width: "14%" }}>
                                    <a
                                      href="javascript:void(0)"
                                      className={`sort-by ${props.sort === "start_date" ? "active-column" : ""}`}
                                    >
                                      Start Date
                                      <div
                                        class={`ascending-icon cursor-pointer ${
                                          props.sort == "start_date" && props.order == "asc" ? "sort-active" : ""
                                        }`}
                                        onClick={() => {
                                          props.setOrder("asc");
                                          props.setSort("start_date");
                                          props?.sortContractData(3, "start_date", "asc");
                                        }}
                                      ></div>
                                      <div
                                        class={`descending-icon cursor-pointer ${
                                          props.sort == "start_date" && props.order == "desc" ? "sort-active" : ""
                                        }`}
                                        onClick={() => {
                                          props.setOrder("desc");
                                          props.setSort("start_date");
                                          props?.sortContractData(3, "start_date", "desc");
                                        }}
                                      ></div>
                                    </a>
                                  </th>
                                  <th style={{ width: "13%" }}>
                                    <a
                                      href="javascript:void(0)"
                                      className={`sort-by ${props.sort === "end_date" ? "active-column" : ""}`}
                                    >
                                      End Date
                                      <div
                                        class={`ascending-icon cursor-pointer ${
                                          props.sort == "end_date" && props.order == "asc" ? "sort-active" : ""
                                        }`}
                                        onClick={() => {
                                          props.setOrder("asc");
                                          props.setSort("end_date");
                                          props?.sortContractData(3, "end_date", "asc");
                                        }}
                                      ></div>
                                      <div
                                        class={`descending-icon cursor-pointer ${
                                          props.sort == "end_date" && props.order == "desc" ? "sort-active" : ""
                                        }`}
                                        onClick={() => {
                                          props.setOrder("desc");
                                          props.setSort("end_date");
                                          props?.sortContractData(3, "end_date", "desc");
                                        }}
                                      ></div>
                                    </a>
                                  </th>
                                  <th style={{ width: "5%" }}>
                                    <a href="javascript:void(0)" class="sort-by">
                                      Contract
                                    </a>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {contractData && contractData?.["3"]?.length > 0 ? (
                                  contractData?.["3"]?.map((item) => {
                                    const ext = item?.short_path?.split(".")[1];
                                    return (
                                      <tr>
                                        <td>
                                          <div class="td-action-btn-group d-flex align-items-center justify-content-between">
                                            <div class="dropdown">
                                              <a
                                                class="td-a-icon dropdown-toggle"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                              >
                                                <img src={More} alt="" />
                                              </a>
                                              <ul class="dropdown-menu ms-1">
                                                <li className="menu-item">
                                                  <a
                                                    class="dropdown-item customized-drpdown-btn"
                                                    href="javascript:void(0)"
                                                    onClick={() => {
                                                      props?.toggleAddContract();
                                                      props?.setSelectedItem(item);
                                                    }}
                                                  >
                                                    Edit
                                                  </a>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        </td>
                                        <td style={{ width: "20%" }}>
                                          {" "}
                                          {item?.proc_logo ? (
                                            <img
                                              className="enterprise-logo-img cursor-pointer"
                                              src={`https://media.salvex.com/co/logos/` + item?.proc_logo}
                                              onClick={() => {
                                                props?.toggleAddContract();
                                                props?.setSelectedItem(item);
                                              }}
                                            />
                                          ) : (
                                            ""
                                          )}
                                        </td>
                                        <td
                                          style={{ width: "22%" }}
                                          className="cursor-pointer"
                                          onClick={() => {
                                            props?.toggleAddContract();
                                            props?.setSelectedItem(item);
                                          }}
                                        >
                                          {item?.c_name} <br />
                                        </td>

                                        <td style={{ width: "20%" }}>
                                          <span class="fw-medium d-block ">
                                            {item?.percentage ? parseFloat(item?.percentage).toFixed(2) : "N/A"}
                                          </span>{" "}
                                          <span class="fw-medium d-block gray-color-text">
                                            Buyer Premium :{" "}
                                            {item?.comm_buyer ? parseFloat(item?.comm_buyer).toFixed(2) : "N/A"}
                                          </span>
                                        </td>

                                        <td style={{ width: "14%" }}>
                                          {item?.start_date ? moment(item?.start_date).format("MM/DD/YYYY") : "N/A"}
                                        </td>
                                        <td style={{ width: "13%" }}>
                                          {item?.end_date ? moment(item?.end_date).format("MM/DD/YYYY") : "N/A"}
                                        </td>
                                        <td style={{ width: "5%" }}>
                                          {item?.short_path ? (
                                            <a
                                              href={process.env.REACT_APP_MEDIA_URL + "/company/c/" + item?.short_path}
                                              target="_blank"
                                            >
                                              {" "}
                                              <img
                                                src={
                                                  ext && ext == "pdf"
                                                    ? pdfIcon
                                                    : ext == "xls"
                                                    ? XLS
                                                    : ext == "doc"
                                                    ? DOC
                                                    : ""
                                                }
                                                alt=""
                                              />
                                            </a>
                                          ) : (
                                            "N/A"
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    <td colSpan="8">
                                      <div className="d-flex justify-content-center">
                                        {" "}
                                        <div>
                                          <img src={emptyFolder} alt="" />
                                          <p>No Items Found</p>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          )}
                          {!props?.loading && (
                            <Pagination
                              page={props?.contractPage?.["3"]?.page}
                              totalPages={props?.contractPage?.["3"]?.totalPages}
                              setPage={props?.setPage}
                            />
                          )}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item
                      className="accordion-item"
                      eventKey="4"
                      onClick={() => {
                        props?.setActiveStatus(4);
                        props?.loadEnterpriseContracts(4);
                      }}
                    >
                      <Accordion.Button class="accordion-button ">
                        <div class="accordion-button-insider">
                          <h3>Expired ({props?.count?.expired ? props?.count?.expired : 0})</h3>
                        </div>
                      </Accordion.Button>
                      <Accordion.Body>
                        <div class="accor-table-row w-100">
                          <div class="table-selected-count d-none align-items-center justify-content-between"></div>
                          {props?.loading ? (
                            <p>Loading...</p>
                          ) : (
                            <table class="table align-middle table-collapse-table">
                              <thead>
                                <tr>
                                  <th style={{ width: "3%" }}></th>
                                  <th style={{ width: "20%" }}>
                                    <a href="javascript:void(0)" class="sort-by ">
                                      Logo
                                    </a>
                                  </th>
                                  <th style={{ width: "22%" }}>
                                    <a
                                      href="javascript:void(0)"
                                      className={`sort-by ${props.sort === "name" ? "active-column" : ""}`}
                                    >
                                      <div
                                        class={`ascending-icon cursor-pointer ${
                                          props.sort == "name" && props.order == "asc" ? "sort-active" : ""
                                        }`}
                                        onClick={() => {
                                          props.setOrder("asc");
                                          props.setSort("name");
                                          props?.sortContractData(4, "name", "asc");
                                        }}
                                      ></div>
                                      <div
                                        class={`descending-icon cursor-pointer ${
                                          props.sort == "name" && props.order == "desc" ? "sort-active" : ""
                                        }`}
                                        onClick={() => {
                                          props.setOrder("desc");
                                          props.setSort("name");
                                          props?.sortContractData(4, "name", "desc");
                                        }}
                                      ></div>
                                      Enterprise
                                    </a>
                                  </th>

                                  <th style={{ width: "20%" }}>
                                    <a href="javascript:void(0)" class="sort-by">
                                      Seller Commission
                                    </a>
                                  </th>

                                  <th style={{ width: "14%" }}>
                                    <a
                                      href="javascript:void(0)"
                                      className={`sort-by ${props.sort === "start_date" ? "active-column" : ""}`}
                                    >
                                      Start Date
                                      <div
                                        class={`ascending-icon cursor-pointer ${
                                          props.sort == "start_date" && props.order == "asc" ? "sort-active" : ""
                                        }`}
                                        onClick={() => {
                                          props.setOrder("asc");
                                          props.setSort("start_date");
                                          props?.sortContractData(4, "start_date", "asc");
                                        }}
                                      ></div>
                                      <div
                                        class={`descending-icon cursor-pointer ${
                                          props.sort == "start_date" && props.order == "desc" ? "sort-active" : ""
                                        }`}
                                        onClick={() => {
                                          props.setOrder("desc");
                                          props.setSort("start_date");
                                          props?.sortContractData(4, "start_date", "desc");
                                        }}
                                      ></div>
                                    </a>
                                  </th>
                                  <th style={{ width: "13%" }}>
                                    <a
                                      href="javascript:void(0)"
                                      className={`sort-by ${props.sort === "end_date" ? "active-column" : ""}`}
                                    >
                                      End Date
                                      <div
                                        class={`ascending-icon cursor-pointer ${
                                          props.sort == "end_date" && props.order == "asc" ? "sort-active" : ""
                                        }`}
                                        onClick={() => {
                                          props.setOrder("asc");
                                          props.setSort("end_date");
                                          props?.sortContractData(4, "end_date", "asc");
                                        }}
                                      ></div>
                                      <div
                                        class={`descending-icon cursor-pointer ${
                                          props.sort == "end_date" && props.order == "desc" ? "sort-active" : ""
                                        }`}
                                        onClick={() => {
                                          props.setOrder("desc");
                                          props.setSort("end_date");
                                          props?.sortContractData(4, "end_date", "desc");
                                        }}
                                      ></div>
                                    </a>
                                  </th>
                                  <th style={{ width: "5%" }}>
                                    <a href="javascript:void(0)" class="sort-by">
                                      Contract
                                    </a>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {contractData && contractData?.["4"]?.length > 0 ? (
                                  contractData?.["4"]?.map((item) => {
                                    const ext = item?.short_path?.split(".")[1];
                                    return (
                                      <tr>
                                        <td>
                                          <div class="td-action-btn-group d-flex align-items-center justify-content-between">
                                            <div class="dropdown">
                                              <a
                                                class="td-a-icon dropdown-toggle"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                              >
                                                <img src={More} alt="" />
                                              </a>
                                              <ul class="dropdown-menu ms-1">
                                                <li className="menu-item">
                                                  <a
                                                    class="dropdown-item customized-drpdown-btn"
                                                    href="javascript:void(0)"
                                                    onClick={() => {
                                                      props?.toggleAddContract();
                                                      props?.setSelectedItem(item);
                                                    }}
                                                  >
                                                    Edit
                                                  </a>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        </td>
                                        <td style={{ width: "20%" }}>
                                          {" "}
                                          {item?.proc_logo ? (
                                            <img
                                              className="enterprise-logo-img cursor-pointer"
                                              src={`https://media.salvex.com/co/logos/` + item?.proc_logo}
                                              onClick={() => {
                                                props?.toggleAddContract();
                                                props?.setSelectedItem(item);
                                              }}
                                            />
                                          ) : (
                                            ""
                                          )}
                                        </td>
                                        <td
                                          style={{ width: "22%" }}
                                          className="cursor-pointer"
                                          onClick={() => {
                                            props?.toggleAddContract();
                                            props?.setSelectedItem(item);
                                          }}
                                        >
                                          {item?.c_name} <br />
                                        </td>

                                        <td style={{ width: "20%" }}>
                                          <span class="fw-medium d-block ">
                                            {item?.percentage ? parseFloat(item?.percentage).toFixed(2) : "N/A"}
                                          </span>{" "}
                                          <span class="fw-medium d-block gray-color-text">
                                            Buyer Premium :{" "}
                                            {item?.comm_buyer ? parseFloat(item?.comm_buyer).toFixed(2) : "N/A"}
                                          </span>
                                        </td>

                                        <td style={{ width: "14%" }}>
                                          {item?.start_date ? moment(item?.start_date).format("MM/DD/YYYY") : "N/A"}
                                        </td>
                                        <td style={{ width: "13%" }}>
                                          {item?.end_date ? moment(item?.end_date).format("MM/DD/YYYY") : "N/A"}
                                        </td>
                                        <td style={{ width: "5%" }}>
                                          {item?.short_path ? (
                                            <a
                                              href={process.env.REACT_APP_MEDIA_URL + "/company/c/" + item?.short_path}
                                              target="_blank"
                                            >
                                              {" "}
                                              <img
                                                src={
                                                  ext && ext == "pdf"
                                                    ? pdfIcon
                                                    : ext == "xls"
                                                    ? XLS
                                                    : ext == "doc"
                                                    ? DOC
                                                    : ""
                                                }
                                                alt=""
                                              />
                                            </a>
                                          ) : (
                                            "N/A"
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    <td colSpan="8">
                                      <div className="d-flex justify-content-center">
                                        {" "}
                                        <div>
                                          <img src={emptyFolder} alt="" />
                                          <p>No Items Found</p>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          )}{" "}
                          {!props?.loading && (
                            <Pagination
                              page={props?.contractPage?.["4"]?.page}
                              totalPages={props?.contractPage?.["4"]?.totalPages}
                              setPage={props?.setPage}
                            />
                          )}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item
                      className="accordion-item"
                      eventKey="5"
                      onClick={() => {
                        props?.setActiveStatus(5);
                        props?.loadEnterpriseContracts(5);
                      }}
                    >
                      <Accordion.Button class="accordion-button ">
                        <div class="accordion-button-insider">
                          <h3>Unassigned ({props?.count?.unAssigned ? props?.count?.unAssigned : 0})</h3>
                        </div>
                      </Accordion.Button>
                      <Accordion.Body>
                        <div class="accor-table-row w-100">
                          <div class="table-selected-count d-none align-items-center justify-content-between"></div>
                          {props?.loading ? (
                            <p>Loading...</p>
                          ) : (
                            <table class="table align-middle table-collapse-table">
                              <thead>
                                <tr>
                                  <th style={{ width: "3%" }}></th>
                                  <th style={{ width: "20%" }}>
                                    <a href="javascript:void(0)" class="sort-by ">
                                      Logo
                                    </a>
                                  </th>
                                  <th style={{ width: "22%" }}>
                                    <a
                                      href="javascript:void(0)"
                                      className={`sort-by ${props.sort === "name" ? "active-column" : ""}`}
                                    >
                                      <div
                                        class={`ascending-icon cursor-pointer ${
                                          props.sort == "name" && props.order == "asc" ? "sort-active" : ""
                                        }`}
                                        onClick={() => {
                                          props.setOrder("asc");
                                          props.setSort("name");
                                          props?.sortContractData(5, "name", "asc");
                                        }}
                                      ></div>
                                      <div
                                        class={`descending-icon cursor-pointer ${
                                          props.sort == "name" && props.order == "desc" ? "sort-active" : ""
                                        }`}
                                        onClick={() => {
                                          props.setOrder("desc");
                                          props.setSort("name");
                                          props?.sortContractData(5, "name", "desc");
                                        }}
                                      ></div>
                                      Enterprise
                                    </a>
                                  </th>

                                  <th style={{ width: "20%" }}>
                                    <a href="javascript:void(0)" class="sort-by">
                                      Seller Commission
                                    </a>
                                  </th>

                                  <th style={{ width: "14%" }}>
                                    <a
                                      href="javascript:void(0)"
                                      className={`sort-by ${props.sort === "start_date" ? "active-column" : ""}`}
                                    >
                                      Start Date
                                      <div
                                        class={`ascending-icon cursor-pointer ${
                                          props.sort == "start_date" && props.order == "asc" ? "sort-active" : ""
                                        }`}
                                        onClick={() => {
                                          props.setOrder("asc");
                                          props.setSort("start_date");
                                          props?.sortContractData(5, "start_date", "asc");
                                        }}
                                      ></div>
                                      <div
                                        class={`descending-icon cursor-pointer ${
                                          props.sort == "start_date" && props.order == "desc" ? "sort-active" : ""
                                        }`}
                                        onClick={() => {
                                          props.setOrder("desc");
                                          props.setSort("start_date");
                                          props?.sortContractData(5, "start_date", "desc");
                                        }}
                                      ></div>
                                    </a>
                                  </th>
                                  <th style={{ width: "13%" }}>
                                    <a
                                      href="javascript:void(0)"
                                      className={`sort-by ${props.sort === "end_date" ? "active-column" : ""}`}
                                    >
                                      End Date
                                      <div
                                        class={`ascending-icon cursor-pointer ${
                                          props.sort == "end_date" && props.order == "asc" ? "sort-active" : ""
                                        }`}
                                        onClick={() => {
                                          props.setOrder("asc");
                                          props.setSort("end_date");
                                          props?.sortContractData(5, "end_date", "asc");
                                        }}
                                      ></div>
                                      <div
                                        class={`descending-icon cursor-pointer ${
                                          props.sort == "end_date" && props.order == "desc" ? "sort-active" : ""
                                        }`}
                                        onClick={() => {
                                          props.setOrder("desc");
                                          props.setSort("end_date");
                                          props?.sortContractData(5, "end_date", "desc");
                                        }}
                                      ></div>
                                    </a>
                                  </th>
                                  <th style={{ width: "5%" }}>
                                    <a href="javascript:void(0)" class="sort-by">
                                      Contract
                                    </a>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {contractData && contractData?.["5"]?.length > 0 ? (
                                  contractData?.["5"]?.map((item) => {
                                    const ext = item?.short_path?.split(".")[1];
                                    return (
                                      <tr>
                                        <td>
                                          <div class="td-action-btn-group d-flex align-items-center justify-content-between">
                                            <div class="dropdown">
                                              <a
                                                class="td-a-icon dropdown-toggle"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                              >
                                                <img src={More} alt="" />
                                              </a>
                                              <ul class="dropdown-menu ms-1">
                                                <li className="menu-item">
                                                  <a
                                                    class="dropdown-item customized-drpdown-btn"
                                                    href="javascript:void(0)"
                                                    onClick={() => {
                                                      props?.toggleAddContract();
                                                      props?.setSelectedItem(item);
                                                    }}
                                                  >
                                                    Edit
                                                  </a>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        </td>
                                        <td style={{ width: "20%" }}>
                                          {" "}
                                          {item?.proc_logo ? (
                                            <img
                                              className="enterprise-logo-img cursor-pointer"
                                              src={`https://media.salvex.com/co/logos/` + item?.proc_logo}
                                              onClick={() => {
                                                props?.toggleAddContract();
                                                props?.setSelectedItem(item);
                                              }}
                                            />
                                          ) : (
                                            ""
                                          )}
                                        </td>
                                        <td
                                          style={{ width: "22%" }}
                                          className="cursor-pointer"
                                          onClick={() => {
                                            props?.toggleAddContract();
                                            props?.setSelectedItem(item);
                                          }}
                                        >
                                          {item?.c_name} <br />
                                        </td>

                                        <td style={{ width: "20%" }}>
                                          <span class="fw-medium d-block ">
                                            {item?.percentage ? parseFloat(item?.percentage).toFixed(2) : "N/A"}
                                          </span>{" "}
                                          <span class="fw-medium d-block gray-color-text">
                                            Buyer Premium :{" "}
                                            {item?.comm_buyer ? parseFloat(item?.comm_buyer).toFixed(2) : "N/A"}
                                          </span>
                                        </td>

                                        <td style={{ width: "14%" }}>
                                          {item?.start_date ? moment(item?.start_date).format("MM/DD/YYYY") : "N/A"}
                                        </td>
                                        <td style={{ width: "13%" }}>
                                          {item?.end_date ? moment(item?.end_date).format("MM/DD/YYYY") : "N/A"}
                                        </td>
                                        <td style={{ width: "5%" }}>
                                          {item?.short_path ? (
                                            <a
                                              href={process.env.REACT_APP_MEDIA_URL + "/company/c/" + item?.short_path}
                                              target="_blank"
                                            >
                                              {" "}
                                              <img
                                                src={
                                                  ext && ext == "pdf"
                                                    ? pdfIcon
                                                    : ext == "xls"
                                                    ? XLS
                                                    : ext == "doc"
                                                    ? DOC
                                                    : ""
                                                }
                                                alt=""
                                              />
                                            </a>
                                          ) : (
                                            "N/A"
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    <td colSpan="8">
                                      <div className="d-flex justify-content-center">
                                        {" "}
                                        <div>
                                          <img src={emptyFolder} alt="" />
                                          <p>No Items Found</p>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          )}{" "}
                          {!props?.loading && (
                            <Pagination
                              page={props?.contractPage?.["5"]?.page}
                              totalPages={props?.contractPage?.["5"]?.totalPages}
                              setPage={props?.setPage}
                            />
                          )}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeContractsTable;
