import React from "react";
import { Modal } from "react-bootstrap";
import NextButton from "../../../common/form/nextButton";

const SendMail = (props) => {
  return (
    <Modal
      className=" fade custom-modal-popup"
      show={props.show}
      onHide={props.onHide}
      enforceFocus={true}
      backdropClassName={"delete-backdrop"}
      centered
    >
      <Modal.Body>
        <div class="modal-alert-box">
          <textarea
            class="form-control w-100"
            id="FormControlTextareaL"
            value={props?.mailData?.new_message}
            onChange={(e) => {
              const data = { ...props.mailData };
              data.new_message = e.target.value;
              props.setMailData(data);
            }}
          />
        </div>
        <div class="modal-action-btn">
          <NextButton
            label="OK"
            classData="btn btn-default btn-lg"
            handleSubmit={props.handleSubmit}
            loading={props.loading}
          />
          <a
            href="javascript:void(0)"
            style={{ background: "#fff" }}
            class="btn btn-cancel global-cancel-button"
            onClick={props.onHide}
          >
            Cancel
          </a>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SendMail;
