import React from "react";

//Images

import emptyFolder from "../../include/images/folder.svg";
import { useHistory } from "react-router-dom";
import searchIcon from "../../include/images/search.svg";

const Table = (props) => {
  const history = useHistory();
  const data = props.listingsData;

  const statusIdentifier = (data) => {
    if ((data >= 3 && data < 5) || data > 5) {
      const status = "Active";
      return status;
    }
    if (data === 5) {
      const status = "Archive";
      return status;
    }
    if (data === 1 || data === 2) {
      const status = "Pending";
      return status;
    }
  };

  return (
    <div class="table-container">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="tb-filter-row d-flex align-items-start justify-content-between">
              <div class="filter-tags d-flex align-items-start">
                <div class="tags"></div>
              </div>
              <div class="tb-filter-right d-flex align-items-center justify-content-end">
                {data && data.length > 0 ? (
                  <div class="showing-result-text">
                    Showing {data.length} of {data.length} total results
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            {data && data.length > 0 ? (
              <div class="table-responsive position-relative">
                <div class="table-selected-count d-none align-items-center justify-content-between">
                  <div class="tsc-check-label d-flex align-items-center"></div>
                  <div class="tsc-del-label d-flex align-items-center fw-medium"></div>
                </div>
                <table class="table align-middle table-collapse-table">
                  <thead>
                    <tr>
                      <th style={{ width: "18%" }}>
                        <a href="javascript:void(0)" class="sort-by">
                          Auction Id
                        </a>
                      </th>

                      <th style={{ width: "43%" }}>
                        <a href="javascript:void(0)" class="sort-by">
                          Title
                        </a>
                      </th>
                      <th style={{ width: "20%" }}>
                        <a href="javascript:void(0)" class="sort-by">
                          Location
                        </a>
                      </th>

                      <th style={{ width: "18%" }}>
                        <a href="javascript:void(0)" class="sort-by">
                          Status
                        </a>
                      </th>
                      <th style={{ width: "1%" }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {data &&
                      data.length > 0 &&
                      data.map((item, i) => (
                        <tr>
                          <td colspan="8">
                            <table class="table2">
                              <tr>
                                <td style={{ width: "18%" }}>
                                  {item.auction_id ? (
                                    <span class="td-text-box link-item">
                                      <a href={`/listing-detail?id=${item.auction_id}`} target="_blank">
                                        {item.auction_id}
                                      </a>
                                    </span>
                                  ) : (
                                    "N/A"
                                  )}
                                </td>
                                <td style={{ width: "43%" }}>
                                  {item.title ? (
                                    <span class="td-text-box link-item">
                                      <a href={`/listing-detail?id=${item.auction_id}`} target="_blank">
                                        {item.title}
                                      </a>
                                    </span>
                                  ) : (
                                    "N/A"
                                  )}
                                </td>
                                <td style={{ width: "20%" }}>{item.location ? item.location : "N/A"}</td>

                                <td style={{ width: "18%" }}>{statusIdentifier(item.status_id)}</td>
                                <td style={{ width: "1%" }}></td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <>
                <div class="table-empty">
                  <div class="table-empty-inner">
                    <img src={emptyFolder} alt="" />
                    <p>
                      {props.loading
                        ? "Loading..."
                        : props.notFound
                        ? "No Items Found"
                        : !props.notFound && "Please enter your keywords to display matching listings"}
                    </p>
                    <div className="search-table-search-box">
                      {" "}
                      <div class="header-search-box position-relative">
                        <input
                          class="w-100"
                          type="search"
                          placeholder="Enter Auction ID or Keywords"
                          onChange={(e) => props.setSearchItem(e.target.value)}
                          value={props.searchItm}
                          onKeyDown={(k) => {
                            if (k.key === "Enter") {
                              props.searchHandler();
                            }
                          }}
                        />
                        <img class="search__icon" src={searchIcon} alt="" onClick={() => props.searchHandler()} />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Table);
