import React, { useEffect } from "react";

const PaymentTypeTwo = ({ setError2, error2, option2Data, setOption2Data, member }) => {
  return (
    <>
      <p>
        <strong>Domestic Wire Transfer (U.S. Only)</strong>
      </p>

      <>
        <p style={{ fontWeight: "600", marginBottom: "0.2rem" }}>
          Please confirm that you have an ABA number for FED Wire Transfers. Any fees incurred from incorrect wire
          information will be deducted from your payment.
        </p>
        <div class="four-column-radio-group mb-4">
          {option2Data?.dwr_type == "1" && (
            <div class="fcrg-item  payment-info-main-option">
              <p class="form-check-label" for="WTflexRadio__1">
                Yes, I have an ABA number from my bank.
              </p>
            </div>
          )}
          {option2Data.dwr_type == "2" && (
            <div class="fcrg-item  payment-info-main-option">
              <p class="form-check-label" for="WTflexRadio__2">
                No, I only have an ACH Routing number and need to request an ACH transfer instead.
              </p>
            </div>
          )}
          {option2Data.dwr_type == "3" && (
            <div class="fcrg-item  payment-info-main-option">
              <p class="form-check-label" for="WTflexRadio__3">
                No, I will contact my bank now to confirm the ABA number.
              </p>
            </div>
          )}
        </div>
      </>

      {option2Data.dwr_type == 1 ? (
        <>
          <div class="form-group payment-info-text">
            <label for="opt1Name" class="form-label fw-medium">
              Beneficiary Name (as is appears on the account)
            </label>
            <p>{option2Data.account_name}</p>
          </div>
          <div class="form-group payment-info-text">
            <label for="opt1Addre" class="form-label fw-medium">
              Beneficiary Address (street, city, state and zip )
            </label>
            <p>{option2Data.account_address}</p>
          </div>

          <div class="form-group payment-info-text">
            <label for="opt1Acc" class="form-label fw-medium">
              Beneficiary Account Number
            </label>
            <p>{option2Data.account_iban_no}</p>
          </div>
          <div class="form-group payment-info-text">
            <label for="op1Aba" class="form-label fw-medium">
              ABA Number
            </label>
            <p>{option2Data.aba_swift_no}</p>
          </div>

          <div class="form-group payment-info-text">
            <label for="op1BankName" class="form-label fw-medium">
              Beneficiary Bank Name
            </label>
            <p>{option2Data.bank_name}</p>
          </div>
          <div class="form-group payment-info-text">
            <label for="op1BankAdd" class="form-label fw-medium">
              Beneficiary Bank Address
            </label>
            <p>{option2Data.bank_address}</p>
          </div>

          <div class="form-group payment-info-text">
            <label>
              <strong>What type of reference number do you prefer?</strong>
            </label>
            <div class="four-column-radio-group fw-medium">
              {option2Data.preferred_ref_no == "1" && (
                <div class="fcrg-item payment-info-main-option">
                  <p class="form-check-label" for="RflowRadio1__1">
                    Claim or File Number
                  </p>
                </div>
              )}
              {option2Data.preferred_ref_no == "2" && (
                <div class="fcrg-item payment-info-main-option">
                  <p class="form-check-label" for="RflowRadio1__2">
                    Invoice Number
                  </p>
                </div>
              )}
              {option2Data.preferred_ref_no == "3" && (
                <div class="fcrg-item payment-info-main-option">
                  <p class="form-check-label" for="RflowRadio1__3">
                    Shipping Container Number
                  </p>
                </div>
              )}
              {option2Data.preferred_ref_no == "4" && (
                <div class="fcrg-item payment-info-main-option">
                  <p class="form-check-label" for="RflowRadio1__4">
                    Salvex Listing number is fine
                  </p>
                </div>
              )}
            </div>
          </div>
          <div class="form-group payment-info-text">
            <label>
              <strong>If you have intermediary bank information, please enter it here.</strong>
            </label>
            <p>{option2Data.intermediary_bank_info}</p>
          </div>
          <div class="form-group payment-info-text">
            <label for="op1Email" class="form-label fw-medium">
              Additional email address to be sent confirmation of payment.
            </label>
            <p>{option2Data.other_email}</p>
          </div>
          <div class="form-group payment-info-text">
            <label for="op1addQue" class="form-label fw-medium">
              Do you have any additional instructions?
            </label>
            <p>{option2Data.additional_info}</p>
          </div>
        </>
      ) : option2Data.dwr_type == 2 ? (
        <>
          <div class="form-group payment-info-text">
            <label for="opt2Name" class="form-label fw-medium">
              Company or Individual Name (as is appears on the account)
            </label>
            <p>{option2Data.account_name}</p>
          </div>
          <div class="form-group payment-info-text">
            <label for="" class="form-label fw-medium">
              Complete Address associated with account
            </label>
            <p>{option2Data.account_address}</p>
          </div>

          <div class="form-group payment-info-text">
            <label for="" class="form-label fw-medium">
              Name of Bank
            </label>
            <p>{option2Data.bank_name}</p>
          </div>
          <div class="form-group payment-info-text">
            <label for="" class="form-label fw-medium">
              Account Number
            </label>
            <p>{option2Data.account_iban_no}</p>
          </div>

          <div class="form-group payment-info-text">
            <label for="" class="form-label fw-medium">
              ACH Routing Number
            </label>
            <p>{option2Data.ach_routing_no}</p>
          </div>
          <div class="form-group payment-info-text">
            <label for="" class="form-label fw-medium">
              Additional email address to be sent confirmation of payment.
            </label>
            <p>{option2Data.other_email}</p>
          </div>
          <div class="form-group payment-info-text">
            <label for="" class="form-label fw-medium">
              Do you have any additional instructions?
            </label>
            <p>{option2Data.additional_info}</p>
          </div>
        </>
      ) : (
        option2Data.dwr_type == 3 && (
          <div class="form-group payment-info-text">
            <label for="" class="form-label fw-medium">
              Do you have any additional instructions?
            </label>
            <p>{option2Data.additional_info}</p>
          </div>
        )
      )}
    </>
  );
};

export default PaymentTypeTwo;
