import React, { useState, useEffect, useRef, useImperativeHandle } from "react";
import TuiImageEditor from "tui-image-editor";

import "tui-image-editor/dist/tui-image-editor.css";
import "tui-color-picker/dist/tui-color-picker.css";

var imageEditorInst = null;

class ImageEditor extends React.Component {
  rootEl = React.createRef();
  //   imageEditorInst = null;

  componentDidMount() {
    imageEditorInst = new TuiImageEditor(this.rootEl.current, {
      ...this.props,
    });
  }

  componentWillUnmount() {
    // this.unbindEventHandlers();
    imageEditorInst.destroy();
    imageEditorInst = null;
  }

  render() {
    return <div ref={this.rootEl} />;
  }
}

export const Scrib = React.forwardRef((props, ref) => {
  const image = props.image || {};
  const prop = {
    includeUI: {
      loadImage: {
        path: `${process.env.REACT_APP_MEDIA_URL}${image.s_path}${
          image.filename
        }_lp.${image.suffix.toLowerCase()}`,
        name: "SampleImage",
      },
      //   theme: "whiteTheme", // or whiteTheme
      menu: ["draw"],
      initMenu: "draw",

      uiSize: {
        width: "100%",
        height: "700px",
      },
      menuBarPosition: "left",
    },
    cssMaxWidth: 600,
    cssMaxHeight: 600,
    selectionStyle: {
      cornerSize: 20,
      rotatingPointOffset: 70,
    },
  };

  useImperativeHandle(ref, () => ({
    handleClick() {
      handleClick();
    },
    clear() {
      clear();
    },
  }));

  const clear = () => {
    imageEditorInst.clearObjects();
  };
  const handleClick = () => {
    const a = imageEditorInst.toDataURL({ format: "jpeg", quality: "0.01" });
    props.setEditedFile(a);
  };

  return (
    <div className="container-edit" style={{ height: "750px" }} ref={ref}>
      <div className="container-cropper-edit">
        <div className="cropper-edit">
          <ImageEditor {...prop} />
        </div>
      </div>
    </div>
  );
});
