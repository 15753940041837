import React, { useEffect, useState } from "react";
import close from "../../../../include/images/close-12x12.svg";
import { Modal } from "react-bootstrap";
import NextButton from "../../../../common/form/nextButton";
import { customStyles } from "../../../../common/customStyles/reactSelectStyle";
import Select from "react-select";

const EditModal = (props) => {
  const [selectedState, setSelectedState] = useState({});
  const [selectedCountry, setSelectedCountry] = useState({});
  useEffect(() => {
    setSelectedCountry(
      props?.country?.find((val) => val?.old == props?.editData?.country_id)
        ? props?.country?.find((val) => val?.old == props?.editData?.country_id)
        : null,
    );
    setSelectedState(
      props?.state?.find((val) => val?.old == props?.editData?.state_id)
        ? props?.state?.find((val) => val?.old == props?.editData?.state_id)
        : null,
    );
  }, [props.country, props.state]);
  return (
    <Modal
      className="modal fade custom-modal"
      id="newBuyer"
      show={props.show}
      onHide={props.onHide}
      dialogClassName="modal-dialog modal-dialog-centered modal-lg"
    >
      <div class="modal-content h-100 d-flex flex-column">
        <div class="custom-modal-header d-flex align-items-center justify-content-between">
          <h5 class="mb-0 text-capitalize">{props?.title}</h5>
          <button
            type="button"
            class="close-btn d-flex align-items-center justify-content-center"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={props.onHide}
          >
            <img src={close} alt="" />
          </button>
        </div>
        <div class="custom-modal-body flex-grow-1 hide-scrollbar">
          <table class="table modal-product-detail-table mb-0">
            <colgroup>
              <col style={{ width: "30%" }} />
              <col style={{ width: "70%" }} />
            </colgroup>
            <tr>
              <td>Headquarters</td>
              <td>
                <div className="d-flex">
                  <div className="form-check radio pe-2">
                    <input
                      className="form-check-input cursor-pointer"
                      value={1}
                      type="radio"
                      name="radioHQ"
                      checked={props?.editData?.headquarters ? 1 : 0}
                      id="HqYes"
                      onClick={(e) => {
                        const data = { ...props?.editData };
                        data.headquarters = e.target.checked ? 1 : 0;
                        props?.setEditData(data);
                      }}
                    />
                    <label className="form-check-label" htmlFor="HqYes">
                      Yes
                    </label>
                  </div>
                  <div className="form-check radio pe-2">
                    <input
                      className="form-check-input cursor-pointer"
                      value={0}
                      type="radio"
                      name="radioHQ"
                      checked={props?.editData?.headquarters ? 0 : 1}
                      id="HqNo"
                      onClick={(e) => {
                        const data = { ...props?.editData };
                        data.headquarters = e.target.checked ? 0 : 1;
                        props?.setEditData(data);
                      }}
                    />
                    <label className="form-check-label" htmlFor="HqNo">
                      No
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>Location/Project Name</td>
              <td>
                <div>
                  <input
                    type="text"
                    value={props?.editData?.title}
                    class="form-control"
                    onChange={(e) => {
                      const data = { ...props?.editData };
                      data.title = e.target.value;
                      props?.setEditData(data);
                    }}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>Address 1</td>
              <td>
                <div>
                  <input
                    type="text"
                    value={props?.editData?.address_1}
                    class="form-control"
                    onChange={(e) => {
                      const data = { ...props?.editData };
                      data.address_1 = e.target.value;
                      props?.setEditData(data);
                    }}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>Address 2</td>
              <td>
                <div>
                  <input
                    type="text"
                    value={props?.editData?.address_2}
                    class="form-control"
                    onChange={(e) => {
                      const data = { ...props?.editData };
                      data.address_2 = e.target.value;
                      props?.setEditData(data);
                    }}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>City</td>
              <td>
                <div>
                  <input
                    type="text"
                    value={props?.editData?.city}
                    class="form-control"
                    onChange={(e) => {
                      const data = { ...props?.editData };
                      data.city = e.target.value;
                      props?.setEditData(data);
                    }}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>Posta Code</td>
              <td>
                <div>
                  <input
                    type="text"
                    value={props?.editData?.zip_code}
                    class="form-control"
                    onChange={(e) => {
                      const data = { ...props?.editData };
                      data.zip_code = e.target.value;
                      props?.setEditData(data);
                    }}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>Phone 1</td>
              <td>
                <div>
                  <input
                    type="text"
                    value={props?.editData?.phone_1}
                    class="form-control"
                    onChange={(e) => {
                      const data = { ...props?.editData };
                      data.phone_1 = e.target.value;
                      props?.setEditData(data);
                    }}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>Phone 2</td>
              <td>
                <div>
                  <input
                    type="text"
                    value={props?.editData?.phone_2}
                    class="form-control"
                    onChange={(e) => {
                      const data = { ...props?.editData };
                      data.phone_2 = e.target.value;
                      props?.setEditData(data);
                    }}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>Country</td>
              <td>
                <div>
                  <Select
                    styles={customStyles}
                    value={selectedCountry}
                    options={props.country}
                    placeholder="Select Country"
                    getOptionLabel={(item) => item.description}
                    getOptionValue={(item) => item.old}
                    onChange={(e) => {
                      const data = { ...props?.editData };
                      data.country_id = e.old;
                      if (e.old !== 1001) {
                        setSelectedState({
                          description: "International",
                          abb: "IT",
                          old: 1001,
                        });
                        data.state_id = 1001;
                      }
                      setSelectedCountry(e);
                      props.setEditData(data);
                    }}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>State</td>
              <td>
                <div>
                  <Select
                    styles={customStyles}
                    value={selectedState}
                    options={
                      props?.editData?.country_id === 1001
                        ? props.state
                        : [
                            {
                              description: "International",
                              abb: "IT",
                              old: 1001,
                            },
                          ]
                    }
                    placeholder="Select State"
                    getOptionLabel={(item) => item.description}
                    getOptionValue={(item) => item.old}
                    onChange={(e) => {
                      const data = { ...props?.editData };
                      data.state_id = e.old;
                      props.setEditData(data);
                      setSelectedState(e);
                    }}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>Description</td>
              <td>
                <div>
                  <textarea
                    type="text"
                    value={props?.editData?.description}
                    class="form-control"
                    onChange={(e) => {
                      const data = { ...props?.editData };
                      data.description = e.target.value;
                      props?.setEditData(data);
                    }}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>Unsubscribed</td>
              <td>
                <div className="d-flex">
                  <div className="form-check radio pe-2">
                    <input
                      className="form-check-input cursor-pointer"
                      value={1}
                      type="radio"
                      checked={props?.editData?.unsubscribe === 1}
                      name="radioUnsubscribe"
                      id="unSubYes"
                      onClick={(e) => {
                        const data = { ...props?.editData };
                        data.unsubscribe = e.target.checked ? 1 : 0;
                        props?.setEditData(data);
                      }}
                    />
                    <label className="form-check-label" htmlFor="unSubYes">
                      Yes
                    </label>
                  </div>
                  <div className="form-check radio pe-2">
                    <input
                      className="form-check-input cursor-pointer"
                      value={0}
                      type="radio"
                      checked={props?.editData?.unsubscribe === 0}
                      name="radioUnsubscribe"
                      id="unSubNo"
                      onClick={(e) => {
                        const data = { ...props?.editData };
                        data.unsubscribe = e.target.checked ? 0 : 1;
                        props?.setEditData(data);
                      }}
                    />
                    <label className="form-check-label" htmlFor="unSubNo">
                      No
                    </label>
                  </div>
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div class="custom-modal-dividor"></div>
        <div class="custom-modal-footer d-grid gap-2 d-md-flex align-items-center justify-content-md-end">
          <button
            class="btn btn-link me-md-2"
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={props.onHide}
          >
            Cancel
          </button>
          <NextButton
            label="Save Location"
            classData="btn btn-default btn-lg"
            handleSubmit={props.handleEdit}
            loading={props.loading}
          />
        </div>
      </div>
    </Modal>
  );
};

export default EditModal;
