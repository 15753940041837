import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import Header from "../layouts/header";
import UserTable from "../components/users/userTable";
import { connect } from "react-redux";
import { getUserList, userListings } from "../store/userList";
import LoadingBar from "react-top-loading-bar";
import PipeLogTable from "../components/pipeLog/pipeLogTable";
import { getPipeLog, getUser } from "../store/users";
import { toast } from "react-toastify";
import AlertSuccess from "../common/alerts/alertSuccess";
import AlertError from "../common/alerts/alertError";

const PipeLog = (props) => {
  const ref = useRef(null);
  const [loading, setLoading] = useState(false);

  //params state
  const [page, setPage] = useState(1);
  const [auctionId, setAuctionId] = useState("");
  const [userId, setUserId] = useState("");
  const [limit, setLimit] = useState({ name: 50, value: 50 });
  const [selectedFilter, setSelectedFilter] = useState({});

  const [showFilter, setShowFilter] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState(false);

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  useEffect(() => {
    ref.current.continuousStart();
    setLoading(true);
    const params = {
      limit: limit.value,
      page: 1,
    };
    if (userId) {
      params.userId = userId;
    }
    if (auctionId) {
      params.auctionId = auctionId;
    }
    props.getPipeLog(params, (res) => {
      if (res && res.status === 200) {
        ref.current.complete();
        setLoading(false);
      } else {
        ref.current.complete();
        setLoading(false);
      }
    });
  }, []);

  //Data

  const userData =
    props.getUser && props.getUser.pipeLog && props.getUser.pipeLog.data;

  const filteredCount =
    props.getUser &&
    props.getUser.pipeLog &&
    props.getUser.pipeLog.filteredCount;

  useEffect(() => {
    ref.current.continuousStart();
    setLoading(true);
    const params = {
      limit: limit.value,
      page: page,
    };
    if (userId) {
      params.userId = userId;
    }
    if (auctionId) {
      params.auctionId = auctionId;
    }
    props.getPipeLog(params, (res) => {
      if (res && res.status === 200) {
        setLoading(false);
        ref.current.complete();
      } else {
        ref.current.complete();
        setLoading(false);
      }
    });
  }, [page]);

  const handleFilter = (
    isModalOpen = false,
    removeAuctionId = false,
    renoveUserId = false
  ) => {
    ref.current.continuousStart();
    setLoadingFilter(true);
    const filterValue = { ...selectedFilter };

    const params = {
      limit: limit.value,
      page: 1,
    };
    if (filterValue.userId && !renoveUserId) {
      params.userId = filterValue.userId;
    }
    if (filterValue.auctionId && !removeAuctionId) {
      params.auctionId = filterValue.auctionId;
    }
    props.getPipeLog(params, (res) => {
      if (res && res.status === 200) {
        setPage(1);

        ref.current.complete();
        if (filterValue.userId && !renoveUserId) {
          setUserId(filterValue.userId);
        }
        if (filterValue.auctionId && !removeAuctionId) {
          setAuctionId(filterValue.auctionId);
        }
        if (!isModalOpen) {
          toggleFilter();
        }
        setLoadingFilter(false);
      } else {
        toast(
          <AlertError
            message={
              res && res.data && res.data.message
                ? res.data.message
                : "Something Went Wrong"
            }
          />
        );
        ref.current.complete();
        setLoadingFilter(false);
      }
    });
  };
  return (
    <>
      <LoadingBar height={5} color="#47AD1D" ref={ref} />
      <Header />
      <PipeLogTable
        ref={ref}
        filteredCount={filteredCount}
        userData={userData}
        page={page}
        setPage={setPage}
        showFilter={showFilter}
        toggleFilter={toggleFilter}
        loading={loading}
        handleFilter={handleFilter}
        loadingFilter={loadingFilter}
        setLimit={setLimit}
        limit={limit}
        setAuctionId={setAuctionId}
        auctionId={auctionId}
        userId={userId}
        setUserId={setUserId}
        selectedFilter={selectedFilter}
        setSelectedFilter={setSelectedFilter}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getUserList: (params, callback) => dispatch(getUserList(params, callback)),
  getPipeLog: (params, callback) => dispatch(getPipeLog(params, callback)),
});

const mapStateToProps = (state) => ({
  userList: userListings(state),
  getUser: getUser(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(PipeLog));
