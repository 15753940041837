import React, { useEffect } from "react";
import { Offcanvas } from "react-bootstrap";
import Select from "react-select";
// import DatePicker from "react-datepicker";
import { customStyles } from "../../../../common/customStyles/reactSelectStyle";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextField } from "@mui/material";
import moment from "moment";

const FilterModal = (props) => {
  const weekoptions = [
    { name: "Select Week", value: "" },
    { name: "0 Week", value: 0 },
    { name: "1 Week", value: 1 },
    { name: "2 Weeks", value: 2 },
    { name: "3 Weeks", value: 3 },
    { name: "4 Weeks", value: 4 },
  ];
  const byOptions_1 = [
    { name: "Select Option", value: "" },
    { name: "Trader", value: "Trader" },
    { name: "Seller", value: "Seller" },
  ];
  const byOptions_2 = [
    { name: "Select Option", value: "" },
    { name: "Buyer", value: "Buyer" },
    { name: "Trader", value: "Trader" },
  ];

  return (
    <Offcanvas
      show={props.show}
      onHide={() => props.onHide()}
      placement="end"
      className="offcanvas offcanvas-end custom-offcanvas-sm "
      backdropClassName="upload-images-backdrop"
      tabindex="-1"
      id="userFilter"
      enforceFocus={false}
    >
      <Offcanvas.Header className="offcanvas-header aviation-filter-fix">
        <h5 class="offcanvas-title">Filters</h5>
        <button type="button" className="btn-close text-reset" onClick={() => props.onHide()}></button>
      </Offcanvas.Header>
      <Offcanvas.Body className="offcanvas-body co-body">
        <div class="form-group">
          <label for="keyword" class="form-label">
            Keyword
          </label>
          <input
            type="text"
            class="form-control"
            id="auction-search"
            value={props.keyword}
            onChange={(e) => {
              props.setKeyword(e.target.value);
            }}
          />
        </div>
        <div class="form-group">
          <label for="keyword" class="form-label">
            {props?.buyer ? "BARR Frequency" : "SARR Frequency"}
          </label>
          <Select
            styles={customStyles}
            value={weekoptions?.find((val) => val?.value === props?.frequency)}
            placeholder="Select Week"
            options={weekoptions}
            getOptionLabel={(val) => val.name}
            getOptionValue={(val) => val.value}
            onChange={(e) => {
              props?.setFrequency(e.value);
            }}
          />
        </div>
        {!props.keyBuyer && (
          <div class="form-group">
            <label for="keyword" class="form-label">
              Frequency By
            </label>
            <Select
              styles={customStyles}
              value={
                props?.buyer
                  ? byOptions_2?.find((val) => val?.value === props?.updatedBy)
                  : byOptions_1?.find((val) => val?.value === props?.updatedBy)
              }
              placeholder="Select Week"
              options={props?.buyer ? byOptions_2 : byOptions_1}
              getOptionLabel={(val) => val.name}
              getOptionValue={(val) => val.value}
              onChange={(e) => {
                props?.setUpdatedBy(e.value);
              }}
            />
          </div>
        )}
        <div class="form-group">
          <label for="keyword" class="form-label">
            Next Email Date
          </label>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              disableHighlightToday={true}
              className="form-control"
              value={props.nextDate}
              format="MM/DD/YYYY"
              onChange={(e) => {
                const date = moment(e).format("MM/DD/YYYY");
                props?.setNextDate(date);
              }}
              renderInput={(params) => <TextField {...params} error={false} />}
              inputFormat="MM/dd/yyyy"
            />
          </LocalizationProvider>
        </div>
        <div class="form-group">
          <label for="keyword" class="form-label">
            Last Email Date
          </label>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              disableHighlightToday={true}
              className="form-control"
              value={props.lastDate}
              format="MM/DD/YYYY"
              onChange={(e) => {
                const date = moment(e).format("MM/DD/YYYY");
                props?.setLastDate(date);
              }}
              renderInput={(params) => <TextField {...params} error={false} />}
              inputFormat="MM/dd/yyyy"
            />
          </LocalizationProvider>
        </div>
      </Offcanvas.Body>
      <div class="offcanvas-footer">
        <a
          href="javascript:void(0)"
          class={`btn btn-default btn-block ${props.loading && "disabled"}`}
          onClick={() => props.handleFilter()}
        >
          Apply Filters
        </a>
      </div>
    </Offcanvas>
  );
};

export default FilterModal;
