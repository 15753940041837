import React from "react";
import { numberFormat } from "../../../utils.js/conversion";

const EmailTypeActivity = (props) => {
  return (
    <div className="barr_email-list">
      <div className="d-flex space-between align-items-center">
        <p>{props?.name}</p>
        <span className="barr_email-counts" style={{ color: props?.bgColor }}>
          {props?.isHovered ? numberFormat(props?.count) : props?.percentage + "%"}
        </span>
      </div>
      <div class="barr_progress">
        <div class="barr_progress-bar " style={{ backgroundColor: props?.bgColor, width: props?.percentage }}></div>
      </div>
    </div>
  );
};

export default EmailTypeActivity;
