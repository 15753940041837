import React, { useEffect, useState } from "react";
import close from "../../../include/images/close.svg";
import { Offcanvas } from "react-bootstrap";
import moment from "moment";
import { capitalizeFirstLetter } from "../../../utils.js/conversion";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { customStyles } from "../../../common/customStyles/reactSelectStyle";
import { filter } from "lodash";

const ARRHistory = (props) => {
  const [optionValues, setOptionValues] = useState(props?.data);

  const options = Array.from(
    new Set(optionValues?.map((ye) => capitalizeFirstLetter(ye?.event === "clicks" ? "clicked" : ye?.event))),
  );

  useEffect(() => {
    if (!props?.show) {
      props?.setData([]);
      props?.setFilter({
        action: { name: "Select Action", value: "-1" },
        startDate: null,
        endDate: null,
      });
    }
  }, [props?.show]);

  return (
    <Offcanvas
      placement="end"
      backdropClassName="upload-documnets-backdrop"
      show={props.show}
      onHide={() => props.onHide()}
      className=" right-collapse fade modal-lg  offcanvas-pannel-container"
      dialogClassName="modal-dialog"
    >
      <Offcanvas.Header className="offcanvas-header" style={{ paddingLeft: "30px" }}>
        <h5 class="offcanvas-title"> {props?.type === "Buyer Report" ? "Buyer ARR Stats" : "Seller ARR Stats"}</h5>
        <button
          type="button"
          class="offcanvas-close-btn d-flex align-items-center justify-content-center"
          onClick={() => props.onHide()}
        >
          <img src={close} alt="" />
        </button>
      </Offcanvas.Header>
      <Offcanvas.Body className="custom-modal-body flex-grow-1 w-100 overflow-scroll" data-simplebar>
        <div className="d-block mb-3">
          <p>Email : {props?.email}</p>
        </div>

        <div class="form-group filter-user-input-width d-block mb-3">
          <div class="form-group filter-user-input-width d-flex " style={{ width: "50%", marginTop: "0px" }}>
            <div class="form-group">
              <label for="keyword" class="form-label">
                Date Range
              </label>
              <Select
                options={[
                  {
                    name: "Yesterday",
                    value: {
                      from: moment().subtract(1, "days").format("YYYY-MM-DD"),
                      to: moment().format("YYYY-MM-DD"),
                    },
                  },
                  {
                    name: "Last 7 days",
                    value: {
                      from: moment().subtract(7, "days").format("YYYY-MM-DD"),
                      to: moment().format("YYYY-MM-DD"),
                    },
                  },
                  {
                    name: "Last 14 days",
                    value: {
                      from: moment().subtract(14, "days").format("YYYY-MM-DD"),
                      to: moment().format("YYYY-MM-DD"),
                    },
                  },
                  {
                    name: "Last 30 days",
                    value: {
                      from: moment().subtract(30, "days").format("YYYY-MM-DD"),
                      to: moment().format("YYYY-MM-DD"),
                    },
                  },

                  { name: "Custom Date", value: "custom" },
                ]}
                className="basic-single country-margin select-background "
                classNamePrefix="select-search country-margin select-background"
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.value}
                name="BARR"
                placeholder="Select Date Range"
                styles={customStyles}
                value={props?.filter?.dateRange}
                onChange={(e) => {
                  const value = { ...props?.filter };
                  value.dateRange = e;
                  if (e.name !== "Custom Date") {
                    value.startDate = e.value?.from;
                    value.endDate = e.value?.to;
                  }
                  props?.setFilter(value);
                }}
              />
            </div>
          </div>
          {props?.filter?.dateRange?.name === "Custom Date" ? (
            <div class="form-group filter-user-input-width d-flex " style={{ width: "100%", marginTop: "15px" }}>
              <div style={{ width: "47%" }}>
                <label for="keyword" class="form-label">
                  From
                </label>
                <DatePicker
                  className="form-control filter-user-input-width "
                  selected={props.filter?.startDate ? moment(props.filter?.startDate, "YYYY-MM-DD").toDate() : ""}
                  onChange={(date) => {
                    const value = { ...props.filter };
                    value.dateRange = { name: "Custom Date", value: "custom" };
                    value.startDate = moment(date)?.format("YYYY-MM-DD");
                    props.setFilter(value);
                  }}
                />
                {!props.filter?.startDate && props.filter?.endDate ? (
                  <p className="error-text">From is required</p>
                ) : (
                  ""
                )}
              </div>
              <div style={{ width: "47%", marginLeft: "30px" }}>
                <label for="keyword" class="form-label">
                  To
                </label>
                <DatePicker
                  className="form-control filter-user-input-width "
                  selected={props.filter?.endDate ? moment(props.filter?.endDate, "YYYY-MM-DD").toDate() : ""}
                  onChange={(date) => {
                    const value = { ...props.filter };
                    value.dateRange = { name: "Custom Date", value: "custom" };
                    value.endDate = moment(date)?.format("YYYY-MM-DD");
                    props.setFilter(value);
                  }}
                />
                {props.filter?.startDate && !props.filter?.endDate ? <p className="error-text">To is required</p> : ""}
              </div>
            </div>
          ) : (
            ""
          )}
          <div class="page-cat-item " style={{ maxWidth: "fit-content", marginTop: "15px" }}>
            <div class={`page-cat-box `}>
              Date Range :{" "}
              {props?.filter?.dateRange?.name !== "Custom Date"
                ? props?.filter?.dateRange?.name
                : `${props?.filter?.startDate} - ${props?.filter?.endDate}`}
            </div>
          </div>
        </div>
        <div className="table-responsive  table-custom">
          <table className="table table-bordered">
            <colgroup>
              <col />
              <col />
              <col />
            </colgroup>
            <thead>
              <tr>
                <th>Action</th>
                <th>Clicked Link</th>
                <th>Date Sent</th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              {props?.show && props?.data?.length > 0 ? (
                props?.data?.map((item) => (
                  <tr>
                    <td>
                      {item?.event ? (item?.event === "clicks" ? "Clicked" : capitalizeFirstLetter(item?.event)) : ""}
                    </td>
                    <td>
                      {item?.link ? (
                        <a className="link-item" target="_blank" href={item?.link}>
                          Link
                        </a>
                      ) : (
                        ""
                      )}
                    </td>
                    <td>{item?.date ? moment?.(item?.date?.split("T")?.[0]).format("MM-DD-YYYY") : ""}</td>
                    <td onClick={() => props?.fetchEmailContent(item?.email, item?.messageId)}>
                      <a className="link-item" href={"javascript:void(0)"}>
                        View Email
                      </a>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={3}>
                    <div className="d-flex justify-content-center align-items-center">
                      <span>No record found</span>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default ARRHistory;
