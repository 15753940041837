import React, { useEffect, useState } from "react";
import Select from "react-select";
import { customStyles } from "../../../common/customStyles/reactSelectStyle";
import { connect } from "react-redux";
import { completedResearchAssignmentRecieved } from "../../../store/dashBoard";

const CommodityTrader = ({ setEmpId, setPt, setPl, setShowOnly, dashboardData, ...props }) => {
  const [selected, setSelected] = useState({});
  const [ptSelected, setPtSelected] = useState({
    name: "-- Show All --",
    value: 0,
  });
  const [plSelected, setPlSelected] = useState({
    name: "-- Show All --",
    value: 0,
  });
  const [showOnlySelected, setShowOnlySelected] = useState({
    name: "-- Show All --",
    value: 0,
  });

  const val = localStorage.getItem("data");
  const session = JSON.parse(val);

  useEffect(() => {
    if (session.member_id) {
      setSelected(props?.data?.find((option) => option.member_id === session.member_id));
    } else {
      setSelected({
        name01: "Select One",
        member_id: 0,
      });
    }
  }, [props.data]);

  const ptOption = [
    { name: "-- Show All --", value: 0 },
    { name: "Include Only", value: 1 },
    { name: "Exclude", value: 2 },
  ];
  const plOption = [
    { name: "-- Show All --", value: 0 },
    { name: "Include Only", value: 1 },
    { name: "Exclude", value: 2 },
  ];
  const showOnlyOption = [
    { name: "-- Show All --", value: 0 },
    { name: "Trader", value: 1 },
    { name: "BD", value: 2 },
    { name: "Trader Manager", value: 3 },
    { name: "BD Manager", value: 4 },
    { name: "Mid Manager", value: 5 },
  ];

  return (
    <div class="details-page-widget-block">
      <div class="page-widget-head d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <div class="page-widget-label text-capitalize">Select Commodity Trader</div>
        </div>
      </div>
      <div class="page-widget-body mt-3">
        <div className="filter-section d-flex">
          <div style={{ width: "200px" }}>
            <Select
              placeholder="Select One"
              styles={customStyles}
              value={selected}
              options={[{ name01: "Select an Option", member_id: 0 }, ...props.data]}
              getOptionLabel={(option) => option.name01}
              getOptionValue={(option) => option.member_id}
              onChange={(e) => {
                const data = { ...selected };
                data.name01 = e.name01;
                data.member_id = e.member_id;
                setSelected(data);
                setEmpId(e.member_id);
              }}
            />
          </div>
          <div className="ms-3 d-flex">
            <span className="fw-medium mt-2 me-2">Publicly Traded:</span>
            <div style={{ width: "200px" }}>
              <Select
                placeholder="-- Show All --"
                value={ptSelected}
                styles={customStyles}
                options={ptOption}
                onChange={(e) => {
                  const data = { ...ptSelected };
                  data.name = e.name;
                  data.value = e.value;
                  setPt(e.value);
                  setPtSelected(data);
                }}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.value}
              />
            </div>
          </div>
          <div className="ms-3 d-flex">
            <span className="fw-medium mt-2 me-2">Project Listing:</span>
            <div style={{ width: "200px" }}>
              <Select
                placeholder="-- Show All --"
                styles={customStyles}
                value={plSelected}
                options={plOption}
                onChange={(e) => {
                  const data = { ...plSelected };
                  data.name = e.name;
                  data.value = e.value;
                  setPl(e.value);
                  setPlSelected(data);
                }}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.value}
              />
            </div>
          </div>
          <div className="ms-3 d-flex">
            <span className="fw-medium mt-2 me-2">Show Only:</span>
            <div style={{ width: "200px" }}>
              <Select
                placeholder="-- Show All --"
                styles={customStyles}
                value={showOnlySelected}
                options={showOnlyOption}
                onChange={(e) => {
                  const data = { ...showOnlySelected };
                  data.name = e.name;
                  data.value = e.value;
                  setShowOnly(e.value);
                  setShowOnlySelected(data);
                }}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.value}
              />
            </div>
          </div>
        </div>
        <div className="filter-section mt-3 d-flex">
          <div className="d-flex">
            <span className="fw-medium me-2">Sort By:</span>
            <div className="d-flex">
              <div className="form-check radio pe-2">
                <input
                  className="form-check-input cursor-pointer"
                  value={2}
                  type="radio"
                  name="radioSortBy"
                  id="radioCatogory"
                  checked={props.sortBy == 2}
                  onChange={(e) => {
                    props.setSortBy(e.target.value);
                  }}
                />
                <label className="form-check-label" htmlFor="radioCategory">
                  Category
                </label>
              </div>
              <div className="form-check radio pe-2">
                <input
                  className="form-check-input cursor-pointer"
                  value={3}
                  type="radio"
                  name="radioSortBy"
                  id="radioSubCat"
                  checked={props.sortBy == 3}
                  onChange={(e) => {
                    props.setSortBy(e.target.value);
                  }}
                />
                <label className="form-check-label" htmlFor="radioSubCat">
                  Sub-Category
                </label>
              </div>
              <div className="form-check radio pe-2">
                <input
                  className="form-check-input cursor-pointer"
                  value={4}
                  type="radio"
                  name="radioSortBy"
                  id="radioRegion"
                  checked={props.sortBy == 4}
                  onChange={(e) => {
                    props.setSortBy(e.target.value);
                    props.setPushYesNo(0);
                  }}
                />
                <label className="form-check-label" htmlFor="radioRegion">
                  Region
                </label>
              </div>
              <div className="form-check radio pe-2">
                <input
                  className="form-check-input cursor-pointer"
                  value={8}
                  type="radio"
                  name="radioSortBy"
                  id="radioTradeGroup"
                  checked={props.sortBy == 8}
                  onChange={(e) => {
                    props.setSortBy(e.target.value);
                    props.setPushYesNo(0);
                  }}
                />
                <label className="form-check-label" htmlFor="radioTradeGroup">
                  Trade Group
                </label>
              </div>
              <div className="form-check radio pe-2">
                <input
                  className="form-check-input cursor-pointer"
                  value={1}
                  type="radio"
                  name="radioSortBy"
                  id="radioIndusty"
                  checked={props.sortBy == 1}
                  onChange={(e) => {
                    props.setSortBy(e.target.value);
                    props.setPushYesNo(0);
                  }}
                />
                <label className="form-check-label" htmlFor="radioIndusty">
                  Industry
                </label>
              </div>
              <div className="form-check radio pe-2">
                <input
                  className="form-check-input cursor-pointer"
                  value={9}
                  type="radio"
                  name="radioSortBy"
                  id="radioSubInd"
                  checked={props.sortBy == 9}
                  onChange={(e) => {
                    props.setSortBy(e.target.value);
                    props.setPushYesNo(0);
                  }}
                />
                <label className="form-check-label" htmlFor="radioSubInd">
                  Sub-Industry
                </label>
              </div>
              <div className="form-check radio pe-2">
                <input
                  className="form-check-input cursor-pointer"
                  value={6}
                  type="radio"
                  name="radioSortBy"
                  id="radioEnterprise"
                  checked={props.sortBy == 6}
                  onChange={(e) => {
                    props.setSortBy(e.target.value);
                    props.setPushYesNo(0);
                  }}
                />
                <label className="form-check-label" htmlFor="radioEnterprise">
                  Enterprise
                </label>
              </div>
              <div className="form-check radio pe-2">
                <input
                  className="form-check-input cursor-pointer"
                  value={7}
                  type="radio"
                  name="radioSortBy"
                  id="radioSeller"
                  checked={props.sortBy == 7}
                  onChange={(e) => {
                    props.setSortBy(e.target.value);
                    props.setPushYesNo(0);
                  }}
                />
                <label className="form-check-label" htmlFor="radioSeller">
                  Seller
                </label>
              </div>
              <div className="form-check radio pe-2">
                <input
                  className="form-check-input cursor-pointer"
                  value={5}
                  type="radio"
                  name="radioSortBy"
                  id="radioManufacturer"
                  checked={props.sortBy == 5}
                  onChange={(e) => {
                    props.setSortBy(e.target.value);
                    props.setPushYesNo(0);
                  }}
                />
                <label className="form-check-label" htmlFor="radioManufacturer">
                  Manufacturer
                </label>
              </div>
              <div className="form-check radio pe-2">
                <input
                  className="form-check-input cursor-pointer"
                  value={0}
                  type="radio"
                  name="radioSortBy"
                  id="radioNeither"
                  checked={props.sortBy == 0}
                  onChange={(e) => {
                    props.setSortBy(e.target.value);
                    props.setPushYesNo(0);
                  }}
                />
                <label className="form-check-label" htmlFor="radioNeither">
                  Neither
                </label>
              </div>
            </div>
          </div>
        </div>
        {(props.sortBy == 2 || props.sortBy == 3) && (
          <div className="filter-section mt-3 d-flex">
            <div className="d-flex">
              <span className="fw-medium me-2">Include Push Categories:</span>
              <div className="d-flex">
                <div className="form-check radio pe-2">
                  <input
                    className="form-check-input cursor-pointer"
                    value={1}
                    type="radio"
                    name="flexRadioPushCategory"
                    id="radioYes"
                    checked={props.pushYesNo === 1}
                    onChange={(e) => {
                      props.setPushYesNo(1);
                    }}
                  />
                  <label className="form-check-label" htmlFor="radioYes">
                    Yes
                  </label>
                </div>
                <div className="form-check radio pe-2">
                  <input
                    className="form-check-input cursor-pointer"
                    value={0}
                    type="radio"
                    name="flexRadioPushCategory"
                    id="radioNo"
                    checked={props.pushYesNo === 0}
                    onChange={(e) => {
                      props.setPushYesNo(0);
                    }}
                  />
                  <label className="form-check-label" htmlFor="radioNo">
                    No
                  </label>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="filter-section mt-3 d-flex">
          <div className="d-flex">
            <span className="fw-medium me-2">View Type:</span>
            <div className="d-flex">
              <div className="form-check radio pe-2">
                <input
                  className="form-check-input cursor-pointer"
                  value={0}
                  type="radio"
                  name="flexRadioDefault"
                  id="radioFull" // Unique ID for the "Full" option
                  checked={props.viewType === 0}
                  onChange={(e) => {
                    props.setViewType(0);
                  }}
                />
                <label className="form-check-label" htmlFor="radioFull">
                  Full
                </label>
              </div>
              <div className="form-check radio pe-2">
                <input
                  className="form-check-input cursor-pointer"
                  value={1}
                  type="radio"
                  name="flexRadioDefault"
                  id="radioList" // Unique ID for the "List" option
                  checked={props.viewType === 1}
                  onChange={(e) => {
                    props.setViewType(1);
                  }}
                />
                <label className="form-check-label" htmlFor="radioList">
                  List
                </label>
              </div>
              <div className="form-check radio pe-2">
                <input
                  className="form-check-input cursor-pointer"
                  value={2}
                  type="radio"
                  name="flexRadioDefault"
                  id="radioMgrView" // Unique ID for the "Manager View" option
                  checked={props.viewType === 2}
                  onChange={(e) => {
                    props.setViewType(2);
                  }}
                />
                <label className="form-check-label" htmlFor="radioMgrView">
                  Manager View
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => ({
  completedResearchAssignmentRecieved: (data) => dispatch(completedResearchAssignmentRecieved(data)),
});
const mapStateToProps = (data) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(React.memo(CommodityTrader));
