import React, { useEffect, useRef, useState } from "react";
import Header from "../layouts/header";
import LoadingBar from "react-top-loading-bar";
import UserReport from "../components/users/userReport";
import Sidebar from "../layouts/sidebar";

const Reports = () => {
  const ref = useRef(null);

  //For SideNav
  const [showMenu, setShowMenu] = useState(false);
  const [menuList, setMenuList] = useState("");

  return (
    <>
      <LoadingBar height={5} color="#47AD1D" ref={ref} />
      <div className={`page-wrapper-outer grey-bg `}>
        <Header />
        <div class="dashboard-insider position-relative d-lg-flex w-100 ">
          <Sidebar
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            menuList={menuList}
            setMenuList={setMenuList}
            page="reports"
            pageCat="registration-report"
          />
          <div
            className={
              showMenu ? ` main-menu-expanded ` : "main-menu-compressed"
            }
          >
            <UserReport
              refStart={ref && ref.current && ref.current.continuousStart}
              refStop={ref && ref.current && ref.current.complete}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Reports;
