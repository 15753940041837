import React, { useState } from "react";
import { Offcanvas } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { customStyles } from "../../../common/customStyles/reactSelectStyle";
import moment from "moment";
const BARRFilters = (props) => {
  const [updateFilterValues, setUpdateFilterValues] = useState(props?.data);

  return (
    <Offcanvas
      show={props.show}
      onHide={() => {
        props.onHide();
      }}
      placement="end"
      className="offcanvas offcanvas-end custom-offcanvas-sm "
      backdropClassName="upload-images-backdrop"
      tabindex="-1"
      id="userFilter"
      enforceFocus={false}
    >
      <Offcanvas.Header className="offcanvas-header ">
        <h5 class="offcanvas-title" style={{ marginLeft: "-9px" }}>
          Filters
        </h5>
        <button
          type="button"
          className="btn-close text-reset"
          onClick={() => {
            props.onHide();
          }}
        ></button>
      </Offcanvas.Header>
      <Offcanvas.Body className="offcanvas-body co-body">
        <div class="form-group filter-user-input-width d-flex ">
          <div style={{ width: "100%" }}>
            <label for="keyword" class="form-label">
              Search
            </label>
            <input
              type="text"
              className="form-control filter-user-input-width "
              value={updateFilterValues?.search}
              onChange={(e) => {
                const value = { ...updateFilterValues };
                value.search = e.target.value;
                setUpdateFilterValues(value);
              }}
            />
          </div>
        </div>
        <div class="form-group filter-user-input-width d-flex ">
          <div style={{ width: "50%" }}>
            <label for="keyword" class="form-label">
              Date Range
            </label>
            <Select
              className="basic-single"
              classNamePrefix="select-search "
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.value}
              name={"buyerArrDateRange"}
              options={[
                {
                  name: "Yesterday",
                  value: { from: moment().subtract(1, "days").format("YYYY-MM-DD"), to: moment().format("YYYY-MM-DD") },
                },
                {
                  name: "Last 7 days",
                  value: { from: moment().subtract(7, "days").format("YYYY-MM-DD"), to: moment().format("YYYY-MM-DD") },
                },
                {
                  name: "Last 14 days",
                  value: {
                    from: moment().subtract(14, "days").format("YYYY-MM-DD"),
                    to: moment().format("YYYY-MM-DD"),
                  },
                },
                {
                  name: "Last 30 days",
                  value: {
                    from: moment().subtract(30, "days").format("YYYY-MM-DD"),
                    to: moment().format("YYYY-MM-DD"),
                  },
                },

                { name: "Custom Date", value: "custom" },
              ]}
              placeholder="Select Date Range"
              styles={customStyles}
              value={updateFilterValues?.dateRange}
              onChange={(e) => {
                const value = { ...updateFilterValues };
                value.dateRange = e;
                if (e.value !== "custom") {
                  value.dateRangeValue.value = e.value;
                }

                setUpdateFilterValues(value);
              }}
            />
          </div>
        </div>
        {updateFilterValues?.dateRange?.name === "Custom Date" ? (
          <div class="form-group filter-user-input-width d-flex ">
            <div style={{ width: "47%" }}>
              <label for="keyword" class="form-label">
                Start Date (From)
              </label>
              <DatePicker
                className="form-control filter-user-input-width "
                selected={
                  updateFilterValues?.dateRangeValue?.value?.from
                    ? moment(updateFilterValues?.dateRangeValue?.value?.from, "YYYY-MM-DD").toDate()
                    : ""
                }
                onChange={(date) => {
                  const value = { ...props.data };
                  value.dateRange = { name: "Custom Date", value: "custom" };
                  value.dateRangeValue.value.from = moment(date)?.format("YYYY-MM-DD");
                  setUpdateFilterValues(value);
                }}
              />
              {!updateFilterValues?.dateRangeValue.value.from && updateFilterValues?.dateRangeValue.value.to ? (
                <p className="error-text">From is required</p>
              ) : (
                ""
              )}
            </div>
            <div style={{ width: "47%", marginLeft: "30px" }}>
              {" "}
              <label for="keyword" class="form-label">
                End Date (To)
              </label>
              <DatePicker
                className="form-control filter-user-input-width "
                selected={
                  updateFilterValues?.dateRangeValue?.value.to
                    ? moment(updateFilterValues?.dateRangeValue?.value.to, "YYYY-MM-DD").toDate()
                    : ""
                }
                onChange={(date) => {
                  const value = { ...updateFilterValues };
                  value.dateRange = { name: "Custom Date", value: "custom" };
                  value.dateRangeValue.value.to = moment(date)?.format("YYYY-MM-DD");
                  setUpdateFilterValues(value);
                }}
              />
              {updateFilterValues?.dateRangeValue?.value.from && !updateFilterValues?.dateRangeValue?.value.to ? (
                <p className="error-text">To is required</p>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          ""
        )}
      </Offcanvas.Body>
      <div class="offcanvas-footer">
        <a
          href="javascript:void(0)"
          class={`btn btn-default btn-block `}
          onClick={() => {
            props.setData(updateFilterValues);
            props?.onHide();
          }}
        >
          Apply Filters
        </a>
      </div>
    </Offcanvas>
  );
};

export default React.memo(BARRFilters);
