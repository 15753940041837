import React, { useRef } from "react";
import { Offcanvas, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import sendImg from "../../../include/images/send.svg";
import Skeleton from "react-loading-skeleton";
import { getProfile } from "../../../utils.js/localStorageServices";
import { useEffect } from "react";
import { connect } from "react-redux";
import { useState } from "react";
import close from "../../../include/images/close-12x12.svg";
import paperClip from "../../../include/images/paperclip.svg";
import { Amplify, Storage } from "aws-amplify";
import { Auth } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import AlertError from "../../../common/alerts/alertError";
import fileIcon from "../../../include/images/file.svg";
import refreshIcon from "../../../include/images/Refresh.svg";
import mp4 from "../../../include/images/mp4.svg";
import More from "../../../include/images/more-icon.svg";
import {
  addReminderQuestion,
  getAllUnreadCount,
  getConversation,
  marAsReadConversation,
  markAsUnread,
  questionDetails,
  updateQuestion,
  updateReminderQuestion,
} from "../../../store/questions";
import { ConvertLocalToCST, ConvertTime } from "../../../common/DateFormat/cstToLocalTime";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import { getProductCount } from "../../../store/listingProduct";
import Editor from "../../../common/editor/editor";
import parse from "html-react-parser";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import ReminderDate from "../reminderMessages/reminderDate";

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    region: "us-east-1",
  },
  Storage: {
    bucket: process.env.REACT_APP_S3_UPLOAD_BUCKET,
    region: "us-east-1",
  },
});

const ChatMessages = (props) => {
  const ref = useRef(null);
  const member = getProfile();
  const chatMessage = props?.questionDetails?.conversation?.data;
  const [message, setMessage] = React.useState("");
  const [messageList, setMessageList] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const [media, setMedia] = useState([]);

  //new states
  const [loadingFileUpload, setLoadingFileUpload] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState([]);
  const [loadAgain, setLoadAgain] = useState(true);
  const [selectedToUpload, setSelectedToUpload] = useState(0);
  const [messageLoading, setMessageLoading] = React.useState(false);
  const [editorLoaded, setEditorLoaded] = useState(false);

  const [showReminder, setShowReminder] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);

  const loadConversationList = () => {
    ref?.current?.continuousStart();
    setMessageLoading(true);
    props?.getConversation(props?.selectedChat?.buyers_conversation_on_auction_key, (res) => {
      if (res && res.status === 200) {
        props?.getAllUnreadCount((res) => {});
        if (res?.data?.unread_ids?.length > 0) {
          props?.marAsReadConversation({ unread_ids: res?.data?.unread_ids }, (res3) => {
            if (res3?.status === 200) {
              props?.getAllUnreadCount((res) => {});
            } else {
              ref?.current?.complete();
              toast(<AlertError message={res?.data?.response ? res?.data?.response : "Something went wrong"} />);
            }
          });
        }
        ref?.current?.complete();
        setMessageList(res?.data?.data);
        setLoadAgain(false);
        setMessageLoading(false);
      } else {
        ref?.current?.complete();
        setMessageLoading(false);
        toast(<AlertError message={res?.data?.response ? res?.data?.response : "Something went wrong"} />);
      }
    });
  };

  useEffect(() => {
    if (props?.show) {
      setEditorLoaded(true);
    }
    setMessage("");
    setMedia([]);
    setUploadedFileName([]);
    setLoadAgain(true);
    props.getProductCount(props?.auctionId, (res) => {
      if (res.status === 200) {
      }
    });
    props?.getAllUnreadCount((res) => {});
  }, [props?.show]);

  useEffect(() => {
    if (props?.show && loadAgain) {
      loadConversationList();
    }
    if (props?.show) {
      const chatLoader = setInterval(() => {
        props?.getConversation(props?.selectedChat?.buyers_conversation_on_auction_key, (res) => {
          if (res && res.status === 200) {
            props.getProductCount(props?.auctionId, (res) => {
              if (res.status === 200) {
              }
            });

            if (res?.data?.unread_ids?.length > 0) {
              props?.marAsReadConversation({ unread_ids: res?.data?.unread_ids }, (res3) => {
                if (res3?.status === 200) {
                  props?.getAllUnreadCount((res) => {});
                } else {
                  ref?.current?.complete();
                  toast(<AlertError message={res?.data?.response ? res?.data?.response : "Something went wrong"} />);
                }
              });
            }

            setMessageList(res?.data?.data);
          } else {
            toast(<AlertError message={res?.data?.response ? res?.data?.response : "Something went wrong"} />);
          }
        });
      }, 5000 * 60);
      return () => clearInterval(chatLoader);
    }
  }, [props?.show, loadAgain]);

  const pasteImg = async () => {
    try {
      const clipboardItems = await navigator.clipboard.read();
      if (clipboardItems[0]) {
        const blobOutput = await clipboardItems[0].getType("image/png");
        // const data = URL.createObjectURL(blobOutput);
        var file = new File([blobOutput], "image.png", { type: "image/png" });
        const a = [...media, file];
        uploadChatMedia([file]);
        setMedia(a);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      const objDiv = document.getElementById("chatPannelBody1");
      if (objDiv) objDiv.scrollTop = objDiv.scrollHeight + 1000;
    }, 100);
  }, [messageList]);

  const uploadChatMedia = (med) => {
    if (med) {
      setSelectedToUpload(med?.length);
      setLoadingFileUpload(true);
      const a = [];
      for (let i = 0; i < med.length; i++) {
        ref?.current?.continuousStart();
        const file = med[i];

        const fSize = Math.round(file.size / 1048576);
        const fType = file.type;
        const ext = file.name.split(".").pop();
        if (fSize > 250) {
          return (
            toast(<AlertError message="Media size exceeds maximum allowable size. Maximum allowable size is 250MB." />),
            setSelectedToUpload(0),
            setLoadingFileUpload(false),
            ref?.current?.complete()
          );
        } else if (
          ![
            "image/jpeg",
            "image/jpg",
            "image/png",
            "video/MP4",
            "video/mp4",
            "video/webm",
            "video/mpeg",
            "application/pdf",
            "text/csv",
            "application/vnd.ms-excel",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          ].includes(fType)
        ) {
          return (
            toast(
              <AlertError message="Media is not of correct format and hence cannot be send. Valid image formats are jpeg, jpg, png, mp4, webm, mpeg, pdf, xlsx, xls and csv." />,
            ),
            setSelectedToUpload(0),
            setLoadingFileUpload(false),
            ref?.current?.complete()
          );
        } else {
          const fileName = `chat/${props?.selectedChat?.member_id}/${member?.user_id}/` + uuidv4() + `/${file?.name}`;

          a.push(
            Storage.put(fileName, file, {
              completeCallback: (event) => {},
              progressCallback: (progress) => {},
              errorCallback: (err) => {},
            }),
          );
        }
      }
      const allPromise = Promise.all([...a]);
      ref?.current?.complete();
      allPromise.then((result) => {
        setUploadedFileName((prev) => [...prev, ...result?.map((ir) => ir?.key)]);
        const a = [...media, ...med];
        setMedia(a);
        setSelectedToUpload(0);
        setLoadingFileUpload(false);
      });
    }
  };

  const handleSend = async () => {
    if (!loading && (message?.length > 0 || uploadedFileName?.length > 0)) {
      props?.refStart();
      setLoading(true);

      const data = {};
      data.response = message ? message : uploadedFileName?.length > 0 ? "File(s) Attached." : "";
      data.q_id = props?.selectedChat?.q_id ? props?.selectedChat?.q_id : "";
      data.mm_name = member.name01 ? member.name01 : "";
      data.mm_email = member.email ? member.email : "";
      data.email = props?.selectedChat?.user_email ? props?.selectedChat?.user_email : "";
      if (uploadedFileName?.length > 0) {
        data.files = [...uploadedFileName];
      }
      const res = await props?.updateQuestion(props?.selectedChat?.auction_id, data, (res) => {
        if (res.status === 200) {
          data.message = message ? message : uploadedFileName?.length > 0 ? "File(s) Attached." : "";
          data.isQuestion = false;
          data.date_created = ConvertLocalToCST(Date.now());
          data.files = uploadedFileName?.map((ie) => ({
            file_url: ie,
          }));
          const updatedData = [...messageList, data];
          setMessage("");
          setMessageList(updatedData);
          setUploadedFileName([]);
          if (media?.length > 0) {
            setMedia([]);
          }
          setLoading(false);
          props?.refStop();
        } else {
          setLoading(false);
          props?.refStop();
          toast.error("Something went wrong !", { theme: "colored" });
        }
      });
    }
  };

  const setReminderQuestion = () => {
    if (!loading) {
      setLoading(true);
      props?.refStart();
      props?.addReminderQuestion(
        {
          q_ids: [selectedMessage?.q_id],
          date: selectedMessage?.reminderDate,
        },
        (res) => {
          if (res && res?.status === 200) {
            props?.refStop();
            setLoading(false);
            setShowReminder(false);
            toast(<AlertSuccess message={"Reminder added"} />);
          } else {
            props?.refStop();
            setLoading(false);
            toast(<AlertError message={res?.data?.message ? res?.data?.message : "Something went wrong"} />);
          }
        },
      );
    }
  };

  const MarkAsUnread = () => {
    if (!loading) {
      setLoading(true);
      props?.refStart();
      props?.markAsUnread(
        {
          read_ids: [selectedMessage?.conversation_id],
        },
        (res) => {
          if (res && res?.status === 200) {
            setSelectedMessage(null);
            props.onHide();
            props?.getAllUnreadCount((res2) => {});

            props?.refStop();
            setLoading(false);
            setShowReminder(false);
          } else {
            props?.refStop();
            setLoading(false);
            toast(<AlertError message={res?.data?.message ? res?.data?.message : "Something went wrong"} />);
          }
        },
      );
    }
  };

  useEffect(() => {
    return () => {
      // Clean up any lingering backdrops
      const backdrops = document.querySelectorAll(".offcanvas-backdrop");
      backdrops.forEach((backdrop) => backdrop.remove());
    };
  }, [props?.show]);

  return (
    <Offcanvas
      placement="end"
      className=" fade right-collapse modal-lg chat-screen"
      backdropClassName="upload-documnets-backdrop"
      dialogClassName="modal-dialog modal-dialog-centered "
      show={props.show}
      onHide={() => props.onHide()}
      backdrop={"static"}
    >
      <Offcanvas.Header className="chatbox-header d-flex align-items-center">
        <div class="ch-member-box position-relative d-flex align-items-center">
          <div
            class="back-btn position-absolute cursor-pointer"
            onClick={() => {
              props.setMessageSection(false);
              props.onHide();
            }}
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M15 18L9 12L15 6"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div
            class="cmb-img chat-cmb custom-profile"
            style={{ backgroundColor: props?.selectedChat?.chat_color ? props?.selectedChat?.chat_color : "#47ad1d" }}
          >
            <p className="chat-dp">
              {props?.selectedChat?.user_name?.split(" ")[0] && props?.selectedChat?.user_name?.split(" ")[0][0]}
              {props?.selectedChat?.user_name?.split(" ")[1] && props?.selectedChat?.user_name?.split(" ")[1][0]}
            </p>
          </div>
          <div class="cmb-text-box">
            <p style={{ fontSize: "14px", marginBottom: "0px", fontWeight: "500" }}>{props?.selectedChat?.user_name}</p>
            <p style={{ fontSize: "12px", fontWeight: "500" }}>
              <span className="gray-color-text">{props?.selectedChat?.user_email}</span>
              <span
                className="gray-color-text"
                style={{ borderLeft: "1px solid #efefef", paddingLeft: "5px", paddingLeft: "9px", marginLeft: "9px" }}
              >
                {props?.selectedChat?.user_phone}
              </span>
            </p>
          </div>
        </div>
        <div
          className="cursor-pointer ms-auto me-3"
          onClick={() => setLoadAgain(true)}
          data-tip={"Click here to load latest messages"}
        >
          <img src={refreshIcon} alt="refresh-icon" />
          <ReactTooltip effect="solid" place="top" type="dark" border={true} />
        </div>

        <button
          type="button"
          class="offcanvas-close-btn d-flex align-items-center justify-content-center"
          onClick={() => {
            props.setMessageSection(false);
            props.onHide();
          }}
        >
          <img src={close} alt="" />
        </button>
      </Offcanvas.Header>

      <Offcanvas.Body className="chatbox-body" data-simplebar id="chatPannelBody1">
        {messageLoading
          ? [1, 2, 3, 4].map((item) => (
              <>
                {" "}
                <div
                  class="message received d-flex flex-column position-relative mt-auto mb-2"
                  style={{ paddingLeft: "0px" }}
                >
                  <div class="content" style={{ background: "transparent" }}>
                    <Skeleton height="40px" />
                  </div>
                </div>
                <div
                  class="message mb-2 send d-flex flex-column position-relative"
                  style={{ width: "100%", paddingRight: "0px" }}
                >
                  <div class="content" style={{ background: "transparent" }}>
                    <Skeleton height="40px" width="350px" />
                  </div>
                </div>
              </>
            ))
          : messageList &&
            messageList.length > 0 &&
            messageList?.map((chatMassage) => (
              <>
                {!chatMassage?.isQuestion ? (
                  chatMassage?.files?.length > 0 ? (
                    <>
                      <div class="message d-flex flex-column align-items-start position-relative send w-100 chat-send">
                        <div class="m-content">
                          <p className="ck-content">{parse(chatMassage?.message)}</p>
                        </div>

                        <div class="m-text-xs d-flex align-items-center">{ConvertTime(chatMassage.date_created)}</div>
                      </div>
                      {chatMassage?.files?.map((it) => {
                        const fileType = it?.file_url?.split(".")?.pop();
                        return (
                          <>
                            <a
                              href={process.env.REACT_APP_CHAT_MEDIA + "public/" + it?.file_url}
                              target="_blank"
                              class="message d-flex flex-column align-items-start position-relative send pb-3 w-100"
                            >
                              {["jpeg", "jpg", "png"].includes(fileType) ? (
                                <img
                                  style={{ height: "60px" }}
                                  src={process.env.REACT_APP_CHAT_MEDIA + "public/" + it?.file_url}
                                  alt=""
                                />
                              ) : ["mp4", "MP4", "mpeg", "webm"].includes(fileType) ? (
                                <div class="media-widget-box position-relative">
                                  <div class="media-widget-file-icon d-flex align-items-center justify-content-center">
                                    <img src={mp4} alt="" />
                                  </div>
                                  <div class="media-widget-content">
                                    <div class="media-intro">
                                      <div class="media-into d-flex flex-nowrap">
                                        <span>{it?.file_url?.split("/")?.[4]?.substring(0, 5) + "..."}</span>
                                      </div>
                                    </div>
                                    <div class="file-size">{fileType}</div>
                                  </div>
                                </div>
                              ) : ["csv", "xlsx", "xls", "pdf"].includes(fileType) ? (
                                <div class="media-widget-box position-relative">
                                  <div class="media-widget-file-icon d-flex align-items-center justify-content-center">
                                    <img src={fileIcon} alt="" />
                                  </div>
                                  <div class="media-widget-content">
                                    <div class="media-intro">
                                      <div class="media-into d-flex flex-nowrap">
                                        <span>{it?.file_url?.split("/")?.[4]?.substring(0, 5) + "..."}</span>
                                      </div>
                                    </div>
                                    <div class="file-size">{fileType}</div>
                                  </div>
                                </div>
                              ) : (
                                <div class="media-widget-box position-relative">
                                  <div class="media-widget-file-icon d-flex align-items-center justify-content-center">
                                    <img src={fileIcon} alt="" />
                                  </div>
                                  <div class="media-widget-content">
                                    <div class="media-intro">
                                      <div class="media-into d-flex flex-nowrap">
                                        <span>{chatMassage?.message?.split("/")?.[4]?.substring(0, 15) + "..."}</span>
                                      </div>
                                    </div>
                                    <div class="file-size">{fileType}</div>
                                  </div>
                                </div>
                              )}

                              <div class="m-text-xs d-flex align-items-center">
                                {moment(ConvertTime(chatMassage.date_created)).format("MM-DD-YYYY HH:mm")}{" "}
                              </div>
                            </a>
                          </>
                        );
                      })}
                    </>
                  ) : (
                    <div class="message d-flex flex-column align-items-start position-relative send chat-send pb-3 w-100">
                      <div class="m-content">
                        <p className="ck-content">{parse(chatMassage?.message)}</p>
                      </div>

                      <div class="m-text-xs d-flex align-items-center">{ConvertTime(chatMassage?.date_created)} </div>
                    </div>
                  )
                ) : chatMassage?.files?.length > 0 ? (
                  <>
                    <div class="message d-flex flex align-items-start position-relative received">
                      <div class=" d-flex flex-column align-items-start">
                        <div class="custom-profile" style={{ backgroundColor: "#47ad1d" }}>
                          <p className="chat-dp">
                            {" "}
                            {chatMassage?.name &&
                              chatMassage?.name?.split(" ")[0] &&
                              chatMassage?.name?.split(" ")[0][0]}
                            {chatMassage?.name &&
                              chatMassage?.name?.split(" ")[1] &&
                              chatMassage?.name?.split(" ")[1][0]}
                          </p>
                        </div>
                        <div class="m-content">
                          <p className="ck-content">{parse(chatMassage?.message)}</p>
                        </div>
                        <div class="m-text-xs d-flex align-items-center">
                          {chatMassage?.name?.split(" ")[0]}, {ConvertTime(chatMassage?.date_created)}{" "}
                        </div>
                        {chatMassage?.future_reminders?.length > 0 ? (
                          <div className="m-text-xs d-flex align-items-center mt-0">
                            <p style={{ color: "red", fontSize: "10px" }}>{`Marked for REVISIT on ${moment(
                              ConvertTime(chatMassage?.future_reminders?.[0]?.reminder_on_utc),
                            )?.format("MM-DD-YYYY")} by ${chatMassage?.future_reminders?.[0]?.name01}${
                              chatMassage?.future_reminders?.length > 1
                                ? ` & ${chatMassage?.future_reminders?.length - 1} Others`
                                : ""
                            }`}</p>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div class="dropdown">
                        <a
                          class="td-a-icon dropdown-toggle cursor-pointer more-icon-size"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          onClick={() => setSelectedMessage(chatMassage)}
                        >
                          <img src={More} alt="" />
                        </a>
                        <ul class="dropdown-menu ms-1">
                          <li className="menu-item">
                            <a class="dropdown-item" href="javascript:void(0)" onClick={() => MarkAsUnread()}>
                              Mark as Unread
                            </a>
                          </li>
                          <li className="menu-item">
                            <a
                              class="dropdown-item"
                              href="javascript:void(0)"
                              onClick={() => {
                                setShowReminder(true);
                              }}
                            >
                              Reminder
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {chatMassage?.files?.map((it) => {
                      const fileType = it?.file_url?.split(".")?.pop();
                      return (
                        <>
                          <a
                            href={process.env.REACT_APP_CHAT_MEDIA + "public/" + it?.file_url}
                            target="_blank"
                            class="message d-flex flex-column align-items-start position-relative received pb-3"
                          >
                            {["jpeg", "jpg", "png"].includes(fileType) ? (
                              <img
                                style={{ height: "60px" }}
                                src={process.env.REACT_APP_CHAT_MEDIA + "public/" + it?.file_url}
                                alt=""
                              />
                            ) : ["mp4", "MP4", "mpeg", "webm"].includes(fileType) ? (
                              <div class="media-widget-box position-relative">
                                <div class="media-widget-file-icon d-flex align-items-center justify-content-center">
                                  <img src={mp4} alt="" />
                                </div>
                                <div class="media-widget-content">
                                  <div class="media-intro">
                                    <div class="media-into d-flex flex-nowrap">
                                      <span>{it?.file_url?.split("/")?.[4]?.substring(0, 5) + "..."}</span>
                                    </div>
                                  </div>
                                  <div class="file-size">{fileType}</div>
                                </div>
                              </div>
                            ) : ["csv", "xlsx", "xls", "pdf", "doc", "docx"].includes(fileType) ? (
                              <div class="media-widget-box position-relative">
                                <div class="media-widget-file-icon d-flex align-items-center justify-content-center">
                                  <img src={fileIcon} alt="" />
                                </div>
                                <div class="media-widget-content">
                                  <div class="media-intro">
                                    <div class="media-into d-flex flex-nowrap">
                                      <span>{it?.file_url?.split("/")?.[4]?.substring(0, 5) + "..."}</span>
                                    </div>
                                  </div>
                                  <div class="file-size">{fileType}</div>
                                </div>
                              </div>
                            ) : (
                              <div class="media-widget-box position-relative">
                                <div class="media-widget-file-icon d-flex align-items-center justify-content-center">
                                  <img src={fileIcon} alt="" />
                                </div>
                                <div class="media-widget-content">
                                  <div class="media-intro">
                                    <div class="media-into d-flex flex-nowrap">
                                      <span>{chatMassage?.message?.split("/")?.[4]?.substring(0, 15) + "..."}</span>
                                    </div>
                                  </div>
                                  <div class="file-size">{fileType}</div>
                                </div>
                              </div>
                            )}

                            <div class="m-text-xs d-flex align-items-center">
                              {moment(ConvertTime(chatMassage.date_created)).format("MM-DD-YYYY HH:mm")}{" "}
                            </div>
                          </a>
                        </>
                      );
                    })}
                  </>
                ) : (
                  <div class="message d-flex flex align-items-start position-relative received pb-3">
                    <div class=" d-flex flex-column align-items-start ">
                      <div
                        class="custom-profile"
                        style={{
                          backgroundColor: props?.selectedChat?.chat_color
                            ? props?.selectedChat?.chat_color
                            : "#47ad1d",
                        }}
                      >
                        <p className="chat-dp">
                          {" "}
                          {chatMassage?.name && chatMassage?.name?.split(" ")[0] && chatMassage?.name?.split(" ")[0][0]}
                          {chatMassage?.name && chatMassage?.name?.split(" ")[1] && chatMassage?.name?.split(" ")[1][0]}
                        </p>
                      </div>
                      <div class="m-content">
                        <p className="ck-content">{parse(chatMassage?.message)}</p>
                      </div>
                      <div class="m-text-xs d-flex align-items-center">
                        {chatMassage?.name?.split(" ")[0]},{" "}
                        {moment(ConvertTime(chatMassage.date_created)).format("MM-DD-YYYY HH:mm")}{" "}
                      </div>
                      {chatMassage?.future_reminders?.length > 0 ? (
                        <div className="m-text-xs d-flex align-items-center mt-0">
                          <p style={{ color: "red", fontSize: "10px" }}>{`Marked for REVISIT on ${moment(
                            ConvertTime(chatMassage?.future_reminders?.[0]?.reminder_on_utc),
                          )?.format("MM-DD-YYYY")} by ${chatMassage?.future_reminders?.[0]?.name01}${
                            chatMassage?.future_reminders?.length > 1
                              ? ` & ${chatMassage?.future_reminders?.length - 1} Others`
                              : ""
                          }`}</p>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div class="dropdown">
                      <a
                        class="td-a-icon dropdown-toggle cursor-pointer more-icon-size"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        onClick={() => setSelectedMessage(chatMassage)}
                      >
                        <img src={More} alt="" />
                      </a>
                      <ul class="dropdown-menu ms-1">
                        <li className="menu-item">
                          <a class="dropdown-item" href="javascript:void(0)" onClick={() => MarkAsUnread()}>
                            Mark as Unread
                          </a>
                        </li>
                        <li className="menu-item">
                          <a
                            class="dropdown-item"
                            href="javascript:void(0)"
                            onClick={() => {
                              setShowReminder(true);
                            }}
                          >
                            Reminder
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
              </>
            ))}
      </Offcanvas.Body>
      {media?.length > 0 || selectedToUpload > 0 ? (
        <div className="chat-media-item">
          <div className="chat-media-list">
            {Array.from(media)?.length > 0 &&
              Array.from(media)?.map((item) => {
                const ext = item.name.split(".").pop();
                return (
                  <>
                    {ext == "jpg" || ext == "jpeg" || ext == "png" ? (
                      <img className="chat-images" style={{ height: "40px" }} src={`${URL.createObjectURL(item)}`} />
                    ) : ext == "mp4" || ext == "MP4" || ext == "mpeg" || ext == "webm" ? (
                      <img className="chat-images" style={{ height: "40px" }} src={mp4} />
                    ) : (
                      <img className="chat-images" style={{ height: "40px" }} src={fileIcon} />
                    )}
                  </>
                );
              })}
            {loadingFileUpload || selectedToUpload > 0
              ? Array(selectedToUpload)
                  .fill(0)
                  ?.map((ie) => (
                    <Spinner
                      className="chat-loader"
                      animation="border"
                      role="status"
                      style={{ marginRight: "10px", marginTop: "5px" }}
                    >
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ))
              : ""}
            {loadingFileUpload || selectedToUpload > 0 ? (
              <div className="chat-media-list">Please wait while the files are being uploaded...</div>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        ""
      )}

      <div class="chatbox-footer  d-flex align-items-center w-100 p-0">
        <div class="custom-input-group w-100 d-flex align-items-center">
          <div class={`custom-form-control chat-box-height d-flex flex-grow-1 position-relative `}>
            <OverlayTrigger
              placement="left"
              delay={{ show: 250, hide: 400 }}
              overlay={(p) => (
                <Tooltip id="button-tooltip" {...p} className="chat-media-tooltip">
                  Click here to send files, images and videos
                </Tooltip>
              )}
            >
              <div class="input-group-prepend position-relative">
                <input
                  type="file"
                  id="inputGroupFile02dk"
                  title=" "
                  multiple
                  accept=".jpg,.jpeg,.png,.csv,.xls,.xlsx,.pdf,.mp4,.mpeg,.webm,.MP4"
                  onChange={(e) => {
                    uploadChatMedia(Array.from(e.target.files));
                  }}
                />

                <a href="#!" class="input-group-text">
                  <img src={paperClip} alt="" />
                </a>
              </div>
            </OverlayTrigger>
            <div className="chatbox-new chat-page-texteditor ">
              <Editor
                id="textarea-chat-page"
                name="description"
                className="ck-content "
                onChange={(data) => {
                  setMessage(data);
                }}
                editorLoaded={editorLoaded}
                value={message}
                isChat={true}
              />
            </div>
            {/* <textarea
              className="chatbox-new"
              type="text"
              placeholder="Type a message"
              value={message}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
              onPaste={() => pasteImg()}
            /> */}
            {loadingFileUpload ? (
              <OverlayTrigger
                placement="left"
                delay={{ show: 250, hide: 400 }}
                overlay={(p) => (
                  <Tooltip id="button-tooltip" {...p} className="chat-media-tooltip">
                    Please wait while the files are being uploaded
                  </Tooltip>
                )}
              >
                <div
                  class={`input-group-append1 chat-send-btn flex-column d-flex align-items-center justify-content-center cursor-auto ${
                    loadingFileUpload || loading || (message?.length === 0 && uploadedFileName?.length === 0)
                      ? "chat-send-btn-disabled"
                      : ""
                  }`}
                >
                  <img src={sendImg} alt="" />
                  <span>Send</span>
                </div>
              </OverlayTrigger>
            ) : message?.length === 0 && uploadedFileName?.length === 0 ? (
              <OverlayTrigger
                placement="left"
                delay={{ show: 250, hide: 400 }}
                overlay={(p) => (
                  <Tooltip id="button-tooltip" {...p} className="chat-media-tooltip">
                    Message field cannot be empty
                  </Tooltip>
                )}
              >
                <div
                  class={`input-group-append1 chat-send-btn flex-column d-flex align-items-center justify-content-center cursor-auto ${
                    loadingFileUpload || loading || (message?.length === 0 && uploadedFileName?.length === 0)
                      ? "chat-send-btn-disabled"
                      : ""
                  }`}
                >
                  <img src={sendImg} alt="" />
                  <span>Send</span>
                </div>
              </OverlayTrigger>
            ) : (
              <div
                class={`input-group-append1 chat-send-btn flex-column d-flex align-items-center justify-content-center cursor-auto ${
                  loadingFileUpload || loading || (message?.length === 0 && uploadedFileName?.length === 0)
                    ? "chat-send-btn-disabled"
                    : ""
                }`}
                onClick={handleSend}
              >
                <img src={sendImg} alt="" />
                <span>Send</span>
              </div>
            )}
          </div>
        </div>
        {showReminder && (
          <ReminderDate
            show={showReminder}
            onHide={(prev) => setShowReminder(!prev)}
            selectedMessage={selectedMessage}
            setReminderQuestion={setReminderQuestion}
            loading={loading}
            setSelectedMessage={setSelectedMessage}
            auctionDetail={`${props?.selectedChat?.auction_id} - ${props?.selectedChat?.title}`}
          />
        )}
      </div>
    </Offcanvas>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateQuestion: (id, data, callback) => dispatch(updateQuestion(id, data, callback)),
  getConversation: (id, callback) => dispatch(getConversation(id, callback)),
  marAsReadConversation: (data, callback) => dispatch(marAsReadConversation(data, callback)),
  getProductCount: (id, callback) => dispatch(getProductCount(id, callback)),
  getAllUnreadCount: (callback) => dispatch(getAllUnreadCount(callback)),
  addReminderQuestion: (data, callback) => dispatch(addReminderQuestion(data, callback)),
  updateReminderQuestion: (data, callback) => dispatch(updateReminderQuestion(data, callback)),
  markAsUnread: (data, callback) => dispatch(markAsUnread(data, callback)),
});

const mapStateToProps = (state) => ({
  questionDetails: questionDetails(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(ChatMessages));
