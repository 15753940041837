import React, { useRef } from "react";
import LoadingBar from "react-top-loading-bar";
import Header from "../../layouts/header";
import PlanePartTypeTable from "../../components/settings/planePartType/planePartTypeTable";
import { connect } from "react-redux";
import {
  addPartMfr,
  addPartType,
  editPartMfr,
  editPartType,
  getAviationDetails,
  getMfr,
  getPartMfr,
} from "../../store/aviation";
import { useEffect } from "react";
import { useState } from "react";
import AddPlanePartType from "../../components/settings/planePartType/modals/addPlanePartType";
import AlertSuccess from "../../common/alerts/alertSuccess";
import AlertError from "../../common/alerts/alertError";
import { toast } from "react-toastify";
import PlanePartManufacturerTable from "../../components/settings/planePartManufacturer/planePartManufacturerTable";
import AddPlanePartManufacturer from "../../components/settings/planePartManufacturer/modals/addPlanePartManufacturer";
import FilterModal from "../../components/settings/modals/filterModal";

const PlanePartManufacturer = (props) => {
  const ref = useRef(null);
  const [loading, setLoading] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [selected, setSelected] = useState(null);
  const [selectedId, setSelectedId] = useState("");
  const [page, setPage] = useState(1);
  const [showFilter, setShowFilter] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [response, setResponse] = useState({
    res: false,
    keyword: "",
  });
  const [addData, setAddData] = useState({
    title: "",
  });
  const [sort, setSort] = useState("title");
  const [order, setOrder] = useState("asc");

  useEffect(() => {
    setPage(1);
    ref?.current?.continuousStart();
    setLoading(true);
    props.getPartMfr({ isActive: true, page: 1, keyword: response?.keyword, sort, order }, (res) => {
      if (res && res.status === 200) {
        ref?.current?.complete();
        setLoading(false);
      } else {
        ref?.current?.complete();
        setLoading(false);
      }
    });
  }, [sort, order]);

  useEffect(() => {
    setAddData({
      title: "",
    });
  }, [showAddModal]);

  const toggleAddModal = () => {
    setShowAddModal(!showAddModal);
  };

  useEffect(() => {
    ref?.current?.continuousStart();
    setLoading(true);
    props.getPartMfr({ isActive: true, page: page, keyword: response?.keyword, sort, order }, (res) => {
      if (res && res.status === 200) {
        ref?.current?.complete();
        setLoading(false);
      } else {
        ref?.current?.complete();
        setLoading(false);
      }
    });
  }, [page]);

  const handleFilter = () => {
    ref?.current?.continuousStart();
    setLoading(true);
    props.getPartMfr({ isActive: true, page: 1, keyword: keyword, sort, order }, (res) => {
      setPage(1);
      if (res && res.status === 200) {
        const data = { ...response };
        data.res = true;
        data.keyword = keyword;
        setResponse(data);
        ref?.current?.complete();
        setLoading(false);
        toggleFilter();
      } else {
        const data = { ...response };
        data.res = false;
        data.keyword = "";
        setResponse(data);
        ref?.current?.complete();
        setLoading(false);
      }
    });
  };
  const clearFilter = () => {
    setPage(1);
    ref?.current?.continuousStart();
    setLoading(true);
    props.getPartMfr({ isActive: true, page: 1, keyword: "", sort, order }, (res) => {
      if (res && res.status === 200) {
        const data = { ...response };
        data.res = false;
        data.keyword = "";
        setResponse(data);
        ref?.current?.complete();
        setLoading(false);
      } else {
        const data = { ...response };
        data.res = true;
        data.keyword = keyword;
        setResponse(data);
        ref?.current?.complete();
        setLoading(false);
      }
    });
  };

  const handleAdd = () => {
    ref?.current?.continuousStart();
    setLoading(true);
    props.addPartMfr(addData, (res) => {
      if (res && res.status === 200) {
        setResponse({
          res: false,
          keyword: "",
        });
        setPage(1);
        props?.getPartMfr({ isActive: true, page: 1, keyword: "", sort, order }, (res) => {});
        ref?.current?.complete();
        setLoading(false);
        toast(<AlertSuccess message={"Information Saved"} />);
        toggleAddModal();
      } else {
        ref?.current?.complete();
        setLoading(false);
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const handleEdit = () => {
    ref?.current?.continuousStart();
    setLoading(true);
    const id = editData?._id;
    const payload = {
      title: editData?.title,
    };
    props.editPartMfr(id, payload, (res) => {
      if (res && res.status === 200) {
        setResponse({
          res: false,
          keyword: "",
        });
        setPage(1);
        props?.getPartMfr({ isActive: true, page: 1, keyword: "", sort, order }, (res) => {});
        ref?.current?.complete();
        setLoading(false);
        toast(<AlertSuccess message={"Information Saved"} />);
        toggleAddModal();
      } else {
        ref?.current?.complete();
        setLoading(false);
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };
  const data = props?.getAviationDetails?.partMfr?.data;
  const totalCount =
    props.getAviationDetails &&
    props.getAviationDetails?.partMfr &&
    props.getAviationDetails?.partMfr?.pagination?.totalCount;

  const filteredCount =
    props.getAviationDetails &&
    props.getAviationDetails?.partMfr &&
    props.getAviationDetails?.partMfr.pagination?.filteredCount;

  const totalPages = Math.ceil(totalCount / 20);
  const toggleFilter = () => {
    setKeyword(response.keyword);
    setShowFilter(!showFilter);
  };
  return (
    <>
      <LoadingBar height={5} color="#47AD1D" ref={ref} />
      <Header />
      <PlanePartManufacturerTable
        data={data}
        loading={loading}
        setSelectedModel={setSelected}
        aviationType={selected}
        toggleAddModal={toggleAddModal}
        editData={editData}
        setEditData={setEditData}
        setSelectedId={setSelectedId}
        page={page}
        setPage={setPage}
        totalCount={totalCount}
        filteredCount={filteredCount}
        totalPages={totalPages}
        toggleFilter={toggleFilter}
        keyword={keyword}
        setKeyword={setKeyword}
        handleFilter={handleFilter}
        clearFilter={clearFilter}
        response={response}
        sort={sort}
        setSort={setSort}
        order={order}
        setOrder={setOrder}
      />
      <AddPlanePartManufacturer
        show={showAddModal}
        onHide={toggleAddModal}
        setSelected={setSelected}
        aviationType={selected}
        editData={editData}
        setEditData={setEditData}
        handleAdd={handleAdd}
        handleEdit={handleEdit}
        loading={loading}
        addData={addData}
        setAddData={setAddData}
      />
      <FilterModal
        show={showFilter}
        onHide={toggleFilter}
        loading={loading}
        handleFilter={handleFilter}
        keyword={keyword}
        setKeyword={setKeyword}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getPartMfr: (params, callback) => dispatch(getPartMfr(params, callback)),
  addPartMfr: (data, callback) => dispatch(addPartMfr(data, callback)),
  editPartMfr: (id, data, callback) => dispatch(editPartMfr(id, data, callback)),
});

const mapStateToProps = (state) => ({
  getAviationDetails: getAviationDetails(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(PlanePartManufacturer));
