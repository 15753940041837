import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ProductConditionModal from "../../../layouts/modals/productConditionModal";
import {
  getListingProductDetail,
  productCondition,
  productConditionReceived,
  updateProductCondition,
} from "../../../store/listingProduct";
import { toast } from "react-toastify";
import AlertError from "../../../common/alerts/alertError";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import parse from "html-react-parser";
import Skeleton from "react-loading-skeleton";
import helpCircle from "../../../include/images/help-circle.svg";
import { getModals, setConditionModal } from "../../../store/modal";

const ProductCondition = (props) => {
  const showConditionModal = props?.conditionModal;
  const toggleConditionModal = () => {
    props?.setShowConditionModal(!showConditionModal);
  };

  const [scrapValue, setScrapValue] = useState(0);
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [editorData, setEditorData] = useState("");
  const [loadingCondition, setLoadingCondition] = useState(false);
  const [savedData, setSavedData] = useState("");
  const [conditions, setConditions] = useState({ name: "", value: "" });
  const [otherData, setOtherData] = useState("");

  const conditionOptions = [
    { name: "New", value: "New" },
    { name: "New Surplus", value: "New Surplus" },
    { name: "Used", value: "Used" },
    { name: "Damaged", value: "Damaged" },
    { name: "Scrap", value: "Scrap" },

    { name: "Factory New", value: "Factory New" },
    { name: "Overhauled", value: "Overhauled" },
    { name: "Serviceable", value: "Serviceable" },
    { name: "As Removed", value: "As Removed" },
    { name: "Repairable", value: "Repairable" },
    { name: "Repaired", value: "Repaired" },
    { name: "Inspected", value: "Inspected" },
    { name: "Core", value: "Core" },

    { name: "Beyond Economical Repair", value: "Beyond Economical Repair" },
    { name: "Rebuilt", value: "Rebuilt" },
    { name: "Modified", value: "Modified" },

    { name: "Tested", value: "Tested" },
    { name: "Unserviceable", value: "Unserviceable" },

    { name: "Disposal", value: "Disposal" },
    { name: "Disposal/Free", value: "Disposal/Free" },

    { name: "Others", value: "Others" },
  ];

  const condition =
    props.getListingProductDetail &&
    props.getListingProductDetail.condition &&
    props.getListingProductDetail.condition.detail;

  const handleSubmit = () => {
    setLoadingCondition(true);
    props.refStart();
    const id = props.auctionId;
    const data = {
      condition: editorData,
      scrap: scrapValue,
      conditionType: conditions?.name ? (conditions?.name == "Others" ? otherData : conditions?.name) : "",
    };
    props.updateProductCondition(id, data, (res) => {
      if (res.status === 200) {
        const detail = {
          condition: editorData,
          scrap: scrapValue ? true : false,
          conditionType: conditions?.name ? (conditions?.name == "Others" ? otherData : conditions?.name) : "",
        };
        setLoadingCondition(false);
        props.refStop();
        props.productConditionReceived({ detail });
        toggleConditionModal();
        toast(<AlertSuccess message={"Information Saved"} />);
      } else {
        setLoadingCondition(false);
        props.refStop();
        toast(<AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  useEffect(() => {
    if (showConditionModal) {
      setSavedData(condition.condition);
      if (condition?.conditionType) {
        const a = conditionOptions?.filter((item) => item?.name == condition?.conditionType);
        if (a?.length > 0) {
          setConditions({ name: condition?.conditionType, value: condition?.conditionType });
        } else {
          setConditions({ name: "Others", value: "Others" });
          setOtherData(condition?.conditionType);
        }
      } else {
        setConditions({ name: "Select Condition", value: "Select Condition" });
      }
    }
  }, [showConditionModal]);

  return (
    <div class="details-page-widget-block" id={props.id}>
      <div class="page-widget-head d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <div
            class="page-widget-label text-capitalize cursor-pointer"
            onClick={() => !props.loadingMain && toggleConditionModal()}
          >
            Condition
          </div>

          <div style={{ display: "none" }} id="popover">
            <div class="popover-inner" role="tooltip">
              <p>
                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer
                took a galley
              </p>
            </div>
          </div>
        </div>
        {/* <div class="btn-block d-flex align-items-center">
          {props.loadingMain ? (
            <Skeleton height="30px" width="90px" />
          ) : (
            <a href="javascript:void(0)" class="btn btn-primary btn-sm" onClick={() => toggleConditionModal()}>
              Edit
            </a>
          )}
        </div> */}
      </div>
      <div class="page-widget-body mt-3">
        <div class="table-responsive">
          <table class="table mb-0  product-table">
            <colgroup>
              <col style={{ width: "34%" }} />
              <col />
            </colgroup>
            <tbody>
              {props.loadingMain ? (
                <>
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                </>
              ) : (
                <>
                  <tr>
                    <td>Condition</td>
                    <td>{condition && condition?.conditionType ? condition?.conditionType : "N/A"}</td>
                  </tr>
                  <tr>
                    <td>Description</td>
                    <td className="ck-content">
                      {condition && condition?.condition ? parse(condition.condition) : "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <td>Scrap</td>
                    <td>{condition && condition?.scrap ? "Yes" : "No"}</td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {/* <ProductConditionModal
        show={showConditionModal}
        onHide={toggleConditionModal}
        editorLoaded={editorLoaded}
        setEditorLoaded={setEditorLoaded}
        editorData={editorData}
        setEditorData={setEditorData}
        scrapValue={scrapValue}
        setScrapValue={setScrapValue}
        handleSubmit={handleSubmit}
        loadingCondition={loadingCondition}
        savedData={savedData}
        conditionOptions={conditionOptions}
        condition={conditions}
        setCondition={setConditions}
        otherData={otherData}
        setOtherData={setOtherData}
      /> */}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  productCondition: (params, callback) => dispatch(productCondition(params, callback)),
  updateProductCondition: (params, data, callback) => dispatch(updateProductCondition(params, data, callback)),

  //global state for manipulating data
  productConditionReceived: (data) => dispatch(productConditionReceived(data)),
  setShowConditionModal: (data) => dispatch(setConditionModal(data)),
});

const mapStateToProps = (state) => ({
  getListingProductDetail: getListingProductDetail(state),
  conditionModal: getModals(state)?.conditionModal,
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(ProductCondition));
