import React, { useEffect, useState } from "react";
import Select from "react-select";
import calculator from "../../../include/images/calculator.svg";
import {
  allYieldtoGrade,
  lengthLookup,
  lstofCoatings,
  lstofConditions,
  lstofdealTypes,
  lstofGrades,
  lstofMillCerts,
  lstofMills,
  lstofnrofgrades,
  lstofnrofsizes,
  lstofnrofthreads,
  lstofPipeConstructs,
  lstofpipeTypes,
  lstofPSLS,
  lstofRanges,
  lstofThreads,
  lstofTypes,
  lstofYears,
  lstofYields,
  sizetoWallThikness,
  sizetoWeightEnglish,
  sizetoWeightMetric,
  typetoSizeEnglish,
  typetoSizeMetric,
  yieldtoGrade,
} from "../../../utils.js/pipeDropdownValues";
import { connect } from "react-redux";
import { getListingProductDetail, getPipeDetails, updatePipeDetails } from "../../../store/listingProduct";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import AlertError from "../../../common/alerts/alertError";
const customStyles = {
  indicatorSeparator: (styles) => ({ display: "none" }),

  option: (provided, state) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "#47ad1d",
      color: "#F5F5F5",
    },
    display: "-webkit-box",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    color: state.isSelected ? "#fff" : "black",
    backgroundColor: state.isSelected ? "#47ad1d" : provided.backgroundColor,
    maxHeight: "84px",
  }),
  control: (base, state) => ({
    ...base,
    height: 30,
    minHeight: 30,
    background: state.isDisabled ? "#e9ecef" : "#f5f5f5",
    borderColor: state.isFocused ? "#47AD1D" : "#e4e4e4",
    boxShadow: state.isFocused ? "0 0 0 0.5px #47AD1D" : "0",
    border: state.isHovered ? "0" : "0px solid #e4e4e4",
    color: state.isDisabled ? "black" : "#f5f5f5",

    "&:hover": {
      borderColor: state.isFocused ? "#47AD1D" : "0",
      boxShadow: state.isFocused ? "0 0 0 0.5px #47AD1D" : "0",
    },
    "&:disabled": {
      background: "#e9ecef",
    },
  }),
  multiValueRemove: (base, state) => ({
    ...base,
    "&:hover": {
      backgroundColor: "rgb(95, 50, 187, 10%)",
      color: "#6119c0",
    },
  }),
};

const PipeDetails = (props) => {
  const pipeData = props?.getListingDetails?.pipeData?.detail;
  const recFound = props?.getListingDetails?.pipeData?.recFound;
  const countries = props?.getListingDetails?.pipeData?.countries;
  const titleList = pipeData?.inTitlelist?.split(",");
  const optionsView = (options) => {
    if (options) {
      if (Array.isArray(options)) {
        return options.map((val) => ({
          value: val,
          label: val,
        }));
      } else {
        return options.split(",").map((val) => ({
          value: val,
          label: val,
        }));
      }
    } else {
      return [];
    }
  };
  const [unitMeasure, setUnitMeasure] = useState(0);

  const [dealType, setDealType] = useState({});
  const [type, setType] = useState({});
  const [condition, setCondition] = useState({});
  const [sizes, setSizes] = useState({});
  const [grades, setGrades] = useState({});
  const [threads, setThreads] = useState({});
  const [pipeType, setPipeType] = useState({});
  const [size, setSize] = useState({});
  const [weight, setWeight] = useState({});
  const [thikness, setThikness] = useState({});
  const [yields, setYields] = useState({});
  const [grade, setGrade] = useState({});
  const [thread, setThread] = useState({});
  const [psl, setPsl] = useState({});
  const [length, setLength] = useState({});
  const [coating, setCoating] = useState({});
  const [pipeConstr, setPipeContr] = useState({});
  const [mill, setMill] = useState({});
  const [origin, setOrigin] = useState({});
  const [year, setYear] = useState({});
  const [millReport, setMillReport] = useState({});
  const [priceperton, setPriceperton] = useState("");

  const [updateData, setUpdateData] = useState({
    auctionId: props?.auctionId,

    title: "",
    description: "",
    summaryCondition: "",

    populateQtyChoice: 1,

    jointCount: "",
    totalLength: "",
    tonnage: "",

    country_id: 0,

    size: "",
    weight: "",
    WT: "",
    grade: "",
    thread: "",
    coating: "",
    pipeConstruct: "",
    psl: "",
    length: "",
    condition: "",
    type: "",
    mill: "",
    year: "",
    millCert: "",
    dealType: "",
    addInfo: "",
    scrapPrice: "",
    push_mtr: false,

    recFound: false, // this will be false for editing
    millCertsReceived: false, //false by default (for edit)
    nrOfSizes: "",
    nrOfGrades: "",
    nrOfThreads: "",
    pipeType: "",
    yield: "",

    includeInTitle: "",

    populateAll: false,
    populateTitle: false,
    populateDescription: false,
    populateCondition: false,
    populateQty: false,
  });
  useEffect(() => {
    setDealType({
      value: pipeData?.dealTypeDefVal,
      label: pipeData?.dealTypeDefVal,
    });
    setType({
      value: pipeData?.typeDefVal,
      label: pipeData?.typeDefVal,
    });
    setCondition({
      value: pipeData?.conditionDefVal,
      label: pipeData?.conditionDefVal,
    });
    setSizes({
      value: pipeData?.nrofSizesDefVal,
      label: pipeData?.nrofSizesDefVal,
    });
    setGrades({
      value: pipeData?.nrofGradesDefVal,
      label: pipeData?.nrofGradesDefVal,
    });
    setThreads({
      value: pipeData?.nrofThreadsDefVal,
      label: pipeData?.nrofThreadsDefVal,
    });
    setPipeType({
      value: pipeData?.pipeTypeDefVal,
      label: pipeData?.pipeTypeDefVal,
    });
    setSize({
      value: pipeData?.sizeDefVal,
      label: pipeData?.sizeDefVal,
    });
    setWeight({
      value: pipeData?.weightDefVal,
      label: pipeData?.weightDefVal,
    });
    setThikness({
      value: pipeData?.wtDefVal,
      label: pipeData?.wtDefVal,
    });
    setYields({
      value: pipeData?.yieldDefVal,
      label: pipeData?.yieldDefVal,
    });
    setGrade({
      value: pipeData?.gradeDefVal,
      label: pipeData?.gradeDefVal,
    });
    setThread({
      value: pipeData?.threadDefVal,
      label: pipeData?.threadDefVal,
    });
    setPsl({
      value: pipeData?.PSLDefVal,
      label: pipeData?.PSLDefVal,
    });
    setLength({
      value: pipeData?.lengthDefVal,
      label: pipeData?.lengthDefVal,
    });
    setCoating({
      value: pipeData?.coatingDefVal,
      label: pipeData?.coatingDefVal,
    });
    setPipeContr({
      value: pipeData?.pipeConstructDefVal,
      label: pipeData?.pipeConstructDefVal,
    });
    setMill({
      value: pipeData?.millDefVal,
      label: pipeData?.millDefVal,
    });
    setOrigin(countries?.find((val) => val.country_id === pipeData?.country_id));
    setYear({
      value: pipeData?.yearDefVal,
      label: pipeData?.yearDefVal,
    });
    setMillReport({
      value: pipeData?.millCertDefVal,
      label: pipeData?.millCertDefVal,
    });
    setUpdateData({
      auctionId: props?.auctionId,

      title: pipeData?.title ? pipeData?.title : "",
      description: pipeData?.description ? pipeData?.description : "",
      summaryCondition: pipeData?.summaryCondition ? pipeData?.summaryCondition : "",
      populateQtyChoice: pipeData?.populateQtyChoice ? pipeData?.populateQtyChoice : 1,

      jointCount: pipeData?.jointCountDefVal ? pipeData?.jointCountDefVal : "",
      totalLength: pipeData?.totalLengthDefVal ? pipeData?.totalLengthDefVal : "",
      tonnage: pipeData?.tonnageDefVal ? pipeData?.tonnageDefVal : "",
      country_id: pipeData?.country_id ? pipeData?.country_id : 0,

      size: pipeData?.sizeDefVal ? pipeData?.sizeDefVal : "",
      weight: pipeData?.weightDefVal ? pipeData?.weightDefVal : "",
      WT: pipeData?.wtDefVal ? pipeData?.wtDefVal : "",
      grade: pipeData?.gradeDefVal ? pipeData?.gradeDefVal : "",
      thread: pipeData?.threadDefVal ? pipeData?.threadDefVal : "",
      coating: pipeData?.coatingDefVal ? pipeData?.coatingDefVal : "",
      pipeConstruct: pipeData?.pipeConstructDefVal ? pipeData?.pipeConstructDefVal : "",
      psl: pipeData?.PSLDefVal ? pipeData?.PSLDefVal : "",
      length: pipeData?.lengthDefVal ? pipeData?.lengthDefVal : "",
      condition: pipeData?.conditionDefVal ? pipeData?.conditionDefVal : "",
      type: pipeData?.typeDefVal ? pipeData?.typeDefVal : "",
      mill: pipeData?.millDefVal ? pipeData?.millDefVal : "",
      year: pipeData?.yearDefVal ? pipeData?.yearDefVal : "",
      millCert: pipeData?.millCertDefVal ? pipeData?.millCertDefVal : "",
      dealType: pipeData?.dealTypeDefVal ? pipeData?.dealTypeDefVal : "",
      addInfo: pipeData?.AddInfoDefVal ? pipeData?.AddInfoDefVal : "",
      scrapPrice: pipeData?.scrapPriceDefVal ? pipeData?.scrapPriceDefVal : "",
      push_mtr: pipeData?.push_mtrDefVal ? true : false,

      recFound: recFound ? true : false, // this will be false for editing
      millCertsReceived: pipeData?.mill_certs_recievedDefVal ? true : false, //false by default (for edit)
      nrOfSizes: pipeData?.nrofSizesDefVal ? pipeData?.nrofSizesDefVal : "",
      nrOfGrades: pipeData?.nrofGradesDefVal ? pipeData?.nrofGradesDefVal : "",
      nrOfThreads: pipeData?.nrofThreadsDefVal ? pipeData?.nrofThreadsDefVal : "",
      pipeType: pipeData?.pipeTypeDefVal ? pipeData?.pipeTypeDefVal : "",
      yield: pipeData?.yieldDefVal ? pipeData?.yieldDefVal : "",
      includeInTitle: pipeData?.inTitlelist ? pipeData?.inTitlelist : "",
      populateAll: pipeData?.populateAll ? true : false,
      populateTitle: pipeData?.populateTitle ? true : false,
      populateDescription: pipeData?.populateDescription ? true : false,
      populateCondition: pipeData?.populateCondition ? true : false,
      populateQty: pipeData?.populateQty ? true : false,
    });
  }, [pipeData]);

  const handleUpdate = () => {
    const payload = { ...updateData };
    props?.refStart();
    props?.updatePipeDetails(props?.auctionId, updateData, (res) => {
      if (res.status === 200) {
        props?.refStop();
        toast(<AlertSuccess message={"Information Saved"} />);
      } else {
        props?.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const handlePopulateQty = () => {
    const payload = { ...updateData };
    payload.populateQty = true;
    props?.refStart();
    props?.updatePipeDetails(props?.auctionId, payload, (res) => {
      if (res.status === 200) {
        props?.refStop();
        toast(<AlertSuccess message={"Information Saved"} />);
      } else {
        props?.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };
  const handlePopulateTitle = () => {
    const payload = { ...updateData };
    payload.populateTitle = true;
    props?.refStart();
    props?.updatePipeDetails(props?.auctionId, payload, (res) => {
      if (res.status === 200) {
        props?.refStop();
        toast(<AlertSuccess message={"Information Saved"} />);
      } else {
        props?.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };
  const handlePopulateCondition = () => {
    const payload = { ...updateData };
    payload.populateCondition = true;
    props?.refStart();
    props?.updatePipeDetails(props?.auctionId, payload, (res) => {
      if (res.status === 200) {
        props?.refStop();
        toast(<AlertSuccess message={"Information Saved"} />);
      } else {
        props?.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };
  const handlePopulateDescription = () => {
    const payload = { ...updateData };
    payload.populateDescription = true;
    props?.refStart();
    props?.updatePipeDetails(props?.auctionId, payload, (res) => {
      if (res.status === 200) {
        props?.refStop();
        toast(<AlertSuccess message={"Information Saved"} />);
      } else {
        props?.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };
  const handlePopulateAll = () => {
    const payload = { ...updateData };
    payload.populateAll = true;
    props?.refStart();
    props?.updatePipeDetails(props?.auctionId, payload, (res) => {
      if (res.status === 200) {
        props?.refStop();
        toast(<AlertSuccess message={"Information Saved"} />);
      } else {
        props?.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  function toDecimal(_x) {
    var parts = _x.split(" ");

    if (parts.length > 2 || isNaN(parts[0])) {
      return 0;
    }

    // if no second element, then must be a whole number
    if (!parts[1]) {
      return _x;
    }

    var decParts = parts[1].split("/");
    return parseInt(parts[0], 10) + parseInt(decParts[0], 10) / parseInt(decParts[1], 10);
  }

  //Calculation Functions.

  const calcTotalLength = () => {
    let jointCount = updateData?.jointCount,
      length = updateData?.length,
      result,
      l,
      v;

    if (lengthLookup[length]) {
      l = lengthLookup[length][unitMeasure ? 1 : 0];
    } else {
      l = length;
    }

    v = jointCount;
    try {
      result = (parseFloat(l) * parseFloat(v)).toFixed(2);
    } catch (err) {
      alert(err.message);
      result = "";
    }
    if (isNaN(result)) {
      const data = { ...updateData };
      data.totalLength = "";
      setUpdateData(data);
    } else {
      const data = { ...updateData };
      data.totalLength = result;
      setUpdateData(data);
    }
  };

  const calcTonnage = () => {
    let jointCount = updateData?.jointCount,
      length = updateData?.length,
      weight = updateData?.weight,
      totalLength = updateData?.totalLength,
      result,
      l,
      v,
      w,
      tl,
      constant;

    /* convert r2, r3 into values */
    if (lengthLookup[length]) {
      l = lengthLookup[length][unitMeasure ? 1 : 0];
    } else {
      l = length;
    }

    /* jointcount  */
    v = jointCount;
    /* weight  lbs/sqft */
    w = weight;
    /* total length */
    tl = totalLength;

    constant = unitMeasure ? 1000 : 2204.6;

    try {
      /*  if we have a totallength then use that. it takes precedence over the joint count */
      if (tl) {
        result = ((parseFloat(tl) * parseFloat(w)) / constant).toFixed(2);
      } else {
        result = ((parseFloat(l) * parseFloat(v) * parseFloat(w)) / constant).toFixed(2);
      }
    } catch (err) {
      alert(err.message);
      result = "";
    }
    if (isNaN(result)) {
      const data = { ...updateData };
      data.tonnage = "";
      setUpdateData(data);
    } else {
      const data = { ...updateData };
      data.tonnage = result;
      setUpdateData(data);
    }
  };

  const calcScrapPrice = () => {
    let result,
      tonnage = updateData?.tonnage,
      ppt = priceperton;

    try {
      result = (tonnage * ppt).toFixed(2);
      if (isNaN(result)) {
        throw new Error("You must enter a number");
      }
    } catch (err) {
      alert(err.message);
      result = "";
    }
    if (isNaN(result)) {
      const data = { ...updateData };
      data.scrapPrice = "";
      setUpdateData(data);
    } else {
      const data = { ...updateData };
      data.scrapPrice = result;
      setUpdateData(data);
    }
  };

  const calWeight = () => {
    let wt = updateData?.WT,
      size = updateData?.size,
      weight = updateData?.weight,
      result,
      s,
      constant;
    try {
      s = unitMeasure ? size : toDecimal(size);
      constant = unitMeasure ? 0.0246421502959472 : 10.69;
      result = ((s - wt) * wt * constant).toFixed(2);
      console.log(s - wt, "is the result...");
      result = result.replace(/0$/, "");
    } catch (err) {
      alert(err.message);
      result = "";
    }
    if (isNaN(result)) {
      const data = { ...updateData };
      data.weight = "";
      setUpdateData(data);
      setWeight({});
    } else {
      const data = { ...updateData };
      data.weight = result;
      setUpdateData(data);
      setWeight({
        valut: result,
        label: result,
      });
    }
  };

  const calcWT = () => {
    let size = updateData?.size,
      weight = updateData?.weight,
      result,
      s,
      v,
      w,
      constant;
    try {
      w = weight;
      s = unitMeasure ? size : toDecimal(size);
      constant = unitMeasure ? 0.0246421502959472 : 10.69;
      result = ((s - Math.sqrt(Math.pow(s, 2) - (4 / constant) * w)) / 2).toFixed(3);
      result = result.replace(/^0\.*/, ".");
    } catch (err) {
      alert(err.message);
      result = "";
    }
    if (isNaN(result)) {
      const data = { ...updateData };
      data.WT = "";
      setUpdateData(data);
      setThikness({});
    } else {
      const data = { ...updateData };
      data.WT = result;
      setUpdateData(data);
      setThikness({
        value: result,
        label: result,
      });
    }
  };
  return (
    <div class="details-page-widget-block">
      <div class="page-widget-head d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <div class="page-widget-label text-capitalize">Pipe Details</div>
        </div>
        {!props?.loadingMain && (
          <div className="d-flex align-items-center">
            <span className="fw-medium me-2">Unit of Measure:</span>
            <div className="d-flex">
              <div className="radio pe-2">
                <input
                  className="form-check-input cursor-pointer me-1"
                  value={0}
                  checked={unitMeasure == 0}
                  type="radio"
                  name="unitOfMeasure"
                  id="english"
                  onChange={(e) => {
                    function simplifyFraction(numerator, denominator) {
                      function gcd(a, b) {
                        return b === 0 ? a : gcd(b, a % b);
                      }
                      const divisor = gcd(numerator, denominator);
                      return [numerator / divisor, denominator / divisor];
                    }
                    function mmToInches(mm) {
                      const inch = mm / 25.4;
                      const inchesInt = Math.floor(inch);
                      const fraction = inch - inchesInt;
                      const closestDenominator = 8;
                      let roundedFraction = Math.round(fraction * closestDenominator);

                      if (roundedFraction === 0) {
                        return `${inchesInt}`;
                      } else if (roundedFraction === closestDenominator) {
                        return `${inchesInt + 1}`;
                      } else {
                        const [simplifiedNumerator, simplifiedDenominator] = simplifyFraction(
                          roundedFraction,
                          closestDenominator,
                        );
                        return `${inchesInt} ${simplifiedNumerator}/${simplifiedDenominator}`;
                      }
                    }
                    const sizes = mmToInches(size.value);
                    setSize({
                      value: sizes,
                      label: sizes,
                    });
                    setWeight({
                      value: weight?.value * (0.3048 / 0.453592)?.toFixed(2),
                      label: weight?.label * (0.3048 / 0.453592)?.toFixed(2),
                    });
                    setThikness({
                      value: thikness?.value / 25.4?.toFixed(2),
                      label: thikness?.label / 25.4?.toFixed(2),
                    });
                    const data = { ...updateData };
                    data.totalLength = (data.totalLength / 0.3048)?.toFixed(2)?.toString();
                    setUpdateData(data);
                    setUnitMeasure(e.target.checked ? 0 : 1);
                  }}
                />
                <label className="form-check-label" htmlFor="english">
                  English
                </label>
              </div>
              <div className="radio pe-2">
                <input
                  className="form-check-input cursor-pointer me-1"
                  value={1}
                  checked={unitMeasure == 1}
                  type="radio"
                  name="unitOfMeasure"
                  id="metric"
                  onChange={(e) => {
                    const data = { ...updateData };
                    // setSize({
                    //   value: size?.value * 25.4,
                    //   label: size?.label * 25.4,
                    // });
                    if (!isNaN(size?.value)) {
                      data.size = (size?.value * 25.4).toFixed(2);
                      setSize({
                        value: (size?.value * 25.4).toFixed(2),
                        label: (size?.label * 25.4).toFixed(2),
                      });
                    } else {
                      const parts = size?.value?.split(" ")?.length > 0 ? size?.value?.split(" ") : [];
                      const wholeNumber = parseInt(parts[0]);
                      // Parse the fraction part
                      const fraction = parts[1].split("/");
                      const numerator = parseInt(fraction[0]);
                      const denominator = parseInt(fraction[1]);

                      // Calculate the decimal value of the fraction
                      const fractionValue = numerator / denominator;

                      // Add the whole number and fraction values together
                      const result = wholeNumber + fractionValue;
                      if (parts?.length > 0) {
                        setSize({
                          value: (result * 25.4)?.toFixed(2),
                          label: (result * 25.4)?.toFixed(2),
                        });
                        data.size = (result * 25.4)?.toFixed(2);
                      } else {
                        data.size = "";
                        setSize({});
                      }
                    }
                    setWeight({
                      value: (weight?.value * (0.453592 / 0.3048))?.toFixed(2),
                      label: (weight?.label * (0.453592 / 0.3048))?.toFixed(2),
                    });
                    data.weight = (weight?.value * (0.453592 / 0.3048))?.toFixed(2);
                    setThikness({
                      value: (thikness?.value * 25.4)?.toFixed(2),
                      label: (thikness?.label * 25.4)?.toFixed(2),
                    });
                    data.WT = (thikness?.value * 25.4)?.toFixed(2);
                    data.totalLength = (data.totalLength * 0.3048)?.toFixed(2)?.toString();
                    setUpdateData(data);
                    setUnitMeasure(e.target.checked ? 1 : 0);
                  }}
                />
                <label className="form-check-label" htmlFor="metric">
                  Metric
                </label>
              </div>
            </div>
          </div>
        )}
      </div>
      {props?.loadingMain ? (
        <div class="page-widget-body mt-2">
          <div class="table-responsive">
            <table class="table mb-0  product-table">
              <colgroup>
                <col style={{ width: "25%" }} />
                <col style={{ width: "35%" }} />
                <col />
                <col style={{ width: "10%" }} />
                <col style={{ width: "10%" }} />
              </colgroup>
              <tbody>
                {[2, 3, 4, 5, 6, 7].map((val, i) => (
                  <tr key={i}>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="dividor"></div>
            <table class="table mb-0  product-table mt-2">
              <colgroup>
                <col style={{ width: "25%" }} />
                <col style={{ width: "35%" }} />
                <col />
                <col style={{ width: "10%" }} />
                <col style={{ width: "10%" }} />
              </colgroup>
              <tbody>
                {[2, 3, 4].map((val, i) => (
                  <tr key={i}>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div class="page-widget-body mt-2">
          <div class="table-responsive">
            <table class="table mb-0  product-table">
              <colgroup>
                <col style={{ width: "25%" }} />
                <col style={{ width: "35%" }} />
                <col />
                <col style={{ width: "10%" }} />
                <col style={{ width: "10%" }} />
              </colgroup>
              <thead>
                <tr>
                  <th>Setting</th>
                  <th>Value</th>
                  <th>Unit</th>
                  <th>
                    <img src={calculator} alt="" />
                  </th>
                  <th>In Title</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Deal Type</td>
                  <td>
                    <div>
                      <Select
                        styles={customStyles}
                        value={dealType}
                        options={optionsView(lstofdealTypes)}
                        onChange={(e) => {
                          setDealType(e);
                          const data = { ...updateData };
                          data.dealType = e.value;
                          setUpdateData(data);
                        }}
                      />
                    </div>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Type</td>
                  <td>
                    <Select
                      styles={customStyles}
                      value={type}
                      options={[{ value: "", label: "" }, ...optionsView(lstofTypes)]}
                      onChange={(e) => {
                        setType(e);
                        const data = { ...updateData };
                        data.type = e.value;
                        setUpdateData(data);
                      }}
                    />
                  </td>
                  <td></td>
                  <td></td>
                  <td>
                    <div class="form-check dark-tick-checkbox">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="type"
                        value="type"
                        defaultChecked={!recFound || titleList?.includes("type")}
                        onChange={(e) => {
                          const newValue = e.target.value.trim();
                          const data = { ...updateData };
                          let includeInTitleArray = data.includeInTitle ? data.includeInTitle.split(",") : [];
                          if (includeInTitleArray.includes(newValue)) {
                            includeInTitleArray = includeInTitleArray.filter((item) => item !== newValue);
                          } else {
                            includeInTitleArray.push(newValue);
                          }
                          data.includeInTitle = includeInTitleArray.join(",");
                          setUpdateData(data);
                        }}
                      />
                      <label class="form-check-label" for="type"></label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Condition</td>
                  <td>
                    <Select
                      styles={customStyles}
                      options={[{ value: "", label: "" }, ...optionsView(lstofConditions)]}
                      value={condition}
                      onChange={(e) => {
                        setCondition(e);
                        const data = { ...updateData };
                        data.condition = e.value;
                        setUpdateData(data);
                      }}
                    />
                  </td>
                  <td></td>
                  <td></td>
                  <td>
                    <div class="form-check dark-tick-checkbox">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="condition"
                        value="condition"
                        defaultChecked={!recFound || titleList?.includes("condition")}
                        onChange={(e) => {
                          const newValue = e.target.value.trim();
                          const data = { ...updateData };
                          let includeInTitleArray = data.includeInTitle ? data.includeInTitle.split(",") : [];
                          if (includeInTitleArray.includes(newValue)) {
                            includeInTitleArray = includeInTitleArray.filter((item) => item !== newValue);
                          } else {
                            includeInTitleArray.push(newValue);
                          }
                          data.includeInTitle = includeInTitleArray.join(",");
                          setUpdateData(data);
                        }}
                      />
                      <label class="form-check-label" for="condition"></label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Number of Sizes</td>
                  <td>
                    <Select
                      styles={customStyles}
                      options={optionsView(lstofnrofsizes)}
                      value={sizes}
                      onChange={(e) => {
                        setSizes(e);
                        const data = { ...updateData };
                        data.nrOfSizes = e.value;
                        setUpdateData(data);
                      }}
                    />
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Number of Grades</td>
                  <td>
                    <Select
                      styles={customStyles}
                      options={optionsView(lstofnrofgrades)}
                      value={grades}
                      onChange={(e) => {
                        setGrades(e);
                        const data = { ...updateData };
                        data.nrOfGrades = e.value;
                        setUpdateData(data);
                      }}
                    />
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Number of Threads</td>
                  <td>
                    <Select
                      styles={customStyles}
                      options={optionsView(lstofnrofthreads)}
                      value={threads}
                      onChange={(e) => {
                        setThreads(e);
                        const data = { ...updateData };
                        data.nrOfThreads = e.value;
                        setUpdateData(data);
                      }}
                    />
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Pipe Type</td>
                  <td>
                    <Select
                      styles={customStyles}
                      options={optionsView(lstofpipeTypes)}
                      value={pipeType}
                      onChange={(e) => {
                        setPipeType(e);
                        const data = { ...updateData };
                        data.pipeType = e.value;
                        setUpdateData(data);
                      }}
                    />
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            <div className="dividor"></div>
            <table class="table mb-0  product-table mt-2">
              <colgroup>
                <col style={{ width: "25%" }} />
                <col style={{ width: "35%" }} />
                <col />
                <col style={{ width: "10%" }} />
                <col style={{ width: "10%" }} />
              </colgroup>
              <tbody>
                <tr>
                  <td>Size</td>
                  <td>
                    <Select
                      styles={customStyles}
                      value={size}
                      options={optionsView(
                        unitMeasure ? typetoSizeMetric[updateData?.type] : typetoSizeEnglish[updateData?.type],
                      )}
                      onChange={(e) => {
                        setSize(e);
                        const data = { ...updateData };
                        data.size = e.value;
                        setUpdateData(data);
                      }}
                    />
                  </td>
                  <td>{unitMeasure ? "mm" : "in."}</td>
                  <td></td>
                  <td>
                    <div class="form-check dark-tick-checkbox">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="size"
                        value="size"
                        defaultChecked={!recFound || titleList?.includes("size")}
                        onChange={(e) => {
                          const newValue = e.target.value.trim();
                          const data = { ...updateData };
                          let includeInTitleArray = data.includeInTitle ? data.includeInTitle.split(",") : [];
                          if (includeInTitleArray.includes(newValue)) {
                            includeInTitleArray = includeInTitleArray.filter((item) => item !== newValue);
                          } else {
                            includeInTitleArray.push(newValue);
                          }
                          data.includeInTitle = includeInTitleArray.join(",");
                          setUpdateData(data);
                        }}
                      />
                      <label class="form-check-label" for="size"></label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Weight</td>
                  <td>
                    <Select
                      styles={customStyles}
                      value={weight}
                      options={optionsView(
                        unitMeasure
                          ? sizetoWeightMetric[updateData?.type]?.[updateData?.size]
                          : sizetoWeightEnglish[updateData?.type]?.[updateData?.size],
                      )}
                      onChange={(e) => {
                        setWeight(e);
                        const data = { ...updateData };
                        data.weight = e.value;
                        setUpdateData(data);
                      }}
                    />
                  </td>
                  <td>{unitMeasure ? "kg/m" : "lbs/ft."}</td>
                  <td>
                    <img src={calculator} alt="" className="cursor-pointer" onClick={calWeight} />
                  </td>
                  <td>
                    <div class="form-check dark-tick-checkbox">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="weight"
                        value="weight"
                        defaultChecked={!recFound || titleList?.includes("weight")}
                        onChange={(e) => {
                          const newValue = e.target.value.trim();
                          const data = { ...updateData };
                          let includeInTitleArray = data.includeInTitle ? data.includeInTitle.split(",") : [];
                          if (includeInTitleArray.includes(newValue)) {
                            includeInTitleArray = includeInTitleArray.filter((item) => item !== newValue);
                          } else {
                            includeInTitleArray.push(newValue);
                          }
                          data.includeInTitle = includeInTitleArray.join(",");
                          setUpdateData(data);
                        }}
                      />
                      <label class="form-check-label" for="weight"></label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Wall Thickness</td>
                  <td>
                    <Select
                      styles={customStyles}
                      value={thikness}
                      options={optionsView(sizetoWallThikness[updateData?.type]?.[updateData?.size])}
                      onChange={(e) => {
                        setThikness(e);
                        const data = { ...updateData };
                        data.WT = e.value;
                        setUpdateData(data);
                      }}
                    />
                  </td>
                  <td>{unitMeasure ? "mm" : "in."}</td>
                  <td>
                    <img src={calculator} alt="" className="cursor-pointer" onClick={calcWT} />
                  </td>
                  <td>
                    <div class="form-check dark-tick-checkbox">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="wt"
                        value="wt"
                        defaultChecked={!recFound || titleList?.includes("wt")}
                        onChange={(e) => {
                          const newValue = e.target.value.trim();
                          const data = { ...updateData };
                          let includeInTitleArray = data.includeInTitle ? data.includeInTitle.split(",") : [];
                          if (includeInTitleArray.includes(newValue)) {
                            includeInTitleArray = includeInTitleArray.filter((item) => item !== newValue);
                          } else {
                            includeInTitleArray.push(newValue);
                          }
                          data.includeInTitle = includeInTitleArray.join(",");
                          setUpdateData(data);
                        }}
                      />
                      <label class="form-check-label" for="wt"></label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Yield</td>
                  <td>
                    <Select
                      styles={customStyles}
                      options={[{ value: "", label: "" }, ...optionsView(lstofYields)]}
                      value={yields}
                      onChange={(e) => {
                        setYields(e);
                        const data = { ...updateData };
                        data.yield = e.value;
                        setUpdateData(data);
                      }}
                    />
                  </td>
                  <td>ksi</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Grade</td>
                  <td>
                    <Select
                      styles={customStyles}
                      // options={optionsView(lstofGrades)}
                      options={
                        updateData?.yield === "ALL"
                          ? optionsView(allYieldtoGrade)
                          : optionsView(yieldtoGrade[updateData?.yield])
                      }
                      value={grade}
                      onChange={(e) => {
                        setGrade(e);
                        const data = { ...updateData };
                        data.grade = e.value;
                        setUpdateData(data);
                      }}
                    />
                  </td>
                  <td></td>
                  <td></td>
                  <td>
                    <div class="form-check dark-tick-checkbox">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="grade"
                        value="grade"
                        defaultChecked={!recFound || titleList?.includes("grade")}
                        onChange={(e) => {
                          const newValue = e.target.value.trim();
                          const data = { ...updateData };
                          let includeInTitleArray = data.includeInTitle ? data.includeInTitle.split(",") : [];
                          if (includeInTitleArray.includes(newValue)) {
                            includeInTitleArray = includeInTitleArray.filter((item) => item !== newValue);
                          } else {
                            includeInTitleArray.push(newValue);
                          }
                          data.includeInTitle = includeInTitleArray.join(",");
                          setUpdateData(data);
                        }}
                      />
                      <label class="form-check-label" for="grade"></label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Thread</td>
                  <td>
                    <Select
                      styles={customStyles}
                      options={[{ value: "", label: "" }, ...optionsView(lstofThreads)]}
                      value={thread}
                      onChange={(e) => {
                        setThread(e);
                        const data = { ...updateData };
                        data.thread = e.value;
                        setUpdateData(data);
                      }}
                    />
                  </td>
                  <td></td>
                  <td></td>
                  <td>
                    <div class="form-check dark-tick-checkbox">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="thread"
                        value="thread"
                        defaultChecked={!recFound || titleList?.includes("thread")}
                        onChange={(e) => {
                          const newValue = e.target.value.trim();
                          const data = { ...updateData };
                          let includeInTitleArray = data.includeInTitle ? data.includeInTitle.split(",") : [];
                          if (includeInTitleArray.includes(newValue)) {
                            includeInTitleArray = includeInTitleArray.filter((item) => item !== newValue);
                          } else {
                            includeInTitleArray.push(newValue);
                          }
                          data.includeInTitle = includeInTitleArray.join(",");
                          setUpdateData(data);
                        }}
                      />
                      <label class="form-check-label" for="thread"></label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>PSL</td>
                  <td>
                    <Select
                      styles={customStyles}
                      options={[{ value: "", label: "" }, ...optionsView(lstofPSLS)]}
                      value={psl}
                      onChange={(e) => {
                        setPsl(e);
                        const data = { ...updateData };
                        data.psl = e.value;
                        setUpdateData(data);
                      }}
                    />
                  </td>
                  <td></td>
                  <td></td>
                  <td>
                    <div class="form-check dark-tick-checkbox">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="psl"
                        value="psl"
                        defaultChecked={!recFound || titleList?.includes("psl")}
                        onChange={(e) => {
                          const newValue = e.target.value.trim();
                          const data = { ...updateData };
                          let includeInTitleArray = data.includeInTitle ? data.includeInTitle.split(",") : [];
                          if (includeInTitleArray.includes(newValue)) {
                            includeInTitleArray = includeInTitleArray.filter((item) => item !== newValue);
                          } else {
                            includeInTitleArray.push(newValue);
                          }
                          data.includeInTitle = includeInTitleArray.join(",");
                          setUpdateData(data);
                        }}
                      />
                      <label class="form-check-label" for="psl"></label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Length</td>
                  <td>
                    <Select
                      styles={customStyles}
                      options={[{ value: "", label: "" }, ...optionsView(lstofRanges)]}
                      value={length}
                      onChange={(e) => {
                        setLength(e);
                        const data = { ...updateData };
                        data.length = e.value;
                        setUpdateData(data);
                      }}
                    />
                  </td>
                  <td>{unitMeasure ? "m" : "ft."}</td>
                  <td></td>
                  <td>
                    <div class="form-check dark-tick-checkbox">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="length"
                        value="length"
                        defaultChecked={!recFound || titleList?.includes("length")}
                        onChange={(e) => {
                          const newValue = e.target.value.trim();
                          const data = { ...updateData };
                          let includeInTitleArray = data.includeInTitle ? data.includeInTitle.split(",") : [];
                          if (includeInTitleArray.includes(newValue)) {
                            includeInTitleArray = includeInTitleArray.filter((item) => item !== newValue);
                          } else {
                            includeInTitleArray.push(newValue);
                          }
                          data.includeInTitle = includeInTitleArray.join(",");
                          setUpdateData(data);
                        }}
                      />
                      <label class="form-check-label" for="length"></label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Coating</td>
                  <td>
                    <Select
                      styles={customStyles}
                      options={[{ value: "", label: "" }, ...optionsView(lstofCoatings)]}
                      value={coating}
                      onChange={(e) => {
                        setCoating(e);
                        const data = { ...updateData };
                        data.coating = e.value;
                        setUpdateData(data);
                      }}
                    />
                  </td>
                  <td></td>
                  <td></td>
                  <td>
                    <div class="form-check dark-tick-checkbox">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="coating"
                        value="coating"
                        defaultChecked={!recFound || titleList?.includes("coating")}
                        onChange={(e) => {
                          const newValue = e.target.value.trim();
                          const data = { ...updateData };
                          let includeInTitleArray = data.includeInTitle ? data.includeInTitle.split(",") : [];
                          if (includeInTitleArray.includes(newValue)) {
                            includeInTitleArray = includeInTitleArray.filter((item) => item !== newValue);
                          } else {
                            includeInTitleArray.push(newValue);
                          }
                          data.includeInTitle = includeInTitleArray.join(",");
                          setUpdateData(data);
                        }}
                      />
                      <label class="form-check-label" for="coating"></label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Pipe Construction</td>
                  <td>
                    <Select
                      styles={customStyles}
                      options={[{ value: "", label: "" }, ...optionsView(lstofPipeConstructs)]}
                      value={pipeConstr}
                      onChange={(e) => {
                        setPipeContr(e);
                        const data = { ...updateData };
                        data.pipeConstruct = e.value;
                        setUpdateData(data);
                      }}
                    />
                  </td>
                  <td></td>
                  <td></td>
                  <td>
                    <div class="form-check dark-tick-checkbox">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="pipeconstruct"
                        value="pipeconstruct"
                        defaultChecked={!recFound || titleList?.includes("pipeconstruct")}
                        onChange={(e) => {
                          const newValue = e.target.value.trim();
                          const data = { ...updateData };
                          let includeInTitleArray = data.includeInTitle ? data.includeInTitle.split(",") : [];
                          if (includeInTitleArray.includes(newValue)) {
                            includeInTitleArray = includeInTitleArray.filter((item) => item !== newValue);
                          } else {
                            includeInTitleArray.push(newValue);
                          }
                          data.includeInTitle = includeInTitleArray.join(",");
                          setUpdateData(data);
                        }}
                      />
                      <label class="form-check-label" for="pipeconstruct"></label>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="dividor"></div>
            <table class="table mb-0  product-table mt-2">
              <colgroup>
                <col style={{ width: "25%" }} />
                <col style={{ width: "35%" }} />
                <col />
                <col style={{ width: "10%" }} />
                <col style={{ width: "10%" }} />
              </colgroup>
              <tbody>
                <tr>
                  <td>Joint Count</td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      value={updateData?.jointCount}
                      onChange={(e) => {
                        const data = { ...updateData };
                        data.jointCount = e.target.value;
                        setUpdateData(data);
                      }}
                    />
                  </td>
                  <td></td>
                  <td></td>
                  <td>
                    <div class="form-check dark-tick-checkbox">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="jointCount"
                        value="jointCount"
                        defaultChecked={!recFound || titleList?.includes("jointCount")}
                        onChange={(e) => {
                          const newValue = e.target.value.trim();
                          const data = { ...updateData };
                          let includeInTitleArray = data.includeInTitle ? data.includeInTitle.split(",") : [];
                          if (includeInTitleArray.includes(newValue)) {
                            includeInTitleArray = includeInTitleArray.filter((item) => item !== newValue);
                          } else {
                            includeInTitleArray.push(newValue);
                          }
                          data.includeInTitle = includeInTitleArray.join(",");
                          setUpdateData(data);
                        }}
                      />
                      <label class="form-check-label" for="jointCount"></label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Total Length</td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      value={updateData?.totalLength}
                      onChange={(e) => {
                        const data = { ...updateData };
                        data.totalLength = e.target.value;
                        setUpdateData(data);
                      }}
                    />
                  </td>
                  <td>{unitMeasure ? "m" : "ft."}</td>
                  <td>
                    <img src={calculator} alt="" className="cursor-pointer" onClick={calcTotalLength} />
                  </td>
                  <td>
                    <div class="form-check dark-tick-checkbox">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="totalLength"
                        value="totalLength"
                        defaultChecked={!recFound || titleList?.includes("totalLength")}
                        onChange={(e) => {
                          const newValue = e.target.value.trim();
                          const data = { ...updateData };
                          let includeInTitleArray = data.includeInTitle ? data.includeInTitle.split(",") : [];
                          if (includeInTitleArray.includes(newValue)) {
                            includeInTitleArray = includeInTitleArray.filter((item) => item !== newValue);
                          } else {
                            includeInTitleArray.push(newValue);
                          }
                          data.includeInTitle = includeInTitleArray.join(",");
                          setUpdateData(data);
                        }}
                      />
                      <label class="form-check-label" for="totalLength"></label>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="dividor"></div>
            <table class="table mb-0  product-table mt-2">
              <colgroup>
                <col style={{ width: "25%" }} />
                <col style={{ width: "35%" }} />
                <col />
                <col style={{ width: "10%" }} />
                <col style={{ width: "10%" }} />
              </colgroup>
              <tbody>
                <tr>
                  <td>Mill</td>
                  <td>
                    <Select
                      styles={customStyles}
                      options={[{ value: "", label: "" }, ...optionsView(lstofMills)]}
                      value={mill}
                      onChange={(e) => {
                        setMill(e);
                        const data = { ...updateData };
                        data.mill = e.value;
                        setUpdateData(data);
                      }}
                    />
                  </td>
                  <td></td>
                  <td></td>
                  <td>
                    <div class="form-check dark-tick-checkbox">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="mill"
                        value="mill"
                        defaultChecked={!recFound || titleList?.includes("mill")}
                        onChange={(e) => {
                          const newValue = e.target.value.trim();
                          const data = { ...updateData };
                          let includeInTitleArray = data.includeInTitle ? data.includeInTitle.split(",") : [];
                          if (includeInTitleArray.includes(newValue)) {
                            includeInTitleArray = includeInTitleArray.filter((item) => item !== newValue);
                          } else {
                            includeInTitleArray.push(newValue);
                          }
                          data.includeInTitle = includeInTitleArray.join(",");
                          setUpdateData(data);
                        }}
                      />
                      <label class="form-check-label" for="mill"></label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Country of Origin</td>
                  <td>
                    <Select
                      styles={customStyles}
                      value={[origin]}
                      options={[{ value: "", label: "" }, ...countries]}
                      getOptionLabel={(val) => val.description}
                      getOptionValue={(val) => val.country_id}
                      onChange={(e) => {
                        setOrigin(e);
                        const data = { ...updateData };
                        data.country_id = e.country_id;
                        setUpdateData(data);
                      }}
                    />
                  </td>
                  <td></td>
                  <td></td>
                  <td>
                    <div class="form-check dark-tick-checkbox">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="country_id"
                        value="country_id"
                        defaultChecked={!recFound || titleList?.includes("country_id")}
                        onChange={(e) => {
                          const newValue = e.target.value.trim();
                          const data = { ...updateData };
                          let includeInTitleArray = data.includeInTitle ? data.includeInTitle.split(",") : [];
                          if (includeInTitleArray.includes(newValue)) {
                            includeInTitleArray = includeInTitleArray.filter((item) => item !== newValue);
                          } else {
                            includeInTitleArray.push(newValue);
                          }
                          data.includeInTitle = includeInTitleArray.join(",");
                          setUpdateData(data);
                        }}
                      />
                      <label class="form-check-label" for="country_id"></label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Year</td>
                  <td>
                    <Select
                      styles={customStyles}
                      options={[{ value: "", label: "" }, ...optionsView(lstofYears)]}
                      value={year}
                      onChange={(e) => {
                        setYear(e);
                        const data = { ...updateData };
                        data.year = e.value;
                        setUpdateData(data);
                      }}
                    />
                  </td>
                  <td></td>
                  <td></td>
                  <td>
                    <div class="form-check dark-tick-checkbox">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="year"
                        value="year"
                        defaultChecked={!recFound || titleList?.includes("year")}
                        onChange={(e) => {
                          const newValue = e.target.value.trim();
                          const data = { ...updateData };
                          let includeInTitleArray = data.includeInTitle ? data.includeInTitle.split(",") : [];
                          if (includeInTitleArray.includes(newValue)) {
                            includeInTitleArray = includeInTitleArray.filter((item) => item !== newValue);
                          } else {
                            includeInTitleArray.push(newValue);
                          }
                          data.includeInTitle = includeInTitleArray.join(",");
                          setUpdateData(data);
                        }}
                      />
                      <label class="form-check-label" for="year"></label>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="dividor"></div>
            <table class="table mb-0  product-table mt-2">
              <colgroup>
                <col style={{ width: "25%" }} />
                <col style={{ width: "35%" }} />
                <col />
                <col style={{ width: "10%" }} />
                <col style={{ width: "10%" }} />
              </colgroup>
              <tbody>
                <tr>
                  <td>Tonnage</td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      value={updateData?.tonnage}
                      onChange={(e) => {
                        const data = { ...updateData };
                        data.tonnage = e.target.value;
                        setUpdateData(data);
                      }}
                    />
                  </td>
                  <td>MT</td>
                  <td>
                    <img src={calculator} alt="" className="cursor-pointer" onClick={calcTonnage} />
                  </td>
                  <td>
                    <div class="form-check dark-tick-checkbox">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="tonnage"
                        value="tonnage"
                        defaultChecked={!recFound || titleList?.includes("tonnage")}
                        onChange={(e) => {
                          const newValue = e.target.value.trim();
                          const data = { ...updateData };
                          let includeInTitleArray = data.includeInTitle ? data.includeInTitle.split(",") : [];
                          if (includeInTitleArray.includes(newValue)) {
                            includeInTitleArray = includeInTitleArray.filter((item) => item !== newValue);
                          } else {
                            includeInTitleArray.push(newValue);
                          }
                          data.includeInTitle = includeInTitleArray.join(",");
                          setUpdateData(data);
                        }}
                      />
                      <label class="form-check-label" for="tonnage"></label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Mill Test Reports</td>
                  <td>
                    <Select
                      styles={customStyles}
                      options={[{ value: "", label: "" }, ...optionsView(lstofMillCerts)]}
                      value={millReport}
                      onChange={(e) => {
                        setMillReport(e);
                        const data = { ...updateData };
                        data.millCert = e.value;
                        setUpdateData(data);
                      }}
                    />
                  </td>
                  <td></td>
                  <td></td>
                  <td>
                    <div class="form-check dark-tick-checkbox">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="millcert"
                        value="millcert"
                        defaultChecked={!recFound || titleList?.includes("millcert")}
                        onChange={(e) => {
                          const newValue = e.target.value.trim();
                          const data = { ...updateData };
                          let includeInTitleArray = data.includeInTitle ? data.includeInTitle.split(",") : [];
                          if (includeInTitleArray.includes(newValue)) {
                            includeInTitleArray = includeInTitleArray.filter((item) => item !== newValue);
                          } else {
                            includeInTitleArray.push(newValue);
                          }
                          data.includeInTitle = includeInTitleArray.join(",");
                          setUpdateData(data);
                        }}
                      />
                      <label class="form-check-label" for="millcert"></label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Mill Certs Received</td>
                  <td>
                    <div class="form-check dark-tick-checkbox">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="millCertReceived"
                        // value={1}
                        checked={updateData?.millCertsReceived}
                        onChange={(e) => {
                          const data = { ...updateData };
                          data.millCertsReceived = e.target.checked;
                          setUpdateData(data);
                        }}
                      />
                      <label class="form-check-label" for="millCertReceived"></label>
                    </div>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>MTR Push</td>
                  <td>
                    <div class="form-check dark-tick-checkbox">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="mtrPush"
                        // value={1}
                        checked={updateData?.push_mtr}
                        onChange={(e) => {
                          const data = { ...updateData };
                          data.push_mtr = e.target.checked;
                          setUpdateData(data);
                        }}
                      />
                      <label class="form-check-label" for="mtrPush"></label>
                    </div>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Estimated Scrap Price</td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      value={updateData?.scrapPrice}
                      onChange={(e) => {
                        const data = { ...updateData };
                        data.scrapPrice = e.target.value;
                        setUpdateData(data);
                      }}
                    />
                  </td>
                  <td>USD</td>
                  <td>
                    <input
                      type="text"
                      value={priceperton}
                      className="form-control"
                      onChange={(e) => {
                        setPriceperton(e.target.value);
                      }}
                      onBlur={calcScrapPrice}
                    />
                    <sub>price/ton</sub>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>Additional Information</td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      value={updateData?.addInfo}
                      onChange={(e) => {
                        const data = { ...updateData };
                        data.addInfo = e.target.value;
                        setUpdateData(data);
                      }}
                    />
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            <sub className="mt-2">
              <i>
                Disclaimer:
                <br /> Calculator should only be used to estimate Wall Thickness and Weight if documentation is not
                available.
              </i>
            </sub>
            <div className="mt-1 d-flex justify-content-end">
              <button className="btn btn-auto btn-xs-size btn-primary" onClick={handleUpdate}>
                Save
              </button>
            </div>
            <div className="d-flex align-items-center mt-3">
              <span className="fw-medium me-2">Populate Qty with:</span>
              <div className="d-flex">
                <div className="radio pe-2">
                  <input
                    className="form-check-input cursor-pointer me-1"
                    value={1}
                    type="radio"
                    name="populateQtyWith"
                    checked={updateData?.populateQtyChoice == 1}
                    id="jointCount"
                    onChange={(e) => {
                      const data = { ...updateData };
                      data.populateQtyChoice = e.target.value;
                      setUpdateData(data);
                    }}
                  />
                  <label className="form-check-label" htmlFor="jointCount">
                    Joint Count
                  </label>
                </div>
                <div className="radio pe-2">
                  <input
                    className="form-check-input cursor-pointer me-1"
                    value={2}
                    type="radio"
                    name="populateQtyWith"
                    checked={updateData?.populateQtyChoice == 2}
                    id="totalLength"
                    onChange={(e) => {
                      const data = { ...updateData };
                      data.populateQtyChoice = e.target.value;
                      setUpdateData(data);
                    }}
                  />
                  <label className="form-check-label" htmlFor="totalLength">
                    Total Length
                  </label>
                </div>
                <div className="radio pe-2">
                  <input
                    className="form-check-input cursor-pointer me-1"
                    value={3}
                    type="radio"
                    name="populateQtyWith"
                    checked={updateData?.populateQtyChoice == 3}
                    id="tonnage"
                    onChange={(e) => {
                      const data = { ...updateData };
                      data.populateQtyChoice = e.target.value;
                      setUpdateData(data);
                    }}
                  />
                  <label className="form-check-label" htmlFor="tonnage">
                    Tonnage
                  </label>
                </div>
              </div>
            </div>
            <div className="mt-1 d-flex justify-content-end">
              <button
                className="btn btn-auto btn-xs-size btn-primary"
                onClick={() => {
                  const data = { ...updateData };
                  data.populateQty = true;
                  setUpdateData(data);
                  handlePopulateQty();
                }}
              >
                Populate Qty
              </button>
            </div>
          </div>
          <div className="dividor"></div>
          <div class="d-flex justify-content-center">
            <div class="page-widget-label text-capitalize">GENERATED</div>
          </div>
          <div class="form-group-15" style={{ fontSize: "0.75rem" }}>
            <label for="" class="form-label fw-medium">
              Title
            </label>
            <textarea class="form-control" readOnly value={pipeData?.title}></textarea>
            <button
              className="btn btn-auto btn-xs-size btn-primary mt-2"
              onClick={() => {
                const data = { ...updateData };
                data.populateTitle = true;
                setUpdateData(data);
                handlePopulateTitle();
              }}
            >
              Populate Title
            </button>
          </div>
          <div class="form-group-15">
            <label for="" class="form-label fw-medium">
              Condition
            </label>
            <textarea class="form-control" readOnly value={pipeData?.summaryCondition}></textarea>
            <button
              className="btn btn-auto btn-xs-size btn-primary mt-2"
              onClick={() => {
                const data = { ...updateData };
                data.populateCondition = true;
                setUpdateData(data);
                handlePopulateCondition();
              }}
            >
              Populate Condition
            </button>
          </div>
          <div class="form-group-15">
            <label for="" class="form-label fw-medium">
              Description
            </label>
            <textarea class="form-control" readOnly value={pipeData?.description}></textarea>
            <button
              className="btn btn-auto btn-xs-size btn-primary mt-2"
              onClick={() => {
                const data = { ...updateData };
                data.populateDescription = true;
                setUpdateData(data);
                handlePopulateDescription();
              }}
            >
              Populate Description
            </button>
          </div>
          <button
            className="btn btn-auto btn-xs-size btn-primary mt-2"
            onClick={() => {
              const data = { ...updateData };
              data.populateAll = true;
              setUpdateData(data);
              handlePopulateAll();
            }}
          >
            Populate All
          </button>
          <div className="mt-2">
            <sub>
              <i>
                note: Clicking the populate buttons will overwrite any existing content in their respective auctions
                fields.
              </i>
            </sub>
          </div>
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getPipeDetails: (id, callback) => dispatch(getPipeDetails(id, callback)),
  updatePipeDetails: (id, data, callback) => dispatch(updatePipeDetails(id, data, callback)),
});

const mapStateToProps = (state) => ({
  getListingDetails: getListingProductDetail(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(PipeDetails));
