import React, { useState } from "react";
import { Offcanvas } from "react-bootstrap";
import close from "../../include/images/close-12x12.svg";
import pdfIcon from "../../include/images/pdf-file-icon.svg";
import csvFile from "../../include/images/csv-latest.svg";
import XLSX from "../../include/images/XLSX.svg";
import XLS from "../../include/images/XLS.svg";
import DOCX from "../../include/images/Docx.svg";
import DOC from "../../include/images/Doc.svg";
import crossIcon from "../../include/images/cross-icon.svg";
import trash from "../../include/images/trash-24x24.svg";

const UploadDocuments = (props) => {
  const {
    show,
    onHide,
    getListingData,
    onChangeDocuments,
    uploadDocuments,
    setDocTitle,
    docTitle,
    handleSubmitDocument,
    loading,
    error,
    deleteHandler,
    setFileType,
    fileType,
  } = props;

  return (
    <Offcanvas
      placement="end"
      backdropClassName="upload-documnets-backdrop"
      show={show}
      onHide={() => onHide()}
      className="offcanvas offcanvas-end border-0"
      id="uploadDocumentOffcanvas"
      enforceFocus={false}
    >
      <Offcanvas.Header className="offcanvas-header">
        <h5 class="offcanvas-title">
          Upload {props?.type == "Public" ? "Public" : props?.type == "aviation" ? "Aviation Parts" : "Private"}{" "}
          Documents
        </h5>
        <button
          type="button"
          class="offcanvas-close-btn d-flex align-items-center justify-content-center"
          onClick={() => onHide()}
        >
          <img src={close} alt="" />
        </button>
      </Offcanvas.Header>
      <Offcanvas.Body className="offcanvas-body p-0">
        <div class="offcanvas-widget-row pt-0">
          <p>
            Documents must be in PDF, XLS, XLSX, DOC, DOCX or CSV format. You must specify a title for each document.
          </p>
          <div class="mb-20">
            <label for="slectFileType" class="form-label">
              Select File Type
            </label>
            <select
              class="form-select"
              aria-label="Default select example"
              onChange={(e) => setFileType(e.target.value)}
            >
              <option selected value=".pdf">
                PDF
              </option>
              <option value=".csv">CSV</option>
              <option value=".xls">XLS</option>
              <option value=".xlsx">XLSX</option>
              <option value=".doc">DOC</option>
              <option value=".docx">DOCX</option>
            </select>
          </div>
          <div class="mb-20">
            <label for="slectDocument" class="form-label">
              Select Document
            </label>
            <div class="upload-lg-button position-relative">
              <span class="file-input">
                <input
                  type="file"
                  id="inputGroupFile02"
                  accept={`${fileType}`}
                  onChange={(e) => onChangeDocuments(e)}
                  onClick={(e) => (e.target.value = null)}
                />
                <span id="upload-file-name" class="d-flex align-items-center">
                  {uploadDocuments && uploadDocuments.length > 0 && uploadDocuments[0].name}
                  {uploadDocuments && uploadDocuments.length ? (
                    <img class="cross-icon-box" src={crossIcon} alt="" onClick={() => onChangeDocuments([])} />
                  ) : (
                    <p>Please select any document</p>
                  )}
                </span>
              </span>
            </div>
            {error && error.document ? error.document : ""}
          </div>
          {props?.type != "aviation" ? (
            <div class="mb-20">
              <label for="documentTitle" class="form-label">
                Document Title
              </label>
              <input type="text" class="form-control" value={docTitle} onChange={(e) => setDocTitle(e.target.value)} />
              {error && error.title ? error.title : ""}
            </div>
          ) : (
            ""
          )}
          <div class="d-grid-button d-grid gap-2 mt-30">
            <button
              class={`btn btn-default ${loading ? "btn-disabled" : ""}`}
              type="button"
              onClick={(e) => !loading && handleSubmitDocument(e)}
            >
              {loading ? "Please Wait" : "Save"}
            </button>
          </div>
        </div>
        {props?.type != "aviation" && getListingData && getListingData.docs && getListingData.docs.length > 0 && (
          <div class="offcanvas-widget-row">
            <div class="page-title mb-3">All {props?.type == "Public" ? "Public" : "Private"} Documents</div>
            <div class="table-responsive mb-3">
              <table class="table align-middle table-borderless publicDocTable mb-0">
                <colgroup>
                  <col style={{ width: "50px" }} />
                  <col />
                  <col style={{ width: "50px" }} />
                </colgroup>
                <tbody>
                  {getListingData &&
                    getListingData.docs &&
                    getListingData.docs.length > 0 &&
                    getListingData.docs.map((item) => {
                      const filenameArray = item.filename.split(".");
                      const fileType = filenameArray[filenameArray.length - 1];
                      return (
                        <tr>
                          <td>
                            <div class="td-pdf-img-box">
                              <img
                                src={
                                  fileType === "csv"
                                    ? csvFile
                                    : fileType === "xlsx"
                                    ? XLSX
                                    : fileType === "xls"
                                    ? XLS
                                    : fileType === "doc"
                                    ? DOC
                                    : fileType === "docx"
                                    ? DOCX
                                    : pdfIcon
                                }
                                alt=""
                              />
                            </div>
                          </td>
                          <td>
                            <a
                              href={
                                item.filename.substring(0, 5) === "https"
                                  ? `${item.filename}`
                                  : item.ft_id === 10
                                  ? `${process.env.REACT_APP_S3_PRIVATE_DOC_BUCKET}${item.folder}${item.filename}`
                                  : `${process.env.REACT_APP_MEDIA_URL}${item.filename}`
                              }
                              target="_blank"
                              download={true}
                              className="deafult-black-hover-green"
                            >
                              {item.name.includes(".") ? item.name : item.name + "." + fileType}
                            </a>
                          </td>
                          <td>
                            <button
                              type="button"
                              class="delete-td-icon d-flex align-items-center justify-content-center"
                              onClick={() => {
                                props.setSelectedItem(item);
                                props.onDelete();
                              }}
                            >
                              <img src={trash} alt="" />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default UploadDocuments;
