import React, { useEffect, useState } from "react";
import { Route, Switch, BrowserRouter, Redirect } from "react-router-dom";

import Signin from "./layouts/signin";
import ListingDetailPage from "./pages/listingDetailPage";
import SearchTable from "./pages/searchTable";
import Users from "./pages/users";
// import Chats from "./pages/chats";
import PipeLog from "./pages/pipeLog";
import Reports from "./pages/reports";
import LoginReport from "./pages/loginReport";
import CreateInvoice from "./pages/invoices/create-invoice";
import ViewInvoice from "./pages/invoices/view-invoice";
import Aviation from "./pages/aviation";
import Log from "./pages/aviation/log";
import Procurement from "./pages/aviation/procurement";
import OrderRequest from "./pages/aviation/order-request";
import Investments from "./pages/accounting/investments";
import Commission from "./pages/accounting/commission";
import Error404 from "./pages/404";
import Contracts from "./pages/legal/contracts";
import EnterpriseContracts from "./pages/legal/enterpriseContracts";
import SellerAgreement from "./pages/agreements/sellerAgreement";
import HomePage from "./pages/homePage/homePage";
import ResearchAssignments from "./pages/researchAssignments/researchAssignments";
import ManufacturerAndModel from "./pages/manufacturerAndModels/manufacturerAndModel";
import Models from "./pages/manufacturerAndModels/models";
import WeeklyEmail from "./pages/reports/weeklyEmail/weeklyEmail";
import AviationModels from "./pages/settings/aviationModels";
import EmailSentSuccssfully from "./pages/successPages/email-sent-succssfully";
import MarketingEmail from "./pages/marketing/marketingEmail";
import AviationConditions from "./pages/settings/aviationConditions";
import PlanePartType from "./pages/settings/planePartType";
import PlaneModel from "./pages/settings/planeModel";
import PlanePartManufacturer from "./pages/settings/planePartManufacturer";
import PlaneManufacturer from "./pages/settings/planeManufacturer";
import PlaneLocation from "./pages/settings/planeLocation";
import Error500 from "./pages/500";
import WeeklyAssetRecoveryReport from "./pages/settings/weeklyAssetRecoveryReport";
import ListingAgreements from "./pages/legal/listingAgreements";
import SellerListings from "./pages/settings/sellerListings";
import SustainabilityReport from "./pages/reports/sustainabilityReport/sustainabilityReport";
import Referrals from "./pages/referrals/referrals";
import EiReferredListings from "./pages/settings/eiReferredListings";
import BuyerAssetRecoveryReport from "./pages/reports/weeklyEmail/buyerAssetRecoveryReport";
import BuyerListings from "./pages/settings/buyerListings";
import BuyerARREmailStats from "./pages/report/buyerARREmailStats";
import QuestionsAnswers from "./pages/questions-answers";
import KeyBuyerListings from "./pages/settings/keyBuyerListings";
import { questionDetails } from "./store/questions";

import ReminderMessages from "./layouts/modals/reminderMessages/reminderMessages";
import { connect } from "react-redux";
import IndividualPushReport from "./pages/reports/pushReports/individualPushReport";
import IndividualPushSuccess from "./pages/successPages/individualPushSuccess";
import GenericParts from "./pages/parts/genericParts";
import PartManufacturer from "./pages/parts/partManufacturer";
import PartCondition from "./pages/parts/partCondition";
import PartRfq from "./pages/parts/partRfq";
import PartProcurement from "./pages/parts/partProcurement";
import PartModel from "./pages/parts/partModel";
import PartLogs from "./pages/parts/partLogs";
import PartLocation from "./pages/parts/partLocation";
import NewRelease from "./pages/views/newRelease";
import NcndAgreement from "./pages/agreements/ncndAgreement";
import ReminderParts from "./pages/aviation/reminder-parts";
import UploadncndAgreement from "./pages/agreements/uploadncndAgreement";
import NcndaEdit from "./components/settings/ncnda/ncndaEdit";
import ArchivedAviation from "./pages/archivedAviation";
import ArchivedGenericParts from "./pages/parts/archivedGenericParts";

const Routes = (props) => {
  const [showReminerMessages, setShowReminderMessages] = useState(false);

  useEffect(() => {
    if (props?.questionDetails?.length > 0) {
      if (props?.questionDetails?.filter((it) => !it?.is_read)?.length > 0) {
        setShowReminderMessages(true);
      }
    }
  }, [props?.questionDetails]);

  const data = props?.questionDetails?.filter((it) => !it?.is_read);

  return (
    <>
      <ReminderMessages
        show={showReminerMessages}
        onHide={() => setShowReminderMessages((prev) => !prev)}
        data={data}
      />
      <BrowserRouter>
        <Switch>
          <Route exact path="/">
            <SearchTable />
          </Route>
          <Route exact path="/home">
            <HomePage />
          </Route>
          <Route exact path="/signin">
            <Signin />
          </Route>
          <Route exact path="/listing-detail">
            <ListingDetailPage />
          </Route>
          <Route exact path="/users">
            <Users />
          </Route>
          <Route exact path="/referrals">
            <Referrals />
          </Route>
          <Route exact path="/chats">
            {/* <Chats /> */}
            <QuestionsAnswers />
          </Route>
          <Route exact path="/pipe-access-report">
            <PipeLog />
          </Route>
          <Route exact path="/buyer-arr-email-stats">
            <BuyerARREmailStats />
          </Route>
          <Route exact path="/user-registration-report">
            <Reports />
          </Route>
          <Route exact path="/login-report">
            <LoginReport />
          </Route>
          <Route exact path="/invoice/create-invoice">
            <CreateInvoice />
          </Route>
          <Route exact path="/views/new-release">
            <NewRelease />
          </Route>
          <Route exact path="/report/seller-asset-recovery-report">
            <WeeklyEmail />{" "}
          </Route>
          <Route exact path="/report/buyer-asset-recovery-report">
            <BuyerAssetRecoveryReport />
          </Route>
          <Route exact path="/invoice/view-invoice">
            <ViewInvoice />
          </Route>
          <Route exact path="/aviation/aircraft-parts">
            <Aviation />
          </Route>
          <Route exact path="/aviation/archived-aircraft-parts">
            <ArchivedAviation />
          </Route>
          <Route exact path="/accounting/employee-investments">
            <Investments />
          </Route>
          <Route exact path="/accounting/commission">
            <Commission />
          </Route>
          <Route exact path="/aviation/logs">
            <Log />
          </Route>
          <Route exact path="/aviation/procurement-requests">
            <Procurement />
          </Route>
          <Route exact path="/aviation/order-requests">
            <OrderRequest />
          </Route>
          <Route exact path="/parts">
            <GenericParts />
          </Route>
          <Route exact path="/parts/archived">
            <ArchivedGenericParts />
          </Route>
          <Route exact path="/parts/part-manufacturer">
            <PartManufacturer />
          </Route>
          <Route exact path="/parts/condition">
            <PartCondition />
          </Route>
          <Route exact path="/parts/order-requests">
            <PartRfq />
          </Route>
          <Route exact path="/parts/procurement-requests">
            <PartProcurement />
          </Route>
          <Route exact path="/parts/models">
            <PartModel />
          </Route>
          <Route exact path="/parts/part-locations">
            <PartLocation />
          </Route>
          <Route exact path="/parts/logs">
            <PartLogs />
          </Route>
          <Route exact path="/legal/contracts">
            <Contracts />
          </Route>
          <Route exact path="/settings/ncnda-edit">
            <NcndaEdit />
          </Route>
          <Route exact path="/settings/manufacturers">
            <ManufacturerAndModel />
          </Route>
          <Route exact path="/settings/models">
            <Models />
          </Route>
          <Route exact path="/settings/ei-referred-listings">
            <EiReferredListings />
          </Route>
          <Route exact path="/aviation/aviation-models">
            <AviationModels />
          </Route>
          <Route exact path="/aviation/aviation-condition">
            <AviationConditions />
          </Route>
          <Route exact path="/aviation/plane-part-type">
            <PlanePartType />
          </Route>
          <Route exact path="/aviation/plane-model">
            <PlaneModel />
          </Route>
          <Route exact path="/aviation/plane-part-manufacturer">
            <PlanePartManufacturer />
          </Route>
          <Route exact path="/aviation/plane-manufacturer">
            <PlaneManufacturer />
          </Route>
          <Route exact path="/aviation/plane-location">
            <PlaneLocation />
          </Route>
          <Route exact path="/aviation/reminder-parts">
            <ReminderParts />
          </Route>
          <Route exact path="/settings/seller-asset-recovery-report/listings">
            <WeeklyAssetRecoveryReport />
          </Route>
          <Route exact path="/settings/listing-sellers">
            <SellerListings />
          </Route>
          <Route exact path="/settings/listing-buyers">
            <BuyerListings />
          </Route>
          <Route exact path="/settings/key-buyers">
            <KeyBuyerListings />
          </Route>
          <Route exact path="/legal/enterprise-contracts">
            <EnterpriseContracts />
          </Route>
          <Route exact path="/legal/listing-agreement">
            <ListingAgreements />
          </Route>
          <Route exact path="/seller-agreement/view">
            <SellerAgreement />
          </Route>
          <Route exact path="/ncnd-agreement/view">
            <NcndAgreement />
          </Route>
          <Route exact path="/ncnd-agreement/upload">
            <UploadncndAgreement />
          </Route>
          <Route exact path="/research-assignment">
            <ResearchAssignments />
          </Route>
          <Route exact path="/email-sent-successfully">
            <EmailSentSuccssfully />
          </Route>
          <Route exact path="/marketing-email">
            <MarketingEmail />
          </Route>
          <Route exact path="/report/sustainability-report">
            <SustainabilityReport />
          </Route>
          <Route exact path="/listings/report/individual-push">
            <IndividualPushReport />
          </Route>
          <Route exact path="/listings/report/individual-push/success">
            <IndividualPushSuccess />
          </Route>
          <Route exact path="/500">
            <Error500 />
          </Route>
          <Route exact path="*">
            <Error404 />
          </Route>
        </Switch>
      </BrowserRouter>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({});

const mapStateToProps = (state) => ({
  questionDetails: questionDetails(state)?.reminderQuestion?.data,
});

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
