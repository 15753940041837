import React, { useState } from "react";
import { connect } from "react-redux";
import { getCompletedTasks, getTasks } from "../../../store/tasks";
import NextButton from "../../../common/form/nextButton";
import { toast } from "react-toastify";
import AlertError from "../../../common/alerts/alertError";
import HTMLReactParser from "html-react-parser";
import DateFormat from "../../../common/DateFormat/dateFormat";
import Skeleton from "react-loading-skeleton";

const CompletedTasks = (props) => {
  const [showTasks, setShowTasks] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listingData, setListingData] = useState([]);
  const [fetchCount, setFetchCount] = useState(0);
  const count = props?.count?.completedTask;

  const fetchCompletedTasks = () => {
    setLoading(true);
    props.refStart();
    props.getCompletedTasks(props.auctionId, (res) => {
      if (res.status === 200) {
        setLoading(false);
        props.refStop();
        setListingData(res?.data?.data);
        setFetchCount(fetchCount + 1);
        setShowTasks(true);
      } else {
        setLoading(false);
        props.refStop();
        toast(<AlertError message={res?.data?.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const fetchCompletedTasksState = () => {
    setLoading(true);
    setListingData(props?.getTasks?.completedTasks?.data);
    setShowTasks(true);
    setLoading(false);
  };
  return (
    <div class="details-page-widget-block" id={props.id}>
      <div class="page-widget-head d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <div class="d-flex align-items-center">
            <div
              class="page-widget-label text-capitalize cursor-pointer"
              onClick={() => {
                if (showTasks) {
                  setShowTasks(false);
                } else if (fetchCount === 0) {
                  fetchCompletedTasks();
                } else {
                  fetchCompletedTasksState();
                }
              }}
            >
              Completed Tasks {!props.loadingMain &&( count > 0 ? `(${count})` : "(0)")}
            </div>
          </div>
          <li
            className="badge-item cursor-pointer"
            onClick={() => {
              if (showTasks) {
                setShowTasks(false);
              } else if (fetchCount === 0) {
                fetchCompletedTasks();
              } else {
                fetchCompletedTasksState();
              }
            }}
          >
            <div className="badge-box" style={{ background: "#DADEE0" }}>
              {showTasks ? "Hide" : "Show"}
            </div>
          </li>
        </div>
        {/* {showTasks ? (
          <div class="btn-block">
            <a href="javascript:void(0)" onClick={() => setShowTasks(false)} class="btn btn-primary btn-sm">
              Hide
            </a>
          </div>
        ) : fetchCount === 0 ? (
          !props.loadingMain && (
            <a
              href="javascript:void(0)"
              class={`btn btn-primary btn-sm ${loading ? "disabled" : ""}`}
              onClick={() => fetchCompletedTasks()}
            >
              View
            </a>
          )
        ) : (
          !props.loadingMain && (
            <NextButton
              label="View"
              classData="btn btn-default"
              handleSubmit={fetchCompletedTasksState}
              loading={loading}
            />
          )
        )} */}
      </div>
      {props.loadingMain ? (
        <Skeleton height="250px" width="100%" />
      ) : (
        <div class={showTasks && "page-widget-body mt-3"}>
          {showTasks && (
            <div class="table-responsive">
              <table class="table align-left">
                <colgroup>
                  <col style={{ width: "70%" }} />
                  <col style={{ width: "30%" }} />
                </colgroup>
                <thead>
                  <tr>
                    <th>Task</th>
                    <th>Date Completed</th>
                  </tr>
                </thead>
                <tbody>
                  {listingData?.map((val) => (
                    <tr>
                      <td>
                        <span class="fw-medium">{HTMLReactParser(val?.description)}</span>
                      </td>
                      <td>
                        <span class="fw-medium">
                          {val?.date_completed ? <DateFormat date={val?.date_completed} /> : "N/A"}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getCompletedTasks: (params, callback) => dispatch(getCompletedTasks(params, callback)),
});
const mapStateToProps = (state) => ({
  getTasks: getTasks(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(CompletedTasks));
