import React, { useEffect, useRef, useState } from "react";
import Header from "../../layouts/header";
import Sidebar from "../../layouts/sidebar";
import LoadingBar from "react-top-loading-bar";
import ComingSoonCard from "../../layouts/comingSoonCard";
import NewestHighBids from "../../components/homePage/newestHighBids/newestHighBids";
import ReadyToCloseListings from "../../components/homePage/readyToCloseListings/readyToCloseListings";
import BigDealListings from "../../components/homePage/bigDealListings/bigDealListings";
import AllListings from "../../components/homePage/allListings/allListings";
import PendingListings from "../../components/homePage/pendingLisings/pendingListings";
import ClosedListings from "../../components/homePage/closedListings/closedListings";
import InvoicedListings from "../../components/homePage/invoicedListings/invoicedListings";
import UnassignedListings from "../../components/homePage/unAssignedListings/unAssignedListings";
import ListingReadyToBePushed from "../../components/homePage/listingReadyToBePushed/listingReadyToBePushed";
import ArchivedListings from "../../components/homePage/archivedListings/archivedListings";
import CommodityTrader from "../../components/homePage/commodityTrader/commodityTrader";
import ActiveListings from "../../components/homePage/activeListings/activeListings";
import {
  dashboardData,
  getAccountManagement,
  getAccountManagementCount,
  getAllOtherListings,
  getBuAssignment,
  getCustomers,
  getEmployees,
  getEnterpriseAssignment,
  getListingCount,
  getMainCateogry,
  getNeglectedOrUrgent,
  getRegions,
  getSellerAssignment,
  getTaskAndCount,
  getUnasweredBuyerQuestions,
} from "../../store/dashBoard";
import { toast } from "react-toastify";
import AlertError from "../../common/alerts/alertError";
import { connect } from "react-redux";
import { Accordion } from "react-bootstrap";
import PendingResearchAssignment from "../../components/homePage/pendingResearchAssignment/pendingResearchAssignment";
import CompletedResearchAssignment from "../../components/homePage/completedResearchAssignment/completedResearchAssignment";
import Menu from "../../components/homePage/menu/menu";
import AccountManagement from "../../components/homePage/accountManagement/accountManagement";
import Customers from "../../components/homePage/customers/customers";
import AllOtherListings from "../../components/homePage/allOtherListings/allOtherListings";
import NeglectedOrUrgent from "../../components/homePage/neglectedOrUrgent/neglectedOrUrgent";
import neglectedOrUrgent from "../../components/homePage/neglectedOrUrgent/neglectedOrUrgent";
import BuAssignments from "../../components/homePage/BuAssignments/BuAssignments";
import EnterpriseAssignments from "../../components/homePage/enterpriseAssignments/enterpriseAssignments";
import SellerAssignments from "../../components/homePage/sellerAssignments/sellerAssignments";
import Skeleton from "react-loading-skeleton";
import ProfileActivity from "../../components/homePage/profileActivity/profileActivity";
import { addMessage, changeUnsubscribe, deleteMessage, getRecipient } from "../../store/emailClickers";
import ListingAgreement from "../../components/homePage/listingAgreement/listingAgreement";
import MyListingBuyerInterest from "../../components/homePage/customers/myListingBuyerInterest";

const HomePage = (props) => {
  const ref = useRef(null);
  const val = localStorage.getItem("data");
  const session = JSON.parse(val);
  //For SideNav
  const [showMenu, setShowMenu] = useState(false);
  const [menuList, setMenuList] = useState("");
  const [count, setCount] = useState("");
  const [loadingCount, setLoadingCount] = useState(false);
  const [loadingEmp, setLoadingEmp] = useState(false);
  const [loadingMain, setLoadingMain] = useState(false);
  const [loadingMenu, setLoadingMenu] = useState(false);
  //params
  const [empId, setEmpId] = useState(session.member_id);
  const [pt, setPt] = useState(0);
  const [pl, setPl] = useState(0);
  const [showOnly, setShowOnly] = useState(0);
  const [sortBy, setSortBy] = useState(0);
  const [viewType, setViewType] = useState(1);
  const [pushYesNo, setPushYesNo] = useState(0);
  const [showBy, setShowBy] = useState("listing");

  const [showSections, setShowSections] = useState(false);
  const [section, setSection] = useState("");
  const [accountManagementType, setAccountManagementType] = useState("");
  const [accountManagementStatus, setAccountManagementStatus] = useState(0);
  const [customerType, setCustomerType] = useState("");
  const [urgentType, setUrgentType] = useState("");
  const [viewContent, setViewContent] = useState(false);
  const [taskType, setTaskType] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [menuTitle, setMenuTitle] = useState("");

  const accountManagementData = props?.dashboardData?.accountManagement;
  const customerData = props?.dashboardData?.customers;
  const allOtherData = props?.dashboardData?.allOtherListings;
  const buAssignmentData = props?.dashboardData?.buAssignment;
  const enterpriseAssignmentData = props?.dashboardData?.enterpriseAssignment;
  const sellerAssignmentData = props?.dashboardData?.sellerAssignment;
  const employeeList = props?.dashboardData?.employees?.data ? props?.dashboardData?.employees?.data : [];
  const regionsList = props?.dashboardData?.regions?.data ? props?.dashboardData?.regions?.data : [];
  const mainCategories = props?.dashboardData?.mainCategories?.data ? props?.dashboardData?.mainCategories?.data : [];

  useEffect(() => {
    const getListingCount = () => {
      setViewContent(true);
      setRefresh(!refresh);
      setLoadingCount(true);
      ref?.current?.continuousStart();
      props.getListingCount(empId, showOnly, pl, pt, sortBy, viewType, (res) => {
        if (res.status === 200) {
          setCount(res.data.data);
          setLoadingCount(false);
          ref?.current?.complete();
        } else {
          setLoadingCount(false);
          ref?.current?.complete();
          toast(<AlertError message={res.data.massage ? res.data.message : "Something Went Wrong"} />);
        }
      });
    };
    getListingCount();
  }, [empId, pt, pl, showOnly, sortBy, viewType, pushYesNo]);

  useEffect(() => {
    if (viewContent) {
      setShowSections(false);
    }
  }, [refresh]);

  //get employees
  useEffect(() => {
    const getEmployees = () => {
      setLoadingEmp(true);
      props.getEmployees((res) => {
        if (res.status === 200) {
          setLoadingEmp(false);
        } else {
          setLoadingEmp(false);
          toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        }
      });
    };
    getEmployees();
  }, []);

  useEffect(() => {
    if (loadingCount || loadingEmp) {
      setLoadingMain(true);
    } else {
      setLoadingMain(false);
    }
  }, [loadingCount, loadingEmp]);

  //Menu

  //Account Management
  const fetchAccountManagement = (type, status) => {
    setAccountManagementType(type);
    setAccountManagementStatus(status);
    setShowSections(false);
    setLoadingMenu(true);
    ref?.current?.continuousStart();
    props.getAccountManagement(empId, type, 1, status, (res) => {
      if (res.status === 200) {
        ref?.current?.complete();
        setLoadingMenu(false);
        setShowSections(true);
      } else {
        ref?.current?.complete();
        setLoadingMenu(false);
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  //Customers
  const fetchCustomers = (type) => {
    setCustomerType(type);
    setShowSections(false);
    setLoadingMenu(true);
    ref?.current?.continuousStart();
    const payload = {
      aType: type,
      employeeId: empId,
      page: 1,
      filterInterest: "",
    };
    props.getCustomers(payload, (res) => {
      if (res.status === 200) {
        setShowSections(true);
        setLoadingMenu(false);
        ref?.current?.complete();
      } else {
        setLoadingMenu(false);
        ref?.current?.complete();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  useEffect(() => {
    if (showBy === "listing") {
      fetchCustomers("buyer_interest_listings");
    } else {
      props?.getRegions((res) => {});
      props?.getMainCateogry((res) => {});
      fetchCustomers("active_buyers");
    }
  }, [showBy]);

  //All Other Listings
  const fetchAllOtherListings = (type) => {
    setShowSections(false);
    setLoadingMenu(true);
    ref?.current?.continuousStart();
    props.getAllOtherListings(empId, pl, pt, type, (res) => {
      if (res.status === 200) {
        setShowSections(true);
        setLoadingMenu(false);
        ref?.current?.complete();
      } else {
        setLoadingMenu(false);
        ref?.current?.complete();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  //Get Neglect / Urgent
  const fetchNeglectedOrUrgent = (type) => {
    setUrgentType(type);
    setShowSections(false);
    setLoadingMenu(true);
    ref?.current?.continuousStart();
    if (type === 0) {
      props.getUnansweredBuyerQuestions(empId, pl, pt, type, 1, null, sortBy, viewType, (res) => {
        if (res.status === 200) {
          setShowSections(true);
          setLoadingMenu(false);
          ref?.current?.complete();
        } else {
          setLoadingMenu(false);
          ref?.current?.complete();
          toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        }
      });
    } else {
      props.getNeglecteOrUrgent(empId, pl, pt, 1, null, type, sortBy, viewType, (res) => {
        if (res.status === 200) {
          setShowSections(true);
          setLoadingMenu(false);
          ref?.current?.complete();
        } else {
          setLoadingMenu(false);
          ref?.current?.complete();
          toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        }
      });
    }
  };

  //Get Buyer Unit Assignment
  const fetchBuAssignment = () => {
    const payload = {
      aType: "business_units",
      employeeId: empId,
      page: 1,
      buStatus: "",
      contractStatus: "",
      industryId: "",
      publiclyTraded: "",
      searchText: "",
      regionId: "",
      companyTypeId: "",
      cId: 0,
      eId: "",
      orderBy: 1,
      order: 0,
      buType: "",
      va: 0,
    };
    setShowSections(false);
    setLoadingMenu(true);
    ref?.current?.continuousStart();
    props.getBuAssignment(payload, (res) => {
      if (res.status === 200) {
        setShowSections(true);
        setLoadingMenu(false);
        ref?.current?.complete();
      } else {
        setLoadingMenu(false);
        ref?.current?.complete();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  //Get Enterprise Assignment
  const fetchEnterpriseAssignment = () => {
    setShowSections(false);
    setLoadingMenu(true);
    ref?.current?.continuousStart();
    props.getEnterpriseAssignment(empId, 1, "", "", "", "", "", "", (res) => {
      if (res.status === 200) {
        setShowSections(true);
        setLoadingMenu(false);
        ref?.current?.complete();
      } else {
        setLoadingMenu(false);
        ref?.current?.complete();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  //Fetch Seller Assignment
  const fetchSellerAssignment = () => {
    setShowSections(false);
    setLoadingMenu(true);
    ref?.current?.continuousStart();
    props.getSellerAssignment(empId, 1, (res) => {
      if (res.status === 200) {
        setShowSections(true);
        setLoadingMenu(false);
        ref?.current?.complete();
      } else {
        setLoadingMenu(false);
        ref?.current?.complete();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  return (
    <>
      <LoadingBar height={5} color="#47AD1D" ref={ref} />
      <div className={`page-wrapper-outer grey-bg `}>
        <Header />
        <div class="dashboard-insider position-relative d-lg-flex w-100 ">
          <Sidebar
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            menuList={menuList}
            setMenuList={setMenuList}
            page="Home"
          />
          <div
            className={showMenu ? ` main-menu-expanded ` : "main-menu-compressed"}
            style={{ backgroundColor: "#F5F5F5" }}
          >
            <div className="col-row-container">
              <div className="container-fluid listing-container-investment mt-3">
                <CommodityTrader
                  data={employeeList}
                  setEmpId={setEmpId}
                  setPl={setPl}
                  setPt={setPt}
                  setShowOnly={setShowOnly}
                  sortBy={sortBy}
                  setSortBy={setSortBy}
                  viewType={viewType}
                  setViewType={setViewType}
                  pushYesNo={pushYesNo}
                  setPushYesNo={setPushYesNo}
                  dashboardData={props.dashboardData}
                />
                <ProfileActivity
                  count={count.pending}
                  loadingMain={loadingMain}
                  refStart={ref?.current?.continuousStart}
                  refStop={ref?.current?.complete}
                  refresh={refresh}
                  viewContent={viewContent}
                  empId={empId}
                  showOnly={showOnly}
                  pl={pl}
                  pt={pt}
                />
                <Menu
                  loadingMain={loadingMain}
                  refStart={ref?.current?.continuousStart}
                  refStop={ref?.current?.complete}
                  refresh={refresh}
                  viewContent={viewContent}
                  empId={empId}
                  showOnly={showOnly}
                  pl={pl}
                  pt={pt}
                  viewType={viewType}
                  sortBy={sortBy}
                  setEmpId={setEmpId}
                  fetchAccountManagement={fetchAccountManagement}
                  fetchCustomers={fetchCustomers}
                  fetchAllOtherListings={fetchAllOtherListings}
                  setTaskType={setTaskType}
                  fetchNeglectedOrUrgent={fetchNeglectedOrUrgent}
                  fetchBuAssignment={fetchBuAssignment}
                  fetchEnterpriseAssignment={fetchEnterpriseAssignment}
                  fetchSellerAssignment={fetchSellerAssignment}
                  section={section}
                  setSection={setSection}
                  setMenuTitle={setMenuTitle}
                />
                {/* Content not loaded section */}
                {showSections ? (
                  section === "accountManagement" ? (
                    <AccountManagement
                      data={accountManagementData}
                      empId={empId}
                      type={accountManagementType}
                      status={accountManagementStatus}
                      refStart={ref?.current?.continuousStart}
                      refStop={ref?.current?.complete}
                      title={menuTitle}
                    />
                  ) : section === "customers" ? (
                    <Customers
                      data={customerData}
                      empList={employeeList}
                      empId={empId}
                      type={customerType}
                      refStart={ref?.current?.continuousStart}
                      refStop={ref?.current?.complete}
                      title={menuTitle}
                    />
                  ) : section === "customer_listing_buyer_interest" ? (
                    <MyListingBuyerInterest
                      data={customerData}
                      empList={employeeList}
                      mainCategories={mainCategories}
                      regionsList={regionsList}
                      empId={empId}
                      type={customerType}
                      refStart={ref?.current?.continuousStart}
                      refStop={ref?.current?.complete}
                      title={menuTitle}
                      showBy={showBy}
                      setShowBy={setShowBy}
                    />
                  ) : section === "allOther" ? (
                    <AllOtherListings
                      data={allOtherData}
                      title={menuTitle}
                      empId={empId}
                      pl={pl}
                      pt={pt}
                      taskType={taskType}
                      refStart={ref?.current?.continuousStart}
                      refStop={ref?.current?.complete}
                    />
                  ) : section === "neglected" ? (
                    <NeglectedOrUrgent
                      data={neglectedOrUrgent}
                      empId={empId}
                      pl={pl}
                      pt={pt}
                      viewType={viewType}
                      sortBy={sortBy}
                      type={urgentType}
                      refStart={ref?.current?.continuousStart}
                      refStop={ref?.current?.complete}
                      title={menuTitle}
                    />
                  ) : section === "businessUnit" ? (
                    <BuAssignments
                      data={buAssignmentData}
                      empId={empId}
                      refStart={ref?.current?.continuousStart}
                      refStop={ref?.current?.complete}
                      getBuAssignment={props.getBuAssignment}
                      title={menuTitle}
                    />
                  ) : section === "enterprise" ? (
                    <EnterpriseAssignments
                      data={enterpriseAssignmentData}
                      empId={empId}
                      refStart={ref?.current?.continuousStart}
                      refStop={ref?.current?.complete}
                      getEnterpriseAssignment={props.getEnterpriseAssignment}
                      title={menuTitle}
                    />
                  ) : section === "sellerAssignment" ? (
                    <SellerAssignments
                      data={sellerAssignmentData}
                      empId={empId}
                      refStart={ref?.current?.continuousStart}
                      refStop={ref?.current?.complete}
                      getSellerAssignment={props.getSellerAssignment}
                      getRecipient={props.getRecipient}
                      addMessage={props.addMessage}
                      deleteMessage={props.deleteMessage}
                      changeUnsubscribe={props.changeUnsubscribe}
                      title={menuTitle}
                    />
                  ) : null
                ) : (
                  loadingMenu && (
                    <div class="details-page-widget-block">
                      <div class="page-widget-head d-flex align-items-center justify-content-between"></div>
                      <div class="page-widget-body mt-3">
                        {loadingMain ? (
                          <Skeleton height="50px" />
                        ) : (
                          <div
                            class="page-widget-empty-box text-capitalize d-flex align-items-center justify-content-center text-center"
                            style={{
                              height: "50px",
                              flexDirection: "column",
                              gap: "10px",
                            }}
                          >
                            <span>{loadingMenu ? "Content Loading..." : "Content not loaded"}</span>
                          </div>
                        )}
                      </div>
                    </div>
                  )
                )}
                <NewestHighBids
                  count={count.newestHighBid}
                  loadingMain={loadingMain}
                  refStart={ref?.current?.continuousStart}
                  refStop={ref?.current?.complete}
                  refresh={refresh}
                  viewContent={viewContent}
                  empId={empId}
                  pl={pl}
                  pt={pt}
                  viewType={viewType}
                  sortBy={sortBy}
                  pushYesNo={pushYesNo}
                  setEmpId={setEmpId}
                />
                <ReadyToCloseListings
                  count={count.readyToClose}
                  loadingMain={loadingMain}
                  refStart={ref?.current?.continuousStart}
                  refStop={ref?.current?.complete}
                  refresh={refresh}
                  viewContent={viewContent}
                  empId={empId}
                  pl={pl}
                  pt={pt}
                  viewType={viewType}
                  sortBy={sortBy}
                  pushYesNo={pushYesNo}
                  setEmpId={setEmpId}
                />
                <BigDealListings
                  count={count.bigDeal}
                  loadingMain={loadingMain}
                  refStart={ref?.current?.continuousStart}
                  refStop={ref?.current?.complete}
                  refresh={refresh}
                  viewContent={viewContent}
                  empId={empId}
                  pl={pl}
                  pt={pt}
                  viewType={viewType}
                  sortBy={sortBy}
                  pushYesNo={pushYesNo}
                  setEmpId={setEmpId}
                />
                <AllListings
                  count={count.all}
                  loadingMain={loadingMain}
                  refStart={ref?.current?.continuousStart}
                  refStop={ref?.current?.complete}
                  refresh={refresh}
                  viewContent={viewContent}
                  empId={empId}
                  pl={pl}
                  pt={pt}
                  viewType={viewType}
                  sortBy={sortBy}
                  pushYesNo={pushYesNo}
                  setEmpId={setEmpId}
                />
                <ActiveListings
                  count={count.active}
                  loadingMain={loadingMain}
                  refStart={ref?.current?.continuousStart}
                  refStop={ref?.current?.complete}
                  refresh={refresh}
                  viewContent={viewContent}
                  pl={pl}
                  pt={pt}
                  empId={empId}
                  viewType={viewType}
                  sortBy={sortBy}
                  pushYesNo={pushYesNo}
                  setEmpId={setEmpId}
                />
                <PendingListings
                  count={count.pending}
                  loadingMain={loadingMain}
                  refStart={ref?.current?.continuousStart}
                  refStop={ref?.current?.complete}
                  refresh={refresh}
                  viewContent={viewContent}
                  empId={empId}
                  pl={pl}
                  pt={pt}
                  viewType={viewType}
                  sortBy={sortBy}
                  pushYesNo={pushYesNo}
                  setEmpId={setEmpId}
                />
                <ClosedListings
                  count={count.closed}
                  loadingMain={loadingMain}
                  refStart={ref?.current?.continuousStart}
                  refStop={ref?.current?.complete}
                  refresh={refresh}
                  viewContent={viewContent}
                  empId={empId}
                  pl={pl}
                  pt={pt}
                  viewType={viewType}
                  sortBy={sortBy}
                  pushYesNo={pushYesNo}
                  setEmpId={setEmpId}
                />
                <InvoicedListings
                  count={count.invoiced}
                  loadingMain={loadingMain}
                  refStart={ref?.current?.continuousStart}
                  refStop={ref?.current?.complete}
                  refresh={refresh}
                  viewContent={viewContent}
                  empId={empId}
                  pl={pl}
                  pt={pt}
                  viewType={viewType}
                  sortBy={sortBy}
                  pushYesNo={pushYesNo}
                  setEmpId={setEmpId}
                />
                <UnassignedListings
                  count={count.unassigned}
                  loadingMain={loadingMain}
                  refStart={ref?.current?.continuousStart}
                  refStop={ref?.current?.complete}
                  refresh={refresh}
                  viewContent={viewContent}
                  empId={empId}
                  pl={pl}
                  pt={pt}
                  viewType={viewType}
                  sortBy={sortBy}
                  pushYesNo={pushYesNo}
                  setEmpId={setEmpId}
                />
                <ListingReadyToBePushed
                  count={count.readyToPush}
                  loadingMain={loadingMain}
                  refStart={ref?.current?.continuousStart}
                  refStop={ref?.current?.complete}
                  refresh={refresh}
                  viewContent={viewContent}
                  empId={empId}
                  pl={pl}
                  pt={pt}
                  viewType={viewType}
                  sortBy={sortBy}
                  pushYesNo={pushYesNo}
                  setEmpId={setEmpId}
                />
                <ArchivedListings
                  count={count.archived}
                  loadingMain={loadingMain}
                  refStart={ref?.current?.continuousStart}
                  refStop={ref?.current?.complete}
                  refresh={refresh}
                  viewContent={viewContent}
                  empId={empId}
                  pl={pl}
                  pt={pt}
                  viewType={viewType}
                  sortBy={sortBy}
                  pushYesNo={pushYesNo}
                  setEmpId={setEmpId}
                />
                <PendingResearchAssignment
                  count={count.pendingAssignment}
                  loadingMain={loadingMain}
                  refStart={ref?.current?.continuousStart}
                  refStop={ref?.current?.complete}
                  refresh={refresh}
                  viewContent={viewContent}
                  empId={empId}
                  pl={pl}
                  pt={pt}
                  viewType={viewType}
                  sortBy={sortBy}
                  pushYesNo={pushYesNo}
                  setEmpId={setEmpId}
                />
                <CompletedResearchAssignment
                  count={count.completedAssignment}
                  loadingMain={loadingMain}
                  refStart={ref?.current?.continuousStart}
                  refStop={ref?.current?.complete}
                  refresh={refresh}
                  viewContent={viewContent}
                  empId={empId}
                  pl={pl}
                  pt={pt}
                  viewType={viewType}
                  sortBy={sortBy}
                  pushYesNo={pushYesNo}
                  setEmpId={setEmpId}
                />
                <ListingAgreement
                  count={count.listingAgreementCount}
                  loadingMain={loadingMain}
                  refStart={ref?.current?.continuousStart}
                  refStop={ref?.current?.complete}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getListingCount: (empId, showOnly, pl, pt, sortBy, viewType, callback) =>
    dispatch(getListingCount(empId, showOnly, pl, pt, sortBy, viewType, callback)),
  getTaskAndCount: (empId, showOnly, pl, pt, sortBy, viewType, callback) =>
    dispatch(getTaskAndCount(empId, showOnly, pl, pt, sortBy, viewType, callback)),
  getEmployees: (callback) => dispatch(getEmployees(callback)),
  getAccountManagement: (empId, type, page, status, callback) =>
    dispatch(getAccountManagement(empId, type, page, status, callback)),
  getCustomers: (params, callback) => dispatch(getCustomers(params, callback)),
  getAllOtherListings: (empId, pl, pt, taskType, callback) =>
    dispatch(getAllOtherListings(empId, pl, pt, taskType, callback)),
  getNeglecteOrUrgent: (empId, pl, pt, page, sort, listType, sortBy, viewType, callback) =>
    dispatch(getNeglectedOrUrgent(empId, pl, pt, page, sort, listType, sortBy, viewType, callback)),
  getUnansweredBuyerQuestions: (empId, pl, pt, type, page, sort, sortBy, viewType, callback) =>
    dispatch(getUnasweredBuyerQuestions(empId, pl, pt, type, page, sort, sortBy, viewType, callback)),
  getSellerAssignment: (empId, page, callback) => dispatch(getSellerAssignment(empId, page, callback)),
  getBuAssignment: (params, callback) => dispatch(getBuAssignment(params, callback)),
  getEnterpriseAssignment: (
    empId,
    page,
    contractStatus,
    industryId,
    publiclyTraded,
    searchText,
    regionId,
    companyTypeId,
    callback,
  ) =>
    dispatch(
      getEnterpriseAssignment(
        empId,
        page,
        contractStatus,
        industryId,
        publiclyTraded,
        searchText,
        regionId,
        companyTypeId,
        callback,
      ),
    ),

  //From push email clickers..
  getRecipient: (callback) => dispatch(getRecipient(callback)),
  getMainCateogry: (callback) => dispatch(getMainCateogry(callback)),
  getRegions: (callback) => dispatch(getRegions(callback)),
  addMessage: (params, data, callback) => dispatch(addMessage(params, data, callback)),
  deleteMessage: (params, callback) => dispatch(deleteMessage(params, callback)),
  changeUnsubscribe: (params, data, callback) => dispatch(changeUnsubscribe(params, data, callback)),
});
const mapStateToProps = (state) => ({
  dashboardData: dashboardData(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(HomePage));
