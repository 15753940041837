import React, { useEffect, useState } from "react";
import DateFormat from "../../../common/DateFormat/dateFormat";
import Select from "react-select";
import { customStyles } from "../../../common/customStyles/reactSelectStyle";
import { toast } from "react-toastify";
import AlertError from "../../../common/alerts/alertError";
import { connect } from "react-redux";
import {
  assignProject,
  dashboardData,
  getBuAssignment,
  getBuRegion,
  getEnterpriseType,
  getEnterprises,
  getIndustry,
  otherAuctions,
  unSubscribe,
} from "../../../store/dashBoard";
import moment from "moment";
import EditModal from "../../../layouts/modals/dashboard/accountManagement/editModal";
import AssignModal from "../../../layouts/modals/dashboard/accountManagement/assignModal";
import AlertSuccess from "../../../common/alerts/alertSuccess";

const EnterpriseAssignments = (props) => {
  const mediaUrl = process.env.REACT_APP_MEDIA_URL;
  const detailData = props.data;
  const BuDetailData = props?.dashboardData?.buAssignment;

  const handleImgError = (e) => {
    e.target.style.display = "none";
  };

  const [page, setPage] = useState(1);
  const [action, setAction] = useState(false);
  const [showBU, setShowBU] = useState(false);
  const [cId, setCId] = useState("");
  const [loading, setLoading] = useState(false);
  const [enterpriseTypeOptions, setEnterpriseTypeOptions] = useState([]);
  const [enterpriseTypeValue, setEnterpriseTypeValue] = useState({
    id: 0,
    description: "-- All Type --",
    sort: 0,
  });
  const [industryOptions, setIndustryOptions] = useState([]);
  const [industryValue, setIndustryValue] = useState({
    id: 0,
    description: "-- Show All --",
    owner_id: 0,
  });
  const [buRegionOptions, setBuRegionOptions] = useState([]);
  const [buRegionValue, setBuRegionValue] = useState({
    country_id: 0,
    country_description: "All Regions",
    state_id: 0,
    state_description: "",
  });
  const [enterpriseOptions, setEnterpriseOptions] = useState([]);
  const [enterpriseValue, setEnterpriseValue] = useState({
    id: 0,
    name: "-- Select One --",
  });
  const [contractValue, setContractValue] = useState({
    value: -1,
    name: "-- Select One  --",
  });
  const [filterData, setFilterData] = useState({
    companyTypeId: "",
    industryId: "",
    regionId: "",
    contractStatus: "",
    publiclyTraded: "",
  });
  const [searchText, setSearchText] = useState("");
  const [assignModal, setAssignModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [title, setTitle] = useState("");
  const [assignData, setAssignData] = useState({
    title: "",
    project_id: "",
  });
  const [editData, setEditData] = useState({
    action: "edit_location_process",
    company_id: 0,
    project_id: 0,
    title: "",
    address_1: "",
    address_2: "",
    city: "",
    state_id: 0,
    zip_code: "",
    phone_1: "",
    phone_2: "",
    country_id: 0,
    description: "",
    headquarters: 0,
    unsubscribe: 0,
  });
  const toggleAssignModal = () => {
    setAssignModal(!assignModal);
  };

  const toggleEditModal = () => {
    setEditModal(!editModal);
  };

  const contractOptions = [
    {
      value: -1,
      name: "-- Select One  --",
    },
    {
      value: 0,
      name: "No Contract",
    },
    {
      value: 1,
      name: "Targeting",
    },
    {
      value: 2,
      name: "Pending",
    },
    {
      value: 3,
      name: "Signed",
    },
    {
      value: 4,
      name: "Expired",
    },
  ];

  //Pagination
  useEffect(() => {
    const pagination = () => {
      props.refStart();
      props.getEnterpriseAssignment(
        props.empId,
        page,
        filterData.contractStatus,
        filterData.industryId,
        filterData.publiclyTraded,
        searchText,
        filterData.regionId,
        filterData.companyTypeId,
        (res) => {
          if (res.status === 200) {
            props.refStop();
          } else {
            props.refStop();
            toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
          }
        },
      );
    };
    if (action) pagination();
  }, [page]);

  //Filter
  useEffect(() => {
    const filtering = () => {
      setLoading(true);
      props.refStart();
      props.getEnterpriseAssignment(
        props.empId,
        page,
        filterData.contractStatus,
        filterData.industryId,
        filterData.publiclyTraded,
        searchText,
        filterData.regionId,
        filterData.companyTypeId,
        (res) => {
          if (res.status === 200) {
            setLoading(false);
            props.refStop();
          } else {
            setLoading(false);
            props.refStop();
            toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
          }
        },
      );
    };
    if (action) {
      filtering();
    }
  }, [filterData]);

  //handle Search
  const hanldeSearch = () => {
    setLoading(true);
    props.refStart();
    props.getEnterpriseAssignment(
      props.empId,
      page,
      filterData.contractStatus,
      filterData.industryId,
      filterData.publiclyTraded,
      searchText,
      filterData.regionId,
      filterData.companyTypeId,
      (res) => {
        if (res.status === 200) {
          setLoading(false);
          props.refStop();
        } else {
          setLoading(false);
          props.refStop();
          toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        }
      },
    );
  };

  useEffect(() => {
    props.getEnterpriseType((res) => {
      if (res.status === 200) {
        setEnterpriseTypeOptions([
          {
            id: 0,
            description: "-- All Type --",
            sort: 0,
          },
          ...res.data.data,
        ]);
      }
    });
    props.getIndustry(props.empId, (res) => {
      if (res.status === 200) {
        setIndustryOptions([
          {
            id: 0,
            description: "-- Show All --",
            owner_id: 0,
          },
          ...res.data.data,
        ]);
      }
    });
    props.getBuRegion(props.empId, (res) => {
      if (res.status === 200) {
        const options = [
          {
            country_id: 0,
            country_description: "All Regions",
            state_id: 0,
            state_description: "",
          },
          ...res.data.data,
        ];
        const mainCategoryOptions = [];
        const usSubcategoryOptions = [];

        options.forEach((option) => {
          if (option.country_description === "United States") {
            usSubcategoryOptions.push(option);
          } else {
            mainCategoryOptions.push(option);
          }
        });

        const groupedOptions = [
          {
            options: mainCategoryOptions,
          },
          {
            label: "States",
            options: usSubcategoryOptions,
          },
        ];
        setBuRegionOptions(groupedOptions);
      }
    });

    props.getEnterprises(props.empId, (res) => {
      if (res.status === 200) {
        setEnterpriseOptions([
          {
            id: 0,
            name: "-- Select One --",
          },
          ...res.data.data,
        ]);
      }
    });
  }, []);

  const handleUnSubscribe = (option, id) => {
    props?.refStart();
    const payload = {
      unsubscribe: option,
      save_unsubscribe: id,
    };
    props?.unSubscribe(payload, (res) => {
      if (res.status === 200) {
        props?.refStop();
      } else {
        props?.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const handleShowBU = (id) => {
    const payload = {
      aType: "business_units",
      employeeId: props.empId,
      buStatus: "",
      contractStatus: "",
      industryId: "",
      publiclyTraded: "",
      searchText: "",
      regionId: "",
      companyTypeId: "",
      eId: "",
      orderBy: 1,
      order: 0,
      buType: "",
      cId: id,
      page: 1,
      va: 1,
    };
    props?.refStart();
    props?.getBuAssignment(payload, (res) => {
      if (res.status === 200) {
        setShowBU(true);
        props?.refStop();
      } else {
        props?.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const getDropdown = (item) => {
    const payload = {
      action: "assign_project_form",
      company_id: item.company_id,
      project_id: item.project_id,
    };
    props.refStart();
    props.assignProject(payload, (res) => {
      if (res.status === 200) {
        const projects = res?.data?.projects;
        const data = res?.data?.data;
        setAssignData(
          projects?.find((val) => val?.project_id === data[0]?.owner_id)
            ? projects?.find((val) => val?.project_id === data[0]?.owner_id)
            : { title: "-- No Assignement --", project_id: 0 },
        );
        setModalData(res.data);
        toggleAssignModal();
        props.refStop();
      } else {
        props.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const getEditModal = (id) => {
    const payload = {
      action: "edit_location_form",
      project_id: id,
    };
    props?.refStart();
    props?.otherAuctions(payload, (res) => {
      if (res.status === 200) {
        const val = res?.data?.data?.project;
        setTitle(val?.title ? val?.title : "");
        const data = { ...editData };
        data.company_id = val?.company_id ? val?.company_id : 0;
        data.project_id = val?.project_id ? val?.project_id : 0;
        data.title = val?.title ? val?.title : "";
        data.address_1 = val?.address_1 ? val?.address_1 : "";
        data.address_2 = val?.address_2 ? val?.address_2 : "";
        data.city = val?.city ? val?.city : "";
        data.state_id = val?.state_id ? val?.state_id : 0;
        data.zip_code = val?.zip_code ? val?.zip_code : "";
        data.phone_1 = val?.phone_1 ? val?.phone_1 : "";
        data.phone_2 = val?.phone_2 ? val?.phone_2 : "";
        data.country_id = val?.country_id ? val?.country_id : 0;
        data.description = val?.description ? val?.description : "";
        data.headquarters = val?.headquarters ? 1 : 0;
        data.unsubscribe = val?.unsubscribe ? 1 : 0;
        setEditData(data);
        setCountry(res?.data?.data?.countries);
        setState(res?.data?.data?.states);
        props?.refStop();
        toggleEditModal();
      } else {
        props?.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };
  const handleEdit = () => {
    setLoading(true);
    props?.refStart();
    props?.otherAuctions(editData, (res) => {
      if (res.status === 200) {
        props?.getEnterpriseAssignment(
          props.empId,
          page,
          filterData.contractStatus,
          filterData.industryId,
          filterData.publiclyTraded,
          searchText,
          filterData.regionId,
          filterData.companyTypeId,
          (res) => {},
        );
        props?.refStop();
        setLoading(false);
        toast(<AlertSuccess message={"Information Saved"} />);
        toggleEditModal();
      } else {
        setLoading(false);
        props?.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const handleAssign = () => {
    const data = modalData?.data[0];
    const payload = {
      action: "assign_project_process",
      company_id: data?.company_id ? data?.company_id : 0,
      project_id: data?.project_id ? data?.project_id : 0,
      owner_id: assignData?.project_id ? assignData?.project_id : 0,
    };
    setLoading(true);
    props.refStart();
    props.assignProject(payload, (res) => {
      if (res.status === 200) {
        props.refStop();
        setLoading(false);
        toggleAssignModal();
        toast(<AlertSuccess message={"Information Saved"} />);
      } else {
        props.refStop();
        setLoading(false);
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };
  return (
    <div class="details-page-widget-block">
      <div class="page-widget-head d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <div class="page-widget-label text-capitalize cursor-pointer">{props.title}</div>
        </div>
      </div>
      <div class="page-widget-body mt-3">
        <div className="filter mb-3">
          <div className="d-flex">
            <div className="d-flex me-3">
              <span className="fw-medium mt-2">Enterprise Type: </span>
              <div className="ms-2" style={{ width: "200px" }}>
                <Select
                  styles={customStyles}
                  placeholder="-- All Types --"
                  value={enterpriseTypeValue}
                  options={enterpriseTypeOptions}
                  getOptionLabel={(option) => option.description}
                  getOptionValue={(option) => option.id}
                  onChange={(e) => {
                    setEnterpriseTypeValue(e);
                    const data = { ...filterData };
                    data.companyTypeId = e.id;
                    setAction(true);
                    setFilterData(data);
                  }}
                />
              </div>
            </div>
            <div className="d-flex me-3">
              <span className="fw-medium mt-2">Industry: </span>
              <div className="ms-2" style={{ width: "250px" }}>
                <Select
                  styles={customStyles}
                  placeholder="-- Show All --"
                  value={industryValue}
                  options={industryOptions}
                  getOptionLabel={(option) => option.description}
                  getOptionValue={(option) => option.id}
                  onChange={(e) => {
                    setIndustryValue(e);
                    const data = { ...filterData };
                    data.industryId = e.id;
                    setAction(true);
                    setFilterData(data);
                  }}
                />
              </div>
            </div>
            <div className="d-flex me-3">
              <span className="fw-medium mt-2">HQ Region: </span>
              <div className="ms-2" style={{ width: "200px" }}>
                <Select
                  styles={customStyles}
                  placeholder="All Regions"
                  value={buRegionValue}
                  options={buRegionOptions}
                  getOptionLabel={(option) =>
                    option.country_description === "United States"
                      ? option.state_description
                      : option.country_description
                  }
                  getOptionValue={(option) => option.country_id}
                  onChange={(e) => {
                    setBuRegionValue(e);
                    const data = { ...filterData };
                    data.regionId = e.country_description === "Uniter States" ? e.state_id : e.country_id;
                    setAction(true);
                    setFilterData(data);
                  }}
                />
              </div>
            </div>
            <div className="d-flex me-3">
              <span className="fw-medium mt-2">Contract? </span>
              <div className="ms-2" style={{ width: "200px" }}>
                <Select
                  styles={customStyles}
                  placeholder="-- Select One --"
                  value={contractValue}
                  options={contractOptions}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.value}
                  onChange={(e) => {
                    setContractValue(e);
                    const data = { ...filterData };
                    data.contractStatus = e.value;
                    setAction(true);
                    setFilterData(data);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="line-2 d-flex mt-3">
            <div className="d-flex me-3">
              <span className="fw-medium mt-2">Publicly Traded: </span>
              <div className="d-flex ms-2">
                <div className="form-check radio pe-2">
                  <input
                    className="form-check-input cursor-pointer"
                    value={1}
                    type="radio"
                    name="flexRadioDefault"
                    id="radioYes" // Unique ID for the "Yes" option
                    checked={filterData.publiclyTraded === 1}
                    onChange={(e) => {
                      const data = { ...filterData };
                      data.publiclyTraded = 1;
                      setAction(true);
                      setFilterData(data);
                    }}
                  />
                  <label className="form-check-label" htmlFor="radioYes">
                    Yes
                  </label>
                </div>
                <div className="form-check radio pe-2">
                  <input
                    className="form-check-input cursor-pointer"
                    value={0}
                    type="radio"
                    name="flexRadioDefault"
                    id="radioNo" // Unique ID for the "No" option
                    checked={filterData.publiclyTraded === 0}
                    onChange={(e) => {
                      const data = { ...filterData };
                      data.publiclyTraded = 0;
                      setAction(true);
                      setFilterData(data);
                    }}
                  />
                  <label className="form-check-label" htmlFor="radioNo">
                    No
                  </label>
                </div>
                <div className="form-check radio pe-2">
                  <input
                    className="form-check-input cursor-pointer"
                    value={-1}
                    type="radio"
                    name="flexRadioDefault"
                    id="radioBoth" // Unique ID for the "Both" option
                    checked={filterData.publiclyTraded === -1}
                    onChange={(e) => {
                      const data = { ...filterData };
                      data.publiclyTraded = -1;
                      setAction(true);
                      setFilterData(data);
                    }}
                  />
                  <label className="form-check-label" htmlFor="radioBoth">
                    Both
                  </label>
                </div>
              </div>

              {/* <div className="d-flex">
                <span className="fw-medium mt-2">Enterprise: </span>
                <div className="ms-2" style={{ width: "250px" }}>
                  <Select
                    styles={customStyles}
                    placeholder="-- Select One --"
                    value={enterpriseValue}
                    options={enterpriseOptions}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    onChange={(e) => {
                      setEnterpriseValue(e);
                    }}
                  />
                </div>
              </div> */}
            </div>
          </div>

          <div className="line-3 d-flex mt-3">
            <span className="fw-medium mt-2">Search:</span>
            <div className="ms-2" style={{ width: "250px" }}>
              <input
                type="text"
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                className="form-control"
              />
            </div>
            <div className="ms-2">
              <button className="btn btn-default" onClick={hanldeSearch}>
                GO
              </button>
            </div>
          </div>
        </div>
        {loading ? (
          <div class="page-widget-body">
            <div
              class="page-widget-empty-box text-capitalize d-flex align-items-center justify-content-center text-center"
              style={{
                height: "50px",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <span>Content Loading...</span>
            </div>
          </div>
        ) : (
          <div class="table-responsive position-relative">
            <table class="table table-bordered custom-table">
              <thead>
                <tr>
                  <th></th>
                  <th>LOGO</th>
                  <th>CUSTOMER</th>
                  {detailData.data.length > 0 && <th style={{ minWidth: "180px" }}></th>}
                  <th>PROFIT</th>
                  <th>ALL DEALS</th>
                  <th>SOLD DEALS</th>
                  <th>CURRENT DEALS</th>
                  <th>LAST DEAL DATE</th>
                  <th>INDUSTRY</th>
                  <th>CONTRACT</th>
                  <th>SITE LOGIN</th>
                  <th>MEMBERS</th>
                  <th>MTR</th>
                  {detailData.data.length > 0 && <th>TRADERS</th>}
                  {detailData.data.length > 0 && <th>UNSUBSCRIBE</th>}
                  {detailData.data.length > 0 && <th></th>}
                </tr>
              </thead>
              <tbody>
                {detailData &&
                  detailData.data &&
                  detailData.data.length > 0 &&
                  detailData.data.map((val, i) => (
                    <>
                      <tr>
                        <td>{(detailData.page - 1) * 10 + (i + 1)}</td>
                        <td>
                          <div class="assignment-td">
                            <a
                              className="cursor-pointer"
                              target="_blank"
                              href={
                                process.env.REACT_APP_MODE == "development"
                                  ? `https://admin-dev.salvex.com/admin/_proc/_company/_edit.cfm?cid=${val?.id}`
                                  : `https://admin.salvex.com/admin/_proc/_company/_edit.cfm?cid=${val?.id}`
                              }
                            >
                              <img
                                src={`${mediaUrl}/co/logos/${val?.proc_logo}`}
                                alt=""
                                style={{ border: `5px solid ${val?.publicly_traded ? "#9e1dad" : "#dfdfdf"}` }}
                                onError={handleImgError}
                              />
                            </a>
                          </div>
                        </td>
                        <td className="green-btn-text">
                          {val.name ? (
                            <a
                              className="cursor-pointer"
                              target="_blank"
                              href={
                                process.env.REACT_APP_MODE == "development"
                                  ? `https://admin-dev.salvex.com/admin/_proc/_company/_edit.cfm?cid=${val?.id}`
                                  : `https://admin.salvex.com/admin/_proc/_company/_edit.cfm?cid=${val?.id}`
                              }
                            >
                              {val?.name}
                            </a>
                          ) : (
                            "N/A"
                          )}
                        </td>
                        <td>
                          <div className="d-flex justify-content-center align-items-center">
                            <button
                              className="btn btn-primary btn-xs-size btn-auto"
                              onClick={() => {
                                setCId(val?.id);
                                showBU && val?.id === cId ? setShowBU(false) : handleShowBU(val?.id);
                              }}
                            >
                              {showBU && val?.id === cId ? "Hide" : "Show"} Business Units
                            </button>
                          </div>
                        </td>
                        <td>
                          {val.profit
                            ? "$" +
                              new Intl.NumberFormat("en-US", {
                                style: "decimal",
                                minimumFractionDigits: 2,
                              }).format(val.profit) +
                              "."
                            : "$0.00"}
                        </td>
                        <td>{val.all_deals}</td>
                        <td>{val.sold_deals}</td>
                        <td>{val.current_deals}</td>
                        {val.last_deal_date ? <DateFormat date={val.last_deal_date} /> : "N/A"}
                        <td>{val.industry_description}</td>
                        <td>{val.contract_status}</td>
                        <td>
                          {val?.members > 9 ? (
                            <a
                              href={
                                process.env.REACT_APP_MODE === "development"
                                  ? `https://admin-dev.salvex.com/admin/_proc/_company/marketing_list.cfm?assoc_id=${
                                      val.id
                                    }&ts=${moment().utc().format("HHmmss")}`
                                  : `https://admin.salvex.com/admin/_proc/_company/marketing_list.cfm?assoc_id=${
                                      val.id
                                    }&ts=${moment().utc().format("HHmmss")}`
                              }
                              target="_blank"
                              className="green-btn-text"
                            >
                              View Marketing List
                            </a>
                          ) : (
                            "no users associated"
                          )}
                        </td>
                        <td>{val.members}</td>
                        <td>{val.mtrs}</td>
                        <td>{val.traders}</td>
                        <td>
                          <div className="d-flex">
                            <span className="form-check-label me-2">No</span>
                            <div class="form-check radio pe-2">
                              <input
                                class="form-check-input cursor-pointer"
                                type="radio"
                                name={"flexRadioDefault" + i}
                                defaultChecked={val.unsubscribe}
                                onChange={(e) => {
                                  handleUnSubscribe(1, val?.id);
                                }}
                                id={`no${i}`}
                              />
                              <label class="form-check-label" for={`no${i}`}>
                                {/* No */}
                              </label>
                            </div>
                            <span className="form-check-label me-2">Yes</span>
                            <div class="form-check radio pe-2">
                              <input
                                class="form-check-input cursor-pointer"
                                type="radio"
                                name={"flexRadioDefault" + i}
                                defaultChecked={!val.unsubscribe}
                                onChange={(e) => {
                                  handleUnSubscribe(0, val?.id);
                                }}
                                id={`yes${i}`}
                              />
                              <label class="form-check-label" for={`yes${i}`}>
                                {/* Yes */}
                              </label>
                            </div>
                          </div>
                        </td>
                        <td>
                          <button className="btn btn-primary btn-xs-size btn-auto">POPULATE</button>
                        </td>
                      </tr>
                      {showBU && val?.id === cId && (
                        <tr>
                          <td colSpan={17}>
                            <div class="table-responsive position-relative">
                              <table class="table table-bordered custom-table">
                                <thead>
                                  <tr>
                                    <th>LOGO</th>
                                    <th>ENTERPRISE</th>
                                    <th>HEADQUARTERS</th>
                                    <th>BUSINESS UNITS</th>
                                    <th>HQ</th>
                                    <th>PT</th>
                                    <th>Pipe History</th>
                                    <th>BU Status</th>
                                    <th>Account Agent</th>
                                    <th>BU Type</th>
                                    <th>Seller Status</th>
                                    <th>Last BU Note</th>
                                    <th>Last Asset Disposal Update</th>
                                    <th>Enterprise Sales</th>
                                    <th>BU Sales</th>
                                    <th>PR Status</th>
                                    <th>Last Buying Update</th>
                                    <th>Last Buyer Interest</th>
                                    <th>Last Meeting Date</th>
                                    <th>INDUSTRY</th>
                                    <th>PHONE</th>
                                    <th>CONTACTS</th>
                                    <th>UNS.</th>
                                    <th>TRADERS</th>
                                    <th colSpan={4}></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {BuDetailData &&
                                    BuDetailData?.data &&
                                    BuDetailData?.data?.length > 0 &&
                                    BuDetailData?.data?.map((val) => (
                                      <tr>
                                        <td>
                                          <div class="assignment-td">
                                            <a
                                              className="cursor-pointer"
                                              href={
                                                process.env.REACT_APP_MODE == "development"
                                                  ? `https://admin-dev.salvex.com/admin/_proc/_company/_edit.cfm?cid=${val?.company_id}`
                                                  : `https://admin.salvex.com/admin/_proc/_company/_edit.cfm?cid=${val?.company_id}`
                                              }
                                              target="_blank"
                                            >
                                              <img
                                                src={`${mediaUrl}/co/logos/${val?.proc_LOGO}`}
                                                alt=""
                                                style={{
                                                  border: `5px solid ${val?.publicly_traded ? "#9e1dad" : "#dfdfdf"}`,
                                                }}
                                                onError={handleImgError}
                                              />
                                            </a>
                                          </div>
                                        </td>
                                        <td className="green-btn-text">
                                          {val.company_name ? (
                                            <a
                                              className="cursor-pointer"
                                              href={
                                                process.env.REACT_APP_MODE == "development"
                                                  ? `https://admin-dev.salvex.com/admin/_proc/_company/_edit.cfm?cid=${val?.company_id}`
                                                  : `https://admin.salvex.com/admin/_proc/_company/_edit.cfm?cid=${val?.company_id}`
                                              }
                                              target="_blank"
                                            >
                                              {val?.company_name}
                                            </a>
                                          ) : (
                                            "N/A"
                                          )}
                                        </td>
                                        <td>{val.headquarters_txt}</td>
                                        <td className="green-btn-text">
                                          {val.title ? (
                                            <a
                                              className="cursor-pointer"
                                              href={
                                                process.env.REACT_APP_MODE == "development"
                                                  ? `https://admin-dev.salvex.com/admin/_proc/_company/project_detail.cfm?project_id=${val?.project_id}`
                                                  : `https://admin.salvex.com/admin/_proc/_company/project_detail.cfm?project_id==${val?.project_id}`
                                              }
                                              target="_blank"
                                            >
                                              {val?.title}
                                            </a>
                                          ) : (
                                            "N/A"
                                          )}
                                        </td>
                                        <td>{val.headquarters && "HQ"}</td>
                                        <td>{val.publicly_traded && "PT"}</td>
                                        <td>{val.pipe_history}</td>
                                        <td>{val.bu_status}</td>
                                        <td>
                                          {val?.account_agents?.length > 0 && (
                                            <div class="table-responsive p-2">
                                              <table class="large-table align-left">
                                                <thead>
                                                  <tr>
                                                    <th>Enterprise</th>
                                                    <th>Business Unit</th>
                                                    <th>Contact Name</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {val?.account_agents?.map((data) => (
                                                    <tr>
                                                      <td className="green-btn-text">
                                                        {data.proc_name ? (
                                                          <a
                                                            className="cursor-pointer"
                                                            href={
                                                              process.env.REACT_APP_MODE == "development"
                                                                ? `https://admin-dev.salvex.com/admin/_proc/_company/_edit.cfm?cid=${data?.proc_company_id}`
                                                                : `https://admin.salvex.com/admin/_proc/_company/_edit.cfm?cid=${data?.proc_company_id}`
                                                            }
                                                            target="_blank"
                                                          >
                                                            {data.proc_name}
                                                          </a>
                                                        ) : (
                                                          ""
                                                        )}
                                                      </td>
                                                      <td className="green-btn-text">
                                                        {data.bu_name ? (
                                                          <a
                                                            className="cursor-pointer"
                                                            href={
                                                              process.env.REACT_APP_MODE == "development"
                                                                ? `https://admin-dev.salvex.com/admin/_proc/_company/project_detail.cfm?project_id=${data?.bu_project_id}`
                                                                : `https://admin.salvex.com/admin/_proc/_company/project_detail.cfm?project_id=${data?.bu_project_id}`
                                                            }
                                                            target="_blank"
                                                          >
                                                            {data.bu_name}
                                                          </a>
                                                        ) : (
                                                          ""
                                                        )}
                                                      </td>
                                                      <td className="green-btn-text">
                                                        {data.customer_name ? (
                                                          <a
                                                            className="cursor-pointer"
                                                            href={
                                                              process.env.REACT_APP_MODE == "development"
                                                                ? `https://admin-dev.salvex.com/admin/_user/_edit.cfm?user_id=${data?.user_id}`
                                                                : `https://admin.salvex.com/admin/_user/_edit.cfm?user_id=${data?.user_id}`
                                                            }
                                                            target="_blank"
                                                          >
                                                            {data.customer_name}
                                                          </a>
                                                        ) : (
                                                          ""
                                                        )}
                                                      </td>
                                                    </tr>
                                                  ))}
                                                </tbody>
                                              </table>
                                            </div>
                                          )}
                                        </td>
                                        <td>{val.bu_type}</td>
                                        <td>{val.seller_status}</td>
                                        <td>{val.last_bu_note ? <DateFormat date={val.last_bu_note} /> : "N/A"}</td>
                                        <td>
                                          {val.last_asset_update ? <DateFormat date={val.last_asset_update} /> : "N/A"}
                                        </td>
                                        <td>
                                          {val.totalsales
                                            ? "$" +
                                              new Intl.NumberFormat("en-US", {
                                                style: "decimal",
                                                minimumFractionDigits: 2,
                                              }).format(val.totalsales) +
                                              "."
                                            : "$0.00"}
                                        </td>
                                        <td>
                                          {val.sales
                                            ? "$" +
                                              new Intl.NumberFormat("en-US", {
                                                style: "decimal",
                                                minimumFractionDigits: 2,
                                              }).format(val.sales) +
                                              "."
                                            : "$0.00"}
                                        </td>
                                        <td>{val.pr_status}</td>
                                        <td>
                                          {val.last_buying_update ? (
                                            <DateFormat date={val.last_buying_update} />
                                          ) : (
                                            "N/A"
                                          )}
                                        </td>
                                        <td>{val.last_interest ? <DateFormat date={val.last_interest} /> : "N/A"}</td>
                                        <td>{val.last_meeting ? <DateFormat date={val.last_meeting} /> : "N/A"}</td>
                                        <td>{val.industry}</td>
                                        <td>
                                          {val.phone_1} {val.phone_2}
                                        </td>
                                        <td>{val.user_count}</td>
                                        <td>
                                          <div class="form-checkbox-item">
                                            <div class="form-check dark-tick-checkbox">
                                              <input class="form-check-input" type="checkbox" id={val.project_id} />
                                              <label
                                                class="form-check-label fw-medium text-nowrap"
                                                for={val.project_id}
                                              ></label>
                                            </div>
                                          </div>
                                        </td>
                                        <td>{val.traders}</td>
                                        <td>
                                          <button
                                            className="btn btn-primary btn-xs-size btn-auto"
                                            target="_blank"
                                            href={
                                              process.env.REACT_APP_MODE === "development"
                                                ? `https://admin-dev.salvex.com/admin/_proc/_company/project_populate.cfm?project_id=${val.project_id}`
                                                : `https://admin-dev.salvex.com/admin/_proc/_company/project_populate.cfm?project_id=${val.project_id}`
                                            }
                                          >
                                            + POPULATE
                                          </button>
                                        </td>
                                        <td>
                                          <button
                                            className="btn btn-primary btn-xs-size btn-auto"
                                            onClick={() => getDropdown(val)}
                                          >
                                            ASSIGN
                                          </button>
                                        </td>
                                        <td>
                                          <button
                                            className="btn btn-primary btn-xs-size btn-auto"
                                            onClick={() => getEditModal(val?.project_id)}
                                          >
                                            EDIT
                                          </button>
                                        </td>
                                        <td>{val.user_count}</td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          </td>
                        </tr>
                      )}
                    </>
                  ))}
              </tbody>
            </table>
          </div>
        )}
        {!loading && detailData?.data?.length > 0 && (
          <div className="d-flex justify-content-between mt-3">
            <div className="justify-content-start">
              Page {detailData?.page} of {detailData?.max_pages} ({detailData?.total_records} records)
            </div>
            <div class="table-pagination">
              <ul class="pagination">
                <li class="page-item">
                  <a
                    class={`page-link ${page === 1 ? "disabled" : "cursor-pointer"}`}
                    aria-label="Previous"
                    onClick={() => {
                      if (page !== 1) {
                        setAction(true);
                        setPage(page - 1);
                      }
                    }}
                  >
                    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7 13L1 7L7 1" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </a>
                </li>
                <li class="page-item active">
                  <a class="page-link" href="javascript:void(0)">
                    {page}
                  </a>
                </li>
                <li class="page-item">
                  {page < detailData?.max_pages && (
                    <a
                      class="page-link"
                      onClick={() => {
                        setAction(true);
                        setPage(page + 1);
                      }}
                    >
                      {page + 1}
                    </a>
                  )}
                </li>
                <li class="page-item">
                  {page < detailData?.max_pages && (
                    <a
                      class="page-link"
                      href="javascript:void(0)"
                      aria-label="Next"
                      onClick={() => {
                        setAction(true);
                        setPage(page + 1);
                      }}
                    >
                      <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 13L7 7L1 1" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </a>
                  )}
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
      <AssignModal
        show={assignModal}
        onHide={toggleAssignModal}
        assignData={assignData}
        setAssignData={setAssignData}
        modalData={modalData}
        loading={loading}
        handleSubmit={handleAssign}
      />
      <EditModal
        show={editModal}
        onHide={toggleEditModal}
        title={title}
        editData={editData}
        setEditData={setEditData}
        loading={loading}
        country={country}
        state={state}
        handleEdit={handleEdit}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getEnterpriseType: (callback) => dispatch(getEnterpriseType(callback)),
  getBuAssignment: (params, callback) => dispatch(getBuAssignment(params, callback)),
  getIndustry: (empId, callback) => dispatch(getIndustry(empId, callback)),
  getEnterprises: (empId, callback) => dispatch(getEnterprises(empId, callback)),
  getBuRegion: (empId, callback) => dispatch(getBuRegion(empId, callback)),
  unSubscribe: (data, callback) => dispatch(unSubscribe(data, callback)),
  assignProject: (data, callback) => dispatch(assignProject(data, callback)),
  otherAuctions: (data, callback) => dispatch(otherAuctions(data, callback)),
});
const mapStateToProps = (state) => ({
  dashboardData: dashboardData(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(EnterpriseAssignments));
