import React, { useState } from "react";
import { connect } from "react-redux";
import pdfFile from "../../../include/images/pdf-file-icon.svg";
import trash from "../../../include/images/trash-24x24.svg";
import DeleteModal from "../../../layouts/modals/deleteModal";
import UploadDocuments from "../../../layouts/modals/uploadDocuments";
import {
  deleteListingDocument,
  getListingMedia,
  listingPrivateDocuments,
  listingPrivateDocumentsReceived,
  uploadPrivateListingDocuments,
} from "../../../store/listingsMedia";
import { Amplify, Storage } from "aws-amplify";
import { Auth } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import AlertError from "../../../common/alerts/alertError";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import { toast } from "react-toastify";
// import pdfFile from "../../../include/images/pdf-file-icon.svg";
import csvFile from "../../../include/images/csv-latest.svg";
import XLSX from "../../../include/images/XLSX.svg";
import XLS from "../../../include/images/XLS.svg";
import DOCX from "../../../include/images/Docx.svg";
import DOC from "../../../include/images/Doc.svg";
import Skeleton from "react-loading-skeleton";
import helpCircle from "../../../include/images/help-circle.svg";
import { getModals, setPrivateDocModal } from "../../../store/modal";

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    region: "us-east-1",
  },
  Storage: {
    bucket: process.env.REACT_APP_S3_UPLOAD_BUCKET,
    region: "us-east-1",
  },
});
const PrivateDocuments = (props) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const [uploadDocuments, setUploadDocuments] = useState([]);
  const [docTitle, setDocTitle] = useState("");
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [error, setError] = useState({});
  const [selectedItem, setSelectedItem] = useState("");
  const [fileType, setFileType] = useState(".pdf");

  const getListingData = props.getMediaData && props.getMediaData.privateDocuments;
  const showModal = props?.privateDocModal;

  const { loadingDocument } = props;

  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const toggleDocumentModal = () => {
    props?.setShowModal(!showModal);
    setFileType(".pdf");
  };

  const reloadDocument = () => {
    props.refStart();
    props.listingPrivateDocuments(props.auctionId, (res) => {
      if (res && res.status === 200) {
        props.refStop();
      } else {
        props.refStop();
        toast(<AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const validateTitle = () => {
    const errors = { ...error };
    if (docTitle && docTitle.trim().length > 0) {
      errors.title = "";
    }

    if (uploadDocuments && uploadDocuments.length > 0) {
      errors.document = "";
    }

    if (!uploadDocuments || uploadDocuments?.length == 0) {
      errors.document = <p className="val-error-text">Document is required</p>;
    }

    if (!docTitle || !docTitle?.trim() || !docTitle?.trim()?.length) {
      errors.title = <p className="val-error-text">Title is required</p>;
    }
    const isEmpty = Object.values(errors).every((x) => x === null || x === "");

    if (!isEmpty) {
      setError(errors);
      return true;
    } else {
      setError(errors);
      return false;
    }
  };

  const onChangeDocuments = (e) => {
    if (e && e.target && e.target.files) {
      setUploadDocuments([e.target.files[0]]);
    } else {
      setUploadDocuments([]);
    }
  };

  const handleSubmitDocument = (e) => {
    if (!validateTitle() && uploadDocuments.length) {
      e.preventDefault();
      setLoadingUpload(true);
      const file = uploadDocuments[0];
      props.refStart();
      const fSize = Math.round(file.size / 1048576);
      const fType = file.type;
      const ext = file.name.split(".").pop();
      if (fSize > 25) {
        props.refStop();
        toast(<AlertError message="Size exceeds maximum allowable size. Maximum allowable size is 25MB." />);
        return setLoadingUpload(false);
      } else if (
        ![
          "application/pdf",
          "text/csv",
          "application/vnd.ms-excel",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ].includes(fType)
      ) {
        props.refStop();
        toast(
          <AlertError message="File is not of correct format and hence cannot be uploaded. Valid document formats are pdf, xlsx, xls, doc, docx and csv." />,
        );
        return setLoadingUpload(false);
      } else {
        const fileName = "newSalvex/doc/" + uuidv4() + "." + ext;
        Storage.put(fileName, file, {
          completeCallback: (event) => {},
          progressCallback: (progress) => {},
          errorCallback: (err) => {
            return setLoadingUpload(false), props.refStop(), toast(<AlertError message={"Something Went Wrong"} />);
          },
        }).then((result) => {
          props.upload(
            props.auctionId,
            {
              document: "public/" + result.key,
              name: docTitle,
            },
            (res) => {
              if (res.status === 200) {
                setUploadDocuments([]);
                setDocTitle("");
                props.refStop();
                reloadDocument();
                toast(<AlertSuccess message={"Information Saved"} />);
                setLoadingUpload(false);
              } else {
                setLoadingUpload(false);
                props.refStop();
                toast(
                  <AlertError
                    message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"}
                  />,
                );
              }
            },
          );
        });
      }
    }
  };

  const deleteHandler = () => {
    setLoadingDelete(true);
    props.refStart();
    const id = selectedItem && selectedItem.doc_id;
    props.deleteDocument(id, (res) => {
      if (res && res.status === 200) {
        const docs = [...getListingData.docs.filter((item) => item.doc_id !== id)];
        props.listingPrivateDocumentsReceived({ docs });
        props.refStop();
        setLoadingDelete(false);
        toggleDeleteModal();
        toast(<AlertSuccess message={"Information Saved"} />);
      } else {
        props.refStop();
        setLoadingDelete(false);
        setLoadingDelete(false);
        toggleDeleteModal();
        toast(<AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  return (
    <>
      <div class="details-page-widget-block" id={props.id}>
        <div class="page-widget-head d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <div
              class="page-widget-label text-capitalize cursor-pointer"
              onClick={() => !loadingDocument && toggleDocumentModal()}
            >{`Private Documents (${getListingData?.docs?.length})`}</div>
          </div>
          {/* {loadingDocument ? (
            <div style={{ width: "10rem" }}>
              <Skeleton height="30px" width="100%" />
            </div>
          ) : (
            <div class="btn-block" onClick={() => toggleDocumentModal()}>
              <a href="javascript:void(0)" class="btn btn-primary btn-sm">
                View All/Upload
              </a>
            </div>
          )} */}
        </div>
        {!loadingDocument && getListingData?.docs?.length > 0 && (
          <div class="page-widget-body mt-3">
            {loadingDocument ? (
              <>
                {[1, 2, 3, 4, 5].map((item) => (
                  <div class="table-responsive mb-3">
                    <table class="table align-middle table-borderless publicDocTable mb-0">
                      <colgroup>
                        <col style={{ width: "50px" }} />
                        <col />
                        <col style={{ width: "50px" }} />
                      </colgroup>
                      <tbody>
                        <tr>
                          <td>
                            <Skeleton height="40px" />
                          </td>
                          <td>
                            <Skeleton height="30px" />
                          </td>
                          <td>
                            <Skeleton width="30px" height="30px" />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ))}
                <Skeleton height="70px" />
                <div class="page-widget-button" style={{ marginTop: "10px" }}>
                  <Skeleton height="30px" />
                </div>
              </>
            ) : getListingData && getListingData.docs && getListingData.docs.length > 0 ? (
              <>
                {getListingData.docs.map((item, i) => {
                  const filenameArray = item.filename.split(".");
                  const fileType = filenameArray[filenameArray.length - 1];
                  return (
                    i < 5 && (
                      <div class="table-responsive mb-3">
                        <table class="table align-middle table-borderless publicDocTable mb-0">
                          <colgroup>
                            <col style={{ width: "50px" }} />
                            <col />
                            <col style={{ width: "50px" }} />
                          </colgroup>
                          <tbody>
                            <tr>
                              <td>
                                <div class="td-pdf-img-box">
                                  <img
                                    src={
                                      fileType === "csv"
                                        ? csvFile
                                        : fileType === "xlsx"
                                        ? XLSX
                                        : fileType === "xls"
                                        ? XLS
                                        : fileType === "doc"
                                        ? DOC
                                        : fileType === "docx"
                                        ? DOCX
                                        : pdfFile
                                    }
                                    alt=""
                                  />
                                </div>
                              </td>

                              <td>
                                <a
                                  href={
                                    item.filename.substring(0, 5) === "https"
                                      ? `${item.filename}`
                                      : item.ft_id === 10
                                      ? `${process.env.REACT_APP_S3_PRIVATE_DOC_BUCKET}${item.folder}${item.filename}`
                                      : `${process.env.REACT_APP_MEDIA_URL}${item.filename}`
                                  }
                                  target="_blank"
                                  download={true}
                                  className="deafult-black-hover-green"
                                >
                                  {item.name.includes(".") ? item.name : item.name + "." + fileType}
                                </a>
                              </td>
                              <td>
                                <button
                                  type="button"
                                  class="delete-td-icon d-flex align-items-center justify-content-center"
                                  onClick={() => {
                                    setSelectedItem(item);
                                    toggleDeleteModal();
                                  }}
                                >
                                  <img src={trash} alt="" />
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    )
                  );
                })}
              </>
            ) : (
              <div class="document-upload-block d-flex flex-column align-items-center justify-content-center">
                <div class="dub-label">No Documents are currently associated with this listing.</div>
                <div class="dub-group-input mt-2 position-relative" onClick={() => toggleDocumentModal()}>
                  <button type="button" class="file-upload-button cursor-pointer" onClick={() => toggleDocumentModal()}>
                    Click here to upload Documents
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
        <UploadDocuments
          show={showModal}
          onHide={toggleDocumentModal}
          getListingData={getListingData}
          onChangeDocuments={onChangeDocuments}
          uploadDocuments={uploadDocuments}
          setDocTitle={setDocTitle}
          docTitle={docTitle}
          handleSubmitDocument={handleSubmitDocument}
          loading={loadingUpload}
          error={error}
          onDelete={toggleDeleteModal}
          setSelectedItem={setSelectedItem}
          fileType={fileType}
          setFileType={setFileType}
          type="Private"
        />
        <DeleteModal
          show={showDeleteModal}
          onHide={toggleDeleteModal}
          loadingDelete={loadingDelete}
          deleteHandler={deleteHandler}
        />
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  //global states for storing api data
  listingPrivateDocuments: (params, callback) => dispatch(listingPrivateDocuments(params, callback)),
  upload: (params, data, callback) => dispatch(uploadPrivateListingDocuments(params, data, callback)),
  deleteDocument: (params, callback) => dispatch(deleteListingDocument(params, callback)),
  //global state for manipulating data
  listingPrivateDocumentsReceived: (data) => dispatch(listingPrivateDocumentsReceived(data)),
  setShowModal: (data) => dispatch(setPrivateDocModal(data)),
});
const mapStateToProps = (state) => ({
  getMediaData: getListingMedia(state),
  privateDocModal: getModals(state)?.privateDocModal,
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(PrivateDocuments));
