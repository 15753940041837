import React, { useEffect, useRef, useState } from "react";
import Header from "../../layouts/header";
import LoadingBar from "react-top-loading-bar";
import { connect } from "react-redux";
import {
  getBuyerListings,
  getEmailReportLog,
  getKeyBuyerListings,
  primarySellerData,
  updateBuyerReportEmailFrequency,
} from "../../store/primarySeller";
import AlertError from "../../common/alerts/alertError";
import { toast } from "react-toastify";
import FilterModal from "../../components/settings/sellerListings/modals/filterModal";
import _, { isBoolean } from "lodash";
import EmailReportLog from "../../layouts/modals/primarySeller/emailReportLog";
import moment from "moment";
import BuyerListingsTable from "../../components/settings/buyerListings/buyerListingsTable";
import KeyBuyerListingsTable from "../../components/settings/keyBuyerListings/keyBuyerListingsTable";

const KeyBuyerListings = (props) => {
  const ref = useRef(null);
  const data = props?.primarySellerData?.keyBuyerListings?.data;
  const totalPages = props?.primarySellerData?.keyBuyerListings?.max_pages;
  const totalCount = props?.primarySellerData?.keyBuyerListings?.total_records;
  const emailHistoryReport = props?.primarySellerData?.emailReportLog?.data;
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [sort, setSort] = useState("date_created");
  const [order, setOrder] = useState("desc");
  const [keyword, setKeyword] = useState("");
  const [frequency, setFrequency] = useState("");
  const [updatedBy, setUpdatedBy] = useState("");
  const [lastDate, setLastDate] = useState();
  const [nextDate, setNextDate] = useState();
  const [enable, setEnable] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [showEmailReport, setShowEmailReport] = useState(false);
  const [response, setResponse] = useState({
    frequency: "",
    keyword: "",
    updatedBy: "",
    lastDate: "",
    nextDate: "",
  });
  const [refresh, setRefresh] = useState(false);

  const toggleFilter = () => {
    setShowFilter(!showFilter);
    setKeyword(response?.keyword);
    setFrequency(response?.frequency);
    setUpdatedBy(response?.updatedBy);
    setLastDate(response?.lastDate);
    setNextDate(response?.nextDate);
  };
  const toggleEmailReport = () => {
    setShowEmailReport(!showEmailReport);
  };

  const getEmailReportHistory = (userId) => {
    const payload = {
      sort: "createdAt",
      order: "desc",
    };
    ref?.current?.continuousStart();
    props?.getEmailReportLog(userId, payload, (res) => {
      if (res.status === 200) {
        ref?.current?.complete();
        toggleEmailReport();
      } else {
        ref?.current?.complete();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  useEffect(() => {
    ref?.current?.continuousStart();
    setLoading(true);
    const param = {
      page: 1,
      sort: sort,
      order: order,
      key_buyer_report_email_frequency: response?.frequency,
      keyword: response?.keyword,
      next_key_buyer_email_to_be_sent_date: response?.nextDate,
      key_buyer_email_sent_date: response?.lastDate,
    };
    const payload = _.pickBy(param, function (value, key) {
      return !(value === "" || value == {} || value === undefined || value === null);
    });
    props?.getKeyBuyerListings(payload, (res) => {
      if (res.status === 200) {
        setEnable(true);
        ref?.current?.complete();
        setLoading(false);
      } else {
        ref?.current?.complete();
        setLoading(false);
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  }, []);
  useEffect(() => {
    if (enable) {
      ref?.current?.continuousStart();
      setLoading(true);
      const param = {
        page: page,
        sort: sort,
        order: order,
        key_buyer_report_email_frequency: response?.frequency,
        keyword: response?.keyword,
        next_key_buyer_email_to_be_sent_date: response?.nextDate,
        key_buyer_email_sent_date: response?.lastDate,
      };
      const payload = _.pickBy(param, function (value, key) {
        return !(value === "" || value == {} || value === undefined || value === null);
      });
      props?.getKeyBuyerListings(payload, (res) => {
        if (res.status === 200) {
          ref?.current?.complete();
          setLoading(false);
        } else {
          ref?.current?.complete();
          setLoading(false);
          toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        }
      });
    }
  }, [page]);

  useEffect(() => {
    if (enable) {
      setEnable(false);
      setPage(1);
      ref?.current?.continuousStart();
      setLoading(true);
      const param = {
        page: 1,
        sort: sort,
        order: order,
        key_buyer_report_email_frequency: response?.frequency,
        keyword: response?.keyword,
        next_key_buyer_email_to_be_sent_date: response?.nextDate,
        key_buyer_email_sent_date: response?.lastDate,
      };
      const payload = _.pickBy(param, function (value, key) {
        return !(value === "" || value == {} || value === undefined || value === null);
      });
      props?.getKeyBuyerListings(payload, (res) => {
        if (res.status === 200) {
          ref?.current?.complete();
          setLoading(false);
          setEnable(true);
        } else {
          ref?.current?.complete();
          setLoading(false);
          setEnable(true);
          toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        }
      });
    }
  }, [sort, order]);

  useEffect(() => {
    if (enable) {
      setEnable(false);
      // ref?.current?.continuousStart();
      const param = {
        page: page,
        sort: sort,
        order: order,
        key_buyer_report_email_frequency: response?.frequency,
        keyword: response?.keyword,
        next_key_buyer_email_to_be_sent_date: response?.nextDate,
        key_buyer_email_sent_date: response?.lastDate,
      };
      const payload = _.pickBy(param, function (value, key) {
        return !(value === "" || value == {} || value === undefined || value === null);
      });
      props?.getKeyBuyerListings(payload, (res) => {
        if (res.status === 200) {
          ref?.current?.complete();
          setEnable(true);
        } else {
          ref?.current?.complete();
          setEnable(true);
          toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        }
      });
    }
  }, [refresh]);

  const handleFilter = () => {
    setEnable(false);
    setPage(1);
    ref?.current?.continuousStart();
    setLoading(true);
    const param = {
      page: 1,
      sort: sort,
      order: order,
      key_buyer_report_email_frequency: frequency,
      keyword: keyword,
      next_key_buyer_email_to_be_sent_date: nextDate,
      key_buyer_email_sent_date: lastDate,
    };
    const payload = _.pickBy(param, function (value, key) {
      return !(value === "" || value == {} || value === undefined || value === null);
    });
    props?.getKeyBuyerListings(payload, (res) => {
      if (res.status === 200) {
        const data = { ...response };
        data.frequency = frequency;
        data.keyword = keyword;
        data.updatedBy = updatedBy;
        data.nextDate = nextDate;
        data.lastDate = lastDate;
        setResponse(data);
        toggleFilter();
        ref?.current?.complete();
        setLoading(false);
        setEnable(true);
      } else {
        ref?.current?.complete();
        setLoading(false);
        setEnable(true);
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };
  const clearFilter = (x) => {
    setEnable(false);
    setPage(1);
    ref?.current?.continuousStart();
    setLoading(true);
    const param = {
      page: 1,
      sort: sort,
      order: order,
      key_buyer_report_email_frequency: x === "frequency" ? "" : response?.frequency,
      keyword: x === "keyword" ? "" : response?.keyword,
      next_key_buyer_email_to_be_sent_date: x === "nextDate" ? "" : response?.nextDate,
      key_buyer_email_sent_date: x === "lastDate" ? "" : response?.lastDate,
    };
    const payload = _.pickBy(param, function (value, key) {
      return !(value === "" || value == {} || value === undefined || value === null);
    });
    props?.getKeyBuyerListings(payload, (res) => {
      if (res.status === 200) {
        ref?.current?.complete();
        setLoading(false);
        setEnable(true);
      } else {
        ref?.current?.complete();
        setLoading(false);
        setEnable(true);
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  return (
    <>
      <LoadingBar height={5} color="#47AD1D" ref={ref} />
      <Header />
      <KeyBuyerListingsTable
        data={data}
        page={page}
        refStart={ref?.current?.continuousStart}
        refStop={ref?.current?.complete}
        setPage={setPage}
        loading={loading}
        totalPages={totalPages}
        totalCount={totalCount}
        order={order}
        setOrder={setOrder}
        sort={sort}
        setSort={setSort}
        response={response}
        setResponse={setResponse}
        toggleFilter={toggleFilter}
        clearFilter={clearFilter}
        getEmailReportHistory={getEmailReportHistory}
        updateBuyerReportEmailFrequency={props?.updateBuyerReportEmailFrequency}
        setEnable={setEnable}
        setRefresh={setRefresh}
        refresh={refresh}
      />
      <FilterModal
        show={showFilter}
        onHide={toggleFilter}
        frequency={frequency}
        setFrequency={setFrequency}
        keyword={keyword}
        setKeyword={setKeyword}
        updatedBy={updatedBy}
        setUpdatedBy={setUpdatedBy}
        lastDate={lastDate}
        setLastDate={setLastDate}
        nextDate={nextDate}
        setNextDate={setNextDate}
        handleFilter={handleFilter}
        loading={loading}
        buyer={true}
        keyBuyer={true}
      />
      <EmailReportLog show={showEmailReport} onHide={toggleEmailReport} data={emailHistoryReport} />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getKeyBuyerListings: (params, callback) => dispatch(getKeyBuyerListings(params, callback)),
});

const mapStateToProps = (state) => ({
  primarySellerData: primarySellerData(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(KeyBuyerListings));
