import React from "react";
import { useState } from "react";
import { Offcanvas } from "react-bootstrap";

import Select from "react-select";
import { customStyles } from "../../../common/customStyles/reactSelectStyle";
const FilterPipeLog = (props) => {
  const booleanOptions = [
    { name: "Yes", value: true },
    { name: "No", value: false },
  ];

  const customStyles1 = {
    indicatorSeparator: (styles) => ({ display: "none" }),
    option: (provided, state) => ({
      ...provided,
      "&:hover": {
        backgroundColor: "#47ad1d",
        color: "#F5F5F5",
      },
      display: "-webkit-box",
      WebkitLineClamp: 3,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      color: state.isSelected ? "#fff" : "black",
      backgroundColor: state.isSelected ? "#47ad1d" : provided.backgroundColor,
      // maxHeight: "84px",
    }),
    control: (base, state) => ({
      ...base,
      minHeight: 42,
      background: state.isDisabled ? "#e9ecef" : "#f5f5f5",
      borderColor: state.isFocused ? "#47AD1D" : "#e4e4e4",
      boxShadow: state.isFocused ? "0 0 0 0.5px #47AD1D" : "0",
      border: state.isHovered ? "0" : "0px solid #e4e4e4",
      color: state.isDisabled ? "black" : "#f5f5f5",

      "&:hover": {
        borderColor: state.isFocused ? "#47AD1D" : "0",
        boxShadow: state.isFocused ? "0 0 0 0.5px #47AD1D" : "0",
      },
    }),
    multiValueRemove: (base, state) => ({
      ...base,
      "&:hover": {
        backgroundColor: "rgb(95, 50, 187, 10%)",
        color: "#6119c0",
      },
    }),
  };

  return (
    <Offcanvas
      show={props.show}
      onHide={() => props.onHide()}
      placement="end"
      className="offcanvas offcanvas-end custom-offcanvas-sm "
      backdropClassName="upload-images-backdrop"
      tabindex="-1"
      id="userFilter"
      enforceFocus={false}
    >
      <Offcanvas.Header className="offcanvas-header aviation-filter-fix">
        <h5 class="offcanvas-title">Filters</h5>
        <button type="button" className="btn-close text-reset" onClick={() => props.onHide()}></button>
      </Offcanvas.Header>
      <Offcanvas.Body className="offcanvas-body co-body">
        <div class="form-group">
          <label for="keyword" class="form-label">
            Listing ID
          </label>
          <input
            type="number"
            class="form-control"
            id="auction-search"
            value={props.selectedFilter?.auctionId}
            onChange={(e) => {
              const data = { ...props.selectedFilter };
              data.auctionId = e.target.value;
              props.setSelectedFilter(data);
            }}
          />
        </div>
        <div class="form-group">
          <label for="keyword" class="form-label">
            Part Number
          </label>
          <input
            type="text"
            class="form-control"
            id="partNumber"
            value={props.selectedFilter?.partNumber}
            onChange={(e) => {
              const data = { ...props.selectedFilter };
              data.partNumber = e.target.value;
              props.setSelectedFilter(data);
            }}
          />
        </div>
        {/* <div class="form-group">
          <label for="keyword" class="form-label">
            Part of the Plane
          </label>
          <Select
            value={props.selectedFilter?.planeParts}
            options={[{ name: "Select Part of the Plane", value: "-1" }, ...props.part]}
            isMulti
            className="basic-single country-margin select-background "
            classNamePrefix="select-search country-margin select-background"
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.value}
            onChange={(e) => {
              const data = { ...props.selectedFilter };
              data.planeParts = e;
              props.setSelectedFilter(data);
            }}
            name="planePart"
            placeholder="Select Part of the Plane"
            styles={customStyles}
          />
        </div> */}
        <div class="form-group">
          <label for="keyword" class="form-label">
            Condition
          </label>
          {props?.isAviation ? (
            <Select
              value={props.selectedFilter?.planeCondition}
              options={props.condition}
              isMulti
              className="basic-single country-margin select-background "
              classNamePrefix="select-search country-margin select-background"
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.value}
              onChange={(e) => {
                const data = { ...props.selectedFilter };
                data.planeCondition = e;
                props.setSelectedFilter(data);
              }}
              name="condition"
              placeholder="Select Condition"
              styles={customStyles1}
            />
          ) : (
            <Select
              value={props.selectedFilter?.partCondition}
              options={props.condition}
              isMulti
              className="basic-single country-margin select-background "
              classNamePrefix="select-search country-margin select-background"
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.value}
              onChange={(e) => {
                const data = { ...props.selectedFilter };
                data.partCondition = e;
                props.setSelectedFilter(data);
              }}
              name="condition"
              placeholder="Select Condition"
              styles={customStyles1}
            />
          )}
        </div>

        {props?.isAviation && (
          <div class="form-group">
            <label for="keyword" class="form-label">
              Certificates
            </label>
            <Select
              value={props.selectedFilter?.certificates}
              options={[{ name: "Select Certificates", value: "" }, ...booleanOptions]}
              className="basic-single country-margin select-background "
              classNamePrefix="select-search country-margin select-background"
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.value}
              onChange={(e) => {
                const data = { ...props.selectedFilter };
                data.certificates = e;
                props.setSelectedFilter(data);
              }}
              name="certificates"
              placeholder="Select Certificates"
              styles={customStyles}
            />
          </div>
        )}
        {/* <div class="form-group">
          <label for="keyword" class="form-label">
            Warranty
          </label>
          <Select
            value={props.selectedFilter?.warranty}
            options={[{ name: "Select Warranty", value: "-1" }, ...booleanOptions]}
            className="basic-single country-margin select-background "
            classNamePrefix="select-search country-margin select-background"
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.value}
            onChange={(e) => {
              const data = { ...props.selectedFilter };
              data.warranty = e;
              props.setSelectedFilter(data);
            }}
            name="warranty"
            placeholder="Select Warranty"
            styles={customStyles}
          />
        </div>
        <div class="form-group">
          <label for="keyword" class="form-label">
            Part Manufacturer
          </label>
          <Select
            value={props.selectedFilter?.manufacturerParts}
            options={[{ name: "Select Part Manufacturer", value: "-1" }, ...props.mfr]}
            isMulti
            className="basic-single country-margin select-background "
            classNamePrefix="select-search country-margin select-background"
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.value}
            onChange={(e) => {
              const data = { ...props.selectedFilter };
              data.manufacturerParts = e;
              props.setSelectedFilter(data);
            }}
            name="partManufacturer"
            placeholder="Select Part Manufacturer"
            styles={customStyles}
          />
        </div>
        <div class="form-group">
          <label for="planeModelFilter" class="form-label">
            Plane Model
          </label>
          <Select
            value={props.selectedFilter?.planeModel}
            options={[{ name: "Select Plane Model", value: "-1" }, ...props.model]}
            isMulti
            className="basic-single country-margin select-background "
            classNamePrefix="select-search country-margin select-background"
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.value}
            onChange={(e) => {
              const data = { ...props.selectedFilter };
              data.planeModel = e;
              props.setSelectedFilter(data);
            }}
            name="planeModel"
            placeholder="Select Plane Model"
            styles={customStyles}
          />
        </div> */}
      </Offcanvas.Body>
      <div class="offcanvas-footer">
        <a
          href="javascript:void(0)"
          class={`btn btn-default btn-block ${props.loading && "disabled"}`}
          onClick={() => {
            const data = { ...props.filterValues };
            props?.setPage(1);
            props.handleFilter();
          }}
        >
          Apply Filters
        </a>
      </div>
    </Offcanvas>
  );
};

export default React.memo(FilterPipeLog);
