import React, { useEffect, useRef, useState } from "react";
import Header from "../../layouts/header";
import { connect } from "react-redux";
import LoadingBar from "react-top-loading-bar";
import {
  getCommission,
  getCommissionData,
  getEmployee,
} from "../../store/commission";
import CommissionTable from "../../components/accounting/commission/commissionTable";
import { getProfile } from "../../utils.js/localStorageServices";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";

const Commission = (props) => {
  const ref = useRef(null);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [sort, setSort] = useState("1");
  const [order, setOrder] = useState("asc");
  const [page, setPage] = useState(1);
  const [loadingFilter, setLoadingFilter] = useState(false);

  const member = getProfile();

  const isInvestmentAccessible =
    member &&
    member?.accessLevels &&
    member?.accessLevels?.employee_investment_admin_access
      ? member?.accessLevels?.employee_investment_admin_access
      : false;

  useEffect(() => {
    if (!isInvestmentAccessible) {
      history.push("/");
    }
  }, [member]);

  const employee =
    props.getCommission &&
    props.getCommission.employee &&
    props.getCommission.employee.data;

  const employeeData =
    employee &&
    employee.length > 0 &&
    employee.map((item) => ({
      name: item.name01,
      value: item?.member_id,
    }));

  const [filteredValues, setFilteredValues] = useState({
    startDate: new Date(moment().subtract(3, "months").format("MM/DD/YYYY")),
    endDate: new Date(moment().format("MM/DD/YYYY")),
    m_id: employeeData?.[0] || { name: "", value: 0 },
  });

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  useEffect(() => {
    if (employee && employee.length > 0) {
      const data = { ...filteredValues };
      data.m_id = {
        name: employee[0]?.name01,
        value: employee[0]?.member_id,
      };
      setFilteredValues(data);
      setLoading(true);
      const params = {
        page: 1,
        order,
        sort,
        m_id: employee[0]?.member_id ? employee[0]?.member_id : 0,
        startDate: filteredValues?.startDate
          ? Date.parse(filteredValues?.startDate)
          : "",
        endDate: filteredValues?.endDate
          ? Date.parse(filteredValues?.endDate)
          : "",
      };
      props.getCommissionData(params, (res) => {
        if (res && res.status === 200) {
          ref.current.complete();
          setLoading(false);
        } else {
          ref.current.complete();
          setLoading(false);
        }
      });
    }
  }, [employee]);

  useEffect(() => {
    if (filteredValues?.m_id?.value) {
      setLoading(true);
      ref.current.continuousStart();
      const params = {
        page: page,
        order,
        sort,
        m_id: filteredValues?.m_id?.value ? filteredValues.m_id?.value : 0,
        startDate: filteredValues?.startDate
          ? Date.parse(filteredValues?.startDate)
          : "",
        endDate: filteredValues?.endDate
          ? Date.parse(filteredValues?.endDate)
          : "",
      };
      props.getCommissionData(params, (res) => {
        if (res && res.status === 200) {
          ref.current.complete();
          setLoading(false);
        } else {
          ref.current.complete();
          setLoading(false);
        }
      });
    }
  }, [page]);

  useEffect(() => {
    setLoading(true);
    ref.current.continuousStart();
    props.getEmployee((res) => {});
  }, []);

  const commissionData =
    props.getCommission &&
    props.getCommission.commission &&
    props.getCommission.commission.data;

  const totalCount =
    props.getCommission &&
    props.getCommission.commission &&
    props.getCommission.commission?.total_records;

  const filteredCount =
    props.getCommission &&
    props.getCommission.commission &&
    props.getCommission.commission.per_page;
  const totalProfit =
    props.getCommission &&
    props.getCommission.commission &&
    props.getCommission.commission.totalProfit;
  const totalCommission =
    props.getCommission &&
    props.getCommission.commission &&
    props.getCommission.commission.totalCommission;

  const totalPages =
    props.getCommission &&
    props.getCommission.commission &&
    props.getCommission.commission?.max_pages;

  const handleFilter = () => {
    setLoading(true);
    ref?.current?.continuousStart();

    props.getCommissionData(
      {
        page: page,
        order,
        sort,
        m_id: filteredValues?.m_id?.value ? filteredValues.m_id?.value : 0,
        startDate: filteredValues?.startDate
          ? Date.parse(filteredValues?.startDate)
          : "",
        endDate: filteredValues?.endDate
          ? Date.parse(filteredValues?.endDate)
          : "",
      },
      (res) => {
        if (res && res.status == 200) {
          toggleFilter();
          setLoading(false);
          ref?.current?.complete();
        }
      }
    );
  };

  const getSortedData = (sort, order) => {
    setLoading(true);
    ref?.current?.continuousStart();

    props.getCommissionData(
      {
        page: page,
        order,
        sort,
        m_id: filteredValues?.m_id?.value ? filteredValues.m_id?.value : 0,
        startDate: filteredValues?.startDate
          ? Date.parse(filteredValues?.startDate)
          : "",
        endDate: filteredValues?.endDate
          ? Date.parse(filteredValues?.endDate)
          : "",
      },
      (res) => {
        if (res && res.status == 200) {
          setLoading(false);
          ref?.current?.complete();
        }
      }
    );
  };

  useEffect(() => {
    if (sort == "1") {
      if (order == "asc") {
        getSortedData("1", "asc");
      } else {
        getSortedData("1", "desc");
      }
    } else if (sort == "2") {
      if (order == "asc") {
        getSortedData("2", "asc");
      } else {
        getSortedData("2", "desc");
      }
    } else if (sort == "3") {
      if (order == "asc") {
        getSortedData("3", "asc");
      } else {
        getSortedData("3", "desc");
      }
    } else if (sort == "4") {
      if (order == "asc") {
        getSortedData("4", "asc");
      } else {
        getSortedData("4", "desc");
      }
    } else if (sort == "5") {
      if (order == "asc") {
        getSortedData("5", "asc");
      } else {
        getSortedData("5", "desc");
      }
    } else if (sort == "6") {
      if (order == "asc") {
        getSortedData("6", "asc");
      } else {
        getSortedData("6", "desc");
      }
    } else if (sort == "0") {
      if (order == "asc") {
        getSortedData("0", "asc");
      } else {
        getSortedData("0", "desc");
      }
    }
  }, [order, sort]);

  return (
    <>
      <LoadingBar height={5} color="#47AD1D" ref={ref} />
      <Header />
      <CommissionTable
        loading={loading}
        commissionData={commissionData}
        employeeData={employeeData}
        filteredCount={filteredCount}
        totalCount={totalCount}
        totalPages={totalPages}
        toggleFilter={toggleFilter}
        filteredValues={filteredValues}
        setFilteredValues={setFilteredValues}
        showFilter={showFilter}
        page={page}
        setPage={setPage}
        loadingFilter={loadingFilter}
        sort={sort}
        order={order}
        handleFilter={handleFilter}
        setOrder={setOrder}
        setSort={setSort}
        totalProfit={totalProfit}
        totalCommission={totalCommission}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getCommissionData: (params, callback) =>
    dispatch(getCommissionData(params, callback)),
  getEmployee: (callback) => dispatch(getEmployee(callback)),
});

const mapStateToProps = (state) => ({
  getCommission: getCommission(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(Commission));
