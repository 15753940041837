import { createSelector, createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";

const slice = createSlice({
  name: "ei",
  initialState: {
    loading: false,
    region: {},
    country: {},
    state: {},
    category: {},
    species: {},
    auctionSpecies: {},
    organizations: {},
    carbonOffset: {},
  },
  reducers: {
    regionRequested: (state, action) => {
      state.loading = true;
    },
    regionReceived: (state, action) => {
      state.region = action.payload;
      state.loading = false;
    },
    regionFailed: (state, action) => {
      state.loading = false;
    },
    countryRequested: (state, action) => {
      state.loading = true;
    },
    countryReceived: (state, action) => {
      state.country = action.payload;
      state.loading = false;
    },
    countryFailed: (state, action) => {
      state.loading = false;
    },
    stateRequested: (state, action) => {
      state.loading = true;
    },
    stateReceived: (state, action) => {
      state.state = action.payload;
      state.loading = false;
    },
    stateFailed: (state, action) => {
      state.loading = false;
    },
    categoryRequested: (state, action) => {
      state.loading = true;
    },
    categoryReceived: (state, action) => {
      state.category = action.payload;
      state.loading = false;
    },
    categoryFailed: (state, action) => {
      state.loading = false;
    },
    speciesRequested: (state, action) => {
      state.loading = true;
    },
    speciesReceived: (state, action) => {
      state.species = action.payload;
      state.loading = false;
    },
    speciesFailed: (state, action) => {
      state.loading = false;
    },
    speciesOrganizationsRequested: (state, action) => {
      state.loading = true;
    },
    speciesOrganizationsReceived: (state, action) => {
      state.organizations = action.payload;
      state.loading = false;
    },
    speciesOrganizationsFailed: (state, action) => {
      state.loading = false;
    },
    carbonOffsetRequested: (state, action) => {
      state.loading = true;
    },
    carbonOffsetReceived: (state, action) => {
      state.carbonOffset = action.payload;
      state.loading = false;
    },
    carbonOffsetFailed: (state, action) => {
      state.loading = false;
    },
    auctionSpeciesRequested: (state, action) => {
      state.loading = true;
    },
    auctionSpeciesReceived: (state, action) => {
      state.auctionSpecies = action.payload;
      state.loading = false;
    },
    auctionSpeciesFailed: (state, action) => {
      state.loading = false;
    },
  },
});

export const {
  regionRequested,
  regionReceived,
  regionFailed,
  countryRequested,
  countryReceived,
  countryFailed,
  stateRequested,
  stateReceived,
  stateFailed,
  categoryRequested,
  categoryReceived,
  categoryFailed,
  speciesRequested,
  speciesReceived,
  speciesFailed,
  auctionSpeciesRequested,
  auctionSpeciesReceived,
  auctionSpeciesFailed,
  speciesOrganizationsRequested,
  speciesOrganizationsReceived,
  speciesOrganizationsFailed,
  carbonOffsetRequested,
  carbonOffsetReceived,
  carbonOffsetFailed,
} = slice.actions;
export default slice.reducer;

const baseUrl = "ei/ei";

//Get Region
export const getRegion = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/region`,
      method: "get",
      callback,
      onStart: regionRequested.type,
      onSuccess: regionReceived.type,
      onError: regionFailed.type,
    }),
  );
};

//Get Country
export const getCountry = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/region/country`,
      method: "get",
      callback,
      onStart: countryRequested.type,
      onSuccess: countryReceived.type,
      onError: countryFailed.type,
    }),
  );
};

//Get State
export const getState = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/region/country/state`,
      method: "get",
      callback,
      onStart: stateRequested.type,
      onSuccess: stateReceived.type,
      onError: stateFailed.type,
    }),
  );
};

//Get Category
export const getCategory = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/species/category`,
      method: "get",
      callback,
      onStart: categoryRequested.type,
      onSuccess: categoryReceived.type,
      onError: categoryFailed.type,
    }),
  );
};

//Get Species
export const getSpecies = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/species`,
      params,
      method: "get",
      callback,
      onStart: speciesRequested.type,
      onSuccess: speciesReceived.type,
      onError: speciesFailed.type,
    }),
  );
};

//Get Auction Species
export const getAuctionSpecies = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/species/${id}`,
      method: "get",
      callback,
      onStart: auctionSpeciesRequested.type,
      onSuccess: auctionSpeciesReceived.type,
      onError: auctionSpeciesFailed.type,
    }),
  );
};

//Get Auction Species
export const saveActionSpecies = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/species/${id}`,
      method: "put",
      data,
      callback,
    }),
  );
};

//Get Species Organizations

export const speciesOrganizations = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `sustainabilityReport/environmentalOrganization?species=${id?.species}`,
      method: "get",
      callback,
      onStart: speciesOrganizationsRequested.type,
      onSuccess: speciesOrganizationsReceived.type,
      onError: speciesOrganizationsFailed.type,
    }),
  );
};

export const getCarbonOffsetReport = (isPrint,id, params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `sustainabilityReport/carbonOffsetReport/${id}`,
      method: "get",
      params,
      callback,
      onStart: carbonOffsetRequested.type,
      onSuccess: !isPrint ? carbonOffsetReceived.type:"",
      onError: carbonOffsetFailed.type,
    }),
  );
};

export const ei = createSelector(
  (state) => state.entities.ei,
  (ei) => ei,
);
