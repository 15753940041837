import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";

const slice = createSlice({
  name: "userlisting",
  initialState: {
    userList: {},
    loading: false,
  },
  reducers: {
    userListRequested: (misc, action) => {
      misc.loading = true;
    },
    userListReceived: (misc, action) => {
      misc.userList = action.payload;
      misc.loading = false;
    },
    userListRequestFailed: (misc, action) => {
      misc.loading = false;
    },
  },
});

export const { userListRequested, userListReceived, userListRequestFailed } =
  slice.actions;
export default slice.reducer;

// Action Creators
const baseUrl = "user";

export const getUserList = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/search`,
      method: "get",
      params,
      callback,
      onStart: userListRequested.type,
      onSuccess: userListReceived.type,
      onError: userListRequestFailed.type,
    })
  );
};
export const userListings = createSelector(
  (state) => state.entities.userlisting,
  (userlisting) => userlisting
);
