import React from "react";
import { Modal } from "react-bootstrap";

const ConfirmModal = (props) => {
  return (
    <Modal
      className=" fade custom-modal-popup confirm-modal-index"
      show={props.show}
      onHide={() => props.onHide()}
      enforceFocus={true}
      backdropClassName={"confirm-backdrop"}
      //   backdrop={uploadImageModal ? false : true}
      centered
    >
      <Modal.Body>
        <div class="modal-alert-box">
          <h4>Alert! </h4>
          <p>{props.message}</p>
        </div>
        <div class="modal-action-btn">
          <a
            href="javascript:void(0)"
            class={`btn btn-md btn-default  ${props.loading && "btn-disabled"}`}
            onClick={() => !props.loading && props.confirmHandler()}
          >
            Confirm
          </a>
          <a
            href="javascript:void(0)"
            style={{ background: "#fff" }}
            class="btn btn-cancel global-cancel-button"
            onClick={() => props.onHide()}
          >
            Cancel
          </a>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmModal;
