import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import close from "../../include/images/close.svg";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker, DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import Select from "react-select";
import { customStyles } from "../../common/customStyles/reactSelectStyle";
import DateFormat from "../../common/DateFormat/dateFormat";
import NextButton from "../../common/form/nextButton";

const MoreDetailsModal = (props) => {
  // function formatDate(dateString) {
  //   const options = {
  //     year: 'numeric',
  //     month: '2-digit',
  //     day: '2-digit',
  //     hour: '2-digit',
  //     minute: '2-digit',
  //   };

  //   const date = new Date(dateString);
  //   const formattedDate = date.toLocaleString('en-US', options);

  //   return formattedDate;
  // }
  function formatDateForDisplay(isoDateString) {
    const date = new Date(isoDateString);
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      timeZone: "UTC", // Set the time zone to UTC
    };
    return date.toLocaleString("en-US", options);
  }

  return (
    <Modal
      className="modal fade custom-modal"
      id="moreDetails"
      dialogClassName="modal-dialog modal-dialog-centered modal-xxl"
      show={props.show}
      onHide={() => props.onHide()}
      enforceFocus={false}
    >
      <div class="modal-content d-flex flex-column">
        <div class="custom-modal-header d-flex align-items-center justify-content-between">
          <h5 class="mb-0 text-capitalize">More Details</h5>
          <button
            type="button"
            class="close-btn d-flex align-items-center justify-content-center"
            onClick={() => props.onHide()}
          >
            <img src={close} alt="" />
          </button>
        </div>
        <Modal.Body className="custom-modal-body flex-grow-1 w-100 overflow-hidden">
          <div class="two-row-column d-flex flex-wrap">
            <div class="tr-column w-50 position-relative">
              <div class="tr-column-content w-100 h-100 d-flex flex-column">
                <table class="table table-borderless align-middle table-left-align">
                  <colgroup>
                    <col style={{ width: "30%" }} />
                    <col />
                  </colgroup>
                  <tbody>
                    <tr>
                      <td>Sale Type</td>
                      <td>
                        <Select
                          value={props.detailData.at_id}
                          className="basic-single"
                          classNamePrefix="select-search "
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.value}
                          name={props.name}
                          options={props.auctionType}
                          placeholder="Select Sale Type"
                          styles={customStyles}
                          onChange={(a) => {
                            const data = { ...props.detailData };
                            data.at_id = a;
                            props.setDetailData(data);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Auction End Date</td>
                      <td>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DateTimePicker
                            disableHighlightToday={true}
                            className="form-control"
                            value={props.auctionEndDate} // ISO 8601 date
                            onChange={(e) => {
                              // Convert the selected date to ISO 8601 format
                              // const isoFormattedDate = e.toISOString();
                              props.setAcutionEndDate(e);
                            }}
                            renderInput={(params) => <TextField {...params} error={false} />}
                            inputFormat="MM/dd/yyyy hh:mm a"
                          />
                        </LocalizationProvider>

                        {props.auctionEndDate && (
                          <p style={{ marginTop: "3px" }}>
                            <DateFormat date={props.auctionEndDate} />
                          </p>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Public Status</td>
                      <td>
                        <Select
                          value={props.detailData.status_id}
                          className="basic-single"
                          classNamePrefix="select-search"
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.value}
                          options={props?.auctionStatus?.length > 0 ? props.auctionStatus : []}
                          name="saleType"
                          placeholder="Select Auction Status"
                          styles={customStyles}
                          onChange={(a) => {
                            const data = { ...props.detailData };
                            data.status_id = a;
                            props.setDetailData(data);
                            props.setReason(true);
                          }}
                        />
                      </td>
                    </tr>
                    {props?.detailData?.status_id?.value === 5 && props.reason && (
                      <tr>
                        <td>
                          <span>Archive Reason</span>
                          <sup style={{ color: "Red" }}>*</sup>
                        </td>
                        <td>
                          <textarea
                            name="archive_reason"
                            id="FormControlTextarea1"
                            className="form-control"
                            value={props?.detailData?.archive_reason}
                            cols="30"
                            rows="10"
                            onChange={(e) => {
                              const data = { ...props.detailData };
                              data.archive_reason = e.target.value;
                              props.setDetailData(data);
                            }}
                          />
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td>Project</td>
                      <td>
                        <Select
                          value={props.detailData.proc_proj_id}
                          className="basic-single "
                          classNamePrefix="select-search"
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.value}
                          name={props.name}
                          options={props.projects}
                          placeholder="Select Project"
                          styles={customStyles}
                          onChange={(a) => {
                            const data = { ...props.detailData };
                            data.proc_proj_id = a;
                            props.setDetailData(data);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Due Date to Seller</td>
                      <td>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DateTimePicker
                            disableHighlightToday={true}
                            className="form-control"
                            value={props.dueDateSeller}
                            onChange={(e) => {
                              // const isoFormattedDate = e.toISOString();
                              props.setDueDateSeller(e);
                            }}
                            renderInput={(params) => <TextField {...params} error={false} />}
                            inputFormat="MM/dd/yyyy hh:mm a"
                            // disableMaskedInput={true}
                          />
                        </LocalizationProvider>
                        {props.dueDateSeller && (
                          <p style={{ marginTop: "3px" }}>
                            {" "}
                            <DateFormat date={props.dueDateSeller} />
                          </p>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Vessel Name</td>
                      <td>
                        <input
                          type="text"
                          class="form-control"
                          value={props.detailData.vessel_name}
                          onChange={(e) => {
                            const data = { ...props.detailData };
                            data.vessel_name = e.target.value;
                            props.setDetailData(data);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>File Number</td>
                      <td>
                        <input
                          type="text"
                          class="form-control"
                          value={props.detailData.file_number}
                          onChange={(e) => {
                            const data = { ...props.detailData };
                            data.file_number = e.target.value;
                            props.setDetailData(data);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Big Deal?</td>
                      <td>
                        <div class="form-check tick-checkbox">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            checked={props.detailData.bigdeal}
                            onChange={() => {
                              const data = { ...props.detailData };
                              data.bigdeal = !data.bigdeal;
                              props.setDetailData(data);
                            }}
                            id="bigDealtick1"
                          />
                          <label class="form-check-label fw-medium" for="bigDealtick1"></label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Exclusive Contract</td>
                      <td>
                        <div class="form-check tick-checkbox">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            checked={props.detailData.mega}
                            onChange={() => {
                              const data = { ...props.detailData };
                              data.mega = !data.mega;
                              props.setDetailData(data);
                            }}
                            id="topListingtick1"
                          />
                          <label class="form-check-label fw-medium" for="topListingtick1"></label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Ready to Close?</td>
                      <td>
                        <div class="form-check tick-checkbox">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            checked={props.detailData.ready_to_close}
                            onChange={() => {
                              const data = { ...props.detailData };
                              data.ready_to_close = !data.ready_to_close;
                              props.setDetailData(data);
                            }}
                            id="readyToClosetick1"
                          />
                          <label class="form-check-label fw-medium" for="readyToClosetick1"></label>
                        </div>
                      </td>
                    </tr>
                    {/* <tr>
                      <td>Location Security</td>
                      <td>
                        <Select
                          value={props.detailData.loc_sec}
                          className="basic-single "
                          classNamePrefix="select-search"
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.value}
                          options={[
                            { name: "Continent", value: 0 },
                            { name: "Region", value: 1 },
                            { name: "City, State/Country", value: 2 },
                            { name: "Exact", value: 3 },
                          ]}
                          name="locationSecurity"
                          placeholder="Select Location Security Type"
                          styles={customStyles}
                          onChange={(a) => {
                            const data = { ...props.detailData };
                            data.loc_sec = a;
                            props.setDetailData(data);
                          }}
                        />
                      </td>
                    </tr> */}
                  </tbody>
                </table>
              </div>
            </div>
            <div class="tr-column w-50 position-relative">
              <div class="tr-column-content w-100 h-100 d-flex flex-column">
                <table class="table table-borderless align-middle table-left-align">
                  <colgroup>
                    <col style={{ width: "30%" }} />
                    <col />
                    <col />
                  </colgroup>
                  <tbody>
                    <tr>
                      <td>Private?</td>
                      <td>
                        <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
                          <input
                            class="form-check-input cursor-pointer"
                            type="radio"
                            name="privateflexRadioDefault"
                            id="privateflexRadioDefault1"
                            checked={!props.detailData.private}
                            onChange={() => {
                              const data = { ...props.detailData };
                              data.private = false;
                              props.setDetailData(data);
                            }}
                          />
                          <label class="form-check-label" for="privateflexRadioDefault1">
                            No
                          </label>
                        </div>
                      </td>
                      <td colspan="2">
                        <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
                          <input
                            class="form-check-input cursor-pointer"
                            type="radio"
                            name="privateflexRadioDefault"
                            id="privateflexRadioDefault2"
                            checked={props.detailData.private}
                            onChange={() => {
                              const data = { ...props.detailData };
                              data.private = true;
                              props.setDetailData(data);
                            }}
                          />
                          <label class="form-check-label" for="privateflexRadioDefault2">
                            Yes
                          </label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Proxibid?</td>
                      <td>
                        <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
                          <input
                            class="form-check-input cursor-pointer "
                            type="radio"
                            name="proxibidflexRadioDefault"
                            id="proxibidflexRadioDefault1"
                            onChange={() => {
                              const data = { ...props.detailData };
                              data.proxibid = 0;
                              props.setDetailData(data);
                            }}
                            checked={props.detailData.proxibid === 0 ? true : false}
                          />
                          <label class="form-check-label" for="proxibidflexRadioDefault1">
                            No
                          </label>
                        </div>
                      </td>
                      <td>
                        <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
                          <input
                            class="form-check-input cursor-pointer"
                            type="radio"
                            name="proxibidflexRadioDefault"
                            id="proxibidflexRadioDefault2"
                            onChange={() => {
                              const data = { ...props.detailData };
                              data.proxibid = 1;
                              props.setDetailData(data);
                            }}
                            checked={props.detailData.proxibid === 1 ? true : false}
                          />
                          <label class="form-check-label" for="proxibidflexRadioDefault2">
                            Buy Now
                          </label>
                        </div>
                      </td>
                      <td>
                        <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
                          <input
                            class="form-check-input cursor-pointer"
                            type="radio"
                            name="proxibidflexRadioDefault"
                            id="proxibidflexRadioDefault3"
                            onChange={() => {
                              const data = { ...props.detailData };
                              data.proxibid = 2;
                              props.setDetailData(data);
                            }}
                            checked={props.detailData.proxibid === 2 ? true : false}
                          />
                          <label class="form-check-label" for="proxibidflexRadioDefault3">
                            Auction
                          </label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Proxibid Archive?</td>
                      <td colspan="3">
                        <div class="form-check tick-checkbox">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            checked={props.detailData.proxibid_archive}
                            onChange={() => {
                              const data = { ...props.detailData };
                              data.proxibid_archive = !data.proxibid_archive;
                              props.setDetailData(data);
                            }}
                            id="proxibidArchivetick1"
                          />
                          <label class="form-check-label fw-medium" for="proxibidArchivetick1"></label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Proxibid Remove From Report?</td>
                      <td colspan="3">
                        <div class="form-check tick-checkbox">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            id="proxibidRemovetick2"
                            onChange={() => {
                              const data = { ...props.detailData };
                              data.proxibid_hidereport = !data.proxibid_hidereport;
                              props.setDetailData(data);
                            }}
                            checked={props.detailData.proxibid_hidereport}
                          />
                          <label class="form-check-label fw-medium" for="proxibidRemovetick2"></label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Main Project Listing?</td>
                      <td colspan="3">
                        <div class="form-check tick-checkbox">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            checked={props.detailData.hotlist}
                            onChange={() => {
                              const data = { ...props.detailData };
                              data.hotlist = !props.detailData.hotlist;
                              props.setDetailData(data);
                            }}
                            id="projectListingtick1"
                          />
                          <label class="form-check-label fw-medium" for="projectListingtick1"></label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Feature?</td>
                      <td colspan="3">
                        <div class="form-check tick-checkbox">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            checked={props.detailData.featured}
                            onChange={() => {
                              const data = { ...props.detailData };
                              data.featured = !props.detailData.featured;
                              props.setDetailData(data);
                            }}
                            id="featuretick1"
                          />
                          <label class="form-check-label fw-medium" for="featuretick1"></label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Push Ready?</td>
                      <td colspan="3">
                        <div class="form-check tick-checkbox">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            checked={props.detailData.pushready}
                            onChange={() => {
                              const data = { ...props.detailData };
                              data.pushready = !props.detailData.pushready;
                              props.setDetailData(data);
                            }}
                            id="pushReadytick1"
                          />
                          <label class="form-check-label fw-medium" for="pushReadytick1"></label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Exclusive?</td>
                      <td colspan="3">
                        <div class="form-check tick-checkbox">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            checked={props.detailData.exclusive}
                            onChange={() => {
                              const data = { ...props.detailData };
                              data.exclusive = !props.detailData.exclusive;
                              props.setDetailData(data);
                            }}
                            id="exclusivetick1"
                          />
                          <label class="form-check-label fw-medium" for="exclusivetick1"></label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Require Bid To Seller?</td>
                      <td colspan="3">
                        <div class="form-check tick-checkbox">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            checked={props.detailData.b_to_s}
                            onChange={() => {
                              const data = { ...props.detailData };
                              data.b_to_s = !data.b_to_s;
                              props.setDetailData(data);
                            }}
                            id="requireBidToSellertick1"
                          />
                          <label class="form-check-label fw-medium" for="requireBidToSellertick1"></label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Hold?</td>
                      <td colspan="3">
                        <div className="d-flex justify-content-center align-items-center">
                          <div class="form-check tick-checkbox">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              checked={props.detailData.chk_hold}
                              onChange={() => {
                                const data = { ...props.detailData };
                                data.chk_hold = !data.chk_hold;
                                if (!data.chk_hold) {
                                  props.setHoldDate("");
                                }
                                props.setDetailData(data);
                              }}
                              id="holdtick1"
                            />
                            <label class="form-check-label fw-medium" for="holdtick1"></label>
                          </div>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DateTimePicker
                              disableHighlightToday={true}
                              className="form-control"
                              value={props.holdDate}
                              onChange={(e) => {
                                // const a = e && e.toISOString();
                                props.setHoldDate(e);
                              }}
                              renderInput={(params) => <TextField {...params} error={false} />}
                              inputFormat="MM/dd/yyyy hh:mm a"
                              // disableMaskedInput={true}
                              disabled={!props.detailData.chk_hold}
                            />
                          </LocalizationProvider>
                        </div>
                        <div className="d-flex align-items-center">
                          <div class="form-check tick-checkbox" style={{ visibility: "hidden" }}>
                            <input
                              class="form-check-input"
                              type="checkbox"
                              checked={props.detailData.chk_hold}
                              onChange={() => {
                                const data = { ...props.detailData };
                                data.chk_hold = !data.chk_hold;
                                props.setDetailData(data);
                              }}
                              id="holdtick1"
                            />
                            <label class="form-check-label fw-medium" for="holdtick1"></label>
                          </div>
                          {props.holdDate && (
                            <p style={{ marginTop: "3px" }}>
                              <DateFormat date={props?.holdDate} />
                            </p>
                          )}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Available to View</td>
                      <td colspan="3">
                        <div class="form-check tick-checkbox">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            checked={props.detailData.available_view}
                            onChange={() => {
                              const data = { ...props.detailData };
                              data.available_view = !data.available_view;
                              props.setDetailData(data);
                            }}
                            id="availableToViewtick1"
                          />
                          <label class="form-check-label fw-medium" for="availableToViewtick1"></label>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="full-width-textarea-row d-flex flex-wrap">
            <div class="fwtr-label-box">Listing Quick Summary</div>
            <div class="fwtr-input-box flex-grow-1">
              <textarea
                class="form-control w-100"
                id="FormControlTextarea1"
                value={props.detailData.motivation ? props.detailData.motivation : ""}
                onChange={(e) => {
                  const data = { ...props.detailData };
                  data.motivation = e.target.value;
                  props.setDetailData(data);
                }}
              ></textarea>
            </div>
          </div>
        </Modal.Body>
        <div class="custom-modal-dividor"></div>
        <div class="custom-modal-footer d-grid gap-2 d-md-flex align-items-center justify-content-md-end">
          <button class="btn btn-link me-md-2 global-cancel-button" type="button" onClick={() => props.onHide()}>
            Cancel
          </button>
          <NextButton
            label="Save"
            classData="btn btn-default"
            handleSubmit={props.handleSubmit}
            loading={props.loading}
          />
        </div>
      </div>
    </Modal>
  );
};

export default React.memo(MoreDetailsModal);
