import React from "react";
import { Modal } from "react-bootstrap";
import close from "../../include/images/close.svg";
import Select from "react-select";
import { customStyles } from "../../common/customStyles/reactSelectStyle";
import NextButton from "../../common/form/nextButton";

const StrategicTeamModal = (props) => {
  const val = localStorage.getItem("data");
  const session = JSON.parse(val);
  const fullAccessArr = [
    12430535, 12210968, 10011993, 10012798, 12011359, 12271715, 12191311, 12213057, 10853838, 10011994, 12213056,
    12213055, 12534635, 12220136, 12818905,
  ];
  const originAccessArr = [11352012, 12534635, 12818905];

  const superAdmin = fullAccessArr.includes(session.user_id);

  const customStyles1 = {
    indicatorSeparator: (styles) => ({ display: "none" }),
    option: (provided, state) => ({
      ...provided,
      "&:hover": {
        backgroundColor: "#47ad1d",
        color: "#fff",
      },
      display: "-webkit-box",
      WebkitLineClamp: 3,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      color: state.isSelected ? "#fff" : "black",
      backgroundColor: state.isSelected ? "#47ad1d" : provided.backgroundColor,
      // maxHeight: "84px",
    }),
    control: (base, state) => ({
      ...base,
      // height: 60,
      minHeight: 42,
      borderColor: state.isFocused ? "#47AD1D" : "#e4e4e4",
      boxShadow: state.isFocused ? "0 0 0 0.5px #47AD1D" : "0",
      border: state.isHovered ? "0" : "1px solid #e4e4e4",

      "&:hover": {
        borderColor: state.isFocused ? "#47AD1D" : "0",
        boxShadow: state.isFocused ? "0 0 0 0.5px #47AD1D" : "0",
      },
    }),
    multiValueRemove: (base, state) => ({
      ...base,
      "&:hover": {
        backgroundColor: "rgb(95, 50, 187, 10%)",
        color: "#6119c0",
      },
    }),
  };
  return (
    <Modal
      className="modal fade custom-modal"
      id="strategic"
      dialogClassName="modal-dialog modal-dialog-centered modal-xl"
      show={props.show}
      onHide={() => props.onHide()}
    >
      <div class="modal-content d-flex flex-column">
        <div class="custom-modal-header d-flex align-items-center justify-content-between">
          <h5 class="mb-0 text-capitalize">Strategies Team</h5>
          <button
            type="button"
            class="close-btn d-flex align-items-center justify-content-center"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => props.onHide()}
          >
            <img src={close} alt="" />
          </button>
        </div>
        <Modal.Body className="custom-modal-body flex-grow-1">
          {!session?.accessLevels?.accounting_payment ? (
            <div className="d-flex justify-content-start align-items-center">
              <span className="fw-medium text-danger">
                You don't have access to edit the internal people. Please contact Dena, Charlie or Ana to make changes.
              </span>
            </div>
          ) : (
            <>
              <div class="fieldset-group-row pt-0">
                <div class="table-responsive table-overflow-visible">
                  <table class="table table-borderless align-middle table-left-align mb-0">
                    <colgroup>
                      <col style={{ width: "20%" }} />
                      <col style={{ width: "44%" }} />
                      <col style={{ width: "9.5%" }} />
                      <col />
                    </colgroup>
                    <tbody>
                      <tr>
                        <td>Commission Status : </td>
                        <td>
                          <Select
                            value={props.detail.commission_status}
                            className="basic-single "
                            classNamePrefix="select-search"
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.value}
                            options={props?.reviewOptions}
                            name="reviewOptions"
                            placeholder=""
                            styles={customStyles}
                            onChange={(a) => {
                              const data = { ...props.detail };
                              data.commission_status = a;
                              props.setDetail(data);
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Created By</td>
                        <td>
                          <Select
                            value={props.detail.author_name}
                            className="basic-single "
                            classNamePrefix="select-search"
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.value}
                            options={[]}
                            name="methodOrigin"
                            isDisabled={true}
                            placeholder=""
                            styles={customStyles}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Method Origin</td>
                        <td>
                          <Select
                            value={props.detail.method_origin}
                            className="basic-single "
                            classNamePrefix="select-search"
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.value}
                            options={
                              props.methodOrigindata
                                ? [
                                    { name: "", value: "" },
                                    ...props.methodOrigindata.map((item) => ({
                                      name: item.origin,
                                      value: item.origin,
                                    })),
                                  ]
                                : []
                            }
                            name="methodOrigin"
                            placeholder="Select Method Origin"
                            styles={customStyles}
                            onChange={(a) => {
                              const data = { ...props.detail };
                              data.method_origin = a;
                              props.setDetail(data);
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Individual Origin</td>
                        <td>
                          <Select
                            value={props.detail.individual_origin_name}
                            className="basic-single "
                            classNamePrefix="select-search"
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.value}
                            options={
                              props.memberData
                                ? [
                                    { name: "Select Individual Origin", value: 0 },
                                    ...props.memberData.map((item) => ({
                                      name: item.name01,
                                      value: item.user_id,
                                    })),
                                  ]
                                : ""
                            }
                            name="methodOrigin"
                            placeholder="Select Individual Origin"
                            styles={customStyles}
                            onChange={(a) => {
                              const data = { ...props.detail };
                              data.individual_origin_name = a;
                              props.setDetail(data);
                            }}
                          />
                        </td>
                        <td>
                          <div class="form-check tick-checkbox p-0 mx-auto">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="IOtick"
                              checked={props.detail.db_pool_1}
                              onChange={() => {
                                const data = { ...props.detail };
                                data.db_pool_1 = !props.detail.db_pool_1;
                                props.setDetail(data);
                              }}
                            />
                            <label class="form-check-label fw-medium" for="IOtick"></label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Trader</td>
                        <td>
                          <Select
                            value={props.detail.bc_id_name}
                            className="basic-single "
                            classNamePrefix="select-search"
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.value}
                            options={
                              props.memberData
                                ? [
                                    { name: "Select Trader", value: 0 },
                                    ...props.memberData.map((item) => ({
                                      name: item.name01,
                                      value: item.member_id,
                                    })),
                                  ]
                                : ""
                            }
                            name="trader"
                            placeholder="Select Trader"
                            styles={customStyles}
                            onChange={(a) => {
                              const data = { ...props.detail };
                              data.bc_id_name = a;
                              props.setDetail(data);
                            }}
                          />
                        </td>
                        <td>
                          <div class="form-check tick-checkbox p-0 mx-auto">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              checked={props.detail.db_pool_2}
                              onChange={() => {
                                const data = { ...props.detail };
                                data.db_pool_2 = !props.detail.db_pool_2;
                                props.setDetail(data);
                              }}
                              id="tradertick"
                            />
                            <label class="form-check-label fw-medium" for="tradertick"></label>
                          </div>
                        </td>
                        <td>
                          <div class="d-inline-group-row d-flex align-items-center">
                            <span class="d-inline-block me-3">+</span>
                            <input
                              type="number"
                              step="0.1"
                              min="0"
                              class="form-control"
                              value={props.detail.buyer_commission}
                              onChange={(e) => {
                                const data = { ...props.detail };
                                data.buyer_commission = e.target.value;
                                props.setDetail(data);
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Business Development</td>
                        <td>
                          <Select
                            value={props.detail.sc_id_name}
                            className="basic-single "
                            classNamePrefix="select-search"
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.value}
                            options={
                              props.memberData
                                ? [
                                    {
                                      name: "Select Business Development",
                                      value: 0,
                                    },
                                    ...props.memberData.map((item) => ({
                                      name: item.name01,
                                      value: item.member_id,
                                    })),
                                  ]
                                : ""
                            }
                            name="businessDevelopment"
                            placeholder="Select Business Development"
                            styles={customStyles}
                            onChange={(a) => {
                              const data = { ...props.detail };
                              data.sc_id_name = a;
                              props.setDetail(data);
                            }}
                          />
                        </td>
                        <td>
                          <div class="form-check tick-checkbox p-0 mx-auto">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              checked={props.detail.db_pool_3}
                              onChange={() => {
                                const data = { ...props.detail };
                                data.db_pool_3 = !props.detail.db_pool_3;
                                props.setDetail(data);
                              }}
                              id="bussinessDevelopmenttick"
                            />
                            <label class="form-check-label fw-medium" for="bussinessDevelopmenttick"></label>
                          </div>
                        </td>
                        <td>
                          <div class="d-inline-group-row d-flex align-items-center">
                            <span class="d-inline-block me-3">+</span>
                            <input
                              type="number"
                              step="0.1"
                              min="0"
                              class="form-control"
                              value={props.detail.seller_commission}
                              onChange={(e) => {
                                const data = { ...props.detail };
                                data.seller_commission = e.target.value;
                                props.setDetail(data);
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Admin 1</td>
                        <td>
                          <Select
                            value={props.detail.dc_id_name}
                            className="basic-single "
                            classNamePrefix="select-search"
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.value}
                            options={
                              props.memberData
                                ? [
                                    { name: "Select Admin 1", value: 0 },
                                    ...props.memberData.map((item) => ({
                                      name: item.name01,
                                      value: item.member_id,
                                    })),
                                  ]
                                : ""
                            }
                            name="admin1"
                            placeholder="Select Admin 1"
                            styles={customStyles}
                            onChange={(a) => {
                              const data = { ...props.detail };
                              data.dc_id_name = a;
                              props.setDetail(data);
                            }}
                          />
                        </td>
                        <td>
                          <div class="form-check tick-checkbox p-0 mx-auto">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              checked={props.detail.db_pool_4}
                              onChange={() => {
                                const data = { ...props.detail };
                                data.db_pool_4 = !props.detail.db_pool_4;
                                props.setDetail(data);
                              }}
                              id="adOnetick"
                            />
                            <label class="form-check-label fw-medium" for="adOnetick"></label>
                          </div>
                        </td>
                        <td>
                          <div class="d-inline-group-row d-flex align-items-center">
                            <span class="d-inline-block me-3">+</span>
                            <input
                              type="number"
                              step="0.1"
                              min="0"
                              class="form-control"
                              value={props.detail.deal_commission}
                              onChange={(e) => {
                                const data = { ...props.detail };
                                data.deal_commission = e.target.value;
                                props.setDetail(data);
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Admin 2</td>
                        <td>
                          <Select
                            value={props.detail.dc_id_1_name}
                            className="basic-single "
                            classNamePrefix="select-search"
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.value}
                            options={
                              props.memberData
                                ? [
                                    { name: "Select Admin 2", value: 0 },
                                    ...props.memberData.map((item) => ({
                                      name: item.name01,
                                      value: item.member_id,
                                    })),
                                  ]
                                : ""
                            }
                            name="admin2"
                            placeholder="Select Admin 2"
                            styles={customStyles}
                            onChange={(a) => {
                              const data = { ...props.detail };
                              data.dc_id_1_name = a;
                              props.setDetail(data);
                            }}
                          />
                        </td>
                        <td>
                          <div class="form-check tick-checkbox p-0 mx-auto">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              checked={props.detail.db_pool_5}
                              onChange={() => {
                                const data = { ...props.detail };
                                data.db_pool_5 = !props.detail.db_pool_5;
                                props.setDetail(data);
                              }}
                              id="adTwotick"
                            />
                            <label class="form-check-label fw-medium" for="adTwotick"></label>
                          </div>
                        </td>
                        <td>
                          <div class="d-inline-group-row d-flex align-items-center">
                            <span class="d-inline-block me-3">+</span>
                            <input
                              type="number"
                              step="0.1"
                              min="0"
                              class="form-control"
                              value={props.detail.deal_commission_1}
                              onChange={(e) => {
                                const data = { ...props.detail };
                                data.deal_commission_1 = e.target.value;
                                props.setDetail(data);
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Mid Manager</td>
                        <td>
                          <Select
                            value={props.detail.dc_id_2_name}
                            className="basic-single "
                            classNamePrefix="select-search"
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.value}
                            options={
                              props.memberData
                                ? [
                                    { name: "Select Mid Manager", value: 0 },
                                    ...props.memberData.map((item) => ({
                                      name: item.name01,
                                      value: item.member_id,
                                    })),
                                  ]
                                : ""
                            }
                            name="midManager"
                            placeholder="Select Mid Manager"
                            styles={customStyles}
                            onChange={(a) => {
                              const data = { ...props.detail };
                              data.dc_id_2_name = a;
                              props.setDetail(data);
                            }}
                          />
                        </td>
                        <td>
                          <div class="form-check tick-checkbox p-0 mx-auto">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              checked={props.detail.db_pool_6}
                              onChange={() => {
                                const data = { ...props.detail };
                                data.db_pool_6 = !props.detail.db_pool_6;
                                props.setDetail(data);
                              }}
                              id="midManagertick"
                            />
                            <label class="form-check-label fw-medium" for="midManagertick"></label>
                          </div>
                        </td>
                        <td>
                          <div class="d-inline-group-row d-flex align-items-center">
                            <span class="d-inline-block me-3">+</span>
                            <input
                              type="number"
                              step="0.1"
                              min="0"
                              class="form-control"
                              value={props.detail.deal_commission_2}
                              onChange={(e) => {
                                const data = { ...props.detail };
                                data.deal_commission_2 = e.target.value;
                                props.setDetail(data);
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Optimizer</td>
                        <td>
                          <Select
                            value={props.detail.dc_id_3_name}
                            className="basic-single "
                            classNamePrefix="select-search"
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.value}
                            options={
                              props.optimizerData
                                ? [
                                    { name: "Select Optimizer", value: 0 },
                                    ...props.optimizerData.map((item) => ({
                                      name: item.name01,
                                      value: item.member_id,
                                    })),
                                  ]
                                : ""
                            }
                            name="optimizer"
                            placeholder="Select Optimizer"
                            styles={customStyles}
                            onChange={(a) => {
                              const data = { ...props.detail };
                              data.dc_id_3_name = a;
                              props.setDetail(data);
                            }}
                          />
                        </td>
                        <td>
                          <div class="form-check tick-checkbox p-0 mx-auto">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              checked={props.detail.db_pool_7}
                              onChange={() => {
                                const data = { ...props.detail };
                                data.db_pool_7 = !props.detail.db_pool_7;
                                props.setDetail(data);
                              }}
                              id="optimizertick"
                            />
                            <label class="form-check-label fw-medium" for="optimizertick"></label>
                          </div>
                        </td>
                        <td>
                          <div class="d-inline-group-row d-flex align-items-center">
                            <span class="d-inline-block me-3">+</span>
                            <input
                              type="number"
                              step="0.1"
                              min="0"
                              class="form-control"
                              value={props.detail.deal_commission_3}
                              onChange={(e) => {
                                const data = { ...props.detail };
                                data.deal_commission_3 = e.target.value;
                                props.setDetail(data);
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Trader Manager</td>
                        <td>
                          <Select
                            value={props.detail.mgr_name}
                            className="basic-single "
                            classNamePrefix="select-search"
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.value}
                            options={
                              props.memberData
                                ? [
                                    { name: "Select Trade Manager", value: 0 },
                                    ...props.memberData.map((item) => ({
                                      name: item.name01,
                                      value: item.user_id,
                                    })),
                                  ]
                                : ""
                            }
                            name="tradeManager"
                            placeholder="Select Trade Manager"
                            styles={customStyles}
                            onChange={(a) => {
                              const data = { ...props.detail };
                              data.mgr_name = a;
                              props.setDetail(data);
                            }}
                          />
                        </td>
                        <td>
                          <div class="form-check tick-checkbox p-0 mx-auto">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              checked={props.detail.db_pool_8}
                              onChange={() => {
                                const data = { ...props.detail };
                                data.db_pool_8 = !props.detail.db_pool_8;
                                props.setDetail(data);
                              }}
                              id="traderManagertick"
                            />
                            <label class="form-check-label fw-medium" for="traderManagertick"></label>
                          </div>
                        </td>
                        <td>
                          <div class="d-inline-group-row d-flex align-items-center">
                            <span class="d-inline-block me-3">+</span>
                            <input
                              type="number"
                              step="0.1"
                              min="0"
                              class="form-control"
                              value={props.detail.mgr_commission}
                              onChange={(e) => {
                                const data = { ...props.detail };
                                data.mgr_commission = e.target.value;
                                props.setDetail(data);
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>BD Manager</td>
                        <td>
                          <Select
                            value={props.detail.admin_name}
                            className="basic-single "
                            classNamePrefix="select-search"
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.value}
                            options={
                              props.memberData
                                ? [
                                    { name: "Select BD Manager", value: 0 },
                                    ...props.memberData.map((item) => ({
                                      name: item.name01,
                                      value: item.user_id,
                                    })),
                                  ]
                                : ""
                            }
                            name="bdManager"
                            placeholder="Select BD Manager"
                            styles={customStyles}
                            onChange={(a) => {
                              const data = { ...props.detail };
                              data.admin_name = a;
                              props.setDetail(data);
                            }}
                          />
                        </td>
                        <td>
                          <div class="form-check tick-checkbox p-0 mx-auto">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              checked={props.detail.db_pool_9}
                              onChange={() => {
                                const data = { ...props.detail };
                                data.db_pool_9 = !props.detail.db_pool_9;
                                props.setDetail(data);
                              }}
                              id="bdManagertick"
                            />
                            <label class="form-check-label fw-medium" for="bdManagertick"></label>
                          </div>
                        </td>
                        <td>
                          <div class="d-inline-group-row d-flex align-items-center">
                            <span class="d-inline-block me-3">+</span>
                            <input
                              type="number"
                              step="0.1"
                              min="0"
                              class="form-control"
                              value={props.detail.admin_commission}
                              onChange={(e) => {
                                const data = { ...props.detail };
                                data.admin_commission = e.target.value;
                                props.setDetail(data);
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="fieldset-group-row">
                <div class="table-responsive table-overflow-visible">
                  <table class="table table-borderless align-middle table-left-align mb-0">
                    <colgroup>
                      <col style={{ width: "20%" }} />
                      <col style={{ width: "44%" }} />
                      <col style={{ width: "9.5%" }} />
                      <col />
                    </colgroup>
                    <tbody>
                      <tr>
                        <td>Outside Agent</td>
                        <td>
                          <Select
                            value={props.detail.outside_agent_name}
                            className="basic-single "
                            classNamePrefix="select-search"
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.value}
                            options={
                              props.outsideAgentData
                                ? [
                                    { name: "Select Outside Agent", value: 0 },
                                    ...props.outsideAgentData.map((item) => ({
                                      name: item.name01,
                                      value: item.user_id,
                                    })),
                                  ]
                                : ""
                            }
                            name="outsideAgent"
                            placeholder="Select Outside Agent"
                            styles={customStyles}
                            onChange={(a) => {
                              const data = { ...props.detail };
                              data.outside_agent_name = a;
                              props.setDetail(data);
                            }}
                          />
                        </td>
                        <td>
                          <div class="form-check tick-checkbox p-0 mx-auto">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              checked={props.detail.db_pool_10}
                              onChange={() => {
                                const data = { ...props.detail };
                                data.db_pool_10 = !props.detail.db_pool_10;
                                props.setDetail(data);
                              }}
                              id="outsideAgenttick"
                            />
                            <label class="form-check-label fw-medium" for="outsideAgenttick"></label>
                          </div>
                        </td>
                        <td>
                          <div class="d-inline-group-row d-flex align-items-center">
                            <span class="d-inline-block me-3">+</span>
                            <input
                              type="number"
                              step="0.1"
                              min="0"
                              class="form-control"
                              value={props.detail.outside_agent_commission}
                              onChange={(e) => {
                                const data = { ...props.detail };
                                data.outside_agent_commission = e.target.value;
                                props.setDetail(data);
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                      {/* ==========Removed inspector as per request================== */}
                      {/* <tr>
                        <td>Inspector</td>
                        <td>
                          <Select
                            value={props.detail.inspector_name}
                            className="basic-single "
                            classNamePrefix="select-search"
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.value}
                            options={
                              props.inspectorData
                                ? [
                                    { name: "Select Inspector", value: 0 },
                                    ...props.inspectorData.map((item) => ({
                                      name: item.name01,
                                      value: item.user_id,
                                    })),
                                  ]
                                : ""
                            }
                            name="inspector"
                            placeholder="Select Inspector"
                            styles={customStyles}
                            onChange={(a) => {
                              const data = { ...props.detail };
                              data.inspector_name = a;
                              props.setDetail(data);
                            }}
                          />
                        </td>
                        <td style={{ visibility: "hidden" }}>
                          <div class="form-check tick-checkbox p-0 mx-auto">
                            <input class="form-check-input" type="checkbox" value="" id="bdManagertick" />
                            <label class="form-check-label fw-medium" for="bdManagertick"></label>
                          </div>
                        </td>
                        <td>
                          <div class="d-inline-group-row d-flex align-items-center">
                            <span class="d-inline-block me-3">+</span>
                            <input
                              type="number"
                              step="0.1"
                              min="0"
                              class="form-control"
                              value={props.detail.inspector_commission}
                              onChange={(e) => {
                                const data = { ...props.detail };
                                data.inspector_commission = e.target.value;
                                props.setDetail(data);
                              }}
                            />
                          </div>
                        </td>
                      </tr> */}
                      <tr>
                        <td>Recommend View</td>
                        <td>
                          <Select
                            value={props.detail.auction_recommend}
                            className="basic-single "
                            isMulti
                            classNamePrefix="select-search"
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.value}
                            options={
                              props.recommendedViewData &&
                              props.recommendedViewData.map((item) => ({
                                name: item.name01,
                                value: item.user_id,
                              }))
                            }
                            name="bdManager"
                            placeholder="Select Recommend View"
                            styles={customStyles1}
                            onChange={(a) => {
                              const data = { ...props.detail };
                              data.auction_recommend = a;
                              props.setDetail(data);
                            }}
                          />
                        </td>
                        <td style={{ visibility: "hidden" }}>
                          <div class="form-check tick-checkbox p-0 mx-auto">
                            <input class="form-check-input" type="checkbox" value="" id="bdManagertick" />
                            <label class="form-check-label fw-medium" for="bdManagertick"></label>
                          </div>
                        </td>
                        <td style={{ visibility: "hidden" }}>
                          <div class="d-inline-group-row d-flex align-items-center">
                            <span class="d-inline-block me-3">+</span>
                            <input type="text" class="form-control" value="0.0" />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="fieldset-group-row">
                <div class="table-responsive">
                  <table class="table table-borderless align-middle table-left-align mb-0" id="page">
                    <colgroup>
                      <col style={{ width: "20%" }} />
                      <col style={{ width: "44%" }} />
                      <col />
                    </colgroup>
                    <tbody>
                      <tr>
                        <td>Ext. Referral</td>
                        <td>
                          <input
                            type="text"
                            class="form-control"
                            disabled={true}
                            value={props.detail.dc_id_4_name}
                            onChange={(e) => {
                              const data = { ...props.detail };
                              data.dc_id_4_name = e.target.value;
                              props.setDetail(data);
                            }}
                          />
                        </td>
                        <td>
                          <div class="d-inline-group-row d-flex align-items-center">
                            <span class="d-inline-block me-3">+</span>
                            <input
                              type="number"
                              step="0.1"
                              min="0"
                              class="form-control"
                              value={props.detail.deal_commission_4}
                              onChange={(e) => {
                                const data = { ...props.detail };
                                data.deal_commission_4 = e.target.value;
                                props.setDetail(data);
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}
        </Modal.Body>
        <div class="custom-modal-dividor"></div>
        <div class="custom-modal-footer d-grid gap-2 d-md-flex align-items-center justify-content-md-end">
          <button
            class="btn btn-link me-md-2 global-cancel-button"
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => props.onHide()}
          >
            {!superAdmin && !originAccessArr.includes(session.user_id) ? "Close" : "Cancel"}
          </button>
          {!(!superAdmin && !originAccessArr.includes(session.user_id)) && (
            <NextButton
              classData="btn btn-default"
              handleSubmit={() => props.handleSubmit()}
              label={"Save"}
              loading={props.loading}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default StrategicTeamModal;
