import { createSelector, createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";

const slice = createSlice({
  name: "invoices",
  initialState: {
    loading: false,
    feedback: {},
    invoices: {},
    invoiceData: {},
    invoiceDetails: {},
    userAccess: [],
    addInvoice: {},
  },
  reducers: {
    invoicesRequested: (state, action) => {
      state.loading = true;
    },
    invoicesRecieved: (state, action) => {
      state.invoices = action.payload;
      state.loading = false;
    },
    invoicesFailed: (state, action) => {
      state.loading = false;
    },
    invoiceDataRequested: (state, action) => {
      state.loading = true;
    },
    invoiceDataRecieved: (state, action) => {
      state.invoiceData = action.payload;
      state.loading = false;
    },
    invoiceDataFailed: (state, action) => {
      state.loading = false;
    },
    invoiceDetailsRequested: (state, action) => {
      state.loading = true;
    },
    invoiceDetailsRecieved: (state, action) => {
      state.invoiceDetails = action.payload;
      state.loading = false;
    },
    invoiceDetailsFailed: (state, action) => {
      state.loading = false;
    },
    feedbackRequested: (state, action) => {
      state.loading = true;
    },
    feedbackRecieved: (state, action) => {
      state.feedback = action.payload;
      state.loading = false;
    },
    feedbackFailed: (state, action) => {
      state.loading = false;
    },
    userAccessRequested: (state, action) => {
      state.loading = true;
    },
    userAccessRecieved: (state, action) => {
      state.userAccess = action.payload;
      state.loading = false;
    },
    userAccessFailed: (state, action) => {
      state.loading = false;
    },
    addInvoiceRequested: (state, action) => {
      state.loading = true;
    },
    addInvoiceRecieved: (state, action) => {
      state.addInvoice = action.payload;
      state.loading = false;
    },
    addInvoiceFailed: (state, action) => {
      state.loading = false;
    },

  },
});

export const {
  invoicesRequested,
  invoicesRecieved,
  invoicesFailed,
  invoiceDataRequested,
  invoiceDataRecieved,
  invoiceDataFailed,
  invoiceDetailsRequested,
  invoiceDetailsRecieved,
  invoiceDetailsFailed,
  feedbackRequested,
  feedbackRecieved,
  feedbackFailed,
  userAccessRequested,
  userAccessRecieved,
  userAccessFailed,
  addInvoiceRequested,
  addInvoiceRecieved,
  addInvoiceFailed,
} = slice.actions;
export default slice.reducer;

//Action Creator
const baseUrl = "auctionAccounting/invoice";

//Get Invoice List
export const getInvoiceList = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/list/${params}`,
      method: "get",
      callback,
      onStart: invoicesRequested.type,
      onSuccess: invoicesRecieved.type,
      onError: invoicesFailed.type,
    })
  );
};

export const getInvoiceData = (params, bidId, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/${params}?b_id=${bidId}`,
      method: "get",
      callback,
      onStart: invoiceDataRequested.type,
      onSuccess: invoiceDataRecieved.type,
      onError: invoiceDataFailed.type,
    })
  );
};

export const getInvoiceDetails = (auctionId, id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/view/${auctionId}?invoice_id=${id}`,
      method: "get",
      callback,
      onStart: invoiceDetailsRequested.type,
      onSuccess: invoiceDetailsRecieved.type,
      onError: invoiceDetailsFailed.type,
    })
  );
};

export const addInvoiceData = (params, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/${params}`,
      method: "post",
      data,
      callback,
    })
  );
};

//Update Invoicee Status
export const updateInvoiceStatus = (params, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/updateInvoiceStatus/${params}`,
      method: "put",
      data,
      callback,
    })
  );
};

//Update Deposit Paid
export const updateDepositPaid = (params, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `auctionAccounting/accounting/updateDepositPaid/${params}`,
      method: 'put',
      data,
      callback,
    })
  )
}

//Invoice collect
export const updateCollect = (params, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/collect/${params}`,
      method: "put",
      data,
      callback,
    })
  );
};

//Get Feedback
export const getFeedback =
  (params, invoiceId, action, callback) => (dispatch) => {
    return dispatch(
      apiCallBegan({
        url: `${baseUrl}/feedback/${params}?invoice_id=${invoiceId}&action=${action}`,
        method: "get",
        callback,
        onStart: feedbackRequested.type,
        onSuccess: feedbackRecieved.type,
        onError: feedbackFailed.type,
      })
    );
  };

//Get User Access
export const getUserAccess = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/userAccess`,
      method: "get",
      callback,
      onStart: userAccessRequested.type,
      onSuccess: userAccessRecieved.type,
      onError: userAccessFailed.type,
    })
  )
}

//Inspection Waiver
export const inspectionWaiver = (params, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/inspectionWaiver/${params}`,
      method: "post",
      data,
      callback
    })
  )
};

//Invoice Add Note
export const invoiceAddNote = (params, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/note/${params}`,
      method: "post",
      data,
      callback,
      onStart: addInvoiceRequested.type,
      onSuccess: addInvoiceRecieved.type,
      onError: addInvoiceFailed.type,
    })
  )
}
export const getInvoice = createSelector(
  (state) => state.entities.invoices,
  (invoices) => invoices
);
