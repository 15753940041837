import React, { useEffect, useRef, useState } from "react";
import Header from "../../layouts/header";
import { connect } from "react-redux";
import LoadingBar from "react-top-loading-bar";
import ContractsTable from "../../components/legal/contracts/contractsTable";
import {
  addContracts,
  deleteContract,
  deleteFile,
  editContracts,
  getBusinessUnits,
  getContractListing,
  getContracts,
  getEnterprises,
  uploadFile,
} from "../../store/contract";
import ViewMoreDetails from "../../components/legal/contracts/modal/viewMoreModal";
import _ from "lodash";
import { Amplify, Storage } from "aws-amplify";
import { Auth } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import AlertSuccess from "../../common/alerts/alertSuccess";
import AlertError from "../../common/alerts/alertError";
import { validateErrorMessage } from "../../common/validation/validation";
import Joi from "joi";
import { getProfile } from "../../utils.js/localStorageServices";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import DeleteModal from "../../layouts/modals/deleteModal";
import moment from "moment";
import AddDocuments from "../../components/legal/contracts/modal/addDocument";
import ViewListings from "../../components/legal/contracts/modal/viewListings";

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    region: "us-east-1",
  },
  Storage: {
    bucket: process.env.REACT_APP_S3_UPLOAD_BUCKET,
    region: "us-east-1",
  },
});

const Contracts = (props) => {
  const ref = useRef(null);
  const history = useHistory();

  const [addContractModal, setAddContractModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState("");
  const [order, setOrder] = useState("");
  const [showMoreDetails, setShowMoreDetails] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [contractValues, setContractValues] = useState({
    enterpriseId: "",
    buId: "",
    contractScope: "",
    contractType: "",
    contractStatus: "",
    startDate: "",
    expirationDate: "",
    automaticRenewal: "",
    terminationNotice: "",
    exclusivity: "",
    commissionRate: "",
    buyerPremiumOrSellerFee: "",
    salvexEntity: "",
    confidentiality: "",
    nonCircumvention: "",
    governingLaws: "",
    arbitration: "",
    indemnification: "",
    contractLanguage: "",
    specialTerms: "",
    registrationAsVendor: "",
    registrationUrl: "",
    registrationUsername: "",
    registrationPassword: "",
    registrationExpirationDate: "",
    registrationStatus: "",
  });

  const [errors, setErrors] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);

  //Filter
  const [showFilter, setShowFilter] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState({
    enterprise: [],
    contractScope: "",
    contractType: "",
    contractStatus: "",
    startDate: { startFrom: "", startTo: "" },
    expirationDate: { expirationFrom: "", expirationTo: "" },
    automaticRenewal: "",
    exclusivity: "",
    buyerPremiumOrSellerFee: "",
    salvexEntity: "",
    confidentiality: "",
    nonCircumvention: "",
    registrationAsVendor: "",
    businessUnit: "",
  });

  const [showDocModal, setShowDocModal] = useState(false);
  const [showListingsModal, setShowListingsModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState({
    doc: null,
    title: "",
  });
  const [showdeleteDoc, setShowDeleteDoc] = useState(false);
  const [deleteDocItem, setDeleteDocItem] = useState(null);
  const [errorsDoc, setErrorsDoc] = useState({});

  const toggleDeleteDoc = () => {
    setShowDeleteDoc(!showdeleteDoc);
  };

  const toggleShowListing = () => {
    setShowListingsModal((prev) => !prev);
  };

  const schemaDoc = Joi.object({
    doc: Joi.object()
      .label("Contract Document")
      .required()
      .error((errors) => validateErrorMessage(errors)),
    title: Joi.string()
      .label("Title")
      .required()
      .error((errors) => validateErrorMessage(errors)),
  });

  const validateDoc = (type = false) => {
    const error = schemaDoc.validate(_.pickBy(selectedDocument, _.identity), {
      abortEarly: type,
    });
    if (error.error && error.error.details) {
      const errors = {};
      error.error.details.forEach((element) => {
        errors[element.context.key] = element.message;
      });
      setErrorsDoc(errors);
      return false;
    }
    setErrorsDoc("");
    return true;
  };

  const toggleDocModal = () => {
    setShowDocModal(!showDocModal);
  };

  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  const toggleShowMoreDetails = () => {
    setShowMoreDetails(!showMoreDetails);
  };

  const toggleAddContractModal = () => {
    setAddContractModal(!addContractModal);
  };

  const member = getProfile();

  const isLegalAccessible =
    member && member?.accessLevels && member?.accessLevels?.legal_contract_admin_access ? true : false;

  useEffect(() => {
    if (!isLegalAccessible) {
      history.push("/404");
    }
  }, [member]);

  useEffect(() => {
    ref?.current?.continuousStart();
    setLoading(true);
    const payload = {
      page: page,
      sort: sort,
      order: order,
      enterpriseId:
        selectedFilter?.enterprise && selectedFilter.enterprise.id != "-1" ? selectedFilter.enterprise?.id : "",
      buId: selectedFilter?.buId && selectedFilter.buId.id != "-1" ? selectedFilter.buId?.id : "",
      contractScopes:
        selectedFilter?.contractScope && selectedFilter.contractScope.value != "-1"
          ? selectedFilter.contractScope?.value
          : "",
      contractTypes:
        selectedFilter?.contractType && selectedFilter.contractType.value != "-1"
          ? selectedFilter.contractType?.value
          : "",
      contractStatuses:
        selectedFilter?.contractStatus && selectedFilter.contractStatus.value != "-1"
          ? selectedFilter.contractStatus?.value
          : "",
      salvexEntities:
        selectedFilter?.salvexEntity && selectedFilter.salvexEntity.value != "-1"
          ? selectedFilter.salvexEntity?.value
          : "",
      startDateStart: selectedFilter?.startDate?.startFrom ? selectedFilter?.startDate?.startFrom : "",
      startDateEnd: selectedFilter?.startDate?.startTo ? selectedFilter?.startDate?.startTo : "",
      expirationDateStart: selectedFilter?.expirationDate?.expirationFrom
        ? selectedFilter?.expirationDate?.expirationFrom
        : "",
      expirationDateEnd: selectedFilter?.expirationDate?.expirationTo
        ? selectedFilter?.expirationDate?.expirationTo
        : "",
      automaticRenewal: selectedFilter?.automaticRenewal
        ? selectedFilter.automaticRenewal == "true"
          ? true
          : false
        : "",
      exclusivity: selectedFilter?.exclusivity ? (selectedFilter.exclusivity == "true" ? true : false) : "",
      buyerPremiumOrSellerFee: selectedFilter?.buyerPremiumOrSellerFee
        ? selectedFilter.buyerPremiumOrSellerFee == "true"
          ? true
          : false
        : "",

      confidentiality: selectedFilter?.confidentiality ? (selectedFilter.confidentiality == "true" ? true : false) : "",
      nonCircumvention: selectedFilter?.nonCircumvention
        ? selectedFilter.nonCircumvention == "true"
          ? true
          : false
        : "",
      registrationAsVendor: selectedFilter?.registrationAsVendor
        ? selectedFilter.registrationAsVendor == "true"
          ? true
          : false
        : "",
    };
    const param = _.pickBy(payload, function (value, key) {
      return !(value === "" || value == {} || value === undefined || value === null);
    });
    props.getContracts(param, (res) => {
      if (res && res.status === 200) {
        if (showFilter) {
          toggleFilter();
        }
        setLoading(false);
        ref?.current?.complete();
      } else {
        setLoading(false);
        toast(<AlertError message={res?.data?.message ? res?.data?.message : "Something went wrong"} />);
        ref?.current?.complete();
      }
    });
  }, [page]);
  useEffect(() => {
    ref?.current?.continuousStart();
    setLoading(true);
    const payload = {
      page: 1,
      sort: sort,
      order: order,
      enterpriseId:
        selectedFilter?.enterprise && selectedFilter.enterprise.id != "-1" ? selectedFilter.enterprise?.id : "",
      buId: selectedFilter?.buId && selectedFilter.buId.id != "-1" ? selectedFilter.buId?.id : "",
      contractScopes:
        selectedFilter?.contractScope && selectedFilter.contractScope.value != "-1"
          ? selectedFilter.contractScope?.value
          : "",
      contractTypes:
        selectedFilter?.contractType && selectedFilter.contractType.value != "-1"
          ? selectedFilter.contractType?.value
          : "",
      contractStatuses:
        selectedFilter?.contractStatus && selectedFilter.contractStatus.value != "-1"
          ? selectedFilter.contractStatus?.value
          : "",
      salvexEntities:
        selectedFilter?.salvexEntity && selectedFilter.salvexEntity.value != "-1"
          ? selectedFilter.salvexEntity?.value
          : "",
      startDateStart: selectedFilter?.startDate?.startFrom ? selectedFilter?.startDate?.startFrom : "",
      startDateEnd: selectedFilter?.startDate?.startTo ? selectedFilter?.startDate?.startTo : "",
      expirationDateStart: selectedFilter?.expirationDate?.expirationFrom
        ? selectedFilter?.expirationDate?.expirationFrom
        : "",
      expirationDateEnd: selectedFilter?.expirationDate?.expirationTo
        ? selectedFilter?.expirationDate?.expirationTo
        : "",
      automaticRenewal: selectedFilter?.automaticRenewal
        ? selectedFilter.automaticRenewal == "true"
          ? true
          : false
        : "",
      exclusivity: selectedFilter?.exclusivity ? (selectedFilter.exclusivity == "true" ? true : false) : "",
      buyerPremiumOrSellerFee: selectedFilter?.buyerPremiumOrSellerFee
        ? selectedFilter.buyerPremiumOrSellerFee == "true"
          ? true
          : false
        : "",

      confidentiality: selectedFilter?.confidentiality ? (selectedFilter.confidentiality == "true" ? true : false) : "",
      nonCircumvention: selectedFilter?.nonCircumvention
        ? selectedFilter.nonCircumvention == "true"
          ? true
          : false
        : "",
      registrationAsVendor: selectedFilter?.registrationAsVendor
        ? selectedFilter.registrationAsVendor == "true"
          ? true
          : false
        : "",
    };
    const param = _.pickBy(payload, function (value, key) {
      return !(value === "" || value == {} || value === undefined || value === null);
    });
    props.getContracts(param, (res) => {
      if (res && res.status === 200) {
        setPage(1);
        if (showFilter) {
          toggleFilter();
        }
        setLoading(false);
        ref?.current?.complete();
      } else {
        setLoading(false);
        toast(<AlertError message={res?.data?.message ? res?.data?.message : "Something went wrong"} />);
        ref?.current?.complete();
      }
    });
  }, [sort, order]);

  useEffect(() => {
    if (!showMoreDetails) {
      setSelectedItem(null);
    }
  }, [showMoreDetails]);

  const data = props?.getContractListings?.contract || {};

  const contractScopeOption = [
    { name: "Listing", value: "listing" },
    { name: "Business Unit", value: "businessUnit" },
    { name: "Enterprise", value: "enterprise" },
  ];

  const contractTypeOptions = [
    { name: "NDA", value: "NDA" },
    { name: "Broker Agreement", value: "brokerAgreement" },
    { name: "Listing Agreement", value: "listingAgreement" },
    {
      name: "Master Service Agreement",
      value: "masterServiceAgreement",
    },
  ];

  const contractStatusOptions = [
    { name: "Drafted", value: "drafted" },
    { name: "Negotiating", value: "negotiating" },
    { name: "Executed", value: "executed" },
  ];

  const entityOptions = [
    {
      name: "Salvex Energy, Inc",
      value: "salvexEnergyInc",
    },
    {
      name: "Salvex Commodities, Inc.",
      value: "salvexCommoditiesInc",
    },
    {
      name: "Salvex Metals, Inc.",
      value: "salvexMetalsInc",
    },
    { name: "Salvex Food, Inc.", value: "salvexFoodInc" },
  ];

  const registrationStatusOptions = [
    { name: "Invitation Requested", value: "invitationRequested" },
    { name: "Pending", value: "pending" },
    { name: "Completed", value: "completed" },
  ];

  const schema = Joi.object({
    contractScope: Joi.object()
      .label("Contract Scope")
      .required()
      .error((errors) => validateErrorMessage(errors)),
    salvexEntity: Joi.object()
      .label("Salvex Entity")
      .required()
      .error((errors) => validateErrorMessage(errors)),

    contractType: Joi.object()
      .label("Contract Type")
      .required()
      .error((errors) => validateErrorMessage(errors)),
    contractStatus: Joi.object()
      .label("Contract Status")
      .required()
      .error((errors) => validateErrorMessage(errors)),
    enterpriseId: Joi.object()
      .label("Enterprise")
      .required()
      .error((errors) => validateErrorMessage(errors)),
    buId: Joi.object()
      .label("Business Unit")
      .allow("")
      .error((errors) => validateErrorMessage(errors)),
    terminationNotice: Joi.string()
      .label("Termination Notice")
      .trim()
      .required()
      .error((errors) => validateErrorMessage(errors)),
    automaticRenewal: Joi.string()
      .label("Automatic Renewal")
      .required()
      .error((errors) => validateErrorMessage(errors)),
    startDate: Joi.number()
      .label("Start Date")
      .required()
      .error((errors) => validateErrorMessage(errors)),
    expirationDate: Joi.number()
      .label("Expiration Date")
      .required()
      .error((errors) => validateErrorMessage(errors)),
    registrationExpirationDate: Joi.number()
      .label("Registration Expiration Date")
      .allow("")
      .error((errors) => validateErrorMessage(errors)),
    terminationNotice: Joi.string()
      .label("Termination Notice")
      .trim()
      .required()
      .error((errors) => validateErrorMessage(errors)),
    commissionRate: Joi.string()
      .label("Commission Rate")
      .trim()
      .required()
      .error((errors) => validateErrorMessage(errors)),
    commissionRate: Joi.string()
      .label("Commission Rate")
      .trim()
      .required()
      .error((errors) => validateErrorMessage(errors)),
    specialTerms: Joi.string()
      .label("Special Terms")
      .trim()
      .allow("")
      .error((errors) => validateErrorMessage(errors)),
    contractLanguage: Joi.string()
      .label("Contract Language Rate")
      .trim()
      .allow("")
      .error((errors) => validateErrorMessage(errors)),
    indemnification: Joi.string()
      .label("Indemnification")
      .trim()
      .allow("")
      .error((errors) => validateErrorMessage(errors)),
    arbitration: Joi.string()
      .label("Arbitration")
      .trim()
      .allow("")
      .error((errors) => validateErrorMessage(errors)),
    governingLaws: Joi.string()
      .label("Governing Laws")
      .trim()
      .allow("")
      .error((errors) => validateErrorMessage(errors)),
    confidentiality: Joi.string()
      .label("Confedentiality")
      .required()
      .error((errors) => validateErrorMessage(errors)),
    nonCircumvention: Joi.string()
      .label("Non Circumvention")
      .required()
      .error((errors) => validateErrorMessage(errors)),
    exclusivity: Joi.string()
      .label("Exclusivity")
      .required()
      .error((errors) => validateErrorMessage(errors)),
    buyerPremiumOrSellerFee: Joi.string()
      .label("Buyer Premium / Seller Fee")
      .required()
      .error((errors) => validateErrorMessage(errors)),
    registrationAsVendor: Joi.string()
      .label("Registration As Vendor")
      .required()
      .error((errors) => validateErrorMessage(errors)),
    registrationUrl: Joi.string()
      .label("Registration URL")
      .trim()
      .when("registrationAsVendor", {
        is: "true",
        then: Joi.required(),
        otherwise: Joi.optional(),
      })
      .error((errors) => validateErrorMessage(errors)),
    registrationUsername: Joi.string()
      .label("Registration User Name")
      .trim()
      .when("registrationAsVendor", {
        is: "true",
        then: Joi.required(),
        otherwise: Joi.optional(),
      })
      .error((errors) => validateErrorMessage(errors)),
    registrationPassword: Joi.string()
      .label("Registration Password")
      .trim()
      .when("registrationAsVendor", {
        is: "true",
        then: Joi.required(),
        otherwise: Joi.optional(),
      })
      .error((errors) => validateErrorMessage(errors)),
    registrationStatus: Joi.object()
      .label("Registration Status")
      .when("registrationAsVendor", {
        is: "true",
        then: Joi.required(),
        otherwise: Joi.optional(),
      })
      .error((errors) => validateErrorMessage(errors)),
  });

  const validate = (type = false) => {
    const error = schema.validate(_.pickBy(contractValues, _.identity), {
      abortEarly: type,
    });
    if (error.error && error.error.details) {
      const errors = {};
      error.error.details.forEach((element) => {
        errors[element.context.key] = element.message;
      });
      setErrors(errors);
      return false;
    }
    setErrors("");
    return true;
  };

  const handleFilter = (isFilterEmpty = false) => {
    setLoading(true);
    ref?.current?.continuousStart();
    const payload = {
      page: 1,
      sort: sort,
      order: order,
      enterpriseId:
        selectedFilter?.enterprise && selectedFilter.enterprise.id != "-1" ? selectedFilter.enterprise?.id : "",
      buId:
        selectedFilter?.businessUnit && selectedFilter.businessUnit.id != "-1" ? selectedFilter.businessUnit?.id : "",
      contractScopes:
        selectedFilter?.contractScope && selectedFilter.contractScope.value != "-1"
          ? selectedFilter.contractScope?.value
          : "",
      contractTypes:
        selectedFilter?.contractType && selectedFilter.contractType.value != "-1"
          ? selectedFilter.contractType?.value
          : "",
      contractStatuses:
        selectedFilter?.contractStatus && selectedFilter.contractStatus.value != "-1"
          ? selectedFilter.contractStatus?.value
          : "",
      salvexEntities:
        selectedFilter?.salvexEntity && selectedFilter.salvexEntity.value != "-1"
          ? selectedFilter.salvexEntity?.value
          : "",
      startDateStart: selectedFilter?.startDate?.startFrom ? selectedFilter?.startDate?.startFrom : "",
      startDateEnd: selectedFilter?.startDate?.startTo ? selectedFilter?.startDate?.startTo : "",
      expirationDateStart: selectedFilter?.expirationDate?.expirationFrom
        ? selectedFilter?.expirationDate?.expirationFrom
        : "",
      expirationDateEnd: selectedFilter?.expirationDate?.expirationTo
        ? selectedFilter?.expirationDate?.expirationTo
        : "",
      automaticRenewal: selectedFilter?.automaticRenewal
        ? selectedFilter.automaticRenewal == "true"
          ? true
          : false
        : "",
      exclusivity: selectedFilter?.exclusivity ? (selectedFilter.exclusivity == "true" ? true : false) : "",
      buyerPremiumOrSellerFee: selectedFilter?.buyerPremiumOrSellerFee
        ? selectedFilter.buyerPremiumOrSellerFee == "true"
          ? true
          : false
        : "",

      confidentiality: selectedFilter?.confidentiality ? (selectedFilter.confidentiality == "true" ? true : false) : "",
      nonCircumvention: selectedFilter?.nonCircumvention
        ? selectedFilter.nonCircumvention == "true"
          ? true
          : false
        : "",
      registrationAsVendor: selectedFilter?.registrationAsVendor
        ? selectedFilter.registrationAsVendor == "true"
          ? true
          : false
        : "",
    };
    const param = _.pickBy(payload, function (value, key) {
      return !(value === "" || value == {} || value === undefined || value === null);
    });
    props.getContracts(param, (res) => {
      if (res && res.status === 200) {
        setPage(1);
        if (showFilter) {
          toggleFilter();
        }
        setLoading(false);
        ref?.current?.complete();
      } else {
        setLoading(false);
        toast(<AlertError message={res?.data?.message ? res?.data?.message : "Something went wrong"} />);
        ref?.current?.complete();
      }
    });
  };
  useEffect(() => {
    if (!showDocModal) {
      setSelectedItem(null);
      setSelectedDocument({ doc: null, title: "" });
      setDeleteDocItem(null);
    }
  }, [showDocModal]);
  useEffect(() => {
    if (!addContractModal) {
      setContractValues({
        enterpriseId: "",
        buId: "",
        contractScope: "",
        contractType: "",
        contractStatus: "",
        startDate: "",
        expirationDate: "",
        automaticRenewal: "",
        terminationNotice: "",
        exclusivity: "",
        commissionRate: "",
        buyerPremiumOrSellerFee: "",
        salvexEntity: "",
        confidentiality: "",
        nonCircumvention: "",
        governingLaws: "",
        arbitration: "",
        indemnification: "",
        contractLanguage: "",
        specialTerms: "",
        registrationAsVendor: "",
        registrationUrl: "",
        registrationUsername: "",
        registrationPassword: "",
        registrationExpirationDate: "",
        registrationStatus: "",
      });
      setSelectedItem(null);
    }
    if (addContractModal && selectedItem) {
      const value = data?.data?.filter((itt) => itt.id == selectedItem.enterpriseId);

      const payload = {
        enterpriseId: selectedItem?.enterpriseId ? { name: value[0]?.name, id: value[0]?.id } : [],
        buId: selectedItem?.buId
          ? {
              name: selectedItem?.businessUnit?.title,
              id: selectedItem?.businessUnit?.project_id,
            }
          : [],
        contractScope: selectedItem?.contractScope
          ? contractScopeOption.filter((item) => item.value == selectedItem.contractScope)[0]
          : "",
        contractType: selectedItem?.contractType
          ? contractTypeOptions.filter((item) => item.value == selectedItem.contractType)[0]
          : "",
        contractStatus: selectedItem?.contractStatus
          ? contractStatusOptions.filter((item) => item.value == selectedItem.contractStatus)[0]
          : "",
        startDate: selectedItem?.startDate ? Date.parse(selectedItem?.startDate) : "",
        expirationDate: selectedItem?.expirationDate ? Date.parse(selectedItem.expirationDate) : "",
        automaticRenewal: selectedItem?.automaticRenewal ? "true" : "false",
        terminationNotice: selectedItem?.terminationNotice ? selectedItem?.terminationNotice : "",
        exclusivity: selectedItem?.exclusivity ? "true" : "false",
        commissionRate: selectedItem?.commissionRate ? selectedItem?.commissionRate : "",
        buyerPremiumOrSellerFee: selectedItem?.buyerPremiumOrSellerFee ? "true" : "false",
        salvexEntity: selectedItem?.salvexEntity
          ? entityOptions.filter((item) => item.value == selectedItem?.salvexEntity)[0]
          : "",
        confidentiality: selectedItem?.confidentiality ? "true" : "false",
        nonCircumvention: selectedItem?.nonCircumvention ? "true" : "false",
        governingLaws: selectedItem?.governingLaws ? selectedItem?.governingLaws : "",
        arbitration: selectedItem?.arbitration ? selectedItem?.arbitration : "",
        indemnification: selectedItem?.indemnification ? selectedItem?.indemnification : "",
        contractLanguage: selectedItem?.contractLanguage ? selectedItem?.contractLanguage : "",
        specialTerms: selectedItem?.specialTerms ? selectedItem?.specialTerms : "",
        registrationAsVendor: selectedItem?.registrationAsVendor ? "true" : "false",
        registrationUrl: selectedItem?.registrationCredentials?.url ? selectedItem?.registrationCredentials?.url : "",
        registrationUsername: selectedItem?.registrationCredentials?.userName
          ? selectedItem?.registrationCredentials?.userName
          : "",
        registrationPassword: selectedItem?.registrationCredentials?.password
          ? selectedItem?.registrationCredentials?.password
          : "",
        registrationStatus: selectedItem?.registrationStatus
          ? registrationStatusOptions.filter((item) => item.value == selectedItem.registrationStatus)[0]
          : "",
        registrationExpirationDate: selectedItem?.registrationExpirationDate
          ? Date.parse(selectedItem.registrationExpirationDate)
          : "",
      };
      setContractValues(payload);
    }
  }, [addContractModal]);

  useEffect(() => {
    if (!deleteModal) {
      setSelectedItem(null);
    }
  }, [deleteModal]);

  const addContract = () => {
    if (validate()) {
      setLoading(true);
      ref?.current?.continuousStart();

      const payload = {
        contractScope: contractValues?.contractScope?.value ? contractValues?.contractScope?.value : "",
        contractType: contractValues?.contractType?.value ? contractValues?.contractType?.value : "",
        contractStatus: contractValues?.contractStatus?.value ? contractValues?.contractStatus?.value : "",
        startDate: contractValues?.startDate ? contractValues?.startDate : "",
        expirationDate: contractValues?.expirationDate ? contractValues?.expirationDate : "",
        automaticRenewal: contractValues?.automaticRenewal
          ? contractValues?.automaticRenewal == "true"
            ? true
            : false
          : "",
        terminationNotice: contractValues?.terminationNotice ? contractValues?.terminationNotice : "",
        exclusivity: contractValues?.exclusivity ? (contractValues?.exclusivity == "true" ? true : false) : "",
        commissionRate: contractValues?.commissionRate ? contractValues?.commissionRate : "",
        buyerPremiumOrSellerFee: contractValues?.buyerPremiumOrSellerFee
          ? contractValues?.buyerPremiumOrSellerFee == "true"
            ? true
            : false
          : "",
        salvexEntity: contractValues?.salvexEntity?.value ? contractValues?.salvexEntity?.value : "",
        confidentiality: contractValues?.confidentiality
          ? contractValues?.confidentiality == "true"
            ? true
            : false
          : "",
        nonCircumvention: contractValues?.nonCircumvention
          ? contractValues?.nonCircumvention == "true"
            ? true
            : false
          : "",
        governingLaws: contractValues?.governingLaws ? contractValues?.governingLaws : "",
        arbitration: contractValues?.arbitration ? contractValues?.arbitration : "",
        indemnification: contractValues?.indemnification ? contractValues?.indemnification : "",
        contractLanguage: contractValues?.contractLanguage ? contractValues?.contractLanguage : "",
        specialTerms: contractValues?.specialTerms ? contractValues?.specialTerms : "",
        registrationAsVendor: contractValues?.registrationAsVendor
          ? contractValues?.registrationAsVendor == "true"
            ? true
            : false
          : "",
        registrationCredentials: {
          url: contractValues?.registrationUrl ? contractValues?.registrationUrl : "",
          userName: contractValues?.registrationUsername ? contractValues?.registrationUsername : "",
          password: contractValues?.registrationPassword ? contractValues?.registrationPassword : "",
        },
        registrationStatus: contractValues?.registrationStatus?.value ? contractValues?.registrationStatus?.value : "",
        registrationExpirationDate: contractValues?.registrationExpirationDate
          ? contractValues?.registrationExpirationDate
          : "",
      };
      if (!selectedItem) {
        payload.enterpriseId = contractValues?.enterpriseId?.id ? `${contractValues.enterpriseId?.id}` : "";
        payload.buId = contractValues?.buId?.id ? `${contractValues?.buId?.id}` : "";
      }

      const param = _.pickBy(payload, function (value, key) {
        return !(value === "" || value == {} || value === undefined || value === null);
      });
      if (selectedItem) {
        const id = selectedItem?._id;
        props.editContracts(id, param, (res) => {
          if (res && res.status === 200) {
            props.getContracts({}, (res) => {
              if (res && res.status === 200) {
                setLoading(false);
                ref?.current?.complete();
                toggleAddContractModal();
                toast(<AlertSuccess message={"Information Saved"} />);
              }
            });
          } else {
            setLoading(false);
            ref?.current?.complete();
            toast(<AlertError message={res?.data?.message ? res.data.message : "Something went wrong"} />);
          }
        });
      } else {
        props.addContracts(param, (res) => {
          if (res && res.status === 200) {
            props.getContracts({}, (res) => {
              if (res && res.status === 200) {
                setLoading(false);
                ref?.current?.complete();
                toggleAddContractModal();
                toast(<AlertSuccess message={"Information Saved"} />);
              }
            });
          } else {
            setLoading(false);
            ref?.current?.complete();
            toast(<AlertError message={res?.data?.message ? res.data.message : "Something went wrong"} />);
          }
        });
      }
    }
  };

  const loadEnterpriseOptions = (name, callback) => {
    console.log(name,'is the name from the load optimizer.')
    let options = [];
    const payload = {
      keyword: name,
      page: 1,
    };
    props.getEnterprises(payload, (res) => {
      if (res && res.status === 200) {
        options = res.data?.data;
        callback(options);
      } else {
        callback([]);
      }
    });
  };

  useEffect(() => {
    const id = addContractModal ? contractValues?.enterpriseId?.id : selectedFilter?.enterprise?.id;
    if (id) {
      props.getBusinessUnits(id, { keyword: "", page: 1 }, (res) => {
        if (res && res.status === 200) {
        }
      });
    }
  }, [contractValues?.enterpriseId?.id, selectedFilter?.enterprise?.id]);

  const loadBuOptions = (name, callback) => {
    let options = [];
    const id = addContractModal ? contractValues?.enterpriseId?.id : selectedFilter?.enterprise?.id;
    const payload = {
      keyword: name,
      page: 1,
    };
    props.getBusinessUnits(id, payload, (res) => {
      if (res && res.status === 200) {
        options = res.data?.data?.map((item) => ({
          name: item.title,
          id: item.project_id,
        }));
        callback(options);
      } else {
        callback([]);
      }
    });
  };

  const buOptions =
    props?.getContractListings?.businessUnit?.data?.map((item) => ({
      name: item.title,
      id: item.project_id,
    })) || [];

  const deleteHandler = () => {
    ref?.current?.continuousStart();
    setLoading(true);
    const id = selectedItem?._id;
    props.deleteContract(id, (res) => {
      if (res && res.status == 200) {
        props.getContracts({}, (res1) => {
          if (res1 && res1.status === 200) {
            ref?.current?.complete();
            setLoading(false);
            toggleDeleteModal();
            toast(<AlertSuccess message={"Information Saved"} />);
          }
        });
      } else {
        res?.current?.complete();
        setLoading(false);
        toast(<AlertError message={res?.data?.message ? res?.data?.message : "Someting went wrong"} />);
      }
    });
  };

  useEffect(() => {
    if (!showFilter) {
      handleFilter();
    }
  }, [selectedFilter]);

  useEffect(() => {
    if (!showListingsModal) {
      setSelectedItem(null);
    }
  }, [showListingsModal]);

  const handleSubmitDocument = (e) => {
    if (validateDoc()) {
      e.preventDefault();
      ref?.current?.continuousStart();
      setLoading(true);
      const file = selectedDocument?.doc;

      const fSize = Math.round(file.size / 1048576);
      const fType = file.type;
      const ext = file.name.split(".").pop();
      if (fSize > 25) {
        ref?.current?.complete();
        toast(<AlertError message="Size exceeds maximum allowable size. Maximum allowable size is 25MB." />);
        return setLoading(false);
      } else if (
        ![
          "application/pdf",
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ].includes(fType)
      ) {
        ref?.current?.complete();
        toast(
          <AlertError message="File is not of correct format and hence cannot be uploaded. Valid document formats are pdf, doc, and docx." />,
        );
        return setLoading(false);
      } else {
        const fileName = "newSalvex/doc/" + uuidv4() + "." + ext;
        Storage.put(fileName, file, {
          completeCallback: (event) => {},
          progressCallback: (progress) => {},
          errorCallback: (err) => {
            return setLoading(false), ref?.current?.complete(), toast(<AlertError message={"Something Went Wrong"} />);
          },
        }).then((result) => {
          props.uploadFile(
            selectedItem?._id,
            {
              files: [
                {
                  media: "public/" + result.key,
                  name: selectedDocument?.title,
                },
              ],
            },
            (res) => {
              if (res && res.status === 200) {
                props.getContracts({}, (res1) => {
                  if (res1 && res1.status === 200) {
                    setSelectedDocument({ doc: "", title: "" });
                    setLoading(false);
                    ref?.current?.complete();
                    toast(<AlertSuccess message={"Information Saved"} />);
                  }
                });
              } else {
                setLoading(false);
                ref?.current?.complete();
                toast(
                  <AlertError
                    message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"}
                  />,
                );
              }
            },
          );
        });
      }
    }
  };

  const deleteDocHandler = () => {
    setLoading(true);
    ref?.current?.continuousStart();
    const id = selectedItem._id;
    const payload = {
      file: `${deleteDocItem?.original}`,
    };
    props.deleteFile(id, payload, (res) => {
      if (res && res.status == 200) {
        props.getContracts({}, (res1) => {
          if (res1 && res1.status === 200) {
            ref?.current?.complete();
            setLoading(false);
            toggleDeleteDoc();
            toast(<AlertSuccess message={"Information Saved"} />);
          }
        });
      } else {
        ref?.current?.complete();
        setLoading(false);
        toggleDeleteDoc();
        toast(<AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  return (
    <>
      <LoadingBar height={5} color="#47AD1D" ref={ref} />
      <Header />
      <ContractsTable
        addContractModal={addContractModal}
        setAddContractModal={setAddContractModal}
        toggleAddContractModal={toggleAddContractModal}
        data={data}
        page={page}
        setPage={setPage}
        loading={loading}
        setSelectedItem={setSelectedItem}
        toggleShowMoreDetails={toggleShowMoreDetails}
        toggleFilter={toggleFilter}
        showFilter={showFilter}
        selectedFilter={selectedFilter}
        setSelectedFilter={setSelectedFilter}
        handleFilter={handleFilter}
        contractValues={contractValues}
        setContractValues={setContractValues}
        loadEnterpriseOptions={loadEnterpriseOptions}
        loadBuOptions={loadBuOptions}
        buOptions={buOptions}
        addContract={addContract}
        errors={errors}
        entityOptions={entityOptions}
        contractScopeOption={contractScopeOption}
        contractTypeOptions={contractTypeOptions}
        contractStatusOptions={contractStatusOptions}
        toggleDeleteModal={toggleDeleteModal}
        selectedItem={selectedItem}
        toggleDocModal={toggleDocModal}
        toggleShowListing={toggleShowListing}
        registrationStatusOptions={registrationStatusOptions}
        sort={sort}
        setSort={setSort}
        order={order}
        setOrder={setOrder}
      />
      <ViewMoreDetails
        show={showMoreDetails}
        onHide={toggleShowMoreDetails}
        selectedItem={selectedItem}
        entityOptions={entityOptions}
        contractScopeOption={contractScopeOption}
        contractTypeOptions={contractTypeOptions}
        contractStatusOptions={contractStatusOptions}
        toggleDocModal={toggleDocModal}
        registrationStatusOptions={registrationStatusOptions}
      />
      <DeleteModal
        show={deleteModal}
        onHide={toggleDeleteModal}
        loadingDelete={loading}
        deleteHandler={deleteHandler}
      />
      <DeleteModal
        show={showdeleteDoc}
        onHide={toggleDeleteDoc}
        loadingDelete={loading}
        deleteHandler={deleteDocHandler}
      />
      <AddDocuments
        show={showDocModal}
        onHide={toggleDocModal}
        data={data}
        loading={loading}
        selectedDocument={selectedDocument}
        setSelectedDocument={setSelectedDocument}
        errorDoc={errorsDoc}
        handleSubmitDocument={handleSubmitDocument}
        deleteDocHandler={deleteDocHandler}
        setDeleteDocItem={setDeleteDocItem}
        toggleDeleteDoc={toggleDeleteDoc}
        selectedItem={selectedItem}
      />
      <ViewListings show={showListingsModal} onHide={toggleShowListing} selectedItem={selectedItem} />
    </>
  );
};
const mapDispatchToProps = (dispatch) => ({
  getContracts: (params, callback) => dispatch(getContracts(params, callback)),
  addContracts: (params, callback) => dispatch(addContracts(params, callback)),
  editContracts: (id, params, callback) => dispatch(editContracts(id, params, callback)),
  deleteFile: (params, data, callback) => dispatch(deleteFile(params, data, callback)),
  uploadFile: (params, data, callback) => dispatch(uploadFile(params, data, callback)),
  deleteContract: (params, callback) => dispatch(deleteContract(params, callback)),
  getBusinessUnits: (params, callback) => dispatch(getBusinessUnits(params, callback)),
  getEnterprises: (params, callback) => dispatch(getEnterprises(params, callback)),
});

const mapStateToProps = (state) => ({
  getContractListings: getContractListing(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Contracts));
