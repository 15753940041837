import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { dashboardData, deleteResearchAssignment, getCompletedResearchAssignment } from "../../../store/dashBoard";
import { toast } from "react-toastify";
import AlertError from "../../../common/alerts/alertError";
import DateFormat from "../../../common/DateFormat/dateFormat";
import Skeleton from "react-loading-skeleton";
import DeleteModal from "../../../layouts/modals/deleteModal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const CompletedResearchAssignment = ({ empId, ...props }) => {
  const history = useHistory();
  const [loadedContent, setLoadedContent] = useState(false);
  const [noContent, setNoContent] = useState(true);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [enable, setEnable] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [contentLoaded, setContentLoaded] = useState(false);
  const [id, setId] = useState("");

  const toggleDeleteModal = (id) => {
    setId(id);
    setShowDeleteModal(!showDeleteModal);
  };

  //Delete handler
  const handleDelete = () => {
    props.refStart();
    setLoadingDelete(true);
    props.deleteResearchAssignment(id, (res) => {
      if (res.status === 200) {
        props.getCompletedResearchAssignment(empId, props.pl, props.pt, page, (res) => {});
        props.refStop();
        setLoadingDelete(false);
        toggleDeleteModal();
      } else {
        props.refStop();
        setLoadingDelete(false);
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const listingData = props.dashboardData?.completedResearchAssignment;
  useEffect(() => {
    if (props.viewContent) {
      setContentLoaded(false);
      setLoadedContent(false);
    }
  }, [props.refresh]);
  const fetchData = () => {
    if (!contentLoaded) {
      props.refStart();
      setLoading(true);
      props.getCompletedResearchAssignment(empId, props.pl, props.pt, page, (res) => {
        if (res.status === 200) {
          setEnable(true);
          setLoading(false);
          setLoadedContent(!loadedContent);
          setContentLoaded(true);
          props.refStop();
        } else {
          setLoading(false);
          props?.refStop();
          toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        }
      });
    } else {
      setNoContent(false);
      setLoadedContent(!loadedContent);
      props.refStop();
    }
  };

  //Pagination
  useEffect(() => {
    if (enable) {
      props?.refStart();
      props.getCompletedResearchAssignment(empId, props.pl, props.pt, page, (res) => {
        if (res.status === 200) {
          props?.refStop();
        } else {
          props?.refStop();
          toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        }
      });
    }
  }, [page]);
  return (
    <div class="details-page-widget-block">
      <div class="page-widget-head d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <div class="page-widget-label text-capitalize cursor-pointer" onClick={fetchData}>
            Completed Research Assignments
            {!props.loadingMain && `(${props.count ? props.count : "0"})`}
          </div>
        </div>
      </div>
      <div class="page-widget-body mt-3">
        {loadedContent ? (
          <div class="table-responsive table-custom">
            <table class="table table-bordered">
              <colgroup>
                <col style={{ width: "28px" }} />
                <col />
                <col />
                <col />
                <col />
                <col />
                <col />
                <col />
                <col />
                <col style={{ width: "28px" }} />
                <col style={{ width: "28px" }} />
              </colgroup>
              <thead>
                <tr>
                  <th></th>
                  <th>Auction/Enterprise</th>
                  <th>Category</th>
                  <th>Industry</th>
                  <th>Manufacturer</th>
                  <th>Region</th>
                  <th>Assigned To</th>
                  <th>Assigned By</th>
                  <th>Completed</th>
                  <th>Pushed</th>
                  <th>Created</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {listingData?.data.map((val, i) => (
                  <tr>
                    <td>{(listingData.page - 1) * 10 + (i + 1)}</td>
                    <td>
                      <a className="green-btn-text" target="_blank" href={`listing-detail?id=${val?.auction_id}`}>
                        {val.auction ? val.auction : "N/A"}
                      </a>
                    </td>
                    <td>{val.category ? val?.category?.replace(/&amp;/g, "&") : "N/A"}</td>
                    <td>{val.industry ? val.industry : "N/A"}</td>
                    <td>{val.manufacturer ? val.manufacturer : "N/A"}</td>
                    <td>{val.region ? val.region : "N/A"}</td>
                    <td>
                      {val.assigned_to_name ? (
                        <span
                          className="green-btn-text cursor-pointer"
                          onClick={() => {
                            if (process.env.REACT_APP_MODE == "development") {
                              window.open(
                                `https://admin-dev.salvex.com/admin/_user/_edit.cfm?user_id=${val?.assigned_to}`,
                              );
                            } else {
                              window.open(`https://admin.salvex.com/admin/_user/_edit.cfm?user_id=${val?.assigned_to}`);
                            }
                          }}
                        >
                          {val.assigned_to_name}
                        </span>
                      ) : (
                        "N/A"
                      )}
                    </td>
                    <td>
                      {val.assigned_by_name ? (
                        <span
                          className="green-btn-text cursor-pointer"
                          onClick={() => {
                            if (process.env.REACT_APP_MODE == "development") {
                              window.open(
                                `https://admin-dev.salvex.com/admin/_user/_edit.cfm?user_id=${val?.assigned_by}`,
                              );
                            } else {
                              window.open(`https://admin.salvex.com/admin/_user/_edit.cfm?user_id=${val?.assigned_by}`);
                            }
                          }}
                        >
                          {val.assigned_by_name}
                        </span>
                      ) : (
                        "N/A"
                      )}
                    </td>
                    <td>{val.completed ? <DateFormat date={val.completed} /> : "No"}</td>
                    <td>{val.pushed ? <DateFormat date={val.pushed} /> : "No"}</td>
                    <td>{val.created ? <DateFormat date={val.created} /> : "N/A"}</td>
                    <td>
                      <a
                        href="javascript:void(0)"
                        class="btn btn-auto btn-xs-size btn-primary"
                        onClick={() => history.push(`/research-assignment?assignment_id=${val?.id}`)}
                      >
                        View
                      </a>
                    </td>
                    <td>
                      <a
                        href="javascript:void(0)"
                        class="btn btn-auto btn-xs-size btn-danger"
                        onClick={() => toggleDeleteModal(val.id)}
                      >
                        Delete
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          (!contentLoaded || noContent) &&
          (props.loadingMain ? (
            <Skeleton height="50px" />
          ) : (
            <div
              class="page-widget-empty-box text-capitalize d-flex align-items-center justify-content-center text-center"
              style={{
                height: "50px",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <span>{loading ? "Content Loading..." : "Content not loaded"}</span>
            </div>
          ))
        )}
      </div>
      {loadedContent && listingData?.data?.length > 0 && (
        <div className="d-flex justify-content-between mt-3">
          <div className="justify-content-start">
            Page {listingData?.page} of {listingData?.max_pages} ({listingData?.total_records} records)
          </div>
          <div class="table-pagination">
            <ul class="pagination">
              <li class="page-item">
                <a
                  class={`page-link ${page === 1 ? "disabled" : "cursor-pointer"}`}
                  aria-label="Previous"
                  onClick={() => {
                    if (page !== 1) {
                      setPage(page - 1);
                    }
                  }}
                >
                  <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 13L1 7L7 1" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </a>
              </li>
              <li class="page-item active">
                <a class="page-link" href="javascript:void(0)">
                  {page}
                </a>
              </li>
              <li class="page-item">
                {page < listingData?.max_pages && (
                  <a class="page-link" onClick={() => setPage(page + 1)}>
                    {page + 1}
                  </a>
                )}
              </li>
              <li class="page-item">
                {page < listingData?.max_pages && (
                  <a class="page-link" href="javascript:void(0)" aria-label="Next" onClick={() => setPage(page + 1)}>
                    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 13L7 7L1 1" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </a>
                )}
              </li>
            </ul>
          </div>
        </div>
      )}
      <DeleteModal
        show={showDeleteModal}
        onHide={toggleDeleteModal}
        loadingDelete={loadingDelete}
        deleteHandler={handleDelete}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getCompletedResearchAssignment: (empId, pl, pt, page, callback) =>
    dispatch(getCompletedResearchAssignment(empId, pl, pt, page, callback)),
  deleteResearchAssignment: (id, callback) => dispatch(deleteResearchAssignment(id, callback)),
});
const mapStateToProps = (state) => ({
  dashboardData: dashboardData(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(CompletedResearchAssignment));
