import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import "../../../include/css/weeklyReport.css";
import { getIndividualPushData, primarySellerData, sendIndividualPush } from "../../../store/primarySeller";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import queryString from "query-string";
import LoadingBar from "react-top-loading-bar";
import { checkToken, setRedirectUrl } from "../../../utils.js/localStorageServices";
import { toast } from "react-toastify";
import { numberFormat } from "../../../utils.js/conversion";
import ScrollButton from "../../../common/scrollTop/scrollTop";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import HTMLReactParser from "html-react-parser";
import close from "../../../include/images/close-white.svg";
import addIcon from "../../../include/images/plus-circle.svg";
import successIcon from "../../../include/images/success-icon.png";
import AlertError from "../../../common/alerts/alertError";
import Joi from "joi";
import { getCategoryPreferenacesDropdown, getListings } from "../../../store/listings";
import {
  getPreferences,
  getRegionPreference,
  getSelectedRegion,
  updateRegionPreference,
} from "../../../store/preferences";
import {
  addCategoryPreference,
  getCategories,
  getCategoryPreference,
  getCountry,
  getIndustry,
  getProfileOptions,
  getState,
  getUser,
} from "../../../store/users";
import { getUserList } from "../../../store/userList";
import AddUserModal from "../../../components/users/modal/addUserModal";
import ProfileOptions from "../../../components/users/modal/profileOptions";
import CategoryPreferenceModal from "../../../components/users/modal/categoryPreferenceModal";
import RegionPreferences from "../../../components/users/modal/regionPreferences";

const BuyerARR = (props) => {
  const ref = useRef(null);
  const location = useLocation();
  const isMultiplePush = true;
  const [loading, setLoading] = useState(false);
  const [emailData, setEmailData] = useState({ from: "", to: "", cc: "", bcc: "", subject: "" });
  const [toArray, setToArray] = useState({ active: "", values: [], isEmailsValid: true });
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [emailList, setEmailList] = useState([]);

  //User Edit States

  const [showProfileOption, setShowProfileOption] = useState(false);
  const [showCategoryPreferenceModal, setShowCategoryPreferenceModal] = useState(false);
  const [userId, setUserId] = useState("");
  const [profileOptionsData, setProfileOptionsData] = useState({});
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [userData, setUserData] = useState({
    userType: "",
    firstName: "",
    lastName: "",
    email: "",
    city: "",
    stateId: 0,
    extNumber: "",
    countryID: 0,
    industryId: 0,
    zipCode: "",
    addressLine1: "",
    webSite: "",
    phone: "",
    mobile: "",
    password: "",
  });
  const [industryList, setIndustryList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [showRegionPref, setShowRegionPref] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState([]);
  const [loadingRegion, setLoadingRegion] = useState(false);
  const [regionPrefData, setRegionPrefData] = useState([]);

  const [buyer, setBuyer] = useState(false);

  const userTypeOptions = [
    { name: "individual", value: "1" },
    { name: "company", value: "2" },
  ];

  const siteUrl =
    process.env.REACT_APP_MODE === "development" ? "https://newadmin-dev.salvex.com/" : "https://newadmin.salvex.com/";

  const data = props?.primarySellerData?.data;

  const isNoContentLoaded = props?.primarySellerData?.data?.subject ? false : true;

  useEffect(() => {
    document.body.classList.add("pt-0");
    document.body.classList.remove("pt-80");
    if (!checkToken()) {
      const a = window.location.href;
      setRedirectUrl(a);
      props.history.push("/signin");
    }
    return () => {
      document.body.classList.add("pt-80");
      document.body.classList.remove("pt-0");
    };
  }, []);
  useEffect(() => {
    const a = queryString.parse(location.search)?.id;
    if (a) {
      ref?.current?.continuousStart();

      props?.getIndividualPushData(a, (res) => {
        if (res && res.status == 200) {
          if (!res?.data?.data?.mm_email) {
            window.location.href = "/404";
          }
          const value = { ...emailData };
          value.from = `${res?.data?.data?.mm_email} (${res?.data?.data?.name01})`;
          value.subject = res?.data?.data?.subject;
          setEmailData(value);
          ref?.current?.complete();
        }
      });
    } else {
      window.location.href = "/500";
    }
  }, []);

  const onSendHandler = () => {
    const a = queryString.parse(location.search)?.id;
    ref?.current?.continuousStart();
    const payload = {
      from_email: data?.mm_email,
      to_emails: isMultiplePush ? [...toArray?.values] : [emailData?.to],
      bcc_email: emailData?.bcc,
      cc_email: emailData?.cc,
      subject_line: emailData?.subject,
      invite_to_preview: 0,
    };
    setLoading(true);
    props?.sendIndividualPush(a, payload, (res) => {
      if (res && res.status == 200) {
        setLoading(false);
        ref?.current?.complete();
        const value = { ...emailData };
        value.to = "";
        value.cc = "";
        value.bcc = "";
        setEmailData(value);
        setIsEmailSent(true);
        setEmailList(res?.data?.data);
      } else {
        setLoading(false);
        ref?.current?.complete();
        window.location.href("/500");
      }
    });
  };

  function scheduledEndTime(auction) {
    if (auction.at_id === 2) {
      return "BID ASAP";
    } else {
      const formattedTime = moment(auction.end_date).utcOffset("-00:00").format("MMM-DD-YY hh:mm A");
      return `${formattedTime == "Invalid date" ? "" : formattedTime} CST`;
    }
  }

  function scheduledTimeRemaining(auction) {
    if (auction.status_id === 5) {
      return "CLOSED";
    } else if (auction.at_id === 2) {
      return "BID ASAP";
    } else {
      const days = auction.days;
      const hours = auction.hours;
      const minutes = auction.minutes;
      if (!days || days === 0) {
        return `${hours} hrs, ${minutes} mins`;
      } else {
        return `${days} days, ${hours} hrs, ${minutes} mins`;
      }
    }
  }

  const emailSchema = Joi.string().email({ tlds: { allow: false } });

  //FOR USER EDIT FEATURE

  const toggleShowRegion = () => {
    setShowRegionPref(!showRegionPref);
  };

  const toggleAddUserModal = () => {
    setShowAddUserModal(!showAddUserModal);
  };
  const toggleCategoryPreferenceModal = (userId) => {
    setCategoryLoading(true);
    props.getCategoryPreference(userId, (res) => {
      if (res.status === 200) {
        setSelectedCategory(res.data.userCategories);
        setCategoryLoading(false);
      } else {
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
    setShowCategoryPreferenceModal(!showCategoryPreferenceModal);
  };

  const closedCategoryPreference = () => {
    setShowCategoryPreferenceModal(!showCategoryPreferenceModal);
  };

  const fetchProfileOptions = (id) => {
    setLoading(true);
    setUserId(id);
    // setShowProfileOption(true);
    setShowAddUserModal(true);
    props.getProfileOptions(id, (res) => {
      if (res.status === 200) {
        setProfileOptionsData(res.data.detail);
        const value = { ...res.data.detail };
        setUserData({
          fullName: value?.p_name01,
          mobile: value?.p_phone,
          email: value?.p_email,
          user_type: value?.user_type === "company" ? "1" : "2",
          member_user_type: "0",
          company: value?.p_company_name,
          industry: { name: value?.industry, value: value?.industry_id },
          address: value?.p_address_line_1,
          city: value?.p_city,
          zip: value?.p_zip,
          country: { name: value?.p_country, value: value?.p_country_id },
          state: { name: value?.p_state, value: value?.p_state_id },
          ext: value?.companyPhone ? value?.companyPhone?.split(",")?.[1] : "",
          phone: value?.companyPhone ? value?.companyPhone?.split(",")?.[0] : "",
          website: value?.web_site,
          member_user_type: value?.memberUserType ? value?.memberUserType.toString() : "0",
        });
        setLoading(false);
      } else {
        setLoading(false);
        setShowProfileOption(false);
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  useEffect(() => {
    if (showAddUserModal) {
      props.getIndustry((res) => {
        if (res.status === 200) {
          setIndustryList(res.data);
        }
      });
    }
  }, [showAddUserModal]);

  useEffect(() => {
    if (props.getPreferences && props.getPreferences.regionlistings && props.getPreferences.regionlistings.data) {
      setRegionPrefData(props.getPreferences.regionlistings.data);
    }
  }, [props.getPreferences && props.getPreferences.regionlistings && props.getPreferences.regionlistings.data]);

  useEffect(() => {
    if (!showRegionPref) {
      setSelectedId(null);
    }
    if (showRegionPref && !regionPrefData.length > 0) {
      props.getRegionPref((res) => {
        if (res && res.status == 200) {
          setRegionPrefData(res.data.data);
        }
      });
    }
    if (showRegionPref && selectedId) {
      setLoading(true);
      props.getSelectedRegion(selectedId?.user_id, (res) => {
        if (res && res.status == 200) {
          setLoading(false);
          const b = [];
          const a =
            res &&
            res?.data?.userRegions &&
            res.data?.userRegions?.length > 0 &&
            res.data?.userRegions?.map((item) => {
              if (item.owner != "All Regions") {
                if (item.owner == "United States") {
                  b.push(`${item.region_id}*United States*${item.description}`);
                }
                if (item.owner == "International") {
                  b.push(`${item.region_id}*International*${item.description}`);
                }
              }
            });
          setSelectedRegion(b);
        }
      });
    }
  }, [showRegionPref]);

  useEffect(() => {
    if (showAddUserModal) {
      props.getCountry((res) => {
        if (res.status === 200) {
          setCountryList(res.data);
        }
      });
    }
  }, [showAddUserModal]);

  useEffect(() => {
    if (showAddUserModal) {
      props.getState((res) => {
        if (res.status === 200) {
          setStateList(res.data);
        }
      });
    }
  }, [showAddUserModal]);

  useEffect(() => {
    props.getCategoryPreferenacesDropdown(0, (res) => {});
  }, []);

  const categoryData =
    (props.getListings && props.getListings.categoryDrpDown && props.getListings.categoryDrpDown.data?.categories) ||
    [];

  return (
    <>
      <ScrollButton />
      <LoadingBar height={5} color="#47AD1D" ref={ref} />
      <table width="100%" cellspacing="0" cellpadding="0" className="w-email-table">
        {!isEmailSent ? (
          <>
            <tr>
              <td class="xs-p-0" align="center" valign="top" style={{ padding: "5px 0" }}>
                <table
                  class="w-full-mobile"
                  cellspacing="0"
                  cellpadding="0"
                  style={{ width: "800px", background: "#fff" }}
                >
                  <div class="details-page-widget-block">
                    {!isMultiplePush ? (
                      <div class="page-widget-head ipr_conatiner d-flex align-items-center justify-content-start">
                        <div class="d-flex align-items-start " style={{ width: "90%" }}>
                          <div class="page-widget-label text-capitalize cursor-pointer ipr_label">From </div>
                          <input
                            style={{ marginLeft: "50px" }}
                            className="form-control"
                            value={emailData?.from}
                            disabled
                          />
                        </div>
                        <div class="d-flex align-items-start " style={{ width: "90%", marginLeft: "35px" }}>
                          <div class="page-widget-label text-capitalize cursor-pointer ipr_label">To </div>
                          <input
                            style={{ marginLeft: "15px" }}
                            className="form-control"
                            value={emailData?.to}
                            onChange={(e) => {
                              const value = { ...emailData };
                              value.to = e.target.value;
                              setEmailData(value);
                            }}
                          />
                        </div>
                      </div>
                    ) : (
                      <>
                        <div class="page-widget-head ipr_conatiner d-flex align-items-center justify-content-start">
                          <div class="d-flex align-items-start " style={{ width: "100%" }}>
                            <div class="page-widget-label text-capitalize cursor-pointer ipr_label">From </div>
                            <input
                              style={{ marginLeft: "42px" }}
                              className="form-control"
                              value={emailData?.from}
                              disabled
                            />
                          </div>
                        </div>
                        <div class="page-widget-head ipr_conatiner d-flex align-items-center justify-content-start">
                          <div class="d-flex align-items-start " style={{ width: "100%" }}>
                            <div class="page-widget-label text-capitalize cursor-pointer ipr_label">To </div>
                            <div
                              className="position-relative w-100"
                              style={{ marginLeft: "42px", height: "140px", background: "#f5f5f5" }}
                            >
                              <div
                                className="d-flex position-absolute w-100 justify-content-start align-items-start flex-wrap"
                                style={{ top: "10px", left: "0px" }}
                              >
                                {toArray?.values?.length > 0
                                  ? toArray?.values?.map((jd, i) => {
                                      const isValidEmail =
                                        jd?.length === 0 ? true : emailSchema.validate(jd).error === undefined;
                                      return (
                                        <div
                                          className={`page-cat-box d-flex align-items-center justify-content-start  ${
                                            !isValidEmail ? "rsp" : "gsp"
                                          }  mt-2`}
                                          style={{ marginLeft: "15px" }}
                                        >
                                          {toArray?.isActive === i ? (
                                            <input
                                              style={{
                                                color: "white",
                                                backgroundColor: !isValidEmail ? "red" : "green",
                                                border: "0px",
                                              }}
                                              autoFocus
                                              value={toArray?.values?.[i]}
                                              onChange={(e) => {
                                                const values = [...toArray?.values];
                                                values[i] = e.target.value;
                                                setToArray({ isActive: toArray?.isActive, values, isEmailsValid: "" });
                                              }}
                                              onBlur={() => {
                                                const values = [
                                                  ...toArray?.values?.filter((ur) => ur?.trim()?.length > 0),
                                                ];
                                                const areAllEmailsValid = values.every(
                                                  (email) => !emailSchema.validate(email).error,
                                                );
                                                if (!areAllEmailsValid) {
                                                  toast(<AlertError message={"One or more emails are invalid"} />);
                                                }
                                                setToArray({ isActive: "", values, isEmailsValid: areAllEmailsValid });
                                              }}
                                              onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                  const values = [
                                                    ...toArray?.values?.filter((ur) => ur?.trim()?.length > 0),
                                                  ];
                                                  const areAllEmailsValid = values.every(
                                                    (email) => !emailSchema.validate(email).error,
                                                  );
                                                  if (!areAllEmailsValid) {
                                                    toast(<AlertError message={"One or more emails are invalid"} />);
                                                  }
                                                  setToArray({
                                                    isActive: "",
                                                    values,
                                                    isEmailsValid: areAllEmailsValid,
                                                  });
                                                }
                                              }}
                                            />
                                          ) : (
                                            <>
                                              <div class={`  d-inline `}>{jd}</div>
                                              <img
                                                className=" d-inline"
                                                src={close}
                                                alt=""
                                                style={{ height: "15px", marginLeft: "10px" }}
                                                onClick={() => {
                                                  const values = [...toArray?.values]?.filter((uy) => uy !== jd);
                                                  const areAllEmailsValid = values.every(
                                                    (email) => !emailSchema.validate(email).error,
                                                  );
                                                  setToArray({
                                                    isActive: "",
                                                    values,
                                                    isEmailsValid: areAllEmailsValid,
                                                  });
                                                }}
                                              />
                                            </>
                                          )}
                                        </div>
                                      );
                                    })
                                  : ""}

                                {toArray?.values?.length < 5 ? (
                                  <div
                                    className="page-cat-box d-flex align-items-center justify-content-start gsp mt-2 cursor-pointer"
                                    style={{ marginLeft: "20px" }}
                                    onClick={() => {
                                      const values = [...toArray?.values];
                                      values.push("");
                                      setToArray({ isActive: toArray?.values?.length, values });
                                    }}
                                  >
                                    <div class={`  d-inline `}>
                                      {toArray?.values?.length === 0 ? "Add recipient's email address" : "Add More"}
                                    </div>
                                    <img
                                      className=" d-inline cursor-pointer"
                                      src={addIcon}
                                      alt=""
                                      style={{ height: "15px", marginLeft: "10px" }}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    <div class="page-widget-head ipr_conatiner d-flex align-items-center justify-content-start  mb-2">
                      <div class="d-flex align-items-start " style={{ width: "90%" }}>
                        <div class="page-widget-label text-capitalize cursor-pointer ipr_label">CC </div>
                        <input
                          style={{ marginLeft: "50px" }}
                          className="form-control"
                          value={emailData?.cc}
                          onChange={(e) => {
                            const value = { ...emailData };
                            value.cc = e.target.value;
                            setEmailData(value);
                          }}
                        />
                      </div>
                      <div class="d-flex align-items-start " style={{ width: "90%", marginLeft: "35px" }}>
                        <div class="page-widget-label text-capitalize cursor-pointer ipr_label">BCC </div>
                        <input
                          style={{ marginLeft: "15px" }}
                          className="form-control"
                          value={emailData?.bcc}
                          onChange={(e) => {
                            const value = { ...emailData };
                            value.bcc = e.target.value;
                            setEmailData(value);
                          }}
                        />
                      </div>
                    </div>
                    <div class="page-widget-head ipr_conatiner d-flex align-items-center justify-content-start  mb-2">
                      <div class="d-flex align-items-start " style={{ width: "100%" }}>
                        <div class="page-widget-label text-capitalize cursor-pointer ipr_label">Subject </div>
                        <input
                          rows={"3"}
                          style={{ marginLeft: "37px" }}
                          className="form-control"
                          value={emailData?.subject}
                          onChange={(e) => {
                            const value = { ...emailData };
                            value.subject = e.target.value;
                            setEmailData(value);
                          }}
                        />
                      </div>
                    </div>
                    <div class="btn-block d-flex align-items-center mb-2">
                      <a
                        class={`button-hover ${
                          loading || (!emailData?.to && toArray?.values?.length === 0) || !toArray?.isEmailsValid
                            ? "btn-disabled"
                            : ""
                        } `}
                        href="javascript:void(0)"
                        style={{
                          width: "150px",
                          display: "block",
                          textAlign: "center",
                          fontSize: "14px",
                          lineHeight: "1.25",
                          fontWeight: "500",
                          backgroundColor: "#47ad1d",
                          color: "#fff",
                          borderRadius: "4px",
                          padding: "15px 30px",
                          textDecoration: "none",
                          marginLeft: "85px",
                        }}
                        onClick={onSendHandler}
                      >
                        Send
                      </a>
                    </div>
                  </div>
                </table>
              </td>
            </tr>
            <tr>
              <td class="xs-p-0" align="center" valign="top" style={{ padding: "5px 0" }}>
                <table
                  class="w-full-mobile"
                  cellspacing="0"
                  cellpadding="0"
                  style={{ width: "800px", minHeight: "90vh", background: "#fff" }}
                >
                  <tbody>
                    <tr>
                      <td align="center" valign="top">
                        <table width="100%" cellspacing="0" cellpadding="0">
                          <tr>
                            <td
                              class="xs-p-15"
                              valign="middle"
                              style={{
                                padding: "15px 32px",
                                borderBottom: "1px solid #e4e4e4",
                              }}
                            >
                              <table width="100%" cellpadding="0" cellspacing="0">
                                <tr>
                                  <td align="left">
                                    <img
                                      style={{
                                        display: "block",
                                        width: "140px",
                                        height: "40px",
                                        margin: "0",
                                        padding: "0",
                                        border: "0",
                                      }}
                                      src="https://s3.amazonaws.com/media-dev.salvex.com/emails/salvex2x.png"
                                      alt="logo"
                                    />
                                  </td>
                                  <td align="right">
                                    <table cellSpacing="0" cellPadding="0">
                                      <tr>
                                        <td>
                                          <a href="https://twitter.com/SalvexGroup" target="_blank">
                                            <img
                                              style={{
                                                display: "block",
                                                width: "30px",
                                                height: "30px",
                                                margin: "0",
                                                padding: "0",
                                                border: "0px",
                                              }}
                                              src="https://s3.amazonaws.com/media-dev.salvex.com/emails/social-icons/Twitter_default.png"
                                              alt="twitter"
                                            />
                                          </a>
                                        </td>
                                        <td style={{ padding: "0px 25px" }}>
                                          <a href="https://www.facebook.com/SalvexGroup/" target="_blank">
                                            <img
                                              style={{
                                                display: "block",
                                                width: "30px",
                                                height: "30px",
                                                margin: "0",
                                                padding: "0",
                                                border: "0px",
                                              }}
                                              src="https://s3.amazonaws.com/media-dev.salvex.com/emails/social-icons/facebook_default.png"
                                              alt="facebook"
                                            />
                                          </a>
                                        </td>
                                        <td style={{ paddingRight: "25px" }}>
                                          <a href="https://www.linkedin.com/company/salvexgroup/" target="_blank">
                                            <img
                                              style={{
                                                display: "block",
                                                width: "30px",
                                                height: "30px",
                                                margin: "0",
                                                padding: "0",
                                                border: "0px",
                                              }}
                                              src="https://s3.amazonaws.com/media-dev.salvex.com/emails/social-icons/LinkedIn_default.png"
                                              alt="linkedin"
                                            />
                                          </a>
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>

                          <tr>
                            <td class="xs-p-15" style={{ padding: "15px 32px" }}>
                              <table width="100%" cellspacing="0" cellpadding="0">
                                <tr>
                                  <td
                                    style={{
                                      fontSize: "14px",
                                      lineHeight: "1.5",
                                      fontWeight: "400",
                                      paddingTop: "5px",
                                    }}
                                  >
                                    {!isNoContentLoaded ? (
                                      <span>
                                        Salvex (
                                        <a href="https://www.salvex.com" target="_blank" className="link-item">
                                          www.salvex.com
                                        </a>
                                        ) is collecting bids for the following item being sold from an insurance claim,
                                        bankruptcy or asset recovery. Please register on our site for the types of
                                        commercial items you are interested in and we will notify you when they become
                                        available. There is no cost to register and you can view the products available
                                        at anytime.
                                      </span>
                                    ) : (
                                      [1, 2, 3, 4, 5, 5, 3, 3, 2].map((ue) => <Skeleton height="60px" />)
                                    )}
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>

                          {!isNoContentLoaded ? (
                            <>
                              <tr>
                                <td
                                  class="xs-p-15"
                                  style={{
                                    padding: "8px 32px",
                                    backgroundColor: "#47ad1d",
                                    color: "#fff",
                                  }}
                                >
                                  <table width="100%" cellspacing="0" cellpadding="0">
                                    <tr>
                                      <td
                                        style={{
                                          fontSize: "18px",
                                          lineHeight: "1.6",
                                          fontWeight: "700",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => window.open(siteUrl + `listing-detail?id=${data?.auction_id}`)}
                                      >
                                        {`${data?.auction_id} - ${data?.auction?.title}`}
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>

                              <tr>
                                <td class="xs-p-15" style={{ padding: "20px 32px", paddingBottom: "0px" }}>
                                  <table cellspacing="0" cellpadding="0" style={{ minWidth: "100%" }}>
                                    <tbody>
                                      <tr>
                                        <td style={{ paddingBottom: "15px" }}>
                                          <table style={{ width: "100%" }} cellspacing="0" cellpadding="0">
                                            {" "}
                                            <tbody>
                                              <tr>
                                                <td
                                                  style={{
                                                    padding: "15px",
                                                    border: "1px solid black",
                                                    borderRadius: "15px",
                                                  }}
                                                >
                                                  {" "}
                                                  <table style={{ width: "100%" }} cellspacing="0" cellpadding="0">
                                                    <tbody>
                                                      <tr>
                                                        <td
                                                          style={{
                                                            fontSize: "16px",
                                                            lineHeight: "1.2",
                                                            fontWeight: "700",
                                                            paddingBottom: "12px",
                                                            display: "flex",
                                                            alignItems: "start",
                                                            justifyContent: "start",
                                                          }}
                                                        >
                                                          <table style={{ width: "100%" }}>
                                                            <tr>
                                                              <td>
                                                                <table style={{ width: "100%" }}>
                                                                  <tr>
                                                                    <td style={{ display: "block", width: "90%" }}>
                                                                      <table style={{ width: "100%" }}>
                                                                        <tr>
                                                                          <td
                                                                            style={{
                                                                              width: "100%",
                                                                              verticalAlign: "top",
                                                                              fontWeight: "500",
                                                                            }}
                                                                            className="ck-content ipr_table"
                                                                          >
                                                                            <span style={{ fontSize: "14px" }}>
                                                                              Description
                                                                            </span>{" "}
                                                                            :
                                                                            <br />
                                                                            <span style={{ fontWeight: "500" }}>
                                                                              {data?.auction?.details
                                                                                ? HTMLReactParser(
                                                                                    data?.auction?.details,
                                                                                  )
                                                                                : ""}
                                                                            </span>
                                                                          </td>
                                                                        </tr>
                                                                        {data?.auction?.quantity ? (
                                                                          <tr>
                                                                            <td
                                                                              style={{
                                                                                fontSize: "14px",
                                                                                paddingTop: "12px",
                                                                              }}
                                                                            >
                                                                              <span style={{ fontWeight: "500" }}>
                                                                                Quantity
                                                                              </span>{" "}
                                                                              :
                                                                              <span
                                                                                style={{
                                                                                  paddingLeft: "6px",
                                                                                  fontWeight: "600",
                                                                                }}
                                                                              >
                                                                                {data?.auction?.quantity
                                                                                  ? `${data?.auction?.quantity}`
                                                                                  : "Missing"}{" "}
                                                                                {data?.auction?.unit
                                                                                  ? data?.auction?.unit
                                                                                  : "Units"}
                                                                              </span>
                                                                            </td>
                                                                          </tr>
                                                                        ) : (
                                                                          ""
                                                                        )}

                                                                        {/* <tr>
                                                                      <td
                                                                        style={{
                                                                          fontSize: "14px",
                                                                          paddingTop: "12px",
                                                                        }}
                                                                      >
                                                                        <span>Asking Price</span> :
                                                                        <span
                                                                          style={{
                                                                            fontWeight: "600",
                                                                            paddingLeft: "6px",
                                                                            fontSize: "20px",
                                                                            color: data?.auction?.asking_price
                                                                              ? ""
                                                                              : "red",
                                                                          }}
                                                                        >
                                                                          {data?.auction?.asking_price
                                                                            ? "$" +
                                                                              numberFormat(data?.auction?.asking_price)
                                                                            : "Missing"}
                                                                        </span>
                                                                      </td>
                                                                    </tr>
                                                                    <tr>
                                                                      <td
                                                                        style={{
                                                                          fontSize: "14px",
                                                                          paddingTop: "12px",
                                                                        }}
                                                                      >
                                                                        <span>Original wholesale Value</span> :
                                                                        <span
                                                                          style={{
                                                                            fontWeight: "600",
                                                                            paddingLeft: "6px",
                                                                            fontSize: "20px",
                                                                            color: data?.auction?.wholesale_value
                                                                              ? ""
                                                                              : "red",
                                                                          }}
                                                                        >
                                                                          {data?.auction?.wholesale_value
                                                                            ? "$" +
                                                                              numberFormat(
                                                                                data?.auction?.wholesale_value,
                                                                              )
                                                                            : "Missing"}
                                                                        </span>
                                                                      </td>
                                                                    </tr> */}
                                                                        {data?.auction?.location ? (
                                                                          <tr>
                                                                            <td
                                                                              style={{
                                                                                fontSize: "14px",
                                                                                paddingTop: "12px",
                                                                              }}
                                                                            >
                                                                              <span style={{ fontWeight: "500" }}>
                                                                                Location
                                                                              </span>{" "}
                                                                              :
                                                                              <span
                                                                                style={{
                                                                                  paddingLeft: "6px",
                                                                                  fontWeight: "600",
                                                                                }}
                                                                              >
                                                                                {data?.auction?.location}
                                                                              </span>
                                                                            </td>
                                                                          </tr>
                                                                        ) : (
                                                                          ""
                                                                        )}
                                                                        <tr>
                                                                          <td
                                                                            style={{
                                                                              fontSize: "14px",
                                                                              paddingTop: "12px",
                                                                            }}
                                                                          >
                                                                            <span style={{ fontWeight: "500" }}>
                                                                              Scheduled End Time
                                                                            </span>{" "}
                                                                            :
                                                                            <span
                                                                              style={{
                                                                                paddingLeft: "6px",
                                                                                fontWeight: "600",
                                                                              }}
                                                                            >
                                                                              {scheduledEndTime(data?.auction)}
                                                                            </span>
                                                                          </td>
                                                                        </tr>
                                                                        <tr>
                                                                          <td
                                                                            style={{
                                                                              fontSize: "14px",
                                                                              paddingTop: "12px",
                                                                            }}
                                                                          >
                                                                            <span style={{ fontWeight: "500" }}>
                                                                              Scheduled Time Remaining
                                                                            </span>{" "}
                                                                            :
                                                                            <span
                                                                              style={{
                                                                                fontWeight: "600",
                                                                                paddingLeft: "6px",
                                                                              }}
                                                                            >
                                                                              {scheduledTimeRemaining(data?.auction)}
                                                                            </span>
                                                                          </td>
                                                                        </tr>
                                                                        {data?.auction?.condition ||
                                                                        data?.auction?.conditionType ? (
                                                                          <tr>
                                                                            <td
                                                                              style={{
                                                                                fontSize: "14px",
                                                                                paddingTop: "12px",
                                                                              }}
                                                                            >
                                                                              <span style={{ fontWeight: "500" }}>
                                                                                Condition
                                                                              </span>{" "}
                                                                              :
                                                                              <span
                                                                                style={{
                                                                                  paddingLeft: "6px",
                                                                                  fontWeight: "600",
                                                                                }}
                                                                              >
                                                                                {data?.auction?.conditionType
                                                                                  ? data?.auction?.conditionType
                                                                                  : ""}
                                                                                {data?.auction?.conditionType ? (
                                                                                  <br />
                                                                                ) : (
                                                                                  ""
                                                                                )}

                                                                                <span
                                                                                  style={{
                                                                                    marginLeft: data?.auction
                                                                                      ?.conditionType
                                                                                      ? "77px"
                                                                                      : "0px",
                                                                                    display: "inline-block",
                                                                                    fontWeight: "500",
                                                                                  }}
                                                                                >
                                                                                  {" "}
                                                                                  {data?.auction?.condition
                                                                                    ? HTMLReactParser(
                                                                                        data?.auction?.condition,
                                                                                      )
                                                                                    : ""}
                                                                                </span>
                                                                              </span>
                                                                            </td>
                                                                          </tr>
                                                                        ) : (
                                                                          ""
                                                                        )}
                                                                      </table>
                                                                    </td>

                                                                    <td
                                                                      style={{
                                                                        textAlign: "-webkit-right",
                                                                        verticalAlign: "top",
                                                                        width: "35%",
                                                                      }}
                                                                    >
                                                                      <table style={{ width: "100%" }}>
                                                                        <tr>
                                                                          <td>
                                                                            <table
                                                                              style={{
                                                                                width: "100%",
                                                                              }}
                                                                              cellspacing="0"
                                                                              cellpadding="0"
                                                                            >
                                                                              <tr>
                                                                                <td
                                                                                  className="ps-map"
                                                                                  style={{
                                                                                    padding: "16px 12px",
                                                                                  }}
                                                                                >
                                                                                  <img
                                                                                    src={data?.auction?.listing_photo}
                                                                                  />
                                                                                </td>
                                                                              </tr>
                                                                            </table>
                                                                          </td>
                                                                        </tr>
                                                                      </table>{" "}
                                                                    </td>
                                                                  </tr>
                                                                </table>
                                                              </td>
                                                            </tr>
                                                          </table>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </>
                          ) : (
                            ""
                          )}

                          {!isNoContentLoaded ? (
                            <>
                              <tr>
                                <td style={{ padding: "0 32px 20px" }}>
                                  <table
                                    style={{
                                      width: "100%",
                                      paddingBottom: "15px",
                                    }}
                                    cellspacing="0"
                                    cellpadding="0"
                                  >
                                    <tr>
                                      <td
                                        style={{
                                          fontSize: "14px",
                                          lineHeight: "1.5",
                                          paddingTop: "10px",
                                          paddingBottom: "10px",
                                        }}
                                      >
                                        Regards,
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        style={{
                                          fontSize: "14px",
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {data?.name01}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        style={{
                                          fontSize: "14px",
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        Salvex Group, Inc.
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        style={{
                                          fontSize: "14px",
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        440 Louisiana St. Ste. 550
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        style={{
                                          fontSize: "14px",
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        Houston, TX 77002
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        style={{
                                          fontSize: "14px",
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        <a href={`tel:${data?.phone}`} target="_blank" className="link-item">
                                          {data?.phone}
                                        </a>{" "}
                                        - Phone
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        style={{
                                          fontSize: "14px",
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        <a href={`tel:973-556-1328`} target="_blank">
                                          973-556-1328
                                        </a>{" "}
                                        - Fax
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        style={{
                                          fontSize: "14px",
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        <a href={`mailto:${data?.mm_email}`} target="_blank" className="link-item">
                                          {" "}
                                          {data?.mm_email}
                                        </a>{" "}
                                        - E-mail
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        style={{
                                          fontSize: "14px",
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        <a href="www.salvex.com" target="_blank" className="link-item">
                                          www.salvex.com
                                        </a>{" "}
                                        - WebSite
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </>
                          ) : (
                            ""
                          )}

                          {!isNoContentLoaded ? (
                            <>
                              <tr>
                                <td class="xs-p-15" style={{ padding: "0px 32px" }}>
                                  <table style={{ width: "100%" }} cellspacing="0" cellpadding="0"></table>
                                </td>
                              </tr>
                              <tr>
                                <td style={{ padding: "0 32px 0px" }}>
                                  <table
                                    style={{
                                      width: "100%",
                                      borderTop: "1px solid #e4e4e4",
                                      paddingTop: "15px",
                                    }}
                                    cellspacing="0"
                                    cellpadding="0"
                                  >
                                    <tr>
                                      <td
                                        style={{
                                          fontSize: "14px",
                                          lineHeight: "1.5",
                                          paddingTop: "15px",
                                          paddingBottom: "15px",
                                        }}
                                      >
                                        This message is intended only for the use of the individual to whom it is
                                        addressed and contains information that is privileged, confidential and exempt
                                        from disclosure under applicable law. If you are not the intended recipient, or
                                        the employee or agent responsible for delivering this message to the intended
                                        recipient, you are hereby notified that any unauthorized disclosure,
                                        dissemination, distribution or copying of this communication is strictly
                                        prohibited. If you have received this communication in error, or are not sure
                                        whether it is privileged, please immediately notify us. Thank you.
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td style={{ padding: "0 32px 30px" }}>
                                  <table
                                    style={{
                                      width: "100%",
                                      borderTop: "1px solid #e4e4e4",
                                      paddingTop: "15px",
                                    }}
                                    cellspacing="0"
                                    cellpadding="0"
                                  >
                                    <tr>
                                      <td style={{ fontSize: "14px", lineHeight: "1.5", paddingTop: "15px" }}>
                                        Questions? Contact us at{" "}
                                        <a
                                          style={{
                                            fontWeight: "500",
                                            color: "#47ad1d",
                                            textDecoration: "underline",
                                          }}
                                          href="mailto:sales@salvex.com"
                                        >
                                          sales@salvex.com
                                        </a>
                                        , or call us at{" "}
                                        <a
                                          style={{
                                            fontWeight: "500",
                                            color: "#47ad1d",
                                            textDecoration: "underline",
                                          }}
                                          href="tel:+17132299000"
                                        >
                                          +1 713.229.9000
                                        </a>
                                        <br />
                                        440 Louisiana Street, Suite 550, Houston, TX 77002
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <a
                                          style={{
                                            fontWeight: "500",
                                            color: "#47ad1d",
                                            textDecoration: "underline",
                                          }}
                                          href={
                                            process.env.REACT_APP_MODE === "development"
                                              ? "https://newdev.salvex.com/terms-and-conditions"
                                              : "https://salvex.com/terms-and-conditions"
                                          }
                                          target="_blank"
                                        >
                                          Click here
                                        </a>{" "}
                                        to visit salvex terms and conditions.
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </>
                          ) : (
                            ""
                          )}
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </>
        ) : (
          <tr>
            <td class="xs-p-0" align="center" valign="top" style={{ padding: "5px 0" }}>
              <table
                class="w-full-mobile"
                cellspacing="0"
                cellpadding="0"
                style={{ width: "800px", minHeight: "100vh", background: "#fff" }}
              >
                <tbody>
                  <tr>
                    <td align="center" valign="top" style={{ verticalAlign: "middle" }}>
                      <table style={{ width: "100%" }} cellspacing="0" cellpadding="0">
                        <tr>
                          <td class="xs-p-15" style={{ padding: "15px 32px" }}>
                            <table width="100%" cellspacing="0" cellpadding="0">
                              <tr>
                                <td style={{ textAlign: "center", paddingBottom: "5px" }}>
                                  <h6>Success!</h6>
                                </td>
                              </tr>
                              <tr>
                                <td style={{ height: "60px", width: "60px", textAlign: "center" }}>
                                  {" "}
                                  <img src={successIcon} alt="Not Found" style={{ height: "100%" }} />
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    fontSize: "20px",
                                    lineHeight: "1.6",
                                    fontWeight: "700",
                                    textAlign: "center",

                                    paddingTop: "40px",
                                  }}
                                >
                                  {emailList?.length > 1 ? (
                                    <>
                                      The emails were successfully sent, <br />
                                      and the users have been created in our system.
                                    </>
                                  ) : (
                                    <>
                                      The email was successfully sent, <br />
                                      and the user has been created in our system.
                                    </>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    fontWeight: "500",
                                    fontSize: "18px",
                                    lineHeight: "1.6",
                                    textAlign: "center",
                                    paddingTop: "40px",
                                  }}
                                >
                                  Please click the email {emailList?.length > 1 ? "links" : "link"} below to edit the
                                  details:
                                </td>
                              </tr>
                              {emailList?.map((ds) => (
                                <tr>
                                  <td
                                    style={{
                                      fontWeight: "500",
                                      fontSize: "18px",
                                      lineHeight: "1.6",
                                      textAlign: "center",
                                      textDecoration: "underline",
                                      color: "#47AD1D",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => fetchProfileOptions(ds?.user_id)}
                                  >
                                    {ds?.email}
                                  </td>
                                </tr>
                              ))}
                            </table>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        )}
      </table>
      <AddUserModal
        show={showAddUserModal}
        onHide={toggleAddUserModal}
        userData={userData}
        setUserData={setUserData}
        industryList={industryList}
        countryList={countryList}
        stateList={stateList}
        // handleAddUser={handleAddUser}
        userTypeOptions={userTypeOptions}
        buyer={buyer}
        setBuyer={setBuyer}
        setUserId={setUserId}
        toggleCategoryPreferenceModal={toggleCategoryPreferenceModal}
        loading={loading}
        edit={true}
        userId={userId}
      />
      <CategoryPreferenceModal
        show={showCategoryPreferenceModal}
        onHide={closedCategoryPreference}
        categoryData={categoryData}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        addCategoryPreference={props.addCategoryPreference}
        userId={userId}
        buyer={buyer}
        setBuyer={setBuyer}
        setSelectedId={setSelectedId}
        userData={props.userData}
        toggleShowRegion={toggleShowRegion}
        categoryLoading={categoryLoading}
      />
      <RegionPreferences
        show={showRegionPref}
        onHide={toggleShowRegion}
        data={regionPrefData}
        selectedId={userId}
        selectedData={selectedRegion}
        setSelectedRegion={setSelectedRegion}
        selectedRegion={selectedRegion}
        loading={loadingRegion}
        setLoading={setLoadingRegion}
        loadingSkeleton={loading}
        setRegionPrefData={setRegionPrefData}
        buyer={buyer}
        setBuyer={setBuyer}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  primarySellerData: primarySellerData(state)?.individualPush,
  getListings: getListings(state),
  getUser: getUser(state),
  getPreferences: getPreferences(state),
});

const mapDispatchToProps = (dispatch) => ({
  getIndividualPushData: (id, callback) => dispatch(getIndividualPushData(id, callback)),
  sendIndividualPush: (id, data, callback) => dispatch(sendIndividualPush(id, data, callback)),

  //USER DATA

  getProfileOptions: (params, callback) => dispatch(getProfileOptions(params, callback)),
  getIndustry: (callback) => dispatch(getIndustry(callback)),
  getCountry: (callback) => dispatch(getCountry(callback)),
  getState: (callback) => dispatch(getState(callback)),
  getUserList: (params, callback) => dispatch(getUserList(params, callback)),
  getCategories: (callback) => dispatch(getCategories(callback)),
  getCategoryPreference: (params, callback) => dispatch(getCategoryPreference(params, callback)),
  addCategoryPreference: (params, data, callback) => dispatch(addCategoryPreference(params, data, callback)),
  getRegionPref: (callback) => dispatch(getRegionPreference(callback)),
  getSelectedRegion: (id, callback) => dispatch(getSelectedRegion(id, callback)),
  updateRegionPreference: (id, data, callback) => dispatch(updateRegionPreference(id, data, callback)),
  getCategoryPreferenacesDropdown: (id, callback) => dispatch(getCategoryPreferenacesDropdown(id, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(BuyerARR));
