import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getExclusivity, getListingProductDetail, updateExclusivity } from "../../../store/listingProduct";
import ExclusivityModal from "../../../layouts/modals/exclusivityModal";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import AlertError from "../../../common/alerts/alertError";
import { twoDecimals } from "../../../utils.js/conversion";

const Exclusivity = (props) => {
  const [showExclusivityModal, setShowExclusivityModal] = useState(false);
  const [loadingExclusivity, setLoadingExclusivity] = useState(false);
  const [values, setValues] = useState({
    exclusive: false,
    comm_seller: 0,
    comm_buyer: 0,
    public_bidding: false,
    seller_can_see_names: false,
    override_bid: false,
    showbid: false,
  });
  const [show, setShow] = useState(false);
  //Show Exclusivity
  const showExclusivity = () => {
    props.refStart();
    props.getExclusivity(props.auctionId, (res) => {
      if (res.status === 200) {
        props.refStop();
        setShow(true);
      } else {
        props.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const toggleExclusivityModal = () => {
    setShowExclusivityModal(!showExclusivityModal);
  };

  const handleSubmit = () => {
    setLoadingExclusivity(true);
    props.refStart();
    const id = props.auctionId;
    props.updateExclusivity(id, values, (res) => {
      if (res.status === 200) {
        const details = { details: values };
        props.getExclusivity(id, (res) => {
          if (res.status === 200) {
            setLoadingExclusivity(false);
            props.refStop();
            toast(<AlertSuccess message={"Information Saved"} />);
            toggleExclusivityModal();
          } else {
            setLoadingExclusivity(false);
            props.refStop();
            toast(
              <AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />,
            );
          }
        });
      } else {
        setLoadingExclusivity(false);
        props.refStop();
        toast(<AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const details =
    (props.getListingProductDetail &&
      props.getListingProductDetail.exclusivity &&
      props.getListingProductDetail.exclusivity.data) ||
    {};

  useEffect(() => {
    if (showExclusivityModal) {
      const data = {};
      data.exclusive = details.exclusive ? details.exclusive : false;
      data.comm_seller = details.comm_seller ? parseFloat(details.comm_seller * 100).toFixed(2) : 0;
      data.comm_buyer = details.comm_buyer ? parseFloat(details.comm_buyer * 100).toFixed(2) : 0;
      data.public_bidding = details.public_bidding ? details.public_bidding : false;
      data.seller_can_see_names = details.seller_can_see_names ? details.seller_can_see_names : false;
      data.override_bid = details.override_bid ? details.override_bid : false;
      data.showbid = details.showbid ? details.showbid : false;
      setValues({ ...data });
    }
  }, [showExclusivityModal]);
  return (
    <div class="details-page-widget-block" id={props.id}>
      <div class="page-widget-head d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <div
            class="page-widget-label text-capitalize cursor-pointer"
            onClick={() => (show ? toggleExclusivityModal() : showExclusivity())}
          >
            Exclusivity
          </div>
          <li
            className="badge-item cursor-pointer"
            onClick={() => (show ? setShow(false) : showExclusivity())}
            style={{ marginLeft: "10px" }}
          >
            <div className="badge-box" style={{ background: "#DADEE0" }}>
              {show ? "Hide" : "Show"}
            </div>
          </li>
        </div>
        {/* <div class="btn-block d-flex align-items-center">
          {props.loadingMain ? (
            <Skeleton height="30px" width="90px" />
          ) : (
            <a href="javascript:void(0)" onClick={() => toggleExclusivityModal()} class="btn btn-primary btn-sm">
              Edit
            </a>
          )}
        </div> */}
      </div>
      {show && (
        <div class="page-widget-body mt-3">
          <div class="table-responsive">
            <table class="table mb-0  product-table">
              <colgroup>
                <col style={{ width: "35%" }}></col>
              </colgroup>
              <tbody>
                {props.loadingMain ? (
                  <>
                    <tr>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                    </tr>
                  </>
                ) : (
                  <>
                    <tr>
                      <td>Exclusive</td>
                      <td>{details.exclusive ? "Yes" : "No"}</td>
                    </tr>
                    <tr>
                      <td>Override Seller Bids</td>
                      <td>{details.override_bid ? "Yes" : "No"}</td>
                    </tr>
                    <tr>
                      <td>Seller Commision</td>
                      <td>{details.comm_seller ? twoDecimals(details.comm_seller * 100) + "%" : "0.00%"}</td>
                    </tr>
                    <tr>
                      <td>Buyer Premium</td>
                      <td>{details.comm_buyer ? twoDecimals(details.comm_buyer * 100) + "%" : "0.00%"}</td>
                    </tr>
                    <tr>
                      <td>Public Bidding</td>
                      <td>{details.public_bidding ? "Yes" : "No"}</td>
                    </tr>
                    <tr>
                      <td>Seller Can See Names</td>
                      <td>{details.seller_can_see_names ? "Yes" : "No"}</td>
                    </tr>
                    <tr>
                      <td>Show High Bid and Graph?</td>
                      <td>{details.showbid ? "Yes" : "No"}</td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}
      <ExclusivityModal
        show={showExclusivityModal}
        onHide={toggleExclusivityModal}
        setValues={setValues}
        values={values}
        loadingExclusivity={loadingExclusivity}
        handleSubmit={handleSubmit}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  //global state for storing api values
  updateExclusivity: (params, data, callback) => dispatch(updateExclusivity(params, data, callback)),

  //global state managed
  getExclusivity: (params, callback) => dispatch(getExclusivity(params, callback)),
});

const mapStateToProps = (state) => ({
  getListingProductDetail: getListingProductDetail(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Exclusivity));
