import React from "react";
import { Offcanvas } from "react-bootstrap";
import Select from "react-select";
import { customStyles } from "../../../common/customStyles/reactSelectStyle";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const CommissionFilter = (props) => {
  return (
    <Offcanvas
      show={props.show}
      onHide={() => props.onHide()}
      placement="end"
      className="offcanvas offcanvas-end custom-offcanvas-sm "
      backdropClassName="upload-images-backdrop"
      tabindex="-1"
      id="userFilter"
      enforceFocus={false}
    >
      <Offcanvas.Header className="offcanvas-header ">
        <h5 class="offcanvas-title">Filters</h5>
        <button type="button" className="btn-close text-reset" onClick={() => props.onHide()}></button>
      </Offcanvas.Header>
      <Offcanvas.Body className="offcanvas-body co-body">
        <div class="form-group">
          <label for="keyword" class="form-label">
            Select Employee
          </label>
          <Select
            value={props.filteredValues?.m_id}
            options={[{ name: "Select Employee", value: "0" }, ...props.employeeData]}
            className="basic-single country-margin select-background "
            classNamePrefix="select-search country-margin select-background"
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.value}
            onChange={(e) => {
              const data = { ...props.filteredValues };
              data.m_id = e;
              props.setFilteredValues(data);
            }}
            name="planePart"
            placeholder="Select Employee"
            styles={customStyles}
          />
        </div>
        <div class="form-group">
          <label for="keyword" class="form-label">
            Start Date
          </label>
          <DatePicker
            className="form-control"
            selected={props.filteredValues?.startDate}
            onChange={(date) => {
              const data = { ...props.filteredValues };
              data.startDate = date;
              props.setFilteredValues(data);
            }}
          />
        </div>
        <div class="form-group">
          <label for="keyword" class="form-label">
            End Data
          </label>
          <DatePicker
            className="form-control"
            selected={props.filteredValues?.endDate}
            onChange={(date) => {
              const data = { ...props.filteredValues };
              data.endDate = date;
              props.setFilteredValues(data);
            }}
          />
        </div>
      </Offcanvas.Body>
      <div class="offcanvas-footer">
        <a
          href="javascript:void(0)"
          class={`btn btn-default btn-block ${props.loadingFilter && "disabled"}`}
          onClick={() => props.handleFilter()}
        >
          Apply Filters
        </a>
      </div>
    </Offcanvas>
  );
};

export default React.memo(CommissionFilter);
