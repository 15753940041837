import { createSelector, createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";

const slice = createSlice({
  name: "dashBoard",
  initialState: {
    loading: false,
    employees: {},
    listingCount: {},
    allListings: {},
    readyToClose: {},
    bigDeal: {},
    activeListing: {},
    pendingListing: {},
    closedListing: {},
    invoicedListing: {},
    unAssignedListing: {},
    readyToPush: {},
    archivedListing: {},
    newestHighBid: {},
    taskAndCount: {},
    pendingResearchAssignment: {},
    completedResearchAssignment: {},
    accountManagement: {},
    customers: {},
    allOtherListings: {},
    neglectedOrUrgent: {},
    buAssignment: {},
    enterpriseAssignment: {},
    sellerAssignment: {},

    enterpriseType: {},
    industry: {},
    enterprise: {},
    buRegion: {},
    myActivity: {},
    empDetail: {},
    empChart: {},
    auctionDetail: {},
    tasks: {},
    categoryData: {},
    motivaiton: {},
    pricing: {},
    googleAnalytics: {},
    listingCampaign: {},
    campaignAggregate: {},
    listingAgreement: {},
    mainCategories: {},
    regions: {},
    buyerCategoryData: {}
  },
  reducers: {
    employeesRequested: (state, action) => {
      state.loading = true;
    },
    employeesRecieved: (state, action) => {
      state.employees = action.payload;
      state.loading = false;
    },
    employeesFailed: (state, action) => {
      state.loading = false;
    },
    allListingsRequested: (state, action) => {
      state.loading = true;
    },
    allListingsRecieved: (state, action) => {
      state.allListings = action.payload;
      state.loading = false;
    },
    allListingsFailed: (state, action) => {
      state.loading = false;
    },
    readyToCloseRequested: (state, action) => {
      state.loading = true;
    },
    readyToCloseRecieved: (state, action) => {
      state.readyToClose = action.payload;
      state.loading = false;
    },
    readyToCloseFailed: (state, action) => {
      state.loading = false;
    },
    bigDealRequested: (state, action) => {
      state.loading = true;
    },
    bigDealRecieved: (state, action) => {
      state.bigDeal = action.payload;
      state.loading = false;
    },
    bigDealFailed: (state, action) => {
      state.loading = false;
    },
    activeListingRequested: (state, action) => {
      state.loading = true;
    },
    activeListingRecieved: (state, action) => {
      state.activeListing = action.payload;
      state.loading = false;
    },
    activeListingFailed: (state, action) => {
      state.loading = false;
    },
    pendingListingRequested: (state, action) => {
      state.loading = true;
    },
    pendingListingRecieved: (state, action) => {
      state.pendingListing = action.payload;
      state.loading = false;
    },
    pendingListingFailed: (state, action) => {
      state.loading = false;
    },
    closedListingRequested: (state, action) => {
      state.loading = true;
    },
    closedListingRecieved: (state, action) => {
      state.closedListing = action.payload;
      state.loading = false;
    },
    closedListingFailed: (state, action) => {
      state.loading = false;
    },
    invoicedListingRequested: (state, action) => {
      state.loading = true;
    },
    invoicedListingRecieved: (state, action) => {
      state.invoicedListing = action.payload;
      state.loading = false;
    },
    invoicedListingFailed: (state, action) => {
      state.loading = false;
    },
    unAssignedListingRequested: (state, action) => {
      state.loading = true;
    },
    unAssignedListingRecieved: (state, action) => {
      state.unAssignedListing = action.payload;
      state.loading = false;
    },
    unAssignedListingFailed: (state, action) => {
      state.loading = false;
    },
    readyToPushRequested: (state, action) => {
      state.loading = true;
    },
    readyToPushRecieved: (state, action) => {
      state.readyToPush = action.payload;
      state.loading = false;
    },
    readyToPushFailed: (state, action) => {
      state.loading = false;
    },
    archivedListingRequested: (state, action) => {
      state.loading = true;
    },
    archivedListingRecieved: (state, action) => {
      state.archivedListing = action.payload;
      state.loading = false;
    },
    archivedListingFailed: (state, action) => {
      state.loading = false;
    },
    newestHighBidRequested: (state, action) => {
      state.loading = true;
    },
    newestHighBidRecieved: (state, action) => {
      state.newestHighBid = action.payload;
      state.loading = false;
    },
    newestHighBidFailed: (state, action) => {
      state.loading = false;
    },
    listingCountRequested: (state, action) => {
      state.loading = true;
    },
    listingCountRecieved: (state, action) => {
      state.listingCount = action.payload;
      state.loading = false;
    },
    listingCountFailed: (state, action) => {
      state.loading = false;
    },
    taskAndCountRequested: (state, action) => {
      state.loading = true;
    },
    taskAndCountRecieved: (state, action) => {
      state.taskAndCount = action.payload;
      state.loading = false;
    },
    taskAndCountFailed: (state, action) => {
      state.loading = false;
    },
    pendingResearchAssignmentRequested: (state, action) => {
      state.loading = true;
    },
    pendingResearchAssignmentRecieved: (state, action) => {
      state.pendingResearchAssignment = action.payload;
      state.loading = false;
    },
    pendingResearchAssignmentFailed: (state, action) => {
      state.loading = false;
    },
    completedResearchAssignmentRequested: (state, action) => {
      state.loading = true;
    },
    completedResearchAssignmentRecieved: (state, action) => {
      state.completedResearchAssignment = action.payload;
      state.loading = false;
    },
    completedResearchAssignmentFailed: (state, action) => {
      state.loading = false;
    },
    accountManagementRequested: (state, action) => {
      state.loading = true;
    },
    accountManagementRecieved: (state, action) => {
      state.accountManagement = action.payload;
      state.loading = false;
    },
    accountManagementFailed: (state, action) => {
      state.loading = false;
    },
    customersRequested: (state, action) => {
      state.loading = true;
    },
    customersRecieved: (state, action) => {
      state.customers = action.payload;
      state.loading = false;
    },
    customersFailed: (state, action) => {
      state.loading = false;
    },
    allOtherListingsRequested: (state, action) => {
      state.loading = true;
    },
    allOtherListingsRecieved: (state, action) => {
      state.allOtherListings = action.payload;
      state.loading = false;
    },
    allOtherListingsFailed: (state, action) => {
      state.loading = false;
    },
    neglectedOrUrgentRequested: (state, action) => {
      state.loading = true;
    },
    neglectedOrUrgentRecieved: (state, action) => {
      state.neglectedOrUrgent = action.payload;
      state.loading = false;
    },
    neglectedOrUrgentFailed: (state, action) => {
      state.loading = false;
    },
    buAssignmentRequested: (state, action) => {
      state.loading = true;
    },
    buAssignmentRecieved: (state, action) => {
      state.buAssignment = action.payload;
      state.loading = false;
    },
    buAssignmentFailed: (state, action) => {
      state.loading = false;
    },
    enterpriseAssignmentRequested: (state, action) => {
      state.loading = true;
    },
    enterpriseAssignmentRecieved: (state, action) => {
      state.enterpriseAssignment = action.payload;
      state.loading = false;
    },
    enterpriseAssignmentFailed: (state, action) => {
      state.loading = false;
    },
    sellerAssignmentRequested: (state, action) => {
      state.loading = true;
    },
    sellerAssignmentRecieved: (state, action) => {
      state.sellerAssignment = action.payload;
      state.loading = false;
    },
    sellerAssignmentFailed: (state, action) => {
      state.loading = false;
    },

    enterpriseTypeRequested: (state, action) => {
      state.loading = true;
    },
    enterpriseTypeRecieved: (state, action) => {
      state.enterpriseType = action.payload;
      state.loading = false;
    },
    enterpriseTypeFailed: (state, action) => {
      state.loading = false;
    },
    industryRequested: (state, action) => {
      state.loading = true;
    },
    industryRecieved: (state, action) => {
      state.industry = action.payload;
      state.loading = false;
    },
    industryFailed: (state, action) => {
      state.loading = false;
    },
    enterpriseRequested: (state, action) => {
      state.loading = true;
    },
    enterpriseRecieved: (state, action) => {
      state.enterprise = action.payload;
      state.loading = false;
    },
    enterpriseFailed: (state, action) => {
      state.loading = false;
    },
    buRegionRequested: (state, action) => {
      state.loading = true;
    },
    buRegionRecieved: (state, action) => {
      state.buRegion = action.payload;
      state.loading = false;
    },
    buRegionFailed: (state, action) => {
      state.loading = false;
    },
    myActivityRequested: (state, action) => {
      state.loading = true;
    },
    myActivityRecieved: (state, action) => {
      state.myActivity = action.payload;
      state.loading = false;
    },
    myActivityFailed: (state, action) => {
      state.loading = false;
    },
    empDetailRequested: (state, action) => {
      state.loading = true;
    },
    empDetailRecieved: (state, action) => {
      state.empDetail = action.payload;
      state.loading = false;
    },
    empDetailFailed: (state, action) => {
      state.loading = false;
    },
    empChartRequested: (state, action) => {
      state.loading = true;
    },
    empChartRecieved: (state, action) => {
      state.empChart = action.payload;
      state.loading = false;
    },
    empChartFailed: (state, action) => {
      state.loading = false;
    },
    auctionDetailRequested: (state, action) => {
      state.loading = true;
    },
    auctionDetailRecieved: (state, action) => {
      state.auctionDetail = action.payload;
      state.loading = false;
    },
    auctionDetailFailed: (state, action) => {
      state.loading = false;
    },
    tasksRequested: (state, action) => {
      state.loading = true;
    },
    tasksRecieved: (state, action) => {
      state.tasks = action.payload;
      state.loading = false;
    },
    tasksFailed: (state, action) => {
      state.loading = false;
    },
    categoryDataRequested: (state, action) => {
      state.loading = true;
    },
    categoryDataRecieved: (state, action) => {
      state.categoryData = action.payload;
      state.loading = false;
    },
    categoryDataFailed: (state, action) => {
      state.loading = false;
    },
    internalPeopleRequested: (state, action) => {
      state.loading = true;
    },
    internalPeopleRecieved: (state, action) => {
      state.categoryData = action.payload;
      state.loading = false;
    },
    internalPeopleFailed: (state, action) => {
      state.loading = false;
    },
    motivaitonRequested: (state, action) => {
      state.loading = true;
    },
    motivaitonRecieved: (state, action) => {
      state.motivaiton = action.payload;
      state.loading = false;
    },
    motivaitonFailed: (state, action) => {
      state.loading = false;
    },
    pricingRequested: (state, action) => {
      state.loading = true;
    },
    pricingRecieved: (state, action) => {
      state.pricing = action.payload;
      state.loading = false;
    },
    pricingFailed: (state, action) => {
      state.loading = false;
    },
    googleAnalyticsRequested: (state, action) => {
      state.loading = true;
    },
    googleAnalyticsRecieved: (state, action) => {
      state.googleAnalytics = action.payload;
      state.loading = false;
    },
    googleAnalyticsFailed: (state, action) => {
      state.loading = false;
    },
    listingCampaignRequested: (state, action) => {
      state.loading = true;
    },
    listingCampaignRecieved: (state, action) => {
      state.listingCampaign = action.payload;
      state.loading = false;
    },
    listingCampaignFailed: (state, action) => {
      state.loading = false;
    },
    campaignAggregateRequested: (state, action) => {
      state.loading = true;
    },
    campaignAggregateRecieved: (state, action) => {
      state.campaignAggregate = action.payload;
      state.loading = false;
    },
    campaignAggregateFailed: (state, action) => {
      state.loading = false;
    },
    listingAgreementRequested: (state, action) => {
      state.loading = true;
    },
    listingAgreementRecieved: (state, action) => {
      state.listingAgreement = action.payload;
      state.loading = false;
    },
    listingAgreementFailed: (state, action) => {
      state.loading = false;
    },
    mainCategoriesRequested: (state, action) => {
      state.loading = true;
    },
    mainCategoriesRecieved: (state, action) => {
      state.mainCategories = action.payload;
      state.loading = false;
    },
    mainCategoriesFailed: (state, action) => {
      state.loading = false;
    },
    regionsRequested: (state, action) => {
      state.loading = true;
    },
    regionsRecieved: (state, action) => {
      state.regions = action.payload;
      state.loading = false;
    },
    regionsFailed: (state, action) => {
      state.loading = false;
    },
    buyerCategoryDataRequested: (state, action) => {
      state.loading = true;
    },
    buyerCategoryDataRecieved: (state, action) => {
      state.buyerCategoryData = action.payload;
      state.loading = false;
    },
    buyerCategoryDataFailed: (state, action) => {
      state.loading = false;
    },
  },
});

export const {
  employeesRequested,
  employeesRecieved,
  employeesFailed,
  allListingsRequested,
  allListingsRecieved,
  allListingsFailed,
  readyToCloseRequested,
  readyToCloseRecieved,
  readyToCloseFailed,
  bigDealRequested,
  bigDealRecieved,
  bigDealFailed,
  activeListingRequested,
  activeListingRecieved,
  activeListingFailed,
  pendingListingRequested,
  pendingListingRecieved,
  pendingListingFailed,
  closedListingRequested,
  closedListingRecieved,
  closedListingFailed,
  invoicedListingRequested,
  invoicedListingRecieved,
  invoicedListingFailed,
  unAssignedListingRequested,
  unAssignedListingRecieved,
  unAssignedListingFailed,
  readyToPushRequested,
  readyToPushRecieved,
  readyToPushFailed,
  archivedListingRequested,
  archivedListingRecieved,
  archivedListingFailed,
  newestHighBidRequested,
  newestHighBidRecieved,
  newestHighBidFailed,
  listingCountRequested,
  listingCountRecieved,
  listingCountFailed,
  taskAndCountRequested,
  taskAndCountRecieved,
  taskAndCountFailed,
  pendingResearchAssignmentRequested,
  pendingResearchAssignmentRecieved,
  pendingResearchAssignmentFailed,
  completedResearchAssignmentRequested,
  completedResearchAssignmentRecieved,
  completedResearchAssignmentFailed,
  accountManagementRequested,
  accountManagementRecieved,
  accountManagementFailed,
  customersRequested,
  customersRecieved,
  customersFailed,
  allOtherListingsRequested,
  allOtherListingsRecieved,
  allOtherListingsFailed,
  neglectedOrUrgentRequested,
  neglectedOrUrgentRecieved,
  neglectedOrUrgentFailed,
  buAssignmentRequested,
  buAssignmentRecieved,
  buAssignmentFailed,
  enterpriseAssignmentRequested,
  enterpriseAssignmentRecieved,
  enterpriseAssignmentFailed,
  sellerAssignmentRequested,
  sellerAssignmentRecieved,
  sellerAssignmentFailed,

  enterpriseTypeRequested,
  enterpriseTypeRecieved,
  enterpriseTypeFailed,
  industryRequested,
  industryRecieved,
  industryFailed,
  enterpriseRequested,
  enterpriseRecieved,
  enterpriseFailed,
  buRegionRequested,
  buRegionRecieved,
  buRegionFailed,
  myActivityRequested,
  myActivityRecieved,
  myActivityFailed,
  empDetailRequested,
  empDetailRecieved,
  empDetailFailed,
  empChartRequested,
  empChartRecieved,
  empChartFailed,
  auctionDetailRequested,
  auctionDetailRecieved,
  auctionDetailFailed,
  tasksRequested,
  tasksRecieved,
  tasksFailed,
  categoryDataRequested,
  categoryDataRecieved,
  categoryDataFailed,
  internalPeopleRequested,
  internalPeopleRecieved,
  internalPeopleFailed,
  motivaitonRequested,
  motivaitonRecieved,
  motivaitonFailed,
  pricingRequested,
  pricingRecieved,
  pricingFailed,
  googleAnalyticsRequested,
  googleAnalyticsRecieved,
  googleAnalyticsFailed,
  listingCampaignRequested,
  listingCampaignRecieved,
  listingCampaignFailed,
  campaignAggregateRequested,
  campaignAggregateRecieved,
  campaignAggregateFailed,
  listingAgreementRequested,
  listingAgreementRecieved,
  listingAgreementFailed,
  mainCategoriesRequested,
  mainCategoriesRecieved,
  mainCategoriesFailed,
  regionsRequested,
  regionsRecieved,
  regionsFailed,
  buyerCategoryDataRequested,
  buyerCategoryDataRecieved,
  buyerCategoryDataFailed,
} = slice.actions;
export default slice.reducer;

//Action Creator
const baseUrl = "dashboard";

//Get Listing Count
export const getListingCount = (empId, showOnly, pl, pt, sortBy, viewType, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/listing/count?employeeId=${empId}&showOnly=${showOnly}&pl=${pl}&pt=${pt}&sortBy=${sortBy}&viewType=${viewType}&pushYesNo=0&filters=`,
      method: "get",
      callback,
      onStart: listingCountRequested.type,
      onSuccess: listingCountRecieved.type,
      onError: listingCountFailed.type,
    }),
  );
};

//Get Employees
export const getEmployees = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/dropdown/employee`,
      method: "get",
      callback,
      onStart: employeesRequested.type,
      onSuccess: employeesRecieved.type,
      onError: employeesFailed.type,
    }),
  );
};

export const getAllListings = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/listing`,
      params,
      method: "get",
      callback,
      onStart: allListingsRequested.type,
      onSuccess: allListingsRecieved.type,
      onError: allListingsFailed.type,
    }),
  );
};
//Get Sorted All Listings
export const getSortedAllListings = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/listing`,
      params,
      method: "get",
      callback,
    }),
  );
};
//Get Sorted Category Listings
export const getSortedOtherListings = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/listing/other`,
      params,
      method: "get",
      callback,
    }),
  );
};

// url: `${baseUrl}/listing?page=${page}&aType=9&listType=20&employeeId=${employeeId}&showOnly=0&pl=${pl}&pt=${pt}&listingSort=${listingSort}&viewType=${viewType}&sortBy=${sortBy}`,
//Ready to Close
export const getReadyToClose = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/listing`,
      params,
      method: "get",
      callback,
      onStart: readyToCloseRequested.type,
      onSuccess: readyToCloseRecieved.type,
      onError: readyToCloseFailed.type,
    }),
  );
};


//Sorted Ready to Close
export const getSortedReadyToClose = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/listing`,
      params,
      method: "get",
      callback,
    }),
  );
};

// url: `${baseUrl}/listing?page=${page}&aType=9&listType=5&employeeId=${employeeId}&showOnly=0&pl=${pl}&pt=${pt}&listingSort=${listingSort}&viewType=${viewType}&sortBy=${sortBy}`,
//Get BigDeal Listings
export const getBigDeal = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/listing`,
      params,
      method: "get",
      callback,
      onStart: bigDealRequested.type,
      onSuccess: bigDealRecieved.type,
      onError: bigDealFailed.type,
    }),
  );
};

//Get Sorted BigDeal Listings
export const getSortedBigDeal = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/Listing`,
      params,
      method: "get",
      callback,
    }),
  );
};

// url: `${baseUrl}/listing?page=${page}&aType=9&listType=0&employeeId=${employeeId}&showOnly=0&pl=${pl}&pt=${pt}&listingSort=${listingSort}&viewType=${viewType}&sortBy=${sortBy}`,
//Get Active Listings
export const getActiveListings = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/listing`,
      params,
      method: "get",
      callback,
      onStart: activeListingRequested.type,
      onSuccess: activeListingRecieved.type,
      onError: activeListingFailed.type,
    }),
  );
};

// url: `${baseUrl}/listing?page=${page}&aType=9&listType=1&employeeId=${employeeId}&showOnly=0&pl=${pl}&pt=${pt}&listingSort=${listingSort}&viewType=${viewType}&sortBy=${sortBy}`,
//Get Pending Listings
export const getPendingListings = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/listing`,
      params,
      method: "get",
      callback,
      onStart: pendingListingRequested.type,
      onSuccess: pendingListingRecieved.type,
      onError: pendingListingFailed.type,
    }),
  );
};

// url: `${baseUrl}/listing?page=${page}&aType=9&listType=4&employeeId=${employeeId}&showOnly=0&pl=${pl}&pt=${pt}&listingSort=${listingSort}&viewType=${viewType}&sortBy=${sortBy}`,
//Get Closed Listings
export const getClosedListings = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/listing`,
      params,
      method: "get",
      callback,
      onStart: closedListingRequested.type,
      onSuccess: closedListingRecieved.type,
      onError: closedListingFailed.type,
    }),
  );
};

// url: `${baseUrl}/listing?page=${page}&aType=9&listType=2&employeeId=${employeeId}&showOnly=0&pl=${pl}&pt=${pt}&listingSort=${listingSort}&viewType=${viewType}&sortBy=${sortBy}`,
//Get Invoiced Listings
export const getInvoicedListings = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/listing`,
      params,
      method: "get",
      callback,
      onStart: invoicedListingRequested.type,
      onSuccess: invoicedListingRecieved.type,
      onError: invoicedListingFailed.type,
    }),
  );
};

// url: `${baseUrl}/listing?page=${page}&aType=9&listType=7&employeeId=${employeeId}&showOnly=0&pl=${pl}&pt=${pt}&listingSort=${listingSort}&viewType=${viewType}&sortBy=${sortBy}`,
//Get UnAssigned Listings
export const getUnAssignedListings = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/listing`,
      params,
      method: "get",
      callback,
      onStart: unAssignedListingRequested.type,
      onSuccess: unAssignedListingRecieved.type,
      onError: unAssignedListingFailed.type,
    }),
  );
};

// url: `${baseUrl}/listing?page=${page}&aType=9&listType=8&employeeId=${employeeId}&showOnly=0&pl=${pl}&pt=${pt}&listingSort=${listingSort}&viewType=${viewType}&sortBy=${sortBy}`,
//Get Ready To Be Pushed
export const getReadyToPushed = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/listing`,
      params,
      method: "get",
      callback,
      onStart: readyToPushRequested.type,
      onSuccess: readyToPushRecieved.type,
      onError: readyToPushFailed.type,
    }),
  );
};

// url: `${baseUrl}/listing?page=${page}&aType=9&listType=17&employeeId=${employeeId}&showOnly=0&pl=${pl}&pt=${pt}&listingSort=${listingSort}&viewType=${viewType}&sortBy=${sortBy}`,
//Get Archived Listings
export const getArchivedListings = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/listing`,
      params,
      method: "get",
      callback,
      onStart: archivedListingRequested.type,
      onSuccess: archivedListingRecieved.type,
      onError: archivedListingFailed.type,
    }),
  );
};

// url: `${baseUrl}/listing?page=${page}&aType=9&listType=10&employeeId=${employeeId}&showOnly=0&pl=${pl}&pt=${pt}&listingSort=${listingSort}&viewType=${viewType}&sortBy=${sortBy}`,
//Get Newest High Bid
export const getNewestHighBid = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/listing`,
      params,
      method: "get",
      callback,
      onStart: newestHighBidRequested.type,
      onSuccess: newestHighBidRecieved.type,
      onError: newestHighBidFailed.type,
    }),
  );
};

//Get Task and Count
export const getTaskAndCount = (empId, showOnly, pl, pt, sortBy, viewType, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/listing/taskAndCount?employeeId=${empId}&filterByIndustry=0&filterByCategory=0&filterByRegion=&showOnly=${showOnly}&pl=${pl}&pt=${pt}&sortBy=${sortBy}&viewType=${viewType}&pushYesNo=0&filters=`,
      method: "get",
      callback,
      onStart: taskAndCountRequested.type,
      onSuccess: taskAndCountRecieved.type,
      onError: taskAndCountFailed.type,
    }),
  );
};

//Get Pending Research Assignment
export const getPendingResearchAssignment = (empId, pl, pt, page, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/listing/other?aType=35&employeeId=${empId}&showOnly=0&pl=${pl}&pt=${pt}&page=${page}`,
      method: "get",
      callback,
      onStart: pendingResearchAssignmentRequested.type,
      onSuccess: pendingResearchAssignmentRecieved.type,
      onError: pendingResearchAssignmentFailed.type,
    }),
  );
};

//Get Completed Research Assignment
export const getCompletedResearchAssignment = (empId, pl, pt, page, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/listing/other?aType=36&employeeId=${empId}&showOnly=0&pl=${pl}&pt=${pt}&page=${page}`,
      method: "get",
      callback,
      onStart: completedResearchAssignmentRequested.type,
      onSuccess: completedResearchAssignmentRecieved.type,
      onError: completedResearchAssignmentFailed.type,
    }),
  );
};

// Delete Reasearch Assignment
export const deleteResearchAssignment = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/other/researchAssignment/${id}`,
      method: "delete",
      callback,
    }),
  );
};

//Get Account Management
export const getAccountManagement = (empId, type, page, status, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url:
        type == "asset_disposal"
          ? `${baseUrl}/listing/customer?aType=${type}&employeeId=${empId}&page=${page}&buStatus=`
          : type === "client_meetings"
            ? `${baseUrl}/listing/customer?aType=${type}&employeeId=${empId}&page=${page}&status=${status}`
            : type === "inv_sharing"
              ? `${baseUrl}/listing/customer?aType=${type}&employeeId=${empId}&page=${page}&pushType=1`
              : "",
      method: "get",
      callback,
      onStart: accountManagementRequested.type,
      onSuccess: accountManagementRecieved.type,
      onError: accountManagementFailed.type,
    }),
  );
};

//Account Management menu count
export const getAccountManagementCount = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/listing/customer`,
      params,
      method: "get",
      callback
    })
  )
}

//Get Customers
// url: `${baseUrl}/listing/customer?aType=${type}&employeeId=${empId}&page=${page}&filterInterest=${filter}`,
export const getCustomers = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/listing/customer`,
      params,
      method: "get",
      callback,
      onStart: customersRequested.type,
      onSuccess: customersRecieved.type,
      onError: customersFailed.type,
    }),
  );
};

//Get All Other Listings
export const getAllOtherListings = (empId, pl, pt, taskType, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/listing/other?aType=12&taskType=${taskType}&employeeId=${empId}&showOnly=0&pl=${pl}&pt=${pt}&filterByCategory=0`,
      method: "get",
      callback,
      onStart: allOtherListingsRequested.type,
      onSuccess: allOtherListingsRecieved.type,
      onError: allOtherListingsFailed.type,
    }),
  );
};

//Update Multiple Task Status
export const updateMultipleTaskStatus = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/task/status`,
      method: 'put',
      data,
      callback,
    })
  )
}

//Get Neglected / Urgent
export const getNeglectedOrUrgent = (empId, pl, pt, page, listingSort, listType, sortBy, viewType, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/listing?page=${page}&aType=9&listType=${listType}&employeeId=${empId}&showOnly=0&pl=${pl}&pt=${pt}&listingSort=${listingSort}&viewType=${viewType}&sortBy=${sortBy}`,
      method: "get",
      callback,
      onStart: neglectedOrUrgentRequested.type,
      onSuccess: neglectedOrUrgentRecieved.type,
      onError: neglectedOrUrgentFailed.type,
    }),
  );
};

//Unanswered Buyer Question
export const getUnasweredBuyerQuestions = (empId, pl, pt, type, page, listingSort, sortBy, viewType, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/listing/other?aType=7&listType=${type}&employeeId=${empId}&showOnly=0&pl=${pl}&pt=${pt}&listingSort=${listingSort}&page=${page}&viewType=${viewType}&sortBy=${sortBy}`,
      method: "get",
      callback,
      onStart: neglectedOrUrgentRequested.type,
      onSuccess: neglectedOrUrgentRecieved.type,
      onError: neglectedOrUrgentFailed.type,
    }),
  );
};

//Get Business Unit Assignment
// url: `${baseUrl}/listing/customer?aType=business_units&employeeId=${empId}&page=${page}&buStatus=&contractStatus=${contractStatus}&industryId='${industryId}'&publiclyTraded=${publiclyTraded}&searchText=${searchText}&regionId= ${regionId}&companyTypeId=${companyTypeId}&cId=0&eId=${eId}&orderBy=1&order=0&buType&va=0`,
const payload = {
  aType: 'business_units',
  employeeId: "",
  page: '',
  buStatus: '',
  contractStatus: "",
  industryId: "",
  publiclyTraded: "",
  searchText: "",
  regionId: "",
  companyTypeId: "",
  cId: 0,
  eId: '',
  orderBy: 1,
  order: 0,
  buType: '',
  va: 0,
}
export const getBuAssignment =
  (params, callback) =>
    (dispatch) => {
      return dispatch(
        apiCallBegan({
          url: `${baseUrl}/listing/customer`,
          params,
          method: "get",
          callback,
          onStart: buAssignmentRequested.type,
          onSuccess: buAssignmentRecieved.type,
          onError: buAssignmentFailed.type,
        }),
      );
    };

//Get Enterprise Assignment
export const getEnterpriseAssignment =
  (empId, page, contractStatus, industryId, publiclyTraded, searchText, regionId, companyTypeId, callback) =>
    (dispatch) => {
      return dispatch(
        apiCallBegan({
          url: `${baseUrl}/listing/customer?aType=enterprises&employeeId=${empId}&page=${page}&buStatus=&contractStatus=${contractStatus}&industryId=${industryId}&publiclyTraded=${publiclyTraded}&searchText=${searchText}&regionId=${regionId}&companyTypeId=${companyTypeId}`,
          method: "get",
          callback,
          onStart: enterpriseAssignmentRequested.type,
          onSuccess: enterpriseAssignmentRecieved.type,
          onError: enterpriseAssignmentFailed.type,
        }),
      );
    };

//Get Seller Assignments
export const getSellerAssignment = (empId, page, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/listing/customer?aType=my_contracts&employeeId=${empId}&page=${page}&filterInterest=&field=managed_by_s`,
      method: "get",
      callback,
      onStart: sellerAssignmentRequested.type,
      onSuccess: sellerAssignmentRecieved.type,
      onError: sellerAssignmentFailed.type,
    }),
  );
};

//DROPDOWNS

//Enterprise Type
export const getEnterpriseType = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/dropdown/enterpriseType`,
      method: "get",
      callback,
      onStart: enterpriseTypeRequested.type,
      onSuccess: enterpriseTypeRecieved.type,
      onError: enterpriseTypeFailed.type,
    }),
  );
};

//Industry
export const getIndustry = (empId, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/dropdown/industry?employeeId=${empId}`,
      method: "get",
      callback,
      onStart: industryRequested.type,
      onSuccess: industryRecieved.type,
      onError: industryFailed.type,
    }),
  );
};

//Enterprises
export const getEnterprises = (empId, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/dropdown/enterprise?employeeId=${empId}`,
      method: "get",
      callback,
      onStart: enterpriseRequested.type,
      onSuccess: enterpriseRecieved.type,
      onError: enterpriseFailed.type,
    }),
  );
};

//Bu Region
export const getBuRegion = (empId, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/dropdown/buRegion?employeeId=${empId}`,
      method: "get",
      callback,
      onStart: buRegionRequested.type,
      onSuccess: buRegionRecieved.type,
      onError: buRegionFailed.type,
    }),
  );
};

//My Activity
export const getMyActivity = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/other/activityLog?employeeId=${params.empId}&showOnly=${params.showOnly}&pl=${params.pl}&pt=${params.pt}`,
      method: "get",
      callback,
      onStart: myActivityRequested.type,
      onSuccess: myActivityRecieved.type,
      onError: myActivityFailed.type,
    }),
  );
};

//Employee Detail
export const getEmpDetail = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/other/employee?employeeId=${params.empId}`,
      method: "get",
      callback,
      onStart: empDetailRequested.type,
      onSuccess: empDetailRecieved.type,
      onError: empDetailFailed.type,
    }),
  );
};

//Employee chart
export const getEmpChart = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/other/chart?dashboardChart=yes&chart=profitnew&date1&date2&date3&caller&mm_id_list`,
      method: "get",
      callback,
      onStart: empChartRequested.type,
      onSuccess: empChartRecieved.type,
      onError: empChartFailed.type,
    }),
  );
};

//Get Auction Detail (More Button)
export const getAuctionDetails = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `auctionOther/other/auctionDetail/${params}`,
      method: "get",
      callback,
      onStart: auctionDetailRequested.type,
      onSuccess: auctionDetailRecieved.type,
      onError: auctionDetailFailed.type,
    }),
  );
};

//Get Tasks
export const getTasks = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `auctionOther/other/task/${params}?filterTaskStatusId=3`,
      method: "get",
      callback,
      onStart: tasksRequested.type,
      onSuccess: tasksRecieved.type,
      onError: tasksFailed.type,
    }),
  );
};

//Post Message(in Auction Detail Section)
export const postMessage = (params, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/other/listing/postMessage/${params}`,
      method: "post",
      data,
      callback,
    }),
  );
};

//Get Category Data
export const getCategoryData = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/other/listing/category/${params}`,
      method: "get",
      callback,
      onStart: categoryDataRequested.type,
      onSuccess: categoryDataRecieved.type,
      onError: categoryDataFailed.type,
    }),
  );
};
//Available to view
export const availableToView = (params, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/other/listing/availableToView/${params}`,
      method: "put",
      data,
      callback,
    }),
  );
};
//Get Pricing
export const getPricing = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/other/listing/pricing/${params}`,
      method: "get",
      callback,
      onStart: pricingRequested.type,
      onSuccess: pricingRecieved.type,
      onError: pricingFailed.type,
    }),
  );
};
//Update Pricing
export const updatePricing = (params, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/other/listing/pricing/${params}`,
      method: "put",
      data,
      callback,
    }),
  );
};
//Get internal People
export const getInternalPeople = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/other/listing/internalPeople/${params}`,
      method: "get",
      callback,
      onStart: internalPeopleRequested.type,
      onSuccess: internalPeopleRecieved.type,
      onError: internalPeopleFailed.type,
    }),
  );
};
//update internal people
export const updateInternalPeople = (params, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/other/listing/internalPeople/${params}`,
      method: "put",
      data,
      callback,
    }),
  );
};

//Get Motivation
export const getMotivation = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/other/listing/motivation/${params}`,
      mothod: "get",
      callback,
      onStart: motivaitonRequested.type,
      onSuccess: motivaitonRecieved.type,
      onError: motivaitonFailed.type,
    }),
  );
};

//Update Motivation
export const updateMotivation = (params, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/other/listing/motivation/${params}`,
      method: "put",
      data,
      callback,
    }),
  );
};
//Delete chat inside more button
export const deleteMoreButtonChat = (params, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/other/listing/chat/${params}`,
      method: "delete",
      data,
      callback,
    }),
  );
};

//Get Google Analytics
export const getGoogleAnalytics = (params, day, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/listing/analyticsFromGA?auctionId=${params}&startDate=${day}daysAgo`,
      method: "get",
      callback,
      onStart: googleAnalyticsRequested.type,
      onSuccess: googleAnalyticsRecieved.type,
      onError: googleAnalyticsFailed.type,
    }),
  );
};

//Get Listing Campaign
export const getListingCampaign = (params, limit, skip, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/listing/campaign?auctionId=${params}&limit=${limit}&skip=${skip}`,
      method: "get",
      callback,
      onStart: listingCampaignRequested.type,
      onSuccess: listingCampaignRecieved.type,
      onError: listingCampaignFailed.type,
    }),
  );
};

export const getCampaignAggregate = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `dashboard/listing/campaignAggregate?auctionId=${params}`,
      method: "get",
      callback,
      onStart: campaignAggregateRequested.type,
      onSuccess: campaignAggregateRecieved.type,
      onError: campaignAggregateFailed.type,
    }),
  );
};

//Get Listing Agreement
export const getListingAgreement = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/listing/agreement`,
      params,
      method: "get",
      callback,
      onStart: listingAgreementRequested.type,
      onSuccess: listingAgreementRecieved.type,
      onError: listingAgreementFailed.type,
    })
  )
}

//Update Auction Field 
export const updateAuctionField = (params, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/other/listing/auctionField/${params}`,
      method: "put",
      data,
      callback,
    })
  )
}

//Assign Project
export const assignProject = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/other/assignProject`,
      method: "put",
      data,
      callback,
    })
  )
}

//Assign Project
export const assignTrader = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/other/assignTrader`,
      method: "put",
      data,
      callback,
    })
  )
}

//UnSubscribe
export const unSubscribe = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/other/unsubscribe`,
      method: 'put',
      data,
      callback,
    })
  )
}

//Get Main Category
export const getMainCateogry = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/dropdown/mainCategory`,
      method: "get",
      callback,
      onStart: mainCategoriesRequested.type,
      onSuccess: mainCategoriesRecieved.type,
      onError: mainCategoriesFailed.type,
    })
  )
};

//Get Regions
export const getRegions = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/dropdown/region`,
      method: "get",
      callback,
      onStart: regionsRequested.type,
      onSuccess: regionsRecieved.type,
      onError: regionsFailed.type,
    })
  )
}

//Get Regions
export const getBuyerCategoryData = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/other/buyerCategoryData/${id}`,
      method: "get",
      callback,
      onStart: buyerCategoryDataRequested.type,
      onSuccess: buyerCategoryDataRecieved.type,
      onError: buyerCategoryDataFailed.type,
    })
  )
};

//edit location pre-fields(account management in homepage) & edit location & apply to other listings
export const otherAuctions = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `dashboard/other/action`,
      method: 'put',
      data,
      callback,
    })
  )
};



export const dashboardData = createSelector(
  (state) => state.entities.dashboard,
  (dashboard) => dashboard,
);
