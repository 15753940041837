import React, { useCallback, useEffect, useState } from "react";
import { getAuctionConversationList, questionDetails } from "../../../store/questions";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";
import ReactTooltip from "react-tooltip";
import { ConvertTime } from "../../../common/DateFormat/cstToLocalTime";
import { removeTags } from "../../../utils.js/conversion";

const QuestionLists = (props) => {
  const count = props?.count?.questionChat;
  const [isRedirectChecked, setIsRedirectChecked] = useState(true);

  const questionData =
    props &&
    props.questionDetails &&
    props.questionDetails.auctionConversationList &&
    props.questionDetails.auctionConversationList.data;

  useEffect(() => {
    if (props?.isRedirect?.length > 0 && isRedirectChecked && questionData?.length > 0) {
      setIsRedirectChecked(false);
      const userChat = questionData?.filter((ur) => ur?.buyers_conversation_on_auction_key == props?.isRedirect);

      if (userChat?.length > 0) {
        props.setMessageSection(true);
        props.setSelectedChat(userChat[0]);
        props.toggleMessages("questions");
      }
    }
  }, [props?.isRedirect, questionData?.length]);

  return (
    <div class="details-page-widget-block" id={props.id}>
      <div class="page-widget-head d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <div class="d-flex align-items-center">
            <div class="page-widget-label text-capitalize cursor-pointer">
              Messages {!props?.loadingMain && (count > 0 ? `(${count})` : "(0)")}
            </div>
          </div>
        </div>
      </div>
      <div class={questionData?.length > 0 && "page-widget-body mt-3"}>
        <div class="table-responsive table-custom">
          <table class="large-table large-table1">
            <colgroup>
              <col />
              <col />
              <col style={{ width: "380px" }} />
              <col />
              <col />
            </colgroup>
            {props.loadingMain ? (
              <>
                <thead>
                  <tr>
                    <th>
                      <Skeleton />
                    </th>
                    <th>
                      <Skeleton />
                    </th>
                    <th>
                      <Skeleton />
                    </th>
                    <th>
                      <Skeleton />
                    </th>
                    <th>
                      <Skeleton />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {[1, 2, 3].map((val, i) => (
                    <tr key={i}>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </>
            ) : (
              <>
                {questionData?.length > 0 && (
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Contact Info</th>
                      <th>Last Message</th>
                      <th>Last Message By</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                )}
                <tbody>
                  {questionData?.map((val, index) => {
                    return (
                      <tr key={val?.conversation_id}>
                        <td>
                          {val?.user_name ? (
                            <span className="green-btn-text">
                              <a
                                href={
                                  process.env.REACT_APP_MODE === "development"
                                    ? `https://admin-dev.salvex.com/admin/_user/_edit.cfm?user_id=${val?.user_id}`
                                    : `https://admin.salvex.com/admin/_user/_edit.cfm?user_id=${val?.user_id}`
                                }
                                target="_blank"
                              >
                                {val?.user_name}
                              </a>
                            </span>
                          ) : (
                            "N/A"
                          )}
                        </td>
                        <td>
                          {val?.user_email || val?.user_phone ? (
                            <>
                              <span className="green-btn-text">
                                <a href={`mailto:${val?.user_email}`}>{val?.user_email}</a>
                              </span>
                              <div>
                                <a>{val?.user_phone}</a>
                              </div>
                            </>
                          ) : (
                            "N/A"
                          )}
                        </td>
                        <td
                          style={{
                            whiteSpace: "normal",
                            textAlign: "left",
                          }}
                        >
                          {val?.message ? (
                            <span
                              className="cursor-pointer"
                              data-for={`${val?.conversation_id}-message`}
                              data-tip={removeTags(val?.message)}
                            >
                              {val?.message
                                ? val?.message?.length > 110
                                  ? removeTags(val?.message?.substring(0, 110)) + "..."
                                  : removeTags(val?.message)
                                : "N/A"}
                              <ReactTooltip
                                effect="solid"
                                place="top"
                                type="dark"
                                id={`${val?.conversation_id}-message`}
                                className="custom-react-tooltip"
                                // offset={{ top: 10 }}
                                border={true}
                              />
                            </span>
                          ) : (
                            "N/A"
                          )}

                          <div className="mt-2">
                            <button
                              className="green-btn-text"
                              onClick={() => {
                                props.setMessageSection(true);
                                props.setSelectedChat(val);
                                props.toggleMessages("questions");
                              }}
                            >
                              {val?.total_messages > 0
                                ? val?.total_messages === 1
                                  ? "View 1 message"
                                  : `View all ${val?.total_messages} messages`
                                : ""}
                            </button>{" "}
                            {val?.isAnswered?.toString() === "false" ? (
                              <div
                                class={`page-cat-box rsp d-inline cursor-pointer`}
                                style={{ maxWidth: "90px", marginLeft: "7px", padding: "2px 5px" }}
                                onClick={() => {
                                  props.setMessageSection(true);
                                  props.setSelectedChat(val);
                                  props.toggleMessages("questions");
                                }}
                              >
                                Unanswered
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </td>
                        <td>
                          {val.name ? (
                            <div>
                              <div>{val.name}</div>
                              <div>{ConvertTime(val.date_created)}</div>
                            </div>
                          ) : (
                            "N/A"
                          )}
                        </td>
                        <td>
                          <button
                            className="green-btn-text"
                            onClick={() => {
                              props.setMessageSection(true);
                              props.setSelectedChat(val);
                              props.toggleMessages("questions");
                            }}
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </>
            )}
          </table>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getAuctionConversationList: (id, callback) => dispatch(getAuctionConversationList(id, callback)),
});
const mapStateToProps = (state) => ({
  questionDetails: questionDetails(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(QuestionLists));
