export const twoDecimals = (data) => {
  return parseFloat(data).toFixed(2);
};

export const availableFlags = (name) => {
  const flags = [
    "AGO.gif",
    "ARE.gif",
    "ARG.gif",
    "ARM.gif",
    "AUS.gif",
    "AUT.gif",
    "BEL.gif",
    "BGD.gif",
    "BHR.gif",
    "BHS.gif",
    "BOL.gif",
    "BRA.gif",
    "BRN.gif",
    "CAN.gif",
    "CHE.gif",
    "CHL.gif",
    "CHN.gif",
    "COL.gif",
    "CRI.gif",
    "CUB.gif",
    "CYM.gif",
    "CYP.gif",
    "CZE.gif",
    "DEU.gif",
    "DNK.gif",
    "DOM.gif",
    "ECU.gif",
    "EGY.gif",
    "ESP.gif",
    "EST.gif",
    "FIN.gif",
    "FRA.gif",
    "FRO.gif",
    "GBR.gif",
    "GRC.gif",
    "GTM.gif",
    "GUM.gif",
    "HND.gif",
    "HUN.gif",
    "IDN.gif",
    "IND.gif",
    "IRL.gif",
    "IRN.gif",
    "IRQ.gif",
    "ISL.gif",
    "ISR.gif",
    "ITA.gif",
    "JAM.gif",
    "JOR.gif",
    "JPN.gif",
    "KAZ.gif",
    "KEN.gif",
    "KWT.gif",
    "LBN.gif",
    "LTU.gif",
    "LVA.gif",
    "MAR.gif",
    "MEX.gif",
    "MLT.gif",
    "MOZ.gif",
    "MWI.gif",
    "MYS.gif",
    "NGA.gif",
    "NIC.gif",
    "NLD.gif",
    "NOR.gif",
    "NZL.gif",
    "PAK.gif",
    "PAN.gif",
    "PER.gif",
    "PHL.gif",
    "POL.gif",
    "PRI.gif",
    "PRK.gif",
    "PRT.gif",
    "PRY.gif",
    "QAT.gif",
    "ROM.gif",
    "RUS.gif",
    "SAU.gif",
    "SGP.gif",
    "SLB.gif",
    "SUR.gif",
    "SVK.gif",
    "SVN.gif",
    "SWE.gif",
    "THA.gif",
    "TTO.gif",
    "TUR.gif",
    "TWN.gif",
    "UGA.gif",
    "UKR.gif",
    "URY.gif",
    "USA_old.gif",
    "USA.gif",
    "VEN.gif",
    "yem.gif",
    "ZMB.gif",
    "ZWE.gif",
  ];
  const bool = flags.includes(name) ? true : false;
  return bool;
};

export const removeTags = (data) => {
  var txt = data;
  var rex = /(<([^>]+)>)/gi;
  return txt?.replace(rex, "");
};

export const findDuplicateCombinationPositions = (arr, key1, key2) => {
  const duplicates = {};

  arr.forEach((obj, index) => {
    const combination = obj[key1] + "|" + obj[key2];

    if (!duplicates[combination]) {
      duplicates[combination] = [index];
    } else {
      duplicates[combination].push(index);
    }
  });

  const result = Object.entries(duplicates).filter(([combination, positions]) => positions.length > 1);

  return result.map(([combination, positions]) => ({
    combination: combination.split("|"),
    positions,
  }));
};

export const statusIdentifier = (data) => {
  if ((data >= 3 && data < 5) || data > 5) {
    const status = "Active";
    return status;
  }
  if (data === 5) {
    const status = "Archive";
    return status;
  }
  if (data === 1 || data === 2) {
    const status = "Pending";
    return status;
  }
};

export const numberFormat = (str) => {
  if (str) {
    const twoDecimals = parseFloat(str).toFixed(2);
    return new Intl.NumberFormat("en-US").format(twoDecimals);
  } else {
    return 0;
  }
};

export function capitalizeFirstLetter(string) {
  return string
    ?.split(" ")
    ?.map((word) => word?.charAt(0)?.toUpperCase() + word?.slice(1))
    ?.join(" ");
}
