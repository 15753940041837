import React, { useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import Select from "react-select";
import { customStyles } from "../../../../common/customStyles/reactSelectStyle";

const AddPlaneModel = (props) => {
  const planeMfr = props?.planeMfr || [];
  const [mfr, setMft] = useState({
    title: "Select Plane Manufacturer",
    _id: "",
  });
  useEffect(() => {
    setMft(planeMfr.find((val) => val?._id === props?.editData?.planeManufacturer));
  }, [planeMfr]);
  return (
    <Offcanvas
      show={props.show}
      onHide={() => {
        props.onHide();
      }}
      placement="end"
      className="offcanvas offcanvas-end custom-offcanvas-sm "
      backdropClassName="upload-images-backdrop"
      tabindex="-1"
      id="userFilter"
      enforceFocus={false}
    >
      {props?.aviationType?.type == "editModel" ? (
        <>
          <Offcanvas.Header className="offcanvas-header ">
            <h5 class="offcanvas-title" style={{ marginLeft: "-9px" }}>
              Edit {props?.isAviation ? "Plane" : "Part"} Model
            </h5>
            <button
              type="button"
              className="btn-close text-reset"
              onClick={() => {
                props.onHide();
              }}
            ></button>
          </Offcanvas.Header>
          <Offcanvas.Body className="offcanvas-body co-body">
            <div class="form-group">
              <label for="keyword" class="form-label">
                Title
              </label>
              <input
                type="text"
                class="form-control"
                id="auction-search"
                value={props.editData?.title}
                onChange={(e) => {
                  const data = { ...props.editData };
                  data.title = e.target.value;
                  props.setEditData(data);
                }}
              />
            </div>
            {props?.isAviation && (
              <div class="form-group">
                <label for="keyword" class="form-label">
                  Plane Manufacturer
                </label>
                <Select
                  styles={customStyles}
                  placeholder="Select Plane Manufacturer"
                  value={mfr}
                  options={[{ title: "Select Plane Manufacturer", _id: "" }, ...planeMfr]}
                  getOptionLabel={(val) => val.title}
                  getOptionValue={(val) => val._id}
                  onChange={(e) => {
                    const data = { ...props?.editData };
                    data.planeManufacturer = e._id;
                    props?.setEditData(data);
                    setMft(e);
                  }}
                />
              </div>
            )}
            {/* <div class="form-group">
              <label for="keyword" class="form-label">
                Description
              </label>
              <input
                type="text"
                class="form-control"
                id="auction-search"
                value={props.editData?.description}
                onChange={(e) => {
                  const data = { ...props.editData };
                  data.description = e.target.value;  
                  props.setEditData(data);
                }}
              />
            </div> */}
          </Offcanvas.Body>
          <div class="offcanvas-footer">
            <a
              href="javascript:void(0)"
              class={`btn btn-default btn-block ${
                (props.loading || !props?.editData?.title || !props?.editData?.planeManufacturer) && "disabled"
              }`}
              onClick={() => props.handleEdit()}
            >
              Submit
            </a>
          </div>
        </>
      ) : props?.aviationType?.type == "filterModel" ? (
        <>
          {/* <Offcanvas.Header className="offcanvas-header ">
            <h5 class="offcanvas-title" style={{ marginLeft: "-9px" }}>
              Filters
            </h5>
            <button type="button" className="btn-close text-reset" onClick={() => props.onHide()}></button>
          </Offcanvas.Header>
          <Offcanvas.Body className="offcanvas-body co-body">
            <div class="form-group">
              <label for="keyword" class="form-label">
                Active
              </label>
              <Select
                value={booleanOptions?.find((val) => val.value === props?.active)}
                className="basic-single"
                classNamePrefix="select-search"
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.value}
                name="aviationModel"
                isLoading={props?.loading}
                loadingMessage={() => "Loading..."}
                options={booleanOptions || []}
                placeholder={"Select Active"}
                styles={customStyles}
                onChange={(e) => {
                  props?.setActive(e.value);
                }}
              />
            </div>
          </Offcanvas.Body>
          <div class="offcanvas-footer">
            <a
              href="javascript:void(0)"
              class={`btn btn-default btn-block ${props.loading && "disabled"}`}
              onClick={() => props.handleFilter()}
            >
              Submit
            </a>
          </div> */}
        </>
      ) : (
        <>
          <Offcanvas.Header className="offcanvas-header ">
            <h5 class="offcanvas-title" style={{ marginLeft: "-9px" }}>
              Add {props?.isAviation ? "Plane" : "Part"} Model
            </h5>
            <button type="button" className="btn-close text-reset" onClick={() => props.onHide()}></button>
          </Offcanvas.Header>
          <Offcanvas.Body className="offcanvas-body co-body">
            <div class="form-group">
              <label for="keyword" class="form-label">
                Title
              </label>
              <input
                type="text"
                class="form-control"
                id="auction-search"
                value={props?.addData?.title}
                onChange={(e) => {
                  const data = { ...props.addData };
                  data.title = e.target.value;
                  props.setAddData(data);
                }}
              />
            </div>
            {props?.isAviation && (
              <div class="form-group">
                <label for="keyword" class="form-label">
                  Plane Manufacturer
                </label>
                <Select
                  styles={customStyles}
                  placeholder="Select Plane Manufacturer"
                  options={[{ title: "Select Plane Manufacturer", _id: "" }, ...planeMfr]}
                  getOptionLabel={(val) => val.title}
                  getOptionValue={(val) => val._id}
                  onChange={(e) => {
                    const data = { ...props?.addData };
                    data.planeManufacturer = e._id;
                    props?.setAddData(data);
                  }}
                />
              </div>
            )}
          </Offcanvas.Body>
          <div class="offcanvas-footer">
            <a
              href="javascript:void(0)"
              class={`btn btn-default btn-block ${
                (props.loading || !props.addData?.title || !props?.addData?.planeManufacturer) && "disabled"
              }`}
              onClick={() => props.handleAdd()}
            >
              Submit
            </a>
          </div>
        </>
      )}
    </Offcanvas>
  );
};

export default AddPlaneModel;
