import React from "react";
import { connect } from "react-redux";
import { getMisc } from "../../../store/misc";
import Skeleton from "react-loading-skeleton";
import DateFormat from "../../../common/DateFormat/dateFormat";
import moment from "moment";

const OwnerAccessLog = (props) => {
  const data = (props.getMisc && props.getMisc.ownerAccessLog && props.getMisc.ownerAccessLog.data) || [];

  return (
    <div class="details-page-widget-block">
      <div class="page-widget-head d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <div class="page-widget-label text-capitalize">Owner Access Log</div>
        </div>
        <div class="btn-block"></div>
      </div>
      <div class="page-widget-body mt-3">
        <div class="table-responsive table-custom">
          {props.loadingMain ? (
            <table class="table table-bordered">
              <colgroup>
                <col />
                <col />
                <col />
                <col />
              </colgroup>
              <thead>
                <tr>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                </tr>
              </tbody>
            </table>
          ) : data && data.length > 0 ? (
            <table class="table table-bordered">
              <colgroup>
                <col />
                <col />
                <col />
                <col />
              </colgroup>
              <thead>
                <tr>
                  <td>User Details</td>
                  <td>Date</td>
                </tr>
              </thead>
              <tbody>
                {data.map((item) => {
                  const a = [];
                  if (item?.user_id) {
                    a.push(item.user_id);
                  }
                  if (item?.name01) {
                    a.push(item.name01);
                  }
                  if (item?.email) {
                    a.push(item.email);
                  }
                  return (
                    <tr>
                      <td>
                        {a &&
                          a.length > 0 &&
                          a.map((item, i) => (
                            <>
                              {i == 0 && <span>{item}</span>}
                              {i == 1 && <span style={{ color: "gray" }}>{item}</span>}
                              {i == 2 && (
                                <a
                                  className="hover-text-green"
                                  style={{ color: "gray" }}
                                  href={item && `mailto:${item}`}
                                >
                                  {item}
                                </a>
                              )}
                              <br />
                            </>
                          ))}{" "}
                      </td>

                      <td>{item?.created_date ? moment(item.created_date).format("MMM-DD-YYYY") : "N/A"}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <div
              class="page-widget-empty-box text-capitalize d-flex align-items-center justify-content-center text-center"
              style={{
                height: "239px",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <span>No records found</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({});

const mapStateToProps = (state) => ({
  getMisc: getMisc(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(OwnerAccessLog));
