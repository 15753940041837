import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import Header from "../../layouts/header";
import { connect } from "react-redux";
import LoadingBar from "react-top-loading-bar";
import AviationTable from "../../components/listingDetail/aviationParts/aviationTable";
import {
  deleteAviationParts,
  getAviationDetails,
  getAviationLocations,
  getCondition,
  getMfr,
  getModels,
  getPart,
  getPartMfr,
  updateAviationParts,
} from "../../store/aviation";
import ViewDetails from "../../components/listingDetail/aviationParts/viewDetails";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import DeleteModal from "../../layouts/modals/deleteModal";
import { toast } from "react-toastify";
import AlertSuccess from "../../common/alerts/alertSuccess";
import AlertError from "../../common/alerts/alertError";
import EditAviationDetails from "../../components/listingDetail/aviationParts/editAviationParts";
import { getProfile } from "../../utils.js/localStorageServices";
import moment from "moment";
import _, { isBoolean } from "lodash";
import PartsTable from "../../components/parts/partsTable";
import {
  deleteGenericPart,
  getGenericParts,
  getPartCondition,
  getPartManufacturer,
  getPartsDetails,
  getPartsLocation,
  getPartsModels,
  updateGenericParts,
} from "../../store/parts";
import AiDescriptionModal from "../../layouts/modals/parts/aiDescriptionModal";

const GenericParts = (props) => {
  const ref = useRef(null);
  const history = useHistory();

  //Upload Parts
  const [showUploadPartModal, setShowUploadPartModal] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [applyFilter, setApplyFilter] = useState(false);
  const [loading, setLoading] = useState(false);

  const [viewModal, setViewModal] = useState(false);
  const [selectedValues, setSelectedValues] = useState(null);

  const [sort, setSort] = useState("asc");
  const [sortBy, setSortBy] = useState("createdAt");
  const [page, setPage] = useState(1);

  const [showDelete, setShowDelete] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const [showAiDesc, setShowAiDesc] = useState(false);
  const toggleAiDescription = () => {
    setShowAiDesc(!showAiDesc);
  };

  const [showEdit, setShowEdit] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [editData, setEditData] = useState({
    auctionId: "",
    partNumber: "",
    partDescription: "",
    condition: "",
    quantity: 0,
    unit: "",
    unitPrice: 0,
    location: "",
    partModels: [],
    partManufacturer: "",
    images: [],
    grade: "",
    serialNumber: "",
    aiProductDescription: "",
    aiManufactureDescription: "",
    notes1: "",
    notes2: "",
    notes3: "",
    notes4: "",
    notes5: "",
  });
  const [aiDescription, setAiDescription] = useState("");

  const [response, setResponse] = useState({
    res: false,
    auctionId: "",
    partNumber: "",
    condition: "",
  });

  const [selectedFilter, setSelectedFilter] = useState({
    auctionId: "",
    partNumber: "",
    partCondition: "",
  });

  const condition = props.getPartsDetails?.partCondition?.data?.map((item) => ({
    name: `(${item.title}) ${item.code}`,
    value: item.code,
  }));
  const partMfr = props.getPartsDetails?.partManufacturer?.data?.map((item) => ({
    name: item.title,
    value: item.title,
  }));
  const model = props.getPartsDetails?.model?.data?.map((item) => ({
    name: item.title,
    value: item.title,
  }));
  const locations = props.getPartsDetails?.partLocation?.data?.map((item) => ({
    name: item.title,
    value: item.title,
  }));

  console.log(props?.getPartsDetails, "are the details from whole redux");

  const location = [
    { name: "United States", value: "United States" },
    { name: "Americas", value: "Americas" },
    { name: "Africa", value: "Africa" },
    { name: "Asia-Pacific", value: "Asia-Pacific" },
    { name: "Europe", value: "Europe" },
    { name: "Middle East", value: "Middle East" },
  ];

  useEffect(() => {
    if (showAiDesc) {
      setAiDescription(selectedValues.aiDescription ? selectedValues.aiDescription : "");
    }
  }, [showAiDesc]);

  useEffect(() => {
    if (showEdit) {
      const data = { ...editData };
      const z = [];
      const b =
        selectedValues?.planeModels && selectedValues?.planeModels?.length > 0
          ? selectedValues?.planeModels.map((item) =>
              model.map((md) => {
                if (md.name == item) {
                  z.push(md);
                }
              }),
            )
          : [];

      data.auctionId = selectedValues.auctionId ? selectedValues.auctionId : "";
      data.partNumber = selectedValues.partNumber ? selectedValues.partNumber : "";
      data.partDescription = selectedValues.partDescription ? selectedValues.partDescription : "";
      data.condition = selectedValues.condition ? selectedValues.condition : "";
      data.quantity = selectedValues.quantity ? selectedValues.quantity : 0;
      data.unit = selectedValues.unit ? selectedValues.unit : "";
      data.unitPrice = selectedValues.unitPrice ? selectedValues.unitPrice : 0;
      data.location = selectedValues.location ? selectedValues.location : "";
      data.partModels = selectedValues.partModels ? selectedValues.partModels : [];
      data.partManufacturer = selectedValues.partManufacturer ? selectedValues.partManufacturer : "";
      data.images = selectedValues.images ? selectedValues.images : [];
      data.grade = selectedValues.grade ? selectedValues.grade : "";
      data.serialNumber = selectedValues.serialNumber ? selectedValues.serialNumber : "";
      data.aiProductDescription = selectedValues.aiProductDescription ? selectedValues.aiProductDescription : "";
      data.aiManufactureDescription = selectedValues.aiManufactureDescription
        ? selectedValues.aiManufactureDescription
        : "";
      data.notes1 = selectedValues.notes1 ? selectedValues.notes1 : "";
      data.notes2 = selectedValues.notes2 ? selectedValues.notes2 : "";
      data.notes3 = selectedValues.notes3 ? selectedValues.notes3 : "";
      data.notes4 = selectedValues.notes4 ? selectedValues.notes4 : "";
      data.notes5 = selectedValues.notes5 ? selectedValues.notes5 : "";

      // Finally, update the state
      setEditData(data);
    }
  }, [showEdit]);

  const toggleDelete = () => {
    setShowDelete(!showDelete);
  };

  const toggleEdit = () => {
    setShowEdit(!showEdit);
  };

  const partsData = (props?.getPartsDetails && props?.getPartsDetails?.parts?.data) || [];

  const toggleViewModal = () => {
    setViewModal(!viewModal);
  };

  const toggleFilter = () => {
    setSelectedFilter({
      auctionId: response?.auctionId ? response?.auctionId : "",
      partNumber: response?.partNumber ? response?.partNumber : "",
      partCondition: response?.condition ? response?.condition : "",
    });
    setShowFilter(!showFilter);
  };

  const toggleUploadPartModal = () => {
    setShowUploadPartModal(!showUploadPartModal);
  };

  const loadGenericParts = () => {
    setLoading(true);
    const payload = {
      partNumber: response?.partNumber ? response?.partNumber : "",
      auctionId: response?.auctionId ? response?.auctionId : "",
      page: page,
      order: sort,
      sort: sortBy,
    };
    props.getGenericParts(payload, (res) => {
      if (res && res.status == 200) {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    loadGenericParts();
  }, []);

  useEffect(() => {
    if (showUploadPartModal) {
      props.getGenericParts({ page: 1, order: "desc", sort: "createdAt" }, (res) => {
        if (res && res.status == 200) {
        }
      });
    }
  }, [showUploadPartModal]);

  useEffect(() => {
    if (partsData?.length) {
      setLoading(true);
      const payload = {
        partNumber: response?.partNumber ? response?.partNumber : "",
        auctionId: response?.auctionId ? response?.auctionId : "",
        partCondition: response?.condition?.length > 0 ? response?.condition?.map((item) => item.value).join(",") : "",
        page: page,
        order: sort,
        sort: sortBy,
      };
      if (response?.certificate) {
        payload.certificate = response?.certificate?.value ? true : false;
      }
      props.getGenericParts(payload, (res) => {
        if (res && res.status == 200) {
          setLoading(false);
        }
      });
    }
  }, [page]);

  useEffect(() => {
    if (!viewModal) {
      setSelectedValues(null);
    }
  }, [viewModal]);

  const handleFilter = () => {
    setLoading(true);
    ref?.current?.continuousStart();
    const param = {
      partNumber: selectedFilter?.partNumber ? selectedFilter?.partNumber : "",
      auctionId: selectedFilter?.auctionId ? selectedFilter?.auctionId : "",
      partCondition: response?.condition?.length > 0 ? response?.condition?.map((item) => item.value).join(",") : "",
      page: page,
      order: sort,
      sort: sortBy,
    };
    const payload = _.pickBy(param, function (value, key) {
      return !(value === "" || value == {} || value === undefined || value === null);
    });
    if (payload.partNumber || payload.auctionId || payload.partCondition) {
      setApplyFilter(true);
    }
    props.getGenericParts(payload, (res) => {
      if (res && res.status == 200) {
        const data = { ...response };
        data.res = true;
        data.auctionId = selectedFilter?.auctionId;
        data.partNumber = selectedFilter?.partNumber;
        data.condition = selectedFilter?.partCondition;
        setResponse(data);
        setLoading(false);
        ref?.current?.complete();
        toggleFilter();
      }
    });
  };

  const handleClearFilter = () => {
    setLoading(true);
    ref?.current?.continuousStart();
    const param = {
      partNumber: response?.partNumber ? response?.partNumber : "",
      auctionId: response?.auctionId ? response?.auctionId : "",
      partCondition: response?.condition?.length > 0 ? response?.condition?.map((item) => item.value).join(",") : "",

      page: 1,
      order: sort,
      sort: sortBy,
    };

    const payload = _.pickBy(param, function (value, key) {
      return !(value === "" || value == {} || value === undefined || value === null);
    });
    // if (
    //   !payload.partNumber &&
    //   !payload.auctionId &&
    //   !payload.partType &&
    //   !payload.planeCondition &&
    //   // !payload.planePartManufacturer &&
    //   !payload.planeModels &&
    //   !payload.certificate &&
    //   !payload.warranty
    // ) {
    //   setApplyFilter(false);
    // }

    props.getGenericParts(payload, (res) => {
      if (res && res.status == 200) {
        setLoading(false);
        ref?.current?.complete();
      }
    });
  };

  useEffect(() => {
    if (!showFilter && applyFilter) {
      handleClearFilter();
    }
  }, [response]);
  const getSortedData = (sortBy, sort) => {
    setLoading(true);
    ref?.current?.continuousStart();
    const param = {
      partNumber: response?.partNumber ? response?.partNumber : "",
      auctionId: response?.auctionId ? response?.auctionId : "",
      partCondition: response?.condition?.length > 0 ? response?.condition?.map((item) => item.value).join(",") : "",
      page: 1,
      order: sort,
      sort: sortBy,
    };
    const payload = _.pickBy(param, function (value, key) {
      return !(value === "" || value == {} || value === undefined || value === null);
    });
    props.getGenericParts(payload, (res) => {
      setPage(1);
      if (res && res.status == 200) {
        setLoading(false);
        ref?.current?.complete();
      }
    });
  };

  useEffect(() => {
    if (sortBy == "partNumber") {
      if (sort == "asc") {
        getSortedData("partNumber", "asc");
      } else {
        getSortedData("partNumber", "desc");
      }
    } else if (sortBy == "condition") {
      if (sort == "asc") {
        getSortedData("condition", "asc");
      } else {
        getSortedData("condition", "desc");
      }
    } else if (sortBy == "partManufacturer") {
      if (sort == "asc") {
        getSortedData("partManufacturer", "asc");
      } else {
        getSortedData("partManufacturer", "desc");
      }
    } else if (sortBy == "partType") {
      if (sort == "asc") {
        getSortedData("partType", "asc");
      } else {
        getSortedData("partType", "desc");
      }
    } else if (sortBy == "planeModels") {
      if (sort == "asc") {
        getSortedData("planeModels", "asc");
      } else {
        getSortedData("planeModels", "desc");
      }
    } else if (sortBy == "warranty") {
      if (sort == "asc") {
        getSortedData("warranty", "asc");
      } else {
        getSortedData("warranty", "desc");
      }
    } else if (sortBy == "quantity") {
      if (sort == "asc") {
        getSortedData("quantity", "asc");
      } else {
        getSortedData("quantity", "desc");
      }
    } else if (sortBy == "availablePrice") {
      if (sort == "asc") {
        getSortedData("availablePrice", "asc");
      } else {
        getSortedData("availablePrice", "desc");
      }
    } else if (sortBy == "wholesalePrice") {
      if (sort == "asc") {
        getSortedData("wholesalePrice", "asc");
      } else {
        getSortedData("wholesalePrice", "desc");
      }
    } else if (sortBy == "location") {
      if (sort == "asc") {
        getSortedData("location", "asc");
      } else {
        getSortedData("location", "desc");
      }
    } else if (sortBy == "certificate") {
      if (sort == "asc") {
        getSortedData("certificate", "asc");
      } else {
        getSortedData("certificate", "desc");
      }
    } else if (sortBy == "partDescription") {
      if (sort == "asc") {
        getSortedData("partDescription", "asc");
      } else {
        getSortedData("partDescription", "desc");
      }
    }
  }, [sortBy, sort]);

  useEffect(() => {
    props.getCondition({}, () => {});

    props.getModels({ isParent: false }, () => {});
    props.getMfr({}, () => {});
    props.getPartMfr({}, () => {});
    props.getPartsLocation({ isActive: true }, () => {});

    props.getParts({}, () => {});
  }, []);

  const partsPaginationData = props?.getPartsDetails && props?.getPartsDetails?.parts?.pagination;

  const filteredCount = partsPaginationData?.filteredCount;
  const totalCount = partsPaginationData?.totalCount;
  const totalPage = Math.ceil(partsPaginationData?.totalCount / partsPaginationData?.recordsPerPage);

  const deleteHandler = () => {
    ref?.current?.continuousStart();
    setLoadingDelete(true);
    props.deleteGenericPart(selectedValues?._id, (res) => {
      if (res && res.status == 200) {
        setLoadingDelete(false);
        ref?.current?.complete();
        // toggleDelete();
        setShowDelete(false);
        setShowEdit(false);
        loadGenericParts();
      } else {
        setLoadingDelete(false);
        ref?.current?.complete();
        toast(<AlertError message={res?.data?.message ? res?.data?.message : "Something went wrong"} />);
      }
    });
  };

  const editHandler = () => {
    const params = {
      auctionId: editData.auctionId ? editData.auctionId : "",
      partNumber: editData.partNumber ? editData.partNumber?.replace("#", "")?.replace("?", "")?.replace("/", "") : "",
      partDescription: editData.partDescription ? editData.partDescription : "",
      condition: editData.condition ? editData?.condition?.value : "",
      quantity: editData.quantity ? editData.quantity : 0,
      unit: editData.unit ? editData.unit : "",
      unitPrice: editData.unitPrice ? editData.unitPrice : 0,
      location: editData.location ? editData.location : "",
      partModels: editData.partModels ? editData.partModels : [],
      partManufacturer: editData.partManufacturer ? editData.partManufacturer : "",
      images: editData.images ? editData.images : [],
      grade: editData.grade ? editData.grade : "",
      serialNumber: editData.serialNumber ? editData.serialNumber : "",
      aiProductDescription: editData.aiProductDescription ? editData.aiProductDescription : "",
      aiManufactureDescription: editData.aiManufactureDescription ? editData.aiManufactureDescription : "",
      notes1: editData.notes1 ? editData.notes1 : "",
      notes2: editData.notes2 ? editData.notes2 : "",
      notes3: editData.notes3 ? editData.notes3 : "",
      notes4: editData.notes4 ? editData.notes4 : "",
      notes5: editData.notes5 ? editData.notes5 : "",
    };
    const param = _.pickBy(params, function (value, key) {
      return !(value === "" || value == {} || value === undefined || value === null);
    });
    if (param?.quantity?.toString()?.split("")?.length > 0) {
      setLoadingEdit(true);
      ref?.current?.continuousStart();
      props.updateGenericParts(selectedValues?._id, param, (res) => {
        if (res && res.status == 200) {
          setLoadingEdit(false);
          ref?.current?.complete();
          toast(<AlertSuccess message={"Information Saved"} />);
          toggleEdit();
          loadGenericParts();
        } else {
          setLoadingEdit(false);
          ref?.current?.complete();
          toast(<AlertError message={res?.data?.message ? res?.data?.message : "Something went wrong"} />);
        }
      });
    } else {
      toast(<AlertError message={"Quantity is not allowed to be empty"} />);
    }
  };

  //Handle AI Description
  const handleAiDescription = () => {
    const payload = {
      aiDescription: aiDescription,
    };
    setLoadingEdit(true);
    ref?.current?.continuousStart();
    props?.updateGenericParts(selectedValues?._id, payload, (res) => {
      if (res.status === 200) {
        setLoadingEdit(false);
        ref?.current?.complete();
        toast(<AlertSuccess message={"Information Saved"} />);
        toggleAiDescription();
        loadGenericParts();
      } else {
        setLoadingEdit(false);
        ref?.current?.complete();
        toast(<AlertError message={res?.data?.message ? res?.data?.message : "Something went wrong"} />);
      }
    });
  };

  return (
    <>
      <LoadingBar height={5} color="#47AD1D" ref={ref} />
      <Header />

      <PartsTable
        toggleUploadPartModal={toggleUploadPartModal}
        showUploadPartModal={showUploadPartModal}
        partsData={partsData}
        toggleFilter={toggleFilter}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
        handleFilter={handleFilter}
        loading={loading}
        handleClearFilter={handleClearFilter}
        setSelectedValues={setSelectedValues}
        toggleViewModal={toggleViewModal}
        sortBy={sortBy}
        setSortBy={setSortBy}
        sort={sort}
        setSort={setSort}
        filteredCount={filteredCount}
        totalCount={totalCount}
        page={page}
        totalPages={totalPage}
        setPage={setPage}
        toggleDelete={toggleDelete}
        showDelete={showDelete}
        setShowDelete={setShowDelete}
        loadingDelete={loadingDelete}
        deleteHandler={deleteHandler}
        toggleEdit={toggleEdit}
        loadData={loadGenericParts}
        toggleAiDescription={toggleAiDescription}
        condition={condition}
        model={model}
        location={location}
        selectedFilter={selectedFilter}
        setSelectedFilter={setSelectedFilter}
        applyFilter={applyFilter}
        response={response}
        setResponse={setResponse}
      />
      <ViewDetails show={viewModal} onHide={toggleViewModal} selectedValue={selectedValues} />

      <EditAviationDetails
        show={showEdit}
        onHide={toggleEdit}
        selectedValue={selectedValues}
        condition={condition}
        partMfr={partMfr}
        model={model}
        locations={locations}
        data={editData}
        setData={setEditData}
        loadingEdit={loadingEdit}
        handleEdit={editHandler}
        loadingDelete={loadingDelete}
        deleteHandler={deleteHandler}
      />
      <AiDescriptionModal
        show={showAiDesc}
        onHide={toggleAiDescription}
        aiDescription={aiDescription}
        setAiDescription={setAiDescription}
        loading={loadingEdit}
        handleSubmit={handleAiDescription}
      />

      <DeleteModal show={showDelete} onHide={toggleDelete} deleteHandler={deleteHandler} loading={loadingDelete} />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getGenericParts: (params, callback) => dispatch(getGenericParts(params, callback)),
  updateGenericParts: (id, data, callback) => dispatch(updateGenericParts(id, data, callback)),
  deleteAviation: (id, callback) => dispatch(deleteAviationParts(id, callback)),
  deleteGenericPart: (id, callback) => dispatch(deleteGenericPart(id, callback)),
  updateAviation: (id, callback) => dispatch(updateAviationParts(id, callback)),
  getMfr: (params, callback) => dispatch(getMfr(params, callback)),
  getPartMfr: (params, callback) => dispatch(getPartManufacturer(params, callback)),
  getParts: (params, callback) => dispatch(getPart(params, callback)),
  getAviationLocations: (params, callback) => dispatch(getAviationLocations(params, callback)),
  getPartsLocation: (params, callback) => dispatch(getPartsLocation(params, callback)),
  getCondition: (params, callback) => dispatch(getPartCondition(params, callback)),
  getModels: (params, callback) => dispatch(getPartsModels(params, callback)),
  updateAviationParts: (id, data, callback) => dispatch(updateAviationParts(id, data, callback)),
});
const mapStateToProps = (state) => ({
  getPartsDetails: getPartsDetails(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(GenericParts));
