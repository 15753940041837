import React, { useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import Select from "react-select";
import close from "../../../../include/images/close-12x12.svg";
import Skeleton from "react-loading-skeleton";
import { customStyles } from "../../../../common/customStyles/reactSelectStyle";
import { connect } from "react-redux";
import { getBUListings, getEnterpriseListings } from "../../../../store/contract";
import emptyChat from "../../../../include/images/chat-empty.svg";
import { toast } from "react-toastify";
import AlertError from "../../../../common/alerts/alertError";

const ViewListings = (props) => {
  const { show, onHide } = props;

  const [listings, setListings] = useState([]);
  const [selectedType, setSelectedType] = useState({
    name: "Active",
    value: props.selectedItem?.type === "bu" ? "active" : "listings",
  });
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState("");
  const [loading, setLoading] = useState(false);

  const enterpriseOptions = [
    { name: "Pending", value: "pending" },
    { name: "Active", value: "listings" },
    { name: "Invoiced", value: "invoiced" },
    { name: "Archived", value: "archived" },
    { name: "Closed", value: "closed" },
    { name: "Sold", value: "sold" },
  ];

  const buOptions = [
    { name: "Pending", value: "pending" },
    { name: "Active", value: "active" },
    { name: "Archived", value: "archive" },
    { name: "Closed", value: "closed" },
    { name: "Sold", value: "sold" },
  ];

  const fetchData = (p = page) => {
    if (props.show && selectedType?.value) {
      if (props.selectedItem?.type === "bu") {
        setLoading(true);
        const params = {
          page: p,
          status: selectedType?.value,
        };
        setPage(p);
        props.getBUListings(props.selectedItem?.buId, params, (res) => {
          if (res && res.status === 200) {
            setListings(res?.data?.data);
            setTotalPage(res?.data?.max_pages);
            setLoading(false);
          } else {
            toast(<AlertError message={res?.data?.message ? res?.data?.message : "Something went wrong"} />);
          }
        });
      } else if (props.selectedItem?.type === "enterprise") {
        setLoading(true);
        const params = {
          page: p,
          aObject: selectedType?.value,
          list_type:
            selectedType?.value === "listings"
              ? 0
              : selectedType?.value === "pending"
              ? 1
              : selectedType?.value === "invoiced"
              ? 2
              : selectedType?.value === "closed"
              ? 4
              : selectedType?.value === "archived"
              ? 15
              : selectedType?.value === "sold" && 16,
        };
        setPage(p);
        props.getEnterpriseListings(props.selectedItem?.enterpriseId, params, (res) => {
          if (res && res.status === 200) {
            setListings(res?.data?.data);
            setTotalPage(res?.data?.max_pages);
            setLoading(false);
          }
        });
      }
    }
  };

  useEffect(() => {
    setListings([]);
  }, [selectedType]);

  useEffect(() => {
    fetchData(1);
  }, [props?.selectedItem?.type, selectedType]);

  useEffect(() => {
    if (!props.show) {
      setSelectedType({ name: "", value: "" });
      setPage(1);
    } else {
      if (props.selectedItem?.type === "bu") {
        setSelectedType({ name: "Active", value: "active" });
      } else if (props.selectedItem?.type === "enterprise") {
        setSelectedType({ name: "Active", value: "listings" });
      }
    }
  }, [props.show]);

  return (
    <Offcanvas
      placement="end"
      backdropClassName="upload-documnets-backdrop"
      show={show}
      onHide={() => onHide()}
      className="offcanvas offcanvas-end border-0"
      id="uploadDocumentOffcanvas"
      enforceFocus={false}
    >
      <Offcanvas.Header className="offcanvas-header">
        <h5 class="offcanvas-title">View Listings</h5>
        <button
          type="button"
          class="offcanvas-close-btn d-flex align-items-center justify-content-center"
          onClick={() => onHide()}
        >
          <img src={close} alt="" />
        </button>
      </Offcanvas.Header>
      <Offcanvas.Body className="offcanvas-body p-0">
        <div class="offcanvas-widget-row">
          <div>
            <div className="mb-4  d-flex align-items-center">
              <label class="form-label " for="industry" style={{ marginRight: "55px" }}>
                Listing Status
              </label>
              {props.selectedItem?.type === "bu" ? (
                <Select
                  options={buOptions}
                  value={selectedType}
                  className="basic-single country-margin select-background"
                  classNamePrefix="select-search country-margin select-background"
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.value}
                  onChange={(e) => {
                    setSelectedType(e);
                  }}
                  name="filter"
                  placeholder="Select Listing Status"
                  styles={customStyles}
                />
              ) : (
                <Select
                  options={enterpriseOptions}
                  value={selectedType}
                  className="basic-single country-margin select-background"
                  classNamePrefix="select-search country-margin select-background"
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.value}
                  onChange={(e) => {
                    setSelectedType(e);
                  }}
                  name="filter"
                  placeholder="Select Listing Status"
                  styles={customStyles}
                />
              )}
            </div>
          </div>
          <div class="table-responsive mb-3">
            <table
              class={`table align-middle table-borderless publicDocTable mb-0 ${
                !loading ? "contract-listings-item" : ""
              }`}
            >
              <colgroup>
                <col />
              </colgroup>
              <tbody>
                {!loading && listings && listings.length > 0
                  ? listings.map((item, i) => (
                      <>
                        <tr>
                          <td className="mb-5 link-item  ">
                            <a href={`/listing-detail?id=${item?.auction_id}`} target="_blank">
                              {item?.auction_id} - {item?.title ? item?.title : "N/A"}
                            </a>
                          </td>
                        </tr>
                      </>
                    ))
                  : !loading && (
                      <>
                        <div class="empty-pannel-box mt-5">
                          <img src={emptyChat} alt="" />
                          <h4>No Listings Found!</h4>
                        </div>
                      </>
                    )}
                {loading
                  ? [1, 23, 4, 5, 24].map((item) => (
                      <tr>
                        <td>
                          <Skeleton height="40px" />
                        </td>
                      </tr>
                    ))
                  : ""}
              </tbody>
            </table>
          </div>
          {totalPage > 1 && !loading && (
            <div class="table-pagination">
              <ul class="pagination  justify-content-end">
                <li class="page-item">
                  <a
                    class={`page-link ${page === 1 ? "disabled" : ""}`}
                    href="#"
                    aria-label="Previous"
                    onClick={() => {
                      if (page !== 1) {
                        fetchData(page - 1);
                      }
                    }}
                  >
                    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7 13L1 7L7 1" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </a>
                </li>
                <li class="page-item active">
                  <a class="page-link" href="javascript:void(0)">
                    {page}
                  </a>
                </li>
                {totalPage > 1 && page + 1 <= totalPage && (
                  <li class="page-item">
                    <a class="page-link" href="#" onClick={() => fetchData(page + 1)}>
                      {page + 1}
                    </a>
                  </li>
                )}
                <li class="page-item">
                  <a
                    class={`page-link ${page + 1 >= totalPage ? "disabled" : ""}`}
                    href="#"
                    aria-label="Next"
                    onClick={() => fetchData(page + 1)}
                  >
                    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 13L7 7L1 1" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
          )}
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getEnterpriseListings: (id, params, callback) => dispatch(getEnterpriseListings(id, params, callback)),
  getBUListings: (id, params, callback) => dispatch(getBUListings(id, params, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(React.memo(ViewListings));
