import React from "react";
import close from "../../../include/images/close-12x12.svg";
import { Modal } from "react-bootstrap";

const ViewPartDetails = (props) => {
  const d = new Date();
  let diff = d.getTimezoneOffset();
  console.log("jdj", props.selectedValue);
  return (
    <Modal
      show={props.show}
      onHide={() => props.onHide()}
      className="modal fade salvex-modal2 av_viewall_modal"
      id="aviationParts"
      dialogClassName="modal-dialog  modal-xl modal-dialog-centered"
      contentClassName="modal-content"
    >
      <div class="custom-modal-header d-flex align-items-center justify-content-between">
        <h5 class="mb-0 text-capitalize">Part Details</h5>
        <button
          type="button"
          class="close-btn d-flex align-items-center justify-content-center"
          onClick={() => props.onHide()}
        >
          <img src={close} alt="" />
        </button>
      </div>

      <Modal.Body className="modal-body aviation-view-body">
        <table class={` table  table-borderless aviation-viewall-table`}>
          <tr>
            <td className="aviation-view-modal aviation-view-direction">
              <span>Listing ID</span>
            </td>
            <td>:</td>
            <td className="aviation-view-modal1 aviation-view-direction">
              <span>
                {props.selectedValue?.auctionId ? (
                  <a
                    className="aviation-auction link-item"
                    href={`/listing-detail?id=${props?.selectedValue?.auctionId}`}
                    target="_blank"
                  >
                    {props?.selectedValue?.auctionId}
                  </a>
                ) : (
                  "N/A"
                )}
              </span>
            </td>
          </tr>
          <tr>
            <td className="aviation-view-modal aviation-view-direction">
              <span>Part Number</span>
            </td>
            <td>:</td>
            <td className="aviation-view-modal1 aviation-view-direction">
              <span>{props.selectedValue?.partNumber ? props.selectedValue?.partNumber : "N/A"}</span>
            </td>
          </tr>
          <tr>
            <td className="aviation-view-modal aviation-view-direction">
              <span>Part Description</span>
            </td>
            <td>:</td>
            <td className="aviation-view-modal1 aviation-view-direction">
              <span>{props.selectedValue?.partDescription ? props.selectedValue?.partDescription : "N/A"}</span>
            </td>
          </tr>
          <tr>
            <td className="aviation-view-modal aviation-view-direction">
              <span>Condition</span>
            </td>
            <td>:</td>
            <td className="aviation-view-modal1 aviation-view-direction">
              <span>{props.selectedValue?.condition ? props.selectedValue?.condition : "N/A"}</span>
            </td>
          </tr>
          <tr>
            <td className="aviation-view-modal aviation-view-direction">
              <span>Quantity</span>
            </td>
            <td>:</td>
            <td className="aviation-view-modal1 aviation-view-direction">
              <span>{props.selectedValue?.quantity ? props.selectedValue?.quantity : "N/A"}</span>
            </td>
          </tr>
          <tr>
            <td className="aviation-view-modal aviation-view-direction">
              <span>Location</span>
            </td>
            <td>:</td>
            <td className="aviation-view-modal1 aviation-view-direction">
              <span>{props?.selectedValue?.location ? props?.selectedValue?.location : "N/A"}</span>
            </td>
          </tr>
          <tr>
            <td className="aviation-view-modal aviation-view-direction">
              <span>Part Manufacturer</span>
            </td>
            <td>:</td>
            <td className="aviation-view-modal1 aviation-view-direction">
              <span>{props?.selectedValue?.partManufacturer ? props?.selectedValue?.partManufacturer : "N/A"}</span>
            </td>
          </tr>
          <tr>
            <td className="aviation-view-modal aviation-view-direction">
              <span>Part Model</span>
            </td>
            <td>:</td>
            <td className="aviation-view-modal1 aviation-view-direction">
              <span>
                {props?.selectedValue?.partModels?.length > 0
                  ? props?.selectedValue?.partModels?.map((val) => val).join(", ")
                  : props?.selectedValue?.partModels?.length != 0
                  ? props?.selectedValue?.partModels
                  : "N/A"}
              </span>
            </td>
          </tr>

          <tr>
            <td className="aviation-view-modal aviation-view-direction">
              <span>Grade</span>
            </td>
            <td>:</td>
            <td className="aviation-view-modal1 aviation-view-direction">
              <span>{props?.selectedValue?.grade ? props?.selectedValue?.grade : "N/A"}</span>
            </td>
          </tr>

          <tr>
            <td className="aviation-view-modal aviation-view-direction">
              <span>Serial Number</span>
            </td>
            <td>:</td>
            <td className="aviation-view-modal1 aviation-view-direction">
              <span>{props?.selectedValue?.serialNumber ? props?.selectedValue?.serialNumber : "N/A"}</span>
            </td>
          </tr>
          <tr>
            <td className="aviation-view-modal aviation-view-direction">
              <span>AI Product Description</span>
            </td>
            <td>:</td>
            <td className="aviation-view-modal1 aviation-view-direction">
              <span>
                {props?.selectedValue?.aiProductDescription ? props?.selectedValue?.aiProductDescription : "N/A"}
              </span>
            </td>
          </tr>
          <tr>
            <td className="aviation-view-modal aviation-view-direction">
              <span>AI Manufacturer Description</span>
            </td>
            <td>:</td>
            <td className="aviation-view-modal1 aviation-view-direction">
              <span>
                {props?.selectedValue?.aiManufactureDescription
                  ? props?.selectedValue?.aiManufactureDescription
                  : "N/A"}
              </span>
            </td>
          </tr>
          <tr>
            <td className="aviation-view-modal aviation-view-direction">
              <span>Serial Number</span>
            </td>
            <td>:</td>
            <td className="aviation-view-modal1 aviation-view-direction">
              <span>{props?.selectedValue?.serialNumber ? props?.selectedValue?.serialNumber : "N/A"}</span>
            </td>
          </tr>
          <tr>
            <td className="aviation-view-modal aviation-view-direction">
              <span>Notes 1</span>
            </td>
            <td>:</td>
            <td className="aviation-view-modal1 aviation-view-direction">
              <span>{props?.selectedValue?.notes1 ? props?.selectedValue?.notes1 : "N/A"}</span>
            </td>
          </tr>
          <tr>
            <td className="aviation-view-modal aviation-view-direction">
              <span>Notes 2</span>
            </td>
            <td>:</td>
            <td className="aviation-view-modal1 aviation-view-direction">
              <span>{props?.selectedValue?.notes2 ? props?.selectedValue?.notes2 : "N/A"}</span>
            </td>
          </tr>
          <tr>
            <td className="aviation-view-modal aviation-view-direction">
              <span>Notes 3</span>
            </td>
            <td>:</td>
            <td className="aviation-view-modal1 aviation-view-direction">
              <span>{props?.selectedValue?.notes3 ? props?.selectedValue?.notes3 : "N/A"}</span>
            </td>
          </tr>
          <tr>
            <td className="aviation-view-modal aviation-view-direction">
              <span>Notes 4</span>
            </td>
            <td>:</td>
            <td className="aviation-view-modal1 aviation-view-direction">
              <span>{props?.selectedValue?.notes4 ? props?.selectedValue?.notes4 : "N/A"}</span>
            </td>
          </tr>
          <tr>
            <td className="aviation-view-modal aviation-view-direction">
              <span>Notes 5</span>
            </td>
            <td>:</td>
            <td className="aviation-view-modal1 aviation-view-direction">
              <span>{props?.selectedValue?.notes5 ? props?.selectedValue?.notes5 : "N/A"}</span>
            </td>
          </tr>
        </table>
      </Modal.Body>
    </Modal>
  );
};

export default ViewPartDetails;
