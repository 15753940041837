import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import close from "../../../include/images/close.svg";
import NextButton from "../../../common/form/nextButton";
import { validateErrorMessage } from "../../../common/validation/validation";
import Joi from "joi";
import _ from "lodash";
import { useRef } from "react";
import { connect } from "react-redux";
import { getAgreementDetail, primarySellerData, sendAgreement, sendAgreements } from "../../../store/primarySeller";
import { toast } from "react-toastify";
import AlertError from "../../../common/alerts/alertError";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { dashboardData, getListingAgreement } from "../../../store/dashBoard";

const SellerAgreementModal = (props) => {
  const datas = props?.dashboardData?.listingAgreement?.data;
  const userAuctions = props?.dashboardData?.userAuctions?.data;
  const userAuctionData = props?.primarySellerData?.userAuctions?.data || [];
  const ref = useRef();
  const history = useHistory();
  const [data, setData] = useState({
    email: "",
    name: "",
    title: "",
    company: "",
    property: "",
    agreementType: "0",
    auctionId: "",
    userId: "",
    auctionTitle: "",
  });

  const [errors, setErrors] = useState({});
  const [listingsSelected, setListingsSelected] = useState([]);

  const schemaDoc = Joi.object({
    email: Joi.string()
      .label("Email")
      .required()
      .error((errors) => validateErrorMessage(errors)),
    name: Joi.string()
      .label("Name")
      .required()
      .error((errors) => validateErrorMessage(errors)),
    title: Joi.string()
      .label("Title")
      .error((errors) => validateErrorMessage(errors)),
    company: Joi.string()
      .label("Company")
      .required()
      .error((errors) => validateErrorMessage(errors)),
    property: Joi.string()
      .label("Property")
      .required()
      .error((errors) => validateErrorMessage(errors)),
    agreementType: Joi.string()
      .label("Agreement Type")
      .required()
      .error((errors) => validateErrorMessage(errors)),
    auctionId: Joi.number()
      .label("Auction Id")
      .allow("")
      .error((errors) => validateErrorMessage(errors)),
    userId: Joi.number()
      .label("User Id")
      .allow("")
      .error((errors) => validateErrorMessage(errors)),
    auctionTitle: Joi.string()
      .label("Auction Title")
      .allow("")
      .error((errors) => validateErrorMessage(errors)),
  });

  const validateDoc = (type = false) => {
    const error = schemaDoc.validate(_.pickBy(data, _.identity), {
      abortEarly: type,
    });
    if (error.error && error.error.details) {
      const errors = {};
      error.error.details.forEach((element) => {
        errors[element.context.key] = element.message;
      });
      setErrors(errors);
      return false;
    }
    setErrors("");
    return true;
  };

  const [loading, setLoading] = useState(false);

  const onChange = (e) => {
    const value = { ...data };
    value[e.target.name] = e.target.value;
    setData(value);
  };

  useEffect(() => {
    if (props?.show && props?.auctionId) {
      setListingsSelected([...userAuctionData]);
    }
  }, [userAuctionData]);

  useEffect(() => {
    if (props.show && props.auctionId) {
      props.refStart();
      setLoading(true);
      const id = props.auctionId;

      props.getAgreementDetails(id, (res) => {
        if (res && res.status == 200) {
          const a = res?.data?.data;
          const value = { ...data };
          value.email = a.seller_email;
          value.name = a.seller_name;
          value.title = a.seller_title;
          value.company = a.seller_company;
          value.auctionId = a.auction_id;
          value.userId = a.user_id;
          value.auctionTitle = a.title;
          value.property = `${a.auction_id} - ${a.title}`;
          setData(value);
        } else {
          toast(<AlertError message={res?.data?.message ? res?.data?.message : "Something went wrong"} />);
        }
        setLoading(false);
        props.refStop();
      });
    }
  }, [props.auctionId, props.show]);

  const sendAgreement = () => {
    if (!validateDoc()) return;
    props.refStart();
    setLoading(true);
    const id = data.auctionId;
    if (props?.allAgreements) {
      const auctiondetails = listingsSelected?.map((item) => ({
        auctionId: item?.auction_id,
        property: `${item?.auction_id}-${item?.title}`,
      }));
      const payload = {
        auctions: auctiondetails,
        userId: data?.userId ? data?.userId : "",
        name: data?.name ? data?.name : "",
        title: data?.title ? data?.title : "",
        company: data?.company ? data?.company : "",
        email: data?.email ? data?.email : "",
        agreementType: data?.agreementType ? data?.agreementType : "",
      };
      props.sendAgreements(payload, (res) => {
        if (res && res.status === 200) {
          props?.getListingAgreement({ auctionId: props?.auctionId, sort: "createdAt", order: "desc" }, (res) => {});
          setLoading(false);
          props.refStop();
          props.onHide();
          toast(<AlertSuccess message={"Information Saved"} />);
        } else {
          setLoading(false);
          props.refStop();
          toast(<AlertError message={res?.data?.message ? res?.data?.message : "Something went wrong"} />);
        }
      });
    } else {
      const payload = {
        property: data?.property ? data.property : "",
        userId: data?.userId ? data?.userId : "",
        name: data?.name ? data?.name : "",
        title: data?.title ? data?.title : "",
        company: data?.company ? data?.company : "",
        email: data?.email ? data?.email : "",
        agreementType: data?.agreementType ? data?.agreementType : "",
      };
      props.sendAgreement(id, payload, (res) => {
        if (res && res.status === 200) {
          props?.getListingAgreement({ auctionId: props?.auctionId, sort: "createdAt", order: "desc" }, (res) => {});
          setLoading(false);
          props.refStop();
          props.onHide();
          toast(<AlertSuccess message={"Information Saved"} />);
        } else {
          setLoading(false);
          props.refStop();
          toast(<AlertError message={res?.data?.message ? res?.data?.message : "Something went wrong"} />);
        }
      });
    }
  };

  return (
    <Modal
      className="modal fade custom-modal"
      id="moreEmailClicker"
      show={props.show}
      onHide={props.onHide}
      dialogClassName="modal-dialog modal-dialog-centered modal-lg"
    >
      <div class="modal-content d-flex flex-column">
        <div class="custom-modal-header d-flex align-items-center justify-content-between">
          <h5 class="mb-0 text-capitalize">
            {props?.allAgreements ? "Send All Listing Agreements" : "Send Listing Agreement"}
          </h5>
          <button
            type="button"
            class="close-btn d-flex align-items-center justify-content-center"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={props.onHide}
          >
            <img src={close} alt="close" />
          </button>
        </div>
        <div class="custom-modal-body flex-grow-1 pb-0">
          <div class="mb-20">
            <label for="documentTitle" class="form-label">
              Email Address
            </label>
            <input type="text" name="email" class="form-control" value={data.email} onChange={onChange} />
            {errors && errors.email ? <p className="error-text">{errors.email}</p> : ""}
          </div>
          <div class="mb-20">
            <label for="documentTitle" class="form-label">
              Name
            </label>
            <input type="text" name="name" class="form-control" value={data.name} onChange={onChange} />
            {errors && errors.name ? <p className="error-text">{errors.name}</p> : ""}
          </div>
          <div class="mb-20">
            <label for="documentTitle" class="form-label">
              Title
            </label>
            <input type="text" name="title" class="form-control" value={data.title} onChange={onChange} />
            {errors && errors.title ? <p className="error-text">{errors.title}</p> : ""}
          </div>
          <div class="mb-20">
            <label for="documentTitle" class="form-label">
              Company
            </label>
            <input type="text" name="company" class="form-control" value={data.company} onChange={onChange} />
            {errors && errors.company ? <p className="error-text">{errors.company}</p> : ""}
          </div>
          <div class="mb-20">
            <label for="documentTitle" class="form-label" onChange={onChange}>
              Property
            </label>
            {props?.allAgreements ? (
              userAuctionData?.map((yr, i) => (
                <div className="d-flex align-items-start">
                  <div class="form-check d-inline-block dark-tick-checkbox" style={{ marginTop: "6px" }}>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id={`seller-agrrement-${i + 1}`}
                      checked={listingsSelected.some((er) => er?.auction_id === yr?.auction_id)}
                      onClick={() => {
                        const isPresent = listingsSelected.some((er) => er?.auction_id === yr?.auction_id);
                        if (isPresent) {
                          const newListingsArray = listingsSelected?.filter((jv) => jv?.auction_id != yr?.auction_id);
                          setListingsSelected(newListingsArray);
                        } else {
                          const newListingsArray = [...listingsSelected];
                          newListingsArray.push(yr);
                          setListingsSelected(newListingsArray);
                        }
                      }}
                    />
                    <label class="form-check-label" for={`seller-agrrement-${i + 1}`}></label>
                  </div>
                  <span className=" multiple-listing-agreement-descp">
                    {yr.auction_id}-{yr.title}
                  </span>
                </div>
              ))
            ) : (
              <input type="text" name="property" disabled class="form-control" value={data.property} />
            )}
            {errors && errors.property ? <p className="error-text">{errors.property}</p> : ""}
          </div>
          <div className="mb-20">
            <label htmlFor="sa_options" className="form-label">
              Agreement Type
            </label>
            <div class="four-column-radio-group mb-4 gap-user ">
              <div class="fcrg-item  payment-info-main-option">
                <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
                  <input
                    class="form-check-input cursor-pointer"
                    type="radio"
                    name="sa_agreement_type"
                    id="sa_WTflexRadio__31"
                    checked={data.agreementType === "0" ? true : false}
                    onChange={(e) => {
                      const value = { ...data };
                      value.agreementType = "0";
                      setData(value);
                    }}
                  />
                  <label class="form-check-label cursor-pointer" for="sa_WTflexRadio__31">
                    Buyer Premium
                  </label>
                </div>
              </div>
              <div class="fcrg-item  payment-info-main-option">
                <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
                  <input
                    class="form-check-input cursor-pointer"
                    type="radio"
                    name="sa_agreement_type"
                    id="sa_WTflexRadio__32"
                    checked={data.agreementType === "1" ? true : false}
                    onChange={(e) => {
                      const value = { ...data };
                      value.agreementType = "1";
                      setData(value);
                    }}
                  />

                  <label class="form-check-label cursor-pointer" for="sa_WTflexRadio__32">
                    Hyrid
                  </label>
                </div>
              </div>
              <div class="fcrg-item  payment-info-main-option">
                <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
                  <input
                    class="form-check-input cursor-pointer"
                    type="radio"
                    name="sa_agreement_type"
                    id="sa_WTflexRadio__33"
                    checked={data.agreementType === "2" ? true : false}
                    onChange={(e) => {
                      const value = { ...data };
                      value.agreementType = "2";
                      setData(value);
                    }}
                  />

                  <label class="form-check-label cursor-pointer" for="sa_WTflexRadio__33">
                    Seller 10%
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="custom-modal-dividor"></div>
        <div class="custom-modal-footer d-grid gap-2 d-md-flex align-items-center justify-content-md-end">
          <a
            href={"javascript:void(0)"}
            class={`btn btn-default sa_preview_btn ${
              loading || (props?.allAgreements && listingsSelected?.length == 0) ? "disabled" : ""
            }`}
            onClick={() => {
              if (props?.allAgreements) {
                if (!validateDoc()) return;
                window.open(`/seller-agreement/view?auction_id=${data?.auctionId}&name=${
                  data?.name
                }&title=${data?.title?.replace("&", "and")}&company=${data?.company}&preview=true&type=${
                  data?.agreementType
                }&multi=true&property=${listingsSelected
                  ?.map((item) => `${item?.auction_id}---${item?.title.replace(/[^a-zA-Z ]/g, "")}`)
                  .join(",,,")}
                `);
              } else {
                if (!validateDoc()) return;
                window.open(`/seller-agreement/view?auction_id=${data?.auctionId}&name=${
                  data?.name
                }&title=${data?.title?.replace("&", "and")}&company=${data?.company}&property=${
                  data?.property
                }&preview=true&type=${data?.agreementType}
                `);
              }
            }}
          >
            Preview
          </a>
          <NextButton
            label="Send Email"
            classData="btn btn-default"
            loading={loading}
            disable={props?.allAgreements && listingsSelected?.length == 0 ? true : false}
            handleSubmit={sendAgreement}
          />
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  primarySellerData: primarySellerData(state),
  dashboardData: dashboardData(state),
});

const mapDispatchToProps = (dispatch) => ({
  getAgreementDetails: (id, callback) => dispatch(getAgreementDetail(id, callback)),
  sendAgreement: (id, data, callback) => dispatch(sendAgreement(id, data, callback)),
  sendAgreements: (data, callback) => dispatch(sendAgreements(data, callback)),
  getListingAgreement: (params, callback) => dispatch(getListingAgreement(params, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(SellerAgreementModal));
