import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import moment from "moment";
import { connect } from "react-redux";
import { checkToken, getProfile } from "../../utils.js/localStorageServices";
import { previewAgreement, previewAgreements, sendAgreements, signAgreement } from "../../store/primarySeller";
import { useRef } from "react";
import LoadingBar from "react-top-loading-bar";
import { toast } from "react-toastify";
import AlertError from "../../common/alerts/alertError";
import logo from "../../include/images/logo.svg";

const SellerAgreement = (props) => {
  const ref = useRef();
  const reportTemplateRef = useRef(null);
  const location = useLocation();
  const history = useHistory();
  const [data, setData] = useState({
    entity: "",
    name: "",
    title: "",
    company: "",
    property: "",
    categoryId: "",
    logo: "salvex-logo.png",
    agreementType: "",
    preview: false,
  });
  const [loading, setLoading] = useState(false);
  const [entity, setEntity] = useState([]);
  const profile = getProfile();

  useEffect(() => {
    const a = location.search;
    const qs = queryString.parse(location.search);
    const hs = queryString.parse(location.hash);
    if (qs?.preview || hs?.preview) {
      if (checkToken()) {
        setLoading(true);
        ref?.current?.continuousStart();
        if (qs?.multi == "true") {
          const a = queryString.parse(location?.search);
          const auctionDetails = a.property.split(",,,")?.map((item) => ({
            auctionId: item?.split("---")?.[0],
            property: `${item?.split("---")?.[0]}-${item?.split("---")?.[1]}`,
          }));

          const payload = {
            auctions: auctionDetails,
            company: a?.company,
            name: a?.name,
            title: a?.title && a?.title !== "undefined" ? a?.title : "",
          };
          if (payload.auction_id) {
            delete payload.auction_id;
          }
          if (payload.preview) {
            delete payload.preview;
          }
          if (payload.type) {
            delete payload.type;
          }
          props.previewAgreements(payload, (res) => {
            if (res && res.status === 200) {
              const pre = payload?.preview ? false : true;
              setData({
                isMulti: true,
                data: res.data.data,
                preview: true,
                type: queryString.parse(location.search)?.type
                  ? queryString.parse(location.search)?.type
                  : queryString.parse(location?.hash)?.type,
              });
              const entityList = res?.data?.data?.map((it) => it?.entity);
              const uniqueEntityList = new Set(entityList);
              const entityArray = Array.from(uniqueEntityList);
              setEntity(entityArray);
              setLoading(false);
              ref?.current?.complete();
            } else {
              history.push("/404");
              setLoading(false);
              ref?.current?.complete();
            }
          });
        } else {
          const a = queryString.parse(location?.search);
          const payload = {
            company: a?.company,
            name: a?.name,
            property: a?.property,
            title: a?.title && a?.title !== "undefined" ? a?.title : "",
            auctionId: a?.auction_id,
          };

          if (payload.preview) {
            delete payload.preview;
          }
          if (payload.type) {
            delete payload.type;
          }
          props.previewAgreement(payload, (res) => {
            if (res && res.status === 200) {
              const pre = payload?.preview ? false : true;
              setData({
                ...res?.data?.data,
                preview: true,
                type: queryString.parse(location.search)?.type
                  ? queryString.parse(location.search)?.type
                  : queryString.parse(location?.hash)?.type,
              });
              setLoading(false);
              ref?.current?.complete();
            } else {
              history.push("/404");
              setLoading(false);
              ref?.current?.complete();
            }
          });
        }
      } else {
        history.push("/signin");
      }
    } else {
      if (location?.search?.u) {
        const a = queryString.parse(location.search);
        const payload = {
          encryptedData: a?.u,
          aeId: a?.aeId,
          agreementType: a?.type,
          signed: false,
        };
        props.signAgreement(payload, (res) => {
          if (res && res.status === 200) {
            setData({
              ...res?.data?.data,
              preview: false,
            });
            setLoading(false);
            ref?.current?.continuousStart();
          } else {
            history.push("/404");
            setLoading(false);
            ref?.current?.continuousStart();
            toast(<AlertError message={res?.data?.message ? res?.data?.message : "Something went wrong"} />);
          }
        });
      }
    }
  }, []);

  return (
    <>
      <LoadingBar height={5} color="#47AD1D" ref={ref} />
      {data?.type ? (
        <div className="seller-agreement-container">
          <div ref={reportTemplateRef}>
            <div className="sa_logo">
              <img src={logo} alt="logo" />
            </div>
            <div className="sa_heading">
              <h1>SELLER LISTING AGREEMENT</h1>
            </div>
            <div className="sa_content">
              <p>
                In consideration of the mutual covenants and agreements herein contained{" "}
                {data?.isMulti
                  ? data?.data?.[0]?.company
                    ? data?.data?.[0]?.company
                    : "N/A"
                  : data?.company
                  ? data?.company
                  : "N/A"}{" "}
                (“
                <span className="sa_bold">Seller</span>") hereby appoints{" "}
                {data?.isMulti ? entity?.map((ue) => ue).join(", ") : data?.entity} (“
                <span className="sa_bold">SALVEX</span>
                "), as the agent for the sale of products that Seller owns or is authorized to sell, (“
                <span className="sa_bold">Products</span>"), and SALVEX and Seller agree as follows.
              </p>
              <div className="sa_condition">
                <ol type="1">
                  <li>
                    Seller hereby authorizes and empowers SALVEX, during the term of this agreement:
                    <ol type="a">
                      <li> to list and advertise the Products for sale; and</li>
                      <li> to access and provide demonstration of the Products to potential buyers.</li>
                      <li>
                        {" "}
                        The term of this agreement shall be from the date set forth below and shall continue for a
                        period of three (3) months thereafter (“<span className="sa_bold">Initial Term</span>"). Upon
                        expiration of the Initial Term, the term of this Agreement will automatically renew for
                        additional successive three (3) month terms unless either party provides written notice to the
                        other party of non-renewal at least thirty (30) days’ notice of termination prior to the then
                        current term (the “<span className="sa_bold">Term</span>"). Either party may terminate this
                        Agreement with 30 days’ prior written notice, such notice to be effective as of the date set
                        forth therein.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Seller represents and warrants to SALVEX that:
                    <ol type="a">
                      <li>
                        Seller has the right, power, and authority to sell good and marketable title to the Products and
                        will discharge all liens and encumbrances affecting the Products prior to the sale thereof,
                        unless otherwise agreed by buyer.
                      </li>
                      <li>
                        Seller will make the Products available for inspection for potential buyers identified by
                        SALVEX.
                      </li>
                      <li>
                        Seller has not failed to disclose any material fact to SALVEX and all information provided by
                        Seller to SALVEX, whether provided herein or otherwise regarding the Products, is and will be
                        accurate and correct.
                      </li>
                      <li>
                        {" "}
                        During the Term, SALVEX shall be the exclusive company or person providing auction, sale or
                        disposition services regarding the Products. Any solicitations for purchase of the products
                        received by the Seller shall be referred to SALVEX.
                      </li>
                      <li>
                        Seller acknowledges that SALVEX may show potential buyers other products listed on the Salvex
                        Marketplace, which shall not constitute a breach of this Agreement.
                      </li>
                    </ol>
                  </li>
                  <li>
                    SALVEX agrees to:
                    <ol type="a">
                      <li>
                        Use best efforts to list the Products on its online Marketplace and promote the Product listing
                        through the SALVEX buyer database during the Term of this Agreement for the purpose of
                        soliciting offers from potential buyers.
                      </li>
                      <li>
                        To market, advertise, promote and sell the Products consistent with good business practice, in
                        each case using its commercially reasonable efforts to maximize the sales of the Products;
                      </li>
                      <li>
                        To market, advertise, promote and sell Products and conduct business in a manner that reflects
                        favorably at all times on Products and the good name, goodwill, and reputation of Seller;
                      </li>
                    </ol>
                  </li>
                  <li>
                    Seller may exercise its sole discretion to:
                    <ol type="a">
                      <li>Establish the pricing advertised for all Products listed on the Salvex Marketplace;</li>
                      <li>Refuse the sale of the Products to any potential buyer.</li>
                      <li>
                        Any attempt by Seller to approach a potential buyer directly, upon learning the potential
                        buyer’s identity through SALVEX, shall constitute a breach of the Non-Circumvention clause of
                        this Agreement.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Seller agrees to indemnify, defend, and hold harmless SALVEX against and from all claims, actions,
                    suits, liabilities, costs, and expenses arising from a breach of this agreement (including, without
                    limitation, the representations and warranties of Seller set forth herein).
                  </li>
                  <li>
                    SALVEX does not assume, and is not delegated, care, custody, or control of the Products. Seller
                    assumes sole risk of, and shall keep Products insured against, all loss, damage or destruction of
                    the Products, or any part thereof, from any cause whatsoever.
                  </li>
                  {data?.type == 2 ? (
                    <li>
                      SALVEX shall be entitled to compensation (the “<strong>SALVEX Service Fees</strong>”) equal to ten
                      per cent (10%) of the total sales price paid by a Buyer in exchange for the SALVEX Services,
                      including but not limited to: Asset Recovery Services; online listing of assets for sale and RFP
                      (request for proposals); bid collection and delivery to Seller.
                      <ol type="a">
                        <li>
                          SALVEX Service Fees are based on the gross proceeds of the sale of the Products achieved
                          through the SALVEX Services, as well as the level of consulting expertise required to assist
                          buyer in shipping the Products.
                        </li>
                        <li>
                          SALVEX Services shall be at the Buyer’s cost and will be automatically reduced from the
                          Buyer’s total bid resulting in the “<span className="sa_bold">Bid Amount</span>”, which shall
                          be the total proceeds remitted to Seller for the Products.
                        </li>
                        <li>
                          Upon Buyer’s payment to the Seller for the Products, SALVEX will invoice the Seller for the
                          Salvex Service Fees. Seller shall transfer the SALVEX Service Fees to SALVEX within 48 hours
                          of receiving the Bid Amount.
                        </li>
                        <li>
                          Failure of the Seller to release the Property to the Buyer after receiving the full Bid Amount
                          will subject the Seller to payment to SALVEX of the SALVEX Service Fees and further subject
                          the Seller to other claims, damages, or liabilities of the buyer of the Products.
                        </li>
                      </ol>
                    </li>
                  ) : data?.type == 1 ? (
                    <li>
                      SALVEX shall be compensated for services provided to manage the sale of Products on the SALVEX
                      marketplace and identify a buyer (the “ <strong>SALVEX Service Fees</strong>”);
                      <ol type="a">
                        <li>
                          SALVEX Service Fees are based on the gross proceeds of the sale of the Products achieved
                          through the SALVEX Services, as well as the level of consulting expertise required to assist
                          buyer in shipping the Products.
                        </li>
                        <li>
                          SALVEX Service Fees shall be collected as a Buyer Premium at the Buyer's cost and will be
                          automatically reduced from the Buyer's total bid resulting in the "
                          <span className="sa_bold">Bid Amount</span>" which shall be the total proceeds offered to
                          Seller for the Products.
                        </li>
                        <li>
                          Upon the successful sale of the Products, Salvex shall also be entitled to a Commission equal
                          ten per cent (10%) of the total sales price which shall be deducted from the Bid Amount
                          remitted to the Seller.
                        </li>
                        <li>
                          Upon Buyer’s payment to the Seller for the Products, SALVEX will invoice the Seller for the
                          Salvex Service Fees. Seller shall transfer the SALVEX Service Fees to SALVEX within 48 hours
                          of receiving the Bid Amount.
                        </li>
                        <li>
                          Failure of the Seller to release the Property to the Buyer after receiving the full Bid Amount
                          will subject the Seller to payment to SALVEX of the SALVEX Service Fees and further subject
                          the Seller to other claims, damages, or liabilities of the buyer of the Products.
                        </li>
                      </ol>
                    </li>
                  ) : (
                    <li>
                      SALVEX shall be compensated for services provided to manage the sale of Products on the SALVEX
                      marketplace and identify a buyer (the “<span className="sa_bold">SALVEX Service Fees</span>”);
                      <ol type="a">
                        <li>
                          SALVEX Service Fees are based on the gross proceeds of the sale of the Products achieved
                          through the SALVEX Services, as well as the level of consulting expertise required to assist
                          buyer in shipping the Products.
                        </li>
                        <li>
                          SALVEX Services shall be at the Buyer’s cost and will be automatically reduced from the
                          Buyer’s total bid resulting in the “<span className="sa_bold">Bid Amount</span>”, which shall
                          be the total proceeds remitted to Seller for the Products.
                        </li>
                        <li>
                          Upon Buyer’s payment to the Seller for the Products, SALVEX will invoice the Seller for the
                          Salvex Service Fees. Seller shall transfer the SALVEX Service Fees to SALVEX within 48 hours
                          of receiving the Bid Amount.
                        </li>
                        <li>
                          Failure of the Seller to release the Property to the Buyer after receiving the full Bid Amount
                          will subject the Seller to payment to SALVEX of the SALVEX Service Fees and further subject
                          the Seller to other claims, damages, or liabilities of the buyer of the Products.
                        </li>
                      </ol>
                    </li>
                  )}
                  <li>
                    Should Seller become involved in any proceedings to determine, interpret, or enforce the terms or
                    conditions of this Agreement or regarding the sale of the Products, then SALVEX shall be entitled to
                    reimbursement of reasonable attorney’s fees and costs from the other party.
                  </li>
                  <li>
                    Seller will indemnify and hold harmless SALVEX from and against any and all claims, actions, suits,
                    liabilities, costs and expenses which may be asserted against it or which it may incur by reason of
                    its execution or performance in connection with any disputes arising from any transaction related to
                    the sale of the Products.
                  </li>
                  <li>
                    Seller acknowledges each buyer/seller agreement is brokered separately and subject to the full
                    amount of Salvex Service Fees.
                  </li>
                  <li>
                    Seller agrees that Confidential Information shall be kept strictly confidential and shall not be
                    sold, traded, published, or otherwise disclosed to anyone in any manner whatsoever, or photocopied
                    or reproduced in any way, without the Owner's prior written consent. "
                    <span className="sa_bold">Confidential Information</span>" shall mean all written, recorded,
                    electronic or oral information or data provided by SALVEX that is not already known to Seller as of
                    the date of disclosure hereunder and is not already in possession of the public.
                  </li>
                  <li>
                    Non-Circumvention. Seller shall not enter any direct negotiations or transactions with any buyer
                    introduced by Salvex. All communications with Buyer will be intermediated by SALVEX - date and time
                    stamps will be used to establish origin and time of such information as it related to how it could
                    already be in the hands of the other party. In the event of circumvention of this Agreement by
                    either party, directly or indirectly, SALVEX shall be entitled to a legal monetary penalty equal to
                    the maximum service fees it should realize from such a transaction plus any and all expenses,
                    including but not limited to all legal costs and expenses incurred to recover the lost revenue.
                  </li>
                  <li>
                    Dispute and Venue. Any legal action arising out of or relating to the Contract will first be
                    submitted for informal resolution between SALVEX and Seller. Should the parties fail to resolve such
                    dispute within thirty (30) days, the parties shall refer the matter to mediation administered by the
                    American Arbitration Association (“AAA”) in accordance with the AAA’s Commercial Arbitration Rules &
                    Mediation Procedures, as amended and then in effect at the time of the dispute (the “AAA Rules”). As
                    an exception, any dispute over the payment of unpaid, undisputed Commission may proceed directly to
                    binding arbitration as set forth below. Should the parties fail to resolve such dispute through
                    mediation, each party shall have the right to refer the matter to arbitration. The arbitration shall
                    be administered by the AAA in accordance with the AAA Rules. The parties shall agree on three
                    neutral arbitrators that are qualified by education and experience to address the matter in the
                    dispute. In the absence of such agreement within ten (10) days after the initiation of an
                    arbitration proceeding, the AAA shall determine the arbitrators. The arbitrators shall have power
                    and authority to award any remedy or judgment that could be awarded by a court of law, except the
                    arbitrators shall not have power to award damages in connection with any dispute in excess of actual
                    compensatory damages and shall not multiply actual damages or award consequential or punitive
                    damages. The award rendered by arbitration shall be final and binding upon the parties, and judgment
                    upon the award may be entered in any court of competent jurisdiction in the United States. The
                    governing law for the arbitration shall be the law of the State of Texas, without reference to its
                    conflicts of law provisions. The prevailing party of any arbitration shall be entitled to recover
                    its reasonable legal costs and expenses to bring and maintain any such suit as part of its damages.
                  </li>
                  <li>
                    This Agreement sets forth the entire agreement of the parties and supersedes all other
                    understandings, representations or warranties, whether oral or writing.
                  </li>
                </ol>
              </div>
            </div>
            <div className="sa_seller_details_container">
              <div className="d-block">
                <div className="d-flex align-items-center sa_details">
                  <span className="sa_mr-6 sa_bold">Name : </span>
                  <span>
                    {data?.isMulti
                      ? data?.data?.[0]?.name
                        ? data?.data?.[0]?.name
                        : "N/A"
                      : data?.name
                      ? data?.name
                      : "N/A"}
                  </span>
                </div>
                <div className="d-flex align-items-center sa_details">
                  <span className="sa_mr-6 sa_bold">Title : </span>
                  <span>
                    {data?.isMulti
                      ? data?.data?.[0]?.title
                        ? data?.data?.[0]?.title
                        : "N/A"
                      : data?.title
                      ? data?.title
                      : "N/A"}
                  </span>
                </div>
                <div className="d-flex align-items-center sa_details">
                  <span className="sa_mr-6 sa_bold">Company : </span>
                  <span>
                    {" "}
                    {data?.isMulti
                      ? data?.data?.[0]?.company
                        ? data?.data?.[0]?.company
                        : "N/A"
                      : data?.company
                      ? data?.company
                      : "N/A"}
                  </span>
                </div>
                <div className={`${!data?.isMulti ? "d-flex align-items-start" : ""}  sa_details`}>
                  <span className="sa_mr-6 sa_bold" style={{ minWidth: "fit-content" }}>
                    Description of Products :{" "}
                  </span>
                  <span className={data?.isMulti ? "d-block" : ""}>
                    {data?.isMulti
                      ? data?.data?.map((ye, i) => (
                          <>
                            {" "}
                            {ye?.property}
                            <br />
                          </>
                        ))
                      : data?.property
                      ? data?.property
                      : "N/A"}
                  </span>
                </div>
                <div className="d-flex align-items-center sa_details">
                  <span className="sa_mr-6 sa_bold">Date : </span>
                  <span>{moment().format("MM/DD/YYYY hh:mm:ss a")}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  previewAgreement: (data, callback) => dispatch(previewAgreement(data, callback)),
  signAgreement: (data, callback) => dispatch(signAgreement(data, callback)),
  previewAgreements: (data, callback) => dispatch(previewAgreements(data, callback)),
  sendAgreements: (data, callback) => dispatch(sendAgreements(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(SellerAgreement));
