import React, { useEffect, useRef, useState } from "react";
import "../../include/css/invoice.css";
import LoadingBar from "react-top-loading-bar";
import HelmetComponent from "../../common/helmetComponent/helmetComponent";
import pdfFile from "../../include/images/pdf-file-icon.svg";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { getProfileOptions, getUser, uploadNDADocs } from "../../store/users";
import { connect } from "react-redux";
import { Amplify, Storage } from "aws-amplify";
import { Auth } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import AlertError from "../../common/alerts/alertError";
import AlertSuccess from "../../common/alerts/alertSuccess";
import queryString from "query-string";
import Select from "react-select";
const customStyles = {
  indicatorSeparator: (styles) => ({ display: "none" }),

  option: (provided, state) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "#47ad1d",
      color: "#F5F5F5",
    },
    display: "-webkit-box",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    color: state.isSelected ? "#fff" : "black",
    backgroundColor: state.isSelected ? "#47ad1d" : provided.backgroundColor,
    maxHeight: "84px",
  }),
  control: (base, state) => ({
    ...base,
    height: 42,
    minHeight: 42,
    background: state.isDisabled ? "#e9ecef" : "#ffff",
    borderColor: state.isFocused ? "#47AD1D" : "#cccccc",
    boxShadow: state.isFocused ? "0 0 0 0.5px #47AD1D" : "0",
    border: state.isHovered ? "0" : "1px solid #cccccc",
    color: state.isDisabled ? "black" : "#f5f5f5",

    "&:hover": {
      borderColor: state.isFocused ? "#47AD1D" : "0",
      boxShadow: state.isFocused ? "0 0 0 0.5px #47AD1D" : "0",
    },
    "&:disabled": {
      background: "#e9ecef",
    },
  }),
  multiValueRemove: (base, state) => ({
    ...base,
    "&:hover": {
      backgroundColor: "rgb(95, 50, 187, 10%)",
      color: "#6119c0",
    },
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 1000,
  }),
};
Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    region: "us-east-1",
  },
  Storage: {
    bucket: process.env.REACT_APP_S3_UPLOAD_BUCKET,
    region: "us-east-1",
  },
});

const UploadAgreement = (props) => {
  const ref = useRef(null);
  const [image, setImage] = useState("https://s3.amazonaws.com/media-dev.salvex.com/emails/salvex2x.png");
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [loadingMain, setLoadingMain] = useState(true);
  const [userData, setUserData] = useState(null);
  const [company, setCompany] = useState({
    label: "Select Company",
    value: "",
  });

  const companyOption = [
    { label: "Select Company", value: "" },
    { label: "Salvex Metals, Inc.", value: "Salvex Metals, Inc." },
    { label: "Salvex Aviation", value: "Salvex Aviation" },
    { label: "Salvex Solar, Inc.", value: "Salvex Solar, Inc." },
    { label: "Salvex Energy, Inc.", value: "Salvex Energy, Inc." },
    { label: "Salvex Food, Inc.", value: "Salvex Food, Inc." },
    { label: "Salvex Commodities, Inc.", value: "Salvex Commodities, Inc." },
  ];

  const user_id = queryString.parse(location.search).user_id.trim();

  useEffect(() => {
    document.body.classList.add("p-0");

    return () => document.body.classList.remove("p-0");
  }, []);

  useEffect(() => {
    setLoadingMain(true);
    ref?.current?.continuousStart();
    props.getProfileOptions(user_id, (res) => {
      if (res && res.status === 200) {
        ref?.current?.complete();
        setLoadingMain(false);
        setUserData(res?.data?.detail);
      } else {
        setLoadingMain(false);
        ref?.current?.complete();

        window.location.href = "/500";
      }
    });
  }, []);

  const handleSubmitDocument = (e) => {
    ref?.current?.continuousStart();
    setLoading(true);
    const file = selectedDoc;
    const fSize = Math.round(file.size / 1048576);
    const fType = file.type;
    const ext = file.name.split(".").pop();
    if (fSize > 25) {
      ref?.current?.complete();
      toast(<AlertError message="Size exceeds maximum allowable size. Maximum allowable size is 25MB." />);
      return setLoading(false);
    } else if (!["application/pdf", "application/msword"].includes(fType)) {
      ref?.current?.complete();
      toast(
        <AlertError message="File is not of correct format and hence cannot be uploaded. Valid document formats are pdf, xlsx, xls, doc, docx and csv." />,
      );
      return setLoading(false);
    } else {
      const fileName = "newSalvex/doc/" + uuidv4() + "." + ext;
      Storage.put(fileName, file, {
        completeCallback: (event) => {},
        progressCallback: (progress) => {},
        errorCallback: (err) => {
          return setLoading(false), ref?.current?.complete(), toast(<AlertError message={"Something Went Wrong"} />);
        },
      }).then((result) => {
        props.uploadNDADocs(
          user_id,
          {
            nda_doc: "public/" + result.key,
            salvex_company: company?.value,
          },
          (res) => {
            if (res.status === 200) {
              ref?.current?.complete();
              toast(<AlertSuccess message="Information Saved" />);
              setLoading(false);
              setSelectedDoc(null);
              setCompany({
                label: "Select Company",
                value: "",
              });
            } else {
              setLoading(false);
              ref?.current?.complete();

              toast(
                <AlertError
                  message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"}
                />,
              );
            }
          },
        );
      });
    }
  };

  return (
    <div className="create-invoice">
      <LoadingBar height={5} color="#47AD1D" ref={ref} />

      <div class="wep-page-wrapper">
        <div class="wp-inside-wrapper">
          <div class="page-head-wrap">
            <div class="ph-left">
              <h4>Upload NCNDA Document</h4>
              <div class="ph-invoice-text">
                {!loadingMain ? (
                  <span>
                    {userData?.p_name01} ({userData?.p_email})
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div class="ph-right">
              <div class="ph-logo">
                <img src={image} alt="" />
              </div>
              <div class="ph-address">
                440 Louisiana St. Ste. 550 <br /> Houston, TX 77002
              </div>
              <div class="ph-link-box d-flex justify-content-end">
                <div>
                  <strong>Phone :</strong> 713 229 9000
                </div>
              </div>
            </div>
          </div>
          <div class="page-divider"></div>
          {user_id?.length > 0 ? (
            <div class="input-group-row justify-content-center">
              <div class="igr-column">
                <div class="igr-box">
                  <label for="" style={{ marginBottom: "0px", marginTop: "4px" }}>
                    Select Salvex Entity
                  </label>
                  <div class="igr-input-group">
                    <div class="igr-input p-0">
                      <Select
                        styles={customStyles}
                        value={company}
                        placeholder="Select Company"
                        options={companyOption}
                        onChange={(e) => {
                          setCompany(e);
                        }}
                      />
                    </div>
                  </div>
                  <label for="" style={{ marginBottom: "0px", marginTop: "8px" }}>
                    {selectedDoc?.name ? "Selected File" : "Select File to Upload"}
                  </label>
                  <div class="igr-input-group">
                    <div
                      class="large-upload-box position-relative d-flex flex-column align-items-center justify-content-center"
                      style={{ width: "100%" }}
                    >
                      {!selectedDoc?.name ? (
                        <>
                          <label for="formFileSm">+ Upload NCNDA Document</label>
                          <div class="supported-file-format">Supported file format : Doc, PDF</div>
                          <input
                            class="form-control position-absolute"
                            id="formFileSm"
                            type="file"
                            accept=".doc,.pdf"
                            onChange={(e) => {
                              setSelectedDoc(e.target.files[0]);
                            }}
                            onClick={(e) => (e.target.value = null)}
                          />
                        </>
                      ) : (
                        <div class="td-pdf-img-box nda-upload-file">
                          <img src={pdfFile} />
                          <p>{selectedDoc?.name}</p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    class="owr-column-button d-grid gap-2 d-md-flex justify-content-md-end mt-40 upload-nda-btn"
                    style={{ marginRight: "-5px" }}
                  >
                    {selectedDoc?.name ? (
                      <button
                        class="btn btn-link global-cancel-button me-md-2"
                        type="button"
                        onClick={() => setSelectedDoc(null)}
                      >
                        Cancel
                      </button>
                    ) : (
                      ""
                    )}
                    <button
                      type="button"
                      class={`btn btn-default ${
                        loading || !company?.value || !selectedDoc?.name ? "disabled btn-loading" : ""
                      }`}
                      style={{ width: "100px", justifyContent: "center", padding: "15px" }}
                      onClick={() => !loading && handleSubmitDocument()}
                    >
                      {"Upload"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div class="input-group-row justify-content-center">
              <div>
                <h1>No User Found</h1>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  uploadNDADocs: (id, data, callback) => dispatch(uploadNDADocs(id, data, callback)),
  getProfileOptions: (params, callback) => dispatch(getProfileOptions(params, callback)),
});
const mapStateToProps = (state) => ({
  getUser: getUser(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(UploadAgreement));
