import React, { useEffect, useState } from "react";
import CarbonFootPrintModal from "../../../layouts/modals/carbonFootPrintModal";
import { getListingProductDetail, otherDescpReceived, updateOtherDescription } from "../../../store/listingProduct";
import { toast } from "react-toastify";
import AlertError from "../../../common/alerts/alertError";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { getModals, setShowAICarbon } from "../../../store/modal";

const CarbonFootprint = (props) => {
  // const [showCarbonFootpintModal, setShowCarbonFootpintAIModal] = useState(false);
  const showCarbonFootpintModal = props?.showCarbonFootpintModal;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState("");

  const toggleCarbonFootpintModal = () => {
    props?.setShowCarbonFootpintAIModal(!showCarbonFootpintModal);
    setData("");
  };

  const value =
    props.getListingProductDetail &&
    props.getListingProductDetail.otherDescription &&
    props.getListingProductDetail.otherDescription.data;

  const handleSubmit = () => {
    const id = props.auctionId?.trim();
    props.refStart();
    setLoading(true);
    const payload = {
      co_description: data,
    };
    props.updateOtherDescription(id, payload, (res) => {
      if (res.status === 200) {
        props.refStop();
        setLoading(false);
        const data1 = {
          ai_description: value.ai_description ? value.ai_description : "",
          mfr_description: value.mfr_description ? value.mfr_description : "",
          co_description: data,
        };
        props.otherDescpReceived({ data: data1 });
        toggleCarbonFootpintModal();
        toast(<AlertSuccess message={"Information Saved"} />);
      } else {
        props.refStop();
        setLoading(false);
        toast(<AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  useEffect(() => {
    if (showCarbonFootpintModal) {
      setData(value && value.co_description ? value.co_description : "");
    }
  }, [showCarbonFootpintModal]);

  return (
    <div class="details-page-widget-block" id={props.id}>
      <div class="page-widget-head d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <div
            class="page-widget-label text-capitalize cursor-pointer"
            onClick={() => !props.loadingMain && toggleCarbonFootpintModal()}
          >
            AI Carbon Footprint Explanation
          </div>
        </div>
        {/* <div class="btn-block d-flex align-items-center">
          {props.loadingMain ? (
            <Skeleton height="30px" width="90px" />
          ) : (
            <a href="javascript:void(0)" class="btn btn-primary btn-sm" onClick={() => toggleCarbonFootpintModal()}>
              Edit
            </a>
          )}
        </div> */}
      </div>
      <div class="page-widget-body mt-3">
        {props.loadingMain ? (
          <Skeleton width="100%" count={6} height="10px" />
        ) : value && value.co_description ? (
          value.co_description.split("\n").map(function (item) {
            return (
              <span>
                {item}
                <br />
              </span>
            );
          })
        ) : (
          "N/A"
        )}
      </div>
      <CarbonFootPrintModal
        show={showCarbonFootpintModal}
        onHide={toggleCarbonFootpintModal}
        loading={loading}
        data={data}
        setData={setData}
        handleSubmit={handleSubmit}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateOtherDescription: (params, data, callback) => dispatch(updateOtherDescription(params, data, callback)),

  //global state for manipulating data
  otherDescpReceived: (data) => dispatch(otherDescpReceived(data)),
  setShowCarbonFootpintAIModal: (data) => dispatch(setShowAICarbon(data)),
});

const mapStateToProps = (state) => ({
  getListingProductDetail: getListingProductDetail(state),
  showCarbonFootpintModal: getModals(state)?.aiCarbon,
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(CarbonFootprint));
