import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import Header from "../layouts/header";
import UserTable from "../components/users/userTable";
import { connect } from "react-redux";
import { getUserList, userListings } from "../store/userList";
import LoadingBar from "react-top-loading-bar";
import { toast } from "react-toastify";
import AlertError from "../common/alerts/alertError";

const Users = (props) => {
  const ref = useRef(null);
  const [loading, setLoading] = useState(false);

  //params state
  const [page, setPage] = useState(1);
  const [show, setShow] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [location, setLocation] = useState("");
  const [limit, setLimit] = useState({ name: 50, value: 50 });
  const [companyAccess, setCompanyAccess] = useState({
    name: "Select Company Access",
    value: "",
  });
  const [selectedFilter, setSelectedFilter] = useState({});

  const [showFilter, setShowFilter] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [expandedItem, setExpandedItem] = useState({});
  const [id, setId] = useState("");

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  useEffect(() => {
    ref.current.continuousStart();
    setLoading(true);
    const params = {
      limit: limit.value,
      page: 1,
      // keyword: "",
      name: "",
      email: "",
      phone: "",
      // location: "",
      // company_access: null,
    };
    props.getUserList(params, (res) => {
      if (res && res.status === 200) {
        ref.current.complete();
        setLoading(false);
      } else {
        ref.current.complete();
        setLoading(false);
      }
    });
  }, []);

  //Data

  const userData =
    props.userList && props.userList.userList && props.userList.userList.data && props.userList.userList.data.list;

  const filteredCount =
    props.userList &&
    props.userList.userList &&
    props.userList.userList.data &&
    props.userList.userList.data.filteredCount;

  useEffect(() => {
    ref.current.continuousStart();
    setLoading(true);
    const params = {
      limit: limit.value,
      page: page,
      // keyword: keyword,
      name: name,
      email: email,
      phone: phone,
      // location: location,
      // company_access: companyAccess.value ? +companyAccess.value : null,
    };
    props.getUserList(params, (res) => {
      if (res && res.status === 200) {
        setLoading(false);
        ref.current.complete();
      } else {
        ref.current.complete();
        setLoading(false);
      }
    });
  }, [page]);

  const handleFilter = (
    isModalOpen = false,
    removeKeyword = false,
    removeName = false,
    removeEmail = false,
    removePhone = false,
    removeLocation = false,
  ) => {
    ref.current.continuousStart();
    setLoadingFilter(true);
    setSelectedFilter({
      // keyword: removeKeyword ? "" : keyword,
      name: removeName ? "" : name,
      email: removeEmail ? "" : email,
      phone: removePhone ? "" : phone,
      // location: removeLocation ? "" : location,
      // companyAccess: removeCompany ? null : companyAccess.value ? companyAccess.value : null,
    });
    setPage(1);
    const params = {
      limit: limit.value,
      page: 1,
      // keyword: removeKeyword ? "" : keyword,
      name: removeName ? "" : name,
      email: removeEmail ? "" : email,
      phone: removePhone ? "" : phone,
      // location: removeLocation ? "" : location,
      // company_access: removeCompany ? null : companyAccess.value ? +companyAccess.value : null,
    };
    props.getUserList(params, (res) => {
      if (res && res.status === 200) {
        if (
          // params.keyword ||
          params.name ||
          params.email ||
          params.phone
          // || params.location
        ) {
          setShow(true);
        } else {
          setShow(false);
        }
        setLoadingFilter(false);
        ref.current.complete();
        if (!isModalOpen) {
          toggleFilter();
        }
      } else {
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        ref.current.complete();
        setLoadingFilter(false);
      }
    });
  };

  return (
    <>
      <LoadingBar height={5} color="#47AD1D" ref={ref} />
      <Header />
      <UserTable
        ref={ref}
        filteredCount={filteredCount}
        userData={userData}
        page={page}
        setPage={setPage}
        show={show}
        setShow={setShow}
        keyword={keyword}
        setKeyword={setKeyword}
        name={name}
        setName={setName}
        email={email}
        setEmail={setEmail}
        phone={phone}
        setPhone={setPhone}
        location={location}
        setLocation={setLocation}
        companyAccess={companyAccess}
        setCompanyAccess={setCompanyAccess}
        showFilter={showFilter}
        toggleFilter={toggleFilter}
        loading={loading}
        handleFilter={handleFilter}
        loadingFilter={loadingFilter}
        selectedFilter={selectedFilter}
        expandedItem={expandedItem}
        setExpandedItem={setExpandedItem}
        setLimit={setLimit}
        limit={limit}
        id={id}
        setId={setId}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getUserList: (params, callback) => dispatch(getUserList(params, callback)),
});

const mapStateToProps = (state) => ({
  userList: userListings(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Users));
