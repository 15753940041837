import React, { useEffect, useRef, useState } from "react";
import "../../include/css/invoice.css";
import logo from "../../include/images/logo.svg";
import { connect } from "react-redux";
import queryString from "query-string";
import { addInvoiceData, getInvoice, getInvoiceData } from "../../store/invoices";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import moment from "moment";
import LoadingBar from "react-top-loading-bar";
import { toast } from "react-toastify";
import AlertSuccess from "../../common/alerts/alertSuccess";
import Editor from "../../common/editor/editor";
import { getBidDetail } from "../../store/bids";
import { twoDecimals } from "../../utils.js/conversion";
import print from "../../include/images/print.svg";
import send from "../../include/images/send-btn.svg";
import Select from "react-select";
import HelmetComponent from "../../common/helmetComponent/helmetComponent";
import { releaseOrder } from "../../store/primarySeller";
import AlertError from "../../common/alerts/alertError";

const customStyles = {
  indicatorSeparator: (styles) => ({ display: "none" }),

  option: (provided, state) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "#47ad1d",
      color: "#F5F5F5",
    },
    display: "-webkit-box",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    color: state.isSelected ? "#fff" : "black",
    backgroundColor: state.isSelected ? "#47ad1d" : provided.backgroundColor,
    maxHeight: "84px",
  }),
  control: (base, state) => ({
    ...base,
    height: 42,
    minHeight: 42,
    background: state.isDisabled ? "#e9ecef" : "#ffff",
    borderColor: state.isFocused ? "#47AD1D" : "#cccccc",
    boxShadow: state.isFocused ? "0 0 0 0.5px #47AD1D" : "0",
    border: state.isHovered ? "0" : "1px solid #cccccc",
    color: state.isDisabled ? "black" : "#f5f5f5",

    "&:hover": {
      borderColor: state.isFocused ? "#47AD1D" : "0",
      boxShadow: state.isFocused ? "0 0 0 0.5px #47AD1D" : "0",
    },
    "&:disabled": {
      background: "#e9ecef",
    },
  }),
  multiValueRemove: (base, state) => ({
    ...base,
    "&:hover": {
      backgroundColor: "rgb(95, 50, 187, 10%)",
      color: "#6119c0",
    },
  }),
};

const NewRelease = (props) => {
  const history = useHistory();
  const location = useLocation();
  const urlParams = queryString.parse(location.search);
  const auctionId = urlParams.id;
  const ref = useRef(null);
  const [show, setShow] = useState(false);
  const [biddersOptions, setBiddersOptions] = useState([]);
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [auction, setAuction] = useState({});
  const [invoiced, setInvoiced] = useState(true);
  const [bId, setBId] = useState(0);
  const [image, setImage] = useState("https://s3.amazonaws.com/media-dev.salvex.com/emails/salvex2x.png");
  const [values, setValues] = useState({
    type: "",
    b_id: 0,
    co_email: "",
    b_email: "",

    mm_email: "",
    title: "",
    via: 0,

    seller_1: "",
    date: "",
    market_maker: "",
    co_2: "",
    co_3: "",
    co_4: "",
    co_5: "",
    co_6: "",
    ref: "",
    attn: "",
    description_1: "",
    description_2: "",
    logistic_notes: [],
    mm_name: "",
    mm_phone: "",
    mm_fax: "",
  });

  //Get Bidders List
  useEffect(() => {
    ref && ref.current && ref.current.continuousStart();
    const payload = { ...values };
    payload.type = "bidderList";
    props?.releaseOrder(auctionId, payload, (res) => {
      if (res.status === 200) {
        ref && ref.current && ref.current.complete();
        if (res.data.message === "You must first invoice a bid.") {
          setAuction(res?.data?.auction ? res?.data?.auction : {});
          setImage(
            res?.data?.auction?.category?.category_image
              ? res?.data?.auction?.category?.category_image
              : "https://s3.amazonaws.com/media-dev.salvex.com/emails/salvex2x.png",
          );
          setInvoiced(false);
        }
        setBiddersOptions(res.data.data ? res.data.data : []);
        setAuction(res?.data?.auction ? res?.data?.auction : {});
        setImage(
          res?.data?.auction?.category?.category_image
            ? res?.data?.auction?.category?.category_image
            : "https://s3.amazonaws.com/media-dev.salvex.com/emails/salvex2x.png",
        );
      } else {
        ref && ref.current && ref.current.complete();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  }, []);

  const logisticsDetails = (val) => {
    if (
      Object.keys(val?.logistics_details?.includeInRelease) &&
      Object.keys(val?.logistics_details?.includeInRelease).length > 0
    ) {
      let details = "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Logistics & Pick up Details:</b><br><br>";
      Object.values(val?.logistics_details?.includeInRelease).forEach((detail) => {
        details += `&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;• ${detail} <br>`;
      });
      return details + "<br><br>";
    }
    return "";
  };

  //Get Preview Detail
  const getPreview = (e) => {
    ref && ref.current && ref.current.continuousStart();
    const payload = { ...values };
    payload.type = "informationReleaseDetail";
    setBId(e.b_id);
    payload.b_id = e.b_id;
    props?.releaseOrder(auctionId, payload, (res) => {
      if (res.status === 200) {
        const val = res.data.bid;
        ref && ref.current && ref.current.complete();
        const data = { ...values };
        data.b_email = val.b_email ? (val.b_email === "undefined" ? "" : val.b_email) : "";
        data.title = val.title ? (val.title === "undefined" ? "" : val.title) : "";
        data.seller_1 = val.c_name ? (val.c_name === "undefined" ? "" : val.c_name) : "";
        data.mm_email = val.mm_email ? (val.mm_email === "undefined" ? "" : val.mm_email) : "";
        data.market_maker = val.mm_name ? (val.mm_name === "undefined" ? "" : val.mm_name) : "";
        data.date = moment().format("MM/DD/YYYY");
        data.co_email = val.c_email ? (val.c_email === "undefined" ? "" : val.c_email) : "";
        data.co_2 = val.address_1 ? (val.address_1 === "undefined" ? "" : val.address_1) : "";
        data.co_3 = val.address_2 ? (val.address_2 === "undefined" ? "" : val.address_2) : "";
        data.co_4 = val.address_3 ? (val.address_3 === "undefined" ? "" : val.address_3) : "";
        data.co_5 = val.c_phone ? (val.c_phone === "undefined" ? "" : val.c_phone) : "";
        data.co_6 = val.s_fax ? (val.s_fax === "undefined" ? "" : val.s_fax) : "";
        data.ref = val.title ? (val.title === "undefined" ? "" : val.title) : "";
        data.attn = val.s_name ? (val.s_name === "undefined" ? "" : val.s_name) : "";
        data.description_1 = `Please release the information related to ${
          val?.quantity ? (val?.quantity === "undefined" ? "" : val?.quantity) : ""
        } ${val?.unit ? (val?.unit === "undefined" ? "" : val?.unit) : ""} of ${
          val.title ? (val.title === "undefined" ? "" : val.title) : ""
        } located at ${val?.address_1 ? (val.address_1 === "undefined" ? "" : `${val.address_1}, `) : ""}${
          val?.a_city ? (val?.a_city === "undefined" ? "" : val?.a_city) : ""
        }, ${val?.a_abb ? (val?.a_abb === "undefined" ? "" : val?.a_abb) : ""}, ${
          val?.a_country ? (val?.a_country === "undefined" ? "" : val?.a_country) : ""
        } to ${val?.b_name ? (val?.b_name === "undefined" ? "" : val?.b_name) : ""} of ${
          val?.b_company_name ? (val?.b_company_name === "undefined" ? "" : val?.b_company_name) : ""
        } ${val?.b_phone ? (val?.b_phone === "undefined" ? "" : val?.b_phone) : ""} under the following terms:<br><br>

        ${val?.terms ? val?.terms + "<br><br>" : ""}
  
  ${logisticsDetails(val)}
  • This product is sold AS IS, WHERE IS.<br>
  • Buyer is responsible for all taxes, duties, loading, and freight charges.<br>
  • Seller does not offer any warranties or manufacturer warranties.<br>
  • It is Buyer's responsibility to verify quantities and condition at the time of pick-up. Once the product has been removed from Seller's location, there will be no adjustments based on quantity or condition.<br>
  • Unless other arrangements have been made with the Seller, any product not picked up within 5 days of the Information Release shall be deemed abandoned and title will revert to the Seller. Buyers forfeit any right to a refund of the purchase price for abandoned goods.<br><br>

  Please call me if there are any questions or complications.<br><br>

  Sincerely,<br>
  ${val?.mm_name ? (val?.mm_name === "undefined" ? "" : val?.mm_name + "<br>") : ""}
  Salvex<br>
  ${val?.mm_phone ? (val?.mm_phone === "undefined" ? "" : val?.mm_phone + "<br>") : ""}
  ${val?.mm_fax ? (val?.mm_fax === "undefined" ? "" : val?.mm_fax + "<br>") : ""}
  ${val?.mm_email ? (val?.mm_email === "undefined" ? "" : val?.mm_email + "<br>") : ""}`;
        data.description_2 = val.terms === "undefined" ? "" : val.terms ? val.terms : "";
        data.logistic_notes = ["Empty containers must be returned to original pick up depot", "Inspections Allowed"];
        setValues(data);
        setImage(
          res?.data?.auction?.category?.category_image
            ? res?.data?.auction?.category?.category_image
            : "https://s3.amazonaws.com/media-dev.salvex.com/emails/salvex2x.png",
        );
        setAuction(res.data.bid ? res.data.bid : {});
        setShow(true);
      } else {
        ref && ref.current && ref.current.complete();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  //Print Information Release
  const printHandler = () => {
    ref && ref.current && ref.current.continuousStart();
    const payload = { ...values };
    payload.type = "printInformationRelease";
    payload.b_id = bId;
    payload.description_1 = values?.description_1?.replace(/&nbsp;/g, "");
    props?.releaseOrder(auctionId, payload, (res) => {
      if (res.status === 200) {
        ref && ref.current && ref.current.complete();
        clickToPrint(res.data?.emailHtml);
      } else {
        ref && ref.current && ref.current.complete();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  //Send Email
  const sendEmail = () => {
    ref?.current?.continuousStart();
    const payload = { ...values };
    payload.type = "sendInformationReleaseEmail";
    payload.b_id = bId;
    payload.description_1 = values?.description_1?.replace(/&nbsp;/g, "");
    props?.releaseOrder(auctionId, payload, (res) => {
      if (res.status === 200) {
        ref && ref.current && ref.current.complete();
        history.replace(`/listing-detail?id=${auctionId}`);
        toast(<AlertSuccess message={"Information Saved"} />);
      } else {
        ref && ref.current && ref.current.complete();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };
  useEffect(() => {
    document.body.classList.add("p-0");

    return () => document.body.classList.remove("p-0");
  }, []);

  useEffect(() => {
    setEditorLoaded(true);
  }, []);

  const onChange = (e) => {
    const data = { ...values };
    data[e.target.name] = e.target.value;
    setValues(data);
  };

  //to decode the html entities from the api response.
  const decodeHtmlEntities = (str) => {
    var txt = document.createElement("textarea");
    txt.innerHTML = str;
    return txt.value;
  };

  const clickToPrint = (data) => {
    let pri =
      document.getElementById("ifmcontentstoprint") && document.getElementById("ifmcontentstoprint").contentWindow;
    if (pri) {
      pri.document.open();
      pri.document.write(decodeHtmlEntities(data));
      pri.document.close();
      pri.focus();
      setTimeout(() => pri.print(), 100);
    }
  };

  return (
    <div className="create-invoice">
      <iframe id="ifmcontentstoprint" style={{ height: "0p", width: "0px", position: "absolute" }}></iframe>
      <LoadingBar height={5} color="#47AD1D" ref={ref} />
      <HelmetComponent
        title={show ? `Release Form # ${auction.auction_id}-${auction.title}` : "Product Release"}
        desription={show ? `Release Form # ${auction.auction_id}-${auction.title}` : "Product Release"}
      />
      <div class="wep-page-wrapper">
        <div class="wp-inside-wrapper">
          <div class="page-head-wrap">
            <div class="ph-left">
              {show && (
                <div class="ph-btn-group">
                  <div class="ph-btn-item">
                    <button type="button" class="btn btn-default" onClick={() => printHandler()}>
                      <img class="asc-icon" src={print} alt="" />
                      <span className="ms-3">Print Release</span>
                    </button>
                  </div>
                  <div class="ph-btn-item">
                    <button type="button" class="btn btn-primary" onClick={sendEmail}>
                      <img class="asc-icon" src={send} alt="" />
                      <span className="ms-3">Send Release</span>
                    </button>
                  </div>
                </div>
              )}
              <h4>INFORMATION RELEASE</h4>
              {(auction.auction_id || auction.title) && (
                <div class="ph-invoice-text">
                  <span>
                    {auction?.auction_id}-{auction?.title}
                  </span>
                </div>
              )}
            </div>
            <div class="ph-right">
              <div class="ph-logo">
                <img src={image} alt="" />
              </div>
              <div class="ph-address">
                440 Louisiana St. Ste. 550 <br /> Houston, TX 77002
              </div>
              <div class="ph-link-box d-flex justify-content-end">
                <div>
                  <strong>Phone :</strong> 713 229 9000
                </div>
              </div>
            </div>
          </div>
          <div class="page-divider"></div>
          {show ? (
            <>
              <div class="input-group-row">
                <div class="igr-column">
                  <div class="igr-box">
                    <label for="">To</label>
                    <div class="igr-input-group">
                      <div class="igr-input">
                        <input
                          type="text"
                          placeholder="Name"
                          class="form-control"
                          name="seller_1"
                          value={values?.seller_1}
                          onChange={(e) => onChange(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="igr-column">
                  <div class="igr-box">
                    <label for="">From</label>
                    <div class="igr-input-group">
                      <div class="igr-input">
                        <input
                          type="text"
                          placeholder="Name"
                          class="form-control"
                          name="market_maker"
                          value={values?.market_maker}
                          onChange={(e) => onChange(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="input-two-group-row">
                <div class="input-two-column">
                  <div class="input-two-wrap">
                    <div class="input-two-col">
                      <label for="" style={{ marginBottom: "0px", marginTop: "4px" }}>
                        Date
                      </label>
                      <input
                        type="text"
                        placeholder=""
                        class="form-control"
                        name="date"
                        value={values?.date}
                        readOnly
                        // onChange={(e) => onChange(e)}
                      />
                    </div>
                    <div class="input-two-col nr-pl">
                      <label for="" style={{ marginBottom: "0px", marginTop: "4px" }}>
                        Location Phone
                      </label>
                      <input
                        type="text"
                        placeholder=""
                        class="form-control"
                        name="co_5"
                        value={values?.co_5}
                        onChange={(e) => onChange(e)}
                      />
                    </div>
                  </div>
                </div>
                <div class="input-two-column">
                  <div class="input-two-wrap">
                    <div class="input-two-col nr-pl">
                      <label for="" style={{ marginBottom: "0px", marginTop: "4px" }}>
                        Location Email
                      </label>
                      <input
                        type="text"
                        placeholder=""
                        class="form-control"
                        name="co_email"
                        value={values?.co_email}
                        onChange={(e) => onChange(e)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="input-group-row" style={{ marginBottom: "8px" }}>
                <div class="igr-full-column">
                  <div class="igr-box">
                    <label for="" style={{ marginBottom: "0px", marginTop: "4px" }}>
                      Product Location
                    </label>
                    <div class="igr-input-group">
                      <div class="igr-input d-flex">
                        <input
                          type="text"
                          className="form-control me-2"
                          name="co_2"
                          value={values?.co_2}
                          onChange={(e) => onChange(e)}
                        />
                        <input
                          type="text"
                          className="form-control me-2"
                          name="co_3"
                          value={values?.co_3}
                          onChange={(e) => onChange(e)}
                        />
                        <input
                          type="text"
                          className="form-control"
                          name="co_4"
                          value={values?.co_4}
                          onChange={(e) => onChange(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="input-group-row">
                <div class="igr-column">
                  <div class="igr-box">
                    <label for="" style={{ marginBottom: "0px", marginTop: "4px" }}>
                      Ref:
                    </label>
                    <div class="igr-input-group">
                      <div class="igr-input">
                        <input
                          type="text"
                          placeholder="Name"
                          class="form-control"
                          name="ref"
                          value={values?.ref}
                          onChange={(e) => onChange(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="igr-column">
                  <div class="igr-box">
                    <label for="" style={{ marginBottom: "0px", marginTop: "4px" }}>
                      Attn:
                    </label>
                    <div class="igr-input-group">
                      <div class="igr-input">
                        <input
                          type="text"
                          placeholder="Name"
                          class="form-control"
                          name="attn"
                          value={values?.attn}
                          onChange={(e) => onChange(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="input-group-row" style={{ marginTop: "8px" }}>
                <div class="igr-full-column">
                  <div class="igr-box">
                    <div class="igr-input-group">
                      <div class="igr-input ck-content nr-descp">
                        <Editor
                          id="textarea-chat-page"
                          name="description"
                          className="ck-content form-control nr-descp"
                          onChange={(data) => {
                            const val = { ...values };
                            val.description_1 = data;
                            setValues(val);
                          }}
                          editorLoaded={editorLoaded}
                          value={values.description_1}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div class="input-group-row justify-content-center">
              {invoiced ? (
                <div class="igr-column">
                  <div class="igr-box">
                    <label for="" style={{ marginBottom: "0px", marginTop: "4px" }}>
                      Select a Bidder
                    </label>
                    <div class="igr-input-group">
                      <div class="igr-input">
                        <Select
                          styles={customStyles}
                          placeholder="Select a bidder"
                          options={biddersOptions}
                          getOptionLabel={(val) => val.buyer_name}
                          getOptionValue={(val) => val.b_id}
                          onChange={(e) => getPreview(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <span className="fw-medium mt-5">You must first invoice a bid.</span>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  releaseOrder: (id, data, callback) => dispatch(releaseOrder(id, data, callback)),
  getInvoiceData: (params, bidId, callback) => dispatch(getInvoiceData(params, bidId, callback)),
  getBidDetail: (params, bidId, callback) => dispatch(getBidDetail(params, bidId, callback)),
  addInvoiceData: (params, data, callback) => dispatch(addInvoiceData(params, data, callback)),
});

const mapStateToProps = (state) => ({
  getInvoice: getInvoice(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(NewRelease));
