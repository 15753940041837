import { createSelector, createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";

const slice = createSlice({
  name: "researchAssignment",
  initialState: {
    loading: false,
    researchAssignmentDetails: {},
    domain: {},
    people: {},
  },
  reducers: {
    assignmentDetailsRequested: (state, action) => {
      state.loading = true;
    },
    assignmentDetailsReceived: (state, action) => {
      state.researchAssignmentDetails = action.payload;
      state.loading = false;
    },
    assignmentDetailsReceivedFailed: (state, action) => {
      state.loading = false;
    },
    domainRequested: (state, action) => {
      state.loading = true;
    },
    domainReceived: (state, action) => {
      state.domain = action.payload;
      state.loading = false;
    },
    domainReceivedFailed: (state, action) => {
      state.loading = false;
    },
    peopleRequested: (state, action) => {
      state.loading = true;
    },
    peopleReceived: (state, action) => {
      state.people = action.payload;
      state.loading = false;
    },
    peopleReceivedFailed: (state, action) => {
      state.loading = false;
    },
  },
});

export const {
  assignmentDetailsRequested,
  assignmentDetailsReceived,
  assignmentDetailsReceivedFailed,
  domainRequested,
  domainReceived,
  domainReceivedFailed,
  peopleRequested,
  peopleReceived,
  peopleReceivedFailed,
} = slice.actions;
export default slice.reducer;

//Action Creators
const baseUrl = "dashboard/other/researchAssignment";

//Get Questions
export const getResearchAssignmentDetails =
  (params, callback) => (dispatch) => {
    return dispatch(
      apiCallBegan({
        url: `${baseUrl}/${params}`,
        method: "get",
        callback,
        onStart: assignmentDetailsRequested.type,
        onSuccess: assignmentDetailsReceived.type,
        onError: assignmentDetailsReceivedFailed.type,
      })
    );
  };

export const getResearchAssignmentPeople = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/people`,
      method: "get",
      params,
      callback,
      onStart: peopleRequested.type,
      onSuccess: peopleReceived.type,
      onError: peopleReceivedFailed.type,
    })
  );
};

export const updateResearchAssignmentDetails =
  (params, data, callback) => (dispatch) => {
    return dispatch(
      apiCallBegan({
        url: `${baseUrl}/${params}`,
        method: "put",
        data,
        callback,
      })
    );
  };

export const getResearchAssignmentDomain = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/domain/${params}`,
      method: "get",
      callback,
      onStart: domainRequested.type,
      onSuccess: domainReceived.type,
      onError: domainReceivedFailed.type,
    })
  );
};

export const uploadReseachAssFile =
  (params, type, data, callback) => (dispatch) => {
    return dispatch(
      apiCallBegan({
        url: `${baseUrl}/file/${params}`,
        method: type === "post" ? "POST" : "PUT",
        data,
        callback,
      })
    );
  };

export const getResearchAssignment = createSelector(
  (state) => state.entities.researchAssignment,
  (researchAssignment) => researchAssignment
);
