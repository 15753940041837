import React, { useEffect, useState } from "react";
import close from "../../../include/images/close.svg";
import { Offcanvas } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import NextButton from "../../../common/form/nextButton";
import DateFormat from "../../../common/DateFormat/dateFormat";
import moment from "moment";
import Chart from "chart.js/auto";
import { Chart as ChartJs, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from "chart.js";
import { Line } from "react-chartjs-2";
import { numberFormat } from "../../../utils.js/conversion";
ChartJs.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);
Chart.register(CategoryScale);

const AskingPriceHistory = (props) => {
  const [chart, setChart] = useState();
  const [chartUrl, setChartUrl] = useState();
  function createChart(reserve, wholesale, dateArray, priceArray) {
    const dates = dateArray?.length === 1 ? dateArray?.push(moment(new Date()).format("MMM-D-YYYY")) : dateArray;
    const amounts = priceArray?.length === 1 ? priceArray?.push(priceArray[0]) : priceArray;
    const chart = {
      type: "line",
      data: {
        labels: dateArray,
        datasets: [
          {
            label: "Public Asking Price History",
            data: priceArray,
            fill: false,
            borderColor: "rgb(209, 79, 94)",
            backgroundColor: ["rgba(255, 255, 255, 0.6)", "rgba(255, 255, 255, 0.6)", "rgba(255, 255, 255, 0.6)"],
            borderWidth: 3,
          },
        ],
      },
      options: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
        scales: {
          yAxes: [
            {
              ticks: {
                callback: function (value, index, values) {
                  return "$" + value;
                },
                fontColor: "black",
                beginAtZero: false,
                min: reserve && reserve > 0 ? parseInt(0.75 * Math.min(...priceArray)) : 0,
                max:
                  wholesale && wholesale > 0 && wholesale > Math.max(...priceArray)
                    ? parseInt(wholesale)
                    : parseInt(1.25 * Math.max(...priceArray)),
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                fontColor: "black",
              },
            },
          ],
        },
      },
    };
    return chart;
  }

  const [viewType, setViewType] = useState("table");
  const dateData = props?.data
    ?.filter((it) => it?.reserve_price)
    ?.filter((val) => val?.reserve_price !== 0)
    ?.map((item) => moment(item.entry_date).format("MMM-D-YYYY"));
  const amount = props?.data
    ?.filter((val) => val.reserve_price)
    ?.filter((it) => it?.reserve_price !== 0)
    ?.map((item) => item?.reserve_price);
  function reverseArrayByDate(arr) {
    return arr.sort((a, b) => new Date(b.entry_date) - new Date(a.entry_date));
  }

  useEffect(() => {
    if (props?.data) {
      setChart(createChart(props?.askingPrice, props?.wholesaleValue, dateData, amount));
    }
  }, [props?.data]);
  useEffect(() => {
    setChartUrl("https://quickchart.io/chart?c=" + JSON.stringify(chart));
  }, [chart]);
  return (
    <Offcanvas
      placement="end"
      backdropClassName="upload-documnets-backdrop"
      show={props.show}
      onHide={() => props.onHide()}
      className=" right-collapse fade modal-lg  offcanvas-pannel-container"
      dialogClassName="modal-dialog"
    >
      <Offcanvas.Header className="offcanvas-header">
        <h5 class="offcanvas-title">Public Asking Price History</h5>
        <button
          type="button"
          class="offcanvas-close-btn d-flex align-items-center justify-content-center"
          onClick={() => props.onHide()}
        >
          <img src={close} alt="" />
        </button>
      </Offcanvas.Header>
      <Offcanvas.Body className="custom-modal-body flex-grow-1 w-100 overflow-scroll" data-simplebar>
        <div className="d-flex " style={{ marginBottom: "25px" }}>
          <div
            class="form-check custom-radio d-flex align-items-center justify-content-start position-relative"
            style={{ marginRight: "25px" }}
          >
            <input
              class="form-check-input cursor-pointer"
              type="radio"
              name="privateflexRadioDefault111"
              id="priceHistory2"
              checked={viewType === "table"}
              onClick={() => setViewType("table")}
            />
            <label class="form-check-label" for="priceHistory2">
              Table
            </label>
          </div>
          <div
            class="form-check custom-radio d-flex align-items-center justify-content-start position-relative mr-2"
            onClick={() => setViewType("graph")}
          >
            <input
              class="form-check-input cursor-pointer "
              type="radio"
              name="privateflexRadioDefault111"
              id="priceHistory1"
              checked={viewType === "graph"}
              onClick={() => setViewType("graph")}
            />
            <label class="form-check-label" for="priceHistory1">
              Graph
            </label>
          </div>
        </div>
        {viewType === "table" ? (
          <div className="table-responsive  table-custom">
            <table className="table table-bordered">
              <colgroup>
                <col />
                <col />
              </colgroup>
              <thead>
                <tr>
                  <th>Price</th>
                  <th>Date</th>
                </tr>
              </thead>

              <tbody>
                {props?.show && props?.data?.length > 0 ? (
                  reverseArrayByDate([...props?.data] || [])?.map((item) => (
                    <tr>
                      <td>{item?.reserve_price ? "$" + numberFormat(item?.reserve_price) : "N/A"}</td>
                      <td>{item?.entry_date ? moment(item?.entry_date).format("MM-DD-yyyy") : "N/A"}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={2}>
                      <div className="d-flex justify-content-center align-items-center">
                        <span>No record found</span>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        ) : (
          <div>
            <img src={chartUrl} alt="chart" />
          </div>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default AskingPriceHistory;
