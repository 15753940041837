import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import "../../../include/css/weeklyReport.css";
import {
  changeWeeklyReportListingStatus,
  getweeklyEmailReport,
  primarySellerData,
  saveAuctionNote,
  sendReportemail,
  updateSendWeeklyReportEmailFrequency,
} from "../../../store/primarySeller";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import queryString from "query-string";
import LoadingBar from "react-top-loading-bar";
import { checkToken, encode, getProfile, setRedirectUrl } from "../../../utils.js/localStorageServices";
import { toast } from "react-toastify";
import AlertError from "../../../common/alerts/alertError";
import Select from "react-select";
import edit from "../../../include/images/edit-18x18.svg";
import { customStyles } from "../../../common/customStyles/reactSelectStyle";
import { addNewBid, editBid, searchBuyer } from "../../../store/bids";
import { capitalizeFirstLetter, numberFormat } from "../../../utils.js/conversion";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import NewBidModal from "../../../layouts/modals/bids/newBidModal";
import { getCountries, getStates } from "../../../store/productLocation";
import ScrollButton from "../../../common/scrollTop/scrollTop";
import Skeleton from "react-loading-skeleton";
import { quantityConfirmation } from "../../../store/latestActivity";
import FindUser from "../../../common/findUser/findUser";

const weekFrequencyOptions = [
  { name: "Select Week", value: "-1" },
  { name: "1 Week", value: 1 },
  { name: "2 Weeks", value: 2 },
  { name: "3 Weeks", value: 3 },
  { name: "4 Weeks", value: 4 },
];

const WeeklyEmail = (props) => {
  const ref = useRef(null);
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [notes, setNotes] = useState([]);
  const [email, setEmail] = useState("");
  const [disabledList, setDisabledList] = useState([]);
  const [weekFrequency, setWeekFrequency] = useState({
    name: "Select Week",
    value: "",
  });
  const [selectedItem, setSelectedItem] = useState(null);
  const [newOffer, setNewOffer] = useState("");
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [auctionId, setAuctionId] = useState("");
  const [userId, setUserId] = useState("");

  const [newBidData, setNewBidData] = useState({
    b_id: -1,
    user_id: process.env.REACT_APP_MODE == "development" ? 12820693 : 10012048,
    unit_bid: "",
    total_bid: "",
    terms: "",
    seller_bid: "",
  });
  const [showAddModal, setShowAddModal] = useState(false);
  const [loadingNewBid, setLoadingNewBid] = useState(false);
  const [buyerName, setBuyerName] = useState("");
  const [showNewBuyer, setShowNewBuyer] = useState(false);
  const [searchData, setSearchData] = useState({
    auction_id: auctionId,
    ext_ref: 0,
    outside_agent: 0,
    inspector: 0,
    recommend_id: 0,
    buyer: 1,

    name: "",
    company_name: "",
    city: "",
    st_des: "",
    zip: "",
    c_des: "",
    phone: "",
    fax: "",
    cell: "",
    email: "",
    setObserver: "",
      removeObserver: "",
  });

  const [loadingSearch, setLoadingSearch] = useState(false);
  const [response, setResponse] = useState(false);
  const [resData, setResData] = useState([]);
  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [buyer, setBuyer] = useState({});

  const active = props?.primarySellerData?.emailReport?.data?.activeAuctions;
  const pending = props?.primarySellerData?.emailReport?.data?.pendingAuctions;
  const siteUrl = props?.primarySellerData?.emailReport?.data?.siteUrl;
  const token = props?.primarySellerData?.emailReport?.data?.token;

  const toggleAddNewModal = () => {
    setNewBidData({
      b_id: -1,
      user_id: process.env.REACT_APP_MODE == "development" ? 12820693 : 10012048,
      unit_bid: "",
      total_bid: "",
      terms: "",
    });
    setBuyerName("Buyer / Offline Reserve");
    setShowAddModal(!showAddModal);
  };

  const toggleNewBuyer = () => {
    setShowAddModal(!showAddModal);
    setShowNewBuyer(!showNewBuyer);
  };

  useEffect(() => {
    document.body.classList.add("pt-0");
    document.body.classList.remove("pt-80");
    if (!checkToken()) {
      const a = window.location.href;
      setRedirectUrl(a);
      props.history.push("/signin");
    }
    return () => {
      document.body.classList.add("pt-80");
      document.body.classList.remove("pt-0");
    };
  }, []);

  useEffect(() => {
    const a = queryString.parse(location.search)?.id;
    setUserId(a);
    if (a) {
      ref?.current?.continuousStart();
      setLoading(true);
      props?.getweeklyEmailReport(a, (res) => {
        if (res && res.status == 200) {
          ref?.current?.complete();
          setLoading(false);
        }
      });
    } else {
      window.location.href = "/500";
    }
  }, []);

  useEffect(() => {
    const a = active?.filter((ue) => ue?.enable_for_seller_weekly_report === false)?.map((ie) => ie?.auction_id) || [];
    const b = pending?.filter((ue) => ue?.enable_for_seller_weekly_report === false)?.map((ie) => ie?.auction_id) || [];

    setDisabledList([...a, ...b]);
  }, [props?.primarySellerData?.emailReport?.data]);

  useEffect(() => {
    if (showNewBuyer) {
      props.getCountries((res) => {
        if (res.status === 200) {
          setCountry(res.data.data);
        }
      });
      props.getStates((res) => {
        if (res.status === 200) {
          setState(res.data.data);
        }
      });
      setResponse(false);
      setResData([]);
      setBuyerName("");
      const data = { ...newBidData };
      data.user_id = 0;
      setNewBidData(data);
      setSearchData({
        auction_id: auctionId,
        ext_ref: 0,
        outside_agent: 0,
        inspector: 0,
        recommend_id: 0,
        buyer: 1,

        name: "",
        company_name: "",
        city: "",
        st_des: "",
        zip: "",
        c_des: "",
        phone: "",
        fax: "",
        cell: "",
        email: "",
        setObserver: "",
      removeObserver: "",
      });
    }
  }, [showNewBuyer]);

  useEffect(() => {
    const weekData = props?.primarySellerData?.emailReport?.data?.weekReportEmailFrequency;
    if (weekData) {
      const a = weekFrequencyOptions?.filter((ye) => ye?.value == weekData)?.[0];
      setWeekFrequency(a);
    } else if (weekData === 0) {
      setWeekFrequency({ name: "Select Week", value: "0" });
    }
  }, [props?.primarySellerData?.emailReport?.data?.weekReportEmailFrequency]);

  const handleAddNew = () => {
    setLoadingNewBid(true);
    ref?.current?.continuousStart();

    props.addNewBid(auctionId, newBidData, (res) => {
      if (res.status === 200) {
        props?.getweeklyEmailReport(userId, (res) => {
          if (res && res.status == 200) {
            setLoadingNewBid(false);
            ref?.current?.complete();
            toggleAddNewModal();
            toast(<AlertSuccess message={"Information Saved"} />);
          }
        });
      } else {
        setLoadingNewBid(false);
        ref?.current?.complete();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const handleWeekReportEmail = (value) => {
    const a = queryString.parse(location.search)?.id;
    const payload = {
      weekReportEmailFrequency: value,
    };
    if (value !== "-1" && value != "0") {
      ref?.current?.continuousStart();
      setLoadingStatus(true);
      props?.updateSendWeeklyReportEmailFrequency(a, payload, (res) => {
        if (res.status === 200) {
          props?.getweeklyEmailReport(a, (res) => {
            setLoadingStatus(false);
          });
          ref?.current?.complete();
        } else {
          setLoadingStatus(false);
          ref?.current?.complete();
          toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        }
      });
    }
  };

  useEffect(() => {
    if (active) {
      const d = active?.map((ie) => ({
        auction_id: ie?.auction_id,
        notes: ie?.note_to_seller,
      }));
      setNotes(d);
      setEmail(props?.primarySellerData?.emailReport?.data?.email);
    }
  }, [active]);

  const data = getProfile();
  const uniqueid = `id=${encode(`${data.user_id}`)}`;

  const onSendHandler = () => {
    const a = queryString.parse(location.search)?.id;
    ref?.current?.continuousStart();
    setLoading(true);
    props?.sendReportemail({ email }, a, (res) => {
      if (res && res.status == 200) {
        setLoading(false);
        ref?.current?.complete();
        toast(<AlertSuccess message={"Email Sent Successfully"} />);
        // history.push("/email-sent-successfully");
      } else {
        setLoading(false);
        ref?.current?.complete();
        window.location.href("/500");
      }
    });
  };

  const changeListingStatus = (id) => {
    const a = queryString.parse(location.search)?.id;
    setLoading(true);
    ref?.current?.continuousStart();
    props?.changeWeeklyReportListingStatus(id, (res) => {
      if (res && res?.status == 200) {
        props?.getweeklyEmailReport(a, (res) => {});
        setLoading(false);
        ref?.current?.complete();
      } else {
        ref?.current?.complete();
        setLoading(false);
        <AlertError message={res?.data?.message ? res?.data?.message : "Something went wrong"} />;
      }
    });
  };

  const onChangeOffer = () => {
    const a = queryString.parse(location.search)?.id;
    setLoading(true);
    ref?.current?.continuousStart();
    props?.editBid(
      selectedItem?.auction_id,
      {
        b_id: selectedItem?.netOffer?.b_id,
        buyer_premium: selectedItem?.netOffer?.b_id,
        buyer_premium: 0,
        seller_bid: newOffer,
      },
      (res) => {
        if (res && res?.status == 200) {
          props?.getweeklyEmailReport(a, (res) => {
            if (res && res.status == 200) {
              setSelectedItem(null);
              setNewOffer("");
              ref?.current?.complete();
              setLoading(false);
            }
          });
        } else {
          setLoading(false);
          ref?.current?.complete();
          toast(<AlertError message={res?.data?.message ? res?.data?.message : "Something went wrong"} />);
        }
      },
    );
  };

  const handleSelectBuyer = () => {
    setBuyerName(buyer.name);
    const data = { ...newBidData };
    data.user_id = buyer.user_id;
    setNewBidData(data);
    toggleNewBuyer();
  };

  const handleSearch = () => {
    setLoadingSearch(true);
    ref?.current?.continuousStart();
    props.searchBuyer(auctionId, searchData, (res) => {
      if (res.status === 200) {
        setResData(res.data.data);
        ref?.current?.complete();
        setLoadingSearch(false);
        setResponse(true);
      } else {
        setLoadingSearch(false);
        ref?.current?.complete();
        setResponse(false);
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const handleQuantityConfirmation = (auctionId, val) => {
    const a = queryString.parse(location.search)?.id;
    ref?.current?.continuousStart();
    const payload = {
      status: val,
    };
    props.quantityConfirmation(auctionId, payload, (res) => {
      if (res.status === 200) {
        props?.getweeklyEmailReport(a, (res) => {
          ref?.current?.complete();
        });
      } else {
        ref?.current?.complete();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const handleNewSearch = () => {
    setResponse(false);
    setResData([]);
    setBuyerName("");
    setSearchData({
      auction_id: auctionId,
      ext_ref: 0,
      outside_agent: 0,
      inspector: 0,
      recommend_id: 0,
      buyer: 1,

      name: "",
      company_name: "",
      city: "",
      st_des: "",
      zip: "",
      c_des: "",
      phone: "",
      fax: "",
      cell: "",
      email: "",
      setObserver: "",
      removeObserver: "",
    });
  };

  const updateType = props?.primarySellerData?.emailReport?.data?.frequencyUpdatedBy;
  const totalSellerBid = props?.primarySellerData?.emailReport?.data?.totalSellerBidFormatted;

  const auctionCount = props?.primarySellerData?.emailReport?.data?.auctionCount || 0;

  const encodedUserId = active?.length > 0 ? active?.[0]?.encoded_user_id : "";
  const payment_info_isComplete = !props?.primarySellerData?.emailReport?.data?.payment_info_isComplete ? true : false;
  return (
    <>
      <ScrollButton />
      <LoadingBar height={5} color="#47AD1D" ref={ref} />
      <table width="100%" cellspacing="0" cellpadding="0" className="w-email-table">
        <tr>
          <td class="xs-p-0" align="center" valign="top" style={{ padding: "5px 0" }}>
            <table class="w-full-mobile" cellspacing="0" cellpadding="0" style={{ width: "700px", background: "#fff" }}>
              <div class="details-page-widget-block">
                <div class="page-widget-head d-flex align-items-center justify-content-between">
                  <div class="d-flex align-items-center " style={{ width: "80%" }}>
                    <div class="page-widget-label text-capitalize cursor-pointer">Email </div>
                    <input
                      style={{ marginLeft: "50px" }}
                      className="form-control"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div class="btn-block d-flex align-items-center">
                    <a
                      class={`button-hover ${
                        loading || (active?.length == 0 && pending?.length == 0) ? "btn-disabled" : ""
                      } `}
                      href="javascript:void(0)"
                      style={{
                        display: "block",
                        textAlign: "center",
                        fontSize: "14px",
                        lineHeight: "1.25",
                        fontWeight: "500",
                        backgroundColor: "#47ad1d",
                        color: "#fff",
                        borderRadius: "4px",
                        padding: "6px 15px",
                        textDecoration: "none",
                      }}
                      onClick={onSendHandler}
                    >
                      Send
                    </a>
                  </div>
                </div>
                <div className="d-flex align-items-center  mt-3 flex-wrap">
                  <div class="page-widget-label text-capitalize cursor-pointer">
                    Seller Asset Recovery Report Frequency :{" "}
                  </div>
                  <div style={{ marginLeft: "50px" }}>
                    {" "}
                    <Select
                      className="email-arr-select"
                      menuPosition="fixed"
                      menuShouldScrollIntoView
                      styles={customStyles}
                      placeholder="Select Week"
                      value={weekFrequency}
                      options={weekFrequencyOptions}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.value}
                      onChange={(e) => {
                        handleWeekReportEmail(e?.value);
                        setWeekFrequency(e);
                      }}
                    />
                  </div>
                </div>
                <div className="d-block">
                  {!loadingStatus && updateType == "Seller" && weekFrequency?.value == 0 ? (
                    <span className="mb-10 mt-10 d-block " style={{ whiteSpace: "pre-line" }}>
                      <span style={{ color: "red" }}> Seller has decided not to receive these emails. </span>{" "}
                    </span>
                  ) : (
                    ""
                  )}
                  {!loadingStatus && updateType == "Seller" && weekFrequency?.value === 1 ? (
                    <span className="mb-10 mt-10 d-block " style={{ whiteSpace: "pre-line" }}>
                      <span style={{ color: "red" }}> Seller has decided to receive this email once in a week.</span>{" "}
                    </span>
                  ) : !loadingStatus && updateType == "Seller" && weekFrequency?.value === 2 ? (
                    <span className="mb-10 mt-10 d-block " style={{ whiteSpace: "pre-line" }}>
                      <span style={{ color: "red" }}> Seller has decided to receive this email once in two weeks.</span>{" "}
                    </span>
                  ) : !loadingStatus && updateType == "Seller" && weekFrequency?.value === 3 ? (
                    <span className="mb-10 mt-10 d-block " style={{ whiteSpace: "pre-line" }}>
                      <span style={{ color: "red" }}>
                        {" "}
                        Seller has decided to receive this email once in three weeks.
                      </span>{" "}
                    </span>
                  ) : !loadingStatus && updateType == "Seller" && weekFrequency?.value === 4 ? (
                    <span className="mb-10 mt-10 d-block " style={{ whiteSpace: "pre-line" }}>
                      <span style={{ color: "red" }}>
                        {" "}
                        Seller has decided to receive this email once in four weeks.
                      </span>{" "}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </table>
          </td>
        </tr>
        <tr>
          <td class="xs-p-0" align="center" valign="top" style={{ padding: "5px 0" }}>
            <table
              class="w-full-mobile"
              cellspacing="0"
              cellpadding="0"
              style={{ width: "700px", minHeight: "90vh", background: "#fff" }}
            >
              <tbody>
                <tr>
                  <td align="center" valign="top">
                    <table width="100%" cellspacing="0" cellpadding="0">
                      <tr>
                        <td
                          class="xs-p-15"
                          valign="middle"
                          style={{
                            padding: "15px 32px",
                            borderBottom: "1px solid #e4e4e4",
                          }}
                        >
                          <table width="100%" cellpadding="0" cellspacing="0">
                            <tr>
                              <td align="left">
                                <img
                                  style={{
                                    display: "block",
                                    width: "140px",
                                    height: "40px",
                                    margin: "0",
                                    padding: "0",
                                    border: "0",
                                  }}
                                  src="https://s3.amazonaws.com/media-dev.salvex.com/emails/salvex2x.png"
                                  alt="logo"
                                />
                              </td>
                              <td align="right">
                                <table cellpadding="0" cellspacing="0">
                                  <tr>
                                    <td>
                                      <a
                                        class="button-hover cursor-pointer"
                                        target={siteUrl ? "_blank" : "_self"}
                                        href={siteUrl ? `${siteUrl}members/post-listing` : "javascript:void(0)"}
                                        style={{
                                          maxWidth: "170px",
                                          display: "block",
                                          textAlign: "center",
                                          fontSize: "14px",
                                          lineHeight: "1.25",
                                          fontWeight: "500",
                                          backgroundColor: "#47ad1d",
                                          color: "#fff",
                                          borderRadius: "4px",
                                          padding: "6px 15px",
                                          textDecoration: "none",
                                        }}
                                      >
                                        Post a Listing
                                      </a>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td class="xs-p-15" style={{ padding: "15px 32px" }}>
                          <table width="100%" cellspacing="0" cellpadding="0">
                            <tr>
                              <td
                                style={{
                                  fontSize: "20px",
                                  lineHeight: "1.6",
                                  fontWeight: "700",
                                }}
                              >
                                Seller Asset Recovery Report
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  fontSize: "14px",
                                  lineHeight: "1.5",
                                  fontWeight: "400",
                                  paddingTop: "5px",
                                }}
                              >
                                {siteUrl && (active?.length > 0 || pending?.length > 0) ? (
                                  <span>
                                    Below is a summary of your assets for sale with Salvex.
                                    <br /> Please review offers in your Active listings, and click 'Accept', 'Counter
                                    Offer' or 'Donate Asset' to notify our team how you would like to proceed. Notify
                                    your trader right away of quantity or availability changes.
                                    <br /> Pending listings require additional information from you so we can market
                                    your asset to our large database of motivated buyers. See highlighted sections to
                                    see what is required to post your assets live on the site.
                                    <br /> Let us know any deadlines for removal, and your timeline for sale of these
                                    assets.
                                    <br /> *All bids are subject to inspection.
                                  </span>
                                ) : siteUrl ? (
                                  <span>
                                    This seller does not seem to have any active or pending listings or pending listing
                                    might have all the information already filled in.
                                  </span>
                                ) : (
                                  [1, 2, 3, 4, 5].map((ue) => <Skeleton height="60px" />)
                                )}
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      {active?.length > 0 ? (
                        <>
                          <tr>
                            <td
                              class="xs-p-15"
                              style={{
                                padding: "8px 32px",
                                backgroundColor: "#47ad1d",
                                color: "#fff",
                              }}
                            >
                              <table width="100%" cellspacing="0" cellpadding="0">
                                <tr>
                                  <td
                                    style={{
                                      fontSize: "18px",
                                      lineHeight: "1.6",
                                      fontWeight: "700",
                                    }}
                                  >
                                    Active Listings For Sale
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    style={{
                                      fontSize: "14px",
                                      lineHeight: "1.5",
                                      fontWeight: "400",
                                      paddingTop: "5px",
                                    }}
                                  >
                                    Please review the offers on your active assets for sale.
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                          {totalSellerBid != "0.00" && auctionCount > 1 ? (
                            <tr>
                              <td
                                class="xs-p-15"
                                style={{
                                  padding: "8px 32px",
                                  backgroundColor: "green",
                                  color: "#fff",
                                }}
                              >
                                <table width="100%" cellspacing="0" cellpadding="0">
                                  <tr>
                                    <td
                                      style={{
                                        width: "60%",
                                        fontSize: "18px",
                                        lineHeight: "1.6",
                                        fontWeight: "700",
                                        padding: "10px 0px",
                                      }}
                                    >
                                      <tr>
                                        <td>
                                          {" "}
                                          Total Bid{" "}
                                          {auctionCount === 0
                                            ? `(0 Lot)`
                                            : auctionCount === 1
                                            ? `(1 Lot)`
                                            : `(${auctionCount} Lots)`}{" "}
                                          : {totalSellerBid ? "$" + totalSellerBid : "$0"}{" "}
                                        </td>
                                      </tr>
                                    </td>
                                    <td
                                      style={{
                                        fontSize: "18px",
                                        lineHeight: "1.6",
                                        fontWeight: "700",
                                        textAlign: "-webkit-right",
                                      }}
                                    >
                                      <a
                                        class="button-hover cursor-pointer"
                                        target="_blank"
                                        href={`${siteUrl}members/accept-all-listings/?token=${token}&id=${encodedUserId}`}
                                        style={{
                                          maxWidth: "170px",
                                          display: "block",
                                          textAlign: "center",
                                          fontSize: "14px",
                                          lineHeight: "1.25",
                                          fontWeight: "500",
                                          backgroundColor: "#47ad1d",
                                          color: "#fff",
                                          borderRadius: "4px",
                                          padding: "6px 15px",
                                          textDecoration: "none",
                                        }}
                                      >
                                        Accept All
                                      </a>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          ) : (
                            ""
                          )}

                          {active?.length > 0 ? (
                            <tr>
                              <td class="xs-p-15" style={{ padding: "20px 32px" }}>
                                <table cellspacing="0" cellpadding="0" style={{ minWidth: "100%" }}>
                                  <tbody>
                                    {active?.map((item) => (
                                      <tr>
                                        <td style={{ paddingBottom: "15px" }}>
                                          <table style={{ width: "100%" }} cellspacing="0" cellpadding="0">
                                            {" "}
                                            <tbody>
                                              <tr>
                                                <td
                                                  style={{
                                                    padding: "15px",
                                                    border: "1px solid #e4e4e4",
                                                    borderRadius: "15px",
                                                  }}
                                                >
                                                  {" "}
                                                  <table style={{ width: "100%" }} cellspacing="0" cellpadding="0">
                                                    <tbody>
                                                      <tr>
                                                        <td
                                                          style={{
                                                            fontSize: "16px",
                                                            lineHeight: "1.2",
                                                            fontWeight: "700",
                                                            paddingBottom: "12px",
                                                          }}
                                                          class=" d-flex space-between align-items-center"
                                                        >
                                                          <a
                                                            class="flex-grow-1 link-item"
                                                            href={`/listing-detail?id=${item?.auction_id}`}
                                                            target="_blank"
                                                          >
                                                            {item?.auction_id} - {item?.title}
                                                          </a>

                                                          <li
                                                            className="badge-item cursor-pointer"
                                                            style={{
                                                              width: "fit-content",
                                                              display: "inline-block",
                                                            }}
                                                          >
                                                            <div
                                                              className={`badge-box badge-box-enabled ${
                                                                disabledList?.length > 0 &&
                                                                disabledList?.includes(item?.auction_id)
                                                                  ? "rsp"
                                                                  : "gsp"
                                                              }`}
                                                              style={{
                                                                padding: "6px 5px",
                                                              }}
                                                              onClick={() => {
                                                                const data = [...disabledList];
                                                                if (data?.includes(item?.auction_id)) {
                                                                  const da = data?.filter(
                                                                    (jh) => jh !== item?.auction_id,
                                                                  );
                                                                  setDisabledList(da);
                                                                } else {
                                                                  setDisabledList([...disabledList, item?.auction_id]);
                                                                }
                                                                changeListingStatus(item?.auction_id);
                                                              }}
                                                            >
                                                              {disabledList?.length > 0 &&
                                                              disabledList?.includes(item?.auction_id)
                                                                ? "Disabled"
                                                                : "Enabled"}
                                                            </div>
                                                          </li>
                                                        </td>
                                                      </tr>
                                                      {item?.auctionDetail?.accepted ? (
                                                        <tr>
                                                          <td>
                                                            {" "}
                                                            <p
                                                              style={{
                                                                color: "red",
                                                              }}
                                                            >
                                                              {`This offer has been
                                                              accepted ${
                                                                item?.auctionDetail?.bid_date_accepted
                                                                  ? `on ${item?.auctionDetail?.bid_date_accepted}. Our team is finalizing all details so we can send you payment, and coordinate a pick up date and time upon your authorization to release.`
                                                                  : " ."
                                                              }`}{" "}
                                                            </p>
                                                          </td>
                                                        </tr>
                                                      ) : (
                                                        ""
                                                      )}
                                                      <tr>
                                                        <td
                                                          style={{
                                                            backgroundcolor: "rgb(71 173 29 / 10%)",
                                                            borderRadius: "6px",
                                                            padding: "6px 10px",
                                                          }}
                                                        >
                                                          <table
                                                            style={{
                                                              width: "100%",
                                                            }}
                                                            cellspacing="0"
                                                            cellpadding="0"
                                                          ></table>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td>
                                                          <table
                                                            style={{
                                                              width: "100%",
                                                            }}
                                                            cellspacing="0"
                                                            cellpadding="0"
                                                          >
                                                            <tbody>
                                                              <tr>
                                                                <td style={{ verticalAlign: "top" }}>
                                                                  <table
                                                                    style={{
                                                                      width: "100%",
                                                                    }}
                                                                    cellspacing="0"
                                                                    cellpadding="0"
                                                                  >
                                                                    <tr>
                                                                      <td
                                                                        style={{
                                                                          fontSize: "14px",
                                                                        }}
                                                                      >
                                                                        <strong>Current Offer</strong> :
                                                                        <span
                                                                          style={{
                                                                            fontSize: "20px",
                                                                            paddingLeft: "6px",
                                                                            fontWeight: "600",
                                                                          }}
                                                                        >
                                                                          {selectedItem?.auction_id ==
                                                                            item?.auction_id &&
                                                                          !item?.auctionDetail?.accepted ? (
                                                                            <>
                                                                              <input
                                                                                className="form-control current-offer-input"
                                                                                value={newOffer}
                                                                                onChange={(e) =>
                                                                                  setNewOffer(e.target.value)
                                                                                }
                                                                                onBlur={() => onChangeOffer()}
                                                                              />
                                                                              <span
                                                                                style={{
                                                                                  // color: "red",
                                                                                  fontSize: "0.75rem",
                                                                                }}
                                                                              >
                                                                                [
                                                                                {item?.highestBid?.total_bid
                                                                                  ? "$" +
                                                                                    new Intl.NumberFormat("en-US", {
                                                                                      minimumFractionDigits: 2,
                                                                                      maximumFractionDigits: 2,
                                                                                    }).format(
                                                                                      item?.highestBid?.total_bid,
                                                                                    )
                                                                                  : "$0"}
                                                                                ]
                                                                              </span>
                                                                            </>
                                                                          ) : item?.netOffer?.original_seller_bid?.toString()
                                                                              ?.length > 0 &&
                                                                            item?.netOffer?.original_seller_bid !=
                                                                              "0" ? (
                                                                            "$" +
                                                                            new Intl.NumberFormat("en-US", {
                                                                              minimumFractionDigits: 2,
                                                                              maximumFractionDigits: 2,
                                                                            }).format(
                                                                              item?.netOffer?.original_seller_bid,
                                                                            )
                                                                          ) : item?.netOffer?.original_seller_bid?.toString()
                                                                              ?.length > 0 &&
                                                                            item?.netOffer?.original_seller_bid ==
                                                                              "0" ? (
                                                                            "NO OFFER"
                                                                          ) : (
                                                                            "NO OFFER"
                                                                          )}
                                                                          {!item?.netOffer ||
                                                                          item?.netOffer?.original_seller_bid?.toString()
                                                                            ?.length == 0 ? (
                                                                            <a
                                                                              class="button-hover cursor-pointer"
                                                                              href={`javascript:void(0)`}
                                                                              style={{
                                                                                maxWidth: "100px",
                                                                                display: "inline",
                                                                                textAlign: "center",
                                                                                fontSize: "14px",
                                                                                lineHeight: "1.25",
                                                                                fontWeight: "500",
                                                                                backgroundColor: "green",
                                                                                color: "#fff",
                                                                                borderRadius: "4px",
                                                                                marginLeft: "10px",
                                                                                padding: "4px 6px",
                                                                                textDecoration: "none",
                                                                              }}
                                                                              onClick={() => {
                                                                                setAuctionId(item?.auction_id);
                                                                                toggleAddNewModal();
                                                                              }}
                                                                            >
                                                                              Offline Bid
                                                                            </a>
                                                                          ) : (
                                                                            ""
                                                                          )}
                                                                          {selectedItem?.auction_id !=
                                                                            item?.auction_id &&
                                                                          item?.netOffer?.original_seller_bid?.toString()
                                                                            ?.length > 0 &&
                                                                          !item?.auctionDetail?.accepted ? (
                                                                            <img
                                                                              src={edit}
                                                                              className="cursor-pointer"
                                                                              style={{
                                                                                marginLeft: "10px",
                                                                              }}
                                                                              onClick={() => {
                                                                                setNewOffer(
                                                                                  item?.netOffer?.original_seller_bid,
                                                                                );
                                                                                setSelectedItem(item);
                                                                              }}
                                                                            />
                                                                          ) : (
                                                                            ""
                                                                          )}
                                                                        </span>
                                                                      </td>
                                                                    </tr>
                                                                    {item?.seller_reserve ? (
                                                                      <tr>
                                                                        <td
                                                                          style={{
                                                                            fontSize: "14px",
                                                                            paddingTop: "12px",
                                                                          }}
                                                                        >
                                                                          <strong>Seller Asking Price</strong> :
                                                                          <span
                                                                            style={{
                                                                              fontWeight: "400",
                                                                              paddingLeft: "6px",
                                                                            }}
                                                                          >
                                                                            {item?.seller_reserve
                                                                              ? "$" +
                                                                                new Intl.NumberFormat("en-US", {
                                                                                  minimumFractionDigits: 2,
                                                                                  maximumFractionDigits: 2,
                                                                                }).format(item?.seller_reserve)
                                                                              : "$0"}
                                                                            {item?.seller_reserve &&
                                                                            !item?.netOffer?.original_seller_bid ? (
                                                                              <a
                                                                                class="button-hover cursor-pointer"
                                                                                target="_blank"
                                                                                href={`${siteUrl}my-account/auction-actions/?type=change-asking-price&auction=${encode(
                                                                                  `${item?.auction_id}`,
                                                                                )}&id=${item?.encoded_user_id}&token=${
                                                                                  item?.token
                                                                                }`}
                                                                                style={{
                                                                                  maxWidth: "100px",
                                                                                  display: "inline",
                                                                                  textAlign: "center",
                                                                                  fontSize: "14px",
                                                                                  lineHeight: "1.25",
                                                                                  fontWeight: "500",
                                                                                  backgroundColor: "#f2b61c",
                                                                                  color: "#fff",
                                                                                  borderRadius: "4px",
                                                                                  marginLeft: "10px",
                                                                                  padding: "4px 11px",
                                                                                  textDecoration: "none",
                                                                                }}
                                                                              >
                                                                                Lower
                                                                              </a>
                                                                            ) : (
                                                                              ""
                                                                            )}
                                                                          </span>
                                                                        </td>
                                                                      </tr>
                                                                    ) : (
                                                                      <tr>
                                                                        <td
                                                                          style={{
                                                                            fontSize: "14px",
                                                                            paddingTop: "12px",
                                                                          }}
                                                                        >
                                                                          <strong>Seller Asking Price</strong> :
                                                                          <span
                                                                            style={{
                                                                              fontWeight: "400",
                                                                              paddingLeft: "6px",
                                                                              color: "red",
                                                                            }}
                                                                          >
                                                                            Missing
                                                                          </span>
                                                                          {!item?.seller_reserve &&
                                                                          !item?.netOffer?.original_seller_bid ? (
                                                                            <a
                                                                              class="button-hover cursor-pointer"
                                                                              target="_blank"
                                                                              href={`${siteUrl}my-account/auction-actions/?type=change-asking-price&auction=${encode(
                                                                                `${item?.auction_id}`,
                                                                              )}&id=${item?.encoded_user_id}&token=${
                                                                                item?.token
                                                                              }`}
                                                                              style={{
                                                                                maxWidth: "100px",
                                                                                display: "inline",
                                                                                textAlign: "center",
                                                                                fontSize: "14px",
                                                                                lineHeight: "1.25",
                                                                                fontWeight: "500",
                                                                                backgroundColor: "#f2b61c",
                                                                                color: "#fff",
                                                                                borderRadius: "4px",
                                                                                marginLeft: "10px",
                                                                                padding: "4px 11px",
                                                                                textDecoration: "none",
                                                                              }}
                                                                            >
                                                                              Add
                                                                            </a>
                                                                          ) : (
                                                                            ""
                                                                          )}
                                                                        </td>
                                                                      </tr>
                                                                    )}
                                                                    {item?.netOffer?.bid_time ? (
                                                                      <tr>
                                                                        <td
                                                                          style={{
                                                                            fontSize: "14px",
                                                                            paddingTop: "12px",
                                                                          }}
                                                                        >
                                                                          <strong>Date Customer Bid Placed</strong> :
                                                                          <span
                                                                            style={{
                                                                              fontWeight: "400",
                                                                              paddingLeft: "6px",
                                                                            }}
                                                                          >
                                                                            {item?.netOffer?.bid_time
                                                                              ? item?.netOffer?.bid_time
                                                                              : "N/A"}
                                                                          </span>
                                                                        </td>
                                                                      </tr>
                                                                    ) : (
                                                                      ""
                                                                    )}
                                                                    {item?.p_value ? (
                                                                      ""
                                                                    ) : (
                                                                      <tr>
                                                                        <td
                                                                          style={{
                                                                            fontSize: "14px",
                                                                            paddingTop: "12px",
                                                                          }}
                                                                        >
                                                                          <strong>Wholesale Value</strong> :
                                                                          <span
                                                                            style={{
                                                                              fontWeight: "400",
                                                                              paddingLeft: "6px",
                                                                              color: "red",
                                                                            }}
                                                                          >
                                                                            Missing
                                                                          </span>
                                                                        </td>
                                                                      </tr>
                                                                    )}
                                                                    {item?.quantity ? (
                                                                      <tr>
                                                                        <td
                                                                          style={{
                                                                            fontSize: "14px",
                                                                            paddingTop: "10px",
                                                                          }}
                                                                        >
                                                                          <strong>Quantity</strong> :
                                                                          <span
                                                                            style={{
                                                                              fontWeight: "400",
                                                                              paddingLeft: "6px",
                                                                            }}
                                                                          >
                                                                            {item?.quantity} {item?.unit}
                                                                          </span>
                                                                          {/* <a
                                                                            class={`button-hover ${
                                                                              item?.qty_confirmed
                                                                                ? "btn-accepted"
                                                                                : "btn-verify"
                                                                            } `}
                                                                            target="_blank"
                                                                            href={
                                                                              process.env.REACT_APP_MODE ===
                                                                              "development"
                                                                                ? `https://www.newdev.salvex.com/members/success/verify-quantity/?token=${
                                                                                    item?.token
                                                                                  }&redirect=${btoa(
                                                                                    `id=${item?.auction_id}&title=${item?.title}`,
                                                                                  )}`
                                                                                : `https://www.salvex.com/members/success/verify-quantity/?token=${
                                                                                    item?.token
                                                                                  }&redirect=${btoa(
                                                                                    `id=${item?.auction_id}&title=${item?.title}`,
                                                                                  )}`
                                                                            }
                                                                            style={{
                                                                              display: "inline-block",
                                                                              textAlign: "center",
                                                                              fontSize: "14px",
                                                                              lineHeight: "1.25",
                                                                              fontWeight: "500",
                                                                              backgroundColor: "#47ad1d",
                                                                              color: "#fff",
                                                                              borderRadius: "4px",
                                                                              padding: "3px 4px",
                                                                              textDecoration: "none",
                                                                              marginLeft: "5px",
                                                                            }}
                                                                          >
                                                                            {!item?.qty_confirmed
                                                                              ? "Verify"
                                                                              : "Verified"}
                                                                          </a> */}
                                                                        </td>
                                                                      </tr>
                                                                    ) : (
                                                                      <tr>
                                                                        <td
                                                                          style={{
                                                                            fontSize: "14px",
                                                                            paddingTop: "12px",
                                                                          }}
                                                                        >
                                                                          <strong>Quantity</strong> :
                                                                          <span
                                                                            style={{
                                                                              fontWeight: "400",
                                                                              paddingLeft: "6px",
                                                                              color: "red",
                                                                            }}
                                                                          >
                                                                            Missing
                                                                          </span>
                                                                        </td>
                                                                      </tr>
                                                                    )}
                                                                    {item?.overallLocation ? (
                                                                      <tr>
                                                                        <td
                                                                          style={{
                                                                            fontSize: "14px",
                                                                            paddingTop: "10px",
                                                                          }}
                                                                        >
                                                                          <strong>Location</strong> :
                                                                          <span
                                                                            style={{
                                                                              fontWeight: "400",
                                                                              paddingLeft: "6px",
                                                                            }}
                                                                          >
                                                                            {item?.overallLocation
                                                                              ? item?.overallLocation
                                                                              : ""}
                                                                          </span>
                                                                        </td>
                                                                      </tr>
                                                                    ) : (
                                                                      <tr>
                                                                        <td
                                                                          style={{
                                                                            fontSize: "14px",
                                                                            paddingTop: "12px",
                                                                          }}
                                                                        >
                                                                          <strong>Location</strong> :
                                                                          <span
                                                                            style={{
                                                                              fontWeight: "400",
                                                                              paddingLeft: "6px",
                                                                              color: "red",
                                                                            }}
                                                                          >
                                                                            Missing
                                                                          </span>
                                                                        </td>
                                                                      </tr>
                                                                    )}
                                                                    {item?.manufacturer ? (
                                                                      <tr>
                                                                        <td
                                                                          style={{
                                                                            fontSize: "14px",
                                                                            paddingTop: "12px",
                                                                          }}
                                                                        >
                                                                          <strong>Manufacturer</strong> :
                                                                          <span
                                                                            style={{
                                                                              fontWeight: "400",
                                                                              paddingLeft: "6px",
                                                                            }}
                                                                          >
                                                                            {" "}
                                                                            {item?.manufacturer}
                                                                          </span>
                                                                        </td>
                                                                      </tr>
                                                                    ) : (
                                                                      ""
                                                                    )}
                                                                    {item?.as_des ? (
                                                                      <tr>
                                                                        <td
                                                                          style={{
                                                                            fontSize: "14px",
                                                                            paddingTop: "12px",
                                                                          }}
                                                                        >
                                                                          <strong>Status</strong> :
                                                                          <span
                                                                            style={{
                                                                              fontWeight: "400",
                                                                              paddingLeft: "6px",
                                                                            }}
                                                                          >
                                                                            {item?.as_des}
                                                                          </span>
                                                                        </td>
                                                                      </tr>
                                                                    ) : (
                                                                      <tr>
                                                                        <td
                                                                          style={{
                                                                            fontSize: "14px",
                                                                            paddingTop: "12px",
                                                                          }}
                                                                        >
                                                                          <strong>Status</strong> :
                                                                          <span
                                                                            style={{
                                                                              fontWeight: "400",
                                                                              paddingLeft: "6px",
                                                                              color: "red",
                                                                            }}
                                                                          >
                                                                            Missing
                                                                          </span>
                                                                        </td>
                                                                      </tr>
                                                                    )}
                                                                    {item?.auctionDetail?.accepted &&
                                                                    payment_info_isComplete ? (
                                                                      <tr>
                                                                        <td
                                                                          style={{
                                                                            fontSize: "14px",
                                                                            paddingTop: "12px",
                                                                          }}
                                                                        >
                                                                          <strong>Payment Info</strong> :
                                                                          <span
                                                                            style={{
                                                                              fontWeight: "400",
                                                                              paddingLeft: "6px",
                                                                              color: "red",
                                                                            }}
                                                                          >
                                                                            Missing
                                                                          </span>
                                                                          <a
                                                                            class="button-hover cursor-pointer"
                                                                            href={
                                                                              process.env.REACT_APP_MODE ===
                                                                              "development"
                                                                                ? `https://www.newdev.salvex.com/my-account/payment-info/`
                                                                                : `https://www.salvex.com/my-account/payment-info/`
                                                                            }
                                                                            target="_blank"
                                                                            style={{
                                                                              maxWidth: "100px",
                                                                              display: "inline",
                                                                              textAlign: "center",
                                                                              fontSize: "14px",
                                                                              lineHeight: "1.25",
                                                                              fontWeight: "500",
                                                                              backgroundColor: "#f2b61c",
                                                                              color: "#fff",
                                                                              borderRadius: "4px",
                                                                              marginLeft: "10px",
                                                                              padding: "4px 11px",
                                                                              textDecoration: "none",
                                                                            }}
                                                                          >
                                                                            Add
                                                                          </a>
                                                                        </td>
                                                                      </tr>
                                                                    ) : (
                                                                      ""
                                                                    )}
                                                                    {item?.species?.name ? (
                                                                      <tr>
                                                                        <td
                                                                          style={{
                                                                            fontSize: "14px",
                                                                            paddingTop: "12px",
                                                                          }}
                                                                        >
                                                                          <strong style={{ display: "block" }}>
                                                                            Environment Inclusion :
                                                                          </strong>{" "}
                                                                          <a
                                                                            className="capitalize-first-letter text-transform"
                                                                            href={
                                                                              process.env.REACT_APP_MODE ===
                                                                              "development"
                                                                                ? `https://www.dev.environmentalinclusion.com/endangered-species/${
                                                                                    item?.species?.slug
                                                                                  }?redirect=${btoa(
                                                                                    `speciesName=${item?.species?.slug}&isAccessible=fromToday`,
                                                                                  )}`
                                                                                : `https://www.environmentalinclusion.com/endangered-species/${
                                                                                    item?.species?.slug
                                                                                  }?redirect=${btoa(
                                                                                    `speciesName=${item?.species?.slug}&isAccessible=fromToday`,
                                                                                  )}`
                                                                            }
                                                                            target="_blank"
                                                                            style={{
                                                                              fontWeight: "400",
                                                                              display: "inline-block",
                                                                            }}
                                                                          >
                                                                            {" "}
                                                                            {capitalizeFirstLetter(
                                                                              item?.species?.name,
                                                                            )}{" "}
                                                                          </a>{" "}
                                                                          <a
                                                                            class={`button-hover cursor-pointer ${
                                                                              item?.for_donation ? "btn-accepted" : ""
                                                                            }`}
                                                                            href={
                                                                              process.env.REACT_APP_MODE ===
                                                                              "development"
                                                                                ? `https://www.newdev.salvex.com/members/success/donate/?redirect=${btoa(
                                                                                    `for_donation=true&token=${item?.token}`,
                                                                                  )}`
                                                                                : `https://www.salvex.com/members/success/donate/?redirect=${btoa(
                                                                                    `for_donation=true&token=${item?.token}`,
                                                                                  )}`
                                                                            }
                                                                            target="_blank"
                                                                            style={{
                                                                              display: "inline",

                                                                              textAlign: "center",
                                                                              fontSize: "14px",
                                                                              lineHeight: "1.25",
                                                                              fontWeight: "500",
                                                                              backgroundColor: "#47ad1d",
                                                                              color: "#fff",
                                                                              borderRadius: "4px",
                                                                              marginTop: "5px",
                                                                              marginRight: "6px",
                                                                              padding: "4px 6px",
                                                                              textDecoration: "none",
                                                                            }}
                                                                          >
                                                                            {item?.for_donation ? "Donated" : "Donate"}
                                                                          </a>{" "}
                                                                          <div>
                                                                            Click here to donate this assets to help the
                                                                            environment.{" "}
                                                                            <a
                                                                              href={
                                                                                process.env.REACT_APP_MODE ===
                                                                                "development"
                                                                                  ? `https://www.dev.environmentalinclusion.com/endangered-species/${
                                                                                      item?.species?.slug
                                                                                    }?redirect=${btoa(
                                                                                      `speciesName=${item?.species?.slug}&isAccessible=fromToday&track=false&token=${item?.token}&type=donate&species=${item?.species._id}`,
                                                                                    )}`
                                                                                  : `https://www.environmentalinclusion.com/endangered-species/${
                                                                                      item?.species?.slug
                                                                                    }?redirect=${btoa(
                                                                                      `speciesName=${item?.species?.slug}&isAccessible=fromToday&track=false&token=${item?.token}&type=donate&species=${item?.species._id}`,
                                                                                    )}`
                                                                              }
                                                                              target="_blank"
                                                                              style={{
                                                                                textDecoration: "underline",
                                                                                color: "#47ad1d",
                                                                              }}
                                                                            >
                                                                              {" "}
                                                                              Read More...
                                                                            </a>
                                                                          </div>
                                                                        </td>
                                                                      </tr>
                                                                    ) : (
                                                                      ""
                                                                    )}

                                                                    {/* {item?.conditionType ? (
                                                                      <tr>
                                                                        <td
                                                                          style={{
                                                                            fontSize: "14px",
                                                                            paddingTop: "12px",
                                                                          }}
                                                                        >
                                                                          <strong>Condition </strong> :
                                                                          <span
                                                                            style={{
                                                                              fontWeight: "400",
                                                                              paddingLeft: "6px",
                                                                            }}
                                                                          >
                                                                            {item?.conditionType
                                                                              ? item?.conditionType
                                                                              : "Missing"}
                                                                          </span>
                                                                        </td>
                                                                      </tr>
                                                                    ) : (
                                                                      <tr>
                                                                        <td
                                                                          style={{
                                                                            fontSize: "14px",
                                                                            paddingTop: "12px",
                                                                          }}
                                                                        >
                                                                          <strong>Condition </strong> :
                                                                          <span
                                                                            style={{
                                                                              fontWeight: "400",
                                                                              paddingLeft: "6px",
                                                                              color: "red",
                                                                            }}
                                                                          >
                                                                            Missing
                                                                          </span>
                                                                        </td>
                                                                      </tr>
                                                                    )} */}
                                                                    {/* {item?.condition ? (
                                                                      <tr>
                                                                        <td
                                                                          style={{
                                                                            fontSize: "14px",
                                                                            paddingTop: "12px",
                                                                          }}
                                                                        >
                                                                          <strong>Condition Description</strong> :
                                                                          <span
                                                                            style={{
                                                                              fontWeight: "400",
                                                                              paddingLeft: "6px",
                                                                            }}
                                                                          >
                                                                            {item?.condition
                                                                              ? HTMLReactParser(item?.condition)
                                                                              : "N/A"}
                                                                          </span>
                                                                        </td>
                                                                      </tr>
                                                                    ) : (
                                                                      ""
                                                                    )} */}
                                                                  </table>
                                                                </td>
                                                                <td
                                                                  class="m-block m-pl-0 m-pt-10"
                                                                  style={{
                                                                    width: "42%",
                                                                    padding: "0px 0px",
                                                                  }}
                                                                  valign="top"
                                                                >
                                                                  <table
                                                                    cellspacing="0"
                                                                    cellpadding="0"
                                                                    style={{
                                                                      width: "100%",
                                                                    }}
                                                                  >
                                                                    <tr>
                                                                      <td>
                                                                        <table
                                                                          style={{
                                                                            width: "100%",
                                                                          }}
                                                                          cellspacing="0"
                                                                          cellpadding="0"
                                                                        >
                                                                          {!item?.auctionDetail?.accepted &&
                                                                          item?.netOffer?.original_seller_bid ? (
                                                                            <tr
                                                                              style={{
                                                                                paddingBottom: "15px",
                                                                              }}
                                                                            >
                                                                              <td
                                                                                style={{
                                                                                  width: "50%",
                                                                                  paddingRight: "5px",
                                                                                  paddingBottom: "15px",
                                                                                }}
                                                                              >
                                                                                <a
                                                                                  class={`button-hover ${
                                                                                    item?.auctionDetail?.accepted
                                                                                      ? "btn-accepted"
                                                                                      : ""
                                                                                  }`}
                                                                                  target="_blank"
                                                                                  href={`${siteUrl}my-account/auction-actions/?type=accept&auction=${encode(
                                                                                    `${item?.auction_id}`,
                                                                                  )}&id=${
                                                                                    item?.encoded_user_id
                                                                                  }&token=${item?.token}`}
                                                                                  style={{
                                                                                    display: "block",
                                                                                    textAlign: "center",
                                                                                    fontSize: "14px",
                                                                                    lineHeight: "1.25",
                                                                                    fontWeight: "500",
                                                                                    backgroundColor: "#47ad1d",
                                                                                    color: "#fff",
                                                                                    borderRadius: "4px",
                                                                                    padding: "6px 15px",
                                                                                    textDecoration: "none",
                                                                                  }}
                                                                                >
                                                                                  {item?.auctionDetail?.accepted
                                                                                    ? "Accepted"
                                                                                    : "Accept"}
                                                                                </a>
                                                                              </td>
                                                                              {!item?.auctionDetail?.accepted ? (
                                                                                <td
                                                                                  style={{
                                                                                    width: "50%",
                                                                                    paddingLeft: "5px",
                                                                                    paddingBottom: "15px",
                                                                                  }}
                                                                                >
                                                                                  <a
                                                                                    class="button-hover"
                                                                                    target="_blank"
                                                                                    href={`${siteUrl}my-account/auction-actions/?type=counter-offer&auction=${encode(
                                                                                      `${item?.auction_id}`,
                                                                                    )}&id=${
                                                                                      item?.encoded_user_id
                                                                                    }&token=${item?.token}`}
                                                                                    style={{
                                                                                      display: "block",
                                                                                      textAlign: "center",
                                                                                      fontSize: "14px",
                                                                                      lineHeight: "1.25",
                                                                                      fontWeight: "500",
                                                                                      backgroundColor: "##fff",
                                                                                      color: "#47ad1d",
                                                                                      borderRadius: "4px",
                                                                                      border: "1px solid #47ad1d",
                                                                                      padding: "6px 15px",
                                                                                      textDecoration: "none",
                                                                                    }}
                                                                                  >
                                                                                    CounterOffer
                                                                                  </a>
                                                                                </td>
                                                                              ) : (
                                                                                ""
                                                                              )}
                                                                            </tr>
                                                                          ) : (
                                                                            ""
                                                                          )}
                                                                          <tr>
                                                                            <td
                                                                              class="m-pl-0"
                                                                              colspan="2"
                                                                              style={{
                                                                                paddingTop: "0px",
                                                                              }}
                                                                            >
                                                                              <tr>
                                                                                <table
                                                                                  style={{
                                                                                    width: "100%",
                                                                                  }}
                                                                                  cellspacing="0"
                                                                                  cellpadding="0"
                                                                                >
                                                                                  <tr>
                                                                                    <td
                                                                                      style={{
                                                                                        borderRadius: "4px",
                                                                                        padding: "16px 0px",
                                                                                        paddingTop: "0px",
                                                                                      }}
                                                                                    >
                                                                                      <table
                                                                                        style={{
                                                                                          width: "100%",
                                                                                        }}
                                                                                        cellspacing="0"
                                                                                        cellpadding="0"
                                                                                      >
                                                                                        <tr>
                                                                                          <td
                                                                                            style={{
                                                                                              fontSize: "14px",
                                                                                              fontweight: "500",
                                                                                              lineHeight: "1.4",
                                                                                            }}
                                                                                          >
                                                                                            <textarea
                                                                                              className="form-control"
                                                                                              placeholder="Enter Notes"
                                                                                              value={
                                                                                                notes?.filter(
                                                                                                  (it) =>
                                                                                                    it.auction_id ==
                                                                                                    item?.auction_id,
                                                                                                )?.[0]?.notes
                                                                                              }
                                                                                              onChange={(e) => {
                                                                                                const value = [
                                                                                                  ...notes,
                                                                                                ];
                                                                                                const a = notes
                                                                                                  ?.map(
                                                                                                    (it) =>
                                                                                                      it.auction_id,
                                                                                                  )
                                                                                                  .indexOf(
                                                                                                    item?.auction_id,
                                                                                                  );
                                                                                                const b = {
                                                                                                  auction_id:
                                                                                                    item?.auction_id,
                                                                                                  notes: e.target.value,
                                                                                                };
                                                                                                value[a] = b;
                                                                                                setNotes(value);
                                                                                              }}
                                                                                              onBlur={() => {
                                                                                                const a = notes?.filter(
                                                                                                  (it) =>
                                                                                                    it.auction_id ==
                                                                                                    item?.auction_id,
                                                                                                )?.[0];
                                                                                                props?.saveAuctionNote(
                                                                                                  item?.auction_id,
                                                                                                  {
                                                                                                    note: a?.notes,
                                                                                                  },
                                                                                                  (res) => {
                                                                                                    if (
                                                                                                      res &&
                                                                                                      res.status == 200
                                                                                                    ) {
                                                                                                    }
                                                                                                  },
                                                                                                );
                                                                                              }}
                                                                                              cols="30"
                                                                                              rows="3"
                                                                                            ></textarea>
                                                                                          </td>
                                                                                        </tr>
                                                                                      </table>
                                                                                    </td>
                                                                                  </tr>
                                                                                </table>
                                                                              </tr>
                                                                            </td>
                                                                          </tr>
                                                                          {item?.auctionDetail?.world_map_image &&
                                                                          (item?.auctionDetail?.world_map_image?.startsWith(
                                                                            "worldMap",
                                                                          ) ||
                                                                            item?.auctionDetail?.world_map_image ===
                                                                              "data_not_found_in_GA") ? (
                                                                            <>
                                                                              <tr>
                                                                                <td
                                                                                  style={{
                                                                                    fontWeight: "500",
                                                                                    fontSize: "12px",
                                                                                    paddingTop: "10px",
                                                                                  }}
                                                                                >
                                                                                  Activity Per Country
                                                                                </td>
                                                                              </tr>
                                                                              <tr>
                                                                                <td
                                                                                  colSpan={"2"}
                                                                                  className="ps-map"
                                                                                  style={{
                                                                                    border: "1px solid black",
                                                                                    borderRadius: "4px",
                                                                                    padding: "16px 12px",
                                                                                  }}
                                                                                >
                                                                                  {item?.auctionDetail
                                                                                    ?.world_map_image ===
                                                                                  "data_not_found_in_GA" ? (
                                                                                    <img
                                                                                      src={
                                                                                        "https://s3.amazonaws.com/uploads-dev.salvex.com/worldMaps/world_map_default.jpeg"
                                                                                      }
                                                                                    />
                                                                                  ) : (
                                                                                    <img
                                                                                      src={
                                                                                        process.env.REACT_APP_MODE ===
                                                                                        "development"
                                                                                          ? "https://s3.amazonaws.com/uploads-dev.salvex.com/" +
                                                                                            item?.auctionDetail
                                                                                              ?.world_map_image
                                                                                          : "https://s3.amazonaws.com/uploads.salvex.com/" +
                                                                                            item?.auctionDetail
                                                                                              ?.world_map_image
                                                                                      }
                                                                                    />
                                                                                  )}
                                                                                </td>
                                                                              </tr>
                                                                            </>
                                                                          ) : (
                                                                            ""
                                                                          )}
                                                                        </table>
                                                                      </td>
                                                                    </tr>
                                                                  </table>
                                                                </td>
                                                              </tr>
                                                            </tbody>
                                                          </table>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        ""
                      )}
                      {pending?.length > 0 ? (
                        <>
                          <tr>
                            <td
                              class="xs-p-15"
                              style={{
                                padding: "8px 32px",
                                backgroundColor: "#47ad1d",
                                color: "#fff",
                              }}
                            >
                              <table style={{ width: "100%" }} cellspacing="0" cellpadding="0">
                                <tr>
                                  <td
                                    style={{
                                      fontSize: "18px",
                                      lineHeight: "1.6",
                                      fontWeight: "700",
                                    }}
                                  >
                                    Pending Assets For Sale
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    style={{
                                      fontSize: "14px",
                                      lineHeight: "1.5",
                                      fontWeight: "400",
                                      paddingTop: "5px",
                                    }}
                                  >
                                    Please review your Pending Assets For Sale and send us any missing information.
                                    Communicate your timeline for selling these assets.
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                          {pending?.map((item) => (
                            <tr>
                              <td class="xs-p-15" style={{ padding: "15px 32px" }}>
                                <table style={{ width: "100%" }} cellspacing="0" cellpadding="0">
                                  <tr>
                                    <td style={{ padding: "8px" }}>
                                      <table style={{ width: "100%" }} cellspacing="0" cellpadding="0">
                                        <tr>
                                          <td
                                            style={{
                                              border: "1px solid #E4E4E4",
                                              borderRadius: "10px",
                                              padding: "15px",
                                            }}
                                          >
                                            {" "}
                                            <table style={{ width: "100%" }} cellspacing="0" cellpadding="0">
                                              <tr>
                                                <td
                                                  style={{
                                                    fontSize: "16px",
                                                    lineHeight: "1.2",
                                                    fontWeight: "700",
                                                    paddingBottom: "12px",
                                                  }}
                                                  class="link-item d-flex space-between align-items-center"
                                                >
                                                  <a href={`/listing-detail?id=${item?.auction_id}`} target="_blank">
                                                    {item?.auction_id} - {item?.title}
                                                  </a>
                                                  <li
                                                    className="badge-item cursor-pointer"
                                                    style={{
                                                      width: "fit-content",
                                                      display: "inline-block",
                                                    }}
                                                  >
                                                    <div
                                                      className={`badge-box badge-box-enabled ${
                                                        disabledList?.length > 0 &&
                                                        disabledList?.includes(item?.auction_id)
                                                          ? "rsp"
                                                          : "gsp"
                                                      }`}
                                                      style={{
                                                        padding: "6px 5px",
                                                      }}
                                                      onClick={() => {
                                                        const data = [...disabledList];
                                                        if (data?.includes(item?.auction_id)) {
                                                          const da = data?.filter((jh) => jh !== item?.auction_id);
                                                          setDisabledList(da);
                                                        } else {
                                                          setDisabledList([...disabledList, item?.auction_id]);
                                                        }
                                                        changeListingStatus(item?.auction_id);
                                                      }}
                                                    >
                                                      {disabledList?.length > 0 &&
                                                      disabledList?.includes(item?.auction_id)
                                                        ? "Disabled"
                                                        : "Enabled"}
                                                    </div>
                                                  </li>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>
                                                  <table
                                                    cellspacing="0"
                                                    cellpadding="0"
                                                    style={{
                                                      border: "1px solid #E4E4E4",
                                                      borderRadius: "6px",
                                                      width: "100%",
                                                    }}
                                                  >
                                                    <tr>
                                                      <td
                                                        class="mob-block"
                                                        style={{
                                                          fontSize: "16px",
                                                          lineHeight: "1",
                                                          borderBottom: "1px solid #E4E4E4",
                                                          padding: "10px 14px",
                                                          width: "40%",
                                                        }}
                                                      >
                                                        <b>Missing Info</b>
                                                      </td>
                                                      <td
                                                        class="mob-block"
                                                        style={{
                                                          borderBottom: "1px solid #E4E4E4",
                                                          padding: "10px 14px",
                                                          width: "60%",
                                                        }}
                                                      >
                                                        <table
                                                          style={{
                                                            width: "100%",
                                                          }}
                                                          cellspacing="0"
                                                          cellpadding="0"
                                                        >
                                                          <tr>
                                                            <td
                                                              style={{
                                                                width: "50%",
                                                                paddingRight: "5px",
                                                              }}
                                                            >
                                                              <a
                                                                href={
                                                                  item?.bc_id_email
                                                                    ? `mailto:${item?.bc_id_email}`
                                                                    : `mailto:`
                                                                }
                                                                class="button-hover"
                                                                style={{
                                                                  display: "block",
                                                                  textAlign: "center",
                                                                  fontSize: "14px",
                                                                  lineHeight: "1.25",
                                                                  fontWeight: "400",
                                                                  backgroundColor: "#47AD1D",
                                                                  color: "#fff",
                                                                  borderRadius: "4px",
                                                                  padding: "6px",
                                                                  textDecoration: "none",
                                                                }}
                                                              >
                                                                Send as an Email{" "}
                                                              </a>
                                                            </td>
                                                            <td
                                                              style={{
                                                                width: "50%",
                                                                paddingLeft: "5px",
                                                              }}
                                                            >
                                                              <a
                                                                target="_blank"
                                                                href={`${siteUrl}my-account/update-post-listings/?auction=${encode(
                                                                  `${item?.auction_id}`,
                                                                )}&id=${item?.encoded_user_id}`}
                                                                class="button-hover"
                                                                style={{
                                                                  display: "block",
                                                                  textAlign: "center",
                                                                  fontSize: "14px",
                                                                  lineHeight: "1.25",
                                                                  fontWeight: "400",
                                                                  backgroundColor: "#227300",
                                                                  color: "#fff",
                                                                  textDecoration: "none",
                                                                  borderRadius: "4px",
                                                                  padding: "6px",
                                                                }}
                                                              >
                                                                Click Here to Add
                                                              </a>
                                                            </td>
                                                          </tr>
                                                        </table>
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td
                                                        colspan="2"
                                                        style={{
                                                          fontSize: "14px",
                                                          fontWeight: "500",
                                                          lineHeight: "1.4",
                                                          color: "#FF4242",
                                                          padding: "9px 14px",
                                                        }}
                                                      >
                                                        {item?.missingValues?.split(",")?.map((it) => (
                                                          <li
                                                            className="badge-item cursor-pointer"
                                                            style={{
                                                              width: "fit-content",
                                                              display: "inline-block",
                                                            }}
                                                          >
                                                            <div className={`badge-box rsp`}>{it}</div>
                                                          </li>
                                                        ))}
                                                      </td>
                                                    </tr>
                                                  </table>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>
                                                  <table style={{ width: "100%" }} cellspacing="0" cellpadding="0">
                                                    <tr>
                                                      <td
                                                        class="m-block"
                                                        style={{
                                                          padding: "2px 10px 15px 0",
                                                          // borderBottom: "1px solid #E4E4E4",
                                                        }}
                                                        valign="top"
                                                      >
                                                        <table
                                                          style={{
                                                            width: "100%",
                                                          }}
                                                          cellspacing="0"
                                                          cellpadding="0"
                                                        >
                                                          <tr>
                                                            <td
                                                              style={{
                                                                fontSize: "14px",
                                                                paddingTop: "12px",
                                                              }}
                                                            >
                                                              <strong>Seller Asking Price</strong> :
                                                              <span
                                                                style={{
                                                                  fontWeight: "400",
                                                                  paddingLeft: "6px",
                                                                }}
                                                              >
                                                                {item?.seller_reserve ? (
                                                                  "$" +
                                                                  new Intl.NumberFormat("en-US", {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2,
                                                                  }).format(item?.seller_reserve)
                                                                ) : (
                                                                  <span
                                                                    style={{
                                                                      color: "red",
                                                                    }}
                                                                  >
                                                                    Missing
                                                                  </span>
                                                                )}
                                                              </span>
                                                            </td>
                                                          </tr>
                                                          {item?.p_value ? (
                                                            ""
                                                          ) : (
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  fontSize: "14px",
                                                                  paddingTop: "12px",
                                                                }}
                                                              >
                                                                <strong>Wholesale Price</strong> :
                                                                <span
                                                                  style={{
                                                                    fontWeight: "400",
                                                                    paddingLeft: "6px",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color: "red",
                                                                    }}
                                                                  >
                                                                    Missing
                                                                  </span>
                                                                </span>
                                                              </td>
                                                            </tr>
                                                          )}
                                                          <tr>
                                                            <td
                                                              style={{
                                                                fontSize: "14px",
                                                                paddingTop: "10px",
                                                              }}
                                                            >
                                                              <strong>Quantity</strong> :{" "}
                                                              <span
                                                                style={{
                                                                  fontWeight: "400",
                                                                  paddingLeft: "6px",
                                                                  color: item?.quantity ? "" : "red",
                                                                }}
                                                              >
                                                                {item?.quantity ? item?.quantity : "Missing"}{" "}
                                                                {item?.unit}
                                                              </span>
                                                            </td>
                                                          </tr>

                                                          {item?.overallLocation ? (
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  fontSize: "14px",
                                                                  paddingTop: "10px",
                                                                }}
                                                              >
                                                                <strong>Location</strong> :
                                                                <span
                                                                  style={{
                                                                    fontWeight: "400",
                                                                    paddingLeft: "6px",
                                                                  }}
                                                                >
                                                                  {item?.overallLocation ? item?.overallLocation : ""}
                                                                </span>
                                                              </td>
                                                            </tr>
                                                          ) : (
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  fontSize: "14px",
                                                                  paddingTop: "10px",
                                                                }}
                                                              >
                                                                <strong>Location</strong> :
                                                                <span
                                                                  style={{
                                                                    fontWeight: "400",
                                                                    paddingLeft: "6px",
                                                                    color: "red",
                                                                  }}
                                                                >
                                                                  Missing
                                                                </span>
                                                              </td>
                                                            </tr>
                                                          )}
                                                          {item?.manufacturer ? (
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  fontSize: "14px",
                                                                  paddingTop: "7px",
                                                                }}
                                                              >
                                                                <strong>Manufacturer</strong> :{" "}
                                                                <span
                                                                  style={{
                                                                    fontWeight: "400",
                                                                    paddingLeft: "6px",
                                                                    color: item?.manufacturer ? "" : "red",
                                                                  }}
                                                                >
                                                                  {item?.manufacturer ? item?.manufacturer : "Missing"}
                                                                </span>
                                                              </td>
                                                            </tr>
                                                          ) : (
                                                            ""
                                                          )}
                                                          <tr>
                                                            <td
                                                              style={{
                                                                fontSize: "14px",
                                                                paddingTop: "10px",
                                                              }}
                                                            >
                                                              <strong>Status</strong> :{" "}
                                                              <span
                                                                style={{
                                                                  fontWeight: "400",
                                                                  paddingLeft: "6px",
                                                                }}
                                                              >
                                                                Pending Assets Waiting to Go Live
                                                              </span>
                                                            </td>
                                                          </tr>

                                                          {item?.species?.name ? (
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  fontSize: "14px",
                                                                  paddingTop: "12px",
                                                                }}
                                                              >
                                                                <strong>Environment Inclusion </strong> :
                                                                <a
                                                                  className="capitalize-first-letter text-transform"
                                                                  href={
                                                                    process.env.REACT_APP_MODE === "development"
                                                                      ? `https://www.dev.environmentalinclusion.com/endangered-species/${
                                                                          item?.species?.slug
                                                                        }?redirect=${btoa(
                                                                          `speciesName=${item?.species?.slug}&isAccessible=fromToday`,
                                                                        )}`
                                                                      : `https://www.environmentalinclusion.com/endangered-species/${
                                                                          item?.species?.slug
                                                                        }?redirect=${btoa(
                                                                          `speciesName=${item?.species?.slug}&isAccessible=fromToday`,
                                                                        )}`
                                                                  }
                                                                  target="_blank"
                                                                  style={{
                                                                    fontWeight: "400",
                                                                    paddingLeft: "6px",
                                                                  }}
                                                                >
                                                                  {" "}
                                                                  {capitalizeFirstLetter(item?.species?.name)}{" "}
                                                                </a>{" "}
                                                                <a
                                                                  class={`button-hover cursor-pointer ${
                                                                    item?.for_donation ? "btn-accepted" : ""
                                                                  }`}
                                                                  href={
                                                                    process.env.REACT_APP_MODE === "development"
                                                                      ? `https://www.newdev.salvex.com/members/success/donate/?redirect=${btoa(
                                                                          `for_donation=true&token=${item?.token}`,
                                                                        )}`
                                                                      : `https://www.salvex.com/members/success/donate/?redirect=${btoa(
                                                                          `for_donation=true&token=${item?.token}`,
                                                                        )}`
                                                                  }
                                                                  target="_blank"
                                                                  style={{
                                                                    display: "inline",

                                                                    textAlign: "center",
                                                                    fontSize: "14px",
                                                                    lineHeight: "1.25",
                                                                    fontWeight: "500",
                                                                    backgroundColor: "#47ad1d",
                                                                    color: "#fff",
                                                                    borderRadius: "4px",
                                                                    marginTop: "5px",
                                                                    marginRight: "6px",
                                                                    padding: "4px 6px",
                                                                    textDecoration: "none",
                                                                  }}
                                                                >
                                                                  {item?.for_donation ? "Donated" : "Donate"}
                                                                </a>{" "}
                                                                <div
                                                                  style={{
                                                                    marginLeft: "163px",
                                                                  }}
                                                                >
                                                                  Click here to donate this assets to help the
                                                                  environment.{" "}
                                                                  <a
                                                                    href={
                                                                      process.env.REACT_APP_MODE === "development"
                                                                        ? `https://www.dev.environmentalinclusion.com/endangered-species/${
                                                                            item?.species?.slug
                                                                          }?redirect=${btoa(
                                                                            `speciesName=${item?.species?.slug}&isAccessible=fromToday&track=false&token=${item?.token}&type=donate&species=${item?.species._id}`,
                                                                          )}`
                                                                        : `https://www.environmentalinclusion.com/endangered-species/${
                                                                            item?.species?.slug
                                                                          }?redirect=${btoa(
                                                                            `speciesName=${item?.species?.slug}&isAccessible=fromToday&track=false&token=${item?.token}&type=donate&species=${item?.species._id}`,
                                                                          )}`
                                                                    }
                                                                    target="_blank"
                                                                    style={{
                                                                      textDecoration: "underline",
                                                                      color: "#47ad1d",
                                                                    }}
                                                                  >
                                                                    {" "}
                                                                    Read More...
                                                                  </a>
                                                                </div>
                                                              </td>
                                                            </tr>
                                                          ) : (
                                                            ""
                                                          )}
                                                          {/* <tr>
                                                            <td
                                                              style={{
                                                                fontSize: "14px",
                                                                paddingTop: "12px",
                                                              }}
                                                            >
                                                              <strong>Condition </strong> :
                                                              <span
                                                                style={{
                                                                  fontWeight: "400",
                                                                  paddingLeft: "6px",
                                                                  color: item?.conditionType ? "" : "red",
                                                                }}
                                                              >
                                                                {item?.conditionType ? item?.conditionType : "Missing"}
                                                              </span>
                                                            </td>
                                                          </tr> */}
                                                        </table>
                                                      </td>
                                                      {/* <td style={{ width: "47%" }}>
                                                        {item?.detail?.world_map_image &&
                                                        (item?.detail?.world_map_image?.startsWith("worldMap") ||
                                                          item?.detail?.world_map_image === "data_not_found_in_GA") ? (
                                                          <>
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  fontWeight: "500",
                                                                  fontSize: "12px",
                                                                  paddingTop: "10px",
                                                                }}
                                                              >
                                                                Activity Per Country
                                                              </td>
                                                            </tr>
                                                            <tr>
                                                              <td
                                                                className="ps-map"
                                                                style={{
                                                                  border: "1px solid black",
                                                                  borderRadius: "4px",
                                                                  padding: "16px 12px",
                                                                }}
                                                              >
                                                                {item?.detail?.world_map_image ===
                                                                "data_not_found_in_GA" ? (
                                                                  <img
                                                                    src={
                                                                      "https://s3.amazonaws.com/uploads-dev.salvex.com/worldMaps/world_map_default.jpeg"
                                                                    }
                                                                  />
                                                                ) : (
                                                                  <img
                                                                    src={
                                                                      process.env.REACT_APP_MODE === "development"
                                                                        ? "https://s3.amazonaws.com/uploads.salvex.com/" +
                                                                          item?.detail?.world_map_image
                                                                        : "https://s3.amazonaws.com/uploads.salvex.com/" +
                                                                          item?.detail?.world_map_image
                                                                    }
                                                                  />
                                                                )}
                                                              </td>
                                                            </tr>
                                                          </>
                                                        ) : (
                                                          ""
                                                        )}
                                                      </td> */}
                                                    </tr>
                                                  </table>
                                                </td>
                                              </tr>

                                              {/* <tr>
                                        <td style={{ paddingTop: "10px" }}>
                                          <a
                                            href="#"
                                            class="button-hover"
                                            style={{
                                              display: "block",
                                              maxWidth: "200px",
                                              textAlign: "center",
                                              fontSize: "14px",
                                              lineHeight: "1.25",
                                              fontWeight: "400",
                                              backgroundColor: "#47AD1D",
                                              color: "#fff",
                                              borderRadius: "4px",
                                              padding: "6px 0",
                                              textDecoration: "none",
                                            }}
                                          >
                                            View Contract with Salvex
                                          </a>
                                        </td>
                                      </tr> */}
                                            </table>
                                          </td>
                                        </tr>
                                      </table>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          ))}
                        </>
                      ) : (
                        ""
                      )}
                      <tr>
                        <td class="xs-p-15" style={{ padding: "0px 32px" }}>
                          <table style={{ width: "100%" }} cellspacing="0" cellpadding="0"></table>
                        </td>
                      </tr>
                      {active?.length != 0 || pending?.length != 0 ? (
                        <tr>
                          <td style={{ padding: "0 32px 0px" }}>
                            <table
                              style={{
                                width: "100%",
                                borderTop: "1px solid #e4e4e4",
                                paddingTop: "15px",
                              }}
                              cellspacing="0"
                              cellpadding="0"
                            >
                              <tr>
                                <td
                                  style={{
                                    fontSize: "14px",
                                    lineHeight: "1.5",
                                    paddingTop: "15px",
                                    paddingBottom: "15px",
                                  }}
                                >
                                  This message is intended only for the use of the individual to whom it is addressed
                                  and contains information that is privileged, confidential and exempt from disclosure
                                  under applicable law. If you are not the intended recipient, or the employee or agent
                                  responsible for delivering this message to the intended recipient, you are hereby
                                  notified that any unauthorized disclosure, dissemination, distribution or copying of
                                  this communication is strictly prohibited. If you have received this communication in
                                  error, or are not sure whether it is privileged, please immediately notify us. Thank
                                  you.
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      ) : (
                        ""
                      )}
                      <tr>
                        <td style={{ padding: "0 32px 30px" }}>
                          <table
                            style={{
                              width: "100%",
                              borderTop: "1px solid #e4e4e4",
                              paddingTop: "15px",
                            }}
                            cellspacing="0"
                            cellpadding="0"
                          >
                            <tr>
                              <td
                                style={{
                                  paddingTop: "15px",
                                  fontSize: "14px",
                                  lineHeight: "1.5",
                                  paddingBottom: "10px",
                                }}
                              >
                                {" "}
                                If you want to change the frequency of receiving this report,{" "}
                                <a
                                  style={{
                                    fontWeight: "500",
                                    color: "#47ad1d",
                                    textDecoration: "underline",
                                    display: "inline",
                                  }}
                                  target="_blank"
                                  href={`${siteUrl}members/seller-report-preferences/?token=${token}`}
                                >
                                  click here to set up your preferences.{" "}
                                </a>
                              </td>
                            </tr>

                            <tr>
                              <td style={{ fontSize: "14px", lineHeight: "1.5" }}>
                                Questions? Contact us at{" "}
                                <a
                                  style={{
                                    fontWeight: "500",
                                    color: "#47ad1d",
                                    textDecoration: "underline",
                                  }}
                                  href="mailto:sales@salvex.com"
                                >
                                  sales@salvex.com
                                </a>
                                , or call us at{" "}
                                <a
                                  style={{
                                    fontWeight: "500",
                                    color: "#47ad1d",
                                    textDecoration: "underline",
                                  }}
                                  href="tel:+17132299000"
                                >
                                  +1 713.229.9000
                                </a>
                                <br />
                                440 Louisiana Street, Suite 550, Houston, TX 77002
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <a
                                  style={{
                                    fontWeight: "500",
                                    color: "#47ad1d",
                                    textDecoration: "underline",
                                  }}
                                  href={
                                    process.env.REACT_APP_MODE === "development"
                                      ? "https://newdev.salvex.com/terms-and-conditions"
                                      : "https://salvex.com/terms-and-conditions"
                                  }
                                  target="_blank"
                                >
                                  Click here
                                </a>{" "}
                                to visit salvex terms and conditions.
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </table>
      <NewBidModal
        show={showAddModal}
        auctionId={auctionId}
        refStart={ref?.current?.continuousStart()}
        refStop={ref?.current?.complete()}
        onHide={toggleAddNewModal}
        newBidData={newBidData}
        setNewBidData={setNewBidData}
        handleAddNew={handleAddNew}
        loading={loadingNewBid}
        searchBuyer={props.searchBuyer}
        getCountries={props.getCountries}
        getStates={props.getStates}
        toggleNewBuyer={toggleNewBuyer}
        buyerName={buyerName}
        page={"SARR"}
      />
      <FindUser
        title={"Buyer"}
        show={showNewBuyer}
        onHide={toggleNewBuyer}
        searchData={searchData}
        setSearchData={setSearchData}
        handleSubmit={handleSearch}
        loading={loadingSearch}
        response={response}
        resData={resData}
        country={country}
        state={state}
        handleNewSearch={handleNewSearch}
        setUser={setBuyer}
        handleSelectUser={handleSelectBuyer}
        buyer={true}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  primarySellerData: primarySellerData(state),
});

const mapDispatchToProps = (dispatch) => ({
  getweeklyEmailReport: (params, callback) => dispatch(getweeklyEmailReport(params, callback)),
  saveAuctionNote: (id, data, callback) => dispatch(saveAuctionNote(id, data, callback)),
  sendReportemail: (email, data, callback) => dispatch(sendReportemail(email, data, callback)),
  changeWeeklyReportListingStatus: (id, callback) => dispatch(changeWeeklyReportListingStatus(id, callback)),
  updateSendWeeklyReportEmailFrequency: (id, data, callback) =>
    dispatch(updateSendWeeklyReportEmailFrequency(id, data, callback)),
  editBid: (params, data, callback) => dispatch(editBid(params, data, callback)),
  searchBuyer: (params, data, callback) => dispatch(searchBuyer(params, data, callback)),
  getCountries: (callback) => dispatch(getCountries(callback)),
  getStates: (callback) => dispatch(getStates(callback)),
  addNewBid: (params, data, callback) => dispatch(addNewBid(params, data, callback)),
  quantityConfirmation: (id, data, callback) => dispatch(quantityConfirmation(id, data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(WeeklyEmail));
