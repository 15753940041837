import React, { useEffect } from "react";

const PaymentTypeTwo = ({
  setError2,
  error2,
  option2Data,
  setOption2Data,
  member,
}) => {
  return (
    <>
      <p>
        <strong>Domestic Wire Transfer (U.S. Only)</strong>
      </p>

      <>
        <p class="fw-normal">
          Please confirm that you have an ABA number for FED Wire Transfers. Any
          fees incurred from incorrect wire information will be deducted from
          your payment. <sup style={{ color: "Red" }}>*</sup>
        </p>
        <div class="four-column-radio-group mb-4">
          <div class="fcrg-item  payment-info-main-option">
            <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
              <input
                class="form-check-input"
                type="radio"
                name="WTflexRadioDefault"
                id="WTflexRadio__1"
                checked={option2Data.dwr_type == "1"}
                onChange={(e) => {
                  setOption2Data({
                    dwr_type: "1",
                    account_name: "",
                    account_address: "",
                    bank_name: "",
                    aba_swift_no: "",
                    account_iban_no: "",
                    ach_routing_no: "",
                    additional_info: "",
                  });
                  setError2({});
                }}
              />
              <label class="form-check-label" for="WTflexRadio__1">
                Yes, I have an ABA number from my bank.
              </label>
            </div>
          </div>
          <div class="fcrg-item  payment-info-main-option">
            <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
              <input
                class="form-check-input"
                type="radio"
                name="WTflexRadioDefault"
                id="WTflexRadio__2"
                checked={option2Data.dwr_type == "2"}
                onChange={(e) => {
                  setOption2Data({
                    dwr_type: "2",
                    account_name: "",
                    account_address: "",
                    bank_name: "",
                    aba_swift_no: "",
                    account_iban_no: "",
                    ach_routing_no: "",
                    additional_info: "",
                  });
                  setError2({});
                }}
              />
              <label class="form-check-label" for="WTflexRadio__2">
                No, I only have an ACH Routing number and need to request an ACH
                transfer instead.
              </label>
            </div>
          </div>
          <div class="fcrg-item  payment-info-main-option">
            <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
              <input
                class="form-check-input"
                type="radio"
                name="WTflexRadioDefault"
                id="WTflexRadio__3"
                checked={option2Data.dwr_type == "3"}
                onChange={(e) => {
                  setOption2Data({
                    dwr_type: "3",
                    account_name: "",
                    aba_swift_no: "",
                    account_address: "",
                    bank_name: "",
                    account_iban_no: "",
                    ach_routing_no: "",
                    additional_info: "",
                  });
                  setError2({});
                }}
              />
              <label class="form-check-label" for="WTflexRadio__3">
                No, I will contact my bank now to confirm the ABA number.
              </label>
            </div>
          </div>
          {error2.dwr_type ? (
            <p style={{ color: "red", marginLeft: "13px" }}>
              {error2.dwr_type}
            </p>
          ) : (
            ""
          )}
        </div>
      </>

      {option2Data.dwr_type == 1 ? (
        <>
          <div class="form-group">
            <label for="opt1Name" class="form-label">
              Beneficiary Name (as is appears on the account)
              <sup style={{ color: "red" }}>*</sup>
            </label>
            <input
              id="opt1Name"
              name="opt1Name"
              type="text"
              class="form-control"
              value={option2Data.account_name}
              onChange={(e) => {
                const error = { ...error2 };
                if (error.account_name) {
                  error.account_name = "";
                  setError2(error);
                }

                const data = { ...option2Data };
                data.account_name = e.target.value.trim();
                setOption2Data(data);
              }}
            />
            {error2.account_name ? (
              <p style={{ color: "red" }}>{error2.account_name}</p>
            ) : (
              ""
            )}
          </div>
          <div class="form-group">
            <label for="opt1Addre" class="form-label">
              Beneficiary Address (street, city, state and zip ){" "}
              <sup style={{ color: "red" }}>*</sup>
            </label>
            <input
              id="opt1Addre"
              type="text"
              class="form-control"
              value={option2Data.account_address}
              onChange={(e) => {
                const error = { ...error2 };
                if (error.account_address) {
                  error.account_address = "";
                  setError2(error);
                }

                const data = { ...option2Data };
                data.account_address = e.target.value.trim();
                setOption2Data(data);
              }}
            />
            {error2.account_address ? (
              <p style={{ color: "red" }}>{error2.account_address}</p>
            ) : (
              ""
            )}
          </div>

          <div class="form-group">
            <label for="opt1Acc" class="form-label">
              Beneficiary Account Number
              <sup style={{ color: "red" }}>*</sup>
            </label>
            <input
              id="opt1Acc"
              type="text"
              class="form-control"
              value={option2Data.account_iban_no}
              onChange={(e) => {
                const error = { ...error2 };
                if (error.account_iban_no) {
                  error.account_iban_no = "";
                  setError2(error);
                }
                const data = { ...option2Data };
                data.account_iban_no = e.target.value.trim();
                setOption2Data(data);
              }}
            />
            {error2.account_iban_no ? (
              <p style={{ color: "red" }}>{error2.account_iban_no}</p>
            ) : (
              ""
            )}
          </div>
          <div class="form-group">
            <label for="op1Aba" class="form-label">
              ABA Number
              <sup style={{ color: "red" }}>*</sup>
            </label>
            <input
              id="op1Aba"
              type="text"
              class="form-control"
              value={option2Data.aba_swift_no}
              onChange={(e) => {
                const error = { ...error2 };
                if (error.aba_swift_no) {
                  error.aba_swift_no = "";
                  setError2(error);
                }
                const data = { ...option2Data };
                data.aba_swift_no = e.target.value.trim();
                setOption2Data(data);
              }}
            />
            {error2.aba_swift_no ? (
              <p style={{ color: "red" }}>{error2.aba_swift_no}</p>
            ) : (
              ""
            )}
          </div>

          <div class="form-group">
            <label for="op1BankName" class="form-label">
              Beneficiary Bank Name <sup style={{ color: "red" }}>*</sup>
            </label>
            <input
              id="op1BankName"
              type="text"
              class="form-control"
              value={option2Data.bank_name}
              onChange={(e) => {
                const error = { ...error2 };
                if (error.bank_name) {
                  error.bank_name = "";
                  setError2(error);
                }
                const data = { ...option2Data };
                data.bank_name = e.target.value.trim();
                setOption2Data(data);
              }}
            />
            {error2.bank_name ? (
              <p style={{ color: "red" }}>{error2.bank_name}</p>
            ) : (
              ""
            )}
          </div>
          <div class="form-group">
            <label for="op1BankAdd" class="form-label">
              Beneficiary Bank Address <sup style={{ color: "red" }}>*</sup>
            </label>
            <input
              id="op1BankAdd"
              type="text"
              class="form-control"
              value={option2Data.bank_address}
              onChange={(e) => {
                const error = { ...error2 };
                if (error.bank_address) {
                  error.bank_address = "";
                  setError2(error);
                }
                const data = { ...option2Data };
                data.bank_address = e.target.value.trim();
                setOption2Data(data);
              }}
            />
            {error2.bank_address ? (
              <p style={{ color: "red" }}>{error2.bank_address}</p>
            ) : (
              ""
            )}
          </div>

          <div class="form-group">
            <p>
              <strong>What type of reference number do you prefer?</strong>
            </p>
            <div class="four-column-radio-group fw-medium">
              <div class="fcrg-item payment-info-main-option">
                <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="RflowRadioDefault"
                    id="RflowRadio1__1"
                    checked={option2Data.preferred_ref_no == "1"}
                    onChange={() => {
                      const data = { ...option2Data };
                      data.preferred_ref_no = "1";
                      setOption2Data(data);
                    }}
                  />
                  <label class="form-check-label" for="RflowRadio1__1">
                    Claim or File Number
                  </label>
                </div>
              </div>
              <div class="fcrg-item payment-info-main-option">
                <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="RflowRadioDefault"
                    id="RflowRadio1__2"
                    checked={option2Data.preferred_ref_no == "2"}
                    onChange={() => {
                      const data = { ...option2Data };
                      data.preferred_ref_no = "2";
                      setOption2Data(data);
                    }}
                  />
                  <label class="form-check-label" for="RflowRadio1__2">
                    Invoice Number
                  </label>
                </div>
              </div>
              <div class="fcrg-item payment-info-main-option">
                <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="RflowRadioDefault"
                    id="RflowRadio1__3"
                    checked={option2Data.preferred_ref_no == "3"}
                    onChange={() => {
                      const data = { ...option2Data };
                      data.preferred_ref_no = "3";
                      setOption2Data(data);
                    }}
                  />
                  <label class="form-check-label" for="RflowRadio1__3">
                    Shipping Container Number
                  </label>
                </div>
              </div>
              <div class="fcrg-item payment-info-main-option">
                <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="RflowRadioDefault"
                    id="RflowRadio1__4"
                    checked={option2Data.preferred_ref_no == "4"}
                    onChange={() => {
                      const data = { ...option2Data };
                      data.preferred_ref_no = "4";
                      setOption2Data(data);
                    }}
                  />
                  <label class="form-check-label" for="RflowRadio1__4">
                    Salvex Listing number is fine
                  </label>
                </div>
              </div>
            </div>
            {option2Data.preferred_ref_no ? (
              <p
                className="cursor-pointer"
                style={{ color: "rgba(0,0,0,0.4)", marginTop: "7px" }}
                onClick={() => {
                  const data = { ...option2Data };
                  data.preferred_ref_no = "";
                  setOption2Data(data);
                }}
              >
                Clear Selection
              </p>
            ) : (
              ""
            )}
          </div>
          <div class="form-group">
            <p>
              <strong>
                If you have intermediary bank information, please enter it here.
              </strong>
            </p>
            <input
              type="text"
              class="form-control"
              value={option2Data.intermediary_bank_info}
              onChange={(e) => {
                const data = { ...option2Data };
                data.intermediary_bank_info = e.target.value.trim();
                setOption2Data(data);
              }}
            />
          </div>
          <div class="form-group">
            <label for="op1Email" class="form-label">
              Additional email address to be sent confirmation of payment.
            </label>
            <textarea
              id="op1Email"
              type="text"
              class="w-100 form-control"
              value={option2Data.other_email}
              onChange={(e) => {
                const data = { ...option2Data };
                data.other_email = e.target.value.trim();
                setOption2Data(data);
              }}
            />
          </div>
          <div class="form-group">
            <label for="op1addQue" class="form-label">
              Do you have any additional instructions?
            </label>
            <textarea
              id="op1addQue"
              type="text"
              class="w-100 form-control"
              value={option2Data.additional_info}
              onChange={(e) => {
                const data = { ...option2Data };
                data.additional_info = e.target.value.trim();
                setOption2Data(data);
              }}
            />
          </div>
        </>
      ) : option2Data.dwr_type == 2 ? (
        <>
          <div class="form-group">
            <label for="opt2Name" class="form-label">
              Company or Individual Name (as is appears on the account)
              <sup style={{ color: "red" }}>*</sup>
            </label>
            <input
              id="opt2Name"
              name="opt2Name"
              type="text"
              class="form-control"
              value={option2Data.account_name}
              onChange={(e) => {
                const error = { ...error2 };
                if (error.account_name) {
                  error.account_name = "";
                  setError2(error);
                }

                const data = { ...option2Data };
                data.account_name = e.target.value.trim();
                setOption2Data(data);
              }}
            />
            {error2.account_name ? (
              <p style={{ color: "red" }}>{error2.account_name}</p>
            ) : (
              ""
            )}
          </div>
          <div class="form-group">
            <label for="" class="form-label">
              Complete Address associated with account{" "}
              <sup style={{ color: "red" }}>*</sup>
            </label>
            <input
              type="text"
              class="form-control"
              value={option2Data.account_address}
              onChange={(e) => {
                const error = { ...error2 };
                if (error.account_address) {
                  error.account_address = "";
                  setError2(error);
                }

                const data = { ...option2Data };
                data.account_address = e.target.value.trim();
                setOption2Data(data);
              }}
            />
            {error2.account_address ? (
              <p style={{ color: "red" }}>{error2.account_address}</p>
            ) : (
              ""
            )}
          </div>

          <div class="form-group">
            <label for="" class="form-label">
              Name of Bank <sup style={{ color: "red" }}>*</sup>
            </label>
            <input
              type="text"
              class="form-control"
              value={option2Data.bank_name}
              onChange={(e) => {
                const error = { ...error2 };
                if (error.bank_name) {
                  error.bank_name = "";
                  setError2(error);
                }
                const data = { ...option2Data };
                data.bank_name = e.target.value.trim();
                setOption2Data(data);
              }}
            />
            {error2.bank_name ? (
              <p style={{ color: "red" }}>{error2.bank_name}</p>
            ) : (
              ""
            )}
          </div>
          <div class="form-group">
            <label for="" class="form-label">
              Account Number <sup style={{ color: "red" }}>*</sup>
            </label>
            <input
              type="number"
              class="form-control"
              value={option2Data.account_iban_no}
              onChange={(e) => {
                const error = { ...error2 };
                if (error.account_iban_no) {
                  error.account_iban_no = "";
                  setError2(error);
                }
                const data = { ...option2Data };
                data.account_iban_no = e.target.value.trim();
                setOption2Data(data);
              }}
            />
            {error2.account_iban_no ? (
              <p style={{ color: "red" }}>{error2.account_iban_no}</p>
            ) : (
              ""
            )}
          </div>

          <div class="form-group">
            <label for="" class="form-label">
              ACH Routing Number <sup style={{ color: "red" }}>*</sup>
            </label>
            <input
              type="number"
              class="form-control"
              value={option2Data.ach_routing_no}
              onChange={(e) => {
                const error = { ...error2 };
                if (error.ach_routing_no) {
                  error.ach_routing_no = "";
                  setError2(error);
                }
                const data = { ...option2Data };
                data.ach_routing_no = e.target.value.trim();
                setOption2Data(data);
              }}
            />
            {error2.ach_routing_no ? (
              <p style={{ color: "red" }}>{error2.ach_routing_no}</p>
            ) : (
              ""
            )}
          </div>
          <div class="form-group">
            <label for="" class="form-label">
              Additional email address to be sent confirmation of payment.
            </label>
            <textarea
              type="text"
              class="w-100 form-control"
              value={option2Data.other_email}
              onChange={(e) => {
                const data = { ...option2Data };
                data.other_email = e.target.value.trim();
                setOption2Data(data);
              }}
            />
          </div>
          <div class="form-group">
            <label for="" class="form-label">
              Do you have any additional instructions?
            </label>
            <textarea
              type="text"
              class="w-100 form-control"
              value={option2Data.additional_info}
              onChange={(e) => {
                const data = { ...option2Data };
                data.additional_info = e.target.value.trim();
                setOption2Data(data);
              }}
            />
          </div>
        </>
      ) : (
        option2Data.dwr_type == 3 && (
          <div class="form-group">
            <label for="" class="form-label">
              Do you have any additional instructions?
            </label>
            <textarea
              type="text"
              class="w-100 form-control"
              value={option2Data.additional_info}
              onChange={(e) => {
                const data = { ...option2Data };
                data.additional_info = e.target.value.trim();
                setOption2Data(data);
              }}
            />
          </div>
        )
      )}
    </>
  );
};

export default PaymentTypeTwo;

