import React from "react";
import { Modal } from "react-bootstrap";
import close from "../../../include/images/close.svg";
import DateFormat from "../../../common/DateFormat/dateFormat";
import NextButton from "../../../common/form/nextButton";

const ShowBidsModal = ({ bidDetail, setBidDetail, updateData, setUpdateData, handleUpdate, loading, ...props }) => {
  const clickToPrint = () => {
    var content = document.getElementById("showBidsContent");
    var pri =
      document.getElementById("ifmcontentstoprintBid") &&
      document.getElementById("ifmcontentstoprintBid").contentWindow;
    if (pri) {
      pri.document.open();
      pri.document.write(content.innerHTML);
      pri.document.close();
      pri.focus();
      pri.print();
    }
  };

  return (
    <>
      <iframe id="ifmcontentstoprintBid" style={{ height: "0px", width: "0px", position: "absolute" }}></iframe>
      <Modal
        className="modal fade custom-modal"
        id="showBidsContent"
        show={props.show}
        onHide={props.onHide}
        dialogClassName="modal-dialog modal-dialog-centered modal-lg"
      >
        <div class="modal-content d-flex flex-column">
          <div class="custom-modal-header d-flex align-items-center justify-content-between">
            <h5 class="mb-0 text-capitalize">Show Bids</h5>
            <button
              type="button"
              class="close-btn d-flex align-items-center justify-content-center"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={props.onHide}
            >
              <img src={close} alt="close" />
            </button>
          </div>
          <div class="custom-modal-body flex-grow-1 pb-0">
            <div className="d-flex mb-3">
              <button className="btn btn-primary btn-xs-size btn-auto" onClick={() => clickToPrint()}>
                Print
              </button>
            </div>
            <div className="dividerTable"></div>
            <table class="table modal-product-detail-table mb-3">
              <colgroup>
                <col style={{ width: "80px" }} />
                <col />
              </colgroup>
              <tr>
                <td>Buyer</td>
                <td>
                  <a href="javascript:void(0)">
                    {bidDetail?.id} - {bidDetail?.name}
                  </a>
                </td>
              </tr>
              <tr>
                <td>Company</td>
                <td>{bidDetail?.company_name}</td>
              </tr>
              <tr>
                <td>Date</td>
                <td>
                  <DateFormat date={bidDetail?.bid_time} />
                </td>
              </tr>
              <tr>
                <td>Unit Bid</td>
                <td>{bidDetail?.unit_bid}</td>
              </tr>
              <tr>
                <td>Original Bid</td>
                <td>{bidDetail?.total_bid}</td>
              </tr>
              <tr>
                <td>Terms</td>
                <td>{bidDetail?.terms}</td>
              </tr>
            </table>
            <div className="dividerTable"></div>
            <table class="table modal-product-detail-table mb-3">
              <colgroup>
                <col style={{ width: "80px" }} />
                <col />
              </colgroup>
              <tr>
                <td>Seller Bid</td>
                <td>
                  <div>
                    <input
                      type="text"
                      value={updateData?.seller_bid}
                      class="form-control"
                      onChange={(e) => {
                        const data = { ...updateData };
                        data.seller_bid = e.target.value;
                        setUpdateData(data);
                      }}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>Buyer Premium</td>
                <td>
                  <div>
                    <input
                      type="text"
                      value={updateData?.buyer_premium}
                      class="form-control"
                      onChange={(e) => {
                        const data = { ...updateData };
                        data.buyer_premium = e.target.value;
                        setUpdateData(data);
                      }}
                    />
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div class="custom-modal-dividor"></div>
          <div class="custom-modal-footer d-grid gap-2 d-md-flex align-items-center justify-content-md-end">
            <button
              class="btn btn-link me-md-2"
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={props.onHide}
            >
              Cancel
            </button>
            <NextButton label="Save" classData="btn btn-default" handleSubmit={handleUpdate} loading={loading} />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ShowBidsModal;
