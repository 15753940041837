import React from "react";
import Header from "../../layouts/header";
import errorImg from "../../include/images/505.svg";
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log("ERR => ", error);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className={` grey-bg `}>
          <Header />
          <div
            class="error-page-container d-flex align-items-center justify-content-center"
            style={{ marginTop: "150px" }}
          >
            <div class="error-content text-center d-flex align-items-center justify-content-between">
              <div class="error-content-box">
                <h6>Error</h6>
                <div class="four-zero-four-img">
                  <img src={errorImg} alt="Not Found" />
                </div>
                <p>Something went wrong!</p>
                <a href="/" class="btn btn-primary">
                  Go back to home
                </a>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
