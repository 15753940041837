import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import close from "../../../include/images/close.svg";
import NextButton from "../../../common/form/nextButton";
import { toast } from "react-toastify";
import AlertError from "../../../common/alerts/alertError";

const NewBidModal = ({ newBidData, setNewBidData, handleAddNew, loading, ...props }) => {
  return (
    <Modal
      className="modal fade custom-modal"
      id="moreEmailClicker"
      show={props.show}
      onHide={props.onHide}
      dialogClassName="modal-dialog modal-dialog-centered modal-lg"
    >
      <div class="modal-content d-flex flex-column">
        <div class="custom-modal-header d-flex align-items-center justify-content-between">
          <h5 class="mb-0 text-capitalize">New Bid</h5>
          <button
            type="button"
            class="close-btn d-flex align-items-center justify-content-center"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={props.onHide}
          >
            <img src={close} alt="close" />
          </button>
        </div>
        <div class="custom-modal-body flex-grow-1 pb-0">
          <table class="table modal-product-detail-table mb-3">
            <colgroup>
              <col style={{ width: "80px" }} />
              <col />
            </colgroup>
            <tr>
              <td>
                Buyer<sup style={{ color: "Red" }}>*</sup>
              </td>
              <td>
                <div className="cursor-pointer" onClick={props.toggleNewBuyer}>
                  <div className="form-control" style={{ height: "40px" }}>
                    {props.buyerName}
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>Unit Bid</td>
              <td>
                <div>
                  <input
                    type="text"
                    value={newBidData?.unit_bid}
                    class="form-control"
                    onChange={(e) => {
                      const data = { ...newBidData };
                      data.unit_bid = e.target.value;
                      setNewBidData(data);
                    }}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td width={"96px"}>
                Total Bid<sup style={{ color: "Red" }}>*</sup>
              </td>
              <td>
                <div>
                  <input
                    type="text"
                    value={newBidData?.total_bid}
                    class="form-control"
                    onChange={(e) => {
                      const data = { ...newBidData };
                      data.total_bid = e.target.value;
                      data.seller_bid = e.target.value;
                      setNewBidData(data);
                    }}
                  />
                </div>
              </td>
            </tr>
            {/* <tr>
              <td>Seller Bid</td>
              <td>
                <div>
                  <input
                    type="text"
                    disabled={props?.page == "SARR" ? false : true}
                    class="form-control"
                    onChange={(e) => {
                      const data = { ...newBidData };
                      data.seller_bid = e.target.value;
                      setNewBidData(data);
                    }}
                    value={newBidData?.seller_bid}
                  />
                </div>
              </td>
            </tr> */}
            <tr>
              <td>Terms</td>
              <td>
                <div>
                  <textarea
                    class="form-control w-100"
                    id="FormControlTextareaL"
                    value={newBidData?.terms}
                    onChange={(e) => {
                      const data = { ...newBidData };
                      data.terms = e.target.value;
                      setNewBidData(data);
                    }}
                  />
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div class="custom-modal-dividor"></div>
        <div class="custom-modal-footer d-grid gap-2 d-md-flex align-items-center justify-content-md-end">
          <button
            class="btn btn-link me-md-2"
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={props.onHide}
          >
            Cancel
          </button>
          <NextButton
            label="Save"
            classData="btn btn-default"
            handleSubmit={handleAddNew}
            loading={loading}
            disable={!newBidData?.total_bid || !newBidData?.seller_bid || newBidData?.user_id == 0 ? true : false}
          />
        </div>
      </div>
    </Modal>
  );
};

export default NewBidModal;
