import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import Header from "../../layouts/header";
import { connect } from "react-redux";
import LoadingBar from "react-top-loading-bar";
import { toast } from "react-toastify";
import AlertError from "../../common/alerts/alertError";
import {
  deletePartReminder,
  getAviationDetails,
  getOrderRequests,
  getRfqRequests,
  setAviationPartReminder,
  updateRfqArchiveStatus,
} from "../../store/aviation";
import OrderRequestTable from "../../components/aviation/orderRequests/orderRequestTable";
import ViewDetails from "../../components/listingDetail/aviationParts/viewDetails";
import OrdersModal from "../../components/aviation/orderRequests/ordersModal";
import LogModal from "../../components/aviation/logs/logModal";
import ImageLargeModal from "../../layouts/modals/imageGalleryModal";
import ArchiveModal from "../../layouts/modals/archiveModal";
import { getProfile } from "../../utils.js/localStorageServices";
import AlertSuccess from "../../common/alerts/alertSuccess";

const OrderRequest = (props) => {
  const profile = getProfile();
  const ref = useRef(null);
  const [loading, setLoading] = useState(false);

  //params state
  const [page, setPage] = useState(1);

  const [applyFilter, setApplyFilter] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [viewAllModal, setViewAllModal] = useState(false);
  const [selectedValue, setSelectedValue] = useState({});
  const [tableData, setTableData] = useState([]);
  const [clearFilter, setClearFilter] = useState(false);
  const [showLogModal, setShowLogModal] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [rfqId, setRfqId] = useState("");
  const toggleArchiveModal = () => {
    setShowArchiveModal(!showArchiveModal);
  };

  const toggleLogModal = () => {
    setShowLogModal(!showLogModal);
  };

  const toggleViewAllModal = () => {
    setViewAllModal(!viewAllModal);
    toggleLogModal();
  };

  const toggleViewModal = () => {
    setViewModal(!viewModal);
  };

  //filter
  const [showFilter, setShowFilter] = useState(false);
  const [filteredValues, setFilteredValues] = useState({
    orderId: "",
    keyword: "",
    isReminderSet: "",
  });
  const [response, setResponse] = useState({
    res: false,
    orderId: "",
    keyword: "",
    isReminderSet: "",
  });

  const toggleFilter = () => {
    setFilteredValues({
      orderId: response?.orderId,
      keyword: response?.keyword,
      isReminderSet: response?.isReminderSet,
    });
    setShowFilter(!showFilter);
  };

  useEffect(() => {
    ref.current.continuousStart();
    setLoading(true);
    const params = {
      page: 1,
      sort: "createdAt",
      order: "desc",
    };
    props.getRfqRequests(params, (res) => {
      if (res && res.status === 200) {
        ref.current.complete();
        setLoading(false);
      } else {
        ref.current.complete();
        setLoading(false);
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  }, []);

  useEffect(() => {
    ref.current.continuousStart();
    setLoading(true);
    const params = {
      page: page,
      sort: "createdAt",
      order: "desc",
    };
    if (response?.orderId) {
      params.orderId = response?.orderId;
    }
    if (response?.keyword) {
      params.keyword = response?.keyword;
    }
    if (response?.isReminderSet?.value !== null || response?.isReminderSet?.value !== "") {
      params.isReminderSet = response?.isReminderSet?.value;
    }
    props.getRfqRequests(params, (res) => {
      if (res && res.status === 200) {
        ref.current.complete();
        setLoading(false);
      } else {
        ref.current.complete();
        setLoading(false);
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  }, [page]);

  const loadOrderRequests = () => {
    ref.current.continuousStart();
    setLoading(true);
    const params = {
      page: page,
      sort: "createdAt",
      order: "desc",
    };
    if (filteredValues?.orderId) {
      params.orderId = filteredValues?.orderId;
    }
    props.getRfqRequests(params, (res) => {
      if (res && res.status === 200) {
        ref.current.complete();
        setLoading(false);
      } else {
        ref.current.complete();
        setLoading(false);
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const orderRequestData = props.getAviation && props.getAviation.rfqRequests && props.getAviation.rfqRequests.data;

  const totalCount =
    props.getAviation && props.getAviation.rfqRequests && props.getAviation.rfqRequests?.pagination?.totalCount;

  const filteredCount =
    props.getAviation && props.getAviation.rfqRequests && props.getAviation.rfqRequests.pagination?.filteredCount;

  const totalPages =
    props.getAviation && props.getAviation.rfqRequests && props.getAviation.rfqRequests.pagination?.totalPage;

  const handleFilter = () => {
    ref.current.continuousStart();
    setLoading(true);
    setPage(1);
    const params = {
      page: 1,
      sort: "createdAt",
      order: "desc",
    };
    if (filteredValues?.orderId) {
      params.orderId = filteredValues?.orderId;
    }
    if (filteredValues?.keyword) {
      params.keyword = filteredValues?.keyword;
    }
    if (filteredValues?.isReminderSet?.value !== null || filteredValues?.isReminderSet?.value !== "") {
      params.isReminderSet = filteredValues?.isReminderSet?.value;
    }
    if (
      params?.orderId ||
      params?.keyword ||
      params?.isReminderSet?.value !== null ||
      params?.isReminderSet?.value !== ""
    ) {
      setApplyFilter(true);
    }
    props.getRfqRequests(params, (res) => {
      if (res && res.status === 200) {
        setResponse({
          res: true,
          orderId: filteredValues?.orderId,
          keyword: filteredValues?.keyword,
          isReminderSet: filteredValues?.isReminderSet,
        });
        if (
          filteredValues?.orderId ||
          filteredValues?.keyword ||
          filteredValues?.isReminderSet?.value !== null ||
          filteredValues?.isReminderSet?.value !== ""
        ) {
          !clearFilter && toggleFilter();
        }

        ref.current.complete();
        setLoading(false);
      } else {
        ref.current.complete();
        setLoading(false);
        console.log(res.data.message === '"orderId" must be a number', "are the message from the api");
        toast(
          <AlertError
            message={
              res.data.message
                ? res.data.message === '"orderId" must be a number'
                  ? "Request ID must be a number"
                  : res.data.message
                : "Something Went Wrong"
            }
          />,
        );
      }
    });
    setClearFilter(false);
  };

  useEffect(() => {
    const data = { ...response };
    data.res = true;
    setResponse(data);
    if (!showFilter && applyFilter && !response?.res) {
      if (
        !response?.orderId &&
        !response?.keyword &&
        (response?.isReminderSet?.value == null || response?.isReminderSet?.value == "")
      ) {
        setApplyFilter(false);
      }
      ref.current.continuousStart();
      setLoading(true);
      setPage(1);
      const params = {
        page: 1,
        sort: "createdAt",
        order: "desc",
      };
      if (response?.orderId) {
        params.orderId = response?.orderId;
      }
      if (response?.keyword) {
        params.keyword = response?.keyword;
      }
      if (response?.isReminderSet?.value !== null || response?.isReminderSet?.value !== "") {
        params.isReminderSet = response?.isReminderSet?.value;
      }
      if (
        params?.orderId ||
        params?.keyword ||
        params?.isReminderSet?.value !== null ||
        params?.isReminderSet?.value !== ""
      ) {
        setApplyFilter(true);
      }
      props.getRfqRequests(params, (res) => {
        if (res && res.status === 200) {
          if (
            filteredValues?.orderId ||
            filteredValues?.keyword ||
            filteredValues?.isReminderSet?.value !== null ||
            filteredValues?.isReminderSet?.value !== ""
          ) {
            !clearFilter && toggleFilter();
          }

          ref.current.complete();
          setLoading(false);
        } else {
          ref.current.complete();
          setLoading(false);
          console.log(res.data.message === '"orderId" must be a number', "are the message from the api");
          toast(
            <AlertError
              message={
                res.data.message
                  ? res.data.message === '"orderId" must be a number'
                    ? "Request ID must be a number"
                    : res.data.message
                  : "Something Went Wrong"
              }
            />,
          );
        }
      });
      setClearFilter(false);
    }
  }, [response?.res]);

  const [selectedImage, setSelecetedImage] = useState([]);
  const [imageGalleryModal, setImageGalleryModal] = useState(false);

  const toggleImageGalleryModal = () => {
    toggleLogModal();
    setImageGalleryModal(!imageGalleryModal);
  };
  const checkIfImageExists = (url, callback) => {
    const img = new Image();
    img.src = url;

    if (img.complete) {
      callback(true);
    } else {
      img.onload = () => {
        callback(true);
      };

      img.onerror = () => {
        callback(false);
      };
    }
  };

  //Archive RFQ
  const handleArchive = () => {
    setLoading(true);
    ref.current.continuousStart();
    const payload = {
      rfqIds: [rfqId],
      isArchived: true,
    };
    props.updateRfqArchiveStatus(payload, (res) => {
      if (res.status === 200) {
        const params = {
          page: page,
          sort: "createdAt",
          order: "desc",
        };
        if (response?.orderId) {
          params.orderId = response?.orderId;
        }
        if (response?.keyword) {
          params.keyword = response?.keyword;
        }
        if (response?.isReminderSet?.value !== null || response?.isReminderSet?.value !== "") {
          params.isReminderSet = response?.isReminderSet;
        }
        props.getRfqRequests(params, (res) => {});
        ref.current.complete();
        setLoading(false);
        toggleArchiveModal();
      } else {
        ref.current.complete();
        setLoading(false);
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const handleAddReminder = (item) => {
    ref.current.continuousStart();
    const payload = {
      partNumber: item?.aviationPart?.partNumber,
      type: "aviation",
      rfq: item?._id,
    };
    props.setAviationPartReminder(payload, (res) => {
      if (res.status === 200) {
        const params = {
          page: page,
          sort: "createdAt",
          order: "desc",
        };

        props.getRfqRequests(params, (res) => {});
        ref.current.complete();
        toast(<AlertSuccess message={"Information Saved"} />);
      } else {
        ref.current.complete();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const handleDeleteReminder = (item) => {
    ref?.current?.continuousStart();
    const payload = {
      rfqIds: [item?._id],
    };
    props?.deletePartReminder(payload, (res) => {
      if (res.status === 200) {
        const params = {
          page: page,
          sort: "createdAt",
          order: "desc",
        };

        props.getRfqRequests(params, (res) => {});
        ref.current.complete();
        toast(<AlertSuccess message={"Information Saved"} />);
      } else {
        ref.current.complete();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };
console.log(response,'are the response...')
  return (
    <>
      <LoadingBar height={5} color="#47AD1D" ref={ref} />
      <Header />
      <OrderRequestTable
        loading={loading}
        orderRequestData={orderRequestData}
        filteredCount={filteredCount}
        totalCount={totalCount}
        totalPages={totalPages}
        page={page}
        setPage={setPage}
        toggleFilter={toggleFilter}
        filteredValues={filteredValues}
        setFilteredValues={setFilteredValues}
        showFilter={showFilter}
        handleFilter={handleFilter}
        applyFilter={applyFilter}
        toggleViewModal={toggleViewModal}
        toggleLogModal={toggleLogModal}
        setTableData={setTableData}
        setClearFilter={setClearFilter}
        response={response}
        setResponse={setResponse}
        handleArchive={handleArchive}
        toggleArchiveModal={toggleArchiveModal}
        setRfqId={setRfqId}
        handleAddReminder={handleAddReminder}
        handleDeleteReminder={handleDeleteReminder}
      />

      <ViewDetails show={viewAllModal} onHide={toggleViewAllModal} selectedValue={selectedValue?.aviationPart} />

      <LogModal
        show={showLogModal}
        onHide={toggleLogModal}
        loading={loading}
        tableData={tableData}
        viewAllModal={viewAllModal}
        toggleViewAllModal={toggleViewAllModal}
        setSelectedValue={setSelectedValue}
        orderRequest={true}
        imageGalleryModal={imageGalleryModal}
        setImageGalleryModal={setImageGalleryModal}
        selectedImage={selectedImage}
        setSelecetedImage={setSelecetedImage}
        toggleImageGalleryModal={toggleImageGalleryModal}
        checkIfImageExists={checkIfImageExists}
      />
      <ImageLargeModal
        show={imageGalleryModal}
        onHide={toggleImageGalleryModal}
        items={selectedImage}
        startIndex={0}
        title={""}
      />
      <ArchiveModal
        show={showArchiveModal}
        onHide={toggleArchiveModal}
        loadingDelete={loading}
        deleteHandler={handleArchive}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getOrderRequests: (params, callback) => dispatch(getOrderRequests(params, callback)),
  getRfqRequests: (params, callback) => dispatch(getRfqRequests(params, callback)),
  updateRfqArchiveStatus: (data, callback) => dispatch(updateRfqArchiveStatus(data, callback)),
  setAviationPartReminder: (data, callback) => dispatch(setAviationPartReminder(data, callback)),
  deletePartReminder: (data, callback) => dispatch(deletePartReminder(data, callback)),
});

const mapStateToProps = (state) => ({
  getAviation: getAviationDetails(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(OrderRequest));
