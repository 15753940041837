import React, { useEffect, useState } from "react";
import tdIcon1 from "../../../include/images/tdIcon1.svg";
import tdIcon4 from "../../../include/images/td-icon-04.svg";
import tdIcon5 from "../../../include/images/td-icon-05.svg";
import tdIcon6 from "../../../include/images/td-icon-06.svg";
import tdIcon7 from "../../../include/images/td-icon-07.svg";
import tdIcon8 from "../../../include/images/td-icon-08.svg";
import barrIcon from "../../../include/images/Reports_R.svg";
import trash from "../../../include/images/trash-18x18.svg";
import close from "../../../include/images/close-icon.svg";
import ndaRed from "../../../include/images/nda-red.svg";
import ndaYellow from "../../../include/images/nda-yellow.svg";
import ndaGreen from "../../../include/images/nda-green.svg";
//temp image
import reactivate from "../../../include/images/reactive.png";
import icon1 from "../../../include/images/icon___1.svg";
import icon2 from "../../../include/images/icon__2.svg";
import icon3 from "../../../include/images/red-hand.png";
import ReactTooltip from "react-tooltip";

import {
  addNewBid,
  bidAddNote,
  bidsData,
  declineBid,
  deleteBid,
  getBidDetail,
  getBids,
  getFeedback,
  searchBuyer,
  sendNDA,
  updateStatus,
} from "../../../store/bids";
import { connect } from "react-redux";
import DateFormat from "../../../common/DateFormat/dateFormat";
import Skeleton from "react-loading-skeleton";
import ShowBidsModal from "../../../layouts/modals/bids/showBidsModal";
import { toast } from "react-toastify";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import AlertError from "../../../common/alerts/alertError";
import NewBidModal from "../../../layouts/modals/bids/newBidModal";
import DeleteModal from "../../../layouts/modals/bids/deleteModal";
import DeclineModal from "../../../layouts/modals/bids/declineModal";
import FeedbackModal from "../../../layouts/modals/bids/feedbackModal";
import ActivationModal from "../../../layouts/modals/bids/activationModal";
import MaxBidModal from "../../../layouts/modals/bids/maxBidModal";
import { availableFlags, removeTags, twoDecimals } from "../../../utils.js/conversion";
import { getCountries, getStates } from "../../../store/productLocation";
import { getLatestActivity, sendMailToManager } from "../../../store/latestActivity";
import SendMail from "../../../layouts/modals/latestActivity/sendMail";
// import moment from "moment";
import moment from "moment-timezone";
import BidsHistory from "../../../layouts/modals/bids/bidsHistory";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import AddNotesModal from "../../../layouts/modals/invoicesModal/addNotesModal";
import Select from "react-select";
import { getInvoice, invoiceAddNote } from "../../../store/invoices";
import { customStyles } from "../../../common/customStyles/reactSelectStyle";
import { updateBuyerReportEmailFrequency } from "../../../store/primarySeller";

import BuyerARRHistory from "../../../layouts/modals/bids/aRRHistory";
import { getEmailContent, getEmailLogs, getEmailUUID } from "../../../store/third-part-apis/brevo";
import ViewBARREmail from "../../reports/buyerARREmailStats/viewEmail";
import { questionDetails } from "../../../store/questions";
import FindUser from "../../../common/findUser/findUser";
import AddBidNotesModal from "../../../layouts/modals/bids/addBidNotesModal";
import NcndaModal from "../../../layouts/modals/bids/ncndaModal";
import Joi from "joi";
import { validateErrorMessage } from "../../../common/validation/validation";
import _ from "lodash";
import { getListingProductDetail } from "../../../store/listingProduct";

const Bids = (props) => {
  const mode = process.env.REACT_APP_MODE;
  const accessArr = [12210968, 10011993, 10012798, 12230160];
  const session = localStorage.getItem("data");
  const data = JSON.parse(session);
  const accessLevels = data?.accessLevels?.bid_delete_access;

  const d = new Date();
  let diff = d.getTimezoneOffset();

  const weekFrequencyOptions = [
    { name: "Select Week", value: "-1" },
    { name: "0 Week", value: 0 },
    { name: "1 Week", value: 1 },
    { name: "2 Weeks", value: 2 },
    { name: "3 Weeks", value: 3 },
    { name: "4 Weeks", value: 4 },
  ];

  const [userId, setUserId] = useState("");
  const [superAdmin, setSuperAdmin] = useState(false);
  const [buyerARRLogData, setBuyerARRLogData] = useState([]);
  const [showBuyerARRHistory, setShowBuyerARRHistory] = useState(false);
  const [BARRfilters, setBARRFilters] = useState({
    action: { name: "Select Action", value: "-1" },
    startDate: null,
    endDate: null,
    dateRange: {
      name: "Last 30 days",
      value: { from: moment().subtract(30, "days").format("YYYY-MM-DD"), to: moment().format("YYYY-MM-DD") },
    },
  });
  const [bARREmail, setBarrEmail] = useState("");
  const [showSentEmail, setShowSentEmail] = useState(false);
  const [sentEmailContent, setSentEmailContent] = useState(null);

  useEffect(() => {
    setUserId(data.user_id);
  }, []);

  const toggleSentEmail = () => {
    setShowSentEmail(!showSentEmail);
  };

  const toggleBuyerARRHistory = () => {
    setShowBuyerARRHistory(!showBuyerARRHistory);
  };

  const fetchEmailContent = async (email, messageId) => {
    props?.refStart();
    const uuidData = await getEmailUUID(
      `smtp/emails?email=${email}&messageId=${messageId}&sort=desc&limit=500&offset=0`,
    );
    const emailContent = await getEmailContent(`smtp/emails/${uuidData?.data?.transactionalEmails?.[0]?.uuid}`);
    setSentEmailContent(emailContent?.data);
    toggleSentEmail();
    props?.refStop();
  };

  const getBuyerARRLogs = async (email) => {
    props?.refStart();
    const logsData = await getEmailLogs(
      `smtp/statistics/events?limit=250&offset=0&email=${email}&tags=Buyer%20Report&sort=desc`,
    );
    setBarrEmail(email);

    setBuyerARRLogData(logsData?.data?.events);
    toggleBuyerARRHistory();
    props?.refStop();
  };

  useEffect(() => {
    const filteredData = async () => {
      props?.refStart();
      const logsData = await getEmailLogs(
        `smtp/statistics/events?limit=250&offset=0&email=${bARREmail}&tags=Buyer%20Report&sort=desc${
          BARRfilters?.startDate && BARRfilters?.endDate
            ? `&startDate=${BARRfilters?.startDate}&endDate=${BARRfilters?.endDate}`
            : ""
        }`,
      );
      if (logsData?.message && logsData?.status !== 200) {
        props?.refStop();
        toast(<AlertError message={logsData?.response?.data?.message} />);
      } else {
        props?.refStop();
        setBuyerARRLogData(logsData?.data?.events);
      }
    };
    if (
      showBuyerARRHistory &&
      ((!BARRfilters?.startDate && !BARRfilters?.endDate) || (BARRfilters?.startDate && BARRfilters?.endDate))
    ) {
      filteredData();
    }
  }, [BARRfilters?.startDate, BARRfilters?.endDate]);

  useEffect(() => {
    if (accessArr.includes(userId)) {
      setSuperAdmin(true);
    } else {
      setSuperAdmin(false);
    }
  }, [userId]);

  const bidsList = props && props.bidsData && props.bidsData.bids && props.bidsData.bids.data;
  const highestBidsArray = props?.bidsData?.bids?.highest5;
  const questionLists = props?.questionDetails?.questions?.data;
  const top3 = props?.bidsData?.bids?.top3;
  const productDetails = props?.getListingProductDetail?.detail?.detail || {};
  const highest5 = bidsList
    ?.filter((it) => highestBidsArray?.includes(it?.b_id))
    ?.sort((a, b) => b?.total_bid - a?.total_bid);

  const [showBidsModal, setShowBidsModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [bidDetail, setBidDetail] = useState({});
  const [loadingNewBid, setLoadingNewBid] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [bidId, setBidId] = useState("");
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const [loadingDecline, setLoadingDecline] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [showActivationModal, setShowActivationModal] = useState(false);
  const [loadingActivation, setLoadingActivation] = useState(false);
  const [activation, setActivation] = useState("");
  const [showMaxBidModal, setShowMaxBidModal] = useState(false);
  const [loadingMaxBid, setLoadingMaxBid] = useState(false);
  const [feedbackData, setFeedbackData] = useState({
    action: "feedback",
    posted_by: "",
    b_id: "",
    auc_title: "",
    member_id: "",
    auction_id: "",
    buyer_id: "",
    rating: "",
    review: "",
  });
  const [loadingFeedback, setLoadingFeedback] = useState(false);
  const [newBidData, setNewBidData] = useState({
    b_id: -1,
    user_id: 0,
    unit_bid: "",
    total_bid: "0.00",
    terms: "",
  });
  const [declineData, setDeclineData] = useState({
    decline: 0,
    new_message: "",
  });

  const [loading, setLoading] = useState(false);
  const [showAddNote, setShowAddNote] = useState(false);
  const [showBidNote, setShowBidNote] = useState(false);
  const [invoiceNote, setInvoiceNote] = useState({
    invoice_id: 0,
    invoiceNotePrompt: false,
    invoiceNote: "",
  });
  const [bidNote, setBidNote] = useState({
    bid_id: 0,
    bidNotePrompt: false,
    bidNote: "",
  });

  const [showSendNDA, setShowSendNDA] = useState(false);
  const [errors, setErrors] = useState({});
  const [ndaData, setNdaData] = useState({
    userId: "",
    name: "",
    email: "",
    country: "",
    company: "",

    salvex_name: "",
    salvex_company: "",
    salvex_e_signature: "public/newsalvex/sign/4b4fd571-3622-4106-b850-b34dbdb25ecd.png",
  });

  const schemaValidate = Joi.object({
    email: Joi.string()
      .label("Email")
      .required()
      .error((errors) => validateErrorMessage(errors)),
    name: Joi.string()
      .label("Name")
      .required()
      .error((errors) => validateErrorMessage(errors)),
    company: Joi.string()
      .label("Company")
      .required()
      .error((errors) => validateErrorMessage(errors)),
    country: Joi.string()
      .label("Country")
      .required()
      .error((errors) => validateErrorMessage(errors)),
    salvex_name: Joi.string()
      .label("Savlex Name")
      .required()
      .error((errors) => validateErrorMessage(errors)),
    // salvex_company: Joi.string()
    //   .label("Salvex Company")
    //   .required()
    //   .error((errors) => validateErrorMessage(errors)),
    // salvex_e_signature: Joi.string()
    //   .label("Savlex Signature")
    //   .required()
    //   .error((errors) => validateErrorMessage(errors)),
  });

  const validateNda = (type = false) => {
    const payload = {
      name: ndaData.name ? ndaData.name : "",
      email: ndaData.email ? ndaData.email : "",
      country: ndaData.country ? ndaData.country : "",
      company: ndaData.company ? ndaData.company : "",
      salvex_name: ndaData.salvex_name ? ndaData.salvex_name : "",
      salvex_company: ndaData.salvex_company ? ndaData.salvex_company : "",
      salvex_e_signature: ndaData.salvex_e_signature ? ndaData.salvex_e_signature : "",
    };
    const error = schemaValidate.validate(_.pickBy(payload, _.identity), {
      abortEarly: type,
    });
    if (error.error && error.error.details) {
      const errors = {};
      error.error.details.forEach((element) => {
        errors[element.context.key] = element.message;
      });
      setErrors(errors);
      return false;
    }
    setErrors("");
    return true;
  };
  const toggleSendNDA = (val) => {
    const data = { ...ndaData };
    data.userId = val.user_id ? val.user_id : "";
    data.name = val.name ? val?.name : "";
    data.email = val.email ? val.email : "";
    data.country = val.c_country ? val.c_country : "";
    data.company = val.company_name ? val.company_name : "";
    setNdaData(data);
    setShowSendNDA(!showSendNDA);
  };

  //Send NDA
  const handleSendNDA = () => {
    if (!validateNda()) return;
    const payload = {
      name: ndaData.name ? ndaData.name : "",
      email: ndaData.email ? ndaData.email : "",
      country: ndaData.country ? ndaData.country : "",
      company: ndaData.company ? ndaData.company : "",
    };
    setLoading(true);
    props.refStart();
    props.sendNDA(ndaData.userId, payload, (res) => {
      if (res.status === 200) {
        props.refStop();
        setLoading(false);
        setShowSendNDA(false);
        toast(<AlertSuccess message={"Information Saved"} />);
      } else {
        props.refStop();
        setLoading(false);
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
    props.refStart();
  };

  const toggleAddNote = () => {
    setShowAddNote(!showAddNote);
  };
  const toggleAddBidNote = () => {
    setShowBidNote(!showBidNote);
  };
  const addInvoice = props?.invoices?.addInvoice?.data;
  const addBid = props?.bidsData?.addBid?.data;

  useEffect(() => {
    if (invoiceNote.invoiceNotePrompt) {
      handleAddNote();
    }
  }, [invoiceNote]);

  useEffect(() => {
    if (bidNote.bidNotePrompt) {
      handleAddBidNote();
    }
  }, [bidNote]);

  //Add Note
  const handleAddNote = () => {
    setLoading(true);
    props.refStart();
    props.invoiceAddNote(props.auctionId, invoiceNote, (res) => {
      if (res.status === 200) {
        const detail = res?.data?.data ? res?.data?.data : {};
        const data = { ...invoiceNote };
        data.invoiceNotePrompt = false;
        data.invoiceNote = detail.invoice_notes ? detail.invoice_notes : "";
        setInvoiceNote(data);
        props.getBids(props.auctionId, (res) => {});
        props.refStop();
        setLoading(false);
        toggleAddNote();
      } else {
        props.refStop();
        setLoading(false);
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  //Add Bid Note
  const handleAddBidNote = () => {
    setLoading(true);
    props.refStart();
    props.bidAddNote(props.auctionId, bidNote, (res) => {
      if (res.status === 200) {
        const detail = res?.data?.data ? res?.data?.data : {};
        const data = { ...bidNote };
        data.bidNotePrompt = false;
        data.bidNote = detail.bid_notes ? detail.bid_notes : "";
        setBidNote(data);
        props.getBids(props.auctionId, (res) => {});
        props.refStop();
        setLoading(false);
        toggleAddBidNote();
      } else {
        props.refStop();
        setLoading(false);
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const [showNewBuyer, setShowNewBuyer] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [response, setResponse] = useState(false);
  const [buyerName, setBuyerName] = useState("");
  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [resData, setResData] = useState([]);
  const [buyer, setBuyer] = useState({});
  const [searchData, setSearchData] = useState({
    auction_id: props.auctionId,
    ext_ref: 0,
    outside_agent: 0,
    inspector: 0,
    recommend_id: 0,
    buyer: 1,

    name: "",
    company_name: "",
    city: "",
    st_des: "",
    zip: "",
    c_des: "",
    phone: "",
    fax: "",
    cell: "",
    email: "",
    setObserver: "",
    removeObserver: "",
  });
  const [showSendMailModal, setShowSendMailModal] = useState(false);
  const [loadingSendMail, setLoadingSendMail] = useState(false);
  const [mailData, setMailData] = useState({
    bid_id: 0,
    managed_by_id: 0,
    new_message:
      "I am working with a buyer account you manage on this listing, and I am requesting your help communicating with them to help move this deal forward.",
    message_prompt: false,
  });
  const [showBidHistory, setShowBidHistory] = useState(false);
  const [historyData, setHistoryData] = useState([]);
  const [showFreq, setShowFreq] = useState(false);
  const [weekFrequency, setWeekFrequency] = useState(false);
  const toggleFreq = (val) => {
    setUserId(val.user_id);
    setWeekFrequency(weekFrequencyOptions.find((s) => s.value === val.buyerReportEmailFrequency));
    setShowFreq(true);
  };
  const handleChange = (e) => {
    props.refStart();
    const payload = {
      buyerReportEmailFrequency: e.value,
    };
    props?.updateBuyerReportEmailFrequency(userId, payload, (res) => {
      if (res.status === 200) {
        props.getBids(props.auctionId, (res) => {
          if (res.status === 200) {
            props.refStop();
            setShowFreq(false);
          } else {
            props.refStop();
            toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
          }
        });
      } else {
        props.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };
  const toggleBidHistory = () => {
    setShowBidHistory(!showBidHistory);
  };

  useEffect(() => {
    if (!showBidHistory) {
      setHistoryData([]);
    }
  }, [showBidHistory]);
  //Toggle showSendMailModal
  const toggleShowSendModal = (data) => {
    setMailData({
      bid_id: data?.b_id,
      managed_by_id: data?.managed_by_id,
      new_message:
        "I am working with a buyer account you manage on this listing, and I am requesting your help communicating with them to help move this deal forward.",
      message_prompt: false,
    });
    setShowSendMailModal(!showSendMailModal);
  };
  //Handle Send Mail To Manager
  const handleSendMail = () => {
    setLoadingSendMail(true);
    props.refStart();
    props.sendMailToManager(props.auctionId, mailData, (res) => {
      if (res.status === 200) {
        props.refStop();
        setLoadingSendMail(false);
        toggleShowSendModal();
      } else {
        props.refStop();
        setLoadingSendMail(false);
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };
  useEffect(() => {
    setBuyerName("");
  }, []);
  useEffect(() => {
    if (showNewBuyer) {
      props.getCountries((res) => {
        if (res.status === 200) {
          setCountry(res.data.data);
        }
      });
      props.getStates((res) => {
        if (res.status === 200) {
          setState(res.data.data);
        }
      });
      setResponse(false);
      setResData([]);
      setBuyerName("");
      setSearchData({
        auction_id: props.auctionId,
        ext_ref: 0,
        outside_agent: 0,
        inspector: 0,
        recommend_id: 0,
        buyer: 1,

        name: "",
        company_name: "",
        city: "",
        st_des: "",
        zip: "",
        c_des: "",
        phone: "",
        fax: "",
        cell: "",
        email: "",
        setObserver: "",
        removeObserver: "",
      });
    }
  }, [showNewBuyer]);

  const toggleNewBuyer = () => {
    setShowAddModal(!showAddModal);
    setShowNewBuyer(!showNewBuyer);
  };

  const handleNewSearch = () => {
    setResponse(false);
    setResData([]);
    setBuyerName("");
    setSearchData({
      auction_id: props.auctionId,
      ext_ref: 0,
      outside_agent: 0,
      inspector: 0,
      recommend_id: 0,
      buyer: 1,

      name: "",
      company_name: "",
      city: "",
      st_des: "",
      zip: "",
      c_des: "",
      phone: "",
      fax: "",
      cell: "",
      email: "",
      setObserver: "",
      removeObserver: "",
    });
  };

  const handleSelectBuyer = () => {
    setBuyerName(buyer.name);
    const data = { ...newBidData };
    data.user_id = buyer.user_id;
    setNewBidData(data);
    toggleNewBuyer();
  };

  const handleSearch = () => {
    setLoadingSearch(true);
    props.refStart();
    props.searchBuyer(props.auctionId, searchData, (res) => {
      if (res.status === 200) {
        setResData(res.data.data);
        props.refStop();
        setLoadingSearch(false);
        setResponse(true);
      } else {
        setLoadingSearch(false);
        props.refStop();
        setResponse(false);
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const toggleShowBidsModal = (bId) => {
    props.refStart();
    props.getBidDetail(props.auctionId, bId, (res) => {
      if (res.status === 200) {
        setBidDetail(res.data.data);
        props.refStop();
        setShowBidsModal(!showBidsModal);
      } else {
        props.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };
  const toggleHideShowBidModal = () => {
    setShowBidsModal(!showBidsModal);
  };
  const toggleAddNewModal = () => {
    setNewBidData({
      b_id: -1,
      user_id: 0,
      unit_bid: "",
      total_bid: "0.00",
      terms: "",
    });
    setBuyerName("");
    setShowAddModal(!showAddModal);
  };
  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };
  const toggleDeclineModal = () => {
    setShowDeclineModal(!showDeclineModal);
  };
  const toggleActivationModal = () => {
    setShowActivationModal(!showActivationModal);
  };
  const toggleMaxBidModal = () => {
    setShowMaxBidModal(!showMaxBidModal);
  };

  //Show Feedback Modal
  const toggleFeedbackModal = (bId) => {
    props.refStart();
    props.getFeedback(props.auctionId, bId, (res) => {
      if (res.status === 200) {
        const data = res.data.data;
        setFeedbackData({
          action: "feedback",
          posted_by: data.posted_by ? data.posted_by : "",
          b_id: data.b_id ? data.b_id : "",
          auc_title: data.auc_title ? data.auc_title : "",
          member_id: data.member_id ? data.member_id : "",
          auction_id: data.auction_id ? data.auction_id : "",
          buyer_id: data.buyer_id ? data.buyer_id : "",
          rating: data.rating ? data.rating : "",
          review: data.review ? data.review : "",
        });
        props.refStop();
        setShowFeedbackModal(!showBidsModal);
      } else {
        props.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };
  //Hide Feedback Modal
  const hideFeedbackModal = () => {
    setShowFeedbackModal(!showFeedbackModal);
  };

  const handleAddNew = () => {
    setLoadingNewBid(true);
    props.refStart();
    props.addNewBid(props.auctionId, newBidData, (res) => {
      if (res.status === 200) {
        props.getBids(props.auctionId, (res) => {});
        setLoadingNewBid(false);
        props.refStop();
        toggleAddNewModal();
        toast(<AlertSuccess message={"Information Saved"} />);
      } else {
        setLoadingNewBid(false);
        props.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  //Delete Bid
  const handleDelete = () => {
    setLoadingDelete(true);
    props.refStart();
    props.deleteBid(props.auctionId, bidId, (res) => {
      if (res.status === 200) {
        props.getBids(props.auctionId, (res) => {});
        setLoadingDelete(false);
        props.refStop();
        toggleDeleteModal();
        toast(<AlertSuccess message={"Information Saved"} />);
      } else {
        setLoadingDelete(false);
        props.refStop();
        toast(<AlertError message={res.data.message ? res.data.massage : "Something Went Wrong"} />);
      }
    });
  };

  //Handle Decline
  const handleDecline = () => {
    setLoadingDecline(true);
    props.refStart();
    props.declineBid(props.auctionId, declineData, (res) => {
      if (res.status === 200) {
        props.getBids(props.auctionId, (res) => {});
        setLoadingDecline(false);
        props.refStop();
        toggleDeclineModal();
        toast(<AlertSuccess message={"Information Saved"} />);
      } else {
        setLoadingDecline(false);
        props.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  //Post Feedback
  const postFeedback = () => {
    setLoadingFeedback(true);
    props.refStart();
    const data = {};
    data.action = feedbackData.action ? feedbackData.action : "feedback";
    data.b_id = feedbackData.b_id ? feedbackData.b_id : "";
    data.rating = feedbackData.rating ? feedbackData.rating : 0;
    data.review = feedbackData.review ? feedbackData.review : "";
    data.member_id = feedbackData.member_id ? feedbackData.member_id : "";
    data.posted_by = feedbackData.posted_by ? feedbackData.posted_by : "";
    props.updateStatus(props.auctionId, data, (res) => {
      if (res.status === 200) {
        setLoadingFeedback(false);
        props.refStop();
        hideFeedbackModal();
        toast(<AlertSuccess message={"Information Saved"} />);
      } else {
        setLoadingFeedback(false);
        props.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  //Handle Activation
  const handleActivation = () => {
    setLoadingActivation(true);
    props.refStart();
    const data = {};
    if (activation) {
      data.action = "reactive";
      data.b_id = bidId;
    } else {
      data.action = "deactive";
      data.b_id = bidId;
    }
    props.updateStatus(props.auctionId, data, (res) => {
      if (res.status === 200) {
        setLoadingActivation(false);
        props.refStop();
        toggleActivationModal();
        props.getBids(props.auctionId, (res) => {});
        // toast(<AlertSuccess message={activation ? "Bid Reactivated" : "Bid Deactivated"} />);
        toast(<AlertSuccess message={"Information Saved"} />);
      } else {
        setLoadingActivation(false);
        props.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  //Handle MaxBid
  const handleMaxBid = () => {
    setLoadingMaxBid(true);
    props.refStart();
    const data = {};
    data.action = "max_bid_achieved";
    data.b_id = bidId;
    props.updateStatus(props.auctionId, data, (res) => {
      if (res.status === 200) {
        props.getBids(props.auctionId, (res) => {});
        setLoadingMaxBid(false);
        props.refStop();
        toggleMaxBidModal();
        toast(<AlertSuccess message="Information Saved" />);
      } else {
        setLoadingMaxBid(false);
        props.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const categoryId = props?.getListingProductDetail?.detail?.detail?.category_id_for_entity;
  const filterEntityByCategory = (arr, catId, entityMap) => {
    return arr?.filter((item) => {
      const entityName = entityMap[catId] || "Salvex Commodities, Inc.";
      return entityName && item.salvex_company.includes(entityName);
    });
  };
  const entityMap = {
    1008: "Salvex Metals, Inc.",
    1176: "Salvex Aviation",
    1395: "Salvex Solar, Inc.",
    1294: "Salvex Energy, Inc.",
    1384: "Salvex Energy, Inc.",
    1002: "Salvex Food, Inc.",
  };
  return (
    <div class="details-page-widget-block" id={props.id}>
      <div class="page-widget-head d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <div class="d-flex align-items-center">
            <div
              class="page-widget-label text-capitalize cursor-pointer"
              onClick={() => props?.scrollToView("bidsRef")}
            >
              High Bids {highest5 && highest5?.length > 0 ? "" : !props.loadingMain && "(0)"}
            </div>
          </div>
          <li
            className="badge-item cursor-pointer"
            onClick={() => {
              props?.setSection("bidsRef");
              props?.scrollToView("bidsRef");
            }}
            style={{ marginLeft: "10px" }}
          >
            <div className="badge-box" style={{ background: "#DADEE0" }}>
              Click here to view all
            </div>
          </li>
        </div>
        {/* <div class="btn-block">
          {props.loadingMain ? (
            <Skeleton />
          ) : (
            <a href="javascript:void(0)" class="btn btn-primary btn-sm" onClick={() => props?.scrollToView("bidsRef")}>
              View All
            </a>
          )}
        </div> */}
      </div>
      <div class={highest5 && highest5?.length > 0 && "page-widget-body mt-3"}>
        {props.loadingMain ? (
          <div class="table-responsive">
            <table class="large-table">
              <colgroup>
                <col style={{ minWidth: "28px" }} />
                <col style={{ minWidth: "86px" }} />
                <col />
                <col />
                <col />
                <col />

                <col style={{ minWidth: "44px" }} />

                <col style={{ minWidth: "44px" }} />
                <col style={{ minWidth: "44px" }} />
                <col style={{ minWidth: "44px" }} />
                <col style={{ minWidth: "44px" }} />

                <col />
                <col />
                <col />
                <col />
                <col />
                <col />
                <col />
                <col />

                <col style={{ minWidth: "24px" }} />
                <col style={{ minWidth: "24px" }} />
                <col style={{ minWidth: "24px" }} />
                <col style={{ minWidth: "24px" }} />
                <col style={{ minWidth: "24px" }} />
                <col style={{ minWidth: "24px" }} />
                <col style={{ minWidth: "24px" }} />
                <col style={{ minWidth: "24px" }} />
                <col style={{ minWidth: "24px" }} />
                {superAdmin && accessLevels && <col style={{ minWidth: "24px" }} />}
              </colgroup>
              <thead>
                <tr>
                  <th>
                    <Skeleton />
                  </th>
                  <th>
                    <Skeleton />
                  </th>
                  <th>
                    <Skeleton />
                  </th>
                  <th>
                    <Skeleton />
                  </th>
                  <th>
                    <Skeleton />
                  </th>
                  <th>
                    <Skeleton />
                  </th>
                  <th>
                    <Skeleton />
                  </th>
                  <th>
                    <Skeleton />
                  </th>
                  <th>
                    <Skeleton />
                  </th>
                  <th>
                    <Skeleton />
                  </th>
                  <th>
                    <Skeleton />
                  </th>
                  <th>
                    <Skeleton />
                  </th>
                  <th>
                    <Skeleton />
                  </th>
                  <th>
                    <Skeleton />
                  </th>
                  <th>
                    <Skeleton />
                  </th>
                  <th>
                    <Skeleton />
                  </th>
                  <th>
                    <Skeleton />
                  </th>
                  <th>
                    <Skeleton />
                  </th>
                  <th>
                    <Skeleton />
                  </th>
                  <th>
                    <Skeleton />
                  </th>
                  <th>
                    <Skeleton />
                  </th>
                  <th>
                    <Skeleton />
                  </th>
                  <th>
                    <Skeleton />
                  </th>
                  <th>
                    <Skeleton />
                  </th>
                  <th>
                    <Skeleton />
                  </th>
                  <th>
                    <Skeleton />
                  </th>
                  <th>
                    <Skeleton />
                  </th>
                  <th>
                    <Skeleton />
                  </th>
                </tr>
              </thead>
              <tbody>
                {[1, 2, 3].map(() => (
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          highest5?.length > 0 && (
            <div class="table-responsive table-custom">
              <table class="large-table large-table1">
                <colgroup>
                  <col style={{ width: "16%" }} />
                  <col style={{ width: "16%" }} />
                  <col style={{ width: "16%" }} />

                  <col style={{ width: "8%" }} />

                  <col style={{ width: "2.2%" }} />
                  <col style={{ width: "2.2%" }} />
                  <col style={{ width: "2.2%" }} />

                  <col style={{ width: "2.2%" }} />
                  <col style={{ width: "2.2%" }} />
                  <col style={{ width: "2.2%" }} />
                  <col style={{ width: "2.2%" }} />

                  {superAdmin && accessLevels && <col style={{ width: "2.2%" }} />}
                </colgroup>
                <thead>
                  <tr>
                    <th style={{ minWidth: "340px", maxWidth: "350px" }}>Buyer</th>
                    <th>Company</th>
                    <th>Bid Date</th>
                    <th style={{ minWidth: "220px", maxWidth: "220px" }}>Original Bid</th>
                    <th style={{ minWidth: "24px", maxWidth: "24px", width: "24px" }}></th>
                    <th style={{ minWidth: "24px", maxWidth: "24px", width: "24px" }}></th>
                    <th style={{ minWidth: "24px", maxWidth: "24px", width: "24px" }}></th>
                    {/* <th></th> */}
                    <th style={{ minWidth: "24px", maxWidth: "24px", width: "24px" }}></th>
                    <th style={{ minWidth: "24px", maxWidth: "24px", width: "24px" }}></th>
                    <th style={{ minWidth: "24px", maxWidth: "24px", width: "24px" }}></th>
                    {superAdmin && accessLevels && <th></th>}
                  </tr>
                </thead>
                <tbody>
                  {highest5.map((val, i) => {
                    return (
                      <tr style={{ cursor: "pointer" }}>
                        <td>
                          <span class="fw-medium d-block">
                            <span
                              className="green-btn-text"
                              onClick={() => {
                                if (process.env.REACT_APP_MODE == "development") {
                                  window.open(
                                    `https://admin-dev.salvex.com/admin/_user/_edit.cfm?user_id=${val.user_id}`,
                                  );
                                } else {
                                  window.open(`https://admin.salvex.com/admin/_user/_edit.cfm?user_id=${val.user_id}`);
                                }
                              }}
                            >
                              {val?.name ? val?.name : "N/A"}{" "}
                            </span>
                            {val?.buyer_type ? ` - ${val?.buyer_type}` : ""}
                            <a
                              className="ms-1"
                              href={
                                filterEntityByCategory(val?.ncnda_agreements, categoryId, entityMap)?.[0]
                                  ?.nda_signed_date
                                  ? moment
                                      .tz("America/Chicago")
                                      .isAfter(
                                        moment.tz(
                                          filterEntityByCategory(val?.ncnda_agreements, categoryId, entityMap)?.[0]
                                            ?.nda_expiry_date,
                                          "YYYY-MM-DDTHH:mm:ss.SSS",
                                          "America/Chicago",
                                        ),
                                      )
                                    ? `/ncnd-agreement/view?user_id=${val?.user_id}&cat_id=${categoryId}&expired=true`
                                    : `${process.env.REACT_APP_S3_PRIVATE_DOC_BUCKET}/${
                                        filterEntityByCategory(val?.ncnda_agreements, categoryId, entityMap)?.[0]
                                          ?.file_name
                                      }`
                                  : `/ncnd-agreement/view?user_id=${val?.user_id}&cat_id=${categoryId}`
                              }
                              target="_blank"
                            >
                              {filterEntityByCategory(val?.ncnda_agreements, categoryId, entityMap)?.[0]
                                ?.nda_sent_date &&
                              filterEntityByCategory(val?.ncnda_agreements, categoryId, entityMap)?.[0]
                                ?.nda_signed_date ? (
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={
                                    <Tooltip style={{ position: "fixed" }}>
                                      <span>
                                        {filterEntityByCategory(val?.ncnda_agreements, categoryId, entityMap)?.[0]?.name
                                          ? "Signed"
                                          : "Uploaded"}{" "}
                                        on{" "}
                                        {moment(
                                          filterEntityByCategory(val?.ncnda_agreements, categoryId, entityMap)?.[0]
                                            ?.nda_signed_date,
                                        ).format("MM/DD/YYYY")}
                                      </span>
                                    </Tooltip>
                                  }
                                >
                                  <img src={ndaGreen} alt="" />
                                </OverlayTrigger>
                              ) : (
                                <img
                                  src={
                                    filterEntityByCategory(val?.ncnda_agreements, categoryId, entityMap)?.[0]
                                      ?.nda_sent_date
                                      ? ndaYellow
                                      : ndaRed
                                  }
                                  alt=""
                                />
                              )}
                            </a>
                            <span className="d-block">
                              {val?.customer_ranking ? `Customer Ranking : ${val?.customer_ranking}` : ""}
                            </span>
                            {val?.c_abb && availableFlags(`${val?.c_abb}.gif`) ? (
                              <img
                                class="td-flag-0"
                                style={{ objectFit: "cover" }}
                                src={require(`../../../include/images/flags/${val?.c_abb}.gif`)}
                                alt=""
                              />
                            ) : (
                              ""
                            )}{" "}
                          </span>
                          <span className="d-block">Managed By : {val?.managed_by ? val?.managed_by : "N/A"}</span>
                          {val?.phone ? <span className=" d-block">{val?.phone}</span> : ""}
                          {val?.cell ? <span className=" d-block">{val?.cell}</span> : ""}
                          <span className=" d-block">
                            {val?.email ? (
                              <a href={`mailto:${val?.email}`} className="green-btn-text cursor-pointer">
                                {val?.email}
                              </a>
                            ) : (
                              ""
                            )}
                          </span>
                          <span className="d-block ">{val.location ? val.location : ""}</span>
                          <span className="d-block">
                            Credit :{" "}
                            <span className="fw-medium">
                              {val?.user_credit_amount ? "$" + twoDecimals(val?.user_credit_amount) : "$0.00"}
                            </span>
                          </span>
                          <a
                            class={`btn btn-primary btn-xs-size btn-auto`}
                            // style={{ marginTop: "10px" }}
                            href={
                              mode === "development"
                                ? `https://admin-dev.salvex.com/admin/_sellers/_mrk_doc_auc/_create.cfm?aucID=${val.auction_id}&s_user_id=${val.user_id}`
                                : `https://admin.salvex.com/admin/_sellers/_mrk_doc_auc/_create.cfm?aucID=${val.auction_id}&s_user_id=${val.user_id}`
                            }
                            target="_blank"
                          >
                            Email
                          </a>
                        </td>
                        <td>
                          {val?.company_name ? (
                            <span
                              onClick={() => {
                                if (process.env.REACT_APP_MODE == "development") {
                                  window.open(
                                    `https://admin-dev.salvex.com/admin/_user/_edit.cfm?user_id=${val.user_id}`,
                                  );
                                } else {
                                  window.open(`https://admin.salvex.com/admin/_user/_edit.cfm?user_id=${val.user_id}`);
                                }
                              }}
                            >
                              {val?.company_name}
                            </span>
                          ) : (
                            "N/A"
                          )}

                          <div className="d-block">
                            <span class="green-btn-text text-decoration-none fw-medium" style={{ marginRight: "4px" }}>
                              {val?.CT3 > 0 ? <span>({val?.CT2})</span> : "(0)"}
                            </span>
                            <span class="grey-color fw-medium" style={{ marginRight: "4px" }}>
                              {val?.CT1 > 0 ? <span>({val?.CT1})</span> : "(0)"}
                            </span>
                            <span class="red-text-label fw-medium">
                              {val?.CT0 > 0 ? <span>({val?.CT0})</span> : "(0)"}
                            </span>
                            <span
                              className=" ms-2 fw-medium"
                              style={{ minWidth: "24px" }}
                              onClick={(e) => {
                                e.stopPropagation();
                                toggleFeedbackModal(val?.b_id);
                              }}
                            >
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip style={{ position: "fixed" }}>
                                    <span>Feedback</span>
                                  </Tooltip>
                                }
                              >
                                <img class="td-icon--0" alt="Feedback" src={tdIcon1} />
                              </OverlayTrigger>
                            </span>

                            <span className="d-block ">
                              Bid Success :{" "}
                              {val?.all_bids > 0
                                ? ` ${parseFloat(100 * (val?.success_bids / val?.all_bids)).toFixed(2)}%`
                                : "0.00%"}
                            </span>
                            <span className="d-block ">Total Sale : {val?.success_bids ? val?.success_bids : "0"}</span>
                            <span className="d-block ">
                              Total Profit :{" "}
                              {val.total_profit
                                ? "$" +
                                  new Intl.NumberFormat("en-US", {
                                    style: "decimal",
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }).format(val?.total_profit)
                                : "$0"}
                            </span>
                            <div className="td-icon-item">
                              {val?.dhs_approved && <img src={icon1} alt="" />}

                              {val?.okay && <img src={icon2} alt="" />}

                              {val?.stop_working && <img src={icon3} alt="" width="30px" height="30px" />}
                            </div>
                          </div>
                          {questionLists?.filter((item) => item.email === val?.email)?.length > 0 && (
                            <div>
                              <span className="fw-medium text-underline">Questions:</span>
                              <ol className="text-wrap" style={{ paddingLeft: "1rem" }}>
                                {questionLists
                                  ?.filter((item) => item.email === val?.email)
                                  ?.map((data) => (
                                    <li
                                      className="mt-2"
                                      style={{ maxWidth: "250px", width: "100%" }}
                                      data-tip={`
                                  <div>
                                    <span>Posted on: ${
                                      moment(data.date_created).add({ minute: diff }).format("MMM-D-YYYY, h:mm:ss A") +
                                      " CST"
                                    }</span>
                                  </div>
                                  <div>
                                    <span>Posted by: ${data.name + "-" + data.company}</span>
                                  </div>
                                  <div>
                                    <span>Email: ${data.email}</span>
                                  </div>
                                  <div>
                                    <span>Phone: ${data.phone}</span>
                                  </div>
                                  <div>
                                    <span>Status: ${
                                      data.qs_id === 1
                                        ? "Needs Response"
                                        : data.qs_id === 2
                                        ? "Answered"
                                        : data.qs_id === 3
                                        ? "To Seller"
                                        : data.qs_id === 4
                                        ? "Seller Responded"
                                        : "N/A"
                                    }</span>
                                  </div>
                                `}
                                      data-html={true}
                                    >
                                      {removeTags(data.message)}
                                      <ReactTooltip
                                        effect="solid"
                                        place="bottom"
                                        type="dark"
                                        // offset={{ top: 10 }}
                                        border={true}
                                      />
                                    </li>
                                  ))}
                              </ol>
                            </div>
                          )}
                        </td>

                        <td style={{ whiteSpace: "normal" }}>
                          {val?.bid_time ? <DateFormat date={val?.bid_time} /> : "N/A"}
                          {val?.otherBids?.length > 0 ? (
                            <span
                              className="d-block link-btn view-history"
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                setHistoryData(val);
                                toggleBidHistory();
                              }}
                            >
                              {val?.otherBids?.length + 1} Bids
                            </span>
                          ) : (
                            ""
                          )}
                          <span className="d-block">
                            {val?.bs_id === 1
                              ? "Pending"
                              : val?.bs_id === 2
                              ? "Submitted"
                              : val?.bs_id === 3
                              ? "Accepted"
                              : val?.bs_id === 4
                              ? "Declined"
                              : val?.bs_id === 5
                              ? "Invoiced"
                              : "N/A"}
                          </span>
                          <span className="d-block" style={{ color: "red" }}>
                            {val?.date_declined && val?.bs_id === 4 ? (
                              <span>
                                Declined on <DateFormat date={val?.date_declined} />
                              </span>
                            ) : (
                              ""
                            )}
                          </span>
                          {val?.max_bid_status === "maxBid" ? (
                            <button
                              type="button"
                              class="btn btn-primary btn-auto btn-xs-size"
                              onClick={() => {
                                setBidId(val?.b_id);
                                toggleMaxBidModal();
                              }}
                            >
                              Max Bid?
                            </button>
                          ) : val?.max_bid_status === "MAX BID ACHIEVED" ? (
                            <b>MAX BID ACHIEVED</b>
                          ) : (
                            ""
                          )}
                        </td>

                        <td>
                          <span class="number-bold">
                            {val?.total_bid
                              ? "$" +
                                new Intl.NumberFormat("en-US", {
                                  style: "decimal",
                                  minimumFractionDigits: 2,
                                }).format(val?.total_bid)
                              : "$0"}
                          </span>
                          {val?.invoice_paid == 1 ? (
                            <>
                              <span className="d-block inv-txt">
                                Invoiced : <span class="green-text">Paid</span>
                              </span>
                              <span className="d-block" style={{ maxWidth: "120px" }}>
                                Created on{" "}
                                <DateFormat date={val?.invoice?.length > 0 && val?.invoice[0]?.date_created} />
                              </span>
                            </>
                          ) : val?.invoice_id && !val?.invoice_paid ? (
                            <>
                              <span className="d-block inv-txt">
                                Invoiced : <span class="green-text">Unpaid</span>
                              </span>
                              <span className="d-block" style={{ maxWidth: "220px" }}>
                                Created on{" "}
                                <DateFormat date={val?.invoice?.length > 0 && val?.invoice[0]?.date_created} />
                              </span>
                            </>
                          ) : (
                            //Removed invoice button
                            // <div style={{ width: "fit-content" }}>
                            //   <a
                            //     className="btn btn-primary btn-xs-size btn-auto"
                            //     href={`/invoice/create-invoice?id=${props.auctionId}&b_id=${val.b_id}`}
                            //     onClick={(e) => e.stopPropagation()}
                            //     target="_blank"
                            //   >
                            //     Invoice
                            //   </a>
                            // </div>
                            ""
                          )}
                          <div>
                            <button
                              className="green-btn-text"
                              onClick={(e) => {
                                e.stopPropagation();
                                const data = { ...bidNote };
                                data.bid_id = val.b_id;
                                data.bidNotePrompt = true;
                                data.bidNote = "";
                                setBidNote(data);
                              }}
                            >
                              Bid Notes
                            </button>
                            {val?.bid_notes ? (
                              <div className="note-width">
                                <p>
                                  {val?.bid_notes?.split("")?.length > 130
                                    ? val?.bid_notes?.substring(0, 130) + "..."
                                    : val?.bid_notes}
                                </p>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          {val?.invoice_id > 0 && (
                            <>
                              <button
                                className="green-btn-text"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  const data = { ...invoiceNote };
                                  data.invoice_id = val.invoice_id;
                                  data.invoiceNotePrompt = true;
                                  data.invoiceNote = "";
                                  setInvoiceNote(data);
                                }}
                              >
                                Invoice Notes
                              </button>
                              {val?.invoice?.length > 0 ? (
                                <div className="note-width">
                                  <p>
                                    {val?.invoice[0]?.invoice_notes?.split("")?.length > 130
                                      ? val?.invoice[0]?.invoice_notes?.substring(0, 130) + "..."
                                      : val?.invoice[0]?.invoice_notes}
                                  </p>
                                </div>
                              ) : (
                                ""
                              )}
                            </>
                          )}
                          <div style={{ width: "fit-content" }}>
                            <a
                              target="_blank"
                              href={`/report/buyer-asset-recovery-report?id=${val?.user_id}`}
                              class={`btn btn-primary btn-primary-group btn-xs-size btn-auto `}
                              style={{ marginTop: "10px", padding: "7px 5px" }}
                            >
                              Buyer ARR
                            </a>
                            <a
                              href="javascript:void(0)"
                              style={{ marginTop: "10px", padding: "7px 5px" }}
                              class={`btn btn-other btn-xs-size btn-auto `}
                              onClick={() => getBuyerARRLogs(val?.email)}
                            >
                              BARR Stats
                            </a>
                          </div>

                          {showFreq && val.user_id === userId ? (
                            <div style={{ width: "fit-content" }} className="d-flex align-items-center  mt-3 flex-wrap">
                              <div>
                                <label for="weeklyReportInput">Buyer ARR Frequency : </label>
                              </div>
                              <div style={{ flexGrow: "2", maxWidth: "220px" }}>
                                <Select
                                  menuPosition="fixed"
                                  menuShouldScrollIntoView
                                  styles={customStyles}
                                  placeholder="Select Week"
                                  value={weekFrequency}
                                  options={weekFrequencyOptions}
                                  getOptionLabel={(option) => option.name}
                                  getOptionValue={(option) => option.value}
                                  onChange={(e) => {
                                    e.value != -1 && handleChange(e);
                                    setWeekFrequency(e);
                                  }}
                                />
                              </div>
                              <button className="ms-2" onClick={() => setShowFreq(false)}>
                                <img src={close} alt="close" />
                              </button>
                            </div>
                          ) : (
                            <div className="mt-2" style={{ width: "fit-content" }}>
                              <span className="cursor-pointer" onDoubleClick={() => toggleFreq(val)}>
                                Buyer ARR Frequency :{" "}
                                {val?.buyerReportEmailFrequency >= 0
                                  ? val?.buyerReportEmailFrequency > 1
                                    ? val?.buyerReportEmailFrequency + " Weeks"
                                    : val?.buyerReportEmailFrequency + " Week"
                                  : "N/A"}
                              </span>
                            </div>
                          )}
                          {val?.buyerFrequencyUpdatedBy === "Buyer" && (
                            <div className="d-block" style={{ width: "fit-content" }}>
                              {val?.buyerReportEmailFrequency == 0 ? (
                                <span className="mb-10 mt-10 d-block " style={{ whiteSpace: "pre-line" }}>
                                  <span style={{ color: "red" }}> Buyer has decided not to receive these emails. </span>{" "}
                                </span>
                              ) : (
                                ""
                              )}
                              {val?.buyerReportEmailFrequency === 1 ? (
                                <span className="mb-10 mt-10 d-block " style={{ whiteSpace: "pre-line" }}>
                                  <span style={{ color: "red" }}>
                                    {" "}
                                    Buyer has decided to receive this email once in a week.
                                  </span>{" "}
                                </span>
                              ) : val?.buyerReportEmailFrequency === 2 ? (
                                <span className="mb-10 mt-10 d-block " style={{ whiteSpace: "pre-line" }}>
                                  <span style={{ color: "red" }}>
                                    {" "}
                                    Buyer has decided to receive this email once in two weeks.
                                  </span>{" "}
                                </span>
                              ) : val?.buyerReportEmailFrequency === 3 ? (
                                <span className="mb-10 mt-10 d-block " style={{ whiteSpace: "pre-line" }}>
                                  <span style={{ color: "red" }}>
                                    {" "}
                                    Buyer has decided to receive this email once in three weeks.
                                  </span>{" "}
                                </span>
                              ) : val?.buyerReportEmailFrequency === 4 ? (
                                <span className="mb-10 mt-10 d-block " style={{ whiteSpace: "pre-line" }}>
                                  <span style={{ color: "red" }}>
                                    {" "}
                                    Buyer has decided to receive this email once in four weeks.
                                  </span>{" "}
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                          )}
                        </td>
                        <td
                          onClick={() => {
                            setDeclineData({
                              decline: val?.b_id,
                              new_message: `The offer you have submitted of ${
                                val?.total_bid > 0
                                  ? "$" +
                                    new Intl.NumberFormat("en-US", {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                    }).format(val?.total_bid)
                                  : "$0.00"
                              } has been declined.`,
                            });
                            toggleDeclineModal();
                          }}
                        >
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip style={{ position: "fixed" }}>
                                <span>
                                  {val?.date_declined
                                    ? `Bid Declined ${moment(val?.date_declined)?.format("MM/DD/YY")} ${val?.name01}`
                                    : "Decline"}
                                </span>
                              </Tooltip>
                            }
                          >
                            <img class="td-icon--0" src={tdIcon7} alt="" />
                          </OverlayTrigger>
                        </td>
                        <td>
                          <a
                            href={
                              mode === "development"
                                ? `https://admin-dev.salvex.com/listings/reportsAR/index.cfm?aucID=${val.auction_id}&b_id=${val.b_id}`
                                : `https://admin.salvex.com/listings/reportsAR/index.cfm?aucID=${val.auction_id}&b_id=${val.b_id}`
                            }
                            target="_blank"
                          >
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip style={{ position: "fixed" }}>
                                  <span>Bid Report</span>
                                </Tooltip>
                              }
                            >
                              <img class="td-icon--0" src={tdIcon4} alt="Bid Report" />
                            </OverlayTrigger>
                          </a>
                        </td>
                        {/* <td>
                          <a
                            href={
                              mode === "development"
                                ? `https://admin-dev.salvex.com/listings/reportsAR/index.cfm?sid=${val.sid}`
                                : `https://admin.salvex.com/listings/reportsAR/index.cfm?sid=${val.sid}`
                            }
                            target="_blank"
                          >
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip style={{ position: "fixed" }}>
                                  <span>Weekly Bid Report</span>
                                </Tooltip>
                              }
                            >
                              <img class="td-icon--0" src={tdIcon3} alt="Weekly Bid Report" />
                            </OverlayTrigger>
                          </a>
                        </td> */}
                        <td>
                          <a href={`/invoice/create-invoice?id=${props.auctionId}&b_id=${val.b_id}`} target="_blank">
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip style={{ position: "fixed" }}>
                                  <span>Invoice Bid</span>
                                </Tooltip>
                              }
                            >
                              <img class="td-icon--0" src={tdIcon5} alt="Invoice Bid" />
                            </OverlayTrigger>
                          </a>
                        </td>
                        <td>
                          <a
                            href={
                              mode === "development"
                                ? `https://admin-dev.salvex.com/admin/_listings/views/edit_invoices.cfm?b_id=${val.b_id}&deposit=1`
                                : `https://admin.salvex.com/admin/_listings/views/edit_invoices.cfm?b_id=${val.b_id}&deposit=1`
                            }
                            target="_blank"
                          >
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip style={{ position: "fixed" }}>
                                  <span>Deposit Agreement</span>
                                </Tooltip>
                              }
                            >
                              <img class="td-icon--0" src={tdIcon6} alt="Deposit Agreement" />
                            </OverlayTrigger>
                          </a>
                        </td>
                        <td>
                          <a href={`/report/buyer-asset-recovery-report?id=${val?.user_id}`} target="_blank">
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip style={{ position: "fixed" }}>
                                  <span>Buyer Asset Recovery Report</span>
                                </Tooltip>
                              }
                            >
                              <img class="td-icon--0" src={barrIcon} alt="Buyer Asset Recovery Report" />
                            </OverlayTrigger>
                          </a>
                        </td>

                        <td
                          onClick={() => {
                            setBidId(val?.b_id);
                            val?.deactive ? setActivation(true) : setActivation(false);
                            toggleActivationModal();
                          }}
                        >
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip style={{ position: "fixed" }}>
                                <span>{val?.deactive ? "Reactivate" : "Deactivate"}</span>
                              </Tooltip>
                            }
                          >
                            <img
                              class="td-icon--0"
                              src={val?.deactive ? reactivate : tdIcon8}
                              alt={val?.deactive ? "Reactivate" : "Deactivate"}
                            />
                          </OverlayTrigger>
                        </td>
                        {superAdmin && accessLevels && (
                          <td
                            onClick={() => {
                              setBidId(val?.b_id);
                              toggleDeleteModal();
                            }}
                          >
                            <a href="javascript:void(0)">
                              <img class="td-icon--0" src={trash} alt="" />
                            </a>
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )
        )}
      </div>

      <AddNotesModal
        show={showAddNote}
        onHide={toggleAddNote}
        addInvoice={addInvoice}
        loading={loading}
        setInvoiceNote={setInvoiceNote}
        invoiceNote={invoiceNote}
        handleSubmit={handleAddNote}
      />
      <AddBidNotesModal
        show={showBidNote}
        onHide={toggleAddBidNote}
        addBid={addBid}
        loading={loading}
        setBidNote={setBidNote}
        bidNote={bidNote}
        handleSubmit={handleAddBidNote}
      />
      <ShowBidsModal
        show={showBidsModal}
        onHide={toggleHideShowBidModal}
        bidDetail={bidDetail}
        setBidDetail={setBidDetail}
      />

      <FindUser
        title={"Buyer"}
        show={showNewBuyer}
        onHide={toggleNewBuyer}
        searchData={searchData}
        setSearchData={setSearchData}
        handleSubmit={handleSearch}
        loading={loadingSearch}
        response={response}
        resData={resData}
        country={country}
        state={state}
        handleNewSearch={handleNewSearch}
        setUser={setBuyer}
        handleSelectUser={handleSelectBuyer}
        buyer={true}
      />

      <NewBidModal
        show={showAddModal}
        auctionId={props.auctionId}
        refStart={props.refStart}
        refStop={props.refStop}
        onHide={toggleAddNewModal}
        newBidData={newBidData}
        setNewBidData={setNewBidData}
        handleAddNew={handleAddNew}
        loading={loadingNewBid}
        searchBuyer={props.searchBuyer}
        getCountries={props.getCountries}
        getStates={props.getStates}
        toggleNewBuyer={toggleNewBuyer}
        buyerName={buyerName}
      />
      <DeleteModal
        show={showDeleteModal}
        onHide={toggleDeleteModal}
        loadingDelete={loadingDelete}
        deleteHandler={handleDelete}
      />
      <DeclineModal
        show={showDeclineModal}
        onHide={toggleDeclineModal}
        declineData={declineData}
        setDeclineData={setDeclineData}
        submitHandler={handleDecline}
        loading={loadingDecline}
      />
      <FeedbackModal
        show={showFeedbackModal}
        onHide={hideFeedbackModal}
        feedbackData={feedbackData}
        setFeedbackData={setFeedbackData}
        loading={loadingFeedback}
        postFeedback={postFeedback}
      />
      <ActivationModal
        show={showActivationModal}
        onHide={toggleActivationModal}
        activation={activation}
        submitHandler={handleActivation}
        loading={loadingActivation}
      />
      <MaxBidModal
        show={showMaxBidModal}
        onHide={toggleMaxBidModal}
        submitHandler={handleMaxBid}
        loading={loadingMaxBid}
      />
      <SendMail
        show={showSendMailModal}
        onHide={toggleShowSendModal}
        mailData={mailData}
        setMailData={setMailData}
        loading={loadingSendMail}
        handleSubmit={handleSendMail}
      />
      <BidsHistory show={showBidHistory} onHide={toggleBidHistory} data={historyData} />
      {showBuyerARRHistory && (
        <BuyerARRHistory
          show={showBuyerARRHistory}
          onHide={toggleBuyerARRHistory}
          data={buyerARRLogData}
          type={"Buyer Report"}
          setData={setBuyerARRLogData}
          setFilter={setBARRFilters}
          filter={BARRfilters}
          email={bARREmail}
          fetchEmailContent={fetchEmailContent}
        />
      )}
      {showSentEmail && <ViewBARREmail show={showSentEmail} onHide={toggleSentEmail} data={sentEmailContent} />}
      <NcndaModal
        show={showSendNDA}
        onHide={toggleSendNDA}
        data={ndaData}
        setData={setNdaData}
        loading={loading}
        errors={errors}
        validateNda={validateNda}
        handleSubmit={handleSendNDA}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getBids: (params, callback) => dispatch(getBids(params, callback)),
  getBidDetail: (params, bId, callback) => dispatch(getBidDetail(params, bId, callback)),
  addNewBid: (params, data, callback) => dispatch(addNewBid(params, data, callback)),
  deleteBid: (params, bidId, callback) => dispatch(deleteBid(params, bidId, callback)),
  declineBid: (params, data, callback) => dispatch(declineBid(params, data, callback)),
  getFeedback: (params, bidId, callback) => dispatch(getFeedback(params, bidId, callback)),
  updateStatus: (params, data, callback) => dispatch(updateStatus(params, data, callback)),
  searchBuyer: (params, data, callback) => dispatch(searchBuyer(params, data, callback)),
  invoiceAddNote: (params, data, callback) => dispatch(invoiceAddNote(params, data, callback)),
  bidAddNote: (params, data, callback) => dispatch(bidAddNote(params, data, callback)),
  updateBuyerReportEmailFrequency: (userId, data, callback) =>
    dispatch(updateBuyerReportEmailFrequency(userId, data, callback)),
  getCountries: (callback) => dispatch(getCountries(callback)),
  getStates: (callback) => dispatch(getStates(callback)),
  sendNDA: (id, data, callback) => dispatch(sendNDA(id, data, callback)),

  sendMailToManager: (params, data, callback) => dispatch(sendMailToManager(params, data, callback)),
});

const mapStateToProps = (state) => ({
  bidsData: bidsData(state),
  getLatestActivity: getLatestActivity(state),
  invoices: getInvoice(state),
  questionDetails: questionDetails(state),
  getListingProductDetail: getListingProductDetail(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Bids));
