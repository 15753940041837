import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import payables, {
  addNewPayable,
  deletePayable,
  getDeliveryInformation,
  getDeliveryMethod,
  getDeliveryService,
  getExpenseType,
  getPayable,
  getPayableLists,
  getPaymentMethod,
  getPaymentTerm,
  getRequestor,
  getStates,
  getStatus,
  payableDetails,
} from "../../../store/payables";
import DateFormat from "../../../common/DateFormat/dateFormat";
import edit from "../../../include/images/edit-18x18.svg";
import deletebtn from "../../../include/images/trash-18x18.svg";
import { getCountries } from "../../../store/productLocation";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import AlertError from "../../../common/alerts/alertError";
import moment from "moment";
import { toast } from "react-toastify";
import DeleteModal from "../../../layouts/modals/deleteModal";
import Skeleton from "react-loading-skeleton";
import AddPayablesModal from "../../../layouts/modals/payablesModal/payablesModal";
import helpCircle from "../../../include/images/help-circle.svg";
import { isNumber } from "lodash";
import More from "../../../include/images/more-icon.svg";
import { getModals, setShowAddPayableModal } from "../../../store/modal";
import { getPaymentIfo, getUser } from "../../../store/users";
import { bidsData, searchBuyer } from "../../../store/bids";
import { getProfile } from "../../../utils.js/localStorageServices";
import { getProductCount } from "../../../store/listingProduct";

const NewPayables = (props) => {
  const buyersList = props?.bidsData?.buyers?.data || [];
  const completed = props?.getUser?.paymentInfo?.detail?.isComplete;
  const [countries, setCountries] = useState([]);
  const data = props && props.payableDetails && props.payableDetails.payables && props.payableDetails.payables.data;
  const count = props?.count?.payable;
  const fieldData = props && props.payableDetails && props.payableDetails.payable && props.payableDetails.payable.data;
  const showAddPayableModal = props?.payablesModal;
  const [showEditPayableModal, setShowEditPayableModal] = useState(false);
  const [payableId, setPayableId] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [clearDropdown, setClearDropdown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [type, setType] = useState("");
  const [buyers, setBuyers] = useState([]);
  const [keyword, setKeyword] = useState("");

  const [values, setValues] = useState({
    pid: 0,

    title: "New Payables Request",
    created: moment(Date.now()).format("MM/DD/YYYY"),
    req_id: 0,
    status_id: 0,
    expense_type: 0,
    inventory: false,

    entity: "",
    notes: "",
    payment_method: 0,
    req_amount: "0.00",
    terms_id: 0,
    pay_to: "",
    name: "",
    address1: "",
    address2: "",
    city: "",
    st: "",
    zip: "",
    p_country_id: 0,
    c_name: "",
    c_phone: "",
    c_email: "",
    delivery_service: 0,
    tracking_num: "",

    bank_name: "",
    bank_address1: "",
    bank_address2: "",
    bank_city: "",
    bank_st: "0",
    bank_zip: "",
    bank_account: "",
    country_id: 0,
    swift_code: "",
    aba_number: "",

    delivery_method: 0,
    delivery_info: 0,

    purchase_purpose: "",
    instructions: "",

    due_date: moment(Date.now()).format("MM/DD/YYYY"),
    category_id: 0,

    s_name: "",
    s_user_id: 0,

    payment_to: 0,
    b_user_id: 0,
    b_email: "",

    approval: false,
  });

  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const toggleAddPayableModal = () => {
    props?.setShowAddPayableModal(!showAddPayableModal);
  };

  const requestorOptions = props?.payableDetails?.requestor?.data;
  const statusOptions = props?.payableDetails?.status?.data;
  const expenseTypeOptions = props?.payableDetails?.expenseType?.data;
  const paymentMethodOptions = props?.payableDetails?.paymentMethod?.data;
  const paymentTermOptions = props?.payableDetails?.paymentTerm?.data;
  const statesOptions = props?.payableDetails?.states?.data;
  const countriesOptions = props?.countries;
  const serviceOptions = props?.payableDetails?.deliveryService?.data;
  const deliveryMethodOptions = props?.payableDetails?.deliveryMethod?.data;
  const deliveryInfoOptions = props?.payableDetails?.deliveryInformation?.data;

  useEffect(() => {
    if (!showAddPayableModal) {
      setType("");
      setValues({
        pid: 0,

        title: "New Payables Request",
        created: moment(Date.now()).format("MM/DD/YYYY"),
        req_id: 0,
        status_id: 0,
        expense_type: 0,
        inventory: false,

        entity: "",
        notes: "",
        payment_method: 0,
        req_amount: "",
        terms_id: 0,
        pay_to: "",
        name: "",
        address1: "",
        address2: "",
        city: "",
        st: "",
        zip: "",
        p_country_id: 0,
        c_name: "",
        c_phone: "",
        c_email: "",
        delivery_service: 0,
        tracking_num: "",

        bank_name: "",
        bank_address1: "",
        bank_address2: "",
        bank_city: "",
        bank_st: "0",
        bank_zip: "",
        bank_account: "",
        country_id: 0,
        swift_code: "",
        aba_number: "",

        delivery_method: 0,
        delivery_info: 0,

        purchase_purpose: "",
        instructions: "",

        due_date: moment(Date.now()).format("MM/DD/YYYY"),
        category_id: 0,

        s_name: "",
        s_user_id: 0,

        payment_to: 0,
        b_user_id: 0,
        b_email: "",

        approval: false,
      });
      setClearDropdown(!clearDropdown);
    }
    if (showAddPayableModal) {
      props.refStart();
      if (!statusOptions) {
        props.getStatus((res) => {});
      }
      if (!requestorOptions) {
        props.getRequestor((res) => {});
      }
      if (!statesOptions) {
        props.getStates((res) => {});
      }
      if (!expenseTypeOptions) {
        props.getExpenseType((res) => {});
      }
      if (!paymentMethodOptions) {
        props.getPaymentMethod((res) => {});
      }
      if (!paymentTermOptions) {
        props.getPaymentTerm((res) => {});
      }
      if (!deliveryInfoOptions) {
        props.getDeliveryInformation((res) => {});
      }
      if (!deliveryMethodOptions) {
        props.getDeliveryMethod((res) => {});
      }
      if (!serviceOptions) {
        props.getDeliveryService((res) => {});
      }
      if (!countriesOptions) {
        props.getCountries((res) => {
          if (res.status === 200) {
            props.refStop();
            setCountries(res.data.data);
          }
        });
      }
      if (!buyers?.length > 0) {
        props?.searchBuyer(
          props?.auctionId,
          {
            auction_id: props?.auctionId,
            buyer: 1,
            c_des: "",
            cell: "",
            city: "",
            company_name: "",
            email: "",
            ext_ref: 0,
            fax: "",
            inspector: 0,
            name: "",
            outside_agent: 0,
            phone: "",
            recommend_id: 0,
            st_des: "",
            zip: "",
          },
          (res) => {
            if (res.status === 200) {
              setBuyers(res.data.data);
            }
          },
        );
      }
    }
  }, [showAddPayableModal]);

  const loadBuyers = (name, callback) => {
    let options = [];
    props?.searchBuyer(
      props?.auctionId,
      {
        auction_id: props?.auctionId,
        setObserver: "",
        removeObserver: "",
        buyer: 1,
        c_des: "",
        cell: "",
        city: "",
        company_name: "",
        email: "",
        ext_ref: 0,
        fax: "",
        inspector: 0,
        name: name ? name : "",
        outside_agent: 0,
        phone: "",
        recommend_id: 0,
        st_des: "",
        zip: "",
      },
      (res) => {
        if (res.status === 200) {
          options = res.data?.data?.map((val) => ({
            name: `${val.name ? val.name + ` (${val.email})` : val.email} `,
            user_id: val.user_id,
            email: val.email,
          }));
          callback(options);
          setBuyers(res.data.data);
        } else {
          callback([]);
          toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        }
      },
    );
  };

  useEffect(() => {
    if (showAddPayableModal) {
      loadBuyers();
    }
  }, [showAddPayableModal]);

  useEffect(() => {
    if (showAddPayableModal && type != "edit") {
      props.getPayable(props.auctionId, 0, (res) => {
        if (res.status === 200) {
          const details = res.data.data;
          const data = { ...values };
          data.s_user_id = details?.sellerInfo?.s_user_id;
          data.s_name = details?.sellerInfo?.name;
          setValues(data);
        } else {
          toast(<AlertError message="Something Went Wrong" />);
        }
      });
    }
  }, [showAddPayableModal]);

  const toggleEditModal = () => {
    setShowEditPayableModal(!showEditPayableModal);
  };

  const openAddModal = () => {
    toggleAddPayableModal();
  };

  const profile = getProfile();

  const openEditModal = (payId) => {
    props.refStart();
    props.getPayable(props.auctionId, payId, (res) => {
      if (res.status === 200) {
        const detailData = res.data.data;
        // toggleEditModal();
        toggleAddPayableModal();
        const data = { ...values };

        data.pid = detailData?.id ? detailData?.id : 0;
        data.title = detailData?.title ? detailData?.title : "New Payables Request";
        data.created = detailData?.created ? detailData?.created : moment(Date.now()).format("MM/DD/YYYY");
        data.req_id = detailData?.req_id ? detailData?.req_id : profile?.member_id;
        data.status_id = detailData?.status_id ? detailData?.status_id : 0;
        data.expense_type = detailData?.expense_type ? detailData?.expense_type : 0;
        data.inventory = detailData?.inventory ? detailData?.inventory : false;

        data.entity = detailData?.entity ? detailData?.entity : "";
        data.notes = detailData?.notes ? detailData?.notes : "";
        data.payment_method = detailData?.payment_method ? detailData?.payment_method : 0;
        data.req_amount = detailData?.req_amount
          ? new Intl.NumberFormat("en-US").format(detailData?.req_amount)
          : "0.00";
        data.terms_id = detailData?.terms_id ? detailData?.terms_id : 0;
        data.pay_to = detailData?.pay_to ? detailData?.pay_to : "";
        data.name = detailData?.name ? detailData?.name : "";
        data.address1 = detailData?.address1 ? detailData?.address1 : "";
        data.address2 = detailData?.address2 ? detailData?.address2 : "";
        data.city = detailData?.city ? detailData?.city : "";
        data.st = detailData?.st ? detailData?.st : "";
        data.zip = detailData?.zip ? detailData?.zip : "";
        data.p_country_id = detailData?.p_country_id ? detailData?.p_country_id : 0;
        data.c_name = detailData?.c_name ? detailData?.c_name : "";
        data.c_phone = detailData?.c_phone ? detailData?.c_phone : "";
        data.c_email = detailData?.c_email ? detailData?.c_email : "";
        data.delivery_service = detailData?.delivery_service ? detailData?.delivery_service : 0;
        data.tracking_num = detailData?.tracking_num ? detailData?.tracking_num : "";

        data.bank_name = detailData?.bank_name ? detailData?.bank_name : "";
        data.bank_address1 = detailData?.bank_address1 ? detailData?.bank_address1 : "";
        data.bank_address2 = detailData?.bank_address2 ? detailData?.bank_address2 : "";
        data.bank_city = detailData?.bank_city ? detailData?.bank_city : "";
        data.bank_st = detailData?.bank_st ? detailData?.bank_st : "0";
        data.bank_zip = detailData?.bank_zip ? detailData?.bank_zip : "";
        data.bank_account = detailData?.bank_account ? detailData?.bank_account : "";
        data.country_id = detailData?.country_id ? detailData?.country_id : 0;
        data.swift_code = detailData?.swift_code ? detailData?.swift_code : "";
        data.aba_number = detailData?.aba_number ? detailData?.aba_number : "";

        data.delivery_method = detailData?.delivery_method ? detailData?.delivery_method : 0;
        data.delivery_info = detailData?.delivery_info ? detailData?.delivery_info : 0;

        data.purchase_purpose = detailData?.purchase_purpose ? detailData?.purchase_purpose : "";
        data.instructions = detailData?.instructions ? detailData?.instructions : "";

        data.due_date = detailData?.due_date ? detailData?.due_date : moment(Date.now()).format("MM/DD/YYYY");
        data.category_id = detailData?.category_id ? detailData?.category_id : 0;

        data.s_name = detailData?.sellerInfo?.name;
        data.s_user_id = detailData?.sellerInfo?.s_user_id;
        data.payment_to = detailData?.payment_to ? detailData?.payment_to : 0;
        data.b_email = detailData?.b_email ? detailData?.b_email : "";
        data.b_user_id = detailData?.b_user_id ? detailData?.b_user_id : 0;
        props.refStop();
        setValues(data);
      } else {
        toast(<AlertError message="Something Went Wrong" />);
      }
    });
  };

  //Add New Payables
  const addNewPayables = () => {
    const data = { ...values };

    setLoading(true);
    props.refStart();
    data.req_amount = values?.req_amount
      ? isNumber(values?.req_amount)
        ? values?.req_amount
        : values?.req_amount?.includes(",")
        ? +values?.req_amount.split(",").join("")
        : values?.req_amount
      : 0;
    data.req_id = values?.req_id !== 0 ? values?.req_id : profile?.member_id;
    props.addNewPayable(props.auctionId, data, (res) => {
      if (res.status === 200) {
        props.getPayableLists(props.auctionId, (res) => {
          if (res.status === 200) {
            props?.getProductCount(props?.auctionId, (res) => {});
            setLoading(false);
            props.refStop();
            toggleAddPayableModal();
            toast(<AlertSuccess message={"Information Saved"} />);
          } else {
            setLoading(false);
            toggleAddPayableModal();
            toast(<AlertSuccess message={"Information Saved"} />);
          }
        });
      } else {
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        setLoading(false);
        props.refStop();
      }
    });
  };

  //Edit Payable
  const editPayable = () => {
    setLoading(true);
    props.refStart();
    const data = { ...values };
    data.req_amount = values?.req_amount
      ? isNumber(values?.req_amount)
        ? values?.req_amount
        : values?.req_amount?.includes(",")
        ? values?.req_amount.split(",").join("")
        : values?.req_amount
      : 0;
    data.req_id = values?.req_id !== 0 ? values?.req_id : profile?.member_id;
    props.addNewPayable(props.auctionId, data, (res) => {
      if (res.status === 200) {
        props.getPayableLists(props.auctionId, (res) => {
          if (res.status === 200) {
            props?.getProductCount(props?.auctionId, (res) => {});
            setLoading(false);
            props.refStop();
            // toggleEditModal();
            toggleAddPayableModal();
            toast(<AlertSuccess message={"Information Saved"} />);
          } else {
            setLoading(false);
            toggleEditModal();
            toast(<AlertSuccess message={"Information Saved"} />);
          }
        });
      } else {
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        setLoading(false);
        props.refStop();
      }
    });
  };

  //Delete Payable
  const deleteHandler = () => {
    setLoadingDelete(true);
    props.refStart();
    props.deletePayable(props.auctionId, payableId, (res) => {
      if (res.status === 200) {
        props.getPayableLists(props.auctionId, (res) => {
          if (res.status === 200) {
            props?.getProductCount(props?.auctionId, (res) => {});
            setLoadingDelete(false);
            props.refStop();
            toggleDeleteModal();
            toast(<AlertSuccess message={"Information Saved"} />);
          } else {
            setLoadingDelete(false);
            props.refStop();
            toggleDeleteModal();
            toast(<AlertSuccess message={"Information Saved"} />);
          }
        });
      } else {
        setLoadingDelete(false);
        props.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  //Total Amount
  const totalAmount = data?.reduce((sum, val) => sum + val?.req_amount, 0);

  return (
    <div class="details-page-widget-block" id={props?.id}>
      <div class="page-widget-head d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <div class="page-widget-label text-capitalize cursor-pointer" onClick={() => toggleAddPayableModal()}>
            Payables {!props?.loadingMain && (count > 0 ? `(${count})` : "(0)")}
          </div>
        </div>
        {/* <div class="btn-block">
          {!props.loadingMain && (
            <a
              href="javascript:void(0)"
              onClick={() => {
                setType("add");
                openAddModal();
              }}
              class="btn btn-primary btn-sm"
            >
              Add New
            </a>
          )}
        </div> */}
      </div>
      <div class={data?.length > 0 && "page-widget-body mt-3"}>
        {data?.length > 0 && (
          <div class="table-responsive table-custom ">
            <table class="table mb-0 align-middle table-bordered  horizontal-table top-bids-table ">
              <colgroup>
                <col style={{ width: "6%" }} />
                <col style={{ width: "24%" }} />
                <col style={{ width: "20%" }} />
                <col style={{ width: "22%" }} />
                <col style={{ width: "25%" }} />
              </colgroup>
              <thead>
                <tr>
                  <td></td>
                  <td>Who are we paying</td>
                  <td>Requestor</td>
                  <td>Payable To</td>
                  <td>Amount</td>
                </tr>
              </thead>
              <tbody>
                {data?.map((val) => (
                  <tr>
                    <td>
                      <div class="td-action-btn-group d-flex align-items-center justify-content-between">
                        <div class="dropdown">
                          <a class="td-a-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                            <img src={More} alt="" />
                          </a>
                          <ul class="dropdown-menu ms-1">
                            <li className="menu-item">
                              <a
                                class="dropdown-item customized-drpdown-btn"
                                href="javascript:void(0)"
                                onClick={() => {
                                  setType("edit");
                                  openEditModal(val?.id);
                                }}
                              >
                                Edit
                              </a>
                            </li>
                            <li className="menu-item">
                              <a
                                class="dropdown-item customized-drpdown-btn"
                                href="javascript:void(0)"
                                onClick={() => {
                                  setPayableId(val?.id);
                                  toggleDeleteModal();
                                }}
                              >
                                Delete
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        {val?.payment_to == 1
                          ? "Seller"
                          : val?.payment_to == 2
                          ? "Buyer (Refund)"
                          : val?.payment_to == 3
                          ? "Other"
                          : "N/A"}
                      </div>
                      <span class="fw-normal ">
                        Created : {val?.created ? <DateFormat date={val?.created} /> : "N/A"}
                      </span>
                    </td>
                    <td>
                      <div>{val?.u_name ? val?.u_name : "N/A"}</div>
                      <span
                        className="fw-normal number-bold "
                        style={{
                          color:
                            val?.status_des == "Hold"
                              ? "red"
                              : val?.status_des == "Submitted"
                              ? "#b3b30e"
                              : val?.status_des == "Paid"
                              ? "green"
                              : "black",
                        }}
                      >
                        Status : {val?.status_des ? val?.status_des : "N/A"}
                      </span>
                    </td>
                    <td>
                      {val?.exact_name
                        ? val?.exact_name
                        : val?.payment_to == 1 && completed
                        ? "Seller Payment Info Autofilled"
                        : "N/A"}
                    </td>
                    <td>
                      {" "}
                      {val?.req_amount
                        ? "$" +
                          new Intl.NumberFormat("en-US", {
                            style: "decimal",
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }).format(val?.req_amount)
                        : "$0.00"}
                    </td>
                  </tr>
                ))}
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td align="left">
                    Total :{" "}
                    <b>
                      {totalAmount
                        ? "$" +
                          new Intl.NumberFormat("en-US", {
                            style: "decimal",
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }).format(totalAmount)
                        : "$0.00"}
                    </b>{" "}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
      <DeleteModal
        show={showDeleteModal}
        onHide={toggleDeleteModal}
        loadingDelete={loadingDelete}
        deleteHandler={deleteHandler}
      />
      <AddPayablesModal
        show={showAddPayableModal}
        onHide={toggleAddPayableModal}
        payableDetails={props.payableDetails}
        countries={countries}
        buyers={buyers}
        values={values}
        setValues={setValues}
        fieldData={fieldData}
        addNewPayables={addNewPayables}
        clearDropdown={clearDropdown}
        loading={loading}
        auctionId={props?.auctionId}
        type={type}
        editPayable={editPayable}
        completed={completed}
        getPaymentInfo={props?.getPaymentInfo}
        buyersList={buyersList}
        loadBuyers={loadBuyers}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getPayableLists: (params, callback) => dispatch(getPayableLists(params, callback)),
  getStatus: (callback) => dispatch(getStatus(callback)),
  getRequestor: (callback) => dispatch(getRequestor(callback)),
  getStates: (callback) => dispatch(getStates(callback)),
  getCountries: (callback) => dispatch(getCountries(callback)),
  getExpenseType: (callback) => dispatch(getExpenseType(callback)),
  getPaymentMethod: (callback) => dispatch(getPaymentMethod(callback)),
  getPaymentTerm: (callback) => dispatch(getPaymentTerm(callback)),
  getDeliveryMethod: (callback) => dispatch(getDeliveryMethod(callback)),
  getDeliveryService: (callback) => dispatch(getDeliveryService(callback)),
  getPaymentInfo: (params, callback) => dispatch(getPaymentIfo(params, callback)),
  getPayable: (params, payableId, callback) => dispatch(getPayable(params, payableId, callback)),
  getDeliveryInformation: (callback) => dispatch(getDeliveryInformation(callback)),
  addNewPayable: (params, data, callback) => dispatch(addNewPayable(params, data, callback)),
  deletePayable: (params, payableId, callback) => dispatch(deletePayable(params, payableId, callback)),
  searchBuyer: (params, data, callback) => dispatch(searchBuyer(params, data, callback)),
  setShowAddPayableModal: (data) => dispatch(setShowAddPayableModal(data)),
  getProductCount: (params, callback) => dispatch(getProductCount(params, callback)),
});
const mapStateToProps = (state) => ({
  payableDetails: payableDetails(state),
  getUser: getUser(state),
  bidsData: bidsData(state),
  payablesModal: getModals(state)?.payablesModal,
});
export default connect(mapStateToProps, mapDispatchToProps)(React.memo(NewPayables));
