import React from "react";
import { Offcanvas } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import emptyChat from "../../../../include/images/chat-empty.svg";
import { useState } from "react";
import Select from "react-select";
import { customStyles } from "../../../../common/customStyles/reactSelectStyle";
const AddAviationModel = (props) => {
  return (
    <Offcanvas
      show={props.show}
      onHide={() => {
        // const data = { ...props.keyword };
        // data.model = "";
        // data.aviationType = "";
        // props.setKeyword(data);
        props.onHide();
      }}
      placement="end"
      className="offcanvas offcanvas-end custom-offcanvas-sm "
      backdropClassName="upload-images-backdrop"
      tabindex="-1"
      id="userFilter"
      enforceFocus={false}
    >
      {props?.aviationType?.type == "editModel" ? (
        <>
          <Offcanvas.Header className="offcanvas-header ">
            <h5 class="offcanvas-title" style={{ marginLeft: "-9px" }}>
              Edit Aviation Model
            </h5>
            <button
              type="button"
              className="btn-close text-reset"
              onClick={() => {
                const data = { ...props.keyword };
                data.model = "";
                data.aviationType = "";
                props.setKeyword(data);
                props.onHide();
              }}
            ></button>
          </Offcanvas.Header>
          <Offcanvas.Body className="offcanvas-body co-body">
            <div class="form-group">
              <label for="keyword" class="form-label">
                Aviation Model Name
              </label>
              <input
                type="text"
                class="form-control"
                id="auction-search"
                value={props.data?.description}
                onChange={(e) => {
                  const data = { ...props.data };
                  data.description = e.target.value;
                  props.setData(data);
                }}
              />
            </div>
            <div class="form-group">
              <label for="keyword" class="form-label">
                Aviation Type
              </label>
              <Select
                value={props?.options?.find((val) => val.value === props?.editData?.type)}
                className="basic-single"
                classNamePrefix="select-search"
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.value}
                name="aviationModel"
                isLoading={props?.loading}
                loadingMessage={() => "Loading..."}
                options={props?.options || []}
                placeholder={"Select Aviation Model"}
                styles={customStyles}
                onChange={(e) => {
                  const val = { ...props.editData };
                  val.type = e.value;
                  props.setEditData(val);
                  const data = { ...props.data };
                  data.aviationType = e;
                  props.setData(data);
                }}
              />
            </div>
          </Offcanvas.Body>
          <div class="offcanvas-footer">
            <a
              href="javascript:void(0)"
              class={`btn btn-default btn-block ${(props.loading || !props.data?.description) && "disabled"}`}
              onClick={() => props.handleEditModel()}
            >
              Submit
            </a>
          </div>
        </>
      ) : props?.aviationType?.type == "filterModel" ? (
        <>
          <Offcanvas.Header className="offcanvas-header ">
            <h5 class="offcanvas-title" style={{ marginLeft: "-9px" }}>
              Filters
            </h5>
            <button type="button" className="btn-close text-reset" onClick={() => props.onHide()}></button>
          </Offcanvas.Header>
          <Offcanvas.Body className="offcanvas-body co-body">
            <div class="form-group">
              <label for="keyword" class="form-label">
                Aviation Model Name
              </label>
              <input
                type="text"
                class="form-control"
                id="auction-search"
                value={props.keyword?.model}
                onChange={(e) => {
                  const data = { ...props.keyword };
                  data.model = e.target.value;
                  props.setKeyword(data);
                }}
              />
            </div>
            <div class="form-group">
              <label for="keyword" class="form-label">
                Aviation Type{" "}
              </label>
              <input
                type="text"
                class="form-control"
                id="auction-search"
                value={props.keyword?.aviationType}
                onChange={(e) => {
                  const data = { ...props.keyword };
                  data.aviationType = e.target.value;
                  props.setKeyword(data);
                }}
              />
            </div>
          </Offcanvas.Body>
          <div class="offcanvas-footer">
            <a
              href="javascript:void(0)"
              class={`btn btn-default btn-block ${props.loading && "disabled"}`}
              onClick={() =>
                props.handleFilterModel({ model: props?.keyword?.model, aviationType: props?.keyword?.aviationType })
              }
            >
              Submit
            </a>
          </div>
        </>
      ) : (
        <>
          <Offcanvas.Header className="offcanvas-header ">
            <h5 class="offcanvas-title" style={{ marginLeft: "-9px" }}>
              Add Aviation Model
            </h5>
            <button type="button" className="btn-close text-reset" onClick={() => props.onHide()}></button>
          </Offcanvas.Header>
          <Offcanvas.Body className="offcanvas-body co-body">
            <div class="form-group">
              <label for="keyword" class="form-label">
                Aviation Model Name
              </label>
              <input
                type="text"
                class="form-control"
                id="auction-search"
                value={props.data?.description}
                onChange={(e) => {
                  const data = { ...props.data };
                  data.description = e.target.value;
                  props.setData(data);
                }}
              />
            </div>
            <div class="form-group">
              <label for="keyword" class="form-label">
                Add Aviation Type
              </label>
              <Select
                value={props.data?.aviationType}
                className="basic-single"
                classNamePrefix="select-search"
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.value}
                name="aviationModel"
                isLoading={props?.loading}
                loadingMessage={() => "Loading..."}
                options={props?.options || []}
                placeholder={"Select Aviation Model"}
                styles={customStyles}
                onChange={(e) => {
                  const data = { ...props.data };
                  data.aviationType = e;
                  props.setData(data);
                }}
              />
            </div>
          </Offcanvas.Body>
          <div class="offcanvas-footer">
            <a
              href="javascript:void(0)"
              class={`btn btn-default btn-block ${
                (props.loading || !props.data?.description || !props?.data?.aviationType?.value) && "disabled"
              }`}
              onClick={() => props.handleAddModel()}
            >
              Submit
            </a>
          </div>
        </>
      )}
    </Offcanvas>
  );
};

export default React.memo(AddAviationModel);
