import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import NextButton from "../../common/form/nextButton";
import close from "../../include/images/close.svg";
import calculator from "../../include/images/calculator.svg";

const SustainabilityModal = (props) => {
  const calculateCo2eTotal = () => {
    const val = props.values;
    const data = { ...props.values };
    data.co2e_total = val.co2e_rate * val.co2e_qty;
    props.setValues(data);
  };
  return (
    <Modal
      className="modal fade custom-modal"
      id="editSustainabilityReport"
      show={props.show}
      onHide={() => props.onHide()}
      dialogClassName="modal-dialog modal-dialog-centered modal-lg"
    >
      <div class="modal-content h-100 d-flex flex-column">
        <div class="custom-modal-header d-flex align-items-center justify-content-between">
          <h5 class="mb-0 text-capitalize">Edit Sustainability Report</h5>
          <button
            type="button"
            class="close-btn d-flex align-items-center justify-content-center"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => props.onHide()}
          >
            <img src={close} alt="" />
          </button>
        </div>
        <Modal.Body className="custom-modal-body flex-grow-1 hide-scrollbar padding-bottom-0">
          <table class="table modal-product-detail-table mb-0">
            <colgroup>
              <col style={{ width: "30%" }} />
              <col style={{ width: "70%" }} />
            </colgroup>
            <tr>
              <td>Raw Materials Pct</td>
              <td>
                <div className="padding-0">
                  <input
                    type="number"
                    min="0"
                    step="0.001"
                    class="form-control"
                    value={props.values && props.values.raw_materials_pct}
                    onChange={(e) => {
                      const values = { ...props.values };
                      values.raw_materials_pct = e.target.value;
                      props.setValues(values);
                    }}
                  />
                </div>
              </td>
            </tr>

            <tr>
              <td>Transportation Pct</td>
              <td>
                <div className="padding-0">
                  <input
                    type="number"
                    min="0"
                    step="0.001"
                    class="form-control"
                    value={props.values && props.values.transportation_pct}
                    onChange={(e) => {
                      const values = { ...props.values };
                      values.transportation_pct = e.target.value;
                      props.setValues(values);
                    }}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>Assembly Pct</td>
              <td>
                <div className="padding-0">
                  <input
                    type="number"
                    min="0"
                    step="0.001"
                    class="form-control"
                    value={props.values && props.values.assembly_pct}
                    onChange={(e) => {
                      const values = { ...props.values };
                      values.assembly_pct = e.target.value;
                      props.setValues(values);
                    }}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>CO2e Rate</td>
              <td>
                <div className="padding-0">
                  <input
                    type="number"
                    min="0"
                    step="0.001"
                    class="form-control"
                    value={props.values && props.values.co2e_rate}
                    onChange={(e) => {
                      const values = { ...props.values };
                      values.co2e_rate = e.target.value;
                      props.setValues(values);
                    }}
                  />
                </div>
              </td>
            </tr>

            <tr>
              <td>CO2e Qty</td>
              <td>
                <div className="padding-0">
                  <input
                    type="number"
                    min="0"
                    step="0.001"
                    class="form-control"
                    value={props.values && props.values.co2e_qty}
                    onChange={(e) => {
                      const values = { ...props.values };
                      values.co2e_qty = e.target.value;
                      props.setValues(values);
                    }}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>CO2e Rate Unit</td>
              <td>
                <div className="padding-0">
                  <input
                    type="text"
                    class="form-control"
                    value={props.values && props.values.co2e_rate_unit}
                    onChange={(e) => {
                      const values = { ...props.values };
                      values.co2e_rate_unit = e.target.value;
                      props.setValues(values);
                    }}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>CO2e Total</td>
              <td>
                <div className="padding-0 d-flex">
                  <input
                    type="number"
                    min="0"
                    step="0.001"
                    class="form-control"
                    value={props.values && props.values.co2e_total}
                    onChange={(e) => {
                      const values = { ...props.values };
                      values.co2e_total = e.target.value;
                      props.setValues(values);
                    }}
                  />
                  <a href="javascript:void(0)" className="d-flex align-items-center ps-1" onClick={calculateCo2eTotal}>
                    <img src={calculator} alt="calculator" />
                  </a>
                </div>
              </td>
            </tr>
          </table>
          {/* <div className="mt-2">
            <div class="title">Environmental Organizations</div>
            <div class="form-checkbox-item">
              {props?.envOrganization?.map((val, i) => (
                <div class="form-check dark-tick-checkbox mb-2" key={val?.enterprise_id}>
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value={val.enterprise_id}
                    checked={props.values?.env_org?.split(",")?.includes(val?.enterprise_id?.toString())}
                    onChange={() => {
                      const values = { ...props.values };
                      const envOrgArray = values?.env_org?.split(",") || [];

                      const enterpriseIdStr = val.enterprise_id.toString();

                      if (envOrgArray.includes(enterpriseIdStr)) {
                        const indexToRemove = envOrgArray.indexOf(enterpriseIdStr);
                        envOrgArray.splice(indexToRemove, 1);
                      } else {
                        envOrgArray.push(enterpriseIdStr);
                      }

                      values.env_org = envOrgArray.join(",");
                      props.setValues(values);
                    }}
                    id={val.enterprise_id}
                  />
                  <label class="form-check-label fw-medium text-nowrap" for={val.enterprise_id}>
                    {val.name}
                  </label>
                </div>
              ))}
            </div>
          </div> */}
        </Modal.Body>
        <div class="custom-modal-dividor"></div>
        <div className="custom-modal-footer d-grid gap-2 d-md-flex align-items-center justify-content-md-end">
          <button
            class="btn btn-link me-md-2 global-cancel-button"
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => props.onHide()}
          >
            Cancel
          </button>
          <NextButton
            label="Save"
            classData="btn btn-default"
            handleSubmit={props.handleSubmit}
            loading={props.loadingSustainabilty}
          />
        </div>
      </div>
    </Modal>
  );
};

export default SustainabilityModal;
