import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getListingProductDetail, otherDescpReceived, updateOtherDescription } from "../../../store/listingProduct";
import { toast } from "react-toastify";
import AlertError from "../../../common/alerts/alertError";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import Skeleton from "react-loading-skeleton";
import AiLeadsModal from "../../../layouts/modals/aiLeadsModal";
import { getModals, setAILead } from "../../../store/modal";

const AiLeads = (props) => {
  // const [showAiModal, setShowAIModal] = useState(false);
  const showAiModal=props?.showAiModal;
  const setShowAIModal=props?.setShowAIModal;
  const [loading, setLoading] = useState(false);
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [data, setData] = useState("");

  const toggleAIModal = () => {
    setShowAIModal(!showAiModal);
    setData("");
  };

  const value =
    props.getListingProductDetail &&
    props.getListingProductDetail.otherDescription &&
    props.getListingProductDetail.otherDescription.data;

  const handleSubmit = () => {
    const id = props.auctionId?.trim();
    props.refStart();
    setLoading(true);
    const payload = {
      ai_lead: data,
    };
    props.updateOtherDescription(id, payload, (res) => {
      if (res.status === 200) {
        props.refStop();
        setLoading(false);
        const data1 = {
          ai_lead: data,
          ai_description: value.ai_description ? value.ai_description : "",
          mfr_description: value.mfr_description ? value.mfr_description : "",
          co_description: value.co_description ? value.co_description : "",
        };
        props.otherDescpReceived({ data: data1 });
        toggleAIModal();
        toast(<AlertSuccess message="Information Saved" />);
      } else {
        props.refStop();
        setLoading(false);
        toast(<AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  useEffect(() => {
    if (showAiModal) {
      setData(value && value.ai_lead ? value.ai_lead : "");
    }
  }, [showAiModal]);
  return (
    <div class="details-page-widget-block" id={props.id}>
      <div class="page-widget-head d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <div
            class="page-widget-label text-capitalize cursor-pointer"
            onClick={() => !props.loadingMain && toggleAIModal()}
          >
            AI Leads
          </div>
        </div>
        {/* <div class="btn-block d-flex align-items-center">
          {props.loadingMain ? (
            <Skeleton height="30px" width="90px" />
          ) : (
            <a href="javascript:void(0)" class="btn btn-primary btn-sm" onClick={() => toggleAIModal()}>
              Edit
            </a>
          )}
        </div> */}
      </div>
      <div class="page-widget-body mt-3 ck-content">
        {props.loadingMain ? (
          <Skeleton width="100%" count={6} height="10px" />
        ) : value && value.ai_lead ? (
          <div
            className="ck-content ck-content-description table custom-table  align-middle table-left-align"
            id="descpFix"
            dangerouslySetInnerHTML={{ __html: value?.ai_lead }}
          />
        ) : (
          "N/A"
        )}
      </div>
      <AiLeadsModal
        show={showAiModal}
        onHide={toggleAIModal}
        initialData={data}
        editorData={data}
        setEditorData={setData}
        setEditorLoaded={setEditorLoaded}
        editorLoaded={editorLoaded}
        loading={loading}
        handleSubmit={handleSubmit}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateOtherDescription: (params, data, callback) => dispatch(updateOtherDescription(params, data, callback)),
  

  //global state for manipulating data
  otherDescpReceived: (data) => dispatch(otherDescpReceived(data)),
  setShowAIModal: (data) => dispatch(setAILead(data)),
});

const mapStateToProps = (state) => ({
  getListingProductDetail: getListingProductDetail(state),
  showAiModal: getModals(state)?.aiLead,
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(AiLeads));
