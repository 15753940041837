import React from "react";
import { Helmet } from "react-helmet";

const HelmetComponent = (props) => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{props?.title ? props?.title : "Salvex - Admin Panel"}</title>
      <meta
        name="description"
        content={props?.description ? props?.description : "An admin user interface to handle business operations."}
      />
    </Helmet>
  );
};

export default HelmetComponent;
