import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import payables, {
  addNewPayable,
  deletePayable,
  getDeliveryInformation,
  getDeliveryMethod,
  getDeliveryService,
  getExpenseType,
  getPayable,
  getPayableLists,
  getPaymentMethod,
  getPaymentTerm,
  getRequestor,
  getStates,
  getStatus,
  payableDetails,
} from "../../../store/payables";
import DateFormat from "../../../common/DateFormat/dateFormat";
import edit from "../../../include/images/edit-18x18.svg";
import deletebtn from "../../../include/images/trash-18x18.svg";
import { getCountries } from "../../../store/productLocation";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import AlertError from "../../../common/alerts/alertError";
import moment from "moment";
import { toast } from "react-toastify";
import DeleteModal from "../../../layouts/modals/deleteModal";
import Skeleton from "react-loading-skeleton";
import AddPayablesModal from "../../../layouts/modals/payablesModal/payablesModal";
import helpCircle from "../../../include/images/help-circle.svg";
import { isNumber } from "lodash";
import { getModals, setShowAddPayableModal } from "../../../store/modal";

const Payables = (props) => {
  const [countries, setCountries] = useState([]);
  const data = props && props.payableDetails && props.payableDetails.payables && props.payableDetails.payables.data;

  const fieldData = props && props.payableDetails && props.payableDetails.payable && props.payableDetails.payable.data;
  const showAddPayableModal = props?.payablesModal;

  const [showEditPayableModal, setShowEditPayableModal] = useState(false);
  const [payableId, setPayableId] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [clearDropdown, setClearDropdown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [type, setType] = useState("");

  const [values, setValues] = useState({
    pid: 0,

    title: "New Payables Request",
    created: moment(Date.now()).format("MM/DD/YYYY"),
    req_id: 0,
    status_id: 0,
    expense_type: 0,
    inventory: false,

    entity: "",
    notes: "",
    payment_method: 0,
    req_amount: "0.00",
    terms_id: 0,
    pay_to: "",
    name: "",
    address1: "",
    address2: "",
    city: "",
    st: "",
    zip: "",
    p_country_id: 0,
    c_name: "",
    c_phone: "",
    c_email: "",
    delivery_service: 0,
    tracking_num: "",

    bank_name: "",
    bank_address1: "",
    bank_address2: "",
    bank_city: "",
    bank_st: "0",
    bank_zip: "",
    bank_account: "",
    country_id: 0,
    swift_code: "",
    aba_number: "",

    delivery_method: 0,
    delivery_info: 0,

    purchase_purpose: "",
    instructions: "",

    due_date: moment(Date.now()).format("MM/DD/YYYY"),
    category_id: 0,

    s_name: "",
    s_user_id: 0,

    approval: false,
  });

  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const toggleAddPayableModal = () => {
    props?.setShowAddPayableModal(!showAddPayableModal);
  };

  const requestorOptions = props?.payableDetails?.requestor?.data;
  const statusOptions = props?.payableDetails?.status?.data;
  const expenseTypeOptions = props?.payableDetails?.expenseType?.data;
  const paymentMethodOptions = props?.payableDetails?.paymentMethod?.data;
  const paymentTermOptions = props?.payableDetails?.paymentTerm?.data;
  const statesOptions = props?.payableDetails?.states?.data;
  const countriesOptions = props?.countries;
  const serviceOptions = props?.payableDetails?.deliveryService?.data;
  const deliveryMethodOptions = props?.payableDetails?.deliveryMethod?.data;
  const deliveryInfoOptions = props?.payableDetails?.deliveryInformation?.data;

  useEffect(() => {
    if (!showAddPayableModal) {
      setType("");
      setValues({
        pid: 0,

        title: "New Payables Request",
        created: moment(Date.now()).format("MM/DD/YYYY"),
        req_id: 0,
        status_id: 0,
        expense_type: 0,
        inventory: false,

        entity: "",
        notes: "",
        payment_method: 0,
        req_amount: "",
        terms_id: 0,
        pay_to: "",
        name: "",
        address1: "",
        address2: "",
        city: "",
        st: "",
        zip: "",
        p_country_id: 0,
        c_name: "",
        c_phone: "",
        c_email: "",
        delivery_service: 0,
        tracking_num: "",

        bank_name: "",
        bank_address1: "",
        bank_address2: "",
        bank_city: "",
        bank_st: "0",
        bank_zip: "",
        bank_account: "",
        country_id: 0,
        swift_code: "",
        aba_number: "",

        delivery_method: 0,
        delivery_info: 0,

        purchase_purpose: "",
        instructions: "",

        due_date: moment(Date.now()).format("MM/DD/YYYY"),
        category_id: 0,

        s_name: "",
        s_user_id: 0,

        approval: false,
      });
      setClearDropdown(!clearDropdown);
    }
    if (showAddPayableModal) {
      props.refStart();
      if (!statusOptions) {
        props.getStatus((res) => {});
      }
      if (!requestorOptions) {
        props.getRequestor((res) => {});
      }
      if (!statesOptions) {
        props.getStates((res) => {});
      }
      if (!expenseTypeOptions) {
        props.getExpenseType((res) => {});
      }
      if (!paymentMethodOptions) {
        props.getPaymentMethod((res) => {});
      }
      if (!paymentTermOptions) {
        props.getPaymentTerm((res) => {});
      }
      if (!deliveryInfoOptions) {
        props.getDeliveryInformation((res) => {});
      }
      if (!deliveryMethodOptions) {
        props.getDeliveryMethod((res) => {});
      }
      if (!serviceOptions) {
        props.getDeliveryService((res) => {});
      }
      if (!countriesOptions) {
        props.getCountries((res) => {
          if (res.status === 200) {
            props.refStop();
            setCountries(res.data.data);
          }
        });
      }
    }
  }, [showAddPayableModal]);

  useEffect(() => {
    if (type == "add") {
      props.getPayable(props.auctionId, 0, (res) => {
        if (res.status === 200) {
          const details = res.data.data;
          const data = { ...values };
          data.s_user_id = details?.sellerInfo?.s_user_id;
          data.s_name = details?.sellerInfo?.name;
          setValues(data);
        } else {
          toast(<AlertError message="Something Went Wrong" />);
        }
      });
    }
  }, [type]);

  const toggleEditModal = () => {
    setShowEditPayableModal(!showEditPayableModal);
  };

  const openAddModal = () => {
    toggleAddPayableModal();
  };

  const openEditModal = (payId) => {
    props.refStart();
    props.getPayable(props.auctionId, payId, (res) => {
      if (res.status === 200) {
        const detailData = res.data.data;
        // toggleEditModal();
        toggleAddPayableModal();
        const data = { ...values };

        data.pid = detailData?.id ? detailData?.id : 0;
        data.title = detailData?.title ? detailData?.title : "New Payables Request";
        data.created = detailData?.created ? detailData?.created : moment(Date.now()).format("MM/DD/YYYY");
        data.req_id = detailData?.req_id ? detailData?.req_id : 0;
        data.status_id = detailData?.status_id ? detailData?.status_id : 0;
        data.expense_type = detailData?.expense_type ? detailData?.expense_type : 0;
        data.inventory = detailData?.inventory ? detailData?.inventory : false;

        data.entity = detailData?.entity ? detailData?.entity : "";
        data.notes = detailData?.notes ? detailData?.notes : "";
        data.payment_method = detailData?.payment_method ? detailData?.payment_method : 0;
        data.req_amount = detailData?.req_amount
          ? new Intl.NumberFormat("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(detailData?.req_amount)
          : "0.00";
        data.terms_id = detailData?.terms_id ? detailData?.terms_id : 0;
        data.pay_to = detailData?.pay_to ? detailData?.pay_to : "";
        data.name = detailData?.name ? detailData?.name : "";
        data.address1 = detailData?.address1 ? detailData?.address1 : "";
        data.address2 = detailData?.address2 ? detailData?.address2 : "";
        data.city = detailData?.city ? detailData?.city : "";
        data.st = detailData?.st ? detailData?.st : "";
        data.zip = detailData?.zip ? detailData?.zip : "";
        data.p_country_id = detailData?.p_country_id ? detailData?.p_country_id : 0;
        data.c_name = detailData?.c_name ? detailData?.c_name : "";
        data.c_phone = detailData?.c_phone ? detailData?.c_phone : "";
        data.c_email = detailData?.c_email ? detailData?.c_email : "";
        data.delivery_service = detailData?.delivery_service ? detailData?.delivery_service : 0;
        data.tracking_num = detailData?.tracking_num ? detailData?.tracking_num : "";

        data.bank_name = detailData?.bank_name ? detailData?.bank_name : "";
        data.bank_address1 = detailData?.bank_address1 ? detailData?.bank_address1 : "";
        data.bank_address2 = detailData?.bank_address2 ? detailData?.bank_address2 : "";
        data.bank_city = detailData?.bank_city ? detailData?.bank_city : "";
        data.bank_st = detailData?.bank_st ? detailData?.bank_st : "0";
        data.bank_zip = detailData?.bank_zip ? detailData?.bank_zip : "";
        data.bank_account = detailData?.bank_account ? detailData?.bank_account : "";
        data.country_id = detailData?.country_id ? detailData?.country_id : 0;
        data.swift_code = detailData?.swift_code ? detailData?.swift_code : "";
        data.aba_number = detailData?.aba_number ? detailData?.aba_number : "";

        data.delivery_method = detailData?.delivery_method ? detailData?.delivery_method : 0;
        data.delivery_info = detailData?.delivery_info ? detailData?.delivery_info : 0;

        data.purchase_purpose = detailData?.purchase_purpose ? detailData?.purchase_purpose : "";
        data.instructions = detailData?.instructions ? detailData?.instructions : "";

        data.due_date = detailData?.due_date ? detailData?.due_date : moment(Date.now()).format("MM/DD/YYYY");
        data.category_id = detailData?.category_id ? detailData?.category_id : 0;

        data.s_name = detailData?.sellerInfo?.name;
        data.s_user_id = detailData?.sellerInfo?.s_user_id;
        props.refStop();
        setValues(data);
      } else {
        toast(<AlertError message="Something Went Wrong" />);
      }
    });
  };

  //Add New Payables
  const addNewPayables = () => {
    const data = { ...values };

    setLoading(true);
    props.refStart();
    data.req_amount = values?.req_amount
      ? isNumber(values?.req_amount)
        ? values?.req_amount
        : values?.req_amount?.includes(",")
        ? +values?.req_amount.split(",").join("")
        : values?.req_amount
      : 0;
    props.addNewPayable(props.auctionId, data, (res) => {
      if (res.status === 200) {
        props.getPayableLists(props.auctionId, (res) => {
          if (res.status === 200) {
            setLoading(false);
            props.refStop();
            toggleAddPayableModal();
            toast(<AlertSuccess message={"Information Saved"} />);
          } else {
            setLoading(false);
            toggleAddPayableModal();
            toast(<AlertSuccess message={"Information Saved"} />);
          }
        });
      } else {
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        setLoading(false);
        props.refStop();
      }
    });
  };

  //Edit Payable
  const editPayable = () => {
    setLoading(true);
    props.refStart();
    const data = { ...values };
    data.req_amount = values?.req_amount
      ? isNumber(values?.req_amount)
        ? values?.req_amount
        : values?.req_amount?.includes(",")
        ? values?.req_amount.split(",").join("")
        : values?.req_amount
      : 0;
    props.addNewPayable(props.auctionId, data, (res) => {
      if (res.status === 200) {
        props.getPayableLists(props.auctionId, (res) => {
          if (res.status === 200) {
            setLoading(false);
            props.refStop();
            // toggleEditModal();
            toggleAddPayableModal();
            toast(<AlertSuccess message={"Information Saved"} />);
          } else {
            setLoading(false);
            toggleEditModal();
            toast(<AlertSuccess message={"Information Saved"} />);
          }
        });
      } else {
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        setLoading(false);
        props.refStop();
      }
    });
  };

  //Delete Payable
  const deleteHandler = () => {
    setLoadingDelete(true);
    props.refStart();
    props.deletePayable(props.auctionId, payableId, (res) => {
      if (res.status === 200) {
        props.getPayableLists(props.auctionId, (res) => {
          if (res.status === 200) {
            setLoadingDelete(false);
            props.refStop();
            toggleDeleteModal();
            toast(<AlertSuccess message={"Information Saved"} />);
          } else {
            setLoadingDelete(false);
            props.refStop();
            toggleDeleteModal();
            toast(<AlertSuccess message={"Information Saved"} />);
          }
        });
      } else {
        setLoadingDelete(false);
        props.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  //Total Amount
  const totalAmount = data?.reduce((sum, val) => sum + val?.req_amount, 0);
  return (
    <div class="details-page-widget-block" id={props.id}>
      <div class="page-widget-head d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <div
            class="page-widget-label text-capitalize cursor-pointer"
            onClick={() => {
              setType("add");
              openAddModal();
            }}
          >
            Payables {data?.length > 0 ? "" : !props.loadingMain && "(0)"}
          </div>
        </div>
        {/* <div class="btn-block">
          {!props.loadingMain && (
            <a
              href="javascript:void(0)"
              onClick={() => {
                setType("add");
                openAddModal();
              }}
              class="btn btn-primary btn-sm"
            >
              Add New
            </a>
          )}
        </div> */}
      </div>
      {props.loadingMain ? (
        <div class="page-widget-body mt-3">
          <div class="table-responsive">
            <table class="large-table align-left">
              <colgroup>
                <col style={{ width: "28px" }} />
                <col />
                <col />
                <col />
                <col />
                <col />
                <col />
                <col style={{ width: "28px" }} />
                <col style={{ width: "28px" }} />
              </colgroup>
              <thead>
                <tr>
                  <th>
                    <Skeleton />
                  </th>
                  <th>
                    <Skeleton />
                  </th>
                  <th>
                    <Skeleton />
                  </th>
                  <th>
                    <Skeleton />
                  </th>
                  <th>
                    <Skeleton />
                  </th>
                  <th>
                    <Skeleton />
                  </th>
                  <th>
                    <Skeleton />
                  </th>
                  <th>
                    <Skeleton />
                  </th>
                  <th>
                    <Skeleton />
                  </th>
                </tr>
              </thead>
              <tbody>
                {[1, 2, 3]?.map(() => (
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div class={data?.length > 0 && "page-widget-body mt-3"}>
          {data?.length > 0 && (
            <div class="table-responsive">
              <table class="large-table align-left">
                <colgroup>
                  <col style={{ width: "28px" }} />
                  <col />
                  <col />
                  <col />
                  <col />
                  <col />
                  <col />
                  <col style={{ width: "28px" }} />
                  <col style={{ width: "28px" }} />
                </colgroup>
                <thead>
                  <tr>
                    <th></th>
                    <th>Expense Type</th>
                    <th>Created</th>
                    <th>Requestor</th>
                    <th>Status</th>
                    <th>Payable to</th>
                    <th className="text-end">Amount</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((val) => (
                    <tr>
                      <td></td>
                      <td dangerouslySetInnerHTML={{ __html: val?.expense_des }}></td>
                      <td>
                        <DateFormat date={val?.created} />
                      </td>
                      <td>{val?.u_name}</td>
                      <td>{val?.status_des}</td>
                      <td>{val?.exact_name}</td>
                      <td align="right">
                        {val?.req_amount
                          ? "$" +
                            new Intl.NumberFormat("en-US", {
                              style: "decimal",
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(val?.req_amount)
                          : "$0.00"}
                      </td>
                      <td>
                        <button
                          onClick={() => {
                            setType("edit");
                            openEditModal(val?.id);
                          }}
                        >
                          <img src={edit} alt="Edit" />
                        </button>
                      </td>
                      <td>
                        <button
                          onClick={() => {
                            setPayableId(val?.id);
                            toggleDeleteModal();
                          }}
                        >
                          <img src={deletebtn} alt="Delete" />
                        </button>
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td align="right">
                      <b>TOTAL:</b>
                    </td>
                    <td align="right">
                      <b>
                        {totalAmount
                          ? "$" +
                            new Intl.NumberFormat("en-US", {
                              style: "decimal",
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(totalAmount)
                          : "$0.00"}
                      </b>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </div>
      )}
      <DeleteModal
        show={showDeleteModal}
        onHide={toggleDeleteModal}
        loadingDelete={loadingDelete}
        deleteHandler={deleteHandler}
      />
      <AddPayablesModal
        show={showAddPayableModal}
        onHide={toggleAddPayableModal}
        payableDetails={props.payableDetails}
        countries={countries}
        values={values}
        setValues={setValues}
        fieldData={fieldData}
        addNewPayables={addNewPayables}
        clearDropdown={clearDropdown}
        loading={loading}
        auctionId={props?.auctionId}
        type={type}
        editPayable={editPayable}
      />
    </div>
  );
};

// export default Payables;
const mapDispatchToProps = (dispatch) => ({
  getPayableLists: (params, callback) => dispatch(getPayableLists(params, callback)),
  getStatus: (callback) => dispatch(getStatus(callback)),
  getRequestor: (callback) => dispatch(getRequestor(callback)),
  getStates: (callback) => dispatch(getStates(callback)),
  getCountries: (callback) => dispatch(getCountries(callback)),
  getExpenseType: (callback) => dispatch(getExpenseType(callback)),
  getPaymentMethod: (callback) => dispatch(getPaymentMethod(callback)),
  getPaymentTerm: (callback) => dispatch(getPaymentTerm(callback)),
  getDeliveryMethod: (callback) => dispatch(getDeliveryMethod(callback)),
  getDeliveryService: (callback) => dispatch(getDeliveryService(callback)),
  getPayable: (params, payableId, callback) => dispatch(getPayable(params, payableId, callback)),
  getDeliveryInformation: (callback) => dispatch(getDeliveryInformation(callback)),
  addNewPayable: (params, data, callback) => dispatch(addNewPayable(params, data, callback)),
  deletePayable: (params, payableId, callback) => dispatch(deletePayable(params, payableId, callback)),
  setShowAddPayableModal: (data) => dispatch(setShowAddPayableModal(data)),
});
const mapStateToProps = (state) => ({
  payableDetails: payableDetails(state),
  payablesModal: getModals(state)?.payablesModal,
});
export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Payables));
