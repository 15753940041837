import React, { useRef, useState } from "react";

import closeIcon from "../../include/images/close-12x12.svg";
import darkStarIcon from "../../include/images/dark-star-icon.svg";
import darkEditIcon from "../../include/images/dark-edit-icon.svg";
import selectedIcon from "../../include/images/yellow-star-icon.svg";
import maxim from "../../include/images/dark-maximize-icon.svg";
import scribble from "../../include/images/Scribble.svg";
import crop from "../../include/images/dark-crop-icon.svg";
import rotate from "../../include/images/dark-rotate-cw-icon.svg";
import trash from "../../include/images/trash-20x20.svg";
import save from "../../include/images/save.svg";
import close from "../../include/images/close-icon.svg";
import addBtn from "../../include/images/add-button-image.png";
import cropNew from "../../include/images/crop-new.svg";
import { Offcanvas, OverlayTrigger, Popover } from "react-bootstrap";
import _ from "lodash";
import DeleteModal from "./deleteModalPics";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Edit from "../../common/editImage/editImageNew";
import { Scrib } from "../../common/editImage/scribble";

const UploadImages = (props) => {
  const childRef = useRef(null);
  const imageref = useRef(null);
  const scribbleRef = useRef(null);

  const popoverClick =
    props.optionType === "edit" ? (
      <Popover
        id="popover-positioned-top"
        title="Popover top"
        bsPrefix=" popover custom-popover border-0 rounded-0"
      >
        <Popover.Body>
          <div class="d-flex align-content-center">
            <input
              type="text"
              placeholder="Enter Image Title"
              class="form-control flex-grow-1"
              value={props.title}
              onChange={(e) => props.setTitle(e.target.value)}
            />
            <button
              type="button"
              class={`addClickButton d-flex align-items-center justify-content-center ${
                props.loadingTitleUpdate ? "btn-disabled " : ""
              }`}
              onClick={() => {
                props.handleSaveTitle();
              }}
            >
              <img src={save} alt="" />
            </button>
          </div>
        </Popover.Body>
      </Popover>
    ) : (
      <Popover></Popover>
    );

  const removeUploaded = (res) => {
    const a = props.imageUploaded.filter((item) => item.name !== res.name);
    props.setImageUploaded(a);
  };

  const data = props.listingImages;

  const primaryImage =
    data &&
    data.pics &&
    data.pics.length > 0 &&
    data.pics.filter((item) => item.picture_id === props.primaryPicture)[0];

  return (
    <Offcanvas
      placement="end"
      show={props.uploadImageModal}
      onHide={() => props.toggleUploadImageModal(false)}
      className={`offcanvas offcanvas-end xl-offcanvas border-0`}
      id="uploadImageOffcanvas"
      enforceFocus={false}
      // backdrop={props.uploadImageModal ? true : false}
      backdropClassName={
        props.optionType === "edit"
          ? `upload-images-edit-backdrop`
          : `upload-images-backdrop`
      }
    >
      <Offcanvas.Header className="offcanvas-header">
        <h5 class="offcanvas-title">
          {props.optionType === "crop"
            ? "Crop Image"
            : props.optionType === "rotate"
            ? "Rotate Image"
            : props.optionType === "scribble"
            ? "Scribble Image"
            : "Upload Images"}
        </h5>
        <button
          type="button"
          class="offcanvas-close-btn d-flex align-items-center justify-content-center"
          data-bs-dismiss="offcanvas"
          onClick={() => {
            if (
              props.optionType === "crop" ||
              props.optionType === "rotate" ||
              props.optionType === "scribble"
            ) {
              props.setOptionType("");
              props.setEditedFile([]);
            } else {
              props.setUploadImageModal(false);
              props.setOptionType("");
            }
          }}
        >
          <img src={closeIcon} alt="" />
        </button>
      </Offcanvas.Header>
      {props.optionType === "crop" || props.optionType === "rotate" ? (
        <Offcanvas.Body className="offcanvas-body p-0 position-relative">
          {props.progress === "uploading" ? (
            <div class="progress-row d-flex align-items-center justify-content-between">
              <div class="row-progress-label" role="progressbar">
                Please wait while image is being saved
              </div>
            </div>
          ) : props.progress === "completed" ? (
            <div class="progress-row d-flex align-items-center justify-content-between">
              <div class="row-progress-label" role="progressbar">
                Images has been saved successfully
              </div>
            </div>
          ) : (
            ""
          )}
          <div
            class={`offcanvas-widget-row ${
              props.optionType === "crop" ||
              (props.optionType === "rotate" && "padding-top-0")
            }`}
          >
            <Edit
              image={props.selectedImage}
              setEditedFile={props.setEditedFile}
              ref={childRef}
              optionType={props.optionType}
            />

            <div
              class="owr-column-button d-grid gap-2 d-md-flex justify-content-md-end"
              style={{ marginRight: "10px" }}
            >
              <button
                type="button"
                class="btn btn-default global-cancel-button"
                onClick={() => {
                  props.setOptionType("");
                  props.setEditedFile([]);
                }}
              >
                <span style={{ color: "black" }}> Cancel</span>
              </button>
              {props.optionType === "rotate" && (
                <button
                  type="button"
                  class="btn btn-default btn-yellow "
                  onClick={() => childRef.current.handleRotate()}
                >
                  <img src={cropNew} alt="crop" />
                  <span style={{ color: "white", marginLeft: "5px" }}>
                    Rotate
                  </span>
                </button>
              )}
              <button
                type="button"
                class={`btn btn-default ${
                  props.loadingUpload ? "btn-disabled" : ""
                }`}
                onClick={() =>
                  !props.loadingUpload && props.handleEditImageUploader()
                }
              >
                {props.loadingUpload ? "Please Wait" : "Save"}
              </button>
            </div>
          </div>

          {/* <div class={`popover-overlay ${showBackdrop && "open"}`}></div> */}
        </Offcanvas.Body>
      ) : props.optionType === "scribble" ? (
        <Offcanvas.Body className="offcanvas-body p-0 position-relative">
          {props.progress === "uploading" ? (
            <div class="progress-row d-flex align-items-center justify-content-between">
              <div class="row-progress-label" role="progressbar">
                Please wait while image is being saved
              </div>
            </div>
          ) : props.progress === "completed" ? (
            <div class="progress-row d-flex align-items-center justify-content-between">
              <div class="row-progress-label" role="progressbar">
                Image has been saved successfully
              </div>
            </div>
          ) : (
            ""
          )}
          <div
            class={`offcanvas-widget-row ${
              props.optionType === "scribble" && "padding-top-0"
            }`}
          >
            <Scrib
              image={props.selectedImage}
              setEditedFile={props.setEditedFile}
              ref={scribbleRef}
              handleEditImageUploader={props.handleEditImageUploader}
              loadingUpload={props.loadingUpload}
            />

            <div
              class="owr-column-button d-grid gap-2 d-md-flex justify-content-md-end"
              style={{ marginRight: "10px" }}
            >
              <button
                type="button"
                class="btn btn-default global-cancel-button"
                onClick={() => scribbleRef.current.clear()}
              >
                <span style={{ color: "black" }}> Clear</span>
              </button>

              <button
                type="button"
                class={`btn btn-default ${
                  props.loadingUpload ? "btn-disabled" : ""
                }`}
                onClick={() => {
                  scribbleRef.current.handleClick();
                }}
              >
                {props.loadingUpload ? "Please Wait" : "Save"}
              </button>
            </div>
          </div>

          {/* <div class={`popover-overlay ${showBackdrop && "open"}`}></div> */}
        </Offcanvas.Body>
      ) : (
        <div class="offcanvas-body p-0 position-relative">
          {props.progress === "uploading" ? (
            <div class="progress-row d-flex align-items-center justify-content-between">
              <div class="row-progress-label" role="progressbar">
                Please wait while images are being uploaded
              </div>
            </div>
          ) : props.progress === "completed" ? (
            <div class="progress-row d-flex align-items-center justify-content-between">
              <div class="row-progress-label" role="progressbar">
                Images have been uploaded successfully
              </div>
            </div>
          ) : (
            ""
          )}
          <div class="offcanvas-widget-row">
            <div class="owr-column">
              {props.loadingMain ? (
                <Skeleton height="100px" width="100%" />
              ) : (
                <div class="large-upload-box position-relative d-flex flex-column align-items-center justify-content-center">
                  <label for="formFileSm">+ Upload Images</label>
                  <div class="supported-file-format">
                    Supported file format : Jpeg, Jpg, Png
                  </div>
                  <input
                    class="form-control position-absolute"
                    multiple
                    id="formFileSm"
                    type="file"
                    accept=".jpg,.jpeg,.png"
                    onChange={(e) => {
                      props.onChangeImageUpload(e);
                    }}
                    onClick={(e) => (e.target.value = null)}
                  />
                </div>
              )}
            </div>
            <div class="owr-column">
              {props.imageUploaded && props.imageUploaded.length > 0 ? (
                <div className="selected-image-parent d-flex flex-wrap">
                  {props.imageUploaded.map((item, i) => (
                    <>
                      <div class="selected-image">
                        <img
                          src={`${URL.createObjectURL(props.imageUploaded[i])}`}
                          alt=""
                        />
                        <a
                          style={{ cursor: "pointer" }}
                          class="selected-image-closed"
                          onClick={() => removeUploaded(props.imageUploaded[i])}
                        >
                          <img src={close} alt="" />
                        </a>
                      </div>
                    </>
                  ))}
                </div>
              ) : (
                ""
              )}
            </div>

            <div class="owr-column-button d-grid gap-2 d-md-flex justify-content-md-end mt-40">
              {props.loadingMain ? (
                <Skeleton width="140px" height="40px" />
              ) : (
                <button
                  type="button"
                  class={`btn btn-default ${
                    props.loadingUpload ? "btn-disabled" : ""
                  }`}
                  onClick={() =>
                    !props.loadingUpload && props.handleImageUploader()
                  }
                >
                  {props.loadingUpload ? "Please Wait" : "Save"}
                </button>
              )}
            </div>
          </div>
          {data && data.pics && data.pics.length > 0 && (
            <div class="offcanvas-widget-row">
              <div class="page-title mb-3">All Images</div>
              <ul class="imageCard-list d-flex flex-wrap">
                {props.loadingMain ? (
                  [1, 2, 3, 4, 5, 6, 7, 8, 9, 9, 9, 9].map((item, i) => (
                    <li class="imageCard-item">
                      <Skeleton height="242px" />
                    </li>
                  ))
                ) : (
                  <>
                    {primaryImage && (
                      <li class="imageCard-item">
                        <div class="imageCard-box w-100 h-100 d-flex flex-column">
                          <div
                            class="imageCard-image-box position-relative"
                            onClick={() => {
                              props.setSelectedImage(null);
                              props.setStartIndex(0);
                              props.toggleImageGalleryModal();
                            }}
                          >
                            <img
                              ref={imageref}
                              id="myImage"
                              class="imageCard-image image"
                              src={
                                primaryImage.s_path.substring(0, 5) === "https"
                                  ? `${primaryImage.s_path}${
                                      primaryImage.filename
                                    }_lp.${primaryImage.suffix.toLowerCase()}`
                                  : `${process.env.REACT_APP_MEDIA_URL}${
                                      primaryImage.s_path
                                    }${
                                      primaryImage.filename
                                    }_lp.${primaryImage.suffix.toLowerCase()}`
                              }
                              alt=""
                            />
                            <div class="imageCard-title">
                              {primaryImage.caption ? primaryImage.caption : ""}
                            </div>
                          </div>
                          <div class="imageCard-icon-list d-flex flex-wrap align-items-center justify-content-center">
                            <div class="imageCard-icon-item">
                              <div class="imageCard-icon d-flex position-relative align-items-center justify-content-center">
                                <img
                                  src={
                                    props.primaryPicture ===
                                    primaryImage.picture_id
                                      ? selectedIcon
                                      : darkStarIcon
                                  }
                                  alt=""
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    if (
                                      props.primaryPicture ===
                                      primaryImage.picture_id
                                    ) {
                                      return;
                                    } else {
                                      props.handlePrimaryPicture(primaryImage);
                                    }
                                  }}
                                />
                              </div>
                            </div>
                            <div class="imageCard-icon-item">
                              <div
                                class="imageCard-icon d-flex position-relative align-items-center justify-content-center"
                                ref={props.componentRef}
                              >
                                <OverlayTrigger
                                  trigger="click"
                                  placement="top"
                                  rootClose
                                  overlay={popoverClick}
                                  // bsPrefix="show"
                                  onToggle={() => {
                                    if (props.optionType === "edit") {
                                      props.setOptionType("");
                                    }
                                  }}
                                >
                                  <img
                                    src={darkEditIcon}
                                    alt=""
                                    onClick={(e) => {
                                      // e.stopPropagation();
                                      if (!props.loadingTitleUpdate) {
                                        props.setOptionType("edit");
                                        props.setTitle(
                                          primaryImage.caption
                                            ? primaryImage.caption
                                            : ""
                                        );
                                        props.setOptionTypeId(
                                          primaryImage.picture_id
                                        );
                                      }
                                    }}
                                  />
                                </OverlayTrigger>
                              </div>
                            </div>
                            <div class="imageCard-icon-item">
                              <div class="imageCard-icon d-flex position-relative align-items-center justify-content-center">
                                <img
                                  src={scribble}
                                  alt="It's Working"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    props.setOptionType("scribble");
                                    props.setSelectedImage(primaryImage);
                                  }}
                                />
                              </div>
                            </div>
                            <div class="imageCard-icon-item">
                              <div class="imageCard-icon d-flex position-relative align-items-center justify-content-center">
                                <img
                                  src={crop}
                                  alt=""
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    props.setOptionType("crop");
                                    props.setSelectedImage(primaryImage);
                                  }}
                                />
                              </div>
                            </div>
                            <div class="imageCard-icon-item">
                              <div class="imageCard-icon d-flex position-relative align-items-center justify-content-center">
                                <img
                                  src={rotate}
                                  alt=""
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    props.setOptionType("rotate");
                                    props.setSelectedImage(primaryImage);
                                  }}
                                />
                              </div>
                            </div>
                            <div class="imageCard-icon-item">
                              <div class="imageCard-icon d-flex position-relative align-items-center justify-content-center">
                                <img
                                  src={trash}
                                  alt=""
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    props.setOptionType("delete");
                                    props.setSelectedImage(primaryImage);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    )}

                    {data &&
                      data.pics &&
                      data.pics.length > 0 &&
                      data.pics.map(
                        (item, i) =>
                          ((primaryImage &&
                            primaryImage.picture_id !== item.picture_id) ||
                            !primaryImage) && (
                            <li
                              // key={item.picture_id}
                              class="imageCard-item cursor-pointer"
                            >
                              <div class="imageCard-box w-100 h-100 d-flex flex-column">
                                <div
                                  class="imageCard-image-box position-relative"
                                  onClick={() => {
                                    props.setSelectedImage(null);
                                    props.setStartIndex(i);
                                    props.toggleImageGalleryModal();
                                  }}
                                >
                                  <img
                                    ref={imageref}
                                    id="myImage"
                                    class="imageCard-image image"
                                    src={
                                      item.s_path.substring(0, 5) === "https"
                                        ? `${item.s_path}${
                                            item.filename
                                          }_lp.${item.suffix.toLowerCase()}`
                                        : `${process.env.REACT_APP_MEDIA_URL}${
                                            item.s_path
                                          }${
                                            item.filename
                                          }_lp.${item.suffix.toLowerCase()}`
                                    }
                                    alt=""
                                  />
                                  <div class="imageCard-title">
                                    {item.caption ? item.caption : ""}
                                  </div>
                                </div>
                                <div class="imageCard-icon-list d-flex flex-wrap align-items-center justify-content-center">
                                  <div class="imageCard-icon-item">
                                    <div class="imageCard-icon d-flex position-relative align-items-center justify-content-center">
                                      <img
                                        src={
                                          props.primaryPicture ===
                                          item.picture_id
                                            ? selectedIcon
                                            : darkStarIcon
                                        }
                                        alt=""
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          if (
                                            props.primaryPicture ===
                                            item.picture_id
                                          ) {
                                            return;
                                          } else {
                                            props.handlePrimaryPicture(item);
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div class="imageCard-icon-item">
                                    <div class="imageCard-icon d-flex position-relative align-items-center justify-content-center">
                                      <OverlayTrigger
                                        trigger="click"
                                        placement="top"
                                        rootClose
                                        overlay={popoverClick}
                                        bsPrefix="show"
                                        onToggle={() => {
                                          if (props.optionType === "edit") {
                                            props.setOptionType("");
                                          }
                                        }}
                                      >
                                        <img
                                          src={darkEditIcon}
                                          alt=""
                                          onClick={() => {
                                            // e.stopPropagation();
                                            if (!props.loadingTitleUpdate) {
                                              props.setOptionType("edit");
                                              props.setTitle(
                                                item.caption ? item.caption : ""
                                              );
                                              props.setOptionTypeId(
                                                item.picture_id
                                              );
                                            }
                                          }}
                                        />
                                      </OverlayTrigger>
                                    </div>
                                  </div>
                                  <div class="imageCard-icon-item">
                                    <div
                                      class="imageCard-icon d-flex position-relative align-items-center justify-content-center"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        props.setOptionType("scribble");
                                        props.setSelectedImage(item);
                                      }}
                                    >
                                      <img src={scribble} alt="" />
                                    </div>
                                  </div>
                                  <div class="imageCard-icon-item">
                                    <div class="imageCard-icon d-flex position-relative align-items-center justify-content-center">
                                      <img
                                        src={crop}
                                        alt=""
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          props.setOptionType("crop");
                                          props.setSelectedImage(item);
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div class="imageCard-icon-item">
                                    <div class="imageCard-icon d-flex position-relative align-items-center justify-content-center">
                                      <img
                                        src={rotate}
                                        alt=""
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          props.setOptionType("rotate");
                                          props.setSelectedImage(item);
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div class="imageCard-icon-item">
                                    <div class="imageCard-icon d-flex position-relative align-items-center justify-content-center">
                                      <img
                                        src={trash}
                                        alt=""
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          props.setOptionType("delete");
                                          props.setSelectedImage(item);
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          )
                      )}
                  </>
                )}
              </ul>
            </div>
          )}
          <div
            class={`popover-overlay ${
              props.optionType === "edit" || props.optionType === "delete"
                ? "open "
                : ""
            }`}
          ></div>
        </div>
      )}
    </Offcanvas>
  );
};

export default UploadImages;
