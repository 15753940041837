import React from "react";
import { Modal } from "react-bootstrap";
import NextButton from "../../../common/form/nextButton";
import close from "../../../include/images/close.svg";
import trash from "../../../include/images/trash-20x20.svg";
import DeleteModal from "../deleteModal";

const ComparablePrice = (props) => {
  return (
    <Modal
      className="modal fade custom-modal"
      show={props.show}
      //   onHide={() => props.onHide()}
      dialogClassName="modal-dialog modal-dialog-centered modal-xxl"
    >
      <div class="modal-content d-flex flex-column">
        <div class="custom-modal-header d-flex align-items-center justify-content-between">
          <h5 class="mb-0 text-capitalize">
            {!props.LatestActivity && "Add"} Comparable Price
          </h5>
          <button
            type="button"
            class="close-btn d-flex align-items-center justify-content-center"
            onClick={() => {
              props.setComparablePriceData(props.comparablePriceData1);
              props.onHide();
            }}
          >
            <img src={close} alt="" />
          </button>
        </div>
        <div class="comparable-price-body">
          <div class="table-responsive table-custom">
            <table class="table table-bordered">
              <colgroup>
                <col />
                <col />
                <col />
                <col />
                <col style={{ width: "4%" }} />
              </colgroup>
              <thead>
                <tr>
                  <td>Amount</td>
                  <td>Link</td>
                  <td>Year</td>
                  <td colSpan="2">Location</td>

                </tr>
              </thead>
              <tbody>
                {props.comparablePriceData &&
                  props.comparablePriceData.length > 0 ? (
                  props.comparablePriceData.map((item, i) => (
                    <tr>
                      <td>
                        <input
                          type="number"
                          className="form-control"
                          value={item.amount ? item.amount : ""}
                          onChange={(e) => {
                            const value = [...props.comparablePriceData];
                            const data = { ...item };
                            data.amount = e.target.value;
                            value[i] = data;
                            props.setComparablePriceData(value);
                          }}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          value={item && item.link ? item.link : ""}
                          onChange={(e) => {
                            const value = [...props.comparablePriceData];
                            const data = { ...item };
                            data.link = e.target.value;
                            value[i] = data;
                            props.setComparablePriceData(value);
                          }}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control"
                          value={item && item.year ? item.year : ""}
                          onChange={(e) => {
                            const value = [...props.comparablePriceData];
                            const data = { ...item };
                            data.year = e.target.value;
                            value[i] = data;
                            props.setComparablePriceData(value);
                          }}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          value={item && item.location ? item.location : ""}
                          onChange={(e) => {
                            const value = [...props.comparablePriceData];
                            const data = { ...item };
                            data.location = e.target.value;
                            value[i] = data;
                            props.setComparablePriceData(value);
                          }}
                        />
                      </td>
                      <td>
                        <img
                          className="m-1 mt-2"
                          src={trash}
                          alt=""
                          onClick={() => {
                            const data = [...props.comparablePriceData];
                            const remData = data.filter(
                              (item2) => item2.id !== item.id
                            );
                            props.setComparablePriceData(remData);
                          }}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5">No Records Found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <Modal.Body className="custom-modal-body flex-grow-1 w-100 overflow-hidden"></Modal.Body>
        <div class="custom-modal-dividor"></div>
        <div class="custom-modal-footer d-grid gap-2 d-md-flex align-items-center justify-content-md-end">
          <button
            class="btn btn-link me-md-2 global-cancel-button"
            type="button"
            onClick={() => {
              props.setComparablePriceData(props.comparablePriceData1);
              props.onHide();
            }}
          >
            Cancel
          </button>
          <button
            class="btn btn-default"
            type="button"
            onClick={() => {
              const data = [...props.comparablePriceData];
              const value = {
                id: "",
                year: "",
                amount: "",
                year: "",
                location: "",
                link: "",
              };
              if (data.length > 0) {
                value.id = data[data.length - 1].id + 1;
                props.setComparablePriceData([...data, value]);
              } else {
                value.id = 1;
                props.setComparablePriceData([...data, value]);
              }
            }}
          >
            Add New
          </button>
          <NextButton
            classData="btn btn-default"
            handleSubmit={() => props.saveHandler()}
            label={"Save"}
            loading={props.loadingSave}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ComparablePrice;
