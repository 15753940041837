import React, { useRef, useEffect } from "react";
import jspreadsheet from "jspreadsheet-pro";

import "../../../node_modules/jspreadsheet-pro/dist/jspreadsheet.css";
import "../../../node_modules/jsuites/dist/jsuites.css";

const Spreadsheet = (props) => {
  const container = useRef(null);
  const license = `${process.env.REACT_APP_SPREADSHEET_TOKEN}`;

  useEffect(() => {
    const spreadsheet = jspreadsheet(container.current, {
      data: [...props?.data],
      columns: props?.isAviation
        ? [
            { title: "Part Number", type: "text", width: 100, align: "left" },
            { title: "Part Description", type: "text", width: 120, align: "left" },
            { title: "Condition", type: "text", width: 80, align: "left" },
            { title: "QTY", type: "text", width: 90, align: "right" },
            { title: "Unit Price", type: "text", width: 100, align: "right" },
            { title: "Location", type: "text", width: 90, align: "left" },
            { title: "Certificate", type: "text", width: 90, align: "left" },
            { title: "Part Type", type: "text", width: 100, align: "left" },
            { title: "Part Manufacturer", type: "text", width: 125, align: "left" },
            { title: "Plane Model", type: "text", width: 100, align: "left" },
            { title: "Plane Manufacturer", type: "text", width: 130, align: "left" },
            { title: "Images", type: "text", width: 100, align: "left" },
          ]
        : [
            { title: "Part Number", type: "text", width: 100, align: "left" },
            { title: "Part Description", type: "text", width: 200, align: "left" },
            { title: "Condition", type: "text", width: 80, align: "left" },
            { title: "QTY", type: "text", width: 90, align: "right" },
            { title: "Unit", type: "text", width: 90, align: "right" },
            { title: "Unit Price", type: "text", width: 100, align: "right" },
            { title: "Location", type: "text", width: 90, align: "left" },
            { title: "Part Manufacturer", type: "text", width: 90, align: "left" },
            { title: "Part Model", type: "text", width: 100, align: "left" },
            { title: "Grade", type: "text", width: 125, align: "left" },
            { title: "Serial Number", type: "text", width: 100, align: "left" },
            { title: "AI Product Description", type: "text", width: 130, align: "left" },
            { title: "AI Manufacturer Description", type: "text", width: 100, align: "left" },
            { title: "Notes 1", type: "text", width: 100, align: "left" },
            { title: "Notes 2", type: "text", width: 100, align: "left" },
            { title: "Notes 3", type: "text", width: 100, align: "left" },
            { title: "Notes 4", type: "text", width: 100, align: "left" },
            { title: "Notes 5", type: "text", width: 100, align: "left" },
            { title: "Images", type: "text", width: 100, align: "left" },
          ],
      rows: props?.data?.map((val, i) => ({ title: (props?.page - 1) * 200 + (i + 1) })),
      minDimensions: [0, props?.data?.length],
      tableOverflow: true,
      tableHeight: "fit-content",
      tableWidth: "100%",
      contextMenu: () => {
        return false;
      },
      license: license,
      allowDeleteColumn: false,
      allowDeleteRow: false,
      autoIncrement: false,
      onchange: (a, b) => {
        if (b) {
          const jsonData = {
            data: b.textContent,
            x: b.getAttribute("data-x"),
            y: b.getAttribute("data-y"),
            textAlign: b.style.textAlign,
          };
          props?.setIndexArr((pre) => [...pre, jsonData.y]);
          props?.setColIndex((pre) => [...pre, jsonData.x]);
          props?.setArrVal((pre) => [...pre, jsonData.data]);
          const data = { ...props?.updateValue };
          data.value = jsonData?.data;
          data.column = jsonData?.x;
          data.row = jsonData?.y;
          props?.setUpdateValue(data);
        }
      },
    });
    return () => spreadsheet.destroy();
  }, [props?.reset]);

  //Code to Hide the License Expired and box shadow.
  useEffect(() => {
    let divsWithText = document.querySelectorAll("div");

    for (let i = 0; i < divsWithText.length; i++) {
      let div = divsWithText[i];
      if (div.textContent === "Your license is expired") {
        div.classList.add("hide_expired");
      }
      if (div.className === "jexcel_content") {
        div.classList.add("hide_border_spreadsheet");
      }
      if (div.className === "jtabs jexcel_container") {
        div.classList.add("pe-0");
      }
      if (props?.data?.length === 0) {
        if (div.className === "jtabs-content") {
          div.classList.add("mt-3");
        }
      }
    }
  }, [props?.data, props?.reset]);

  return (
    <div style={{ minWidth: "100%" }}>
      <div ref={container} style={{ minWidth: "100%", boxShadow: "none" }} />
    </div>
  );
};

export default Spreadsheet;
