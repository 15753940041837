import React, { useState } from "react";
import { Offcanvas } from "react-bootstrap";
import close from "../../../../include/images/close-12x12.svg";
import pdfIcon from "../../../../include/images/pdf-file-icon.svg";
import csvFile from "../../../../include/images/csv-latest.svg";
import XLSX from "../../../../include/images/XLSX.svg";
import XLS from "../../../../include/images/XLS.svg";
import DOCX from "../../../../include/images/Docx.svg";
import DOC from "../../../../include/images/Doc.svg";
import crossIcon from "../../../../include/images/cross-icon.svg";
import trash from "../../../../include/images/trash-24x24.svg";
import download from "../../../../include/images/download-circle-icon.svg";
import emptyChat from "../../../../include/images/chat-empty.svg";

const AddDocuments = (props) => {
  const { show, onHide } = props;

  const enterprise = props.data?.data?.filter((item) => item.enterpriseId == props.selectedItem?.enterpriseId);

  const data = enterprise?.[0]?.documents?.filter((item) => item?._id == props.selectedItem?._id);

  const docList = data?.[0]?.files || [];

  return (
    <Offcanvas
      placement="end"
      backdropClassName="upload-documnets-backdrop"
      show={show}
      onHide={() => onHide()}
      className="offcanvas offcanvas-end border-0"
      id="uploadDocumentOffcanvas"
      enforceFocus={false}
    >
      <Offcanvas.Header className="offcanvas-header">
        <h5 class="offcanvas-title">
          {props.selectedItem && props.selectedItem?.mode == "add" ? "Upload Documents" : "View Documents"}
        </h5>
        <button
          type="button"
          class="offcanvas-close-btn d-flex align-items-center justify-content-center"
          onClick={() => onHide()}
        >
          <img src={close} alt="" />
        </button>
      </Offcanvas.Header>
      <Offcanvas.Body className="offcanvas-body p-0">
        {props.selectedItem?.mode && props.selectedItem?.mode == "add" && (
          <div class="offcanvas-widget-row pt-0">
            <p>Documents must be in PDF, DOC or DOCX format. You must specify a title for each document.</p>

            <div class="mb-20">
              <label for="slectDocument" class="form-label">
                Select Document
              </label>
              <div class="upload-lg-button position-relative">
                <span class="file-input">
                  <input
                    type="file"
                    id="inputGroupFile02"
                    accept="application/pdf,application/msword,
                  application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    onChange={(e) => {
                      const data = { ...props.selectedDocument };
                      data.doc = e.target.files[0];
                      props.setSelectedDocument(data);
                    }}
                    // onClick={(e) => (e.target.value = null)}
                  />
                  <span id="upload-file-name" class="d-flex align-items-center">
                    {props.selectedDocument && props.selectedDocument.doc && props.selectedDocument?.doc?.name}
                    {props.selectedDocument?.doc ? (
                      <img
                        class="cross-icon-box"
                        src={crossIcon}
                        alt=""
                        onClick={(e) => {
                          const data = { ...props.selectedDocument };
                          data.doc = null;
                          props.setSelectedDocument(data);
                        }}
                      />
                    ) : (
                      <p>Please select any document</p>
                    )}
                  </span>
                </span>
              </div>
              {props.errorDoc && props.errorDoc.doc ? <p className="error-text"> {props.errorDoc.doc} </p> : ""}
            </div>
            <div class="mb-20">
              <label for="documentTitle" class="form-label">
                Document Title
              </label>
              <input
                type="text"
                class="form-control"
                value={props.selectedDocument?.title}
                onChange={(e) => {
                  const data = { ...props.selectedDocument };
                  data.title = e.target.value;
                  props.setSelectedDocument(data);
                }}
              />
              {props.errorDoc && props.errorDoc.title ? <p className="error-text"> {props.errorDoc.title} </p> : ""}
            </div>
            <div class="d-grid-button d-grid gap-2 mt-30">
              <button
                class={`btn btn-default ${props.loading ? "btn-disabled" : ""}`}
                type="button"
                onClick={(e) => !props.loading && props.handleSubmitDocument(e)}
              >
                {props.loading ? "Please wait" : "Save"}
              </button>
            </div>
          </div>
        )}

        <div class="offcanvas-widget-row">
          {props.selectedItem && props.selectedItem?.mode == "add" ? (
            <div class="page-title mb-3">All Documents</div>
          ) : (
            ""
          )}
          <div class="table-responsive mb-3">
            <table class="table align-middle table-borderless publicDocTable mb-0">
              <colgroup>
                <col style={{ width: "50px" }} />
                <col />
                <col style={{ width: "50px" }} />
                <col style={{ width: "50px" }} />
              </colgroup>
              <tbody>
                {docList && docList.length > 0 ? (
                  docList.map((item) => {
                    const filenameArray = item?.original?.split(".") || [];
                    const fileType = filenameArray?.length > 0 ? filenameArray[filenameArray?.length - 1] : "pdf";
                    return (
                      <tr>
                        <td>
                          <div class="td-pdf-img-box">
                            <img
                              onClick={() =>
                                window.open(`${process.env.REACT_APP_S3_PRIVATE_DOC_BUCKET}/${item.original}`)
                              }
                              className="cursor-pointer"
                              src={
                                fileType === "csv"
                                  ? csvFile
                                  : fileType === "xlsx"
                                  ? XLSX
                                  : fileType === "xls"
                                  ? XLS
                                  : fileType === "doc"
                                  ? DOC
                                  : fileType === "docx"
                                  ? DOCX
                                  : pdfIcon
                              }
                              alt=""
                            />
                          </div>
                        </td>
                        <td>
                          <a
                            href={`${process.env.REACT_APP_S3_PRIVATE_DOC_BUCKET}/${item.original}`}
                            target="_blank"
                            download={true}
                            className="deafult-black-hover-green"
                          >
                            {item.name.includes(".") ? item.name : item.name + "." + fileType}
                          </a>
                        </td>
                        <td>
                          <a
                            href={`${process.env.REACT_APP_S3_PRIVATE_DOC_BUCKET}/${item.original}`}
                            target="_blank"
                            download={true}
                            class="delete-td-icon d-flex align-items-center justify-content-center"
                          >
                            <img src={download} alt="" />
                          </a>
                        </td>
                        <td>
                          <button
                            type="button"
                            class="delete-td-icon d-flex align-items-center justify-content-center"
                            onClick={() => {
                              props.setDeleteDocItem(item);
                              props.toggleDeleteDoc();
                            }}
                          >
                            <img src={trash} alt="" />
                          </button>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <>
                    <div class="empty-pannel-box mt-5">
                      <img src={emptyChat} alt="" />
                      <h4>No Documents Found!</h4>
                    </div>
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default AddDocuments;
