import React, { useEffect, useRef } from "react";
import { Offcanvas } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import emptyChat from "../../../../include/images/chat-empty.svg";
import { useState } from "react";
import Select from "react-select";
import { customStyles } from "../../../../common/customStyles/reactSelectStyle";
const AddModel = (props) => {
  const [showSuggestions, setShowSuggestions] = useState(false);
  const suggestions = props?.filteredData?.map((val) => val?.description)
    ? props?.filteredData?.map((val) => val?.description)
    : [];

  const autocompleteRef = useRef();

  useEffect(() => {
    const handleClick = (event) => {
      if (autocompleteRef.current && !autocompleteRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    };
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  const handleChange = (e) => {
    const data = { ...props.data };
    data.description = e.target.value;
    props.setData(data);
  };

  const handleSuggestionClick = (suggetion) => {
    const data = { ...props.data };
    data.description = suggetion;
    props.setData(data);
    setShowSuggestions(false);
  };
  return (
    <Offcanvas
      show={props.show}
      onHide={() => {
        const data = { ...props.keyword };
        data.model = "";
        data.mfr = "";
        props.setKeyword(data);
        props.onHide();
      }}
      placement="end"
      className="offcanvas offcanvas-end custom-offcanvas-sm "
      backdropClassName="upload-images-backdrop"
      tabindex="-1"
      id="userFilter"
      enforceFocus={false}
    >
      {props?.mfr?.type == "view" ? (
        <>
          <Offcanvas.Header className="offcanvas-header ">
            <h5 class="offcanvas-title" style={{ marginLeft: "-9px" }}>
              {props?.mfr?.item?.Description} Models
            </h5>
            <button type="button" className="btn-close text-reset" onClick={() => props.onHide()}></button>
          </Offcanvas.Header>
          <Offcanvas.Body className="offcanvas-body co-body">
            {props?.loading ? (
              [1, 2, 3, 4, 21, 2].map((e) => (
                <div className="d-block " style={{ margin: "1rem 1rem 1rem 0px " }}>
                  <Skeleton />
                </div>
              ))
            ) : props?.data && props?.data?.length > 0 ? (
              props?.data?.map((ey, i) => (
                <div className="d-block " style={{ margin: "1rem 1rem 1rem 0px ", fontSize: "1rem" }}>
                  <p>
                    {i + 1}.&nbsp; &nbsp;
                    {ey?.description}
                  </p>
                </div>
              ))
            ) : (
              <div class="pannel-body d-flex align-items-center justify-content-center empty-verification-documents">
                <div class="empty-pannel-box">
                  <img src={emptyChat} alt="" />
                  <h4>No Models Found!</h4>
                </div>
              </div>
            )}
          </Offcanvas.Body>
        </>
      ) : props?.mfr?.type == "editModel" ? (
        <>
          <Offcanvas.Header className="offcanvas-header ">
            <h5 class="offcanvas-title" style={{ marginLeft: "-9px" }}>
              Edit Model
            </h5>
            <button
              type="button"
              className="btn-close text-reset"
              onClick={() => {
                const data = { ...props.keyword };
                data.model = "";
                data.mfr = "";
                props.setKeyword(data);
                props.onHide();
              }}
            ></button>
          </Offcanvas.Header>
          <Offcanvas.Body className="offcanvas-body co-body">
            {
              <div class="form-group">
                <label for="keyword" class="form-label">
                  Model Name
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="auction-search"
                  value={props.data?.description}
                  onChange={handleChange}
                  onFocus={() => setShowSuggestions(true)}
                />
                {showSuggestions && props?.filteredData?.length > 0 && (
                  <ul className="suggestions">
                    {props?.filteredData.map((val) => (
                      <li onClick={() => handleSuggestionClick(val?.description)} key={val?.description}>
                        {val?.description}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            }
          </Offcanvas.Body>
          <div class="offcanvas-footer">
            <a
              href="javascript:void(0)"
              class={`btn btn-default btn-block ${(props.loading || !props.data?.description) && "disabled"}`}
              onClick={() => props.handleEditModel()}
            >
              Submit
            </a>
          </div>
        </>
      ) : props?.mfr?.type == "filterModel" ? (
        <>
          <Offcanvas.Header className="offcanvas-header ">
            <h5 class="offcanvas-title" style={{ marginLeft: "-9px" }}>
              Filters
            </h5>
            <button type="button" className="btn-close text-reset" onClick={() => props.onHide()}></button>
          </Offcanvas.Header>
          <Offcanvas.Body className="offcanvas-body co-body">
            <div class="form-group">
              <label for="keyword" class="form-label">
                Model Name
              </label>
              <input
                type="text"
                class="form-control"
                id="auction-search"
                value={props.keyword?.model}
                onChange={(e) => {
                  const data = { ...props.keyword };
                  data.model = e.target.value;
                  props.setKeyword(data);
                }}
              />
            </div>
            <div class="form-group">
              <label for="keyword" class="form-label">
                Manufacturer Name
              </label>
              <input
                type="text"
                class="form-control"
                id="auction-search"
                value={props.keyword?.mfr}
                onChange={(e) => {
                  const data = { ...props.keyword };
                  data.mfr = e.target.value;
                  props.setKeyword(data);
                }}
              />
            </div>
          </Offcanvas.Body>
          <div class="offcanvas-footer">
            <a
              href="javascript:void(0)"
              class={`btn btn-default btn-block ${props.loading && "disabled"}`}
              onClick={() => props.handleFilterModel({ model: props?.keyword?.model, mfr: props?.keyword?.mfr })}
            >
              Submit
            </a>
          </div>
        </>
      ) : (
        <>
          <Offcanvas.Header className="offcanvas-header ">
            <h5 class="offcanvas-title" style={{ marginLeft: "-9px" }}>
              Add Model
            </h5>
            <button type="button" className="btn-close text-reset" onClick={() => props.onHide()}></button>
          </Offcanvas.Header>
          <Offcanvas.Body className="offcanvas-body co-body">
            <div class="form-group">
              <label for="keyword" class="form-label">
                Manufacturer
              </label>
              <Select
                value={props.data?.mfr}
                className="basic-single"
                classNamePrefix="select-search"
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.value}
                isClearable={true}
                isSearchable={true}
                name="manufacturer"
                isLoading={props?.loading}
                loadingMessage={() => "Loading..."}
                options={props?.options || []}
                placeholder={"Select Manufacturer"}
                styles={customStyles}
                onChange={(e) => {
                  const data = { ...props.data };
                  data.mfr = e;
                  props.setData(data);
                }}
              />
            </div>
            <div class="form-group">
              <label for="keyword" class="form-label">
                Model Name
              </label>
              <input
                type="text"
                class="form-control"
                id="auction-search"
                value={props.data?.description}
                onChange={handleChange}
                onFocus={() => setShowSuggestions(true)}
              />
              {showSuggestions && props?.filteredData?.length > 0 && (
                <ul className="suggestions">
                  {props?.filteredData.map((val) => (
                    <li onClick={() => handleSuggestionClick(val?.description)} key={val?.description}>
                      {val?.description}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </Offcanvas.Body>
          <div class="offcanvas-footer">
            <a
              href="javascript:void(0)"
              class={`btn btn-default btn-block ${
                (props.loading || !props.data?.description || !props?.data?.mfr?.value) && "disabled"
              }`}
              onClick={() => props.handleAddModel()}
            >
              Submit
            </a>
          </div>
        </>
      )}
    </Offcanvas>
  );
};

export default React.memo(AddModel);
