import React, { useEffect } from "react";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import {
  getListingProductDetail,
  getBuyerPushHistory,
  getKeyBuyerCount,
  getKeyBuyer,
} from "../../../store/listingProduct";
import DateFormat from "../../../common/DateFormat/dateFormat";
import helpCircle from "../../../include/images/help-circle.svg";
import { useState } from "react";
import AlertError from "../../../common/alerts/alertError";
import { toast } from "react-toastify";
import KeyPushModal from "../../../layouts/modals/buyerPushHistory/keyPushModal";
import { getProfile } from "../../../utils.js/localStorageServices";
const BuyerPush = (props) => {
  const profile = getProfile();
  const [keyPush, setKeyPush] = useState(false);
  const [page, setPage] = useState(1);
  const [region, setRegion] = useState("");
  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [showKeyPushModal, setShowKeyPushModal] = useState(false);
  const toggelKeyPushModal = () => {
    setRegion("");
    setPage(1);
    setShowKeyPushModal(!showKeyPushModal);
  };
  const details =
    (props.getListingProductDetail &&
      props.getListingProductDetail.buyerPush &&
      props.getListingProductDetail.buyerPush.data) ||
    {};
  const keyPushCountAccess = profile?.accessLevels?.key_push_count_access;

  const keyPushCount = props?.getListingProductDetail?.keyBuyerCount?.data;
  const modalData = props?.getListingProductDetail?.keyBuyer?.data;
  const dataCount = props?.getListingProductDetail?.keyBuyer?.total_records;

  //send key buyer push function
  const handleSendKeyPush = () => {
    props?.setLoadingMain(true);
    props?.getKeyBuyerCount(props?.auctionId, (res) => {
      if (res.status === 200) {
        props?.setLoadingMain(false);
      } else {
        props?.setLoadingMain(false);
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const handleKeyPush = () => {
    setLoading(true);
    props?.refStart();
    props?.getKeyBuyer(props?.auctionId, region, page, keyword, (res) => {
      if (res.status === 200) {
        props?.refStop();
        setLoading(false);
        setShowKeyPushModal(true);
      } else {
        props?.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  useEffect(() => {
    if (props?.auctionId) {
      handleSendKeyPush();
    }
  }, [props?.auctionId]);

  useEffect(() => {
    if (props?.refStart && region) {
      handleKeyPush();
    }
  }, [region, page]);

  useEffect(() => {
    if (props?.refStart && keyword === "") {
      handleKeyPush();
    }
  }, [keyword]);
  return (
    <div className="details-page-widget-block" id={props.id}>
      <div className="page-widget-head d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <div className="page-widget-label text-capitalize">Buyer Push History</div>
        </div>
      </div>
      <div className="d-flex justify-content-start align-items-center mt-3 flex-wrap">
        <div className="mb-2">
          <button
            className="btn key-push-btn btn-primary-group"
            onClick={() => {
              // setRegion("local");
              window.open(
                process.env.REACT_APP_MODE === "development"
                  ? `https://admin-dev.salvex.com/admin/_listings/views/key_buyer.cfm?aucID=${props?.auctionId}&tr=local`
                  : `https://admin.salvex.com/admin/_listings/views/key_buyer.cfm?aucID=${props?.auctionId}&tr=local`,
              );
            }}
          >
            LOCAL KEY PUSH {keyPushCount?.local > 0 ? `(${keyPushCount?.local})` : "(0)"}
          </button>
        </div>
        <div className="mb-2">
          <button
            className="btn key-push-btn btn-other"
            onClick={() => {
              // setRegion("regional");
              window.open(
                process.env.REACT_APP_MODE === "development"
                  ? `https://admin-dev.salvex.com/admin/_listings/views/key_buyer.cfm?aucID=${props?.auctionId}&tr=regional`
                  : `https://admin.salvex.com/admin/_listings/views/key_buyer.cfm?aucID=${props?.auctionId}&tr=regional`,
              );
            }}
          >
            REGIONAL KEY PUSH {keyPushCount?.regional > 0 ? `(${keyPushCount?.regional})` : "(0)"}
          </button>
        </div>
        {keyPushCountAccess ? (
          <>
            <div className="mb-2">
              <button
                className="btn key-push-btn btn-primary-group"
                onClick={() => {
                  // setRegion("global");
                  window.open(
                    process.env.REACT_APP_MODE === "development"
                      ? `https://admin-dev.salvex.com/admin/_listings/views/key_buyer.cfm?aucID=${props?.auctionId}&tr=global`
                      : `https://admin.salvex.com/admin/_listings/views/key_buyer.cfm?aucID=${props?.auctionId}&tr=global`,
                  );
                }}
              >
                GLOBAL KEY PUSH {keyPushCount?.global > 0 ? `(${keyPushCount?.global})` : "(0)"}
              </button>
            </div>
            <div className="mb-2">
              <button
                className="btn key-push-btn btn-other"
                onClick={() => {
                  // setRegion("global");
                  window.open(
                    process.env.REACT_APP_MODE === "development"
                      ? `https://admin-dev.salvex.com/admin/_listings/views/key_buyer.cfm?aucID=${props?.auctionId}&tr=combine`
                      : `https://admin.salvex.com/admin/_listings/views/key_buyer.cfm?aucID=${props?.auctionId}&tr=combine`,
                  );
                }}
              >
                ALL KEY PUSH {keyPushCount?.combine > 0 ? `(${keyPushCount?.combine})` : "(0)"}
              </button>
            </div>
          </>
        ) : (
          ""
        )}
        <div className="mb-2 d-flex justify-content-end">
          <button
            className="btn key-push-btn btn-primary-group"
            onClick={() => {
              // setRegion("global");
              window.open(
                process.env.REACT_APP_MODE === "development"
                  ? `https://admin-dev.salvex.com/admin/_listings/views/buyerworksheet.cfm?aucID=${props?.auctionId}&tr=local`
                  : `https://admin.salvex.com/admin/_listings/views/buyerworksheet.cfm?aucID=${props?.auctionId}&tr=local`,
              );
            }}
          >
            Buyer Work Sheet{" "}
            {keyPushCountAccess ? (keyPushCount?.workSheet > 0 ? `(${keyPushCount?.workSheet})` : "(0)") : ""}
          </button>
        </div>
      </div>

      {/* =====================logs section -- UnComment If Needed ============================== */}
      {details.logs && details.logs.length > 0 ? (
        <>
          <div className="page-widget-body mt-3" id={props.id}>
            <div className="table-responsive" style={{ maxHeight: "400px", border: "1px solid #e4e4e4" }}>
              <table className="table align-middle product-table" style={{ marginBottom: "0px" }}>
                <colgroup>
                  <col style={{ minWidth: "36%" }} />
                  <col />
                </colgroup>
                <tbody>
                  {props.loadingMain ? (
                    <>
                      <tr>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                      </tr>
                    </>
                  ) : details.logs && details.logs.length > 0 ? (
                    details.logs.map((val, idx) => {
                      return (
                        <>
                          <tr>
                            <td>
                              <span>{val.sender} </span>
                              <span>{val && val.created ? <DateFormat date={val.created} /> : "N/A"}</span>
                            </td>
                            <td>
                              {" "}
                              <p className="mt-2">{val.message}</p>
                            </td>
                          </tr>
                        </>
                      );
                    })
                  ) : (
                    ""
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      <div className="page-widget-body mt-3">
        <div className="page-widget-label text-capitalize mb-3">Email Statistics</div>
        <div className="table-responsive">
          <table className="table align-middle product-table">
            <colgroup>
              <col style={{ width: "36%" }} />
              <col />
            </colgroup>
            <tbody>
              {props.loadingMain ? (
                <>
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                </>
              ) : (
                <>
                  <tr>
                    <td>Buyers Targeted</td>
                    <td>
                      {details && details.buyer_targeted
                        ? new Intl.NumberFormat("en-US").format(details.buyer_targeted)
                        : "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td>Emails Opened</td>
                    <td>
                      <u
                        className="cursor-pointer"
                        onClick={() => {
                          window.open(
                            process.env.REACT_APP_MODE === "development"
                              ? `https://admin-dev.salvex.com/admin/_marketing/_dr_push/_contents.cfm?auction_id=${props?.auctionId}&type=emailread`
                              : `https://admin.salvex.com/admin/_marketing/_dr_push/_contents.cfm?auction_id=${props?.auctionId}&type=emailread`,
                          );
                        }}
                      >
                        {details &&
                          details.emails_opened &&
                          new Intl.NumberFormat("en-US").format(details.emails_opened)}
                      </u>
                    </td>
                  </tr>

                  <tr>
                    <td>Emails Clicked</td>
                    <td>
                      {details &&
                        details.emails_clicked &&
                        new Intl.NumberFormat("en-US").format(details.emails_clicked)}
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </div>
        <div className="page-widget-label text-capitalize mb-3">Key Buyer Push Statistics</div>
        <div className="table-responsive">
          <table className="table align-middle product-table">
            <colgroup>
              <col style={{ width: "36%" }} />
              <col />
            </colgroup>
            <tbody>
              {props.loadingMain ? (
                <>
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                </>
              ) : (
                <>
                  <tr>
                    <td>Buyers Targeted</td>
                    <td>{details && details.total ? new Intl.NumberFormat("en-US").format(details.total) : "0"}</td>
                  </tr>

                  <tr>
                    <td>Emails Opened</td>
                    <td>
                      <u
                        className="cursor-pointer"
                        onClick={() => {
                          window.open(
                            process.env.REACT_APP_MODE === "development"
                              ? `https://admin-dev.salvex.com/admin/_marketing/_key_push/_contents.cfm?auction_id=${props?.auctionId}&type=emailread`
                              : `https://admin.salvex.com/admin/_marketing/_key_push/_contents.cfm?auction_id=${props?.auctionId}&type=emailread`,
                          );
                        }}
                      >
                        {details && details.email_read && new Intl.NumberFormat("en-US").format(details.email_read)}
                      </u>
                    </td>
                  </tr>

                  <tr>
                    <td>Emails Clicked</td>
                    <td>
                      {details && details.email_click && new Intl.NumberFormat("en-US").format(details.email_click)}
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </div>
        <div class="page-widget-label text-capitalize mb-3">Sales Effort Emails Sent Statistics</div>
        <div class="table-responsive">
          <table class="table align-middle product-table">
            <colgroup>
              <col style={{ width: "36%" }} />
              <col />
            </colgroup>
            <tbody>
              {props.loadingMain ? (
                <>
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                </>
              ) : (
                <>
                  <tr>
                    <td>Key Buyer Push</td>
                    <td>
                      {details &&
                        details.key_buyer_push &&
                        details.key_buyer_push.counter &&
                        new Intl.NumberFormat("en-US").format(details.key_buyer_push.counter)}
                    </td>
                    <td>
                      {details && details.key_buyer_push && details.key_buyer_push.latest_push ? (
                        <DateFormat date={details.key_buyer_push.latest_push} />
                      ) : (
                        "N/A"
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>Individual Push</td>
                    <td>
                      {details &&
                        details.individual_push &&
                        details.individual_push.counter &&
                        new Intl.NumberFormat("en-US").format(details.individual_push.counter)}
                    </td>
                    <td>
                      {details && details.individual_push && details.individual_push.latestPush ? (
                        <DateFormat date={details.individual_push.latestPush} />
                      ) : (
                        "N/A"
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>Research Assignment Push</td>
                    <td>
                      {details &&
                        details.research_assignment_push &&
                        details.research_assignment_push.counter &&
                        new Intl.NumberFormat("en-US").format(details.research_assignment_push.counter)}
                    </td>
                    <td>
                      {details && details.research_assignment_push && details.research_assignment_push.latest_push ? (
                        <DateFormat date={details.research_assignment_push.latest_push} />
                      ) : (
                        "N/A"
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>Regional Push</td>
                    <td>
                      {details &&
                        details.regional_push &&
                        details.regional_push.counter &&
                        new Intl.NumberFormat("en-US").format(details.regional_push.counter)}
                    </td>
                    <td>
                      {details && details.regional_push && details.regional_push.latestPush ? (
                        <DateFormat date={details.regional_push.latestPush} />
                      ) : (
                        "N/A"
                      )}
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
          {details.push_categories && details.push_categories.length > 0 ? (
            <>
              <div class="page-widget-label pb-3 "> Push Categories:</div>
              <div style={{ border: "1px solid #e4e4e4", padding: "5px 0px" }}>
                <div style={{ height: "150px", overflow: "auto" }}>
                  {details.push_categories && details.push_categories.length > 0
                    ? details.push_categories.map((val, idx) => {
                        return (
                          <>
                            <div className="previous-seller-motivation-box">
                              <p class=" previous-seller-motivation-p">{val.description}</p>
                            </div>
                          </>
                        );
                      })
                    : ""}
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
      <KeyPushModal
        show={showKeyPushModal}
        onHide={toggelKeyPushModal}
        data={modalData}
        count={keyPushCount}
        dataCount={dataCount}
        region={region}
        page={page}
        setPage={setPage}
        handleSubmit={handleKeyPush}
        loading={loading}
        keyword={keyword}
        setKeyword={setKeyword}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getBuyerPushHistory: (params, callback) => dispatch(getBuyerPushHistory(params, callback)),
  getKeyBuyerCount: (params, callback) => dispatch(getKeyBuyerCount(params, callback)),
  getKeyBuyer: (params, region, page, keyword, callback) =>
    dispatch(getKeyBuyer(params, region, page, keyword, callback)),
});

const mapStateToProps = (state) => ({
  getListingProductDetail: getListingProductDetail(state),
});
export default connect(mapStateToProps, mapDispatchToProps)(React.memo(BuyerPush));
