import React from "react";
import { Modal } from "react-bootstrap";

const CollectModal = (props) => {
  const date = new Date(props.date);
  const formattedDate = date.toLocaleDateString("en-US", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  return (
    <Modal
      className=" fade custom-modal-popup"
      show={props.show}
      onHide={() => props.onHide()}
      enforceFocus={true}
      backdropClassName={"delete-backdrop"}
      //   backdrop={uploadImageModal ? false : true}
      centered
    >
      <Modal.Body>
        <div class="modal-alert-box">
          <h4>Alert! </h4>
          <p>
            Are you sure you want to collect?{props?.date && ` A notice was sent on ${formattedDate}.`}
          </p>
        </div>
        <div class="modal-action-btn">
          <a
            href="javascript:void(0)"
            class={`btn btn-default btn-lg  ${
              props.loadingDelete && "btn-disabled"
            }`}
            onClick={() => !props.loadingDelete && props.submitHandler()}
          >
            Ok
          </a>
          <a
            href="javascript:void(0)"
            style={{ background: "#fff" }}
            class="btn btn-cancel global-cancel-button"
            onClick={() => props.onHide()}
          >
            Cancel
          </a>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CollectModal;
