import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import close from "../../../include/images/close.svg";
import NextButton from "../../../common/form/nextButton";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { customStyles } from "../../../common/customStyles/reactSelectStyle";

const AnimalModal = (props) => {
  const region = props?.region?.filter((val) => val?.country !== "US");
  const subRegion = props?.region?.filter((val) => val?.country === "US");
  return (
    <Modal
      className="modal fade custom-modal"
      id="editCondition"
      dialogClassName="modal-dialog modal-dialog-centered modal-lg"
      show={props.show}
      onHide={() => props.onHide()}
    >
      <div class="modal-content d-flex flex-column">
        <div class="custom-modal-header d-flex align-items-center justify-content-between">
          <h5 class="mb-0 text-capitalize">Animal</h5>
          <button type="button" class="close-btn d-flex align-items-center justify-content-center">
            <img src={close} onClick={() => props.onHide()} />
          </button>
        </div>
        <Modal.Body className="custom-modal-body flex-grow-1 w-100 overflow-hidden" style={{ minHeight: "425px" }}>
          <div class="rdr-item d-flex fle x-nowrap mb-4" style={{ marginTop: "10px" }}>
            <div class="rdr-label-box align-self-start">Select Region</div>
            <div class="rdr-form-box w-350">
              <Select
                menuPosition="fixed"
                menuShouldScrollIntoView
                styles={customStyles}
                className="basic-single "
                classNamePrefix="select-search  "
                value={props?.regionSelect}
                options={[{ countryName: "United States", _id: "0" }, ...region]}
                placeholder={`Please Select Region`}
                getOptionLabel={(option) => option.countryName}
                getOptionValue={(option) => option._id}
                onChange={(e) => {
                  props?.setRegionSelect(e);
                  props?.setCategorySelect({
                    name: "Please Select Category",
                    _id: "",
                  });
                }}
                name={"animalregion"}
              />
              {props?.errors && props?.errors.region ? <p className="error-text">{props?.errors.region}</p> : ""}
            </div>
          </div>
          {props?.regionSelect?.countryName === "United States" && (
            <div class="rdr-item d-flex fle x-nowrap mb-4" style={{ marginTop: "30px" }}>
              <div class="rdr-label-box align-self-start">Select Sub-region</div>
              <div class="rdr-form-box  w-350">
                <Select
                  menuPosition="fixed"
                  menuShouldScrollIntoView
                  styles={customStyles}
                  className="basic-single  "
                  classNamePrefix="select-search "
                  value={props?.subRegionSelect}
                  placeholder={`Please Select Sub-region`}
                  options={subRegion}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option._id}
                  onChange={(e) => {
                    props?.setSubRegionSelect(e);
                    props?.setCategorySelect({
                      name: "Please Select Category",
                      _id: "",
                    });
                  }}
                  name={"animalSubRegion"}
                />
                {props?.errors && props?.errors.subRegion ? (
                  <p className="error-text">{props?.errors.subRegion}</p>
                ) : (
                  ""
                )}
              </div>
            </div>
          )}

          <div class="rdr-item d-flex fle x-nowrap mb-4" style={{ marginTop: "30px" }}>
            <div class="rdr-label-box align-self-start">Select Category</div>
            <div class="rdr-form-box  w-350">
              <Select
                menuPosition="fixed"
                menuShouldScrollIntoView
                className="basic-single  "
                classNamePrefix="select-search  "
                placeholder={`Please Select Category`}
                value={props?.categorySelect}
                styles={customStyles}
                options={[{ name: "Please Select Category", _id: "" }, ...props?.category]}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option._id}
                onChange={(e) => {
                  props?.setCategorySelect(e);
                }}
                name={"animalCategory"}
              />
              {props?.errors && props?.errors.category ? <p className="error-text">{props?.errors.category}</p> : ""}
            </div>
          </div>
          <div class="rdr-item d-flex fle x-nowrap mb-4" style={{ marginTop: "30px" }}>
            <div class="rdr-label-box align-self-start">Select Animal</div>
            <div class="rdr-form-box  w-350">
              <AsyncSelect
                cacheOptions
                defaultOptions={props?.species}
                value={props?.loading ? { name: "Loading...", value: "" } : props?.speciesSelect}
                className="basic-single"
                classNamePrefix="select-search"
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option._id}
                onChange={(e) => {
                  props?.setSpeciesSelect(e);
                  props?.setImage(e?.coverImage?.medium);
                }}
                loadOptions={props.fetchSpecies}
                isDisabled={props.loading ? true : false}
                name="businessUnits"
                placeholder="Please Select Animal"
                styles={customStyles}
                menuPosition="fixed"
                menuShouldScrollIntoView
              />
              {props?.errors && props?.errors.animal ? <p className="error-text">{props?.errors.animal}</p> : ""}
            </div>
          </div>
          {props?.image && (
            <div className="animal-img">
              <img src={`${process.env.REACT_APP_EI_MEDIA}${props?.image}`} alt="" />
            </div>
          )}
        </Modal.Body>
        <div class="custom-modal-dividor"></div>
        <div className="custom-modal-footer d-grid gap-2 d-md-flex align-items-center justify-content-md-end">
          <button
            class="btn btn-link me-md-2 global-cancel-button"
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => props.onHide()}
          >
            Cancel
          </button>
          <NextButton
            label="Save"
            classData="btn btn-default"
            handleSubmit={props.handleSave}
            disable={props?.loadingMain}
            // loading={props.loadingCategory}
          />
        </div>
      </div>
    </Modal>
  );
};

export default React.memo(AnimalModal);
