import React, { useState } from "react";
import close from "../../include/images/close-icon.svg";

import "react-bootstrap";
import "bootstrap";
import emptyFolder from "../../include/images/folder.svg";
import moment from "moment/moment";
import Skeleton from "react-loading-skeleton";
import DateFormat from "../../common/DateFormat/dateFormat";
import { connect } from "react-redux";
import {
  getProfileOptions,
  getUser,
  getVerificationDoc,
} from "../../store/users";
import PipeLogFilterModal from "./pipeLogFilterModal/pipeLogFilterModal";
import Sidebar from "../../layouts/sidebar";
const UserTable = (props) => {
  const totalPage = props.filteredCount
    ? Math.ceil(props.filteredCount / 50)
    : 0;

  //For SideNav
  const [showMenu, setShowMenu] = useState(false);
  const [menuList, setMenuList] = useState("");

  return (
    <>
      <div class="dashboard-insider position-relative d-lg-flex w-100 ">
        <Sidebar
          showMenu={showMenu}
          setShowMenu={setShowMenu}
          menuList={menuList}
          setMenuList={setMenuList}
          page="reports"
          pageCat="pipe-report"
        />
        <div
          className={showMenu ? ` main-menu-expanded ` : "main-menu-compressed"}
        >
          <div class="filter-container d-flex align-items-center sticky-top">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12 d-flex align-items-center flex-wrap justify-content-between">
                  <div class="filter-title fw-medium text-capitalize">
                    Pipe Access Report
                  </div>
                  <div class="filter-widget-right d-flex align-items-center flex-wrap">
                    <div class="fw-item">
                      {props.loading ? (
                        <Skeleton width="50px" height="30px" />
                      ) : (
                        <div
                          class="filter-row d-flex align-items-center"
                          onClick={() => props.toggleFilter()}
                        >
                          <svg
                            viewBox="0 0 16 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2.39767 16.5C2.07751 16.5 1.81796 16.2357 1.81796 15.9097V1.09032C1.81796 0.764295 2.07751 0.5 2.39767 0.5C2.71784 0.5 2.97738 0.764295 2.97738 1.09032V15.9097C2.97738 16.2357 2.71784 16.5 2.39767 16.5Z"
                              fill="currentColor"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M13.0226 15.9097C13.0226 16.2357 13.2822 16.5 13.6023 16.5C13.9225 16.5 14.182 16.2357 14.182 15.9097V1.09032C14.182 0.764295 13.9225 0.5 13.6023 0.5C13.2822 0.5 13.0226 0.764295 13.0226 1.09032V15.9097ZM0.57971 13.18H4.21565C4.53582 13.18 4.79536 12.9158 4.79536 12.5897C4.79536 12.2637 4.53582 11.9994 4.21565 11.9994H0.57971C0.259545 11.9994 0 12.2637 0 12.5897C0 12.9158 0.259545 13.18 0.57971 13.18Z"
                              fill="currentColor"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M7.42029 15.9097C7.42029 16.2357 7.67983 16.5 8 16.5C8.32016 16.5 8.57971 16.2357 8.57971 15.9097V1.09032C8.57971 0.764295 8.32016 0.5 8 0.5C7.67983 0.5 7.42029 0.764295 7.42029 1.09032V15.9097ZM11.7843 13.18H15.4203C15.7405 13.18 16 12.9158 16 12.5897C16 12.2637 15.7405 11.9994 15.4203 11.9994H11.7843C11.4642 11.9994 11.2046 12.2637 11.2046 12.5897C11.2046 12.9158 11.4642 13.18 11.7843 13.18Z"
                              fill="currentColor"
                            />
                            <path
                              d="M9.81798 5.00058H6.18204C5.86187 5.00058 5.60233 4.73629 5.60233 4.41027C5.60233 4.08424 5.86187 3.81995 6.18204 3.81995H9.81798C10.1381 3.81995 10.3977 4.08424 10.3977 4.41027C10.3977 4.73629 10.1381 5.00058 9.81798 5.00058Z"
                              fill="currentColor"
                            />
                          </svg>
                          <span>Filters</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="table-container">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12">
                  <div class="tb-filter-row d-flex align-items-start justify-content-between">
                    <div class="filter-tags d-flex align-items-start">
                      {!props.loading &&
                        ((props.auctionId && props.selectedFilter.auctionId) ||
                          (props.userId && props.selectedFilter.userId)) && (
                          <>
                            <div class="filter-tag-title">
                              Filters Applied :
                            </div>

                            <div class="tags">
                              {props.auctionId &&
                                props.selectedFilter?.auctionId && (
                                  <span class="badge">
                                    Auction Id :{" "}
                                    <span>
                                      {props.selectedFilter &&
                                        props.selectedFilter.auctionId}
                                    </span>
                                    <img
                                      class="tag-close-icon"
                                      src={close}
                                      alt=""
                                      onClick={() => {
                                        const data = {
                                          ...props.selectedFilter,
                                        };
                                        data.auctionId = "";
                                        props.setSelectedFilter(data);
                                        props.setAuctionId("");
                                        props.handleFilter(true, true, false);
                                      }}
                                    />
                                  </span>
                                )}
                              {props.userId && props.selectedFilter?.userId && (
                                <span class="badge">
                                  User Id :{" "}
                                  <span>
                                    {props.selectedFilter &&
                                      props.selectedFilter.userId}
                                  </span>
                                  <img
                                    class="tag-close-icon"
                                    src={close}
                                    alt=""
                                    onClick={() => {
                                      const data = { ...props.selectedFilter };
                                      data.userId = "";
                                      props.setSelectedFilter(data);
                                      props.setUserId("");
                                      props.handleFilter(true, false, true);
                                    }}
                                  />
                                </span>
                              )}
                            </div>
                          </>
                        )}{" "}
                      {props.loading && (
                        <div class="filter-tags d-flex align-items-start">
                          <div class="filter-tag-title">
                            <div class="tags d-flex align-items-start">
                              <span class="badge">
                                <Skeleton />
                              </span>
                              <span class="badge">
                                <Skeleton />
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div class="tb-filter-right d-flex align-items-center justify-content-end">
                      <div class="showing-result-text">
                        {!props.loading && props.filteredCount < 50 ? (
                          `Showing ${props.filteredCount} of ${props.filteredCount} total results`
                        ) : !props.loading && props.page == totalPage ? (
                          `Showing ${props.filteredCount} of ${props.filteredCount} total results`
                        ) : !props.loading ? (
                          `Showing ${50 * props.page} of ${
                            props.filteredCount
                          } total results`
                        ) : (
                          <Skeleton width="300px" height="25px" />
                        )}
                      </div>
                    </div>
                  </div>
                  <div class="selected-table-row d-flex justify-content-between"></div>
                  <div
                    class={`table-responsive position-relative ${
                      props.userData && props.userData.length > 0
                        ? "min-user-table-height"
                        : ""
                    }`}
                  >
                    <div class="table-selected-count d-none align-items-center justify-content-between">
                      <div class="tsc-check-label d-flex align-items-center">
                        <div class="tsc-checkbox">
                          <div class="form-check tick-checkbox">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="checkAll"
                            />
                            <label
                              class="form-check-label"
                              for="checkAll"
                            ></label>
                          </div>
                        </div>
                        <div class="link-label fw-medium">2 Selected</div>
                        <div class="link-clear fw-medium">Clear all</div>
                      </div>
                    </div>
                    <table class="table align-middle table-collapse-table">
                      <thead>
                        <tr>
                          {/* <th style={{ width: "auto" }}></th> */}

                          <th style={{ width: "15%" }}>
                            <a href="javascript:void(0)" class="sort-by">
                              Auction ID
                            </a>
                          </th>
                          <th style={{ width: "35%" }}>
                            <a href="javascript:void(0)" class="sort-by">
                              Title
                            </a>
                          </th>
                          <th style={{ width: "20%" }}>
                            <a href="javascript:void(0)" class="sort-by">
                              Action
                            </a>
                          </th>
                          {/* <th style={{ width: "12.5%" }}>
                        <a href="javascript:void(0)" class="sort-by">
                          Date
                        </a>
                      </th> */}
                          <th style={{ width: "10%" }}>
                            <a href="javascript:void(0)" class="sort-by">
                              User Id
                            </a>
                          </th>
                          <th style={{ width: "20%" }}>
                            <a href="javascript:void(0)" class="sort-by">
                              User Email
                            </a>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {props.loading || props.loadingFilter
                          ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2, 3, 4].map(
                              (item) => (
                                <tr>
                                  <td colspan="8">
                                    <table class="table2">
                                      <tr>
                                        <td style={{ width: "15%" }}>
                                          <Skeleton />
                                        </td>
                                        <td style={{ width: "25%" }}>
                                          <Skeleton />
                                        </td>
                                        <td style={{ width: "17.5%" }}>
                                          <Skeleton />
                                        </td>
                                        <td style={{ width: "12.5%" }}>
                                          <Skeleton />
                                        </td>
                                        <td style={{ width: "10%" }}>
                                          <Skeleton />
                                        </td>
                                        <td style={{ width: "20%" }}>
                                          <Skeleton />
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                </tr>
                              )
                            )
                          : props.userData &&
                            props.userData.length > 0 &&
                            props.userData.map((item) => {
                              return (
                                <tr>
                                  <td colspan="8">
                                    <table class="table2">
                                      <tr>
                                        <td style={{ width: "15%" }}>
                                          <div class="td-action-btn-group d-flex align-items-center justify-content-between">
                                            <span>
                                              {item.auction_id
                                                ? item.auction_id
                                                : "N/A"}
                                            </span>
                                          </div>
                                        </td>
                                        <td
                                          style={{ width: "35%" }}
                                          className="word-break"
                                        >
                                          <span>
                                            {item.title ? item.title : "N/A"}
                                          </span>
                                        </td>
                                        <td
                                          style={{ width: "20%" }}
                                          className="word-break"
                                        >
                                          <span>
                                            {item.action ? item.action : "N/A"}
                                          </span>
                                        </td>
                                        {/* <td style={{ width: "12.5%" }}>
                                      <span>Date</span>
                                    </td> */}
                                        <td style={{ width: "10%" }}>
                                          <span>
                                            {item.user_id
                                              ? item.user_id
                                              : "N/A"}
                                          </span>
                                        </td>

                                        <td style={{ width: "20%" }}>
                                          <span>
                                            {item.user_email
                                              ? item.user_email
                                              : "N/A"}
                                          </span>
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                </tr>
                              );
                            })}
                      </tbody>
                    </table>
                  </div>
                  {!props.loading &&
                    !props.loadingFilter &&
                    props.userData &&
                    props.userData.length === 0 && (
                      <>
                        <div class="table-empty">
                          <div class="table-empty-inner">
                            <img src={emptyFolder} alt="" />
                            <p>No Items Found</p>
                          </div>
                        </div>
                      </>
                    )}

                  <div class="table-pagination">
                    <ul class="pagination  justify-content-end">
                      <li class="page-item">
                        <a
                          class={`page-link ${
                            props.page === 1 ? "disabled" : ""
                          }`}
                          href="#"
                          aria-label="Previous"
                          onClick={() => {
                            if (props.page !== 1) {
                              props.setPage(props.page - 1);
                            }
                          }}
                        >
                          <svg
                            width="8"
                            height="14"
                            viewBox="0 0 8 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7 13L1 7L7 1"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </a>
                      </li>
                      <li class="page-item active">
                        <a class="page-link" href="javascript:void(0)">
                          {props.page}
                        </a>
                      </li>
                      {totalPage > 1 && props.page + 1 <= totalPage && (
                        <li class="page-item">
                          <a
                            class="page-link"
                            href="#"
                            onClick={() => props.setPage(props.page + 1)}
                          >
                            {props.page + 1}
                          </a>
                        </li>
                      )}
                      <li class="page-item">
                        <a
                          class={`page-link ${
                            props.page + 1 >= totalPage ? "disabled" : ""
                          }`}
                          href="#"
                          aria-label="Next"
                          onClick={() => props.setPage(props.page + 1)}
                        >
                          <svg
                            width="8"
                            height="14"
                            viewBox="0 0 8 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1 13L7 7L1 1"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <PipeLogFilterModal
                show={props.showFilter}
                onHide={props.toggleFilter}
                handleFilter={props.handleFilter}
                loadingFilter={props.loadingFilter}
                setAuctionId={props.setAuctionId}
                auctionId={props.auctionId}
                userId={props.userId}
                setUserId={props.setUserId}
                selectedFilter={props.selectedFilter}
                setSelectedFilter={props.setSelectedFilter}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getVerificationDoc: (params, callback) =>
    dispatch(getVerificationDoc(params, callback)),
  getProfileOptions: (params, callback) =>
    dispatch(getProfileOptions(params, callback)),
});
const mapStateToProps = (state) => ({
  getUser: getUser(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(UserTable));
