import React from 'react'
import { Modal } from 'react-bootstrap';
import Editor from '../../../../common/editor/editor';
import NextButton from '../../../../common/form/nextButton';
import close from "../../../../include/images/close.svg";

const MotivationModal = (props) => {
  return (
    <Modal
      className="modal fade custom-modal"
      id="addTaskModal"
      show={props.show}
      onHide={() => props.onHide()}
      dialogClassName="modal-dialog modal-dialog-centered modal-xl"
    >
      <div class="modal-content h-100 d-flex flex-column">
        <div class="custom-modal-header d-flex align-items-center justify-content-between">
          <h5 class="mb-0 text-capitalize">Seller Motivaiton</h5>
          <button
            type="button"
            class="close-btn d-flex align-items-center justify-content-center"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => props.onHide()}
          >
            <img src={close} alt="" />
          </button>
        </div>
        <Modal.Body className="custom-modal-body flex-grow-1 hide-scrollbar padding-bottom-0">
                <div className="w-100 p-3">
                  <Editor
                    name="task"
                    className="ck-content"
                    onChange={(e) => {
                      let data ={...props.data};
                      data.motivation = e;
                      props.setData(data);
                    }}
                    editorLoaded={true}
                    value={props.data.motivation}
                  />
                </div>
        </Modal.Body>
        <div class="custom-modal-dividor"></div>
        <div className="custom-modal-footer d-grid gap-2 d-md-flex align-items-center justify-content-md-end">
          <button
            class="btn btn-link me-md-2 global-cancel-button"
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => props.onHide()}
          >
            Cancel
          </button>
          <NextButton
            label="Save"
            classData="btn btn-default"
            handleSubmit={props.handleSubmit}
            loading={props.loading}
          />
        </div>
      </div>
    </Modal>
  )
}

export default MotivationModal