import React from "react";
import Select from "react-select";
import { customStyles } from "../../../../common/customStyles/reactSelectStyle";

const BuyerInterestModal = () => {
  return (
    <tr>
      <td colSpan={18}>
        <div class="table-responsive">
          <table class="large-table align-left">
            <thead>
              <tr>
                <th></th>
                <th>Product Listing Information</th>
                <th>Sales Opportunity</th>
                <th>Buyer Interest Status</th>
                <th>Buyer Interest Notes</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {[1, 2].map((val, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td stlye={{ width: "120px" }}>
                    <div>
                      <a href="javascript:void(0)" className="green-btn-text">
                        Cummins Generator()*&^%!@#$%
                      </a>
                      <div className="mt-3">
                        <button className="btn btn-primary btn-xs-size btn-auto">Seller Motivaiton</button>
                      </div>
                      <div className="mt-3">
                        <div>
                          <span className="fw-medium">Public Status: </span>
                          <span>Active</span>
                        </div>
                        <div>
                          <span className="fw-medium">Product Location: </span>
                          <span>International India</span>
                        </div>
                      </div>
                      <div className="mt-3">
                        <div class="page-widget-label text-capitalize">
                          <u>Listing Pricing</u>
                        </div>
                        <div className="mt-1">
                          <div>
                            <span className="fw-medium">Highest Buyer Bid: </span>
                            <span>$0.00</span>
                          </div>
                          <div>
                            <span className="fw-medium">Public Asking Price: </span>
                            <span>$0.00</span>
                          </div>
                          <div>
                            <span className="fw-medium">Seller Asking Price: </span>
                            <span>$0.00</span>
                          </div>
                          <div>
                            <span className="fw-medium">Recycle Value: </span>
                            <span>$0.00</span>
                          </div>
                          <div>
                            <span className="fw-medium">Retail Value: </span>
                            <span>$0.00</span>
                          </div>
                          <div>
                            <span className="fw-medium">Original Wholesale Value: </span>
                            <span>$0.00</span>
                          </div>
                          <div>
                            <span className="fw-medium">Claim Value: </span>
                            <span>$0.00</span>
                          </div>
                          <div>
                            <span className="fw-medium">Estimated Scrap Price: </span>
                            <span>$0.00</span>
                          </div>
                        </div>
                      </div>
                      <div className="mt-3">
                        <div class="page-widget-label text-capitalize">
                          <u>Strategic Team</u>
                        </div>
                        <div className="mt-1">
                          <div>
                            <span className="fw-medium">Created By: </span>
                            <span>Super Admin</span>
                          </div>
                          <div>
                            <span className="fw-medium">Method Origin: </span>
                            <span>Super Admin</span>
                          </div>
                          <div>
                            <span className="fw-medium">Trader: </span>
                            <span>Super Admin</span>
                          </div>
                          <div>
                            <span className="fw-medium">Administrator 1: </span>
                            <span>Super Admin</span>
                          </div>
                          <div>
                            <span className="fw-medium">Administrator 2: </span>
                            <span>Super Admin</span>
                          </div>
                          <div>
                            <span className="fw-medium">Market Developer: </span>
                            <span>Super Admin</span>
                          </div>
                          <div>
                            <span className="fw-medium">Trader Manager: </span>
                            <span>Super Admin</span>
                          </div>
                          <div>
                            <span className="fw-medium">BD Manager: </span>
                            <span>Super Admin</span>
                          </div>
                        </div>
                        <div className="mt-2">
                          <button className="btn btn-primary btn-xs-size btn-auto">Copy Product Info</button>
                        </div>
                        <div className="mt-1">
                          <button className="btn btn-primary btn-xs-size btn-auto">Copy Buyer Details</button>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td style={{ width: "130px" }}>
                    <div>
                      <div>
                        <span className="fw-medium">Quick Sales Timeline: </span>
                        <span>Immediately, No Timeline</span>
                      </div>
                      <div>
                        <span className="fw-medium">Exclusive Listing: </span>
                        <span>NO</span>
                      </div>
                      <div>
                        <span className="fw-medium">Low Pricing On Listing: </span>
                        <span>
                          Seller Asking Price Percentage <span className="fw-medium">30%</span>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      <div>
                        <span className="fw-medium">Created By: </span>
                        <span>Super Admin</span>
                      </div>
                      <div>
                        <span className="fw-medium">Method Origin: </span>
                        <span>Super Admin</span>
                      </div>
                      <div>
                        <span className="fw-medium">Trader: </span>
                        <span>Super Admin</span>
                      </div>
                      <div>
                        <span className="fw-medium">Administrator 1: </span>
                        <span>Super Admin</span>
                      </div>
                      <div>
                        <span className="fw-medium">Administrator 2: </span>
                        <span>Super Admin</span>
                      </div>
                      <div className="mt-2">
                        <label htmlFor="budget">Buyer Budget($):</label>
                        <input type="text" className="form-control" />
                        <div className="mt-1">
                          <button className="btn btn-primary btn-xs-size btn-auto">Save</button>
                        </div>
                      </div>
                      <div className="mt-2">
                        <label htmlFor="budget">Bid Status:</label>
                        <Select styles={customStyles} />
                        <div className="mt-1">
                          <button className="btn btn-primary btn-xs-size btn-auto">Save</button>
                        </div>
                      </div>
                      <div className="mt-2">
                        <label htmlFor="budget">Max Bid Status:</label>
                        <Select styles={customStyles} />
                        <div className="mt-1">
                          <button className="btn btn-primary btn-xs-size btn-auto">Save</button>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      <div>
                        <label htmlFor="budget">Create Task:</label>
                        <Select styles={customStyles} />
                      </div>
                      <div>
                        <label htmlFor="budget">Additional Recipients:</label>
                        <Select styles={customStyles} />
                      </div>
                      <div className="mt-2">
                        <label htmlFor="budget">Note:</label>
                        <textarea className="form-control" />
                        <div className="mt-1">
                          <button className="btn btn-primary btn-xs-size btn-auto">Post Note</button>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      <button className="btn btn-primary btn-xs-size btn-auto">Copy Product Info</button>
                    </div>
                    <div className="mt-1">
                      <button className="btn btn-primary btn-xs-size btn-auto">Copy Buyer Details</button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </td>
    </tr>
  );
};

export default BuyerInterestModal;
