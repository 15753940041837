import React, { useEffect, useState } from "react";
import emptyFolder from "../../../include/images/folder.svg";
import close from "../../../include/images/close-icon.svg";
import moment from "moment/moment";
import Skeleton from "react-loading-skeleton";
import Sidebar from "../../../layouts/sidebar";
import EmailTypeActivity from "./emailTypeActivity";
import { capitalizeFirstLetter, numberFormat } from "../../../utils.js/conversion";
import Chart from "chart.js/auto";
import { Chart as ChartJs, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from "chart.js";
import { Line } from "react-chartjs-2";
ChartJs.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);
Chart.register(CategoryScale);

const BARRTable = (props) => {
  const totalPage = props.filteredCount ? Math.ceil(props.filteredCount / 50) : 0;

  //For SideNav
  const [showMenu, setShowMenu] = useState(false);
  const [menuList, setMenuList] = useState("");
  const [isHovered, setIsHovered] = useState(false);

  const calculatePrecentage = (a) => {
    if (a > 0 && props?.emailActivity?.requests > 0) {
      return Math.round((a * 100) / props?.emailActivity?.requests);
    } else {
      return 0;
    }
  };

  return (
    <>
      <div class="dashboard-insider position-relative d-lg-flex w-100 ">
        <Sidebar
          showMenu={showMenu}
          setShowMenu={setShowMenu}
          menuList={menuList}
          setMenuList={setMenuList}
          page="reports"
          pageCat="barr-email-stats"
        />
        <div className={showMenu ? ` main-menu-expanded ` : "main-menu-compressed"}>
          <div class="filter-container d-flex align-items-center sticky-top">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12 d-flex align-items-center flex-wrap justify-content-between">
                  <div class="filter-title fw-medium text-capitalize">Buyer ARR Email Statistics</div>
                  <div class="filter-widget-right d-flex align-items-center flex-wrap">
                    <div class="fw-item">
                      <div className="d-flex ">
                        <div class="filter-row d-flex align-items-center me-4" onClick={() => props.toggleFilters()}>
                          <svg viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M2.39767 16.5C2.07751 16.5 1.81796 16.2357 1.81796 15.9097V1.09032C1.81796 0.764295 2.07751 0.5 2.39767 0.5C2.71784 0.5 2.97738 0.764295 2.97738 1.09032V15.9097C2.97738 16.2357 2.71784 16.5 2.39767 16.5Z"
                              fill="currentColor"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M13.0226 15.9097C13.0226 16.2357 13.2822 16.5 13.6023 16.5C13.9225 16.5 14.182 16.2357 14.182 15.9097V1.09032C14.182 0.764295 13.9225 0.5 13.6023 0.5C13.2822 0.5 13.0226 0.764295 13.0226 1.09032V15.9097ZM0.57971 13.18H4.21565C4.53582 13.18 4.79536 12.9158 4.79536 12.5897C4.79536 12.2637 4.53582 11.9994 4.21565 11.9994H0.57971C0.259545 11.9994 0 12.2637 0 12.5897C0 12.9158 0.259545 13.18 0.57971 13.18Z"
                              fill="currentColor"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M7.42029 15.9097C7.42029 16.2357 7.67983 16.5 8 16.5C8.32016 16.5 8.57971 16.2357 8.57971 15.9097V1.09032C8.57971 0.764295 8.32016 0.5 8 0.5C7.67983 0.5 7.42029 0.764295 7.42029 1.09032V15.9097ZM11.7843 13.18H15.4203C15.7405 13.18 16 12.9158 16 12.5897C16 12.2637 15.7405 11.9994 15.4203 11.9994H11.7843C11.4642 11.9994 11.2046 12.2637 11.2046 12.5897C11.2046 12.9158 11.4642 13.18 11.7843 13.18Z"
                              fill="currentColor"
                            />
                            <path
                              d="M9.81798 5.00058H6.18204C5.86187 5.00058 5.60233 4.73629 5.60233 4.41027C5.60233 4.08424 5.86187 3.81995 6.18204 3.81995H9.81798C10.1381 3.81995 10.3977 4.08424 10.3977 4.41027C10.3977 4.73629 10.1381 5.00058 9.81798 5.00058Z"
                              fill="currentColor"
                            />
                          </svg>
                          <span>Filters</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12 d-flex  space-between  barr_total-emails">
                <p>
                  {" "}
                  <span className="font-weight-600">
                    {" "}
                    {props?.emailActivity?.requests ? numberFormat(props?.emailActivity?.requests) : "0"}
                  </span>{" "}
                  Emails Sent
                </p>
              </div>
            </div>

            <div
              class=" d-flex align-items-center flex-wrap  flex-wrap barr_total-emails"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <EmailTypeActivity
                name="Delieverd"
                count={props?.emailActivity?.delivered}
                bgColor="#4388ae"
                percentage={calculatePrecentage(props?.emailActivity?.delivered)}
                isHovered={isHovered}
              />
              <EmailTypeActivity
                name="Estimated openers"
                count={props?.emailActivity?.opens}
                bgColor="#3499db"
                percentage={calculatePrecentage(props?.emailActivity?.opens)}
                isHovered={isHovered}
              />
              <EmailTypeActivity
                name="Unique clickers"
                count={props?.emailActivity?.clicks}
                bgColor="#9cc980"
                percentage={calculatePrecentage(props?.emailActivity?.clicks)}
                isHovered={isHovered}
              />
              <EmailTypeActivity
                name="Unique Opens"
                count={props?.emailActivity?.uniqueOpens}
                bgColor="#9cc980"
                percentage={calculatePrecentage(props?.emailActivity?.uniqueOpens)}
                isHovered={isHovered}
              />
              <EmailTypeActivity
                name="Bounced"
                count={props?.emailActivity?.hardBounces + props?.emailActivity?.softBounces}
                bgColor="#e6735c"
                percentage={calculatePrecentage(props?.emailActivity?.hardBounces + props?.emailActivity?.softBounces)}
                isHovered={isHovered}
              />
              <EmailTypeActivity
                name="Error"
                count={props?.emailActivity?.error}
                bgColor="#e6735c"
                percentage={calculatePrecentage(props?.emailActivity?.error)}
                isHovered={isHovered}
              />
              <EmailTypeActivity
                name="Spam Reports"
                count={props?.emailActivity?.spamReports}
                bgColor="#687484"
                percentage={calculatePrecentage(props?.emailActivity?.spamReports)}
                isHovered={isHovered}
              />
              <EmailTypeActivity
                name="Hard Bounced"
                count={props?.emailActivity?.hardBounces}
                bgColor="#e6735c"
                percentage={calculatePrecentage(props?.emailActivity?.hardBounces)}
                isHovered={isHovered}
              />
              <EmailTypeActivity
                name="Soft Bounced"
                count={props?.emailActivity?.softBounces}
                bgColor="#e6735c"
                percentage={calculatePrecentage(props?.emailActivity?.softBounces)}
                isHovered={isHovered}
              />
              <EmailTypeActivity
                name="Blocked"
                count={props?.emailActivity?.blocked}
                bgColor="#687484"
                percentage={calculatePrecentage(props?.emailActivity?.blocked)}
                isHovered={isHovered}
              />
              <EmailTypeActivity
                name="Unsubscribed"
                count={props?.emailActivity?.unsubscribed}
                bgColor="#687484"
                percentage={calculatePrecentage(props?.emailActivity?.unsubscribed)}
                isHovered={isHovered}
              />
            </div>
            <div class="container-fluid" style={{ marginBottom: "25px" }}>
              <div class="row">
                <div class="col-md-12 d-flex  space-between  barr_total-emails">
                  <Line
                    // style={{ height: "400px", width: "100%" }}
                    data={{
                      labels: props?.graphData?.map((qw) => qw?.date),
                      datasets: [
                        {
                          label: "Sent",
                          data: props?.graphData?.map((qw) => qw?.sent),
                          fill: false,
                          borderColor: "#687484",
                          // tension: 0.5,
                          backgroundColor: [
                            "rgba(255, 255, 255, 0.6)",
                            "rgba(255, 255, 255, 0.6)",
                            "rgba(255, 255, 255, 0.6)",
                          ],
                          borderWidth: 3,
                        },
                        {
                          label: "Delivered",
                          data: props?.graphData?.map((qw) => qw?.delivered),
                          fill: false,
                          borderColor: "#4388ae",
                          // tension: 0.5,
                          backgroundColor: [
                            "rgba(255, 255, 255, 0.6)",
                            "rgba(255, 255, 255, 0.6)",
                            "rgba(255, 255, 255, 0.6)",
                          ],
                          borderWidth: 3,
                        },
                        {
                          label: "Estimated Opens",
                          data: props?.graphData?.map((qw) => qw?.estimatedOpens),
                          fill: false,
                          borderColor: "#3499db",
                          // tension: 0.5,
                          backgroundColor: [
                            "rgba(255, 255, 255, 0.6)",
                            "rgba(255, 255, 255, 0.6)",
                            "rgba(255, 255, 255, 0.6)",
                          ],
                          borderWidth: 3,
                        },
                        {
                          label: "Unique Opens",
                          data: props?.graphData?.map((qw) => qw?.uniqueOpens),
                          fill: false,
                          borderColor: "#9cc980",
                          // tension: 0.5,
                          backgroundColor: [
                            "rgba(255, 255, 255, 0.6)",
                            "rgba(255, 255, 255, 0.6)",
                            "rgba(255, 255, 255, 0.6)",
                          ],
                          borderWidth: 3,
                        },
                        {
                          label: "Unique Clicks",
                          data: props?.graphData?.map((qw) => qw?.uniqueClicks),
                          fill: false,
                          borderColor: "blue",
                          // tension: 0.5,
                          backgroundColor: [
                            "rgba(255, 255, 255, 0.6)",
                            "rgba(255, 255, 255, 0.6)",
                            "rgba(255, 255, 255, 0.6)",
                          ],
                          borderWidth: 3,
                        },
                        {
                          label: "Bounced",
                          data: props?.graphData?.map((qw) => qw?.bounced),
                          fill: false,
                          borderColor: "#e6735c",
                          // tension: 0.5,
                          backgroundColor: [
                            "rgba(255, 255, 255, 0.6)",
                            "rgba(255, 255, 255, 0.6)",
                            "rgba(255, 255, 255, 0.6)",
                          ],
                          borderWidth: 3,
                        },
                      ],
                      options: {
                        scales: {
                          yAxes: [
                            {
                              fontColor: "black",
                              beginAtZero: false,
                            },
                          ],
                          xAxes: [
                            {
                              ticks: {
                                fontColor: "black",
                              },
                            },
                          ],
                        },
                      },
                    }}
                  />
                </div>
              </div>
            </div>

            <div class="table-container" style={{ paddingTop: "0px" }}>
              <div class="container-fluid" style={{ paddingLeft: "0px", width: "100%" }}>
                <div class="row">
                  <div class="col-md-12">
                    <div class="tb-filter-row d-flex align-items-start justify-content-between">
                      <div class="filter-tags d-flex align-items-start">
                        <div class="filter-tag-title">Filters Applied :</div>

                        <div class="tags">
                          {props.filterValues?.search && (
                            <span class="badge">
                              Email : <span>{props?.filterValues?.search}</span>
                              <img
                                class="tag-close-icon"
                                src={close}
                                alt=""
                                onClick={() => {
                                  const data = { ...props.filterValues };
                                  data.search = "";
                                  props.setFilterValues(data);
                                }}
                              />
                            </span>
                          )}

                          <span class="badge">
                            Date Range :{" "}
                            <span>
                              {props?.filterValues?.dateRange?.name !== "Custom Date"
                                ? props?.filterValues?.dateRange?.name
                                : `Date Range - ${props?.filterValues?.dateRangeValue?.value?.from} - ${props?.filterValues?.dateRangeValue?.value?.to}`}
                            </span>
                            <img class="tag-close-icon" src={close} alt="" onClick={() => props?.toggleFilters()} />
                          </span>
                        </div>
                      </div>
                      <div class="tb-filter-right d-flex align-items-center justify-content-end">
                        <div class="showing-result-text">Showing {props?.sentEmailData?.length} total results</div>
                      </div>
                    </div>
                    <div class="selected-table-row d-flex justify-content-between"></div>
                    <div class={`table-responsive position-relative`}>
                      <div class="table-selected-count d-none align-items-center justify-content-between">
                        <div class="tsc-check-label d-flex align-items-center">
                          <div class="tsc-checkbox">
                            <div class="form-check tick-checkbox">
                              <input class="form-check-input" type="checkbox" value="" id="checkAll" />
                              <label class="form-check-label" for="checkAll"></label>
                            </div>
                          </div>
                          <div class="link-label fw-medium">2 Selected</div>
                          <div class="link-clear fw-medium">Clear all</div>
                        </div>
                      </div>
                      <table class="table align-middle table-collapse-table barr_table-hover">
                        <thead>
                          <tr>
                            <th style={{ width: "25%" }}>Email</th>
                            <th style={{ width: "25%" }}>Action</th>
                            <th style={{ width: "25%" }}>Clicked Link</th>
                            <th style={{ width: "25%" }}>Date Sent</th>
                          </tr>
                        </thead>
                        <tbody>
                          {props.loading
                            ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2, 3, 4].map((item) => (
                                <tr>
                                  <td colspan="4">
                                    <table class="table2">
                                      <tr>
                                        <td style={{ width: "25%" }}>
                                          <Skeleton />
                                        </td>{" "}
                                        <td style={{ width: "25%" }}>
                                          <Skeleton />
                                        </td>{" "}
                                        <td style={{ width: "25%" }}>
                                          <Skeleton />
                                        </td>{" "}
                                        <td style={{ width: "25%" }}>
                                          <Skeleton />
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                </tr>
                              ))
                            : props.sentEmailData &&
                              props.sentEmailData?.length > 0 &&
                              props.sentEmailData?.map((item) => {
                                return (
                                  <tr>
                                    <td colspan="8">
                                      <table class="table2">
                                        <tr
                                          onClick={(e) => {
                                            props?.getSellerARRLogs(item.email);
                                          }}
                                        >
                                          <td style={{ width: "25%" }}>
                                            <div class="td-action-btn-group d-flex align-items-center justify-content-between">
                                              <span>{item.email ? item.email : "N/A"}</span>
                                            </div>
                                          </td>
                                          <td style={{ width: "25%" }} className="word-break">
                                            <span>
                                              {item?.event
                                                ? item?.event === "clicks"
                                                  ? "Clicked"
                                                  : capitalizeFirstLetter(item?.event)
                                                : ""}
                                            </span>
                                          </td>
                                          <td style={{ width: "25%" }} className="word-break">
                                            <span>
                                              {" "}
                                              {item?.link ? (
                                                <a
                                                  className="link-item"
                                                  target="_blank"
                                                  href={item?.link}
                                                  onClick={(e) => e.stopPropagation()}
                                                >
                                                  Link
                                                </a>
                                              ) : (
                                                ""
                                              )}
                                            </span>
                                          </td>

                                          <td style={{ width: "25%" }}>
                                            <span>
                                              {item?.date
                                                ? moment?.(item?.date?.split("T")?.[0]).format("MM-DD-YYYY")
                                                : ""}
                                            </span>
                                          </td>
                                        </tr>
                                      </table>
                                    </td>
                                  </tr>
                                );
                              })}
                        </tbody>
                      </table>
                    </div>
                    {!props?.loading && props?.sentEmailData?.length === 0 ? (
                      <>
                        <div class="table-empty">
                          <div class="table-empty-inner">
                            <img src={emptyFolder} alt="" />
                            <p>No Items Found</p>
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(BARRTable);
