import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Editor from "../../common/editor/editor";
import parse from "html-react-parser";
import NextButton from "../../common/form/nextButton";
import close from "../../include/images/close.svg";
const ProductDescriptionModal = (props) => {
  useEffect(() => {
    props.setEditorLoaded(true);
  }, []);
  return (
    <Modal
      className="modal fade custom-modal"
      id="editProductDescription"
      dialogClassName="modal-dialog modal-dialog-centered modal-xxl"
      show={props.show}
      onHide={() => props.onHide()}
      enforceFocus={false}
    >
      <div class="modal-content d-flex flex-column">
        <div class="custom-modal-header d-flex align-items-center justify-content-between">
          <h5 class="mb-0 text-capitalize">Product Description</h5>
          <button
            type="button"
            class="close-btn d-flex align-items-center justify-content-center"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <img src={close} alt="" onClick={() => props.onHide()} />
          </button>
        </div>
        <Modal.Body className="custom-modal-body flex-grow-1 w-100 overflow-hidden">
          <div class="full-width-textarea-row d-flex ">
            <div class="fwtr-label-box pt-2">Description</div>
            <div class="fwtr-input-box flex-grow-1">
              <Editor
                name="description"
                className="ck-content"
                onChange={(data) => {
                  props.setEditorData(data);
                }}
                editorLoaded={props.editorLoaded}
                value={props.initialData}
              />
            </div>
          </div>
        </Modal.Body>
        <div class="custom-modal-dividor"></div>
        <div class="custom-modal-footer d-grid gap-2 d-md-flex align-items-center justify-content-md-end">
          <button
            class="btn btn-link me-md-2 global-cancel-button"
            type="button"
            onClick={() => props.onHide()}
          >
            Cancel
          </button>
          <NextButton
            label="Save"
            classData="btn btn-default"
            handleSubmit={props.handleSubmit}
            loading={props.loadingDescription}
          />
        </div>
      </div>
    </Modal>
  );
};

export default React.memo(ProductDescriptionModal);
