import React from "react";

const PaymentTypeFour = ({ setError4, error4, option4Data, setOption4Data, member, setSelectedOption }) => {
  return (
    <>
      <p>
        <strong>Check (U.S. Only)</strong>
      </p>

      <>
        <p class="fw-normal">
          Please confirm that goods will be released with an email copy of the check being mailed and the release of
          goods will NOT be delayed by your receipt of payment. <sup style={{ color: "red" }}>*</sup>
        </p>
        <div class="four-column-radio-group fw-medium mb-4">
          <div class="fcrg-item payment-info-main-option">
            <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
              <input
                class="form-check-input"
                type="radio"
                name="ABflexRadioDefault"
                id="ABflexRadio__1"
                checked={option4Data.checkOption == 1}
                onChange={() => {
                  const error = { ...error4 };
                  if (error.checkOption) {
                    error.checkOption = "";
                    setError4(error);
                  }
                  const data = { ...option4Data };
                  data.checkOption = 1;
                  setOption4Data(data);
                }}
              />
              <label class="form-check-label" for="ABflexRadio__1">
                Yes, I confirm that release of goods will not be delayed.
              </label>
            </div>
          </div>
          <div class="fcrg-item">
            <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
              <input
                class="form-check-input"
                type="radio"
                name="ABflexRadioDefault"
                id="ABflexRadio__2"
                onChange={() => {
                  const data = { ...option4Data };
                  data.checkOption = "";
                  setSelectedOption("");
                  setOption4Data(data);
                }}
              />
              <label class="form-check-label" for="ABflexRadio__2">
                No, I need payment in my account before release and need to choose a different payment option.
              </label>
            </div>
          </div>
          {error4.checkOption ? <p style={{ color: "red", marginLeft: "13px" }}>{error4.checkOption}</p> : ""}
        </div>
      </>

      {option4Data.checkOption == 1 && (
        <>
          <div class="form-group">
            <label className="form-label">
              Make check payable to <sup style={{ color: "red" }}>*</sup>
            </label>
            <input
              type="text"
              class="form-control"
              value={option4Data.check_payable}
              onChange={(e) => {
                const error = { ...error4 };
                if (error.check_payable) {
                  error.check_payable = "";
                  setError4(error);
                }
                const data = { ...option4Data };
                data.check_payable = e.target.value.trim();
                setOption4Data(data);
              }}
            />
            {error4.check_payable ? <p style={{ color: "red" }}>{error4.check_payable}</p> : ""}
          </div>
          <div class="form-group">
            <label className="form-label">Send check to the attention of</label>
            <input
              type="text"
              class="form-control"
              value={option4Data.check_attention}
              onChange={(e) => {
                const data = { ...option4Data };
                data.check_attention = e.target.value.trim();
                setOption4Data(data);
              }}
            />
          </div>
          <div class="form-group">
            <label className="form-label">
              Mailing address for the check <sup style={{ color: "red" }}>*</sup>
            </label>
            <input
              type="text"
              class="form-control"
              value={option4Data.check_address}
              onChange={(e) => {
                const error = { ...error4 };
                if (error.check_address) {
                  error.check_address = "";
                  setError4(error);
                }
                const data = { ...option4Data };
                data.check_address = e.target.value.trim();
                setOption4Data(data);
              }}
            />
            {error4.check_address ? <p style={{ color: "red" }}>{error4.check_address}</p> : ""}
          </div>

          <div class="form-group">
            <p className="form-label">
              <strong>
                What type of reference number do you prefer? <sup style={{ color: "red" }}>*</sup>
              </strong>
            </p>
            <div class="four-column-radio-group fw-medium">
              <div class="fcrg-item payment-info-main-option">
                <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="RflowRadioDefault"
                    id="RflowRadio3__1"
                    checked={option4Data.preferred_ref_no == "1"}
                    onChange={() => {
                      const error = { ...error4 };
                      if (error.preferred_ref_no) {
                        error.preferred_ref_no = "";
                        setError4(error);
                      }
                      const data = { ...option4Data };
                      data.preferred_ref_no = "1";
                      setOption4Data(data);
                    }}
                  />
                  <label class="form-check-label" for="RflowRadio3__1">
                    Claim or File Number
                  </label>
                </div>
              </div>
              <div class="fcrg-item payment-info-main-option">
                <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="RflowRadioDefault"
                    id="RflowRadio3__2"
                    checked={option4Data.preferred_ref_no == "2"}
                    onChange={() => {
                      const error = { ...error4 };
                      if (error.preferred_ref_no) {
                        error.preferred_ref_no = "";
                        setError4(error);
                      }
                      const data = { ...option4Data };
                      data.preferred_ref_no = "2";
                      setOption4Data(data);
                    }}
                  />
                  <label class="form-check-label" for="RflowRadio3__2">
                    Invoice Number
                  </label>
                </div>
              </div>
              <div class="fcrg-item payment-info-main-option">
                <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="RflowRadioDefault"
                    id="RflowRadio3__3"
                    checked={option4Data.preferred_ref_no == "3"}
                    onChange={() => {
                      const error = { ...error4 };
                      if (error.preferred_ref_no) {
                        error.preferred_ref_no = "";
                        setError4(error);
                      }
                      const data = { ...option4Data };
                      data.preferred_ref_no = "3";
                      setOption4Data(data);
                    }}
                  />
                  <label class="form-check-label" for="RflowRadio3__3">
                    Shipping Container Number
                  </label>
                </div>
              </div>
              <div class="fcrg-item">
                <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="RflowRadioDefault"
                    id="RflowRadio3__4"
                    checked={option4Data.preferred_ref_no == "4"}
                    onChange={() => {
                      const error = { ...error4 };
                      if (error.preferred_ref_no) {
                        error.preferred_ref_no = "";
                        setError4(error);
                      }
                      const data = { ...option4Data };
                      data.preferred_ref_no = "4";
                      setOption4Data(data);
                    }}
                  />
                  <label class="form-check-label" for="RflowRadio3__4">
                    Salvex Listing number is fine
                  </label>
                </div>
              </div>
              {error4.preferred_ref_no ? (
                <p style={{ color: "red", marginLeft: "13px" }}>{error4.preferred_ref_no}</p>
              ) : (
                ""
              )}
            </div>
          </div>
          <div class="form-group">
            <label for="" class="form-label">
              Additional email address to be sent confirmation of payment.
            </label>
            <textarea
              type="text"
              class="w-100 form-control"
              value={option4Data.other_email}
              onChange={(e) => {
                const data = { ...option4Data };
                data.other_email = e.target.value.trim();
                setOption4Data(data);
              }}
            />
          </div>
          <div class="form-group">
            <label className="form-label">Do you have any additional information ?</label>
            <input
              type="text"
              class="form-control"
              value={option4Data.additional_info}
              onChange={(e) => {
                const data = { ...option4Data };
                data.additional_info = e.target.value.trim();
                setOption4Data(data);
              }}
            />
          </div>
        </>
      )}
    </>
  );
};

export default PaymentTypeFour;
