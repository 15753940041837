import React, { forwardRef } from "react";
import ReactDOM from "react-dom";
import Cropper from "react-cropper";

import "cropperjs/dist/cropper.css";
import { dataUrlToFileUsingFetch } from "./dataURLtoFile";
// import "./styles.css";

class Edit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageSrc: "",
      rotate: 0,
    };
    this.fileInput = React.createRef();
    this.handleFileRead = this.handleFileRead.bind(this);
    this.fileReader = new FileReader();
    this.cropper = React.createRef();
  }

  handleFileRead(e) {
    console.log("The reading is over");
    const binaryData = this.fileReader.result;
    const base64Data = window.btoa(binaryData);
    this.setState({ base64: base64Data });
  }

  componentDidMount = async () => {
    if (this.props && this.props.image) {
      const data = this.props.image;
      const image = `${process.env.REACT_APP_MEDIA_URL}${data.s_path}${
        data.filename
      }_lp.${data.suffix.toLowerCase()}`;
      const a = await dataUrlToFileUsingFetch(image, "new", "jpeg");
      const { name, size, type } = a;
      const imageSrc = URL.createObjectURL(a);

      this.setState({
        name,
        size,
        type,
        imageSrc,
        croppedImgSrc: null,
      });

      this.fileReader.onloadend = this.handleFileRead;
      this.fileReader.readAsBinaryString(a);
    }
  };

  handleChange(event) {
    const file = this.fileInput.current.files[0];
    const { name, size, type } = file;
    const imageSrc = URL.createObjectURL(event.target.files[0]);

    this.setState({
      name,
      size,
      type,
      imageSrc,
      croppedImgSrc: null,
    });

    this.fileReader.onloadend = this.handleFileRead;
    this.fileReader.readAsBinaryString(file);
  }

  handleCropChange() {
    console.log("## cropped !");
    const croppedImgData = this.cropper.current.cropper
      .getCroppedCanvas()
      .toDataURL("image/jpeg");

    this.setState({ croppedImgSrc: croppedImgData });
    this.props.setEditedFile(croppedImgData);
  }

  handleRotate() {
    this.cropper.current.cropper.rotate(90);
    this.handleCropChange();
  }

  render() {
    const { name, type, size, imageSrc, base64, croppedImgSrc } = this.state;

    return (
      <div className="container-edit" style={{ height: "550px" }}>
        <div className="container-cropper-edit">
          <div className="cropper-edit">
            {this.props.optionType === "rotate" ? (
              <div
                class="parentCrop cropdiv cropDivFix"
                style={{ cursor: "none" }}
                onClick={() => console.log("u clicked")}
                onDragCapture={() => console.log("u clicked")}
              >
                {" "}
                <Cropper
                  style={{ maxWidth: "100%", height: "500px" }}
                  ref={this.cropper}
                  src={imageSrc}
                  aspectRatio={16 / 9}
                  cropend={() => this.handleCropChange()}
                  autoCrop={false}
                  autoCropArea={0}
                  movable={false}
                  scalable={true}
                  zoomable={true}
                  cropBoxMovable={false}
                  cropBoxResizable={false}
                  background={false}
                  crop={false}
                />
              </div>
            ) : (
              <Cropper
                style={{ maxWidth: "100%", height: "500px" }}
                ref={this.cropper}
                src={imageSrc}
                // aspectRatio={16 / 9}
                cropend={() => this.handleCropChange()}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Edit;
