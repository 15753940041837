import React from "react";
import { Modal } from "react-bootstrap";
import close from "../../../include/images/close-12x12.svg";
import NextButton from "../../../common/form/nextButton";

const ApplyTeam = (props) => {
  return (
    <Modal
      className="modal fade custom-modal"
      id="newBuyer"
      show={props.show}
      onHide={props.onHide}
      dialogClassName="modal-dialog modal-dialog-centered modal-xl"
    >
      <div class="modal-content h-100 d-flex flex-column">
        <div class="custom-modal-header d-flex align-items-center justify-content-between">
          <h5 class="mb-0 text-capitalize">Apply To Other Listings</h5>
          <button
            type="button"
            class="close-btn d-flex align-items-center justify-content-center"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={props.onHide}
          >
            <img src={close} alt="" />
          </button>
        </div>
        <div class="custom-modal-body flex-grow-1 hide-scrollbar">
          <div class="table-responsive table-custom">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th></th>
                  <th>Title</th>
                  <th>Status</th>
                  <th>Salvex Team</th>
                </tr>
              </thead>
              <tbody>
                {props?.data?.map((val) => (
                  <tr>
                    <td>
                      <div class="form-check tick-checkbox">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id={`apply-team-${val?.auction_id}`}
                          disabled={val?.is_invoiced ? true : false}
                          onChange={(e) =>
                            e.target.checked
                              ? props?.setAucIds((pre) => [val?.auction_id, ...pre])
                              : props?.setAucIds((pre) => pre?.filter((id) => id !== val?.auction_id))
                          }
                          name="apply-team"
                        />
                        <label class="form-check-label" htmlFor={`apply-team-${val?.auction_id}`}></label>
                      </div>
                    </td>
                    <td>{val?.title ? val?.title : "N/A"}</td>
                    <td>{val?.status ? val?.status : "N/A"}</td>
                    <td>
                      {val?.method_origin ? (
                        <div>
                          <label class="fw-medium">Method Origin:</label>
                          <span>{val?.method_origin}</span>
                        </div>
                      ) : (
                        ""
                      )}
                      {val?.individual_origin ? (
                        <div>
                          <label class="fw-medium">Individual Origin:</label>
                          <span>{val?.individual_origin}</span>
                        </div>
                      ) : (
                        ""
                      )}
                      {val?.comm_member_1 ? (
                        <div>
                          <label class="fw-medium">Trader:</label>
                          <span>{val?.comm_member_1}</span>
                        </div>
                      ) : (
                        ""
                      )}
                      {val?.comm_member_2 ? (
                        <div>
                          <label class="fw-medium">Business Development:</label>
                          <span>{val?.comm_member_2}</span>
                        </div>
                      ) : (
                        ""
                      )}
                      {val?.comm_member_3 ? (
                        <div>
                          <label class="fw-medium">Administration 1:</label>
                          <span>{val?.comm_member_3}</span>
                        </div>
                      ) : (
                        ""
                      )}
                      {val?.comm_member_4 ? (
                        <div>
                          <label class="fw-medium">Administration 2:</label>
                          <span>{val?.comm_member_4}</span>
                        </div>
                      ) : (
                        ""
                      )}
                      {val?.comm_member_5 ? (
                        <div>
                          <label class="fw-medium">Mid Manager:</label>
                          <span>{val?.comm_member_5}</span>
                        </div>
                      ) : (
                        ""
                      )}
                      {val?.comm_member_6 ? (
                        <div>
                          <label class="fw-medium">Optimizer:</label>
                          <span>{val?.comm_member_6}</span>
                        </div>
                      ) : (
                        ""
                      )}
                      {val?.outside_agent_name ? (
                        <div>
                          <label class="fw-medium">Outside Agent:</label>
                          <span>{val?.outside_agent_name}</span>
                        </div>
                      ) : (
                        ""
                      )}
                      {val?.inspector_name ? (
                        <div>
                          <label class="fw-medium">Inspector:</label>
                          <span>{val?.inspector_name}</span>
                        </div>
                      ) : (
                        ""
                      )}
                      {val?.mgr_name ? (
                        <div>
                          <label class="fw-medium">Trader Manager:</label>
                          <span>{val?.mgr_name}</span>
                        </div>
                      ) : (
                        ""
                      )}
                      {val?.admin_name ? (
                        <div>
                          <label class="fw-medium">BD Manager:</label>
                          <span>{val?.admin_name}</span>
                        </div>
                      ) : (
                        ""
                      )}
                      {val?.recommend_viewers ? (
                        <div>
                          <label class="fw-medium">Recommend Viewer:</label>
                          <span>{val?.recommend_viewers}</span>
                        </div>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div class="custom-modal-dividor"></div>
        <div class="custom-modal-footer d-grid gap-2 d-md-flex align-items-center justify-content-md-end">
          <button
            class="btn btn-link me-md-2"
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={props.onHide}
          >
            Cancel
          </button>
          <NextButton
            label="Save"
            classData="btn btn-default btn-lg"
            handleSubmit={props.handleSubmit}
            loading={props.loading}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ApplyTeam;
