import React, { useEffect, useRef, useState } from "react";
import LoadingBar from "react-top-loading-bar";
import Header from "../../layouts/header";
import EiReferredListingTable from "../../components/settings/eiReferredListings/eiReferredListingTable";
import { connect } from "react-redux";
import { getDonationListings, primarySellerData, updateDonationStatus } from "../../store/primarySeller";
import { toast } from "react-toastify";
import AlertError from "../../common/alerts/alertError";
import ReferredListingFilterModal from "../../components/settings/eiReferredListings/referredListingFilterModal";
import RemoveDonationModal from "../../layouts/modals/removeDonationModal";

const EiReferredListings = (props) => {
  const ref = useRef(null);
  const data = props?.primarySellerData?.donationListings?.data;
  const totalPages = props?.primarySellerData?.donationListings?.max_pages;
  const totalCount = props?.primarySellerData?.donationListings?.total_records;
  const [page, setPage] = useState(1);
  const [showFilter, setShowFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sort, setSort] = useState("donated_date");
  const [order, setOrder] = useState("desc");
  const [keyword, setKeyword] = useState("");
  const [response, setResponse] = useState({
    keyword: "",
  });
  const [enable, setEnable] = useState(false);
  const [loadingChange, setLoadingChange] = useState(false);
  const [selected, setSelected] = useState(null);

  const [donationModal, setDonationModal] = useState(false);
  const toggleDonationModal = () => {
    setDonationModal(!donationModal);
  };

  const toggleFilter = () => {
    setShowFilter(!showFilter);
    setKeyword(response?.keyword);
  };
  useEffect(() => {
    ref?.current?.continuousStart();
    setLoading(true);
    const payload = {
      page: 1,
      sort: sort,
      order: order,
      keyword: response?.keyword,
    };
    props?.getDonationListings(payload, (res) => {
      if (res.status === 200) {
        setEnable(true);
        ref?.current?.complete();
        setLoading(false);
      } else {
        ref?.current?.complete();
        setLoading(false);
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  }, []);

  useEffect(() => {
    if (enable) {
      ref?.current?.continuousStart();
      setLoading(true);
      const payload = {
        page: page,
        sort: sort,
        order: order,
        keyword: response?.keyword,
      };
      props?.getDonationListings(payload, (res) => {
        if (res.status === 200) {
          ref?.current?.complete();
          setLoading(false);
        } else {
          ref?.current?.complete();
          setLoading(false);
          toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        }
      });
    }
  }, [page]);

  useEffect(() => {
    if (enable) {
      setEnable(false);
      setPage(1);
      ref?.current?.continuousStart();
      setLoading(true);
      const payload = {
        page: 1,
        sort: sort,
        order: order,
        keyword: response?.keyword,
      };
      props?.getDonationListings(payload, (res) => {
        if (res.status === 200) {
          ref?.current?.complete();
          setLoading(false);
          setEnable(true);
        } else {
          ref?.current?.complete();
          setLoading(false);
          setEnable(true);
          toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        }
      });
    }
  }, [sort, order]);

  const handleFilter = () => {
    setEnable(false);
    setPage(1);
    ref?.current?.continuousStart();
    setLoading(true);
    const payload = {
      page: 1,
      sort: sort,
      order: order,
      keyword: keyword,
    };
    props?.getDonationListings(payload, (res) => {
      if (res.status === 200) {
        const data = { ...response };
        data.keyword = keyword;
        setResponse(data);
        toggleFilter();
        ref?.current?.complete();
        setLoading(false);
        setEnable(true);
      } else {
        ref?.current?.complete();
        setLoading(false);
        setEnable(true);
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };
  const clearFilter = (x) => {
    setEnable(false);
    setPage(1);
    ref?.current?.continuousStart();
    setLoading(true);
    const payload = {
      page: 1,
      sort: sort,
      order: order,
      keyword: x === "keyword" ? "" : response?.keyword,
    };
    props?.getDonationListings(payload, (res) => {
      if (res.status === 200) {
        ref?.current?.complete();
        setLoading(false);
        setEnable(true);
      } else {
        ref?.current?.complete();
        setLoading(false);
        setEnable(true);
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const changeDonationStatus = () => {
    ref?.current?.continuousStart();
    setLoadingChange(true);

    props?.updateDonationStatus(selected?.auction_id, { for_donation: !selected?.for_donation }, (res) => {
      if (res && res.status == 200) {
        const payload = {
          page: page,
          sort: sort,
          order: order,
          keyword: response?.keyword,
        };
        props?.getDonationListings(payload, (res1) => {
          if (res1?.status == 200) {
            toggleDonationModal();
            setLoadingChange(false);
            ref?.current?.complete();
          }
        });
      } else {
        setLoadingChange(false);
        ref?.current?.complete();
      }
    });
  };

  return (
    <>
      <LoadingBar height={5} color="#47AD1D" ref={ref} />
      <Header />
      <EiReferredListingTable
        data={data}
        page={page}
        setPage={setPage}
        loading={loading}
        totalPages={totalPages}
        totalCount={totalCount}
        order={order}
        setOrder={setOrder}
        sort={sort}
        setSort={setSort}
        response={response}
        setResponse={setResponse}
        toggleFilter={toggleFilter}
        clearFilter={clearFilter}
        loadingChange={loadingChange}
        changeDonationStatus={toggleDonationModal}
        setSelected={setSelected}
      />
      <ReferredListingFilterModal
        show={showFilter}
        onHide={toggleFilter}
        keyword={keyword}
        setKeyword={setKeyword}
        handleFilter={handleFilter}
        loading={loading}
      />
      <RemoveDonationModal
        show={donationModal}
        onHide={toggleDonationModal}
        loadingDelete={loadingChange}
        deleteHandler={changeDonationStatus}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getDonationListings: (params, callback) => dispatch(getDonationListings(params, callback)),
  updateDonationStatus: (id, data, callback) => dispatch(updateDonationStatus(id, data, callback)),
});

const mapStateToProps = (state) => ({
  primarySellerData: primarySellerData(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(EiReferredListings));
