import React from "react";
import { Offcanvas } from "react-bootstrap";
import close from "../../../include/images/close.svg";
import DateFormat from "../../../common/DateFormat/dateFormat";
import Trash from "../../../include/images/trash-18x18.svg";
import selectedIcon from "../../../include/images/yellow-star-icon.svg";
import darkStarIcon from "../../../include/images/dark-star-icon.svg";
import { getProfile } from "../../../utils.js/localStorageServices";
import emptyChat from "../../../include/images/chat-empty.svg";


const FeaturedNotes = (props) => {
  const session = getProfile();
  return (
    <Offcanvas
      placement="end"
      backdropClassName="upload-documnets-backdrop"
      show={props.show}
      onHide={() => props.onHide()}
      className=" right-collapse fade modal-lg  offcanvas-pannel-container"
      dialogClassName="modal-dialog"
    >
      <Offcanvas.Header className="offcanvas-header">
        <h5 class="offcanvas-title">Featured Notes</h5>
        <button
          type="button"
          class="offcanvas-close-btn d-flex align-items-center justify-content-center"
          onClick={() => props.onHide()}
        >
          <img src={close} alt="" />
        </button>
      </Offcanvas.Header>
      <Offcanvas.Body className="custom-modal-body flex-grow-1 w-100 overflow-scroll" data-simplebar>
        {props?.data && props?.data?.length > 0 && props?.data?.some((val) => val?.isStarred === true) ? (
          props?.data?.map((val) => {
            if (val?.task_id > 0 && (val.task_status_id == 1 || val.task_status_id == 4)) {
              return;
            } else {
              if (val?.isStarred) {
                return (
                  <div class="text-card-block-column">
                    <div class="tcbc-header d-flex flex-wrap align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        <div
                          class="tcbc-header-label d-flex ps-2 flex-column align-items-start justify-content-center text-white me-1"
                          style={{
                            backgroundColor: val?.task_id > 0 ? "#137c10" : "#104b7c",
                            width: "250px",
                            minHeight: "50px",
                            maxHeight: "auto",
                          }}
                        >
                          <div class="fw-medium">
                            {val?.is_declined ? <span>Declined Bid</span> : val?.name01}
                            {val?.sent_id ? <em> to {val?.sent_name}</em> : ""}
                            <br /> <div>{val?.created && <DateFormat date={val?.created} />}</div>
                          </div>
                        </div>
                        <div className="cursor-pointer" onClick={() => props?.handleStarred(val?.chat_id)}>
                          <img src={val?.isStarred ? selectedIcon : darkStarIcon} alt="" />
                        </div>
                      </div>
                      {val?.member_id == session.member_id || session.member_id == 1 ? (
                        <div class="tcbc-right-box">
                          <button
                            type="button"
                            class="delete-button d-flex align-items-center"
                            onClick={() => {
                              props?.toggleDeleteModal(val?.chat_id);
                            }}
                          >
                            <img src={Trash} alt="" />
                            Delete
                          </button>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {val?.is_declined ? (
                      <>
                        <strong>Amount Declined:</strong> &nbsp;
                        {val?.BidAmount ? "$" + new Intl.NumberFormat("en-US").format(val?.BidAmount) : "$0.00"} <br />
                        <strong>Bidder:</strong> &nbsp;{val?.bidderName} <br />
                        <strong>Message Sent: </strong>
                      </>
                    ) : (
                      ""
                    )}
                    {val?.requested ? (
                      <>
                        <span>
                          {`${val?.name01} has requested ${val?.managed_by} to help communicate with ${val?.buyer_name}`}{" "}
                        </span>
                        <br />
                      </>
                    ) : (
                      ""
                    )}
                    <div
                      style={{ whiteSpace: "pre-wrap" }}
                      class="tcbc-text-row div-comm-notes-sec text-start div-comments-message"
                      id="comments-messages"
                      dangerouslySetInnerHTML={{
                        __html: val.message
                          ?.replaceAll("/s3/viewDocFile.cfm?hash=", process.env.REACT_APP_S3_PRIVATE_DOC_BUCKET + "/")
                          ?.replaceAll(`">View Agreement.`, `.pdf">View Agreement.`),
                      }}
                    />
                    {/* <div className="d-flex flex-column  ">
                      {val?.date_completed && (
                        <>
                          <div style={{ marginTop: "6px", color: "#47AD1D" }}>
                            <span>Completed on </span>
                            <DateFormat date={val?.date_completed} />
                          </div>
                        </>
                      )}
                    </div> */}
                    {val?.task_id > 0 && val?.task_status_id === 1 ? (
                      val?.task_id === 169 ? (
                        <>
                          <a
                            href={
                              process.env.REACT_APP_MODE !== "development"
                                ? "admin.salvex.com/"
                                : "admin-dev.salvex.com" +
                                  `/admin/_sellers/_mrk_doc_auc/_create.cfm?aucID=${props?.auctionId}&s_user_id=${val?.user_id}&request_location=${val?.task_id}`
                            }
                            target="_blank"
                          >
                            &raquo; Respond
                          </a>{" "}
                          <a class="TaskChatMessage" status="4" rel="#ch.chat_id#">
                            &raquo; In Progress
                          </a>
                          <a class="TaskChatMessage" status="5" rel="#ch.chat_id#">
                            &raquo; Revisit
                          </a>
                          <a class="TaskChatMessage" status="3" rel="#ch.chat_id#">
                            &raquo; Complete
                          </a>
                          <div
                            id="revisit_date"
                            style={{ marginTop: "5px", padding: ".3em .5em", background: "#EFEFEF", display: "none" }}
                          >
                            <input name="revisit_date" placeholder="Revisit Date" />
                            <a class="TaskChatMessage" status="5" rel="#ch.chat_id#">
                              {" "}
                              Save{" "}
                            </a>
                          </div>
                        </>
                      ) : (
                        <>
                          {" "}
                          <a class="TaskChatMessage" status="4" rel="#ch.chat_id#">
                            &raquo; In Progress
                          </a>
                          <a class="TaskChatMessage" status="5" rel="#ch.chat_id#">
                            &raquo; Revisit
                          </a>
                          <a class="TaskChatMessage" status="3" rel="#ch.chat_id#">
                            &raquo; Complete
                          </a>
                          <div
                            id="revisit_date"
                            style={{ marginTop: "5px", padding: ".3em .5em", background: "#EFEFEF", display: "none" }}
                          >
                            <input name="revisit_date" placeholder="Revisit Date" />
                            <a class="TaskChatMessage" status="5" rel="#ch.chat_id#">
                              {" "}
                              Save{" "}
                            </a>
                          </div>
                        </>
                      )
                    ) : val?.task_id > 0 && val?.task_status_id == 4 ? (
                      <>
                        <strong style={{ color: "#137c10" }}>In progress</strong>
                        <a class="TaskChatMessage" status="5" rel="#ch.chat_id#">
                          &raquo; Revisit
                        </a>
                        <a class="TaskChatMessage" status="3" rel="#ch.chat_id#">
                          &raquo; Complete
                        </a>
                        <div
                          id="revisit_date"
                          style={{ marginTop: "5px", padding: ".3em .5em", background: "#EFEFEF", display: "none" }}
                        >
                          <input name="revisit_date" placeholder="Revisit Date" />
                          <a class="TaskChatMessage" status="5" rel="#ch.chat_id#">
                            {" "}
                            Save{" "}
                          </a>
                        </div>
                      </>
                    ) : val?.task_id > 0 && val?.task_status_id == 5 && val?.date_revisit ? (
                      <strong style={{ color: "#137c10" }}>
                        Revisit on{" "}
                        <em>
                          <DateFormat date={val?.date_revisit} />
                        </em>
                      </strong>
                    ) : val?.task_id > 0 && val?.task_status_id == 3 && val?.date_completed ? (
                      <strong style={{ color: "#137c10" }}>
                        Completed on{" "}
                        <em>
                          <DateFormat date={val?.date_completed} />
                        </em>
                      </strong>
                    ) : (
                      ""
                    )}
                  </div>
                );
              }
            }
          })
        ) : (
          <div class="d-flex align-items-center justify-content-center empty-verification-documents">
            <div class="empty-pannel-box">
              <img src={emptyChat} alt="" />
              <h4>No Notes Found!</h4>
            </div>
          </div>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default FeaturedNotes;
