import { createSelector, createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";

const slice = createSlice({
    name: "commentsAndNotes",
    initialState: {
        loading: false,
        commentsAndNotes: [],
        adminList: [],
        tasks: [],
        pendingTask: []
    },
    reducers: {
        commentsAndNotesFetching: (state, action) => {
            state.loading = true;
        },
        commentsAndNotesFetched: (state, action) => {
            state.commentsAndNotes = action.payload;
            state.loading = false;
        },
        commentsAndNotesFailed: (state, action) => {
            state.loading = false;
        },
        adminListFetching: (state, action) => {
            state.loading = true;
        },
        adminListFetched: (state, action) => {
            state.adminList = action.payload;
            state.loading = false;
        },
        adminListFailed: (state, action) => {
            state.loading = false;
        },
        taskTypeFetching: (state, action) => {
            state.loading = true;
        },
        taskTypeFetched: (state, action) => {
            state.tasks = action.payload;
            state.loading = false;
        },
        taskTypeFailed: (state, action) => {
            state.loading = false;
        },
        pendingTaskTypeFetching: (state, action) => {
            state.loading = true;
        },
        pendingTaskTypeFetched: (state, action) => {
            state.pendingTask = action.payload;
            state.loading = false;
        },
        pendingTaskTypeFailed: (state, action) => {
            state.loading = false;
        },
    }
})


export const {
    commentsAndNotesFetching,
    commentsAndNotesFetched,
    commentsAndNotesFailed,
    adminListFetching,
    adminListFetched,
    adminListFailed,
    taskTypeFetching,
    taskTypeFetched,
    taskTypeFailed,
    pendingTaskTypeFetching,
    pendingTaskTypeFetched,
    pendingTaskTypeFailed
} = slice.actions;
export default slice.reducer;


//Action Creators

const baseUrl = 'commentAndNote';
const pendingTaskUrl = 'auctionOther/other/pendingTask';


//Get Comments and Notes
export const getCommentsAndNotes = (params, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: `${baseUrl}/${params}`,
            method: 'get',
            callback,
            onStart: commentsAndNotesFetching.type,
            onSuccess: commentsAndNotesFetched.type,
            onError: commentsAndNotesFailed.type,
        })
    )
}

//Delete Chat
export const deleteChat = (params, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: `${baseUrl}/${params}`,
            method: 'delete',
            callback,
        })
    )
}

//Get to Admin List
export const getAdminList = (params, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: `${baseUrl}/toAdminList/${params}`,
            method: 'get',
            callback,
            onStart: adminListFetching.type,
            onSuccess: adminListFetched.type,
            onError: adminListFailed.type,
        })
    )
};

//Get Task Type
export const getTaskType = (callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: `${baseUrl}/taskType`,
            method: 'get',
            callback,
            onStart: taskTypeFetching.type,
            onSuccess: taskTypeFetched.type,
            onError: taskTypeFailed.type,
        })
    )
}

//Add Comments and Notes
export const addCommentsAndNotes = (params, data, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: `${baseUrl}/${params}`,
            method: 'post',
            data,
            callback,
        })
    )
}
//Get Pending tasks
export const getPendingTask = (params, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: `${pendingTaskUrl}/${params}`,
            method: 'get',
            callback,
            onStart: pendingTaskTypeFetching.type,
            onSuccess: pendingTaskTypeFetched.type,
            onError: pendingTaskTypeFailed.type,
        })
    )
}


export const commentsAndNotesDetails = createSelector(
    (state) => state.entities.commentsAndNotes,
    (commentsAndNotes) => commentsAndNotes
)