import React from "react";

const FilterModal = (props) => {
  return (
    <div
      class={`offcanvas offcanvas-end custom-offcanvas-sm ${
        props.showFilterModal ? "show" : ""
      } `}
      id="offcanvasRight"
    >
      <div class="offcanvas-header co-head">
        <h6>Filters</h6>
        <button
          type="button"
          class="btn-close text-reset"
          onClick={() => props.setShowFilterModal(false)}
        ></button>
      </div>
      <div class="offcanvas-body co-body">
        <div class="form-group">
          <label for="keyword" class="form-label">
            Keyword
          </label>
          <input type="text" class="form-control" id="keyword" />
        </div>
        <div class="form-group">
          <label for="type" class="form-label">
            Type
          </label>
          <input type="text" class="form-control" id="type" />
        </div>
        <div class="form-group">
          <label for="date" class="form-label">
            Date
          </label>
          <input type="text" class="form-control" id="date" />
        </div>
      </div>
      <div class="offcanvas-footer">
        <a href="javascript:void(0)" class="btn btn-default btn-block">
          Apply Filters
        </a>
      </div>
    </div>
  );
};

export default FilterModal;
