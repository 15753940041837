import React from "react";
import NextButton from "../../../common/form/nextButton";
import Skeleton from "react-loading-skeleton";
import { useState } from "react";
import CustomerChat from "../../../layouts/modals/customerChat/customerChat";
import { connect } from "react-redux";
import { getChats, loadAuctionChat, loadChatMassages } from "../../../store/chats";
import { useEffect } from "react";
import ChatMessages from "../../../layouts/modals/customerChat/chatMessages";
import { getProfile } from "../../../utils.js/localStorageServices";

const CustomerChats = (props) => {
  const [showChats, setShowchats] = useState(false);
  const [showMessages, setShowMessages] = useState(false);
  const [loadingChats, setLoadingChats] = useState(false);
  const [selectedChat, setSelectedChat] = useState(null);

  const toggleChat = () => {
    setShowchats(!showChats);
  };

  const toggleMessages = () => {
    setShowchats(!showChats);
    setShowMessages(!showMessages);
  };

  const member = getProfile();

  const chatList = (props.getChatList && props.getChatList.auctionChats) || [];
  const selectedMessages = (props.getChatList && props.getChatList.chatMassages) || [];

  useEffect(() => {
    if (showChats) {
      props.refStart();
      setLoadingChats(true);
      const id = props.auctionId;
      props.getAuctionChats(id, (res) => {
        if (res && res.status == 200) {
          setLoadingChats(false);
          props.refStop();
        }
      });
    }
  }, [showChats]);

  useEffect(() => {
    if (!showMessages) {
      setSelectedChat(null);
    }
  }, [showMessages]);

  useEffect(() => {
    if (selectedChat) {
      props.refStart();
      setLoadingChats(true);
      const id = props.auctionId;
      props.loadMassages(selectedChat.userId, id, (res) => {
        if (res && res.status == 200) {
          setLoadingChats(false);
          props.refStop();
        }
      });
    }
  }, [selectedChat]);

  return (
    <div class="details-page-widget-block" id={props.id}>
      <div class="page-widget-head d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <div class="d-flex align-items-center">
            <div class="page-widget-label text-capitalize cursor-pointer" onClick={!props.loadingMain && toggleChat}>
              Customer Chats
            </div>
          </div>
          {!props?.loadingMain ? (
            <li className="badge-item cursor-pointer" onClick={toggleChat} style={{ marginLeft: "10px" }}>
              <div className="badge-box" style={{ background: "#DADEE0" }}>
                {showChats ? "Hide" : "Show"}
              </div>
            </li>
          ) : (
            ""
          )}
        </div>
        {/* <div class="d-flex align-items-center">
          {!props.loadingMain && (
            <a href="javascript:void(0)" class={`btn btn-primary btn-sm `} onClick={() => toggleChat()}>
              View
            </a>
          )}
        </div> */}
      </div>
      {props.loadingMain && <Skeleton height="250px" width="100%" />}
      <CustomerChat
        show={showChats}
        onHide={toggleChat}
        chatList={chatList}
        loadingChats={loadingChats}
        toggleMessages={toggleMessages}
        selectedChat={selectedChat}
        setSelectedChat={setSelectedChat}
        detail={props?.detail}
      />
      <ChatMessages
        show={showMessages}
        onHide={toggleMessages}
        toggleChat={toggleChat}
        loadingChats={loadingChats}
        selectedMessages={selectedMessages}
        selectedChat={selectedChat}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getAuctionChats: (id, callback) => dispatch(loadAuctionChat(id, callback)),
  loadMassages: (userId, auctionId, callback) => dispatch(loadChatMassages(userId, auctionId, callback)),
});

const mapStateToProps = (state) => ({
  getChatList: getChats(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(CustomerChats));
