import { createSelector, createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";

const slice = createSlice({
  name: "otherListings",
  initialState: {
    loading: false,
    otherListings: [],
    count1: "",
    count2: "",
    observers: {},
    otherBuyerListing: {},
  },
  reducers: {
    otherListingsFetching: (state, action) => {
      state.loading = true;
    },
    otherListingsFetched: (state, action) => {
      state.otherListings = action.payload;
      state.loading = false;
    },
    otherListingsFailed: (state, action) => {
      state.loading = false;
    },
    count1Fetching: (state, action) => {
      state.loading = true;
    },
    count1Fetched: (state, action) => {
      state.count1 = action.payload;
      state.loading = false;
    },
    count1Failed: (state, action) => {
      state.loading = false;
    },
    count2Fetching: (state, action) => {
      state.loading = true;
    },
    count2Fetched: (state, action) => {
      state.count2 = action.payload;
      state.loading = false;
    },
    count2Failed: (state, action) => {
      state.loading = false;
    },
    observersFetching: (state, action) => {
      state.loading = true;
    },
    observersFetched: (state, action) => {
      state.observers = action.payload;
      state.loading = false;
    },
    observersFailed: (state, action) => {
      state.loading = false;
    },
    otherBuyerListingFetching: (state, action) => {
      state.loading = true;
    },
    otherBuyerListingFetched: (state, action) => {
      state.otherBuyerListing = action.payload;
      state.loading = false;
    },
    otherBuyerListingFailed: (state, action) => {
      state.loading = false;
    },
  },
});

export const {
  otherListingsFetching,
  otherListingsFetched,
  otherListingsFailed,
  count1Fetching,
  count1Fetched,
  count1Failed,
  count2Fetching,
  count2Fetched,
  count2Failed,
  observersFetching,
  observersFetched,
  observersFailed,
  otherBuyerListingFetching,
  otherBuyerListingFetched,
  otherBuyerListingFailed,
} = slice.actions;
export default slice.reducer;

//Action Creators

const baseUrl = "auctionOther/other/list";
const countUrl = "auctionOther/other/count";
const otherListingUrl = "auctionOther/other/buyerList";

//Get Other Listings
export const getOtherListings =
  (params, catId, page, callback) => (dispatch) => {
    return dispatch(
      apiCallBegan({
        url: `${baseUrl}/${params}?page=${page}&limit=10&category_id=${catId}`,
        method: "get",
        callback,
        onStart: otherListingsFetching.type,
        onSuccess: otherListingsFetched.type,
        onError: otherListingsFailed.type,
      })
    );
  };

//Get other Listing Count
export const getOtherListingCount1 = (id, params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${countUrl}/${id}`,
      params,
      method: "get",
      callback,
      onStart: count1Fetching.type,
      onSuccess: count1Fetched.type,
      onError: count1Failed.type,
    })
  );
};
export const getOtherListingCount2 = (id, params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${countUrl}/${id}`,
      params,
      method: "get",
      callback,
      onStart: count2Fetching.type,
      onSuccess: count2Fetched.type,
      onError: count2Failed.type,
    })
  );
};


//Get Observers
export const getObservers = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `auctionOther/other/observer/${id}`,
      method: 'get',
      callback,
      onStart: observersFetching.type,
      onSuccess: observersFetched.type,
      onError: observersFailed.type,
    })
  )
}

//Get Other Buyer Listings.
export const getOtherBuyerListing = (id, params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${otherListingUrl}/${id}`,
      params,
      method: "get",
      callback,
      onStart: otherBuyerListingFetching.type,
      onSuccess: otherBuyerListingFetched.type,
      onError: otherBuyerListingFailed.type,
    })
  )
}

export const otherListingsDetails = createSelector(
  (state) => state.entities.otherListings,
  (otherListings) => otherListings
);
