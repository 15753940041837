import React, { useState } from "react";
import { Offcanvas } from "react-bootstrap";
import close from "../../../include/images/close-12x12.svg";

const AddAdminList = (props) => {
  return (
    <Offcanvas
      placement="end"
      backdropClassName="upload-documnets-backdrop"
      show={props?.show}
      onHide={() => props?.onHide()}
      className="offcanvas offcanvas-end border-0"
      id="uploadDocumentOffcanvas"
      enforceFocus={false}
    >
      <Offcanvas.Header className="offcanvas-header">
        <h5 class="offcanvas-title">Select More Recipients</h5>
        <button
          type="button"
          class="offcanvas-close-btn d-flex align-items-center justify-content-center"
          onClick={() => props?.onHide()}
        >
          <img src={close} alt="" />
        </button>
      </Offcanvas.Header>
      <Offcanvas.Body className="offcanvas-body p-0">
        <div class="offcanvas-widget-row pt-0">
          <div className="d-block ">
            <input
              type="text"
              className="form-control"
              placeholder="Search"
              style={{ marginBottom: "20px" }}
              value={props?.keyword}
              onChange={(e) => props?.setKeyword(e.target.value)}
            />
          </div>
          <div class="two-row-column d-flex flex-wrap">
            <div class="tr-column  position-relative">
              <div class="tr-column-content w-100 h-100 d-flex flex-column">
                <table class="table table-borderless align-middle table-left-align">
                  <colgroup>
                    <col />
                    <col />
                    <col />
                  </colgroup>
                  <tbody>
                    <tr className="d-flex flex-column " style={{ flexWrap: "wrap" }}>
                      {props.adminList && props.adminList.length > 0
                        ? props.adminList.map((val, idx) => {
                            return (
                              <>
                                <tr>
                                  <td>
                                    <div class="form-check tick-checkbox">
                                      <input
                                        name={`${val.name01}${idx + 10}`}
                                        id={`${val.name01}${idx + 10}`}
                                        class="form-check-input"
                                        type="checkbox"
                                        checked={props.selectAdmin?.some((io) => io?.value == val?.member_id)}
                                        onChange={(e) => {
                                          const values = { ...props?.addMessage };
                                          const data = [...props.selectAdmin];
                                          if (!props.selectAdmin?.some((io) => io?.value == val?.member_id)) {
                                            data.push({ name: val?.name01, value: `${val?.member_id}` });
                                            const b = data.map((da) => da?.value.toString()).join(",");
                                            values.to_id = b;
                                            props?.setSelectAdmin(data);
                                            props?.setAddMessage(values);
                                          } else {
                                            const a = data?.filter((ep) => ep?.value != val?.member_id);
                                            const b = a.map((da) => da?.value.toString()).join(",");
                                            values.to_id = b;
                                            props?.setSelectAdmin(a);
                                            props?.setAddMessage(values);
                                          }
                                        }}
                                      />
                                      <label
                                        class="form-check-label fw-medium cursor-pointer"
                                        for={`${val.name01}${idx + 10}`}
                                      ></label>
                                    </div>
                                  </td>
                                  <td>
                                    {val?.name01}
                                    {val?.optionText ? val?.optionText : ""}
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        : ""}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default AddAdminList;
