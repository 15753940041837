import React, { useEffect, useState } from "react";
import greenEmail from "../../../include/images/green-mail.svg";
import notVerified from "../../../include/images/mail-not-verified.svg";
import close from "../../../include/images/close-icon.svg";
import invalid from "../../../include/images/mail-invalid.svg";
import spamEmail from "../../../include/images/mail-spammed-blocked.svg";
import purpleEmail from "../../../include/images/mail-purple.svg";
import { connect } from "react-redux";
import Select from "react-select";
import { assignTrader, dashboardData, getBuyerCategoryData, getCustomers } from "../../../store/dashBoard";
import { toast } from "react-toastify";
import AlertError from "../../../common/alerts/alertError";
import MoreModal from "../../../layouts/modals/pushEmailClickers/moreModal";
import UserContactInformationModal from "../../../layouts/modals/pushEmailClickers/userContactInformationModal";
import {
  addMessage,
  changeUnsubscribe,
  deleteMessage,
  emailClickersDetail,
  getRecipient,
  getUserDetail,
} from "../../../store/emailClickers";
import BuyerInterestModal from "../../../layouts/modals/dashboard/customers/buyerInterestModal";
import DateFormat from "../../../common/DateFormat/dateFormat";
import { customStyles } from "../../../common/customStyles/reactSelectStyle";
import BuyerCategoryDataModal from "../../../layouts/modals/dashboard/customers/buyerCategoryDataModal";

const MyListingBuyerInterest = (props) => {
  const detailData = props.data;
  const userDetailData = props?.emailClickersDetail?.user?.data;
  const categoryData = props?.dashboardData?.buyerCategoryData?.data;
  const { type, empId } = props;
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState("");
  const [action, setAction] = useState(false);
  const [showMoreModal, setShowMoreModal] = useState(false);
  const [recipientList, setRecipientList] = useState([]);
  const [showContactModal, setShowContactModal] = useState(false);
  const [showBuyerInterest, setShowBuyerInterest] = useState(false);
  const [userId, setUserId] = useState("");
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [quickEditLoading, setQuickEditLoading] = useState(false);
  const [contactInformation, setContactInformation] = useState({
    customer_name: "",
    customer_city: "",
    customer_email: "",
    customer_phone: "",
    customer_cell: "",
    linkedin_url: "",
    state_id: 0,
    country_id: 0,
  });
  const [showUpdate, setShowUpdate] = useState(false);
  const [updateUserId, setUpdateUserId] = useState(false);
  const [selected, setSelected] = useState();
  const [refresh, setRefresh] = useState(false);
  const [showCategoryData, setShowCategoryData] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({
    category_id: 0,
    description: "-- Show All --",
  });
  const [selectedTradeGroup, setSelectedTradeGroup] = useState({
    member_id: 0,
    name01: "Select an Option",
  });
  const [selectedRegion, setSelectedRegion] = useState({
    value: 0,
    label: "All Region",
  });

  const toggleShowUpdate = (val) => {
    setUpdateUserId(val.user_id);
    setSelected(props.empList.find((s) => s.name01 === val?.customer?.managed_by));
    setShowUpdate(!showUpdate);
  };

  const toggleCategoryData = () => {
    setShowCategoryData(!showCategoryData);
  };

  const handleGetCategoryData = (id) => {
    props?.refStart();
    props?.getBuyerCategoryData(id, (res) => {
      if (res.status === 200) {
        props?.refStop();
        toggleCategoryData();
      } else {
        props?.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const handleChange = (e) => {
    props.refStart();
    const payload = {
      action: "edit_managed_by_process",
      field: "managed_by",
      user_id: updateUserId,
      managed_by: e.user_id,
    };
    props.assignTrader(payload, (res) => {
      if (res.status === 200) {
        setAction(true);
        props.refStop();
        setRefresh(!refresh);
      } else {
        props.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const toggleMoreModal = () => {
    setShowMoreModal(!showMoreModal);
  };

  const toggelBuyerInterest = () => {
    setShowBuyerInterest(!showBuyerInterest);
  };

  //Toggle User Contact Information Modal
  const toggleContactInformationModal = () => {
    setShowMoreModal(!showMoreModal);
    setShowContactModal(!showContactModal);
  };

  const fetchUserDetail = (userId) => {
    props.refStart();
    props.getUserDetail(userId, (res) => {
      if (res.status === 200) {
        // setUserDetailData(res.data.data);
        props.getRecipient((res) => {
          if (res.status === 200) {
            setRecipientList(res.data.data);
            props.refStop();
            toggleMoreModal();
          } else {
            toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
            props.refStop();
            toggleMoreModal();
          }
        });
      } else {
        toast(<AlertError message={res.data.message ? res.data.messge : "Something Went Wrong"} />);
      }
    });
  };

  //Quick Edit
  const quickEditHandle = (userId) => {
    props.refStart();
    setQuickEditLoading(true);
    props.quickEdit(userId, contactInformation, (res) => {
      if (res.status === 200) {
        props.getUserDetail(userId, (res) => {
          if (res.status === 200) {
            props.refStop();
            setQuickEditLoading(false);
            toggleContactInformationModal();
          } else {
            props.refStop();
            setQuickEditLoading(false);
            toggleContactInformationModal();
          }
        });
      } else {
        props.refStop();
        setQuickEditLoading(false);
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  //Pagination
  useEffect(() => {
    const pagination = () => {
      props.refStart();
      const payload = {
        aType: type,
        employeeId: empId,
        page: page,
        filterInterest: filter,
      };
      props?.getCustomers(payload, (res) => {
        if (res.status === 200) {
          setShowUpdate(false);
          props.refStop();
        } else {
          props.refStop();
          toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        }
      });
    };
    if (action) {
      pagination();
    }
  }, [page, refresh]);

  const applyFilter = () => {
    const payload = {
      aType: type,
      employeeId: empId,
      page: page,
      filterInterest: filter,
      cId: selectedCategory?.category_id ? selectedCategory?.category_id : 0,
      tId: selectedTradeGroup?.user_id ? selectedTradeGroup?.user_id : 0,
      rId: selectedRegion?.country_id ? selectedRegion?.country_id : "",
      categoryId: 0,
      va: 0,
    };
    props?.refStart();
    props?.getCustomers(payload, (res) => {
      if (res.status === 200) {
        props?.refStop();
      } else {
        props?.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };
  const processOptions = (data) => {
    const groupedOptions = data.reduce((acc, item) => {
      if (item.country_id === 1001) {
        // Handle grouped options for the United States
        let countryGroup = acc.find((opt) => opt.label === item.country_description);
        if (!countryGroup) {
          countryGroup = {
            label: item.country_description,
            options: [],
          };
          acc.push(countryGroup);
        }
        // Add states to the United States group
        countryGroup.options.push({
          value: `${item.state_id}`,
          label: item.state_description,
        });
      } else {
        !acc.some((val) => val.label === item.country_description) &&
          // Handle standalone options for other countries
          acc.push({
            value: `${item.country_id}`,
            label: item.country_description,
          });
      }
      return acc;
    }, []);

    return groupedOptions;
  };

  const [regionOptions, setRegionOption] = useState([]);
  useEffect(() => {
    setRegionOption(processOptions(props?.regionsList));
  }, [props?.regionsList]);

  console.log(props?.empList, "are the list of emps...");
  return (
    <div class="details-page-widget-block">
      <div class="page-widget-head d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <div class="page-widget-label text-capitalize cursor-pointer">{props.title}</div>
        </div>
      </div>
      <div class="page-widget-body mt-3">
        <div class="table-responsive mb-2">
          <table class="large-table align-left">
            <tbody>
              <tr>
                <td>
                  <div className="filter-section d-flex mt-2">
                    <div className="d-flex">
                      <span className="fw-medium me-2">Show By:</span>
                      <div className="d-flex">
                        <div className="form-check radio pe-2">
                          <input
                            className="form-check-input cursor-pointer"
                            value={"buyer"}
                            type="radio"
                            name="radioShowBy"
                            id="radioBuyer"
                            checked={props?.showBy === "buyer"}
                            onChange={(e) => {
                              props.setShowBy("buyer");
                            }}
                          />
                          <label className="form-check-label" htmlFor="radioBuyer">
                            Buyer
                          </label>
                        </div>
                        <div className="form-check radio pe-2">
                          <input
                            className="form-check-input cursor-pointer"
                            value={"listing"}
                            type="radio"
                            name="radioShowBy"
                            checked={props?.showBy === "listing"}
                            onChange={(e) => {
                              props.setShowBy("listing");
                            }}
                          />
                          <label className="form-check-label" htmlFor="radioListing">
                            Listing
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                {props?.showBy === "buyer" && (
                  <>
                    <td>
                      <div className="d-flex">
                        <span className="fw-medium mt-2 me-2">Main Category:</span>
                        <div style={{ minWidth: "200px" }}>
                          <Select
                            placeholder="-- Show All --"
                            value={selectedCategory}
                            styles={customStyles}
                            options={[
                              { description: "Select Category", category_id: 0 },
                              ...[...props?.mainCategories]
                                ?.map((item) => ({
                                  ...item,
                                  subCat: item.subCat
                                    .slice()
                                    .sort((a, b) => a.description.localeCompare(b.description)), // Sort subcategories
                                }))
                                .sort((a, b) => a.description.localeCompare(b.description)) // Sort main categories
                                .flatMap((item) => [
                                  {
                                    description: item.description,
                                    category_id: item.category_id,
                                    owner_id: item.owner_id,
                                  },
                                  ...item.subCat.map((sub) => ({
                                    description: sub.description,
                                    category_id: sub.category_id,
                                  })),
                                ]),
                            ]}
                            className="select-show-by"
                            getOptionLabel={(option) => option.description}
                            getOptionValue={(option) => option.category_id}
                            onChange={(e) => {
                              setSelectedCategory(e);
                            }}
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex">
                        <span className="fw-medium mt-2 me-2">Trade Group:</span>
                        <div style={{ width: "200px" }}>
                          <Select
                            placeholder="Select an Option"
                            styles={customStyles}
                            className="select-show-by"
                            value={selectedTradeGroup}
                            options={[{ name01: "Select an Option", user_id: 0 }, ...props.empList]}
                            getOptionLabel={(option) => option.name01}
                            getOptionValue={(option) => option.user_id}
                            onChange={(e) => {
                              setSelectedTradeGroup(e);
                            }}
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex">
                        <span className="fw-medium mt-2 me-2">Region:</span>
                        <div style={{ width: "200px" }}>
                          <Select
                            placeholder="-- Show All --"
                            styles={customStyles}
                            value={selectedRegion}
                            className="select-show-by"
                            options={[{ label: "All Region", value: 0 }, ...regionOptions]}
                            // getOptionLabel={(option) => option.country_description}
                            // getOptionValue={(option) => option.country_id}
                            onChange={(e) => {
                              setSelectedRegion(e);
                            }}
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <button className="btn btn-primary btn-xs-size btn-auto mt-2" onClick={applyFilter}>
                        Apply Filters
                      </button>
                    </td>
                  </>
                )}
              </tr>
            </tbody>
          </table>
        </div>
        <div class="table-responsive mb-2">
          <table class="large-table align-left">
            <thead>
              <tr>
                <th>Buyer Account Follow Ups</th>
                <th>Pending Buyer Interest</th>
                <th>Information Requirements</th>
                <th>Active Buyer Interest</th>
                <th>Archived Buyer Interest</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td>
                  <ul className="mb-3">
                    <li className="mt-2 pt-2">
                      <div class="form-check tick-checkbox">
                        <input class="form-check-input" type="checkbox" value="" id="checkbox_4" />
                        <label class="form-check-label fw-medium" for="checkbox_4">
                          Bid Status Don't Know
                        </label>
                      </div>
                    </li>
                    <li className="mt-2 pt-2">
                      <div class="form-check tick-checkbox">
                        <input class="form-check-input" type="checkbox" value="" id="checkbox_5" />
                        <label class="form-check-label fw-medium" for="checkbox_5">
                          Buyer Budget Don't Know
                        </label>
                      </div>
                    </li>
                  </ul>
                </td>
                <td>
                  <ul className="mb-3">
                    <li className="mt-2 pt-2">
                      <div class="form-check tick-checkbox">
                        <input class="form-check-input" type="checkbox" value="" id="checkbox_9" />
                        <label class="form-check-label fw-medium" for="checkbox_9">
                          Getting Info From Seller
                        </label>
                      </div>
                    </li>
                    <li className="mt-2 pt-2">
                      <div class="form-check tick-checkbox">
                        <input class="form-check-input" type="checkbox" value="" id="1" />
                        <label class="form-check-label fw-medium" for="1">
                          Product Info Requirements
                        </label>
                      </div>
                    </li>
                    <li className="mt-2 pt-2">
                      <div class="form-check tick-checkbox">
                        <input class="form-check-input" type="checkbox" value="" id="23" />
                        <label class="form-check-label fw-medium" for="23">
                          Inspection Requirements
                        </label>
                      </div>
                    </li>
                    <li className="mt-2 pt-2">
                      <div class="form-check tick-checkbox">
                        <input class="form-check-input" type="checkbox" value="" id="3" />
                        <label class="form-check-label fw-medium" for="3">
                          Logistics Requirements
                        </label>
                      </div>
                    </li>
                  </ul>
                </td>
                <td>
                  <ul className="mb-3">
                    <li className="mt-2 pt-2">
                      <div class="form-check tick-checkbox">
                        <input class="form-check-input" type="checkbox" value="" id="checkbox_13" />
                        <label class="form-check-label fw-medium" for="checkbox_13">
                          Buyer Can Increase Bid
                        </label>
                      </div>
                    </li>
                    <li className="mt-2 pt-2">
                      <div class="form-check tick-checkbox">
                        <input class="form-check-input" type="checkbox" value="" id="checkbox_17" />
                        <label class="form-check-label fw-medium" for="checkbox_17">
                          Max Bid Received
                        </label>
                      </div>
                    </li>
                    <li className="mt-2 pt-2">
                      <div class="form-check tick-checkbox">
                        <input class="form-check-input" type="checkbox" value="" id="checkbox_16" />
                        <label class="form-check-label fw-medium" for="checkbox_16">
                          Max Bid Don't Know
                        </label>
                      </div>
                    </li>
                    <li className="mt-2 pt-2">
                      <div class="form-check tick-checkbox">
                        <input class="form-check-input" type="checkbox" value="" id="checkbox_14" />
                        <label class="form-check-label fw-medium" for="checkbox_14">
                          Buyer Will Bid
                        </label>
                      </div>
                    </li>
                    <li className="mt-2 pt-2">
                      <div class="form-check tick-checkbox">
                        <input class="form-check-input" type="checkbox" value="" id="checkbox_15" />
                        <label class="form-check-label fw-medium" for="checkbox_15">
                          Buyer Budget Given{" "}
                        </label>
                      </div>
                    </li>
                  </ul>
                </td>
                <td>
                  <ul className="mb-3">
                    <li className="mt-2 pt-2">
                      <div class="form-check tick-checkbox">
                        <input class="form-check-input" type="checkbox" value="" id="checkbox_18" />
                        <label class="form-check-label fw-medium" for="checkbox_18">
                          Not Interested
                        </label>
                      </div>
                    </li>
                    <li className="mt-2 pt-2">
                      <div class="form-check tick-checkbox">
                        <input class="form-check-input" type="checkbox" value="" id="checkbox_19" />
                        <label class="form-check-label fw-medium" for="checkbox_19">
                          Archived Listings
                        </label>
                      </div>
                    </li>
                    <li className="mt-2 pt-2">
                      <div class="form-check tick-checkbox">
                        <input class="form-check-input" type="checkbox" value="" id="checkbox_20" />
                        <label class="form-check-label fw-medium" for="checkbox_20">
                          No Response 3rd Attempt
                        </label>
                      </div>
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {props?.showBy === "listing" ? (
          <div class="table-responsive">
            <table class="large-table align-left">
              <thead>
                <tr>
                  <th>Product Listing Information</th>
                  <th>Pricing Information</th>
                  <th>Seller</th>
                  <th>Trading Team</th>
                  <th>Contract Deal</th>
                  <th>Big Deal</th>
                  <th>Buyer Interest</th>
                </tr>
              </thead>
              <tbody>
                {detailData?.data &&
                  detailData?.data?.length > 0 &&
                  detailData?.data?.map((val) => (
                    <>
                      <tr>
                        <td align="center">
                          <div>
                            <a href={`/listing-detail?id=${val?.auction_id}`} target="_blank">
                              <span className="green-btn-text">{val?.auction?.title}</span>
                            </a>
                          </div>
                          <div className="d-flex justify-content-center mt-2">
                            <button className="btn btn-primary btn-xs-size btn-auto">Seller Motivation</button>
                          </div>
                          <div>
                            <span className="fw-medium">Public Status: </span>
                            {val?.auction?.status_description}
                          </div>
                          <div>
                            <span className="fw-medium">Public Status: </span>
                            {val?.auction?.auction_state}, {val?.auction?.auction_country}
                          </div>
                        </td>
                        <td>
                          <div>
                            <div>
                              <span className="fw-medium">Highest Buyer Bid: </span>
                              {val?.auction?.high_bid
                                ? "$" +
                                  new Intl.NumberFormat("en-US", {
                                    style: "decimal",
                                    minimumFractionDigits: 2,
                                  }).format(val?.auction?.high_bid) +
                                  "."
                                : "$0.00"}
                            </div>
                            <div>
                              <span className="fw-medium">Public Asking Price: </span>
                              {val?.auction?.public_reserve
                                ? "$" +
                                  new Intl.NumberFormat("en-US", {
                                    style: "decimal",
                                    minimumFractionDigits: 2,
                                  }).format(val?.auction?.public_reserve) +
                                  "."
                                : "$0.00"}
                            </div>
                            <div>
                              <span className="fw-medium">Seller Asking Price: </span>
                              {val?.auction?.seller_reserve
                                ? "$" +
                                  new Intl.NumberFormat("en-US", {
                                    style: "decimal",
                                    minimumFractionDigits: 2,
                                  }).format(val?.auction?.seller_reserve) +
                                  "."
                                : "$0.00"}
                            </div>
                            <div>
                              <span className="fw-medium">Recycle Value: </span>
                              {val?.auction?.recycle_value
                                ? "$" +
                                  new Intl.NumberFormat("en-US", {
                                    style: "decimal",
                                    minimumFractionDigits: 2,
                                  }).format(val?.auction?.recycle_value) +
                                  "."
                                : "$0.00"}
                            </div>
                            <div>
                              <span className="fw-medium">Retail Value: </span>
                              {val?.auction?.retail_value
                                ? "$" +
                                  new Intl.NumberFormat("en-US", {
                                    style: "decimal",
                                    minimumFractionDigits: 2,
                                  }).format(val?.auction?.retail_value) +
                                  "."
                                : "$0.00"}
                            </div>
                            <div>
                              <span className="fw-medium">Original Wholesale Value: </span>
                              {val?.auction?.orig_wholesale_value
                                ? "$" +
                                  new Intl.NumberFormat("en-US", {
                                    style: "decimal",
                                    minimumFractionDigits: 2,
                                  }).format(val?.auction?.orig_wholesale_value) +
                                  "."
                                : "$0.00"}
                            </div>
                            <div>
                              <span className="fw-medium">Claim Value: </span>
                              {val?.auction?.claim_value
                                ? "$" +
                                  new Intl.NumberFormat("en-US", {
                                    style: "decimal",
                                    minimumFractionDigits: 2,
                                  }).format(val?.auction?.claim_value) +
                                  "."
                                : "$0.00"}
                            </div>
                            <div>
                              <span className="fw-medium">Estimated Scrap Price: </span>
                              {val?.auction?.scrapPrice
                                ? "$" +
                                  new Intl.NumberFormat("en-US", {
                                    style: "decimal",
                                    minimumFractionDigits: 2,
                                  }).format(val?.auction?.scrapPrice) +
                                  "."
                                : "$0.00"}
                            </div>
                          </div>
                        </td>

                        <td>
                          <div>
                            <a
                              href={
                                process.env.REACT_APP_MODE == "development"
                                  ? `https://admin-dev.salvex.com/admin/_user/_edit.cfm?user_id=${val?.auction?.s_user_id}`
                                  : `https://admin.salvex.com/admin/_user/_edit.cfm?user_id=${val?.auction?.s_user_id}`
                              }
                              target="_blank"
                            >
                              <span className="green-btn-text">{val?.auction?.seller_name}</span>
                            </a>
                          </div>
                          <div>{val?.auction?.seller_company}</div>
                          <div>
                            <span className="me-1">
                              {val?.auction?.success_rate
                                ? new Intl.NumberFormat("en-US", {
                                    style: "decimal",
                                    minimumFractionDigits: 2,
                                  }).format(val?.auction?.success_rate) + "% "
                                : "0.00% "}
                              {val?.auction?.rate1 ? val?.auction?.rate1 : "0"}/
                              {val?.auction?.rate2 ? val?.auction?.rate2 : "0"}
                            </span>
                            {val?.auction?.emailStatusCode === 0 ? (
                              <img src={greenEmail} alt="" title="Email OK" />
                            ) : val?.auction?.emailStatusCode === 1 ? (
                              <img
                                src={purpleEmail}
                                alt=""
                                title="Email Temporarily Suspended/Unreachable or Undetermined (Soft Bounce)"
                              />
                            ) : val?.auction?.emailStatusCode === 2 ? (
                              <img src={invalid} alt="" title="Email Invalid" />
                            ) : val?.auction?.emailStatusCode === 3 ? (
                              <img src={spamEmail} alt="" title="Email Blocked by Spam or Firewall" />
                            ) : (
                              <img src={notVerified} alt="" title="Email Not Verified" />
                            )}
                          </div>
                          <div>
                            <img
                              src={`https://us-central1-salvex-mail.cloudfunctions.net/requestRecipientStats?email=${val?.auction?.seller_email}`}
                              alt=""
                            />
                            <div>{val?.auction?.industry_description}</div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div>
                              <span className="fw-medium">Created By: </span>
                              {val?.auction?.auth_name}
                            </div>
                            <div>
                              <span className="fw-medium">Method Origin: </span>
                              {val?.auction?.method_origin}
                            </div>
                            <div>
                              <span className="fw-medium">Individual Origin: </span>
                              {val?.auction?.individual_origin}
                            </div>
                            <div>
                              <span className="fw-medium">Trader: </span>
                              {val?.auction?.trader_name}
                            </div>
                            <div>
                              <span className="fw-medium">Business Development: </span>
                              {val?.auction?.internal_referral}
                            </div>
                            <div>
                              <span className="fw-medium">Administrator 1: </span>
                              {val?.auction?.comm_member_3}
                            </div>
                            <div>
                              <span className="fw-medium">Market Developer: </span>
                              {val?.auction?.comm_member_5}
                            </div>
                            <div>
                              <span className="fw-medium">Trader Manager: </span>
                              {val?.auction?.mgr_name}
                            </div>
                            <div>
                              <span className="fw-medium">BD Manager: </span>
                              {val?.auction?.admin_name}
                            </div>
                          </div>
                        </td>
                        <td>{val?.auction?.mega && <div className="text-danger">Contract Deal</div>}</td>
                        <td>{val?.auction?.bigdeal && <div className="text-danger">Big Deal</div>}</td>
                        <td>
                          <button className="btn btn-primary btn-xs-size btn-auto">Show Buyer Interest</button>
                        </td>
                      </tr>
                      {showBuyerInterest && val.user_id === userId && <BuyerInterestModal />}
                    </>
                  ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div class="table-responsive">
            <table class="large-table align-left">
              <thead>
                <tr>
                  <th>Customer</th>
                  <th>Unsubscribed</th>
                  <th></th>
                  <th>Company</th>
                  <th>Location</th>
                  <th>Assigned To</th>
                  <th>Main Category</th>
                  <th></th>
                  <th>Feedback</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Contract Deals</th>
                  <th>Big Deals</th>
                  <th>Max Bid</th>
                  <th>Cumulative Bid</th>
                  <th>Last Bid Date</th>
                  <th>Last Activity</th>
                  <th>Profit</th>
                </tr>
              </thead>
              <tbody>
                {detailData?.data &&
                  detailData?.data?.length > 0 &&
                  detailData?.data?.map((val) => (
                    <>
                      <tr>
                        <td>
                          <span
                            className="green-btn-text cursor-pointer"
                            onClick={() => {
                              if (process.env.REACT_APP_MODE == "development") {
                                window.open(
                                  `https://admin-dev.salvex.com/admin/_user/_edit.cfm?user_id=${val?.customer?.user_id}`,
                                );
                              } else {
                                window.open(
                                  `https://admin.salvex.com/admin/_user/_edit.cfm?user_id=${val?.customer?.user_id}`,
                                );
                              }
                            }}
                          >
                            {val?.customer?.customer_name}
                          </span>
                          {val?.customer?.credit && val?.customer?.credit > 0 && (
                            <div>
                              <span className="text-danger">
                                (
                                {"$" +
                                  new Intl.NumberFormat("en-US", {
                                    style: "decimal",
                                    minimumFractionDigits: 2,
                                  }).format(val?.customer?.credit)}
                                )
                              </span>
                            </div>
                          )}
                        </td>
                        <td>{val?.customer?.unsubscribe ? "YES" : "NO"}</td>
                        <td>
                          {type === "active_sellers" ? (
                            <button
                              className="btn btn-primary btn-xs-size btn-auto"
                              onClick={() => {
                                fetchUserDetail(val?.user_id);
                              }}
                            >
                              More
                            </button>
                          ) : (
                            <div className="d-flex flex-column justify-content-center align-items-center">
                              <div>
                                <button
                                  className="btn btn-primary btn-xs-size btn-auto"
                                  onClick={() => {
                                    fetchUserDetail(val?.user_id);
                                  }}
                                >
                                  More Customer Info
                                </button>
                              </div>
                              <div className="mt-1">
                                <button
                                  className="btn btn-primary btn-xs-size btn-auto"
                                  onClick={() => {
                                    setUserId(val?.user_id);
                                    toggelBuyerInterest();
                                  }}
                                >
                                  Buyer Interest
                                </button>
                              </div>
                            </div>
                          )}
                        </td>
                        <td>
                          <span
                            className="cursor-pointer"
                            onClick={() => {
                              if (process.env.REACT_APP_MODE == "development") {
                                window.open(
                                  `https://admin-dev.salvex.com/admin/_user/_edit.cfm?user_id=${val?.customer?.user_id}`,
                                );
                              } else {
                                window.open(
                                  `https://admin.salvex.com/admin/_user/_edit.cfm?user_id=${val?.customer?.user_id}`,
                                );
                              }
                            }}
                          >
                            {val?.customer?.customer_company}
                          </span>
                        </td>
                        <td>
                          {val?.customer?.customer_city}
                          {val.customer?.customer_state
                            ? val?.customer?.customer_state === "International"
                              ? val?.customer?.customer_country && val?.customer?.customer_city
                                ? `,${val?.customer?.customer_country}`
                                : val?.customer?.customer_country
                              : `, ${val?.customer?.customer_state}`
                            : val?.customer?.customer_country && ` ${val?.customer?.customer_country}`}
                        </td>
                        <td>
                          {showUpdate && val?.user_id === updateUserId ? (
                            <div className="d-flex">
                              <div style={{ width: "200px" }}>
                                <Select
                                  menuPosition="fixed"
                                  menuShouldScrollIntoView
                                  styles={customStyles}
                                  placeholder="Select Trader"
                                  value={selected}
                                  options={[{ name01: "Select Trader", member_id: 0 }, ...props.empList]}
                                  getOptionLabel={(option) => option.name01}
                                  getOptionValue={(option) => option.member_id}
                                  onChange={(e) => {
                                    setSelected(e);
                                    handleChange(e);
                                  }}
                                />
                              </div>
                              <button className="ms-2" onClick={() => setShowUpdate(false)}>
                                <img src={close} alt="close" />
                              </button>
                            </div>
                          ) : (
                            <div className="cursor-pointer" onDoubleClick={() => toggleShowUpdate(val)}>
                              {val?.customer?.managed_by ? val?.customer?.managed_by : "N/A"}
                            </div>
                          )}
                        </td>
                        <td>N/A</td>
                        <td>
                          {" "}
                          <button
                            className="btn btn-primary btn-xs-size btn-auto"
                            onClick={() => handleGetCategoryData(val?.user_id)}
                          >
                            Buyer Category Data
                          </button>
                        </td>
                        <td>
                          Feedback:{" "}
                          <span className="text-green">
                            {val?.customer?.buyerFeedback?.total
                              ? Math.round(
                                  (val?.customer?.buyerFeedback?.good / val?.customer?.buyerFeedback?.total) * 100,
                                ) + "%"
                              : "0%"}
                          </span>{" "}
                          {val?.customer?.buyerFeedback?.total
                            ? Math.round(
                                (val?.customer?.buyerFeedback?.neutral / val?.customer?.buyerFeedback?.total) * 100,
                              ) + "%"
                            : "0%"}{" "}
                          <span className="text-danger">
                            {val?.customer?.buyerFeedback?.total
                              ? Math.round(
                                  (val?.customer?.buyerFeedback?.bad / val?.customer?.buyerFeedback?.total) * 100,
                                ) + "%"
                              : "0%"}
                          </span>
                        </td>
                        <td>
                          <div className="d-flex">
                            <a className="green-btn-text" href={`mailto:${val?.customer?.customer_email}`}>
                              {val?.customer?.customer_email}
                            </a>
                            <div className="ms-2">
                              {val?.customer?.emailstatuscode === 0 ? (
                                <img src={greenEmail} alt="" title="Email OK" />
                              ) : val?.customer?.emailstatuscode === 1 ? (
                                <img
                                  src={purpleEmail}
                                  alt=""
                                  title="Email Temporarily Suspended/Unreachable or Undetermined (Soft Bounce)"
                                />
                              ) : val?.customer?.emailstatuscode === 2 ? (
                                <img src={invalid} alt="" title="Email Invalid" />
                              ) : val?.customer?.emailstatuscode === 3 ? (
                                <img src={spamEmail} alt="" title="Email Blocked by Spam or Firewall" />
                              ) : (
                                <img src={notVerified} alt="" title="Email Not Verified" />
                              )}
                            </div>
                          </div>
                          <div class="td-button-group d-flex">
                            <img
                              src={`https://us-central1-salvex-mail.cloudfunctions.net/requestRecipientStats?email=${val?.customer?.customer_email}`}
                              alt=""
                            />
                          </div>
                        </td>
                        <td>{val?.customer?.customer_phone}</td>
                        <td>{val?.contract_deal ? val?.contract_deal : "N/A"}</td>
                        <td>{val?.big_deal ? val?.big_deal : "N/A"}</td>
                        <td>N/A</td>
                        <td>N/A</td>
                        <td>
                          {val?.customer?.lastBid?.date ? <DateFormat date={val?.customer?.lastBid?.date} /> : "N/A"}
                        </td>
                        <td>N/A</td>
                        <td>
                          {val.customer?.profitBuying > 0
                            ? "$" +
                              new Intl.NumberFormat("en-US", {
                                style: "decimal",
                                minimumFractionDigits: 2,
                              }).format(val?.customer?.profitBuying)
                            : val?.customer?.profitSelling > 0
                            ? "$" +
                              new Intl.NumberFormat("en-US", {
                                style: "decimal",
                                minimumFractionDigits: 2,
                              }).format(val?.customer?.profitSelling)
                            : "$0.00"}
                        </td>
                      </tr>
                      {showBuyerInterest && val.user_id === userId && <BuyerInterestModal />}
                    </>
                  ))}
              </tbody>
            </table>
          </div>
        )}
        {detailData?.data?.length > 0 && (
          <div className="d-flex justify-content-between mt-3">
            <div className="justify-content-start">
              Page {detailData?.page} of {detailData?.max_pages} ({detailData?.total_records} records)
            </div>
            <div class="table-pagination">
              <ul class="pagination">
                <li class="page-item">
                  <a
                    class={`page-link ${page === 1 ? "disabled" : "cursor-pointer"}`}
                    aria-label="Previous"
                    onClick={() => {
                      if (page !== 1) {
                        setAction(true);
                        setPage(page - 1);
                      }
                    }}
                  >
                    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7 13L1 7L7 1" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </a>
                </li>
                <li class="page-item active">
                  <a class="page-link" href="javascript:void(0)">
                    {page}
                  </a>
                </li>
                <li class="page-item">
                  {page < detailData?.max_pages && (
                    <a
                      class="page-link"
                      onClick={() => {
                        setAction(true);
                        setPage(page + 1);
                      }}
                    >
                      {page + 1}
                    </a>
                  )}
                </li>
                <li class="page-item">
                  {page < detailData?.max_pages && (
                    <a
                      class="page-link"
                      href="javascript:void(0)"
                      aria-label="Next"
                      onClick={() => {
                        setAction(true);
                        setPage(page + 1);
                      }}
                    >
                      <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 13L7 7L1 1" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </a>
                  )}
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
      <MoreModal
        show={showMoreModal}
        onHide={toggleMoreModal}
        userDetailData={userDetailData}
        recipientList={recipientList}
        refStart={props.refStart}
        refStop={props.refStop}
        addMessage={props.addMessage}
        deleteMessage={props.deleteMessage}
        getUserDetail={props.getUserDetail}
        changeUnsubscribe={props.changeUnsubscribe}
        auctionId={props.auctionId}
        toggleContactInformationModal={toggleContactInformationModal}
      />
      <UserContactInformationModal
        show={showContactModal}
        onHide={toggleContactInformationModal}
        userDetailData={userDetailData}
        contactInformation={contactInformation}
        setContactInformation={setContactInformation}
        stateOptions={stateOptions}
        countryOptions={countryOptions}
        loading={quickEditLoading}
        quickEditHandle={quickEditHandle}
      />
      <BuyerCategoryDataModal show={showCategoryData} onHide={toggleCategoryData} data={categoryData} />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getCustomers: (params, callback) => dispatch(getCustomers(params, callback)),
  getUserDetail: (userId, callback) => dispatch(getUserDetail(userId, callback)),
  getRecipient: (callback) => dispatch(getRecipient(callback)),
  addMessage: (params, data, callback) => dispatch(addMessage(params, data, callback)),
  deleteMessage: (params, callback) => dispatch(deleteMessage(params, callback)),
  changeUnsubscribe: (params, data, callback) => dispatch(changeUnsubscribe(params, data, callback)),
  assignTrader: (data, callback) => dispatch(assignTrader(data, callback)),
  getBuyerCategoryData: (id, callback) => dispatch(getBuyerCategoryData(id, callback)),
});
const mapStateToProps = (state) => ({
  dashboardData: dashboardData(state),
  emailClickersDetail: emailClickersDetail(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(MyListingBuyerInterest));
