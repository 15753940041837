import React, { useEffect, useRef } from "react";
import LoadingBar from "react-top-loading-bar";
import Select from "react-select";
import Header from "../../layouts/header";
import Sidebar from "../../layouts/sidebar";
import { useState } from "react";
import { connect } from "react-redux";
import { getEmployee, getInv, getInvestments, updateInvestments } from "../../store/investments";
import { customStyles } from "../../common/customStyles/reactSelectStyle";
import AlertError from "../../common/alerts/alertError";

import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getProfile } from "../../utils.js/localStorageServices";
import NextButton from "../../common/form/nextButton";

const Investments = (props) => {
  const history = useHistory();
  const ref = useRef(null);
  const [showMenu, setShowMenu] = useState(false);
  const [menuList, setMenuList] = useState("");
  const [investmentData, setInvestmentData] = useState({
    sixMonth: [],
    twelveMonth: [],
  });
  const [employeeData, setEmployeeData] = useState([]);
  const [employeeData1, setEmployeeData1] = useState([]);
  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [loadingInvestmentsSix, setLoadingInvestmentsSix] = useState(false);
  const [loadingInvestmentsTwelve, setLoadingInvestmentsTwelve] = useState(false);
  const [loadingEmp, setLoadingEmp] = useState(false);
  const [loading, setLoading] = useState(false);

  //toggle
  const [showSixYears, setShowSixYears] = useState(false);
  const [showTwelveYears, setShowTwelveYears] = useState(false);

  const member = getProfile();

  const isInvestmentAccessible =
    member && member?.accessLevels && member?.accessLevels?.employee_investment_admin_access
      ? member?.accessLevels?.employee_investment_admin_access
      : false;

  const monthsArray = [
    { name: "January", value: 1 },
    { name: "February", value: 2 },
    { name: "March", value: 3 },
    { name: "April", value: 4 },
    { name: "May", value: 5 },
    { name: "June", value: 6 },
    { name: "July", value: 7 },
    { name: "August", value: 8 },
    { name: "September", value: 9 },
    { name: "October", value: 10 },
    { name: "November", value: 11 },
    { name: "December", value: 12 },
  ];

  useEffect(() => {
    if (!isInvestmentAccessible) {
      history.push("/404");
    }
  }, [member]);
  const getDay = (month, year) => {
    if (month == 1) {
      const day = "January" + " " + year;
      return day;
    } else if (month == 2) {
      const day = "February" + " " + year;
      return day;
    } else if (month == 3) {
      const day = "March" + " " + year;
      return day;
    } else if (month == 4) {
      const day = "April" + " " + year;
      return day;
    } else if (month == 5) {
      const day = "May" + " " + year;
      return day;
    } else if (month == 6) {
      const day = "June" + " " + year;
      return day;
    } else if (month == 7) {
      const day = "July" + " " + year;
      return day;
    } else if (month == 8) {
      const day = "August" + " " + year;
      return day;
    } else if (month == 9) {
      const day = "September" + " " + year;
      return day;
    } else if (month == 10) {
      const day = "October" + " " + year;
      return day;
    } else if (month == 11) {
      const day = "November" + " " + year;
      return day;
    } else if (month == 12) {
      const day = "December" + " " + year;
      return day;
    }
  };

  useEffect(() => {
    if (loadingEmp) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [loadingEmp]);

  useEffect(() => {
    setLoadingEmp(true);
    props.getEmployee((res) => {
      if (res && res.status == 200) {
        const data = res.data?.data?.years;
        const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear();
        if (data[0]?.m == currentMonth && data[0]?.y == currentYear) {
          setYears(res.data?.data?.years);
        } else {
          let clonedObject = [...data];
          clonedObject.unshift({ m: currentMonth, y: currentYear });
          setYears(clonedObject);
        }
      }
    });
  }, []);
  const empData = props.getInv && props.getInv.employee?.data;

  const invData = empData?.investments;

  const uniqueOptions = [...new Set(invData?.map((item) => item.y))]?.sort((a, b) => a - b);

  const filterOptions = uniqueOptions?.map((item) => ({
    name: item,
    value: item,
  }));

  useEffect(() => {
    if (selectedYear == 0 && years?.length > 0) {
      var sortedData =
        invData?.slice()?.sort(function (a, b) {
          return new Date(b["y"], b["m"], 1) - new Date(a["y"], a["m"], 1);
        }) || [];

      const employeeIds =
        (empData &&
          empData?.employees &&
          empData?.employees?.length > 0 &&
          empData?.employees.map((item) => item?.user_id)) ||
        [];

      const uniqueIds = [...new Set([...sortedData?.map((item) => item?.user_id), ...employeeIds])];

      const monthData = years?.map((item) => ({
        m: item.m,
        y: item.y,
      }));

      const z = [];

      for (let i = 0; i < uniqueIds.length; i++) {
        const a = sortedData?.filter((item) => item.user_id == uniqueIds[i]);
        const b = a.map((item) => `${item.m}-${item.y}`);

        monthData?.map((item) => {
          if (!b.includes(`${item.m}-${item.y}`)) {
            z.push({
              bd: false,
              gross: 0,
              m: item.m,
              net: 0,
              user_id: uniqueIds[i],
              y: item.y,
            });
          }
        });
      }

      const x = [...sortedData, ...z];

      var finalSortedData = x?.slice()?.sort(function (a, b) {
        return new Date(b["y"], b["m"], 1) - new Date(a["y"], a["m"], 1);
      });

      setEmployeeData(finalSortedData);
      setLoadingEmp(false);
    }
  }, [empData, years]);

  useEffect(() => {
    if (employeeData1) {
      setLoading(true);
      var finalSortedData = employeeData1?.slice()?.sort(function (a, b) {
        return new Date(b["y"], b["m"], 1) - new Date(a["y"], a["m"], 1);
      });

      setEmployeeData(finalSortedData);
      setLoading(false);
    }
  }, [employeeData1]);

  useEffect(() => {
    if (selectedYear) {
      var sortedData =
        invData
          ?.slice()
          ?.filter((it) => it.y == selectedYear)
          ?.sort(function (a, b) {
            return new Date(b["y"], b["m"], 1) - new Date(a["y"], a["m"], 1);
          }) || [];

      const employeeIds =
        (empData &&
          empData?.employees &&
          empData?.employees?.length > 0 &&
          empData?.employees.map((item) => item?.user_id)) ||
        [];

      const uniqueIds = [...new Set([...sortedData?.map((item) => item?.user_id), ...employeeIds])];

      let monthData = [];
      const yearsData = empData?.years;
      const currentMonth = new Date().getMonth() + 1;
      const currentYear = new Date().getFullYear();
      if (yearsData?.[0]?.m == currentMonth && yearsData[0]?.y == currentYear) {
        monthData = yearsData
          ?.filter((it) => it?.y == selectedYear)
          .map((item) => ({
            m: item.m,
            y: item.y,
          }));
        setYears(monthData);
      } else {
        let clonedObject = [...yearsData];
        clonedObject.unshift({ m: currentMonth, y: currentYear });
        monthData = clonedObject
          ?.filter((it) => it?.y == selectedYear)
          .map((item) => ({
            m: item.m,
            y: item.y,
          }));
        setYears(monthData);
      }

      const z = [];

      for (let i = 0; i < uniqueIds.length; i++) {
        const a = sortedData?.filter((item) => item.user_id == uniqueIds[i]);
        const b = a.map((item) => `${item.m}-${item.y}`);

        monthData.map((item) => {
          if (!b.includes(`${item.m}-${item.y}`)) {
            z.push({
              bd: false,
              gross: 0,
              m: item.m,
              net: 0,
              user_id: uniqueIds[i],
              y: item.y,
            });
          }
        });
      }

      const x = [...sortedData, ...z];

      var finalSortedData = x?.slice()?.sort(function (a, b) {
        return new Date(b["y"], b["m"], 1) - new Date(a["y"], a["m"], 1);
      });

      setEmployeeData(finalSortedData);
      setLoadingEmp(false);
    } else if (selectedYear == 0) {
      setYears(empData?.years);
    }
    setSelectedMonth({ name: "", value: "" });
  }, [selectedYear]);

  useEffect(() => {
    if (selectedYear && selectedMonth && selectedMonth?.value) {
      var sortedData =
        invData
          ?.slice()
          ?.filter((it) => it?.y == selectedYear && it.m == selectedMonth?.value)
          ?.sort(function (a, b) {
            return new Date(b["y"], b["m"], 1) - new Date(a["y"], a["m"], 1);
          }) || [];

      const employeeIds =
        (empData &&
          empData?.employees &&
          empData?.employees?.length > 0 &&
          empData?.employees.map((item) => item?.user_id)) ||
        [];

      const uniqueIds = [...new Set([...sortedData?.map((item) => item?.user_id), ...employeeIds])];

      let monthData = [];
      const yearsData = empData?.years;
      const currentMonth = new Date().getMonth() + 1;
      const currentYear = new Date().getFullYear();
      if (yearsData?.[0]?.m == currentMonth && yearsData[0]?.y == currentYear) {
        monthData = yearsData
          ?.filter((it) => it?.y == selectedYear && it.m == selectedMonth?.value)
          .map((item) => ({
            m: item.m,
            y: item.y,
          }));
        setYears(monthData);
      } else {
        let clonedObject = [...yearsData];
        clonedObject.unshift({ m: currentMonth, y: currentYear });
        monthData = clonedObject
          ?.filter((it) => it?.y == selectedYear && it.m == selectedMonth?.value)
          .map((item) => ({
            m: item.m,
            y: item.y,
          }));
        setYears(monthData);
      }

      const z = [];

      for (let i = 0; i < uniqueIds.length; i++) {
        const a = sortedData?.filter((item) => item.user_id == uniqueIds[i]);
        const b = a.map((item) => `${item.m}-${item.y}`);

        monthData.map((item) => {
          if (!b.includes(`${item.m}-${item.y}`)) {
            z.push({
              bd: false,
              gross: 0,
              m: item.m,
              net: 0,
              user_id: uniqueIds[i],
              y: item.y,
            });
          }
        });
      }

      const x = [...sortedData, ...z];

      var finalSortedData = x?.slice()?.sort(function (a, b) {
        return new Date(b["y"], b["m"], 1) - new Date(a["y"], a["m"], 1);
      });

      setEmployeeData(finalSortedData);
      setLoadingEmp(false);
    } else if (selectedYear == 0) {
      setYears(empData?.years);
    }
  }, [selectedMonth]);

  useEffect(() => {
    if (empData) {
      const currentMonth = new Date().getMonth() + 1;
      const currentYear = new Date().getFullYear();
      setSelectedYear(currentYear);
      setSelectedMonth(currentMonth);
    }
  }, [empData]);

  const loadSixYears = () => {
    ref?.current?.continuousStart();
    const data = { ...investmentData };
    setLoadingInvestmentsSix(true);
    props.getInvestments({ month: 6 }, (res) => {
      if (res && res.status == 200) {
        setShowSixYears(true);
        ref?.current?.complete();
        data.sixMonth = res?.data?.data;
        setInvestmentData(data);
        setLoadingInvestmentsSix(false);
      } else {
        ref?.current?.complete();
        setLoadingInvestmentsSix(false);
        toast(<AlertError message={res?.data?.message ? res.data?.message : "Something went wrong"} />);
      }
    });
  };

  const loadTweleveYears = () => {
    ref?.current?.continuousStart();
    const data = { ...investmentData };
    setLoadingInvestmentsTwelve(true);
    props.getInvestments({ month: 12 }, (res) => {
      if (res && res.status == 200) {
        ref?.current?.complete();
        setShowTwelveYears(true);
        data.twelveMonth = res?.data?.data;
        setInvestmentData(data);
        setLoadingInvestmentsTwelve(false);
      } else {
        ref?.current?.complete();
        setLoadingInvestmentsTwelve(false);
        toast(<AlertError message={res?.data?.message ? res.data?.message : "Something went wrong"} />);
      }
    });
  };

  return (
    <>
      <LoadingBar height={5} color="#47AD1D" ref={ref} />
      <Header />

      <div class="dashboard-insider position-relative d-lg-flex w-100 ">
        <Sidebar
          showMenu={showMenu}
          setShowMenu={setShowMenu}
          menuList={menuList}
          setMenuList={setMenuList}
          page="accounting"
          pageCat="investments"
        />
        <div
          className={showMenu ? ` main-menu-expanded ` : "main-menu-compressed"}
          style={{ backgroundColor: "#F5F5F5" }}
        >
          <div class="filter-container d-flex align-items-center sticky-top">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12 d-flex align-items-center flex-wrap justify-content-between">
                  <div class="filter-title fw-medium text-capitalize investment-title-left">Employee Investment</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-row-container">
            <div className="container-fluid listing-container-investment">
              <div className="row">
                <div className="col-md-12">
                  <div className="col-row-list d-flex flex-wrap">
                    <div class="col-row-item w-50">
                      <div class="col-row-box w-100 h-100 d-flex flex-column overflow-hidden">
                        <div
                          class={`col-row-head ${
                            showSixYears ? "ml-15" : ""
                          }  pb-0 d-flex align-items-center justify-content-between`}
                        >
                          <div class="col-row-title">6 Months</div>
                        </div>
                        <div class="col-row-body m-3">
                          {!showSixYears ? (
                            <div
                              class="page-widget-empty-box text-capitalize d-flex align-items-center justify-content-center text-center"
                              style={{
                                height: "239px",
                                flexDirection: "column",
                                gap: "10px",
                              }}
                            >
                              <span>Click Here To View </span>

                              <NextButton
                                label="View"
                                classData="btn btn-default"
                                handleSubmit={() => loadSixYears()}
                                loading={loadingInvestmentsSix}
                              />
                            </div>
                          ) : (
                            <div class="col-row-table-box pt-0">
                              <div class="table-responsive table-custom">
                                <table class="table table-bordered">
                                  <colgroup>
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                  </colgroup>
                                  <thead>
                                    <tr>
                                      <td>Name</td>
                                      <td>Gross ROI</td>
                                      <td>Net ROI</td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {investmentData?.sixMonth &&
                                      investmentData?.sixMonth.length > 0 &&
                                      investmentData?.sixMonth
                                        ?.slice()
                                        ?.sort((a, b) => b?.name01 - a?.name01)
                                        ?.map((item, i) => (
                                          <tr key={i}>
                                            <td>{item?.name01}</td>
                                            <td>{item?.gross_roi ? parseFloat(item?.gross_roi).toFixed(2) : 0}</td>
                                            <td>{item?.net_roi ? parseFloat(item?.net_roi).toFixed(2) : 0}</td>
                                          </tr>
                                        ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div class="col-row-item w-50">
                      <div class="col-row-box w-100 h-100 d-flex flex-column overflow-hidden">
                        <div
                          class={`col-row-head ${
                            showTwelveYears ? "ml-15" : ""
                          }  pb-0 d-flex align-items-center justify-content-between`}
                        >
                          <div class="col-row-title">12 Months</div>
                        </div>
                        <div class="col-row-body m-3">
                          {showTwelveYears ? (
                            <div class="col-row-table-box pt-0">
                              <div class="table-responsive table-custom">
                                <table class="table table-bordered">
                                  <colgroup>
                                    <col />
                                    <col />
                                    <col />
                                    <col />
                                  </colgroup>
                                  <thead>
                                    <tr>
                                      <td>Name</td>
                                      <td>Gross ROI</td>
                                      <td>Net ROI</td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {investmentData?.twelveMonth &&
                                      investmentData?.twelveMonth.length > 0 &&
                                      investmentData?.twelveMonth
                                        ?.slice()
                                        ?.sort((a, b) => a?.name01 - b?.name01)
                                        ?.map((item, i) => (
                                          <tr key={i}>
                                            <td>{item?.name01}</td>
                                            <td>{item?.gross_roi ? parseFloat(item?.gross_roi).toFixed(2) : 0}</td>
                                            <td>{item?.net_roi ? parseFloat(item?.net_roi).toFixed(2) : 0}</td>
                                          </tr>
                                        ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          ) : (
                            <div
                              class="page-widget-empty-box text-capitalize d-flex align-items-center justify-content-center text-center"
                              style={{
                                height: "239px",
                                flexDirection: "column",
                                gap: "10px",
                              }}
                            >
                              <span>Click Here To View </span>

                              <NextButton
                                label="View"
                                classData="btn btn-default"
                                handleSubmit={() => loadTweleveYears()}
                                loading={loadingInvestmentsTwelve}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-row-container">
            <div className="container-fluid listing-container-investment">
              <div className="row">
                <div className="col-md-12">
                  <div class="details-page-widget-block">
                    <div class="page-widget-head d-flex align-items-center justify-content-between">
                      <div class="d-flex align-items-center">
                        <div class="page-widget-label text-capitalize"></div>
                      </div>
                    </div>
                    <div class="page-widget-body mt-3">
                      <div className="d-flex">
                        <table class="table table-borderless align-middle table-left-align filter-investment mr-20">
                          <colgroup>
                            <col style={{ width: "32%" }} />
                          </colgroup>
                          <tbody>
                            <tr>
                              <td>Filter Year</td>
                              <td>
                                <Select
                                  className="basic-single investment-filter av_viewall_modal1"
                                  classNamePrefix="select-search investment-filter av_viewall_modal1"
                                  placeholder="Please Select a Year"
                                  options={[{ name: "Select Year", value: 0 }, ...filterOptions]}
                                  isDisabled={loading ? true : false}
                                  styles={customStyles}
                                  getOptionLabel={(option) => option.name}
                                  getOptionValue={(option) => option.value}
                                  onChange={(e) => setSelectedYear(e.value)}
                                  name="InvestmentYear"
                                  value={{ name: selectedYear, value: selectedYear }}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <table class="table table-borderless align-middle table-left-align filter-investment ">
                          <colgroup>
                            <col style={{ width: "32%" }} />
                          </colgroup>
                          <tbody>
                            <tr>
                              <td>Filter Month</td>
                              <td>
                                <Select
                                  className="basic-single investment-filter"
                                  classNamePrefix="select-search investment-filter"
                                  placeholder="Please Select a Year"
                                  options={[{ name: "Select Month", value: 0 }, ...monthsArray]}
                                  isDisabled={loading ? true : false}
                                  styles={customStyles}
                                  getOptionLabel={(option) => option.name}
                                  getOptionValue={(option) => option.value}
                                  onChange={(e) => setSelectedMonth(e)}
                                  name="InvestmentYear"
                                  value={{ name: selectedMonth?.name, value: selectedMonth?.value }}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="table-responsive table-custom emp-inv-table">
                        {loading ? (
                          <table class="table table-bordered">
                            <colgroup>
                              <col />
                              <col />
                            </colgroup>
                            <thead>
                              <tr>
                                <th rowSpan="2">{loading ? <Skeleton /> : "Employee"}</th>
                                {[1, 2, 3, 4, 5].map((item) => (
                                  <th colSpan="3">
                                    <Skeleton />
                                  </th>
                                ))}
                              </tr>
                              <tr>
                                {[1, 2, 3, 4, 5].map((item) => (
                                  <>
                                    <th style={{ fontWeight: "500" }}>
                                      <Skeleton />
                                    </th>
                                    <th style={{ fontWeight: "500" }}>
                                      <Skeleton />
                                    </th>
                                    <th style={{ fontWeight: "500" }}>
                                      <Skeleton />
                                    </th>
                                  </>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {[1, 2, 3, 4, 5].map((emp, i) => (
                                <tr key={i}>
                                  <td>
                                    <Skeleton />
                                  </td>
                                  {[1, 2, 3, 4, 5].map((sd, j) => (
                                    <>
                                      <td>
                                        <div class="form-check tick-checkbox d-flex align-items-center">
                                          <Skeleton />
                                        </div>
                                      </td>
                                      <td style={{ minWidth: "130px" }}>
                                        <Skeleton />
                                      </td>
                                      <td style={{ minWidth: "130px" }}>
                                        <Skeleton />
                                      </td>
                                    </>
                                  ))}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        ) : (
                          <table class="table table-bordered ">
                            <colgroup>
                              <col />
                              <col />
                            </colgroup>
                            <thead>
                              <tr>
                                <th rowSpan="2" className="employee" style={{ minWidth: "200px" }}>
                                  Employee
                                </th>
                                {years &&
                                  years?.length > 0 &&
                                  years?.map((item, i) => (
                                    <th key={i} colSpan="4" className="years-tab">
                                      {getDay(item.m, item.y)}
                                    </th>
                                  ))}
                              </tr>
                              <tr class="inv-labels">
                                {years &&
                                  years?.length > 0 &&
                                  years?.map((item) => (
                                    <>
                                      <th style={{ fontWeight: "500" }}>R&D</th>
                                      <th style={{ fontWeight: "500" }}>Legal</th>
                                      <th style={{ fontWeight: "500" }}>Gross</th>
                                      <th style={{ fontWeight: "500" }}>Net</th>
                                    </>
                                  ))}
                              </tr>
                            </thead>
                            <tbody>
                              {empData?.employees &&
                                empData?.employees?.length > 0 &&
                                empData?.employees?.map((emp, i) => (
                                  <tr key={i}>
                                    <th>{emp?.name01}</th>
                                    {employeeData &&
                                      employeeData.length > 0 &&
                                      employeeData.map((sd, j) => (
                                        <React.Fragment key={`emph${j}`}>
                                          {emp?.user_id == sd?.user_id && (
                                            <React.Fragment key={`emp${i}`}>
                                              {console.log("poiuytrewd", `emp${i}`)}
                                              <td>
                                                <div class="form-check tick-checkbox d-flex align-items-center">
                                                  <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    id={`${sd?.user_id}${j}`}
                                                    checked={sd?.bd}
                                                    onChange={(e) => {
                                                      const data = [...employeeData];

                                                      const empData = data?.filter(
                                                        (ct) =>
                                                          sd?.user_id == ct?.user_id &&
                                                          `${ct?.m}-${ct?.y}` == `${sd?.m}-${sd?.y}`,
                                                      )?.[0];
                                                      let clonedObject = {
                                                        ...empData,
                                                      };

                                                      if (empData.bd) {
                                                        clonedObject = {
                                                          ...clonedObject,
                                                          bd: false,
                                                        };
                                                      } else {
                                                        clonedObject = {
                                                          ...clonedObject,
                                                          bd: true,
                                                        };
                                                      }
                                                      const otherData = data?.filter(
                                                        (ct) =>
                                                          `${ct?.m}-${ct?.y}-${ct?.user_id}` !=
                                                          `${sd?.m}-${sd?.y}-${sd?.user_id}`,
                                                      );
                                                      setEmployeeData1([...otherData, clonedObject]);
                                                      const payload = {
                                                        type: "bd",
                                                        user_id: sd?.user_id,
                                                        month: sd?.m,
                                                        year: sd?.y,
                                                        value: clonedObject?.bd ? 1 : 0,
                                                      };
                                                      ref?.current?.continuousStart();
                                                      props.updateInvestments(payload, (res) => {
                                                        if (res && res.status == 200) {
                                                          ref?.current?.complete();
                                                        } else {
                                                          ref?.current?.complete();
                                                          toast(
                                                            <AlertError
                                                              message={
                                                                res && res.data && res.data.message
                                                                  ? res.data.message
                                                                  : "Something went wrong"
                                                              }
                                                            />,
                                                          );
                                                        }
                                                      });
                                                    }}
                                                    onBlur={(e) => {
                                                      const data = [...employeeData];

                                                      const empData = data?.filter(
                                                        (ct) =>
                                                          sd?.user_id == ct?.user_id &&
                                                          `${ct?.m}-${ct?.y}` == `${sd?.m}-${sd?.y}`,
                                                      )?.[0];
                                                      let clonedObject = {
                                                        ...empData,
                                                      };
                                                      const otherData = data?.filter(
                                                        (ct) =>
                                                          `${ct?.m}-${ct?.y}-${ct?.user_id}` !=
                                                          `${sd?.m}-${sd?.y}-${sd?.user_id}`,
                                                      );
                                                      setEmployeeData1([...otherData, clonedObject]);
                                                    }}
                                                  />
                                                  <label
                                                    class="form-check-label fw-medium"
                                                    for={`${sd?.user_id}${j}`}
                                                  ></label>
                                                </div>
                                              </td>
                                              <td>
                                                <div class="form-check tick-checkbox d-flex align-items-center">
                                                  <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    id={`${sd?.user_id}${j}00`}
                                                    checked={sd?.legal}
                                                    onChange={(e) => {
                                                      const data = [...employeeData];

                                                      const empData = data?.filter(
                                                        (ct) =>
                                                          sd?.user_id == ct?.user_id &&
                                                          `${ct?.m}-${ct?.y}` == `${sd?.m}-${sd?.y}`,
                                                      )?.[0];
                                                      let clonedObject = {
                                                        ...empData,
                                                      };

                                                      if (empData.legal) {
                                                        clonedObject = {
                                                          ...clonedObject,
                                                          legal: false,
                                                        };
                                                      } else {
                                                        clonedObject = {
                                                          ...clonedObject,
                                                          legal: true,
                                                        };
                                                      }
                                                      const otherData = data?.filter(
                                                        (ct) =>
                                                          `${ct?.m}-${ct?.y}-${ct?.user_id}` !=
                                                          `${sd?.m}-${sd?.y}-${sd?.user_id}`,
                                                      );
                                                      setEmployeeData1([...otherData, clonedObject]);
                                                      const payload = {
                                                        type: "legal",
                                                        user_id: sd?.user_id,
                                                        month: sd?.m,
                                                        year: sd?.y,
                                                        value: clonedObject?.legal ? 1 : 0,
                                                      };
                                                      ref?.current?.continuousStart();
                                                      props.updateInvestments(payload, (res) => {
                                                        if (res && res.status == 200) {
                                                          ref?.current?.complete();
                                                        } else {
                                                          ref?.current?.complete();
                                                          toast(
                                                            <AlertError
                                                              message={
                                                                res && res.data && res.data.message
                                                                  ? res.data.message
                                                                  : "Something went wrong"
                                                              }
                                                            />,
                                                          );
                                                        }
                                                      });
                                                    }}
                                                    onBlur={(e) => {
                                                      const data = [...employeeData];

                                                      const empData = data?.filter(
                                                        (ct) =>
                                                          sd?.user_id == ct?.user_id &&
                                                          `${ct?.m}-${ct?.y}` == `${sd?.m}-${sd?.y}`,
                                                      )?.[0];
                                                      let clonedObject = {
                                                        ...empData,
                                                      };
                                                      const otherData = data?.filter(
                                                        (ct) =>
                                                          `${ct?.m}-${ct?.y}-${ct?.user_id}` !=
                                                          `${sd?.m}-${sd?.y}-${sd?.user_id}`,
                                                      );
                                                      setEmployeeData1([...otherData, clonedObject]);
                                                    }}
                                                  />
                                                  <label
                                                    class="form-check-label fw-medium"
                                                    for={`${sd?.user_id}${j}00`}
                                                  ></label>
                                                </div>
                                              </td>
                                              <td style={{ minWidth: "130px" }}>
                                                <input
                                                  class="form-control"
                                                  type="text"
                                                  value={sd?.gross}
                                                  onChange={(e) => {
                                                    const data = [...employeeData];

                                                    const empData = data?.filter(
                                                      (ct) =>
                                                        sd?.user_id == ct?.user_id &&
                                                        `${ct?.m}-${ct?.y}` == `${sd?.m}-${sd?.y}`,
                                                    )?.[0];
                                                    let clonedObject = {
                                                      ...empData,
                                                    };
                                                    clonedObject.gross = e.target.value;
                                                    const otherData = data?.filter(
                                                      (ct) =>
                                                        `${ct?.m}-${ct?.y}-${ct?.user_id}` !=
                                                        `${sd?.m}-${sd?.y}-${sd?.user_id}`,
                                                    );
                                                    setEmployeeData1([...otherData, clonedObject]);
                                                  }}
                                                  onBlur={(e) => {
                                                    const data = [...employeeData];

                                                    const empData = data?.filter(
                                                      (ct) =>
                                                        sd?.user_id == ct?.user_id &&
                                                        `${ct?.m}-${ct?.y}` == `${sd?.m}-${sd?.y}`,
                                                    )?.[0];
                                                    let clonedObject = {
                                                      ...empData,
                                                    };

                                                    const otherData = data?.filter(
                                                      (ct) =>
                                                        `${ct?.m}-${ct?.y}-${ct?.user_id}` !=
                                                        `${sd?.m}-${sd?.y}-${sd?.user_id}`,
                                                    );
                                                    setEmployeeData1([...otherData, clonedObject]);
                                                    const payload = {
                                                      type: "gross",
                                                      user_id: sd?.user_id,
                                                      month: sd?.m,
                                                      year: sd?.y,
                                                      value: empData?.gross ? empData?.gross : 0,
                                                    };
                                                    ref?.current?.continuousStart();
                                                    props.updateInvestments(payload, (res) => {
                                                      if (res && res.status == 200) {
                                                        ref?.current?.complete();
                                                      } else {
                                                        ref?.current?.complete();
                                                        toast(
                                                          <AlertError
                                                            message={
                                                              res && res.data && res.data.message
                                                                ? res.data.message
                                                                : "Something went wrong"
                                                            }
                                                          />,
                                                        );
                                                      }
                                                    });
                                                  }}
                                                />
                                              </td>
                                              <td style={{ minWidth: "130px" }}>
                                                <input
                                                  class="form-control"
                                                  type="text"
                                                  value={sd?.net}
                                                  onChange={(e) => {
                                                    const data = [...employeeData];

                                                    const empData = data?.filter(
                                                      (ct) =>
                                                        sd?.user_id == ct?.user_id &&
                                                        `${ct?.m}-${ct?.y}` == `${sd?.m}-${sd?.y}`,
                                                    )?.[0];
                                                    let clonedObject = {
                                                      ...empData,
                                                    };
                                                    clonedObject.net = e.target.value;
                                                    const otherData = data?.filter(
                                                      (ct) =>
                                                        `${ct?.m}-${ct?.y}-${ct?.user_id}` !=
                                                        `${sd?.m}-${sd?.y}-${sd?.user_id}`,
                                                    );

                                                    setEmployeeData1([...otherData, clonedObject]);
                                                  }}
                                                  onBlur={(e) => {
                                                    const data = [...employeeData];

                                                    const empData = data?.filter(
                                                      (ct) =>
                                                        sd?.user_id == ct?.user_id &&
                                                        `${ct?.m}-${ct?.y}` == `${sd?.m}-${sd?.y}`,
                                                    )?.[0];
                                                    let clonedObject = {
                                                      ...empData,
                                                    };
                                                    const otherData = data?.filter(
                                                      (ct) =>
                                                        `${ct?.m}-${ct?.y}-${ct?.user_id}` !=
                                                        `${sd?.m}-${sd?.y}-${sd?.user_id}`,
                                                    );
                                                    setEmployeeData1([...otherData, clonedObject]);
                                                    const payload = {
                                                      type: "net",
                                                      user_id: sd?.user_id,
                                                      month: sd?.m,
                                                      year: sd?.y,
                                                      value: empData?.net ? empData?.net : 0,
                                                    };
                                                    ref?.current?.continuousStart();
                                                    props.updateInvestments(payload, (res) => {
                                                      if (res && res.status == 200) {
                                                        ref?.current?.complete();
                                                      } else {
                                                        ref?.current?.complete();
                                                        toast(
                                                          <AlertError
                                                            message={
                                                              res && res.data && res.data.message
                                                                ? res.data.message
                                                                : "Something went wrong"
                                                            }
                                                          />,
                                                        );
                                                      }
                                                    });
                                                  }}
                                                />
                                              </td>
                                            </React.Fragment>
                                          )}
                                        </React.Fragment>
                                      ))}
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  getInv: getInv(state),
});

const mapDispatchtoProps = (dispatch) => ({
  getInvestments: (params, callback) => dispatch(getInvestments(params, callback)),
  getEmployee: (callback) => dispatch(getEmployee(callback)),
  updateInvestments: (data, callback) => dispatch(updateInvestments(data, callback)),
});

export default connect(mapStateToProps, mapDispatchtoProps)(React.memo(Investments));
