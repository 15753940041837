import React from "react";
import close from "../../../include/images/close-12x12.svg";
import { Offcanvas } from "react-bootstrap";
import NextButton from "../../../common/form/nextButton";
import Select from "react-select";
import { customStyles } from "../../../common/customStyles/reactSelectStyle";
import Skeleton from "react-loading-skeleton";

const ProfileOptions = (props) => {
  const detailData = props && props.profileOptionsData;
  const stateValue = {
    name: detailData.p_state,
    value: detailData.p_state_id,
  };
  const countryValue = {
    name: detailData.p_country,
    value: detailData.p_country_id,
  };
  const industryValue = {
    name: detailData.industry,
    value: detailData.industry_id,
  };
  return (
    <>
      <Offcanvas
        placement="end"
        backdropClassName="upload-documnets-backdrop"
        show={props.show}
        onHide={props.onHide}
        className="offcanvas offcanvas-end border-0 width-950"
        id="verifyDocsOffcanvas"
        enforceFocus={false}
      >
        <Offcanvas.Header className="offcanvas-header">
          <h5 class="offcanvas-title">Profile</h5>
          <button
            type="button"
            class="offcanvas-close-btn d-flex align-items-center justify-content-center"
            data-bs-dismiss="offcanvas"
            onClick={props.onHide}
          >
            <img src={close} alt="" />
          </button>
        </Offcanvas.Header>
        <Offcanvas.Body className="offcanvas-body p-0">
          <div class="offcanvas-widget-row">
            <div class="table-responsive hide-scrollbar mb-3">
              {props.loading ? (
                <>
                  <div className="mb-4 ">
                    <Skeleton height="40px" />
                  </div>

                  <div className="d-flex add-user-flex">
                    <div className="mb-4 add-user-input-width">
                      <Skeleton height="40px" />
                    </div>
                    <div className="mb-4 add-user-input-width">
                      <Skeleton height="40px" />
                    </div>
                  </div>

                  <div class="dividerTable mt-4  mb-45"></div>

                  <div className="d-flex add-user-flex">
                    <div className="mb-4 add-user-input-width">
                      <Skeleton height="40px" />
                    </div>
                    <div className="mb-4 add-user-input-width">
                      <Skeleton height="40px" />
                    </div>
                  </div>

                  <div className="mb-4">
                    <Skeleton height="40px" />
                  </div>
                  <div className="d-flex add-user-flex">
                    <div className="mb-4 add-user-input-width">
                      <Skeleton height="40px" />
                    </div>
                    <div className="mb-4 add-user-input-width">
                      <Skeleton height="40px" />
                    </div>
                  </div>
                  <div className="d-flex add-user-flex">
                    <div className="mb-4 add-user-input-width">
                      <Skeleton height="40px" />
                    </div>
                    <div className="mb-4 add-user-input-width">
                      <Skeleton height="40px" />
                    </div>
                  </div>
                  <div className="d-flex add-user-flex">
                    <div className="d-flex mb-4 add-user-input-width">
                      <div className="w-70 me-3" style={{ width: "70%" }}>
                        <Skeleton height="40px" />
                      </div>
                      <div className="w-30" style={{ width: "30%" }}>
                        <Skeleton height="40px" />
                      </div>
                    </div>
                    <div className="mb-4 add-user-input-width">
                      <Skeleton height="40px" />
                    </div>
                  </div>

                  <div class="dividerTable mt-4"></div>
                </>
              ) : (
                <>
                  <div className="mb-4 ">
                    <label class="form-label" for="firstName">
                      {" "}
                      Full Name<sup style={{ color: "Red" }}>*</sup>
                    </label>
                    <input
                      name="firstName"
                      type="text"
                      value={detailData.p_name01 ? detailData.p_name01 : ""}
                      disabled
                      className="form-control"
                    />
                  </div>

                  <div className="d-flex add-user-flex">
                    <div className="mb-4 add-user-input-width">
                      <label class="form-label" for="mobile">
                        Mobile Number<sup style={{ color: "Red" }}>*</sup>
                      </label>
                      <input
                        type="text"
                        name="mobile"
                        value={detailData.p_phone ? detailData.p_phone : ""}
                        disabled
                        className="form-control"
                      />
                    </div>
                    <div className="mb-4 add-user-input-width">
                      <label class="form-label" for="email">
                        Email<sup style={{ color: "Red" }}>*</sup>
                      </label>
                      <input
                        name="email"
                        type="text"
                        disabled
                        value={detailData.p_email && detailData.p_email != "undefined" ? detailData.p_email : ""}
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div class="dividerTable mt-4  mb-45"></div>

                  {detailData && detailData?.user_type && detailData?.user_type == "company" ? (
                    <div className="d-flex add-user-flex">
                      <div className="mb-4 add-user-input-width">
                        <label class="form-label" for="industry">
                          Industry<sup style={{ color: "Red" }}>*</sup>
                        </label>
                        <Select
                          value={industryValue}
                          className="basic-single select-disabled-background"
                          isDisabled
                          classNamePrefix="select-search select-disabled-background"
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.value}
                          name="state"
                          placeholder="Select State"
                          styles={customStyles}
                        />
                      </div>
                      <div className="mb-4 add-user-input-width">
                        <label class="form-label" for="company">
                          Company
                        </label>
                        <input
                          name="company"
                          type="text"
                          value={detailData.p_company_name ? detailData.p_company_name : ""}
                          disabled
                          className="form-control"
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="mb-4 ">
                      <label class="form-label" for="industry">
                        Industry<sup style={{ color: "Red" }}>*</sup>
                      </label>
                      <Select
                        value={industryValue}
                        className="basic-single select-disabled-background"
                        isDisabled
                        classNamePrefix="select-search select-disabled-background"
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.value}
                        name="state"
                        placeholder="Select State"
                        styles={customStyles}
                      />
                    </div>
                  )}

                  <div className="mb-4">
                    <label class="form-label" for="address">
                      Address
                    </label>
                    <input
                      name="address"
                      type="text"
                      value={detailData.p_address_line_1 ? detailData.p_address_line_1 : ""}
                      disabled
                      className="form-control"
                    />
                  </div>
                  <div className="d-flex add-user-flex">
                    <div className="mb-4 add-user-input-width">
                      <label class="form-label" for="city">
                        City
                      </label>
                      <input
                        name="city"
                        type="text"
                        value={detailData.p_city ? detailData.p_city : ""}
                        disabled
                        className="form-control"
                      />
                    </div>
                    <div className="mb-4 add-user-input-width">
                      <label class="form-label" for="zip">
                        Zip Code
                      </label>
                      <input
                        name="zip"
                        type="text"
                        value={detailData.p_zip ? detailData.p_zip : ""}
                        disabled
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="d-flex add-user-flex">
                    <div className="mb-4 add-user-input-width">
                      <label class="form-label" for="country">
                        Country
                      </label>
                      <Select
                        value={countryValue}
                        className="basic-single select-disabled-background"
                        isDisabled
                        classNamePrefix="select-search select-disabled-background"
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.value}
                        name="state"
                        placeholder=""
                        styles={customStyles}
                      />
                    </div>
                    <div className="mb-4 add-user-input-width">
                      <label class="form-label" for="state">
                        State
                      </label>
                      <Select
                        value={stateValue}
                        className="basic-single select-disabled-background"
                        isDisabled
                        classNamePrefix="select-search select-disabled-background"
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.value}
                        name="state"
                        placeholder="Select State"
                        styles={customStyles}
                      />
                    </div>
                  </div>
                  <div className="d-flex add-user-flex">
                    <div className="d-flex mb-4 add-user-input-width">
                      <div className="w-70 me-3" style={{ width: "70%" }}>
                        <label class="form-label" for="phone">
                          Business Phone
                        </label>
                        <input
                          name="phone"
                          type="text"
                          value={
                            detailData?.companyPhone?.includes(",")
                              ? detailData?.companyPhone?.split(",")[0]
                              : detailData?.companyPhone != "undefined"
                              ? detailData?.companyPhone
                              : ""
                          }
                          disabled
                          className="form-control"
                        />
                      </div>
                      <div className="w-30" style={{ width: "30%" }}>
                        <label class="form-label" for="ext">
                          Ext
                        </label>
                        <input
                          name="ext"
                          type="text"
                          value={
                            detailData?.companyPhone?.includes(",") &&
                            detailData?.companyPhone?.split(",")[1] &&
                            detailData?.companyPhone?.split(",")[1] != "undefined"
                              ? detailData?.companyPhone?.split(",")[1]
                              : ""
                          }
                          disabled
                          className="form-control"
                        />
                      </div>
                    </div>
                    {detailData && detailData?.user_type && detailData?.user_type == "company" && (
                      <div className="mb-4 add-user-input-width">
                        <label class="form-label" for="website">
                          Website
                        </label>
                        <input
                          name="website"
                          type="text"
                          value={detailData?.web_site ? detailData?.web_site : ""}
                          disabled
                          className="form-control"
                        />
                      </div>
                    )}
                  </div>

                  <div class="dividerTable mt-4"></div>
                </>
              )}
            </div>

            <div className="d-flex justify-content-end">
              {!props.loading && (
                <button
                  class="btn btn-default"
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => props.onHide()}
                >
                  Close
                </button>
              )}
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default ProfileOptions;
