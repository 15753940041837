import React, { useEffect, useState } from "react";
import helpCircle from "../../../include/images/help-circle.svg";
import Select from "react-select";
import extLink from "../../../include/images/external-link.svg";

import greenEmail from "../../../include/images/green-mail.svg";
import notVerified from "../../../include/images/mail-not-verified.svg";
import invalid from "../../../include/images/mail-invalid.svg";
import spamEmail from "../../../include/images/mail-spammed-blocked.svg";
import purpleEmail from "../../../include/images/mail-purple.svg";

//Alerts
import AlertSuccess from "../../../common/alerts/alertSuccess";
import AlertError from "../../../common/alerts/alertError";
import {
  buyerInterestsData,
  getBuyerInterests,
  getBuyerMotivation,
  getSellerMotivation,
  getTaskType,
  saveNote,
  sendInspectionSupportRequest,
  sendLogisticSupportRequest,
  sendProductInfoRequest,
  updateBidStatus,
  updateBuyerBudget,
  updateBuyerMotivation,
  updateBuyerMotivationCheckbox,
  updateMaxBidStatus,
} from "../../../store/buyerInterests";
import { connect } from "react-redux";
import DateFormat from "../../../common/DateFormat/dateFormat";
import { customStyles } from "../../../common/customStyles/reactSelectStyle";
import { toast } from "react-toastify";
import { getRequestor } from "../../../store/payables";
import SellerMotivationModal from "../../../layouts/modals/buyerInterestsModal/sellerMotivationModal";
import BuyerMotivationModal from "../../../layouts/modals/buyerInterestsModal/buyerMotivationModal";
import { flatMap } from "lodash";
import { ref } from "joi";
import { buyerInterest } from "../../../store/emailClickers";
import EditBuyerMotivationModal from "../../../layouts/modals/buyerInterestsModal/editBuyerMotivationModal";
import NextButton from "../../../common/form/nextButton";
import Skeleton from "react-loading-skeleton";
import PreSellerMotivationModal from "../../../layouts/modals/sellerMotivationModal.jsx/preSellerMotivationModal";
import { getMotivationLog, getPreMotivation } from "../../../store/listings";

const customStyles1 = {
  indicatorSeparator: (styles) => ({ display: "none" }),

  option: (provided, state) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "#47ad1d",
      color: "#F5F5F5",
    },
    display: "-webkit-box",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    color: state.isSelected ? "#fff" : "black",
    backgroundColor: state.isSelected ? "#47ad1d" : provided.backgroundColor,
    maxHeight: "84px",
  }),
  control: (base, state) => ({
    ...base,
    height: 42,
    minHeight: 42,
    background: state.isDisabled ? "#e9ecef" : "white",
    borderColor: state.isFocused ? "#47AD1D" : "#e4e4e4",
    boxShadow: state.isFocused ? "0 0 0 0.5px #47AD1D" : "0",
    border: state.isHovered ? "0" : "0px solid #e4e4e4",
    color: state.isDisabled ? "black" : "#f5f5f5",

    "&:hover": {
      borderColor: state.isFocused ? "#47AD1D" : "0",
      boxShadow: state.isFocused ? "0 0 0 0.5px #47AD1D" : "0",
    },
    "&:disabled": {
      background: "#e9ecef",
    },
  }),
  multiValueRemove: (base, state) => ({
    ...base,
    "&:hover": {
      backgroundColor: "rgb(95, 50, 187, 10%)",
      color: "#6119c0",
    },
  }),
};

const BuyerInterests = ({ filterData, setFilterData, ...props }) => {
  const detailData = props?.buyerInterestsData?.buyerinterests;
  const [taskType, setTaskType] = useState([]);
  const [requestor, setRequestor] = useState([]);
  const [saveNote, setSaveNote] = useState(false);
  const [saveProductInfo, setSaveProductInfo] = useState(false);
  const [saveInspectionSupport, setSaveInspectionSupport] = useState(false);
  const [saveLogisticSupport, setSaveLogisticSupport] = useState(false);
  const [showSellerMotivation, setShowSellerMotivation] = useState(false);
  const [showBuyerMotivation, setShowBuyerMotivation] = useState(false);
  const [showAdditionalProductInformation, setShowAdditionalProductInformation] = useState(false);
  const [showAdditionalInspectionInfo, setShowAdditionalInspectionInfo] = useState(false);
  const [showAdditionalLogisticsInfo, setShowAdditionalLogisticsInfo] = useState(false);
  const [userId, setUserId] = useState("");
  const [sellerMotivationData, setSellerMotivationData] = useState({});
  const [buyerMotivationData, setBuyerMotivationData] = useState({});
  const [page, setPage] = useState(1);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [loadingSaveNote, setLoadingSaveNote] = useState(false);
  const [updateBuyerMotivationData, setUpdateBuyerMotivationData] = useState({
    information_id: [
      "Buying Interest",
      "Purchase Reason",
      "Timeline for Purchase",
      "Product Condition",
      "Fund Transfer Preference",
      "Max Budget Given",
      "Cumulative Sales",
      "Buyer Communications",
      "Buyer Motivation Other Information",
    ],
    information_text_1: "",
    information_text_2: "",
    information_text_3: "",
    information_text_4: "",
    information_text_5: "",
    information_text_6: "",
    information_text_7: "",
    information_text_8: "",
    information_text_9: "",
  });
  const [buyerMotivationCheckboxData, setBuyerMotivationCheckboxData] = useState({
    value: "",
    check: 0,
    len: 1,
    dontName: "check",
  });

  //Log State
  const [log1, setLog1] = useState({});
  const [log2, setLog2] = useState({});
  const [log3, setLog3] = useState({});
  const [log4, setLog4] = useState({});
  const [log5, setLog5] = useState({});
  const [log6, setLog6] = useState({});
  const [log7, setLog7] = useState({});
  const [log8, setLog8] = useState({});
  const [log9, setLog9] = useState({});
  const [log10, setLog10] = useState({});
  const [log11, setLog11] = useState({});

  const data1 = {
    type: ["checkbox_2,checkbox_3,checkbox_26,checkbox_28,checkbox_29,checkbox_30,checkbox_66,checkbox_49"],
  };
  const data2 = {
    type: ["checkbox_5,checkbox_6,checkbox_7,checkbox_8"],
  };
  const data3 = {
    type: ["checkbox_10,checkbox_11,checkbox_12"],
  };
  const data4 = {
    type: ["checkbox_68,checkbox_69,checkbox_70,checkbox_71,checkbox_72,checkbox_73"],
  };
  const data5 = {
    type: ["checkbox_61,checkbox_32,checkbox_62,checkbox_63,checkbox_33,checkbox_64"],
  };
  const data6 = {
    type: ["checkbox_42,checkbox_43,checkbox_44,checkbox_45"],
  };
  const data7 = {
    type: ["checkbox_27,checkbox_47,checkbox_48,checkbox_58,checkbox_59,checkbox_60"],
  };
  const data8 = {
    type: ["checkbox_25,checkbox_14,checkbox_15,checkbox_16"],
  };
  const data9 = {
    type: ["checkbox_18,checkbox_54,checkbox_19,checkbox_20,checkbox_21,checkbox_55,checkbox_56"],
  };
  const data10 = {
    type: ["checkbox_22,checkbox_23,checkbox_24,checkbox_51,checkbox_52"],
  };
  const data11 = {
    type: ["checkbox_35,checkbox_36,checkbox_37,checkbox_38,checkbox_39,checkbox_40,checkbox_50"],
  };

  useEffect(() => {
    if (userId) {
      props.getMotivationLog(userId, data1, (res) => {
        if (res.status === 200) {
          setLog1(res.data.data);
        }
      });
      props.getMotivationLog(userId, data2, (res) => {
        if (res.status === 200) {
          setLog2(res.data.data);
        }
      });
      props.getMotivationLog(userId, data3, (res) => {
        if (res.status === 200) {
          setLog3(res.data.data);
        }
      });
      props.getMotivationLog(userId, data4, (res) => {
        if (res.status === 200) {
          setLog4(res.data.data);
        }
      });
      props.getMotivationLog(userId, data5, (res) => {
        if (res.status === 200) {
          setLog5(res.data.data);
        }
      });
      props.getMotivationLog(userId, data6, (res) => {
        if (res.status === 200) {
          setLog6(res.data.data);
        }
      });
      props.getMotivationLog(userId, data7, (res) => {
        if (res.status === 200) {
          setLog7(res.data.data);
        }
      });
      props.getMotivationLog(userId, data8, (res) => {
        if (res.status === 200) {
          setLog8(res.data.data);
        }
      });
      props.getMotivationLog(userId, data9, (res) => {
        if (res.status === 200) {
          setLog9(res.data.data);
        }
      });
      props.getMotivationLog(userId, data10, (res) => {
        if (res.status === 200) {
          setLog10(res.data.data);
        }
      });
      props.getMotivationLog(userId, data11, (res) => {
        if (res.status === 200) {
          setLog11(res.data.data);
        }
      });
    }
  }, [userId]);

  //Update Buyer Motivation Checkbox
  useEffect(() => {
    if (props.auctionId) {
      props.updateBuyerMotivationCheckbox(userId, buyerMotivationCheckboxData, (res) => {
        props.refStart();
        if (res.status === 200) {
          props.refStop();
        } else {
          props.refStop();
          toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        }
      });
    }
  }, [buyerMotivationCheckboxData]);

  //Filter Buyer Interests
  useEffect(() => {
    const filter = filterData.length > 0 ? filterData.join(",") : "";
    const getBuyerInterest = () => {
      props.getBuyerInterests(props.auctionId, page, filter, (res) => {
        if (res.status === 200) {
          props.refStop();
        } else {
          props.refStop();
          toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        }
      });
    };
    if (props.auctionId) {
      props.refStart();
      getBuyerInterest();
    }
  }, [filterData, page]);

  //Get Task Type & Requestor
  useEffect(() => {
    props.getTaskType((res) => {
      if (res.status === 200) {
        setTaskType(res.data.data);
      }
    });
    props.getRequestor((res) => {
      if (res.status === 200) {
        setRequestor(res.data.data);
      }
    });
  }, []);

  //Toggle Seller Motivation Modal
  const toggleSellerMotivation = () => {
    setShowSellerMotivation(!showSellerMotivation);
  };
  useEffect(() => {
    if (showSellerMotivation) {
      props.getSellerMotivation(props.auctionId, userId, (res) => {
        if (res.status === 200) {
          setSellerMotivationData(res.data.data);
        }
      });
    }
  }, [showSellerMotivation]);

  //Toggle Buyer Motivation Modal
  const toggleBuyerMotivation = () => {
    setShowBuyerMotivation(!showBuyerMotivation);
  };
  useEffect(() => {
    if (showBuyerMotivation) {
      props.getBuyerMotivation(props.auctionId, userId, (res) => {
        if (res.status === 200) {
          setBuyerMotivationData(res.data.data);
          const val = res.data.data;
          const data = { ...updateBuyerMotivationData };
          data.information_text_1 = val?.motivation?.find((item) => item.type === "Buying_Interest")?.motivation;
          data.information_text_2 = val?.motivation?.find((item) => item.type === "Purchase_Reason")?.motivation;
          data.information_text_3 = val?.motivation?.find((item) => item.type === "Timeline_for_Purchase")?.motivation;
          data.information_text_4 = val?.motivation?.find((item) => item.type === "Product_Condition")?.motivation;
          data.information_text_5 = val?.motivation?.find(
            (item) => item.type === "Fund_Transfer_Preference",
          )?.motivation;
          data.information_text_6 = val?.motivation?.find((item) => item.type === "Max_Budget_Given")?.motivation;
          data.information_text_7 = val?.motivation?.find((item) => item.type === "Cumulative_Sales")?.motivation;
          data.information_text_8 = val?.motivation?.find((item) => item.type === "Buyer_Communications")?.motivation;
          data.information_text_9 = val?.motivation?.find(
            (item) => item.type === "Buyer_Motivation_Other_Information",
          )?.motivation;
          setUpdateBuyerMotivationData(data);
        }
      });
    }
  }, [showBuyerMotivation]);

  const handleUpdateBuyerMotivationTextFields = () => {
    setLoadingUpdate(true);
    props.refStart();
    props.updateBuyerMotivation(userId, updateBuyerMotivationData, (res) => {
      if (res.status === 200) {
        toggleEditBuyerMotivation();
        setLoadingUpdate(false);
        props.refStop();
      } else {
        setLoadingUpdate(false);
        props.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  //Bids Status Options
  const bidStatusOptions = [
    { name: "Don't Know", value: "Don't Know" },
    { name: "Buyer Bid On Listing", value: "Buyer Bid On Listing" },
    { name: "Buyer Will Bid", value: "Buyer Will Bid" },
    { name: "Not Interested", value: "Not Interested" },
    { name: "Getting Info From Seller", value: "Getting Info From Seller" },
    { name: "No Response 1st Attempt", value: "No Response 1st Attempt" },
    { name: "No Response 2nd Attempt", value: "No Response 2nd Attempt" },
    { name: "No Response 3rd Attempt", value: "No Response 3rd Attempt" },
    { name: "Bid Deactivated", value: "Bid Deactivated" },
    { name: "Bid Declined", value: "Bid Declined" },
  ];
  //Max Bids Status Options
  const maxBidStatusOptions = [
    { name: "Don't Know", value: "Don't Know" },
    { name: "Max Bid Received", value: "Max Bid Received" },
    { name: "Buyer Can Increase Bid", value: "Buyer Can Increase Bid" },
  ];

  const [bidStatusData, setBidStatusData] = useState({
    user_id: "",
    interest_id: "",
    status: "",
  });
  const [buyerBudgetData, setBuyerBudgetData] = useState({
    interest_id: "",
    buyer_budget: "",
  });
  const [maxBidStatusData, setMaxBidStatusData] = useState({
    user_id: "",
    interest_id: "",
    max_bid_status: "",
  });
  const [noteData, setNoteData] = useState({
    user_id: 0,
    interest_id: 0,
    task_type: 0,
    to_id: 0,
    message: "",
    sendemail: true,
  });
  const [additionalProductInformation, setAdditionalProductinformation] = useState({
    user_id: 0,
    interest_id: 0,
    additional_info: "",
    message: "",
  });
  const [inspectionSupportData, setInspectionSupportData] = useState({
    user_id: 0,
    interest_id: 0,
    additional_info: "",
    message: "",
  });
  const [logisticSupportData, setLogisticSupportData] = useState({
    user_id: 0,
    interest_id: 0,
    additional_info: "",
    message: "",
  });
  const [showEditBuyerMotivation, setShowEditBuyerMotivation] = useState(false);
  const toggleEditBuyerMotivation = () => {
    setShowEditBuyerMotivation(!showEditBuyerMotivation);
  };

  const [showPreMotivation, setShowPreMotivation] = useState(false);
  const [preMotivaitonData, setPreMotivationData] = useState({});
  const togglePreMotivation = () => {
    setShowSellerMotivation(!showSellerMotivation);
    setShowPreMotivation(!showPreMotivation);
  };

  const preMotivationFetch = (date) => {
    props.refStart();
    props.getPreMotivation(props.auctionId, userId, date, (res) => {
      if (res.status === 200) {
        setPreMotivationData(res.data.data);
        props.refStop();
        togglePreMotivation();
      } else {
        props.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  //Update Bid Status
  const handleUpdateBidStatus = () => {
    props.refStart();
    props.updateBidStatus(props.auctionId, bidStatusData, (res) => {
      if (res.status === 200) {
        props.getBuyerInterests(props.auctionId, page, filterData, (res) => {});
        props.refStop();
        toast(<AlertSuccess message={"Information Saved"} />);
      } else {
        props.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  //Update Buyer Budget
  const handleUpdateBuyerBudget = (interestId) => {
    props.refStart();
    if (interestId === buyerBudgetData.interest_id) {
      props.updateBuyerBudget(props.auctionId, buyerBudgetData, (res) => {
        if (res.status === 200) {
          props.getBuyerInterests(props.auctionId, page, filterData, (res) => {});
          props.refStop();
          toast(<AlertSuccess message={"Information Saved"} />);
        } else {
          props.refStop();
          toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        }
      });
    } else {
      props.getBuyerInterests(props.auctionId, page, filterData, (res) => {});
      props.refStop();
    }
  };
  //Update Max Bid Status
  const handleUpdateMaxBidStatus = () => {
    props.refStart();
    props.updateMaxBidStatus(props.auctionId, maxBidStatusData, (res) => {
      if (res.status === 200) {
        props.getBuyerInterests(props.auctionId, page, filterData, (res) => {});
        props.refStop();
        toast(<AlertSuccess message={"Information Saved"} />);
      } else {
        props.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };
  const handleProductInfo = (val) => {
    const data = { ...additionalProductInformation };
    data.interest_id = val.interest_id;
    data.user_id = val.user_id;
    setAdditionalProductinformation(data);
    setSaveProductInfo(true);
  };
  //Send Product Info Request
  useEffect(() => {
    const handleSendProductInfo = () => {
      props.refStart();
      props.sendProductInfoRequest(props.auctionId, additionalProductInformation, (res) => {
        if (res.status === 200) {
          setAdditionalProductinformation({
            user_id: 0,
            interest_id: 0,
            additional_info: "",
            message: "",
          });
          setPage(1);
          setFilterData(["Interested"]);
          props.refStop();
          toast(<AlertSuccess message={"Information Saved"} />);
        } else {
          props.refStop();
          toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        }
      });
    };
    if (saveProductInfo) {
      handleSendProductInfo();
      setSaveProductInfo(false);
      setShowAdditionalProductInformation(false);
    }
  }, [handleProductInfo]);
  const handleChangeNote = (val) => {
    const data = { ...noteData };
    data.interest_id = val.interest_id;
    data.user_id = val.user_id;
    setNoteData(data);
    setSaveNote(true);
  };

  const handleInspectionInfo = (val) => {
    const data = { ...inspectionSupportData };
    data.interest_id = val.interest_id;
    data.user_id = val.user_id;
    setInspectionSupportData(data);
    setSaveInspectionSupport(true);
  };
  //Send Inspection Support Request
  useEffect(() => {
    const handleSendInspectionSupport = () => {
      props.refStart();
      props.sendInspectionSupportRequest(props.auctionId, inspectionSupportData, (res) => {
        if (res.status === 200) {
          setInspectionSupportData({
            user_id: 0,
            interest_id: 0,
            additional_info: "",
            message: "",
          });
          setPage(1);
          setFilterData(["Interested"]);
          props.refStop();
          toast(<AlertSuccess message={"Information Saved"} />);
        } else {
          props.refStop();
          toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        }
      });
    };
    if (saveInspectionSupport) {
      handleSendInspectionSupport();
      setSaveInspectionSupport(false);
      setShowAdditionalInspectionInfo(false);
    }
  }, [handleInspectionInfo]);

  const handleLogisticInfo = (val) => {
    const data = { ...logisticSupportData };
    data.interest_id = val.interest_id;
    data.user_id = val.user_id;
    setLogisticSupportData(data);
    setSaveLogisticSupport(true);
  };
  //Save Logistic Support Request
  useEffect(() => {
    const handleSendLogisticSupport = () => {
      props.refStart();
      props.sendLogisticSupportRequest(props.auctionId, logisticSupportData, (res) => {
        if (res.status === 200) {
          setLogisticSupportData({
            user_id: 0,
            interest_id: 0,
            additional_info: "",
            message: "",
          });
          setPage(1);
          setFilterData(["Interested"]);
          props.refStop();
          toast(<AlertSuccess message={"Information Saved"} />);
        } else {
          props.refStop();
          toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        }
      });
    };
    if (saveLogisticSupport) {
      handleSendLogisticSupport();
      setSaveLogisticSupport(false);
      setShowAdditionalLogisticsInfo(false);
    }
  }, [handleLogisticInfo]);
  //Save Note
  useEffect(() => {
    const handleSaveNote = () => {
      setLoadingSaveNote(true);
      props.refStart();
      props.saveNote(props.auctionId, noteData, (res) => {
        if (res.status === 200) {
          props.getBuyerInterests(props.auctionId, page, filterData, (res) => {});
          setLoadingSaveNote(false);
          props.refStop();
          toast(<AlertSuccess message={"Information Saved"} />);
        } else {
          setLoadingSaveNote(false);
          props.refStop();
          toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        }
      });
    };
    if (saveNote) {
      handleSaveNote();
      setSaveNote(false);
    }
  }, [handleChangeNote]);

  //Handle More Customer Info
  const handleMoreCustomerInfo = (userId) => {
    props.refStart();
    props.moreCustomerInfo(props.auctionId, userId, (res) => {
      if (res.status === 200) {
        props.refStop();
      } else {
        props.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };
  return (
    <div class="details-page-widget-block" id={props.id}>
      <div class="page-widget-head d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <div class="page-widget-label text-capitalize">
            Buyer Interests {detailData?.data?.length > 0 ? "" : !props.loadingMain && "(0)"}
          </div>
        </div>
      </div>
      {props.loadingMain ? (
        <Skeleton height="239px" />
      ) : (
        <div class="page-widget-body mt-3">
          <div className="d-flex flex-row">
            <div class="form-check d-flex align-items-center me-4">
              <input
                class="form-check-input"
                type="checkbox"
                value="All"
                id="allListings"
                checked={filterData.includes("All")}
                onChange={(e) => {
                  const data = [...filterData];
                  if (e.target.checked) {
                    data.push(e.target.value);
                    setFilterData(data);
                  } else {
                    const d = data.filter((val) => val !== e.target.value);
                    setFilterData(d);
                  }
                }}
              />
              <label class="form-check-label fw-medium" for="allListings"></label>
              <span>All Listings</span>
            </div>
            <div class="form-check d-flex align-items-center me-4">
              <input
                class="form-check-input"
                type="checkbox"
                value="My"
                checked={filterData.includes("My")}
                id="myListings"
                onChange={(e) => {
                  const data = [...filterData];
                  if (e.target.checked) {
                    data.push(e.target.value);
                    setFilterData(data);
                  } else {
                    const d = data.filter((val) => val !== e.target.value);
                    setFilterData(d);
                  }
                }}
              />
              <label class="form-check-label fw-medium" for="myListings"></label>
              <span>My Listings</span>
            </div>
            <div class="form-check d-flex align-items-center me-4">
              <input
                class="form-check-input"
                type="checkbox"
                value="Interested"
                checked={filterData.includes("Interested")}
                id="activeBuyerInterests"
                onChange={(e) => {
                  const data = [...filterData];
                  if (e.target.checked) {
                    data.push(e.target.value);
                    setFilterData(data);
                  } else {
                    const d = data.filter((val) => val !== e.target.value);
                    setFilterData(d);
                  }
                }}
              />
              <label class="form-check-label fw-medium" for="activeBuyerInterests"></label>
              <span>Active Buyer Interest</span>
            </div>
            <div class="form-check d-flex align-items-center me-4">
              <input
                class="form-check-input"
                type="checkbox"
                value="NotInterested"
                checked={filterData.includes("NotInterested")}
                id="notInterested"
                onChange={(e) => {
                  const data = [...filterData];
                  if (e.target.checked) {
                    data.push(e.target.value);
                    setFilterData(data);
                  } else {
                    const d = data.filter((val) => val !== e.target.value);
                    setFilterData(d);
                  }
                }}
              />
              <label class="form-check-label fw-medium" for="notInterested"></label>
              <span>Not Interested</span>
            </div>
            <div class="form-check d-flex align-items-center me-4">
              <input
                class="form-check-input"
                type="checkbox"
                value="Archived"
                checked={filterData.includes("Archived")}
                id="archivedListings"
                onChange={(e) => {
                  const data = [...filterData];
                  if (e.target.checked) {
                    data.push(e.target.value);
                    setFilterData(data);
                  } else {
                    const d = data.filter((val) => val !== e.target.value);
                    setFilterData(d);
                  }
                }}
              />
              <label class="form-check-label fw-medium" for="archivedListings"></label>
              <span>Archived Listings</span>
            </div>
            <div class="form-check d-flex align-items-center">
              <input
                class="form-check-input"
                type="checkbox"
                value="Viewed"
                id="viewed"
                checked={filterData.includes("Viewed")}
                onChange={(e) => {
                  const data = [...filterData];
                  if (e.target.checked) {
                    data.push(e.target.value);
                    setFilterData(data);
                  } else {
                    const d = data.filter((val) => val !== e.target.value);
                    setFilterData(d);
                  }
                }}
              />
              <label class="form-check-label fw-medium" for="viewed"></label>
              <span>Viewed</span>
            </div>
          </div>
          <div className="table-responsive position-relative mt-3">
            {detailData?.data?.length > 0 && (
              <table class="table table-bordered custom-table mb-0">
                <thead>
                  <tr>
                    <th style={{ width: "28px" }}></th>
                    <th style={{ minWidth: "280px" }}>Customer Info</th>
                    <th style={{ minWidth: "350px", maxWidth: "400px" }}>Product Listing Information</th>
                    <th style={{ minWidth: "250px", maxWidth: "300px" }}>Product Information</th>
                    <th style={{ minWidth: "250px", maxWidth: "300px" }}>Inspections</th>
                    <th style={{ minWidth: "250px", maxWidth: "300px" }}>Logistics</th>
                    <th style={{ minWidth: "350px", maxWidth: "400px" }}>Buyer Interest Status</th>
                    <th style={{ minWidth: "400px", maxWidth: "400px" }}>Buyer Interest Notes</th>
                    <th style={{ minWidth: "150px" }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {detailData?.data?.map((val, i) => (
                    <tr>
                      <td>{(page - 1) * 5 + (i + 1)}</td>
                      <td>
                        <div className="d-flex flex-column justify-content-center">
                          <span>
                            <img src={extLink} alt="External Link" />
                            <a href="javascript:void(0)" className="green-btn-text">
                              {val?.customer?.customer_name}
                            </a>
                          </span>
                          <span>{val?.customer?.customer_job_title}</span>
                          <span>{val?.customer?.customer_company}</span>
                          <span>
                            {val?.customer?.customer_city}, {val?.customer?.customer_state}
                          </span>
                          <span>{val?.customer?.customer_country}</span>
                          <span>
                            {val?.customer?.customer_phone && "W:"} {val?.customer?.customer_phone}
                          </span>
                          <span>
                            {val?.customer?.customer_cell && "M:"} {val?.customer?.customer_cell}
                          </span>
                          <span>
                            <a className="green-btn-text" href={`mailto:${val?.customer?.customer_email}`}>
                              {val?.customer?.customer_email}
                            </a>
                            {val?.customer?.emailstatuscode === 0 ? (
                              <img src={greenEmail} alt="" title="Email OK" />
                            ) : val?.customer?.emailstatuscode === 1 ? (
                              <img
                                src={purpleEmail}
                                alt=""
                                title="Email Temporarily Suspended/Unreachable or Undetermined (Soft Bounce)"
                              />
                            ) : val?.customer?.emailstatuscode === 2 ? (
                              <img src={invalid} alt="" title="Email Invalid" />
                            ) : val?.customer?.emailstatuscode === 3 ? (
                              <img src={spamEmail} alt="" title="Email Blocked by Spam or Firewall" />
                            ) : (
                              <img src={notVerified} alt="" title="Email Not Verified" />
                            )}
                          </span>
                          <div class="td-button-group d-flex">
                            <img
                              src={`https://us-central1-salvex-mail.cloudfunctions.net/requestRecipientStats?email=${val?.customer?.customer_email}`}
                              alt=""
                            />
                          </div>
                          <div className="mt-2 mb-2 d-flex flex-column">
                            <span>
                              Created On:{" "}
                              <b className="fw-medium">
                                <DateFormat date={val?.customer?.customer_date_created} />
                              </b>
                            </span>
                            {val?.customer?.customer_created_by && (
                              <span>
                                Created By: <b className="fw-medium">{val?.customer?.customer_created_by}</b>
                              </span>
                            )}
                            <span>
                              Last Login:{" "}
                              <b className="fw-medium">
                                <DateFormat date={val?.customer?.last_login} />
                              </b>
                            </span>
                          </div>
                          <div className="fw-medium mb-2">Buying Status</div>
                          <span>
                            Total Bids: <b className="fw-medium">{val?.customer?.total_bids}</b>
                          </span>
                          <span>
                            Profit:{" "}
                            <b className="fw-medium">
                              {val?.customer?.profitBuying
                                ? "$" +
                                  new Intl.NumberFormat("en-US", {
                                    style: "decimal",
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }).format(val?.customer?.profitBuying)
                                : "$0.00"}
                            </b>
                          </span>
                          <span>
                            Last Bid :{" "}
                            <a
                              href={`/listing-detail?id=${val?.customer?.lastBid?.auction_id}`}
                              rel="noreferrer"
                              target="_blank"
                              className="fw-medium green-btn-text"
                            >
                              {val?.customer?.lastBid?.title}
                            </a>
                            on{" "}
                            <b className="fw-medium">
                              <DateFormat date={val?.customer?.lastBid?.date} />
                            </b>
                          </span>
                          {val?.customer?.lastPurchase.auction_title && (
                            <span>
                              Last Purchased:{" "}
                              <a
                                href={`/listing-detail?id=${val?.customer?.lastPurchase?.auction_id}`}
                                rel="noreferrer"
                                target="_blank"
                                className="fw-medium green-btn-text"
                              >
                                {val?.customer?.lastPurchase?.auction_title}
                              </a>
                              on{" "}
                              <b className="fw-medium">
                                <DateFormat date={val?.customer?.lastPurchase?.date} />
                              </b>
                            </span>
                          )}
                          <span>
                            Feedback:{" "}
                            <b className="fw-medium text-success">
                              {val?.customer?.buyerFeedback?.total != 0 && val?.customer?.buyerFeedback?.good != 0
                                ? Math.round(
                                    (val?.customer?.buyerFeedback?.good / val?.customer?.buyerFeedback?.total) * 100,
                                  )
                                : "0"}
                              %
                            </b>{" "}
                            <b className="fw-medium">
                              {val?.customer?.buyerFeedback?.total != 0 && val?.customer?.buyerFeedback?.neutral != 0
                                ? Math.round(
                                    (val?.customer?.buyerFeedback?.neutral / val?.customer?.buyerFeedback?.total) * 100,
                                  )
                                : "0"}
                              %
                            </b>{" "}
                            <b className="fw-medium text-danger">
                              {val?.customer?.buyerFeedback?.total != 0 && val?.customer?.buyerFeedback?.bad != 0
                                ? Math.round(
                                    (val?.customer?.buyerFeedback?.bad / val?.customer?.buyerFeedback?.total) * 100,
                                  )
                                : "0"}
                              %
                            </b>
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex flex-column justify-content-center align-items-center">
                          <a href={`/listing-detail?id=${val?.auction?.auction_id}`} rel="noreferrer" target="_blank">
                            {val?.auction?.title}
                          </a>
                          <button
                            className="ms-4 mb-2 btn btn-primary btn-xs-size btn-auto "
                            style={{ width: "150px" }}
                            onClick={() => {
                              setUserId(val?.auction?.s_user_id);
                              toggleSellerMotivation();
                            }}
                          >
                            Seller Motivation
                          </button>
                          <button
                            className="btn btn-primary btn-xs-size btn-auto "
                            style={{ width: "150px" }}
                            onClick={() => {
                              setUserId(val?.auction?.s_user_id);
                              toggleBuyerMotivation();
                            }}
                          >
                            Buyer Motivation
                          </button>
                          <div class="table-responsive mb-0 mt-3">
                            <table class="table table-borderless no-padd-table">
                              <tr>
                                <td className="fw-medium">Public Status</td>
                                <td>:</td>
                                <td>
                                  <em>{val?.auction?.status_description}</em>
                                </td>
                              </tr>
                              <tr>
                                <td className="fw-medium">Product Location</td>
                                <td>:</td>
                                <td>
                                  <em>{val?.auction?.location2}</em>
                                </td>
                              </tr>
                            </table>
                          </div>
                          <div className="fw-medium mb-2">Listing Pricing</div>
                          <div class="table-responsive mb-0">
                            <table class="table table-borderless  no-padd-table">
                              <tr>
                                <td className="fw-medium">Higher Buyer Bid</td>
                                <td>:</td>
                                <td>
                                  <em>
                                    $
                                    {val?.auction?.high_bid
                                      ? val?.auction?.high_bid.toLocaleString("en-US", {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })
                                      : "0.00"}
                                  </em>
                                </td>
                              </tr>
                              <tr>
                                <td className="fw-medium">Public Asking Price</td>
                                <td>:</td>
                                <td>
                                  <em>
                                    $
                                    {val?.auction?.public_reserve
                                      ? val?.auction?.public_reserve.toLocaleString("en-US", {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })
                                      : "0.00"}
                                  </em>
                                </td>
                              </tr>
                              <tr>
                                <td className="fw-medium">Seller Asking Price</td>
                                <td>:</td>
                                <td>
                                  <em>
                                    $
                                    {val?.auction?.seller_reserve
                                      ? val?.auction?.seller_reserve.toLocaleString("en-US", {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })
                                      : "0.00"}
                                  </em>
                                </td>
                              </tr>
                              <tr>
                                <td className="fw-medium">Recycle Value</td>
                                <td>:</td>
                                <td>
                                  <em>
                                    $
                                    {val?.auction?.recycle_value
                                      ? val?.auction?.recycle_value.toLocaleString("en-US", {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })
                                      : "0.00"}
                                  </em>
                                </td>
                              </tr>
                              <tr>
                                <td className="fw-medium">Retail Value</td>
                                <td>:</td>
                                <td>
                                  <em>
                                    $
                                    {val?.auction?.retail_value
                                      ? val?.auction?.retail_value.toLocaleString("en-US", {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })
                                      : "0.00"}
                                  </em>
                                </td>
                              </tr>
                              <tr>
                                <td className="fw-medium">Original Wholesale Value</td>
                                <td>:</td>
                                <td>
                                  <em>
                                    $
                                    {val?.auction?.orig_wholesale_value
                                      ? val?.auction?.orig_wholesale_value.toLocaleString("en-US", {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })
                                      : "0.00"}
                                  </em>
                                </td>
                              </tr>
                              <tr>
                                <td className="fw-medium">Claim Value</td>
                                <td>:</td>
                                <td>
                                  <em>
                                    $
                                    {val?.auction?.claim_value
                                      ? val?.auction?.claim_value.toLocaleString("en-US", {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })
                                      : "0.00"}
                                  </em>
                                </td>
                              </tr>
                              <tr>
                                <td className="fw-medium">Estimated Scrap Price</td>
                                <td>:</td>
                                <td>
                                  <em>
                                    $
                                    {val?.auction?.scrapPrice
                                      ? val?.auction?.scrapPrice.toLocaleString("en-US", {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })
                                      : "0.00"}
                                  </em>
                                </td>
                              </tr>
                            </table>
                          </div>

                          <div className="fw-medium mb-2 mt-3">Strategic Team</div>
                          {val?.auction?.auth_name && (
                            <span>
                              <b className="fw-medium">Created By: </b>
                              {val?.auction?.auth_name}
                            </span>
                          )}
                          {val?.auction?.method_origin && (
                            <span>
                              <b className="fw-medium">Method Origin: </b>
                              {val?.auction?.method_origin}
                            </span>
                          )}

                          {val?.auction?.individual_origin && (
                            <span>
                              <b className="fw-medium">Individual Origin: </b>
                              {val?.auction?.individual_origin}
                            </span>
                          )}

                          {val?.auction?.trader_name && (
                            <span>
                              <b className="fw-medium">Trader: </b>
                              {val?.auction?.trader_name}
                            </span>
                          )}
                          {val?.auction?.internal_referral && (
                            <span>
                              <b className="fw-medium">Business Development: </b>
                              {val?.auction?.internal_referral}
                            </span>
                          )}
                          {val?.auction?.comm_member_3 && (
                            <span>
                              <b className="fw-medium">Administrator 1:</b>
                              {val?.auction?.comm_member_3}
                            </span>
                          )}
                          {val?.auction?.comm_member_4 && (
                            <span>
                              <b className="fw-medium">Administrator 2:</b>
                              {val?.auction?.comm_member_4}
                            </span>
                          )}
                          {val?.auction?.comm_member_5 && (
                            <span>
                              <b className="fw-medium">Market Developer:</b>
                              {val?.auction?.comm_member_5}
                            </span>
                          )}
                          {val?.auction?.comm_member_6 && (
                            <span>
                              <b className="fw-medium">Optimizer:</b>
                              {val?.auction?.comm_member_6}
                            </span>
                          )}
                          {val?.auction?.outside_agent_name && (
                            <span>
                              <b className="fw-medium">Outside Agent: </b>
                              {val?.auction?.outside_agent_name}
                            </span>
                          )}
                          {val?.auction?.mgr_name && (
                            <span>
                              <b className="fw-medium">Trader Manager: </b>
                              {val?.auction?.mgr_name}
                            </span>
                          )}
                          {val?.auction?.admin_name && (
                            <span>
                              <b className="fw-medium">BD Manager: </b>
                              {val?.auction?.admin_name}
                            </span>
                          )}
                          {val?.auction?.comm_member_7 && (
                            <span>
                              <b className="fw-medium">Ext. Referral: </b>
                              {val?.auction?.comm_member_7}
                            </span>
                          )}
                        </div>
                      </td>
                      <td className={val.interest_type === "Viewed" && "disabled-buyer-interest"}>
                        <div className="d-flex flex-column align-item-center pe-0">
                          <div class="page-widget-label text-capitalize">Product Info Terms</div>
                          {!val?.buyer_product_info &&
                            !val?.buyer_manufacture_doc &&
                            !val?.buyer_inspection_report &&
                            !val?.buyer_certificate_of_origin &&
                            !val?.buyer_mill_certificates && (
                              <div class="form-check d-flex align-items-center mt-2">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  value=""
                                  defaultChecked={val?.buyer_product_dontknow || val?.buyer_product_dontknow == null}
                                  id={`dontknow1` + val.interest_id}
                                />
                                <label class="form-check-label fw-medium" for={`dontknow1` + val.interest_id}></label>
                                <span className="d-inline">Don't Know</span>
                              </div>
                            )}
                          <div class="form-check d-flex align-items-center mt-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              defaultChecked={val?.buyer_product_info}
                              id={`productInfo` + val.interest_id}
                            />
                            <label class="form-check-label fw-medium" for={`productInfo` + val.interest_id}></label>
                            <span>Product Info</span>
                          </div>
                          <div class="form-check d-flex align-items-center mt-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              defaultChecked={val?.buyer_manufacture_doc}
                              id={`manufactureDocs` + val.interest_id}
                            />
                            <label class="form-check-label fw-medium" for={`manufactureDocs` + val.interest_id}></label>
                            <span>Manufacturer Docs</span>
                          </div>
                          <div class="form-check d-flex align-items-center mt-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              defaultChecked={val?.buyer_inspection_report}
                              id={`inspectionReport` + val.interest_id}
                            />
                            <label
                              class="form-check-label fw-medium"
                              for={`inspectionReport` + val.interest_id}
                            ></label>
                            <span>Inspection Report</span>
                          </div>
                          <div class="form-check d-flex align-items-center mt-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              defaultChecked={val?.buyer_certificate_of_origin}
                              id={`certificateOrigin` + val.interest_id}
                            />
                            <label
                              class="form-check-label fw-medium"
                              for={`certificateOrigin` + val.interest_id}
                            ></label>
                            <span>Certificate of Origin</span>
                          </div>
                          <div class="form-check d-flex align-items-center mt-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              defaultChecked={val?.buyer_mill_certificates}
                              id={`millCertificate` + val.interest_id}
                            />
                            <label class="form-check-label fw-medium" for={`millCertificate` + val.interest_id}></label>
                            <span>Mill Certificates (MTRs)</span>
                          </div>

                          <div class="page-widget-label text-capitalize mt-4">Product Info Status</div>
                          {!val?.info_status_noinfo &&
                            !val?.info_status_moreinfo &&
                            val?.status !== "Not Interested" && (
                              <div class="form-check d-flex align-items-center mt-2">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  value=""
                                  defaultChecked={val?.info_status_dontknow || val?.info_status_dontknow == null}
                                  id={`dontknow2` + val.interest_id}
                                />
                                <label class="form-check-label fw-medium" for={`dontknow2` + val.interest_id}></label>
                                <span className="d-inline">Don't Know</span>
                              </div>
                            )}
                          <div class="form-check d-flex align-items-center mt-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              defaultChecked={val?.info_status_noinfo || val?.status == "Not Interested"}
                              id={`noInfoRequired` + val.interest_id}
                            />
                            <label class="form-check-label fw-medium" for={`noInfoRequired` + val.interest_id}></label>
                            <span>No Information Required</span>
                          </div>
                          <div class="form-check d-flex align-items-center mt-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              defaultChecked={val?.info_status_moreinfo || val?.status == "Not Interested"}
                              id={`moreInfoRequired` + val.interest_id}
                            />
                            <label
                              class="form-check-label fw-medium"
                              for={`moreInfoRequired` + val.interest_id}
                            ></label>
                            <span>More Information Required</span>
                          </div>
                          {showAdditionalProductInformation && userId === val.user_id && (
                            <>
                              <div class="page-widget-label text-capitalize mt-4">Additional Information Required</div>
                              <div class="form-check d-flex align-items-center mt-3">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  value="Product Info"
                                  defaultChecked={val.product_info}
                                  name="productInfo"
                                  id={val.interest_id + "productInfo"}
                                  onChange={(e) => {
                                    const data = {
                                      ...additionalProductInformation,
                                    };
                                    if (e.target.checked) {
                                      data.additional_info = e.target.value;
                                    } else {
                                      data.additional_info = "";
                                    }
                                    setAdditionalProductinformation(data);
                                  }}
                                />
                                <label class="form-check-label fw-medium" for={val.interest_id + "productInfo"}></label>
                                <span>Product Info</span>
                              </div>
                              <div class="form-check d-flex align-items-center mt-3">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  value="Manufacturer Docs"
                                  defaultChecked={val.manufacture_doc}
                                  name="manufactureDocs"
                                  id={val.interest_id + "manufactureDocs"}
                                  onChange={(e) => {
                                    const data = {
                                      ...additionalProductInformation,
                                    };
                                    if (e.target.checked) {
                                      data.additional_info = e.target.value;
                                    } else {
                                      data.additional_info = "";
                                    }
                                    setAdditionalProductinformation(data);
                                  }}
                                />
                                <label
                                  class="form-check-label fw-medium"
                                  for={val.interest_id + "manufactureDocs"}
                                ></label>
                                <span>Manufacturer Docs</span>
                              </div>
                              <div class="form-check d-flex align-items-center mt-3">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  value="Inspection Report"
                                  defaultChecked={val.inspection_report}
                                  name="inspectionReport"
                                  id={val.interest_id + "inspectionReport"}
                                  onChange={(e) => {
                                    const data = {
                                      ...additionalProductInformation,
                                    };
                                    if (e.target.checked) {
                                      data.additional_info = e.target.value;
                                    } else {
                                      data.additional_info = "";
                                    }
                                    setAdditionalProductinformation(data);
                                  }}
                                />
                                <label
                                  class="form-check-label fw-medium"
                                  for={val.interest_id + "inspectionReport"}
                                ></label>
                                <span>Inspection Report</span>
                              </div>
                              <div class="form-check d-flex align-items-center mt-3">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  value="Certificate of Origin"
                                  defaultChecked={val.certificate_of_origin}
                                  name="certificateOfOrigin"
                                  id={val.interest_id + "certificateOfOrigin"}
                                  onChange={(e) => {
                                    const data = {
                                      ...additionalProductInformation,
                                    };
                                    if (e.target.checked) {
                                      data.additional_info = e.target.value;
                                    } else {
                                      data.additional_info = "";
                                    }
                                    setAdditionalProductinformation(data);
                                  }}
                                />
                                <label
                                  class="form-check-label fw-medium"
                                  for={val.interest_id + "certificateOfOrigin"}
                                ></label>
                                <span>Certificate of Origin</span>
                              </div>
                              <div class="form-check d-flex align-items-center mt-3">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  value="Mill Certificates (MTRs)"
                                  defaultChecked={val.mill_certificates}
                                  name="millCertificate"
                                  id={val.interest_id + "millCertificate"}
                                  onChange={(e) => {
                                    const data = {
                                      ...additionalProductInformation,
                                    };
                                    if (e.target.checked) {
                                      data.additional_info = e.target.value;
                                    } else {
                                      data.additional_info = "";
                                    }
                                    setAdditionalProductinformation(data);
                                  }}
                                />
                                <label
                                  class="form-check-label fw-medium"
                                  for={val.interest_id + "millCertificate"}
                                ></label>
                                <span>Mill Certificates (MTRs)</span>
                              </div>
                              <div className="mt-2">
                                <span className="fw-medium">Message:</span>
                                <textarea
                                  name=""
                                  value={additionalProductInformation.message}
                                  id=""
                                  cols="30"
                                  rows="3"
                                  className="form-control"
                                  onChange={(e) => {
                                    const data = {
                                      ...additionalProductInformation,
                                    };
                                    data.message = e.target.value;
                                    setAdditionalProductinformation(data);
                                  }}
                                ></textarea>
                              </div>
                            </>
                          )}
                          {showAdditionalProductInformation && userId === val.user_id ? (
                            <button
                              className="mt-3 mb-3 btn btn-primary btn-xs-size btn-auto "
                              style={{ width: "180px" }}
                              onClick={() => handleProductInfo(val)}
                            >
                              Send Product Info Request
                            </button>
                          ) : (
                            <button
                              className="mt-3 mb-3 btn btn-primary btn-xs-size btn-auto "
                              style={{ width: "180px" }}
                              onClick={() => {
                                setUserId(val.user_id);
                                setShowAdditionalProductInformation(true);
                              }}
                            >
                              New Product Info Request
                            </button>
                          )}
                          <div class="page-widget-label text-capitalize mt-4">Product Info Requests</div>
                          <div className="mt-2">
                            {val?.reqs
                              ?.filter((val) => val.reqtype === "Info Request")
                              ?.map((data) => (
                                <div className="d-flex justify-content-around">
                                  <div>{data?.status ? "Completed" : "Pending"}</div>
                                  <div>
                                    <a href="javascript:void(0)">Show Details</a>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </td>
                      <td className={val.interest_type === "Viewed" && "disabled-buyer-interest"}>
                        <div className="d-flex flex-column align-item-center">
                          <div class="page-widget-label text-capitalize">Inspection Buyer Terms</div>
                          {!val?.buyer_inspection_photos &&
                            !val?.buyer_inspection_video &&
                            !val?.buyer_inspection_3rd &&
                            !val?.buyer_inspection_personal && (
                              <div class="form-check d-flex align-items-center mt-2">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  value=""
                                  defaultChecked={
                                    val?.buyer_inspection_dontknow || val?.buyer_inspection_dontknow == null
                                  }
                                  id={"dontknow3" + val.interest_id}
                                />
                                <label class="form-check-label fw-medium" for={"dontknow3" + val.interest_id}></label>
                                <span>Don't Know</span>
                              </div>
                            )}
                          <div class="form-check d-flex align-items-center mt-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              defaultChecked={val?.buyer_inspection_photos}
                              id={"photos" + val.interest_id}
                            />
                            <label class="form-check-label fw-medium" for={"photos" + val.interest_id}></label>
                            <span>Photos</span>
                          </div>
                          <div class="form-check d-flex align-items-center mt-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              defaultChecked={val?.buyer_inspection_video}
                              id={"videoVirtualInspection" + val.interest_id}
                            />
                            <label
                              class="form-check-label fw-medium"
                              for={"videoVirtualInspection" + val.interest_id}
                            ></label>
                            <span>Video Virtual Inspection</span>
                          </div>
                          <div class="form-check d-flex align-items-center mt-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              defaultChecked={val?.buyer_inspection_3rd}
                              id={"3PartyInspection" + val.interest_id}
                            />
                            <label
                              class="form-check-label fw-medium"
                              for={"3PartyInspection" + val.interest_id}
                            ></label>
                            <span>3rd Party Inspection</span>
                          </div>
                          <div class="form-check d-flex align-items-center mt-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              defaultChecked={val?.buyer_inspection_personal}
                              id={"personalInspection" + val.interest_id}
                            />
                            <label
                              class="form-check-label fw-medium"
                              for={"personalInspection" + val.interest_id}
                            ></label>
                            <span>Personal Inspection</span>
                          </div>

                          <div class="page-widget-label text-capitalize mt-4">Inspection Status</div>
                          {!val?.inspec_status_noreq && !val?.inspec_status_req && val?.status !== "Not Interested" && (
                            <div class="form-check d-flex align-items-center mt-3">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value=""
                                defaultChecked={val?.inspec_status_dontknow || val?.inspec_status_dontknow == null}
                                id={"dontknow4" + val.interest_id}
                              />
                              <label class="form-check-label fw-medium" for={"dontknow4" + val.interest_id}></label>
                              <span>Don't Know</span>
                            </div>
                          )}
                          <div class="form-check d-flex align-items-center mt-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              defaultChecked={val?.inspec_status_noreq || val?.status == "Not Interested"}
                              id={"noIspectionSupportRequired" + val.interest_id}
                            />
                            <label
                              class="form-check-label fw-medium"
                              for={"noIspectionSupportRequired" + val.interest_id}
                            ></label>
                            <span>No Inspection Support Required</span>
                          </div>
                          <div class="form-check d-flex align-items-center mt-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              defaultChecked={val?.inspec_status_req}
                              disabled={val?.status == "Not Interested"}
                              id={"inspectionSupportRequired" + val.interest_id}
                            />
                            <label
                              class="form-check-label fw-medium"
                              for={"inspectionSupportRequired" + val.interest_id}
                            ></label>
                            <span>Inspection Support Required</span>
                          </div>
                          {showAdditionalInspectionInfo && userId === val.user_id && (
                            <>
                              <div class="page-widget-label text-capitalize mt-4">Additional Information Required</div>
                              <div class="form-check d-flex align-items-center mt-3">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  value="Photos"
                                  defaultChecked={val.inspection_photos}
                                  id={"inspectionPhoto" + val.interest_id}
                                  onChange={(e) => {
                                    const data = {
                                      ...inspectionSupportData,
                                    };
                                    if (e.target.checked) {
                                      data.additional_info = e.target.value;
                                    } else {
                                      data.additional_info = "";
                                    }
                                    setInspectionSupportData(data);
                                  }}
                                />
                                <label
                                  class="form-check-label fw-medium"
                                  for={"inspectionPhoto" + val.interest_id}
                                ></label>
                                <span>Photos</span>
                              </div>
                              <div class="form-check d-flex align-items-center mt-3">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  value="Video Virtual Inspection"
                                  defaultChecked={val?.inspection_video}
                                  id={"videoVirtualInspection2" + val?.interest_id}
                                  onChange={(e) => {
                                    const data = {
                                      ...inspectionSupportData,
                                    };
                                    if (e.target.checked) {
                                      data.additional_info = e.target.value;
                                    } else {
                                      data.additional_info = "";
                                    }
                                    setInspectionSupportData(data);
                                  }}
                                />
                                <label
                                  class="form-check-label fw-medium"
                                  for={"videoVirtualInspection2" + val?.interest_id}
                                ></label>
                                <span>Video Virtual Inspection</span>
                              </div>
                              <div class="form-check d-flex align-items-center mt-3">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  value="3rd Party Inspection"
                                  defaultChecked={val.inspection_3rd}
                                  id={"3rdPartyInspection" + val.interest_id}
                                  onChange={(e) => {
                                    const data = {
                                      ...inspectionSupportData,
                                    };
                                    if (e.target.checked) {
                                      data.additional_info = e.target.value;
                                    } else {
                                      data.additional_info = "";
                                    }
                                    setInspectionSupportData(data);
                                  }}
                                />
                                <label
                                  class="form-check-label fw-medium"
                                  for={"3rdPartyInspection" + val.interest_id}
                                ></label>
                                <span>3rd Party Inspection</span>
                              </div>
                              <div class="form-check d-flex align-items-center mt-3">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  value="Personal Inspection"
                                  defaultChecked={val.inspection_personal}
                                  id={"personalInspection2" + val.interest_id}
                                  onChange={(e) => {
                                    const data = {
                                      ...inspectionSupportData,
                                    };
                                    if (e.target.checked) {
                                      data.additional_info = e.target.value;
                                    } else {
                                      data.additional_info = "";
                                    }
                                    setInspectionSupportData(data);
                                  }}
                                />
                                <label
                                  class="form-check-label fw-medium"
                                  for={"personalInspection2" + val.interest_id}
                                ></label>
                                <span>Personal Inspection</span>
                              </div>
                              <div className="mt-2">
                                <span className="fw-medium">Message:</span>
                                <textarea
                                  name=""
                                  id=""
                                  value={inspectionSupportData.message}
                                  cols="30"
                                  rows="3"
                                  className="form-control"
                                  onChange={(e) => {
                                    const data = { ...inspectionSupportData };
                                    data.message = e.target.value;
                                    setInspectionSupportData(data);
                                  }}
                                ></textarea>
                              </div>
                            </>
                          )}
                          {showAdditionalInspectionInfo && userId === val.user_id ? (
                            <button
                              className="mt-3 mb-3 btn btn-primary btn-xs-size btn-auto "
                              style={{ width: "180px" }}
                              onClick={() => {
                                handleInspectionInfo(val);
                              }}
                            >
                              Send Inspection Support Request
                            </button>
                          ) : (
                            <button
                              className="mt-3 mb-3 btn btn-primary btn-xs-size btn-auto "
                              style={{ width: "180px" }}
                              onClick={() => {
                                setUserId(val.user_id);
                                setShowAdditionalInspectionInfo(true);
                              }}
                            >
                              New Inspection Support Request
                            </button>
                          )}
                          <div class="page-widget-label text-capitalize mt-4">Inspection Support Requests</div>
                          <div className="mt-2">
                            {val?.reqs
                              ?.filter((val) => val.reqtype === "Inspection Request")
                              ?.map((data) => (
                                <div className="d-flex justify-content-around">
                                  <div>{data?.status ? "Completed" : "Pending"}</div>
                                  <div>
                                    <a href="javascript:void(0)">Show Details</a>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </td>
                      <td className={val.interest_type === "Viewed" && "disabled-buyer-interest"}>
                        <div className="d-flex flex-column align-item-center">
                          <div class="page-widget-label text-capitalize">Logistics Buyer Terms</div>
                          {!val?.buyer_logistics_exworks &&
                            !val?.buyer_logistics_loaded &&
                            !val?.buyer_logistics_loadedcost &&
                            !val?.buyer_logistics_truckingcost &&
                            !val?.buyer_logistics_customs &&
                            !val?.buyer_logistics_vessel &&
                            !val?.logist_stus_req && (
                              <div class="form-check d-flex align-items-center mt-2">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  value=""
                                  defaultChecked={
                                    val?.buyer_logistics_dontknow || val?.buyer_logistics_dontknow == null
                                  }
                                  id={"dontknow5" + val.interest_id}
                                />
                                <label class="form-check-label fw-medium" for={"dontknow5" + val.interest_id}></label>
                                <span>Don't Know</span>
                              </div>
                            )}
                          <div class="form-check d-flex align-items-center mt-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              defaultChecked={val?.buyer_logistics_exworks}
                              id={"exWorks" + val.interest_id}
                            />
                            <label class="form-check-label fw-medium" for={"exWorks" + val.interest_id}></label>
                            <span>Ex Works</span>
                          </div>
                          <div class="form-check d-flex align-items-center mt-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              defaultChecked={val?.buyer_logistics_loaded}
                              id={"loadedbyseller" + val.interest_id}
                            />
                            <label class="form-check-label fw-medium" for={"loadedbyseller" + val.interest_id}></label>
                            <span>Loaded Trucks(By Seller)</span>
                          </div>
                          <div class="form-check d-flex align-items-center mt-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              defaultChecked={val.buyer_logistics_loadedcost}
                              id={"loadedbycost" + val.interest_id}
                            />
                            <label class="form-check-label fw-medium" for={"loadedbycost" + val.interest_id}></label>
                            <span>Loaded Trucks(Buyer Cost)</span>
                          </div>
                          <div class="form-check d-flex align-items-center mt-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              defaultChecked={val.buyer_logistics_truckingcost}
                              id={"truckbycost" + val.interest_id}
                            />
                            <label class="form-check-label fw-medium" for={"truckbycost" + val.interest_id}></label>
                            <span>Trucking(Buyer Cost)</span>
                          </div>
                          <div class="form-check d-flex align-items-center mt-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              defaultChecked={val.buyer_logistics_customs}
                              id={"customerbysost" + val.interest_id}
                            />
                            <label class="form-check-label fw-medium" for={"customerbysost" + val.interest_id}></label>
                            <span>Customs(Buyer Cost)</span>
                          </div>
                          <div class="form-check d-flex align-items-center mt-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              defaultChecked={val.buyer_logistics_vessel}
                              id={"vesselbycost" + val.interest_id}
                            />
                            <label class="form-check-label fw-medium" for={"vesselbycost" + val.interest_id}></label>
                            <span>Vessel Freight (Buyer Cost)</span>
                          </div>
                          <div className="mt-3">
                            <span>Inc. Logistics To:</span>
                            <input type="text" className="form-control" />
                          </div>

                          <div class="page-widget-label text-capitalize mt-4">Logistics Status</div>
                          {!val?.logist_stus_noreq && !val?.logist_stus_req && val?.status !== "Not Interested" && (
                            <div class="form-check d-flex align-items-center mt-3">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value=""
                                defaultChecked={val.logist_stus_dontknow || val.logist_stus_dontknow == null}
                                id={"dontknow6" + val.interest_id}
                              />
                              <label class="form-check-label fw-medium" for={"dontknow6" + val.interest_id}></label>
                              <span>Don't Know</span>
                            </div>
                          )}
                          <div class="form-check d-flex align-items-center mt-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              defaultChecked={val.logist_stus_noreq}
                              id={"nologisticSupportRequired" + val.interest_id}
                            />
                            <label
                              class="form-check-label fw-medium"
                              for={"nologisticSupportRequired" + val.interest_id}
                            ></label>
                            <span>No Logistics Support Required</span>
                          </div>
                          <div class="form-check d-flex align-items-center mt-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              defaultChecked={val.logist_stus_req}
                              disabled={val.status == "Not Interested"}
                              id={"logisticSupportRequired" + val.interest_id}
                            />
                            <label
                              class="form-check-label fw-medium"
                              for={"logisticSupportRequired" + val.interest_id}
                            ></label>
                            <span>Logistics Support Required</span>
                          </div>
                          {showAdditionalLogisticsInfo && userId === val.user_id && (
                            <>
                              <div class="page-widget-label text-capitalize mt-4">Additional Information Required</div>
                              <div class="form-check d-flex align-items-center mt-3">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  value="Loaded Trucks(By Seller)"
                                  defaultChecked={val.logistics_loading}
                                  id={"loadedTruchBySeller" + val.interest_id}
                                  onChange={(e) => {
                                    const data = {
                                      ...logisticSupportData,
                                    };
                                    if (e.target.checked) {
                                      data.additional_info = e.target.value;
                                    } else {
                                      data.additional_info = "";
                                    }
                                    setLogisticSupportData(data);
                                  }}
                                />
                                <label
                                  class="form-check-label fw-medium"
                                  for={"loadedTruchBySeller" + val.interest_id}
                                ></label>
                                <span>Loaded Trucks(By Seller)</span>
                              </div>
                              <div class="form-check d-flex align-items-center mt-3">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  value="Loaded Trucks(Buyer Cost"
                                  defaultChecked={val.logistics_truck_qoutes}
                                  id={"loadedTruchBuyerCoast" + val.interest_id}
                                  onChange={(e) => {
                                    const data = {
                                      ...logisticSupportData,
                                    };
                                    if (e.target.checked) {
                                      data.additional_info = e.target.value;
                                    } else {
                                      data.additional_info = "";
                                    }
                                    setLogisticSupportData(data);
                                  }}
                                />
                                <label
                                  class="form-check-label fw-medium"
                                  for={"loadedTruchBuyerCoast" + val.interest_id}
                                ></label>
                                <span>Loaded Trucks(Buyer Cost)</span>
                              </div>
                              <div class="form-check d-flex align-items-center mt-3">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  value="Trucking(Buyer Cost)"
                                  defaultChecked={val.logistics_trucking_qoutes}
                                  id={"truckingBuyerCost" + val.interest_id}
                                  onChange={(e) => {
                                    const data = {
                                      ...logisticSupportData,
                                    };
                                    if (e.target.checked) {
                                      data.additional_info = e.target.value;
                                    } else {
                                      data.additional_info = "";
                                    }
                                    setLogisticSupportData(data);
                                  }}
                                />
                                <label
                                  class="form-check-label fw-medium"
                                  for={"truckingBuyerCost" + val.interest_id}
                                ></label>
                                <span>Trucking(Buyer Cost)</span>
                              </div>
                              <div class="form-check d-flex align-items-center mt-3">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  value="Customs(Buyer Cost)"
                                  defaultChecked={val.logistics_custom_qoutes}
                                  id={"customsBuyerCost" + val.interest_id}
                                  onChange={(e) => {
                                    const data = {
                                      ...logisticSupportData,
                                    };
                                    if (e.target.checked) {
                                      data.additional_info = e.target.value;
                                    } else {
                                      data.additional_info = "";
                                    }
                                    setLogisticSupportData(data);
                                  }}
                                />
                                <label
                                  class="form-check-label fw-medium"
                                  for={"customsBuyerCost" + val.interest_id}
                                ></label>
                                <span>Customs(Buyer Cost)</span>
                              </div>
                              <div class="form-check d-flex align-items-center mt-3">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  value="Vessel Freight (Buyer Cost)"
                                  defaultChecked={val.logistics_freight_qoutes}
                                  id={"vesselFreightBuyerCost" + val.interest_id}
                                  onChange={(e) => {
                                    const data = {
                                      ...logisticSupportData,
                                    };
                                    if (e.target.checked) {
                                      data.additional_info = e.target.value;
                                    } else {
                                      data.additional_info = "";
                                    }
                                    setLogisticSupportData(data);
                                  }}
                                />
                                <label
                                  class="form-check-label fw-medium"
                                  for={"vesselFreightBuyerCost" + val.interest_id}
                                ></label>
                                <span>Vessel Freight (Buyer Cost)</span>
                              </div>
                              <div className="mt-2">
                                <span className="fw-medium">Message:</span>
                                <textarea
                                  name=""
                                  id=""
                                  value={logisticSupportData.message}
                                  cols="30"
                                  rows="3"
                                  className="form-control"
                                  onChange={(e) => {
                                    const data = { ...logisticSupportData };
                                    data.message = e.target.value;
                                    setLogisticSupportData(data);
                                  }}
                                ></textarea>
                              </div>
                            </>
                          )}
                          {showAdditionalLogisticsInfo && userId === val.user_id ? (
                            <button
                              className="mt-3 mb-3 btn btn-primary btn-xs-size btn-auto "
                              style={{ width: "180px" }}
                              onClick={() => {
                                handleLogisticInfo(val);
                              }}
                            >
                              Send Logistics Support Request
                            </button>
                          ) : (
                            <button
                              className="mt-3 mb-3 btn btn-primary btn-xs-size btn-auto "
                              style={{ width: "180px" }}
                              onClick={() => {
                                setUserId(val.user_id);
                                setShowAdditionalLogisticsInfo(true);
                              }}
                            >
                              New Logistics Support Request
                            </button>
                          )}
                          <div class="page-widget-label text-capitalize mt-4">Logistics Support Requests</div>
                          <div className="mt-2">
                            {val?.reqs
                              ?.filter((val) => val.reqtype === "Logistics Request")
                              ?.map((data) => (
                                <div className="d-flex justify-content-around">
                                  <div>{data?.status ? "Completed" : "Pending"}</div>
                                  <div>
                                    <a href="javascript:void(0)">Show Details</a>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </td>
                      <td className={val.interest_type === "Viewed" && "disabled-buyer-interest"}>
                        <div className="d-flex flex-column  justify-content-center">
                          <div class="table-responsive mb-0">
                            <table class="table table-borderless  no-padd-table">
                              <tr>
                                <td className="fw-medium">Salvex User Origin</td>
                                <td>:</td>
                                <td>
                                  <em>{val.interest_by ? val.interest_by : ""}</em>
                                </td>
                              </tr>
                              <tr>
                                <td className="fw-medium">Buyer Interest Origin</td>
                                <td>:</td>
                                <td>
                                  <em>{val.interest_origin ? val.interest_origin : ""}</em>
                                </td>
                              </tr>
                              <tr>
                                <td className="fw-medium">Buyer Interest Type</td>
                                <td>:</td>
                                <td>
                                  <em>{val.interest_type ? val.interest_type : ""}</em>
                                </td>
                              </tr>
                              <tr>
                                <td className="fw-medium">Best Deal Outcome</td>
                                <td>:</td>
                                <td>
                                  <em>{val.bid_status ? val.bid_status : ""}</em>
                                </td>
                              </tr>
                              <tr>
                                <td className="fw-medium">Buyer Bid Amount</td>
                                <td>:</td>
                                <td>
                                  <em>
                                    {val.bid_amount
                                      ? val.bid_amount.toLocaleString("en-US", {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })
                                      : "0.00"}
                                  </em>
                                </td>
                              </tr>
                              <tr>
                                <td className="fw-medium">Buyer Bid</td>
                                <td>:</td>
                                <td>
                                  <em>{val.buyer_bid ? val.buyer_bid : ""}</em>
                                </td>
                              </tr>
                              {/* <tr>
                                <td className="fw-medium">Bid Status</td>
                                <td>:</td>
                                <td>
                                  <em>
                                    {val.bid_status
                                      ? val.bid_status
                                      : ""}
                                  </em>
                                </td>
                              </tr> */}
                              <tr>
                                <td className="fw-medium">Buyer Bid Outcome</td>
                                <td>:</td>
                                <td>
                                  <em>{val.buyer_bid_outcome ? val.buyer_bid_outcome : ""}</em>
                                </td>
                              </tr>
                              <tr>
                                <td className="fw-medium">Seller Bid Outcome</td>
                                <td>:</td>
                                <td>
                                  <em>{val.seller_bid_outcome ? val.seller_bid_outcome : ""}</em>
                                </td>
                              </tr>
                            </table>
                          </div>
                          <div className="d-flex mt-3">
                            <span>Buyer Budget($): </span>
                            <input
                              type="text"
                              defaultValue={val.buyer_budget}
                              onChange={(e) => {
                                setBuyerBudgetData({
                                  interest_id: val.interest_id,
                                  buyer_budget: e.target.value,
                                });
                              }}
                              className="form-control"
                            />
                          </div>

                          <div className="d-flex flex-column mt-2 align-items-start">
                            <button
                              className="btn btn-primary btn-xs-size btn-auto"
                              onClick={() => handleUpdateBuyerBudget(val.interest_id)}
                            >
                              Save
                            </button>
                            {val.budget_updated_by && <sub className="mt-1">Updated By {val.budget_updated_by}</sub>}
                            <sub style={{ marginTop: "10px" }}>
                              <DateFormat date={val.buyer_budget_at} />{" "}
                            </sub>
                          </div>
                          <div className="d-flex mt-4">
                            <span className="mt-2">Bid Status: </span>
                            <Select
                              className="basic-single "
                              classNamePrefix="select-search"
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.value}
                              defaultValue={{
                                name: val.status ? val.status : "Don't Know",
                                value: val.status ? val.status : "Don't Know",
                              }}
                              options={bidStatusOptions}
                              name="bidStatus"
                              placeholder="Select a Status"
                              onChange={(e) => {
                                setBidStatusData({
                                  user_id: val?.user_id,
                                  interest_id: val.interest_id,
                                  status: e.value,
                                });
                              }}
                              styles={{
                                ...customStyles,
                                control: (base, state) => ({
                                  ...base,
                                  background:
                                    val.status === "" || val.status === "Don't Know"
                                      ? "red"
                                      : val.status === "Not Interested" ||
                                        val.status === "No Response 1st Attempt" ||
                                        val.status === "No Response 2nd Attempt" ||
                                        val.status === "No Response 3rd Attempt"
                                      ? "grey"
                                      : val.status === "Buyer Bid On Listing"
                                      ? "green"
                                      : val.status === "Buyer Will Bid" || val.status === "Getting Info From Seller"
                                      ? "orange"
                                      : "red",
                                }),
                              }}
                            />
                          </div>
                          <div className="d-flex flex-column mt-2 align-items-start">
                            <button className="btn btn-primary btn-xs-size btn-auto" onClick={handleUpdateBidStatus}>
                              Save
                            </button>
                            {val.status_updated_by && <sub className="mt-1">Updated By {val.status_updated_by}</sub>}
                            <sub style={{ marginTop: "10px" }}>
                              <DateFormat date={val.udpated_status} />{" "}
                            </sub>
                          </div>
                          <div className="d-flex mt-4">
                            <span className="mt-2">Max Bid Status:</span>
                            <Select
                              className="basic-single "
                              classNamePrefix="select-search"
                              options={maxBidStatusOptions}
                              defaultValue={{
                                name: val.max_bid_status ? val.max_bid_status : "Don't Know",
                                value: val.max_bid_status ? val.max_bid_status : "Don't Know",
                              }}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.value}
                              name="maxBidStatus"
                              onChange={(e) => {
                                setMaxBidStatusData({
                                  user_id: val.user_id,
                                  interest_id: val.interest_id,
                                  max_bid_status: e.value,
                                });
                              }}
                              placeholder="Select a Status"
                              styles={{
                                ...customStyles,
                                control: (base, state) => ({
                                  ...base,
                                  background:
                                    val.max_bid_status === "" || val.max_bid_status === "Don't Know"
                                      ? "red"
                                      : val.max_bid_status === "Max Bid Received"
                                      ? "green"
                                      : val.max_bid_status === "Buyer Can Increase Bid"
                                      ? "orange"
                                      : "red",
                                }),
                              }}
                            />
                          </div>
                          <div className="d-flex flex-column mt-2 align-items-start">
                            <button className="btn btn-primary btn-xs-size btn-auto" onClick={handleUpdateMaxBidStatus}>
                              Save
                            </button>
                            {val.max_bid_status_by && <sub className="mt-1">Updated By {val.max_bid_status_by}</sub>}
                            <sub style={{ marginTop: "10px" }}>
                              <DateFormat data={val.updated_max_bid_status} />{" "}
                            </sub>
                          </div>
                        </div>
                      </td>
                      <td className={val.interest_type === "Viewed" && "disabled-buyer-interest"}>
                        <div>
                          <div class="widget-form-widget">
                            <div class="form-group-15">
                              <label for="" class="form-label fw-medium">
                                Create Task
                              </label>
                              <Select
                                className="basic-single "
                                classNamePrefix="select-search"
                                options={[
                                  {
                                    task_type: 0,
                                    description: "Select Task",
                                    further: "",
                                  },
                                  ...taskType,
                                ]}
                                getOptionLabel={(option) => option.description}
                                getOptionValue={(option) => option.task_type}
                                styles={customStyles1}
                                name="taskType"
                                placeholder="Select Task"
                                onChange={(e) => {
                                  const data = { ...noteData };
                                  data.task_type = e.task_type;
                                  setNoteData(data);
                                }}
                              />
                            </div>
                            <div class="form-group-15">
                              <label for="" class="form-label fw-medium">
                                Additional Recipients
                              </label>
                              {/* <Select
                                className="basic-single "
                                isMulti
                                classNamePrefix="select-search"
                                options={requestor}
                                getOptionLabel={(option) => option.name01}
                                getOptionValue={(option) => option.member_id}
                                name="bidStatus"
                                placeholder="Select Requestor"
                                styles={customStyles1}
                                onChange={(e) => {
                                  const data = { ...noteData };
                                  data.to_id = e[e.length - 1].member_id;
                                  setNoteData(data);
                                }}
                              /> */}
                              <select
                                name="admin_comments"
                                id="admin_comments"
                                multiple
                                style={{ height: "116px", width: "100%" }}
                                onChange={(e) => {
                                  const data = { ...noteData };
                                  data.to_id = e[e.length - 1].member_id;
                                  setNoteData(data);
                                }}
                              >
                                {requestor?.map((val) => (
                                  <option value={val?.member_id}>{val?.name01}</option>
                                ))}
                              </select>
                            </div>
                            <div class="form-group-15">
                              <label for="" class="form-label fw-medium">
                                Note
                              </label>
                              <textarea
                                class="form-control white"
                                id="textarea130x130"
                                onChange={(e) => {
                                  const data = { ...noteData };
                                  data.message = e.target.value;
                                  setNoteData(data);
                                }}
                              ></textarea>
                            </div>
                            <div class="form-check d-flex align-items-center mt-3">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                name="sentEmail"
                                value=""
                                checked={noteData.sendemail}
                                id={val.interest_id}
                                onChange={(e) => {
                                  const data = { ...noteData };
                                  data.sendemail = e.target.checked;
                                  setNoteData(data);
                                }}
                              />
                              <label class="form-check-label fw-medium" for={val.interest_id}></label>
                              <span>Send Email</span>
                            </div>
                            <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                              <NextButton
                                label="Post Note"
                                classData="btn btn-default"
                                handleSubmit={() => handleChangeNote(val)}
                                loading={loadingSaveNote}
                              />
                            </div>
                          </div>
                          {val?.notes && val?.notes.length > 0 ? (
                            <div class="text-card-block-row mt-4" style={{ maxHeight: "200px", overflowY: "scroll" }}>
                              {val?.notes?.map((data) => (
                                <div class="text-card-block-column">
                                  <div class="tcbc-header d-flex flex-wrap align-items-center justify-content-between">
                                    <div class="tcbc-header-label d-flex flex-wrap align-items-center">
                                      <div class="fw-medium">{data.name}</div>
                                      <span>
                                        <DateFormat
                                          data={
                                            data?.created_date?.length > 0 ? data?.created_date[0] : data?.created_date
                                          }
                                        />
                                      </span>
                                      <div
                                        class="tcbc-text-row div-comm-notes-sec"
                                        dangerouslySetInnerHTML={{
                                          __html: data?.notes,
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div className="d-flex justify-content-center mt-4">
                              <div>
                                <span className="fw-medium text-muted">No Messages.</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </td>
                      <td>
                        <div className="d-flex flex-column align-items-center justify-content-center">
                          <div>
                            <button
                              className="mt-3 mb-3 btn btn-primary btn-xs-size btn-auto "
                              style={{ width: "180px" }}
                              onClick={() => handleMoreCustomerInfo(val.user_id)}
                            >
                              More Customer Info
                            </button>
                          </div>
                          <div>
                            <button
                              className="mt-3 mb-3 btn btn-primary btn-xs-size btn-auto "
                              style={{ width: "180px" }}
                            >
                              Trader Call Task
                            </button>
                          </div>
                          <div>
                            <button
                              className="mt-3 mb-3 btn btn-primary btn-xs-size btn-auto "
                              style={{ width: "180px" }}
                            >
                              Inventory Sharing
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
          {detailData?.data?.length > 0 && (
            <div className="d-flex justify-content-between mt-3">
              <div className="justify-content-start">
                Page {detailData?.page} of {detailData?.max_pages} ({detailData?.total_records} records)
              </div>
              <div class="table-pagination">
                <ul class="pagination">
                  <li class="page-item">
                    <a
                      class={`page-link ${page === 1 ? "disabled" : "cursor-pointer"}`}
                      aria-label="Previous"
                      onClick={() => {
                        if (page !== 1) {
                          setPage(page - 1);
                        }
                      }}
                    >
                      <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 13L1 7L7 1" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </a>
                  </li>
                  <li class="page-item active">
                    <a class="page-link" href="javascript:void(0)">
                      {page}
                    </a>
                  </li>
                  <li class="page-item">
                    {page < detailData?.max_pages && (
                      <a class="page-link" onClick={() => setPage(page + 1)}>
                        {page + 1}
                      </a>
                    )}
                  </li>
                  <li class="page-item">
                    {page < detailData?.max_pages && (
                      <a
                        class="page-link"
                        href="javascript:void(0)"
                        aria-label="Next"
                        onClick={() => setPage(page + 1)}
                      >
                        <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 13L7 7L1 1" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </a>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
      )}
      <SellerMotivationModal
        show={showSellerMotivation}
        onHide={toggleSellerMotivation}
        data={sellerMotivationData}
        preMotivationFetch={preMotivationFetch}
      />
      <BuyerMotivationModal
        show={showBuyerMotivation}
        onHide={toggleBuyerMotivation}
        data={buyerMotivationData}
        toggle={() => {
          toggleBuyerMotivation();
          toggleEditBuyerMotivation();
        }}
      />
      <EditBuyerMotivationModal
        show={showEditBuyerMotivation}
        onHide={toggleEditBuyerMotivation}
        data={buyerMotivationData}
        updateData={updateBuyerMotivationData}
        setUpdateData={setUpdateBuyerMotivationData}
        loading={loadingUpdate}
        handleSubmit={handleUpdateBuyerMotivationTextFields}
        updateCheckbox={buyerMotivationCheckboxData}
        setUpdateCheckbox={setBuyerMotivationCheckboxData}
      />
      <PreSellerMotivationModal
        show={showPreMotivation}
        onHide={togglePreMotivation}
        data={preMotivaitonData}
        values={preMotivaitonData}
        log1={log1}
        log2={log2}
        log3={log3}
        log4={log4}
        log5={log5}
        log6={log6}
        log7={log7}
        log8={log8}
        log9={log9}
        log10={log10}
        log11={log11}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getBuyerInterests: (params, page, filter, callback) => dispatch(getBuyerInterests(params, page, filter, callback)),
  updateBidStatus: (params, data, callback) => dispatch(updateBidStatus(params, data, callback)),
  updateBuyerBudget: (params, data, callback) => dispatch(updateBuyerBudget(params, data, callback)),
  updateMaxBidStatus: (params, data, callback) => dispatch(updateMaxBidStatus(params, data, callback)),
  getTaskType: (callback) => dispatch(getTaskType(callback)),
  getBuyerMotivation: (params, userId, callback) => dispatch(getBuyerMotivation(params, userId, callback)),
  getSellerMotivation: (params, userId, callback) => dispatch(getSellerMotivation(params, userId, callback)),
  getRequestor: (callback) => dispatch(getRequestor(callback)),
  saveNote: (params, data, callback) => dispatch(saveNote(params, data, callback)),
  sendProductInfoRequest: (params, data, callback) => dispatch(sendProductInfoRequest(params, data, callback)),
  sendInspectionSupportRequest: (params, data, callback) =>
    dispatch(sendInspectionSupportRequest(params, data, callback)),
  sendLogisticSupportRequest: (params, data, callback) => dispatch(sendLogisticSupportRequest(params, data, callback)),
  moreCustomerInfo: (params, userId, callback) => dispatch(buyerInterest(params, userId, callback)),
  updateBuyerMotivation: (params, data, callback) => dispatch(updateBuyerMotivation(params, data, callback)),
  updateBuyerMotivationCheckbox: (params, data, callback) =>
    dispatch(updateBuyerMotivationCheckbox(params, data, callback)),
  getPreMotivation: (params, userId, date, callback) => dispatch(getPreMotivation(params, userId, date, callback)),
  getMotivationLog: (userId, data, callback) => dispatch(getMotivationLog(userId, data, callback)),
});
const mapStateToProps = (state) => ({
  buyerInterestsData: buyerInterestsData(state),
});
export default connect(mapStateToProps, mapDispatchToProps)(React.memo(BuyerInterests));
