import React, { useEffect, useRef, useState } from "react";
import LoadingBar from "react-top-loading-bar";
import searchIcon from "../include/images/sw-search.svg";
import sendIcon from "../include/images/send.svg";
import { connect } from "react-redux";
import momentTimeZone from "moment-timezone";
import Skeleton from "react-loading-skeleton";
import Sidebar from "../layouts/sidebar";
import paperClip from "../include/images/paperclip.svg";
import { Amplify, Storage } from "aws-amplify";
import { Auth } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import AlertError from "../common/alerts/alertError";
import Spinner from "react-bootstrap/Spinner";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import mp4 from "../include/images/mp4.svg";
import fileIcon from "../include/images/file.svg";
import More from "../include/images/more-icon.svg";
import refreshIcon from "../include/images/Refresh.svg";
import {
  addReminderQuestion,
  getAllUnreadCount,
  getConversation,
  getConversationList,
  marAsReadConversation,
  markAsUnread,
  questionDetails,
  updateQuestion,
  updateReminderQuestion,
} from "../store/questions";
import { checkToken, getProfile } from "../utils.js/localStorageServices";
import { ConvertTime } from "../common/DateFormat/cstToLocalTime";
import ReactTooltip from "react-tooltip";
import Editor from "../common/editor/editor";
import parse from "html-react-parser";
import ReminderDate from "../layouts/modals/reminderMessages/reminderDate";
import AlertSuccess from "../common/alerts/alertSuccess";
import moment from "moment/moment";

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    region: "us-east-1",
  },
  Storage: {
    bucket: process.env.REACT_APP_S3_UPLOAD_BUCKET,
    region: "us-east-1",
  },
});

const Chats = (props) => {
  const ref = useRef(null);
  const leftElementRef = useRef(null);
  const [loading, setLoading] = React.useState(false);
  const [messageLoading, setMessageLoading] = React.useState(false);
  const [message, setMessage] = React.useState("");

  const [search, setSearch] = React.useState("");
  const [showPopup, setShowPopup] = React.useState(false);
  const [media, setMedia] = useState([]);
  //For SideNav
  const [showMenu, setShowMenu] = useState(false);
  const [menuList, setMenuList] = useState("");

  //New States
  const [chatList, setChatList] = React.useState([]);
  const [selectedChat, setSelectedChat] = React.useState([]);
  const [messageList, setMessageList] = React.useState([]);
  const [listLoading, setListLoading] = useState(false);
  const [loadingFileUpload, setLoadingFileUpload] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [selectedToUpload, setSelectedToUpload] = useState(0);
  const [markedArray, setMarkedArray] = useState([]);
  const [editorLoaded, setEditorLoaded] = useState(false);

  const [showReminder, setShowReminder] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);

  const handleRefresh = () => {
    setRefresh(!refresh);
  };

  const [listPage, setListPage] = useState(1);
  const listMaxPage = props?.questionDetails?.conversationList?.max_pages;

  const loadChatList = () => {
    ref?.current?.continuousStart();
    setListLoading(true);
    props?.getConversationList({ page: listPage, limit: 20 }, (res) => {
      if (res && res.status === 200) {
        setListLoading(false);
        ref?.current?.complete();
        setChatList((prevData) => [...prevData, ...res?.data?.data]);
        if (res?.data?.data && res?.data?.data?.length > 0 && listPage === 1) {
          setSelectedChat(res?.data?.data[0]);
        }
      }
    });
  };

  const loadConversationList = () => {
    ref?.current?.continuousStart();

    setMessageLoading(true);
    props?.getConversation(selectedChat?.buyers_conversation_on_auction_key, (res) => {
      if (res && res.status === 200) {
        if (res?.data?.unread_ids?.length > 0) {
          props.marAsReadConversation({ unread_ids: res?.data?.unread_ids }, (res1) => {
            if (res1 && res1.status === 200) {
              props?.getAllUnreadCount((res2) => {});
            }
          });
        }

        ref?.current?.complete();
        setMessageLoading(false);
        setMessageList(res?.data?.data);
      } else {
        ref?.current?.complete();
        toast(<AlertError message={res?.data?.response ? res?.data?.response : "Something went wrong"} />);
      }
    });
  };

  useEffect(() => {
    loadChatList();
    props?.getAllUnreadCount((res2) => {});
  }, [listPage]);

  useEffect(() => {
    if (selectedChat?.buyers_conversation_on_auction_key) {
      loadConversationList();
      props?.getAllUnreadCount((res2) => {});
      setMedia([]);
      setUploadedFileName([]);

      const chatLoader = setInterval(() => {
        props?.getAllUnreadCount((res2) => {});
        props?.getConversation(selectedChat?.buyers_conversation_on_auction_key, (res) => {
          if (res && res.status === 200) {
            if (res?.data?.unread_ids?.length > 0) {
              props.marAsReadConversation({ unread_ids: res?.data?.unread_ids }, (res1) => {
                if (res1 && res1?.status === 200) {
                  props?.getAllUnreadCount((res2) => {});
                }
              });
            }

            setMessageList(res?.data?.data);
          } else {
            toast(<AlertError message={res?.data?.response ? res?.data?.response : "Something went wrong"} />);
          }
        });
      }, 5000 * 60);
      return () => clearInterval(chatLoader);
    }
  }, [selectedChat, refresh]);

  useEffect(() => {
    //add and remove body class
    if (showPopup) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [showPopup]);

  const toggleChat = () => {
    // add class to body
    document.body.classList.toggle("show-chat-pannel");
  };

  useEffect(() => {
    setEditorLoaded(true);
    toggleChat();
  }, []);

  useEffect(() => {
    const objDiv = document.getElementById("chatPannelBody");
    if (objDiv) objDiv.scrollTop = objDiv.scrollHeight + 1000;
  }, [messageList, messageLoading]);

  const member = getProfile();

  const pasteImg = async () => {
    try {
      const clipboardItems = await navigator.clipboard.read();
      if (clipboardItems[0]) {
        const blobOutput = await clipboardItems[0].getType("image/png");
        // const data = URL.createObjectURL(blobOutput);
        var file = new File([blobOutput], "image.png", { type: "image/png" });
        const a = [...media, file];
        uploadChatMedia([file]);
        setMedia(a);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleSend = () => {
    if (!loading && (message?.length > 0 || uploadedFileName?.length > 0)) {
      setLoading(true);
      ref?.current?.continuousStart();
      const data = {};
      data.response = message ? message : uploadedFileName?.length > 0 ? "File(s) Attached." : "";
      data.q_id = selectedChat?.q_id ? selectedChat?.q_id : "";
      data.mm_name = member.name01 ? member.name01 : "";
      data.mm_email = member.email ? member.email : "";
      data.email = selectedChat?.user_email ? selectedChat?.user_email : "";
      if (uploadedFileName?.length > 0) {
        data.files = [...uploadedFileName];
      }

      props?.updateQuestion(selectedChat?.auction_id, data, (res) => {
        if (res && res.status === 200) {
          data.message = message ? message : uploadedFileName?.length > 0 ? "File(s) Attached." : "";
          data.isQuestion = false;
          data.date_created = momentTimeZone().tz("America/Chicago").format("YYYY-MM-DDTHH:mm:ss.SSSZ");
          data.files = uploadedFileName?.map((ie) => ({
            file_url: ie,
          }));
          const updatedData = [...messageList, data];
          setMessage("");
          setMedia([]);
          setUploadedFileName([]);
          setMessageList(updatedData);
          setLoading(false);
          ref?.current?.complete();
        } else {
          ref?.current?.complete();
          toast.error("Something went wrong !", { theme: "colored" });
        }
      });
    }
  };

  const uploadChatMedia = (med) => {
    if (med) {
      const a = [];
      for (let i = 0; i < med.length; i++) {
        ref?.current?.continuousStart();
        setSelectedToUpload(med?.length);
        setLoadingFileUpload(true);
        const file = med[i];

        const fSize = Math.round(file.size / 1048576);
        const fType = file.type;
        const ext = file.name.split(".").pop();
        if (fSize > 250) {
          return (
            toast(<AlertError message="Media size exceeds maximum allowable size. Maximum allowable size is 250MB." />),
            setSelectedToUpload(0),
            setLoadingFileUpload(false),
            ref?.current?.complete()
          );
        } else if (
          ![
            "image/jpeg",
            "image/jpg",
            "image/png",
            "video/MP4",
            "video/mp4",
            "video/webm",
            "video/mpeg",
            "application/pdf",
            "text/csv",
            "application/vnd.ms-excel",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          ].includes(fType)
        ) {
          return (
            toast(
              <AlertError message="Media is not of correct format and hence cannot be send. Valid image formats are jpeg, jpg, png, mp4, webm, mpeg, pdf, xlsx, xls and csv." />,
            ),
            setSelectedToUpload(0),
            setLoadingFileUpload(false),
            ref?.current?.complete()
          );
        } else {
          const fileName = `chat/${selectedChat?.user_member_id}/${member?.user_id}/` + uuidv4() + `/${file?.name}`;

          a.push(
            Storage.put(fileName, file, {
              completeCallback: (event) => {},
              progressCallback: (progress) => {},
              errorCallback: (err) => {},
            }),
          );
        }
      }
      const allPromise = Promise.all([...a]);
      ref?.current?.complete();
      allPromise.then((result) => {
        setUploadedFileName((prev) => [...prev, ...result?.map((ir) => ir?.key)]);
        const a = [...media, ...med];
        setMedia(a);
        setSelectedToUpload(0);
        setLoadingFileUpload(false);
      });
    }
  };

  useEffect(() => {
    document.body.classList.add("pt-0");
    document.body.classList.remove("pt-80");

    return () => {
      document.body.classList.add("pt-80");
      document.body.classList.remove("pt-0");
    };
  }, []);

  const setReminderQuestion = () => {
    if (!loading) {
      setLoading(true);
      ref?.current?.continuousStart();
      props?.addReminderQuestion(
        {
          q_ids: [selectedMessage?.q_id],
          date: selectedMessage?.reminderDate,
        },
        (res) => {
          if (res && res?.status === 200) {
            ref?.current?.complete();
            setLoading(false);
            setShowReminder(false);
            toast(<AlertSuccess message={"Reminder added"} />);
          } else {
            ref?.current?.complete();
            setLoading(false);
            toast(<AlertError message={res?.data?.message ? res?.data?.message : "Something went wrong"} />);
          }
        },
      );
    }
  };

  const MarkAsUnread = () => {
    if (!loading) {
      setLoading(true);
      ref?.current?.continuousStart();
      props?.markAsUnread(
        {
          read_ids: [selectedMessage?.conversation_id],
        },
        (res) => {
          if (res && res?.status === 200) {
            setSelectedChat(null);
            setSelectedMessage(null);
            props?.getAllUnreadCount((res2) => {});

            props?.getConversationList({ page: listPage, limit: 20 }, (res) => {
              if (res && res.status === 200) {
                setChatList(res?.data?.data);
              }
            });
            ref?.current?.complete();
            setLoading(false);
            setShowReminder(false);
          } else {
            ref?.current?.complete();
            setLoading(false);
            toast(<AlertError message={res?.data?.message ? res?.data?.message : "Something went wrong"} />);
          }
        },
      );
    }
  };

  return (
    <>
      <LoadingBar height={5} color="#47AD1D" ref={ref} />
      {/* <Header /> */}
      <div class="dashboard-insider position-relative d-lg-flex w-100 ">
        <Sidebar
          showMenu={showMenu}
          setShowMenu={setShowMenu}
          menuList={menuList}
          setMenuList={setMenuList}
          page="chats"
        />
        <div className={showMenu ? ` main-menu-expanded ` : "main-menu-compressed"}>
          <div class="chat-outer d-flex flex-wrap">
            <div class="chat-sidebar h-100 d-flex flex-column">
              <div class="tab-content flex-grow-1" id="myTabContent">
                <div class="tab-pane fade show active h-100 flex-column" id="paneA">
                  <div class="search-widget position-relative">
                    <input
                      value={search}
                      class="sw-search"
                      type="search"
                      placeholder="Search"
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                    />
                    <div class="sw-icon-box d-flex align-items-center justify-content-center">
                      <img class="w-100 h-100" src={searchIcon} alt="" />
                    </div>
                  </div>
                  <div class="chat-member-widget flex-grow-1" ref={leftElementRef}>
                    <ul class="chat-member-list">
                      {chatList
                        ?.filter((x) =>
                          search
                            ? x?.user_name.toLowerCase().includes(search.toLowerCase()) ||
                              x?.title?.toLowerCase().includes(search.toLowerCase()) ||
                              x?.auction_id?.toString().includes(search.toLowerCase())
                            : true,
                        )
                        .map((list) => (
                          <li
                            onClick={(e) => {
                              e.preventDefault();
                              setSelectedChat(list);
                            }}
                            class={`chat-member-item ${
                              selectedChat?.conversation_id === list?.conversation_id ? "active-selected-chat" : ""
                            }`}
                          >
                            <div
                              class={
                                selectedChat?.conversation_id === list?.conversation_id
                                  ? "chat-member-box d-flex flex-wrap w-100 h-100 chat-pending-notify active"
                                  : "chat-member-box d-flex flex-wrap w-100 h-100 chat-pending-notify "
                              }
                            >
                              <div
                                class="custom-profile"
                                style={{ backgroundColor: list?.chat_color ? list?.chat_color : "#47ad1d" }}
                              >
                                <p className="chat-dp">
                                  {list?.user_name.split(" ")[0] && list?.user_name.split(" ")[0][0]}
                                  {list?.user_name.split(" ")[1] && list?.user_name.split(" ")[1][0]}
                                </p>
                              </div>
                              <div class="chat-member-detail">
                                <h5>{list?.user_name}</h5>

                                <div class="chat-member-txt">
                                  <p>
                                    {list?.auction_id} - {list?.title}
                                  </p>
                                  {list.unread_ids?.length > 0 && !markedArray?.includes(list.conversation_id) ? (
                                    <span
                                      class="d-flex align-items-center justify-content-center"
                                      style={{ background: "red" }}
                                    >
                                      {list?.unread_ids?.length}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          </li>
                        ))}
                      {chatList?.filter((x) =>
                        search
                          ? x?.user_name.toLowerCase().includes(search.toLowerCase()) ||
                            x?.title?.toLowerCase().includes(search.toLowerCase()) ||
                            x?.auction_id?.toString().includes(search.toLowerCase())
                          : true,
                      )?.length > 0 && listPage < listMaxPage ? (
                        <OverlayTrigger
                          placement="left"
                          overlay={
                            <Tooltip style={{ position: "fixed" }}>
                              <span>Click here to load more chats</span>
                            </Tooltip>
                          }
                        >
                          <div
                            class={"chat-member-box d-flex flex-wrap  h-100 chat-pending-notify "}
                            style={{ width: "60px", margin: "0px auto" }}
                            onClick={() => setListPage((prevPage) => prevPage + 1)}
                          >
                            <svg
                              fill="#46ac1c"
                              height="50px"
                              width="50px"
                              version="1.1"
                              id="Layer_1"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 64 64"
                              enable-background="new 0 0 64 64"
                              stroke="#46ac1c"
                            >
                              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                              <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                              <g id="SVGRepo_iconCarrier">
                                {" "}
                                <g id="Down-arrow">
                                  {" "}
                                  <path d="M17.2929993,29.7070007c-0.3906994-0.3906002-0.3906994-1.0234013,0-1.4140015 c0.3906002-0.3906002,1.0234013-0.3906002,1.4140015,0L32,41.5858994l13.2929993-13.2929001 c0.3905983-0.3906002,1.0233994-0.3906002,1.4140015,0C46.902298,28.4883003,47,28.7441998,47,29 c0,0.2558994-0.097702,0.5116997-0.2929993,0.7070007l-14,14c-0.3906021,0.3906975-1.0234013,0.3906975-1.4140015,0 L17.2929993,29.7070007z"></path>{" "}
                                  <path d="M32,64c17.6730957,0,32-14.3269005,32-32S49.6730957,0,32,0C14.3268003,0,0,14.3268995,0,32S14.3268003,64,32,64z M32,62 C15.4579,62,2,48.542099,2,32C2,15.4580002,15.4579,2,32,2s30,13.4580002,30,30C62,48.542099,48.542099,62,32,62z"></path>{" "}
                                </g>{" "}
                              </g>
                            </svg>
                          </div>
                        </OverlayTrigger>
                      ) : (
                        ""
                      )}
                    </ul>
                  </div>
                </div>
                <div class="tab-pane fade flex-grow-1 h-100 flex-column" id="paneB">
                  <div class="search-widget position-relative">
                    <input class="sw-search" type="search" placeholder="Search" />
                    <div class="sw-icon-box d-flex align-items-center justify-content-center">
                      <img class="w-100 h-100" src={searchIcon} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {selectedChat?.conversation_id && (
              <div class="chat-main-container h-100 d-flex flex-column">
                <div class="chat-pannel-header d-flex align-items-center justify-content-between">
                  <div class="cp-member  d-flex align-items-center flex-wrap">
                    <div class="chat-menu-icon d-lg-none" id="chatCollapseBtn">
                      <i class="fas fa-comments"></i>
                    </div>
                    <div
                      class="custom-profile"
                      style={{ backgroundColor: selectedChat?.chat_color ? selectedChat?.chat_color : "#47ad1d" }}
                    >
                      <p className="chat-dp">
                        {selectedChat?.user_name.split(" ")[0] && selectedChat?.user_name.split(" ")[0][0]}
                        {selectedChat?.user_name.split(" ")[1] && selectedChat?.user_name.split(" ")[1][0]}
                      </p>
                    </div>
                    <div class="cp-member-details ">
                      <span className=" position-relative" style={{ fontWeight: "500" }}>
                        {selectedChat?.user_name}
                      </span>
                      <p className="d-block">
                        <span className="gray-color-text">{selectedChat?.user_email}</span>
                        <span
                          className="gray-color-text"
                          style={{
                            borderLeft: "1px solid #efefef",
                            paddingLeft: "5px",
                            paddingLeft: "9px",
                            marginLeft: "9px",
                          }}
                        >
                          {selectedChat?.user_phone}
                        </span>
                      </p>
                    </div>
                  </div>

                  <div class="list-detail-pannel d-flex align-items-center">
                    <div
                      className="cursor-pointer"
                      data-tip={"Click here to load latest messages"}
                      onClick={handleRefresh}
                    >
                      <img src={refreshIcon} alt="refresh-icon" />
                    </div>
                    <ReactTooltip effect="solid" place="left" type="dark" border={true} />
                    <div
                      className="d-none d-md-block"
                      target="_blank"
                      style={{
                        marginLeft: "10px",
                        textDecoration: "underline",
                        color: "#47ad1d",
                        width: "100%",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        //open in new tab
                        window.open(`/listing-detail?id=${selectedChat?.auction_id}`, "_blank");
                      }}
                    >
                      {selectedChat?.auction_id} - {selectedChat?.title}
                    </div>
                  </div>
                </div>

                <div class="chat-pannel-body chat-new-body flex-grow-1 d-flex flex-column" id="chatPannelBody">
                  {!messageLoading ? (
                    messageList?.map((chatMassage, i) => (
                      <React.Fragment key={i}>
                        {!chatMassage?.isQuestion ? (
                          chatMassage?.files?.length > 0 ? (
                            <>
                              <div className="message d-flex flex-column align-items-start position-relative send pb-3">
                                <div class="m-content">
                                  <p className="ck-content">{parse(chatMassage?.message)}</p>
                                </div>

                                <div class="m-text-xs d-flex align-items-center">
                                  {ConvertTime(chatMassage?.date_created)}{" "}
                                </div>
                              </div>
                              {chatMassage?.files?.map((it) => {
                                const fileType = it?.file_url?.split(".")?.pop();
                                return (
                                  <>
                                    <a
                                      href={process.env.REACT_APP_CHAT_MEDIA + "public/" + it?.file_url}
                                      target="_blank"
                                      class="message d-flex flex-column align-items-start position-relative send pb-3"
                                    >
                                      {["jpeg", "jpg", "png"].includes(fileType) ? (
                                        <img
                                          style={{ height: "60px" }}
                                          src={process.env.REACT_APP_CHAT_MEDIA + "public/" + it?.file_url}
                                          alt=""
                                        />
                                      ) : ["mp4", "MP4", "mpeg", "webm"].includes(fileType) ? (
                                        <div class="media-widget-box position-relative">
                                          <div class="media-widget-file-icon d-flex align-items-center justify-content-center">
                                            <img src={mp4} alt="" />
                                          </div>
                                          <div class="media-widget-content">
                                            <div class="media-intro">
                                              <div class="media-into d-flex flex-nowrap">
                                                <span>{it?.file_url?.split("/")?.[4]?.substring(0, 5) + "..."}</span>
                                              </div>
                                            </div>
                                            <div class="file-size">{fileType}</div>
                                          </div>
                                        </div>
                                      ) : ["csv", "xlsx", "xls", "pdf", "doc", "docx"].includes(fileType) ? (
                                        <div class="media-widget-box position-relative">
                                          <div class="media-widget-file-icon d-flex align-items-center justify-content-center">
                                            <img src={fileIcon} alt="" />
                                          </div>
                                          <div class="media-widget-content">
                                            <div class="media-intro">
                                              <div class="media-into d-flex flex-nowrap">
                                                <span>{it?.file_url?.split("/")?.[4]?.substring(0, 5) + "..."}</span>
                                              </div>
                                            </div>
                                            <div class="file-size">{fileType}</div>
                                          </div>
                                        </div>
                                      ) : (
                                        <div class="media-widget-box position-relative">
                                          <div class="media-widget-file-icon d-flex align-items-center justify-content-center">
                                            <img src={fileIcon} alt="" />
                                          </div>
                                          <div class="media-widget-content">
                                            <div class="media-intro">
                                              <div class="media-into d-flex flex-nowrap">
                                                <span>
                                                  {chatMassage?.message?.split("/")?.[4]?.substring(0, 15) + "..."}
                                                </span>
                                              </div>
                                            </div>
                                            <div class="file-size">{fileType}</div>
                                          </div>
                                        </div>
                                      )}

                                      <div class="m-text-xs d-flex align-items-center">
                                        {ConvertTime(chatMassage.date_created)}{" "}
                                      </div>
                                    </a>
                                  </>
                                );
                              })}
                            </>
                          ) : (
                            <div class="message d-flex flex-column align-items-start  position-relative send pb-3">
                              <div class="m-content">
                                <p className="ck-content">{parse(chatMassage?.message)}</p>
                              </div>

                              <div class="m-text-xs d-flex align-items-center">
                                {ConvertTime(chatMassage?.date_created)}{" "}
                              </div>
                            </div>
                          )
                        ) : chatMassage?.files?.length > 0 ? (
                          <>
                            <div class="message d-flex  align-items-start position-relative received">
                              <div class=" d-flex flex-column align-items-start">
                                <div
                                  class="custom-profile"
                                  style={{
                                    backgroundColor: selectedChat?.chat_color ? selectedChat?.chat_color : "#47ad1d",
                                  }}
                                >
                                  <p className="chat-dp">
                                    {" "}
                                    {chatMassage?.name &&
                                      chatMassage?.name?.split(" ")[0] &&
                                      chatMassage?.name?.split(" ")[0][0]}
                                    {chatMassage?.name &&
                                      chatMassage?.name?.split(" ")[1] &&
                                      chatMassage?.name?.split(" ")[1][0]}
                                  </p>
                                </div>
                                <div class="m-content">
                                  <p className="ck-content">{parse(chatMassage?.message)}</p>
                                </div>
                                <div class="m-text-xs d-flex align-items-center">
                                  {chatMassage?.name?.split(" ")[0]}, {ConvertTime(chatMassage?.date_created)}{" "}
                                </div>
                                {chatMassage?.future_reminders?.length > 0 ? (
                                  <div className="m-text-xs d-flex align-items-center mt-0">
                                    <p style={{ color: "red", fontSize: "10px" }}>{`Marked for REVISIT on ${moment(
                                      ConvertTime(chatMassage?.future_reminders?.[0]?.reminder_on_utc),
                                    )?.format("MM-DD-YYYY")} by ${chatMassage?.future_reminders?.[0]?.name01}${
                                      chatMassage?.future_reminders?.length > 1
                                        ? ` & ${chatMassage?.future_reminders?.length - 1} Others`
                                        : ""
                                    }`}</p>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div class="dropdown">
                                <a
                                  class="td-a-icon dropdown-toggle cursor-pointer more-icon-size"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                  onClick={() => setSelectedMessage(chatMassage)}
                                >
                                  <img src={More} alt="" />
                                </a>
                                <ul class="dropdown-menu ms-1">
                                  <li className="menu-item">
                                    <a class="dropdown-item" href="javascript:void(0)" onClick={() => MarkAsUnread()}>
                                      Mark as Unread
                                    </a>
                                  </li>
                                  <li className="menu-item">
                                    <a
                                      class="dropdown-item"
                                      href="javascript:void(0)"
                                      onClick={() => {
                                        setShowReminder(true);
                                      }}
                                    >
                                      Reminder
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            {chatMassage?.files?.map((it) => {
                              const fileType = it?.file_url?.split(".")?.pop();
                              return (
                                <>
                                  <a
                                    href={process.env.REACT_APP_CHAT_MEDIA + "public/" + it?.file_url}
                                    target="_blank"
                                    class="message d-flex flex-column align-items-start position-relative received pb-3"
                                  >
                                    {["jpeg", "jpg", "png"].includes(fileType) ? (
                                      <img
                                        style={{ height: "60px" }}
                                        src={process.env.REACT_APP_CHAT_MEDIA + "public/" + it?.file_url}
                                        alt=""
                                      />
                                    ) : ["mp4", "MP4", "mpeg", "webm"].includes(fileType) ? (
                                      <div class="media-widget-box position-relative">
                                        <div class="media-widget-file-icon d-flex align-items-center justify-content-center">
                                          <img src={mp4} alt="" />
                                        </div>
                                        <div class="media-widget-content">
                                          <div class="media-intro">
                                            <div class="media-into d-flex flex-nowrap">
                                              <span>{it?.file_url?.split("/")?.[4]?.substring(0, 5) + "..."}</span>
                                            </div>
                                          </div>
                                          <div class="file-size">{fileType}</div>
                                        </div>
                                      </div>
                                    ) : ["csv", "xlsx", "xls", "pdf"].includes(fileType) ? (
                                      <div class="media-widget-box position-relative">
                                        <div class="media-widget-file-icon d-flex align-items-center justify-content-center">
                                          <img src={fileIcon} alt="" />
                                        </div>
                                        <div class="media-widget-content">
                                          <div class="media-intro">
                                            <div class="media-into d-flex flex-nowrap">
                                              <span>{it?.file_url?.split("/")?.[4]?.substring(0, 5) + "..."}</span>
                                            </div>
                                          </div>
                                          <div class="file-size">{fileType}</div>
                                        </div>
                                      </div>
                                    ) : (
                                      <div class="media-widget-box position-relative">
                                        <div class="media-widget-file-icon d-flex align-items-center justify-content-center">
                                          <img src={fileIcon} alt="" />
                                        </div>
                                        <div class="media-widget-content">
                                          <div class="media-intro">
                                            <div class="media-into d-flex flex-nowrap">
                                              <span>{it?.file_url?.split("/")?.[4]?.substring(0, 15) + "..."}</span>
                                            </div>
                                          </div>
                                          <div class="file-size">{fileType}</div>
                                        </div>
                                      </div>
                                    )}

                                    <div class="m-text-xs d-flex align-items-center">
                                      {ConvertTime(chatMassage.date_created)}{" "}
                                    </div>
                                  </a>
                                </>
                              );
                            })}
                          </>
                        ) : (
                          <div class="message d-flex  align-items-start position-relative received pb-3">
                            <div class=" d-flex flex-column align-items-start ">
                              <div
                                class="custom-profile"
                                style={{
                                  backgroundColor: selectedChat?.chat_color ? selectedChat?.chat_color : "#47ad1d",
                                }}
                              >
                                <p className="chat-dp">
                                  {" "}
                                  {chatMassage?.name &&
                                    chatMassage?.name?.split(" ")[0] &&
                                    chatMassage?.name?.split(" ")[0][0]}
                                  {chatMassage?.name &&
                                    chatMassage?.name?.split(" ")[1] &&
                                    chatMassage?.name?.split(" ")[1][0]}
                                </p>
                              </div>
                              <div class="m-content">
                                <p className="ck-content">{parse(chatMassage?.message)}</p>
                              </div>
                              <div class="m-text-xs d-flex align-items-center">
                                {chatMassage?.name?.split(" ")[0]}, {ConvertTime(chatMassage?.date_created)}{" "}
                              </div>
                              {chatMassage?.future_reminders?.length > 0 ? (
                                <div className="m-text-xs d-flex align-items-center mt-0">
                                  <p style={{ color: "red", fontSize: "10px" }}>{`Marked for REVISIT on ${moment(
                                    ConvertTime(chatMassage?.future_reminders?.[0]?.reminder_on_utc),
                                  )?.format("MM-DD-YYYY")} by ${chatMassage?.future_reminders?.[0]?.name01}${
                                    chatMassage?.future_reminders?.length > 1
                                      ? ` & ${chatMassage?.future_reminders?.length - 1} Others`
                                      : ""
                                  }`}</p>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                            <div class="dropdown">
                              <a
                                class="td-a-icon dropdown-toggle cursor-pointer more-icon-size"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                onClick={() => setSelectedMessage(chatMassage)}
                              >
                                <img src={More} alt="" />
                              </a>
                              <ul class="dropdown-menu ms-1">
                                <li className="menu-item">
                                  <a class="dropdown-item" href="javascript:void(0)" onClick={() => MarkAsUnread()}>
                                    Mark as Unread
                                  </a>
                                </li>
                                <li className="menu-item">
                                  <a
                                    class="dropdown-item"
                                    href="javascript:void(0)"
                                    onClick={() => {
                                      setShowReminder(true);
                                    }}
                                  >
                                    Reminder
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        )}
                      </React.Fragment>
                    ))
                  ) : (
                    <>
                      <Skeleton height={40} />
                      <Skeleton height={40} />
                      <Skeleton height={40} />
                      <Skeleton height={40} />
                      <Skeleton height={40} />
                      <Skeleton height={40} />
                      <Skeleton height={40} />
                      <Skeleton height={40} />
                    </>
                  )}
                </div>
                {media?.length > 0 || selectedToUpload > 0 ? (
                  <div className="chat-media-item">
                    <div className="chat-media-list">
                      {Array.from(media)?.length > 0 &&
                        Array.from(media)?.map((item) => {
                          const ext = item.name.split(".").pop();
                          return (
                            <>
                              {ext == "jpg" || ext == "jpeg" || ext == "png" ? (
                                <img
                                  className="chat-images"
                                  style={{ height: "40px" }}
                                  src={`${URL.createObjectURL(item)}`}
                                />
                              ) : ext == "mp4" || ext == "MP4" || ext == "mpeg" || ext == "webm" ? (
                                <img className="chat-images" style={{ height: "40px" }} src={mp4} />
                              ) : (
                                <img className="chat-images" style={{ height: "40px" }} src={fileIcon} />
                              )}
                            </>
                          );
                        })}
                      {loadingFileUpload || selectedToUpload > 0
                        ? Array(selectedToUpload)
                            .fill(0)
                            ?.map((ie) => (
                              <Spinner
                                className="chat-loader"
                                animation="border"
                                role="status"
                                style={{ marginRight: "10px", marginTop: "5px" }}
                              >
                                <span className="visually-hidden">Loading...</span>
                              </Spinner>
                            ))
                        : ""}
                      {loadingFileUpload || selectedToUpload > 0 ? (
                        <div className="chat-media-list">Please wait while the files are being uploaded...</div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <div class="chat-pannel-footer chat-lg-footer">
                  <div class="custom-input-group w-100 d-flex align-items-start ">
                    {" "}
                    <OverlayTrigger
                      placement="left"
                      delay={{ show: 250, hide: 400 }}
                      overlay={(p) => (
                        <Tooltip id="button-tooltip" {...p} className="chat-media-tooltip">
                          Click here to send files, images and videos
                        </Tooltip>
                      )}
                    >
                      <div class="input-group-prepend position-relative cursor-pointer">
                        <input
                          title=" "
                          type="file"
                          id="inputGroupFile02dk"
                          multiple
                          accept=".jpg,.jpeg,.png,.csv,.xls,.xlsx,.pdf,.mp4,.mpeg,.webm,.MP4"
                          onChange={(e) => {
                            uploadChatMedia(Array.from(e.target.files));
                          }}
                        />

                        <a href="#!" class="input-group-text">
                          <img src={paperClip} alt="" />
                        </a>
                      </div>
                    </OverlayTrigger>
                    <div className="custom-send-input chat-page-textarea chat-page-texteditor flex-grow-1 h-100">
                      <Editor
                        id="textarea-chat-page"
                        name="description"
                        className="ck-content "
                        onChange={(data) => {
                          setMessage(data);
                        }}
                        editorLoaded={editorLoaded}
                        value={message}
                        isChat={true}
                      />
                    </div>
                    {/* <textarea
                      type="text"
                      class="custom-send-input chat-page-textarea flex-grow-1 h-100"
                      placeholder="Type a message"
                      value={message}
                      onChange={(e) => {
                        setMessage(e.target.value);
                      }}
                      
                    /> */}
                    <div
                      class={`input-group-append rounded-circle cursor-pointer ${
                        loadingFileUpload || loading || (message?.length === 0 && uploadedFileName?.length === 0)
                          ? "chat-send-btn-disabled"
                          : ""
                      }`}
                    >
                      {loadingFileUpload ? (
                        <OverlayTrigger
                          placement="left"
                          delay={{ show: 250, hide: 400 }}
                          overlay={(p) => (
                            <Tooltip id="button-tooltip" {...p}>
                              Please wait while the files are being uploaded
                            </Tooltip>
                          )}
                        >
                          <a href="javascript:void(0)" style={{ padding: "10px 0px 1px 8px" }}>
                            <img src={sendIcon} alt="sendIcon" />
                            <span>Send</span>
                          </a>
                        </OverlayTrigger>
                      ) : message?.length === 0 && uploadedFileName?.length === 0 ? (
                        <OverlayTrigger
                          placement="left"
                          delay={{ show: 250, hide: 400 }}
                          overlay={(p) => (
                            <Tooltip id="button-tooltip" {...p}>
                              Message field cannot be empty
                            </Tooltip>
                          )}
                        >
                          <a href="javascript:void(0)" style={{ padding: "10px 0px 1px 8px" }}>
                            <img src={sendIcon} alt="sendIcon" />
                            <span>Send</span>
                          </a>
                        </OverlayTrigger>
                      ) : (
                        <a href="javascript:void(0)" style={{ padding: "10px 0px 1px 8px" }} onClick={handleSend}>
                          <img src={sendIcon} alt="sendIcon" />
                          <span>Send</span>
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {showReminder && (
          <ReminderDate
            show={showReminder}
            onHide={(prev) => setShowReminder(!prev)}
            selectedMessage={selectedMessage}
            setReminderQuestion={setReminderQuestion}
            loading={loading}
            setSelectedMessage={setSelectedMessage}
            auctionDetail={`${selectedChat?.auction_id} - ${selectedChat?.title}`}
          />
        )}
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getConversationList: (params, callback) => dispatch(getConversationList(params, callback)),
  getConversation: (id, callback) => dispatch(getConversation(id, callback)),
  marAsReadConversation: (data, callback) => dispatch(marAsReadConversation(data, callback)),
  updateQuestion: (id, data, callback) => dispatch(updateQuestion(id, data, callback)),
  getAllUnreadCount: (callback) => dispatch(getAllUnreadCount(callback)),
  addReminderQuestion: (data, callback) => dispatch(addReminderQuestion(data, callback)),
  updateReminderQuestion: (data, callback) => dispatch(updateReminderQuestion(data, callback)),
  markAsUnread: (data, callback) => dispatch(markAsUnread(data, callback)),
});

const mapStateToProps = (state) => ({
  questionDetails: questionDetails(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Chats));
