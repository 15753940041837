import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import NextButton from "../../../common/form/nextButton";
import close from "../../../include/images/close.svg";
import Select from "react-select";
import { customStyles } from "../../../common/customStyles/reactSelectStyle";
import Editor from "../../../common/editor/editor";

const EditTaskModal = (props) => {
  const assignId = props.editAssignData && props.editAssignData.length > 0 && props.editAssignData.map((val) => val);

  return (
    <Modal
      className="modal fade custom-modal"
      id="addTaskModal"
      show={props.show}
      onHide={() => props.onHide()}
      dialogClassName="modal-dialog modal-dialog-centered modal-xl"
    >
      <div class="modal-content h-100 d-flex flex-column">
        <div class="custom-modal-header d-flex align-items-center justify-content-between">
          <h5 class="mb-0 text-capitalize">Edit Task</h5>
          <button
            type="button"
            class="close-btn d-flex align-items-center justify-content-center"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => props.onHide()}
          >
            <img src={close} alt="" />
          </button>
        </div>
        <Modal.Body className="custom-modal-body flex-grow-1 hide-scrollbar padding-bottom-0">
          <table class="table modal-product-detail-table mb-0" style={{ height: "400px" }}>
            <tr>
              <td>Status</td>
              <td>
                <Select
                  value={props?.status}
                  className="basic-single"
                  classNamePrefix="select-search"
                  menuPosition="fixed"
                  menuShouldScrollIntoView
                  placeholder="Select"
                  styles={customStyles}
                  getOptionLabel={(option) => option.des}
                  getOptionValue={(option) => option.sid}
                  options={props.statusList}
                  name={props.statusList}
                  onChange={(event) => {
                    const data = { ...props.statusList };
                    data.value = event;
                    props.setStatus(data.value);
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>Title</td>
              <td>
                <div>
                  <input
                    className="form-control"
                    type="text"
                    value={props.taskTitle}
                    onChange={(e) => {
                      let data = props.taskTitle;
                      data = e.target.value;
                      props.setTaskTitle(data);
                    }}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>Assign To</td>
              <td>
                <Select
                  value={props.selectAssign}
                  styles={customStyles}
                  placeholder="Market Maker"
                  menuPosition="fixed"
                  menuShouldScrollIntoView
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.member_id}
                  options={props.editAssignData}
                  name={assignId && assignId.member_id}
                  onChange={(event) => {
                    const data = { ...assignId };
                    data.value = event;
                    props.setAssign(data.value);
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>Task</td>
              <td>
                <Editor
                  name="task"
                  className="ck-content"
                  onChange={(e) => {
                    let data = props.taskDescription;
                    data = e;
                    props.setTaskDescription(data);
                  }}
                  editorLoaded={true}
                  value={props.taskDescription}
                />
              </td>
            </tr>
          </table>
        </Modal.Body>
        <div class="custom-modal-dividor"></div>
        <div className="custom-modal-footer d-grid gap-2 d-md-flex align-items-center justify-content-md-end">
          <button
            class="btn btn-link me-md-2 global-cancel-button"
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => props.onHide()}
          >
            Cancel
          </button>
          <NextButton
            label="Save"
            classData="btn btn-default"
            handleSubmit={props.handleSubmit}
            loading={props.loadingTask}
          />
        </div>
      </div>
    </Modal>
  );
};

export default EditTaskModal;
