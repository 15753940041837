import React, { useEffect, useRef, useState } from "react";
import { Offcanvas } from "react-bootstrap";
const AddMfr = (props) => {
  const [showSuggestions, setShowSuggestions] = useState(false);
  const suggestions = props?.filteredData?.map((val) => val?.Description)
    ? props?.filteredData?.map((val) => val?.Description)
    : [];


  const autocompleteRef = useRef();

  useEffect(() => {
    const handleClick = (event) => {
      if (autocompleteRef.current && !autocompleteRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    };
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  const handleChange = (e) => {
    const data = { ...props.data };
    data.Description = e.target.value;
    props.setData(data);
  };

  const handleSuggestionClick = (suggetion) => {
    const data = { ...props.data };
    data.Description = suggetion;
    props.setData(data);
    setShowSuggestions(false);
  };
  return (
    <Offcanvas
      show={props.show}
      onHide={() => props.onHide()}
      placement="end"
      className="offcanvas offcanvas-end custom-offcanvas-sm "
      backdropClassName="upload-images-backdrop"
      tabindex="-1"
      id="userFilter"
      enforceFocus={false}
    >
      <Offcanvas.Header className="offcanvas-header ">
        <h5 class="offcanvas-title" style={{ marginLeft: "-9px" }}>
          {props?.type == "addMfr" ? "Add Manufacturer" : props?.type == "filterMfr" ? "Filter" : "Edit Manufacturer"}
        </h5>
        <button type="button" className="btn-close text-reset" onClick={() => props.onHide()}></button>
      </Offcanvas.Header>
      <Offcanvas.Body className="offcanvas-body co-body">
        <div class="form-group autocomplete" ref={autocompleteRef}>
          <label for="keyword" class="form-label">
            Manufacturer Name
          </label>
          {/* <input
            type="text"
            class="form-control"
            id="auction-search"
            value={props.data?.Description}
            onChange={(e) => {
              const data = { ...props.data };
              data.Description = e.target.value;
              props.setData(data);
            }}
          /> */}
          <input
            value={props.data?.Description}
            onChange={handleChange}
            className="form-control"
            onFocus={() => setShowSuggestions(true)}
          />
          {showSuggestions && props?.filteredData?.length > 0 && (
            <ul className="suggestions">
              {props?.filteredData.map((val) => (
                <li onClick={() => handleSuggestionClick(val?.Description)} key={val?.Description}>
                  {val?.Description}
                </li>
              ))}
            </ul>
          )}
        </div>
        {/* {props?.type == "addMfr" &&
          (props?.filteredData?.length > 0 ? (
            <div class="form-group">
              <label for="keyword" class="form-label">
                Existing Manufacturers:
              </label>
              <ul>
                {props?.filteredData?.map((val) => (
                  <li>{val?.Description}</li>
                ))}
              </ul>
            </div>
          ) : (
            ""
          ))} */}
      </Offcanvas.Body>
      <div class="offcanvas-footer">
        <a
          href="javascript:void(0)"
          class={`btn btn-default btn-block ${
            (props.loading || !props.data?.Description || props?.loadingFilter) && "disabled"
          }`}
          onClick={() => (props?.type == "filterMfr" ? props?.handleFilterMfr() : props.handleAddMfr())}
        >
          {props?.type == "addMfr" ? "Submit" : props?.type == "filterMfr" ? "Submit" : "Submit"}
        </a>
      </div>
    </Offcanvas>
  );
};

export default React.memo(AddMfr);
