import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { dashboardData, getAccountManagementCount, getTaskAndCount } from "../../../store/dashBoard";
import { toast } from "react-toastify";
import AlertError from "../../../common/alerts/alertError";
import Skeleton from "react-loading-skeleton";
import { assignToEditReceived } from "../../../store/tasks";

const Menu = ({
  section,
  setSection,
  refresh,
  viewContent,
  empId,
  showOnly,
  pl,
  pt,
  fetchAccountManagement,
  fetchCustomers,
  fetchAllOtherListings,
  fetchNeglectedOrUrgent,
  fetchBuAssignment,
  fetchEnterpriseAssignment,
  fetchSellerAssignment,
  setMenuTitle,
  setTaskType,
  ...props
}) => {
  const [showContent, setShowContent] = useState(false);
  const [contentLoaded, setContentLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [assetDisp, setAssetDisp] = useState("");
  const [clineMeet, setClientMeet] = useState("");
  const [meetCompleted, setMeetCompleted] = useState("");
  const [invSharing, setInvSharing] = useState("");

  useEffect(() => {
    if (viewContent) {
      setShowContent(false);
      setContentLoaded(false);
    }
  }, [refresh]);
  const fetchData = () => {
    if (!contentLoaded) {
      props?.refStart();
      setLoading(true);
      props.getTaskAndCount(empId, showOnly, pl, pt, props.sortBy, props.viewType, (res) => {
        if (res.status === 200) {
          const param1 = {
            employeeId: empId,
            aType: "asset_disposal",
            page: 1,
            callFor: "count",
          };
          const param2 = {
            employeeId: empId,
            aType: "client_meetings",
            page: 1,
            callFor: "count",
            status: 1,
          };
          const param3 = {
            employeeId: empId,
            aType: "client_meetings",
            page: 1,
            callFor: "count",
            status: 6,
          };
          const param4 = {
            employeeId: empId,
            aType: "inv_sharing",
            page: 1,
            callFor: "count",
            pushType: 1,
          };
          props.getAccountManagementCount(param1, (res) => {
            if (res.status === 200) {
              setAssetDisp(res.data.data);
            }
          });
          props.getAccountManagementCount(param2, (res) => {
            if (res.status === 200) {
              setClientMeet(res.data.data);
            }
          });
          props.getAccountManagementCount(param3, (res) => {
            if (res.status === 200) {
              setMeetCompleted(res.data.data);
            }
          });
          props.getAccountManagementCount(param4, (res) => {
            if (res.status === 200) {
              setInvSharing(res.data.data);
            }
          });
          props?.refStop();
          setLoading(false);
          setShowContent(!showContent);
          setContentLoaded(true);
        } else {
          toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        }
      });
    } else {
      setShowContent(!showContent);
    }
  };
  const detailData = props.dashboardData?.taskAndCount;
  return (
    <div class="details-page-widget-block">
      <div class="page-widget-head d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <div class="page-widget-label text-capitalize cursor-pointer" onClick={fetchData}>
            Menu
          </div>
        </div>
      </div>
      {showContent ? (
        <div class="page-widget-body mt-3">
          <div className="row">
            {detailData?.data?.pTaskTypes?.map((val, i) =>
              i === 0 ? (
                <div className="col-md-2">
                  <div class="page-widget-label text-capitalize">Account Management</div>
                  <ul className="mt-3 mb-3 text-green" onClick={() => setSection("accountManagement")}>
                    <li onClick={() => setMenuTitle("Asset Disposal Update Required")}>
                      <a
                        className="mt-2"
                        href="javascript:void(0)"
                        onClick={() => fetchAccountManagement("asset_disposal", 0)}
                      >
                        Asset Disposal Update Required{`(${assetDisp ? assetDisp : 0})`}
                      </a>
                    </li>
                    <li onClick={() => setMenuTitle("Client Meeting Required")}>
                      <a
                        className="mt-2"
                        href="javascript:void(0)"
                        onClick={() => {
                          fetchAccountManagement("client_meetings", 1);
                        }}
                      >
                        Client Meeting Required{`(${clineMeet ? clineMeet : 0})`}
                      </a>
                    </li>
                    <li onClick={() => setMenuTitle("Client Completed Meeting")}>
                      <a
                        className="mt-2"
                        href="javascript:void(0)"
                        onClick={() => {
                          fetchAccountManagement("client_meetings", 6);
                        }}
                      >
                        Client Completed Meeting{`(${meetCompleted ? meetCompleted : 0})`}
                      </a>
                    </li>
                    <li onClick={() => setMenuTitle("Inventory Sharing")}>
                      <a
                        className="mt-2"
                        href="javascript:void(0)"
                        onClick={() => fetchAccountManagement("inv_sharing", 0)}
                      >
                        Inventory Sharing{`(${invSharing ? invSharing : 0})`}
                      </a>
                    </li>
                  </ul>
                  <div class="page-widget-label text-capitalize mt-3">Customers</div>
                  <ul className="mt-3 mb-3 text-green">
                    <li
                      onClick={() => {
                        setMenuTitle(`My Active Sellers (
                      ${detailData?.data?.pTaskTypes[0][0]?.customer?.myActiveSeller}
                      )`);
                        setSection("customers");
                      }}
                    >
                      <a className="mt-2" href="javascript:void(0)" onClick={() => fetchCustomers("active_sellers")}>
                        My Active Sellers ({detailData?.data?.pTaskTypes[0][0]?.customer?.myActiveSeller})
                      </a>
                    </li>
                    <li
                      onClick={() => {
                        setMenuTitle(`My Listings Buyer Interest (
                      ${detailData?.data?.pTaskTypes[0][0]?.customer?.myActiveBuyer}
                      )`);
                        setSection("customer_listing_buyer_interest");
                      }}
                    >
                      <a
                        className="mt-2"
                        href="javascript:void(0)"
                        onClick={() => fetchCustomers("buyer_interest_listings")}
                      >
                        My Listings Buyer Interest ({detailData?.data?.pTaskTypes[0][0]?.customer?.myActiveBuyer})
                      </a>
                    </li>
                    <li
                      onClick={() => {
                        setMenuTitle(`My Region Buyers (
                      ${detailData?.data?.pTaskTypes[0][0]?.customer?.myRegionBuyer}
                      )`);
                        setSection("customers");
                      }}
                    >
                      <a className="mt-2" href="javascript:void(0)" onClick={() => fetchCustomers("region_buyers")}>
                        My Regions Buyer Interest ({detailData?.data?.pTaskTypes[0][0]?.customer?.myRegionBuyer})
                      </a>
                    </li>
                    <li
                      onClick={() => {
                        setMenuTitle(`My Category Buyers (
                      ${detailData?.data?.pTaskTypes[0][0]?.customer?.myCategoryBuyer}
                      )`);
                        setSection("customers");
                      }}
                    >
                      <a className="mt-2" href="javascript:void(0)" onClick={() => fetchCustomers("category_buyers")}>
                        My Categories Buyer Interest ({detailData?.data?.pTaskTypes[0][0]?.customer?.myCategoryBuyer})
                      </a>
                    </li>
                    <li
                      onClick={() => {
                        setMenuTitle(`My Buyer Assignments (
                      ${detailData?.data?.pTaskTypes[0][0]?.customer?.myBuyerAssignment}
                      )`);
                        setSection("customers");
                      }}
                    >
                      <a
                        className="mt-2"
                        href="javascript:void(0)"
                        onClick={() => fetchCustomers("my_buyer_assignments")}
                      >
                        My Buyer Assignments ({detailData?.data?.pTaskTypes[0][0]?.customer?.myBuyerAssignment})
                      </a>
                    </li>
                    <li
                      onClick={() => {
                        setMenuTitle(`My Seller Assignments (
                        ${detailData?.data?.pTaskTypes[0][0]?.customer?.mySellerAssignment}
                        )`);
                        setSection("sellerAssignment");
                        fetchSellerAssignment();
                      }}
                    >
                      <a className="mt-2" href="javascript:void(0)">
                        My Seller Assignments ({detailData?.data?.pTaskTypes[0][0]?.customer?.mySellerAssignment})
                      </a>
                    </li>
                    <li
                      onClick={() => {
                        setMenuTitle(`My Business Unit Assignments (
                        ${detailData?.data?.pTaskTypes[0][0]?.customer?.myBuAssignment}
                        )`);
                        setSection("businessUnit");
                        fetchBuAssignment();
                      }}
                    >
                      <a className="mt-2" href="javascript:void(0)">
                        My Business Unit Assignments ({detailData?.data?.pTaskTypes[0][0]?.customer?.myBuAssignment})
                      </a>
                    </li>
                    <li
                      onClick={() => {
                        setMenuTitle(`My Enterprise Assignments (
                        ${detailData?.data?.pTaskTypes[0][0]?.customer?.myEnterpriseAssignment}
                        )`);
                        setSection("enterprise");
                        fetchEnterpriseAssignment();
                      }}
                    >
                      <a className="mt-2" href="javascript:void(0)">
                        My Enterprise Assignments (
                        {detailData?.data?.pTaskTypes[0][0]?.customer?.myEnterpriseAssignment})
                      </a>
                    </li>
                  </ul>
                </div>
              ) : i === 3 ? (
                <div className="col-md-2">
                  {val?.map((val) => (
                    <>
                      <div class="page-widget-label text-capitalize">{val.description}</div>
                      <ul className="mt-3 mb-3 text-green" onClick={() => setSection("allOther")}>
                        {val?.qTaskTypes?.map(
                          (data) =>
                            data.ct > 0 && (
                              <li
                                onClick={() => {
                                  setMenuTitle(
                                    `${data?.description} (${data?.pending || ""}${
                                      data?.pending && data?.inprogress ? "/" : ""
                                    }${data?.inprogress || ""})`,
                                  );
                                  fetchAllOtherListings(data.task_type);
                                  setTaskType(data.task_type);
                                }}
                              >
                                <a className="mt-2" href="javascript:void(0)">
                                  {data.description} ({data?.pending}
                                  {data?.pending && data?.inprogress ? "/" : ""}
                                  <span className="text-danger">{data?.inprogress}</span>)
                                </a>
                              </li>
                            ),
                        )}
                      </ul>
                    </>
                  ))}
                </div>
              ) : i === 4 ? (
                <div className="col-md-2">
                  {val?.map((val) => (
                    <>
                      <div class="page-widget-label text-capitalize">{val.description}</div>
                      <ul className="mt-3 mb-3 text-green" onClick={() => setSection("neglected")}>
                        {detailData?.data?.neglected ? (
                          <li
                            onClick={() => {
                              setMenuTitle(`Neglected Listings (${detailData?.data?.neglected})`);
                              fetchNeglectedOrUrgent(16);
                            }}
                          >
                            <a className="mt-2" href="javascript:void(0)">
                              Neglected Listings ({detailData?.data?.neglected})
                            </a>
                          </li>
                        ) : (
                          ""
                        )}
                        {detailData?.data?.neglectedPending ? (
                          <li
                            onClick={() => {
                              setMenuTitle(`Neglected Pending Listings (
                          ${detailData?.data?.neglectedPending})`);
                              fetchNeglectedOrUrgent(21);
                            }}
                          >
                            <a className="mt-2" href="javascript:void(0)">
                              Neglected Pending Listings ({detailData?.data?.neglectedPending})
                            </a>
                          </li>
                        ) : (
                          ""
                        )}
                        {detailData?.data?.neglectedSellerBd ? (
                          <li
                            onClick={() => {
                              setMenuTitle(`Neglected Seller Bid Listings (
                          ${detailData?.data?.neglectedSellerBd})`);
                              fetchNeglectedOrUrgent(22);
                            }}
                          >
                            <a className="mt-2" href="javascript:void(0)">
                              Neglected Seller Bid Listings ({detailData?.data?.neglectedSellerBd})
                            </a>
                          </li>
                        ) : (
                          ""
                        )}
                        {detailData?.data?.neglectedSellerBdr ? (
                          <li
                            onClick={() => {
                              setMenuTitle(`Neglected Seller Bid Response (
                          ${detailData?.data?.neglectedSellerBdr})`);
                              fetchNeglectedOrUrgent(220);
                            }}
                          >
                            <a className="mt-2" href="javascript:void(0)">
                              Neglected Seller Bid Response ({detailData?.data?.neglectedSellerBdr})
                            </a>
                          </li>
                        ) : (
                          ""
                        )}
                        {detailData?.data?.sellerMotivationNotEntered ? (
                          <li
                            onClick={() => {
                              setMenuTitle(`No Seller Motivation Listings (
                          ${detailData?.data?.sellerMotivationNotEntered})`);
                              fetchNeglectedOrUrgent(25);
                            }}
                          >
                            <a className="mt-2" href="javascript:void(0)">
                              No Seller Motivation Listings ({detailData?.data?.sellerMotivationNotEntered})
                            </a>
                          </li>
                        ) : (
                          ""
                        )}
                        {detailData?.data?.unpaidInvoice ? (
                          <li
                            onClick={() => {
                              setMenuTitle(`Unpaid Invoices (${detailData?.data?.unpaidInvoice})`);
                              fetchNeglectedOrUrgent(27);
                            }}
                          >
                            <a className="mt-2" href="javascript:void(0)">
                              Unpaid Invoices ({detailData?.data?.unpaidInvoice})
                            </a>
                          </li>
                        ) : (
                          ""
                        )}
                        {detailData?.data?.unanswered ? (
                          <li
                            onClick={() => {
                              setMenuTitle(`Unanswered Buyer Questions (
                          ${detailData?.data?.unanswered})`);
                              fetchNeglectedOrUrgent(0);
                            }}
                          >
                            <a className="mt-2" href="javascript:void(0)">
                              Unanswered Buyer Questions ({detailData?.data?.unanswered})
                            </a>
                          </li>
                        ) : (
                          ""
                        )}
                      </ul>
                    </>
                  ))}
                </div>
              ) : (
                <div className="col-md-2">
                  {val?.map((val) => (
                    <>
                      <div class="page-widget-label text-capitalize">{val.description}</div>
                      <ul className="mt-3 mb-3 text-green" onClick={() => setSection("allOther")}>
                        {val?.qTaskTypes?.map(
                          (data) =>
                            data.ct > 0 && (
                              <li
                                onClick={() => {
                                  setMenuTitle(
                                    `${data?.description} (${data?.pending || ""}${
                                      data?.pending && data?.inprogress ? "/" : ""
                                    }${data?.inprogress || ""})`,
                                  );
                                  fetchAllOtherListings(data.task_type);
                                  setTaskType(data.task_type);
                                }}
                              >
                                <a className="mt-2" href="javascript:void(0)">
                                  {data.description} ({data?.pending}
                                  {data?.pending && data?.inprogress ? "/" : ""}
                                  <span className="text-danger">{data?.inprogress}</span>)
                                </a>
                              </li>
                            ),
                        )}
                      </ul>
                    </>
                  ))}
                </div>
              ),
            )}
          </div>
        </div>
      ) : (
        !contentLoaded &&
        (props.loadingMain ? (
          <Skeleton height="50px" />
        ) : (
          <div class="page-widget-body mt-3">
            <div
              class="page-widget-empty-box text-capitalize d-flex align-items-center justify-content-center text-center"
              style={{
                height: "50px",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <span>{loading ? "Content Loading..." : "Content not loaded"}</span>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getTaskAndCount: (empId, showOnly, pl, pt, sortBy, viewType, callback) =>
    dispatch(getTaskAndCount(empId, showOnly, pl, pt, sortBy, viewType, callback)),
  getAccountManagementCount: (params, callback) => dispatch(getAccountManagementCount(params, callback)),
});
const mapStateToProps = (state) => ({
  dashboardData: dashboardData(state),
});
export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Menu));
