import React, { useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import close from "../../../include/images/close-12x12.svg";
import pdfIcon from "../../../include/images/pdf-file-icon.svg";
import csvFile from "../../../include/images/csv-latest.svg";
import XLSX from "../../../include/images/XLSX.svg";
import XLS from "../../../include/images/XLS.svg";
import DOCX from "../../../include/images/Docx.svg";
import DOC from "../../../include/images/Doc.svg";
import download from "../../../include/images/download-circle-icon.svg";
import emptyChat from "../../../include/images/chat-empty.svg";
import { connect } from "react-redux";
import { getContractListing, getListingContracts } from "../../../store/contract";
import Skeleton from "react-loading-skeleton";

const ListingContract = (props) => {
  const { show, onHide } = props;

  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const docList = props.getContractListings?.listingContracts || [];

  const totalPage = props.getContractListings?.listingContracts?.totalPage || 0;

  useEffect(() => {
    // props?.refStart();
    if (props.show) {
      setLoading(true);
      const auctionId = props.auctionId;
      const sellerId = props.data?.sellerUserId;
      props.getListingContracts(
        { auctionId: auctionId, sellerUserId: sellerId, sort: "createdAt", order: "desc", page: page },
        (res) => {
          if (res && res.status === 200) {
            //   props?.refStop();
            setLoading(false);
          }
        },
      );
    }
  }, [page, props.show]);

  useEffect(() => {
    if (!props.show) {
      setPage(1);
    }
  }, [props.show]);

  return (
    <Offcanvas
      placement="end"
      backdropClassName="upload-documnets-backdrop"
      show={show}
      onHide={() => onHide()}
      className="offcanvas offcanvas-end border-0"
      id="uploadDocumentOffcanvas"
      enforceFocus={false}
    >
      <Offcanvas.Header className="offcanvas-header">
        <h5 class="offcanvas-title">View Documents</h5>
        <button
          type="button"
          class="offcanvas-close-btn d-flex align-items-center justify-content-center"
          onClick={() => onHide()}
        >
          <img src={close} alt="" />
        </button>
      </Offcanvas.Header>
      <Offcanvas.Body className="offcanvas-body p-0">
        <div class="offcanvas-widget-row">
          <div class={`table-responsive mb-3 ${docList?.data?.length > 0 && "min-user-table-height"}`}>
            <table class="table align-middle table-borderless publicDocTable mb-0">
              <colgroup>
                <col style={{ width: "50px" }} />
                <col />
                <col style={{ width: "50px" }} />
              </colgroup>
              <tbody>
                {loading
                  ? [1, 23, 4, 5, 24].map((item) => (
                      <tr>
                        <td></td>
                        <td>
                          <Skeleton height="40px" width={"100%"} />
                        </td>
                        <td></td>
                      </tr>
                    ))
                  : ""}
                {!loading && docList && docList?.data?.length > 0
                  ? docList?.data?.map((item) => {
                      const filenameArray = item?.files?.original?.split(".") || [];
                      const fileType = filenameArray[filenameArray?.length - 1];
                      return (
                        <tr>
                          <td>
                            <div class="td-pdf-img-box">
                              <img
                                onClick={() =>
                                  window.open(`${process.env.REACT_APP_MEDIA_URL}/${item?.files?.original}`)
                                }
                                className="cursor-pointer"
                                src={
                                  fileType === "csv"
                                    ? csvFile
                                    : fileType === "xlsx"
                                    ? XLSX
                                    : fileType === "xls"
                                    ? XLS
                                    : fileType === "doc"
                                    ? DOC
                                    : fileType === "docx"
                                    ? DOCX
                                    : pdfIcon
                                }
                                alt=""
                              />
                            </div>
                          </td>
                          <td>
                            <a
                              href={`${process.env.REACT_APP_S3_PRIVATE_DOC_BUCKET}/${item?.files?.original}`}
                              target="_blank"
                              download={true}
                              className="deafult-black-hover-green"
                            >
                              {item?.files?.name.includes(".") ? item?.files?.name : item?.files?.name + "." + fileType}
                            </a>
                          </td>
                          <td>
                            <a
                              href={`${process.env.REACT_APP_S3_PRIVATE_DOC_BUCKET}/${item?.files?.original}`}
                              target="_blank"
                              download={true}
                              class="delete-td-icon d-flex align-items-center justify-content-center"
                            >
                              <img src={download} alt="" />
                            </a>
                          </td>
                        </tr>
                      );
                    })
                  : !loading && (
                      <>
                        <div class="empty-pannel-box mt-5">
                          <img src={emptyChat} alt="" />
                          <h4>No Documents Found!</h4>
                        </div>
                      </>
                    )}
              </tbody>
            </table>
          </div>
          {totalPage > 1 && !loading && (
            <div class="table-pagination">
              <ul class="pagination  justify-content-end">
                <li class="page-item">
                  <a
                    class={`page-link ${page === 1 ? "disabled" : ""}`}
                    href="#"
                    aria-label="Previous"
                    onClick={() => {
                      if (page !== 1) {
                        setPage(page - 1);
                      }
                    }}
                  >
                    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7 13L1 7L7 1" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </a>
                </li>
                <li class="page-item active">
                  <a class="page-link" href="javascript:void(0)">
                    {page}
                  </a>
                </li>
                {totalPage > 1 && page + 1 <= totalPage && (
                  <li class="page-item">
                    <a class="page-link" href="#" onClick={() => setPage(page + 1)}>
                      {page + 1}
                    </a>
                  </li>
                )}
                <li class="page-item">
                  <a
                    class={`page-link ${page + 1 >= totalPage ? "disabled" : ""}`}
                    href="#"
                    aria-label="Next"
                    onClick={() => setPage(page + 1)}
                  >
                    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 13L7 7L1 1" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
          )}
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getListingContracts: (params, callback) => dispatch(getListingContracts(params, callback)),
});

const mapStateToProps = (state) => ({
  getContractListings: getContractListing(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(ListingContract));
