import Select from "react-select";
import React, { useState, useEffect, useRef } from "react";
import { getAdminList } from "../../../store/commentsAndNotes";
import { connect } from "react-redux";
import NextButton from "../../../common/form/nextButton";
import { toast } from "react-toastify";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import AlertError from "../../../common/alerts/alertError";
import { getListings, getOtherDeals, otherDealsPostMsg } from "../../../store/listings";
import { getPrimarySeller, primarySellerData } from "../../../store/primarySeller";
import Skeleton from "react-loading-skeleton";
import DateFormat from "../../../common/DateFormat/dateFormat";
import Trash from "../../../include/images/trash-18x18.svg";
import DeleteModal from "../../../layouts/modals/deleteModal";
import { deleteOtherDealChat } from "../../../store/emailClickers";
const customStyles1 = {
  indicatorSeparator: (styles) => ({ display: "none" }),
  option: (provided, state) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "#47ad1d",
      color: "#fff",
    },
    display: "-webkit-box",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    color: state.isSelected ? "#fff" : "black",
    backgroundColor: state.isSelected ? "#47ad1d" : provided.backgroundColor,
    // maxHeight: "84px",
  }),
  control: (base, state) => ({
    ...base,
    // height: 60,
    minHeight: 42,
    borderColor: state.isFocused ? "#47AD1D" : "#e4e4e4",
    boxShadow: state.isFocused ? "0 0 0 0.5px #47AD1D" : "0",
    border: state.isHovered ? "0" : "1px solid #e4e4e4",

    "&:hover": {
      borderColor: state.isFocused ? "#47AD1D" : "0",
      boxShadow: state.isFocused ? "0 0 0 0.5px #47AD1D" : "0",
    },
  }),
  multiValueRemove: (base, state) => ({
    ...base,
    "&:hover": {
      backgroundColor: "rgb(95, 50, 187, 10%)",
      color: "#6119c0",
    },
  }),
};
function OtherDeals(props) {
  const [adminList, setAdminList] = useState([]);
  const data =
    (props.getListings && props.getListings.sellerMotivation && props.getListings.sellerMotivation.data) || {};
  const [addMessage, setAddMessage] = useState({
    to_id: [],
    message: "",
    send_email: true,
    cc_emails_list: [],
    user_id: "",
  });
  const val = localStorage.getItem("data");
  const session = JSON.parse(val);
  const sId = props?.lisitingData?.detail?.sid;
  const chatMessagesFromRedux = props?.primarySellerData?.primarySeller?.data?.chatMessages;
  const [chatMessages, setChatMessages] = useState(chatMessagesFromRedux || []);
  const msgRef = useRef();
  const [loading, setLoading] = useState(false);
  const [listingData, setListingData] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [chatId, setChatId] = useState("");
  const [onDelete, setOnDelete] = useState(false);
  useEffect(() => {
    if (sId) {
      props.getOtherDeals({ auctionId: props.auctionId, sId: sId }, (res) => {
        if (res.status === 200) {
          setListingData(res.data.data);
          props.getAdminList(props.auctionId, (res) => {
            if (res.status === 200) {
              setAdminList(res.data.data);
            }
          });
        }
      });
    }
  }, [sId]);
  const deleteHandler = () => {
    setLoadingDelete(true);
    setOnDelete(true);
    props.refStart();
    const id = chatId;
    props.deleteOtherDealChat(id, (res) => {
      if (res && res.status === 200) {
        props.getPrimarySeller(
          props.auctionId,
          props.primarySellerParams.sellerId,
          props.primarySellerParams.memberId,
          props.primarySellerParams.s_id,
          (res) => {
            setChatMessages(res.data.data.chatMessages);
          },
        );
        props.refStop();
        setLoadingDelete(false);
        toggleDeleteModal();
        toast(<AlertSuccess message={"Information Saved"} />);
      } else {
        props.refStop();
        setLoadingDelete(false);
        setLoadingDelete(false);
        toggleDeleteModal();
        toast(<AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };
  const handleSubmit = () => {
    props.refStart();
    setLoading(true);
    const values = { ...addMessage };
    values.user_id = data.s_user_id;
    if (values.message) {
      props.otherDealsPostMsg(props.auctionId, values, (res) => {
        if (res.status === 200) {
          const data = {
            to_id: [],
            message: "",
            send_email: true,
            cc_emails_list: [],
            user_id: 12820208,
          };
          props.getPrimarySeller(
            props.auctionId,
            props.primarySellerParams.sellerId,
            props.primarySellerParams.memberId,
            props.primarySellerParams.s_id,
            (res) => {
              setChatMessages(res.data.data.chatMessages);
            },
          );
          setAddMessage(data);
          props.refStop();
          msgRef.current.value = "";
          setLoading(false);
          toast(<AlertSuccess message={"Information Saved"} />);
        } else {
          props.refStop();
          setLoading(false);
          toast(<AlertError message={res.data.data ? res.data.daa : "Something went Wrong!"} />);
        }
      });
    } else {
      props.refStop();
      setLoading(false);
      toast(<AlertError message={"Please enter a message."} />);
    }
  };
  const toggleDeleteModal = (chat_id) => {
    setChatId(chat_id);
    setShowDeleteModal(!showDeleteModal);
  };

  const adjustFontSize = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");

    const headerTags = ["h1", "h2", "h3", "h4", "h5", "h6"];
    const normalTags = ["p", "span"];
    headerTags.forEach((tag) => {
      const elements = doc.getElementsByTagName(tag);
      for (let i = 0; i < elements.length; i++) {
        elements[i].style.fontSize = "14px";
      }
    });
    normalTags.forEach((tag) => {
      const elements = doc.getElementsByTagName(tag);
      for (let i = 0; i < elements.length; i++) {
        elements[i].style.fontSize = "12px";
      }
    });

    return doc.documentElement.outerHTML;
  };
  return (
    <div className="page-widget-block-inside" id={props.id}>
      <div className="page-widget-block-inside-body">
        {props.loadingMain ? (
          <Skeleton height="300px" width="100%" />
        ) : (
          <div>
            {listingData &&
              listingData.map((listing, index) => (
                <div className="pwbi-content-box" key={index}>
                  <div className="pwbi-label-box mb-4  d-flex flex-wrap">
                    <div className="pwbi-label me-2 d-flex align-items-start">
                      <b>{index + 1}.</b>
                      <a
                        href={`/listing-detail?id=${listing.auction_id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ms-2"
                      >
                        {listing.auction_id} - {listing.title}
                      </a>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table className="table mb-0 product-table">
                      <colgroup>
                        <col style={{ width: "35%" }} />
                        <col />
                      </colgroup>
                      <tbody>
                        {listing.trader_name && (
                          <tr>
                            <td className="py-3">Trader</td>
                            <td className="py-3">{listing.trader_name || "N/A"}</td>
                          </tr>
                        )}
                        {listing.trader_manager && (
                          <tr>
                            <td className="py-3">Trader Manager</td>
                            <td className="py-3">{listing.trader_manager || "N/A"}</td>
                          </tr>
                        )}
                        {listing.int_ref && (
                          <tr>
                            <td className="py-3">BD</td>
                            <td className="py-3">{listing.int_ref || "N/A"}</td>
                          </tr>
                        )}
                        {listing.bd_manager && (
                          <tr>
                            <td className="py-3">BD Manager</td>
                            <td className="py-3">{listing.bd_manager || "N/A"}</td>
                          </tr>
                        )}
                        <tr>
                          <td className="py-3">Location</td>
                          <td className="py-3">
                            {listing.auction_city ? <span>{listing.auction_city}</span> : ""}
                            {listing.auction_state ? (
                              <span>
                                {listing.auction_state}
                                {listing.auction_state && listing.auction_country ? ", " : ""}
                              </span>
                            ) : (
                              ""
                            )}
                            {listing.country_id === 1001 && listing.state_id > 0 ? (
                              <span>
                                {", "}
                                {session.sec_list === 100103 || session.sec_list === 100105 ? (
                                  <a href="javascript:void(0)" className="green-btn-text">
                                    {listing.auction_state}
                                  </a>
                                ) : (
                                  listing.auction_state
                                )}
                              </span>
                            ) : (
                              ""
                            )}
                            {listing.country_id !== 1001 && listing.country_id > 0 ? (
                              <span>
                                {", "}
                                {session.sec_list === 100103 || session.sec_list === 100105 ? (
                                  <a href="javascript:void(0)" className="green-btn-text">
                                    {listing.auction_country}
                                  </a>
                                ) : (
                                  listing.auction_country
                                )}
                              </span>
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              ))}
          </div>
        )}
        <div className="widget-form-widget ">
          <div className="widget-form-widget other-deal-container">
            {props.loadingMain ? (
              <Skeleton height="300px" width="100%" />
            ) : (
              <>
                <div className="wfw-label-row w-100 d-flex flex-wrap justify-content-between">
                  <div className="wfw-label w-50">
                    <div className="wfw-txt-bold fw-medium">Additional Recipients</div>
                  </div>
                </div>
                {adminList && adminList.length > 0 && (
                  <div class="form-group-15">
                    <select
                      name="admin_comments"
                      id="admin_comments"
                      multiple
                      style={{ height: "116px", width: "95%" }}
                      onChange={(e) => {
                        const values = { ...addMessage };
                        const data = Array.from(e.target.selectedOptions);
                        const a =
                          data &&
                          data.length > 0 &&
                          data.map((item) => ({
                            name: item?.text,
                            value: item?.value,
                          }));
                        const b = a.map((da) => da?.value.toString()).join(",");
                        values.to_id = [b];
                        setAddMessage(values);
                      }}
                    >
                      {adminList?.map((val, id) => (
                        <option key={id} value={val?.member_id}>
                          {val?.name01}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
                <div className="form-group-30">
                  {/* <label for="cc" className="form-label fw-medium">
                    CC:
                  </label> */}
                  <input
                    ref={msgRef}
                    type="text"
                    placeholder="CC"
                    onChange={(e) => {
                      const values = { ...addMessage };
                      const val = e.target.value.split(",");
                      values.cc_emails_list = val;
                      setAddMessage(values);
                    }}
                    name="cc"
                    className="form-control white"
                  />
                </div>
                <div class="form-group-15">
                  {/* <label for="" class="form-label fw-medium">
                    Message
                  </label> */}
                  <textarea
                    value={addMessage.message}
                    class="form-control white"
                    id="textarea130x130"
                    onChange={(e) => {
                      const values = { ...addMessage };
                      values.message = e.target.value ? e.target.value : "";
                      setAddMessage(values);
                    }}
                    placeholder="Message"
                  ></textarea>
                </div>
                <div className="form-checkbox-group-row     d-flex flex-wrap">
                  <div className="form-checkbox-item">
                    <div className="form-check dark-tick-checkbox">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value={addMessage.send_email}
                        id="sendMail_1"
                        checked={addMessage.send_email}
                        onChange={(e) => {
                          const values = { ...addMessage };
                          values.send_email = e.target.checked;
                          setAddMessage(values);
                        }}
                      />
                      <label className="form-check-label fw-medium text-nowrap" htmlFor="sendMail_1">
                        Send Email
                      </label>
                    </div>
                  </div>
                </div>
                <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                  <NextButton
                    label="Post Message"
                    classData="btn btn-default"
                    handleSubmit={handleSubmit}
                    loading={loading}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <div className="text-card-block-row mt-4">
          {props.loadingMain ? (
            <>
              <div className="text-card-block-column skeleton">
                <div className="tcbc-header d-flex flex-wrap align-items-center justify-content-between">
                  <div className="tcbc-header-label d-flex flex-wrap align-items-center">
                    <div className="fw-medium skeleton-line">
                      <Skeleton width={100} />
                    </div>
                    <span className="skeleton-line"></span>
                  </div>
                  <div className="tcbc-right-box">
                    <Skeleton width={100} />
                  </div>
                </div>
                <div className="tcbc-text-row">
                  <p className="skeleton-line">
                    <Skeleton count={3} />
                  </p>
                </div>
              </div>
              <div className="text-card-block-column skeleton">
                <div className="tcbc-header d-flex flex-wrap align-items-center justify-content-between">
                  <div className="tcbc-header-label d-flex flex-wrap align-items-center">
                    <div className="fw-medium skeleton-line">
                      <Skeleton width={100} />
                    </div>
                    <span className="skeleton-line"></span>
                  </div>
                  <div className="tcbc-right-box">
                    <Skeleton width={100} />
                  </div>
                </div>
                <div className="tcbc-text-row">
                  <p className="skeleton-line">
                    <Skeleton count={3} />
                  </p>
                </div>
              </div>
              <div className="text-card-block-column skeleton">
                <div className="tcbc-header d-flex flex-wrap align-items-center justify-content-between">
                  <div className="tcbc-header-label d-flex flex-wrap align-items-center">
                    <div className="fw-medium skeleton-line">
                      <Skeleton width={100} />
                    </div>
                    <span className="skeleton-line"></span>
                  </div>
                  <div className="tcbc-right-box">
                    <Skeleton width={100} />
                  </div>
                </div>
                <div className="tcbc-text-row">
                  <p className="skeleton-line">
                    <Skeleton count={3} />
                  </p>
                </div>
              </div>
            </>
          ) : chatMessages && chatMessages?.length > 0 ? (
            chatMessages?.map((val, index) => {
              return (
                <div class="text-card-block-column">
                  <div class="tcbc-header d-flex flex-wrap align-items-center justify-content-between">
                    <div
                      class="tcbc-header-label d-flex ps-2 flex-column align-items-start justify-content-center text-white"
                      style={{
                        backgroundColor: val?.task_id > 0 ? "#137c10" : "#104b7c",
                        width: "250px",
                        minHeight: "50px",
                        maxHeight: "70px",
                      }}
                    >
                      <div class="fw-medium">
                        {val?.sent_name ? `${val?.name01 ? val?.name01 : ""} to ${val?.sent_name}` : val?.name01}
                      </div>
                      <div>{val?.created && <DateFormat date={val?.created} />}</div>
                    </div>
                    <div class="tcbc-right-box">
                      <button
                        type="button"
                        class="delete-button d-flex align-items-center"
                        onClick={() => toggleDeleteModal(val?.chat_id)}
                      >
                        <img src={Trash} alt="" />
                        Delete
                      </button>
                    </div>
                  </div>

                  <div
                    class="tcbc-text-row div-comm-notes-sec text-start"
                    dangerouslySetInnerHTML={{
                      __html: adjustFontSize(val?.message)
                        ?.replace(/\r\n/g, "<br/>")
                        ?.replace(
                          "salvex.com/admin/_listings/views/_full.cfm?aucID=",
                          process.env.REACT_APP_MODE !== "development"
                            ? "newadmin.salvex.com/listing-detail?id="
                            : "newadmin-dev.salvex.com/listing-detail?id=",
                        ),
                    }}
                  />
                  <div className="d-flex flex-column  ">
                    {val?.date_completed && (
                      <>
                        <div style={{ marginTop: "6px", color: "#47AD1D" }}>
                          <span>Completed on </span>
                          <DateFormat date={val?.date_completed} />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              );
            })
          ) : (
            <div
              class="page-widget-empty-box text-capitalize d-flex align-items-center justify-content-center text-center"
              style={{
                height: "239px",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <button className="btn btn-default">No Data Available</button>
            </div>
          )}
        </div>
      </div>
      <DeleteModal
        show={showDeleteModal}
        onHide={toggleDeleteModal}
        loadingDelete={loadingDelete}
        deleteHandler={deleteHandler}
      />
    </div>
  );
}
const mapDispatchToProps = (dispatch) => ({
  getAdminList: (params, callback) => dispatch(getAdminList(params, callback)),
  otherDealsPostMsg: (params, data, callback) => dispatch(otherDealsPostMsg(params, data, callback)),
  getOtherDeals: (data, callback) => {
    dispatch(getOtherDeals(data, callback));
  },
  deleteOtherDealChat: (params, callback) => dispatch(deleteOtherDealChat(params, callback)),
  getPrimarySeller: (params, sellerId, memberId, s_id, callback) =>
    dispatch(getPrimarySeller(params, sellerId, memberId, s_id, callback)),
});
const mapStateToProps = (state) => ({
  getListings: getListings(state),
  primarySellerData: primarySellerData(state),
});
export default connect(mapStateToProps, mapDispatchToProps)(React.memo(OtherDeals));
