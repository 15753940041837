import { createSlice, current } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";

const slice = createSlice({
  name: "chats",
  initialState: {
    loading: false,
    chats: [],
    chatMassages: [],
    auctionChats: [],
  },
  reducers: {
    chatsRequested: (misc, action) => {
      misc.loading = true;
    },
    chatsReceived: (misc, action) => {
      misc.chats = action.payload;
      misc.loading = false;
    },
    chatsRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    auctionChatsRequested: (misc, action) => {
      misc.loading = true;
    },
    auctionChatsReceived: (misc, action) => {
      misc.auctionChats = action.payload;
      misc.loading = false;
    },
    auctionChatsRequestFailed: (misc, action) => {
      misc.loading = false;
    },

    chatMassagesRequested: (misc, action) => {
      misc.chatMassages = [];
      misc.loading = true;
    },
    chatMassagesReceived: (misc, action) => {
      misc.chatMassages = action.payload.messages;
      misc.loading = false;

      // set adminUnreadCount to 0
      const chatId = action.payload.chatId;
      const chat = misc.chats.find((chat) => chat.chatId === chatId);
      if (chat) {
        chat.adminUnreadCount = 0;
      }

      //set to store
      misc.chats = misc.chats.map((chat) => (chat.chatId === chatId ? { ...chat } : chat));
    },
    chatMassagesRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    addChatReducer: (misc, action) => {
      misc.chatMassages.push(action.payload);
      // set adminUnreadCount to 0
      const chatId = misc.chatMassages[0].chatId;
      if (!chatId) return console.log("chatId is null");
      const chat = misc.chats.find((chat) => chat.chatId === chatId);
      chat.adminUnreadCount = 0;

      //set to store
      misc.chats = misc.chats.map((chat) => (chat.chatId === chatId ? { ...chat } : chat));
    },
    addCustomerChatsReducer: (misc, action) => {
      misc.chatMassages.push(action.payload);
      // set adminUnreadCount to 0
      const chatId = misc.chatMassages[0].chatId;
      if (!chatId) return console.log("chatId is null");
      const chat = misc.auctionChats.find((chat) => chat.chatId === chatId);
      chat.adminUnreadCount = 0;

      //set to store
      misc.chats = misc.chats.map((chat) => (chat.chatId === chatId ? { ...chat } : chat));
    },
    updateUnread: (misc, action) => {
      // misc.chatMassages.push(action.payload);
      const chatId = action.payload.chatId;

      const chat = misc.chats.find((chat) => chat.chatId === chatId);
      if (chat) {
        chat.adminUnreadCount = chat.adminUnreadCount + 1;

        //set to store
        misc.chats = misc.chats.map((chat) => (chat.chatId === chatId ? { ...chat } : chat));
      }
    },
  },
});

export const {
  chatsRequested,
  chatsReceived,
  chatsRequestFailed,
  auctionChatsRequested,
  auctionChatsReceived,
  auctionChatsRequestFailed,
  chatMassagesRequested,
  chatMassagesReceived,
  chatMassagesRequestFailed,
  addChatReducer,
  updateUnread,
  addCustomerChatsReducer,
} = slice.actions;
export default slice.reducer;

// Action Creators
const chatsUrl = "chats/";

export const loadChatList = (userId, callback) => (dispatch) => {
  if (!userId) return console.log("loadChatList", "userId is null");
  return dispatch(
    apiCallBegan({
      url: chatsUrl + userId + "/",
      method: "get",
      callback,
      onStart: chatsRequested.type,
      onSuccess: chatsReceived.type,
      onError: chatsRequestFailed.type,
    }),
  );
};

export const loadChatMassages = (userId, auctionId, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: chatsUrl + "messagesAdmin/" + auctionId + "/" + userId,
      method: "get",
      callback,
      onStart: chatMassagesRequested.type,
      onSuccess: chatMassagesReceived.type,
      onError: chatMassagesRequestFailed.type,
    }),
  );
};

export const loadAuctionChat = (auctionId, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `auctionChats/${auctionId}`,
      method: "get",
      callback,
      onStart: auctionChatsRequested.type,
      onSuccess: auctionChatsReceived.type,
      onError: auctionChatsRequestFailed.type,
    }),
  );
};

export const updateUnreadCount = (data) => (dispatch) => {
  dispatch(updateUnread(data));
};

export const addChat = (data) => (dispatch) => {
  dispatch(addChatReducer(data));
};

export const addCustomerChat = (data) => (dispatch) => {
  dispatch(addCustomerChatsReducer(data));
};
export const getChats = createSelector(
  (state) => state.entities.chats,
  (chats) => chats,
);
