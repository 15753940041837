import moment from "moment";
import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import AlertError from "../../../common/alerts/alertError";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import DateFormat from "../../../common/DateFormat/dateFormat";
import AccountingModal from "../../../layouts/modals/accounting/accountingModal";
import {
  deleteAuctionExpenses,
  getAccounting,
  getListingProductDetail,
  updateAccounting,
  updateAuctionExpense,
} from "../../../store/listingProduct";
import { twoDecimals } from "../../../utils.js/conversion";
import helpCircle from "../../../include/images/help-circle.svg";
import { getProfile } from "../../../utils.js/localStorageServices";
import ExpenseAllocationModal from "../../../layouts/modals/accounting/expenseAllocationModal";

const Accounting = (props) => {
  const session = getProfile();
  const [showAccountingModal, setShowAccountingModal] = useState(false);
  const [loadingAccounting, setLoadingAccounting] = useState(false);
  const [expenseData, setExpenseData] = useState([]);
  const [showAllocation, setShowAllocation] = useState(false);
  const [values, setValues] = useState({
    signed: false,
    acc_flag_7: false,
    inventory: false,
    proxibid_paid: false,
    date_invoiced: "",
    payrec_date: "",
    total_sale: 0,
    sales_price: 0,
    cog: 0,
    expenses: 0,
    commission: 0,
  });

  const toggleAccountingModal = () => {
    setShowAccountingModal(!showAccountingModal);
    setValues({
      acc_flag_7: details.acc_flag_7,
      signed: details.signed,
      inventory: details.inventory,
      proxibid_paid: details.proxibid_paid,
      date_invoiced: details.date_invoiced ? details.date_invoiced : "",
      payrec_date: details.payrec_date ? details.payrec_date : "",
      total_sale: details.total_sale ? details.total_sale : 0,
      sales_price: details.sales_price ? details.sales_price : 0,
      cog: details.cog ? details.cog : 0,
      expenses: details.expenses ? details.expenses : 0,
      commission: details.commission ? details.commission : 0,
    });
  };
  const toggleExpenseAllocation = () => {
    const data =
      details?.expenseAllocation?.length > 0 ? details?.expenseAllocation?.map(({ auction_id, ...rest }) => rest) : [];
    setExpenseData(data);
    toggleAccountingModal();
    setShowAllocation(!showAllocation);
  };

  const handleDeleteExpense = (id) => {
    const auctionId = props?.auctionId;
    const params = {
      del: id,
    };
    props.deleteAuctionExpenses(auctionId, params, (res) => {
      if (res.status === 200) {
        props.getAccounting(auctionId, (res) => {});
      } else {
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const handleExpenseAllocation = () => {
    const id = props?.auctionId;
    setLoadingAccounting(true);
    props?.refStart();
    const payload = {
      expenses: expenseData,
    };
    props?.updateAuctionExpense(id, payload, (res) => {
      if (res.status === 200) {
        props.getAccounting(id, (res) => {
          if (res.status === 200) {
            props?.refStop();
            setLoadingAccounting(false);
            toggleExpenseAllocation();
            toast(<AlertSuccess message={"Information Saved"} />);
          }
        });
      } else {
        setLoadingAccounting(false);
        props?.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const handleSubmit = () => {
    setLoadingAccounting(true);
    props.refStart();
    const id = props.auctionId;
    const payload =
      session.member_id === 820291 ||
      session.member_id === 842446 ||
      (process.env.REACT_APP_MODE == "development" && session.member_id === 1)
        ? {
            signed: values.signed ? true : false,
            acc_flag_7: values.acc_flag_7 ? true : false,
            inventory: values.inventory ? true : false,
            proxibid_paid: values.proxibid_paid ? true : false,
            date_invoiced: values.date_invoiced ? moment(values.date_invoiced).format("MM/DD/YYYY") : "",
            payrec_date: values.payrec_date ? moment(values.payrec_date).format("MM/DD/YYYY") : "",
            total_sale: values.total_sale ? values.total_sale?.toString() : "0",
            sales_price: values.sales_price ? values.sales_price?.toString() : "0",
            cog: values.cog ? values.cog?.toString() : "0",
          }
        : { signed: values.signed, acc_flag_7: values.acc_flag_7 };
    props.updateAccounting(id, payload, (res) => {
      if (res.status === 200) {
        props.getAccounting(id, (res) => {
          if (res.status === 200) {
            props.refStop();
            setLoadingAccounting(false);

            toast(<AlertSuccess message="Information Saved" />);
            toggleAccountingModal();
          } else {
            props.refStop();
            setLoadingAccounting(false);
            toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
          }
        });
      } else {
        props.refStop();
        setLoadingAccounting(false);
        toast(<AlertError message={res && res.data && res.data.meesage ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const details =
    (props.getListingProductDetail &&
      props.getListingProductDetail.accounting &&
      props.getListingProductDetail.accounting.data) ||
    {};

  useEffect(() => {
    if (showAccountingModal) {
      setValues({
        acc_flag_7: details.acc_flag_7,
        signed: details.signed,
        inventory: details.inventory,
        proxibid_paid: details.proxibid_paid,
        date_invoiced: details.date_invoiced ? details.date_invoiced : "",
        payrec_date: details.payrec_date ? details.payrec_date : "",
        total_sale: details.total_sale ? details.total_sale : 0,
        sales_price: details.sales_price ? details.sales_price : 0,
        cog: details.cog ? details.cog : 0,
        expenses: details.expenses ? details.expenses : 0,
        commission: details.commission ? details.commission : 0,
      });
    }
  }, [showAccountingModal]);

  return (
    <div class="details-page-widget-block" id={props.id}>
      <div class="page-widget-head d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <div
            class="page-widget-label text-capitalize cursor-pointer"
            onClick={() => !props.loadingMain && toggleAccountingModal()}
          >
            Accounting
          </div>
        </div>
        {/* <div class="btn-block">
          {props.loadingMain ? (
            <Skeleton height="30px" width="90px" />
          ) : (
            <a href="javascript:void(0)" class="btn btn-primary btn-sm" onClick={() => toggleAccountingModal()}>
              Edit
            </a>
          )}
        </div> */}
      </div>
      <div class="page-widget-body mt-3">
        <div class="widget-content-row d-flex flex-wrap">
          <div class="widget-content-left w-50">
            <div class="widget-content-label">{props.loadingMain ? <Skeleton width="60px" /> : "Date"}</div>
            {props.loadingMain ? (
              <Skeleton width="60px" />
            ) : (
              <div class="widget-content-date">
                {details && details.payrec_date ? moment(details.payrec_date).format("L") : "N/A"}
              </div>
            )}
          </div>
          <div class="widget-conten-right w-50">
            <div class="widget-content-label">{props.loadingMain ? <Skeleton width="60px" /> : "Edited By"}</div>
            {props.loadingMain ? (
              <Skeleton width="60px" />
            ) : (
              <div class="widget-content-date">{details && details.payrec_by ? details.payrec_by[0] : "N/A"}</div>
            )}
          </div>
        </div>
        <div class="table-responsive mt-3">
          <table class="table  product-table accounting-table">
            <colgroup>
              <col style={{ width: "45%" }} />
              <col />
            </colgroup>
            <tbody>
              {props.loadingMain ? (
                <>
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                </>
              ) : (
                <>
                  <tr>
                    <td>Seller Signed Date</td>
                    {/* <td class="text-end">{details && details.payrec_date? details.payrec_date:'-'}</td> */}
                    <td class="text-end">{details && details.signedDate ? details.signedDate : "N/A"}</td>
                  </tr>
                  <tr>
                    <td>High Bid</td>
                    <td class="text-end">{details && details.total_bid ? twoDecimals(details.total_bid) : "0.00"}</td>
                  </tr>
                  <tr>
                    <td>Seller Bid</td>
                    <td class="text-end">{details && details.seller_bid ? twoDecimals(details.seller_bid) : "0.00"}</td>
                  </tr>
                  <tr>
                    <td>Potential Profit</td>
                    <td class="text-end">
                      <strong>
                        {details && details.potential_profit && details.potential_profit
                          ? details.potential_profit > 0
                            ? "$" +
                              new Intl.NumberFormat("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }).format(details.potential_profit)
                            : details.potential_profit < 0 &&
                              "-$" +
                                new Intl.NumberFormat("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }).format(-details.potential_profit)
                          : "$0.00"}
                      </strong>
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </div>
        <div class="table-responsive">
          <table class="table mb-0  product-table accounting-table2">
            <colgroup>
              <col style={{ width: "45%" }} />
              <col />
            </colgroup>
            <tbody>
              {props.loadingMain ? (
                <>
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                </>
              ) : (
                <>
                  <tr>
                    <td>Total Sale</td>
                    <td class="text-end">{details && details.total_sale ? twoDecimals(details.total_sale) : "0.00"}</td>
                  </tr>
                  <tr>
                    <td>Final Sales Price</td>
                    <td class="text-end">
                      {details && details.sales_price ? twoDecimals(details.sales_price) : "0.00"}
                    </td>
                  </tr>
                  <tr>
                    <td>COG</td>
                    <td class="text-end">{details && details.cog ? twoDecimals(details.cog) : "0.00"}</td>
                  </tr>
                  <tr>
                    <td>Expenses</td>
                    <td class="text-end">{details && details.expenses ? twoDecimals(details.expenses) : "0.00"}</td>
                  </tr>
                  <tr>
                    <td>Commissions</td>
                    <td class="text-end">
                      {details && details.commissions ? twoDecimals(details.commissions) : "0.00"}
                    </td>
                  </tr>
                  <tr>
                    <td>Profit</td>
                    <td class="text-end">
                      <strong>
                        {details && details.profit && details.profit
                          ? details.profit > 0
                            ? "$" +
                              new Intl.NumberFormat("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }).format(details.profit)
                            : details.profit < 0 &&
                              "-$" +
                                new Intl.NumberFormat("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }).format(-details.profit)
                          : "$0.00"}
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Profit Percentage</strong>
                    </td>
                    <td class="text-end">
                      <strong>
                        {details && details.profit_pct
                          ? details.profit_pct > 0
                            ? twoDecimals(details.profit_pct) + "%"
                            : details.profit_pct < 0 && (
                                <span style={{ color: "red" }}>{twoDecimals(-details.profit_pct) + "%"}</span>
                              )
                          : "0.00%"}
                      </strong>
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <AccountingModal
        show={showAccountingModal}
        onHide={toggleAccountingModal}
        toggleExpenseAllocation={toggleExpenseAllocation}
        values={values}
        setValues={setValues}
        loadingAccounting={loadingAccounting}
        details={details}
        handleSubmit={handleSubmit}
      />
      <ExpenseAllocationModal
        show={showAllocation}
        onHide={toggleExpenseAllocation}
        expenseData={expenseData}
        setExpenseData={setExpenseData}
        saveHandler={handleExpenseAllocation}
        loadingSave={loadingAccounting}
        handleDeleteExpense={handleDeleteExpense}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateAccounting: (params, data, callback) => dispatch(updateAccounting(params, data, callback)),
  getAccounting: (params, callback) => dispatch(getAccounting(params, callback)),
  updateAuctionExpense: (params, data, callback) => dispatch(updateAuctionExpense(params, data, callback)),
  deleteAuctionExpenses: (id, params, callback) => dispatch(deleteAuctionExpenses(id, params, callback)),
});

const mapStateToProps = (state) => ({
  getListingProductDetail: getListingProductDetail(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Accounting));
