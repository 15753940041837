import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import close from "../../include/images/close-12x12.svg";
import crossIcon from "../../include/images/cross-icon.svg";
import _ from "lodash";
import NextButton from "../../common/form/nextButton";
import { toast } from "react-toastify";

import AlertError from "../../common/alerts/alertError";
import AlertSuccess from "../../common/alerts/alertSuccess";
import parse from "html-react-parser";
import { connect } from "react-redux";
import { Amplify, Storage } from "aws-amplify";
import { Auth } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import LoadingBar from "react-top-loading-bar";
import { uploadReseachAssFile } from "../../store/researchAssignment";

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    region: "us-east-1",
  },
  Storage: {
    bucket: process.env.REACT_APP_S3_UPLOAD_BUCKET,
    region: "us-east-1",
  },
});
const UploadParts = (props) => {
  const ref = useRef();

  const [loadingUpload, setLoadingUpload] = useState(false);

  const handleSubmitDocument = (e) => {
    if (props.file) {
      setLoadingUpload(true);
      const uploadFile = props.file;
      ref?.current?.continuousStart();
      const fSize = Math.round(uploadFile.size / 1048576);
      const fType = uploadFile.type;
      const ext = uploadFile.name.split(".").pop();
      if (fSize > 25) {
        ref?.current?.complete();
        toast(<AlertError message="Size exceeds maximum allowable size. Maximum allowable size is 25MB." />);
        return setLoadingUpload(false);
      } else if (!["text/csv"].includes(fType)) {
        ref?.current?.complete();
        toast(
          <AlertError message="File is not of correct format and hence cannot be uploaded. Valid document format is csv." />,
        );
        return setLoadingUpload(false);
      } else {
        const fileName = "newSalvex/doc/" + uuidv4() + "." + ext;
        Storage.put(fileName, props.file, {
          completeCallback: (event) => {},
          progressCallback: (progress) => {},
          errorCallback: (err) => {
            return (
              setLoadingUpload(false), ref?.current?.complete(), toast(<AlertError message={"Something Went Wrong"} />)
            );
          },
        }).then((result) => {
          props.uploadReseachAssFile(
            props.assId,
            "post",
            {
              document: "public/" + result.key,
            },
            (res) => {
              if (res.status === 200) {
                props.uploadReseachAssFile(
                  props.assId,
                  "put",
                  {
                    document: res.data?.data?.document,
                    action: "processcolumn",
                  },
                  (res1) => {
                    if (res1 && res1.status === 200) {
                      ref?.current?.complete();
                      props.setFileData(res1?.data?.data);
                      props.setStep(2);
                      setLoadingUpload(false);
                    } else {
                      setLoadingUpload(false);
                      ref?.current?.complete();
                      toast(
                        <AlertError
                          message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"}
                        />,
                      );
                    }
                  },
                );
              } else {
                setLoadingUpload(false);
                ref?.current?.complete();
                toast(
                  <AlertError
                    message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"}
                  />,
                );
              }
            },
          );
        });
      }
    }
  };

  return (
    <>
      <LoadingBar height={5} color="#47AD1D" ref={ref} />
      <Modal
        className="modal fade custom-modal"
        id="editProductDescription"
        show={props.show}
        dialogClassName="modal-dialog modal-dialog-centered modal-xxl"
      >
        <div class="modal-content d-flex flex-column">
          <div class="custom-modal-header d-flex align-items-center justify-content-between">
            <h5 class="mb-0 text-capitalize" style={{ marginLeft: "40px" }}>
              {"Upload File"}
            </h5>
            <button
              type="button"
              class="close-btn d-flex align-items-center justify-content-center"
              onClick={() => {
                props.setFile(null);
                props.onHide();
              }}
            >
              <img src={close} alt="" />
            </button>
          </div>
          <Modal.Body className="custom-modal-body flex-grow-1 w-100 overflow-hidden">
            <div class="offcanvas-widget-row pt-0">
              <p>Document must be in CSV format. </p>

              <div class="mb-20">
                <label for="slectDocument" class="form-label">
                  Select CSV File
                </label>
                <div class="upload-lg-button position-relative">
                  <span class="file-input">
                    <input
                      type={"file"}
                      id="inputGroupFile02"
                      accept={".csv"}
                      onChange={(e) => props.setFile(e.target.files[0])}
                    />

                    <span id="upload-file-name" class="d-flex align-items-center">
                      {props.file && props.file.name}
                      {props.file ? (
                        <img class="cross-icon-box" src={crossIcon} alt="" onClick={() => props.setFile(null)} />
                      ) : (
                        "Please select a file"
                      )}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </Modal.Body>
          <div class="custom-modal-dividor"></div>
          <div class="custom-modal-footer d-grid gap-2 d-md-flex align-items-center justify-content-md-end">
            <button
              class="btn btn-link me-md-2 global-cancel-button"
              type="button"
              onClick={() => {
                props.setFile(null);
                props.onHide();
              }}
            >
              Cancel
            </button>

            <NextButton
              classData="btn btn-default"
              handleSubmit={(e) => handleSubmitDocument()}
              loading={loadingUpload}
              label={"Upload"}
              disable={!props.file ? true : false}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  uploadReseachAssFile: (params, type, data, callback) => dispatch(uploadReseachAssFile(params, type, data, callback)),
});

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(UploadParts));
