import React from "react";
import { Modal } from "react-bootstrap";
import close from "../../../../include/images/close-12x12.svg";

const BuyerCategoryDataModal = (props) => {
  return (
    <Modal
      className="modal fade custom-modal"
      id="newBuyer"
      show={props.show}
      onHide={props.onHide}
      dialogClassName="modal-dialog modal-dialog-centered modal-xl"
    >
      <div class="modal-content h-100 d-flex flex-column">
        <div class="custom-modal-header d-flex align-items-center justify-content-between">
          <h5 class="mb-0 text-capitalize">Buyer Category Data</h5>
          <button
            type="button"
            class="close-btn d-flex align-items-center justify-content-center"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={props.onHide}
          >
            <img src={close} alt="" />
          </button>
        </div>
        <div class="custom-modal-body flex-grow-1 hide-scrollbar">
          <div class="table-responsive table-custom">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>Category</th>
                  <th>Purchase Status</th>
                  <th>Cumulative Purchase</th>
                  <th>Max Bid</th>
                  <th>Cumulative Bids</th>
                </tr>
              </thead>
              <tbody>
                {props?.data?.map((val) => (
                  <tr>
                    <td>{val?.description ? val?.description : "N/A"}</td>
                    <td>{val?.amount > 0 ? "Purchased" : val?.max_bid > 0 ? "Bid" : "Questioned"}</td>
                    <td>
                      {val.amount
                        ? "$" +
                          new Intl.NumberFormat("en-US", {
                            style: "decimal",
                            minimumFractionDigits: 2,
                          }).format(val.amount)
                        : "$0.00"}
                    </td>
                    <td>
                      {val.max_bid
                        ? "$" +
                          new Intl.NumberFormat("en-US", {
                            style: "decimal",
                            minimumFractionDigits: 2,
                          }).format(val.max_bid)
                        : "$0.00"}
                    </td>
                    <td>
                      {val.cumulative_bid
                        ? "$" +
                          new Intl.NumberFormat("en-US", {
                            style: "decimal",
                            minimumFractionDigits: 2,
                          }).format(val.cumulative_bid)
                        : "$0.00"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div class="custom-modal-dividor"></div>
        <div class="custom-modal-footer d-grid gap-2 d-md-flex align-items-center justify-content-md-end">
          <button
            class="btn btn-link me-md-2"
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={props.onHide}
          >
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default BuyerCategoryDataModal;
