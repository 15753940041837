import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import Header from "../../layouts/header";
import LoadingBar from "react-top-loading-bar";
import { toast } from "react-toastify";
import AlertError from "../../common/alerts/alertError";
import BarrTable from "../../components/reports/buyerARREmailStats/barrTable";
import {
  getEmailContent,
  getEmailLogs,
  getEmailsActivity,
  getEmailUUID,
  getGraphData,
  getSentEmailLogs,
} from "../../store/third-part-apis/brevo";
import moment from "moment";
import ViewBARREmail from "../../components/reports/buyerARREmailStats/viewEmail";
import ARRHistory from "../../layouts/modals/bids/aRRHistory";
import BarrFilters from "../../components/reports/buyerARREmailStats/barrFilters";

const BuyerARRStats = (props) => {
  const ref = useRef(null);
  const [loading, setLoading] = useState(false);
  const [emailActivity, setEmailActivty] = useState(null);
  const [sentEmailData, setSentEmailData] = useState([]);
  const [graphData, setGraphData] = useState([]);

  const [showSentMail, setShowSentMail] = useState(false);
  const [emailContent, setEmailContent] = useState();
  const [emailHistoryReport, setEmailHistoryReport] = useState([]);
  const [SARRfilters, setSARRFilters] = useState({
    action: { name: "Select Action", value: "-1" },
    startDate: moment().subtract(30, "days").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    dateRange: {
      name: "Last 30 days",
      value: { from: moment().subtract(30, "days").format("YYYY-MM-DD"), to: moment().format("YYYY-MM-DD") },
    },
  });
  const [sarrEmail, setSarrEmail] = useState("");
  const [showEmailReport, setShowEmailReport] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [filterValues, setFilterValues] = useState({
    dateRange: {
      name: "Last 30 days",
      value: { from: moment().subtract(30, "days").format("YYYY-MM-DD"), to: moment().format("YYYY-MM-DD") },
    },
    search: "",
    dateRangeValue: {
      value: { from: moment().subtract(30, "days").format("YYYY-MM-DD"), to: moment().format("YYYY-MM-DD") },
    },
  });

  const toggleSentMail = () => {
    setShowSentMail(!showSentMail);
  };
  const toggleEmailReport = () => {
    setShowEmailReport(!showEmailReport);
  };

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const getSellerARRLogs = async (email) => {
    ref?.current?.continuousStart();
    const logsData = await getEmailLogs(
      `smtp/statistics/events?limit=250&offset=0&email=${email}&tags=Buyer%20Report&sort=desc`,
    );
    setEmailHistoryReport(logsData?.data?.events);
    setSarrEmail(email);
    ref?.current?.complete();
    toggleEmailReport();
  };

  useEffect(() => {
    const filteredData = async () => {
      ref?.current?.continuousStart();
      const logsData = await getEmailLogs(
        `smtp/statistics/events?limit=250&offset=0&email=${sarrEmail}&tags=Buyer%20Report&sort=desc${
          SARRfilters?.startDate && SARRfilters?.endDate
            ? `&startDate=${SARRfilters?.startDate}&endDate=${SARRfilters?.endDate}`
            : ""
        }`,
      );
      if (logsData?.message && logsData?.status !== 200) {
        ref?.current?.complete();
        toast(<AlertError message={logsData?.response?.data?.message} />);
      } else {
        ref?.current?.complete();
        setEmailHistoryReport(logsData?.data?.events);
      }
    };
    if (
      showEmailReport &&
      ((!SARRfilters?.startDate && !SARRfilters?.endDate) || (SARRfilters?.startDate && SARRfilters?.endDate))
    ) {
      filteredData();
    }
  }, [SARRfilters?.startDate, SARRfilters?.endDate]);

  const fetchEmailActivity = async () => {
    ref?.current?.continuousStart();
    setLoading(true);
    const url = `smtp/statistics/aggregatedReport?startDate=${filterValues?.dateRangeValue?.value?.from}&endDate=${filterValues?.dateRangeValue?.value?.to}&tag=Buyer%20Report`;
    const data = await getEmailsActivity(url);
    if (data?.message && data?.status !== 200) {
      toast(<AlertError message={data?.response?.data?.message} />);
    } else {
      setEmailActivty(data?.data);
    }
    ref?.current?.complete();
    setLoading(false);
  };

  const fetchSentEmail = async () => {
    ref?.current?.continuousStart();
    setLoading(true);
    const url = `smtp/statistics/events?${filterValues?.search ? `email=${filterValues?.search}&` : ""}startDate=${
      filterValues?.dateRangeValue?.value?.from
    }&endDate=${filterValues?.dateRangeValue?.value?.to}&tags=Buyer%20Report&limit=500`;
    const data = await getSentEmailLogs(url);
    if (data?.message && data?.status !== 200) {
      toast(<AlertError message={data?.response?.data?.message} />);
    } else {
      setSentEmailData(data?.data?.events);
    }
    ref?.current?.complete();
    setLoading(false);
  };

  const fetchGraphData = async () => {
    ref?.current?.continuousStart();
    setLoading(true);
    const url = `smtp/statistics/reports?startDate=${filterValues?.dateRangeValue?.value?.from}&endDate=${filterValues?.dateRangeValue?.value?.to}&tag=Buyer%20Report`;
    const data = await getGraphData(url);

    if (data?.message && data?.status !== 200) {
      toast(<AlertError message={data?.response?.data?.message} />);
    } else {
      const finaldata = data?.data?.reports?.map((ye) => ({
        delivered: ye?.delivered,
        bounced: ye?.softBounces + ye?.hardBounces,
        uniqueClicks: ye?.uniqueClicks,
        estimatedOpens: ye?.opens,
        uniqueOpens: ye?.uniqueOpens,
        sent: ye?.requests,
        date: ye?.date,
      }));
      setGraphData(finaldata);
    }
    ref?.current?.complete();
    setLoading(false);
  };

  const fetchEmailContent = async (email, messageId) => {
    ref?.current?.continuousStart();
    const uuidData = await getEmailUUID(
      `smtp/emails?email=${email}&messageId=${messageId}&sort=desc&limit=500&offset=0`,
    );
    const emailContent = await getEmailContent(`smtp/emails/${uuidData?.data?.transactionalEmails?.[0]?.uuid}`);
    setEmailContent(emailContent?.data);
    toggleSentMail();
    ref?.current?.complete();
  };

  useEffect(() => {
    fetchEmailActivity();
    fetchSentEmail();
    fetchGraphData();
  }, [filterValues?.dateRangeValue?.value?.from, filterValues?.dateRangeValue?.value?.to]);

  useEffect(() => {
    fetchSentEmail();
  }, [filterValues?.search]);

  return (
    <>
      <LoadingBar height={5} color="#47AD1D" ref={ref} />
      <Header />

      <BarrTable
        ref={ref}
        emailActivity={emailActivity}
        sentEmailData={sentEmailData}
        filterValues={filterValues}
        graphData={graphData}
        getSellerARRLogs={getSellerARRLogs}
        toggleFilters={toggleFilters}
        setFilterValues={setFilterValues}
      />

      {showEmailReport && (
        <ARRHistory
          show={showEmailReport}
          onHide={toggleEmailReport}
          data={emailHistoryReport}
          type={"Buyer Report"}
          setData={setEmailHistoryReport}
          setFilter={setSARRFilters}
          filter={SARRfilters}
          email={sarrEmail}
          fetchEmailContent={fetchEmailContent}
        />
      )}
      {showSentMail && <ViewBARREmail show={showSentMail} onHide={toggleSentMail} data={emailContent} />}
      {showFilters && (
        <BarrFilters show={showFilters} onHide={toggleFilters} data={filterValues} setData={setFilterValues} />
      )}
    </>
  );
};

export default React.memo(BuyerARRStats);
