import React from "react";
import close from "../../../include/images/close-12x12.svg";
import { Modal } from "react-bootstrap";
import DateFormat from "../../../common/DateFormat/dateFormat";

const BidsHistory = (props) => {
  return (
    <Modal
      show={props.show}
      onHide={() => props.onHide()}
      className="modal fade salvex-modal2 av_viewall_modal"
      id="aviationParts"
      dialogClassName="modal-dialog  modal-xl modal-dialog-centered"
      contentClassName="modal-content"
    >
      <div class="custom-modal-header d-flex align-items-center justify-content-between">
        <h5 class="mb-0 text-capitalize">{`Bids History of ${props?.data?.name}`}</h5>
        <button
          type="button"
          class="close-btn d-flex align-items-center justify-content-center"
          onClick={() => props.onHide()}
        >
          <img src={close} alt="" />
        </button>
      </div>

      <Modal.Body className="modal-body aviation-view-body">
        <div class="table-responsive table-custom ">
          <table class="table mb-0  table-bordered history-bids">
            <colgroup>
              <col style={{ width: "50%" }} />
              <col style={{ width: "50%" }} />
            </colgroup>
            <thead>
              <tr>
                <th>Date</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <DateFormat date={props?.data?.bid_time} />
                </td>
                <td>
                  {" "}
                  {props?.data?.total_bid
                    ? "$" +
                      new Intl.NumberFormat("en-US", {
                        style: "decimal",
                        minimumFractionDigits: 2,
                      }).format(props?.data?.total_bid)
                    : "$0"}
                </td>
              </tr>
              {props?.data?.otherBids?.length > 0 &&
                props?.data?.otherBids?.map((de) => (
                  <tr>
                    <td>
                      {" "}
                      <DateFormat date={de?.bid_time} />
                    </td>
                    <td>
                      {de?.total_bid
                        ? "$" +
                          new Intl.NumberFormat("en-US", {
                            style: "decimal",
                            minimumFractionDigits: 2,
                          }).format(de?.total_bid)
                        : "$0"}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </Modal.Body>
      <div class="custom-modal-footer d-grid gap-2 d-md-flex align-items-center justify-content-md-end">
        <button class="btn btn-link me-md-2 global-cancel-button" type="button" onClick={() => props.onHide()}>
          Cancel
        </button>
      </div>
    </Modal>
  );
};

export default BidsHistory;
