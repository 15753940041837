import React, { useEffect, useRef, useState } from "react";
import "../../../include/css/sustainability.css";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";

import { Pie } from "react-chartjs-2";
import Skeleton from "react-loading-skeleton";
import moment from "moment";

import eiLogo from "../../../include/images/eii__logo.svg";
import logo from "../../../include/images/logo.svg";
import graphImg from "../../../include/images/map.png";
import pinIng from "../../../include/images/pin-img.svg";
import { ei, getCarbonOffsetReport } from "../../../store/ei";
import { capitalizeFirstLetter, numberFormat, removeTags } from "../../../utils.js/conversion";
import { connect } from "react-redux";
import chevronDown from "../../../include/images/chevron-down.svg";
import send from "../../../include/images/send.svg";
import ScrollButton from "../../../common/scrollTop/scrollTop";
import LoadingBar from "react-top-loading-bar";
import { checkToken, getProfile, setRedirectUrl } from "../../../utils.js/localStorageServices";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { isNull } from "lodash";
import { customStyles } from "../../../common/customStyles/reactSelectStyle";
import Select from "react-select";
import AlertError from "../../../common/alerts/alertError";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import { toast } from "react-toastify";
import { Amplify, Storage } from "aws-amplify";
import { Auth } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import html2canvas from "html2canvas";
import { dataURLtoFile } from "../../../common/editImage/dataURLtoFile";
import html2pdf from "html2pdf.js";

Chart.register(ArcElement, Tooltip, Legend);

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    region: "us-east-1",
  },
  Storage: {
    bucket: process.env.REACT_APP_S3_UPLOAD_BUCKET,
    region: "us-east-1",
  },
});

const SustainabilityReport = (props) => {
  const mapRef = useRef();
  const chartRef = useRef(null);
  const ref = useRef(null);
  const location = useLocation();
  const data = props?.getEiData?.detail;
  const [sections, setSections] = useState([
    "seller",
    "carbon_offset",
    "speciesAndOrgs",
    "team",
    "activity",
    "details",
  ]);
  const [emailData, setEmailData] = useState({ sender: [], recipient: [] });
  const [selectedData, setSelectedData] = useState({ sender: "", recipient: "" });
  const [loadingMain, setLoadingMain] = useState(true);
  const [loadingSend, setLoadingSend] = useState(false);

  useEffect(() => {
    document.body.classList.add("fixed");

    if (!checkToken()) {
      const a = window.location.href;
      setRedirectUrl(a);
      props.history.push("/signin");
    }
    return () => {
      document.body.classList.remove("fixed");
    };
  }, []);

  useEffect(() => {
    if (!isNull(data)) {
      const sender = data?.from?.map((ur) => ({
        name: `${ur.name} (${ur?.email})`,
        value: ur.email,
      }));

      const usersByType = {};

      data?.to.forEach((user) => {
        if (!usersByType[user.type]) {
          usersByType[user.type] = [];
        }
        usersByType[user.type].push(user);
      });

      const groupedOptions = Object.keys(usersByType).map((type) => ({
        label: type,
        options: usersByType[type].map((user) => ({ value: user.email, name: `${user.name} (${user?.email})` })),
      }));
      setEmailData({ sender: sender, recipient: groupedOptions });
    }
  }, [data]);

  const getSustainabilityDetail = () => {
    const id = location?.search?.split("=")?.[1];

    setLoadingMain(true);
    ref?.current?.continuousStart();
    if (id) {
      props?.getCarbonOffsetReport(false, id, { section: sections.join(",") }, (res) => {
        if (res && res?.status == 200) {
          ref?.current?.complete();
          setLoadingMain(false);
        } else {
          ref?.current?.complete();
          setLoadingMain(false);
        }
      });
    }
  };

  useEffect(() => {
    getSustainabilityDetail();
  }, []);

  const imageRender = (data) => {
    const elements = [];

    for (let i = 0; i < data; i++) {
      elements.push(
        <img
          style={{ marginLeft: "3px", marginRight: "3px" }}
          src="https://media.salvex.com/person.png"
          alt="person"
        />,
      );
    }
    return elements;
  };

  const chartData = {
    labels: ["Raw Materials", "Assembly", "Transportation"],

    datasets: [
      {
        data: [data?.raw_materials_pct, data?.assembly_pct, data?.transportation_pct],
        backgroundColor: ["#376830", "#47AD1D", "#A1CF6D"],
        borderColor: ["#376830", "#47AD1D", "#A1CF6D"],
        borderWidth: 1,
        offset: [10, 10, 10],
      },
    ],
  };

  const total_contacted = data?.total_contacted ? +data?.total_contacted : 0;

  function roundToNearestInteger(number) {
    const floor = Math.floor(number);
    const ceil = Math.ceil(number);

    if (number - floor < ceil - number) {
      return floor;
    } else {
      return ceil;
    }
  }

  const captureComponent = async (isPrint) => {
    const id = location?.search?.split("=")?.[1];
    ref?.current?.continuousStart();
    setLoadingSend(true);
    let allPromise;
    let fileName1 = "";
    let fileName2 = "";
    let file = null;
    let file1 = null;
    if (sections?.includes("carbon_offset") || sections?.includes("activity")) {
      const value = { map: "", graph: "" };
      if (
        sections?.includes("carbon_offset") &&
        (data?.raw_materials_pct || data?.assembly_pct || data?.transportation_pct)
      ) {
        const chartCanvas = await html2canvas(chartRef.current);
        const cDataUrl = chartCanvas.toDataURL("image/png");
        file = dataURLtoFile(cDataUrl, "map");
        const ext = "png";
        fileName1 = "report/" + uuidv4() + "." + ext;
      }
      if (sections?.includes("activity")) {
        const mapCanvas = await html2canvas(mapRef.current);
        const bDataUrl = mapCanvas.toDataURL("image/png");
        file1 = dataURLtoFile(bDataUrl, "graph");
        const ext1 = "png";
        fileName2 = "report/" + uuidv4() + "." + ext1;
      }
      const a = [];
      if (fileName1) {
        a.push(
          Storage.put(fileName1, file, {
            completeCallback: (event) => {},
            progressCallback: (progress) => {},
            errorCallback: (err) => {},
          }),
        );
      }
      if (fileName2) {
        a.push(
          Storage.put(fileName2, file1, {
            completeCallback: (event) => {},
            progressCallback: (progress) => {},
            errorCallback: (err) => {},
          }),
        );
      }
      allPromise = Promise.all([...a]);
    } else {
      allPromise = new Promise((resolve, reject) => {
        resolve(console.log("New Promise"));
      });
    }
    allPromise.then((values) => {
      props?.getCarbonOffsetReport(
        isPrint,
        id,
        {
          section: sections.join(", "),
          sendMail: !isPrint,
          print: isPrint,
          fromEmail: selectedData?.sender?.value,
          toEmail: selectedData?.recipient?.value,
          mapImage: fileName2,
          pieImage: fileName1,
        },
        (res) => {
          if (res && res?.status == 200) {
            setLoadingMain(false);
            setLoadingSend(false);

            ref?.current?.complete();
            toast(<AlertSuccess message={"Sustainability Report Sent Successfully"} />);
          } else {
            ref?.current?.complete();
            setLoadingMain(false);
            toast(<AlertError message={res?.data?.message ? res?.data?.message : "Something went wrong"} />);
          }
        },
      );
    });
  };
  useEffect(() => {
    if (!isNull(data)) {
      const profile = getProfile();
      const recipient = { name: `${data?.seller_name} (${data?.seller_email})`, value: `${data?.seller_email}` };
      const sender = { name: `${profile?.name01} (${profile?.email})`, value: `${profile?.email}` };
      setSelectedData({ sender, recipient });
    }
  }, [data]);

  const downloadAsPDF = () => {
    const element = document.getElementById("contentToConvert");
    html2pdf(element, {
      margin: [0.5, 0],
      filename: "sustainability-report.pdf",
      image: { type: "jpeg", quality: 1 },
      html2canvas: {
        scale: 3,
        useCORS: true,
        allowTaint: false,
        letterRendering: true,
        y: 20,
        x: 0,
      },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    });
  };

  return (
    <>
      <ScrollButton />
      <LoadingBar height={5} color="#47AD1D" ref={ref} />

      <header id="eiHeader " className="eiHeader1">
        <nav class="navbar ei__navbar ">
          <div class="container ei-container">
            <div class="ei__nav-inside d-flex align-items-center justify-content-between">
              <a
                href="javascript:void(0)"
                class={`ei__btn ei__btn-default ${loadingSend ? "btn-disabled" : ""}`}
                onClick={() => downloadAsPDF()}
              >
                <span class="d-none d-sm-inline-block">Print</span>
              </a>
              <div class="ei__header-right d-flex align-items-center">
                <div class="dropdown ei__dropdown-menu">
                  <button
                    type="button"
                    class="ei__btn ei__btn-transparent d-flex align-items-center justify-content-end dropdown-toggle"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    aria-expanded="false"
                    data-bs-offset="20,20"
                  >
                    Sections <img class="icon" src={chevronDown} alt="chevron down" />
                  </button>
                  <div class="dropdown-menu dropdown-menu-md-end">
                    <div class="ei__filter-sections-list">
                      <div class="ei__fsl-item">
                        <label class="ei__filter-content-box">
                          Environmental Organizations
                          <input
                            type="checkbox"
                            checked={sections?.includes("speciesAndOrgs")}
                            onClick={() => {
                              const value = [...sections];
                              if (sections?.includes("speciesAndOrgs")) {
                                const a = value?.filter((item) => item !== "speciesAndOrgs");
                                setSections(a);
                              } else {
                                value.push("speciesAndOrgs");
                                setSections(value);
                              }
                            }}
                          />
                          <span class="ei__checkmark"></span>
                        </label>
                      </div>
                      <div class="ei__fsl-item">
                        <label class="ei__filter-content-box">
                          Carbon Offset
                          <input
                            type="checkbox"
                            checked={sections?.includes("carbon_offset")}
                            onClick={() => {
                              const value = [...sections];
                              if (sections?.includes("carbon_offset")) {
                                const a = value?.filter((item) => item !== "carbon_offset");
                                setSections(a);
                              } else {
                                value.push("carbon_offset");
                                setSections(value);
                              }
                            }}
                          />
                          <span class="ei__checkmark"></span>
                        </label>
                      </div>

                      <div class="ei__fsl-item">
                        <label class="ei__filter-content-box">
                          Asset Repurposing Team
                          <input
                            type="checkbox"
                            checked={sections?.includes("team")}
                            onClick={() => {
                              const value = [...sections];
                              if (sections?.includes("team")) {
                                const a = value?.filter((item) => item !== "team");
                                setSections(a);
                              } else {
                                value.push("team");
                                setSections(value);
                              }
                            }}
                          />
                          <span class="ei__checkmark"></span>
                        </label>
                      </div>
                      <div class="ei__fsl-item">
                        <label class="ei__filter-content-box">
                          Buyer Repurposing Activity
                          <input
                            type="checkbox"
                            checked={sections?.includes("activity")}
                            onClick={() => {
                              const value = [...sections];
                              if (sections?.includes("activity")) {
                                const a = value?.filter((item) => item !== "activity");
                                setSections(a);
                              } else {
                                value.push("activity");
                                setSections(value);
                              }
                            }}
                          />
                          <span class="ei__checkmark"></span>
                        </label>
                      </div>
                      <div class="ei__fsl-item">
                        <label class="ei__filter-content-box">
                          Customer Information
                          <input
                            type="checkbox"
                            checked={sections?.includes("seller")}
                            onClick={() => {
                              const value = [...sections];
                              if (sections?.includes("seller")) {
                                const a = value?.filter((item) => item !== "seller");
                                setSections(a);
                              } else {
                                value.push("seller");
                                setSections(value);
                              }
                            }}
                          />
                          <span class="ei__checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="dropdown ei__dropdown-menu">
                  <button
                    type="button"
                    class={`ei__btn ei__btn-primary btn-sm d-flex align-items-center justify-content-end dropdown-toggle ${
                      loadingSend ? "btn-disabled" : ""
                    }`}
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    aria-expanded="false"
                    data-bs-offset="20,20"
                  >
                    <img class="asc-icon" src={send} alt="sendIcon" /> Send{" "}
                    <img class="dsc-icon" src={chevronDown} alt="chevron down" />
                  </button>
                  <form class="dropdown-menu dropdown-menu-end  ei__dropdown-menu-md">
                    <div class="form-floating mb-3">
                      <Select
                        menuPosition="fixed"
                        menuShouldScrollIntoView
                        styles={customStyles}
                        className="basic-single "
                        classNamePrefix="select-search  "
                        placeholder={`From`}
                        name={"fromEmail"}
                        options={emailData?.sender}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.value}
                        onChange={(e) => {
                          const value = { ...selectedData };
                          value.sender = e;
                          setSelectedData(value);
                        }}
                        value={selectedData?.sender}
                      />
                    </div>
                    <div class="form-floating mb-3">
                      <Select
                        menuPosition="fixed"
                        menuShouldScrollIntoView
                        styles={customStyles}
                        className="basic-single "
                        classNamePrefix="select-search  "
                        placeholder={`To`}
                        name={"toEmail"}
                        options={emailData?.recipient}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.value}
                        onChange={(e) => {
                          const value = { ...selectedData };
                          value.recipient = e;
                          setSelectedData(value);
                        }}
                        value={selectedData?.recipient}
                      />
                    </div>
                    <div class="btn-group">
                      <a
                        href="javascript:void(0)"
                        class={`ei__btn ei__btn-default text-uppercase ${loadingSend ? "btn-disabled" : ""}`}
                        onClick={() => captureComponent(false)}
                      >
                        SEND
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
      <div class="sustainability-pdf html2canvas-container" id="contentToConvert">
        <div class="ei__content-container ei__report-text-container ei__contenrt-container1">
          <div class="container ei-container">
            <div class="row">
              <div class="col-md-12">
                <div class="ei__report-text-upper d-flex flex-wrap justify-content-between">
                  <div class="ei__rtu-left">
                    <div class="ei__rtul-box">
                      <h6>Sustainability Report</h6>
                      <div class="ei__rtul-box-img d-md-none">
                        <img class="w-100 h-100 object-fit-cover" src={logo} alt="" />
                      </div>
                      <div class="mb-1">Powered by Salvex Group, Inc.</div>
                      <a href="/" target="_blank">
                        www.salvex.com
                      </a>
                    </div>
                  </div>
                  <div class="ei__rtu-right">
                    <div class="ei__rtur-box">
                      <div class="ei__rtur-box-img d-none d-md-block exclude-print">
                        <img class="w-100 h-100 object-fit-cover" src={eiLogo} alt="" />
                      </div>
                      <div class="ei__rtur-box-text text-end">
                        {loadingMain ? (
                          <Skeleton count="2" />
                        ) : (
                          <>
                            <div> {typeof window !== "undefined" && moment().format("dddd, MMM DD YYYY, h:mm A")}</div>
                            <div>
                              Salvex ID : <strong>{data && data.auction_id ? data.auction_id : "N/A"}</strong>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ei__report-text-upper" style={{ padding: "15px 0px" }}>
                  <div class="container ei-container" style={{ padding: "0px" }}>
                    <div class="row">
                      <div class="col-md-12">
                        <p>
                          The sale of your commercial asset is considered a repurposing activity. This sustainability
                          report outlines the environmental outreach and carbon offset created. This has no tax benefit,
                          but does illustrates the positive impact on the environment.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="ei__report-text-lower">
                  <div class="ei__etl-content d-flex">
                    <div class="ei__rtlc-img ratio ratio-4x3 exclude-print">
                      {loadingMain ? (
                        <Skeleton height={"150px"} />
                      ) : (
                        <img
                          class="w-100 h-100 object-fit-cover"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            window.open(
                              process.env.REACT_APP_MODE === "development"
                                ? `https://www.newdev.salvex.com/listings/listing_detail.cfm/aucid/${data.auction_id}`
                                : `https://www.salvex.com/listings/listing_detail.cfm/aucid/${data.auction_id}`,
                            )
                          }
                          src={
                            (data && data.primary_picture === "dummy") || !data.primary_picture
                              ? "https://media.salvex.com/auction/p/1830012/183001141_641791_mp.jpg"
                              : data.primary_picture + "_mp." + data.primary_ext
                          }
                          alt={data && data.title ? data.title : "N/A"}
                        />
                      )}
                    </div>
                    <div class="ei__rtlc-text">
                      <div class="ei__rtlc-text-box">
                        {loadingMain ? (
                          <Skeleton height={"20px"} width={"50%"} />
                        ) : (
                          <h6
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              window.open(
                                process.env.REACT_APP_MODE === "development"
                                  ? `https://www.newdev.salvex.com/listings/listing_detail.cfm/aucid/${data.auction_id}`
                                  : `https://www.salvex.com/listings/listing_detail.cfm/aucid/${data.auction_id}`,
                              )
                            }
                          >
                            {data && data.title ? data.title : "N/A"}
                          </h6>
                        )}
                        {loadingMain ? (
                          ""
                        ) : (
                          <a
                            href={
                              process.env.REACT_APP_MODE === "development"
                                ? `https://www.newdev.salvex.com/listings/listing_detail.cfm/aucid/${data.auction_id}`
                                : `https://www.salvex.com/listings/listing_detail.cfm/aucid/${data.auction_id}`
                            }
                            target="_blank"
                          >
                            (Please click here to see the listing online)
                          </a>
                        )}
                        <div class="ei__rtlctb-box">
                          {loadingMain ? (
                            <Skeleton width={"50%"} />
                          ) : (
                            <div class="ei__rtlctb-box-list">
                              Quantity :{" "}
                              <strong>
                                {data.quantity ? data.quantity + " " + `${data.unit ? data.unit : "Units"}` : "N/A"}
                              </strong>
                            </div>
                          )}
                          {loadingMain ? (
                            <Skeleton width={"50%"} />
                          ) : (
                            <div class="ei__rtlctb-box-list">
                              Make : <strong>{data?.mfr_description ? data?.mfr_description : "N/A"}</strong>
                            </div>
                          )}
                          {loadingMain ? (
                            <Skeleton width={"50%"} />
                          ) : (
                            <div class="ei__rtlctb-box-list">
                              Model : <strong>{data?.model ? data?.model : "N/A"}</strong>
                            </div>
                          )}
                          {loadingMain ? (
                            <Skeleton count={"3"} width={"50%"} />
                          ) : (
                            <div class="ei__rtlctb-box-list">
                              Location :<strong>{data.location4 ? data.location4 : "N/A"}</strong>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {sections?.includes("speciesAndOrgs") && data?.species?.name ? (
          <div class="ei__content-container ei__text-img ei__contenrt-container1 ">
            <div class="container ei-container">
              <div class="row">
                <div class="col-md-8">
                  <div class="ei__heading">
                    <h6>Environment Outreach</h6>
                    {loadingMain ? (
                      ""
                    ) : (
                      <p>
                        The following organizations will receive a donation from the proceeds of this sale. Thanks for
                        helping the environment through your repurposing activities.
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="ei__text-img-content d-md-flex">
                    <div class="ei__img-content ratio ratio-4x3 flex-shrink-0 overflow-hidden exclude-print">
                      {loadingMain ? (
                        <Skeleton height={"440px"} />
                      ) : (
                        <img
                          src={process.env.REACT_APP_EI_MEDIA + data?.species?.coverImage?.medium}
                          alt={data?.species?.name}
                        />
                      )}
                    </div>
                    <div class="ei__text-content flex-grow-1">
                      <div class="ei__text-box">
                        {loadingMain ? (
                          <Skeleton height={"20px"} count={3} />
                        ) : (
                          <h6 className="capitalize-first-letter text-transform">
                            {data?.species?.name ? capitalizeFirstLetter(data?.species?.name) : "N/A"}{" "}
                            <span className="capitalize-first-letter text-transform">
                              {" "}
                              {data?.species?.scientificName
                                ? `(${capitalizeFirstLetter(data?.species?.scientificName)})`
                                : ""}
                            </span>
                          </h6>
                        )}

                        <p>
                          {loadingMain ? (
                            <Skeleton count="8" />
                          ) : data?.species?.description ? (
                            `${removeTags(data?.species?.description)}`
                          ) : (
                            ""
                          )}
                        </p>
                        {!loadingMain ? (
                          <div className="d-block">
                            <a
                              href={
                                process.env.REACT_APP_MODE === "development"
                                  ? `https://www.dev.environmentalinclusion.com/endangered-species/${
                                      data?.species?.slug
                                    }?redirect=${btoa(`speciesName=${data?.species?.slug}&isAccessible=fromToday`)}`
                                  : `https://www.environmentalinclusion.com/endangered-species/${
                                      data?.species?.slug
                                    }?redirect=${btoa(`speciesName=${data?.species?.slug}&isAccessible=fromToday`)}`
                              }
                              target="_blank"
                              class="ei__btn ei__btn-default"
                            >
                              <span class="d-none d-sm-inline-block">Donate a commercial asset</span>
                            </a>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {sections?.includes("carbon_offset") ? (
          <div class="ei__content-container ei__coce-container ei__contenrt-container1">
            <div class="container ei-container">
              <div class="row">
                <div class="col-md-8">
                  <div class="ei__heading">
                    <h6>Carbon Offset Calculation Estimate</h6>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="ei__coce-upper d-flex flex-wrap">
                    <div class="ei__coce-image-box">
                      <div
                        class="ei__coce-image position-relative exclude-print"
                        ref={chartRef}
                        style={{ paddingBottom: "0" }}
                      >
                        {loadingMain ? (
                          <Skeleton circle={true} height={"250px"} />
                        ) : data.raw_materials_pct && data.assembly_pct && data.transportation_pct ? (
                          <Pie
                            className="pie-chart-modification"
                            data={chartData}
                            options={{
                              responsive: true,
                              plugins: {
                                legend: {
                                  display: false,
                                  position: "bottom",
                                },
                              },
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div class="ei__coce-content">
                      {loadingMain ? (
                        <Skeleton count={8} height={"15px"} />
                      ) : (
                        <div class="ei__coce-content-box">
                          <h4 class="fw-semibold">
                            {data.quantity
                              ? numberFormat(data.quantity) + " " + `${data.unit ? data.unit : "Units"}`
                              : ""}{" "}
                            - {data.co2e_total ? numberFormat(data.co2e_total) : "0"} Tons of Carbon Offset
                          </h4>
                          <p>
                            This sale has created a manufacture carbon emission avoidance by moving the product back
                            into the circular economy. Here is the carbon offsets calculations, emissions impact, and
                            direct environmental beneficiaries.
                          </p>
                          <h5 class="fw-medium">
                            Raw Materials : {data && data.raw_materials_pct ? data.raw_materials_pct + "%" : "0%"}
                          </h5>
                          <h5 class="fw-medium">
                            Assembly : {data && data.assembly_pct ? data.assembly_pct + "%" : "0%"}
                          </h5>
                          <h5 class="fw-medium">
                            Transportation : {data && data.transportation_pct ? data.transportation_pct + "%" : "0%"}
                          </h5>
                        </div>
                      )}
                    </div>
                  </div>
                  <div class="ei__coce-lower">
                    <div class="ei__heading mb-0">
                      <h6>Carbon Offset Impact</h6>
                      {loadingMain ? (
                        <Skeleton height={"40px"} />
                      ) : (
                        <p>
                          Repurposing this asset created a Carbon Offset that equals the average annual carbon emissions
                          of <strong>{Math.ceil(data.co2e_total / 15)}</strong> people on average
                        </p>
                      )}
                      <div className="sustainability-person">
                        {!loadingMain ? imageRender(Math.ceil(data.co2e_total / 15)) : ""}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {sections?.includes("team") && data?.team?.length > 0 ? (
          <div class="ei__content-container ei__team-container overflow-hidden ei__contenrt-container1">
            <div class="container ei-container">
              <div class="row">
                <div class="col-md-7">
                  <div class="ei__heading">
                    <h6>Asset Repurposing Team</h6>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  {loadingMain ? (
                    <div class="d-flex flex-wrap">
                      {[1, 2, 3, 4, 5]?.map((cd) => (
                        <div style={{ marginRight: "15px" }}>
                          <Skeleton circle={true} height="100px" width={"100px"} />
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div class="ei__team-list d-flex flex-wrap">
                      {data?.team?.length > 0 &&
                        data?.team
                          .filter((fe) => fe?.profile_avatar)
                          ?.map((item) => (
                            <>
                              <div class="ei__team-item">
                                <div class="ei__team-box d-flex flex-wrap align-items-center">
                                  <div class="ei__team-image rounded-circle">
                                    <img class="w-100 h-100 object-cover" src={item.profile_avatar} alt="Avatar" />
                                  </div>
                                </div>
                              </div>
                            </>
                          ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {sections?.includes("activity") ? (
          <div class="ei__content-container ei__contenrt-container1 ">
            <div class="container ei-container">
              <div class="row">
                <div class="col-md-7">
                  <div class="ei__heading">
                    <h6>Buyer Repurposing Activity</h6>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12" ref={mapRef}>
                  {loadingMain ? (
                    <Skeleton height="500px" />
                  ) : (
                    <div class="map-outer w-100 position-relative">
                      <div class="total-contacted-label mb-4">
                        Total Contacted : <strong>{total_contacted ? numberFormat(total_contacted) : 0}</strong>
                      </div>
                      <div class="map position-relative">
                        <div class="map-wrap">
                          <img class="map-box" src={graphImg} alt="map image" />
                        </div>
                        <div class="pin pin-1 d-flex align-items-center justify-content-center">
                          <img src={pinIng} alt="/" />
                          <span class="position-relative z-1">
                            {total_contacted ? numberFormat(roundToNearestInteger(total_contacted * 0.65)) : 0}
                          </span>
                        </div>
                        <div class="pin pin-2 d-flex align-items-center justify-content-center">
                          <img src={pinIng} alt="/" />
                          <span class="position-relative z-1">
                            {total_contacted ? numberFormat(roundToNearestInteger(total_contacted * 0.09)) : 0}
                          </span>
                        </div>
                        <div class="pin pin-3 d-flex align-items-center justify-content-center">
                          <img src={pinIng} alt="/" />
                          <span class="position-relative z-1">
                            {total_contacted ? numberFormat(roundToNearestInteger(total_contacted * 0.17)) : 0}
                          </span>
                        </div>
                        <div class="pin pin-4 d-flex align-items-center justify-content-center">
                          <img src={pinIng} alt="/" />
                          <span class="position-relative z-1">
                            {total_contacted ? numberFormat(roundToNearestInteger(total_contacted * 0.04)) : 0}
                          </span>
                        </div>
                        <div class="pin pin-5 d-flex align-items-center justify-content-center">
                          <img src={pinIng} alt="/" />
                          <span class="position-relative z-1">
                            {total_contacted ? numberFormat(roundToNearestInteger(total_contacted * 0.03)) : 0}
                          </span>
                        </div>
                        <div class="pin pin-6 d-flex align-items-center justify-content-center">
                          <img src={pinIng} alt="/" />
                          <span class="position-relative z-1">
                            {total_contacted ? numberFormat(roundToNearestInteger(total_contacted * 0.02)) : 0}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {sections?.includes("seller") ? (
          <div class="ei__content-container ei__customer-info-container ei__contenrt-container1">
            <div class="container ei-container">
              <div class="row">
                <div class="col-md-7">
                  <div class="ei__heading">
                    <h6>CUSTOMER INFO</h6>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="ei__ci-content-box d-md-flex flex-md-wrap">
                    <div class="ei__ci-content-left">
                      <div class="ei__ci-img-box w-100 d-lg-flex flex-lg-wrap align-items-lg-center">
                        <div class="ei__left-box exclude-print">
                          {loadingMain ? (
                            <Skeleton height="151px" />
                          ) : (
                            <div class="ei__ci-img">
                              <img
                                style={{ objectFit: "contain" }}
                                src={
                                  data.seller_logo
                                    ? `https://media.salvex.com/co/logos/${data.seller_logo}`
                                    : "https://media.salvex.com/auction/p/1830012/183001141_641791_mp.jpg"
                                }
                                alt={data.seller_name ? data.seller_name : "N/A"}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div class="ei__ci-content-right">
                      <div class="ei__ci-text-box w-100 mx-auto d-sm-flex flex-sm-nowrap">
                        <div class="ei__ci-text-left flex-grow-1">
                          <div class="ei__icr-left-box w-100">
                            {loadingMain ? (
                              <Skeleton count={6} height="15px" width="100%" />
                            ) : (
                              <>
                                <h5> {data.seller_name ? data.seller_name : "N/A"}</h5>
                                {data.seller_title ? <h6> {data.seller_title}</h6> : ""}
                                {data.seller_enterprise ? <p>{data.seller_enterprise}</p> : ""}
                                {data.seller_location ? <p>{data.seller_location}</p> : ""}
                              </>
                            )}
                          </div>
                        </div>
                        {loadingMain ? (
                          ""
                        ) : (
                          <div class="ei__ci-text-right flex-shrink-0">
                            <ul class="ei__icr-right-list d-flex flex-column w-100">
                              <li class="ei__icr-right-item">
                                <div class="ei__icr-right-box d-flex align-items-center">
                                  <div class="ei__icr-label">P :</div>
                                  <a href={`tel:${data.seller_phone ? data.seller_phone : ""}`}>
                                    {data.seller_phone ? data.seller_phone : "N/A"}
                                  </a>
                                </div>
                              </li>
                              <li class="ei__icr-right-item">
                                <div class="ei__icr-right-box d-flex align-items-center">
                                  <div class="ei__icr-label">M :</div>
                                  <a href={`tel:${data.seller_cell ? data.seller_cell : ""}`}>
                                    {data.seller_cell ? data.seller_cell : "N/A"}
                                  </a>
                                </div>
                              </li>
                              <li class="ei__icr-right-item">
                                <div class="ei__icr-right-box d-flex align-items-center">
                                  <div class="ei__icr-label">E :</div>
                                  <a href={`mailto:${data.seller_email ? data.seller_email : ""}`}>
                                    {data.seller_email ? data.seller_email : "N/A"}
                                  </a>
                                </div>
                              </li>
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <div class="page-footer-container">
          <div class="container ei-container">
            <div class="row">
              <div class="col-md-12">
                <p>
                  Salvex is a repurposing platform of exchange for corporations to sell their surplus assets. Our
                  experience is based on a 40 year of experience in the secondary industry giving us the ability to
                  handle all types of commercial products. As part of our service we have an environmental out reach and
                  carbon offset calculations. This report has no commercial tax benefits.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  getEiData: ei(state)?.carbonOffset,
});

const mapDispatchToProps = (dispatch) => ({
  getCarbonOffsetReport: (isPrint, id, params, callback) =>
    dispatch(getCarbonOffsetReport(isPrint, id, params, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SustainabilityReport);
