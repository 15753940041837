import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import close from "../../../include/images/close.svg";
import emptyFolder from "../../../include/images/folder.svg";
import imageFill from "../../../include/images/image.svg";
import imageOutLine from "../../../include/images/Image-outline.svg";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import { numberFormat, twoDecimals } from "../../../utils.js/conversion";
import ImageLargeModal from "../../../layouts/modals/imageGalleryModal";
import DateFormat from "../../../common/DateFormat/dateFormat";

const LogModal = (props) => {
  return (
    <Modal
      className="modal fade custom-modal"
      id="editProductDescription"
      dialogClassName="modal-dialog modal-dialog-centered modal-xxl modal-wide"
      show={props.show}
      onHide={() => props.onHide()}
    >
      <div class="modal-content d-flex flex-column">
        <div
          class="custom-modal-header d-flex align-items-center justify-content-between"
          style={{ paddingBottom: "0px" }}
        >
          <h5 class="mb-0 text-capitalize" style={{ marginLeft: "30px", lineHeight: "1.2" }}>
            {props?.log ? (
              <span className="mb-3"> Log Id : {props.tableData?.logId ? props.tableData?.logId : ""}</span>
            ) : (
              <span className="mb-3"> Request Id : {props.tableData?.orderId ? props.tableData?.orderId : ""}</span>
            )}
            <br />
            <span className="gray-color-text gray-color-text-xl mt-3">
              {props.tableData?.name ? props.tableData?.name : ""}
            </span>
            <br />
            <span className="gray-color-text gray-color-text-xl mt-3">
              {props.tableData?.email ? props.tableData?.email : ""}
            </span>
            <br />
            <span className="gray-color-text gray-color-text-xl mt-3">
              {props?.tableData?.createdAt ? moment(props?.tableData?.createdAt).format("MMM-DD-YYYY") : ""}
            </span>
          </h5>
          <button
            type="button"
            class="close-btn d-flex align-items-center justify-content-center"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => props.onHide()}
          >
            <img src={close} alt="" />
          </button>
        </div>
        <Modal.Body className="custom-modal-body flex-grow-1 w-100 overflow-hidden">
          <div class="table-container">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12">
                  <div class="selected-table-row d-flex justify-content-between"></div>
                  <div
                    class={`table-responsive position-relative ${
                      props.tableData && props.tableData?.logs?.length > 0 ? "min-user-table-height" : ""
                    }`}
                  >
                    <div class="table-selected-count d-none align-items-center justify-content-between">
                      <div class="tsc-check-label d-flex align-items-center">
                        <div class="tsc-checkbox">
                          <div class="form-check tick-checkbox">
                            <input class="form-check-input" type="checkbox" value="" id="checkAll" />
                            <label class="form-check-label" for="checkAll"></label>
                          </div>
                        </div>
                        <div class="link-label fw-medium">2 Selected</div>
                        <div class="link-clear fw-medium">Clear all</div>
                      </div>
                    </div>
                    <div class="table-responsive table-custom">
                      {props?.isProcurement ? (
                        <table class="table table-bordered">
                          <thead>
                            <tr>
                              <th>
                                <a className={`sort-by`}> Part Number </a>{" "}
                              </th>

                              <th>
                                <a className={`sort-by`}>Condition </a>{" "}
                              </th>
                              <th>
                                <span className={`d-flex justify-content-end`}>QTY </span>
                              </th>
                              <th>
                                <span>Date </span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {props.loading
                              ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2, 3, 4].map((item) => (
                                  <tr>
                                    <td>
                                      <Skeleton />
                                    </td>
                                    <td>
                                      <Skeleton />
                                    </td>
                                    <td>
                                      <Skeleton />
                                    </td>
                                    <td>
                                      <Skeleton />
                                    </td>
                                  </tr>
                                ))
                              : props.tableData?.genericParts &&
                                props.tableData?.genericParts?.length > 0 &&
                                props.tableData?.genericParts?.map((item) => {
                                  return (
                                    <tr>
                                      <td style={{ minWidth: "130px" }}>
                                        <span className="aviation-auction ">
                                          {" "}
                                          {item?.partNumber ? item?.partNumber : ""}
                                        </span>
                                      </td>

                                      <td> {item?.condition ? item?.condition : ""}</td>
                                      <td align="right"> {item?.quantity ? numberFormat(item?.quantity) : ""}</td>
                                      <td>
                                        {props?.tableData?.createdAt
                                          ? moment(props?.tableData?.createdAt).format("MMM-DD-YYYY")
                                          : ""}
                                      </td>
                                    </tr>
                                  );
                                })}
                          </tbody>
                        </table>
                      ) : (
                        <table class="table table-bordered">
                          <thead>
                            <tr>
                              <th>
                                <a className={`sort-by`}> Part Number </a>{" "}
                              </th>
                              <th>
                                <a className={`sort-by`}> Part Description </a>{" "}
                              </th>
                              <th>
                                <a className={`sort-by`}>Condition </a>{" "}
                              </th>
                              <th>
                                <span className={`d-flex justify-content-end`}>QTY </span>
                              </th>
                              <th>
                                <span className={`d-flex justify-content-end`}>
                                  Original Unit
                                  <br /> Price{" "}
                                </span>
                              </th>
                              <th>
                                <span className={`d-flex justify-content-end`}>
                                  Public Unit
                                  <br /> Price{" "}
                                </span>
                              </th>
                              <th>
                                <a className={`sort-by`}>Location </a>
                              </th>
                              <th>
                                <a className={`sort-by`}>Part Manufacturer</a>{" "}
                              </th>
                              <th>
                                <a className={`sort-by`}>Part Model</a>{" "}
                              </th>

                              <th>
                                <a className="sort-by"> Images </a>{" "}
                              </th>
                              <th>
                                <a className="sort-by"> Listing ID </a>{" "}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {props.loading ? (
                              [1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2, 3, 4].map((item) => (
                                <tr>
                                  <td>
                                    <Skeleton />
                                  </td>
                                  <td>
                                    <Skeleton />
                                  </td>
                                  <td>
                                    <Skeleton />
                                  </td>
                                  <td>
                                    <Skeleton />
                                  </td>
                                  <td>
                                    <Skeleton />
                                  </td>
                                  <td>
                                    <Skeleton />
                                  </td>
                                  <td>
                                    <Skeleton />
                                  </td>
                                  <td>
                                    <Skeleton />
                                  </td>
                                  <td>
                                    <Skeleton />
                                  </td>
                                  <td>
                                    <Skeleton />
                                  </td>
                                  <td>
                                    <Skeleton />
                                  </td>
                                </tr>
                              ))
                            ) : props?.orderRequest ? (
                              <tr>
                                <td style={{ minWidth: "130px" }}>
                                  <span
                                    className="aviation-auction link-item"
                                    onClick={() => {
                                      props.setSelectedValue(props?.tableData);
                                      props.toggleViewAllModal();
                                    }}
                                  >
                                    {" "}
                                    {props?.tableData?.genericPart?.partNumber
                                      ? props?.tableData?.genericPart?.partNumber
                                      : ""}
                                  </span>
                                </td>
                                <td>
                                  {" "}
                                  {props?.tableData?.genericPart?.partDescription
                                    ? props?.tableData?.genericPart?.partDescription
                                    : ""}
                                </td>
                                <td>
                                  {" "}
                                  {props?.tableData?.genericPart?.condition
                                    ? `(${props?.tableData?.genericPart?.condition}) ${props?.tableData?.genericPart?.conditionTitle}`
                                    : ""}
                                </td>
                                <td align="right">
                                  {" "}
                                  {props?.tableData?.genericPart?.quantity
                                    ? numberFormat(props?.tableData?.genericPart?.quantity)
                                    : ""}
                                </td>
                                <td align="right">
                                  {" "}
                                  {props?.tableData?.genericPart?.unitPrice
                                    ? twoDecimals(props?.tableData?.genericPart?.unitPrice)
                                    : ""}
                                </td>
                                <td align="right">
                                  {" "}
                                  {props?.tableData?.genericPart?.unitPrice
                                    ? twoDecimals(props?.tableData?.genericPart?.unitPrice * 1.2)
                                    : ""}
                                </td>
                                <td>
                                  {" "}
                                  {props?.tableData?.genericPart?.location
                                    ? props?.tableData?.genericPart?.location
                                    : ""}
                                </td>

                                <td>
                                  {" "}
                                  {props?.tableData?.genericPart?.partManufacturer
                                    ? props?.tableData?.genericPart?.partManufacturer
                                    : ""}
                                </td>
                                <td>
                                  {" "}
                                  {props?.tableData?.genericPart?.partModels?.length > 0
                                    ? props?.tableData?.genericPart?.partModels?.map((val) => val).join(", ")
                                    : props?.tableData?.genericPart?.partModels?.length != 0
                                    ? props?.tableData?.genericPart?.partModels
                                    : ""}
                                </td>

                                <td
                                  align="center"
                                  className={`aviation-auction ${
                                    props?.tableData?.genericPart?.images?.length > 0 ? "link-item" : ""
                                  } `}
                                  onClick={() => {
                                    if (props?.tableData?.genericPart?.images?.length > 0) {
                                      const a = [];
                                      props?.tableData?.genericPart?.images?.map((de) => {
                                        props?.checkIfImageExists(
                                          `https://s3.amazonaws.com/uploads-dev.salvex.com/public/parts/images/${props?.tableData?.genericPart?.auctionId}/${de}`,
                                          (exists) => {
                                            if (exists) {
                                              a.push({
                                                original: `https://s3.amazonaws.com/uploads-dev.salvex.com/public/parts/images/${props?.tableData?.genericPart?.auctionId}/${de}`,
                                                thumbnail: `https://s3.amazonaws.com/uploads-dev.salvex.com/public/parts/images/${props?.tableData?.genericPart?.auctionId}/${de}`,
                                              });
                                            } else {
                                              a.push({
                                                original:
                                                  "https://media.salvex.com/auction/p/1830012/183001141_641791_mp.jpg",
                                                thumbnail:
                                                  "https://media.salvex.com/auction/p/1830012/183001141_641791_mp.jpg",
                                              });
                                            }
                                          },
                                        );
                                      });
                                      props?.setSelecetedImage(a);
                                      setTimeout(() => {
                                        props?.toggleImageGalleryModal();
                                      }, 700);
                                    }
                                  }}
                                >
                                  {props?.tableData?.genericPart?.images?.length > 0 ? (
                                    <img src={imageFill} alt="Image Exists" />
                                  ) : (
                                    <img src={imageOutLine} alt="Image not exists" />
                                  )}
                                </td>
                                <td>
                                  {props?.tableData?.genericPart?.auctionId ? (
                                    <a
                                      className="aviation-auction link-item"
                                      href={`/listing-detail?id=${props?.tableData?.genericPart?.auctionId}`}
                                      target="_blank"
                                    >
                                      {props?.tableData?.genericPart?.auctionId}
                                    </a>
                                  ) : (
                                    ""
                                  )}
                                </td>
                              </tr>
                            ) : (
                              props.data &&
                              props.data?.length > 0 &&
                              props.data?.map((item) => {
                                return (
                                  <tr>
                                    <td style={{ minWidth: "130px" }}>
                                      <span
                                        className="aviation-auction link-item"
                                        onClick={() => {
                                          props.setSelectedValue(item);
                                          props.toggleViewAllModal();
                                        }}
                                      >
                                        {" "}
                                        {item?.genericPart?.partNumber ? item?.genericPart?.partNumber : ""}
                                      </span>
                                    </td>
                                    <td>
                                      {" "}
                                      {item?.genericPart?.partDescription ? item?.genericPart?.partDescription : ""}
                                    </td>
                                    <td>
                                      {" "}
                                      {item?.genericPart?.condition
                                        ? `(${item?.genericPart?.condition}) ${item?.genericPart?.conditionTitle}`
                                        : ""}
                                    </td>
                                    <td align="right">
                                      {" "}
                                      {item?.genericPart?.quantity ? numberFormat(item?.genericPart?.quantity) : ""}
                                    </td>
                                    <td align="right">
                                      {" "}
                                      {item?.genericPart?.unitPrice ? twoDecimals(item?.genericPart?.unitPrice) : ""}
                                    </td>
                                    <td align="right">
                                      {" "}
                                      {item?.genericPart?.unitPrice
                                        ? twoDecimals(item?.genericPart?.unitPrice * 1.2)
                                        : ""}
                                    </td>
                                    <td> {item?.genericPart?.location ? item?.genericPart?.location : ""}</td>

                                    <td>
                                      {" "}
                                      {item?.genericPart?.partManufacturer ? item?.genericPart?.partManufacturer : ""}
                                    </td>
                                    <td>
                                      {" "}
                                      {item?.genericPart?.partModels?.length > 0
                                        ? item?.genericPart?.partModels?.map((val) => val).join(", ")
                                        : item?.genericPart?.partModels?.length != 0
                                        ? item?.genericPart?.partModels
                                        : ""}
                                    </td>

                                    <td
                                      align="center"
                                      className={`aviation-auction ${
                                        item?.genericPart?.images?.length > 0 ? "link-item" : ""
                                      } `}
                                      onClick={() => {
                                        if (item?.genericPart?.images?.length > 0) {
                                          const a = [];
                                          item?.genericPart?.images?.map((de) => {
                                            props?.checkIfImageExists(
                                              `https://s3.amazonaws.com/uploads-dev.salvex.com/public/parts/images/${item?.genericPart?.auctionId}/${de}`,
                                              (exists) => {
                                                if (exists) {
                                                  a.push({
                                                    original: `https://s3.amazonaws.com/uploads-dev.salvex.com/public/parts/images/${item?.genericPart?.auctionId}/${de}`,
                                                    thumbnail: `https://s3.amazonaws.com/uploads-dev.salvex.com/public/parts/images/${item?.genericPart?.auctionId}/${de}`,
                                                  });
                                                } else {
                                                  a.push({
                                                    original:
                                                      "https://media.salvex.com/auction/p/1830012/183001141_641791_mp.jpg",
                                                    thumbnail:
                                                      "https://media.salvex.com/auction/p/1830012/183001141_641791_mp.jpg",
                                                  });
                                                }
                                              },
                                            );
                                          });
                                          props?.setSelecetedImage(a);
                                          setTimeout(() => {
                                            props?.toggleImageGalleryModal();
                                          }, 700);
                                        }
                                      }}
                                    >
                                      {item?.genericPart?.images?.length > 0 ? (
                                        <img src={imageFill} alt="Image Exists" />
                                      ) : (
                                        <img src={imageOutLine} alt="Image not exists" />
                                      )}
                                    </td>
                                    <td>
                                      {item?.genericPart.auctionId ? (
                                        <a
                                          className="aviation-auction link-item"
                                          href={`/listing-detail?id=${item?.genericPart.auctionId}`}
                                          target="_blank"
                                        >
                                          {item?.genericPart?.auctionId}
                                        </a>
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                  </tr>
                                );
                              })
                            )}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                  {!props.loading && props.data?.length === 0 && (
                    <>
                      <div class="table-empty">
                        <div class="table-empty-inner">
                          <img src={emptyFolder} alt="" />
                          <p>No Items Found</p>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default LogModal;
