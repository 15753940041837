import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import StrategicTeamModal from "../../../layouts/modals/strategicTeamModal";
import {
  getInspector,
  getMember,
  getMethodOrigin,
  getOptimizer,
  getOutsideAgent,
  getRecommendedView,
  getStrategicTeam,
  getStrategicTeamDetails,
  updateStrategicTeamDetails,
} from "../../../store/strategicTeam";
import { toast } from "react-toastify";
import AlertError from "../../../common/alerts/alertError";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import { twoDecimals } from "../../../utils.js/conversion";
import { getProfile } from "../../../utils.js/localStorageServices";
import { getModals, setStrategicModal } from "../../../store/modal";
import LoadingBar from "react-top-loading-bar";

const reviewOptions = [
  { name: "Pending Review", value: 1 },

  { name: "Submitted", vlaue: 2 },
  { name: "Approved", value: 3 },
];

const StrategicTeam = (props) => {
  const ref = useRef(null);
  const [loading, setLoading] = useState(false);
  const [detail, setDetail] = useState({
    mm_id: null,
    int_ref: null,
    author: null,
    author_name: "",
    method_origin: "",
    individual_origin: null,
    individual_origin_name: "",
    bc_id: null,
    bc_id_name: "",
    buyer_commission: null,
    sc_id: null,
    sc_id_name: "",
    seller_commission: null,
    dc_id: null,
    dc_id_name: "",
    deal_commission: null,
    dc_id_1: null,
    dc_id_1_name: "",
    deal_commission_1: null,
    dc_id_2: null,
    dc_id_2_name: "",
    deal_commission_2: null,
    dc_id_3: null,
    dc_id_3_name: "",
    deal_commission_3: null,
    mgr_id: null,
    mgr_name: "",
    mgr_commission: null,
    admin_id: null,
    admin_name: "",
    admin_commission: null,
    outside_agent: null,
    outside_agent_name: "",
    outside_agent_commission: null,
    inspector: null,
    inspector_name: "",
    inspector_commission: null,
    dc_id_4: null,
    dc_id_4_name: "",
    deal_commission_4: null,
    profit: null,
    auction_recommend: [],
    db_pool_1: false,
    db_pool_2: false,
    db_pool_3: false,
    db_pool_4: false,
    db_pool_5: false,
    db_pool_6: false,
    db_pool_7: false,
    db_pool_8: false,
    db_pool_9: false,
    db_pool_10: false,
    commission_status: 1,
  });

  const showStrategicModal = props?.startegicModal;

  const toggleStrategicModal = () => {
    props?.setShowStrategicModal(!showStrategicModal);
  };

  useEffect(() => {
    if (showStrategicModal && props.getStrategicTeam.methodOrigin && !props.getStrategicTeam.methodOrigin.data) {
      props.getMethodOrigin((res) => {
        if (res.status === 200) {
        } else {
          toast(
            <AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />,
          );
        }
      });
    }
    if (showStrategicModal && props.getStrategicTeam.methodOrigin && !props.getStrategicTeam.member.data) {
      props.getMember((res) => {
        if (res.status === 200) {
        } else {
          toast(
            <AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />,
          );
        }
      });
    }
    if (showStrategicModal && props.getStrategicTeam.inspector && !props.getStrategicTeam.inspector.data) {
      props.getInspector((res) => {
        if (res.status === 200) {
        } else {
          toast(
            <AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />,
          );
        }
      });
    }
    if (showStrategicModal && props.getStrategicTeam.outsideAgent && !props.getStrategicTeam.outsideAgent.data) {
      props.getOutsideAgent((res) => {
        if (res.status === 200) {
        } else {
          toast(
            <AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />,
          );
        }
      });
    }
    if (showStrategicModal && props.getStrategicTeam.recommendedView && !props.getStrategicTeam.recommendedView.data) {
      props.getRecommendView((res) => {
        if (res.status === 200) {
        } else {
          toast(
            <AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />,
          );
        }
      });
    }

    if (showStrategicModal && props.getStrategicTeam.optimizer && !props.getStrategicTeam.optimizer.data) {
      props.getOptimizer((res) => {
        if (res.status === 200) {
        } else {
          toast(
            <AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />,
          );
        }
      });
    }
  }, [showStrategicModal]);

  const teamDetails =
    props.getStrategicTeam && props.getStrategicTeam.strategicTeam && props.getStrategicTeam.strategicTeam.detail;

  const methodOrigindata =
    props.getStrategicTeam && props.getStrategicTeam.methodOrigin && props.getStrategicTeam.methodOrigin.data;

  const memberData = props.getStrategicTeam && props.getStrategicTeam.member && props.getStrategicTeam.member.data;

  const inspectorData =
    props.getStrategicTeam && props.getStrategicTeam.inspector && props.getStrategicTeam.inspector.data;

  const outsideAgentData =
    props.getStrategicTeam && props.getStrategicTeam.outsideAgent && props.getStrategicTeam.outsideAgent.data;

  const recommendedViewData =
    props.getStrategicTeam && props.getStrategicTeam.recommendedView && props.getStrategicTeam.recommendedView.data;

  const optimizerData =
    props.getStrategicTeam && props.getStrategicTeam.optimizer && props.getStrategicTeam.optimizer.data;

  const handleSubmit = () => {
    const id = props.auctionId;
    ref?.current?.continuousStart();
    setLoading(true);
    const payload = {
      author: !_.isEmpty(detail.author_name) && detail.author_name.value ? detail.author_name.value : 0,
      sc_id: !_.isEmpty(detail.sc_id_name) && detail.sc_id_name.value ? detail.sc_id_name.value : 0,
      seller_commission: detail.seller_commission ? detail.seller_commission : 0,
      bc_id: !_.isEmpty(detail.bc_id_name) && detail.bc_id_name.value ? detail.bc_id_name.value : 0,
      buyer_commission: detail.buyer_commission ? detail.buyer_commission : 0,
      dc_id: !_.isEmpty(detail.dc_id_name) && detail.dc_id_name.value ? detail.dc_id_name.value : 0,
      deal_commission: detail.deal_commission ? detail.deal_commission : 0,
      dc_id_1: !_.isEmpty(detail.dc_id_1_name) && detail.dc_id_1_name.value ? detail.dc_id_1_name.value : 0,
      deal_commission_1: detail.deal_commission_1 ? detail.deal_commission_1 : 0,
      dc_id_2: !_.isEmpty(detail.dc_id_2_name) && detail.dc_id_2_name.value ? detail.dc_id_2_name.value : 0,
      deal_commission_2: detail.deal_commission_2 ? detail.deal_commission_2 : 0,
      dc_id_3: !_.isEmpty(detail.dc_id_3_name) && detail.dc_id_3_name.value ? detail.dc_id_3_name.value : 0,
      deal_commission_3: detail.deal_commission_3 ? detail.deal_commission_3 : 0,
      // dc_id_4: detail.dc_id_4_name ? detail.dc_id_4_name : 0,
      dc_id_4: 0,
      deal_commission_4: detail.deal_commission_4 ? detail.deal_commission_4 : 0,
      outside_agent:
        !_.isEmpty(detail.outside_agent_name) && detail.outside_agent_name.value ? detail.outside_agent_name.value : 0,
      outside_agent_commission: detail.outside_agent_commission ? detail.outside_agent_commission : 0,
      inspector: !_.isEmpty(detail.inspector_name) && detail.inspector_name.value ? detail.inspector_name.value : 0,
      inspector_commission: detail.inspector_commission ? detail.inspector_commission : 0,
      mgr_id: !_.isEmpty(detail.mgr_name) && detail.mgr_name.value ? detail.mgr_name.value : 0,
      mgr_commission: detail.mgr_commission ? detail.mgr_commission : 0,
      admin_id: !_.isEmpty(detail.admin_name) && detail.admin_name.value ? detail.admin_name.value : 0,
      admin_commission: detail.admin_commission ? detail.admin_commission : 0,
      method_origin: !_.isEmpty(detail.method_origin) && detail.method_origin.name ? detail.method_origin.name : "",
      individual_origin:
        !_.isEmpty(detail.individual_origin_name) && detail.individual_origin_name.value
          ? detail.individual_origin_name.value
          : 0,
      recommend_id:
        detail.auction_recommend && detail.auction_recommend.length > 0
          ? detail.auction_recommend.map((item) => item.value)
          : [],
      db_pool_1:
        detail.db_pool_1 && !_.isEmpty(detail.individual_origin_name) && detail.individual_origin_name.value
          ? `u|individual_origin|${detail.individual_origin_name.value}`
          : "",
      db_pool_2:
        detail.db_pool_2 && !_.isEmpty(detail.bc_id_name) && detail.bc_id_name.value
          ? `m|bc_id|${detail.bc_id_name.value}`
          : "",
      db_pool_3:
        detail.db_pool_3 && !_.isEmpty(detail.sc_id_name) && detail.sc_id_name.value
          ? `m|sc_id|${detail.sc_id_name.value}`
          : "",
      db_pool_4:
        detail.db_pool_4 && !_.isEmpty(detail.dc_id_name) && detail.dc_id_name.value
          ? `m|dc_id|${detail.dc_id_name.value}`
          : "",
      db_pool_5:
        detail.db_pool_5 && !_.isEmpty(detail.dc_id_1_name) && detail.dc_id_1_name.value
          ? `m|dc_id_1|${detail.dc_id_1_name.value}`
          : "",
      db_pool_6:
        detail.db_pool_6 && !_.isEmpty(detail.dc_id_2_name) && detail.dc_id_2_name.value
          ? `m|dc_id_2|${detail.dc_id_2_name.value}`
          : "",
      db_pool_7:
        detail.db_pool_7 && !_.isEmpty(detail.dc_id_3_name) && detail.dc_id_3_name.value
          ? `m|dc_id_3|${detail.dc_id_3_name.value}`
          : "",
      db_pool_8:
        detail.db_pool_8 && !_.isEmpty(detail.mgr_name) && detail.mgr_name.value
          ? `u|mgr_id|${detail.mgr_name.value}`
          : "",
      db_pool_9:
        detail.db_pool_9 && !_.isEmpty(detail.admin_name) && detail.admin_name.value
          ? `u|admin_id|${detail.admin_name.value}`
          : "",
      db_pool_10:
        detail.db_pool_10 && !_.isEmpty(detail.outside_agent_name) && detail.outside_agent_name.value
          ? `u|outside_agent|${detail.outside_agent_name.value}`
          : "",
      commission_status:
        !_.isEmpty(detail.commission_status) && detail.commission_status.value ? detail.commission_status.value : 1,
    };
    props.updateStrategicTeamDetails(id, payload, (res) => {
      if (res.status === 200) {
        props.getStrategicTeamDetails(id, (res) => {
          if (res.status === 200) {
            setLoading(false);
            ref?.current?.complete();
            toggleStrategicModal();
            toast(<AlertSuccess message={"Information Saved"} />);
          } else {
            setLoading(false);
            ref?.current?.complete();
            toast(
              <AlertError message={res && res.data && res.data.message ? res.data.message : "Something went wrong"} />,
            );
          }
        });
      } else {
        setLoading(false);
        ref?.current?.complete();
        toast(<AlertError message={res && res.data && res.data.message ? res.data.message : "Something went wrong"} />);
      }
    });
  };

  useEffect(() => {
    if (showStrategicModal) {
      const data = { ...detail };

      let a = [];

      recommendedViewData &&
        teamDetails.auction_recommend.length &&
        recommendedViewData.forEach((element) => {
          if (teamDetails.auction_recommend.map((item) => item.recommend_id).includes(element.user_id)) {
            a.push({ name: element.name01, value: element.user_id });
          }
        });
      data.author_name = teamDetails.author_name
        ? {
            name: teamDetails.author_name,
            value: teamDetails.author,
          }
        : "";
      data.method_origin = teamDetails.method_origin
        ? {
            name: teamDetails.method_origin,
            value: teamDetails.method_origin,
          }
        : "";
      data.individual_origin_name = teamDetails.individual_origin_name
        ? {
            name: teamDetails.individual_origin_name,
            value: teamDetails.individual_origin,
          }
        : "";
      data.bc_id_name = teamDetails.bc_id_name
        ? {
            name: teamDetails.bc_id_name,
            value: teamDetails.bc_id,
          }
        : "";
      data.sc_id_name = teamDetails.sc_id_name
        ? {
            name: teamDetails.sc_id_name,
            value: teamDetails.sc_id,
          }
        : "";
      data.dc_id_name = teamDetails.dc_id_name
        ? {
            name: teamDetails.dc_id_name,
            value: teamDetails.dc_id,
          }
        : "";
      data.dc_id_1_name = teamDetails.dc_id_1_name
        ? {
            name: teamDetails.dc_id_1_name,
            value: teamDetails.dc_id_1,
          }
        : "";
      data.dc_id_2_name = teamDetails.dc_id_2_name
        ? {
            name: teamDetails.dc_id_2_name,
            value: teamDetails.dc_id_2,
          }
        : "";
      data.dc_id_3_name = teamDetails.dc_id_3_name
        ? {
            name: teamDetails.dc_id_3_name,
            value: teamDetails.dc_id_3,
          }
        : "";
      data.dc_id_4_name = teamDetails.dc_id_4_name ? teamDetails.dc_id_4_name : "";
      data.mgr_name = teamDetails.mgr_name
        ? {
            name: teamDetails.mgr_name,
            value: teamDetails.mgr_id,
          }
        : "";
      data.admin_name = teamDetails.admin_name
        ? {
            name: teamDetails.admin_name,
            value: teamDetails.admin_id,
          }
        : "";
      data.outside_agent_name = teamDetails.outside_agent_name
        ? {
            name: teamDetails.outside_agent_name,
            value: teamDetails.outside_agent,
          }
        : "";
      data.inspector_name = teamDetails.inspector_name
        ? {
            name: teamDetails.inspector_name,
            value: teamDetails.inspector,
          }
        : "";
      data.auction_recommend = a && a.length ? a : [];
      data.commission_status = teamDetails?.commission_status
        ? reviewOptions?.filter((ir) => ir.value == teamDetails?.commission_status)
        : { name: "Pending", value: 1 };
      data.db_pool_1 = teamDetails.db_pool_1;
      data.db_pool_2 = teamDetails.db_pool_2;
      data.db_pool_3 = teamDetails.db_pool_3;
      data.db_pool_4 = teamDetails.db_pool_4;
      data.db_pool_5 = teamDetails.db_pool_5;
      data.db_pool_6 = teamDetails.db_pool_6;
      data.db_pool_7 = teamDetails.db_pool_7;
      data.db_pool_8 = teamDetails.db_pool_8;
      data.db_pool_9 = teamDetails.db_pool_9;
      data.db_pool_10 = teamDetails.db_pool_10;
      data.buyer_commission = teamDetails.buyer_commission
        ? parseFloat(teamDetails.buyer_commission * 100).toFixed(1)
        : parseFloat(0).toFixed(1);
      data.seller_commission = teamDetails.seller_commission
        ? parseFloat(teamDetails.seller_commission * 100).toFixed(1)
        : parseFloat(0).toFixed(1);
      data.deal_commission = teamDetails.deal_commission
        ? parseFloat(teamDetails.deal_commission * 100).toFixed(1)
        : parseFloat(0).toFixed(1);
      data.deal_commission_1 = teamDetails.deal_commission_1
        ? parseFloat(teamDetails.deal_commission_1 * 100).toFixed(1)
        : parseFloat(0).toFixed(1);
      data.deal_commission_2 = teamDetails.deal_commission_2
        ? parseFloat(teamDetails.deal_commission_2 * 100).toFixed(1)
        : parseFloat(0).toFixed(1);
      data.deal_commission_3 = teamDetails.deal_commission_3
        ? parseFloat(teamDetails.deal_commission_3 * 100).toFixed(1)
        : parseFloat(0).toFixed(1);
      data.mgr_commission = teamDetails.mgr_commission
        ? parseFloat(teamDetails.mgr_commission * 100).toFixed(1)
        : parseFloat(0).toFixed(1);
      data.admin_commission = teamDetails.admin_commission
        ? parseFloat(teamDetails.admin_commission * 100).toFixed(1)
        : parseFloat(0).toFixed(1);
      data.outside_agent_commission = teamDetails.outside_agent_commission
        ? parseFloat(teamDetails.outside_agent_commission * 100).toFixed(1)
        : parseFloat(0).toFixed(1);
      data.inspector_commission = teamDetails.inspector_commission
        ? parseFloat(teamDetails.inspector_commission * 100).toFixed(1)
        : parseFloat(0).toFixed(1);
      data.deal_commission_4 = teamDetails.deal_commission_4
        ? parseFloat(teamDetails.deal_commission_4 * 100).toFixed(1)
        : parseFloat(0).toFixed(1);
      setDetail(data);
    }
  }, [showStrategicModal]);

  const session = getProfile();
  return (
    <>
      <LoadingBar height={5} color="#47AD1D" ref={ref} />
      {/* <div class="page-widget-head d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <div
            class="page-widget-label text-capitalize cursor-pointer"
            onClick={() => !props.loadingMain && toggleStrategicModal()}
          >
            <span>Strategic Team</span>

            <div className="badge-box startegic-team-badge">
              Commissions Status:
              <span
                style={{
                  color: teamDetails?.commission_status === 3 && "#41ad1d",
                }}
              >
                {teamDetails?.commission_status === 2
                  ? " Submitted"
                  : teamDetails?.commission_status === 3
                  ? " Approved"
                  : " Pending Review"}
              </span>
            </div>
           
          </div>
        </div>
      </div> */}

      {session?.accessLevels?.accounting_payment && (
        <div class="col-row-body mt-3">
          <div class="d-flex align-items-center space-between mb-4">
            <div className="badge-box startegic-team-badge ">
              Commissions Status:
              <span
                style={{
                  color: teamDetails?.commission_status === 3 && "#41ad1d",
                }}
              >
                {teamDetails?.commission_status === 2
                  ? " Submitted"
                  : teamDetails?.commission_status === 3
                  ? " Approved"
                  : " Pending Review"}
              </span>
            </div>
            <a
              href="javascript:void(0)"
              class="link-btn add-more margin-right-click"
              onClick={() => !props.loadingMain && toggleStrategicModal()}
            >
              Click here to Edit
            </a>
          </div>

          <div class="col-row-table-box pt-0 pl-pr-0">
            {props.loadingMain ? (
              <>
                <div class="table-responsive">
                  <table class="table mb-0 align-middle product-table strategic-team-table">
                    <colgroup>
                      <col style={{ width: "30%" }} />
                      <col style={{ width: "50%" }} />
                      <col style={{ width: "20%" }} />
                    </colgroup>
                    <tbody>
                      <tr>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </>
            ) : teamDetails &&
              // teamDetails.method_origin ||
              // teamDetails.author_name ||
              // teamDetails.individual_origin ||
              (teamDetails.bc_id ||
                teamDetails.sc_id ||
                teamDetails.dc_id ||
                teamDetails.dc_id_1 ||
                teamDetails.dc_id_2 ||
                teamDetails.dc_id_3 ||
                teamDetails.mgr_id ||
                teamDetails.admin_id ||
                teamDetails.outsideAgent ||
                teamDetails.inspector) ? (
              // || teamDetails.auction_recommend.length
              <div class="table-responsive">
                <table class="table mb-0 align-middle product-table">
                  <colgroup>
                    <col style={{ width: "30%" }} />
                    <col style={{ width: "30%" }} />
                    <col style={{ width: "40%" }} />
                  </colgroup>
                  {/* <thead>
                    <th></th>
                    <th></th>
                    <th>BD Pool</th>
                  </thead> */}
                  <tbody>
                    <>
                      {teamDetails && teamDetails.author_name && (
                        <tr>
                          <td>Created By</td>
                          <td>
                            <div>
                              <span className="name-bold">
                                {teamDetails && teamDetails.author_name ? teamDetails.author_name : "N/A"}
                              </span>
                              <br />
                              <div>
                                {teamDetails?.memberOrgs?.hasOwnProperty(teamDetails?.author_name) && (
                                  <em>{teamDetails?.memberOrgs[teamDetails?.author_name]}</em>
                                )}
                              </div>
                            </div>
                          </td>
                          <td></td>
                        </tr>
                      )}
                      {teamDetails && teamDetails.method_origin ? (
                        <tr>
                          <td>Method Origin</td>
                          <td>
                            <span className="name-bold">
                              {teamDetails && teamDetails.method_origin ? teamDetails.method_origin : "N/A"}
                            </span>
                          </td>
                          <td></td>
                        </tr>
                      ) : (
                        ""
                      )}
                      {teamDetails && teamDetails.individual_origin_name ? (
                        <tr>
                          <td>Individual Origin</td>
                          <td>
                            <span className="name-bold">
                              {teamDetails && teamDetails.individual_origin_name
                                ? teamDetails.individual_origin_name
                                : "N/A"}
                            </span>
                          </td>
                          <td></td>
                        </tr>
                      ) : (
                        ""
                      )}
                      {teamDetails && teamDetails.d.comm_member_1 ? (
                        <tr>
                          <td>Trader</td>
                          <td>
                            <div>
                              <span className="name-bold">
                                {teamDetails && teamDetails.d.comm_member_1 ? teamDetails.d.comm_member_1 : "N/A"}
                              </span>
                              <br />
                              {/* <span>
                                {teamDetails?.memberOrgs?.hasOwnProperty(teamDetails?.d.comm_member_1) && (
                                  <em>{teamDetails?.memberOrgs[teamDetails?.d.comm_member_1]}</em>
                                )}
                              </span> */}
                            </div>
                          </td>
                          {session?.super_admin ||
                          (teamDetails?.d?.bc_id === session?.member_id && teamDetails?.d?.isinvoiced > 0) ? (
                            <td className="commission-container">
                              {teamDetails && (
                                <>
                                  <span>
                                    {teamDetails.db_pool_2 && teamDetails.bdpoolpercent
                                      ? twoDecimals(+teamDetails.bdpoolpercent) + "% "
                                      : "0% "}
                                  </span>
                                  <span>+ </span>
                                  <span>
                                    {teamDetails.buyer_commission
                                      ? parseFloat(teamDetails.buyer_commission * 100).toFixed(2) + "% "
                                      : "0% "}
                                  </span>
                                  <span>=</span>
                                  <span>
                                    {teamDetails.buyer_commission && teamDetails.profit
                                      ? "$" + twoDecimals(teamDetails.profit * teamDetails.buyer_commission)
                                      : "$0.00"}
                                  </span>
                                </>
                              )}
                            </td>
                          ) : (
                            <td></td>
                          )}
                        </tr>
                      ) : (
                        ""
                      )}
                      {teamDetails && teamDetails.d.comm_member_2 ? (
                        <tr>
                          <td>Business Development</td>
                          <td>
                            <div>
                              <span className="name-bold">
                                {teamDetails && teamDetails.d.comm_member_2 ? teamDetails.d.comm_member_2 : "N/A"}
                              </span>
                              <br />
                              {/* <span>
                                {teamDetails?.memberOrgs?.hasOwnProperty(teamDetails?.d.comm_member_2) && (
                                  <em>{teamDetails?.memberOrgs[teamDetails?.d.comm_member_2]}</em>
                                )}
                              </span> */}
                            </div>
                          </td>
                          {session?.super_admin ||
                          (teamDetails?.d?.sc_id === session?.member_id && teamDetails?.d?.isinvoiced > 0) ? (
                            <td className="commission-container">
                              {teamDetails && (
                                <>
                                  <span>
                                    {teamDetails.db_pool_3 && teamDetails.bdpoolpercent
                                      ? twoDecimals(+teamDetails.bdpoolpercent) + "% "
                                      : "0% "}
                                  </span>
                                  <span>+ </span>
                                  <span>
                                    {teamDetails.seller_commission
                                      ? parseFloat(teamDetails.seller_commission * 100).toFixed(2) + "% "
                                      : "0% "}
                                  </span>
                                  <span>= </span>
                                  <span>
                                    {teamDetails.seller_commission && teamDetails.profit
                                      ? "$" + twoDecimals(teamDetails.profit * teamDetails.seller_commission)
                                      : "$0.00"}
                                  </span>
                                </>
                              )}
                            </td>
                          ) : (
                            <td></td>
                          )}
                        </tr>
                      ) : (
                        ""
                      )}
                      {teamDetails && teamDetails.d.comm_member_3 ? (
                        <tr>
                          <td>Administrator 1</td>
                          <td>
                            <div>
                              <span className="name-bold">
                                {teamDetails && teamDetails.d.comm_member_3 ? teamDetails.d.comm_member_3 : "N/A"}
                              </span>
                              <br />
                              <span>
                                {teamDetails?.memberOrgs?.hasOwnProperty(teamDetails?.d.comm_member_3) && (
                                  <em>{teamDetails?.memberOrgs[teamDetails?.d.comm_member_3]}</em>
                                )}
                              </span>
                            </div>
                          </td>
                          {session?.super_admin ||
                          (teamDetails?.d?.dc_id === session?.member_id && teamDetails?.d?.isinvoiced > 0) ? (
                            <td className="commission-container">
                              {teamDetails && (
                                <>
                                  <span>
                                    {teamDetails.db_pool_4 && teamDetails.bdpoolpercent
                                      ? twoDecimals(+teamDetails.bdpoolpercent) + "% "
                                      : "0% "}
                                  </span>
                                  <span>+ </span>
                                  <span>
                                    {teamDetails.deal_commission
                                      ? parseFloat(teamDetails.deal_commission * 100).toFixed(2) + "% "
                                      : "0% "}
                                  </span>
                                  <span>= </span>
                                  <span>
                                    {teamDetails.deal_commission && teamDetails.profit
                                      ? "$" + twoDecimals(teamDetails.profit * teamDetails.deal_commission)
                                      : "$0.00"}
                                  </span>
                                </>
                              )}
                            </td>
                          ) : (
                            <td></td>
                          )}
                        </tr>
                      ) : (
                        ""
                      )}
                      {teamDetails && teamDetails.d.comm_member_4 ? (
                        <tr>
                          <td>Administrator 2</td>
                          <td>
                            <div>
                              <span className="name-bold">
                                {teamDetails && teamDetails.d.comm_member_4 ? teamDetails.d.comm_member_4 : "N/A"}
                              </span>
                              <br />
                              <span>
                                {teamDetails?.memberOrgs?.hasOwnProperty(teamDetails?.d.comm_member_4) && (
                                  <em>{teamDetails?.memberOrgs[teamDetails?.d.comm_member_4]}</em>
                                )}
                              </span>
                            </div>
                          </td>
                          {session?.super_admin ||
                          (teamDetails?.d?.dc_id_1 === session?.member_id && teamDetails?.d?.isinvoiced > 0) ? (
                            <td className="commission-container">
                              {teamDetails && (
                                <>
                                  <span>
                                    {teamDetails.db_pool_5 && teamDetails.bdpoolpercent
                                      ? twoDecimals(+teamDetails.bdpoolpercent) + "% "
                                      : "0% "}
                                  </span>
                                  <span>+ </span>
                                  <span>
                                    {teamDetails.deal_commission_1
                                      ? parseFloat(teamDetails.deal_commission_1 * 100).toFixed(2) + "% "
                                      : "0% "}
                                  </span>
                                  <span>= </span>
                                  <span>
                                    {teamDetails.deal_commission_1 && teamDetails.profit
                                      ? "$" + twoDecimals(teamDetails.profit * teamDetails.deal_commission_1)
                                      : "$0.00"}
                                  </span>
                                </>
                              )}
                            </td>
                          ) : (
                            <td></td>
                          )}
                        </tr>
                      ) : (
                        ""
                      )}
                      {teamDetails && teamDetails.d.comm_member_5 ? (
                        <tr>
                          <td>Mid Manager</td>
                          <td>
                            <div>
                              <span className="name-bold">
                                {teamDetails && teamDetails.d.comm_member_5 ? teamDetails.d.comm_member_5 : "N/A"}
                              </span>
                              <br />
                              {/* <span>
                                {teamDetails?.memberOrgs?.hasOwnProperty(teamDetails?.d.comm_member_5) && (
                                  <em>{teamDetails?.memberOrgs[teamDetails?.d.comm_member_5]}</em>
                                )}
                              </span> */}
                            </div>
                          </td>
                          {session?.super_admin ||
                          (teamDetails?.d?.dc_id_2 === session?.member_id && teamDetails?.d?.isinvoiced > 0) ? (
                            <td className="commission-container">
                              {teamDetails && (
                                <>
                                  <span>
                                    {teamDetails.db_pool_6 && teamDetails.bdpoolpercent
                                      ? twoDecimals(+teamDetails.bdpoolpercent) + "% "
                                      : "0% "}
                                  </span>
                                  <span>+ </span>
                                  <span>
                                    {teamDetails.deal_commission_2
                                      ? parseFloat(teamDetails.deal_commission_2 * 100).toFixed(2) + "% "
                                      : "0% "}
                                  </span>
                                  <span>= </span>
                                  <span>
                                    {teamDetails.deal_commission_2 && teamDetails.profit
                                      ? "$" + twoDecimals(teamDetails.profit * teamDetails.deal_commission_2)
                                      : "$0.00"}
                                  </span>
                                </>
                              )}
                            </td>
                          ) : (
                            <td></td>
                          )}
                        </tr>
                      ) : (
                        ""
                      )}
                      {teamDetails && teamDetails.d.comm_member_6 ? (
                        <tr>
                          <td>Optimizer</td>
                          <td>
                            <div>
                              <span className="name-bold">
                                {teamDetails && teamDetails.d.comm_member_6 ? teamDetails.d.comm_member_6 : "N/A"}
                              </span>
                              <br />
                              <span>
                                {teamDetails?.memberOrgs?.hasOwnProperty(teamDetails?.d.comm_member_6) && (
                                  <em>{teamDetails?.memberOrgs[teamDetails?.d.comm_member_6]}</em>
                                )}
                              </span>
                            </div>
                          </td>
                          {session?.super_admin ||
                          (teamDetails?.d?.dc_id_3 === session?.member_id && teamDetails?.d?.isinvoiced > 0) ? (
                            <td className="commission-container">
                              {teamDetails && (
                                <>
                                  <span>
                                    {teamDetails.db_pool_7 && teamDetails.bdpoolpercent
                                      ? twoDecimals(+teamDetails.bdpoolpercent) + "% "
                                      : "0% "}
                                  </span>
                                  <span>+ </span>
                                  <span>
                                    {teamDetails.deal_commission_3
                                      ? parseFloat(teamDetails.deal_commission_3 * 100).toFixed(2) + "% "
                                      : "0% "}
                                  </span>
                                  <span>= </span>
                                  <span>
                                    {teamDetails.deal_commission_3 && teamDetails.profit
                                      ? "$" + twoDecimals(teamDetails.profit * teamDetails.deal_commission_3)
                                      : "$0.00"}
                                  </span>
                                </>
                              )}
                            </td>
                          ) : (
                            <td></td>
                          )}
                        </tr>
                      ) : (
                        ""
                      )}
                      {teamDetails && teamDetails.d.outside_agent_name ? (
                        <tr>
                          <td>Outside Agent</td>
                          <td>
                            <div>
                              <span className="name-bold">
                                {teamDetails && teamDetails.d.outside_agent_name
                                  ? teamDetails.d.outside_agent_name
                                  : "N/A"}
                              </span>
                              <br />
                              <span>
                                {teamDetails?.memberOrgs?.hasOwnProperty(teamDetails?.d.outside_agent_name) && (
                                  <em>{teamDetails?.memberOrgs[teamDetails?.d.outside_agent_name]}</em>
                                )}
                              </span>
                            </div>
                          </td>
                          {session?.super_admin ||
                          (teamDetails?.d?.outside_agenet === session?.user_id && teamDetails?.d?.isinvoiced > 0) ? (
                            <td className="commission-container">
                              {teamDetails && (
                                <>
                                  <span>
                                    {teamDetails.db_pool_10 && teamDetails.bdpoolpercent
                                      ? twoDecimals(+teamDetails.bdpoolpercent) + "% "
                                      : "0% "}
                                  </span>
                                  <span>+ </span>
                                  <span>
                                    {teamDetails.outside_agent_commission
                                      ? parseFloat(teamDetails.outside_agent_commission * 100).toFixed(2) + "% "
                                      : "0% "}
                                  </span>
                                  <span>= </span>
                                  <span>
                                    {teamDetails.outside_agent_commission && teamDetails.profit
                                      ? "$" + twoDecimals(teamDetails.profit * teamDetails.outside_agent_commission)
                                      : "$0.00"}
                                  </span>
                                </>
                              )}
                            </td>
                          ) : (
                            <td></td>
                          )}
                        </tr>
                      ) : (
                        ""
                      )}

                      {/* Removed the Inspector from here and edit modal as per request(26/04/2024) */}
                      {/* {teamDetails && teamDetails.d.inspector_name ? (
                      <tr>
                        <td>Inspector</td>
                        <td>
                          <div>
                            <span className="name-bold">
                              {teamDetails && teamDetails.d.inspector_name ? teamDetails.d.inspector_name : "N/A"}
                            </span>
                            <br />
                            <span>
                              {teamDetails?.memberOrgs?.hasOwnProperty(teamDetails?.d.inspector_name) && (
                                <em>{teamDetails?.memberOrgs[teamDetails?.d.inspector_name]}</em>
                              )}
                            </span>
                          </div>
                        </td>
                        {session?.super_admin ||
                        (teamDetails?.d?.inspector === session?.user_id && teamDetails?.d?.isinvoiced > 0) ? (
                          <td className="commission-container">
                            {teamDetails && (
                              <>
                                <span>{"0%"}</span>
                                <span>+ </span>
                                <span>
                                  {teamDetails.inspector_commission
                                    ? parseFloat(teamDetails.inspector_commission * 100).toFixed(2) + "% "
                                    : "0% "}
                                </span>
                                <span>= </span>
                                <span>
                                  {teamDetails.inspector_commission && teamDetails.profit
                                    ? "$" + twoDecimals(teamDetails.profit * teamDetails.inspector_commission)
                                    : "$0.00"}
                                </span>
                              </>
                            )}
                          </td>
                        ) : (
                          <td></td>
                        )}
                      </tr>
                    ) : (
                      ""
                    )} */}
                      {teamDetails && teamDetails.d.mgr_name ? (
                        <tr>
                          <td>Trader Manager</td>
                          <td>
                            <div>
                              <span className="name-bold">
                                {teamDetails && teamDetails.d.mgr_name ? teamDetails.d.mgr_name : "N/A"}
                              </span>
                              <br />
                              {/* <span>
                                {teamDetails?.memberOrgs?.hasOwnProperty(teamDetails?.d.mgr_name) && (
                                  <em>{teamDetails?.memberOrgs[teamDetails?.d.mgr_name]}</em>
                                )}
                              </span> */}
                            </div>
                          </td>
                          {session?.super_admin ||
                          (teamDetails?.d?.mgr_id === session?.user_id && teamDetails?.d?.isinvoiced > 0) ? (
                            <td className="commission-container">
                              {teamDetails && (
                                <>
                                  <span>
                                    {teamDetails.db_pool_8 && teamDetails.bdpoolpercent
                                      ? twoDecimals(+teamDetails.bdpoolpercent) + "% "
                                      : "0% "}
                                  </span>
                                  <span>+ </span>
                                  <span>
                                    {teamDetails.mgr_commission
                                      ? parseFloat(teamDetails.mgr_commission * 100).toFixed(2) + "% "
                                      : "0% "}
                                  </span>
                                  <span>= </span>
                                  <span>
                                    {teamDetails.mgr_commission && teamDetails.profit
                                      ? "$" + twoDecimals(teamDetails.profit * teamDetails.mgr_commission)
                                      : "$0.00"}
                                  </span>
                                </>
                              )}
                            </td>
                          ) : (
                            <td></td>
                          )}
                        </tr>
                      ) : (
                        ""
                      )}
                      {teamDetails && teamDetails.d.admin_name ? (
                        <tr>
                          <td>BD Manager</td>
                          <td>
                            <div>
                              <span className="name-bold">
                                {teamDetails && teamDetails.d.admin_name ? teamDetails.d.admin_name : "N/A"}
                              </span>
                              <br />
                              {/* <span>
                                {teamDetails?.memberOrgs?.hasOwnProperty(teamDetails?.d.admin_name) && (
                                  <em>{teamDetails?.memberOrgs[teamDetails?.d.admin_name]}</em>
                                )}
                              </span> */}
                            </div>
                          </td>
                          {session?.super_admin ||
                          (teamDetails?.d?.admin_id === session?.user_id && teamDetails?.d?.isinvoiced > 0) ? (
                            <td>
                              {teamDetails && (
                                <>
                                  <span>
                                    {teamDetails.db_pool_9 && teamDetails.bdpoolpercent
                                      ? twoDecimals(+teamDetails.bdpoolpercent) + "% "
                                      : "0% "}
                                  </span>
                                  <span>+ </span>
                                  <span>
                                    {teamDetails.admin_commission
                                      ? parseFloat(teamDetails.admin_commission * 100).toFixed(2) + "% "
                                      : "0% "}
                                  </span>
                                  <span>= </span>
                                  <span>
                                    {teamDetails.admin_commission && teamDetails.profit
                                      ? "$" + twoDecimals(teamDetails.profit * teamDetails.admin_commission)
                                      : "$0.00"}
                                  </span>
                                </>
                              )}
                            </td>
                          ) : (
                            <td></td>
                          )}
                        </tr>
                      ) : (
                        ""
                      )}
                      {teamDetails && teamDetails.auction_recommend && teamDetails.auction_recommend.length > 0 ? (
                        <tr>
                          <td>Recommend View</td>
                          <td>
                            {teamDetails && teamDetails.auction_recommend && teamDetails.auction_recommend.length > 0
                              ? teamDetails.auction_recommend.map((item) => item.name).join(", ")
                              : "N/A"}
                          </td>
                          <td></td>
                        </tr>
                      ) : (
                        ""
                      )}
                      {teamDetails && teamDetails.comm_member_7 ? (
                        <tr>
                          <td>Ext Referral : </td>
                          <td>
                            <div>
                              <span className="name-bold">
                                {teamDetails && teamDetails.comm_member_7 ? teamDetails.comm_member_7 : "N/A"}
                              </span>
                            </div>
                          </td>
                          <td>
                            {session?.super_admin ||
                            (teamDetails?.dc_id_4 == session?.user_id && teamDetails?.isinvoiced > 0) ? (
                              <>
                                <span>
                                  {teamDetails.db_pool_9 && teamDetails.bdpoolpercent
                                    ? twoDecimals(+teamDetails.bdpoolpercent) + "% "
                                    : "0% "}
                                </span>
                                <span>+ </span>
                                <span>
                                  {teamDetails.admin_commission
                                    ? parseFloat(teamDetails.deal_commission_4 * 100).toFixed(2) + "% "
                                    : "0% "}
                                </span>
                                <span>= </span>
                                <span>
                                  {teamDetails.deal_commission_4 && teamDetails.profit
                                    ? "$" + twoDecimals(teamDetails.profit * teamDetails.deal_commission_4)
                                    : "$0.00"}
                                </span>
                              </>
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                      ) : (
                        ""
                      )}
                    </>
                  </tbody>
                </table>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      )}

      {showStrategicModal && (
        <StrategicTeamModal
          show={showStrategicModal}
          onHide={toggleStrategicModal}
          methodOrigindata={methodOrigindata}
          memberData={memberData}
          inspectorData={inspectorData}
          outsideAgentData={outsideAgentData}
          recommendedViewData={recommendedViewData}
          optimizerData={optimizerData}
          detail={detail}
          setDetail={setDetail}
          loading={loading}
          handleSubmit={handleSubmit}
          reviewOptions={reviewOptions}
        />
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getMethodOrigin: (callback) => dispatch(getMethodOrigin(callback)),
  getMember: (callback) => dispatch(getMember(callback)),
  getInspector: (callback) => dispatch(getInspector(callback)),
  getOutsideAgent: (callback) => dispatch(getOutsideAgent(callback)),
  getRecommendView: (callback) => dispatch(getRecommendedView(callback)),
  getOptimizer: (callback) => dispatch(getOptimizer(callback)),
  updateStrategicTeamDetails: (params, data, callback) => dispatch(updateStrategicTeamDetails(params, data, callback)),
  getStrategicTeamDetails: (data, callback) => dispatch(getStrategicTeamDetails(data, callback)),
  setShowStrategicModal: (data) => dispatch(setStrategicModal(data)),
});

const mapStateToProps = (state) => ({
  getStrategicTeam: getStrategicTeam(state),
  startegicModal: getModals(state)?.startegicModal,
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(StrategicTeam));
