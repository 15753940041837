import React, { useRef } from "react";
import LoadingBar from "react-top-loading-bar";
import Select from "react-select";
const customStyles = {
  indicatorSeparator: (styles) => ({ display: "none" }),
  option: (provided, state) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "#47ad1d",
      color: "#fff",
    },
    display: "-webkit-box",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    color: state.isSelected ? "#fff" : "black",
    backgroundColor: state.isSelected ? "#47ad1d" : provided.backgroundColor,
    // maxHeight: "84px",
  }),
  control: (base, state) => ({
    ...base,
    // height: 60,
    minHeight: 42,
    borderColor: state.isFocused ? "#47AD1D" : "#e4e4e4",
    boxShadow: state.isFocused ? "0 0 0 0.5px #47AD1D" : "0",
    border: state.isHovered ? "0" : "1px solid #e4e4e4",

    "&:hover": {
      borderColor: state.isFocused ? "#47AD1D" : "0",
      boxShadow: state.isFocused ? "0 0 0 0.5px #47AD1D" : "0",
    },
  }),
  multiValueRemove: (base, state) => ({
    ...base,
    "&:hover": {
      backgroundColor: "rgb(95, 50, 187, 10%)",
      color: "#6119c0",
    },
  }),
};
const MarketingEmail = (props) => {
  const ref = useRef();
  return (
    <>
      <LoadingBar height={5} color="#47AD1D" ref={ref} />
      <div style={{ marginTop: "-80px" }}>
        <h4>Send Marketing Email</h4>

        <div className="page-widget-label text-capitalize">Recipient:</div>
        <div className="d-flex mb-4" style={{ backgroundColor: "#CCE1C4", height: "40px" }}>
          <span className="d-flex align-items-center">{"Naji kp <najikp@webexcellis.com>"}</span>
          <div class="d-flex align-items-center">
            <div>
              <input type="checkbox" value="" id={`checkTwo`} />
              <label for={`checkTwo`}>Include note on all seller's listings</label>
            </div>
          </div>
        </div>

        <div className="page-widget-label text-capitalize">From:</div>
        <div className="d-flex mb-4" style={{ backgroundColor: "#CCE1C4", height: "60px" }}>
          <div className="d-flex align-items-center">
            <select name="from" id="">
              <option value="Super Admin">Super Admin</option>
              <option value="Super Admin">Super Admin</option>
              <option value="Super Admin">Super Admin</option>
              <option value="Super Admin">Super Admin</option>
            </select>
          </div>
        </div>

        <div className="page-widget-label text-capitalize">Traders/Employees:</div>
        <div className="d-flex mb-4" style={{ backgroundColor: "#CCE1C4", height: "120px" }}>
          <div className="d-flex align-items-center">
            <select name="traders" multiple id="">
              <option value="Super Admin">Super Admin</option>
              <option value="Super Admin">Super Admin</option>
              <option value="Super Admin">Super Admin</option>
              <option value="Super Admin">Super Admin</option>
              <option value="Super Admin">Super Admin</option>
              <option value="Super Admin">Super Admin</option>
              <option value="Super Admin">Super Admin</option>
              <option value="Super Admin">Super Admin</option>
              <option value="Super Admin">Super Admin</option>
            </select>
          </div>
        </div>

        <div className="page-widget-label text-capitalize">CC:</div>
        <div className="d-flex mb-4" style={{ backgroundColor: "#CCE1C4", height: "60px" }}>
          <div className="d-flex align-items-center">
            <input type="text" placeholder="yourmail@salvex.com" />
          </div>
        </div>

        <div className="page-widget-label text-capitalize">Product Location Contact:</div>
        <div className="d-flex mb-4" style={{ backgroundColor: "#CCE1C4", height: "40px" }}>
          <div class="d-flex align-items-center">
            <div>
              <input type="checkbox" value="" id={`checkTwo`} />
              <label for={`checkTwo`}>najikp@webexcellis.com</label>
            </div>
          </div>
        </div>

        <div className="page-widget-label text-capitalize">Subject:</div>
        <div className="d-flex mb-4" style={{ backgroundColor: "#CCE1C4", height: "60px" }}>
          <div className="d-flex align-items-center">
            <input type="text" placeholder="109833381  - Test product for sale" />
          </div>
        </div>

        <div className="page-widget-label text-capitalize p-2">Email Body(HTML Version):</div>
        <div className="mb-4" style={{ backgroundColor: "#CCE1C4", height: "160px" }}>
          <div className="d-flex">
            <div>
              <input type="radio" value="" id={`checkTwo`} />
              <label for={`checkTwo`}>Select Marketing Document</label>
            </div>
            <div>
              <input type="radio" value="" id={`checkTwo`} />
              <label for={`checkTwo`}>Custom Document</label>
            </div>
          </div>
          <div className="w-80 mt-2">
            <textarea name="" id="" rows={4} style={{ width: "100%" }}></textarea>
          </div>
        </div>

        <div className="page-widget-label text-capitalize">Attachments -</div>
        <div className="d-flex mb-4" style={{ backgroundColor: "#CCE1C4", height: "60px" }}>
          <div className="d-flex align-items-center">
            <select name="from" id="">
              <option value="Super Admin">Super Admin</option>
              <option value="Super Admin">Super Admin</option>
              <option value="Super Admin">Super Admin</option>
              <option value="Super Admin">Super Admin</option>
            </select>
          </div>
        </div>

        <div className="page-widget-label text-capitalize">Select Banner -</div>
        <div className="d-flex mb-4" style={{ backgroundColor: "#CCE1C4", height: "60px" }}>
          <div className="d-flex align-items-center"></div>
        </div>

        <div className="page-widget-label text-capitalize">Signature</div>
        <div className="d-flex mb-4" style={{ backgroundColor: "#CCE1C4", height: "240px" }}>
          <div>
            <div className="form-check radio pe-2">
              <input
                className="form-check-input cursor-pointer"
                value={1}
                type="radio"
                name="flexRadioDefault"
                id="radioYes" // Unique ID for the "Yes" option
              />
              <label className="form-check-label" htmlFor="radioYes">
                English Signature
              </label>
            </div>
            <sub>
              Regards,
              <br />
              <br />
              Ben Williams
              <br />
              Salvex Group, Inc.
              <br />
              440 Louisiana St. Ste. 550
              <br />
              Houston, TX 77002
              <br />
              +44 (0) 7922815934 - Phone
              <br />
              973-556-1328 - Fax
              <br />
              <a href="javascript:void(0)">ben@salvex.com</a> - E-mail
              <br />
              <a href="javascript:void(0)">www.salvex.com</a> - Web Site
              <br />
            </sub>
          </div>
          <div>
            <div className="form-check radio pe-2">
              <input
                className="form-check-input cursor-pointer"
                value={0}
                type="radio"
                name="flexRadioDefault"
                id="radioNo" // Unique ID for the "No" option
              />
              <label className="form-check-label" htmlFor="radioNo">
                Spanish Signature
              </label>
            </div>
            <sub>
              Saludos cordiales,
              <br />
              <br />
              Ben Williams
              <br />
              +44 (0) 7922815934 - Teléfono
              <br />
              973-556-1328 - Fax
              <br />
              <a href="javascript:void(0)">ben@salvex.com</a> - Email
              <br />
              <a href="javascript:void(0)">www.salvex.com</a> - Web Site
              <br />
            </sub>
          </div>
        </div>
      </div>
    </>
  );
};

export default MarketingEmail;
