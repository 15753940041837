import React from "react";
import NextButton from "../../../common/form/nextButton";
import { Modal } from "react-bootstrap";
import close from "../../../include/images/close.svg";
import DateFormat from "../../../common/DateFormat/dateFormat";

const SellerMotivationModal = (props) => {
  const data = props.data;
  return (
    <Modal
      className="modal fade custom-modal"
      id="sellerMotivation"
      show={props.show}
      onHide={() => props.onHide()}
      dialogClassName="modal-dialog modal-dialog-centered modal-md"
    >
      <div class="modal-content h-100 d-flex flex-column">
        <div class="custom-modal-header d-flex align-items-center justify-content-between">
          <h5 class="mb-0 text-capitalize">Seller Motivation</h5>
          <button
            type="button"
            class="close-btn d-flex align-items-center justify-content-center"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => props.onHide()}
          >
            <img src={close} alt="" />
          </button>
        </div>
        <Modal.Body className="custom-modal-body flex-grow-1 hide-scrollbar padding-bottom-0">
          <div className="d-flex justify-content-between">
            <div className="fw-medium">Ownership Investigation:</div>
            <div>{data?.motivation?.find((item) => item.type === "Ownership_Seller_Broker")?.motivation}</div>
          </div>

          <div className="mt-3 mb-3">
            {(!data?.motivation?.some(
              (item) =>
                [
                  "checkbox_2",
                  "checkbox_3",
                  "checkbox_26",
                  "checkbox_28",
                  "checkbox_29",
                  "checkbox_30",
                  "checkbox_66",
                  "checkbox_49",
                ].includes(item.type) && item.motivation === "1",
            ) ||
              data?.motivation?.find((item) => item.type === "checkbox_1")?.motivation == 1) && (
              <div class="form-check d-flex align-items-center mb-2">
                <input className="form-check-input" type="checkbox" name="sentEmail" value="" checked />
                <label class="form-check-label fw-medium"></label>
                <span>Don't Know</span>
              </div>
            )}
            {data?.motivation?.find((item) => item.type === "checkbox_2")?.motivation == 1 && (
              <div class="form-check d-flex align-items-center mb-2">
                <input className="form-check-input" type="checkbox" name="sentEmail" value="" checked />
                <label class="form-check-label fw-medium"></label>
                <span>Owner (Asset Disposal)</span>
              </div>
            )}
            {data?.motivation?.find((item) => item.type === "checkbox_26")?.motivation == 1 && (
              <div class="form-check d-flex align-items-center mb-2">
                <input className="form-check-input" type="checkbox" name="sentEmail" value="" checked />
                <label class="form-check-label fw-medium"></label>
                <span>Owner (Reseller)</span>
              </div>
            )}
            {data?.motivation?.find((item) => item.type === "checkbox_3")?.motivation == 1 && (
              <div class="form-check d-flex align-items-center mb-2">
                <input className="form-check-input" type="checkbox" name="sentEmail" value="" checked />
                <label class="form-check-label fw-medium"></label>
                <span>No Ownership (Seller Broker)</span>
              </div>
            )}
            {data?.motivation?.find((item) => item.type === "checkbox_28")?.motivation == 1 && (
              <div class="form-check d-flex align-items-center mb-2">
                <input className="form-check-input" type="checkbox" name="sentEmail" value="" checked />
                <label class="form-check-label fw-medium"></label>
                <span>Insurance Representative (Salvage)</span>
              </div>
            )}
            {data?.motivation?.find((item) => item.type === "checkbox_29")?.motivation == 1 && (
              <div class="form-check d-flex align-items-center mb-2">
                <input className="form-check-input" type="checkbox" name="sentEmail" value="" checked />
                <label class="form-check-label fw-medium"></label>
                <span>Owner (Salvage)</span>
              </div>
            )}
            {data?.motivation?.find((item) => item.type === "checkbox_30")?.motivation == 1 && (
              <div class="form-check d-flex align-items-center mb-2">
                <input className="form-check-input" type="checkbox" name="sentEmail" value="" checked />
                <label class="form-check-label fw-medium"></label>
                <span>Warehouse/Location (Abandoned Cargo)</span>
              </div>
            )}
            {data?.motivation?.find((item) => item.type === "checkbox_66")?.motivation == 1 && (
              <div class="form-check d-flex align-items-center mb-2">
                <input className="form-check-input" type="checkbox" name="sentEmail" value="" checked />
                <label class="form-check-label fw-medium"></label>
                <span>Freight Forwarder (Abandoned / Damaged Cargo)</span>
              </div>
            )}
            {data?.motivation?.find((item) => item.type === "checkbox_49")?.motivation == 1 && (
              <div class="form-check d-flex align-items-center mb-2">
                <input className="form-check-input" type="checkbox" name="sentEmail" value="" checked />
                <label class="form-check-label fw-medium"></label>
                <span>Owner (Manufacturer)</span>
              </div>
            )}
          </div>
          <div className="dividerTable"></div>

          <div>
            <div className="d-flex justify-content-between">
              <div className="fw-medium">Timeline for Sale:</div>
              <div>{data?.motivation?.find((item) => item.type === "Sale_Deadlines_Timelines")?.motivation}</div>
            </div>
            <div className="mt-3 mb-3">
              {(!data?.motivation?.some(
                (item) =>
                  ["checkbox_5", "checkbox_6", "checkbox_7", "checkbox_8"].includes(item.type) &&
                  item.motivation === "1",
              ) ||
                data?.motivation?.find((item) => item.type === "checkbox_4")?.motivation == 1) && (
                <div class="form-check d-flex align-items-center mb-2">
                  <input class="form-check-input" type="checkbox" name="sentEmail" value="" checked />
                  <label class="form-check-label fw-medium"></label>
                  <span>Don't Know</span>
                </div>
              )}
              {data?.motivation?.find((item) => item.type === "checkbox_5")?.motivation == 1 && (
                <div class="form-check d-flex align-items-center mb-2">
                  <input class="form-check-input" type="checkbox" name="sentEmail" value="" checked />
                  <label class="form-check-label fw-medium"></label>
                  <span>Immediately</span>
                </div>
              )}
              {data?.motivation?.find((item) => item.type === "checkbox_6")?.motivation == 1 && (
                <div class="form-check d-flex align-items-center mb-2">
                  <input class="form-check-input" type="checkbox" name="sentEmail" value="" checked />
                  <label class="form-check-label fw-medium"></label>
                  <span>Less Than 1 Month</span>
                </div>
              )}
              {data?.motivation?.find((item) => item.type === "checkbox_7")?.motivation == 1 && (
                <div class="form-check d-flex align-items-center mb-2">
                  <input class="form-check-input" type="checkbox" name="sentEmail" value="" checked />
                  <label class="form-check-label fw-medium"></label>
                  <span>Less Than 3 Months</span>
                </div>
              )}
              {data?.motivation?.find((item) => item.type === "checkbox_8")?.motivation == 1 && (
                <div class="form-check d-flex align-items-center mb-2">
                  <input class="form-check-input" type="checkbox" name="sentEmail" value="" checked />
                  <label class="form-check-label fw-medium"></label>
                  <span>No Timeline</span>
                </div>
              )}
              {data?.motivation?.find((item) => item.type == "timeline_followup")?.motivation && (
                <div class="form-check d-flex align-items-center mb-2">
                  Timeline Follow Up Date:
                  <DateFormat data={data?.motivation?.find((item) => item.type == "timeline_followup")?.motivation} />
                </div>
              )}
              {data?.motivation?.find((item) => item.type === "date_1")?.motivation && (
                <div class="form-check d-flex align-items-center mb-2">
                  Date: <DateFormat date={data?.motivation?.find((item) => item.type === "date_1")?.motivation} />
                </div>
              )}
            </div>
          </div>
          <div className="dividerTable"></div>
          <div>
            <div className="d-flex justify-content-between">
              <div className="fw-medium">Inventory Investigation:</div>
              <div></div>
            </div>
            <div className="mt-3 mb-3">
              {(!data?.motivation?.some(
                (item) => ["checkbox_10", "checkbox_11", "checkbox_12"].includes(item.type) && item.motivation === "1",
              ) ||
                data?.motivation?.find((item) => item.type === "checkbox_9")?.motivation == 1) && (
                <div class="form-check d-flex align-items-center mb-2">
                  <input class="form-check-input" type="checkbox" name="sentEmail" value="" checked />
                  <label class="form-check-label fw-medium"></label>
                  <span>Don't Know</span>
                </div>
              )}
              {data?.motivation?.find((item) => item.type === "checkbox_10")?.motivation == 1 && (
                <div class="form-check d-flex align-items-center mb-2">
                  <input class="form-check-input" type="checkbox" name="sentEmail" value="" checked />
                  <label class="form-check-label fw-medium"></label>
                  <span>Pilot Listing Given Only</span>
                </div>
              )}
              {data?.motivation?.find((item) => item.type === "checkbox_11")?.motivation == 1 && (
                <div class="form-check d-flex align-items-center mb-2">
                  <input class="form-check-input" type="checkbox" name="sentEmail" value="" checked />
                  <label class="form-check-label fw-medium"></label>
                  <span>Partial Inventory Given</span>
                </div>
              )}
              {data?.motivation?.find((item) => item.type === "checkbox_12")?.motivation == 1 && (
                <div class="form-check d-flex align-items-center mb-2">
                  <input class="form-check-input" type="checkbox" name="sentEmail" value="" checked />
                  <label class="form-check-label fw-medium"></label>
                  <span>All Inventory Given</span>
                </div>
              )}
              {data?.motivation?.find((item) => item.type === "inventory_followup")?.motivation && (
                <div class="form-check d-flex align-items-center mb-2">
                  Inventory Follow Up Date:{" "}
                  <DateFormat data={data?.motivation?.find((item) => item.type === "inventory_followup")?.motivation} />
                </div>
              )}
            </div>
          </div>
          <div className="dividerTable"></div>
          <div>
            <div className="d-flex justify-content-between">
              <div className="fw-medium">Salvex Control of Sale:</div>
              <div>{data?.motivation?.find((item) => item.type === "Salvex_Control_of_Sale")?.motivation}</div>
            </div>
            <div className="mt-3 mb-3">
              {(!data?.motivation?.some(
                (item) =>
                  ["checkbox_68", "checkbox_69", "checkbox_70", "checkbox_71", "checkbox_72", "checkbox_73"].includes(
                    item.type,
                  ) && item.motivation === "1",
              ) ||
                data?.motivation?.find((item) => item.type === "checkbox_67")?.motivation == 1) && (
                <div class="form-check d-flex align-items-center mb-2">
                  <input class="form-check-input" type="checkbox" name="sentEmail" value="" checked />
                  <label class="form-check-label fw-medium"></label>
                  <span>Don't Know</span>
                </div>
              )}
              {data?.motivation?.find((item) => item.type === "checkbox_68")?.motivation == 1 && (
                <div class="form-check d-flex align-items-center mb-2">
                  <input class="form-check-input" type="checkbox" name="sentEmail" value="" checked />
                  <label class="form-check-label fw-medium"></label>
                  <span>Quick Sale</span>
                </div>
              )}
              {data?.motivation?.find((item) => item.type === "checkbox_69")?.motivation == 1 && (
                <div class="form-check d-flex align-items-center mb-2">
                  <input class="form-check-input" type="checkbox" name="sentEmail" value="" checked />
                  <label class="form-check-label fw-medium"></label>
                  <span>Open Marketplace Buyer Control</span>
                </div>
              )}
              {data?.motivation?.find((item) => item.type === "checkbox_70")?.motivation == 1 && (
                <div class="form-check d-flex align-items-center mb-2">
                  <input class="form-check-input" type="checkbox" name="sentEmail" value="" checked />
                  <label class="form-check-label fw-medium"></label>
                  <span>Tender Software Buyer Control</span>
                </div>
              )}
              {data?.motivation?.find((item) => item.type === "checkbox_71")?.motivation == 1 && (
                <div class="form-check d-flex align-items-center mb-2">
                  <input class="form-check-input" type="checkbox" name="sentEmail" value="" checked />
                  <label class="form-check-label fw-medium"></label>
                  <span>Exclusive Contract Buyer Control</span>
                </div>
              )}
              {data?.motivation?.find((item) => item.type === "checkbox_72")?.motivation == 1 && (
                <div class="form-check d-flex align-items-center mb-2">
                  <input class="form-check-input" type="checkbox" name="sentEmail" value="" checked />
                  <label class="form-check-label fw-medium"></label>
                  <span>No Buyer Control</span>
                </div>
              )}
            </div>
          </div>
          <div className="dividerTable"></div>
          <div>
            <div className="d-flex justify-content-between">
              <div className="fw-medium">Seller Communications:</div>
              <div>{data?.motivation?.find((item) => item.type === "Seller_Communications")?.motivation}</div>
            </div>
            <div className="mt-3 mb-3">
              {(!data?.motivation?.some(
                (item) =>
                  ["checkbox_61", "checkbox_32", "checkbox_62", "checkbox_63", "checkbox_33", "checkbox_64"].includes(
                    item.type,
                  ) && item.motivation === "1",
              ) ||
                data?.motivation?.find((item) => item.type === "seller_motivation_empty")?.motivation ||
                data?.motivation?.find((item) => item.type === "checkbox_31")?.motivation == "1") && (
                <div class="form-check d-flex align-items-center mb-2">
                  <input class="form-check-input" type="checkbox" name="sentEmail" value="" checked />
                  <label class="form-check-label fw-medium"></label>
                  <span>Don't Know</span>
                </div>
              )}
              {data?.motivation?.find((item) => item.type === "checkbox_61")?.motivation == 1 && (
                <div class="form-check d-flex align-items-center mb-2">
                  <input class="form-check-input" type="checkbox" name="sentEmail" value="" />
                  <label class="form-check-label fw-medium"></label>
                  <span>Priority Seller</span>
                </div>
              )}
              {data?.motivation?.find((item) => item.type === "checkbox_32")?.motivation == 1 && (
                <div class="form-check d-flex align-items-center mb-2">
                  <input class="form-check-input" type="checkbox" name="sentEmail" value="" />
                  <label class="form-check-label fw-medium"></label>
                  <span>Good Communication</span>
                </div>
              )}
              {data?.motivation?.find((item) => item.type === "checkbox_62")?.motivation == 1 && (
                <div class="form-check d-flex align-items-center mb-2">
                  <input class="form-check-input" type="checkbox" name="sentEmail" value="" />
                  <label class="form-check-label fw-medium"></label>
                  <span>Phone Preference</span>
                </div>
              )}
              {data?.motivation?.find((item) => item.type === "checkbox_63")?.motivation == 1 && (
                <div class="form-check d-flex align-items-center mb-2">
                  <input class="form-check-input" type="checkbox" name="sentEmail" value="" />
                  <label class="form-check-label fw-medium"></label>
                  <span>Email Preference</span>
                </div>
              )}
              {data?.motivation?.find((item) => item.type === "checkbox_64")?.motivation == 1 && (
                <div class="form-check d-flex align-items-center mb-2">
                  <input class="form-check-input" type="checkbox" name="sentEmail" value="" />
                  <label class="form-check-label fw-medium"></label>
                  <span>Text Preference</span>
                </div>
              )}
              {data?.motivation?.find((item) => item.type === "checkbox_33")?.motivation == 1 && (
                <div class="form-check d-flex align-items-center mb-2">
                  <input class="form-check-input" type="checkbox" name="sentEmail" value="" />
                  <label class="form-check-label fw-medium"></label>
                  <span>No Communication</span>
                </div>
              )}
            </div>
          </div>
          <div className="dividerTable"></div>
          <div>
            <div className="d-flex justify-content-between">
              <div className="fw-medium">Competition:</div>
              <div>{data?.motivation?.find((item) => item.type === "Insured_Bidding")?.motivation}</div>
            </div>
            <div className="mt-3 mb-3">
              {(!data?.motivation?.some(
                (item) =>
                  ["checkbox_42", "checkbox_43", "checkbox_44", "checkbox_45"].includes(item.type) &&
                  item.motivation === "1",
              ) ||
                data?.motivation?.find((item) => item.type === "checkbox_41")?.motivation == 1) && (
                <div class="form-check d-flex align-items-center mb-2">
                  <input class="form-check-input" type="checkbox" name="sentEmail" value="" checked />
                  <label class="form-check-label fw-medium"></label>
                  <span>Don't Know</span>
                </div>
              )}
              {data?.motivation?.find((item) => item.type === "checkbox_42")?.motivation == 1 && (
                <div class="form-check d-flex align-items-center mb-2">
                  <input class="form-check-input" type="checkbox" name="sentEmail" value="" />
                  <label class="form-check-label fw-medium"></label>
                  <span>Insured Bidding</span>
                </div>
              )}
              {data?.motivation?.find((item) => item.type === "checkbox_43")?.motivation == 1 && (
                <div class="form-check d-flex align-items-center mb-2">
                  <input class="form-check-input" type="checkbox" name="sentEmail" value="" />
                  <label class="form-check-label fw-medium"></label>
                  <span>Insured Not Bidding</span>
                </div>
              )}
              {data?.motivation?.find((item) => item.type === "checkbox_44")?.motivation == 1 && (
                <div class="form-check d-flex align-items-center mb-2">
                  <input class="form-check-input" type="checkbox" name="sentEmail" value="" />
                  <label class="form-check-label fw-medium"></label>
                  <span>Distribution List</span>
                </div>
              )}
              {data?.motivation?.find((item) => item.type === "checkbox_45")?.motivation == 1 && (
                <div class="form-check d-flex align-items-center mb-2">
                  <input class="form-check-input" type="checkbox" name="sentEmail" value="" />
                  <label class="form-check-label fw-medium"></label>
                  <span>Other Websites</span>
                </div>
              )}
            </div>
          </div>
          <div className="dividerTable"></div>
          <div>
            <div className="d-flex justify-content-between">
              <div className="fw-medium">Seller Legal & Contracts:</div>
              <div>{data?.motivation?.find((item) => item.type === "Vendor_Exclusive_Contracts")?.motivation}</div>
            </div>
            <div className="mt-3 mb-3">
              {(!data?.motivation?.some(
                (item) =>
                  ["checkbox_27", "checkbox_47", "checkbox_48", "checkbox_58", "checkbox_59", "checkbox_60"].includes(
                    item.type,
                  ) && item.motivation === "1",
              ) ||
                data?.motivation?.find((item) => item.type === "checkbox_46")?.motivation == 1) && (
                <div class="form-check d-flex align-items-center mb-2">
                  <input class="form-check-input" type="checkbox" name="sentEmail" value="" checked />
                  <label class="form-check-label fw-medium"></label>
                  <span>Don't Know</span>
                </div>
              )}
              {data?.motivation?.find((item) => item.type === "checkbox_27")?.motivation == 1 && (
                <div class="form-check d-flex align-items-center mb-2">
                  <input class="form-check-input" type="checkbox" name="sentEmail" value="" />
                  <label class="form-check-label fw-medium"></label>
                  <span>Tender</span>
                </div>
              )}
              {data?.motivation?.find((item) => item.type === "checkbox_47")?.motivation == 1 && (
                <div class="form-check d-flex align-items-center mb-2">
                  <input class="form-check-input" type="checkbox" name="sentEmail" value="" />
                  <label class="form-check-label fw-medium"></label>
                  <span>Vendor Agreement Required</span>
                </div>
              )}
              {data?.motivation?.find((item) => item.type === "checkbox_57")?.motivation == 1 && (
                <div class="form-check d-flex align-items-center mb-2">
                  <input class="form-check-input" type="checkbox" name="sentEmail" value="" />
                  <label class="form-check-label fw-medium"></label>
                  <span>Vendor Agreement Signed</span>
                </div>
              )}
              {data?.motivation?.find((item) => item.type === "checkbox_48")?.motivation == 1 && (
                <div class="form-check d-flex align-items-center mb-2">
                  <input class="form-check-input" type="checkbox" name="sentEmail" value="" />
                  <label class="form-check-label fw-medium"></label>
                  <span>Service Contract Required</span>
                </div>
              )}
              {data?.motivation?.find((item) => item.type === "checkbox_58")?.motivation == 1 && (
                <div class="form-check d-flex align-items-center mb-2">
                  <input class="form-check-input" type="checkbox" name="sentEmail" value="" />
                  <label class="form-check-label fw-medium"></label>
                  <span>Service Contract Signed</span>
                </div>
              )}
              {data?.motivation?.find((item) => item.type === "checkbox_59")?.motivation == 1 && (
                <div class="form-check d-flex align-items-center mb-2">
                  <input class="form-check-input" type="checkbox" name="sentEmail" value="" />
                  <label class="form-check-label fw-medium"></label>
                  <span>Seller Verification Required</span>
                </div>
              )}
              {data?.motivation?.find((item) => item.type === "checkbox_60")?.motivation == 1 && (
                <div class="form-check d-flex align-items-center mb-2">
                  <input class="form-check-input" type="checkbox" name="sentEmail" value="" />
                  <label class="form-check-label fw-medium"></label>
                  <span>Seller Verification Complete</span>
                </div>
              )}
            </div>
          </div>
          <div className="dividerTable"></div>
          <div>
            <div className="d-flex justify-content-between">
              <div className="fw-medium">Geographic Market Sale Options:</div>
              <div>{data?.motivation?.find((item) => item.type === "Geographic_Market_Sale_Options")?.motivation}</div>
            </div>
            <div className="mt-3 mb-3">
              {(!data?.motivation?.some(
                (item) =>
                  ["checkbox_25", "checkbox_14", "checkbox_15", "checkbox_16"].includes(item.type) &&
                  item.motivation === "1",
              ) ||
                data?.motivation?.find((item) => item.type === "checkbox_13")?.motivation == 1) && (
                <div class="form-check d-flex align-items-center mb-2">
                  <input class="form-check-input" type="checkbox" name="sentEmail" value="" checked />
                  <label class="form-check-label fw-medium"></label>
                  <span>Don't Know</span>
                </div>
              )}
              {data?.motivation?.find((item) => item.type === "checkbox_25")?.motivation == 1 && (
                <div class="form-check d-flex align-items-center mb-2">
                  <input class="form-check-input" type="checkbox" name="sentEmail" value="" />
                  <label class="form-check-label fw-medium"></label>
                  <span>All Locations</span>
                </div>
              )}
              {data?.motivation?.find((item) => item.type === "checkbox_14")?.motivation == 1 && (
                <div class="form-check d-flex align-items-center mb-2">
                  <input class="form-check-input" type="checkbox" name="sentEmail" value="" />
                  <label class="form-check-label fw-medium"></label>
                  <span>All Locations Some Restrictions</span>
                </div>
              )}
              {data?.motivation?.find((item) => item.type === "checkbox_15")?.motivation == 1 && (
                <div class="form-check d-flex align-items-center mb-2">
                  <input class="form-check-input" type="checkbox" name="sentEmail" value="" />
                  <label class="form-check-label fw-medium"></label>
                  <span>Local Only</span>
                </div>
              )}
              {data?.motivation?.find((item) => item.type === "checkbox_16")?.motivation == 1 && (
                <div class="form-check d-flex align-items-center mb-2">
                  <input class="form-check-input" type="checkbox" name="sentEmail" value="" />
                  <label class="form-check-label fw-medium"></label>
                  <span>Export Only</span>
                </div>
              )}
            </div>
          </div>
          <div className="dividerTable"></div>
          <div>
            <div className="d-flex justify-content-between">
              <div className="fw-medium">Location, Loading and Logistics:</div>
              <div>{data?.motivation?.find((item) => item.type === "Location_Loading_and_Logistics")?.motivation}</div>
            </div>
            <div className="mt-3 mb-3">
              {(!data?.motivation?.some(
                (item) =>
                  [
                    "checkbox_18",
                    "checkbox_54",
                    "checkbox_19",
                    "checkbox_20",
                    "checkbox_21",
                    "checkbox_55",
                    "checkbox_56",
                  ].includes(item.type) && item.motivation === "1",
              ) ||
                data?.motivation?.find((item) => item.type === "checkbox_17")?.motivation == 1) && (
                <div class="form-check d-flex align-items-center mb-2">
                  <input class="form-check-input" type="checkbox" name="sentEmail" value="" checked />
                  <label class="form-check-label fw-medium"></label>
                  <span>Don't Know</span>
                </div>
              )}
              {data?.motivation?.find((item) => item.type === "checkbox_18")?.motivation == 1 && (
                <div class="form-check d-flex align-items-center mb-2">
                  <input class="form-check-input" type="checkbox" name="sentEmail" value="" />
                  <label class="form-check-label fw-medium"></label>
                  <span>Ex Works</span>
                </div>
              )}
              {data?.motivation?.find((item) => item.type === "checkbox_54")?.motivation == 1 && (
                <div class="form-check d-flex align-items-center mb-2">
                  <input class="form-check-input" type="checkbox" name="sentEmail" value="" />
                  <label class="form-check-label fw-medium"></label>
                  <span>Location Loading (By Seller)</span>
                </div>
              )}
              {data?.motivation?.find((item) => item.type === "checkbox_19")?.motivation == 1 && (
                <div class="form-check d-flex align-items-center mb-2">
                  <input class="form-check-input" type="checkbox" name="sentEmail" value="" />
                  <label class="form-check-label fw-medium"></label>
                  <span>Location Loading (Buyer Cost)</span>
                </div>
              )}
              {data?.motivation?.find((item) => item.type === "checkbox_20")?.motivation == 1 && (
                <div class="form-check d-flex align-items-center mb-2">
                  <input class="form-check-input" type="checkbox" name="sentEmail" value="" />
                  <label class="form-check-label fw-medium"></label>
                  <span>No Location Loading Support</span>
                </div>
              )}
              {data?.motivation?.find((item) => item.type === "checkbox_55")?.motivation == 1 && (
                <div class="form-check d-flex align-items-center mb-2">
                  <input class="form-check-input" type="checkbox" name="sentEmail" value="" />
                  <label class="form-check-label fw-medium"></label>
                  <span>Trucking (By Seller)</span>
                </div>
              )}
              {data?.motivation?.find((item) => item.type === "checkbox_56")?.motivation == 1 && (
                <div class="form-check d-flex align-items-center mb-2">
                  <input class="form-check-input" type="checkbox" name="sentEmail" value="" />
                  <label class="form-check-label fw-medium"></label>
                  <span>Customs Clearance (By Seller)</span>
                </div>
              )}
              {data?.motivation?.find((item) => item.type === "checkbox_21")?.motivation == 1 && (
                <div class="form-check d-flex align-items-center mb-2">
                  <input class="form-check-input" type="checkbox" name="sentEmail" value="" />
                  <label class="form-check-label fw-medium"></label>
                  <span>Multiple Locations</span>
                </div>
              )}
            </div>
          </div>
          <div className="dividerTable"></div>
          <div>
            <div className="d-flex justify-content-between">
              <div className="fw-medium">Product Markets Investigation:</div>
              <div>{data?.motivation?.find((item) => item.type === "Product_Markets_Discussion")?.motivation}</div>
            </div>
            <div className="mt-3 mb-3">
              {(!data?.motivation?.some(
                (item) =>
                  ["checkbox_22", "checkbox_23", "checkbox_24", "checkbox_51", "checkbox_52"].includes(item.type) &&
                  item.motivation === "1",
              ) ||
                data?.motivation?.find((item) => item.type === "checkbox_22")?.motivation == 1) && (
                <div class="form-check d-flex align-items-center mb-2">
                  <input class="form-check-input" type="checkbox" name="sentEmail" value="" checked />
                  <label class="form-check-label fw-medium"></label>
                  <span>Don't Know</span>
                </div>
              )}
              {data?.motivation?.find((item) => item.type === "checkbox_23")?.motivation == 1 && (
                <div class="form-check d-flex align-items-center mb-2">
                  <input class="form-check-input" type="checkbox" name="sentEmail" value="" />
                  <label class="form-check-label fw-medium"></label>
                  <span>Original Use</span>
                </div>
              )}
              {data?.motivation?.find((item) => item.type === "checkbox_24")?.motivation == 1 && (
                <div class="form-check d-flex align-items-center mb-2">
                  <input class="form-check-input" type="checkbox" name="sentEmail" value="" />
                  <label class="form-check-label fw-medium"></label>
                  <span>Repurposing Use</span>
                </div>
              )}
              {data?.motivation?.find((item) => item.type === "checkbox_51")?.motivation == 1 && (
                <div class="form-check d-flex align-items-center mb-2">
                  <input class="form-check-input" type="checkbox" name="sentEmail" value="" />
                  <label class="form-check-label fw-medium"></label>
                  <span>Lot Sale</span>
                </div>
              )}
              {data?.motivation?.find((item) => item.type === "checkbox_52")?.motivation == 1 && (
                <div class="form-check d-flex align-items-center mb-2">
                  <input class="form-check-input" type="checkbox" name="sentEmail" value="" />
                  <label class="form-check-label fw-medium"></label>
                  <span>Seller as Individual Listing</span>
                </div>
              )}
            </div>
          </div>
          <div className="dividerTable"></div>
          <div>
            <div className="d-flex justify-content-between">
              <div className="fw-medium">Asset Pricing Discussion:</div>
              <div></div>
            </div>
            <div className="mt-3 mb-3">
              {(!data?.motivation?.some(
                (item) =>
                  [
                    "checkbox_35",
                    "checkbox_36",
                    "checkbox_37",
                    "checkbox_38",
                    "checkbox_39",
                    "checkbox_40",
                    "checkbox_50",
                  ].includes(item.type) && item.motivation === "1",
              ) ||
                data?.motivation?.find((item) => item.type === "checkbox_34")?.motivation == 1) && (
                <div class="form-check d-flex align-items-center mb-2">
                  <input class="form-check-input" type="checkbox" name="sentEmail" value="" checked />
                  <label class="form-check-label fw-medium"></label>
                  <span>Don't Know</span>
                </div>
              )}
              {data?.motivation?.find((item) => item.type === "checkbox_35")?.motivation == 1 && (
                <div class="form-check d-flex align-items-center mb-2">
                  <input class="form-check-input" type="checkbox" name="sentEmail" value="" />
                  <label class="form-check-label fw-medium"></label>
                  <span>Less than 20% Wholesale Value</span>
                </div>
              )}
              {data?.motivation?.find((item) => item.type === "checkbox_36")?.motivation == 1 && (
                <div class="form-check d-flex align-items-center mb-2">
                  <input class="form-check-input" type="checkbox" name="sentEmail" value="" />
                  <label class="form-check-label fw-medium"></label>
                  <span>20%-40% Wholesale Value</span>
                </div>
              )}
              {data?.motivation?.find((item) => item.type === "checkbox_37")?.motivation == 1 && (
                <div class="form-check d-flex align-items-center mb-2">
                  <input class="form-check-input" type="checkbox" name="sentEmail" value="" />
                  <label class="form-check-label fw-medium"></label>
                  <span>40%-60% Wholesale Value</span>
                </div>
              )}
              {data?.motivation?.find((item) => item.type === "checkbox_38")?.motivation == 1 && (
                <div class="form-check d-flex align-items-center mb-2">
                  <input class="form-check-input" type="checkbox" name="sentEmail" value="" />
                  <label class="form-check-label fw-medium"></label>
                  <span>60%-80% Wholesale Value</span>
                </div>
              )}
              {data?.motivation?.find((item) => item.type === "checkbox_39")?.motivation == 1 && (
                <div class="form-check d-flex align-items-center mb-2">
                  <input class="form-check-input" type="checkbox" name="sentEmail" value="" />
                  <label class="form-check-label fw-medium"></label>
                  <span>80%-100% Wholesale Value</span>
                </div>
              )}
              {data?.motivation?.find((item) => item.type === "checkbox_40")?.motivation == 1 && (
                <div class="form-check d-flex align-items-center mb-2">
                  <input class="form-check-input" type="checkbox" name="sentEmail" value="" />
                  <label class="form-check-label fw-medium"></label>
                  <span>100% + Wholesale Value</span>
                </div>
              )}
              {data?.motivation?.find((item) => item.type === "checkbox_50")?.motivation == 1 && (
                <div class="form-check d-flex align-items-center mb-2">
                  <input class="form-check-input" type="checkbox" name="sentEmail" value="" />
                  <label class="form-check-label fw-medium"></label>
                  <span>No Pricing</span>
                </div>
              )}
            </div>
          </div>
          <div className="dividerTable"></div>
          <table class="table modal-product-detail-table mb-0">
            <tr>
              <td className="fw-medium">Other Information:</td>
              <td>{data?.motivation?.find((item) => item.type === "Other_Information")?.motivation}</td>
            </tr>
          </table>
          <div className="dividerTable"></div>
          <table class="table modal-product-detail-table mb-0">
            <tr>
              <td>Last Updated By</td>
              <td>{data?.sellerData?.completedby}</td>
            </tr>
          </table>
          <div className="dividerTable"></div>
          <table class="table modal-product-detail-table mb-0">
            <tr>
              <td>Last Updated Date</td>
              <td>
                <DateFormat date={data?.sellerData?.updated_at} />
              </td>
            </tr>
          </table>
          <div className="dividerTable"></div>
          <div className="fw-medium">Previous Seller Motivation Updates:</div>
          <table class="table modal-product-detail-table mb-0 mt-2">
            {data?.seller?.map((val) => (
              <tr>
                <td>
                  <DateFormat data={val?.updatedAt} />
                </td>
                <td>{val?.updateBy}</td>
                <td>
                  <button className="green-btn-text" onClick={() => props.preMotivationFetch(val?.updatedAt)}>
                    More
                  </button>
                </td>
              </tr>
            ))}
          </table>
        </Modal.Body>
        <div class="custom-modal-dividor"></div>
        <div className="custom-modal-footer d-grid gap-2 d-md-flex align-items-center justify-content-md-end">
          <button
            class="btn btn-link me-md-2 global-cancel-button"
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => props.onHide()}
          >
            Cancel
          </button>
          {/* <NextButton
            label="Save"
            classData="btn btn-default"
            // handleSubmit={props.handleSubmit}
            // loading={props.loadingQuickSummary}
          /> */}
        </div>
      </div>
    </Modal>
  );
};

export default SellerMotivationModal;
