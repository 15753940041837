import React, { useEffect, useRef, useState } from "react";
import Header from "../../layouts/header";
import { connect } from "react-redux";
import LoadingBar from "react-top-loading-bar";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import AddModel from "../../components/settings/manufacturerAndModals/modals/addModel";
import { getListings } from "../../store/listings";
import { addAviationModel, getAviationModelList, getManufacturers, getMisc, getModelList } from "../../store/misc";
import { addMfr, addModel } from "../../store/listingProduct";
import { toast } from "react-toastify";

import AlertError from "../../common/alerts/alertError";
import AlertSuccess from "../../common/alerts/alertSuccess";
import ModelTable from "../../components/settings/manufacturerAndModals/modelTable";
import AddAvaitionModel from "../../components/settings/aviationModels/modals/addAvaitionModel";
import AviationModelTable from "../../components/settings/aviationModels/aviationModelTable";

const aviationTypeOptions = [
  { name: "Airbus", value: "Airbus" },
  { name: "Antonov", value: "Antonov" },

  { name: "ATR", value: "ATR" },

  { name: "Beechcraft", value: "Beechcraft" },

  { name: "Boeing", value: "Boeing" },

  { name: "Bombardier", value: "Bombardier" },

  { name: "CASA", value: "CASA" },
  { name: "Cessna", value: "Cessna" },
  { name: "COMAC", value: "COMAC" },
  { name: "Dassault Aviation", value: "Dassault Aviation" },
  { name: "De Havilland Canada (DHC)", value: "De Havilland Canada (DHC)" },
  { name: "Dornier", value: "Dornier" },
  { name: "Embraer", value: "Embraer" },
  { name: "Fokker", value: "Fokker" },
  { name: "General Dynamics", value: "General Dynamics" },
  { name: "Gulfstream", value: "Gulfstream" },
  { name: "Honda Aircraft Company", value: "Honda Aircraft Company" },
  { name: "Ilyushin", value: "Ilyushin" },
  { name: "Irkut", value: "Irkut" },
  { name: "Leonardo", value: "Leonardo" },
  { name: "Lockheed Martin", value: "Lockheed Martin" },
  { name: "McDonnell Douglas", value: "McDonnell Douglas" },
  { name: "Northrop Grumman", value: "Northrop Grumman" },
  { name: "Pilatus Aircraft", value: "Pilatus Aircraft" },
  { name: "Saab", value: "Saab" },

  { name: "Sukhoi", value: "Sukhoi" },

  { name: "Tupolev", value: "Tupolev" },
];

const AviationModels = (props) => {
  const ref = useRef(null);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [loadingModel, setLoadingModel] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [selectedModel, setSelectedModel] = useState(null);
  const [editData, setEditData] = useState({});
  const [page, setPage] = useState(1);
  const [modelName, setModelName] = useState(null);
  const [sort, setSort] = useState("type");
  const [order, setOrder] = useState("asc");
  const [keyword, setKeyword] = useState({ model: "", aviationType: "" });
  const [response, setResponse] = useState({
    res: false,
    model: "",
    aviationType: "",
  });

  const toggleAddModal = () => {
    setKeyword({
      model: response?.model,
      aviationType: response?.aviationType,
    });
    setShowAddModal(!showAddModal);
  };

  useEffect(() => {
    props.getManufacturers((res) => {});
  }, []);

  const options =
    props.getMisc &&
    props.getMisc.manufacturers &&
    props.getMisc.manufacturers.data &&
    props.getMisc.manufacturers.data.length > 0 &&
    props.getMisc.manufacturers.data.map((item) => ({
      name: `${item.Description} ${item.ra_users ? `(${item.ra_users})` : ""}`,
      value: item.ID,
    }));

  useEffect(() => {
    ref?.current?.continuousStart();
    setLoadingModel(true);
    props.getModel(
      {
        page: page,
        sort,
        order,
        description: response?.model ? response?.model : "",
        type: response?.aviationType ? response?.aviationType : "",
      },
      (res) => {
        if (res && res.status === 200) {
          ref?.current?.complete();
          setLoadingModel(false);
        } else {
          ref?.current?.complete();
          setLoadingModel(false);
        }
      },
    );
  }, [page]);
  useEffect(() => {
    ref?.current?.continuousStart();
    setLoadingModel(true);
    setPage(1);
    props.getModel(
      {
        page: 1,
        sort,
        order,
        description: response?.model ? response?.model : "",
        type: response?.aviationType ? response?.aviationType : "",
      },
      (res) => {
        if (res && res.status === 200) {
          ref?.current?.complete();
          setLoadingModel(false);
        } else {
          ref?.current?.complete();
          setLoadingModel(false);
        }
      },
    );
  }, [sort, order]);

  useEffect(() => {
    if (showAddModal && selectedModel?.type == "editModel" && selectedModel?.item?.description) {
      const a = { description: selectedModel?.item?.description };
      setModelName(a);
    } else if (showAddModal && selectedModel?.type == "filterModel") {
      setModelName(null);
    } else {
      setSelectedModel(null);
      setModelName(null);
    }
  }, [showAddModal]);

  const modelData = props?.getMisc?.aviationModelList?.data || [];

  const totalCount = props?.getMisc?.aviationModelList?.total_records || 0;

  const filteredCount = props?.getMisc?.aviationModelList?.per_page || 0;

  const totalPages = props?.getMisc?.aviationModelList?.max_pages || 0;

  const handleAddModel = () => {
    setLoading(true);
    ref?.current?.continuousStart();
    const payload = {
      description: modelName?.description?.replace("/t", ""),
      type: `${modelName?.aviationType?.value}`,
      // aviationModelID: "",
    };
    props?.addModel(payload, (res) => {
      if (res && res.status == 200) {
        props.getModel({ page: page, sort, order }, (res) => {
          if (res && res.status === 200) {
            setLoading(false);
            ref?.current?.complete();
            toggleAddModal();
            setSelectedModel(null);
            setModelName(null);

            toast(<AlertSuccess message={"Information Saved"} />);
          } else {
            ref?.current?.complete();
            setLoadingModel(false);
          }
        });
      } else {
        setLoading(false);
        ref?.current?.complete();
        toast(<AlertError message={res?.data?.message ? res?.data?.message : "Something went wrong"} />);
      }
    });
  };

  const handleEditModel = () => {
    setLoading(true);
    ref?.current?.continuousStart();
    const payload = {
      description: modelName?.description?.replace("/t", ""),
      type: `${editData?.type}`,
      aviationModelID: `${selectedModel?.item?.aviationModelID}`,
    };
    props?.addModel(payload, (res) => {
      if (res && res.status == 200) {
        props.getModel({ page: page, sort, order }, (res) => {
          if (res && res.status === 200) {
            setLoading(false);
            ref?.current?.complete();
            toggleAddModal();
            setSelectedModel(null);
            setEditData({});
            setModelName(null);

            toast(<AlertSuccess message={"Information Saved"} />);
          } else {
            ref?.current?.complete();
            setLoadingModel(false);
          }
        });
      } else {
        setLoading(false);
        ref?.current?.complete();
        toast(<AlertError message={res?.data?.message ? res?.data?.message : "Something went wrong"} />);
      }
    });
  };

  const handleFilterModel = (key) => {
    ref?.current?.continuousStart();
    setLoadingModel(true);
    props.getModel(
      {
        page: 1,
        sort,
        order,
        description: key?.model ? key?.model : "",
        type: key?.aviationType ? key?.aviationType : "",
      },
      (res) => {
        if (res && res.status === 200) {
          setResponse({
            res: key.model || key.aviationType ? true : false,
            model: key.model ? key.model : "",
            aviationType: key.aviationType ? key.aviationType : "",
          });
          setPage(1);
          ref?.current?.complete();
          setLoadingModel(false);
          if (showAddModal) {
            toggleAddModal();
          }
        } else {
          ref?.current?.complete();
          setLoadingModel(false);
        }
      },
    );
  };

  return (
    <>
      <LoadingBar height={5} color="#47AD1D" ref={ref} />
      <Header />

      <AviationModelTable
        toggleAddModal={toggleAddModal}
        selectedModel={selectedModel}
        setSelectedModel={setSelectedModel}
        editData={editData}
        setEditData={setEditData}
        modelName={modelName}
        setModelName={setModelName}
        page={page}
        order={order}
        sort={sort}
        setSort={setSort}
        setOrder={setOrder}
        setPage={setPage}
        loading={loadingModel}
        filteredCount={filteredCount}
        totalPages={totalPages}
        totalCount={totalCount}
        data={modelData}
        keyword={keyword}
        setKeyword={setKeyword}
        handleFilter={handleFilterModel}
        response={response}
        setResponse={setResponse}
      />

      <AddAvaitionModel
        show={showAddModal}
        onHide={toggleAddModal}
        data={modelName}
        setData={setModelName}
        handleEditModel={handleEditModel}
        handleAddModel={handleAddModel}
        aviationType={selectedModel}
        loading={loading}
        keyword={keyword}
        setKeyword={setKeyword}
        handleFilterModel={handleFilterModel}
        setSelectedModel={setSelectedModel}
        editData={editData}
        setEditData={setEditData}
        loadingModel={loadingModel}
        options={aviationTypeOptions}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getModel: (data, callback) => dispatch(getAviationModelList(data, callback)),
  addModel: (data, callback) => dispatch(addAviationModel(data, callback)),
  getManufacturers: (callback) => dispatch(getManufacturers(callback)),
});

const mapStateToProps = (state) => ({
  getListings: getListings(state),
  getMisc: getMisc(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(AviationModels));
