import queryString from "query-string";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { getProfileOptions, getUser } from "../../store/users";
import { toast } from "react-toastify";
import send from "../../include/images/send-btn.svg";
import AlertError from "../../common/alerts/alertError";
import LoadingBar from "react-top-loading-bar";
import "../../include/css/invoice.css";
import "../../include/css/weeklyReport.css";
import successIcon from "../../include/images/success-icon.png";

import moment from "moment";
import { sendNDA } from "../../store/bids";
import AlertSuccess from "../../common/alerts/alertSuccess";
import ReactSignatureCanvas from "react-signature-canvas";
import { Helmet } from "react-helmet";
import { v4 as uuidv4 } from "uuid";
import { Amplify, Storage } from "aws-amplify";
import Select from "react-select";
import NextButton from "../../common/form/nextButton";
import { getCountries, productLocationDetails } from "../../store/productLocation";
import Joi from "joi";
import { validateErrorMessage } from "../../common/validation/validation";
import _ from "lodash";
import { getListingMedia, getNcndaTemplate } from "../../store/listingsMedia";

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    region: "us-east-1",
  },
  Storage: {
    bucket: process.env.REACT_APP_S3_UPLOAD_BUCKET,
    region: "us-east-1",
  },
});

const customStyles = {
  indicatorSeparator: (styles) => ({ display: "none" }),

  option: (provided, state) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "#47ad1d",
      color: "#F5F5F5",
    },
    display: "-webkit-box",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    color: state.isSelected ? "#fff" : "black",
    backgroundColor: state.isSelected ? "#47ad1d" : provided.backgroundColor,
    maxHeight: "84px",
  }),
  control: (base, state) => ({
    ...base,
    height: 42,
    minHeight: 42,
    background: state.isDisabled ? "#e9ecef" : "#ffff",
    borderColor: state.isFocused ? "#47AD1D" : "#cccccc",
    boxShadow: state.isFocused ? "0 0 0 0.5px #47AD1D" : "0",
    border: state.isHovered ? "0" : "1px solid #cccccc",
    color: state.isDisabled ? "black" : "#f5f5f5",

    "&:hover": {
      borderColor: state.isFocused ? "#47AD1D" : "0",
      boxShadow: state.isFocused ? "0 0 0 0.5px #47AD1D" : "0",
    },
    "&:disabled": {
      background: "#e9ecef",
    },
  }),
  multiValueRemove: (base, state) => ({
    ...base,
    "&:hover": {
      backgroundColor: "rgb(95, 50, 187, 10%)",
      color: "#6119c0",
    },
  }),
};

const NcndAgreement = (props) => {
  const ref = useRef();
  const signCanvas = useRef(null);
  const history = useHistory();
  const location = useLocation();
  const profile = props?.getUser?.profileOptions?.detail;
  const countryOption = props?.productLocationDetails?.countries?.data;

  const handleRemoveExpiredParam = () => {
    const url = new URL(window.location.href);
    if (url.searchParams.has("expired")) {
      url.searchParams.delete("expired");
      history.replace(url.pathname + url.search);
    }
  };

  const companyOption = [
    { label: "Select Company", value: "" },
    { label: "Salvex Metals, Inc.", value: "Salvex Metals, Inc." },
    { label: "Salvex Aviation", value: "Salvex Aviation" },
    { label: "Salvex Solar, Inc.", value: "Salvex Solar, Inc." },
    { label: "Salvex Energy, Inc.", value: "Salvex Energy, Inc." },
    { label: "Salvex Food, Inc.", value: "Salvex Food, Inc." },
    { label: "Salvex Commodities, Inc.", value: "Salvex Commodities, Inc." },
  ];

  const [isEmailSent, setIsEmailSent] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [expired, setExpired] = useState(false);
  const [company, setCompany] = useState({
    label: "Select Company",
    value: "",
  });
  const [country, setCountry] = useState({
    old: 0,
    description: "",
  });
  const [data, setData] = useState({
    userId: "",
    name: "",
    title: "",
    email: "",
    country: "",
    company: "",
    company_address: "",

    salvex_name: "",
    salvex_company: "",
    salvex_title: "",
    salvex_e_signature: "",
  });
  const onChange = (e) => {
    const val = { ...data };
    val[e.target.name] = e.target.value;
    setData(val);
  };

  const clearSignature = () => {
    signCanvas.current.clear();
  };

  const schemaDoc = Joi.object({
    userId: Joi.string()
      .label("Email")
      .required()
      .error((errors) => validateErrorMessage(errors)),
    name: Joi.string()
      .label("Name")
      .required()
      .error((errors) => validateErrorMessage(errors)),
    title: Joi.string()
      .label("Title")
      .allow("")
      .error((errors) => validateErrorMessage(errors)),
    email: Joi.string()
      .label("Email")
      .required()
      .error((errors) => validateErrorMessage(errors)),
    country: Joi.string()
      .label("Country")
      .allow("")
      .error((errors) => validateErrorMessage(errors)),
    company: Joi.string()
      .label("Company Name")
      .allow("")
      .error((errors) => validateErrorMessage(errors)),
    company_address: Joi.string()
      .label("Company Address")
      .allow("")
      .error((errors) => validateErrorMessage(errors)),
    salvex_name: Joi.string()
      .label("Name")
      .required()
      .error((errors) => validateErrorMessage(errors)),
    salvex_company: Joi.string()
      .label("Company Name")
      .allow("")
      .error((errors) => validateErrorMessage(errors)),
    salvex_title: Joi.string()
      .label("Titlte")
      .allow("")
      .error((errors) => validateErrorMessage(errors)),
    salvex_e_signature: Joi.string()
      .label("Signature")
      .allow("")
      .error((errors) => validateErrorMessage(errors)),
  });

  const validation = (type = false) => {
    const error = schemaDoc.validate(_.pickBy(data, _.identity), {
      abortEarly: type,
    });
    if (error.error && error.error.details) {
      const errors = {};
      error.error.details.forEach((element) => {
        errors[element.context.key] = element.message;
      });
      setErrors(errors);
      return false;
    }
    setErrors("");
    return true;
  };

  useEffect(() => {
    ref.current.continuousStart();
    props?.getNcndaTemplate({ type: "ncnda" }, (res) => {
      if (res.status === 200) {
        ref.current.complete();
      } else {
        ref.current.complete();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  }, []);

  useEffect(() => {
    if (location && location.search) {
      ref && ref.current && ref.current.continuousStart();
      setLoading(true);
      let id = queryString.parse(location.search).user_id.trim();
      const catId = queryString.parse(location.search).cat_id ? queryString.parse(location.search).cat_id.trim() : "";
      const exp = queryString.parse(location.search).expired;
      if (exp) {
        setExpired(true);
      } else {
        setExpired(false);
      }
      if (catId) {
        if (catId == 1008) {
          setCompany({ label: "Salvex Metals, Inc.", value: "Salvex Metals, Inc." });
        } else if (catId == 1176) {
          setCompany({ label: "Salvex Aviation", value: "Salvex Aviation" });
        } else if (catId == 1395) {
          setCompany({ label: "Salvex Solar, Inc.", value: "Salvex Solar, Inc." });
        } else if (catId == 1294 || catId == 1384) {
          setCompany({ label: "Salvex Energy, Inc.", value: "Salvex Energy, Inc." });
        } else if (catId == 1002) {
          setCompany({ label: "Salvex Food, Inc.", value: "Salvex Food, Inc." });
        } else {
          setCompany({ label: "Salvex Commodities, Inc.", value: "Salvex Commodities, Inc." });
        }
      } else {
        setCompany({
          label: "Select Company",
          value: "",
        });
      }
      props.getProfile(id, (res) => {
        if (res && res.status === 200) {
          const response = res?.data?.detail;
          props?.getCountries((res1) => {
            if (res1.status === 200) {
              const vals = res1?.data?.data;
              setCountry(vals.find((val) => val.description === response?.nda_detail?.country));
            }
          });
          ref && ref.current && ref.current.complete();
          setLoading(false);
          // setCompany(companyOption.find((val) => val.value === response?.nda_detail?.salvex_company));
          const val = { ...data };
          val.userId = id;
          val.name = response?.nda_signed_date
            ? response?.nda_detail?.name
              ? response?.nda_detail?.name
              : ""
            : response?.p_name01
            ? response?.p_name01
            : "";
          val.email = response?.nda_signed_date
            ? response?.nda_detail?.email
              ? response?.nda_detail?.email
              : ""
            : response?.p_email
            ? response?.p_email
            : "";
          val.title = response?.nda_detail?.title ? response?.nda_detail?.title : "";
          val.company = response?.nda_detail?.company ? response?.nda_detail?.company : "";
          val.company_address = response?.nda_detail?.company_address ? response?.nda_detail?.company_address : "";
          val.country = response?.nda_detail?.country ? response?.nda_detail?.country : "";
          val.salvex_name = response?.nda_detail?.salvex_name ? response?.nda_detail?.salvex_name : "";
          val.salvex_title = response?.nda_detail?.salvex_title ? response?.nda_detail?.salvex_title : "";
          val.salvex_company = catId
            ? catId == 1008
              ? "Salvex Metals, Inc."
              : catId == 1176
              ? "Salvex Aviation"
              : catId == 1395
              ? "Salvex Solar, Inc."
              : catId == 1294 || catId == 1384
              ? "Salvex Energy, Inc."
              : catId == 1002
              ? "Salvex Food, Inc."
              : "Salvex Commodities, Inc."
            : "";
          val.salvex_e_signature = response?.nda_detail?.salvex_e_signature
            ? response?.nda_detail?.salvex_e_signature
            : "";
          setData(val);
        } else {
          ref && ref.current && ref.current.complete();
          setLoading(false);
          toast(<AlertError message={res.data.message ? res.data.message : "something Went Wrong"} />);
        }
      });
    }
  }, []);

  const handleSendNDA = () => {
    if (signCanvas.current.isEmpty()) {
      return toast(<AlertError message={"Signature should not be empty"} />);
    }
    if (!validation()) return;
    try {
      ref?.current?.continuousStart();
      setLoading(true);
      const dataURL = signCanvas.current.toDataURL("image/png");
      const dataURLToBlob = (dataURL) => {
        const byteString = atob(dataURL.split(",")[1]);
        const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
      };
      const signatureBlob = dataURLToBlob(dataURL);
      const fileName = `newSalvex/sign/${uuidv4()}.png`;
      Storage.put(fileName, signatureBlob, {
        completeCallback: (event) => {},
        progressCallback: (progress) => {},
        errorCallback: (err) => {
          toast(<AlertError message={err?.message ? err?.message : "Something Went Wrong"} />);
        },
      }).then((result) => {
        const payload = {
          name: data.name || "",
          email: data.email || "",
          title: data.title || "",
          country: data.country || "",
          company: data.company || "",
          salvex_name: data.salvex_name || "",
          salvex_company: data.salvex_company || "",
          salvex_title: data.salvex_title || "",
          company_address: data.company_address || "",
          salvex_e_signature: "public/" + result.key,
        };
        props.sendNDA(data.userId, payload, (res) => {
          setLoading(false);
          ref?.current?.complete();
          if (res.status === 200) {
            handleRemoveExpiredParam();
            setIsEmailSent(true);
          } else {
            toast(<AlertError message={res.data.message || "Something Went Wrong"} />);
          }
        });
      });
    } catch (error) {
      console.error("Upload or API call failed:", error);
      setLoading(false);
      ref?.current?.complete();
      toast(<AlertError message={"Failed to upload signature or save data"} />);
    }
  };

  const templateData =
    props?.getListingMedia?.ncnda?.templates
      ?.find((val) => val?.sub_type === "body2b")
      ?.content?.replace(/#{salvex_company}/g, `${data.salvex_company ? data.salvex_company : "________"}`)
      ?.replace(/#{company}/g, `${data.company ? data.company : "________"}`)
      ?.replace(/#{country}/g, `${data.country ? data.country : "________"}`)
      ?.replace(/#{company_address}/g, `${data.company_address ? data.company_address : "________"}`)
      ?.replace(
        /#{day}/g,
        `${profile?.nda_signed_date ? moment(profile?.nda_signed_date).format("D") : moment().format("D")}`,
      )
      ?.replace(
        /#{month}/g,
        `${profile?.nda_signed_date ? moment(profile?.nda_signed_date).format("MMMM") : moment().format("MMMM")}`,
      )
      ?.replace(
        /#{year}/g,
        `${profile?.nda_signed_date ? moment(profile?.nda_signed_date).format("YYYY") : moment().format("YYYY")}`,
      ) || "";

  return (
    <>
      <Helmet>
        <style>{`html, body { min-height: 100vh; margin-top:-40px }`}</style>
      </Helmet>
      <LoadingBar height={5} color="#47AD1D" ref={ref} />
      {!isEmailSent ? (
        <div style={{ width: "100%", minHeight: "30vh", backgroundColor: "#fff" }}>
          <div
            style={{
              width: "100%",
              maxWidth: "1440px",
              padding: "30px 130px",
              marginLeft: "auto",
              marginRight: "auto",
              backgroundColor: "#fff",
            }}
            className="create-invoice"
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <h4>NON-CIRCUMVENTION NON-DISCLOSURE AGREEMENT</h4>
              </div>
              <div>
                <div style={{ width: "98px", marginLeft: "auto", marginBottom: "7px" }}>
                  <img src={"https://s3.amazonaws.com/media-dev.salvex.com/emails/salvex2x.png"} alt="" />
                </div>
                <div style={{ fontSize: "16px", lineHeight: "1.2", marginBottom: "10px", textAlign: "right" }}>
                  440 Louisiana St. Ste. 550 <br /> Houston, TX 77002
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                    gap: "25px",
                    fontSize: "14px",
                    marginBottom: "5px",
                  }}
                >
                  <div>
                    <strong style={{ fontWeight: "700", display: "inline-block", marginRight: "4px" }}>Phone :</strong>{" "}
                    713 229 9000
                  </div>
                </div>
              </div>
            </div>
            <div style={{ width: "100%", height: "1px", backgroundColor: "#000", margin: "19px  0 26px" }} />
            {expired ? (
              <table width="100%" cellspacing="0" cellpadding="0" className="w-email-table">
                <tr>
                  <td class="xs-p-0" align="center" valign="top" style={{ padding: "5px 0" }}>
                    <table
                      class="w-full-mobile"
                      cellspacing="0"
                      cellpadding="0"
                      style={{ width: "100%", minHeight: "100vh" }}
                    >
                      <tbody>
                        <tr>
                          <td align="center" valign="top" style={{ verticalAlign: "middle" }}>
                            <table style={{ width: "100%" }} cellspacing="0" cellpadding="0">
                              <tr>
                                <td class="xs-p-15" style={{ padding: "15px 32px" }}>
                                  <table width="100%" cellspacing="0" cellpadding="0">
                                    <tr>
                                      <td
                                        style={{
                                          fontSize: "20px",
                                          lineHeight: "1.6",
                                          fontWeight: "700",
                                          textAlign: "center",

                                          paddingTop: "40px",
                                        }}
                                      >
                                        The agreement has expired. Please resend the agreement.
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        style={{
                                          textAlign: "center",
                                          paddingTop: "20px",
                                        }}
                                      >
                                        <button className={"btn-nxt btn-default"} onClick={() => setExpired(false)}>
                                          {"Resend"}
                                        </button>
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </table>
            ) : (
              <div>
                <div
                  className="mb-3"
                  dangerouslySetInnerHTML={{
                    __html: templateData,
                  }}
                />
                <div className="d-flex">
                  <div className="w-50 d-flex flex-column" style={{ paddingRight: "5px" }}>
                    <span style={{ fontWeight: "600", fontSize: "20px", marginBottom: "12px" }}>SALVEX</span>
                    <div class="input-group-row w-100" style={{ marginBottom: "8px", marginLeft: "0" }}>
                      <div class="igr-box">
                        <label for="" style={{ marginBottom: "0px", marginTop: "4px" }}>
                          Signature
                        </label>
                        <div class="igr-input-group">
                          <div class="igr-input">
                            {profile?.nda_signed_date ? (
                              <img
                                className="sign-canvas-img"
                                src={process.env.REACT_APP_CHAT_MEDIA + profile?.nda_detail?.salvex_e_signature}
                                alt=""
                              />
                            ) : (
                              <ReactSignatureCanvas
                                ref={signCanvas}
                                canvasProps={{ height: 200, className: "sign-canvas" }}
                                clearOnResize={false}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      {!profile?.nda_signed_date && (
                        <div className="ms-auto mt-2">
                          <div class="ph-btn-item">
                            <button
                              type="button"
                              class="btn"
                              style={{ backgroundColor: "#d4af37", color: "white" }}
                              onClick={clearSignature}
                            >
                              <span className="text-white">Clear Signature</span>
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                    <div class="input-group-row w-100" style={{ marginBottom: "8px", marginLeft: "0" }}>
                      <div class="igr-box">
                        <label for="" style={{ marginBottom: "0px", marginTop: "4px" }}>
                          Name
                        </label>
                        <div class="igr-input-group">
                          <div class="igr-input">
                            <input
                              type="text"
                              placeholder="Name"
                              readOnly={profile?.nda_signed_date}
                              class="form-control"
                              name="salvex_name"
                              value={data.salvex_name}
                              onChange={(e) => onChange(e)}
                            />
                            {errors && errors.salvex_name ? <p className="error-text">{errors.salvex_name}</p> : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="input-group-row w-100" style={{ marginBottom: "8px", marginLeft: "0" }}>
                      <div class="igr-box">
                        <label for="" style={{ marginBottom: "0px", marginTop: "4px" }}>
                          Company Name
                        </label>
                        {profile?.nda_signed_date ? (
                          <div class="igr-input-group">
                            <div class="igr-input">
                              <input
                                type="text"
                                placeholder="Company Name"
                                class="form-control"
                                value={data.salvex_company}
                                readOnly
                              />
                            </div>
                          </div>
                        ) : (
                          <div class="igr-input-group">
                            <div class="igr-input">
                              <Select
                                styles={customStyles}
                                value={company}
                                placeholder="Select Company"
                                options={companyOption}
                                onChange={(e) => {
                                  const val = { ...data };
                                  val.salvex_company = e.value;
                                  setCompany(e);
                                  setData(val);
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div class="input-group-row w-100" style={{ marginBottom: "8px", marginLeft: "0" }}>
                      <div class="igr-box">
                        <label for="" style={{ marginBottom: "0px", marginTop: "4px" }}>
                          Title
                        </label>
                        <div class="igr-input-group">
                          <div class="igr-input">
                            <input
                              type="text"
                              placeholder="Title"
                              class="form-control"
                              readOnly={profile?.nda_signed_date}
                              name="salvex_title"
                              value={data.salvex_title}
                              onChange={(e) => onChange(e)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-50 d-flex flex-column" style={{ paddingLeft: "5px" }}>
                    <span style={{ fontWeight: "600", fontSize: "20px", marginBottom: "12px" }}>DISCLOSING PARTY</span>
                    {profile?.nda_signed_date && (
                      <div class="input-group-row w-100" style={{ marginBottom: "8px", marginLeft: "0" }}>
                        <div class="igr-box">
                          <label for="" style={{ marginBottom: "0px", marginTop: "4px" }}>
                            Signature
                          </label>
                          <div class="igr-input-group">
                            <div class="igr-input">
                              <img
                                className="sign-canvas-img"
                                src={process.env.REACT_APP_CHAT_MEDIA + profile?.nda_detail?.e_signature}
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div class="input-group-row w-100" style={{ marginBottom: "8px", marginLeft: "0" }}>
                      <div class="igr-box">
                        <label for="" style={{ marginBottom: "0px", marginTop: "4px" }}>
                          Email
                        </label>
                        <div class="igr-input-group">
                          <div class="igr-input">
                            <input
                              type="text"
                              placeholder="Email"
                              class="form-control"
                              readOnly={profile?.nda_signed_date}
                              name="email"
                              value={data.email}
                              onChange={(e) => onChange(e)}
                            />
                            {errors && errors.email ? <p className="error-text">{errors.email}</p> : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="input-group-row w-100" style={{ marginBottom: "8px", marginLeft: "0" }}>
                      <div class="igr-box">
                        <label for="" style={{ marginBottom: "0px", marginTop: "4px" }}>
                          Name
                        </label>
                        <div class="igr-input-group">
                          <div class="igr-input">
                            <input
                              type="text"
                              placeholder="Name"
                              class="form-control"
                              readOnly={profile?.nda_signed_date}
                              name="name"
                              value={data.name}
                              onChange={(e) => onChange(e)}
                            />
                            {errors && errors.name ? <p className="error-text">{errors.name}</p> : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="input-group-row w-100" style={{ marginBottom: "8px", marginLeft: "0" }}>
                      <div class="igr-box">
                        <label for="" style={{ marginBottom: "0px", marginTop: "4px" }}>
                          Company Name
                        </label>
                        <div class="igr-input-group">
                          <div class="igr-input">
                            <input
                              type="text"
                              placeholder="Company Name"
                              class="form-control"
                              readOnly={profile?.nda_signed_date}
                              name="company"
                              value={data.company}
                              onChange={(e) => onChange(e)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="input-group-row w-100" style={{ marginBottom: "8px", marginLeft: "0" }}>
                      <div class="igr-box">
                        <label for="" style={{ marginBottom: "0px", marginTop: "4px" }}>
                          Company Address
                        </label>
                        <div class="igr-input-group">
                          <div class="igr-input">
                            <input
                              type="text"
                              placeholder="Company Address"
                              class="form-control"
                              readOnly={profile?.nda_signed_date}
                              name="company_address"
                              value={data.company_address}
                              onChange={(e) => onChange(e)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="input-group-row w-100" style={{ marginBottom: "8px", marginLeft: "0" }}>
                      <div class="igr-box">
                        <label for="" style={{ marginBottom: "0px", marginTop: "4px" }}>
                          Company Country
                        </label>
                        {profile?.nda_signed_date ? (
                          <div class="igr-input-group">
                            <div class="igr-input">
                              <input
                                type="text"
                                placeholder="Company Country"
                                class="form-control"
                                value={data.country}
                                readOnly
                              />
                            </div>
                          </div>
                        ) : (
                          <div class="igr-input-group">
                            <div class="igr-input">
                              <Select
                                styles={customStyles}
                                value={country}
                                isDisabled={profile?.nda_signed_date}
                                placeholder="Select Country"
                                options={countryOption}
                                getOptionLabel={(val) => val.description}
                                getOptionValue={(val) => val.old}
                                onChange={(e) => {
                                  const val = { ...data };
                                  val.country = e.description;
                                  setData(val);
                                  setCountry(e);
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div class="input-group-row w-100" style={{ marginBottom: "8px", marginLeft: "0" }}>
                      <div class="igr-box">
                        <label for="" style={{ marginBottom: "0px", marginTop: "4px" }}>
                          Title
                        </label>
                        <div class="igr-input-group">
                          <div class="igr-input">
                            <input
                              type="text"
                              placeholder="Title"
                              class="form-control"
                              readOnly={profile?.nda_signed_date}
                              name="title"
                              value={data.title}
                              onChange={(e) => onChange(e)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-3 d-flex justify-content-end">
                  {profile?.nda_signed_date ? (
                    <button
                      className="btn-nxt btn-default"
                      onClick={() =>
                        window.open(
                          `${process.env.REACT_APP_S3_PRIVATE_DOC_BUCKET}/${profile?.nda_detail?.file_name}`,
                          "_blank",
                        )
                      }
                    >
                      Download
                    </button>
                  ) : (
                    <button
                      className={loading ? "btn-nxt btn-default  disabled btn-loading" : "btn-nxt btn-default"}
                      onClick={handleSendNDA}
                    >
                      {loading ? "Loading..." : "Send"}
                    </button>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <table width="100%" cellspacing="0" cellpadding="0" className="w-email-table">
          <tr>
            <td class="xs-p-0" align="center" valign="top" style={{ padding: "5px 0" }}>
              <table
                class="w-full-mobile"
                cellspacing="0"
                cellpadding="0"
                style={{ width: "800px", minHeight: "100vh", background: "#fff" }}
              >
                <tbody>
                  <tr>
                    <td align="center" valign="top" style={{ verticalAlign: "middle" }}>
                      <table style={{ width: "100%" }} cellspacing="0" cellpadding="0">
                        <tr>
                          <td class="xs-p-15" style={{ padding: "15px 32px" }}>
                            <table width="100%" cellspacing="0" cellpadding="0">
                              <tr>
                                <td style={{ textAlign: "center", paddingBottom: "5px" }}>
                                  <h6>Success!</h6>
                                </td>
                              </tr>
                              <tr>
                                <td style={{ height: "60px", width: "60px", textAlign: "center" }}>
                                  {" "}
                                  <img src={successIcon} alt="Not Found" style={{ height: "100%" }} />
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    fontSize: "20px",
                                    lineHeight: "1.6",
                                    fontWeight: "700",
                                    textAlign: "center",

                                    paddingTop: "40px",
                                  }}
                                >
                                  Email has been sent successfully!
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    textAlign: "center",
                                    paddingTop: "20px",
                                  }}
                                >
                                  <a href="/home" class="btn btn-primary">
                                    Go back to home
                                  </a>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </table>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getProfile: (id, callback) => dispatch(getProfileOptions(id, callback)),
  sendNDA: (id, data, callback) => dispatch(sendNDA(id, data, callback)),
  getNcndaTemplate: (params, callback) => dispatch(getNcndaTemplate(params, callback)),
  getCountries: (callback) => dispatch(getCountries(callback)),
});
const mapStateToProps = (state) => ({
  getUser: getUser(state),
  productLocationDetails: productLocationDetails(state),
  getListingMedia: getListingMedia(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(NcndAgreement));
