import { createSelector, createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";

const slice = createSlice({
  name: "emailClickers",
  initialState: {
    loading: false,
    count: null,
    emailClickers: [],
    user: {},
    recipients: [],
  },
  reducers: {
    countRequested: (state, action) => {
      state.loading = true;
    },
    countRecieved: (state, action) => {
      state.count = action.payload;
      state.loading = false;
    },
    countFailed: (state, action) => {
      state.loading = false;
    },
    emailClickerRequested: (state, action) => {
      state.loading = true;
    },
    emailClickerRecieved: (state, action) => {
      state.emailClickers = action.payload;
      state.loading = false;
    },
    emailClickerFailed: (state, action) => {
      state.loading = false;
    },
    userDetailRequested: (state, action) => {
      state.loading = true;
    },
    userDetailRecieved: (state, action) => {
      state.user = action.payload;
      state.loading = false;
    },
    userDetailFailed: (state, action) => {
      state.loading = false;
    },
    recipientRequested: (state, action) => {
      state.loading = true;
    },
    recipientRecieved: (state, action) => {
      state.recipients = action.payload;
      state.loading = false;
    },
    recipientFailed: (state, action) => {
      state.loading = false;
    },
  },
});

export const {
  countRequested,
  countRecieved,
  countFailed,
  emailClickerRequested,
  emailClickerRecieved,
  emailClickerFailed,
  userDetailRequested,
  userDetailRecieved,
  userDetailFailed,
  recipientRequested,
  recipientRecieved,
  recipientFailed,
} = slice.actions;
export default slice.reducer;

//Action Creators
const baseUrl = "pushEmailClicker";
const countUrl = "pushEmailClicker/count";

//Push Email Clickers Count
export const getEmailClickersCount = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${countUrl}/${params}`,
      method: "get",
      callback,
      onStart: countRequested.type,
      onSuccess: countRecieved.type,
      onError: countFailed.type,
    }),
  );
};

//Get Push Email Clickers
export const getPushEmailClickers = (id, params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/list/${id}`,
      params,
      method: "get",
      callback,
      onStart: emailClickerRequested.type,
      onSuccess: emailClickerRecieved.type,
      onError: emailClickerFailed.type,
    }),
  );
};

export const getAllPushEmailClickers = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/list/${params}`,
      method: "get",
      callback,
      onStart: emailClickerRequested.type,
      onSuccess: emailClickerRecieved.type,
      onError: emailClickerFailed.type,
    }),
  );
};
//Get User Detail
export const getUserDetail = (userId, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/detail/${userId}`,
      method: "get",
      callback,
      onStart: userDetailRequested.type,
      onSuccess: userDetailRecieved.type,
      onError: userDetailFailed.type,
    }),
  );
};

//Buyer Interest
export const buyerInterest = (params, userId, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/buyerInterest/${params}?user_id=${userId}`,
      method: "put",
      callback,
    }),
  );
};

//Call
export const callEmailClicker = (params, userId, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/call/${params}?call_user_id=${userId}`,
      method: "put",
      callback,
    }),
  );
};

//Get Recipient
export const getRecipient = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/recipient`,
      method: "get",
      callback,
      onStart: recipientRequested.type,
      onSuccess: recipientRecieved.type,
      onError: recipientFailed.type,
    }),
  );
};

//Add Message
export const addMessage = (params, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/message/${params}`,
      method: "post",
      data,
      callback,
    }),
  );
};

//Delete Message
export const deleteMessage = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/message/${params}`,
      method: "delete",
      callback,
    }),
  );
};

//Quick Edit
export const quickEdit = (params, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/quickEdit/${params}`,
      method: "put",
      data,
      callback,
    }),
  );
};
//Delete comments in other Deal section
export const deleteOtherDealChat = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/message/${params}`,
      method: "delete",
      callback,
    }),
  );
};
//Push click Unsubscribe
export const changeUnsubscribe = (params, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/unsubscribe/${params}`,
      method: "put",
      data,
      callback,
    }),
  );
};

export const emailClickersDetail = createSelector(
  (state) => state.entities.emailClickers,
  (emailClickers) => emailClickers,
);
