import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getLatestActivity,
  getPriceInfo,
  getRevisitSellerBid,
  quantityConfirmation,
  saveBidLog,
  sendMailToManager,
  updatePriceFeature,
} from "../../../store/latestActivity";
import moment from "moment";
import DateFormat from "../../../common/DateFormat/dateFormat";
import {
  getListingProductDetail,
  listingProductDetailReceived,
  productDetails,
  updateComparablePrice,
  updateProductDetails,
} from "../../../store/listingProduct";
import { toast } from "react-toastify";
import AlertError from "../../../common/alerts/alertError";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import ComparablePrice from "../../../layouts/modals/pricingDetail/pricingDetailModal";
import EditProductDetails from "../../../layouts/modals/editProductDetails";
import Skeleton from "react-loading-skeleton";
import MaxBidModal from "../../../layouts/modals/bids/maxBidModal";
import { setMaxBid } from "../../../store/latestActivity";
import { getLatestBids } from "../../../store/latestActivity";
import { deactivateBid } from "../../../store/latestActivity";
import ActivationModal from "../../../layouts/modals/bids/activationModal";
import DeclineModal from "../../../layouts/modals/bids/declineModal";
import { declineBid } from "../../../store/bids";
import icon1 from "../../../include/images/icon___1.svg";
import icon2 from "../../../include/images/icon__2.svg";
import icon3 from "../../../include/images/red-hand.png";
import extLink from "../../../include/images/external-link.svg";
import helpMsg from "../../../include/images/Help 01.svg";
import SendMail from "../../../layouts/modals/latestActivity/sendMail";
import { detailListing, getListings } from "../../../store/listings";

const LatestActivity = (props) => {
  const val = localStorage.getItem("data");
  const session = JSON.parse(val);
  const pipeCategories = [1115, 1295, 1321, 1322, 1323, 1400, 1404, 1442, 1515, 1754];
  const latestBids = props.getLatestActivity?.latestBids || {};
  const priceInfo = props.getLatestActivity?.priceInfo || {};
  const bidLog = props?.getLatestActivity?.bidLog?.data;
  const productDetails = props?.getListingProductDetail?.detail?.detail || {};
  const [editModal, setEditModal] = useState(false);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [comparablePriceModal, setComparablePriceModal] = useState(false);
  const [comparablePriceData, setComparablePriceData] = useState([]);
  const [comparablePriceData1, setComparablePriceData1] = useState([]);
  const [loadingSave, setLoadingSave] = useState(false);
  const [showMaxBidModal, setShowMaxBidModal] = useState(false);
  const [bidId, setBidId] = useState("");
  const [loadingMaxBid, setLoadingMaxBid] = useState(false);
  const [showActivationModal, setShowActivationModal] = useState(false);
  const [loadingActivation, setLoadingActivation] = useState(false);
  const [priceFeatureData, setPriceFeatureData] = useState({
    price_type: "",
  });
  const [revisitSellerBid1, setRevisitSellerBid1] = useState("");
  const [revisitSellerBid2, setRevisitSellerBid2] = useState("");
  const [showTerms, setShowTerms] = useState(false);
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const [loadingDecline, setLoadingDecline] = useState(false);
  const [declineData, setDeclineData] = useState({
    decline: 0,
    new_message: "",
  });

  const [showSendMailModal, setShowSendMailModal] = useState(false);
  const [loadingSendMail, setLoadingSendMail] = useState(false);
  const [mailData, setMailData] = useState({
    bid_id: 0,
    managed_by_id: 0,
    new_message:
      "I am working with a buyer account you manage on this listing, and I am requesting your help communicating with them to help move this deal forward.",
    message_prompt: false,
  });
  //Toggle showSendMailModal
  const toggleShowSendModal = (data) => {
    setMailData({
      bid_id: data?.b_id,
      managed_by_id: data?.managed_by_id,
      new_message:
        "I am working with a buyer account you manage on this listing, and I am requesting your help communicating with them to help move this deal forward.",
      message_prompt: false,
    });
    setShowSendMailModal(!showSendMailModal);
  };
  //Handle Send Mail To Manager
  const handleSendMail = () => {
    setLoadingSendMail(true);
    props.refStart();
    props.sendMailToManager(props.auctionId, mailData, (res) => {
      if (res.status === 200) {
        props.refStop();
        setLoadingSendMail(false);
        toggleShowSendModal();
      } else {
        props.refStop();
        setLoadingSendMail(false);
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const toggleDeclineModal = () => {
    setShowDeclineModal(!showDeclineModal);
  };
  //Handle Decline
  const handleDecline = () => {
    setLoadingDecline(true);
    props.refStart();
    props.declineBid(props.auctionId, declineData, (res) => {
      if (res.status === 200) {
        props.getLatestBids(props.auctionId, (res) => {});
        setLoadingDecline(false);
        props.refStop();
        toggleDeclineModal();
        toast(<AlertSuccess message={"Information Saved"} />);
      } else {
        setLoadingDecline(false);
        props.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  useEffect(() => {
    setRevisitSellerBid1(latestBids?.auctionInfo?.revisit_seller_bid_date);
    setRevisitSellerBid2(latestBids?.auctionInfo?.revisit_seller_bid_date);
  }, [latestBids]);

  const toggleMaxBidModal = () => {
    setShowMaxBidModal(!showMaxBidModal);
  };
  const handleMaxBid = () => {
    setLoadingMaxBid(true);
    props.refStart();
    const data = {};
    data.b_id = bidId;
    props.setMaxBid(props.auctionId, data, (res) => {
      if (res.status === 200) {
        props.getLatestBids(props.auctionId, (res) => {});
        setLoadingMaxBid(false);
        props.refStop();
        toast(<AlertSuccess message={"Information Saved"} />);
        toggleMaxBidModal();
      } else {
        setLoadingMaxBid(false);
        props.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const toggleActivationModal = () => {
    setShowActivationModal(!showActivationModal);
  };

  const handleActivation = () => {
    setLoadingActivation(true);
    props.refStart();
    const data = {};
    data.b_id = bidId;
    props.deactivateBid(props.auctionId, data, (res) => {
      if (res.status === 200) {
        props.getLatestBids(props.auctionId, (res) => {});
        setLoadingActivation(false);
        props.refStop();
        toast(<AlertSuccess message={"Information Saved"} />);
        toggleActivationModal();
      } else {
        setLoadingActivation(false);
        props.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const handleSaveBidLog = (data) => {
    props.refStop();
    props.saveBidLog(props.auctionId, data, (res) => {
      if (res.status === 200) {
        props.refStop();
        toast(<AlertSuccess message={"Information Saved"} />);
      } else {
        props.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  useEffect(() => {
    const handleUpdatePriceFeature = () => {
      props.refStart();
      props.updatePriceFeature(props.auctionId, priceFeatureData, (res) => {
        if (res.status === 200) {
          props.refStop();
        } else {
          props.refStop();
          toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        }
      });
    };
    if (props.auctionId) {
      handleUpdatePriceFeature();
    }
  }, [priceFeatureData]);

  const handleRevisitSellerBid = (index) => {
    props.refStart();
    props.getRevisitSellerBid(props.auctionId, (res) => {
      if (res.status === 200) {
        props.getLatestBids(props.auctionId, (res) => {});
        props.refStop();
        if (index === 1) {
          setRevisitSellerBid1(res.data.revisit_seller_bid_date);
        } else if (index === 2) {
          setRevisitSellerBid2(res.data.revisit_seller_bid_date);
        }
      } else {
        props.refStop();
        toast(<AlertError messge={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  useEffect(() => {
    const data =
      props.getListingProductDetail &&
      props.getListingProductDetail.comparablePrice &&
      props.getListingProductDetail.comparablePrice.data;
    setComparablePriceData(data);
    setComparablePriceData1(data);
  }, [
    props.getListingProductDetail &&
      props.getListingProductDetail.comparablePrice &&
      props.getListingProductDetail.comparablePrice.data,
  ]);

  const toggleEditModal = () => {
    setEditModal(!editModal);
  };

  const toggleComparablePrice = () => {
    setComparablePriceModal(!comparablePriceModal);
  };

  const saveHandler = () => {
    setLoadingSave(true);
    props.refStart();
    const id = props.auctionId;
    const data =
      comparablePriceData &&
      comparablePriceData.length > 0 &&
      comparablePriceData.map((item) => ({
        amount: item.amount ? item.amount : "",
        link: item.link ? item.link : "",
        year: item.year ? item.year : "",
        location: item.location ? item.location : "",
      }));
    const payload = { price: data ? data : [] };
    props.updateComparablePrice(id, payload, (res) => {
      if (res.status === 200) {
        props.refStop();
        setLoadingSave(false);
        setComparablePriceData1(payload.price);
        toggleComparablePrice();
        toast(<AlertSuccess message={"Information Saved"} />);
      } else {
        props.refStop();
        setLoadingSave(false);
        toast(<AlertError message={res && res.data && res.data.message ? res.data.message : "Something went wrong"} />);
      }
    });
  };

  const toggleTerms = () => {
    setShowTerms(!showTerms);
  };

  const data =
    (props.getListingProductDetail &&
      props.getListingProductDetail.detail &&
      props.getListingProductDetail.detail.detail) ||
    {};
  const formatPercent = (a, b) => {
    if (b > 0) return (a / b) * 100;
    else return 0;
  };

  //Quantity Confirmation
  const handleQuantityConfirmation = (val) => {
    props.refStart();
    const payload = {
      status: val,
    };
    props.quantityConfirmation(props.auctionId, payload, (res) => {
      if (res.status === 200) {
        props.detailListing(props.auctionId, (res) => {});
        props.getLatestBids(props.auctionId, (res) => {});
        props.refStop();
      } else {
        props.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  return (
    <div class="details-page-widget-block" id={props.id}>
      {!props.isOnListing && (
        <div class="page-widget-head d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <div class="page-widget-label text-capitalize">Latest Activity</div>
          </div>
        </div>
      )}
      {session.sec_list === 100101 && !props.isOnListing ? (
        <div
          class="page-widget-empty-box text-capitalize d-flex align-items-center justify-content-center text-center"
          style={{
            height: "150px",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <span>Access requested.</span>
        </div>
      ) : (
        <div class="page-widget-body mt-3">
          <div class="col-two-column-row d-flex flex-wrap">
            {props.loadingMain ? (
              <div class="col-two-column-left">
                <div className="text-card-block-column skeleton">
                  <div className="tcbc-text-row">
                    <p className="skeleton-line">
                      <Skeleton count={3} />
                    </p>
                  </div>
                </div>
                <div className="text-card-block-column skeleton">
                  <div className="tcbc-text-row">
                    <p className="skeleton-line">
                      <Skeleton count={3} />
                    </p>
                  </div>
                </div>
                <div className="text-card-block-column skeleton">
                  <div className="tcbc-text-row">
                    <p className="skeleton-line">
                      <Skeleton count={3} />
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <div class="col-two-column-left">
                {latestBids && latestBids?.latestBids && latestBids?.latestBids.length > 0 ? (
                  latestBids?.latestBids.map((item, i) => (
                    <div class="col-content-widget" key={i}>
                      <div class="col-content-heaing d-flex flex-wrap align-items-center">
                        <div class="col-content-heaing-count">{i + 1}.</div>
                        <div class="col-content-heaing-txt d-flex flex-wrap align-items-center">
                          <div class="col-content-heaing-logo me-2 d-flex flex-wrap align-items-center">
                            {item?.dhs_approved && <img class="l-logo-box" src={icon1} alt="" />}
                            {item.okay && <img class="l-logo-box" src={icon2} alt="" />}
                            {item?.stop_working && (
                              <img class="l-logo-box" src={icon3} width="30px" height="30px" alt="" />
                            )}
                          </div>
                          <img src={extLink} className="me-1 cursor-pointer" alt="External Link" />
                          <a href="javascript:void(0)" className="green-btn-text">
                            {item.name ? (item.name === "n/a" ? "N/A" : item.name) : "N/A"}
                          </a>
                        </div>
                      </div>
                      <table class="col-table-row">
                        <tr>
                          <td>Managed By</td>
                          <td>:</td>
                          <td>
                            <span className="fw-medium">
                              {item.managed_by ? (item.managed_by === "n/a" ? "N/A" : item.managed_by) : "N/A"}
                            </span>
                            {item.managed_by ? (
                              item.managed_by === "n/a" || item.managed_by === "N/A" ? (
                                ""
                              ) : (
                                <a href="javascript:void(0)" onClick={() => toggleShowSendModal(item)}>
                                  <img src={helpMsg} className="ms-1" alt="help" />
                                </a>
                              )
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                      </table>
                      <div class="col-text">{item.job_title ? item.job_title : ""}</div>
                      <div class="col-text">{item.company_name ? item.company_name : ""}</div>
                      <div class="col-text">{item.location && item.location?.length > 0 ? item.location[0] : ""}</div>
                      <div class="col-text">{item.phone ? item.phone : ""}</div>

                      <div class="col-num-list fw-medium position-relative d-flex">
                        <div class="col-green me-2">
                          {" "}
                          {parseInt(formatPercent(item?.CT2, item?.CT3)).toFixed()}
                          %({item?.CT2}){" "}
                        </div>
                        <div class="col-gray me-2">
                          {parseInt(formatPercent(item?.CT1, item?.CT3)).toFixed()}
                          %({item?.CT1}){" "}
                        </div>
                        <div class="col-red">
                          {parseInt(formatPercent(item?.CT0, item?.CT3)).toFixed()}
                          %({item?.CT0}){" "}
                        </div>
                        <div class="col-mail-icon">
                          <img src="include/images/mail-14x14.svg" alt="" />
                        </div>
                      </div>
                      <div class="col-label">
                        <span class="fw-medium">
                          {item.total_bid
                            ? "$" +
                              new Intl.NumberFormat("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }).format(item?.total_bid)
                            : "$0.00"}
                        </span>
                        {item?.total_bid &&
                          !isNaN(item?.total_bid) &&
                          productDetails?.pipeDetail?.tonnage &&
                          !isNaN(productDetails?.pipeDetail?.tonnage) &&
                          productDetails?.pipeDetail?.tonnage > 0 && (
                            <span class="fw-normal">
                              (
                              {(item?.total_bid / productDetails?.pipeDetail?.tonnage)?.toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }) + "/MT"}
                              )
                            </span>
                          )}
                        {" at "}
                        {item?.bid_time ? <DateFormat date={item?.bid_time} /> : "N/A"}
                      </div>
                      <table class="col-table-row">
                        {item?.seller_bid_date && item?.seller_bid_date != "" && (
                          <tr>
                            <td>
                              <em>Submitted</em>
                            </td>
                            <td>:</td>
                            <td>
                              <span class="fw-medium">
                                {" "}
                                {item.seller_bid
                                  ? "$" +
                                    new Intl.NumberFormat("en-US", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }).format(item?.seller_bid)
                                  : "$0.00"}
                              </span>{" "}
                              at {item?.seller_bid_date ? <DateFormat date={item?.seller_bid_date} /> : ""}
                            </td>
                          </tr>
                        )}
                        {item.invoice_paid === 1 || (item?.invoice_id && !item?.invoice_paid) ? (
                          <tr>
                            <td>Invoiced</td>
                            <td>:</td>
                            <td>
                              <span class="fw-medium green-text">
                                {item?.invoice_paid == 1
                                  ? "Paid"
                                  : item?.invoice_id && !item?.invoice_paid
                                  ? "Deactivated"
                                  : ""}
                              </span>
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                        <tr>
                          <td>Credit</td>
                          <td>:</td>
                          <td>
                            <span class="fw-medium">
                              {item.credit_amount
                                ? "$" +
                                  new Intl.NumberFormat("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }).format(item?.credit_amount)
                                : "$0.00"}
                            </span>
                          </td>
                        </tr>
                      </table>
                      <div class="col-button-group">
                        <div class="col-btn-list d-flex flex-wrap">
                          {!item?.deactive && (
                            <div class="col-btn-item">
                              {item?.max_bid_achieved ? (
                                <b>MAX BID ACHIEVED</b>
                              ) : (
                                <a
                                  href="javascript:void(0)"
                                  class="btn btn-auto btn-xs-size btn-default"
                                  onClick={() => {
                                    setBidId(item?.b_id);
                                    toggleMaxBidModal();
                                  }}
                                >
                                  Max Bid?
                                </a>
                              )}
                            </div>
                          )}
                        </div>
                        <div class="col-btn-list d-flex flex-wrap">
                          {/* <div class="col-btn-item">
                            <a href="javascript:void(0)" class="btn btn-auto btn-xs-size btn-primary">
                              Email
                            </a>
                          </div>
                          <div class="col-btn-item">
                            <a href="javascript:void(0)" class="btn btn-auto btn-xs-size btn-primary">
                              Bid
                            </a>
                          </div> */}
                          <div class="col-btn-item">
                            {item.invoice_id > 0 && (
                              <a
                                class="btn btn-auto btn-xs-size btn-primary"
                                href={`/invoice/view-invoice?auctionId=${props.auctionId}&id=${item.invoice_id}&date=${item.bid_time}`}
                                state={{ a: "dk" }}
                                target="_blank"
                              >
                                Invoice
                              </a>
                            )}
                          </div>
                          <div class="col-btn-item">
                            <a
                              href="javascript:void(0)"
                              class="btn btn-auto btn-xs-size btn-primary"
                              onClick={() => {
                                setBidId(item.b_id);
                                toggleActivationModal();
                              }}
                            >
                              Deactivate
                            </a>
                          </div>
                        </div>
                        <div class="col-btn-list d-flex flex-wrap">
                          <div class="col-btn-item">
                            {item?.date_declined ? (
                              <a href="javascript:void(0)" class="btn btn-auto btn-xs-size btn-danger">
                                Bid Declined {moment(item?.date_declined)?.format("MM/DD/YY")} {item?.name01}
                              </a>
                            ) : (
                              <a
                                href="javascript:void(0)"
                                class="btn btn-auto btn-xs-size btn-danger"
                                onClick={() => {
                                  setDeclineData({
                                    decline: item?.b_id,
                                    new_message: `The offer you have submitted of ${
                                      item?.total_bid > 0
                                        ? "$" +
                                          new Intl.NumberFormat("en-US", {
                                            style: "decimal",
                                            minimumFractionDigits: 2,
                                          }).format(item?.total_bid)
                                        : "$0.00"
                                    } has been declined.`,
                                  });
                                  toggleDeclineModal();
                                }}
                              >
                                Decline Bid
                              </a>
                            )}
                          </div>
                          {item?.terms && item?.terms != "" && (
                            <div class="col-btn-item">
                              <a
                                href="javascript:void(0)"
                                class="btn btn-auto btn-xs-size btn-yellow"
                                onClick={() => {
                                  setBidId(item.b_id);
                                  toggleTerms();
                                }}
                              >
                                Terms
                              </a>
                            </div>
                          )}
                        </div>
                        <div className="terms mt-2">
                          {bidId === item.b_id && showTerms && (
                            <div class="form-control" dangerouslySetInnerHTML={{ __html: item?.terms }} />
                          )}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="d-flex justify-content-start">
                    <span>No bids.</span>
                  </div>
                )}
              </div>
            )}
            {props.loadingMain ? (
              <div class="col-two-column-right p-0">
                <Skeleton width={"100%"} height={"100%"} />
              </div>
            ) : (
              <div class="col-two-column-right">
                <div class="ctcr-widget">
                  <div class="ctcr-widget-item">
                    <div class="ctcr-label">Bid Due to Seller</div>
                    <div class="ctcr-labal-txt fw-medium">
                      {latestBids?.auctionInfo?.due_date ? (
                        <DateFormat date={latestBids?.auctionInfo?.due_date} />
                      ) : (
                        "N/A"
                      )}
                    </div>
                  </div>
                  <div class="ctcr-widget-item">
                    <div className="d-flex">
                      {latestBids?.isSellerAccepted && <img src={icon2} alt="" />}
                      <div class="ctcr-label">Seller Asking Price</div>
                    </div>
                    <div class="ctcr-labal-txt fw-medium">
                      {priceInfo?.auction_details?.seller_reserve
                        ? "$" +
                          new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(
                            priceInfo?.auction_details?.seller_reserve,
                          )
                        : 0}{" "}
                      {props?.auction_details?.seller_reserve &&
                        !isNaN(props?.auction_details?.seller_reserve) &&
                        productDetails?.pipeDetail?.tonnage &&
                        !isNaN(productDetails?.pipeDetail?.tonnage) &&
                        productDetails?.pipeDetail?.tonnage > 0 && (
                          <span class="fw-normal">
                            (
                            {(
                              props?.auction_details?.seller_reserve / productDetails?.pipeDetail?.tonnage
                            )?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + "/MT"}
                            )
                          </span>
                        )}
                      <span class="fw-normal">
                        (
                        {priceInfo?.auction_details?.seller_reserve && priceInfo?.auction_details?.quantity
                          ? "$" +
                            new Intl.NumberFormat("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(
                              (
                                priceInfo?.auction_details?.seller_reserve / priceInfo?.auction_details?.quantity
                              ).toFixed(2),
                            )
                          : 0}{" "}
                        Per Unit)
                      </span>
                    </div>
                  </div>
                  <div class="ctcr-widget-item">
                    <div class="ctcr-label">Seller Asking Price Percentage</div>
                    <div class="ctcr-labal-txt fw-medium">
                      {latestBids?.auctionInfo?.orig_wholesale_value > 0
                        ? Math.round(
                            (latestBids?.auctionInfo?.seller_reserve / latestBids?.auctionInfo?.orig_wholesale_value) *
                              100,
                          ) + "%"
                        : "0%"}
                    </div>
                  </div>
                  <div class="ctcr-widget-item d-flex align-items-start justify-content-between">
                    <div class="ctcr-left">
                      <div class="ctcr-label">Public Asking Price</div>
                      <div class="ctcr-labal-txt fw-medium">
                        {priceInfo?.auction_prices?.p_value
                          ? "$" +
                            new Intl.NumberFormat("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(priceInfo?.auction_prices?.p_value)
                          : "$0.00"}
                      </div>
                      {priceInfo?.auction_prices?.p_value &&
                        !isNaN(priceInfo?.auction_prices?.p_value) &&
                        productDetails?.pipeDetail?.tonnage &&
                        !isNaN(productDetails?.pipeDetail?.tonnage) &&
                        productDetails?.pipeDetail?.tonnage > 0 && (
                          <span class="fw-normal">
                            (
                            {(priceInfo?.auction_prices?.p_value / productDetails?.pipeDetail?.tonnage)?.toLocaleString(
                              "en-US",
                              { minimumFractionDigits: 2, maximumFractionDigits: 2 },
                            ) + "/MT"}
                            )
                          </span>
                        )}
                    </div>
                    <div class="ctcr-right">
                      <div class="form-check dark-tick-checkbox">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value="chk_asking_price_feature"
                          defaultChecked={priceInfo?.auction_prices?.find(
                            (item) => item.feature && item.description === "Asking Price",
                          )}
                          id="latest-activity-asking-price"
                          onClick={(e) => {
                            const data = { ...priceFeatureData };
                            data.price_type = e.target.value;
                            setPriceFeatureData(data);
                          }}
                        />
                        <label class="form-check-label" for="latest-activity-asking-price"></label>
                      </div>
                    </div>
                  </div>
                  <div class="ctcr-widget-item d-flex align-items-start justify-content-between">
                    <div class="ctcr-left">
                      <div class="ctcr-label">Recycle Value</div>
                      <div class="ctcr-labal-txt fw-medium">
                        {latestBids?.auctionInfo?.scrap_value
                          ? "$" +
                            new Intl.NumberFormat("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(latestBids?.auctionInfo?.scrap_value)
                          : "$0.00"}
                      </div>
                    </div>
                  </div>
                  <div class="ctcr-widget-item d-flex align-items-start justify-content-between">
                    <div class="ctcr-left">
                      <div class="ctcr-label">Retail Value </div>
                      <div class="ctcr-labal-txt fw-medium">
                        {" "}
                        {latestBids?.auctionInfo?.retail_value
                          ? "$" +
                            new Intl.NumberFormat("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(latestBids?.auctionInfo?.retail_value)
                          : "$0.00"}
                      </div>
                    </div>
                    <div class="ctcr-right">
                      <div class="form-check dark-tick-checkbox">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value="chk_retail_value_feature"
                          defaultChecked={priceInfo?.auction_prices?.find(
                            (item) => item.feature && item.description === "Retail Value",
                          )}
                          id="latest-activity-retailvalue-price"
                          onClick={(e) => {
                            const data = { ...priceFeatureData };
                            data.price_type = e.target.value;
                            setPriceFeatureData(data);
                          }}
                        />
                        <label class="form-check-label" for="latest-activity-retailvalue-price"></label>
                      </div>
                    </div>
                  </div>
                  <div class="ctcr-widget-item d-flex align-items-start justify-content-between">
                    <div class="ctcr-left">
                      <div class="ctcr-label">Original Wholesale Value</div>
                      <div class="ctcr-labal-txt fw-medium">
                        {" "}
                        {latestBids?.auctionInfo?.orig_wholesale_value
                          ? "$" +
                            new Intl.NumberFormat("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(latestBids?.auctionInfo?.orig_wholesale_value)
                          : "$0.00"}
                      </div>
                    </div>
                    <div class="ctcr-right">
                      <div class="form-check dark-tick-checkbox">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value="chk_wholesale_value_feature"
                          defaultChecked={priceInfo?.auction_prices?.find(
                            (item) => item.feature && item.description === "Original Wholesale Value",
                          )}
                          id="latest-activity-wholesale-price"
                          onClick={(e) => {
                            const data = { ...priceFeatureData };
                            data.price_type = e.target.value;
                            setPriceFeatureData(data);
                          }}
                        />
                        <label class="form-check-label" for="latest-activity-wholesale-price"></label>
                      </div>
                    </div>
                  </div>
                  <div class="ctcr-widget-item d-flex align-items-start justify-content-between">
                    <div class="ctcr-left">
                      <div class="ctcr-label">Claim Value</div>
                      <div class="ctcr-labal-txt fw-medium">
                        {" "}
                        {latestBids?.auctionInfo?.claim_value
                          ? "$" +
                            new Intl.NumberFormat("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(latestBids?.auctionInfo?.claim_value)
                          : "$0.00"}
                      </div>
                    </div>
                    <div class="ctcr-right">
                      <div class="form-check dark-tick-checkbox">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value="chk_claim_value_feature"
                          defaultChecked={priceInfo?.auction_prices?.find(
                            (item) => item.feature && item.description === "Claim Value",
                          )}
                          id="latest-activity-claim-price"
                          onClick={(e) => {
                            const data = { ...priceFeatureData };
                            data.price_type = e.target.value;
                            setPriceFeatureData(data);
                          }}
                        />
                        <label class="form-check-label" for="latest-activity-claim-price"></label>
                      </div>
                    </div>
                  </div>
                  {latestBids?.auctionInfo?.scrapPrice && !isNaN(latestBids?.auctionInfo?.scrapPrice) && (
                    <div class="ctcr-widget-item d-flex align-items-start justify-content-between">
                      <div class="ctcr-left">
                        <div class="ctcr-label">Estimated Scrap Price</div>
                        <div class="ctcr-labal-txt fw-medium">
                          {latestBids?.auctionInfo?.scrapPrice
                            ? "$" +
                              new Intl.NumberFormat("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }).format(latestBids?.auctionInfo?.scrapPrice)
                            : "$0.00"}
                        </div>
                      </div>
                    </div>
                  )}
                  <div class="ctcr-btn">
                    <a href="javascript:void(0)" class="btn btn-auto btn-primary" onClick={() => toggleEditModal()}>
                      Edit Pricing
                    </a>
                  </div>
                </div>
                <div class="ctcr-widget">
                  <div class="ctcr-widget-item">
                    <div class="ctcr-label">Last Bid Date</div>
                    <div class="ctcr-labal-txt fw-medium">
                      {latestBids?.bidInfo?.seller_bid_date ? (
                        <DateFormat date={latestBids?.bidInfo?.seller_bid_date} />
                      ) : (
                        "N/A"
                      )}
                    </div>
                  </div>
                  <div class="col-button-group">
                    <div class="col-btn-list d-flex flex-wrap">
                      <div class="col-btn-item">
                        <a
                          href="javascript:void(0)"
                          class="btn btn-auto btn-xs-size btn-default"
                          onClick={() => {
                            handleRevisitSellerBid(1);
                          }}
                        >
                          {revisitSellerBid1 ? "Reinitiate" : "Revisit"} Seller Bid
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="ctcr-widget-item">
                    <div class="ctcr-label">Last Bid Amount</div>
                    <div class="ctcr-labal-txt fw-medium">
                      {latestBids?.bidInfo?.seller_bid
                        ? "$" +
                          new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(
                            latestBids?.bidInfo?.seller_bid,
                          )
                        : "$0.00"}{" "}
                      <span class="fw-normal">
                        (
                        {latestBids?.bidInfo?.seller_bid && priceInfo?.auction_details?.quantity
                          ? "$" +
                            new Intl.NumberFormat("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(
                              (latestBids?.bidInfo?.seller_bid / priceInfo?.auction_details?.quantity).toFixed(2),
                            )
                          : 0}{" "}
                        Per Unit )
                      </span>
                    </div>
                  </div>
                  <div class="col-button-group">
                    <div class="col-btn-list d-flex flex-wrap">
                      <div class="col-btn-item">
                        <a
                          href="javascript:void(0)"
                          class="btn btn-auto btn-xs-size btn-default"
                          onClick={() => {
                            const data = {};
                            data.type = 1;
                            data.step = "Seller Accepted Bid";
                            data.category = "Closing";
                            data.bid = latestBids?.bidInfo?.seller_bid.toString();
                            data.sp = latestBids?.auctionInfo?.seller_reserve.toString();
                            data.bid_id = latestBids?.bidInfo?.b_id.toString();
                            handleSaveBidLog(data);
                          }}
                        >
                          Accepted
                        </a>
                      </div>
                      <div class="col-btn-item">
                        <a
                          href="javascript:void(0)"
                          class="btn btn-auto btn-xs-size btn-primary"
                          onClick={() => {
                            const data = {};
                            data.type = 0;
                            data.step = "Seller Accepted Bid";
                            data.category = "Closing";
                            data.bid = latestBids?.bidInfo?.seller_bid.toString();
                            data.sp = latestBids?.auctionInfo?.seller_reserve.toString();
                            data.bid_id = latestBids?.bidInfo?.b_id.toString();
                            handleSaveBidLog(data);
                          }}
                        >
                          Rejected
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-3">
                  {bidLog &&
                    bidLog.length > 0 &&
                    bidLog.map((val) => (
                      <div className="col-text mt-2">
                        {val.updated_dt} Bid{" "}
                        <span className={val.type ? "text-success" : "text-danger"}>
                          {val.bid_amount
                            ? "$" +
                              new Intl.NumberFormat("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }).format(val.bid_amount)
                            : "$0"}{" "}
                          {val.type ? "Accepted" : "Rejected"}
                        </span>
                        {!val.type &&
                          `- Seller Asking ${
                            val.sp_amount
                              ? "$" +
                                new Intl.NumberFormat("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }).format(val.sp_amount)
                              : "$0"
                          }`}
                      </div>
                    ))}
                </div>
                <div class="col-button-group">
                  <div class="col-btn-list d-flex flex-wrap">
                    {latestBids?.auctionInfo?.revisit_seller_bid_date && (
                      <div>
                        Revisit seller bid on{" "}
                        <span className="fw-medium">
                          {moment(latestBids?.auctionInfo?.revisit_seller_bid_date).format("MM/DD/YY")}
                        </span>
                      </div>
                    )}
                    <div class="col-btn-item">
                      <a
                        href="javascript:void(0)"
                        class="btn btn-auto btn-xs-size btn-default"
                        onClick={() => {
                          handleRevisitSellerBid(2);
                        }}
                      >
                        {revisitSellerBid2 ? "Reinitiate" : "Revisit"} Seller Bid
                      </a>
                    </div>
                  </div>
                </div>
                <div className="dividerTable mt-3"></div>
                <div class="ctcr-widget">
                  <div class="ctcr-widget-item d-flex align-items-start justify-content-between">
                    <div class="ctcr-left">
                      <div class="ctcr-label">Quantity</div>
                      <div class="ctcr-labal-txt fw-medium">
                        {priceInfo?.auction_details?.quantity
                          ? new Intl.NumberFormat("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format((priceInfo?.auction_details?.quantity).toFixed(2))
                          : 0}{" "}
                        Units
                      </div>
                    </div>
                    <div class="ctcr-right d-flex ">
                      <div>
                        <a
                          href="javascript:void(0)"
                          class="btn btn-auto btn-primary btn-xs-size"
                          onClick={() => toggleEditModal()}
                        >
                          Edit
                        </a>
                      </div>
                      <div className="d-flex flex-column justify-content-center align-items-center">
                        <div className="form-check dark-tick-checkbox">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            defaultChecked={props.qtyConfirmation}
                            id="confirmed"
                            onChange={(e) => {
                              handleQuantityConfirmation(e.target.checked ? 1 : 0);
                            }}
                          />
                          <label className="form-check-label" htmlFor="confirmed"></label>
                        </div>
                        <span>Confirmed ?</span>
                      </div>
                    </div>
                  </div>
                  {latestBids?.qtyInfo && latestBids?.qtyInfo?.last_qty_confirmation_date != "" && (
                    <small>
                      Updated By {latestBids?.qtyInfo?.name01} at {latestBids?.qtyInfo?.last_qty_confirmation_date}
                    </small>
                  )}
                </div>
                <div class="ctcr-widget">
                  <div class="ctcr-widget-item">
                    <div class="ctcr-md-txt fw-medium">Comparable Prices</div>
                    <a
                      href="javascript:void(0)"
                      class="btn btn-auto btn-primary btn-xs-size"
                      onClick={() => toggleComparablePrice()}
                    >
                      Edit Comparable Prices
                    </a>
                    <div class="table-responsive table-custom mt-2">
                      {props.loadingMain ? (
                        <table class="table table-bordered">
                          <colgroup>
                            <col style={{ width: "25%" }} />
                            <col style={{ width: "25%" }} />
                            <col style={{ width: "25%" }} />
                            <col style={{ width: "25%" }} />
                          </colgroup>
                          <thead>
                            <tr>
                              <td>
                                <Skeleton />
                              </td>
                              <td>
                                <Skeleton />
                              </td>
                              <td>
                                <Skeleton />
                              </td>
                              <td>
                                <Skeleton />
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            {[1, 2, 3].map((item) => (
                              <tr>
                                <td>
                                  <Skeleton />
                                </td>
                                <td>
                                  {" "}
                                  <Skeleton />
                                </td>
                                <td>
                                  {" "}
                                  <Skeleton />
                                </td>
                                <td>
                                  <Skeleton />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : comparablePriceData1 && comparablePriceData1.length > 0 ? (
                        <table class="table table-bordered">
                          <colgroup>
                            <col style={{ width: "25%" }} />
                            <col style={{ width: "25%" }} />
                            <col style={{ width: "25%" }} />
                            <col style={{ width: "25%" }} />
                          </colgroup>
                          <thead>
                            <tr>
                              <td>Amount</td>
                              <td>Year</td>
                              <td>Location</td>
                              <td>Link</td>
                            </tr>
                          </thead>
                          <tbody>
                            {comparablePriceData1.map((item) => (
                              <tr>
                                <td>
                                  {item.amount
                                    ? "$" +
                                      new Intl.NumberFormat("en-US", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      }).format(item.amount)
                                    : "$0.00"}
                                </td>
                                <td>{item.year ? item.year : "N/A"}</td>
                                <td>{item.location ? item.location : "N/A"}</td>
                                <td>
                                  {item?.link ? (
                                    <a
                                      href={
                                        item.link && item.link.substring(0, 5) === "https"
                                          ? `${item.link}`
                                          : `https://${item.link}`
                                      }
                                      target="_blank"
                                      className="text-green"
                                    >
                                      {item.link ? "Link" : "N/A"}
                                    </a>
                                  ) : (
                                    "N/A"
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        ""
                        // <div
                        //   class="page-widget-empty-box text-capitalize d-flex align-items-center justify-content-center text-center"
                        //   style={{
                        //     height: "239px",
                        //     flexDirection: "column",
                        //     gap: "10px",
                        //   }}
                        // >
                        //   <span>No records found</span>
                        //   <button className="btn btn-default" onClick={() => toggleComparablePrice()}>
                        //     Click here to Add Comparable Price
                        //   </button>
                        // </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            <EditProductDetails
              section="pricing"
              show={editModal}
              LatestActivity={true}
              toggleEditModal={() => toggleEditModal()}
              updateProductDetails={props.updateProductDetails}
              listingProductDetailReceived={props.listingProductDetailReceived}
              auctionId={props.auctionId}
              setLoadingDetails={setLoadingDetails}
              loadingDetails={loadingDetails}
              data={data}
              refStart={() => props.refStart()}
              refStop={() => props.refStop()}
              getLatestBids={props.getLatestBids}
              productDetails={props.productDetails}
              getPriceInfo={props.getPriceInfo}
              detailListing={props.detailListing}
            />
            <ComparablePrice
              show={comparablePriceModal}
              LatestActivity={true}
              onHide={toggleComparablePrice}
              comparablePriceData={comparablePriceData}
              comparablePriceData1={comparablePriceData1}
              setComparablePriceData={setComparablePriceData}
              loadingSave={loadingSave}
              saveHandler={saveHandler}
            />
            <MaxBidModal
              show={showMaxBidModal}
              onHide={toggleMaxBidModal}
              submitHandler={handleMaxBid}
              loading={loadingMaxBid}
            />
            <ActivationModal
              show={showActivationModal}
              onHide={toggleActivationModal}
              activation={false}
              submitHandler={handleActivation}
              loading={loadingActivation}
            />
            <DeclineModal
              show={showDeclineModal}
              onHide={toggleDeclineModal}
              declineData={declineData}
              setDeclineData={setDeclineData}
              submitHandler={handleDecline}
              loading={loadingDecline}
            />
          </div>
        </div>
      )}
      <SendMail
        show={showSendMailModal}
        onHide={toggleShowSendModal}
        mailData={mailData}
        setMailData={setMailData}
        loading={loadingSendMail}
        handleSubmit={handleSendMail}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  getLatestActivity: getLatestActivity(state),
  getListingProductDetail: getListingProductDetail(state),
  getListings: getListings(state),
});

const mapDispatchToProps = (dispatch) => ({
  getLatestBids: (params, callback) => dispatch(getLatestBids(params, callback)),

  getPriceInfo: (params, callback) => dispatch(getPriceInfo(params, callback)),
  productDetails: (params, callback) => dispatch(productDetails(params, callback)),

  setMaxBid: (params, data, callback) => dispatch(setMaxBid(params, data, callback)),
  deactivateBid: (params, data, callback) => dispatch(deactivateBid(params, data, callback)),
  saveBidLog: (params, data, callback) => dispatch(saveBidLog(params, data, callback)),
  updatePriceFeature: (params, data, callback) => dispatch(updatePriceFeature(params, data, callback)),
  getRevisitSellerBid: (params, callback) => dispatch(getRevisitSellerBid(params, callback)),
  updateComparablePrice: (params, data, callback) => dispatch(updateComparablePrice(params, data, callback)),
  updateProductDetails: (params, data, callback) => dispatch(updateProductDetails(params, data, callback)),
  listingProductDetailReceived: (data) => dispatch(listingProductDetailReceived(data)),
  declineBid: (params, data, callback) => dispatch(declineBid(params, data, callback)),
  quantityConfirmation: (params, data, callback) => dispatch(quantityConfirmation(params, data, callback)),
  sendMailToManager: (params, data, callback) => dispatch(sendMailToManager(params, data, callback)),
  detailListing: (params, callback) => dispatch(detailListing(params, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(LatestActivity));
