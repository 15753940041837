import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import close from "../../../include/images/close-12x12.svg";
import crossIcon from "../../../include/images/cross-icon.svg";
import _ from "lodash";
import NextButton from "../../../common/form/nextButton";
import { toast } from "react-toastify";

import AlertError from "../../../common/alerts/alertError";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import parse from "html-react-parser";
import { connect } from "react-redux";
import { Amplify, Storage } from "aws-amplify";
import { Auth } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import LoadingBar from "react-top-loading-bar";
import {
  getAviationDetails,
  getAviationParts,
  uploadAviationParts,
  getCondition,
  getMfr,
  getModels,
  getPart,
  getPartMfr,
  getAviationLocations,
  updateAviationPartBulk,
} from "../../../store/aviation";
import { parse as parse1 } from "papaparse";
import { findDuplicateCombinationPositions } from "../../../utils.js/conversion";

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    region: "us-east-1",
  },
  Storage: {
    bucket: process.env.REACT_APP_S3_UPLOAD_BUCKET,
    region: "us-east-1",
  },
});
const UploadParts = (props) => {
  const ref = useRef(null);
  const [file, setFile] = useState();
  const [array, setArray] = useState([]);
  const [headerKeys, setHeaderKeys] = useState([]);

  const [loading, setLoading] = useState(false);
  const [parsedCsvData, setParsedCsvData] = useState([]);

  const condition = props.getAviationDetails?.condition?.data?.map((item) => `${item.code} (${item.title})`);
  const planeMfr = props.getAviationDetails?.mfr?.data?.map((item) => item.title);
  const planeModel = props.getAviationDetails?.model?.data?.map((item) => item.title);
  const partType = props.getAviationDetails?.part?.data?.map((item) => item.title);
  const partMfr = props.getAviationDetails?.partMfr?.data?.map((item) => item.title);
  const partLocation = props.getAviationDetails?.location?.data?.map((item) => item.title);

  const parseFile = (file) => {
    parse1(file, {
      header: true,
      complete: (results) => {
        setParsedCsvData(results.data);
      },
    });
  };

  const fileReader = new FileReader();

  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
    parseFile(e.target.files[0]);
  };

  const validateFile = (data) => {
    let valid = true;
    const headerValues =
      props?.type == "upload"
        ? [...props?.uploadHeaders]
        : [
            "Part Number",
            "Part Description",
            "Condition",
            "Quantity",
            "Unit Price",
            "Location",
            "Certificate",
            "Part Type",
            "Part Manufacturer",
            "Plane Model",
            "Plane Manufacturer",
            "Images",
            "Listing Id\r",
          ];
    if (props?.type == "upload") {
      headerValues[headerValues.length - 1] = "Listing Id\r";
    }

    headerValues.map((item, i) => {
      if (item != data[i] && valid) {
        valid = false;
        toast(
          <AlertError
            message={
              props?.type == "upload"
                ? "File structure is not correct. Please check if all the columns are available and in the correct order."
                : parse(
                    `<p>File structure is not correct. Please check if all the columns are available and in the correct order. <a class="toast-link-msg" target="_blank" href=${process.env.REACT_APP_MEDIA_URL}/aviation_parts_demo_file.csv>Click here</a> to download the sample file. </p>`,
                  )
            }
          />,
        );
        return false;
      }
    });
    if (props?.type == "upload") {
      if (!valid || !validateUpdateValues()) return;
    } else {
      if (!valid || !validateValues()) return;
    }

    return valid;
  };

  useEffect(() => {
    props.getCondition({}, () => {});

    props.getModels({ isParent: false }, () => {});
    props.getMfr({}, () => {});
    props?.getPartMfr({}, () => {});
    props?.getAviationLocations({}, () => {});
    props.getParts({}, () => {});
  }, []);
  const csvFileToArray = async (string) => {
    const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");

    const array = await csvRows.map((i) => {
      const values = i.split(",");
      const obj = csvHeader.reduce((object, header, index) => {
        object[header] = values[index];
        return object;
      }, {});
      return obj;
    });

    const data = Object.keys(Object.assign({}, array[0]));

    if (validateFile(data)) {
      setArray(array);
      setHeaderKeys(data);
    } else {
      setArray([]);
      setHeaderKeys([]);
    }
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();

    if (file && file.type == "text/csv") {
      if (file) {
        fileReader.onload = function (event) {
          const text = event.target.result;
          csvFileToArray(text);
        };

        fileReader.readAsText(file);
      }
    } else {
      toast(<AlertError message="File extension is not correct. Please upload CSV file." />);
    }
  };

  const validateValues = () => {
    var check = true;
    const data = [...array];

    const certicate = ["Yes", "No"];

    parsedCsvData.map((item, i) => {
      if (check && item["Part Number"]) {
        if (check && (!item["Listing Id"]?.length > 0 || item?.["Listing Id"] !== props?.id)) {
          check = false;
          return toast(<AlertError message={`Incorrect Listing ID`} />);
        }

        if (check && !item["Part Number"]?.length > 0) {
          check = false;
          return toast(<AlertError message={`Value of Part Number field for row ${i + 1} is not valid`} />);
        }

        if (
          (check && !item?.Condition?.length > 0) ||
          (check &&
            item?.Condition?.length > 0 &&
            !props.getAviationDetails?.condition?.data?.map((cd) => `${cd.code}`).includes(item?.Condition))
        ) {
          check = false;
          return toast(
            <AlertError
              message={`Value of Condition field for ${
                item["Part Number"]
              } part number is not valid. Valid values are ${condition?.map((jf) => jf).join(", ")}`}
            />,
          );
        }

        if (check && item?.Certificate?.length > 0 && !certicate.includes(item?.Certificate)) {
          check = false;
          return toast(
            <AlertError message={`Value of Certificate field for ${item["Part Number"]} part number is not valid`} />,
          );
        }
        if (check && (!item["Quantity"]?.length > 0 || isNaN(item["Quantity"]))) {
          check = false;
          return toast(
            <AlertError
              message={`Value of "Quantity" is not valid for part number ${item["Part Number"]}. It must be in number format. Please make sure not to add any characters or symbols. `}
            />,
          );
        }
        if (check && item["Unit Price"]?.length > 0 && isNaN(item["Unit Price"])) {
          check = false;
          return toast(
            <AlertError
              message={`Value of Unit Price is not valid for part number ${item["Part Number"]}. It must be in number format. For example, 20.00, 35.50 etc. Please make sure not to add any character or symbol like $. `}
            />,
          );
        }
        if (check && item?.Location?.length > 0 && !partLocation?.map((cd) => `${cd}`).includes(item?.Location)) {
          check = false;
          return toast(
            <AlertError
              message={`Value of Location field for ${
                item["Part Number"]
              } part number is not valid. Valid values are ${partLocation?.map((jf) => jf).join(", ")}`}
            />,
          );
        }
        if (check && item?.["Part Type"]?.length > 0 && !partType?.map((cd) => `${cd}`).includes(item?.["Part Type"])) {
          check = false;
          return toast(
            <AlertError
              message={`Value of Part Type field for ${
                item["Part Number"]
              } part number is not valid. Valid values are ${partType?.map((jf) => jf).join(", ")}`}
            />,
          );
        }
        if (
          check &&
          item?.["Plane Manufacturer"]?.length > 0 &&
          !planeMfr?.map((cd) => `${cd}`).includes(item?.["Plane Manufacturer"])
        ) {
          check = false;
          return toast(
            <AlertError
              message={`Value of Plane Manufacturer field for ${
                item["Part Number"]
              } part number is not valid. Valid values are ${planeMfr?.map((jf) => jf).join(", ")}`}
            />,
          );
        }

        if (
          check &&
          item?.["Plane Model"]?.length > 0 &&
          item?.["Plane Model"]
            ?.split(",")
            ?.map((dh) => planeModel?.map((cd) => `${cd}`).includes(dh))
            ?.includes(false)
        ) {
          check = false;

          return toast(
            <AlertError
              message={`Value of Plane Model field for ${
                item["Part Number"]
              } part number is not valid. Valid values are ${planeModel?.map((jf) => jf).join(", ")}`}
            />,
          );
        }
        if (
          check &&
          item?.["Part Manufacturer"]?.length > 0 &&
          !partMfr?.map((cd) => `${cd}`).includes(item?.["Part Manufacturer"])
        ) {
          check = false;
          return toast(
            <AlertError
              message={`Value of Part Manufacturer field for ${
                item["Part Number"]
              } part number is not valid. Valid values are ${partMfr?.map((jf) => jf).join(", ")}`}
            />,
          );
        }
      }
    });
    return check;
  };

  const validateUpdateValues = () => {
    var check = true;
    const data = [...array];

    const certicate = ["Yes", "No"];

    parsedCsvData.map((item, i) => {
      if (check && item["Part Number"]) {
        if (check && parsedCsvData?.length > 10001) {
          check = false;
          return toast(<AlertError message={`Max limit reached. Records cannot be more than 10,000.`} />);
        }

        if (check && (!item["Listing Id"]?.length > 0 || item?.["Listing Id"] !== props?.id)) {
          check = false;
          return toast(<AlertError message={`Incorrect Listing ID`} />);
        }

        if (check && !item["Part Number"]?.length > 0) {
          check = false;
          return toast(<AlertError message={`Value of Part Number field for row ${i + 1} is not valid`} />);
        }

        // if (
        //   (check && !item?.Condition?.length > 0) ||
        //   (check &&
        //     item?.Condition?.length > 0 &&
        //     !props.getAviationDetails?.condition?.data?.map((cd) => `${cd.code}`).includes(item?.Condition))
        // ) {
        //   check = false;
        //   return toast(
        //     <AlertError
        //       message={`Value of Condition field for ${
        //         item["Part Number"]
        //       } part number is not valid. Valid values are ${condition?.map((jf) => jf).join(", ")}`}
        //     />,
        //   );
        // }

        if (check && item?.Certificate?.length > 0 && !certicate.includes(item?.Certificate)) {
          check = false;
          return toast(
            <AlertError message={`Value of Certificate field for ${item["Part Number"]} part number is not valid`} />,
          );
        }
        // if (check && (!item["Quantity"]?.length > 0 || !parseFloat(item["Quantity"]))) {
        //   check = false;
        //   return toast(
        //     <AlertError
        //       message={`Value of "Quantity" is not valid for part number ${item["Part Number"]}. It must be in number format. Please make sure not to add any characters or symbols. `}
        //     />,
        //   );
        // }
        if (check && item["Unit Price"]?.length > 0 && !parseFloat(item["Unit Price"])) {
          check = false;
          return toast(
            <AlertError
              message={`Value of Unit Price is not valid for part number ${item["Part Number"]}. It must be in number format. For example, 20.00, 35.50 etc. Please make sure not to add any character or symbol like $. `}
            />,
          );
        }
        if (check && item?.Location?.length > 0 && !partLocation?.map((cd) => `${cd}`).includes(item?.Location)) {
          check = false;
          return toast(
            <AlertError
              message={`Value of Location field for ${
                item["Part Number"]
              } part number is not valid. Valid values are ${partLocation?.map((jf) => jf).join(", ")}`}
            />,
          );
        }
        if (check && item?.["Part Type"]?.length > 0 && !partType?.map((cd) => `${cd}`).includes(item?.["Part Type"])) {
          check = false;
          return toast(
            <AlertError
              message={`Value of Part Type field for ${
                item["Part Number"]
              } part number is not valid. Valid values are ${partType?.map((jf) => jf).join(", ")}`}
            />,
          );
        }
        if (
          check &&
          item?.["Plane Manufacturer"]?.length > 0 &&
          !planeMfr?.map((cd) => `${cd}`).includes(item?.["Plane Manufacturer"])
        ) {
          check = false;
          return toast(
            <AlertError
              message={`Value of Plane Manufacturer field for ${
                item["Part Number"]
              } part number is not valid. Valid values are ${planeMfr?.map((jf) => jf).join(", ")}`}
            />,
          );
        }
        if (
          check &&
          item?.["Plane Model"]?.length > 0 &&
          // !planeModel?.map((cd) => `${cd}`).includes(item?.["Plane Model"])
          item?.["Plane Model"]
            ?.split(",")
            ?.map((dh) => planeModel?.map((cd) => `${cd}`).includes(dh))
            ?.includes(false)
        ) {
          check = false;
          return toast(
            <AlertError
              message={`Value of Plane Model field for ${
                item["Part Number"]
              } part number is not valid. Valid values are ${planeModel?.map((jf) => jf).join(", ")}`}
            />,
          );
        }
        if (
          check &&
          item?.["Part Manufacturer"]?.length > 0 &&
          !partMfr?.map((cd) => `${cd}`).includes(item?.["Part Manufacturer"])
        ) {
          check = false;
          return toast(
            <AlertError
              message={`Value of Part Manufacturer field for ${
                item["Part Number"]
              } part number is not valid. Valid values are ${partMfr?.map((jf) => jf).join(", ")}`}
            />,
          );
        }
      }
    });
    return check;
  };

  const [startUploading, setStartUploading] = useState(false);
  const [aviationData, setAviationData] = useState([]);

  const totalPages = aviationData?.length;

  useEffect(() => {
    if (aviationData?.length > 0 && startUploading) {
      const fetchData = async (page) => {
        try {
          if (props?.type == "upload") {
            const response = await props.updateAviationPartBulk(
              props?.id,
              { records: aviationData[page - 1], selectedCsvHeader: [...props?.uploadHeaders] },
              (res) => {
                if (res && res?.status == 200) {
                  console.log("PAge", page);
                  console.log("TotalPage", totalPages);
                  if (page < totalPages) {
                    fetchData(page + 1);
                  } else if (page == totalPages) {
                    setHeaderKeys([]);
                    setArray([]);
                    setFile(null);
                    props?.reloader();
                    setStartUploading(false);
                    ref?.current?.complete();
                    toast(<AlertSuccess message={"Information Saved"} />);
                    props.onHide();
                    props.loadData();
                    setLoading(false);
                  } else {
                    ref?.current?.complete();
                    setLoading(false); // Once all data is fetched, set loading to false
                  }
                } else {
                  setLoading(false);
                  ref?.current?.complete();
                  toast(<AlertError message={res?.data?.message ? res.data.message : "Something went wrong"} />);
                }
              },
            );
          } else {
            const response = await props.uploadAviationParts(props?.id, { records: aviationData[page - 1] }, (res) => {
              if (res && res?.status == 200) {
                if (page < totalPages) {
                  fetchData(page + 1);
                } else if (page == totalPages) {
                  setHeaderKeys([]);
                  setArray([]);
                  setFile(null);
                  props?.reloader();
                  setStartUploading(false);
                  ref?.current?.complete();
                  toast(<AlertSuccess message={"Information Saved"} />);
                  props.onHide();
                  props.loadData();
                  setLoading(false);
                } else {
                  ref?.current?.complete();
                  setLoading(false); // Once all data is fetched, set loading to false
                }
              } else {
                setLoading(false);
                ref?.current?.complete();
                toast(<AlertError message={res?.data?.message ? res.data.message : "Something went wrong"} />);
              }
            });
          }
        } catch (error) {
          setLoading(false);
          ref?.current?.complete();
          toast(<AlertError message={"Something went wrong"} />);
          console.error("Error:", error);
        }
      };

      fetchData(1); // Start fetching data from the first page
    }
  }, [aviationData]);

  function createBatches(array, batchSize) {
    const batches = [];
    const header =
      props?.type == "upload"
        ? [...props?.uploadHeaders]
        : [
            "Part Number",
            "Part Description",
            "Condition",
            "Quantity",
            "Unit Price",
            "Location",
            "Certificate",
            "Part Type",
            "Part Manufacturer",
            "Plane Model",
            "Plane Manufacturer",
            "Images",
            "Listing Id",
          ];
    for (let i = 0; i < array.length; i += batchSize) {
      const batch = array.slice(i, i + batchSize);
      batches.push([header, ...batch]);
    }
    return batches;
  }

  const handleSubmitDocument = (e) => {
    e.preventDefault();
    setLoading(true);
    ref?.current?.continuousStart();
    const mainArray = [];
    const incomingData = parsedCsvData.filter((cs) => cs?.["Part Number"]?.length > 0).map((obj) => Object.values(obj));
    mainArray.push(...incomingData);
    const batchSize = 500;
    const batches = createBatches(mainArray, batchSize);
    setAviationData(batches);
    setStartUploading(true);
  };

  const handleUpdateDocument = (e) => {
    e.preventDefault();
    setLoading(true);
    ref?.current?.continuousStart();
    const mainArray = [];
    const incomingData = parsedCsvData.filter((cs) => cs?.["Part Number"]?.length > 0).map((obj) => Object.values(obj));
    mainArray.push(...incomingData);
    const batchSize = 500;
    const batches = createBatches(mainArray, batchSize);
    setAviationData(batches);
    setStartUploading(true);
  };

  useEffect(() => {
    if (headerKeys?.length > 0) {
      const filteredData = parsedCsvData
        ?.map((item) => ({
          ...item,
          "Part Number": item?.["Part Number"]?.replace("#", "")?.replace("?", "")?.replace("/", ""),
        }))
        ?.filter((item) => item["Part Number"] !== "");
      setParsedCsvData(filteredData);
    }
  }, [headerKeys?.length]);

  return (
    <>
      <LoadingBar height={5} color="#47AD1D" ref={ref} />
      <Modal
        className="modal fade custom-modal"
        id="editProductDescription"
        show={props.show}
        // onHide={() => props.onHide()}
        dialogClassName={`modal-dialog modal-dialog-centered modal-xxl ${
          headerKeys && headerKeys.length == 0 ? "" : "modal-wide"
        }`}
      >
        <div class="modal-content d-flex flex-column">
          <div class="custom-modal-header d-flex align-items-center justify-content-between">
            <h5 class="mb-0 text-capitalize" style={{ marginLeft: "40px" }}>
              {props?.type == "upload" ? "Update Parts" : "Upload Parts"}
            </h5>
            <button
              type="button"
              class="close-btn d-flex align-items-center justify-content-center"
              onClick={() => {
                setHeaderKeys([]);
                setArray([]);
                setFile();
                props.onHide();
              }}
            >
              <img src={close} alt="" />
            </button>
          </div>
          <Modal.Body className="custom-modal-body flex-grow-1 w-100 overflow-hidden">
            {headerKeys && headerKeys.length == 0 ? (
              <div class="offcanvas-widget-row pt-0">
                {props?.type != "upload" ? (
                  <p>
                    Document must be in CSV format.{" "}
                    <a
                      target="_blank"
                      href={`${process.env.REACT_APP_MEDIA_URL}/aviation_parts_demo_file.csv`}
                      className="toast-link-msg"
                    >
                      Click here
                    </a>
                    &nbsp;to download the sample file.
                  </p>
                ) : (
                  <p>Edit the CSV file that you have downloaded and Upload again.</p>
                )}

                <div class="mb-20">
                  <label for="slectDocument" class="form-label">
                    Select CSV File
                  </label>
                  <div class="upload-lg-button position-relative">
                    <span class="file-input">
                      <input type={"file"} id="inputGroupFile02" accept={".csv"} onChange={handleOnChange} />

                      <span id="upload-file-name" class="d-flex align-items-center">
                        {file && file.name}
                        {file ? (
                          <img class="cross-icon-box" src={crossIcon} alt="" onClick={() => setFile()} />
                        ) : (
                          "Please select a file"
                        )}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <div class="offcanvas-widget-row pt-0">
                <div className="d-flex justify-content-between mb-20">
                  <p>
                    Uploaded File : <span className="font-weight-600"> {file && file.name}</span>
                  </p>

                  <NextButton
                    classData="btn btn-default"
                    handleSubmit={(e) =>
                      headerKeys && headerKeys.length == 0
                        ? handleOnSubmit(e)
                        : props?.type == "upload"
                        ? handleUpdateDocument(e)
                        : parsedCsvData?.length <= 10001
                        ? handleSubmitDocument(e)
                        : toast(<AlertError message={"Max limit reached. Records cannot be more than 10,000"} />)
                    }
                    label={headerKeys && headerKeys.length == 0 ? "View" : "Upload"}
                    loading={loading}
                  />
                </div>

                <div class="table-responsive table-custom">
                  <table class="table table-bordered">
                    <colgroup>
                      {headerKeys && headerKeys.length > 0 && headerKeys.map((item) => <col />)}
                      <col />
                    </colgroup>
                    <thead>
                      <tr>
                        {headerKeys.map((key) => (
                          <td style={{ minWidth: "200px" }}>
                            <span>{key}</span>
                          </td>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {parsedCsvData.map((item) =>
                        item["Part Number"] ? (
                          <tr key={item.id}>
                            {Object.values(item).map((val) => (
                              <td style={{ minWidth: "200px" }}>{val}</td>
                            ))}
                          </tr>
                        ) : (
                          ""
                        ),
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </Modal.Body>
          <div class="custom-modal-dividor"></div>
          <div class="custom-modal-footer d-grid gap-2 d-md-flex align-items-center justify-content-md-end">
            <button
              class="btn btn-link me-md-2 global-cancel-button"
              type="button"
              onClick={() => {
                setHeaderKeys([]);
                setArray([]);
                setFile();
                props.onHide();
              }}
            >
              Cancel
            </button>

            {props?.type == "upload" ? (
              <NextButton
                classData="btn btn-default mr-40"
                handleSubmit={(e) =>
                  headerKeys && headerKeys.length == 0 ? handleOnSubmit(e) : handleUpdateDocument(e)
                }
                label={headerKeys && headerKeys.length == 0 ? "View" : "Upload"}
                loading={loading}
              />
            ) : (
              <NextButton
                classData="btn btn-default mr-40"
                handleSubmit={(e) =>
                  headerKeys && headerKeys.length == 0
                    ? handleOnSubmit(e)
                    : parsedCsvData?.length <= 10001
                    ? handleSubmitDocument(e)
                    : toast(<AlertError message={"Max limit reached. Records cannot be more than 10,000"} />)
                }
                label={headerKeys && headerKeys.length == 0 ? "View" : "Upload"}
                loading={loading}
              />
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  uploadAviationParts: (id, data, callback) => dispatch(uploadAviationParts(id, data, callback)),
  getMfr: (params, callback) => dispatch(getMfr(params, callback)),
  getParts: (params, callback) => dispatch(getPart(params, callback)),
  getCondition: (params, callback) => dispatch(getCondition(params, callback)),
  getModels: (params, callback) => dispatch(getModels(params, callback)),
  getPartMfr: (paramns, callback) => dispatch(getPartMfr(paramns, callback)),
  getAviationLocations: (params, callback) => dispatch(getAviationLocations(params, callback)),
  updateAviationPartBulk: (id, data, callback) => dispatch(updateAviationPartBulk(id, data, callback)),
});

const mapStateToProps = (state) => ({
  getAviationDetails: getAviationDetails(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(UploadParts));
