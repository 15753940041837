import React from "react";
import close from "../../../include/images/close.svg";
import { Offcanvas } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import NextButton from "../../../common/form/nextButton";

const KeyPushModal = (props) => {
  const totalCount = props?.dataCount;
  return (
    <Offcanvas
      placement="end"
      backdropClassName="upload-documnets-backdrop"
      show={props.show}
      onHide={() => props.onHide()}
      className=" right-collapse fade modal-lg  offcanvas-pannel-container"
      dialogClassName="modal-dialog"
    >
      <Offcanvas.Header className="offcanvas-header">
        <h5 class="offcanvas-title">
          {props?.region === "regional"
            ? "REGIONAL"
            : props?.region === "global"
            ? "GLOBAL"
            : props?.region === "local"
            ? "LOCAL"
            : ""}{" "}
          KEY PUSH{" "}
          {props?.region === "regional"
            ? `(${props?.count?.regional})`
            : props?.region === "global"
            ? `(${props?.count?.global})`
            : props?.region === "local"
            ? `(${props?.count?.local})`
            : ""}
        </h5>
        <button
          type="button"
          class="offcanvas-close-btn d-flex align-items-center justify-content-center"
          onClick={() => props.onHide()}
        >
          <img src={close} alt="" />
        </button>
      </Offcanvas.Header>
      <Offcanvas.Body className="custom-modal-body flex-grow-1 w-100 overflow-scroll" data-simplebar>
        <div className="d-flex justify-content-start mb-3">
          <div className="header-search-box ms-0 me-3">
            <input
              type="search"
              className="w-100"
              placeholder="Enter Keywords"
              value={props?.keyword}
              onChange={(e) => props?.setKeyword(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  props?.page === 1 ? props?.handleSubmit() : props?.setPage(1);
                }
              }}
            />
          </div>
          <NextButton
            label="Search"
            classData="btn btn-default"
            handleSubmit={() => {
              props?.page === 1 ? props?.handleSubmit() : props?.setPage(1);
            }}
            loading={props?.loading}
          />
        </div>
        <div className="table-responsive  table-custom">
          <table className="table table-bordered">
            <colgroup>
              <col />
              <col />
            </colgroup>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
              </tr>
            </thead>
            {props?.loading ? (
              <tbody>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]?.map(() => (
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <tbody>
                {props?.data?.length > 0 ? (
                  props?.data?.map((item) => (
                    <tr>
                      <td>{item?.name01 ? item?.name01 : "N/A"}</td>
                      <td>{item?.email ? <a href="javascript:void(0)">{item?.email}</a> : "N/A"}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={2}>
                      <div className="d-flex justify-content-center align-items-center">
                        <span>No record found</span>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            )}
          </table>
        </div>
        {!props.loadingMain && props?.data && props?.data?.length > 0 && (
          <div class="table-pagination mt-3">
            <ul class="pagination  justify-content-end">
              <li class="page-item">
                <a
                  class={`page-link ${props?.page === 1 ? "disabled" : ""}`}
                  href="javascript:void(0)"
                  aria-label="Previous"
                  onClick={() => {
                    if (props?.page !== 1) {
                      props?.setPage(props?.page - 1);
                    }
                  }}
                >
                  <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 13L1 7L7 1" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </a>
              </li>
              <li class="page-item active">
                <a class="page-link" href="javascript:void(0)">
                  {props?.page}
                </a>
              </li>
              <li class="page-item">
                {props?.page <
                  (totalCount % 20 === 0 ? Math.floor(totalCount / 20) : Math.floor(totalCount / 20) + 1) && (
                  <a
                    class={`page-link ${
                      props?.page >=
                      (totalCount % 20 === 0 ? Math.floor(totalCount / 20) : Math.floor(totalCount / 20) + 1)
                        ? "disabled"
                        : ""
                    }`}
                    href="javascript:void(0)"
                    onClick={() => {
                      props?.setPage(props?.page + 1);
                    }}
                  >
                    {props?.page + 1}
                  </a>
                )}
              </li>
              <li class="page-item">
                {props?.page <
                  (totalCount % 20 === 0 ? Math.floor(totalCount / 20) : Math.floor(totalCount / 20) + 1) && (
                  <a
                    class={`page-link ${
                      props?.page >=
                      (totalCount % 20 === 0 ? Math.floor(totalCount / 20) : Math.floor(totalCount / 20) + 1)
                        ? "disabled"
                        : ""
                    }`}
                    href="javascript:void(0)"
                    aria-label="Next"
                    onClick={() => {
                      props?.setPage(props?.page + 1);
                    }}
                  >
                    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 13L7 7L1 1" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </a>
                )}
              </li>
            </ul>
          </div>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default KeyPushModal;
