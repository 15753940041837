import React from "react";
import { Modal } from "react-bootstrap";
import NextButton from "../../../common/form/nextButton";
import close from "../../../include/images/close.svg";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextField } from "@mui/material";
import { getProfile } from "../../../utils.js/localStorageServices";
import moment from "moment";

const AccountingModal = (props) => {
  const session = getProfile();
  const d = props?.details;
  const profit = props?.values?.sales_price - props?.values?.cog - props?.values?.expenses - props?.values?.commission;
  return (
    <Modal
      className="modal fade custom-modal"
      id="editExclusivity"
      show={props.show}
      onHide={() => props.onHide()}
      dialogClassName="modal-dialog modal-dialog-centered modal-lg"
    >
      <div class="modal-content h-100 d-flex flex-column">
        <div class="custom-modal-header d-flex align-items-center justify-content-between">
          <h5 class="mb-0 text-capitalize">Edit Accounting</h5>
          <button
            type="button"
            class="close-btn d-flex align-items-center justify-content-center"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => props.onHide()}
          >
            <img src={close} alt="" />
          </button>
        </div>
        <Modal.Body className="custom-modal-body flex-grow-1 hide-scrollbar">
          <table class="table modal-product-detail-table mb-0">
            <colgroup>
              <col style={{ width: "55%" }} />
              <col style={{ width: "45%" }} />
            </colgroup>
            <tr>
              <td>
                <div class="form-check dark-tick-checkbox mt-2">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    checked={props.values && props.values.signed}
                    onChange={(e) => {
                      const values = { ...props.values };
                      values.signed = e.target.checked;
                      props.setValues(values);
                    }}
                    id="sellerSigned"
                  />
                  <label class="form-check-label" for="sellerSigned">
                    Seller Signed
                  </label>
                </div>
              </td>
              <td>
                {(session.member_id === 820291 ||
                  session.member_id === 842446 ||
                  (process.env.REACT_APP_MODE == "development" && session.member_id === 1)) && (
                  <div class="form-check dark-tick-checkbox mt-2">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      checked={props.values && props.values.inventory}
                      onChange={(e) => {
                        const values = { ...props.values };
                        values.inventory = e.target.checked;
                        props.setValues(values);
                      }}
                      id="inventory"
                    />
                    <label class="form-check-label" for="inventory">
                      Inventory
                    </label>
                  </div>
                )}
              </td>
            </tr>
            <tr>
              <td>
                <div class="form-check dark-tick-checkbox">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    checked={props.values && props.values.acc_flag_7}
                    onChange={(e) => {
                      const values = { ...props.values };
                      values.acc_flag_7 = e.target.checked;
                      props.setValues(values);
                    }}
                    id="loadingCostChecked"
                  />
                  <label class="form-check-label" for="loadingCostChecked">
                    Loading Costs Checked
                  </label>
                </div>
              </td>
              <td>
                {(session.member_id === 820291 ||
                  session.member_id === 842446 ||
                  (process.env.REACT_APP_MODE == "development" && session.member_id === 1)) && (
                  <div class="form-check dark-tick-checkbox">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      checked={props.values && props.values.proxibid_paid}
                      onChange={(e) => {
                        const values = { ...props.values };
                        values.proxibid_paid = e.target.checked;
                        props.setValues(values);
                      }}
                      id="proxibidPaid"
                    />
                    <label class="form-check-label" for="proxibidPaid">
                      Proxibid Paid
                    </label>
                  </div>
                )}
              </td>
            </tr>
          </table>
          {(session.member_id === 820291 ||
            session.member_id === 842446 ||
            (process.env.REACT_APP_MODE == "development" && session.member_id === 1)) && (
            <div class="tr-column-content w-100 h-100 d-flex flex-column">
              <table class="table table-borderless align-middle table-left-align">
                <colgroup>
                  <col width={"30%"} />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <td>Date Invoiced</td>
                    <td>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          disableHighlightToday={true}
                          className="form-control"
                          value={props?.values?.date_invoiced}
                          format="MM/DD/YYYY"
                          onChange={(e) => {
                            const values = { ...props?.values };
                            values.date_invoiced = e;
                            props?.setValues(values);
                          }}
                          renderInput={(params) => <TextField {...params} error={false} />}
                          inputFormat="MM/dd/yyyy"
                        />
                      </LocalizationProvider>
                    </td>
                  </tr>
                  <tr>
                    <td>Date Paid</td>
                    <td>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          disableHighlightToday={true}
                          className="form-control"
                          value={props?.values?.payrec_date}
                          format="MM/DD/YYYY"
                          onChange={(e) => {
                            const values = { ...props?.values };
                            values.payrec_date = e;
                            props?.setValues(values);
                          }}
                          renderInput={(params) => <TextField {...params} error={false} />}
                          inputFormat="MM/dd/yyyy"
                        />
                      </LocalizationProvider>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
          {(session.member_id === 820291 ||
            session.member_id === 842446 ||
            (process.env.REACT_APP_MODE == "development" && session.member_id === 1)) && (
            <div className="dividerTable mt-3" />
          )}
          {(session.member_id === 820291 ||
            session.member_id === 842446 ||
            (process.env.REACT_APP_MODE == "development" && session.member_id === 1)) && (
            <div class="tr-column-content w-100 h-100 d-flex flex-column">
              <table class="table table-borderless align-middle table-left-align">
                <colgroup>
                  <col style={{ width: "30%" }} />
                  <col />
                  <col style={{ width: "4%" }} />
                </colgroup>
                <tbody>
                  <tr>
                    <td>Total Sale</td>
                    <td>
                      <input
                        style={{ textAlign: "right" }}
                        type="number"
                        class="form-control"
                        value={props?.values?.total_sale}
                        onChange={(e) => {
                          const values = { ...props?.values };
                          values.total_sale = e.target.value;
                          props?.setValues(values);
                        }}
                      />
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Final Sales Price</td>
                    <td>
                      <input
                        style={{ textAlign: "right" }}
                        type="number"
                        class="form-control"
                        value={props?.values?.sales_price}
                        onChange={(e) => {
                          const values = { ...props?.values };
                          values.sales_price = e.target.value;
                          props?.setValues(values);
                        }}
                      />
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>COG</td>
                    <td>
                      <input
                        style={{ textAlign: "right" }}
                        type="number"
                        class="form-control"
                        value={props?.values?.cog}
                        onChange={(e) => {
                          const values = { ...props?.values };
                          values.cog = e.target.value;
                          props?.setValues(values);
                        }}
                      />
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Expenses</td>
                    <td>
                      <input
                        style={{ textAlign: "right" }}
                        type="number"
                        class="form-control"
                        value={props?.values?.expenses}
                        onChange={(e) => {
                          const values = { ...props?.values };
                          values.expenses = e.target.value;
                          props?.setValues(values);
                        }}
                      />
                    </td>
                    <td>
                      <a
                        href="javascript:void(0)"
                        class="btn btn-auto btn-xs-size btn-primary"
                        onClick={props?.toggleExpenseAllocation}
                      >
                        +
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>Commission</td>
                    <td align="right">
                      <span>{props?.values?.commission}</span>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      <div className="dividerTable" />
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Profit</td>
                    <td align="right">
                      <span className="fw-medium">
                        {profit
                          ? "$" +
                            new Intl.NumberFormat("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(profit)
                          : "$0.00"}
                      </span>
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
          {(session.member_id === 820291 ||
            session.member_id === 842446 ||
            (process.env.REACT_APP_MODE == "development" && session.member_id === 1)) && (
            <div className="dividerTable mt-3" />
          )}
          {(session.member_id === 820291 ||
            session.member_id === 842446 ||
            (process.env.REACT_APP_MODE == "development" && session.member_id === 1)) && (
            <div class="page-widget-label text-capitalize">Commission Details</div>
          )}
          {(session.member_id === 820291 ||
            session.member_id === 842446 ||
            (process.env.REACT_APP_MODE == "development" && session.member_id === 1)) && (
            <div class="tr-column-content w-100 h-100 d-flex flex-column">
              <table class="table table-borderless align-middle table-left-align">
                <colgroup>
                  <col style={{ width: "30%" }} />
                  <col />
                </colgroup>
                <tbody>
                  {d.bc_id > 0 && d.buyer_commission > 0 && (
                    <tr>
                      <td>{d.bc_name}</td>
                      <td align="right">
                        <span>
                          {"$" +
                            new Intl.NumberFormat("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(d.buyer_commission * d.profit_for_comm)}
                        </span>
                      </td>
                    </tr>
                  )}
                  {d.sc_id > 0 && d.seller_commission > 0 && (
                    <tr>
                      <td>{d.sc_name}</td>
                      <td align="right">
                        <span>
                          {"$" +
                            new Intl.NumberFormat("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(d.seller_commission * d.profit_for_comm)}
                        </span>
                      </td>
                    </tr>
                  )}
                  {d.dc_id > 0 && d.deal_commission > 0 && (
                    <tr>
                      <td>{d.dc_name}</td>
                      <td align="right">
                        <span>
                          {"$" +
                            new Intl.NumberFormat("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(d.deal_commission * d.profit_for_comm)}
                        </span>
                      </td>
                    </tr>
                  )}
                  {d.dc_id_1 > 0 && d.deal_commission_1 > 0 && (
                    <tr>
                      <td>{d.dc_name_1}</td>
                      <td align="right">
                        <span>
                          {"$" +
                            new Intl.NumberFormat("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(d.deal_commission_1 * d.profit_for_comm)}
                        </span>
                      </td>
                    </tr>
                  )}
                  {d.dc_id_2 > 0 && d.deal_commission_2 > 0 && (
                    <tr>
                      <td>{d.dc_name_2}</td>
                      <td align="right">
                        <span>
                          {"$" +
                            new Intl.NumberFormat("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(d.deal_commission_2 * d.profit_for_comm)}
                        </span>
                      </td>
                    </tr>
                  )}
                  {d.dc_id_3 > 0 && d.deal_commission_3 > 0 && (
                    <tr>
                      <td>{d.dc_name_3}</td>
                      <td align="right">
                        <span>
                          {"$" +
                            new Intl.NumberFormat("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(d.deal_commission_3 * d.profit_for_comm)}
                        </span>
                      </td>
                    </tr>
                  )}
                  {d.dc_id_4 > 0 && d.deal_commission_4 > 0 && (
                    <tr>
                      <td>{d.dc_name_4}</td>
                      <td align="right">
                        <span>
                          {"$" +
                            new Intl.NumberFormat("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(d.deal_commission_4 * d.profit_for_comm)}
                        </span>
                      </td>
                    </tr>
                  )}
                  {d.outside_agent > 0 && d.outside_agent_commission > 0 && (
                    <tr>
                      <td>{d.outside_agent_name}</td>
                      <td align="right">
                        <span>
                          {"$" +
                            new Intl.NumberFormat("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(d.outside_agent_commission * d.profit_for_comm)}
                        </span>
                      </td>
                    </tr>
                  )}
                  {d.inspector > 0 && d.inspector_commission > 0 && (
                    <tr>
                      <td>{d.inspector_name}</td>
                      <td align="right">
                        <span>
                          {"$" +
                            new Intl.NumberFormat("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(d.inspector_commission * d.profit_for_comm)}
                        </span>
                      </td>
                    </tr>
                  )}
                  {d.mgr_id > 0 && d.mgr_commission > 0 && (
                    <tr>
                      <td>{d.mgr_name}</td>
                      <td align="right">
                        <span>
                          {"$" +
                            new Intl.NumberFormat("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(d.mgr_commission * d.profit_for_comm)}
                        </span>
                      </td>
                    </tr>
                  )}
                  {d.admin_id > 0 && d.admin_commission > 0 && (
                    <tr>
                      <td>{d.admin_name}</td>
                      <td align="right">
                        <span>
                          {"$" +
                            new Intl.NumberFormat("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(d.admin_commission * d.profit_for_comm)}
                        </span>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
        </Modal.Body>
        <div class="custom-modal-dividor"></div>
        <div class="custom-modal-footer d-grid gap-2 d-md-flex align-items-center justify-content-md-end">
          <button
            class="btn btn-link me-md-2 global-cancel-button"
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => props.onHide()}
          >
            Cancel
          </button>
          <NextButton
            label="Save"
            classData="btn btn-default"
            handleSubmit={props.handleSubmit}
            loading={props.loadingAccounting}
          />
        </div>
      </div>
    </Modal>
  );
};

export default AccountingModal;
