import React, { useEffect, useState } from "react";
import trash from "../../../include/images/trash-18x18.svg";
import { connect } from "react-redux";
import { getObservers, otherListingsDetails } from "../../../store/otherListings";
import greenEmail from "../../../include/images/green-mail.svg";
import notVerified from "../../../include/images/mail-not-verified.svg";
import invalid from "../../../include/images/mail-invalid.svg";
import spamEmail from "../../../include/images/mail-spammed-blocked.svg";
import purpleEmail from "../../../include/images/mail-purple.svg";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import FindUser from "../../../common/findUser/findUser";
import { searchBuyer } from "../../../store/bids";
import { toast } from "react-toastify";
import AlertError from "../../../common/alerts/alertError";
import { getCountries, getStates } from "../../../store/productLocation";
import DeleteModal from "../../../layouts/modals/observers/deleteModal";
import Skeleton from "react-loading-skeleton";

const Observers = (props) => {
  const observers = props?.otherListingsDetails?.observers?.data;
  const [showNewObservers, setShowNewObservers] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [response, setResponse] = useState(false);
  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [resData, setResData] = useState([]);
  const [observer, setObserver] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [searchData, setSearchData] = useState({
    auction_id: props.auctionId,
    ext_ref: 0,
    outside_agent: 0,
    inspector: 0,
    recommend_id: 0,
    buyer: 0,

    name: "",
    company_name: "",
    city: "",
    st_des: "",
    zip: "",
    c_des: "",
    phone: "",
    fax: "",
    cell: "",
    email: "",
    setObserver: "",
    removeObserver: "",
  });
  const toggleNewObservers = () => {
    setShowNewObservers(!showNewObservers);
  };
  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  useEffect(() => {
    if (showNewObservers) {
      props.getCountries((res) => {
        if (res.status === 200) {
          setCountry(res.data.data);
        }
      });
      props.getStates((res) => {
        if (res.status === 200) {
          setState(res.data.data);
        }
      });
      setResponse(false);
      setResData([]);
      setObserver({});
      setSearchData({
        auction_id: props.auctionId,
        ext_ref: 0,
        outside_agent: 0,
        inspector: 0,
        recommend_id: 0,
        buyer: 0,

        name: "",
        company_name: "",
        city: "",
        st_des: "",
        zip: "",
        c_des: "",
        phone: "",
        fax: "",
        cell: "",
        email: "",
        setObserver: "",
        removeObserver: "",
      });
    }
  }, [showNewObservers]);

  const handleSearch = () => {
    setLoadingSearch(true);
    props.refStart();
    props.searchBuyer(props.auctionId, searchData, (res) => {
      if (res.status === 200) {
        setResData(res.data.data);
        props.refStop();
        setLoadingSearch(false);
        setResponse(true);
      } else {
        setLoadingSearch(false);
        props.refStop();
        setResponse(false);
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };
  const handleNewSearch = () => {
    setResponse(false);
    setResData([]);
    setObserver({});
    setSearchData({
      auction_id: props.auctionId,
      ext_ref: 0,
      outside_agent: 0,
      inspector: 0,
      recommend_id: 0,
      buyer: 0,

      name: "",
      company_name: "",
      city: "",
      st_des: "",
      zip: "",
      c_des: "",
      phone: "",
      fax: "",
      cell: "",
      email: "",
      setObserver: "",
      removeObserver: "",
    });
  };
  const handleSelectObserver = () => {
    setLoadingSearch(true);
    props.refStart();
    const payload = { ...searchData };
    payload.setObserver = observer.id ? observer.id : "";
    props.searchBuyer(props.auctionId, payload, (res) => {
      if (res.status === 200) {
        props?.getObservers(props?.auctionId, (res) => {});
        props.refStop();
        setLoadingSearch(false);
        toggleNewObservers();
      } else {
        props?.refStop();
        setLoadingSearch(false);
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const handleDelete = () => {
    setLoadingSearch(true);
    props.refStart();
    const payload = { ...searchData };
    payload.auction_id = props?.auctionId;
    payload.removeObserver = observer.id ? observer.id : "";
    props.searchBuyer(props.auctionId, payload, (res) => {
      if (res.status === 200) {
        props.getObservers(props?.auctionId, (res) => {});
        props.refStop();
        setLoadingSearch(false);
        toggleDeleteModal();
      } else {
        props?.refStop();
        setLoadingSearch(false);
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };
  return (
    <div class="details-page-widget-block">
      <div class="page-widget-head d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <div class="page-widget-label text-capitalize">Observers</div>
        </div>
        {!props?.loadingMain && (
          <div className="btn-block">
            <a href="javascript:void(0)" className="btn btn-primary btn-xs-size btn-auto" onClick={toggleNewObservers}>
              Find Person
            </a>
          </div>
        )}
      </div>
      <div class="page-widget-body mt-3">
        {props?.loadingMain ? (
          <div class="table-responsive">
            <table class="large-table align-left">
              <colgroup>
                <col />
                <col />
                <col />
                <col />
                <col style={{ width: "6%" }} />
              </colgroup>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>NAME</th>
                  <th>COMPANY</th>
                  <th>Login#</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {[1, 2, 3].map((val, i) => (
                  <tr key={i}>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div class="table-responsive hide-scrollbar">
            <table class="large-table align-left">
              <colgroup>
                <col />
                <col />
                <col />
                <col />
                <col style={{ width: "6%" }} />
              </colgroup>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>NAME</th>
                  <th>COMPANY</th>
                  <th>Login#</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {observers?.map((val, i) => (
                  <>
                    <tr key={i}>
                      <td>
                        {val?.id ? (
                          <a
                            href={
                              process.env.REACT_APP_MODE === "development"
                                ? `https://admin-dev.salvex.com/admin/_user/_edit.cfm?user_id=${val?.o_user_id}`
                                : `https://admin.salvex.com/admin/_user/_edit.cfm?user_id=${val?.o_user_id}`
                            }
                            target="_blank"
                          >
                            <span className="green-btn-text">{val?.id}</span>
                          </a>
                        ) : (
                          "N/A"
                        )}
                      </td>
                      <td>
                        {val?.name ? (
                          <a
                            href={
                              process.env.REACT_APP_MODE === "development"
                                ? `https://admin-dev.salvex.com/admin/_user/_edit.cfm?user_id=${val?.o_user_id}`
                                : `https://admin.salvex.com/admin/_user/_edit.cfm?user_id=${val?.o_user_id}`
                            }
                            target="_blank"
                          >
                            <span className="green-text">{val?.name}</span>
                          </a>
                        ) : (
                          "N/A"
                        )}
                      </td>
                      <td>
                        {val?.company_name ? (
                          <a
                            href={
                              process.env.REACT_APP_MODE === "development"
                                ? `https://admin-dev.salvex.com/admin/_user/_edit.cfm?user_id=${val?.o_user_id}`
                                : `https://admin.salvex.com/admin/_user/_edit.cfm?user_id=${val?.o_user_id}`
                            }
                            target="_blank"
                          >
                            <span className="green-text">{val?.company_name}</span>
                          </a>
                        ) : (
                          "N/A"
                        )}
                      </td>
                      <td>{val?.login_ct}</td>
                      <td>
                        <a
                          href="javascript:void(0)"
                          onClick={() => {
                            setObserver(val);
                            toggleDeleteModal();
                          }}
                        >
                          <img class="td-icon--0" src={trash} alt="" />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        {val?.email ? (
                          <a href={`mailto:${val?.email}`}>
                            <span className="green-btn-text me-1">{val?.email}</span>
                            {val?.emailStatusCode === 0 ? (
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip style={{ position: "fixed" }}>
                                    <span>Email OK</span>
                                  </Tooltip>
                                }
                              >
                                <img src={greenEmail} alt="" title="Email OK" />
                              </OverlayTrigger>
                            ) : val?.emailStatusCode === 1 ? (
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip style={{ position: "fixed" }}>
                                    <span>Email Temporarily Suspended/Unreachable or Undetermined (Soft Bounce)</span>
                                  </Tooltip>
                                }
                              >
                                <img src={purpleEmail} alt="" />
                              </OverlayTrigger>
                            ) : val?.emailStatusCode === 2 ? (
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip style={{ position: "fixed" }}>
                                    <span>Email Invalid</span>
                                  </Tooltip>
                                }
                              >
                                <img src={invalid} alt="" />
                              </OverlayTrigger>
                            ) : val?.emailStatusCode === 3 ? (
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip style={{ position: "fixed" }}>
                                    <span>Email Blocked by Spam or Firewall</span>
                                  </Tooltip>
                                }
                              >
                                <img src={spamEmail} alt="" />
                              </OverlayTrigger>
                            ) : (
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip style={{ position: "fixed" }}>
                                    <span>Email Not Verified</span>
                                  </Tooltip>
                                }
                              >
                                <img src={notVerified} alt="" title="" />
                              </OverlayTrigger>
                            )}
                          </a>
                        ) : (
                          "N/A"
                        )}
                      </td>
                      <td colSpan={3} style={{ position: "relative" }}>
                        <div className="d-flex" style={{ width: "fit-content" }}>
                          <Dropdown>
                            <Dropdown.Toggle as="a" className="btn btn-primary btn-xs-size btn-auto position-absolute">
                              Send
                            </Dropdown.Toggle>

                            <Dropdown.Menu bsPrefix="dropdown-menu dropdown-menu-end position-relative mb-5">
                              <Dropdown.Item
                                as={"a"}
                                className="cursor-pointer customized-drpdown-btn"
                                target="_blank"
                                href={
                                  process.env.REACT_APP_MODE === "development"
                                    ? `https://admin-dev.salvex.com/listings/views/new_release.cfm?aucID=${props?.auctionId}&via=2`
                                    : `https://admin.salvex.com/listings/views/new_release.cfm?aucID=${props?.auctionId}&via=2`
                                }
                              >
                                Information Release
                              </Dropdown.Item>
                              <Dropdown.Item
                                as={"a"}
                                target="_blank"
                                href={
                                  process.env.REACT_APP_MODE === "development"
                                    ? `https://admin-dev.salvex.com/admin/_listings/views/services_agreement.cfm?aucID=${props?.auctionId}&userID=${val?.o_user_id}&target=observer`
                                    : `https://admin.salvex.com/admin/_listings/views/services_agreement.cfm?aucID=${props?.auctionId}&userID=${val?.o_user_id}&target=observer`
                                }
                                className="cursor-pointer customized-drpdown-btn"
                              >
                                Services Agreement
                              </Dropdown.Item>
                              <Dropdown.Item
                                as={"a"}
                                target="_blank"
                                href={
                                  process.env.REACT_APP_MODE === "development"
                                    ? `https://admin-dev.salvex.com/listings/reportsAR/index.cfm?sid=${
                                        props?.sId ? props?.sId : 1
                                      }&ouserID=${val?.o_user_id}&target=observer`
                                    : `https://admin.salvex.com/listings/reportsAR/index.cfm?sid=${
                                        props?.sId ? props?.sId : 1
                                      }&ouserID=${val?.o_user_id}&target=observer`
                                }
                                className="cursor-pointer customized-drpdown-btn"
                              >
                                Asset Recovery Report
                              </Dropdown.Item>
                              <Dropdown.Item
                                as={"a"}
                                target="_blank"
                                href={
                                  process.env.REACT_APP_MODE === "development"
                                    ? `https://admin-dev.salvex.com/admin/_listings/views/_reportCO2e.cfm?auction_id=${props?.auctionId}`
                                    : `https://admin.salvex.com/admin/_listings/views/_reportCO2e.cfm?auction_id=${props?.auctionId}`
                                }
                                className="cursor-pointer customized-drpdown-btn"
                              >
                                Sustainability Report
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                          <a
                            href={`https://${
                              process.env.REACT_APP_MODE == "development" ? "admin-dev.salvex" : "admin.salvex"
                            }.com/admin/_sellers/_mrk_doc_auc/_create.cfm?aucID=${props?.auctionId}&s_user_id=${
                              val?.o_user_id
                            }`}
                            target="_blank"
                            class={`btn btn-other-email btn-xs-size btn-auto position-absolute`}
                          >
                            Email
                          </a>
                        </div>
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <FindUser
        title={"Observers"}
        show={showNewObservers}
        onHide={toggleNewObservers}
        searchData={searchData}
        setSearchData={setSearchData}
        handleSubmit={handleSearch}
        loading={loadingSearch}
        response={response}
        resData={resData}
        country={country}
        state={state}
        handleNewSearch={handleNewSearch}
        setUser={setObserver}
        handleSelectUser={handleSelectObserver}
      />
      <DeleteModal
        show={showDeleteModal}
        onHide={toggleDeleteModal}
        observer={observer}
        loadingDelete={loadingSearch}
        deleteHandler={handleDelete}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getObservers: (id, callback) => dispatch(getObservers(id, callback)),
  searchBuyer: (params, data, callback) => dispatch(searchBuyer(params, data, callback)),
  getCountries: (callback) => dispatch(getCountries(callback)),
  getStates: (callback) => dispatch(getStates(callback)),
});

const mapStateToProps = (state) => ({
  otherListingsDetails: otherListingsDetails(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Observers));
