import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import Header from "../layouts/header";
import { connect } from "react-redux";
import LoadingBar from "react-top-loading-bar";
import AviationTable from "../components/listingDetail/aviationParts/aviationTable";
import {
  deleteAviationParts,
  getAviationDetails,
  getAviationLocations,
  getAviationParts,
  getCondition,
  getMfr,
  getModels,
  getPart,
  getPartMfr,
  updateAviationParts,
} from "../store/aviation";
import ViewDetails from "../components/listingDetail/aviationParts/viewDetails";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import DeleteModal from "../layouts/modals/deleteModal";
import { toast } from "react-toastify";
import AlertSuccess from "../common/alerts/alertSuccess";
import AlertError from "../common/alerts/alertError";
import EditAviationDetails from "../components/listingDetail/aviationParts/editAviationParts";
import { getProfile } from "../utils.js/localStorageServices";
import moment from "moment";
import _, { isBoolean } from "lodash";

const Avaition = (props) => {
  const ref = useRef(null);
  const history = useHistory();

  //Upload Parts
  const [showUploadPartModal, setShowUploadPartModal] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [applyFilter, setApplyFilter] = useState(false);
  const [loading, setLoading] = useState(false);

  const [viewModal, setViewModal] = useState(false);
  const [selectedValues, setSelectedValues] = useState(null);

  const [sort, setSort] = useState("asc");
  const [sortBy, setSortBy] = useState("createdAt");
  const [page, setPage] = useState(1);

  const [showDelete, setShowDelete] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const [showEdit, setShowEdit] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [editData, setEditData] = useState({
    partDescription: "",
    quantity: 0,
    certificate: false,
    unitPrice: 0,
    partNumber: "",
    condition: "",
    location: "",
    tagType: "",
    alternatePartNumber: "",
    partType: "",
    planeModels: "",
  });

  const [response, setResponse] = useState({
    res: false,
    auctionId: "",
    partNumber: "",
    condition: "",
    certificate: "",
  });

  const [selectedFilter, setSelectedFilter] = useState({
    auctionId: "",
    partNumber: "",
    planeParts: "",
    planeCondition: "",
    certificates: "",
    warranty: "",
    manufacturerParts: "",
    planeModel: "",
  });

  const condition = props.getAviationDetails?.condition?.data?.map((item) => ({
    name: `(${item.title}) ${item.code}`,
    value: item.code,
  }));
  const mfr = props.getAviationDetails?.mfr?.data?.map((item) => ({
    name: item.title,
    value: item.title,
  }));
  const partMfr = props.getAviationDetails?.partMfr?.data?.map((item) => ({
    name: item.title,
    value: item.title,
  }));
  const model = props.getAviationDetails?.model?.data?.map((item) => ({
    name: item.title,
    value: item.title,
  }));
  const part = props.getAviationDetails?.part?.data?.map((item) => ({
    name: item.title,
    value: item.title,
  }));
  const locations = props.getAviationDetails?.location?.data?.map((item) => ({
    name: item.title,
    value: item.title,
  }));

  const location = [
    { name: "United States", value: "United States" },
    { name: "Americas", value: "Americas" },
    { name: "Africa", value: "Africa" },
    { name: "Asia-Pacific", value: "Asia-Pacific" },
    { name: "Europe", value: "Europe" },
    { name: "Middle East", value: "Middle East" },
  ];

  useEffect(() => {
    if (showEdit) {
      const data = { ...editData };
      const z = [];
      const b =
        selectedValues?.planeModels && selectedValues?.planeModels?.length > 0
          ? selectedValues?.planeModels.map((item) =>
              model.map((md) => {
                if (md.name == item) {
                  z.push(md);
                }
              }),
            )
          : [];
      data.partDescription = selectedValues.partDescription ? selectedValues.partDescription : "";
      data.quantity = selectedValues.quantity ? selectedValues.quantity : 0;
      data.certificate = selectedValues.certificate ? true : false;
      data.unitPrice = selectedValues.unitPrice ? selectedValues.unitPrice : 0;
      data.partNumber = selectedValues.partNumber ? selectedValues.partNumber : 0;
      data.condition = selectedValues.condition ? selectedValues.condition : "";
      data.location = selectedValues.location ? selectedValues.location : "";
      data.partType = selectedValues.partType ? selectedValues.partType : "";
      data.planeModels = selectedValues.planeModels?.length > 0 ? selectedValues.planeModels : [];
      data.planeManufacturer = selectedValues.planeManufacturer ? selectedValues.planeManufacturer : "";
      data.partManufacturer = selectedValues.partManufacturer ? selectedValues.partManufacturer : "";

      setEditData(data);
    }
  }, [showEdit]);

  const toggleDelete = () => {
    setShowDelete(!showDelete);
  };

  const toggleEdit = () => {
    setShowEdit(!showEdit);
  };

  const aviationData = (props?.getAviationDetails && props?.getAviationDetails?.aviation?.data) || [];

  const toggleViewModal = () => {
    setViewModal(!viewModal);
  };

  const toggleFilter = () => {
    setSelectedFilter({
      auctionId: response?.auctionId ? response?.auctionId : "",
      partNumber: response?.partNumber ? response?.partNumber : "",
      planeCondition: response?.condition ? response?.condition : "",
      certificates: response?.certificate,
    });
    setShowFilter(!showFilter);
  };

  const toggleUploadPartModal = () => {
    setShowUploadPartModal(!showUploadPartModal);
  };

  const member = getProfile();

  const isAviationAccessible =
    member && member?.accessLevels && member?.accessLevels?.aviation_parts_admin_access ? true : false;

  useEffect(() => {
    if (!isAviationAccessible) {
      history.push("/404");
    }
  }, [member]);

  const loadAvaitionParts = () => {
    setLoading(true);
    const payload = {
      partNumber: response?.partNumber ? response?.partNumber : "",
      auctionId: response?.auctionId ? response?.auctionId : "",
      planeCondition:
        response?.condition?.length > 0
          ? response?.condition?.map((item) => (item.value != "-1" ? item.value : "")).join(",")
          : "",

      page: page,
      order: sort,
      sort: sortBy,
      isArchived: true,
    };
    if (response?.certificate) {
      payload.certificate = response?.certificate?.value ? true : false;
    }
    props.getAviationParts(payload, (res) => {
      if (res && res.status == 200) {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    loadAvaitionParts();
  }, []);

  useEffect(() => {
    if (showUploadPartModal) {
      props.getAviationParts({ page: 1, order: "desc", sort: "createdAt", isArchived: true }, (res) => {
        if (res && res.status == 200) {
        }
      });
    }
  }, [showUploadPartModal]);

  useEffect(() => {
    if (aviationData?.length) {
      setLoading(true);
      const payload = {
        partNumber: response?.partNumber ? response?.partNumber : "",
        auctionId: response?.auctionId ? response?.auctionId : "",
        planeCondition:
          response?.condition?.length > 0
            ? response?.condition?.map((item) => (item.value != "-1" ? item.value : "")).join(",")
            : "",

        page: page,
        order: sort,
        sort: sortBy,
        isArchived: true,
      };
      if (response?.certificate) {
        payload.certificate = response?.certificate?.value ? true : false;
      }
      props.getAviationParts(payload, (res) => {
        if (res && res.status == 200) {
          setLoading(false);
        }
      });
    }
  }, [page]);

  useEffect(() => {
    if (!viewModal) {
      setSelectedValues(null);
    }
  }, [viewModal]);

  const handleFilter = () => {
    setLoading(true);
    ref?.current?.continuousStart();
    const param = {
      partNumber: selectedFilter?.partNumber ? selectedFilter?.partNumber : "",
      auctionId: selectedFilter?.auctionId ? selectedFilter?.auctionId : "",
      planeCondition:
        selectedFilter?.planeCondition?.length > 0
          ? selectedFilter?.planeCondition?.map((item) => (item.value != "-1" ? item.value : "")).join(",")
          : "",
      certificate: selectedFilter?.certificates
        ? selectedFilter?.certificates?.value === true
          ? true
          : selectedFilter?.certificates?.value === false
          ? false
          : ""
        : "",
      page: page,
      order: sort,
      sort: sortBy,
      isArchived: true,
    };
    const payload = _.pickBy(param, function (value, key) {
      return !(value === "" || value == {} || value === undefined || value === null);
    });
    if (payload.partNumber || payload.auctionId || payload.planeCondition || payload.certificate) {
      setApplyFilter(true);
    }
    props.getAviationParts(payload, (res) => {
      if (res && res.status == 200) {
        const data = { ...response };
        data.res = true;
        data.auctionId = selectedFilter?.auctionId;
        data.partNumber = selectedFilter?.partNumber;
        data.certificate = selectedFilter?.certificates;
        data.condition = selectedFilter?.planeCondition;
        setResponse(data);
        setLoading(false);
        ref?.current?.complete();
        toggleFilter();
      }
    });
  };

  const handleClearFilter = () => {
    setLoading(true);
    ref?.current?.continuousStart();
    const param = {
      partNumber: response?.partNumber ? response?.partNumber : "",
      auctionId: response?.auctionId ? response?.auctionId : "",
      planeCondition: response?.condition?.length > 0 ? response?.condition?.map((item) => item.value).join(",") : "",
      certificate: response?.certificate ? (response?.certificate?.value ? true : false) : "",

      page: 1,
      order: sort,
      sort: sortBy,
      isArchived: true,
    };

    const payload = _.pickBy(param, function (value, key) {
      return !(value === "" || value == {} || value === undefined || value === null);
    });
    // if (
    //   !payload.partNumber &&
    //   !payload.auctionId &&
    //   !payload.partType &&
    //   !payload.planeCondition &&
    //   // !payload.planePartManufacturer &&
    //   !payload.planeModels &&
    //   !payload.certificate &&
    //   !payload.warranty
    // ) {
    //   setApplyFilter(false);
    // }

    props.getAviationParts(payload, (res) => {
      if (res && res.status == 200) {
        setLoading(false);
        ref?.current?.complete();
      }
    });
  };

  useEffect(() => {
    if (!showFilter && applyFilter) {
      handleClearFilter();
    }
  }, [response]);
  const getSortedData = (sortBy, sort) => {
    setLoading(true);
    ref?.current?.continuousStart();
    const param = {
      partNumber: response?.partNumber ? response?.partNumber : "",
      auctionId: response?.auctionId ? response?.auctionId : "",
      planeCondition:
        response?.condition?.length > 0
          ? response?.condition?.map((item) => (item.value != "-1" ? item.value : "")).join(",")
          : "",
      certificate: response?.certificate ? (response?.certificate?.value ? true : false) : "",

      page: 1,
      order: sort,
      sort: sortBy,
      isArchived: true,
    };
    const payload = _.pickBy(param, function (value, key) {
      return !(value === "" || value == {} || value === undefined || value === null);
    });
    props.getAviationParts(payload, (res) => {
      setPage(1);
      if (res && res.status == 200) {
        setLoading(false);
        ref?.current?.complete();
      }
    });
  };

  useEffect(() => {
    if (sortBy == "partNumber") {
      if (sort == "asc") {
        getSortedData("partNumber", "asc");
      } else {
        getSortedData("partNumber", "desc");
      }
    } else if (sortBy == "condition") {
      if (sort == "asc") {
        getSortedData("condition", "asc");
      } else {
        getSortedData("condition", "desc");
      }
    } else if (sortBy == "partManufacturer") {
      if (sort == "asc") {
        getSortedData("partManufacturer", "asc");
      } else {
        getSortedData("partManufacturer", "desc");
      }
    } else if (sortBy == "partType") {
      if (sort == "asc") {
        getSortedData("partType", "asc");
      } else {
        getSortedData("partType", "desc");
      }
    } else if (sortBy == "planeModels") {
      if (sort == "asc") {
        getSortedData("planeModels", "asc");
      } else {
        getSortedData("planeModels", "desc");
      }
    } else if (sortBy == "warranty") {
      if (sort == "asc") {
        getSortedData("warranty", "asc");
      } else {
        getSortedData("warranty", "desc");
      }
    } else if (sortBy == "quantity") {
      if (sort == "asc") {
        getSortedData("quantity", "asc");
      } else {
        getSortedData("quantity", "desc");
      }
    } else if (sortBy == "availablePrice") {
      if (sort == "asc") {
        getSortedData("availablePrice", "asc");
      } else {
        getSortedData("availablePrice", "desc");
      }
    } else if (sortBy == "wholesalePrice") {
      if (sort == "asc") {
        getSortedData("wholesalePrice", "asc");
      } else {
        getSortedData("wholesalePrice", "desc");
      }
    } else if (sortBy == "location") {
      if (sort == "asc") {
        getSortedData("location", "asc");
      } else {
        getSortedData("location", "desc");
      }
    } else if (sortBy == "certificate") {
      if (sort == "asc") {
        getSortedData("certificate", "asc");
      } else {
        getSortedData("certificate", "desc");
      }
    } else if (sortBy == "partDescription") {
      if (sort == "asc") {
        getSortedData("partDescription", "asc");
      } else {
        getSortedData("partDescription", "desc");
      }
    }
  }, [sortBy, sort]);

  useEffect(() => {
    props.getCondition({}, () => {});

    props.getModels({ isParent: false }, () => {});
    props.getMfr({}, () => {});
    props.getPartMfr({}, () => {});
    props.getAviationLocations({}, () => {});

    props.getParts({}, () => {});
  }, []);

  const aviationPaginationData = props?.getAviationDetails && props?.getAviationDetails?.aviation?.pagination;

  const filteredCount = aviationPaginationData?.filteredCount;
  const totalCount = aviationPaginationData?.totalCount;
  const totalPage = Math.ceil(aviationPaginationData?.totalCount / aviationPaginationData?.recordsPerPage);

  const deleteHandler = () => {
    ref?.current?.continuousStart();
    setLoadingDelete(true);
    props.deleteAviation(selectedValues?._id, (res) => {
      if (res && res.status == 200) {
        setLoadingDelete(false);
        ref?.current?.complete();
        // toggleDelete();
        setShowDelete(false);
        setShowEdit(false);
        loadAvaitionParts();
      } else {
        setLoadingDelete(false);
        ref?.current?.complete();
        toast(<AlertError message={res?.data?.message ? res?.data?.message : "Something went wrong"} />);
      }
    });
  };

  const editHandler = () => {
    const payload = {
      partDescription: editData.partDescription ? editData.partDescription : " ",
      quantity: editData.quantity ? editData.quantity : "",
      certificate: editData?.certificate?.value ? editData?.certificate?.value : false,
      unitPrice: editData.unitPrice ? editData.unitPrice : "",
      partNumber: editData.partNumber ? editData.partNumber?.replace("#", "")?.replace("?", "")?.replace("/", "") : "",
      condition: editData?.condition?.value ? editData?.condition?.value : "",
      location: editData?.location ? editData?.location : "",
      partType: editData.partType ? editData.partType : "",
      planeModels: editData?.planeModels?.length > 0 ? editData?.planeModels : [],
      partManufacturer: editData.partManufacturer ? editData.partManufacturer : "",
      planeManufacturer: editData.planeManufacturer ? editData.planeManufacturer : "",
    };
    const param = _.pickBy(payload, function (value, key) {
      return !(value === "" || value == {} || value === undefined || value === null);
    });
    if (param?.quantity?.toString()?.split("")?.length > 0) {
      setLoadingEdit(true);
      ref?.current?.continuousStart();
      props.updateAviationParts(selectedValues?._id, param, (res) => {
        if (res && res.status == 200) {
          setLoadingEdit(false);
          ref?.current?.complete();
          toast(<AlertSuccess message={"Information Saved"} />);
          toggleEdit();
          loadAvaitionParts();
        } else {
          setLoadingEdit(false);
          ref?.current?.complete();
          toast(<AlertError message={res?.data?.message ? res?.data?.message : "Something went wrong"} />);
        }
      });
    } else {
      toast(<AlertError message={"Quantity is not allowed to be empty"} />);
    }
  };

  return (
    <>
      <LoadingBar height={5} color="#47AD1D" ref={ref} />
      <Header />

      <AviationTable
        toggleUploadPartModal={toggleUploadPartModal}
        showUploadPartModal={showUploadPartModal}
        aviationData={aviationData}
        toggleFilter={toggleFilter}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
        handleFilter={handleFilter}
        loading={loading}
        handleClearFilter={handleClearFilter}
        setSelectedValues={setSelectedValues}
        toggleViewModal={toggleViewModal}
        sortBy={sortBy}
        setSortBy={setSortBy}
        sort={sort}
        setSort={setSort}
        filteredCount={filteredCount}
        totalCount={totalCount}
        page={page}
        totalPages={totalPage}
        setPage={setPage}
        toggleDelete={toggleDelete}
        showDelete={showDelete}
        setShowDelete={setShowDelete}
        loadingDelete={loadingDelete}
        deleteHandler={deleteHandler}
        toggleEdit={toggleEdit}
        loadData={loadAvaitionParts}
        condition={condition}
        mfr={mfr}
        part={part}
        model={model}
        location={location}
        selectedFilter={selectedFilter}
        setSelectedFilter={setSelectedFilter}
        applyFilter={applyFilter}
        response={response}
        setResponse={setResponse}
        isArchived={true}
      />
      <ViewDetails show={viewModal} onHide={toggleViewModal} selectedValue={selectedValues} />

      <EditAviationDetails
        isAviation={true}
        show={showEdit}
        onHide={toggleEdit}
        selectedValue={selectedValues}
        condition={condition}
        mfr={mfr}
        partMfr={partMfr}
        part={part}
        model={model}
        locations={locations}
        data={editData}
        setData={setEditData}
        loadingEdit={loadingEdit}
        handleEdit={editHandler}
        loadingDelete={loadingDelete}
        deleteHandler={deleteHandler}
      />

      <DeleteModal show={showDelete} onHide={toggleDelete} deleteHandler={deleteHandler} loading={loadingDelete} />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getAviationParts: (params, callback) => dispatch(getAviationParts(params, callback)),
  deleteAviation: (id, callback) => dispatch(deleteAviationParts(id, callback)),
  updateAviation: (id, callback) => dispatch(updateAviationParts(id, callback)),
  getMfr: (params, callback) => dispatch(getMfr(params, callback)),
  getPartMfr: (params, callback) => dispatch(getPartMfr(params, callback)),
  getParts: (params, callback) => dispatch(getPart(params, callback)),
  getAviationLocations: (params, callback) => dispatch(getAviationLocations(params, callback)),
  getCondition: (params, callback) => dispatch(getCondition(params, callback)),
  getModels: (params, callback) => dispatch(getModels(params, callback)),
  updateAviationParts: (id, data, callback) => dispatch(updateAviationParts(id, data, callback)),
});
const mapStateToProps = (state) => ({
  getAviationDetails: getAviationDetails(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Avaition));
