import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import close from "../../../include/images/close-12x12.svg";
import _ from "lodash";
import Select from "react-select";
import NextButton from "../../../common/form/nextButton";
import { customStyles } from "../../../common/customStyles/reactSelectStyle";
import { useState } from "react";
import { useRef } from "react";
import LoadingBar from "react-top-loading-bar";
import { getResearchAssignmentDomain, uploadReseachAssFile } from "../../../store/researchAssignment";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import AlertError from "../../../common/alerts/alertError";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import HTMLReactParser from "html-react-parser";

const ViewParts = (props) => {
  const ref = useRef();
  const [loading, setLoading] = useState(false);
  const data = props.fileData?.fileDate || [];
  const [excludeHeader, setExcludeHeader] = useState(false);
  const [mappedData, setMappedData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [errors, setErrors] = useState("");

  const options = [
    { name: "Unmapped", value: "unmapped" },
    { name: "Company Name", value: "company" },
    { name: "URL", value: "url" },
    { name: "City", value: "city" },
    { name: "Country", value: "country" },
    { name: "State", value: "state" },
    { name: "Zip Code", value: "zip_code" },
    { name: "Phone", value: "phone" },
  ];

  const mappedStateChooser = (data) => {
    if (data === "Company Name" || data === "company") {
      return { name: "Company Name", value: "company" };
    } else if (data === "URL" || data === "url") {
      return { name: "URL", value: "url" };
    } else if (data === "Zip Code") {
      return { name: "Zip Code", value: "zip_code" };
    } else if (data === "Phone") {
      return { name: "Phone", value: "phone" };
    } else if (data === "State") {
      return { name: "State", value: "state" };
    } else if (data === "Country") {
      return { name: "Country", value: "country" };
    }
  };

  useEffect(() => {
    if (props.fileData?.fileDate && mappedData.length == 0) {
      var a = [];
      const b =
        data &&
        data?.length > 0 &&
        data[0][0].map((item, i) => {
          const value = options.map((it) => it.name).includes(item)
            ? { name: item, value: item }
            : item === "Domain"
            ? { name: "URL", value: "url" }
            : item === "Name"
            ? {
                name: "Company Name",
                value: "company",
              }
            : { name: "Unmapped", value: "unmapped" };
          a.push(value.value);
        });

      setMappedData(a);
    }
  }, [props.fileData?.fileDate]);

  const submitHandler = () => {
    let a = [];
    for (let i = 0; i < data.length; i++) {
      a.push(...data[i]);
    }
    setTableData(a);
    const totalData = a.length;

    for (let i = 0; i <= Math.ceil(totalData / 250) - 1; i += 1) {
      ref?.current?.continuousStart();
      setLoading(true);
      const payload = {
        document: props.fileData?.document,
        action: "save",
        start: i * 250 > totalData ? i * 250 - totalData : i * 250,
        exclude_header_row: excludeHeader,
        columns: mappedData.map((item) => (item === "unmapped" ? "" : item)),
      };
      props.uploadReseachAssFile(props.assId, "put", payload, (res) => {
        if (res && res.status === 200) {
          setErrors(res?.data?.data?.errors);
          if (i >= Math.ceil(totalData / 250) - 1 && !res?.data?.data?.errors) {
            props.getResearchAssignmentDomain(props.assId, (res) => {
              if (res && res.status == 200) {
                setLoading(false);
                ref?.current?.complete();
                props.onHide();
                toast(<AlertSuccess message={"Information Saved"} />);
              }
            });
          } else if (i >= Math.ceil(totalData / 250) - 1 && res?.data?.data?.errors) {
            setLoading(false);
            ref?.current?.complete();
            toast(<AlertError message="Something went wrong" />);
          }
        } else {
          setLoading(false);
          ref?.current?.complete();
          toast(<AlertError message={res && res.data?.message ? res?.data?.message : "Something Went Wrong"} />);
        }
      });
    }
  };

  return (
    <>
      <LoadingBar height={5} color="#47AD1D" ref={ref} />
      <Modal
        className="modal fade custom-modal"
        id="editProductDescription"
        show={props.show}
        // onHide={() => props.onHide()}
        dialogClassName="modal-dialog modal-dialog-centered modal-xxl"
      >
        <div class="modal-content d-flex flex-column">
          <div class="custom-modal-header d-flex align-items-center justify-content-between">
            {errors ? (
              <h5 class="mb-0 text-capitalize">Errors</h5>
            ) : (
              <h5 class="mb-0 text-capitalize" style={{ marginLeft: "40px" }}>
                Map Fields
              </h5>
            )}

            <button
              type="button"
              class="close-btn d-flex align-items-center justify-content-center"
              onClick={() => {
                props.setFile("");
                setErrors("");
                props.onHide();
              }}
            >
              <img src={close} alt="" />
            </button>
          </div>

          <Modal.Body className="custom-modal-body flex-grow-1 w-100 overflow-hidden">
            {errors ? (
              <div class="table-responsive table-custom">
                <table class="table table-bordered">
                  <colgroup>
                    <col />
                  </colgroup>

                  <tbody>{HTMLReactParser(errors)}</tbody>
                </table>
              </div>
            ) : (
              <div class="offcanvas-widget-row pt-0">
                <p>
                  Import <span style={{ fontWeight: "600" }}>{props.fileName}</span>{" "}
                </p>
                <p>Map fields to your file below. Unmapped columns will not be imported.</p>
                <div class="form-check tick-checkbox d-flex align-items-center mb-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value={excludeHeader}
                    onChange={() => setExcludeHeader(!excludeHeader)}
                    id="excludeHeader"
                  />
                  <label class="form-check-label" for="excludeHeader" style={{ paddingTop: "3px" }}>
                    {" "}
                    Exclude Header Row
                  </label>
                </div>
                <div class="table-responsive table-custom">
                  <table class="table table-bordered">
                    <colgroup>
                      {data && data?.length > 0 && data[0][0].map((item) => <col style={{ minWidth: "150px" }} />)}
                      <col />
                    </colgroup>
                    <thead>
                      <tr>
                        {data &&
                          data?.length > 0 &&
                          data[0][0].map((item, i) => {
                            return (
                              <td>
                                <Select
                                  value={mappedStateChooser(mappedData[i])}
                                  options={options}
                                  className="basic-single country-margin select-background "
                                  classNamePrefix="select-search country-margin select-background"
                                  getOptionLabel={(option) => option.name}
                                  getOptionValue={(option) => option.value}
                                  name={`selectMap${i}`}
                                  styles={customStyles}
                                  onChange={(e) => {
                                    const data = [...mappedData];
                                    data[i] = e.value;
                                    setMappedData(data);
                                  }}
                                />
                              </td>
                            );
                          })}
                      </tr>
                    </thead>

                    <tbody>
                      {data &&
                        data?.length > 0 &&
                        data.map(
                          (item) =>
                            item &&
                            item.map((it, i) =>
                              excludeHeader && i === 0 ? (
                                <tr style={{ textDecoration: " line-through" }}>
                                  {it?.map((er) => (
                                    <td>{er}</td>
                                  ))}{" "}
                                </tr>
                              ) : (
                                <tr>
                                  {it?.map((er) => (
                                    <td>{er}</td>
                                  ))}{" "}
                                </tr>
                              ),
                            ),
                        )}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </Modal.Body>
          <div class="custom-modal-dividor"></div>
          <div class="custom-modal-footer d-grid gap-2 d-md-flex align-items-center justify-content-md-end">
            <button
              class="btn btn-link me-md-2 global-cancel-button"
              type="button"
              onClick={() => {
                props.setFile();
                setErrors("");
                props.onHide();
              }}
            >
              Cancel
            </button>

            {!errors && (
              <NextButton
                classData="btn btn-default"
                handleSubmit={() => submitHandler()}
                label={"Save"}
                loading={loading}
              />
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  uploadReseachAssFile: (params, type, data, callback) => dispatch(uploadReseachAssFile(params, type, data, callback)),
  getResearchAssignmentDomain: (params, callback) => dispatch(getResearchAssignmentDomain(params, callback)),
});

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(ViewParts));
