import { createSlice, current } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";

const slice = createSlice({
  name: "commission",
  initialState: {
    loading: false,
    commission: [],
    employee: [],
  },
  reducers: {
    commissionRequested: (misc, action) => {
      misc.loading = true;
    },
    commissionReceived: (misc, action) => {
      misc.commission = action.payload;
      misc.loading = false;
    },
    commissionRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    employeeRequested: (misc, action) => {
      misc.loading = true;
    },
    employeeReceived: (misc, action) => {
      misc.employee = action.payload;
      misc.loading = false;
    },
    employeeRequestFailed: (misc, action) => {
      misc.loading = false;
    },
  },
});

export const {
  commissionRequested,
  commissionReceived,
  commissionRequestFailed,
  employeeRequested,
  employeeReceived,
  employeeRequestFailed,
} = slice.actions;
export default slice.reducer;

// Action Creators

const baseUrl = "account/commission";

export const getCommissionData = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: baseUrl,
      method: "get",
      params,
      callback,
      onStart: commissionRequested.type,
      onSuccess: commissionReceived.type,
      onError: commissionRequestFailed.type,
    })
  );
};
export const getEmployee = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: baseUrl + "/employee",
      method: "get",
      callback,
      onStart: employeeRequested.type,
      onSuccess: employeeReceived.type,
      onError: employeeRequestFailed.type,
    })
  );
};

export const getCommission = createSelector(
  (state) => state.entities.commission,
  (commission) => commission
);
