import { createSelector, createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";

const slice = createSlice({
  name: "questions",
  initialState: {
    loading: false,
    questions: [],
    question: {},
    conversationList: {},
    auctionConversationList: {},
    conversation: {},
    allUnreadCount: {},
    reminderQuestion: {},
  },
  reducers: {
    questionFetching: (state, action) => {
      state.loading = true;
    },
    questionFetched: (state, action) => {
      state.questions = action.payload;
      state.loading = false;
    },
    questionFailed: (state, action) => {
      state.loading = false;
    },
    reminderQuestionFetching: (state, action) => {
      state.loading = true;
    },
    reminderQuestionFetched: (state, action) => {
      state.reminderQuestion = action.payload;
      state.loading = false;
    },
    reminderQuestionFailed: (state, action) => {
      state.loading = false;
    },
    questionDataFetching: (state, action) => {
      state.loading = true;
    },
    questionDataFetched: (state, action) => {
      state.question = action.payload;
      state.loading = false;
    },
    questionDataFailed: (state, action) => {
      state.loading = false;
    },
    conversationFetching: (state, action) => {
      state.loading = true;
    },
    conversationFetched: (state, action) => {
      state.conversation = action.payload;
      state.loading = false;
    },
    conversationFailed: (state, action) => {
      state.loading = false;
    },
    conversationListFetching: (state, action) => {
      state.loading = true;
    },
    conversationListFetched: (state, action) => {
      state.conversationList = action.payload;
      state.loading = false;
    },
    conversationListFailed: (state, action) => {
      state.loading = false;
    },
    auctionConversationListFetching: (state, action) => {
      state.loading = true;
    },
    auctionConversationListFetched: (state, action) => {
      state.auctionConversationList = action.payload;
      state.loading = false;
    },
    auctionConversationListFailed: (state, action) => {
      state.loading = false;
    },
    allUnreadCountFetching: (state, action) => {
      state.loading = true;
    },
    allUnreadCountFetched: (state, action) => {
      state.allUnreadCount = action.payload;
      state.loading = false;
    },
    allUnreadCountFailed: (state, action) => {
      state.loading = false;
    },
  },
});

export const {
  questionFetching,
  questionFetched,
  questionFailed,
  questionDataFetching,
  questionDataFetched,
  questionDataFailed,
  conversationListFetching,
  conversationListFetched,
  conversationListFailed,
  auctionConversationListFetching,
  auctionConversationListFetched,
  auctionConversationListFailed,
  conversationFetching,
  conversationFetched,
  conversationFailed,
  allUnreadCountFetching,
  allUnreadCountFetched,
  allUnreadCountFailed,
  reminderQuestionFetching,
  reminderQuestionFetched,
  reminderQuestionFailed,
} = slice.actions;
export default slice.reducer;

//Action Creators
const baseUrl = "auctionOther/other/question";
const questionDetailUrl = "auctionOther/other/questionDetail";

//Get Questions
export const getQuestions = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/${params}`,
      method: "get",
      callback,
      onStart: questionFetching.type,
      onSuccess: questionFetched.type,
      onError: questionFailed.type,
    }),
  );
};

//Delete Question
export const deleteQuestion = (params, questionId, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/${params}?q_id=${questionId}`,
      method: `delete`,
      questionId,
      callback,
    }),
  );
};

//Get Queston Detail
export const getQuestionDetail = (params, questionId, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${questionDetailUrl}/${params}?q_id=${questionId}`,
      method: "get",
      callback,
      onStart: questionDataFetching.type,
      onSuccess: questionDataFetched.type,
      onError: questionDataFailed.type,
    }),
  );
};

//Update Question
export const updateQuestion = (params, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/${params}`,
      method: "put",
      data,
      callback,
    }),
  );
};

export const getConversationList = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `questions/list/all`,
      method: "get",
      params,
      callback,
      onStart: conversationListFetching.type,
      onSuccess: conversationListFetched.type,
      onError: conversationListFailed.type,
    }),
  );
};
export const getAuctionConversationList = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `questions/list/${params}`,
      method: "get",
      callback,
      onStart: auctionConversationListFetching.type,
      onSuccess: auctionConversationListFetched.type,
      onError: auctionConversationListFailed.type,
    }),
  );
};

export const getConversation = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `questions/conversation/${id}`,
      method: "get",
      callback,
      onStart: conversationFetching.type,
      onSuccess: conversationFetched.type,
      onError: conversationFailed.type,
    }),
  );
};

export const marAsReadConversation = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `questions/markAsRead`,
      method: "put",
      data,
      callback,
    }),
  );
};

export const getAllUnreadCount = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `questions/allUnreadCount`,
      method: "get",
      callback,
      onStart: allUnreadCountFetching.type,
      onSuccess: allUnreadCountFetched.type,
      onError: allUnreadCountFailed.type,
    }),
  );
};

export const getAllReminderQuestions = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `questions/reminder`,
      method: "get",
      params,
      callback,
      onStart: reminderQuestionFetching.type,
      onSuccess: reminderQuestionFetched.type,
      onError: reminderQuestionFailed.type,
    }),
  );
};

export const addReminderQuestion = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `questions/markForReminder`,
      method: "post",
      data,
      callback,
    }),
  );
};

export const updateReminderQuestion = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `questions/markReminderAsRead`,
      method: "put",
      data,
      callback,
    }),
  );
};

export const markAsUnread = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `questions/markAsUnRead`,
      method: "put",
      data,
      callback,
    }),
  );
};

export const questionDetails = createSelector(
  (state) => state.entities.questions,
  (questions) => questions,
);
