import React from "react";
import { Modal } from "react-bootstrap";
import close from "../../include/images/close.svg";
import Select from "react-select";
import { customStyles } from "../customStyles/reactSelectStyle";
import NextButton from "../form/nextButton";

const FindUser = (props) => {
  return (
    <Modal
      className="modal fade custom-modal"
      id="newBuyer"
      show={props.show}
      onHide={props.onHide}
      dialogClassName="modal-dialog modal-dialog-centered modal-lg"
    >
      <div class="modal-content h-100 d-flex flex-column">
        <div class="custom-modal-header d-flex align-items-center justify-content-between">
          <h5 class="mb-0 text-capitalize">{props?.title}</h5>
          <button
            type="button"
            class="close-btn d-flex align-items-center justify-content-center"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={props.onHide}
          >
            <img src={close} alt="" />
          </button>
        </div>
        <div class="custom-modal-body flex-grow-1 hide-scrollbar">
          {!props.response ? (
            <table class="table modal-product-detail-table mb-0">
              <colgroup>
                <col style={{ width: "30%" }} />
                <col style={{ width: "70%" }} />
              </colgroup>
              <tr>
                <td>Name</td>
                <td>
                  <div>
                    <input
                      type="text"
                      value={props?.searchData?.name}
                      onChange={(e) => {
                        const data = { ...props?.searchData };
                        data.name = e.target.value;
                        props?.setSearchData(data);
                      }}
                      class="form-control"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>Company</td>
                <td>
                  <div>
                    <input
                      type="text"
                      value={props?.searchData?.company_name}
                      onChange={(e) => {
                        const data = { ...props?.searchData };
                        data.company_name = e.target.value;
                        props?.setSearchData(data);
                      }}
                      class="form-control"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>City</td>
                <td>
                  <div>
                    <input
                      type="text"
                      value={props?.searchData?.city}
                      onChange={(e) => {
                        const data = { ...props?.searchData };
                        data.city = e.target.value;
                        props?.setSearchData(data);
                      }}
                      class="form-control"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>Zip</td>
                <td>
                  <div>
                    <input
                      type="text"
                      value={props?.searchData?.zip}
                      onChange={(e) => {
                        const data = { ...props?.searchData };
                        data.zip = e.target.value;
                        props?.setSearchData(data);
                      }}
                      class="form-control"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>Country</td>
                <td>
                  <div>
                    <Select
                      styles={customStyles}
                      options={props.country}
                      placeholder="Select Country"
                      getOptionLabel={(item) => item.description}
                      getOptionValue={(item) => item.old}
                      onChange={(e) => {
                        const data = { ...props?.searchData };
                        data.c_des = e.description;
                        props.setSearchData(data);
                      }}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>State</td>
                <td>
                  <div>
                    <Select
                      styles={customStyles}
                      options={
                        props?.searchData?.c_des === "United States"
                          ? props.state
                          : [
                              {
                                description: "International",
                                abb: "IT",
                              },
                            ]
                      }
                      placeholder="Select State"
                      getOptionLabel={(item) => item.description}
                      getOptionValue={(item) => item.abb}
                      onChange={(e) => {
                        const data = { ...props?.searchData };
                        data.st_des = e.abb;
                        props.setSearchData(data);
                      }}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>Phone</td>
                <td>
                  <div>
                    <input
                      type="text"
                      value={props?.searchData?.phone}
                      onChange={(e) => {
                        const data = { ...props?.searchData };
                        data.phone = e.target.value;
                        props?.setSearchData(data);
                      }}
                      class="form-control"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>Fax</td>
                <td>
                  <div>
                    <input
                      type="text"
                      value={props?.searchData?.fax}
                      onChange={(e) => {
                        const data = { ...props?.searchData };
                        data.fax = e.target.value;
                        props?.setSearchData(data);
                      }}
                      class="form-control"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>Cell</td>
                <td>
                  <div>
                    <input
                      type="text"
                      value={props?.searchData?.cell}
                      onChange={(e) => {
                        const data = { ...props?.searchData };
                        data.cell = e.target.value;
                        props?.setSearchData(data);
                      }}
                      class="form-control"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>Email</td>
                <td>
                  <div>
                    <input
                      type="text"
                      value={props?.searchData?.email}
                      onChange={(e) => {
                        const data = { ...props?.searchData };
                        data.email = e.target.value;
                        props?.setSearchData(data);
                      }}
                      class="form-control"
                    />
                  </div>
                </td>
              </tr>
              {!props.buyer && (
                <tr>
                  <td>Notes</td>
                  <td>
                    <div>
                      <input
                        type="text"
                        value={props?.searchData?.notes}
                        onChange={(e) => {
                          const data = { ...props?.searchData };
                          data.notes = e.target.value;
                          props?.setSearchData(data);
                        }}
                        class="form-control"
                      />
                    </div>
                  </td>
                </tr>
              )}
            </table>
          ) : props?.resData && props?.resData?.length > 0 ? (
            <div class="table-responsive">
              <table class="large-table">
                <thead>
                  <tr>
                    <th></th>
                    <th>ID</th>
                    <th>NAME</th>
                    <th>COMPANY</th>
                  </tr>
                </thead>
                <tbody>
                  {props?.resData &&
                    props?.resData?.length > 0 &&
                    props?.resData?.map((val) => (
                      <tr>
                        <td>
                          <div class="form-check radio">
                            <input
                              class="form-check-input cursor-pointer"
                              type="radio"
                              name={`resData`}
                              id="resData"
                              onChange={() => {
                                props.setUser(val);
                              }}
                            />
                            <label class="form-check-label" htmlFor="resData"></label>
                          </div>
                        </td>
                        <td>{val.id}</td>
                        <td>{val.name}</td>
                        <td>{val.company_name}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div
              class="page-widget-empty-box text-capitalize d-flex align-items-center justify-content-center text-center"
              style={{
                height: "239px",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <span>No Results. Please try your search again.</span>
              {/* <NextButton
                label="Create New Observer"
                classData="btn btn-default"
                // handleSubmit={fetchOtherListings}
                // loading={loading}
              /> */}
            </div>
          )}
        </div>
        <div class="custom-modal-dividor"></div>
        <div class="custom-modal-footer d-grid gap-2 d-md-flex align-items-center justify-content-md-end">
          <button
            class="btn btn-link me-md-2"
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={props.onHide}
          >
            Cancel
          </button>
          {props.response && props?.resData && props?.resData?.length > 0 ? (
            props?.buyer ? (
              <button className="btn btn-primary" onClick={props.handleSelectUser}>
                Select Buyer
              </button>
            ) : (
              <NextButton
                label="Save"
                classData="btn btn-default btn-lg"
                handleSubmit={props.handleSelectUser}
                loading={props.loading}
              />
            )
          ) : (
            ""
            // <button class="btn btn-primary btn-disabled" type="button">
            //   Select Buyer
            // </button>
          )}
          {props.response ? (
            <button className="btn btn-default btn-lg" onClick={props.handleNewSearch}>
              New Search
            </button>
          ) : (
            <NextButton
              label="Search"
              classData="btn btn-default btn-lg"
              handleSubmit={props.handleSubmit}
              loading={props.loading}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default FindUser;
