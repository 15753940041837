import React from "react";

const PaymentTypeFour = ({ setError4, error4, option4Data, setOption4Data, member, setSelectedOption }) => {
  return (
    <>
      <p>
        <strong>Check (U.S. Only)</strong>
      </p>

      <>
        <p style={{ fontWeight: "600", marginBottom: "0.2rem" }}>
          Please confirm that goods will be released with an email copy of the check being mailed and the release of
          goods will NOT be delayed by your receipt of payment.
        </p>
        <div class="four-column-radio-group fw-medium mb-4">
          {option4Data.checkOption == 1 ? (
            <div class="fcrg-item payment-info-main-option">
              <p class="form-check-label" for="ABflexRadio__1">
                Yes, I confirm that release of goods will not be delayed.
              </p>
            </div>
          ) : (
            <div class="fcrg-item payment-info-main-option">
              <p class="form-check-label" for="ABflexRadio__1">
                No, I need payment in my account before release and need to choose a different payment option.
              </p>
            </div>
          )}
        </div>
      </>

      {option4Data.checkOption == 1 && (
        <>
          <div class=" payment-info-text">
            <label className="payment- fw-medium">Make check payable to</label>
            <p>{option4Data.check_payable}</p>
          </div>
          <div class=" payment-info-text">
            <label className="payment- fw-medium">Send check to the attention of</label>
            <p>{option4Data.check_attention}</p>
          </div>
          <div class=" payment-info-text">
            <label className="payment- fw-medium">Mailing address for the check</label>
            <p>{option4Data.check_address}</p>
          </div>

          <div class=" payment-info-text">
            <label className="payment- fw-medium">
              <strong>What type of reference number do you prefer?</strong>
            </label>
            <div class="four-column-radio-group fw-medium">
              {option4Data.preferred_ref_no == "1" && (
                <div class="fcrg-item payment-info-main-option">
                  <p class="form-check-label" for="RflowRadio3__1">
                    Claim or File Number
                  </p>
                </div>
              )}
              {option4Data.preferred_ref_no == "2" && (
                <div class="fcrg-item payment-info-main-option">
                  <p class="form-check-label" for="RflowRadio3__2">
                    Invoice Number
                  </p>
                </div>
              )}
              {option4Data.preferred_ref_no == "3" && (
                <div class="fcrg-item payment-info-main-option">
                  <p class="form-check-label" for="RflowRadio3__3">
                    Shipping Container Number
                  </p>
                </div>
              )}
              {option4Data.preferred_ref_no == "4" && (
                <div class="fcrg-item">
                  <p class="form-check-label" for="RflowRadio3__4">
                    Salvex Listing number is fine
                  </p>
                </div>
              )}
            </div>
          </div>
          <div class=" payment-info-text">
            <label for="" class="payment- fw-medium">
              Additional email address to be sent confirmation of payment.
            </label>
            <p>{option4Data.other_email}</p>
          </div>
          <div class=" payment-info-text">
            <label className="payment- fw-medium">Do you have any additional information ?</label>
            <p>{option4Data.additional_info}</p>
          </div>
        </>
      )}
    </>
  );
};

export default PaymentTypeFour;
