import React from "react";
import close from "../../../../include/images/close-12x12.svg";
import { Modal } from "react-bootstrap";
import moment from "moment";

const ViewMoreDetails = (props) => {
  const data = props.selectedItem;
  return (
    <Modal
      show={props.show}
      onHide={() => props.onHide()}
      className="modal fade salvex-modal2"
      id="aviationParts"
      dialogClassName="modal-dialog  modal-xl modal-dialog-centered"
      contentClassName="modal-content"
    >
      <div class="custom-modal-header d-flex align-items-center justify-content-between">
        <h5 class="mb-0 text-capitalize">Contract Details</h5>
        <button
          type="button"
          class="close-btn d-flex align-items-center justify-content-center"
          onClick={() => props.onHide()}
        >
          <img src={close} alt="" />
        </button>
      </div>

      <Modal.Body className="modal-body aviation-view-body">
        <div class="modal-heading"></div>
        <div>
          <div className="d-flex  aviation-view-modal">
            <span>Contract Scope</span>
            <span>Contract Type</span>
            <span>Contract Status</span>
          </div>
          <div className="d-flex   aviation-view-modal1">
            <span>
              {data?.contractScope
                ? props.contractScopeOption.filter(
                    (cd) => data?.contractScope === cd.value
                  )?.[0]?.name
                : "N/A"}
            </span>
            <span>
              {data?.contractType
                ? props.contractTypeOptions.filter(
                    (cd) => data?.contractType === cd.value
                  )?.[0]?.name
                : "N/A"}
            </span>
            <span>
              {data?.contractStatus
                ? props.contractStatusOptions.filter(
                    (cd) => data?.contractStatus === cd.value
                  )?.[0]?.name
                : "N/A"}
            </span>
          </div>
        </div>

        <div>
          <div className="d-flex  aviation-view-modal">
            <span>Start Date</span>
            <span>Expiration Date</span> <span>Automatic Renewal</span>
          </div>
          <div className="d-flex   aviation-view-modal1">
            <span>
              {data?.startDate
                ? moment(data?.startDate).format("MM/DD/YYYY")
                : "N/A"}
            </span>
            <span>
              {data?.expirationDate
                ? moment(data?.expirationDate).format("MM/DD/YYYY")
                : "N/A"}
            </span>{" "}
            <span>{data?.automaticRenewal ? "Yes" : "No"}</span>
          </div>
        </div>
        <div>
          <div className="d-flex  aviation-view-modal">
            <span>Termination Notice</span>
            <span>Exclusivity</span> <span>Commission Rate</span>
          </div>
          <div className="d-flex   aviation-view-modal1">
            <span>
              {data?.terminationNotice ? data?.terminationNotice : "N/A"}
            </span>
            <span>{data?.exclusivity ? "Yes" : "No"}</span>{" "}
            <span>{data?.commissionRate ? data?.commissionRate : "N/A"}</span>
          </div>
        </div>
        <div>
          <div className="d-flex  aviation-view-modal">
            <span>Buyer Premium/ Seller Fee</span>
            <span>Salvex Entity</span> <span>Confidentiality</span>
          </div>
          <div className="d-flex   aviation-view-modal1">
            <span>{data?.buyerPremiumOrSellerFee ? "Yes" : "No"}</span>
            <span>
              {data?.salvexEntity
                ? props.entityOptions.filter(
                    (cd) => data?.salvexEntity === cd.value
                  )?.[0]?.name
                : "N/A"}
            </span>{" "}
            <span>{data?.confidentiality ? "Yes" : "No"}</span>
          </div>
        </div>
        <div>
          <div className="d-flex  aviation-view-modal">
            <span>Non-Circumvention </span>
            <span>Governing Laws</span>
            <span>Arbitration</span>
          </div>
          <div className="d-flex   aviation-view-modal1">
            <span>{data?.nonCircumvention === "true" ? "Yes" : "No"}</span>
            <span>{data?.governingLaws ? data?.governingLaws : "N/A"}</span>
            <span>{data?.arbitration ? data?.arbitration : "N/A"}</span>
          </div>
        </div>
        <div>
          <div className="d-flex  aviation-view-modal">
            <span>Indemnification </span>
            <span>Contract Language</span>
            <span>Special Terms</span>
          </div>
          <div className="d-flex   aviation-view-modal1">
            <span>{data?.indemnification ? data?.indemnification : "N/A"}</span>
            <span>
              {data?.contractLanguage ? data?.contractLanguage : "N/A"}
            </span>{" "}
            <span>{data?.specialTerms ? data?.specialTerms : "N/A"}</span>
          </div>
        </div>
        <div>
          <div className="d-flex  aviation-view-modal">
            <span>Registration as a Vendor</span>
            <span>Registration Status</span> <span>Registration Username</span>
          </div>
          <div className="d-flex   aviation-view-modal1">
            <span>{data?.registrationAsVendor ? "Yes" : "No"}</span>
            <span>
              {data?.registrationStatusOptions
                ? props.registrationStatusOptions.filter(
                    (cd) => data?.registrationStatus === cd.value
                  )?.[0]?.name
                : "N/A"}
            </span>
            <span>
              {data?.registrationCredentials?.userName
                ? data?.registrationCredentials?.userName
                : "N/A"}
            </span>
          </div>
        </div>
        <div>
          <div className="d-flex  aviation-view-modal">
            <span>Registration Password</span>
            <span>Registration Url</span>{" "}
            <span>Registration Expiration Date </span>
          </div>
          <div className="d-flex   aviation-view-modal1">
            <span>
              {" "}
              {data?.registrationCredentials?.password
                ? data?.registrationCredentials?.password
                : "N/A"}
            </span>
            <span>
              {data?.registrationCredentials?.url
                ? data?.registrationCredentials?.url
                : "N/A"}
            </span>
            <span>
              {data?.registrationExpirationDate
                ? moment(data?.registrationExpirationDate).format("MM/DD/YYYY")
                : "N/A"}
            </span>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ViewMoreDetails;
