import React, { useEffect, useRef, useState } from "react";
import { Accordion, Offcanvas } from "react-bootstrap";
import close from "../../../include/images/close.svg";
import trash from "../../../include/images/trash-24x24.svg";
import emptyChat from "../../../include/images/chat-empty.svg";
import LoadingBar from "react-top-loading-bar";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import AlertError from "../../../common/alerts/alertError";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import NextButton from "../../../common/form/nextButton";

const CategoryPreferenceModal = (props) => {
  const ref = useRef();
  const [selectAll, setSelectAll] = useState({});
  const [loading, setLoading] = useState(false);
  const categoryData = props.categoryData;
  const userData = props?.userData;
  useEffect(() => {
    props.setSelectedCategory([]);
    setSelectAll({});
  }, [props.show]);

  const handleAddCategoryPreference = () => {
    setLoading(true);
    ref?.current?.continuousStart();
    const categoryList = [];
    props?.selectedCategory?.map((val) => categoryList.push(val.category_id));
    const data = { category_preferences: categoryList };
    props.addCategoryPreference(props.userId, data, (res) => {
      if (res.status === 200) {
        setLoading(false);
        ref?.current?.complete();
        props.onHide();
        toast(<AlertSuccess message={"Information Saved"} />);
        if (props?.buyer) {
          const item = userData?.length > 0 && userData[0];
          props?.setSelectedId(item);
          props?.toggleShowRegion();
        } else {
          props?.setBuyer(false);
        }
      } else {
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        setLoading(false);
        ref?.current?.complete();
      }
    });
  };

  return (
    <>
      <LoadingBar height={5} color="#47AD1D" ref={ref} />
      <Offcanvas
        className="offcanvas offcanvas-end xl-offcanvas border-0"
        id="buyerPreferences"
        placement="end"
        backdropClassName="upload-documnets-backdrop"
        show={props.show}
        onHide={() => {
          props?.setBuyer(false);
          props.onHide();
        }}
        enforceFocus={false}
      >
        <Offcanvas.Header className="header-buyer-pref">
          <h5 class="offcanvas-title">Category Preferences</h5>
          {!props?.categoryLoading && (
            <a
              href="javascript:void(0)"
              class="btn btn-link me-md-2 global-cancel-button cancel-user-pref"
              onClick={() => {
                props?.setBuyer(false);
                props.onHide();
              }}
            >
              {" "}
              Cancel{" "}
            </a>
          )}
          {!props?.categoryLoading && (
            <a
              className={loading ? "btn btn-border-green disabled" : "btn btn-border-green"}
              onClick={handleAddCategoryPreference}
            >
              {loading ? "Loading..." : "Save"}
            </a>
          )}
        </Offcanvas.Header>

        <Offcanvas.Body className="offcanvas-body position-relative p-4">
          <div class="dashboard-widget-row-container w-100 d-lg-flex flex-lg-wrap">
            <div class="dwrc-left">
              <h3 class="widget-title text-black">Add/Edit Category</h3>
              <Accordion className="accordion left-arrow-accordion small-checkbox">
                {props?.categoryLoading
                  ? categoryData?.length > 0 &&
                    categoryData.map((val, i) => (
                      <>
                        <div>
                          <Skeleton height="20px" width="75%" className="mb-3" />
                        </div>
                      </>
                    ))
                  : categoryData.map((val, i) => {
                      const owner = val.subCategories.length > 0 ? val.description : "All Listings";
                      return (
                        <>
                          {val?.subCategories?.length > 0 ? (
                            <Accordion.Item className="accordion-item" eventKey={i}>
                              <Accordion.Header className="accordion-header">{val.description}</Accordion.Header>

                              <Accordion.Body className="accordion-body">
                                <div class="form-check d-flex">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id={`selectAll${i}`}
                                    checked={selectAll[val.description] ? selectAll[val.description] : false}
                                    onClick={() => {
                                      const data = { ...selectAll };
                                      const data1 = [...props.selectedCategory];
                                      let newData;
                                      data[val.description] = selectAll[val.description]
                                        ? !selectAll[val.description]
                                        : true;
                                      if (selectAll[val.description]) {
                                        val?.subCategories?.map((s) => {
                                          newData = data1.filter((item) => item.owner != val.description);
                                        });
                                        props.setSelectedCategory(newData);
                                      }

                                      if (!selectAll[val.description]) {
                                        val?.subCategories?.map((s) => {
                                          if (!data1.includes(s)) {
                                            const ss = { ...s };
                                            ss.owner = val.description;
                                            data1.push(ss);
                                            props.setSelectedCategory(data1);
                                          }
                                        });
                                      }

                                      setSelectAll(data);
                                    }}
                                  />
                                  <label class="form-check-label" for={`selectAll${i}`}>
                                    Select All
                                  </label>
                                </div>
                                {val?.subCategories?.map((s, i) => (
                                  <div class="form-check d-flex">
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      checked={props?.selectedCategory?.some(
                                        (item) => item.category_id === s.category_id,
                                      )}
                                      onChange={() => {
                                        const data = [...props.selectedCategory];
                                        const data1 = { ...selectAll };
                                        if (data.some((item) => item.category_id === s.category_id)) {
                                          const a = data.filter((item) => item.category_id !== s.category_id);
                                          data1[val.description] = false;
                                          setSelectAll(data1);
                                          props.setSelectedCategory(a);
                                        }

                                        if (!data.some((item) => item.category_id === s.category_id)) {
                                          const ss = { ...s };
                                          ss.owner = owner;
                                          data.push(ss);
                                          props.setSelectedCategory(data);
                                        }
                                      }}
                                      id={s.description}
                                    />
                                    <label class="form-check-label" for={s.description}>
                                      {s.description}
                                    </label>
                                  </div>
                                ))}
                              </Accordion.Body>
                            </Accordion.Item>
                          ) : (
                            <div class="form-check d-flex">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                id={val.category_id}
                                checked={props?.selectedCategory?.some((item) => item.category_id === val.category_id)}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    const obj = { ...val };
                                    obj.owner = owner;
                                    const data = [...props.selectedCategory, obj];
                                    props.setSelectedCategory(data);
                                  } else {
                                    let data = [...props.selectedCategory];
                                    const filteredData = data?.filter((obj) => obj.category_id !== val.category_id);
                                    props.setSelectedCategory(filteredData);
                                  }
                                }}
                              />
                              <label class="form-check-label" for={val.category_id}>
                                {val.description}
                              </label>
                            </div>
                          )}
                        </>
                      );
                    })}
              </Accordion>
            </div>
            <div class="dwrc-right d-flex flex-column">
              <h3 class="widget-title text-black">Current Selection</h3>
              <div class="table-responsive mb-30">
                <table class="table table-border-radious table-bordered table-fit-content table-wrap align-middle table-large">
                  <tbody>
                    {props?.categoryLoading ? (
                      [1, 2, 3, 4, 5].map((item) => (
                        <tr>
                          <td>
                            <Skeleton />
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                          <td>
                            <div class="td-delete-icon">
                              <Skeleton height="30px" />
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : props?.selectedCategory?.length > 0 ? (
                      props?.selectedCategory?.map((val, i) => (
                        <tr>
                          <td>{val?.owner}</td>
                          <td>{val?.description}</td>
                          <td>
                            <div
                              class="td-delete-icon"
                              onClick={() => {
                                const data = [...props.selectedCategory];
                                const filteredData = data.filter((obj) => obj.category_id !== val.category_id);
                                props.setSelectedCategory(filteredData);
                              }}
                            >
                              <img src={trash} alt="" />
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td>
                          <div class="pannel-body d-flex align-items-center justify-content-center mt-5 mb-5">
                            <div class="empty-pannel-box">
                              <img src={emptyChat} alt="" />
                              <h4>No Categories Selected!</h4>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div class="widgets-btn text-end mt-auto">
                {!props.categoryLoading && (
                  <NextButton
                    label="Save"
                    classData="btn btn-default btn-lg"
                    handleSubmit={handleAddCategoryPreference}
                    loading={loading}
                  />
                )}
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default CategoryPreferenceModal;
