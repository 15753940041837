import React, { useEffect, useRef, useState } from "react";
import {
  changePrimarySeller,
  getEmailReportLog,
  getPrimarySeller,
  getSellers,
  getweeklyEmailReport,
  primarySellerData,
  updateSendReportEmail,
  updateSendWeeklyReportEmailFrequency,
} from "../../../store/primarySeller";
import { connect } from "react-redux";
import DateFormat from "../../../common/DateFormat/dateFormat";
import { twoDecimals } from "../../../utils.js/conversion";

import greenEmail from "../../../include/images/green-mail.svg";
import notVerified from "../../../include/images/mail-not-verified.svg";
import invalid from "../../../include/images/mail-invalid.svg";
import spamEmail from "../../../include/images/mail-spammed-blocked.svg";
import purpleEmail from "../../../include/images/mail-purple.svg";
import helpCircle from "../../../include/images/help-circle.svg";
import Skeleton from "react-loading-skeleton";
import ImageLargeModal from "../../../layouts/modals/imageGalleryModal";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import riderLogo from "../../../include/images/external-link.svg";
import pdfFile from "../../../include/images/pdf-file-icon.svg";
import csvFile from "../../../include/images/csv-latest.svg";
import XLSX from "../../../include/images/XLSX.svg";
import XLS from "../../../include/images/XLS.svg";
import DOCX from "../../../include/images/Docx.svg";
import DOC from "../../../include/images/Doc.svg";
import icon1 from "../../../include/images/icon___1.svg";
import icon2 from "../../../include/images/icon__2.svg";
import icon3 from "../../../include/images/red-hand.png";
import LoadingBar from "react-top-loading-bar";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import AlertError from "../../../common/alerts/alertError";
import moment from "moment";
import EmailReportLog from "../../../layouts/modals/primarySeller/emailReportLog";
import { getModals, setListingAgreement } from "../../../store/modal";
import SellerAgreementModal from "../../../layouts/modals/primarySeller/sellerAgreementModal";
import { getCountries, getStates } from "../../../store/productLocation";
import FindUser from "../../../common/findUser/findUser";
import { getKeyBuyerCount } from "../../../store/listingProduct";
import { getSellerMotivation } from "../../../store/listings";

const PrimarySeller = (props) => {
  const ref = useRef();
  const data = props?.primarySellerData?.primarySeller?.data;
  const mediaUrl = process.env.REACT_APP_MEDIA_URL;
  const history = useHistory();

  const [imageGalleryModal, setImageGalleryModal] = useState(false);
  const [showNewSeller, setShowNewSeller] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [response, setResponse] = useState(false);
  const [resData, setResData] = useState([]);
  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [seller, setSeller] = useState({});
  const [searchData, setSearchData] = useState({
    name: "",
    company_name: "",
    city: "",
    st_des: "",
    zip: "",
    c_des: "",
    phone: "",
    fax: "",
    cell: "",
    email: "",
    notes: "",
    mType: 0,
  });
  const [startIndex, setStartIndex] = useState("");
  const [imageData, setImageData] = useState([]);
  const [title, setTitle] = useState("");
  const [show, setShow] = useState(false);
  const [showEmailReport, setShowEmailReport] = useState(false);
  const [weekFrequency, setWeekFrequency] = useState({
    name: "Select Week",
    value: "",
  });

  const toggleNewSeller = () => {
    setShowNewSeller(!showNewSeller);
  };

  const handleSearch = () => {
    setLoadingSearch(true);
    props.refStart();
    props.getSellers(props.auctionId, searchData, (res) => {
      if (res.status === 200) {
        setResData(res.data.data);
        props.refStop();
        setLoadingSearch(false);
        setResponse(true);
      } else {
        setLoadingSearch(false);
        props.refStop();
        setResponse(false);
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };
  const handleNewSearch = () => {
    setResponse(false);
    setResData([]);
    setSeller({});
    setSearchData({
      name: "",
      company_name: "",
      city: "",
      st_des: "",
      zip: "",
      c_des: "",
      phone: "",
      fax: "",
      cell: "",
      email: "",
      notes: "",
      mType: 0,
    });
  };
  const handleSelectSeller = () => {
    const payload = {
      setSeller: seller?.id ? seller?.id : "",
    };
    setLoadingSearch(true);
    props?.refStart();
    props.changePrimarySeller(props.auctionId, payload, (res) => {
      if (res.status === 200) {
        const data = res?.data?.data;
        const sellerId = data?.seller_id ? data?.seller_id : 0;
        const memberId = data?.member_id ? data?.member_id : 0;
        const sId = data?.id ? data?.id : 1;
        if (sellerId == 0 && memberId == 0) {
          props?.refStop();
          setLoadingSearch(false);
          props?.setNoSeller(true);
          toggleNewSeller();
        } else {
          props?.getPrimarySeller(props?.auctionId, sellerId, memberId, sId, (res) => {
            if (res.status === 200) {
              props?.setNoSeller(false);
              props?.getKeyBuyerCount(props?.auctionId, (res) => {});
              props?.getSellerMotivation(props?.auctionId, (res) => {});
              props?.refStop();
              setLoadingSearch(false);
              toggleNewSeller();
            } else {
              props?.refStop();
              setLoadingSearch(false);
              toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
            }
          });
        }
      } else {
        props?.refStop();
        setLoadingSearch(false);
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  useEffect(() => {
    if (showNewSeller) {
      props.getCountries((res) => {
        if (res.status === 200) {
          setCountry(res.data.data);
        }
      });
      props.getStates((res) => {
        if (res.status === 200) {
          setState(res.data.data);
        }
      });
      setResponse(false);
      setResData([]);
      setSeller({});
      setSearchData({
        name: "",
        company_name: "",
        city: "",
        st_des: "",
        zip: "",
        c_des: "",
        phone: "",
        fax: "",
        cell: "",
        email: "",
        notes: "",
        mType: 0,
      });
    }
  }, [showNewSeller]);

  useEffect(() => {
    if (data && !weekFrequency?.value) {
      if (data?.weekReportEmailFrequency) {
        const a = weekFrequencyOptions?.filter((ye) => ye?.value == data?.weekReportEmailFrequency)?.[0];
        setWeekFrequency(a);
      }
    }
  }, [data]);

  const handleWeekReportEmail = (value) => {
    const payload = {
      weekReportEmailFrequency: value,
    };
    if (weekFrequency?.value !== "") {
      props?.refStart();
      props?.updateSendWeeklyReportEmailFrequency(data?.user?.s_user_id, payload, (res) => {
        if (res.status === 200) {
          props?.refStop();
        } else {
          props?.refStop();
          toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        }
      });
    }
  };

  const weekFrequencyOptions = [
    { name: "Select Week", value: "" },
    { name: "1 Week", value: 1 },
    { name: "2 Weeks", value: 2 },
    { name: "3 Weeks", value: 3 },
    { name: "4 Weeks", value: 4 },
  ];
  const toggleEmailReport = () => {
    setShowEmailReport(!showEmailReport);
  };

  const toggleSellerAgreementModal = () => {
    props?.setListingAgreement(!props?.listingAgreement);
  };

  const toggleImageGalleryModal = () => {
    setImageGalleryModal(!imageGalleryModal);
  };
  //website function
  const website = (val) => (val && val.includes("http://") ? val : "http://" + val);

  const resubscribeHandler = () => {
    ref?.current?.continuousStart();
    props?.updateSendReportEmail(data?.user?.s_user_id, (res) => {
      if (res && res.status == 200) {
        props?.getPrimarySeller(props?.auctionId, props?.sellerId, props?.memberId, props?.s_id, (res) => {});
        ref?.current?.complete();
        toast(<AlertSuccess message={"Seller subscribed to Asset Recovery Weekly Report"} />);
      } else {
        ref?.current?.complete();
        toast(<AlertError message={res?.data?.message ? res?.data?.message : "Something went wrong"} />);
      }
    });
  };

  const getEmailReportHistory = () => {
    props?.refStart();
    props?.getEmailReportLog(data?.user?.s_user_id, "createdAt", "desc", 1, (res) => {
      if (res.status === 200) {
        props?.refStop();
        toggleEmailReport();
      } else {
        props?.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const emailHistoryReport = props?.primarySellerData?.emailReportLog?.data;

  return (
    <>
      <LoadingBar height={5} color="#47AD1D" ref={ref} />
      <div class="page-widget-head d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center space-between">
          <div class="d-flex align-items-center  space-between">
            <div class="d-flex align-items-center">
              <div class="page-widget-label text-capitalize">Primary Seller</div>
            </div>
            <li className="badge-item cursor-pointer" onClick={() => setShow(!show)} style={{ marginLeft: "10px" }}>
              <div className="badge-box" style={{ background: "#DADEE0" }}>
                {show ? "Hide" : "Show"}
              </div>
            </li>
          </div>
        </div>
        {/* {show && !data?.sendReportEmail && (
          <li className="badge-item cursor-pointer" onClick={() => resubscribeHandler()}>
            <div className="badge-box rsp">Resubscribe</div>
          </li>
        )} */}
      </div>
      <div class="page-widget-body mt-3">
        <div class="seller-tab-list">
          <ul class="nav nav-tabs primary-seller-header">
            <li class="nav-item">
              <a
                class="nav-link text-center"
                style={{ cursor: "pointer" }}
                href="javascript:void(0)"
                onClick={toggleNewSeller}
              >
                {/* <a class="nav-link text-center" style={{ cursor: "pointer" }} href={`/users?filter=true`} target="_blank"> */}
                Find Seller
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link text-center "
                style={{ cursor: "pointer" }}
                aria-current="page"
                href={`/users?create=true`}
                target="_blank"
              >
                Create Seller
              </a>
            </li>
            {!props?.noSeller && (
              <li class="nav-item cursor-pointer">
                <a
                  class="nav-link text-center"
                  style={{ cursor: "pointer" }}
                  href={`https://${
                    process.env.REACT_APP_MODE == "development" ? "admin-dev.salvex" : "admin.salvex"
                  }.com/admin/_user/_edit.cfm?ref_auction_id=${props?.auctionId}&user_id=${data?.user?.s_user_id}`}
                  target="_blank"
                >
                  Edit Info
                </a>
              </li>
            )}
            <Dropdown as="li" className="nav-item cursor-pointer">
              <Dropdown.Toggle as="a" className="nav-link text-center cursor-pointer">
                {" "}
                Send
              </Dropdown.Toggle>

              <Dropdown.Menu bsPrefix="dropdown-menu dropdown-menu-end">
                <Dropdown.Item
                  as={"a"}
                  target="_blank"
                  href={`/views/new-release?id=${props?.auctionId}`}
                  className="cursor-pointer customized-drpdown-btn"
                >
                  Information Release
                </Dropdown.Item>
                <Dropdown.Item
                  className="cursor-pointer customized-drpdown-btn"
                  onClick={() => toggleSellerAgreementModal()}
                >
                  Listing Agreement
                </Dropdown.Item>
                <Dropdown.Item
                  as={"a"}
                  target="_blank"
                  href={`/report/seller-asset-recovery-report?id=${data?.user?.s_user_id}`}
                  className="cursor-pointer customized-drpdown-btn"
                >
                  Asset Recovery Weekly Report
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <li class="nav-item cursor-pointer">
              <a
                class="nav-link text-center "
                href={`https://${
                  process.env.REACT_APP_MODE == "development" ? "admin-dev.salvex" : "admin.salvex"
                }.com/admin/_sellers/_mrk_doc_auc/_create.cfm?aucID=${props?.auctionId}&s_user_id=${
                  data?.user?.s_user_id
                }`}
                style={{ cursor: "pointer" }}
                target="_blank"
              >
                Email
              </a>
            </li>
          </ul>
        </div>
        {show ? (
          props?.noSeller ? (
            <div
              class="page-widget-empty-box text-capitalize d-flex align-items-center justify-content-center text-center mt-3"
              style={{
                height: "100px",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <span>No seller specified.</span>
            </div>
          ) : (
            <>
              <div class="seller-logo-row d-flex flex-wrap align-items-end justify-content-between">
                {!props.loadingMain && (
                  <a href="javascript:void(0)" style={{ cursor: "auto" }} class="green-btn-text fw-medium ms-auto">
                    {data?.user?.business_unit}
                  </a>
                )}
                <div
                  class="seller-logo w-100 cursor-pointer"
                  onClick={() => {
                    if (data?.user?.name) {
                      if (process.env.REACT_APP_MODE == "development") {
                        window.open(
                          `https://admin-dev.salvex.com/admin/_proc/_company/_edit.cfm?cid=${data?.user?.cid}`,
                        );
                      } else {
                        window.open(`https://admin.salvex.com/admin/_proc/_company/_edit.cfm?cid=${data?.user?.cid}`);
                      }
                    }
                  }}
                >
                  <img src={`${mediaUrl}/co/logos/${data?.picture?.proc_logo}`} alt="" />
                </div>
              </div>

              {/* {data?.lastWeeklyReport?.sender?.name ? (
              <span className="mb-10 mt-10 d-block cursor-pointer" onClick={getEmailReportHistory}>
                <span>
                  {" "}
                  Asset Recovery Weekly Report was last sent at{" "}
                  {data?.lastWeeklyReport?.createdAt
                    ? moment(data?.lastWeeklyReport?.createdAt).format("MM-DD-yyyy")
                    : "N/A"}{" "}
                  by {data?.lastWeeklyReport?.sender?.name}. <span className="link-item">Click to view history.</span>
                </span>{" "}
              </span>
            ) : (
              ""
            )} */}

              {/* <div className="d-block">
              {data?.frequencyUpdatedBy ? (
                <span style={{ color: "red", fontWeight: "500", marginTop: "5px" }}>Seller decided the frequency</span>
              ) : (
                ""
              )}
            </div>
            <div className="d-flex align-items-center space-between">
              <div className="w-60">
                <label for="weeklyReportInput">Weekly Report Frequency</label>
              </div>
              <div className="w-40">
                <Select
                  styles={customStyles}
                  placeholder="Select Week"
                  value={weekFrequency}
                  options={weekFrequencyOptions}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.value}
                  onChange={(e) => {
                    handleWeekReportEmail(e?.value);
                    setWeekFrequency(e);
                  }}
                />
              </div>
            </div> */}

              {/* {data?.picture?.proc_logo && (
          <img className="rounded-top" src={`${mediaUrl}/co/logos/${data?.picture?.proc_logo}`} alt="proc_logo" />
        )} */}
              <div class="table-responsive mt-10">
                <table class="table align-middle product-table">
                  <colgroup>
                    <col style={{ width: "36%" }} />
                    <col />
                  </colgroup>
                  <tbody>
                    {props.loadingMain ? (
                      <>
                        <tr>
                          <td>
                            <span class="fw-medium">
                              <Skeleton />
                            </span>
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span class="fw-medium">
                              <Skeleton />
                            </span>
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span class="fw-medium">
                              <Skeleton />
                            </span>
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span class="fw-medium">
                              <Skeleton />
                            </span>
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span class="fw-medium">
                              <Skeleton />
                            </span>
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span class="fw-medium">
                              <Skeleton />
                            </span>
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span class="fw-medium">
                              <Skeleton />
                            </span>
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span class="fw-medium">
                              <Skeleton />
                            </span>
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span class="fw-medium">
                              <Skeleton />
                            </span>
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span class="fw-medium">
                              <Skeleton />
                            </span>
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                        </tr>
                      </>
                    ) : (
                      <>
                        <tr>
                          <td>Name</td>
                          <td>
                            <div className="d-flex align-items-center">
                              <div className="me-2">
                                <span
                                  className="green-btn-text"
                                  onClick={() => {
                                    if (data?.user?.name) {
                                      if (process.env.REACT_APP_MODE == "development") {
                                        window.open(
                                          `https://admin-dev.salvex.com/admin/_user/_edit.cfm?user_id=${data?.user?.s_user_id}`,
                                        );
                                      } else {
                                        window.open(
                                          `https://admin.salvex.com/admin/_user/_edit.cfm?user_id=${data?.user?.s_user_id}`,
                                        );
                                      }
                                    }
                                  }}
                                >
                                  {data?.user?.name}
                                </span>
                                {data?.user?.cid > 0 && <span class="red-text-label">(Enterprise)</span>}
                              </div>
                              <div style={{ display: "inline-flex" }}>
                                {data?.user?.okay && <img class="l-logo-box me-1" src={icon2} alt="" />}
                                {data?.user?.dhs_approved && <img class="l-logo-box me-1" src={icon1} alt="" />}
                                {data?.user?.stop_working && (
                                  <img class="l-logo-box me-1" src={icon3} width="30px" height="30px" alt="" />
                                )}
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Industry</td>
                          <td>{data?.user?.industry_description ? data?.user?.industry_description : "N/A"}</td>
                        </tr>
                        <tr>
                          <td>Title</td>
                          <td>
                            {data?.user?.job_title ? data?.user?.job_title : data?.user?.designation ? "" : "N/A"}
                            {data?.user?.designation && <p class="td-light-size mt-1">{data?.user?.designation}</p>}
                          </td>
                        </tr>
                        <tr>
                          <td>Company</td>
                          <td>
                            {data?.user?.company_name ? (
                              <span
                                className="d-block cursor-pointer"
                                onClick={() => {
                                  if (process.env.REACT_APP_MODE == "development") {
                                    window.open(
                                      `https://admin-dev.salvex.com/admin/_proc/_company/_edit.cfm?cid=${data?.user?.cid}`,
                                    );
                                  } else {
                                    window.open(
                                      `https://admin.salvex.com/admin/_proc/_company/_edit.cfm?cid=${data?.user?.cid}`,
                                    );
                                  }
                                }}
                              >
                                {data?.user?.company_name}
                              </span>
                            ) : (
                              "N/A"
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>
                            {data?.user?.address_line_1} {data?.user?.address_line_2}{" "}
                            {(data?.user?.address_line_1 || data?.user?.address_line_2) && <br />}{" "}
                            {data?.user?.city && data?.user?.city + ","} {data?.user?.st_abb} {data?.user?.zip}{" "}
                            {(data?.user?.city || data?.user?.st_abb || data?.user?.zip) && <br />}
                            {data?.user?.c_abb}
                          </td>
                        </tr>
                        <tr>
                          <td>Phone</td>
                          <td>{data?.user?.phone ? data?.user?.phone : "N/A"}</td>
                        </tr>
                        <tr>
                          <td>Fax</td>
                          <td>{data?.user?.fax ? data?.user?.fax : "N/A"}</td>
                        </tr>
                        <tr>
                          <td>Cell</td>
                          <td>{data?.user?.cell ? data?.user?.cell : "N/A"}</td>
                        </tr>
                        <tr>
                          <td>Email</td>
                          <td>
                            {data?.user?.email ? (
                              <a href={`mailto:${data?.user?.email}`} className="green-btn-text cursor-pointer me-2">
                                {data?.user?.email}
                              </a>
                            ) : (
                              "N/A"
                            )}
                            {data?.user?.emailStatusCode === 0 ? (
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip style={{ position: "fixed" }}>
                                    <span>Email OK</span>
                                  </Tooltip>
                                }
                              >
                                <img src={greenEmail} alt="" />
                              </OverlayTrigger>
                            ) : data?.user?.emailStatusCode === 1 ? (
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip style={{ position: "fixed" }}>
                                    <span>Email Temporarily Suspended/Unreachable or Undetermined (Soft Bounce)</span>
                                  </Tooltip>
                                }
                              >
                                <img src={purpleEmail} alt="" />
                              </OverlayTrigger>
                            ) : data?.user?.emailStatusCode === 2 ? (
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip style={{ position: "fixed" }}>
                                    <span>Email Invalid</span>
                                  </Tooltip>
                                }
                              >
                                <img src={invalid} alt="" />
                              </OverlayTrigger>
                            ) : data?.user?.emailStatusCode === 3 ? (
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip style={{ position: "fixed" }}>
                                    <span>Email Blocked by Spam or Firewall</span>
                                  </Tooltip>
                                }
                              >
                                <img src={spamEmail} alt="" />
                              </OverlayTrigger>
                            ) : (
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip style={{ position: "fixed" }}>
                                    <span>Email Not Verified</span>
                                  </Tooltip>
                                }
                              >
                                <img src={notVerified} alt="" />
                              </OverlayTrigger>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Website</td>
                          <td>
                            <a
                              target="_blank"
                              href={website(data?.user?.web_site)}
                              class="green-btn-text fw-medium cursor-pointer"
                            >
                              Click here
                            </a>
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
              {props?.loadingMain ? (
                <div className="d-grid-btn d-grid">
                  <div className="page-widget-button">
                    <Skeleton width="100%" height="40px" />
                  </div>
                  <div className="page-widget-button">
                    <Skeleton width="100%" height="40px" />
                  </div>
                  <div className="page-widget-button">
                    <Skeleton width="100%" height="40px" />
                  </div>
                </div>
              ) : (
                <div class="d-grid-btn d-grid">
                  <a
                    href="javascript:void(0)"
                    class={`btn border-radius-4 fw-medium mb-10  ${
                      (data?.verification?.verification_status === "yellow" ||
                        !data?.verification?.verification_status) &&
                      "btn-yellow"
                    }`}
                    style={{
                      backgroundColor:
                        data?.verification?.verification_status != "yellow" && data?.verification?.verification_status,
                      color: data?.verification?.verification_status != "yellow" && "white",
                      cursor: "auto",
                    }}
                    type="button"
                  >
                    Customer Verification
                  </a>
                  {data?.verification?.seller_verification_status &&
                  data?.verification?.seller_verification_status_note?.trim()?.split("")?.length ? (
                    <a
                      href="javascript:void(0)"
                      class={`btn border-radius-4 fw-medium mt-0 mb-10 `}
                      style={{ backgroundColor: "#CBC3E3", cursor: "auto" }}
                      type="button"
                    >
                      {data?.verification?.seller_verification_status_note}
                    </a>
                  ) : null}
                  {data?.verification?.verification_status_note?.trim()?.split("")?.length ? (
                    <a
                      href="javascript:void(0)"
                      class={`btn border-radius-4 fw-medium btn-grey mt-0 mb-10 `}
                      type="button"
                      style={{ cursor: "auto" }}
                    >
                      {data?.verification?.verification_status_note}
                    </a>
                  ) : null}
                  <div className={data?.media?.some((obj) => obj.ft_id == 4 || obj.ft_id == 5) ? "d-flex mt-2" : ""}>
                    <div className={data?.media?.some((obj) => obj.ft_id == 4 || obj.ft_id == 5) ? "w-30 me-2" : ""}>
                      <a
                        href="javascript:void(0)"
                        class="btn border-radius-4 fw-medium btn-grey w-100 mb-10 "
                        type="button"
                        style={{ cursor: "auto" }}
                      >
                        Tax ID
                      </a>
                    </div>
                    {data?.media?.map((val) => (
                      <div
                        className={
                          data?.media?.some((obj) => obj.ft_id == 4 || obj.ft_id == 5) ? "w-70 mb-10 " : "mb-10 "
                        }
                        style={{ width: "100%" }}
                      >
                        {val.ft_id == 4 ? (
                          <a
                            href="javascript:void(0)"
                            class="btn border-radius-4 fw-medium btn-grey"
                            style={{ width: "100%", cursor: "auto" }}
                            type="button"
                          >
                            Photo ID
                          </a>
                        ) : val.ft_id == 5 ? (
                          <a
                            href="javascript:void(0)"
                            class="btn border-radius-4 fw-medium btn-grey mb-10 "
                            type="button"
                            style={{ cursor: "auto" }}
                          >
                            Document of Incorporation
                          </a>
                        ) : (
                          ""
                        )}
                      </div>
                    ))}
                  </div>
                  <div className={data?.media?.some((obj) => obj.ft_id == 4 || obj.ft_id == 5) ? "d-flex mt-1" : ""}>
                    <div className={data?.media?.some((obj) => obj.ft_id == 4 || obj.ft_id == 5) ? "w-30 me-2" : ""}>
                      {data?.verification && Object?.keys(data?.verification)?.length > 0 ? (
                        data?.verification?.tax_id_status === "yes" ? (
                          <a
                            href="javascript:void(0)"
                            class="btn border-radius-4 w-100 h-100 fw-medium btn-outline d-flex align-items-center justify-content-center"
                            type="button"
                            style={{ cursor: "auto" }}
                          >
                            Verified
                          </a>
                        ) : (
                          <a
                            href="javascript:void(0)"
                            class="btn border-radius-4 w-100 h-100 fw-medium btn-outline d-flex align-items-center justify-content-center"
                            type="button"
                            style={{ cursor: "auto" }}
                          >
                            Not Verified
                          </a>
                        )
                      ) : (
                        <a
                          href="javascript:void(0)"
                          class="btn border-radius-4 w-100 h-100 fw-medium btn-outline d-flex align-items-center justify-content-center"
                          type="button"
                          style={{ cursor: "auto" }}
                        >
                          Not Verified
                        </a>
                      )}
                    </div>

                    {data?.media?.length > 0 &&
                      data?.media?.map((val, i) => {
                        const filenameArray = val?.filename && val?.filename.split(".");
                        const fileType = filenameArray && filenameArray[filenameArray.length - 1];
                        return (
                          <div
                            className={
                              data?.media?.some((obj) => obj.ft_id == 4 || obj.ft_id == 5)
                                ? "btn border-radius-4 w-70 fw-medium btn-outline d-flex align-items-center justify-content-center"
                                : ""
                            }
                            style={{ width: "100%" }}
                          >
                            {fileType === "csv" ? (
                              <img
                                src={csvFile}
                                className="border-radius-4 object-fit-cover cursor-pointer"
                                style={{
                                  width: "100%",
                                  height: "150px",
                                  objectFit: "contain",
                                }}
                                alt="Photo"
                              />
                            ) : fileType === "xlsx" ? (
                              <img
                                src={XLSX}
                                className="border-radius-4 object-fit-cover cursor-pointer"
                                style={{
                                  width: "100%",
                                  height: "150px",
                                  objectFit: "contain",
                                }}
                                alt="Photo"
                              />
                            ) : fileType === "xls" ? (
                              <img
                                src={XLS}
                                className="border-radius-4 object-fit-cover cursor-pointer"
                                style={{
                                  width: "100%",
                                  height: "150px",
                                  objectFit: "contain",
                                }}
                                alt="Photo"
                              />
                            ) : fileType === "doc" ? (
                              <img
                                src={DOC}
                                className="border-radius-4 object-fit-cover cursor-pointer"
                                style={{
                                  width: "100%",
                                  height: "150px",
                                  objectFit: "contain",
                                }}
                                alt="Photo"
                              />
                            ) : fileType === "docx" ? (
                              <img
                                src={DOCX}
                                className="border-radius-4 object-fit-cover cursor-pointer"
                                style={{
                                  width: "100%",
                                  height: "150px",
                                  objectFit: "contain",
                                }}
                                alt="Photo"
                              />
                            ) : fileType === "pdf" ? (
                              <a
                                href={`/s3/viewDocFile.cfm?folder=${val.folder}&filename=${val.filename}`}
                                target="_blank"
                                // download={true}
                                className="deafult-black-hover-green"
                              >
                                <img
                                  src={pdfFile}
                                  className="border-radius-4 object-fit-cover cursor-pointer"
                                  style={{
                                    width: "100%",
                                    height: "150px",
                                    objectFit: "contain",
                                  }}
                                  alt="Photo"
                                />
                              </a>
                            ) : (
                              <img
                                src={`${mediaUrl}${val.folder}${val.filename}`}
                                className="border-radius-4 object-fit-cover cursor-pointer"
                                style={{
                                  width: "100%",
                                  minHeight: "150px",
                                  maxHeight: "150px",
                                  objectFit: "contain",
                                }}
                                onClick={() => {
                                  setStartIndex(i);
                                  setTitle(data?.user?.name);
                                  const items = [];
                                  items.push({
                                    original:
                                      val.folder.substring(0, 5) === "https"
                                        ? `${val.folder}${val.filename}`
                                        : `${mediaUrl}${val.folder}${val.filename}`,
                                  });
                                  setImageData(items);
                                  toggleImageGalleryModal();
                                }}
                                alt="Photo"
                              />
                            )}
                          </div>
                        );
                      })}
                  </div>
                </div>
              )}

              <div class="table-responsive">
                <table class="table align-middle product-table">
                  <colgroup>
                    <col style={{ width: "36%" }} />
                    <col />
                  </colgroup>
                  <tbody>
                    {props.loadingMain ? (
                      <>
                        <tr>
                          <td>
                            <span class="fw-medium">
                              <Skeleton />
                            </span>
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span class="fw-medium">
                              <Skeleton />
                            </span>
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span class="fw-medium">
                              <Skeleton />
                            </span>
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span class="fw-medium">
                              <Skeleton />
                            </span>
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span class="fw-medium">
                              <Skeleton />
                            </span>
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span class="fw-medium">
                              <Skeleton />
                            </span>
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span class="fw-medium">
                              <Skeleton />
                            </span>
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span class="fw-medium">
                              <Skeleton />
                            </span>
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span class="fw-medium">
                              <Skeleton />
                            </span>
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span class="fw-medium">
                              <Skeleton />
                            </span>
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                        </tr>
                      </>
                    ) : (
                      <>
                        <tr>
                          <td>Status</td>
                          <td>{data?.contract_status ? data?.contract_status : "N/A"}</td>
                        </tr>
                        <tr>
                          <td>Percentage</td>
                          <td>
                            {data?.contract_percentage
                              ? data?.contract_percentage !== undefined && !isNaN(data.contract_percentage)
                                ? data?.contract_percentage?.toFixed(2)
                                : "N/A"
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <td>Expiration Date</td>
                          <td>
                            {data?.contract_end_date
                              ? data?.contract_end_date
                              : // <DateFormat date={data?.contract_end_date} />
                                "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <td># of Logins</td>
                          <td>{data?.user?.login_ct ? data?.user?.login_ct : "0"}</td>
                        </tr>
                        <tr>
                          <td>Last Login</td>
                          <td>{data?.user?.last_login ? <DateFormat date={data?.user?.last_login} /> : ""}</td>
                        </tr>
                        <tr>
                          <td>Account Rep.</td>
                          <td>{data?.user?.account_rep ? data?.user?.account_rep : "N/A"}</td>
                        </tr>
                        <tr>
                          <td>Created By</td>
                          <td>{data?.user?.created_by ? data?.user?.created_by : "N/A"}</td>
                        </tr>
                        <tr>
                          <td>Seller Success</td>
                          <td className="fw-medium">
                            {data?.success_rate ? twoDecimals(data?.success_rate) + "%" : "0.00%"} {data?.rate[0]}/
                            {data?.rate[1]}
                          </td>
                        </tr>
                        <tr>
                          <td>Enterprise Success</td>
                          <td className="fw-medium">
                            {data?.success_rate_ent ? twoDecimals(data?.success_rate_ent) + "%" : "0.00%"}{" "}
                            {data?.rate[2]}/{data?.rate[3]}
                          </td>
                        </tr>
                        <tr>
                          <td>Enterprise Active Deals</td>
                          <td class="fw-medium">{data?.ent_active_deals}</td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </>
          )
        ) : (
          ""
        )}
      </div>
      <FindUser
        title={"Find Seller"}
        show={showNewSeller}
        onHide={toggleNewSeller}
        searchData={searchData}
        setSearchData={setSearchData}
        handleSubmit={handleSearch}
        loading={loadingSearch}
        response={response}
        resData={resData}
        country={country}
        state={state}
        handleNewSearch={handleNewSearch}
        setUser={setSeller}
        handleSelectUser={handleSelectSeller}
      />
      <ImageLargeModal
        show={imageGalleryModal}
        onHide={toggleImageGalleryModal}
        items={imageData}
        startIndex={startIndex}
        title={title}
      />
      <SellerAgreementModal
        show={props?.listingAgreement}
        onHide={toggleSellerAgreementModal}
        auctionId={props.auctionId}
        refStart={props.refStart}
        refStop={props?.refStop}
      />
      <EmailReportLog show={showEmailReport} onHide={toggleEmailReport} data={emailHistoryReport} />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getPrimarySeller: (params, sellerId, memberId, s_id, callback) =>
    dispatch(getPrimarySeller(params, sellerId, memberId, s_id, callback)),
  getweeklyEmailReport: (params, callback) => dispatch(getweeklyEmailReport(params, callback)),
  updateSendReportEmail: (params, callback) => dispatch(updateSendReportEmail(params, callback)),
  getEmailReportLog: (params, sort, order, page, callback) =>
    dispatch(getEmailReportLog(params, sort, order, page, callback)),
  setListingAgreement: (data) => dispatch(setListingAgreement(data)),
  updateSendWeeklyReportEmailFrequency: (id, data, callback) =>
    dispatch(updateSendWeeklyReportEmailFrequency(id, data, callback)),
  getSellers: (id, params, callback) => dispatch(getSellers(id, params, callback)),
  changePrimarySeller: (id, params, callback) => dispatch(changePrimarySeller(id, params, callback)),
  getCountries: (callback) => dispatch(getCountries(callback)),
  getStates: (callback) => dispatch(getStates(callback)),
  getKeyBuyerCount: (id, callback) => dispatch(getKeyBuyerCount(id, callback)),
  getSellerMotivation: (id, callback) => dispatch(getSellerMotivation(id, callback)),
});

const mapStateToProps = (state) => ({
  primarySellerData: primarySellerData(state),
  listingAgreement: getModals(state).listingAgreement,
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(PrimarySeller));
