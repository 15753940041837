import React, { useEffect, useState } from "react";
import close from "../../../include/images/close.svg";
import Select from "react-select";
import { Modal } from "react-bootstrap";
import { customStyles } from "../../../common/customStyles/reactSelectStyle";
import NextButton from "../../../common/form/nextButton";

const UserContactInformationModal = (props) => {
  const [defaultCountry, setDefaultCountry] = useState({
    description: "Please Select a Country",
    country_id: 0,
  });
  const [defaultState, setDefaultState] = useState({
    abb: "Please Select a State",
    state_id: 0,
  });
  const countryOption = [
    {
      description: "Please Select a Country",
      country_id: 0,
    },
    ...props.countryOptions,
  ];

  const stateOption = [
    {
      abb: "Please Select a State",
      state_id: 0,
    },
    ...props?.stateOptions,
  ];

  useEffect(() => {
    props.setContactInformation({
      customer_name: props?.userDetailData?.customer_name ? props?.userDetailData?.customer_name : "",
      customer_city: props?.userDetailData?.customer_city ? props?.userDetailData?.customer_city : "",
      customer_email: props?.userDetailData?.customer_email ? props?.userDetailData?.customer_email : "",
      customer_phone: props?.userDetailData?.customer_phone ? props?.userDetailData?.customer_phone : "",
      customer_cell: props?.userDetailData?.customer_cell ? props?.userDetailData?.customer_cell : "",
      linkedin_url: props?.userDetailData?.linkedin_url ? props?.userDetailData?.linkedin_url : "",
      state_id: props?.userDetailData?.state_id ? props?.userDetailData?.state_id : 0,
      country_id: props?.userDetailData?.country_id ? props?.userDetailData?.country_id : 0,
    });

    if (props?.contactInformation?.state_id) {
      setDefaultState(stateOption?.find((option) => option.state_id === props?.contactInformation?.state_id));
    } else {
      setDefaultState({ abb: "Please Select a State", state_id: 0 });
    }
    if (props?.contactInformation?.country_id) {
      setDefaultCountry(countryOption?.find((option) => option.country_id === props?.contactInformation?.country_id));
    } else {
      setDefaultCountry({
        description: "Please Select a Country",
        country_id: 0,
      });
    }
  }, [props.show, props.stateOptions, props.countryOptions]);
  return (
    <Modal
      className="modal fade custom-modal"
      id="userContactInformation"
      show={props.show}
      onHide={props.onHide}
      dialogClassName="modal-dialog modal-dialog-centered modal-lg"
    >
      <div class="modal-content h-100 d-flex flex-column">
        <div class="custom-modal-header d-flex align-items-center justify-content-between">
          <h5 class="mb-0 text-capitalize">User Contact Information</h5>
          <button
            type="button"
            class="close-btn d-flex align-items-center justify-content-center"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={props.onHide}
          >
            <img src={close} alt="" />
          </button>
        </div>
        <Modal.Body class="custom-modal-body flex-grow-1 hide-scrollbar">
          <table class="table modal-product-detail-table mb-0">
            <colgroup>
              <col style={{ width: "30%" }} />
              <col style={{ width: "70%" }} />
            </colgroup>
            <tr>
              <td>Seller Name</td>
              <td>
                <div>
                  <input
                    type="text"
                    value={props?.contactInformation?.customer_name}
                    class="form-control"
                    onChange={(e) => {
                      const data = { ...props.contactInformation };
                      data.customer_name = e.target.value;
                      props.setContactInformation(data);
                    }}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>LinkedIn Profile</td>
              <td>
                <div>
                  <input
                    type="text"
                    value={props?.contactInformation?.linkedin_url}
                    class="form-control"
                    onChange={(e) => {
                      const data = { ...props.contactInformation };
                      data.linkedin_url = e.target.value;
                      props.setContactInformation(data);
                    }}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>Country</td>
              <td>
                <div>
                  <Select
                    className="basic-single"
                    value={defaultCountry}
                    classNamePrefix="select-search "
                    placeholder="Please Select a Country"
                    menuPosition="fixed"
                    menuShouldScrollIntoView
                    options={countryOption}
                    getOptionLabel={(option) => option.description}
                    getOptionValue={(option) => option.country_id}
                    onChange={(e) => {
                      const data = { ...props.contactInformation };
                      data.country_id = e.country_id;
                      props.setContactInformation(data);
                      setDefaultCountry(e);
                      setDefaultState({ abb: "Please Select a State", state_id: 0 });
                    }}
                    styles={customStyles}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>State</td>
              {defaultCountry?.country_id === 1001 ? (
                <td>
                  <div>
                    <Select
                      className="basic-single"
                      value={defaultState}
                      classNamePrefix="select-search "
                      placeholder="Please Select a State"
                      menuPosition="fixed"
                      menuShouldScrollIntoView
                      options={stateOption.filter((option) => option.state_id !== 1001)}
                      getOptionLabel={(option) => option.abb}
                      getOptionValue={(option) => option.state_id}
                      onChange={(e) => {
                        const data = { ...props.contactInformation };
                        data.state_id = e.state_id;
                        props.setContactInformation(data);
                        setDefaultState(e);
                      }}
                      styles={customStyles}
                    />
                  </div>
                </td>
              ) : (
                <td>
                  <div>
                    <Select
                      className="basic-single"
                      value={{
                        state_id: 1001,
                        abb: "IT",
                        description: "International",
                        country_id: 0,
                        region_id: 1001,
                        geo_lat: null,
                        geo_long: null,
                        traders: null,
                      }}
                      classNamePrefix="select-search "
                      menuPosition="fixed"
                      menuShouldScrollIntoView
                      placeholder="Please Select a State"
                      options={[
                        {
                          state_id: 1001,
                          abb: "IT",
                          description: "International",
                          country_id: 0,
                          region_id: 1001,
                          geo_lat: null,
                          geo_long: null,
                          traders: null,
                        },
                      ]}
                      getOptionLabel={(option) => option.abb}
                      getOptionValue={(option) => option.state_id}
                      onChange={(e) => {
                        const data = { ...props.contactInformation };
                        data.state_id = e.state_id;
                        props.setContactInformation(data);
                        setDefaultState(e);
                      }}
                      styles={customStyles}
                    />
                  </div>
                </td>
              )}
            </tr>
            <tr>
              <td>Seller Phone</td>
              <td>
                <div>
                  <input
                    type="text"
                    value={props?.contactInformation?.customer_phone}
                    class="form-control"
                    onChange={(e) => {
                      const data = { ...props.contactInformation };
                      data.customer_phone = e.target.value;
                      props.setContactInformation(data);
                    }}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>Seller Cell</td>
              <td>
                <div>
                  <input
                    type="text"
                    value={props?.contactInformation?.customer_cell}
                    class="form-control"
                    onChange={(e) => {
                      const data = { ...props.contactInformation };
                      data.customer_cell = e.target.value;
                      props.setContactInformation(data);
                    }}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>Seller Email</td>
              <td>
                <div>
                  <input
                    type="text"
                    value={props?.contactInformation?.customer_email}
                    class="form-control"
                    onChange={(e) => {
                      const data = { ...props.contactInformation };
                      data.customer_email = e.target.value;
                      props.setContactInformation(data);
                    }}
                  />
                </div>
              </td>
            </tr>
          </table>
        </Modal.Body>
        <div class="custom-modal-dividor"></div>
        <div class="custom-modal-footer d-grid gap-2 d-md-flex align-items-center justify-content-md-end">
          <button
            class="btn btn-link me-md-2"
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={props.onHide}
          >
            Cancel
          </button>
          <NextButton
            label="Save User"
            classData="btn btn-default"
            handleSubmit={() => props.quickEditHandle(props?.userDetailData?.user_id)}
            loading={props.loading}
          />
        </div>
      </div>
    </Modal>
  );
};

export default UserContactInformationModal;
