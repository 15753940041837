import React, { useEffect, useState } from "react";
import greenEmail from "../../../include/images/green-mail.svg";
import notVerified from "../../../include/images/mail-not-verified.svg";
import close from "../../../include/images/close-icon.svg";
import invalid from "../../../include/images/mail-invalid.svg";
import spamEmail from "../../../include/images/mail-spammed-blocked.svg";
import purpleEmail from "../../../include/images/mail-purple.svg";
import { connect } from "react-redux";
import Select from "react-select";
import { assignTrader, dashboardData, getBuyerCategoryData, getCustomers } from "../../../store/dashBoard";
import { toast } from "react-toastify";
import AlertError from "../../../common/alerts/alertError";
import MoreModal from "../../../layouts/modals/pushEmailClickers/moreModal";
import UserContactInformationModal from "../../../layouts/modals/pushEmailClickers/userContactInformationModal";
import {
  addMessage,
  changeUnsubscribe,
  deleteMessage,
  emailClickersDetail,
  getRecipient,
  getUserDetail,
} from "../../../store/emailClickers";
import BuyerInterestModal from "../../../layouts/modals/dashboard/customers/buyerInterestModal";
import DateFormat from "../../../common/DateFormat/dateFormat";
import { customStyles } from "../../../common/customStyles/reactSelectStyle";
import BuyerCategoryDataModal from "../../../layouts/modals/dashboard/customers/buyerCategoryDataModal";

const Customers = (props) => {
  const detailData = props.data;
  const userDetailData = props?.emailClickersDetail?.user?.data;
  const categoryData = props?.dashboardData?.buyerCategoryData?.data;
  const { type, empId } = props;
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState("");
  const [action, setAction] = useState(false);
  const [showMoreModal, setShowMoreModal] = useState(false);
  const [recipientList, setRecipientList] = useState([]);
  const [showContactModal, setShowContactModal] = useState(false);
  const [showBuyerInterest, setShowBuyerInterest] = useState(false);
  const [userId, setUserId] = useState("");
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [quickEditLoading, setQuickEditLoading] = useState(false);
  const [showCategoryData, setShowCategoryData] = useState(false);
  const [contactInformation, setContactInformation] = useState({
    customer_name: "",
    customer_city: "",
    customer_email: "",
    customer_phone: "",
    customer_cell: "",
    linkedin_url: "",
    state_id: 0,
    country_id: 0,
  });
  const [showUpdate, setShowUpdate] = useState(false);
  const [updateUserId, setUpdateUserId] = useState(false);
  const [selected, setSelected] = useState();
  const [refresh, setRefresh] = useState(false);

  const toggleShowUpdate = (val) => {
    setUpdateUserId(val.user_id);
    setSelected(props.empList.find((s) => s.name01 === val?.customer?.managed_by));
    setShowUpdate(!showUpdate);
  };

  const toggleCategoryData = () => {
    setShowCategoryData(!showCategoryData);
  };

  const handleGetCategoryData = (id) => {
    props?.refStart();
    props?.getBuyerCategoryData(id, (res) => {
      if (res.status === 200) {
        props?.refStop();
        toggleCategoryData();
      } else {
        props?.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const handleChange = (e) => {
    props.refStart();
    const payload = {
      action: "edit_managed_by_process",
      field: "managed_by",
      user_id: updateUserId,
      managed_by: e.user_id,
    };
    props.assignTrader(payload, (res) => {
      if (res.status === 200) {
        setAction(true);
        props.refStop();
        setRefresh(!refresh);
      } else {
        props.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const toggleMoreModal = () => {
    setShowMoreModal(!showMoreModal);
  };

  const toggelBuyerInterest = () => {
    setShowBuyerInterest(!showBuyerInterest);
  };

  //Toggle User Contact Information Modal
  const toggleContactInformationModal = () => {
    setShowMoreModal(!showMoreModal);
    setShowContactModal(!showContactModal);
  };

  const fetchUserDetail = (userId) => {
    props.refStart();
    props.getUserDetail(userId, (res) => {
      if (res.status === 200) {
        // setUserDetailData(res.data.data);
        props.getRecipient((res) => {
          if (res.status === 200) {
            setRecipientList(res.data.data);
            props.refStop();
            toggleMoreModal();
          } else {
            toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
            props.refStop();
            toggleMoreModal();
          }
        });
      } else {
        toast(<AlertError message={res.data.message ? res.data.messge : "Something Went Wrong"} />);
      }
    });
  };

  //Quick Edit
  const quickEditHandle = (userId) => {
    props.refStart();
    setQuickEditLoading(true);
    props.quickEdit(userId, contactInformation, (res) => {
      if (res.status === 200) {
        props.getUserDetail(userId, (res) => {
          if (res.status === 200) {
            props.refStop();
            setQuickEditLoading(false);
            toggleContactInformationModal();
          } else {
            props.refStop();
            setQuickEditLoading(false);
            toggleContactInformationModal();
          }
        });
      } else {
        props.refStop();
        setQuickEditLoading(false);
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  //Pagination
  useEffect(() => {
    const pagination = () => {
      props.refStart();
      const payload = {
        aType: type,
        employeeId: empId,
        page: page,
        filterInterest: filter,
      };
      props?.getCustomers(payload, (res) => {
        if (res.status === 200) {
          setShowUpdate(false);
          props.refStop();
        } else {
          props.refStop();
          toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        }
      });
    };
    if (action) {
      pagination();
    }
  }, [page, refresh]);
  return (
    <div class="details-page-widget-block">
      <div class="page-widget-head d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <div class="page-widget-label text-capitalize cursor-pointer">{props.title}</div>
        </div>
      </div>
      <div class="page-widget-body mt-3">
        {type != "active_sellers" && (
          <div class="table-responsive mb-2">
            <table class="large-table align-left">
              <thead>
                <tr>
                  <th>Buyer Account Follow Ups</th>
                  <th>Pending Buyer Interest</th>
                  <th>Information Requirements</th>
                  <th>Active Buyer Interest</th>
                  <th>Archived Buyer Interest</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td></td>
                  <td>
                    <ul className="mb-3">
                      <li className="mt-2 pt-2">
                        <div class="form-check tick-checkbox">
                          <input class="form-check-input" type="checkbox" value="" id="checkbox_4" />
                          <label class="form-check-label fw-medium" for="checkbox_4">
                            Bid Status Don't Know
                          </label>
                        </div>
                      </li>
                      <li className="mt-2 pt-2">
                        <div class="form-check tick-checkbox">
                          <input class="form-check-input" type="checkbox" value="" id="checkbox_5" />
                          <label class="form-check-label fw-medium" for="checkbox_5">
                            Buyer Budget Don't Know
                          </label>
                        </div>
                      </li>
                    </ul>
                  </td>
                  <td>
                    <ul className="mb-3">
                      <li className="mt-2 pt-2">
                        <div class="form-check tick-checkbox">
                          <input class="form-check-input" type="checkbox" value="" id="checkbox_9" />
                          <label class="form-check-label fw-medium" for="checkbox_9">
                            Getting Info From Seller
                          </label>
                        </div>
                      </li>
                      <li className="mt-2 pt-2">
                        <div class="form-check tick-checkbox">
                          <input class="form-check-input" type="checkbox" value="" id="1" />
                          <label class="form-check-label fw-medium" for="1">
                            Product Info Requirements
                          </label>
                        </div>
                      </li>
                      <li className="mt-2 pt-2">
                        <div class="form-check tick-checkbox">
                          <input class="form-check-input" type="checkbox" value="" id="23" />
                          <label class="form-check-label fw-medium" for="23">
                            Inspection Requirements
                          </label>
                        </div>
                      </li>
                      <li className="mt-2 pt-2">
                        <div class="form-check tick-checkbox">
                          <input class="form-check-input" type="checkbox" value="" id="3" />
                          <label class="form-check-label fw-medium" for="3">
                            Logistics Requirements
                          </label>
                        </div>
                      </li>
                    </ul>
                  </td>
                  <td>
                    <ul className="mb-3">
                      <li className="mt-2 pt-2">
                        <div class="form-check tick-checkbox">
                          <input class="form-check-input" type="checkbox" value="" id="checkbox_13" />
                          <label class="form-check-label fw-medium" for="checkbox_13">
                            Buyer Can Increase Bid
                          </label>
                        </div>
                      </li>
                      <li className="mt-2 pt-2">
                        <div class="form-check tick-checkbox">
                          <input class="form-check-input" type="checkbox" value="" id="checkbox_17" />
                          <label class="form-check-label fw-medium" for="checkbox_17">
                            Max Bid Received
                          </label>
                        </div>
                      </li>
                      <li className="mt-2 pt-2">
                        <div class="form-check tick-checkbox">
                          <input class="form-check-input" type="checkbox" value="" id="checkbox_16" />
                          <label class="form-check-label fw-medium" for="checkbox_16">
                            Max Bid Don't Know
                          </label>
                        </div>
                      </li>
                      <li className="mt-2 pt-2">
                        <div class="form-check tick-checkbox">
                          <input class="form-check-input" type="checkbox" value="" id="checkbox_14" />
                          <label class="form-check-label fw-medium" for="checkbox_14">
                            Buyer Will Bid
                          </label>
                        </div>
                      </li>
                      <li className="mt-2 pt-2">
                        <div class="form-check tick-checkbox">
                          <input class="form-check-input" type="checkbox" value="" id="checkbox_15" />
                          <label class="form-check-label fw-medium" for="checkbox_15">
                            Buyer Budget Given{" "}
                          </label>
                        </div>
                      </li>
                    </ul>
                  </td>
                  <td>
                    <ul className="mb-3">
                      <li className="mt-2 pt-2">
                        <div class="form-check tick-checkbox">
                          <input class="form-check-input" type="checkbox" value="" id="checkbox_18" />
                          <label class="form-check-label fw-medium" for="checkbox_18">
                            Not Interested
                          </label>
                        </div>
                      </li>
                      <li className="mt-2 pt-2">
                        <div class="form-check tick-checkbox">
                          <input class="form-check-input" type="checkbox" value="" id="checkbox_19" />
                          <label class="form-check-label fw-medium" for="checkbox_19">
                            Archived Listings
                          </label>
                        </div>
                      </li>
                      <li className="mt-2 pt-2">
                        <div class="form-check tick-checkbox">
                          <input class="form-check-input" type="checkbox" value="" id="checkbox_20" />
                          <label class="form-check-label fw-medium" for="checkbox_20">
                            No Response 3rd Attempt
                          </label>
                        </div>
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
        <div class="table-responsive">
          <table class="large-table align-left">
            <thead>
              <tr>
                <th>Customer</th>
                {type != "active_sellers" && <th>Unsubscribed</th>}
                <th></th>
                <th>Company</th>
                <th>Location</th>
                <th>Assigned To</th>
                {type != "active_sellers" && <th>Main Category</th>}
                {type != "active_sellers" && <th></th>}
                <th>Feedback</th>
                <th>Email</th>
                <th>Phone</th>
                {type != "active_sellers" && <th>Contract Deals</th>}
                {type != "active_sellers" && <th>Big Deals</th>}
                {type != "active_sellers" && <th>Max Bid</th>}
                {type != "active_sellers" && <th>Cumulative Bid</th>}
                {type != "active_sellers" && <th>Last Bid Date</th>}
                {type != "active_sellers" && <th>Last Activity</th>}
                <th>Profit</th>
              </tr>
            </thead>
            <tbody>
              {detailData?.data &&
                detailData?.data?.length > 0 &&
                detailData?.data?.map((val) => (
                  <>
                    <tr>
                      <td>
                        <span
                          className="green-btn-text cursor-pointer"
                          onClick={() => {
                            if (process.env.REACT_APP_MODE == "development") {
                              window.open(
                                `https://admin-dev.salvex.com/admin/_user/_edit.cfm?user_id=${val?.customer?.user_id}`,
                              );
                            } else {
                              window.open(
                                `https://admin.salvex.com/admin/_user/_edit.cfm?user_id=${val?.customer?.user_id}`,
                              );
                            }
                          }}
                        >
                          {val?.customer?.customer_name}
                        </span>
                        {val?.customer?.credit && val?.customer?.credit > 0 && (
                          <div>
                            <span className="text-danger">
                              (
                              {"$" +
                                new Intl.NumberFormat("en-US", {
                                  style: "decimal",
                                  minimumFractionDigits: 2,
                                }).format(val?.customer?.credit)}
                              )
                            </span>
                          </div>
                        )}
                      </td>
                      {type != "active_sellers" && <td>{val?.customer?.unsubscribe ? "YES" : "NO"}</td>}
                      <td>
                        {type === "active_sellers" ? (
                          <button
                            className="btn btn-primary btn-xs-size btn-auto"
                            onClick={() => {
                              fetchUserDetail(val?.user_id);
                            }}
                          >
                            More
                          </button>
                        ) : (
                          <div className="d-flex flex-column justify-content-center align-items-center">
                            <div>
                              <button
                                className="btn btn-primary btn-xs-size btn-auto"
                                onClick={() => {
                                  fetchUserDetail(val?.user_id);
                                }}
                              >
                                More Customer Info
                              </button>
                            </div>
                            <div className="mt-1">
                              <button
                                className="btn btn-primary btn-xs-size btn-auto"
                                onClick={() => {
                                  setUserId(val?.user_id);
                                  toggelBuyerInterest();
                                }}
                              >
                                Buyer Interest
                              </button>
                            </div>
                          </div>
                        )}
                      </td>
                      <td>
                        <span
                          className="cursor-pointer"
                          onClick={() => {
                            if (process.env.REACT_APP_MODE == "development") {
                              window.open(
                                `https://admin-dev.salvex.com/admin/_user/_edit.cfm?user_id=${val?.customer?.user_id}`,
                              );
                            } else {
                              window.open(
                                `https://admin.salvex.com/admin/_user/_edit.cfm?user_id=${val?.customer?.user_id}`,
                              );
                            }
                          }}
                        >
                          {val?.customer?.customer_company}
                        </span>
                      </td>
                      <td>
                        {val?.customer?.customer_city}
                        {val.customer?.customer_state
                          ? val?.customer?.customer_state === "International"
                            ? val?.customer?.customer_country && val?.customer?.customer_city
                              ? `,${val?.customer?.customer_country}`
                              : val?.customer?.customer_country
                            : `, ${val?.customer?.customer_state}`
                          : val?.customer?.customer_country && ` ${val?.customer?.customer_country}`}
                      </td>
                      <td>
                        {showUpdate && val?.user_id === updateUserId ? (
                          <div className="d-flex">
                            <div style={{ width: "200px" }}>
                              <Select
                                menuPosition="fixed"
                                menuShouldScrollIntoView
                                styles={customStyles}
                                placeholder="Select Trader"
                                value={selected}
                                options={[{ name01: "Select Trader", member_id: 0 }, ...props.empList]}
                                getOptionLabel={(option) => option.name01}
                                getOptionValue={(option) => option.member_id}
                                onChange={(e) => {
                                  setSelected(e);
                                  handleChange(e);
                                }}
                              />
                            </div>
                            <button className="ms-2" onClick={() => setShowUpdate(false)}>
                              <img src={close} alt="close" />
                            </button>
                          </div>
                        ) : (
                          <div className="cursor-pointer" onDoubleClick={() => toggleShowUpdate(val)}>
                            {val?.customer?.managed_by ? val?.customer?.managed_by : "N/A"}
                          </div>
                        )}
                      </td>
                      {type != "active_sellers" && <td>N/A</td>}
                      {type != "active_sellers" && (
                        <td>
                          {" "}
                          <button
                            className="btn btn-primary btn-xs-size btn-auto"
                            onClick={() => handleGetCategoryData(val?.user_id)}
                          >
                            Buyer Category Data
                          </button>
                        </td>
                      )}

                      <td>
                        Feedback:{" "}
                        <span className="text-green">
                          {val?.customer?.buyerFeedback?.total
                            ? Math.round(
                                (val?.customer?.buyerFeedback?.good / val?.customer?.buyerFeedback?.total) * 100,
                              ) + "%"
                            : "0%"}
                        </span>{" "}
                        {val?.customer?.buyerFeedback?.total
                          ? Math.round(
                              (val?.customer?.buyerFeedback?.neutral / val?.customer?.buyerFeedback?.total) * 100,
                            ) + "%"
                          : "0%"}{" "}
                        <span className="text-danger">
                          {val?.customer?.buyerFeedback?.total
                            ? Math.round(
                                (val?.customer?.buyerFeedback?.bad / val?.customer?.buyerFeedback?.total) * 100,
                              ) + "%"
                            : "0%"}
                        </span>
                      </td>
                      <td>
                        <div className="d-flex">
                          <a className="green-btn-text" href={`mailto:${val?.customer?.customer_email}`}>
                            {val?.customer?.customer_email}
                          </a>
                          <div className="ms-2">
                            {val?.customer?.emailstatuscode === 0 ? (
                              <img src={greenEmail} alt="" title="Email OK" />
                            ) : val?.customer?.emailstatuscode === 1 ? (
                              <img
                                src={purpleEmail}
                                alt=""
                                title="Email Temporarily Suspended/Unreachable or Undetermined (Soft Bounce)"
                              />
                            ) : val?.customer?.emailstatuscode === 2 ? (
                              <img src={invalid} alt="" title="Email Invalid" />
                            ) : val?.customer?.emailstatuscode === 3 ? (
                              <img src={spamEmail} alt="" title="Email Blocked by Spam or Firewall" />
                            ) : (
                              <img src={notVerified} alt="" title="Email Not Verified" />
                            )}
                          </div>
                        </div>
                        <div class="td-button-group d-flex">
                          <img
                            src={`https://us-central1-salvex-mail.cloudfunctions.net/requestRecipientStats?email=${val?.customer?.customer_email}`}
                            alt=""
                          />
                        </div>
                      </td>
                      <td>{val?.customer?.customer_phone}</td>
                      {type != "active_sellers" && <td>N/A</td>}
                      {type != "active_sellers" && <td>N/A</td>}
                      {type != "active_sellers" && <td>N/A</td>}
                      {type != "active_sellers" && <td>N/A</td>}
                      {type != "active_sellers" && (
                        <td>
                          {val?.customer?.lastBid?.date ? <DateFormat date={val?.customer?.lastBid?.date} /> : "N/A"}
                        </td>
                      )}
                      {type != "active_sellers" && <td>N/A</td>}
                      <td>
                        {val.customer?.profitBuying > 0
                          ? "$" +
                            new Intl.NumberFormat("en-US", {
                              style: "decimal",
                              minimumFractionDigits: 2,
                            }).format(val?.customer?.profitBuying)
                          : val?.customer?.profitSelling > 0
                          ? "$" +
                            new Intl.NumberFormat("en-US", {
                              style: "decimal",
                              minimumFractionDigits: 2,
                            }).format(val?.customer?.profitSelling)
                          : "$0.00"}
                      </td>
                    </tr>
                    {showBuyerInterest && val.user_id === userId && <BuyerInterestModal />}
                  </>
                ))}
            </tbody>
          </table>
        </div>
        {detailData?.data?.length > 0 && (
          <div className="d-flex justify-content-between mt-3">
            <div className="justify-content-start">
              Page {detailData?.page} of {detailData?.max_pages} ({detailData?.total_records} records)
            </div>
            <div class="table-pagination">
              <ul class="pagination">
                <li class="page-item">
                  <a
                    class={`page-link ${page === 1 ? "disabled" : "cursor-pointer"}`}
                    aria-label="Previous"
                    onClick={() => {
                      if (page !== 1) {
                        setAction(true);
                        setPage(page - 1);
                      }
                    }}
                  >
                    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7 13L1 7L7 1" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </a>
                </li>
                <li class="page-item active">
                  <a class="page-link" href="javascript:void(0)">
                    {page}
                  </a>
                </li>
                <li class="page-item">
                  {page < detailData?.max_pages && (
                    <a
                      class="page-link"
                      onClick={() => {
                        setAction(true);
                        setPage(page + 1);
                      }}
                    >
                      {page + 1}
                    </a>
                  )}
                </li>
                <li class="page-item">
                  {page < detailData?.max_pages && (
                    <a
                      class="page-link"
                      href="javascript:void(0)"
                      aria-label="Next"
                      onClick={() => {
                        setAction(true);
                        setPage(page + 1);
                      }}
                    >
                      <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 13L7 7L1 1" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </a>
                  )}
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
      <MoreModal
        show={showMoreModal}
        onHide={toggleMoreModal}
        userDetailData={userDetailData}
        recipientList={recipientList}
        refStart={props.refStart}
        refStop={props.refStop}
        addMessage={props.addMessage}
        deleteMessage={props.deleteMessage}
        getUserDetail={props.getUserDetail}
        changeUnsubscribe={props.changeUnsubscribe}
        auctionId={props.auctionId}
        toggleContactInformationModal={toggleContactInformationModal}
      />
      <UserContactInformationModal
        show={showContactModal}
        onHide={toggleContactInformationModal}
        userDetailData={userDetailData}
        contactInformation={contactInformation}
        setContactInformation={setContactInformation}
        stateOptions={stateOptions}
        countryOptions={countryOptions}
        loading={quickEditLoading}
        quickEditHandle={quickEditHandle}
      />
      <BuyerCategoryDataModal show={showCategoryData} onHide={toggleCategoryData} data={categoryData} />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getCustomers: (params, callback) => dispatch(getCustomers(params, callback)),
  getUserDetail: (userId, callback) => dispatch(getUserDetail(userId, callback)),
  getRecipient: (callback) => dispatch(getRecipient(callback)),
  addMessage: (params, data, callback) => dispatch(addMessage(params, data, callback)),
  deleteMessage: (params, callback) => dispatch(deleteMessage(params, callback)),
  changeUnsubscribe: (params, data, callback) => dispatch(changeUnsubscribe(params, data, callback)),
  assignTrader: (data, callback) => dispatch(assignTrader(data, callback)),
  getBuyerCategoryData: (id, callback) => dispatch(getBuyerCategoryData(id, callback)),
});
const mapStateToProps = (state) => ({
  dashboardData: dashboardData(state),
  emailClickersDetail: emailClickersDetail(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Customers));
