import React from "react";
import { Offcanvas } from "react-bootstrap";
const ReminderPartsFilter = ({ state, dispatch, ...props }) => {
  return (
    <Offcanvas
      show={state.showFilter}
      onHide={() => dispatch({ type: "TOGGLE_FILTER", payload: false })}
      placement="end"
      className="offcanvas offcanvas-end custom-offcanvas-sm "
      backdropClassName="upload-images-backdrop"
      tabindex="-1"
      id="userFilter"
      enforceFocus={false}
    >
      <Offcanvas.Header className="offcanvas-header aviation-filter-fix">
        <h5 class="offcanvas-title">Filters</h5>
        <button
          type="button"
          className="btn-close text-reset"
          onClick={() => dispatch({ type: "TOGGLE_FILTER", payload: false })}
        ></button>
      </Offcanvas.Header>
      <Offcanvas.Body className="offcanvas-body co-body">
        <div class="form-group">
          <label for="keyword" class="form-label">
            Keyword
          </label>
          <input
            type="text"
            class="form-control"
            id="auction-search"
            value={state.filteredValues?.keyword}
            onChange={(e) => dispatch({ type: "SET_KEYWORD", payload: e.target.value })}
          />
        </div>
      </Offcanvas.Body>
      <div class="offcanvas-footer">
        <a
          href="javascript:void(0)"
          class={`btn btn-default btn-block ${props.loadingFilter && "disabled"}`}
          onClick={() => props.handleFilter()}
        >
          Apply Filters
        </a>
      </div>
    </Offcanvas>
  );
};

export default React.memo(ReminderPartsFilter);
