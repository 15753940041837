import React, { useEffect, useState } from "react";
import close from "../../../include/images/close.svg";
import { Modal } from "react-bootstrap";
import NextButton from "../../../common/form/nextButton";
import DatePicker from "react-datepicker";
import parse from "html-react-parser";
import moment from "moment";
import { ConvertTime } from "../../../common/DateFormat/cstToLocalTime";
import { getProfile } from "../../../utils.js/localStorageServices";

const ReminderDate = (props) => {
  const [error, setError] = useState("");
  const profile = getProfile();

  useEffect(() => {
    if (props?.selectedMessage?.originalDate) {
      const selectedMoment = moment(props?.selectedMessage?.originalDate, "YYYY-MM-DD");
      const today = moment().startOf("day");
      const isAlreadyAdded =
        props?.selectedMessage?.future_reminders?.filter(
          (qw) =>
            qw?.email === profile?.email &&
            moment(ConvertTime(qw?.reminder_on_utc))?.format("MM-DD-YYYY") === props?.selectedMessage?.reminderDate,
        )?.length > 0
          ? true
          : false;

      if (!selectedMoment.isSameOrAfter(today)) {
        setError("Please select a date that is in the future");
      } else if (isAlreadyAdded) {
        setError("Reminder has already been added for the selected date and message");
      } else {
        setError("");
      }
    }
  }, [props?.selectedMessage?.originalDate]);

  return (
    <Modal
      className="modal fade custom-modal "
      id="userContactInformation"
      show={props.show}
      onHide={props.onHide}
      dialogClassName="modal-dialog modal-dialog-centered modal-lg "
      backdropClassName="reminder-date-z-index"
    >
      <div class="modal-content h-100 d-flex flex-column">
        <div class="custom-modal-header d-flex align-items-center justify-content-between">
          <h5 class="mb-0 text-capitalize">Set Reminder</h5>
          <button
            type="button"
            class="close-btn d-flex align-items-center justify-content-center"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={props.onHide}
          >
            <img src={close} alt="" />
          </button>
        </div>
        <Modal.Body class="custom-modal-body flex-grow-1 hide-scrollbar">
          <div className="mb-0 d-flex flex-column align-items-start justify-content-start">
            <div className="mb-2  reminder-date ">
              <label class="form-label d-block" for="industry">
                Date
              </label>
              <DatePicker
                className="form-control filter-user-input-width "
                selected={props?.selectedMessage?.originalDate ? props?.selectedMessage?.originalDate : ""}
                onChange={(date) => {
                  const value = { ...props.selectedMessage };
                  value.reminderDate = moment(date)?.format("MM-DD-YYYY");
                  value.originalDate = date;
                  props?.setSelectedMessage(value);
                }}
              />
              {error?.length > 0 ? <p style={{ color: "red" }}>{error}</p> : ""}
            </div>
            <div className="  reminder-date ">
              <label class="form-label d-block fw-medium" for="industry">
                Message :
              </label>
              <p>{parse(props?.selectedMessage?.message)}</p>
            </div>

            <div className="mb-2  reminder-date ">
              <label class="form-label d-block fw-medium" for="industry">
                Listing :
              </label>
              <p>{props?.auctionDetail}</p>
            </div>
          </div>
        </Modal.Body>
        <div class="custom-modal-dividor"></div>
        <div class="custom-modal-footer d-grid gap-2 d-md-flex align-items-center justify-content-md-end">
          <button
            class="btn btn-link me-md-2"
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={props.onHide}
          >
            Cancel
          </button>
          <NextButton
            label="Save"
            classData="btn btn-default"
            disable={props.loading || !props?.selectedMessage?.reminderDate || error?.length > 0}
            handleSubmit={props?.setReminderQuestion}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ReminderDate;
