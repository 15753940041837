import { createSlice, current } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";

const slice = createSlice({
  name: "avaition",
  initialState: {
    loading: false,
    parts: {},
    partManufacturer: {},
    partCondition: {},
    rfqRequests: {},
    procurementRequests: {},
    partLocation: {},
    model: {},
    logs: {},
    log: {},
  },
  reducers: {
    partsRequested: (state, action) => {
      state.loading = true;
    },
    partsReceived: (state, action) => {
      state.parts = action.payload;
      state.loading = false;
    },
    partsFailed: (state, action) => {
      state.loading = false;
    },
    partManufacturerRequested: (state, action) => {
      state.loading = true;
    },
    partManufacturerReceived: (state, action) => {
      state.partManufacturer = action.payload;
      state.loading = false;
    },
    partManufacturerFailed: (state, action) => {
      state.loading = false;
    },
    partConditionRequested: (state, action) => {
      state.loading = true;
    },
    partConditionReceived: (state, action) => {
      state.partCondition = action.payload;
      state.loading = false;
    },
    partConditionFailed: (state, action) => {
      state.loading = false;
    },
    rfqRequestsRequested: (state, action) => {
      state.loading = true;
    },
    rfqRequestsReceived: (state, action) => {
      state.rfqRequests = action.payload;
      state.loading = false;
    },
    rfqRequestsFailed: (state, action) => {
      state.loading = false;
    },
    procurementRequestsRequested: (state, action) => {
      state.loading = true;
    },
    procurementRequestsReceived: (state, action) => {
      state.procurementRequests = action.payload;
      state.loading = false;
    },
    procurementRequestsFailed: (state, action) => {
      state.loading = false;
    },
    partModelsRequested: (state, action) => {
      state.loading = true;
    },
    partModelsReceived: (state, action) => {
      state.model = action.payload;
      state.loading = false;
    },
    partModelsFailed: (state, action) => {
      state.loading = false;
    },
    logsRequested: (state, action) => {
      state.loading = true;
    },
    logsReceived: (state, action) => {
      state.logs = action.payload;
      state.loading = false;
    },
    logsFailed: (state, action) => {
      state.loading = false;
    },
    logRequested: (state, action) => {
      state.loading = true;
    },
    logReceived: (state, action) => {
      state.log = action.payload;
      state.loading = false;
    },
    logFailed: (state, action) => {
      state.loading = false;
    },
    partLocationRequested: (state, action) => {
      state.loading = true;
    },
    partLocationReceived: (state, action) => {
      state.partLocation = action.payload;
      state.loading = false;
    },
    partLocationFailed: (state, action) => {
      state.loading = false;
    },
  },
});

export const {
  partsRequested,
  partsReceived,
  partsFailed,
  partManufacturerRequested,
  partManufacturerReceived,
  partManufacturerFailed,
  partConditionRequested,
  partConditionReceived,
  partConditionFailed,
  rfqRequestsRequested,
  rfqRequestsReceived,
  rfqRequestsFailed,
  procurementRequestsRequested,
  procurementRequestsReceived,
  procurementRequestsFailed,
  partModelsRequested,
  partModelsReceived,
  partModelsFailed,
  logsRequested,
  logsReceived,
  logsFailed,
  logRequested,
  logReceived,
  logFailed,
  partLocationRequested,
  partLocationReceived,
  partLocationFailed,
} = slice.actions;
export default slice.reducer;

// Action Creators

const baseUrl = "aviation";

//Get Generic Parts
export const getGenericParts = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/genericPart`,
      params,
      method: "get",
      callback,
      onStart: partsRequested.type,
      onSuccess: partsReceived.type,
      onError: partsFailed.type,
    }),
  );
};

//Update Genereric Parts
export const updateGenericParts = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/genericPart/update/${id}`,
      method: 'put',
      data,
      callback,
    })
  )
};

//Delete Generic Parts
export const deleteGenericPart = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/genericPart/delete/${id}`,
      method: 'delete',
      callback,
    })
  )
};

//Get Part Manufacturer
export const getPartManufacturer = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/other/genericPartManufacturer`,
      params,
      method: "get",
      callback,
      onStart: partManufacturerRequested.type,
      onSuccess: partManufacturerReceived.type,
      onError: partManufacturerFailed.type,
    }),
  );
};

//Add Part Manufacturer
export const addPartManufacturer = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/other/genericPartManufacturer`,
      method: "post",
      data,
      callback,
    }),
  );
};

//Update Part Manufacturer
export const updatePartManufacturer = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/other/genericPartManufacturer/${id}`,
      method: "put",
      data,
      callback,
    })
  )
};

//Get Part Conditions
export const getPartCondition = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/other/genericPartCondition`,
      params,
      method: "get",
      callback,
      onStart: partConditionRequested.type,
      onSuccess: partConditionReceived.type,
      onError: partConditionFailed.type,
    }),
  );
};

//Add Part Conditions
export const addPartCondition = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/other/genericPartCondition`,
      method: "post",
      data,
      callback,
    }),
  );
};

//Get Rfq Requests
export const getRfqRequests = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/genericPart/rfqRequest`,
      params,
      method: "get",
      callback,
      onStart: rfqRequestsRequested.type,
      onSuccess: rfqRequestsReceived.type,
      onError: rfqRequestsFailed.type,
    }),
  );
};

//Get Procurement Requests
export const getProcurementRequests = (params, callback) => (dispatch) => {
  dispatch(
    apiCallBegan({
      url: `${baseUrl}/genericPart/procurementRequest`,
      params,
      method: "get",
      callback,
      onStart: procurementRequestsRequested.type,
      onSuccess: procurementRequestsReceived.type,
      onError: procurementRequestsFailed.type,
    }),
  );
};

//Get Models

export const getPartsModels = (params, callback) => (dispatch) => {
  dispatch(
    apiCallBegan({
      url: `${baseUrl}/other/genericPartModel`,
      params,
      method: "get",
      callback,
      onStart: partModelsRequested.type,
      onSuccess: partModelsReceived.type,
      onError: partModelsFailed.type,
    }),
  );
};

//Add Part Models
export const addPartModel = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/other/genericPartModel`,
      method: "post",
      data,
      callback,
    }),
  );
};

//update Part Models
export const updatePartModel = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/other/genericPartModel/${id}`,
      method: "put",
      data,
      callback,
    }),
  );
};

//Get Parts Logs
export const getLogs = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/genericPart/log`,
      params,
      method: "get",
      callback,
      onStart: logsRequested.type,
      onSuccess: logsReceived.type,
      onError: logsFailed.type,
    })
  )
};

//Get Parts Log by Id
export const getLogById = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/genericPart/log/${id}`,
      method: "get",
      callback,
      onStart: logRequested.type,
      onSuccess: logReceived.type,
      onError: logFailed.type,
    })
  )
};

//Get Parts Location
export const getPartsLocation = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/other/genericPartLocation`,
      params,
      method: "get",
      callback,
      onStart: partLocationRequested.type,
      onSuccess: partLocationReceived.type,
      onError: partLocationFailed.type,
    })
  )
};

//Add Parts Location
export const addPartsLocation = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/other/genericPartLocation`,
      method: "post",
      data,
      callback,
    })
  )
}

//Update Parts Location
export const udpatePartsLocation = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/other/genericPartLocation/${id}`,
      method: "put",
      data,
      callback,
    })
  )
};


export const getPartsDetails = createSelector(
  (state) => state.entities.parts,
  (parts) => parts,
);
