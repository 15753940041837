import Joi from "joi-browser";
import _ from "lodash";
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Form from "../../common/form/form";
import NextButton from "../../common/form/nextButton";
import close from "../../include/images/close.svg";
import { toast } from "react-toastify";
import AlertError from "../../common/alerts/alertError";
import AlertSuccess from "../../common/alerts/alertSuccess";
import { DatePicker, DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";

class EditProductDetails extends Form {
  state = {
    data: {
      unit: "",
      quantity: "",
      asking_price: "",
      wholesale_value: "",
      claim_value: "",
      retail_value: "",
      seller_asking_price: "",
      recycle_value: "",
      loading_cost: "",
      desctruction_amt_salvex: 0,
      desctruction_amt_seller: 0,
      custom_duty: 0,
      tax_amt: 0,
      tax_detail: "",
      storage_cost: 0,
      charge_frequency: "",
    },
    errors: {},
    loading: false,
    featureClaimValue: false,
    featureWholesaleValue: false,
    featureReatilValue: false,
    featureAskingPrice: false,
    proof_of_destruction: false,
    b_storage_costs: false,
    b_custom_duty: false,
    storage_cost_date: "",
  };

  schema = {
    unit: Joi.string().allow(""),
    quantity: Joi.number().required(),
    asking_price: Joi.number().allow(""),
    wholesale_value: Joi.number().allow(""),
    claim_value: Joi.number().allow(""),
    retail_value: Joi.number().allow(""),
    seller_asking_price: Joi.number().allow(""),
    recycle_value: Joi.number().allow(""),
    loading_cost: Joi.string().allow("").default(""),
    desctruction_amt_salvex: Joi.number().default(0),
    desctruction_amt_seller: Joi.number().default(0),
    proof_of_destruction: Joi.boolean().default(0),
    custom_duty: Joi.number().default(0),
    b_custom_duty: Joi.boolean().default(0),
    tax_amt: Joi.number().default(0),
    tax_detail: Joi.number().allow("").default(""),
    storage_cost: Joi.number().default(0),
    storage_cost_date: Joi.string().allow("").default(""),
    b_storage_costs: Joi.boolean().default(0),
    charge_frequency: Joi.string().allow("").default(""),
  };

  componentDidUpdate = (prevProps, prevstate) => {
    if (this.props.show !== prevProps.show && this.props.show) {
      const data = {
        unit: this.props.data.unit ? `${this.props.data.unit}` : "",
        quantity: this.props.data.quantity ? this.props.data.quantity : 0,
        asking_price: this.props.data.askingPrice.p_value ? this.props.data.askingPrice.p_value : 0,
        wholesale_value: this.props.data.wholesaleValue.p_value ? this.props.data.wholesaleValue.p_value : 0,
        claim_value: this.props.data.claimValue.p_value ? this.props.data.claimValue.p_value : 0,
        retail_value: this.props.data.retailValue.p_value ? this.props.data.retailValue.p_value : 0,
        seller_asking_price: this.props.data.reserverPrice ? this.props.data.reserverPrice : 0,
        recycle_value: this.props.data.scrapValue ? this.props.data.scrapValue : 0,
        loading_cost: this.props.data.lodingCost ? this.props.data.lodingCost : 0,
        desctruction_amt_salvex: this.props.data.desctruction_amt_salvex ? this.props.data.desctruction_amt_salvex : 0,
        desctruction_amt_seller: this.props.data.desctruction_amt_seller ? this.props.data.desctruction_amt_seller : 0,
        custom_duty: this.props.data.custom_duty ? this.props.data.custom_duty : 0,

        tax_amt: this.props.data.tax_amt ? this.props.data.tax_amt : 0,
        tax_detail: this.props.data.tax_detail ? this.props.data.tax_detail : "",
        storage_cost: this.props.data.storage_cost ? this.props.data.storage_cost : 0,

        charge_frequency: this.props.data.charge_frequency ? this.props.data.charge_frequency : "",
      };
      this.setState({
        data,
        featureAskingPrice: this.props.data.askingPrice && this.props.data.askingPrice.feature,
        featureReatilValue: this.props.data.retailValue && this.props.data.retailValue.feature,
        featureWholesaleValue: this.props.data.wholesaleValue && this.props.data.wholesaleValue.feature,
        featureClaimValue: this.props.data.claimValue && this.props.data.claimValue.feature,
        proof_of_destruction: this.props.data.proof_of_destruction,
        b_storage_costs: this.props.data.b_storage_costs,
        b_custom_duty: this.props.data.b_custom_duty,
        storage_cost_date: this.props.data.storage_cost_date,
      });
    }
  };

  doSubmit = (e) => {
    if (e) e.preventDefault();
    this.props.refStart();
    // this.setState({ loading: true });
    this.props.setLoadingDetails(true);
    const data = this.state.data;
    const auctionId = this.props.auctionId;
    let payload = {
      unit: data.unit,
      quantity: data.quantity,
      asking_price: data.asking_price,
      wholesale_value: data.wholesale_value,
      claim_value: data.claim_value,
      retail_value: data.retail_value,
      seller_asking_price: data.seller_asking_price,
      scrap_value: data.recycle_value,
      loading_cost: this.props.section === "pricing" ? data.loading_cost.toString() : "",
      asking_price_feature: this.props.askingPrice,
      retail_value_feature: this.props.retailValue,
      wholesale_value_feature: this.props.wholesaleValue,
      claim_value_feature: this.props.claimValue,
      desctruction_amt_salvex: data.desctruction_amt_salvex,
      desctruction_amt_seller: data.desctruction_amt_seller,
      proof_of_destruction: this.state.proof_of_destruction,
      custom_duty: data.custom_duty,
      b_custom_duty: this.state.b_custom_duty,
      tax_amt: data.tax_amt,
      tax_detail: data.tax_detail,
      storage_cost: data.storage_cost,
      storage_cost_date: this.state.storage_cost_date ? moment(this.state.storage_cost_date).format("MM-DD-YYYY") : "",
      b_storage_costs: this.state.b_storage_costs,
      charge_frequency: data.charge_frequency,
    };
    let pricingPayload = {
      unit: data.unit,
      quantity: data.quantity,
      asking_price: data.asking_price,
      wholesale_value: data.wholesale_value,
      claim_value: data.claim_value,
      retail_value: data.retail_value,
      seller_asking_price: data.seller_asking_price,
      scrap_value: data.recycle_value,
      loading_cost: "",
      asking_price_feature: this.props.askingPrice,
      retail_value_feature: this.props.retailValue,
      wholesale_value_feature: this.props.wholesaleValue,
      claim_value_feature: this.props.claimValue,
      desctruction_amt_salvex: data.desctruction_amt_salvex,
      desctruction_amt_seller: data.desctruction_amt_seller,
      proof_of_destruction: this.state.proof_of_destruction,
      custom_duty: data.custom_duty,
      b_custom_duty: this.state.b_custom_duty,
      tax_amt: data.tax_amt,
      tax_detail: data.tax_detail,
      storage_cost: data.storage_cost,
      storage_cost_date: this.state.storage_cost_date ? moment(this.state.storage_cost_date).format("MM-DD-YYYY") : "",
      b_storage_costs: this.state.b_storage_costs,
      charge_frequency: data.charge_frequency,
    };
    const payloadForPricing = {
      f_1001: this.state.featureAskingPrice,
      f_1002: this.state.featureReatilValue,
      f_1003: this.state.featureWholesaleValue,
      f_1004: this.state.featureClaimValue,
      p_1003: data.wholesale_value.toString(),
      p_1004: data.claim_value.toString(),
      p_1002: data.retail_value.toString(),
      p_1001: data.asking_price.toString(),
      scrap_value: data.recycle_value,
      seller_reserve: data.seller_asking_price,
    };
    if (this.props.MoreButton) {
      this.props.updatePricing(auctionId, payloadForPricing, (res1) => {
        if (res1.status === 200) {
          this.props.updateProductDetails(auctionId, payload, (res) => {
            if (res && res.status === 200) {
              const detail = {
                askingPrice: {
                  p_value: data.asking_price,
                  feature: this.state.featureAskingPrice,
                  pt_id: 1001,
                },
                claimValue: {
                  p_value: data.claim_value,
                  feature: this.state.featureClaimValue,
                  pt_id: 1004,
                },
                lodingCost: data.loading_cost,
                quantity: data.quantity,
                reserverPrice: data.seller_asking_price,
                desctruction_amt_salvex: data.desctruction_amt_salvex,
                desctruction_amt_seller: data.desctruction_amt_seller,
                proof_of_destruction: this.state.proof_of_destruction,
                custom_duty: data.custom_duty,
                b_custom_duty: this.state.b_custom_duty,
                tax_amt: data.tax_amt,
                tax_detail: data.tax_detail,
                storage_cost: data.storage_cost,
                storage_cost_date: this.state.storage_cost_date,
                b_storage_costs: this.state.b_storage_costs,
                charge_frequency: data.charge_frequency,
                retailValue: {
                  p_value: data.retail_value,
                  feature: this.state.featureReatilValue,
                  pt_id: 1002,
                },
                scrapValue: data.recycle_value,
                unit: data.unit,
                wholesaleValue: {
                  p_value: data.wholesale_value,
                  feature: this.state.featureWholesaleValue,
                  pt_id: 1003,
                },
              };
              this.props.productDetails(auctionId, (res) => {});
              this.props.listingProductDetailReceived({ detail });
              this.props.setLoadingDetails(false);
              this.props.toggleEditModal();
              this.props.refStop();
              toast(<AlertSuccess message={"Information Saved"} />);
            } else {
              this.props.refStop();
              toast(
                <AlertError
                  message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"}
                />,
              );
              this.props.setLoadingDetails(false);
            }
          });
        } else {
          this.props.refStop();
          toast(
            <AlertError
              message={res1 && res1.data && res1.data.message ? res1.data.message : "Something Went Wrong"}
            />,
          );
          this.props.setLoadingDetails(false);
        }
      });
    } else {
      this.props.updateProductDetails(auctionId, this.props.section === "pricing" ? pricingPayload : payload, (res) => {
        if (res.status === 200) {
          this.props.section === "pricing" && this.props.getLatestBids(auctionId, (res) => {});
          this.props.section === "pricing" && this.props.getPriceInfo(auctionId, (res) => {});
          this.props.section === "pricing" && this.props.getCommentsAndNotes(auctionId, (res) => {});
          this.props.productDetails(auctionId, (res) => {});
          this.props.section === "pricing" && this.props.detailListing(auctionId, (res) => {});
          this.props.section === "pricing" && this.props.getDealProgress(auctionId, (res) => {});
          const detail = {
            askingPrice: {
              p_value: data.asking_price,
              feature: this.state.featureAskingPrice,
              pt_id: 1001,
            },
            claimValue: {
              p_value: data.claim_value,
              feature: this.state.featureClaimValue,
              pt_id: 1004,
            },
            lodingCost: data.loading_cost,
            quantity: data.quantity,
            reserverPrice: data.seller_asking_price,
            desctruction_amt_salvex: data.desctruction_amt_salvex,
            desctruction_amt_seller: data.desctruction_amt_seller,
            proof_of_destruction: this.state.proof_of_destruction,
            custom_duty: data.custom_duty,
            b_custom_duty: this.state.b_custom_duty,
            tax_amt: data.tax_amt,
            tax_detail: data.tax_detail,
            storage_cost: data.storage_cost,
            storage_cost_date: this.state.storage_cost_date,
            b_storage_costs: this.state.b_storage_costs,
            charge_frequency: data.charge_frequency,
            retailValue: {
              p_value: data.retail_value,
              feature: this.state.featureReatilValue,
              pt_id: 1002,
            },
            scrapValue: data.recycle_value,
            unit: data.unit,
            wholesaleValue: {
              p_value: data.wholesale_value,
              feature: this.state.featureWholesaleValue,
              pt_id: 1003,
            },
          };
          this.props.toggleEditModal();
          this.props.listingProductDetailReceived({ detail });

          this.props.setLoadingDetails(false);

          this.props.refStop();
          toast(<AlertSuccess message={"Information Saved"} />);
        } else {
          this.props.refStop();
          toast(
            <AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />,
          );
          this.props.setLoadingDetails(false);
        }
      });
    }
  };

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={() => this.props.toggleEditModal()}
        className="modal fade custom-modal"
        id="editProductDetail"
        dialogClassName={
          !this.props.LatestActivity && !this.props?.transportation
            ? "modal-dialog modal-dialog-centered modal-xxl"
            : "modal-dialog modal-dialog-centered modal-lg"
        }
      >
        <div class="modal-content d-flex flex-column">
          <div class="custom-modal-header d-flex align-items-center justify-content-between">
            <h5 class="mb-0 text-capitalize">
              {this.props.section === "pricing"
                ? "Qty & Pricing"
                : this.props.section === "trasportation"
                ? "Transportation & Storage"
                : "Product Details"}
            </h5>
            <button
              type="button"
              class="close-btn d-flex align-items-center justify-content-center"
              onClick={() => this.props.toggleEditModal()}
            >
              <img src={close} alt="" />
            </button>
          </div>
          <Modal.Body className="custom-modal-body flex-grow-1 w-100 overflow-hidden">
            <div class="two-row-column d-flex flex-wrap">
              {!this?.props?.transportation && (
                <div
                  class={
                    !this.props.LatestActivity && !this?.props?.transportation
                      ? "tr-column w-50 position-relative"
                      : "tr-column w-100 position-relative"
                  }
                >
                  <div class="tr-column-content w-100 h-100 d-flex flex-column">
                    <table class="table modal-product-detail-table mb-0">
                      <colgroup>
                        <col style={{ width: "32%" }} />
                        <col style={{ width: "43%" }} />
                        <col style={{ width: "25%" }} />
                      </colgroup>
                      <tr>
                        <td>Unit</td>
                        <td>
                          <div className="padding-0">{this.renderInput("unit", "")}</div>
                        </td>
                        <td></td>
                      </tr>
                      {this.props.section != "pricing" && (
                        <tr>
                          <td>Qty</td>
                          <td>
                            <div className="padding-0">{this.renderInput("quantity", "")}</div>
                          </td>
                          <td></td>
                        </tr>
                      )}
                    </table>

                    {/* Three row table */}
                    <table class="table modal-product-detail-table mb-0">
                      <colgroup>
                        <col style={{ width: "32%" }} />
                        <col style={{ width: "43%" }} />
                        <col style={{ width: "25%" }} />
                      </colgroup>
                      {this.props.section === "pricing" && (
                        <tr>
                          <td>Qty</td>
                          <td>
                            <div className="padding-0">{this.renderInput("quantity", "")}</div>
                          </td>
                          <td>
                            <div class="form-check tick-checkbox d-flex align-items-center">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value=""
                                id="confirmd"
                                checked={this.props.confirmed}
                                onChange={(e) => {
                                  this.props.setConfirmed(!this.props.confirmed);
                                  this.props.handleQuantityConfirmation(e.target.checked ? 1 : 0);
                                }}
                              />
                              <label class="form-check-label fw-medium" for="confirmd">
                                Confirmed?
                              </label>
                            </div>
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td>Public Asking Price</td>
                        <td>
                          <div className="padding-0">{this.renderInput("asking_price", "")}</div>
                        </td>
                        <td>
                          <div class="form-check tick-checkbox d-flex align-items-center">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="tick1"
                              checked={this.props.askingPrice}
                              onChange={() => {
                                this.props.setAskingPrice(!this.props.askingPrice);
                                this.setState({
                                  featureAskingPrice: !this.props.askingPrice,
                                });
                              }}
                            />
                            <label class="form-check-label fw-medium" for="tick1">
                              Feature?
                            </label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Seller Asking Price</td>
                        <td>
                          <div className="padding-0">{this.renderInput("seller_asking_price", "")}</div>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>Original Wholesale Value</td>
                        <td>
                          <div className="padding-0">{this.renderInput("wholesale_value", "", "", "number")}</div>
                        </td>
                        <td>
                          <div class="form-check tick-checkbox d-flex align-items-center">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="tick3"
                              checked={this.props.wholesaleValue}
                              onChange={() => {
                                this.props.setWholesaleValue(!this.props.wholesaleValue);
                                this.setState({
                                  featureWholesaleValue: !this.props.wholesaleValue,
                                });
                              }}
                            />
                            <label class="form-check-label fw-medium" for="tick3">
                              Feature?
                            </label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Retail Value</td>
                        <td>
                          <div className="padding-0">{this.renderInput("retail_value", "", "", "number")}</div>
                        </td>
                        <td>
                          <div class="form-check tick-checkbox d-flex align-items-center">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="tick2"
                              checked={this.props.retailValue}
                              onChange={() => {
                                this.props.setRetailValue(!this.props.retailValue);
                                this.setState({
                                  featureReatilValue: !this.props.retailValue,
                                });
                              }}
                            />
                            <label class="form-check-label fw-medium" for="tick2">
                              Feature?
                            </label>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td>Claim Value</td>
                        <td>
                          <div className="padding-0">{this.renderInput("claim_value", "", "", "number")}</div>
                        </td>
                        <td>
                          <div class="form-check tick-checkbox d-flex align-items-center">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="tick4"
                              checked={this.props.claimValue}
                              onChange={() => {
                                this.props.setClaimValue(!this.props.claimValue);
                                this.setState({
                                  featureClaimValue: !this.props.claimValue,
                                });
                              }}
                            />
                            <label class="form-check-label fw-medium" for="tick4">
                              Feature?
                            </label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Recycle Value</td>
                        <td>
                          <div className="padding-0">{this.renderInput("recycle_value", "", "", "number")}</div>
                        </td>
                        <td></td>
                      </tr>
                    </table>
                  </div>
                </div>
              )}
              {!this.props.LatestActivity && (
                <div
                  class={
                    !this.props.LatestActivity && !this?.props?.transportation
                      ? "tr-column w-50 position-relative"
                      : "tr-column w-100 position-relative"
                  }
                >
                  <div class="tr-column-content w-100 h-100 d-flex flex-column">
                    <table class="table modal-product-detail-table mb-0">
                      <colgroup>
                        <col style={{ width: "32%" }} />
                        <col />
                      </colgroup>
                      <tr>
                        <td>Amount Quoted to Salvex:</td>
                        <td>
                          <div className="padding-0">
                            {this.renderInput("desctruction_amt_salvex", "", "", "number")}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Amount Quoted to Seller:</td>
                        <td>
                          <div className="padding-0">
                            {this.renderInput("desctruction_amt_seller", "", "", "number")}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Seller Requires Proof of Destruction Certificate / Video / Photos?</td>
                        <td>
                          <div class="form-check tick-checkbox d-flex align-items-center">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="tick5"
                              checked={this.state.proof_of_destruction}
                              onChange={() =>
                                this.setState({
                                  proof_of_destruction: !this.state.proof_of_destruction,
                                })
                              }
                            />
                            <label class="form-check-label fw-medium" for="tick5"></label>
                          </div>
                        </td>
                      </tr>
                      {/*
              <tr>
                <td>Pickup Location</td>
                <td>
                  <div className="padding-0">{this.renderInput("", "")}</div>
                </td>
                <td></td>
              </tr>

              <tr>
                <td>Dropoff Location</td>
                <td>
                  <div className="padding-0">{this.renderInput("", "")}</div>
                </td>
                <td></td>
              </tr> */}
                      <tr>
                        <td>Storage Costs</td>
                        <td>
                          <div className="padding-0">{this.renderInput("storage_cost", "")}</div>
                        </td>
                      </tr>
                      <tr>
                        <td>Buyer is responsible to pay for Storage costs? </td>
                        <td>
                          <div class="form-check tick-checkbox d-flex align-items-center">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="tick6"
                              checked={this.state.b_storage_costs}
                              onChange={() =>
                                this.setState({
                                  b_storage_costs: !this.state.b_storage_costs,
                                })
                              }
                            />
                            <label class="form-check-label fw-medium" for="tick6"></label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Date Storage Costs Begin</td>
                        <td>
                          <div className="padding-0">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                disableHighlightToday={true}
                                className="form-control"
                                value={this.state.storage_cost_date}
                                onChange={(e) => {
                                  this.setState({
                                    storage_cost_date: e,
                                  });
                                }}
                                renderInput={(params) => <TextField {...params} error={false} />}
                                inputFormat="MM/dd/yyyy"
                                // disableMaskedInput={true}
                                // disabled={!props.values.storage_date}
                              />
                            </LocalizationProvider>
                          </div>
                        </td>
                      </tr>
                      {/* <tr>
                <td>How often charged </td>
                <td>
                  <div className="padding-0">{this.renderInput("", "")}</div>
                </td>
                <td></td>
              </tr> */}
                      <tr>
                        <td>Customs Duties</td>
                        <td>
                          <div className="padding-0">{this.renderInput("custom_duty", "")}</div>
                        </td>
                      </tr>
                      {/* <tr>
                <td>Buyer is responsible to pay? </td>
                <td>
                  <div class="form-check tick-checkbox d-flex align-items-center">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="tick7"
                      checked={this.state.b_custom_duty}
                      onChange={() =>
                        this.setState({
                          b_custom_duty: !this.state.b_custom_duty,
                        })
                      }
                    />
                    <label
                      class="form-check-label fw-medium"
                      for="tick7"
                    ></label>
                  </div>
                </td>
                <td></td>
              </tr> */}
                      <tr>
                        <td>Taxes</td>
                        <td>
                          <div className="padding-0">{this.renderInput("tax_detail", "")}</div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              )}
            </div>
          </Modal.Body>
          <div class="custom-modal-dividor"></div>
          <div class="custom-modal-footer d-grid gap-2 d-md-flex align-items-center justify-content-md-end">
            <button
              class="btn btn-link global-cancel-button me-md-2"
              type="button"
              onClick={() => this.props.toggleEditModal()}
            >
              Cancel
            </button>
            <NextButton
              classData="btn btn-default"
              handleSubmit={() => this.doSubmit()}
              label={"Save"}
              loading={this.props.loadingDetails}
            />
          </div>
        </div>
      </Modal>
    );
  }
}

export default React.memo(EditProductDetails);
