import React, { useEffect, useState } from "react";
import close from "../../../include/images/close.svg";
import { Offcanvas } from "react-bootstrap";
import DateFormat from "../../../common/DateFormat/dateFormat";

const PushHistory = (props) => {
  return (
    <Offcanvas
      placement="end"
      backdropClassName="upload-documnets-backdrop"
      show={props.show}
      onHide={() => props.onHide()}
      className=" right-collapse fade modal-lg  offcanvas-pannel-container"
      dialogClassName="modal-dialog"
    >
      <Offcanvas.Header className="offcanvas-header" style={{ paddingLeft: "30px" }}>
        <h5 class="offcanvas-title">Push History</h5>
        <button
          type="button"
          class="offcanvas-close-btn d-flex align-items-center justify-content-center"
          onClick={() => props.onHide()}
        >
          <img src={close} alt="" />
        </button>
      </Offcanvas.Header>
      <Offcanvas.Body className="custom-modal-body flex-grow-1 w-100 overflow-scroll" data-simplebar>
        <div className="table-responsive  table-custom">
          <table className="table table-bordered">
            <colgroup>
              <col />
              <col />
              <col />
            </colgroup>
            <thead>
              <tr>
                <th>Sender</th>
                <th>Message</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {props?.show && props?.data?.logs?.length > 0 ? (
                props?.data.logs.map((val, idx) => {
                  return (
                    <>
                      <tr>
                        <td>
                          <span>{val.sender} </span>
                        </td>
                        <td>
                          {" "}
                          <p className="mt-2">{val.message}</p>
                        </td>
                        <td>
                          {" "}
                          <span>{val && val.created ? <DateFormat date={val.created} /> : "N/A"}</span>
                        </td>
                      </tr>
                    </>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={3}>
                    <div className="d-flex justify-content-center align-items-center">
                      <span>No record found</span>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default PushHistory;
