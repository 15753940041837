import React from "react";
import { Modal } from "react-bootstrap";

const ActivationModal = (props) => {
  return (
    <Modal
      className=" fade custom-modal-popup"
      show={props.show}
      onHide={() => props.onHide()}
      enforceFocus={true}
      backdropClassName={"delete-backdrop"}
      //   backdrop={uploadImageModal ? false : true}
      centered
    >
      <Modal.Body>
        <div class="modal-alert-box">
          <h4>Alert! </h4>
          <p>
            {props.activation
              ? "Are you sure you want to reactivate this bid?"
              : "Are you sure you want to deactivate this bid?"}
          </p>
        </div>
        <div class="modal-action-btn">
          <a
            href="javascript:void(0)"
            class={`btn btn-default btn-lg  ${props.loading && "btn-disabled"}`}
            onClick={() => !props.loading && props.submitHandler()}
          >
            Ok
          </a>
          <a
            href="javascript:void(0)"
            style={{ background: "#fff" }}
            class="btn btn-cancel global-cancel-button"
            onClick={() => props.onHide()}
          >
            Cancel
          </a>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ActivationModal;
