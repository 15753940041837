import React from "react";
import { Modal } from "react-bootstrap";
import close from "../../../include/images/close.svg";
import NextButton from "../../../common/form/nextButton";

const DeActivateRateModal = (props) => {
  return (
    <Modal
      className="modal fade custom-modal"
      id="deactivateandrate"
      show={props.show}
      onHide={props.onHide}
      dialogClassName="modal-dialog modal-dialog-centered modal-xxl"
    >
      <div class="modal-content d-flex flex-column">
        <div class="custom-modal-header d-flex align-items-center justify-content-between">
          <h5 class="mb-0 text-capitalize">Deactivate And Rate</h5>
          <button
            type="button"
            class="close-btn d-flex align-items-center justify-content-center"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={props.onHide}
          >
            <img src={close} alt="" />
          </button>
        </div>

        <Modal.Body class="custom-modal-body flex-grow-1 w-100 overflow-hidden">
          <sup style={{ color: "red" }}>*</sup>
          <strong style={{ color: "red" }}>
            BEFORE EDITING THIS INVOICE, YOU MUST RATE THIS BUYER.
          </strong>

          <table class="table table-borderless align-middle table-left-align mt-4">
            <colgroup>
              <col style={{ width: "15%" }} />
              <col />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <td>Add New Rating</td>
                <td>
                  {props?.data?.auction_id ? props?.data?.auction_id : "0 "}-{" "}
                  {props?.data?.auc_title ? props?.data?.auc_title : ""}
                </td>
              </tr>
            </tbody>
          </table>

          <table class="table table-borderless align-middle table-left-align mb-4">
            <colgroup>
              <col style={{ width: "15%" }} />
              <col style={{ width: "4%" }} />
              <col style={{ width: "4%" }} />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <td>How do you rate this member?</td>
                <td>
                  <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="option"
                      id="positive"
                      checked={props?.statusData?.rating === 2}
                      onChange={() => {
                        const data = { ...props.statusData };
                        data.rating = 2;
                        props.setStatusData(data);
                      }}
                    />
                    <label class="form-check-label" for="positive">
                      Positive
                    </label>
                  </div>
                </td>
                <td colspan="2">
                  <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="option"
                      id="nuetral"
                      checked={props?.statusData?.rating === 1}
                      onChange={() => {
                        const data = { ...props.statusData };
                        data.rating = 1;
                        props.setStatusData(data);
                      }}
                    />
                    <label class="form-check-label" for="nuetral">
                      Neutral
                    </label>
                  </div>
                </td>
                <td colspan="2">
                  <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="option"
                      id="negative"
                      checked={props?.statusData?.rating === 0}
                      onChange={() => {
                        const data = { ...props.statusData };
                        data.rating = 0;
                        props.setStatusData(data);
                      }}
                    />
                    <label class="form-check-label" for="negative">
                      Negative
                    </label>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="full-width-textarea-row d-flex flex-wrap">
            <div class="fwtr-label-box pt-2">Type your review</div>
            <div class="fwtr-input-box flex-grow-1">
              <textarea
                class="form-control w-100"
                id="FormControlTextareaL"
                value={props?.statusData?.review}
                onChange={(e) => {
                  const data = { ...props.statusData };
                  data.review = e.target.value;
                  props.setStatusData(data);
                }}
              ></textarea>
            </div>
          </div>
        </Modal.Body>
        <div class="custom-modal-dividor"></div>
        <div class="custom-modal-footer d-grid gap-2 d-md-flex align-items-center justify-content-md-end">
          <button
            class="btn btn-link me-md-2"
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={props.onHide}
          >
            Cancel
          </button>
          <NextButton
            class="btn btn-default"
            handleSubmit={props.handleSubmit}
            type="button"
            label={"Save"}
            loading={props.loading}
          />
        </div>
      </div>
    </Modal>
  );
};

export default DeActivateRateModal;
