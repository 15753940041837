import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import ProductDescriptionModal from "../../../layouts/modals/productDescriptionModal";
import {
  getListingProductDetail,
  productDescriptionReceived,
  updateProductDescription,
} from "../../../store/listingProduct";
import { toast } from "react-toastify";
import AlertError from "../../../common/alerts/alertError";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import parse from "html-react-parser";
import Skeleton from "react-loading-skeleton";
import UploadParts from "../../../layouts/modals/productDescription/uploadParts";
import { getDescpParts, getListingMedia, saveColumn, savePart, uploadDescpParts } from "../../../store/listingsMedia";
import { v4 as uuidv4 } from "uuid";
import { Amplify, Storage } from "aws-amplify";
import { Auth } from "aws-amplify";
import ViewParts from "../../../layouts/modals/productDescription/viewParts";
import _ from "lodash";
import ConfirmModal from "../../../layouts/modals/confirmModal/confirmModal";
import helpCircle from "../../../include/images/help-circle.svg";
import { getModals, setDescription } from "../../../store/modal";
import { detailListing } from "../../../store/listings";

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    region: "us-east-1",
  },
  Storage: {
    bucket: process.env.REACT_APP_S3_UPLOAD_BUCKET,
    region: "us-east-1",
  },
});
const ProductDescription = (props) => {
  const viewPartRef = useRef(null);
  // const [showDescpModal, setShowDescpModal] = useState(false);
  const [initialData, setInitialData] = useState("");
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [editorData, setEditorData] = useState("");
  const [loadingDescription, setLoadingDescription] = useState(false);

  //Upload Parts
  const [showUploadPartModal, setShowUploadPartModal] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [loadingUploadParts, setLoadingUploadParts] = useState(false);
  const [uploadPartData, setUploadPartData] = useState({});
  const [checkHeaderRow, setCheckHeaderRow] = useState(false);
  const [columnData, setColumnData] = useState([]);
  const [filePath, setFilePath] = useState("");
  const [loadingUploadParts2, setLoadingUploadParts2] = useState(false);
  const [loadingUploadParts3, setLoadingUploadParts3] = useState(false);
  const [loadingUploadParts4, setLoadingUploadParts4] = useState(false);
  const [viewType, setViewType] = useState("edit");

  //View Parts
  const [showViewPartsModal, setShowViewPartsModal] = useState(false);
  const [loadingGetParts, setLoadingGetParts] = useState(false);
  const [attributes, setAttributes] = useState([]);
  const [viewPartData, setViewPartData] = useState({});

  //confirmModal
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const showDescpModal = props?.showDescpModal;

  const toggleConfirmModal = () => {
    setShowConfirmModal(!showConfirmModal);
  };

  const toggleDescriptionModal = () => {
    props?.setShowDescpModal(!showDescpModal);
  };

  const toggleUploadPartModal = () => {
    if (showUploadPartModal) {
      setUploadPartData({});
      setSelectedDoc(null);
      setFilePath("");
    }
    setShowUploadPartModal(!showUploadPartModal);
  };

  const toggleViewPartModal = () => {
    if (showViewPartsModal) {
      setViewPartData({});
    }
    setShowViewPartsModal(!showViewPartsModal);
  };

  const handleEditUploadParts = () => {
    setLoadingUploadParts2(true);
    props.refStart();
    const id = props.auctionId;
    const payload1 = {
      document: filePath,
      action: "complete",
    };
    const payload2 = {
      document: filePath,
      action: "save",
      start: 0,
      columns: columnData && columnData.length ? columnData.map((item) => item.value) : [],
      exclude_header_row: checkHeaderRow,
    };
    props.uploadDescpParts(id, payload2, (res) => {
      if (res.status === 200) {
        props.uploadDescpParts(id, payload1, (res) => {
          if (res.status === 200) {
            props.refStop();
            setLoadingUploadParts2(false);
            setCheckHeaderRow(false);
            setColumnData([]);
            setFilePath("");
            setSelectedDoc(null);
            setUploadPartData({});
            toggleUploadPartModal();
            toast(<AlertSuccess message={"Information Saved"} />);
          } else {
            setLoadingUploadParts(false);
            props.refStop();
            toast(
              <AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />,
            );
          }
        });
      } else {
        setLoadingUploadParts(false);
        props.refStop();
        toast(<AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const handleUploadParts = () => {
    if (selectedDoc) {
      setLoadingUploadParts(true);
      const file = selectedDoc;
      props.refStart();
      const fSize = Math.round(file.size / 1048576);
      const fType = file.type;
      const ext = file.name.split(".").pop();
      if (!selectedDoc) {
        props.refStop();
        toast(<AlertError message="No File Selected." />);
        return setLoadingUploadParts(false);
      } else if (fSize > 25) {
        props.refStop();
        toast(<AlertError message="Size exceeds maximum allowable size. Maximum allowable size is 25MB." />);
        return setLoadingUploadParts(false);
      } else if (!["text/csv"].includes(fType)) {
        props.refStop();
        toast(
          <AlertError message="File is not of correct format and hence cannot be uploaded. Valid document format is csv." />,
        );
        return setLoadingUploadParts(false);
      } else {
        const fileName = "newSalvex/doc/" + uuidv4() + "." + ext;
        Storage.put(fileName, file, {
          completeCallback: (event) => {},
          progressCallback: (progress) => {},
          errorCallback: (err) => {
            return (
              setLoadingUploadParts(false), props.refStop(), toast(<AlertError message={"Something Went Wrong"} />)
            );
          },
        }).then((result) => {
          setFilePath("public/" + result.key);
          props.uploadDescpParts(
            props.auctionId,
            {
              document: "public/" + result.key,
              action: "processcolumn",
            },
            (res) => {
              if (res.status === 200) {
                setUploadPartData(res.data.data);
                props.refStop();
                setLoadingUploadParts(false);
              } else {
                setLoadingUploadParts(false);

                props.refStop();
                toast(
                  <AlertError
                    message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"}
                  />,
                );
              }
            },
          );
        });
      }
    } else {
      toast(<AlertError message="No File Selected." />);
    }
  };

  const handleSubmit = () => {
    setLoadingDescription(true);
    props.refStart();
    const id = props.auctionId;
    const data = {
      description: editorData,
    };

    props.updateProductDescription(id, data, (res) => {
      if (res.status === 200) {
        props.detailListing(id, (res) => {});
        setLoadingDescription(false);
        const a = editorData;
        props.productDescriptionReceived(editorData);
        toggleDescriptionModal();
        props.refStop();
        toast(<AlertSuccess message={"Information Saved"} />);
      } else {
        setLoadingDescription(false);
        props.refStop();
        toast(<AlertError message={res && res.data && res.data.message ? res.data.message : "Somethig Went Wrong"} />);
      }
    });
  };

  const handleSaveDetails = () => {
    setLoadingUploadParts3(true);
    props.refStart();
    let payload;
    if (attributes) {
      const a = attributes;
      payload = {
        public: `${a.map((item) => item.checked && item.value)}`,
      };
    } else {
      payload = "";
    }
    const id = props.auctionId;
    props.saveColumn(id, payload, (res) => {
      if (res && res.status === 200) {
        setLoadingUploadParts3(false);
        props.refStop();
        toast(<AlertSuccess message={"Information Saved"} />);
      } else {
        setLoadingUploadParts3(false);
        props.refStop();
        toast(<AlertError message={res && res.data && res.data.message ? res.data.message : "Somethig Went Wrong"} />);
      }
    });
  };

  const handlePopulateDetails = () => {
    setLoadingUploadParts4(true);
    props.refStart();
    const payload = {
      description: `${
        viewPartRef && viewPartRef.current && viewPartRef.current.innerHTML ? viewPartRef.current.innerHTML : ""
      }`,
    };

    const id = props.auctionId;
    props.savePart(id, payload, (res) => {
      if (res && res.status === 200) {
        props.productDescriptionReceived(payload.description);
        setLoadingUploadParts4(false);
        props.refStop();
        toggleConfirmModal();
        toggleViewPartModal();

        toast(<AlertSuccess message={"Information Saved"} />);
      } else {
        setLoadingUploadParts4(false);
        props.refStop();
        toast(<AlertError message={res && res.data && res.data.message ? res.data.message : "Somethig Went Wrong"} />);
      }
    });
  };

  useEffect(() => {
    if (uploadPartData && uploadPartData.table && uploadPartData.table.length && uploadPartData.table[0].length) {
      const c = new Array(uploadPartData.table[0].length).fill({
        name: "Unmapped",
        value: 99999999,
      });
      setColumnData(c);
    }
  }, [uploadPartData && uploadPartData.table && uploadPartData.table.length && uploadPartData.table[0].length]);

  useEffect(() => {
    if (showDescpModal) {
      setInitialData(listingDetail);
    }
  }, [showDescpModal]);

  useEffect(() => {
    if (showViewPartsModal) {
      const id = props.auctionId;
      setLoadingGetParts(true);
      props.getDescpParts(id, viewType, (res) => {
        if (res && res.status === 200) {
          setLoadingGetParts(false);
          const columnActive =
            res.data &&
            res.data.data &&
            res.data.data.columns &&
            res.data.data.columns.length > 0 &&
            res.data.data.columns.map((item) => {
              return {
                name: item[0],
                checked: item[1],
                value: item[2],
              };
            });
          setAttributes(columnActive);
          setViewPartData(res.data.data);
        } else {
          setLoadingGetParts(false);

          toast(
            <AlertError message={res && res.data && res.data.message ? res.data.message : "Somethig Went Wrong"} />,
          );
        }
      });
    }
  }, [showViewPartsModal, viewType]);

  const listingDetail = props.getListingProductDetail && props.getListingProductDetail.description;

  const title =
    props.getListingProductDetail &&
    props.getListingProductDetail.condition &&
    props.getListingProductDetail.condition.detail &&
    props.getListingProductDetail.condition.detail.title;

  return (
    <div class="details-page-widget-block" id={props.id}>
      <div class="page-widget-head d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <div
            class="page-widget-label text-capitalize cursor-pointer"
            onClick={() => !props.loadingMain && toggleDescriptionModal()}
          >
            Product Description
          </div>

          <div style={{ display: "none" }} id="popover">
            <div class="popover-inner" role="tooltip">
              <p>
                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer
                took a galley
              </p>
            </div>
          </div>
        </div>
        <div class="btn-block d-flex align-items-center">
          {/* {props.loadingMain ? (
            <Skeleton />
          ) : (
            <a
              href="javascript:void(0)"
              class="btn btn-default btn-sm"
              onClick={() => toggleUploadPartModal()}
            >
              Upload Parts
            </a>
          )}
          {props.loadingMain ? (
            <Skeleton />
          ) : (
            <a
              href="javascript:void(0)"
              class="btn btn-default btn-sm"
              onClick={() => toggleViewPartModal()}
            >
              View Parts
            </a>
          )} */}

          {/* -------------------edit button------------------ */}
          {/* {props.loadingMain ? (
            <Skeleton />
          ) : (
            <a href="javascript:void(0)" class="btn btn-primary btn-sm" onClick={() => toggleDescriptionModal()}>
              Edit
            </a>
          )} */}
        </div>
      </div>
      <div class="page-widget-body mt-3 ">
        {props.loadingMain ? (
          <Skeleton width="100%" count={6} height="10px" />
        ) : listingDetail ? (
          <div
            className="ck-content ck-content-description table custom-table  align-middle table-left-align ipr_table"
            id="descpFix"
            dangerouslySetInnerHTML={{ __html: listingDetail }}
          />
        ) : (
          "N/A"
        )}

        {/* <div
          className="ck-content ck-content-description table  align-middle table-left-align"
          id="descpFix"
        >
          {props.loadingMain ? (
            <Skeleton width="100%" count={6} height="10px" />
          ) : listingDetail ? (
            parse(listingDetail)
          ) : (
            "N/A"
          )}
        </div> */}
      </div>
      <ProductDescriptionModal
        show={showDescpModal}
        onHide={toggleDescriptionModal}
        initialData={initialData}
        setEditorData={setEditorData}
        editorData={editorData}
        setEditorLoaded={setEditorLoaded}
        editorLoaded={editorLoaded}
        loadingDescription={loadingDescription}
        handleSubmit={handleSubmit}
      />
      {/* <UploadParts
        show={showUploadPartModal}
        onHide={toggleUploadPartModal}
        handleUploadParts={handleUploadParts}
        selectedDoc={selectedDoc}
        setSelectedDoc={setSelectedDoc}
        loadingUploadParts={loadingUploadParts}
        uploadPartData={uploadPartData}
        setUploadPartData={setUploadPartData}
        checkHeaderRow={checkHeaderRow}
        setCheckHeaderRow={setCheckHeaderRow}
        handleEditUploadParts={handleEditUploadParts}
        setColumnData={setColumnData}
        columnData={columnData}
        filePath={filePath}
        loadingUploadParts2={loadingUploadParts2}
      /> */}
      {/* <ViewParts
        show={showViewPartsModal}
        onHide={toggleViewPartModal}
        viewPartData={viewPartData}
        title={title}
        attributes={attributes}
        setAttributes={setAttributes}
        handleSaveDetails={handleSaveDetails}
        handlePopulateDetails={handlePopulateDetails}
        loadingUploadParts3={loadingUploadParts3}
        loadingUploadParts4={loadingUploadParts4}
        id={"viewPartTable"}
        viewPartRef={viewPartRef}
        setViewType={setViewType}
        viewType={viewType}
        toggleConfirmModal={toggleConfirmModal}
        setViewPartData={setViewPartData}
        loadingGetParts={loadingGetParts}
      /> */}
      <ConfirmModal
        show={showConfirmModal}
        onHide={toggleConfirmModal}
        message="Are you sure you want to replace the listings details with this table?"
        loading={loadingUploadParts4}
        confirmHandler={handlePopulateDetails}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateProductDescription: (params, data, callback) => dispatch(updateProductDescription(params, data, callback)),
  uploadDescpParts: (params, data, callback) => dispatch(uploadDescpParts(params, data, callback)),
  saveColumn: (params, data, callback) => dispatch(saveColumn(params, data, callback)),
  savePart: (params, data, callback) => dispatch(savePart(params, data, callback)),
  getDescpParts: (params, mode, callback) => dispatch(getDescpParts(params, mode, callback)),

  //global state for manipulating data
  productDescriptionReceived: (data) => dispatch(productDescriptionReceived(data)),
  setShowDescpModal: (data) => dispatch(setDescription(data)),
  detailListing: (id, callback) => dispatch(detailListing(id, callback)),
});

const mapStateToProps = (state) => ({
  getListingProductDetail: getListingProductDetail(state),
  getListingMedia: getListingMedia(state),
  showDescpModal: getModals(state)?.descriptionModal,
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(ProductDescription));
