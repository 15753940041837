import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import Skeleton from "react-loading-skeleton";
import LoadingBar from "react-top-loading-bar";
import deletebtn from "../../../include/images/trash-18x18.svg";
import enquiry from "../../../include/images/salvex_enquiry_img.jpg";
import imageFill from "../../../include/images/image.svg";
import imageOutLine from "../../../include/images/Image-outline.svg";
import {
  addBulkParts,
  deleteBulkAviationParts,
  deleteParts,
  getAviationDetails,
  getBulkParts,
  updateBulkParts,
  updateGenericParts,
} from "../../../store/aviation";
// import ViewDetails from "./viewDetails";
import { useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import { Amplify, Storage } from "aws-amplify";
import { toast } from "react-toastify";
import DeleteModal from "../../../layouts/modals/deleteModal";
import AlertError from "../../../common/alerts/alertError";
import { twoDecimals } from "../../../utils.js/conversion";
import UploadImage from "../../../layouts/modals/productLocationModal/uploadImage";
import AlertSuccess from "../../../common/alerts/alertSuccess";
// import UploadDocuments from "../../../layouts/modals/uploadDocuments";
import ImageLargeModal from "../../../layouts/modals/imageGalleryModal";
// import UpdateAviationModal from "../../../layouts/modals/aviation/updateAviationModal";
import { unparse } from "papaparse";
import Spreadsheet from "../../../common/spreadsheet/spreadsheet";
import UploadParts from "../../../layouts/modals/parts/uploadParts";
import ViewDetails from "../aviationParts/viewDetails";
import { getPartsDetails } from "../../../store/parts";

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    region: "us-east-1",
  },
  Storage: {
    bucket: process.env.REACT_APP_S3_UPLOAD_BUCKET,
    region: "us-east-1",
  },
});

const Parts = (props) => {
  const partLocation = props.getPartsDetails?.partLocation?.data?.map((item) => item.title);
  const partMfr = props.getPartsDetails?.partManufacturer?.data?.map((item) => item.title);
  const partModels = props.getPartsDetails?.model?.data?.map((item) => item.title);
  const condition = props.getPartsDetails?.partCondition?.data?.map((item) => `${item.code} (${item.title})`);

  const ref = useRef(null);
  const [viewModal, setViewModal] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const [page, setPage] = useState(1);
  const [allData, setAllData] = useState([]);
  const [enable, setEnable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showUploadPartModal, setShowUploadPartModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [aviationParts, setAvaitionParts] = useState([]);
  const [imageUploaded, setImageUploaded] = useState([]);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [showUploadImage, setShowUploadImage] = useState(false);
  const [showUploadFile, setShowUploadFile] = useState(false);
  const [progress, setProgress] = useState("");
  const [uploadDocuments, setUploadDocuments] = useState([]);
  const [fileType, setFileType] = useState(".pdf");
  const [imageGalleryModal, setImageGalleryModal] = useState(false);
  const [selectedImage, setSelecetedImage] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [updateAviation, setUpdateAviation] = useState(false);
  const [checkedValues, setCheckedValues] = useState([]);
  const [uploadHeaders, setUploadHeaders] = useState([]);
  const [indexArr, setIndexArr] = useState([]);
  const [colIndex, setColIndex] = useState([]);
  const [arrVal, setArrVal] = useState([]);
  const [spreadSheetView, setSpreadSheetView] = useState(true);
  const [reset, setReset] = useState(false);
  const [updateValue, setUpdateValue] = useState({
    value: "",
    row: "",
    column: "",
  });
  const validateUpdateValues = (x) => {
    var check = true;
    const row = updateValue?.row;
    const col = updateValue?.column;
    if (colIndex?.includes("0")) {
      if (check && !updateValue?.value?.split("")?.length > 0) {
        check = false;
        toast(<AlertError message={"Part Number is not allowed to be empty"} />);
      }
    }

    if (colIndex?.includes("2")) {
      if (
        check &&
        updateValue?.value?.split("").length > 0 &&
        !condition?.map((cd) => `${cd.code}`).includes(updateValue?.value)
      ) {
        check = false;
        toast(
          <AlertError
            message={`Value of Condition field is not valid. Valid values are ${condition?.map((jf) => jf).join(", ")}`}
          />,
        );
      }
      if (check && !updateValue?.value) {
        check = false;
        toast(<AlertError message={"Condition is not allowed to be empty"} />);
      }
    }
    if (colIndex?.includes("3")) {
      if (check && updateValue?.value?.split("")?.length > 0 && isNaN(updateValue?.value)) {
        check = false;
        toast(
          <AlertError
            message={`Value of "Quantity" is not valid. It must be in number format. Please make sure not to add any characters or symbols. `}
          />,
        );
      }
      if (check && !updateValue?.value) {
        check = false;
        toast(<AlertError message={"Quantity is not allowed to be empty"} />);
      }
    }
    if (colIndex?.includes("5")) {
      if (check && updateValue?.value?.split("")?.length > 0 && !parseFloat(updateValue?.value)) {
        check = false;
        toast(
          <AlertError
            message={`Value of Unit Price is not valid. It must be in number format. For example, 20.00, 35.50 etc. Please make sure not to add any character or symbol like $. `}
          />,
        );
      }
      if (check && arrVal?.some((val) => isNaN(val))) {
        check = false;
        toast(
          <AlertError
            message={`Value of Unit Price is not valid. It must be in number format. For example, 20.00, 35.50 etc. Please make sure not to add any character or symbol like $. `}
          />,
        );
      }
    }
    if (colIndex?.includes("6")) {
      if (
        check &&
        updateValue?.value?.split("")?.length > 0 &&
        !partLocation?.map((cd) => `${cd?.toLowerCase().trim()}`).includes(updateValue?.value?.toLowerCase().trim())
      ) {
        check = false;
        toast(
          <AlertError
            message={`Value of Location field is not valid. Valid values are ${partLocation
              ?.map((jf) => jf)
              .join(", ")}`}
          />,
        );
      }
    }

    if (colIndex?.includes("7")) {
      if (
        check &&
        updateValue?.value?.split("")?.length > 0 &&
        !partMfr?.map((cd) => `${cd?.toLowerCase().trim()}`).includes(updateValue?.value?.toLowerCase().trim())
      ) {
        check = false;
        toast(
          <AlertError
            message={`Value of Part Manufacturer is not valid. Valid values are ${partMfr?.map((jf) => jf).join(", ")}`}
          />,
        );
      } else if (
        check &&
        updateValue?.value?.split("")?.length > 0 &&
        arrVal?.length > 0 &&
        arrVal?.some(
          (val) => !partMfr?.map((item) => item?.toLowerCase()?.trim())?.includes(val?.toLowerCase()?.trim()),
        )
      ) {
        check = false;
        toast(
          <AlertError
            message={`Value of Part Manufacturer is not valid. Valid values are ${partMfr?.map((jf) => jf).join(", ")}`}
          />,
        );
      }
    }
    if (colIndex?.includes("8")) {
      if (
        check &&
        updateValue?.value?.split("")?.length > 0 &&
        updateValue?.value
          ?.split(",")
          ?.map((dh) => partModels?.map((cd) => `${cd?.toLowerCase().trim()}`).includes(dh?.toLowerCase().trim()))
          ?.includes(false)
      ) {
        check = false;

        toast(
          <AlertError
            message={`Value of Plane Model field is not valid. Valid values are ${partModels
              ?.map((jf) => jf)
              .join(", ")}`}
          />,
        );
      }
    }

    return check;
  };

  useEffect(() => {
    const index = [...new Set(indexArr)];
    const column = [...new Set(colIndex)];
    if (index?.length > 1 && column?.length > 1) {
      if (validateUpdateValues("bulk")) {
        props?.refStart();
        const ids = index?.map((val) => aviationData[val]?._id);
        const col = updateValue?.column;
        const csvHeader = ["Id"];
        if (colIndex?.includes("0")) {
          csvHeader?.push("Part Number");
        }
        if (colIndex?.includes("1")) {
          csvHeader?.push("Part Description");
        }
        if (colIndex?.includes("2")) {
          csvHeader?.push("Condition");
        }
        if (colIndex?.includes("3")) {
          csvHeader?.push("Quantity");
        }
        if (colIndex?.includes("4")) {
          csvHeader?.push("Unit");
        }
        if (colIndex?.includes("5")) {
          csvHeader?.push("Unit Price");
        }
        if (colIndex?.includes("6")) {
          csvHeader?.push("Location");
        }
        if (colIndex?.includes("7")) {
          csvHeader?.push("Part Manufacturer");
        }
        if (colIndex?.includes("8")) {
          csvHeader?.push("Part Model");
        }
        if (colIndex?.includes("9")) {
          csvHeader?.push("Grade");
        }
        if (colIndex?.includes("10")) {
          csvHeader?.push("Serial Number");
        }
        if (colIndex?.includes("11")) {
          csvHeader?.push("AI Product Description");
        }
        if (colIndex?.includes("12")) {
          csvHeader?.push("AI Manufacturer Description");
        }
        if (colIndex?.includes("13")) {
          csvHeader?.push("AI Manufacturer Description");
        }
        if (colIndex?.includes("14")) {
          csvHeader?.push("Notes 1");
        }
        if (colIndex?.includes("15")) {
          csvHeader?.push("Notes 2");
        }
        if (colIndex?.includes("16")) {
          csvHeader?.push("Notes 3");
        }
        if (colIndex?.includes("17")) {
          csvHeader?.push("Notes 4");
        }
        if (colIndex?.includes("18")) {
          csvHeader?.push("Notes 5");
        }
        if (colIndex?.includes("19")) {
          csvHeader?.push("Images");
        }
        const records = ids.map((val, i) => [val, ...column?.map((val) => updateValue?.value)]);
        const payload = {
          selectedCsvHeader: csvHeader,
          records: [csvHeader, ...records],
        };
        props?.updateBulkParts(props?.auctionId, payload, (res) => {
          if (res.status === 200) {
            props.getBulkParts(
              {
                page: page,
                auctionId: props.auctionId,
                sort: "createdAt",
                order: "desc",
              },
              (res) => {
                if (res && res.status == 200) {
                  setReset(!reset);
                  props?.refStop();
                  setIndexArr([]);
                  setArrVal([]);
                  setColIndex([]);
                }
              },
            );
          } else {
            props?.refStop();
            setIndexArr([]);
            setArrVal([]);
            setColIndex([]);
            setReset(!reset);
            toast(
              <AlertError
                message={
                  res.data.message
                    ? res.data.message === '"partDescription" is not allowed to be empty'
                      ? "Part Description is not allowed to be empty"
                      : res.data.message === '"condition" is not allowed to be empty'
                      ? "Condition is not allowed to be empty"
                      : res.data.message === '"quantity" is not allowed to be empty'
                      ? "Quantity is not allowed to be empty"
                      : res.data.message
                    : "Something Went Wrong"
                }
              />,
            );
          }
        });
      } else {
        setReset(!reset);
      }
    }
  }, [colIndex, indexArr]);

  useEffect(() => {
    const row = updateValue?.row;
    const id = aviationData[row]?._id;
    const col = updateValue?.column;
    const payload = {};
    if (colIndex?.includes("0")) {
      payload.partNumber = updateValue?.value?.replace("#", "")?.replace("?", "")?.replace("/", "");
    }
    if (colIndex?.includes("1")) {
      payload.partDescription = updateValue?.value;
    }
    if (colIndex?.includes("2")) {
      payload.condition = updateValue?.value;
    }
    if (colIndex?.includes("3")) {
      payload.quantity = updateValue?.value;
    }
    if (colIndex?.includes("4")) {
      payload.unit = updateValue?.value;
    }
    if (colIndex?.includes("5")) {
      payload.unitPrice = updateValue?.value;
    }
    if (colIndex?.includes("6")) {
      payload.location = updateValue?.value;
    }
    if (colIndex?.includes("7")) {
      payload.partManufacturer = updateValue?.value;
    }
    if (colIndex?.includes("8")) {
      payload.partModels = updateValue?.value ? updateValue?.value?.split(",") : [];
    }
    if (colIndex?.includes("9")) {
      payload.grade = updateValue?.value;
    }
    if (colIndex?.includes("10")) {
      payload.serialNumber = updateValue?.value;
    }
    if (colIndex?.includes("11")) {
      payload.aiProductDescription = updateValue?.value;
    }
    if (colIndex?.includes("12")) {
      payload.aiManufactureDescription = updateValue?.value;
    }
    if (colIndex?.includes("13")) {
      payload.notes1 = updateValue?.value;
    }
    if (colIndex?.includes("14")) {
      payload.notes2 = updateValue?.value;
    }
    if (colIndex?.includes("15")) {
      payload.notes3 = updateValue?.value;
    }
    if (colIndex?.includes("16")) {
      payload.notes4 = updateValue?.value;
    }
    if (colIndex?.includes("17")) {
      payload.notes5 = updateValue?.value;
    }
    if (colIndex?.includes("18")) {
      payload.images = updateValue?.value ? updateValue?.value?.split(",") : [];
    }

    const index = [...new Set(indexArr)];
    const column = [...new Set(colIndex)];
    if (index.length < 2 && updateValue?.row != "" && validateUpdateValues()) {
      props?.refStart();
      props?.updateAviationParts(id, payload, (res) => {
        if (res.status === 200) {
          props?.refStop();
          setColIndex([]);
          setIndexArr([]);
          setArrVal([]);
          props.getBulkParts(
            {
              page: page,
              auctionId: props.auctionId,
              sort: "createdAt",
              order: "desc",
            },
            (res) => {
              if (res && res.status == 200) {
                setReset(!reset);
              }
            },
          );
        } else {
          setColIndex([]);
          setIndexArr([]);
          setArrVal([]);
          toast(
            <AlertError
              message={
                res.data.message
                  ? res.data.message === '"partDescription" is not allowed to be empty'
                    ? "Part Description is not allowed to be empty"
                    : res.data.message === '"condition" is not allowed to be empty'
                    ? "Condition is not allowed to be empty"
                    : res.data.message === '"quantity" is not allowed to be empty'
                    ? "Quantity is not allowed to be empty"
                    : res.data.message === '"partNumber" is not allowed to be empty'
                    ? "Part Number is not allowed to be empty"
                    : res.data.message
                  : "Something Went Wrong"
              }
            />,
          );
          props.getBulkParts(
            {
              page: page,
              auctionId: props.auctionId,
              sort: "createdAt",
              order: "desc",
            },
            (res) => {
              if (res && res.status == 200) {
                setReset(!reset);
              }
            },
          );
          props?.refStop();
        }
      });
    } else {
      if (index?.length > 1 && column?.length < 2) {
        setColIndex([]);
        setIndexArr([]);
        setArrVal([]);
      } else if (index?.length > 1 && column?.length > 1) {
        setColIndex([]);
        setIndexArr([]);
        setArrVal([]);
      } else {
        setColIndex([]);
        setIndexArr([]);
        setArrVal([]);
        setReset(!reset);
      }
    }
  }, [updateValue]);

  useEffect(() => {
    const index = [...new Set(indexArr)];
    const column = [...new Set(colIndex)];
    if (index?.length > 1 && column?.length < 2) {
      if (validateUpdateValues("bulk")) {
        props?.refStart();
        const ids = index?.map((val) => aviationData[val]?._id);
        const col = updateValue?.column;
        const csvHeader = ["Id"];
        if (colIndex?.includes("0")) {
          csvHeader?.push("Part Number");
        }
        if (colIndex?.includes("1")) {
          csvHeader?.push("Part Description");
        }
        if (colIndex?.includes("2")) {
          csvHeader?.push("Condition");
        }
        if (colIndex?.includes("3")) {
          csvHeader?.push("Quantity");
        }
        if (colIndex?.includes("4")) {
          csvHeader?.push("Unit");
        }
        if (colIndex?.includes("5")) {
          csvHeader?.push("Unit Price");
        }
        if (colIndex?.includes("6")) {
          csvHeader?.push("Location");
        }
        if (colIndex?.includes("7")) {
          csvHeader?.push("Part Manufacturer");
        }
        if (colIndex?.includes("8")) {
          csvHeader?.push("Part Model");
        }
        if (colIndex?.includes("9")) {
          csvHeader?.push("Grade");
        }
        if (colIndex?.includes("10")) {
          csvHeader?.push("Serial Number");
        }
        if (colIndex?.includes("11")) {
          csvHeader?.push("AI Product Description");
        }
        if (colIndex?.includes("12")) {
          csvHeader?.push("AI Manufacturer Description");
        }
        if (colIndex?.includes("13")) {
          csvHeader?.push("AI Manufacturer Description");
        }
        if (colIndex?.includes("14")) {
          csvHeader?.push("Notes 1");
        }
        if (colIndex?.includes("15")) {
          csvHeader?.push("Notes 2");
        }
        if (colIndex?.includes("16")) {
          csvHeader?.push("Notes 3");
        }
        if (colIndex?.includes("17")) {
          csvHeader?.push("Notes 4");
        }
        if (colIndex?.includes("18")) {
          csvHeader?.push("Notes 5");
        }
        if (colIndex?.includes("19")) {
          csvHeader?.push("Images");
        }
        const records = ids.map((val, i) => [val, arrVal[i]]);
        const payload = {
          selectedCsvHeader: csvHeader,
          records: [csvHeader, ...records],
        };
        props?.updateBulkParts(props?.auctionId, payload, (res) => {
          if (res.status === 200) {
            props.getBulkParts(
              {
                page: page,
                auctionId: props.auctionId,
                sort: "createdAt",
                order: "desc",
              },
              (res) => {
                if (res && res.status == 200) {
                  setReset(!reset);
                  props?.refStop();
                  setIndexArr([]);
                  setArrVal([]);
                  setColIndex([]);
                }
              },
            );
          } else {
            props?.refStop();
            setIndexArr([]);
            setArrVal([]);
            setColIndex([]);
            setReset(!reset);
            toast(
              <AlertError
                message={
                  res.data.message
                    ? res.data.message === '"partDescription" is not allowed to be empty'
                      ? "Part Description is not allowed to be empty"
                      : res.data.message === '"condition" is not allowed to be empty'
                      ? "Condition is not allowed to be empty"
                      : res.data.message === '"quantity" is not allowed to be empty'
                      ? "Quantity is not allowed to be empty"
                      : res.data.message === '"partNumber" is not allowed to be empty'
                      ? "Part Number is not allowed to be empty"
                      : res.data.message
                    : "Something Went Wrong"
                }
              />,
            );
          }
        });
      } else {
        setReset(!reset);
      }
    }
  }, [indexArr]);

  const toggleImageGalleryModal = () => {
    setImageGalleryModal(!imageGalleryModal);
  };

  const toggleUploadImage = () => {
    setShowUploadImage(!showUploadImage);
  };

  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const handleBulkDelete = () => {
    setLoading(true);
    ref && ref.current && ref.current.continuousStart();
    props?.deleteParts(
      {
        genericParts: aviationParts,
      },
      (res) => {
        if (res.status === 200) {
          props?.getBulkParts(
            {
              page: page,
              auctionId: props.auctionId,
              sort: "createdAt",
              order: "desc",
            },
            (res) => {
              if (res.status === 200) {
                setAvaitionParts([]);
                setReset(!reset);
                setSelectAll(false);
              }
            },
          );
          setLoading(false);
          ref && ref.current && ref.current.complete();
          toggleDeleteModal();
        } else {
          setLoading(false);
          ref && ref.current && ref.current.complete();
          toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        }
      },
    );
  };

  useEffect(() => {
    if (props.auctionId) {
      setEnable(false);
      //all parts api
      props?.getBulkParts({ auctionId: props.auctionId, page: 1, limit: 200 }, (res) => {
        if (res && res?.status == 200) {
          setEnable(true);
        }
      });
    }
  }, [props?.auctionId]);

  const totalCount = props.getAviationDetails?.allAviationPart?.pagination?.totalCount || 0;
  const totalPages = totalCount % 200 === 0 ? totalCount / 200 : totalCount / 200 + 1 || 1;

  useEffect(() => {
    if (enable) {
      for (let i = 1; i <= totalPages; i++) {
        //all parts
        props?.getBulkParts({ auctionId: props.auctionId, page: i, limit: 200 }, (res) => {
          if (res && res?.status == 200) {
            setAllData((pre) => [...pre, ...res.data.data]);
          }
        });
      }
    }
  }, [enable]);
  const reloader = () => {
    setAllData([]);
    for (let i = 1; i <= totalPages; i++) {
      props?.getBulkParts({ auctionId: props.auctionId, page: i, limit: 200 }, (res) => {
        if (res && res?.status == 200) {
          setAllData((pre) => [...pre, ...res.data.data]);
        }
      });
    }
  };

  useEffect(() => {
    if (!showUploadImage) {
      setImageUploaded([]);
    }
  }, [showUploadImage]);

  const handleImageUploader = () => {
    const e = imageUploaded;
    if (imageUploaded.length) {
      setLoadingUpload(true);
      setProgress("uploading");
      props.refStart();
    }
    for (let i = 0; i < imageUploaded.length; i++) {
      const file = imageUploaded[i];

      const fSize = Math.round(file.size / 1048576);
      const fType = file.type;
      const ext = file.name.split(".").pop();
      if (fSize > 25) {
        return (
          toast(<AlertError message="Image size exceeds maximum allowable size. Maximum allowable size is 25MB." />),
          props.refStop(),
          setLoadingUpload(false),
          setProgress("error")
        );
      }
      if (!["image/jpeg", "image/jpg", "image/png"].includes(fType)) {
        return (
          toast(
            <AlertError message="Image is not of correct format and hence cannot be uploaded. Valid image formats are jpeg, jpg, and png." />,
          ),
          props.refStop(),
          setLoadingUpload(false),
          setProgress("error")
        );
      } else {
        const fileName = `parts/images/${props.auctionId}/${file.name}`;
        Storage.put(fileName, file, {
          completeCallback: (event) => {},
          progressCallback: (progress) => {},
          errorCallback: (err) => {
            setLoadingUpload(false);

            toast(<AlertError message={err && err.message ? err.message : "Something went wrong"} />);
          },
        }).then((result) => {
          if (i == imageUploaded?.length - 1) {
            setLoadingUpload(false);
            props.refStop();
            setProgress("completed");
            setImageUploaded([]);
            setTimeout(() => setProgress(""), 800);
            toast(<AlertSuccess message={"Information Saved"} />);
          }
        });
      }
    }
  };

  const toggleUploadPartModal = () => {
    setShowUploadPartModal(!showUploadPartModal);
  };

  const loadAvaitionParts = () => {
    setLoading(true);
    props.getBulkParts({ page: 1, auctionId: props.auctionId, sort: "createdAt", order: "desc" }, (res) => {
      if (res && res.status == 200) {
        setReset(!reset);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    if (!props.loadingMain && props.auctionId) {
      setLoading(true);
      props.getBulkParts(
        {
          page: page,
          auctionId: props.auctionId,
          sort: "createdAt",
          order: "desc",
        },
        (res) => {
          if (res && res.status === 200) {
            setReset(!reset);
            setLoading(false);
          }
        },
      );
    }
  }, [page, props.auctionId]);

  const toggleViewModal = () => {
    setViewModal(!viewModal);
  };

  const checkIfImageExists = (url, callback) => {
    const img = new Image();
    img.src = url;

    if (img.complete) {
      callback(true);
    } else {
      img.onload = () => {
        callback(true);
      };

      img.onerror = () => {
        callback(false);
      };
    }
  };

  useEffect(() => {
    if (!updateAviation && !showUploadPartModal) {
      setCheckedValues([]);
      setUploadHeaders([]);
    }
  }, [updateAviation]);

  useEffect(() => {
    if (!showUploadPartModal) {
      setCheckedValues([]);
      setUploadHeaders([]);
    }
  }, [showUploadPartModal]);

  const aviationData = props.getAviationDetails?.parts?.data || [];

  // const allAviationData = props.getAviationDetails?.allAviationPart?.data || [];

  const aviationPaginationData = props?.getAviationDetails && props?.getAviationDetails?.parts?.pagination;
  const totalPage = Math.ceil(aviationPaginationData?.totalCount / aviationPaginationData?.recordsPerPage);

  const selectKeysFromArray = (array, keyMapping) => {
    // Ensure the input array is not empty
    if (array.length === 0) {
      console.log("Input array is empty.");
      return [];
    }

    // Ensure keyMapping is an object
    if (typeof keyMapping !== "object" || keyMapping === null || Array.isArray(keyMapping)) {
      console.log("keyMapping should be an object.");
      return [];
    }

    // Filter each object in the array to include only the selected keys
    const newArray = array.map((obj) => {
      const newObj = {};
      for (const oldKey in keyMapping) {
        const newKey = keyMapping[oldKey];
        if (obj.hasOwnProperty(oldKey)) {
          newObj[newKey] = obj[oldKey];
        }
      }
      return newObj;
    });

    return newArray;
  };

  const downloadCSV = () => {
    const value = { _id: "Id", partNumber: "Part Number" };
    if (checkedValues?.includes("unitPrice")) {
      value.unitPrice = "Unit Price";
    }
    if (checkedValues?.includes("location")) {
      value.location = "Location";
    }
    if (checkedValues?.includes("certificate")) {
      value.certificate = "Certificate";
    }
    if (checkedValues?.includes("partType")) {
      value.partType = "Part Type";
    }
    if (checkedValues?.includes("planeManufacturer")) {
      value.planeManufacturer = "Plane Manufacturer";
    }
    if (checkedValues?.includes("planeModels")) {
      value.planeModels = "Plane Model";
    }
    if (checkedValues?.includes("partManufacturer")) {
      value.partManufacturer = "Part Manufacturer";
    }
    if (checkedValues?.includes("images")) {
      value.images = "Images";
    }
    value.auctionId = "Listing Id";
    const arrangedData = allData?.map((e) => ({
      ...e,
    }));
    const array = selectKeysFromArray(arrangedData, value);
    if (array?.length > 0) {
      setUploadHeaders(Object.keys(array[0]));
      var csv = unparse(array);

      var csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      var csvURL = null;
      if (navigator.msSaveBlob) {
        csvURL = navigator.msSaveBlob(csvData, "download.csv");
      } else {
        csvURL = window.URL.createObjectURL(csvData);
      }

      var tempLink = document.createElement("a");
      tempLink.href = csvURL;
      tempLink.setAttribute("download", "download.csv");
      tempLink.click();
    } else {
      toast(<AlertError message={"Please select column names to be edited"} />);
    }
  };

  const spreadSheetData = aviationData?.map((val) => [
    val.partNumber,
    val.partDescription,
    val.condition,
    val.quantity,
    val?.unit,
    val.unitPrice,
    val.location,
    val.partManufacturer,
    val.partModels,
    val.grade,
    val.serialNumber,
    val.aiProductDescription,
    val.aiManufactureDescription,
    val.notes1,
    val.notes2,
    val.notes3,
    val.notes4,
    val?.notes5,
    val.images,
  ]);
  useEffect(() => {
    setReset(!reset);
  }, [aviationData?.length > 0, aviationData]);

  return (
    <>
      <LoadingBar height={5} color="#47AD1D" ref={ref} />

      <div class="details-page-widget-block" id={props.id} style={{ marginTop: "10px" }}>
        <div class="page-widget-head d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <div
              class="page-widget-label text-capitalize cursor-pointer"
              onClick={!props.loadingMain && toggleUploadPartModal}
            >
              Parts{" "}
              {!props.loadingMain && aviationPaginationData?.totalCount > 0
                ? `(${aviationPaginationData?.totalCount})`
                : "(0)"}
            </div>
          </div>
          <div class="btn-block">
            {props.loadingMain ? (
              <Skeleton />
            ) : (
              <>
                {aviationParts?.length > 0
                  ? aviationData?.length > 0 && (
                      <a
                        href="javascript:void(0)"
                        className="btn btn-danger btn-sm"
                        onClick={() => {
                          toggleDeleteModal();
                        }}
                      >
                        Delete
                      </a>
                    )
                  : ""}
                <a
                  href="javascript:void(0)"
                  class={`btn btn-sm ${spreadSheetView ? "ptm-green-bg" : "btn-primary"}`}
                  onClick={() => setSpreadSheetView(!spreadSheetView)}
                >
                  {spreadSheetView ? "Table View" : "Spreadsheet View"}
                </a>
                <a href="javascript:void(0)" class="btn ysp btn-sm" onClick={toggleUploadImage}>
                  Upload Images
                </a>
                {/* uncomment if needed */}
                {/* <a href="javascript:void(0)" class="btn btn-primary btn-sm" onClick={toggleUpdateAviation}>
                  Update Aviation Parts
                </a> */}
                <a href="javascript:void(0)" class="btn btn-primary btn-sm" onClick={toggleUploadPartModal}>
                  Upload Parts
                </a>
              </>
            )}
          </div>
        </div>
        <div class={aviationData?.length > 0 && "page-widget-body mt-3"}>
          <div class="table-responsive">
            {spreadSheetView && spreadSheetData?.length > 0 ? (
              <Spreadsheet
                data={spreadSheetData}
                setUpdateValue={setUpdateValue}
                updateValue={updateValue}
                page={page}
                indexArr={indexArr}
                setIndexArr={setIndexArr}
                setArrVal={setArrVal}
                setColIndex={setColIndex}
                colIndex={colIndex}
                reset={reset}
              />
            ) : (
              <table class="large-table">
                {aviationData.length > 0 && (
                  <thead>
                    <tr>
                      {props?.loadingMain || loading ? (
                        ""
                      ) : (
                        <th>
                          <div class="form-check tick-checkbox">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="select-all-bulk-part-delete"
                              checked={selectAll}
                              onChange={async (e) => {
                                if (e.target.checked) {
                                  const data = await aviationData?.map((val) => val._id);
                                  setAvaitionParts(data);
                                  setSelectAll(true);
                                } else {
                                  setAvaitionParts([]);
                                  setSelectAll(false);
                                }
                              }}
                              name="bulk-delete"
                            />
                            <label class="form-check-label" htmlFor="select-all-bulk-part-delete"></label>
                          </div>
                        </th>
                      )}
                      <th className="parts-section-th">
                        <a className={`sort-by`}> Part Number </a>{" "}
                      </th>
                      <th className="parts-section-descp-th">
                        <a className={`sort-by`}> Part Description </a>{" "}
                      </th>
                      <th className="parts-section-th">
                        <a className={`sort-by`}>Condition </a>{" "}
                      </th>
                      <th className="parts-section-th">
                        <span className={`d-flex justify-content-end`}>QTY </span>
                      </th>
                      <th className="parts-section-th">
                        <span className={`d-flex justify-content-end`}>Unit </span>
                      </th>
                      <th className="parts-section-th">
                        <span className={`d-flex justify-content-end`}>Original Unit Price </span>
                      </th>
                      <th className="parts-section-th">
                        <span className={`d-flex justify-content-end`}>Public Unit Price </span>
                      </th>
                      <th className="parts-section-th">
                        <a className={`sort-by`}>Location</a>
                      </th>
                      <th className="parts-section-th">
                        <a className={`sort-by`}>Part Manufacturer</a>
                      </th>
                      <th className="parts-section-th">
                        <a className={`sort-by`}>Part Model</a>
                      </th>
                      <th className="parts-section-th">
                        <a className={`sort-by`}>Grade</a>
                      </th>
                      <th className="parts-section-th">
                        <a className={`sort-by`}>Serial Number</a>
                      </th>
                      <th className="parts-section-th">
                        <a className={`sort-by`}>AI Product Description</a>
                      </th>
                      <th className="parts-section-th">
                        <a className={`sort-by`}>AI Manufacturer Description</a>
                      </th>
                      <th className="parts-section-th">
                        <a className={`sort-by`}>Notes 1</a>
                      </th>
                      <th className="parts-section-th">
                        <a className={`sort-by`}>Notes 2</a>
                      </th>
                      <th className="parts-section-th">
                        <a className={`sort-by`}>Notes 3 </a>
                      </th>{" "}
                      <th className="parts-section-th">
                        <a className={`sort-by`}>Notes 4</a>
                      </th>{" "}
                      <th className="parts-section-th">
                        <a className={`sort-by`}>Notes 5</a>
                      </th>
                      <th className="parts-section-th">
                        <a className={`sort-by`}> Images</a>
                      </th>
                      <th className="parts-section-th">
                        <a className="sort-by"> Listing ID </a>{" "}
                      </th>
                      {/* {props?.loadingMain || loading ? "" : <th style={{ width: "9%", minWidth: "30px" }}></th>} */}
                    </tr>
                  </thead>
                )}
                <tbody>
                  {props.loadingMain || loading
                    ? [1, 2, 3, 4].map((item) => (
                        <tr>
                          <td>
                            <Skeleton />
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                          <td>
                            <Skeleton />
                          </td>{" "}
                          <td>
                            <Skeleton />
                          </td>{" "}
                          <td>
                            <Skeleton />
                          </td>{" "}
                          <td>
                            <Skeleton />
                          </td>{" "}
                          <td>
                            <Skeleton />
                          </td>{" "}
                          <td>
                            <Skeleton />
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                        </tr>
                      ))
                    : aviationData &&
                      aviationData.length > 0 &&
                      aviationData.map((item) => {
                        return (
                          <tr>
                            {props?.loadingMain || loading ? (
                              ""
                            ) : (
                              <td className="parts-section-th">
                                {" "}
                                <div class="form-check tick-checkbox">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id={`bulk-delete-partq-${item._id}`}
                                    name="bulk-delete-partq"
                                    checked={aviationParts?.some((val) => val === item._id)}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        const data = [...aviationParts];
                                        data?.push(item._id);
                                        setAvaitionParts(data);
                                      } else {
                                        setAvaitionParts(aviationParts?.filter((val) => val != item._id));
                                      }
                                    }}
                                  />
                                  <label class="form-check-label" htmlFor={`bulk-delete-partq-${item._id}`}></label>
                                </div>
                              </td>
                            )}
                            <td className="parts-section-th">
                              <span
                                className="aviation-auction link-item d-flex align-items-start"
                                onClick={() => {
                                  setSelectedValue(item);
                                  toggleViewModal();
                                }}
                              >
                                {" "}
                                {item.partNumber ? item.partNumber : ""}
                              </span>
                            </td>
                            <td className="parts-section-descp-th">
                              {" "}
                              {item.partDescription ? item.partDescription : ""}
                            </td>

                            <td className="parts-section-th">
                              {" "}
                              {item.condition ? `(${item.condition}) ${item.conditionTitle}` : ""}
                            </td>
                            <td className="text-end parts-section-th"> {item.quantity ? item.quantity : ""}</td>
                            <td className="text-end parts-section-th"> {item.unit ? item.unit : ""}</td>
                            <td className="text-end parts-section-th">
                              <span>{item.unitPrice ? "$" + twoDecimals(item.unitPrice) : ""}</span>
                            </td>
                            <td className="text-end parts-section-th">
                              <span>{item.unitPrice ? "$" + twoDecimals(item.unitPrice * 1.2) : ""}</span>
                            </td>
                            <td className="parts-section-th"> {item.location ? item.location : ""}</td>

                            <td className="parts-section-th">{item.partManufacturer ? item.partManufacturer : ""}</td>
                            <td className="parts-section-th">
                              {item?.partModels?.length > 0 ? item.partModels?.map((is) => is).join(", ") : ""}
                            </td>
                            <td className="parts-section-th">{item.grade ? item.grade : ""}</td>
                            <td className="parts-section-th">{item.serialNumber ? item.serialNumber : ""}</td>
                            <td className="parts-section-th">
                              {item.aiProductDescription ? item.aiProductDescription : ""}
                            </td>
                            <td className="parts-section-th">
                              {item.aiManufactureDescription ? item.aiManufactureDescription : ""}
                            </td>

                            <td className="parts-section-th">{item.notes1 ? item.notes1 : ""}</td>
                            <td className="parts-section-th">{item.notes2 ? item.notes2 : ""}</td>
                            <td className="parts-section-th">{item.notes3 ? item.notes3 : ""}</td>
                            <td className="parts-section-th">{item.notes4 ? item.notes4 : ""}</td>
                            <td className="parts-section-th">{item.notes5 ? item.notes5 : ""}</td>

                            <td
                              align="center"
                              className={`aviation-auction ${item?.images?.length > 0 ? "link-item" : ""} `}
                              onClick={() => {
                                if (item?.images?.length > 0) {
                                  const a = [];
                                  item?.images?.map((de) => {
                                    checkIfImageExists(
                                      `https://s3.amazonaws.com/${
                                        process.env.REACT_APP_MODE == "development" ? "uploads-dev" : "uploads"
                                      }.salvex.com/public/parts/images/${props?.auctionId}/${de}`,
                                      (exists) => {
                                        if (exists) {
                                          a.push({
                                            original: `https://s3.amazonaws.com/${
                                              process.env.REACT_APP_MODE == "development" ? "uploads-dev" : "uploads"
                                            }.salvex.com/public/parts/images/${props?.auctionId}/${de}`,
                                            thumbnail: `https://s3.amazonaws.com/${
                                              process.env.REACT_APP_MODE == "development" ? "uploads-dev" : "uploads"
                                            }.salvex.com/public/parts/images/${props?.auctionId}/${de}`,
                                          });
                                        } else {
                                          a.push({
                                            original:
                                              "https://media.salvex.com/auction/p/1830012/183001141_641791_mp.jpg",
                                            thumbnail:
                                              "https://media.salvex.com/auction/p/1830012/183001141_641791_mp.jpg",
                                          });
                                        }
                                      },
                                    );
                                  });
                                  setSelecetedImage(a);
                                  setTimeout(() => {
                                    toggleImageGalleryModal();
                                  }, 650);
                                }
                              }}
                            >
                              {item?.images?.length > 0 ? (
                                <img src={imageFill} alt="Image Exists" />
                              ) : (
                                <img src={imageOutLine} alt="Image not exists" />
                              )}
                            </td>
                            <td className="parts-section-th" style={{ textAlign: "left" }}>
                              {" "}
                              {item.auctionId ? item.auctionId : ""}
                            </td>

                            {/* {props?.loadingMain || loading ? (
                            ""
                          ) : (
                            <td>
                              <button
                                onClick={() => {
                                  setBulk(false);
                                  setSelectedValue(item);
                                  toggleDeleteModal();
                                }}
                              >
                                <img src={deletebtn} alt="delete" />
                              </button>
                            </td>
                          )} */}
                          </tr>
                        );
                      })}
                </tbody>
              </table>
            )}
          </div>
          {!props.loadingMain && aviationData.length > 0 && aviationPaginationData?.totalCount > 200 && (
            <div class="table-pagination mt-3">
              <ul class="pagination  justify-content-end">
                <li class="page-item">
                  <a
                    class={`page-link ${page === 1 ? "disabled" : ""}`}
                    href="javascript:void(0)"
                    aria-label="Previous"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      if (page != 1) {
                        if (props.section == "aviationRef") {
                          props.scrollToView();
                        } else {
                          props.setSection("aviationRef");
                        }
                        setPage(page - 1);
                      }
                    }}
                  >
                    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7 13L1 7L7 1" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </a>
                </li>
                <li class="page-item active">
                  <a class="page-link" href="javascript:void(0)">
                    {page}
                  </a>
                </li>
                {totalPage > 1 && page + 1 <= totalPage && (
                  <li class="page-item">
                    <a
                      class="page-link"
                      href="javascript:void(0)"
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        if (props.section == "aviationRef") {
                          props.scrollToView();
                        } else {
                          props.setSection("aviationRef");
                        }
                        setPage(page + 1);
                      }}
                    >
                      {page + 1}
                    </a>
                  </li>
                )}
                <li class="page-item">
                  <a
                    class={`page-link ${page + 1 >= totalPage ? "disabled" : ""}`}
                    href="javascript:void(0)"
                    aria-label="Next"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      if (props.section == "aviationRef") {
                        props.scrollToView();
                      } else {
                        props.setSection("aviationRef");
                      }
                      setPage(page + 1);
                    }}
                  >
                    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 13L7 7L1 1" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
          )}
        </div>
        {viewModal && <ViewDetails show={viewModal} onHide={toggleViewModal} selectedValue={selectedValue} />}

        <UploadParts
          show={showUploadPartModal}
          onHide={toggleUploadPartModal}
          loadData={loadAvaitionParts}
          id={props.auctionId}
          getAllAviationParts={props?.getBulkParts}
          type={uploadHeaders?.length > 0 ? "upload" : ""}
          uploadHeaders={uploadHeaders}
          setUploadHeaders={setUploadHeaders}
          setCheckedValues={setCheckedValues}
          reloader={reloader}
        />
      </div>
      {showDeleteModal && (
        <DeleteModal
          show={showDeleteModal}
          onHide={toggleDeleteModal}
          loadingDelete={loading}
          deleteHandler={handleBulkDelete}
        />
      )}
      {showUploadImage && (
        <UploadImage
          show={showUploadImage}
          onHide={() => setShowUploadImage(false)}
          imageUploaded={imageUploaded}
          setImageUploaded={setImageUploaded}
          loadingUpload={loadingUpload}
          handleImageUploader={handleImageUploader}
          type="aviation"
          progress={progress}
        />
      )}
      {imageGalleryModal && (
        <ImageLargeModal
          show={imageGalleryModal}
          onHide={toggleImageGalleryModal}
          items={selectedImage}
          startIndex={0}
          title={""}
        />
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  deleteBulkAviationParts: (data, callback) => dispatch(deleteBulkAviationParts(data, callback)),
  updateAviationParts: (id, data, callback) => dispatch(updateGenericParts(id, data, callback)),

  //For Generic Parts
  addBulkParts: (id, data, callback) => dispatch(addBulkParts(id, data, callback)),
  updateBulkParts: (id, data, callback) => dispatch(updateBulkParts(id, data, callback)),
  getBulkParts: (id, params, callback) => dispatch(getBulkParts(id, params, callback)),
  deleteParts: (data, callback) => dispatch(deleteParts(data, callback)),
});
const mapStateToProps = (state) => ({
  getAviationDetails: getAviationDetails(state),
  getPartsDetails: getPartsDetails(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Parts));
