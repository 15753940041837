import React, { useEffect, useRef, useState } from "react";
import close from "../../../../include/images/close-12x12.svg";
import { Offcanvas } from "react-bootstrap";
import NextButton from "../../../../common/form/nextButton";
import Select from "react-select";
import { customStyles } from "../../../../common/customStyles/reactSelectStyle";
import { addUser, getUser } from "../../../../store/users";
import { connect } from "react-redux";
import _ from "lodash";
import { getUserList } from "../../../../store/userList";
import pdfIcon from "../../../../include/images/pdf-file-icon.svg";
import DOC from "../../../../include/images/Doc.svg";
import XLS from "../../../../include/images/XLS.svg";
import download from "../../../../include/images/download-circle-icon.svg";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AsyncSelect from "react-select/async";
const AddUserModal = ({ data, errors, loading, addHandler, setData, ...props }) => {
  return (
    <>
      <Offcanvas
        placement="end"
        backdropClassName="upload-documnets-backdrop"
        show={props.show}
        onHide={props.onHide}
        className="offcanvas offcanvas-end border-0 width-950"
        id="verifyDocsOffcanvas"
        enforceFocus={false}
      >
        <Offcanvas.Header className="offcanvas-header">
          <h5 class="offcanvas-title">{props?.selectedItem ? "Edit" : "Add"} Enterprise Contract</h5>
          <button
            type="button"
            class="offcanvas-close-btn d-flex align-items-center justify-content-center"
            data-bs-dismiss="offcanvas"
            onClick={props.onHide}
          >
            <img src={close} alt="" />
          </button>
        </Offcanvas.Header>
        <Offcanvas.Body className="offcanvas-body p-0 ">
          <div class="offcanvas-widget-row ">
            <div class="table-responsive hide-scrollbar mb-3">
              <div className="d-flex add-user-flex">
                <div className="mb-4 add-user-input-width">
                  <label class="form-label" for="firstName">
                    {" "}
                    Enterprise<sup style={{ color: "Red" }}>*</sup>
                  </label>
                  <AsyncSelect
                    cacheOptions
                    defaultOptions={props?.enterpriseOptions}
                    value={data?.enterprise}
                    className="basic-single country-margin select-background"
                    classNamePrefix="select-search country-margin select-background"
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    loadOptions={props.loadEnterpriseOptions}
                    name="enterprise"
                    placeholder="Select Enterprise"
                    isDisabled={props.selectedItem ? true : false}
                    styles={customStyles}
                    onChange={(e) => {
                      const value = { ...data };
                      value.enterprise = e;
                      setData(value);
                    }}
                  />
                  {errors && errors?.enterprise ? <p className="val-error-text">{errors?.enterprise}</p> : ""}
                </div>
                <div className="mb-4 add-user-input-width">
                  <label class="form-label" for="lastName">
                    Status <sup style={{ color: "Red" }}>*</sup>
                  </label>
                  <Select
                    value={data?.status}
                    className="basic-single status-margin select-background"
                    classNamePrefix="select-search status-margin select-background"
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.value}
                    name="statuss"
                    placeholder="Select Status"
                    styles={customStyles}
                    options={props?.statusOption}
                    onChange={(e) => {
                      const value = { ...data };
                      value.status = e;
                      setData(value);
                    }}
                  />
                  {errors && errors?.status ? <p className="val-error-text">{errors?.status}</p> : ""}
                </div>
              </div>
              <div className="d-flex add-user-flex">
                <div className="mb-4 add-user-input-width">
                  <label class="form-label" for="sellerCommisiion">
                    Seller Commission<sup style={{ color: "Red" }}>*</sup>
                  </label>
                  <input
                    type="number"
                    name="sellerCommisiion"
                    className="form-control"
                    value={data?.sellerCommission}
                    onChange={(e) => {
                      const value = { ...data };
                      value.sellerCommission = e.target.value;
                      setData(value);
                    }}
                  />
                  {errors && errors?.sellerCommission ? (
                    <p className="val-error-text">{errors?.sellerCommission}</p>
                  ) : (
                    ""
                  )}
                </div>
                <div className="mb-4 add-user-input-width">
                  <label class="form-label" for="email">
                    Buyer Premium<sup style={{ color: "Red" }}>*</sup>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    value={data?.buyerPremium}
                    onChange={(e) => {
                      const value = { ...data };
                      value.buyerPremium = e.target.value;
                      setData(value);
                    }}
                  />
                  {errors && errors?.buyerPremium ? <p className="val-error-text">{errors?.buyerPremium}</p> : ""}
                </div>
              </div>

              <div className="d-flex add-user-flex">
                <div className="mb-4 add-user-input-width enterprise-contract">
                  <label class="form-label" for="industry">
                    Start Date<sup style={{ color: "Red" }}>*</sup>
                  </label>
                  <DatePicker
                    className="form-control"
                    selected={data?.startDate}
                    onChange={(date) => {
                      const value = { ...data };
                      value.startDate = date;
                      setData(value);
                    }}
                  />
                  {errors && errors?.startDate ? <p className="val-error-text">{errors?.startDate}</p> : ""}
                </div>
                <div className="mb-4 add-user-input-width enterprise-contract">
                  <label class="form-label" for="company">
                    End Date<sup style={{ color: "Red" }}>*</sup>
                  </label>
                  <DatePicker
                    className="form-control"
                    selected={data?.endDate}
                    onChange={(date) => {
                      const value = { ...data };
                      value.endDate = date;
                      setData(value);
                    }}
                  />
                  {errors && errors?.endDate ? <p className="val-error-text">{errors?.endDate}</p> : ""}
                </div>
              </div>

              <div className="d-flex add-user-flex">
                <div className="mb-4 add-user-input-width">
                  <label class="form-label" for="city mb-0">
                    Upload Contract<sup style={{ color: "Red" }}>*</sup>
                  </label>
                  <p style={{ marginBottom: "0.5rem", fontSize: "12px", marginTop: "0.1rem" }}>
                    Document must be in DOC, XLS or PDF
                  </p>

                  <div class="upload-lg-button position-relative">
                    <span class="file-input">
                      <input
                        type="file"
                        id="inputGroupFile02"
                        onChange={(e) => {
                          const value = { ...data };
                          value.doc = e.target.files[0];
                          setData(value);
                        }}
                      />
                      <span id="upload-file-name" class="d-flex align-items-center">
                        {data?.doc ? (
                          <p>
                            {data?.doc?.name
                              ? data?.doc?.name?.length > 20
                                ? data?.doc?.name?.substring(0, 20) + "..."
                                : data?.doc?.name
                              : "Please Select Document"}
                          </p>
                        ) : (
                          <p>Please select any document</p>
                        )}
                      </span>
                    </span>
                  </div>
                  {errors && errors?.doc ? <p className="val-error-text">{errors?.doc}</p> : ""}
                  {data?.doc?.name && !data?.doc?.type ? (
                    <div className="d-flex align-items-center" style={{ marginTop: "20px" }}>
                      <a
                        href={`${process.env.REACT_APP_S3_PRIVATE_DOC_BUCKET}/${data?.doc?.name}`}
                        target="_blank"
                        download={true}
                        class="delete-td-icon d-flex align-items-center justify-content-center"
                        style={{ marginRight: "5px" }}
                      >
                        <img src={download} alt="" />
                      </a>
                      <a
                        href={`${process.env.REACT_APP_S3_PRIVATE_DOC_BUCKET}/${data?.doc?.name}`}
                        target="_blank"
                        download={true}
                      >
                        {" "}
                        {data?.doc?.name ? data?.doc?.name : "Please Select Document"}
                      </a>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <button class="btn btn-link me-md-2 global-cancel-button" type="button" onClick={() => props.onHide()}>
                Cancel
              </button>
              <NextButton
                class="btn btn-default"
                type="button"
                label={"Submit"}
                handleSubmit={() => addHandler()}
                loading={loading}
                disable={loading}
              />
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  addUser: (data, callback) => dispatch(addUser(data, callback)),
  getUserList: (params, callback) => dispatch(getUserList(params, callback)),
});
const mapStateToProps = (state) => ({
  getUser: getUser(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(AddUserModal));
