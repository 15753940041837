import React from "react";

const PaymentTypeOne = ({ setError1, error1, option1Data, setOption1Data, member }) => {
  return (
    <>
      {" "}
      <p>
        <strong>ACH/EFT (U.S. Only)</strong>
      </p>
      <div class=" payment-info-input-margin payment-info-text">
        <label for="" class="form-label fw-medium">
          Company or Individual Name (as is appears on the account)
        </label>
        <p>{option1Data.account_name}</p>
        {/* <input
          type="text"
          class="form-control "
          value={option1Data.account_name}
          onChange={(e) => {
            const error = { ...error1 };
            if (error.account_name) {
              error.account_name = "";
              setError1(error);
            }

            const data = { ...option1Data };
            data.account_name = e.target.value.trim();
            setOption1Data(data);
          }}
        />
        {error1.account_name ? (
          <p style={{ color: "red" }}>{error1.account_name}</p>
        ) : (
          ""
        )} */}
      </div>
      <div class=" payment-info-input-margin payment-info-text">
        <label for="" class="form-label fw-medium">
          Complete Address associated with account
        </label>
        <p>{option1Data.account_address}</p>
        {/* <input
          type="text"
          class="form-control "
          value={option1Data.account_address}
          onChange={(e) => {
            const error = { ...error1 };
            if (error.account_address) {
              error.account_address = "";
              setError1(error);
            }

            const data = { ...option1Data };
            data.account_address = e.target.value.trim();
            setOption1Data(data);
          }}
        />
        {error1.account_address ? <p style={{ color: "red" }}>{error1.account_address}</p> : ""} */}
      </div>
      <div class=" payment-info-input-margin payment-info-text">
        <label for="" class="form-label fw-medium">
          Name of Bank
        </label>
        <p>{option1Data.bank_name}</p>
        {/* <input
          type="text"
          class="form-control "
          value={option1Data.bank_name}
          onChange={(e) => {
            const error = { ...error1 };
            if (error.bank_name) {
              error.bank_name = "";
              setError1(error);
            }

            const data = { ...option1Data };
            data.bank_name = e.target.value.trim();
            setOption1Data(data);
          }}
        />
        {error1.bank_name ? <p style={{ color: "red" }}>{error1.bank_name}</p> : ""} */}
      </div>
      <div class=" payment-info-input-margin payment-info-text">
        <label for="" class="form-label fw-medium">
          Account Number
        </label>
        <p>{option1Data.account_iban_no}</p>
        {/* <input
          type="number"
          class="form-control "
          value={option1Data.account_iban_no}
          onChange={(e) => {
            const error = { ...error1 };
            if (error.account_iban_no) {
              error.account_iban_no = "";
              setError1(error);
            }

            const data = { ...option1Data };
            data.account_iban_no = e.target.value.trim();
            setOption1Data(data);
          }}
        />
        {error1.account_iban_no ? <p style={{ color: "red" }}>{error1.account_iban_no}</p> : ""} */}
      </div>
      <div class=" mt-0 payment-info-input-margin payment-info-text">
        <label for="" class="form-label fw-medium">
          ACH Routing Number
        </label>
        <p>{option1Data.ach_routing_no}</p>
        {/* <input
          type="number"
          class="form-control "
          value={option1Data.ach_routing_no}
          onChange={(e) => {
            const error = { ...error1 };
            if (error.ach_routing_no) {
              error.ach_routing_no = "";
              setError1(error);
            }
            const data = { ...option1Data };
            data.ach_routing_no = e.target.value.trim();
            setOption1Data(data);
          }}
        />
        {error1.ach_routing_no ? <p style={{ color: "red" }}>{error1.ach_routing_no}</p> : ""} */}
      </div>
      <div class=" mt-0 payment-info-input-margin payment-info-text">
        <label for="" class="form-label fw-medium">
          Additional email address to be sent confirmation of payment.
        </label>
        <p>{option1Data.other_email}</p>
        {/* <textarea
          type="text"
          class="w-100 form-control "
          value={option1Data.other_email}
          onChange={(e) => {
            const data = { ...option1Data };
            data.other_email = e.target.value.trim();
            setOption1Data(data);
          }}
        /> */}
      </div>
      <div class=" mt-0 payment-info-input-margin payment-info-text">
        <label for="" class="form-label fw-medium">
          Do you have any additional instructions?
        </label>
        <p>{option1Data.additional_info}</p>
        {/* <textarea
          type="text"
          class="w-100 form-control "
          value={option1Data.additional_info}
          onChange={(e) => {
            const data = { ...option1Data };
            data.additional_info = e.target.value.trim();
            setOption1Data(data);
          }}
        /> */}
      </div>
    </>
  );
};

export default PaymentTypeOne;
