import React, { useRef } from "react";
import LoadingBar from "react-top-loading-bar";
import Header from "../../layouts/header";
import { connect } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import AddPlanePartType from "../../components/settings/planePartType/modals/addPlanePartType";
import AlertSuccess from "../../common/alerts/alertSuccess";
import AlertError from "../../common/alerts/alertError";
import { toast } from "react-toastify";
import FilterModal from "../../components/settings/modals/filterModal";
import {
  changeWeeklyReportListingStatus,
  getweeklyReportListings,
  primarySellerData,
  weeklyReportListingsReceived,
} from "../../store/primarySeller";
import WeeklyAssetReportTable from "../../components/settings/weeklyAssetReport/weeklyAssetReportTable";

const WeeklyAssetRecoveryReport = (props) => {
  const ref = useRef(null);
  const [loading, setLoading] = useState(false);
  const [loadingChange, setLoadingChange] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [page, setPage] = useState(1);
  const [showFilter, setShowFilter] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [auction_id, setAuction_id] = useState("");
  const [status, setStatus] = useState({
    name: "-- Select a Status --",
    value: "",
  });
  const [action, setAction] = useState({
    name: "-- Select an Action --",
    value: "",
  });
  const [response, setResponse] = useState({
    res: false,
    keyword: "",
    auction_id: "",
    status_id: "",
    enable_for_seller_weekly_report: "",
  });
  const [sort, setSort] = useState("title");
  const [order, setOrder] = useState("asc");

  useEffect(() => {
    ref?.current?.continuousStart();
    setLoading(true);
    setPage(1);
    props.getweeklyReportListings(
      {
        page: 1,
        keyword: response?.keyword,
        auction_id: response?.auction_id,
        status_id: response?.status_id,
        enable_for_seller_weekly_report: response?.enable_for_seller_weekly_report,
        sort,
        order,
      },
      (res) => {
        if (res && res.status === 200) {
          ref?.current?.complete();
          setLoading(false);
        } else {
          ref?.current?.complete();
          setLoading(false);
        }
      },
    );
  }, [sort, order]);

  const toggleAddModal = () => {
    setShowAddModal(!showAddModal);
  };

  useEffect(() => {
    ref?.current?.continuousStart();
    setLoading(true);
    props.getweeklyReportListings(
      {
        page: page,
        keyword: response?.keyword,
        auction_id: response?.auction_id,
        status_id: response?.status_id,
        enable_for_seller_weekly_report: response?.enable_for_seller_weekly_report,
        sort,
        order,
      },
      (res) => {
        if (res && res.status === 200) {
          ref?.current?.complete();
          setLoading(false);
        } else {
          ref?.current?.complete();
          setLoading(false);
        }
      },
    );
  }, [page]);

  const handleFilter = () => {
    ref?.current?.continuousStart();
    setLoading(true);
    props.getweeklyReportListings(
      {
        page: 1,
        keyword: keyword,
        auction_id: auction_id,
        status_id: status?.value,
        enable_for_seller_weekly_report: action?.value,
        sort,
        order,
      },
      (res) => {
        setPage(1);
        if (res && res.status === 200) {
          const data = { ...response };
          data.res = true;
          data.keyword = keyword;
          data.auction_id = auction_id;
          data.status_id = status?.value;
          data.enable_for_seller_weekly_report = action?.value;
          setResponse(data);
          ref?.current?.complete();
          setLoading(false);
          toggleFilter();
        } else {
          const data = { ...response };
          data.res = false;
          data.keyword = "";
          data.auction_id = "";
          data.enable_for_seller_weekly_report = "";
          setResponse(data);
          toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
          ref?.current?.complete();
          setLoading(false);
        }
      },
    );
  };
  const clearFilter = (sec) => {
    ref?.current?.continuousStart();
    setLoading(true);
    props.getweeklyReportListings(
      {
        page: 1,
        keyword: sec === "keyword" ? "" : response?.keyword,
        auction_id: sec === "auction_id" ? "" : response?.auction_id,
        status_id: sec === "status_id" ? "" : response?.status_id,
        enable_for_seller_weekly_report:
          sec === "enable_for_seller_weekly_report" ? "" : response?.enable_for_seller_weekly_report,
        sort,
        order,
      },
      (res) => {
        if (res && res.status === 200) {
          const data = { ...response };
          data.res = false;
          data.keyword = sec === "keyword" ? "" : data?.keyword;
          data.auction_id = sec === "auction_id" ? "" : data?.auction_id;
          data.status_id = sec === "status_id" ? "" : data?.status_id;
          data.enable_for_seller_weekly_report =
            sec === "enable_for_seller_weekly_report" ? "" : data?.enable_for_seller_weekly_report;
          setResponse(data);
          setPage(1);
          ref?.current?.complete();
          setLoading(false);
        } else {
          const data = { ...response };
          data.res = true;
          data.keyword = keyword;
          data.auction_id = auction_id;
          setResponse(data);
          ref?.current?.complete();
          setLoading(false);
        }
      },
    );
  };

  const changeListingStatus = (id) => {
    setLoadingChange(true);
    ref?.current?.continuousStart();
    props?.changeWeeklyReportListingStatus(id, (res) => {
      if (res && res?.status == 200) {
        props.getweeklyReportListings(
          {
            page: page,
            keyword: response?.keyword,
            auction_id: response?.auction_id,
            status_id: response?.status_id,
            enable_for_seller_weekly_report: response?.enable_for_seller_weekly_report,
            sort,
            order,
          },
          (res) => {
            if (res && res.status === 200) {
              setLoadingChange(false);
              ref?.current?.complete();
            } else {
              ref?.current?.complete();
              setLoading(false);
            }
          },
        );
      } else {
        ref?.current?.complete();
        setLoadingChange(false);
        <AlertError message={res?.data?.message ? res?.data?.message : "Something went wrong"} />;
      }
    });
  };

  const data = props?.primarySellerData?.weeklyReportListings?.data;
  const totalCount =
    props.primarySellerData &&
    props.primarySellerData?.weeklyReportListings &&
    props.primarySellerData?.weeklyReportListings?.total_records;

  const filteredCount =
    props.primarySellerData &&
    props.primarySellerData?.weeklyReportListings &&
    props.primarySellerData?.weeklyReportListings.per_page;

  const totalPages =
    props.primarySellerData &&
    props.primarySellerData?.weeklyReportListings &&
    props.primarySellerData?.weeklyReportListings.max_pages;

  const statusOptions = [
    { name: "-- Select a Status --", value: "" },
    { name: "Pending", value: "1,2" },
    // { name: "Archived", value: "5" },
    { name: "Active", value: "3,4,5" },
  ];
  const actionOptions = [
    { name: "-- Select an Action--", value: "" },
    { name: "Disabled", value: "0" },
    { name: "Enabled", value: "1" },
  ];

  const toggleFilter = () => {
    setAuction_id(response.auction_id);
    setKeyword(response.keyword);
    setStatus(statusOptions?.find((val) => val.value === response?.status_id));
    setAction(actionOptions?.find((val) => val?.value === response?.enable_for_seller_weekly_report));
    setShowFilter(!showFilter);
  };

  return (
    <>
      <LoadingBar height={5} color="#47AD1D" ref={ref} />
      <Header />

      <WeeklyAssetReportTable
        data={data}
        loading={loading}
        page={page}
        setPage={setPage}
        totalCount={totalCount}
        filteredCount={filteredCount}
        totalPages={totalPages}
        toggleFilter={toggleFilter}
        keyword={keyword}
        setKeyword={setKeyword}
        handleFilter={handleFilter}
        clearFilter={clearFilter}
        response={response}
        sort={sort}
        setSort={setSort}
        order={order}
        setOrder={setOrder}
        changeListingStatus={changeListingStatus}
        loadingchange={loadingChange}
        setAuction_id={setAuction_id}
        setStatus={setStatus}
        setAction={setAction}
        statusOptions={statusOptions}
        actionOptions={actionOptions}
      />

      <FilterModal
        show={showFilter}
        onHide={toggleFilter}
        loading={loading}
        handleFilter={handleFilter}
        keyword={keyword}
        setKeyword={setKeyword}
        auction_id={auction_id}
        setAuction_id={setAuction_id}
        status={status}
        setStatus={setStatus}
        action={action}
        setAction={setAction}
        sellerArr={true}
        statusOptions={statusOptions}
        actionOptions={actionOptions}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  changeWeeklyReportListingStatus: (id, callback) => dispatch(changeWeeklyReportListingStatus(id, callback)),
  getweeklyReportListings: (params, callback) => dispatch(getweeklyReportListings(params, callback)),

  //To change internal state
  weeklyReportListingsReceived: (data) => dispatch(weeklyReportListingsReceived(data)),
});

const mapStateToProps = (state) => ({
  primarySellerData: primarySellerData(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(WeeklyAssetRecoveryReport));
