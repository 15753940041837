import React from "react";
import { useState } from "react";
import Sidebar from "../../../layouts/sidebar";
import AddContract from "./modal/addContract";
import close from "../../../include/images/close-icon.svg";
import emptyFolder from "../../../include/images/folder.svg";
import More from "../../../include/images/more-icon.svg";
import Skeleton from "react-loading-skeleton";
import FilterContract from "./modal/filterContract";
import moment from "moment";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
const ContractsTable = (props) => {
  //For SideNav
  const [showMenu, setShowMenu] = useState(false);
  const [menuList, setMenuList] = useState("");

  const contractList = props.data?.data;
  const totalPages = props?.data?.totalPage;
  const totalCount = props?.data?.totalCount;
  const filteredCount = props?.data?.totalRecordOnCurrentPage;

  return (
    <div class="dashboard-insider position-relative d-lg-flex w-100 ">
      <Sidebar
        showMenu={showMenu}
        setShowMenu={setShowMenu}
        menuList={menuList}
        setMenuList={setMenuList}
        page="legal"
        pageCat="contractor"
      />
      <div className={showMenu ? ` main-menu-expanded ` : "main-menu-compressed"}>
        <div class="filter-container d-flex align-items-center sticky-top">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12 d-flex align-items-center flex-wrap justify-content-between">
                <div class="filter-title fw-medium text-capitalize">Contracts</div>
                <div class="filter-widget-right d-flex align-items-center flex-wrap">
                  <div class="fw-item">
                    <div className="d-flex ">
                      <div class="filter-row d-flex align-items-center me-4" onClick={() => props.toggleFilter()}>
                        <svg viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M2.39767 16.5C2.07751 16.5 1.81796 16.2357 1.81796 15.9097V1.09032C1.81796 0.764295 2.07751 0.5 2.39767 0.5C2.71784 0.5 2.97738 0.764295 2.97738 1.09032V15.9097C2.97738 16.2357 2.71784 16.5 2.39767 16.5Z"
                            fill="currentColor"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M13.0226 15.9097C13.0226 16.2357 13.2822 16.5 13.6023 16.5C13.9225 16.5 14.182 16.2357 14.182 15.9097V1.09032C14.182 0.764295 13.9225 0.5 13.6023 0.5C13.2822 0.5 13.0226 0.764295 13.0226 1.09032V15.9097ZM0.57971 13.18H4.21565C4.53582 13.18 4.79536 12.9158 4.79536 12.5897C4.79536 12.2637 4.53582 11.9994 4.21565 11.9994H0.57971C0.259545 11.9994 0 12.2637 0 12.5897C0 12.9158 0.259545 13.18 0.57971 13.18Z"
                            fill="currentColor"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M7.42029 15.9097C7.42029 16.2357 7.67983 16.5 8 16.5C8.32016 16.5 8.57971 16.2357 8.57971 15.9097V1.09032C8.57971 0.764295 8.32016 0.5 8 0.5C7.67983 0.5 7.42029 0.764295 7.42029 1.09032V15.9097ZM11.7843 13.18H15.4203C15.7405 13.18 16 12.9158 16 12.5897C16 12.2637 15.7405 11.9994 15.4203 11.9994H11.7843C11.4642 11.9994 11.2046 12.2637 11.2046 12.5897C11.2046 12.9158 11.4642 13.18 11.7843 13.18Z"
                            fill="currentColor"
                          />
                          <path
                            d="M9.81798 5.00058H6.18204C5.86187 5.00058 5.60233 4.73629 5.60233 4.41027C5.60233 4.08424 5.86187 3.81995 6.18204 3.81995H9.81798C10.1381 3.81995 10.3977 4.08424 10.3977 4.41027C10.3977 4.73629 10.1381 5.00058 9.81798 5.00058Z"
                            fill="currentColor"
                          />
                        </svg>
                        <span>Filters</span>
                      </div>

                      <div>
                        <button className="btn btn-sm btn-primary" onClick={() => props.toggleAddContractModal()}>
                          Add Contract
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="table-container">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="tb-filter-row d-flex align-items-start justify-content-between">
                  <div class="filter-tags d-flex align-items-start">
                    {!props.showFilter &&
                      (props.selectedFilter?.enterprise?.name ||
                        props.selectedFilter?.businessUnit?.name ||
                        props.selectedFilter?.contractScope?.name ||
                        props.selectedFilter?.contractType?.name ||
                        props.selectedFilter?.contractStatus?.name ||
                        props.selectedFilter?.salvexEntity?.name ||
                        (props.selectedFilter?.startDate?.startFrom && props.selectedFilter?.startDate?.startTo) ||
                        (props.selectedFilter?.expirationDate?.expirationFrom &&
                          props.selectedFilter?.expirationDate?.expirationTo) ||
                        props.selectedFilter?.automaticRenewal ||
                        props.selectedFilter?.exclusivity ||
                        props.selectedFilter?.confidentiality ||
                        props.selectedFilter?.buyerPremiumOrSellerFee ||
                        props.selectedFilter?.nonCircumvention ||
                        props.selectedFilter?.registrationAsVendor) && (
                        <>
                          <div class="filter-tag-title">Filters Applied :</div>
                          <div class="tags">
                            {props.selectedFilter?.enterprise?.name && props.selectedFilter?.enterprise?.id != "-1" && (
                              <span class="badge">
                                Enterprise : <span>{props.selectedFilter?.enterprise?.name}</span>
                                <img
                                  class="tag-close-icon"
                                  src={close}
                                  alt=""
                                  onClick={() => {
                                    const data = { ...props.selectedFilter };
                                    data.enterprise = "";
                                    props.setSelectedFilter(data);
                                  }}
                                />
                              </span>
                            )}
                            {props.selectedFilter?.businessUnit?.name &&
                              props.selectedFilter?.businessUnit?.id != "-1" && (
                                <span class="badge">
                                  BU : <span>{props.selectedFilter?.businessUnit?.name}</span>
                                  <img
                                    class="tag-close-icon"
                                    src={close}
                                    alt=""
                                    onClick={() => {
                                      const data = { ...props.selectedFilter };
                                      data.businessUnit = "";
                                      props.setSelectedFilter(data);
                                    }}
                                  />
                                </span>
                              )}
                            {props.selectedFilter?.contractScope?.name &&
                              props.selectedFilter?.contractScope?.value != "-1" && (
                                <span class="badge">
                                  Contract Scope : <span>{props.selectedFilter?.contractScope?.name}</span>
                                  <img
                                    class="tag-close-icon"
                                    src={close}
                                    alt=""
                                    onClick={() => {
                                      const data = { ...props.selectedFilter };
                                      data.contractScope = "";
                                      props.setSelectedFilter(data);
                                    }}
                                  />
                                </span>
                              )}
                            {props.selectedFilter?.contractType?.name &&
                              props.selectedFilter?.contractType?.value != "-1" && (
                                <span class="badge">
                                  Contract Type : <span>{props.selectedFilter?.contractType?.name}</span>
                                  <img
                                    class="tag-close-icon"
                                    src={close}
                                    alt=""
                                    onClick={() => {
                                      const data = { ...props.selectedFilter };
                                      data.contractType = "";
                                      props.setSelectedFilter(data);
                                    }}
                                  />
                                </span>
                              )}
                            {props.selectedFilter?.contractStatus &&
                              props.selectedFilter?.contractStatus?.value != "-1" && (
                                <span class="badge">
                                  Contract Status : <span>{props.selectedFilter?.contractStatus?.name}</span>
                                  <img
                                    class="tag-close-icon"
                                    src={close}
                                    alt=""
                                    onClick={() => {
                                      const data = { ...props.selectedFilter };
                                      data.contractStatus = "";
                                      props.setSelectedFilter(data);
                                    }}
                                  />
                                </span>
                              )}
                            {props.selectedFilter?.salvexEntity &&
                              props.selectedFilter?.salvexEntity?.value != "-1" && (
                                <span class="badge">
                                  Contract Status : <span>{props.selectedFilter?.salvexEntity?.name}</span>
                                  <img
                                    class="tag-close-icon"
                                    src={close}
                                    alt=""
                                    onClick={() => {
                                      const data = { ...props.selectedFilter };
                                      data.salvexEntity = "";
                                      props.setSelectedFilter(data);
                                    }}
                                  />
                                </span>
                              )}

                            {props.selectedFilter?.startDate?.startFrom && props.selectedFilter?.startDate?.startTo && (
                              <span class="badge">
                                Start Date :{" "}
                                <span>
                                  {moment(props.selectedFilter?.startDate?.startFrom).format("MM/DD/YYYY")}-
                                  {moment(props.selectedFilter?.startDate?.startTo).format("MM/DD/YYYY")}
                                </span>
                                <img
                                  class="tag-close-icon"
                                  src={close}
                                  alt=""
                                  onClick={() => {
                                    const data = { ...props.selectedFilter };
                                    data.startDate = {
                                      startFrom: "",
                                      startTo: "",
                                    };
                                    props.setSelectedFilter(data);
                                  }}
                                />
                              </span>
                            )}
                            {props.selectedFilter?.expirationDate?.expirationFrom &&
                              props.selectedFilter?.expirationDate?.expirationTo && (
                                <span class="badge">
                                  Expiration Date :{" "}
                                  <span>
                                    {moment(props.selectedFilter?.expirationDate?.expirationFrom).format("MM/DD/YYYY")}-
                                    {moment(props.selectedFilter?.expirationDate?.expirationTo).format("MM/DD/YYYY")}
                                  </span>
                                  <img
                                    class="tag-close-icon"
                                    src={close}
                                    alt=""
                                    onClick={() => {
                                      const data = { ...props.selectedFilter };
                                      data.expirationDate = {
                                        expirationFrom: "",
                                        expirationTo: "",
                                      };
                                      props.setSelectedFilter(data);
                                    }}
                                  />
                                </span>
                              )}
                            {props.selectedFilter?.automaticRenewal && (
                              <span class="badge">
                                Automatic Renewal :{" "}
                                <span>{props.selectedFilter?.automaticRenewal === "true" ? "Yes" : "No"}</span>
                                <img
                                  class="tag-close-icon"
                                  src={close}
                                  alt=""
                                  onClick={() => {
                                    const data = { ...props.selectedFilter };
                                    data.automaticRenewal = "";
                                    props.setSelectedFilter(data);
                                  }}
                                />
                              </span>
                            )}
                            {props.selectedFilter?.exclusivity && (
                              <span class="badge">
                                Exclusivity : <span>{props.selectedFilter?.exclusivity === "true" ? "Yes" : "No"}</span>
                                <img
                                  class="tag-close-icon"
                                  src={close}
                                  alt=""
                                  onClick={() => {
                                    const data = { ...props.selectedFilter };
                                    data.exclusivity = "";
                                    props.setSelectedFilter(data);
                                  }}
                                />
                              </span>
                            )}
                            {props.selectedFilter?.buyerPremiumOrSellerFee && (
                              <span class="badge">
                                Buyer Premium / Seller Fee :{" "}
                                <span>{props.selectedFilter?.buyerPremiumOrSellerFee === "true" ? "Yes" : "No"}</span>
                                <img
                                  class="tag-close-icon"
                                  src={close}
                                  alt=""
                                  onClick={() => {
                                    const data = { ...props.selectedFilter };
                                    data.buyerPremiumOrSellerFee = "";
                                    props.setSelectedFilter(data);
                                  }}
                                />
                              </span>
                            )}
                            {props.selectedFilter?.confidentiality && (
                              <span class="badge">
                                Confidentiality :{" "}
                                <span>{props.selectedFilter?.confidentiality === "true" ? "Yes" : "No"}</span>
                                <img
                                  class="tag-close-icon"
                                  src={close}
                                  alt=""
                                  onClick={() => {
                                    const data = { ...props.selectedFilter };
                                    data.confidentiality = "";
                                    props.setSelectedFilter(data);
                                  }}
                                />
                              </span>
                            )}
                            {props.selectedFilter?.nonCircumvention && (
                              <span class="badge">
                                Non-Circumvention :{" "}
                                <span>{props.selectedFilter?.nonCircumvention === "true" ? "Yes" : "No"}</span>
                                <img
                                  class="tag-close-icon"
                                  src={close}
                                  alt=""
                                  onClick={() => {
                                    const data = { ...props.selectedFilter };
                                    data.nonCircumvention = "";
                                    props.setSelectedFilter(data);
                                  }}
                                />
                              </span>
                            )}
                            {props.selectedFilter?.registrationAsVendor && (
                              <span class="badge">
                                Registration As Vendor :{" "}
                                <span>{props.selectedFilter?.registrationAsVendor === "true" ? "Yes" : "No"}</span>
                                <img
                                  class="tag-close-icon"
                                  src={close}
                                  alt=""
                                  onClick={() => {
                                    const data = { ...props.selectedFilter };
                                    data.registrationAsVendor = "";
                                    props.setSelectedFilter(data);
                                  }}
                                />
                              </span>
                            )}
                          </div>
                        </>
                      )}
                  </div>
                  <div class="tb-filter-right d-flex align-items-center justify-content-end">
                    <div class="showing-result-text">
                      {!props.loading && totalCount < 10 ? (
                        `Showing ${totalCount} of ${totalCount} total results`
                      ) : !props.loading && props.page == totalPages ? (
                        `Showing ${totalCount} of ${totalCount} total results`
                      ) : !props.loading &&
                        `Showing ${10 * (props.page - 1) + filteredCount} of ${totalCount} total results` ? (
                        `Showing ${10 * (props.page - 1) + filteredCount} of ${totalCount} total results`
                      ) : (
                        <Skeleton width="300px" height="25px" />
                      )}
                    </div>
                  </div>
                </div>

                <div
                  class={`table-responsive position-relative ${
                    contractList && contractList.length > 0 ? "min-user-table-height" : ""
                  }`}
                >
                  <div class="table-selected-count d-none align-items-center justify-content-between"></div>
                  <table class="table align-middle table-collapse-table">
                    <thead>
                      <tr>
                        <th style={{ width: "22%" }}>
                          <a href="javascript:void(0)" class="sort-by">
                            Enterprise
                          </a>
                        </th>
                        <th style={{ width: "3%" }}></th>
                        <th style={{ width: "13%" }}>
                          <a href="javascript:void(0)" class="sort-by">
                            BU
                          </a>
                        </th>
                        <th style={{ width: "11%" }}>
                          <a
                            href="javascript:void(0)"
                            className={`sort-by ${props.sort === "contractScope" ? "active-column" : ""}`}
                          >
                            Contract Scope
                            <div
                              class={`ascending-icon cursor-pointer ${
                                props.order == "asc" && props.sort == "contractScope" ? "sort-active" : ""
                              }`}
                              onClick={() => {
                                props.setSort("contractScope");
                                props.setOrder("asc");
                              }}
                            ></div>
                            <div
                              class={`descending-icon cursor-pointer ${
                                props.order == "desc" && props.sort == "contractScope" ? "sort-active1" : ""
                              }`}
                              onClick={() => {
                                props.setSort("contractScope");
                                props.setOrder("desc");
                              }}
                            ></div>
                          </a>
                        </th>
                        <th style={{ width: "11%" }}>
                          <a
                            href="javascript:void(0)"
                            className={`sort-by ${props.sort === "contractType" ? "active-column" : ""}`}
                          >
                            Contract Type
                            <div
                              class={`ascending-icon cursor-pointer ${
                                props.order == "asc" && props.sort == "contractType" ? "sort-active" : ""
                              }`}
                              onClick={() => {
                                props.setSort("contractType");
                                props.setOrder("asc");
                              }}
                            ></div>
                            <div
                              class={`descending-icon cursor-pointer ${
                                props.order == "desc" && props.sort == "contractType" ? "sort-active1" : ""
                              }`}
                              onClick={() => {
                                props.setSort("contractType");
                                props.setOrder("desc");
                              }}
                            ></div>
                          </a>
                        </th>
                        <th style={{ width: "11%" }}>
                          <a
                            href="javascript:void(0)"
                            className={`sort-by ${props.sort === "contractStatus" ? "active-column" : ""}`}
                          >
                            Contract Status
                            <div
                              class={`ascending-icon cursor-pointer ${
                                props.order == "asc" && props.sort == "contractStatus" ? "sort-active" : ""
                              }`}
                              onClick={() => {
                                props.setSort("contractStatus");
                                props.setOrder("asc");
                              }}
                            ></div>
                            <div
                              class={`descending-icon cursor-pointer ${
                                props.order == "desc" && props.sort == "contractStatus" ? "sort-active1" : ""
                              }`}
                              onClick={() => {
                                props.setSort("contractStatus");
                                props.setOrder("desc");
                              }}
                            ></div>
                          </a>
                        </th>
                        <th style={{ width: "11%" }}>
                          <a
                            href="javascript:void(0)"
                            className={`sort-by ${props.sort === "startDate" ? "active-column" : ""}`}
                          >
                            Start Date
                            <div
                              class={`ascending-icon cursor-pointer ${
                                props.order == "asc" && props.sort == "startDate" ? "sort-active" : ""
                              }`}
                              onClick={() => {
                                props.setSort("startDate");
                                props.setOrder("asc");
                              }}
                            ></div>
                            <div
                              class={`descending-icon cursor-pointer ${
                                props.order == "desc" && props.sort == "startDate" ? "sort-active1" : ""
                              }`}
                              onClick={() => {
                                props.setSort("startDate");
                                props.setOrder("desc");
                              }}
                            ></div>
                          </a>
                        </th>
                        <th style={{ width: "11%" }}>
                          <a
                            href="javascript:void(0)"
                            className={`sort-by ${props.sort === "expirationDate" ? "active-column" : ""}`}
                          >
                            Expiration Date
                            <div
                              class={`ascending-icon cursor-pointer ${
                                props.order == "asc" && props.sort == "expirationDate" ? "sort-active" : ""
                              }`}
                              onClick={() => {
                                props.setSort("expirationDate");
                                props.setOrder("asc");
                              }}
                            ></div>
                            <div
                              class={`descending-icon cursor-pointer ${
                                props.order == "desc" && props.sort == "expirationDate" ? "sort-active1" : ""
                              }`}
                              onClick={() => {
                                props.setSort("expirationDate");
                                props.setOrder("desc");
                              }}
                            ></div>
                          </a>
                        </th>
                        <th style={{ width: "7%" }}>
                          <a href="javascript:void(0)" class="sort-by">
                            Number of Documents
                          </a>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.loading
                        ? [1, 2, 3, 4, 56, 3].map((item) => (
                            <tr>
                              <td colspan="9">
                                <table class="table2 contracts-bottom-border">
                                  <tr>
                                    <td style={{ width: "22%" }}>
                                      <Skeleton />
                                    </td>
                                    <td style={{ width: "3%" }}>
                                      <Skeleton />
                                    </td>
                                    <td style={{ width: "13%" }}>
                                      <Skeleton />
                                    </td>

                                    <td style={{ width: "11%" }}>
                                      <Skeleton />
                                    </td>
                                    <td style={{ width: "11%" }}>
                                      <Skeleton />
                                    </td>
                                    <td style={{ width: "11%" }}>
                                      <Skeleton />
                                    </td>

                                    <td style={{ width: "11%" }}>
                                      <Skeleton />
                                    </td>
                                    <td style={{ width: "11%" }}>
                                      <Skeleton />
                                    </td>
                                    <td style={{ width: "7%" }}>
                                      <Skeleton />
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          ))
                        : contractList &&
                          contractList?.length > 0 &&
                          contractList.map((item) => (
                            <tr>
                              <td colspan="9">
                                <table class="table2 contracts-bottom-border">
                                  {item?.documents &&
                                    item.documents?.length > 0 &&
                                    item?.documents?.map((it, i) =>
                                      i === 0 ? (
                                        <tr>
                                          <td style={{ width: "22%" }} rowspan={item.documents?.length}>
                                            <div class="td-text-box d-flex align-items-center">
                                              <OverlayTrigger
                                                placement="right"
                                                overlay={
                                                  <Tooltip
                                                    style={{
                                                      position: "fixed",
                                                    }}
                                                  >
                                                    Click here to view Listings
                                                  </Tooltip>
                                                }
                                              >
                                                <span
                                                  className="link-item"
                                                  onClick={() => {
                                                    props.setSelectedItem({
                                                      type: "enterprise",
                                                      ...it,
                                                    });
                                                    props.toggleShowListing();
                                                  }}
                                                >
                                                  {item?.name ? item?.name : "N/A"}
                                                </span>
                                              </OverlayTrigger>{" "}
                                              {item?.proc_logo ? (
                                                <div class="td-img proc-logo-contract">
                                                  <img
                                                    src={
                                                      process.env.REACT_APP_MEDIA_URL + "/co/logos/" + item.proc_logo
                                                    }
                                                    alt=""
                                                  />
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </td>
                                          <td style={{ width: "3%" }}>
                                            <div class="td-action-btn-group d-flex align-items-center justify-content-between">
                                              <div class="dropdown">
                                                <a
                                                  class="td-a-icon dropdown-toggle"
                                                  data-bs-toggle="dropdown"
                                                  aria-expanded="false"
                                                >
                                                  <img src={More} alt="" />
                                                </a>
                                                <ul class="dropdown-menu ms-1">
                                                  <li className="menu-item">
                                                    <a
                                                      class="dropdown-item customized-drpdown-btn"
                                                      href="javascript:void(0)"
                                                      onClick={() => {
                                                        props.setSelectedItem(it);
                                                        props.toggleShowMoreDetails();
                                                      }}
                                                    >
                                                      View Details
                                                    </a>
                                                  </li>
                                                  <li className="menu-item">
                                                    <a
                                                      class="dropdown-item customized-drpdown-btn"
                                                      href="javascript:void(0)"
                                                      onClick={() => {
                                                        props.setSelectedItem({
                                                          type: "bu",
                                                          ...it,
                                                        });
                                                        props.toggleShowListing();
                                                      }}
                                                    >
                                                      View Listings
                                                    </a>
                                                  </li>
                                                  <li className="menu-item">
                                                    <a
                                                      class="dropdown-item customized-drpdown-btn"
                                                      href="javascript:void(0)"
                                                      onClick={() => {
                                                        props.setSelectedItem({
                                                          mode: "view",
                                                          ...it,
                                                        });
                                                        props.toggleDocModal();
                                                      }}
                                                    >
                                                      View Documents
                                                    </a>
                                                  </li>
                                                  <li className="menu-item">
                                                    <a
                                                      class="dropdown-item customized-drpdown-btn"
                                                      href="javascript:void(0)"
                                                      onClick={() => {
                                                        props.setSelectedItem({
                                                          mode: "add",
                                                          ...it,
                                                        });
                                                        props.toggleDocModal();
                                                      }}
                                                    >
                                                      Add Document
                                                    </a>
                                                  </li>
                                                  <li
                                                    className="menu-item"
                                                    data-bs-toggle="offcanvas"
                                                    data-bs-target="#offcanvasRight"
                                                    aria-controls="offcanvasRight"
                                                  >
                                                    <a
                                                      class="dropdown-item customized-drpdown-btn"
                                                      href="javascript:void(0)"
                                                      onClick={() => {
                                                        props.setSelectedItem(it);
                                                        props.toggleAddContractModal();
                                                      }}
                                                    >
                                                      Edit Details
                                                    </a>
                                                  </li>

                                                  <li className="menu-item">
                                                    <a
                                                      class="dropdown-item customized-drpdown-btn"
                                                      href="javascript:void(0)"
                                                      onClick={() => {
                                                        props.setSelectedItem(it);
                                                        props.toggleDeleteModal();
                                                      }}
                                                    >
                                                      Delete
                                                    </a>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          </td>
                                          <td
                                            style={{ width: "13%" }}
                                            className="link-item"
                                            onClick={() => {
                                              props.setSelectedItem({
                                                type: "bu",
                                                ...it,
                                              });
                                              props.toggleShowListing();
                                            }}
                                          >
                                            <OverlayTrigger
                                              placement="right"
                                              overlay={
                                                <Tooltip style={{ position: "fixed" }}>
                                                  Click here to view Listings
                                                </Tooltip>
                                              }
                                            >
                                              <span>{it?.businessUnit?.title ? it?.businessUnit?.title : "N/A"}</span>
                                            </OverlayTrigger>
                                          </td>

                                          <td style={{ width: "11%" }}>
                                            {it?.contractScope
                                              ? props.contractScopeOption.filter(
                                                  (cd) => it?.contractScope === cd.value,
                                                )?.[0]?.name
                                              : "N/A"}
                                          </td>
                                          <td style={{ width: "11%" }}>
                                            {it?.contractType
                                              ? props.contractTypeOptions.filter(
                                                  (cd) => it?.contractType === cd.value,
                                                )?.[0]?.name
                                              : "N/A"}
                                          </td>
                                          <td style={{ width: "11%" }}>
                                            {it?.contractStatus
                                              ? props.contractStatusOptions.filter(
                                                  (cd) => it?.contractStatus === cd.value,
                                                )?.[0]?.name
                                              : "N/A"}
                                          </td>
                                          <td style={{ width: "11%" }}>
                                            {it?.startDate ? moment(it?.startDate).format("MM/DD/YYYY") : "N/A"}
                                          </td>
                                          <td style={{ width: "11%" }}>
                                            {it?.expirationDate
                                              ? moment(it?.expirationDate).format("MM/DD/YYYY")
                                              : "N/A"}
                                          </td>
                                          <td
                                            style={{ width: "7%" }}
                                            className="link-item"
                                            onClick={() => {
                                              props.setSelectedItem({
                                                mode: "view",
                                                ...it,
                                              });
                                              props.toggleDocModal();
                                            }}
                                          >
                                            {it?.files ? it?.files?.length : "0"}
                                          </td>
                                        </tr>
                                      ) : (
                                        <tr>
                                          <td style={{ width: "3%" }}>
                                            <div class="td-action-btn-group d-flex align-items-center justify-content-between">
                                              <div class="dropdown">
                                                <a
                                                  class="td-a-icon dropdown-toggle"
                                                  data-bs-toggle="dropdown"
                                                  aria-expanded="false"
                                                >
                                                  <img src={More} alt="" />
                                                </a>
                                                <ul class="dropdown-menu ms-1">
                                                  <li className="menu-item">
                                                    <a
                                                      class="dropdown-item customized-drpdown-btn"
                                                      href="javascript:void(0)"
                                                      onClick={() => {
                                                        props.setSelectedItem(it);
                                                        props.toggleShowMoreDetails();
                                                      }}
                                                    >
                                                      View Details
                                                    </a>
                                                  </li>
                                                  <li className="menu-item">
                                                    <a
                                                      class="dropdown-item customized-drpdown-btn"
                                                      href="javascript:void(0)"
                                                      onClick={() => {
                                                        props.setSelectedItem({
                                                          type: "bu",
                                                          ...it,
                                                        });
                                                        props.toggleShowListing();
                                                      }}
                                                    >
                                                      View Listings
                                                    </a>
                                                  </li>
                                                  <li className="menu-item">
                                                    <a
                                                      class="dropdown-item customized-drpdown-btn"
                                                      href="javascript:void(0)"
                                                      onClick={() => {
                                                        props.setSelectedItem({
                                                          mode: "view",
                                                          ...it,
                                                        });
                                                        props.toggleDocModal();
                                                      }}
                                                    >
                                                      View Documents
                                                    </a>
                                                  </li>
                                                  <li className="menu-item">
                                                    <a
                                                      class="dropdown-item customized-drpdown-btn"
                                                      href="javascript:void(0)"
                                                      onClick={() => {
                                                        props.setSelectedItem({
                                                          mode: "add",
                                                          ...it,
                                                        });
                                                        props.toggleDocModal();
                                                      }}
                                                    >
                                                      Add Document
                                                    </a>
                                                  </li>
                                                  <li
                                                    className="menu-item "
                                                    data-bs-toggle="offcanvas"
                                                    data-bs-target="#offcanvasRight"
                                                    aria-controls="offcanvasRight"
                                                  >
                                                    <a
                                                      class="dropdown-item customized-drpdown-btn"
                                                      href="javascript:void(0)"
                                                      onClick={() => {
                                                        props.setSelectedItem(it);
                                                        props.toggleAddContractModal();
                                                      }}
                                                    >
                                                      Edit Details
                                                    </a>
                                                  </li>

                                                  <li className="menu-item">
                                                    <a
                                                      class="dropdown-item customized-drpdown-btn"
                                                      href="javascript:void(0)"
                                                      onClick={() => {
                                                        props.setSelectedItem(it);
                                                        props.toggleDeleteModal();
                                                      }}
                                                    >
                                                      Delete
                                                    </a>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          </td>
                                          <td
                                            style={{ width: "13%" }}
                                            className="link-item"
                                            onClick={() => {
                                              props.setSelectedItem({
                                                type: "bu",
                                                ...it,
                                              });
                                              props.toggleShowListing();
                                            }}
                                          >
                                            <OverlayTrigger
                                              placement="right"
                                              overlay={
                                                <Tooltip style={{ position: "fixed" }}>
                                                  Click here to view Listings
                                                </Tooltip>
                                              }
                                            >
                                              <span>{it?.businessUnit?.title ? it?.businessUnit?.title : "N/A"}</span>
                                            </OverlayTrigger>
                                          </td>

                                          <td style={{ width: "11%" }}>
                                            {it?.contractScope
                                              ? props.contractScopeOption.filter(
                                                  (cd) => it?.contractScope === cd.value,
                                                )?.[0]?.name
                                              : "N/A"}
                                          </td>
                                          <td style={{ width: "11%" }}>
                                            {it?.contractType
                                              ? props.contractTypeOptions.filter(
                                                  (cd) => it?.contractType === cd.value,
                                                )?.[0]?.name
                                              : "N/A"}
                                          </td>
                                          <td style={{ width: "11%" }}>
                                            {it?.contractStatus
                                              ? props.contractStatusOptions.filter(
                                                  (cd) => it?.contractStatus === cd.value,
                                                )?.[0]?.name
                                              : "N/A"}
                                          </td>
                                          <td style={{ width: "11%" }}>
                                            {it?.startDate ? moment(it?.startDate).format("MM/DD/YYYY") : "N/A"}
                                          </td>
                                          <td style={{ width: "11%" }}>
                                            {it?.expirationDate
                                              ? moment(it?.expirationDate).format("MM/DD/YYYY")
                                              : "N/A"}
                                          </td>
                                          <td
                                            style={{ width: "7%" }}
                                            className="link-item"
                                            onClick={() => {
                                              props.setSelectedItem({
                                                mode: "view",
                                                ...it,
                                              });
                                              props.toggleDocModal();
                                            }}
                                          >
                                            {it?.files ? it?.files?.length : "0"}
                                          </td>
                                        </tr>
                                      ),
                                    )}
                                </table>
                              </td>
                            </tr>
                          ))}
                    </tbody>
                  </table>
                </div>
                {!props.loading && contractList && contractList.length === 0 && (
                  <>
                    <div class="table-empty">
                      <div class="table-empty-inner">
                        <img src={emptyFolder} alt="" />
                        <p>No Items Found</p>
                      </div>
                    </div>
                  </>
                )}

                <div class="table-pagination">
                  <ul class="pagination  justify-content-end">
                    <li class="page-item">
                      <a
                        class={`page-link ${props.page === 1 ? "disabled" : ""}`}
                        href="#"
                        aria-label="Previous"
                        onClick={() => {
                          if (props.page !== 1) {
                            props.setPage(props.page - 1);
                          }
                        }}
                      >
                        <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7 13L1 7L7 1" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </a>
                    </li>
                    <li class="page-item active">
                      <a class="page-link" href="javascript:void(0)">
                        {props.page}
                      </a>
                    </li>
                    {totalPages > 1 && props.page + 1 <= totalPages && (
                      <li class="page-item">
                        <a class="page-link" href="#" onClick={() => props.setPage(props.page + 1)}>
                          {props.page + 1}
                        </a>
                      </li>
                    )}
                    <li class="page-item">
                      <a
                        class={`page-link ${props.page + 1 >= totalPages ? "disabled" : ""}`}
                        href="#"
                        aria-label="Next"
                        onClick={() => props.setPage(props.page + 1)}
                      >
                        <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 13L7 7L1 1" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AddContract
          show={props.addContractModal}
          onHide={props.toggleAddContractModal}
          setData={props.setContractValues}
          data={props.contractValues}
          loadEnterpriseOptions={props.loadEnterpriseOptions}
          loadBuOptions={props.loadBuOptions}
          buOptions={props.buOptions}
          addContract={props.addContract}
          loading={props.loading}
          errors={props.errors}
          entityOptions={props.entityOptions}
          contractScopeOption={props.contractScopeOption}
          contractTypeOptions={props.contractTypeOptions}
          contractStatusOptions={props.contractStatusOptions}
          selectedItem={props.selectedItem}
          registrationStatusOptions={props.registrationStatusOptions}
        />
        <FilterContract
          show={props.showFilter}
          onHide={props.toggleFilter}
          data={contractList}
          selectedFilter={props.selectedFilter}
          setSelectedFilter={props.setSelectedFilter}
          handleFilter={props.handleFilter}
          loadingFilter={props.loading}
          entityOptions={props.entityOptions}
          contractScopeOption={props.contractScopeOption}
          contractTypeOptions={props.contractTypeOptions}
          contractStatusOptions={props.contractStatusOptions}
          loadEnterpriseOptions={props.loadEnterpriseOptions}
          loadBuOptions={props.loadBuOptions}
          buOptions={props.buOptions}
        />
      </div>
    </div>
  );
};

export default ContractsTable;
