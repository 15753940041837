import React from "react";

const PaymentTypeThree = ({
  setError3,
  error3,
  option3Data,
  setOption3Data,
  member,
}) => {
  return (
    <>
      <p>
        <strong>International Wire Transfer (Outside U.S.)</strong>
      </p>
      <div class="form-group mt-0">
        <div class="form-group-row d-md-flex flex-md-wrap">
          <div class="form-group-column flex-fill">
            <label for="" class="form-label">
              Beneficiary
              <sup style={{ color: "red" }}>*</sup>
            </label>
            <input
              type="text"
              class="form-control payment-info-input-margin"
              value={option3Data.account_name}
              onChange={(e) => {
                const error = { ...error3 };
                if (error.account_name) {
                  error.account_name = "";
                  setError3(error);
                }

                const data = { ...option3Data };
                data.account_name = e.target.value.trim();
                setOption3Data(data);
              }}
            />
            {error3.account_name ? (
              <p style={{ color: "red" }}>{error3.account_name}</p>
            ) : (
              ""
            )}
          </div>
          <div class="form-group-column flex-fill">
            <label for="" class="form-label">
              Beneficiary Address (street, city, state and zip ){" "}
              <sup style={{ color: "red" }}>*</sup>
            </label>
            <input
              type="text"
              class="form-control payment-info-input-margin"
              value={option3Data.account_address}
              onChange={(e) => {
                const error = { ...error3 };
                if (error.account_address) {
                  error.account_address = "";
                  setError3(error);
                }

                const data = { ...option3Data };
                data.account_address = e.target.value.trim();
                setOption3Data(data);
              }}
            />
            {error3.account_address ? (
              <p style={{ color: "red" }}>{error3.account_address}</p>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div class="form-group mt-0">
        <div class="form-group-row d-md-flex flex-md-wrap">
          <div class="form-group-column flex-fill">
            <label for="" class="form-label">
              Beneficiary IBAN and/or Account Number{" "}
              <sup style={{ color: "red" }}>*</sup>
            </label>
            <input
              type="text"
              class="form-control payment-info-input-margin"
              value={option3Data.account_iban_no}
              onChange={(e) => {
                const error = { ...error3 };
                if (error.account_iban_no) {
                  error.account_iban_no = "";
                  setError3(error);
                }
                const data = { ...option3Data };
                data.account_iban_no = e.target.value.trim();
                setOption3Data(data);
              }}
            />
            {error3.account_iban_no ? (
              <p style={{ color: "red" }}>{error3.account_iban_no}</p>
            ) : (
              ""
            )}
          </div>
          <div class="form-group-column flex-fill">
            <label for="" class="form-label">
              SWIFT Code (or ABA number for U.S. accounts){" "}
              <sup style={{ color: "red" }}>*</sup>
            </label>
            <input
              type="text"
              class="form-control payment-info-input-margin"
              value={option3Data.aba_swift_no}
              onChange={(e) => {
                const error = { ...error3 };
                if (error.aba_swift_no) {
                  error.aba_swift_no = "";
                  setError3(error);
                }
                const data = { ...option3Data };
                data.aba_swift_no = e.target.value.trim();
                setOption3Data(data);
              }}
            />
            {error3.aba_swift_no ? (
              <p style={{ color: "red" }}>{error3.aba_swift_no}</p>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div class="form-group mt-0">
        <div class="form-group-row d-md-flex flex-md-wrap">
          <div class="form-group-column flex-fill">
            <label for="" class="form-label">
              Beneficiary Bank Name <sup style={{ color: "red" }}>*</sup>
            </label>
            <input
              type="text"
              class="form-control payment-info-input-margin"
              value={option3Data.bank_name}
              onChange={(e) => {
                const error = { ...error3 };
                if (error.bank_name) {
                  error.bank_name = "";
                  setError3(error);
                }
                const data = { ...option3Data };
                data.bank_name = e.target.value.trim();
                setOption3Data(data);
              }}
            />
            {error3.bank_name ? (
              <p style={{ color: "red" }}>{error3.bank_name}</p>
            ) : (
              ""
            )}
          </div>
          <div class="form-group-column flex-fill">
            <label for="" class="form-label">
              Beneficiary Bank Address (including country){" "}
              <sup style={{ color: "red" }}>*</sup>
            </label>
            <input
              type="text"
              class="form-control payment-info-input-margin"
              value={option3Data.bank_address}
              onChange={(e) => {
                const error = { ...error3 };
                if (error.bank_address) {
                  error.bank_address = "";
                  setError3(error);
                }
                const data = { ...option3Data };
                data.bank_address = e.target.value.trim();
                setOption3Data(data);
              }}
            />
            {error3.bank_address ? (
              <p style={{ color: "red" }}>{error3.bank_address}</p>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div class="form-group mt-0">
        <p>
          <strong>What type of reference number do you prefer?</strong>
        </p>
        <div class="four-column-radio-group fw-medium payment-info-input-margin">
          <div class="fcrg-item payment-info-main-option">
            <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
              <input
                class="form-check-input payment-info-input-margin"
                type="radio"
                name="RflowRadioDefault"
                id="RflowRadio2__1"
                checked={option3Data.preferred_ref_no == "1"}
                onChange={() => {
                  const data = { ...option3Data };
                  data.preferred_ref_no = "1";
                  setOption3Data(data);
                }}
              />
              <label class="form-check-label" for="RflowRadio2__1">
                Claim or File Number
              </label>
            </div>
          </div>
          <div class="fcrg-item payment-info-main-option">
            <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
              <input
                class="form-check-input"
                type="radio"
                name="RflowRadioDefault"
                id="RflowRadio2__2"
                checked={option3Data.preferred_ref_no == "2"}
                onChange={() => {
                  const data = { ...option3Data };
                  data.preferred_ref_no = "2";
                  setOption3Data(data);
                }}
              />
              <label class="form-check-label" for="RflowRadio2__2">
                Invoice Number
              </label>
            </div>
          </div>
          <div class="fcrg-item payment-info-main-option">
            <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
              <input
                class="form-check-input"
                type="radio"
                name="RflowRadioDefault"
                id="RflowRadio2__3"
                checked={option3Data.preferred_ref_no == "3"}
                onChange={() => {
                  const data = { ...option3Data };
                  data.preferred_ref_no = "3";
                  setOption3Data(data);
                }}
              />
              <label class="form-check-label" for="RflowRadio2__3">
                Shipping Container Number
              </label>
            </div>
          </div>
          <div class="fcrg-item payment-info-main-option">
            <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
              <input
                class="form-check-input"
                type="radio"
                name="RflowRadioDefault"
                id="RflowRadio2__4"
                checked={option3Data.preferred_ref_no == "4"}
                onChange={() => {
                  const data = { ...option3Data };
                  data.preferred_ref_no = "4";
                  setOption3Data(data);
                }}
              />
              <label class="form-check-label" for="RflowRadio2__4">
                Salvex Listing number is fine
              </label>
            </div>
          </div>
        </div>
        {option3Data.preferred_ref_no ? (
          <p
            className="cursor-pointer"
            style={{ color: "rgba(0,0,0,0.4)", marginTop: "7px" }}
            onClick={() => {
              const data = { ...option3Data };
              data.preferred_ref_no = "";
              setOption3Data(data);
            }}
          >
            Clear Selection
          </p>
        ) : (
          ""
        )}
      </div>
      <div class="form-group mt-0">
        <p className="mb-2">
          <strong>
            If you have intermediary bank information, please enter it here.
          </strong>
        </p>
        <input
          type="text"
          class="form-control payment-info-input-margin"
          value={option3Data.intermediary_bank_info}
          onChange={(e) => {
            const data = { ...option3Data };
            data.intermediary_bank_info = e.target.value.trim();
            setOption3Data(data);
          }}
        />
      </div>
      <div class="form-group">
        <label for="" class="form-label">
          Additional email address to be sent confirmation of payment.
        </label>
        <textarea
          type="text"
          class="w-100 form-control payment-info-input-margin"
          value={option3Data.other_email}
          onChange={(e) => {
            const data = { ...option3Data };
            data.other_email = e.target.value.trim();
            setOption3Data(data);
          }}
        />
      </div>
      <div class="form-group mt-0">
        <label for="" class="form-label">
          Do you have any additional instructions?
        </label>
        <textarea
          type="text"
          class="w-100 form-control payment-info-input-margin"
          value={option3Data.additional_info}
          onChange={(e) => {
            const data = { ...option3Data };
            data.additional_info = e.target.value.trim();
            setOption3Data(data);
          }}
        />
      </div>
    </>
  );
};

export default PaymentTypeThree;