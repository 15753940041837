import Router from "./Routes";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import configureStore from "./store/configureStore";
import AlertError from "./common/alerts/alertError";
import AlertClose from "./common/alerts/alertClose";
import HelmetComponent from "./common/helmetComponent/helmetComponent";
import ErrorBoundary from "./common/errorBoundary/errorBoundary";
import ReminderMessages from "./layouts/modals/reminderMessages/reminderMessages";
import { useState } from "react";

function App() {
  const store = configureStore();
  const [showReminerMessages, setShowReminderMessages] = useState(false);

  return (
    <Provider store={store}>
      <ReminderMessages show={showReminerMessages} onHide={() => setShowReminderMessages((prev) => !prev)} />
      <HelmetComponent />
      <ToastContainer
        hideProgressBar
        autoClose={5000}
        toastClassName="alert alert-success alert-white"
        closeButton={<AlertClose />}
      />
      <ErrorBoundary>
        <Router />
      </ErrorBoundary>
    </Provider>
  );

  // </Provider>
}

export default App;
