import React, { useEffect, useState } from "react";
import { dashboardData, getListingAgreement } from "../../../store/dashBoard";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import AlertError from "../../../common/alerts/alertError";
import Skeleton from "react-loading-skeleton";
import DateFormat from "../../../common/DateFormat/dateFormat";
import NextButton from "../../../common/form/nextButton";

const ListingAgreement = (props) => {
  const detail = props?.dashboardData?.listingAgreement?.data;
  const totalCount = props.dashboardData?.listingAgreement?.total_records;
  const [show, setShow] = useState(false);
  const [contentLoaded, setContentLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [enable, setEnable] = useState(false);
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [sort, setSort] = useState("createdAt");
  const [order, setOrder] = useState("desc");

  //Resetting the Params
  useEffect(() => {
    setContentLoaded(false);
    setSort("createdAt");
    setPage(1);
    setOrder("desc");
    setShow(false);
  }, [props.loadingMain]);

  const fetchListingAgreement = () => {
    if (contentLoaded) {
      setShow(true);
    } else {
      setLoading(true);
      props.refStart();
      props.getListingAgreement({ keyword, page, sort, order }, (res) => {
        if (res.status === 200) {
          props.refStop();
          setLoading(false);
          setContentLoaded(true);
          setShow(true);
        } else {
          props.refStop();
          setLoading(false);
          setShow(false);
          toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        }
      });
    }
  };

  //Searching
  const searching = () => {
    setLoading(true);
    setSort("createdAt");
    setPage(1);
    setOrder("desc");
    props.refStart();
    props.getListingAgreement({ keyword, page, sort, order }, (res) => {
      if (res.status === 200) {
        props.refStop();
        setLoading(false);
      } else {
        props.refStop();
        setLoading(false);
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  //Pagination, Sort and Order
  useEffect(() => {
    const pagination = () => {
      setLoading(true);
      props.refStart();
      props.getListingAgreement({ keyword, page, sort, order }, (res) => {
        if (res.status === 200) {
          props.refStop();
          setLoading(false);
        } else {
          props.refStop();
          setLoading(false);
          toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        }
      });
    };
    if (enable) {
      pagination();
    }
  }, [page, sort, order]);
  return (
    <div class="details-page-widget-block">
      <div class="page-widget-head d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <div
            class="page-widget-label text-capitalize cursor-pointer"
            onClick={() => {
              show ? setShow(false) : fetchListingAgreement();
            }}
          >
            Listing Agreements{!props.loadingMain && `(${props.count ? props.count : "0"})`}
          </div>
        </div>
        {show && (
          <div className="d-flex">
            <div style={{ width: "400px" }} className="header-search-box me-3">
              <input
                type="search"
                className="w-100"
                placeholder="Enter Keywords"
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    searching();
                  }
                }}
              />
            </div>
            <NextButton label="Search" classData="btn btn-default" handleSubmit={searching} loading={loading} />
          </div>
        )}
      </div>
      <div class="page-widget-body mt-3">
        {props.loadingMain ? (
          <Skeleton height="50px" />
        ) : show ? (
          <>
            <div class="table-responsive table-custom">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>SR#</th>
                    <th>Listing ID</th>
                    <th>
                      <a
                        href="javascript:void(0)"
                        class="sort-by"
                        onClick={() => {
                          setEnable(true);
                          setSort("auctionTitle");
                        }}
                      >
                        Listing Title
                        <div
                          class={
                            sort === "auctionTitle" && order === "asc" ? "ascending-icon active" : "ascending-icon"
                          }
                          onClick={() => setOrder("asc")}
                        ></div>
                        <div
                          class={
                            sort === "auctionTitle" && order === "desc" ? "descending-icon active" : "descending-icon"
                          }
                          onClick={() => setOrder("desc")}
                        ></div>
                      </a>
                    </th>
                    <th>
                      <a
                        href="javascript:void(0)"
                        class="sort-by"
                        onClick={() => {
                          setEnable(true);
                          setSort("userName");
                        }}
                      >
                        Name
                        <div
                          class={sort === "userName" && order === "asc" ? "ascending-icon active" : "ascending-icon"}
                          onClick={() => setOrder("asc")}
                        ></div>
                        <div
                          class={sort === "userName" && order === "desc" ? "descending-icon active" : "descending-icon"}
                          onClick={() => setOrder("desc")}
                        ></div>
                      </a>
                    </th>
                    <th>Agreement Type</th>
                    <th>
                      <a
                        href="javascript:void(0)"
                        class="sort-by"
                        onClick={() => {
                          setEnable(true);
                          setSort("createdAt");
                        }}
                      >
                        Sent Date
                        <div
                          class={sort === "createdAt" && order === "asc" ? "ascending-icon active" : "ascending-icon"}
                          onClick={() => setOrder("asc")}
                        ></div>
                        <div
                          class={
                            sort === "createdAt" && order === "desc" ? "descending-icon active" : "descending-icon"
                          }
                          onClick={() => setOrder("desc")}
                        ></div>
                      </a>
                    </th>
                    <th>
                      <a
                        href="javascript:void(0)"
                        class="sort-by"
                        onClick={() => {
                          setEnable(true);
                          setSort("agreedAt");
                        }}
                      >
                        Status
                        <div
                          class={sort === "agreedAt" && order === "asc" ? "ascending-icon active" : "ascending-icon"}
                          onClick={() => setOrder("asc")}
                        ></div>
                        <div
                          class={sort === "agreedAt" && order === "desc" ? "descending-icon active" : "descending-icon"}
                          onClick={() => setOrder("desc")}
                        ></div>
                      </a>
                    </th>
                    <th>Download Authorization PDF</th>
                  </tr>
                </thead>
                {loading ? (
                  <tbody>
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(() => (
                      <tr>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <tbody>
                    {detail &&
                      detail?.length > 0 &&
                      detail?.map((val, i) => (
                        <tr key={i}>
                          <td>{(page - 1) * 20 + (i + 1)}</td>
                          <td>
                            {val.auction_id ? (
                              <span className="green-text">
                                <a target="_blank" href={`listing-detail?id=${val?.auction_id}`}>
                                  {val.auction_id}
                                </a>
                              </span>
                            ) : val?.uniqueKey ? (
                              "--"
                            ) : (
                              "N/A"
                            )}
                          </td>
                          <td>
                            {val.auction_title ? (
                              <span className="green-btn-text">
                                <a
                                  className="green-btn-text"
                                  target="_blank"
                                  href={`listing-detail?id=${val?.auction_id}`}
                                >
                                  {val.auction_title}
                                </a>
                              </span>
                            ) : val?.uniqueKey ? (
                              "--"
                            ) : (
                              "N/A"
                            )}
                          </td>
                          <td>
                            <span
                              className="green-btn-text"
                              onClick={() => {
                                if (process.env.REACT_APP_MODE == "development") {
                                  window.open(
                                    `https://admin-dev.salvex.com/admin/_user/_edit.cfm?user_id=${val?.user_id}`,
                                  );
                                } else {
                                  window.open(`https://admin.salvex.com/admin/_user/_edit.cfm?user_id=${val?.user_id}`);
                                }
                              }}
                            >
                              {val.name}
                            </span>
                          </td>
                          <td>{val.uniqueKey ? "Multiple" : "Single"}</td>
                          <td>{val?.created ? <DateFormat date={val?.created} /> : "N/A"}</td>
                          <td>
                            {val?.agreed ? (
                              <span>
                                Agreed on <DateFormat date={val?.agreed} />
                              </span>
                            ) : (
                              <span className="fw-medium text-danger">Pending</span>
                            )}
                          </td>
                          <td>
                            {val?.hash ? (
                              <a
                                className="green-btn-text"
                                target="_blank"
                                href={process.env.REACT_APP_S3_PRIVATE_DOC_BUCKET + "/" + val?.hash + ".pdf"}
                              >
                                Download
                              </a>
                            ) : (
                              "N/A"
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                )}
              </table>
              {detail && detail?.length > 0 ? (
                ""
              ) : (
                <div className="d-flex justify-content-center align-items-center">
                  <span>No record found</span>
                </div>
              )}
            </div>
            {detail && detail?.length > 0 && (
              <div class="table-pagination mt-2">
                <ul class="pagination  justify-content-end">
                  <li class="page-item">
                    <a
                      class={`page-link ${page === 1 ? "disabled" : ""}`}
                      href="javascript:void(0)"
                      aria-label="Previous"
                      onClick={() => {
                        if (page !== 1) {
                          setEnable(true);
                          setPage(page - 1);
                        }
                      }}
                    >
                      <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 13L1 7L7 1" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </a>
                  </li>
                  <li class="page-item active">
                    <a class="page-link" href="javascript:void(0)">
                      {page}
                    </a>
                  </li>
                  <li class="page-item">
                    {page < (totalCount % 10 === 0 ? Math.floor(totalCount / 10) : Math.floor(totalCount / 10) + 1) && (
                      <a
                        class={`page-link ${
                          page >=
                          (totalCount % 10 === 0 ? Math.floor(totalCount / 10) : Math.floor(totalCount / 10) + 1)
                            ? "disabled"
                            : ""
                        }`}
                        href="javascript:void(0)"
                        onClick={() => {
                          setEnable(true);
                          setPage(page + 1);
                        }}
                      >
                        {page + 1}
                      </a>
                    )}
                  </li>
                  <li class="page-item">
                    {page < (totalCount % 10 === 0 ? Math.floor(totalCount / 10) : Math.floor(totalCount / 10) + 1) && (
                      <a
                        class={`page-link ${
                          page >=
                          (totalCount % 10 === 0 ? Math.floor(totalCount / 10) : Math.floor(totalCount / 10) + 1)
                            ? "disabled"
                            : ""
                        }`}
                        href="javascript:void(0)"
                        aria-label="Next"
                        onClick={() => {
                          setEnable(true);
                          setPage(page + 1);
                        }}
                      >
                        <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 13L7 7L1 1" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </a>
                    )}
                  </li>
                </ul>
              </div>
            )}
          </>
        ) : (
          !contentLoaded && (
            <div
              class="page-widget-empty-box text-capitalize d-flex align-items-center justify-content-center text-center"
              style={{
                height: "50px",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <span>{loading ? "Content Loading..." : "Content not loaded"}</span>
            </div>
          )
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getListingAgreement: (keyword, page, sort, order, callback) =>
    dispatch(getListingAgreement(keyword, page, sort, order, callback)),
});
const mapStateToProps = (state) => ({
  dashboardData: dashboardData(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(ListingAgreement));
