import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import addIcon from "../../../include/images/add-icn.svg";
import { getManufacturers, getMisc, getModel } from "../../../store/misc";
import Select from "react-select";
import _ from "lodash";
import { customStyles } from "../../../common/customStyles/reactSelectStyle";
import {
  getListingProductDetail,
  getMfrAndModel,
  mfrAndModelReceived,
  updateMfrAndModel,
} from "../../../store/listingProduct";
import NextButton from "../../../common/form/nextButton";
import { toast } from "react-toastify";
import AlertError from "../../../common/alerts/alertError";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import Skeleton from "react-loading-skeleton";
import CreatableSelect from "react-select/creatable";

const Manufacturer = (props) => {
  const [value, setValue] = useState({ manufacturer: "", model: "" });
  const [loadingManufacturer, setLoadingManufacturer] = useState(false);
  const [loadingModel, setLoadingModel] = useState(false);
  const [createListing, setCreateListing] = useState(false);
  const [isPushMfr, setIsPushMfr] = useState(false);
  const [loading, setLoading] = useState(false);

  const initialData =
    props.getListingProductDetail &&
    props.getListingProductDetail.mfrAndModel &&
    props.getListingProductDetail.mfrAndModel.data;

  useEffect(() => {
    props.getManufacturers((res) => {
      if (res.status === 200) {
        setLoadingManufacturer(false);
      } else {
        setLoadingManufacturer(false);
      }
    });
  }, []);

  useEffect(() => {
    if (initialData && initialData.mfr && !createListing) {
      const value = { manufacturer: {}, model: {} };
      value.manufacturer.name =
        initialData.mfr && initialData.mfr.Description
          ? initialData.mfr.Description
          : initialData.mfr.ID
          ? initialData.mfr.ID
          : "";
      value.manufacturer.value = initialData.mfr && initialData.mfr.ID;

      if (value.manufacturer.value) {
        if (value.manufacturer.value === 1305) return;
        else {
          setLoadingModel(true);
          value.model.name =
            initialData.model && initialData.model.description
              ? initialData.model.description
              : initialData.model.modelId
              ? initialData.model.modelId
              : "";
          value.model.value = initialData.model && initialData.model.modelId;
          setValue(value);
          setIsPushMfr(initialData.push_mfr);
        }
      }
    } else if (!createListing) {
      const value = { manufacturer: "", model: "" };
      setValue(value);
    }
  }, [initialData, createListing]);

  useEffect(() => {
    if (!_.isEmpty(value.manufacturer)) {
      if (value.manufacturer.value) {
        if (value.manufacturer.value === 1305) return;
        else {
          setLoadingModel(true);
          props.getModel(value.manufacturer.value, (res) => {
            if (res.status === 200) {
              setLoadingModel(false);
            } else {
              setLoadingModel(false);
            }
          });
        }
      }
    }
  }, [value.manufacturer]);

  const handleSubmit = () => {
    if ((!_.isEmpty(value.manufacturer) && !_.isEmpty(value.model)) || value.manufacturer.value === 1305) {
      props.refStart();
      setLoading(true);
      const data = {
        mfr_id: value.manufacturer.value ? (value.manufacturer.value === 1305 ? 0 : value.manufacturer.value) : 0,
        mfr: value.manufacturer.name,
        model: value.model.name,
        model_list: value.model.value ? (value.manufacturer.value === 1305 ? 0 : value.model.value) : 0,
        push_mfr: isPushMfr ? 1 : 0,
      };
      props.updateMfrAndModel(props.auctionId, data, (res) => {
        if (res.status === 200) {
          const data = { push_mfr: false, mfr: {}, model: {} };
          data.push_mfr = isPushMfr;
          data.mfr.Description = value.manufacturer.name;
          data.mfr.ID = value.manufacturer.value;
          data.model.description = value.model.name;
          data.model.modelId = value.model.value;
          props.mfrAndModelReceived({ data });
          setLoading(false);
          props.refStop();
          toast(<AlertSuccess message={"Information Saved"} />);
        } else {
          setLoading(false);
          props.refStop();
          toast(
            <AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />,
          );
        }
      });
    }
  };

  const handleCreate = () => {
    if (!_.isEmpty(value.manufacturer)) {
      props.refStart();
      setLoading(true);
      const data = {
        mfr: value.manufacturer.name,
        model: value.model.name ? value.model.name : "",
        push_mfr: isPushMfr ? 1 : 0,
      };
      props.updateMfrAndModel(props.auctionId, data, (res) => {
        if (res && res.status === 200) {
          props.getMfrAndModel(props.auctionId, (res) => {
            if (res && res.status === 200) {
            } else {
              toast(<AlertError message="Something Went Wrong. Please Refresh the page." />);
            }
          });
          setLoading(false);
          props.refStop();
          toast(<AlertSuccess message={"Information Saved"} />);
          setCreateListing(false);
          props.getManufacturers((res) => {
            if (res && res.status === 200) {
            } else {
              props.refStop();
              setLoadingManufacturer(false);
              toast(
                <AlertError
                  message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"}
                />,
              );
            }
          });
        } else {
          setLoading(false);
          props.refStop();
          toast(
            <AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />,
          );
        }
      });
    }
  };

  // useEffect(() => {
  //   if (
  //     !createListing &&
  //     props.getListingProductDetail &&
  //     props.getListingProductDetail.mfrAndModel &&
  //     props.getListingProductDetail.mfrAndModel.data
  //   ) {
  //     const response = props.getListingProductDetail.mfrAndModel.data;
  //     const value = { manufacturer: {}, model: {} };

  //     value.manufacturer.name =
  //       response.mfr && response.mfr.Description
  //         ? response.mfr.Description
  //         : "";
  //     value.manufacturer.value =
  //       response.mfr && response.mfr.ID ? response.mfr.ID : "";
  //     setLoadingModel(true);
  //     props.getModel(value.manufacturer.value, (res) => {
  //       if (res && res.status === 200) {
  //         setLoadingModel(false);
  //         value.model.name = response.model.description;
  //         value.model.value = response.model.modelId;
  //         setValue(value);
  //         setIsPushMfr(response.push_mfr);
  //       }
  //     });
  //   }
  // }, [createListing]);

  const options =
    props.getMisc &&
    props.getMisc.manufacturers &&
    props.getMisc.manufacturers.data &&
    props.getMisc.manufacturers.data.length > 0 &&
    props.getMisc.manufacturers.data.map((item) => ({
      name: `${item.Description} ${item.ra_users ? `(${item.ra_users})` : ""}`,
      value: item.ID,
    }));

  const option1 =
    props.getMisc &&
    props.getMisc.model &&
    props.getMisc.model.data &&
    props.getMisc.model.data.length > 0 &&
    props.getMisc.model.data.map((item) => ({
      name: item.description,
      value: item.modelID,
    }));

  return (
    <div class="details-page-widget-block" id={props.id}>
      <div class="page-widget-head d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <div class="page-widget-label text-capitalize">Manufacturer and Model</div>

          <div style={{ display: "none" }} id="popover">
            <div class="popover-inner" role="tooltip">
              <p>
                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer
                took a galley
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="page-widget-body mt-3">
        <table class="table table-borderless align-middle table-left-align">
          <colgroup>
            <col style={{ width: "32%" }} />
            <col />
          </colgroup>
          <tbody>
            {props.loadingMain ? (
              <>
                <tr>
                  <td colspan="1">
                    <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
                      <Skeleton height="20px" width="150px" />
                    </div>
                  </td>
                  <td colspan="2">
                    <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
                      <Skeleton height="20px" width="150px" />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Skeleton height="35px" width="100%" />
                  </td>
                  <td>
                    <Skeleton height="35px" width="100%" />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Skeleton height="35px" width="100%" />
                  </td>
                  <td>
                    <Skeleton height="35px" width="100%" />
                  </td>
                </tr>
              </>
            ) : (
              <>
                {/* <tr>
                  <td colspan="1">
                    <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
                      <input
                        class="form-check-input cursor-pointer"
                        type="radio"
                        name="manufacturer"
                        id="manufacturerStatus1"
                        checked={!createListing}
                        onChange={() => {
                          setCreateListing(!createListing);
                          setValue({ manufacturer: "", model: "" });
                        }}
                      />
                      <label class="form-check-label" for="manufacturerStatus1">
                        Existing
                      </label>
                    </div>
                  </td>

                 
                  
                   <td colspan="2">
                    <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
                      <input
                        class="form-check-input cursor-pointer"
                        type="radio"
                        name="manufacturer"
                        id="manufacturerStatus2"
                        checked={createListing}
                        onChange={() => {
                          setCreateListing(!createListing);
                          setValue({
                            manufacturer: { name: "" },
                            model: { name: "" },
                          });
                        }}
                      />
                      <label class="form-check-label" for="manufacturerStatus2">
                        New
                      </label>
                    </div>
                  </td> 
                </tr> */}
                <tr>
                  <td>Manufacturer</td>
                  <td>
                    {!createListing ? (
                      <div class="d-flex">
                        <Select
                          value={[value.manufacturer]}
                          className="basic-single"
                          classNamePrefix="select-search"
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.value}
                          isClearable={true}
                          isSearchable={true}
                          name="manufacturer"
                          isLoading={loadingManufacturer}
                          loadingMessage={() => "Loading..."}
                          options={options || []}
                          placeholder={"Select Manufacturer"}
                          styles={customStyles}
                          onChange={(a) => {
                            const data = { ...value };
                            data.manufacturer = a;
                            data.model = "";
                            setValue(data);
                          }}
                        />

                        {/* <a
                      href="javascript:void(0)"
                      class="icon-add-input"
                      onClick={() => {
                        setCreateListing(true);
                        setValue({ manufacturer: {}, model: {} });
                      }}
                    >
                      <img src={addIcon} alt="" />
                    </a> */}
                      </div>
                    ) : (
                      <div className="d-flex">
                        <CreatableSelect
                          className="basic-single"
                          classNamePrefix="select-search"
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.value}
                          getNewOptionData={(inputValue, optionLabel) => ({
                            value: inputValue,
                            name: optionLabel,
                          })}
                          isSearchable={true}
                          isClearable={true}
                          styles={customStyles}
                          name="manufacturer1"
                          options={options || []}
                          onChange={(e) => {
                            const data = { ...value };
                            data.manufacturer.name = e.name;
                            setValue(data);
                          }}
                        />
                        {/* <input
                          className="form-control"
                          placeholder="Enter Manufacturer Name"
                          value={
                            (value.manufacturer && value.manufacturer.name) ||
                            ""
                          }
                          onChange={(e) => {
                            const data = { ...value };
                            data.manufacturer.name = e.target.value;
                            setValue(data);
                          }}
                        /> */}
                      </div>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Model</td>
                  <td>
                    {!createListing ? (
                      <Select
                        value={value.model}
                        className="basic-single"
                        classNamePrefix="select-search"
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.value}
                        name="model"
                        isLoading={loadingModel}
                        loadingMessage={() => "Loading..."}
                        options={option1 || []}
                        placeholder={"Select Model"}
                        styles={customStyles}
                        isSearchable={true}
                        isClearable={true}
                        isDisabled={_.isEmpty(value.manufacturer) || value.manufacturer.value === 1305}
                        onChange={(a) => {
                          const data = { ...value };
                          data.model = a;
                          setValue(data);
                        }}
                      />
                    ) : (
                      <input
                        className="form-control"
                        placeholder="Enter Model Name"
                        value={(value.model && value.model.name) || ""}
                        onChange={(e) => {
                          const data = { ...value };
                          data.model.name = e.target.value;
                          setValue(data);
                        }}
                      />
                    )}
                  </td>
                </tr>
              </>
            )}
            {!props.loadingMain && (
              <tr>
                <td>Push Manufacturer</td>
                <td>
                  <div class="form-check tick-checkbox mt-2">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="proxibidRemovetick1"
                      checked={isPushMfr}
                      onChange={() => setIsPushMfr(!isPushMfr)}
                    />
                    <label class="form-check-label fw-medium" for="proxibidRemovetick1"></label>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div class="d-flex justify-content-end">
          {props.loadingMain ? (
            <Skeleton height="40px" width="90px" />
          ) : (
            <NextButton
              classData="btn btn-default"
              handleSubmit={() => (!createListing ? handleSubmit() : handleCreate())}
              label={createListing ? "Create" : "Save"}
              loading={loading}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getManufacturers: (callback) => dispatch(getManufacturers(callback)),
  getModel: (data, callback) => dispatch(getModel(data, callback)),
  getMfrAndModel: (data, callback) => dispatch(getMfrAndModel(data, callback)),
  updateMfrAndModel: (params, data, callback) => dispatch(updateMfrAndModel(params, data, callback)),
  mfrAndModelReceived: (data) => dispatch(mfrAndModelReceived(data)),
});

const mapStateToProps = (state) => ({
  getMisc: getMisc(state),
  getListingProductDetail: getListingProductDetail(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Manufacturer));
