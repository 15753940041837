import React, { useEffect, useState } from "react";
import close from "../../../include/images/close.svg";
import { Offcanvas } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import moment from "moment";
import { capitalizeFirstLetter } from "../../../utils.js/conversion";

const ViewBARREmail = (props) => {
  const emailData = props?.data;
  return (
    <Offcanvas
      placement="end"
      backdropClassName="upload-documnets-backdrop"
      show={props.show}
      onHide={() => props.onHide()}
      className=" right-collapse fade modal-lg  offcanvas-pannel-container bvw_width"
    >
      <Offcanvas.Header className="offcanvas-header">
        <h5 class="offcanvas-title"> {emailData?.subject}</h5>
        <button
          type="button"
          class="offcanvas-close-btn d-flex align-items-center justify-content-center"
          onClick={() => props.onHide()}
        >
          <img src={close} alt="" />
        </button>
      </Offcanvas.Header>
      <Offcanvas.Body className="custom-modal-body flex-grow-1 w-100 overflow-scroll" data-simplebar>
        <Accordion className="accordion new-custom-accor" defaultActiveKey="0" alwaysOpen={true}>
          <Accordion.Item eventKey="0" className="accordion-item">
            <Accordion.Button class="accordion-button bvw_btn ">
              <div class="accordion-button-insider">
                <h3>Details</h3>
              </div>
            </Accordion.Button>
            <Accordion.Body className="bvw_body">
              <div className="d-block bvw_email-container">
                <div className="d-block">
                  <p className="bvw_label">Sent on : </p>
                  <p className="bvw_value">{moment?.(emailData?.date?.split("T")?.[0]).format("MM-DD-YYYY")}</p>
                </div>
                <div className="d-block">
                  <p className="bvw_label">Email : </p>
                  <p className="bvw_value">{emailData?.email}</p>
                </div>
                <div className="d-block" style={{ height: "588px" }}>
                  <iframe srcDoc={emailData?.body} style={{ width: "100%", height: "100%" }} />
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1" className="accordion-item">
            <Accordion.Button class="accordion-button bvw_btn ">
              <div class="accordion-button-insider">
                <h3>History</h3>
              </div>
            </Accordion.Button>
            <Accordion.Body className="bvw_body">
              <div className="table-responsive  table-custom">
                <table className="table table-bordered">
                  <colgroup>
                    <col />
                    <col />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>Event</th>
                      <th>Date </th>
                    </tr>
                  </thead>

                  <tbody>
                    {emailData?.events?.length > 0 ? (
                      emailData?.events?.map((item) => (
                        <tr>
                          <td>
                            {item?.name ? (item?.name === "click" ? "Clicked" : capitalizeFirstLetter(item?.name)) : ""}
                          </td>

                          <td>{item?.time ? moment(item?.time)?.format("MM-DD-YYYY h:mm:ss a") : ""}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={2}>
                          <div className="d-flex justify-content-center align-items-center">
                            <span>No record found</span>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default ViewBARREmail;
