import React, { useEffect } from "react";
import Select from "react-select";
import { customStyles } from "../../../common/customStyles/reactSelectStyle";
import Skeleton from "react-loading-skeleton";
import { getSellerOtherListings, primarySellerData } from "../../../store/primarySeller";
import { connect } from "react-redux";
import { useState } from "react";
import { toast } from "react-toastify";
import AlertError from "../../../common/alerts/alertError";

const SellerOtherListings = (props) => {
  const userId = props?.primarySellerData?.primarySeller?.data?.user?.s_user_id;
  const data = props?.primarySellerData?.listings?.data;
  const totalPages = props?.primarySellerData?.listings?.max_pages || 0;
  const statusOptions = [
    { name: "All", value: "all" },
    { name: "Active", value: "active" },
    { name: "Pending", value: "pending" },
    { name: "Invoiced", value: "invoiced" },
  ];
  const [status, setStatus] = useState({ name: "All", value: "all" });
  const [order, setOrder] = useState("asc");

  useEffect(() => {
    if (userId && props?.auctionId) {
      props?.setLoading(true);
      // props?.refStart();
      props?.getSellerOtherListings(
        status?.value,
        userId,
        { auction_id: props?.auctionId, sort: "title", order: order },
        (res) => {
          if (res && res.status === 200) {
            // props?.refStop();
            props?.setLoading(false);
          } else {
            // props?.refStop();
            props?.setLoading(false);
            toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
          }
        },
      );
    }
  }, [status, order]);
  return (
    <div class="details-page-widget-block" id={props.id}>
      <div class="page-widget-head d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <div class="d-flex align-items-start">
            <div class="page-widget-label text-capitalize cursor-pointer">Other Listings</div>
          </div>
          <li class="badge-item ms-3">
            {props.loadingMain ? (
              <Skeleton />
            ) : (
              <div style={{ width: "110px" }}>
                <Select
                  styles={customStyles}
                  value={status}
                  placeholder="Select Listing Type"
                  options={statusOptions}
                  getOptionLabel={(val) => val.name}
                  getOptionValue={(val) => val.value}
                  onChange={(e) => setStatus(e)}
                />
              </div>
            )}
          </li>
        </div>
        {/* <div class="btn-block">
          {props.loadingMain ? (
            <Skeleton />
          ) : (
            <Select
              styles={customStyles}
              placeholder="Select Listing Type"
              options={statusOptions}
              getOptionLabel={(val) => val.name}
              getOptionValue={(val) => val.value}
            />
          )}
        </div> */}
      </div>
      <div class={"page-widget-body mt-3"}>
        <div class="table-responsive table-custom">
          <table class="table table-bordered">
            <colgroup>
              <col />
              <col />
              <col />
              <col />
              <col />
              <col />
            </colgroup>
            <thead>
              {props?.loadingMain ? (
                <tr>
                  <th>
                    <Skeleton />
                  </th>
                  <th>
                    <Skeleton />
                  </th>
                  <th>
                    <Skeleton />
                  </th>
                  <th>
                    <Skeleton />
                  </th>
                  <th>
                    <Skeleton />
                  </th>
                  <th>
                    <Skeleton />
                  </th>
                  <th>
                    <Skeleton />
                  </th>
                </tr>
              ) : (
                <tr>
                  <th>Listing ID</th>
                  <th>
                    <a href="javascript:void(0)" class="sort-by">
                      Title{" "}
                      <div
                        class={order === "asc" ? "ascending-icon active" : "ascending-icon"}
                        onClick={() => setOrder("asc")}
                      ></div>
                      <div
                        class={order === "desc" ? "descending-icon active" : "descending-icon"}
                        onClick={() => setOrder("desc")}
                      ></div>
                    </a>
                  </th>
                  <th>Trader</th>
                  <th>BD Manager</th>
                  <th>Quantity</th>
                  <th>Location</th>
                  <th>Status</th>
                </tr>
              )}
            </thead>
            <tbody>
              {props?.loadingMain
                ? [1, 2, 3].map((i) => (
                    <tr>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                    </tr>
                  ))
                : data?.length > 0 &&
                  data?.map((val) => (
                    <tr>
                      <td>
                        <a
                          className="link-item"
                          href="javascript:void(0)"
                          onClick={() => window.open(`/listing-detail?id=${val?.auction_id}`)}
                        >
                          {val?.auction_id ? val?.auction_id : "N/A"}
                        </a>
                      </td>
                      <td>
                        <a
                          className="link-item"
                          href="javascript:void(0)"
                          onClick={() => window.open(`/listing-detail?id=${val?.auction_id}`)}
                        >
                          {val?.title ? val?.title : "N/A"}
                        </a>
                      </td>
                      <td>{val?.trader_name ? val?.trader_name : "N/A"}</td>
                      <td>{val?.ir_name ? val?.ir_name : "N/A"}</td>
                      <td>{val?.quantity ? val?.quantity : "N/A"}</td>
                      <td>{val?.location ? val?.location : "N/A"}</td>
                      <td>
                        {val?.status_id === 3
                          ? "Active"
                          : val?.status_id === 0 || val?.status_id === 1 || val?.status_id === 2
                          ? "Pending"
                          : val?.status_id === 6 || val?.status_id === 4
                          ? "Invoiced"
                          : ""}
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getSellerOtherListings: (option, id, params, callback) =>
    dispatch(getSellerOtherListings(option, id, params, callback)),
});

const mapStateToProps = (state) => ({
  primarySellerData: primarySellerData(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(SellerOtherListings));
