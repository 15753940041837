import React, { useRef, useState } from "react";
import { Accordion, Offcanvas } from "react-bootstrap";
import close from "../../../include/images/close.svg";
import trash from "../../../include/images/trash-24x24.svg";
import LoadingBar from "react-top-loading-bar";
import Select from "react-select";
import { flatten } from "lodash";
import emptyChat from "../../../include/images/chat-empty.svg";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import AlertError from "../../../common/alerts/alertError";
import NextButton from "../../../common/form/nextButton";

const customStyles = {
  indicatorSeparator: (styles) => ({ display: "none" }),
  option: (provided, state) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "#47ad1d",
      color: "#fff",
    },
    display: "-webkit-box",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    color: state.isSelected ? "#fff" : "black",
    backgroundColor: state.isSelected ? "#47ad1d" : provided.backgroundColor,
    // maxHeight: "84px",
  }),
  control: (base, state) => ({
    ...base,
    // height: 60,
    minHeight: 42,
    borderColor: state.isFocused ? "#47AD1D" : "#e4e4e4",
    boxShadow: state.isFocused ? "0 0 0 0.5px #47AD1D" : "0",
    border: state.isHovered ? "0" : "1px solid #e4e4e4",

    "&:hover": {
      borderColor: state.isFocused ? "#47AD1D" : "0",
      boxShadow: state.isFocused ? "0 0 0 0.5px #47AD1D" : "0",
    },
  }),
  multiValueRemove: (base, state) => ({
    ...base,
    "&:hover": {
      backgroundColor: "rgb(95, 50, 187, 10%)",
      color: "#6119c0",
    },
  }),
};

const ManufacturePreferences = ({
  selectedManufacture,
  setSelectedManufacture,
  userId,
  addManufacturePreference,
  ...props
}) => {
  const ref = useRef();
  const manufactureOption = props.manufactures;
  const [loading, setLoading] = useState(false);

  const options = manufactureOption.map((category) => ({
    label: category.description,
    options: category.subManufacturers.map((subManufacturer) => ({
      mfrid: subManufacturer.mfrid,
      description: subManufacturer.description,
    })),
  }));

  const saveManufacturePreference = () => {
    ref?.current?.continuousStart();
    setLoading(true);
    const list = [];
    selectedManufacture?.map((val) => list.push(val.mfrid));
    const data = { mfr_preferences: list };
    addManufacturePreference(userId, data, (res) => {
      if (res.status === 200) {
        ref?.current?.complete();
        setLoading(false);
        props.onHide();
        toast(<AlertSuccess message={"Information Saved"} />);
      } else {
        ref?.current?.complete();
        setLoading(false);
        toast(
          <AlertError
            message={
              res.data.message ? res.data.message : "Something Went Wrong"
            }
          />
        );
      }
    });
  };

  return (
    <>
      <LoadingBar height={5} color="#47AD1D" ref={ref} />
      <Offcanvas
        className="offcanvas offcanvas-end xl-offcanvas border-0"
        id="buyerPreferences"
        placement="end"
        backdropClassName="upload-documnets-backdrop"
        show={props.show}
        onHide={() => props.onHide()}
        enforceFocus={false}
      >
        <Offcanvas.Header className="header-buyer-pref">
          <h5 class="offcanvas-title">Manufacturer Preferences</h5>
          {!props.loadingManufacture && (
            <a
              href="javascript:void(0)"
              class="btn btn-link me-md-2 global-cancel-button cancel-user-pref"
              onClick={() => props.onHide()}
            >
              {" "}
              Cancel{" "}
            </a>
          )}
          {!props.loadingManufacture && (
            <a
              className={
                loading
                  ? "btn btn-border-green disabled"
                  : "btn btn-border-green"
              }
              onClick={saveManufacturePreference}
            >
              {loading ? "Loading..." : "Save"}
            </a>
          )}
        </Offcanvas.Header>

        <Offcanvas.Body className="offcanvas-body position-relative p-4">
          <div class="dashboard-widget-row-container w-100 d-lg-flex flex-lg-wrap">
            <div class="dwrc-left">
              <h3 class="widget-title text-black">Add/Edit Manufacturer</h3>
              <div className="me-2">
                <span>Search</span>
                {props?.loadingManufacture ? (
                  <>
                    <div>
                      <Skeleton height="40px"  className="mb-3" />
                    </div>
                  </>
                ) : (
                  <Select
                    className="basic-single"
                    classNamePrefix="select-search"
                    value={selectedManufacture}
                    options={options}
                    isMulti
                    placeholder="Keywords"
                    styles={customStyles}
                    getOptionLabel={(option) => option.description}
                    getOptionValue={(option) => option.mfrid}
                    onChange={(e) => {
                      setSelectedManufacture(e);
                    }}
                  />
                )}
              </div>
            </div>
            <div class="dwrc-right d-flex flex-column">
              <h3 class="widget-title text-black">Current Selection</h3>
              <div class="table-responsive mb-30">
                <table class="table table-border-radious table-bordered table-fit-content table-wrap align-middle table-large">
                  <tbody>
                    {props.loadingManufacture ? (
                      [1, 2, 3, 4, 5].map((item) => (
                        <tr>
                          <td>
                            <Skeleton />
                          </td>
                          <td>
                            <div class="td-delete-icon">
                              <Skeleton height="30px" />
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : selectedManufacture?.length > 0 ? (
                      selectedManufacture?.map((val) => (
                        <tr>
                          <td>{val.description}</td>
                          <td>
                            <div
                              class="td-delete-icon"
                              onClick={() => {
                                const data = [...selectedManufacture];
                                const newData = data?.filter(
                                  (s) => s.mfrid !== val.mfrid
                                );
                                setSelectedManufacture(newData);
                              }}
                            >
                              <img src={trash} alt="" />
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td>
                          <div class="pannel-body d-flex align-items-center justify-content-center mt-5 mb-5">
                            <div class="empty-pannel-box">
                              <img src={emptyChat} alt="" />
                              <h4>No Manufacturers Selected!</h4>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div class="widgets-btn text-end mt-auto">
                {!props.loadingManufacture && (
                  <NextButton
                    label="Save"
                    classData="btn btn-default btn-lg"
                    handleSubmit={saveManufacturePreference}
                    loading={loading}
                  />
                )}
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default ManufacturePreferences;
