import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";

const slice = createSlice({
  name: "listingMedia",
  initialState: {
    listingImages: [],
    loadingListingImages: false,
    picture_id: "",
    listingDocuments: [],
    loadingListingDocuments: false,
    primaryPicture: "",
    descpPart: {},
    loading: false,
    privateDocuments: [],
    ncnda: {},
  },
  reducers: {
    primaryPicture: (misc, action) => {
      misc.picture_id = action.payload;
    },
    listingImageRequested: (misc, action) => {
      misc.loadingListingImages = true;
    },
    listingImageReceived: (misc, action) => {
      misc.listingImages = action.payload;
      misc.loadingListingImages = false;
    },
    listingImageRequestFailed: (misc, action) => {
      misc.loadingListingImages = false;
    },
    listingDocumentsRequested: (misc, action) => {
      misc.loadingListingDocuments = true;
    },
    listingDocumentsReceived: (misc, action) => {
      misc.listingDocuments = action.payload;
      misc.loadingListingDocuments = false;
    },
    listingDocumentsRequestFailed: (misc, action) => {
      misc.loadingListingDocuments = false;
    },
    listingPrivateDocumentsRequested: (misc, action) => {
      misc.loadingListingDocuments = true;
    },
    listingPrivateDocumentsReceived: (misc, action) => {
      misc.privateDocuments = action.payload;
      misc.loadingListingDocuments = false;
    },
    listingPrivateDocumentsRequestFailed: (misc, action) => {
      misc.loadingListingDocuments = false;
    },
    partsRequested: (misc, action) => {
      misc.loading = true;
    },
    partsReceived: (misc, action) => {
      misc.descpPart = action.payload;
      misc.loading = false;
    },
    partsRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    ncndaRequested: (misc, action) => {
      misc.loading = true;
    },
    ncndaReceived: (misc, action) => {
      misc.ncnda = action.payload;
      misc.loading = false;
    },
    ncndaFailed: (misc, action) => {
      misc.loading = false;
    },
  },
});

export const {
  listingImageRequested,
  listingImageReceived,
  listingImageRequestFailed,
  listingDocumentsRequested,
  listingDocumentsReceived,
  listingDocumentsRequestFailed,
  primaryPicture,
  partsRequested,
  partsReceived,
  partsRequestFailed,
  listingPrivateDocumentsRequested,
  listingPrivateDocumentsReceived,
  listingPrivateDocumentsRequestFailed,
  ncndaRequested,
  ncndaReceived,
  ncndaFailed,
} = slice.actions;
export default slice.reducer;

// Action Creators
const listingImagesUrl = "auctionMedia/picture";
const listingDocumentsUrl = "auctionMedia/document";
const markPrimaryPictutre = "auctionMedia/markAsPrimaryPicture";
const docTemplateUrl = "documentTemplate"
const baseUrl = "auctionDetail";

export const listingImages = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${listingImagesUrl}/${data}`,
      method: "get",
      callback,
      onStart: listingImageRequested.type,
      onSuccess: listingImageReceived.type,
      onError: listingImageRequestFailed.type,
    })
  );
};

export const listingDocuments = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${listingDocumentsUrl}/${data}`,
      method: "get",
      callback,
      onStart: listingDocumentsRequested.type,
      onSuccess: listingDocumentsReceived.type,
      onError: listingDocumentsRequestFailed.type,
    })
  );
};

export const listingPrivateDocuments = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${listingDocumentsUrl}/${data}?private=${true}`,
      method: "get",
      callback,
      onStart: listingPrivateDocumentsRequested.type,
      onSuccess: listingPrivateDocumentsReceived.type,
      onError: listingPrivateDocumentsRequestFailed.type,
    })
  );
};

export const uploadListingImages = (params, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${listingImagesUrl}/${params}`,
      method: "POST",
      callback,
      data,
    })
  );
};

export const uploadListingDocuments =
  (params, data, callback) => (dispatch) => {
    return dispatch(
      apiCallBegan({
        url: `${listingDocumentsUrl}/${params}`,
        method: "POST",
        callback,
        data,
      })
    );
  };

export const uploadPrivateListingDocuments =
  (params, data, callback) => (dispatch) => {
    return dispatch(
      apiCallBegan({
        url: `${listingDocumentsUrl}/private/${params}`,
        method: "POST",
        callback,
        data,
      })
    );
  };

export const updateListingTitle = (params, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${listingImagesUrl}/${params}`,
      method: "PUT",
      callback,
      data,
    })
  );
};

export const markAsPrimary = (params, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${markPrimaryPictutre}/${params}`,
      method: "PUT",
      callback,
      data,
    })
  );
};

export const deleteListingImage = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${listingImagesUrl}/${params}`,
      method: "DELETE",
      callback,
    })
  );
};

export const deleteListingDocument = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${listingDocumentsUrl}/${params}`,
      method: "DELETE",
      callback,
    })
  );
};

export const uploadDescpParts = (params, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/uploadPart/${params}`,
      method: "POST",
      callback,
      data,
    })
  );
};

export const saveColumn = (params, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/saveColumn/${params}`,
      method: "PUT",
      callback,
      data,
    })
  );
};

export const savePart = (params, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/savePart/${params}`,
      method: "PUT",
      callback,
      data,
    })
  );
};

export const getDescpParts = (params, mode, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/viewPart/${params}?mode=${mode}`,
      method: "get",
      callback,
      onStart: partsRequested.type,
      onSuccess: partsReceived.type,
      onError: partsRequestFailed.type,
    })
  );
};

export const getNcndaTemplate = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${docTemplateUrl}`,
      params,
      method: "get",
      callback,
      onStart: ncndaRequested.type,
      onSuccess: ncndaReceived.type,
      onError: ncndaFailed.type,
    })
  );
};

export const updateNcndaTemplate=(data,callback)=>(dispatch)=>{
  return dispatch(
    apiCallBegan({
      url:`${docTemplateUrl}`,
      method:"put",
      data,
      callback
    })
  )
}

export const getListingMedia = createSelector(
  (state) => state.entities.listingMedia,
  (listingMedia) => listingMedia
);
