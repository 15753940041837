import React, { useEffect, useRef, useState } from "react";
import Header from "../../layouts/header";
import { connect } from "react-redux";
import LoadingBar from "react-top-loading-bar";
import {
  getContractListing,
  getCounts,
  getEnterpriseContractDetail,
  getEnterpriseContracts,
  getEnterprises,
  updateEnterpriseContractDetail,
} from "../../store/contract";
import _ from "lodash";
import { Amplify, Storage } from "aws-amplify";
import { Auth } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import AlertSuccess from "../../common/alerts/alertSuccess";
import AlertError from "../../common/alerts/alertError";
import { validateErrorMessage } from "../../common/validation/validation";
import Joi from "joi";
import EmployeeContractsTable from "../../components/legal/enterpriseContacts.jsx/enterpriseContractsTable";
import AddEnterpriseContract from "../../components/legal/enterpriseContacts.jsx/modal/addEnterpriseContract";
import moment from "moment";

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    region: "us-east-1",
  },
  Storage: {
    bucket: process.env.REACT_APP_S3_UPLOAD_BUCKET,
    region: "us-east-1",
  },
});

const statusOptions = [
  {
    name: "No Contract",
    value: "0",
  },
  {
    name: "Targeting",
    value: "1",
  },
  {
    name: "Pending",
    value: "2",
  },
  {
    name: "Signed",
    value: "3",
  },
  {
    name: "Expired",
    value: "4",
  },
];

const EnterpriseContracts = (props) => {
  const ref = useRef(null);

  const [showAddContract, setShowAddContract] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [contractData, setContractData] = useState({});
  const [contractPage, setContractPage] = useState({});
  const [activeStatus, setActiveStatus] = useState("");
  const [sort, setSort] = useState("name");
  const [order, setOrder] = useState("desc");

  const [data, setData] = useState({
    enterprise: null,
    status: null,
    sellerCommission: "",
    buyerPremium: "",
    startDate: "",
    endDate: "",
    doc: null,
    contractId: "",
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState({});

  const count = props?.getContractListings?.count?.data;
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const toggleAddContract = () => {
    setShowAddContract(!showAddContract);
  };

  const schema = Joi.object({
    sellerCommission: Joi.number()
      .label("Seller Commission")
      .error((errors) => validateErrorMessage(errors)),
    buyerPremium: Joi.number()
      .label("Buyer Premium")
      .error((errors) => validateErrorMessage(errors)),

    startDate: Joi.any()
      .label("Start Date")
      .required()
      .error((errors) => validateErrorMessage(errors)),
    endDate: Joi.any()
      .label("End Date")
      .required()
      .error((errors) => validateErrorMessage(errors)),
    enterprise: Joi.object()
      .label("Enterprise")
      .required("")
      .error((errors) => validateErrorMessage(errors)),
    status: Joi.object()
      .label("Status")
      .required("")
      .error((errors) => validateErrorMessage(errors)),
    doc: Joi.object()
      .label("Document")
      .required("")
      .error((errors) => validateErrorMessage(errors)),
    contractId: Joi.any().error((errors) => validateErrorMessage(errors)),
  });

  const validate = (type = false) => {
    const error = schema.validate(_.pickBy(data, _.identity), {
      abortEarly: type,
    });
    if (error.error && error.error.details) {
      const errors = {};
      error.error.details.forEach((element) => {
        errors[element.context.key] = element.message;
      });
      setErrors(errors);
      return false;
    }
    setErrors("");
    return true;
  };

  const loadCounts = () => {
    props?.getCounts((res) => {});
  };

  const loadEnterpriseContracts = (status_id) => {
    if (!contractData[status_id]) {
      setLoading(true);
      ref?.current?.continuousStart();
      props?.getEnterpriseContracts({ status_id, sort, order }, (res) => {
        if (res && res?.status == 200) {
          const contracts = { ...contractData };
          const pages = { ...contractPage };
          contracts[status_id] = res?.data?.data;
          pages[status_id] = { totalPages: res?.data?.max_pages, page: res?.data?.page };

          setContractPage(pages);
          setContractData(contracts);

          setLoading(false);

          ref?.current?.complete();
        } else {
          setLoading(false);
          ref?.current?.complete();
          toast(<AlertError message={res?.data?.message ? res?.data?.message : "Something went wrong"} />);
        }
      });
    }
  };

  const setPage = (p) => {
    ref?.current?.continuousStart();
    setLoading(true);
    const page =
      contractPage?.[activeStatus]?.page && p > contractPage?.[activeStatus]?.page
        ? contractPage?.[activeStatus]?.page + 1
        : contractPage?.[activeStatus]?.page - 1;
    props?.getEnterpriseContracts({ status_id: activeStatus, page, sort, order }, (res) => {
      if (res && res?.status == 200) {
        const contracts = { ...contractData };
        const pages = { ...contractPage };
        contracts[activeStatus] = res?.data?.data;
        pages[activeStatus] = { totalPages: res?.data?.max_pages, page: res?.data?.page };

        setContractPage(pages);
        setContractData(contracts);

        setLoading(false);

        ref?.current?.complete();
      } else {
        setLoading(false);

        ref?.current?.complete();
        toast(<AlertError message={res?.data?.message ? res?.data?.message : "Something went wrong"} />);
      }
    });
  };
  const enterpriseOptions =
    props?.getContractListings?.enterprise?.data?.map((item) => ({
      name: item.name,
      id: item.id,
    })) || [];
  useEffect(() => {
    if (!showAddContract) {
      setSelectedItem(null);
      setErrors({});
    } else {
      const value = { ...data };
      value.enterprise = selectedItem ? { name: selectedItem?.c_name, id: selectedItem?.company_id } : null;
      value.status = statusOptions.filter((ie) => selectedItem?.contract_status_id == ie.value)[0];
      value.sellerCommission = selectedItem?.percentage;
      value.buyerPremium = selectedItem?.comm_buyer;
      value.startDate = selectedItem?.start_date ? Date.parse(selectedItem.start_date) : "";
      value.endDate = selectedItem?.end_date ? Date.parse(selectedItem.end_date) : "";
      value.doc = selectedItem?.short_path ? { name: selectedItem?.short_path } : "";
      value.contractId = selectedItem?.contract_id;
      setData(value);
    }
    if (enterpriseOptions?.length === 0) {
      loadEnterpriseOptions();
      loadCounts();
    }
  }, [showAddContract]);

  const loadEnterpriseOptions = (name, callback) => {
    let options = [];
    const payload = {
      keyword: name,
      page: 1,
    };
    props.getEnterprises(payload, (res) => {
      if (res && res.status === 200) {
        options = res.data?.data;
        callback(options);
      } else {
        callback([]);
      }
    });
  };

  const addHandler = () => {
    if (validate()) {
      if (!selectedItem || data?.doc?.type) {
        ref?.current?.continuousStart();
        setLoadingSubmit(true);

        const file = data?.doc;

        const fSize = Math.round(file.size / 1048576);
        const fType = file.type;
        const ext = file.name.split(".").pop();
        if (fSize > 25) {
          ref?.current?.complete();
          toast(<AlertError message="Size exceeds maximum allowable size. Maximum allowable size is 25MB." />);
          return setLoadingSubmit(false);
        } else if (
          ![
            "application/pdf",
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          ].includes(fType)
        ) {
          ref?.current?.complete();
          toast(
            <AlertError message="File is not of correct format and hence cannot be uploaded. Valid document formats are pdf, doc, and xls." />,
          );
          return setLoadingSubmit(false);
        } else {
          const fileName = "newSalvex/doc/" + uuidv4() + "." + ext;
          Storage.put(fileName, file, {
            completeCallback: (event) => {},
            progressCallback: (progress) => {},
            errorCallback: (err) => {
              return (
                setLoadingSubmit(false),
                ref?.current?.complete(),
                toast(<AlertError message={"Something Went Wrong"} />)
              );
            },
          }).then((result) => {
            const id = data?.enterprise?.id;
            const payload = {
              contractId: selectedItem ? data?.contractId : 0,
              startDate: data?.startDate ? moment(data?.startDate)?.format("MM/DD/YYYY") : "",
              endDate: data?.endDate ? moment(data?.endDate)?.format("MM/DD/YYYY") : "",
              percentage: data?.sellerCommission ? data?.sellerCommission : "",
              commBuyer: data?.buyerPremium ? data?.buyerPremium : "",
              statusId: data?.status?.value,
              doc: "public/" + result.key,
              minimal: 0,
            };
            const statusID = data?.status?.value;
            props?.updateEnterpriseContractDetail(id, payload, (res) => {
              if (res && res.status == 200) {
                loadCounts();
                props?.getEnterpriseContracts({ status_id: statusID, sort, order }, (res1) => {
                  if (res1 && res1?.status == 200) {
                    const contracts = { ...contractData };
                    const pages = { ...contractPage };
                    contracts[statusID] = res1?.data?.data;
                    pages[statusID] = { totalPages: res1?.data?.max_pages, page: res1?.data?.page };

                    setContractPage(pages);
                    setContractData(contracts);

                    setLoadingSubmit(false);

                    ref?.current?.complete();
                    toggleAddContract();
                    toast(<AlertSuccess message={"Information Saved"} />);
                  } else {
                    setLoadingSubmit(false);
                    ref?.current?.complete();
                    toast(<AlertError message={res?.data?.message ? res?.data?.message : "Something went wrong"} />);
                  }
                });
              } else {
                setLoadingSubmit(false);
                ref?.current?.complete();
                toast(<AlertError message={res?.data?.message ? res?.data?.message : "Something went wrong"} />);
              }
            });
          });
        }
      } else {
        ref?.current?.continuousStart();
        setLoadingSubmit(true);
        if (!data?.doc?.type) {
          const id = data?.enterprise?.id;
          const payload = {
            contractId: data?.contractId,
            startDate: data?.startDate ? moment(data?.startDate)?.format("MM/DD/YYYY") : "",
            endDate: data?.endDate ? moment(data?.endDate)?.format("MM/DD/YYYY") : "",
            percentage: data?.sellerCommission ? data?.sellerCommission : "",
            commBuyer: data?.buyerPremium ? data?.buyerPremium : "",
            statusId: data?.status?.value,
            doc: "",
            minimal: 0,
          };
          const statusID = data?.status?.value;
          props?.updateEnterpriseContractDetail(id, payload, (res) => {
            if (res && res.status == 200) {
              props?.getEnterpriseContracts({ status_id: statusID, sort, order }, (res1) => {
                if (res && res?.status == 200) {
                  const contracts = { ...contractData };
                  const pages = { ...contractPage };
                  contracts[statusID] = res1?.data?.data;
                  pages[statusID] = { totalPages: res1?.data?.max_pages, page: res1?.data?.page };

                  setContractPage(pages);
                  setContractData(contracts);

                  setLoadingSubmit(false);

                  ref?.current?.complete();
                  toggleAddContract();
                  toast(<AlertSuccess message={"Information Saved"} />);
                } else {
                  setLoadingSubmit(false);
                  ref?.current?.complete();
                  toast(<AlertError message={res?.data?.message ? res?.data?.message : "Something went wrong"} />);
                }
              });
            } else {
              setLoadingSubmit(false);
              ref?.current?.complete();
              toast(<AlertError message={res?.data?.message ? res?.data?.message : "Something went wrong"} />);
            }
          });
        }
      }
    }
  };

  const sortContractData = (status_id, sort, order) => {
    setLoading(true);
    ref?.current?.continuousStart();
    props?.getEnterpriseContracts({ status_id, sort, order }, (res) => {
      if (res && res?.status == 200) {
        const contracts = { ...contractData };
        const pages = { ...contractPage };
        contracts[status_id] = res?.data?.data;
        pages[status_id] = { totalPages: res?.data?.max_pages, page: res?.data?.page };

        setContractPage(pages);
        setContractData(contracts);

        setLoading(false);

        ref?.current?.complete();
      } else {
        setLoading(false);
        ref?.current?.complete();
        toast(<AlertError message={res?.data?.message ? res?.data?.message : "Something went wrong"} />);
      }
    });
  };

  return (
    <>
      <LoadingBar height={5} color="#47AD1D" ref={ref} />
      <Header />
      <EmployeeContractsTable
        toggleAddContract={toggleAddContract}
        loadEnterpriseContracts={loadEnterpriseContracts}
        loading={loading}
        contractData={contractData}
        contractPage={contractPage}
        setActiveStatus={setActiveStatus}
        setPage={setPage}
        setSelectedItem={setSelectedItem}
        setOrder={setOrder}
        setSort={setSort}
        sort={sort}
        order={order}
        sortContractData={sortContractData}
        count={count}
      />
      <AddEnterpriseContract
        show={showAddContract}
        onHide={() => toggleAddContract()}
        data={data}
        setData={setData}
        addHandler={addHandler}
        loading={loadingSubmit}
        errors={errors}
        loadEnterpriseOptions={loadEnterpriseOptions}
        statusOption={statusOptions}
        enterpriseOptions={enterpriseOptions}
        selectedItem={selectedItem}
      />
    </>
  );
};
const mapDispatchToProps = (dispatch) => ({
  getEnterpriseContracts: (params, callback) => dispatch(getEnterpriseContracts(params, callback)),

  getEnterprises: (params, callback) => dispatch(getEnterprises(params, callback)),
  getEnterpriseContractDetail: (id, params, callback) => dispatch(getEnterpriseContractDetail(id, params, callback)),
  updateEnterpriseContractDetail: (id, data, callback) => dispatch(updateEnterpriseContractDetail(id, data, callback)),
  getCounts: (callback) => dispatch(getCounts(callback)),
});

const mapStateToProps = (state) => ({
  getContractListings: getContractListing(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(EnterpriseContracts));
