import axios from "axios";

const headers = {
  accept: "application/json",
  "api-key": process.env.REACT_APP_BREVO_TOKEN,
};

const baseUrl = process.env.REACT_APP_BREVO_APIBASE;

export const getEmailLogs = async (url) => {
  try {
    const logsData = await axios.get(baseUrl + url, { headers });
    return logsData;
  } catch (e) {
    return e;
  }
};

export const getEmailsActivity = async (url) => {
  try {
    const logsData = await axios.get(baseUrl + url, { headers });
    return logsData;
  } catch (e) {
    return e;
  }
};

export const getSentEmailLogs = async (url) => {
  try {
    const logsData = await axios.get(baseUrl + url, { headers });
    return logsData;
  } catch (e) {
    return e;
  }
};

export const getGraphData = async (url) => {
  try {
    const logsData = await axios.get(baseUrl + url, { headers });
    return logsData;
  } catch (e) {
    return e;
  }
};

export const getEmailUUID = async (url) => {
  try {
    const logsData = await axios.get(baseUrl + url, { headers });
    return logsData;
  } catch (e) {
    return e;
  }
};

export const getEmailContent = async (url) => {
  try {
    const logsData = await axios.get(baseUrl + url, { headers });
    return logsData;
  } catch (e) {
    return e;
  }
};
