import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import close from "../../../include/images/close.svg";
import NextButton from "../../../common/form/nextButton";
import Select from "react-select";
import { customStyles } from "../../../common/customStyles/reactSelectStyle";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextField } from "@mui/material";
import Editor from "../../../common/editor/editor";

const OverallDetailModal = ({
  data,
  setData,
  conditionOptions,
  categoryOptions,
  manufactureOptions,
  auctionTypeOptions,
  countryOptions,
  stateOptions,
  primaryCategory,
  setPrimaryCategory,
  ...props
}) => {
  const [conditionValue, setConditionValue] = useState({
    name: "Select Condition",
    value: "",
  });
  const [typeCondition, setTypeCondition] = useState("");
  useEffect(() => {
    setTypeCondition(props?.typeCondition);
  }, [conditionOptions]);
  const [categoryValue, setCategoryValue] = useState({
    description: "Select Category",
    category_id: 0,
  });
  const [manufactureValue, setManufactureValue] = useState({
    name: "Select Manufacture",
    value: "",
  });
  const [SaleTypeValue, setSaleTypeValue] = useState({
    description: "Select SaleType",
    at_id: "",
  });
  const [countryValue, setCountryValue] = useState({
    description: "Select Country",
    old: "",
  });
  const [stateValue, setStateValue] = useState({
    description: "Select State",
    old: "",
  });

  const [locSec, setLocSec] = useState("");
  const categoryId =
    props.categoryDropdown && props.categoryDropdown.length > 0 && props.categoryDropdown.map((val) => val);

  useEffect(() => {
    if (props?.show) {
      props?.getAuctionType((res) => {});
      props?.getCountries((res) => {});
      props?.getStates((res) => {});
    }
  }, [props?.show]);

  useEffect(() => {
    setManufactureValue(manufactureOptions?.find((val) => val.value === data.mfr_id));
    setSaleTypeValue(auctionTypeOptions?.find((val) => val.at_id === data.at_id));
    setCountryValue(countryOptions?.find((val) => val.old === data.country_id));
    setStateValue(stateOptions?.find((val) => val.old === data.state_id));
    setCategoryValue(categoryOptions?.find((val) => val.category_id === data.category_id));
  }, [categoryOptions, manufactureOptions, auctionTypeOptions, countryOptions, stateOptions]);

  useEffect(() => {
    setConditionValue(
      typeCondition === ""
        ? { name: "Select Condition", value: "" }
        : conditionOptions?.find((val) => val.name === data.conditionType)
        ? conditionOptions?.find((val) => val.name === data.conditionType)
        : {
            name: "Others",
            value: "Others",
          },
    );
  }, [conditionOptions]);

  function formatDateForDisplay(isoDateString) {
    const date = new Date(isoDateString);
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      timeZone: "UTC", // Set the time zone to UTC
    };
    return date.toLocaleString("en-US", options);
  }

  const handleLocationChange = (e) => {
    const id = props?.auctionId;
    props.updateLocSec(id, { loc_sec: e }, (res) => {
      if (res.status === 200) {
      }
    });
  };

  useEffect(() => {
    if (props?.show) {
      setLocSec(data?.loc_sec);
    }
  }, [props?.show]);

  return (
    <Modal
      className="modal fade custom-modal"
      id="editSustainabilityReport"
      show={props.show}
      onHide={() => props.onHide()}
      dialogClassName="modal-dialog modal-dialog-centered modal-xxl"
    >
      <div class="modal-content h-100 d-flex flex-column">
        <div class="custom-modal-header d-flex align-items-center justify-content-between">
          {/* <h5 class="mb-0 text-capitalize">Overall Details</h5> */}
          <h5 class="mb-0 text-capitalize">Important Details</h5>
          <button
            type="button"
            class="close-btn d-flex align-items-center justify-content-center"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => props.onHide()}
          >
            <img src={close} alt="" />
          </button>
        </div>
        <Modal.Body className="custom-modal-body flex-grow-1 w-100 overflow-hidden pb-0">
          <div class="detail-row-container d-flex flex-wrap">
            <div class="drc-left w-50">
              <table class="table modal-product-detail-table mb-0">
                <colgroup>
                  <col style={{ width: "30%" }} />
                  <col style={{ width: "70%" }} />
                </colgroup>

                <tr>
                  <td>Quantity</td>
                  <td>
                    <div className="padding-0">
                      <input
                        type="text"
                        class="form-control"
                        value={data.quantity}
                        onChange={(e) => {
                          const val = { ...data };
                          val.quantity = e.target.value;
                          setData(val);
                        }}
                      />
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>Unit</td>
                  <td>
                    <div className="padding-0">
                      <input
                        type="text"
                        class="form-control"
                        value={data.unit}
                        onChange={(e) => {
                          const val = { ...data };
                          val.unit = e.target.value;
                          setData(val);
                        }}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Auction End Date</td>
                  <td>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DateTimePicker
                        disableHighlightToday={true}
                        className="form-control"
                        value={formatDateForDisplay(data.end_date)} // ISO 8601 date
                        onChange={(e) => {
                          const val = { ...data };
                          // Convert the selected date to ISO 8601 format
                          val.end_date = e.toISOString();
                          setData(val);
                        }}
                        renderInput={(params) => <TextField {...params} error={false} />}
                        inputFormat="MM/dd/yyyy hh:mm a"
                      />
                    </LocalizationProvider>
                  </td>
                </tr>
                <tr>
                  <td>Public Asking Price</td>
                  <td>
                    <div className="padding-0">
                      <input
                        type="text"
                        class="form-control"
                        value={data.asking_price}
                        onChange={(e) => {
                          const val = { ...data };
                          val.asking_price = e.target.value;
                          setData(val);
                        }}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Seller Asking Price</td>
                  <td>
                    <div className="padding-0">
                      <input
                        type="text"
                        class="form-control"
                        value={data.seller_asking_price}
                        onChange={(e) => {
                          const val = { ...data };
                          val.seller_asking_price = e.target.value;
                          setData(val);
                        }}
                      />
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>Wholesale Price</td>
                  <td>
                    <div className="padding-0">
                      <input
                        type="text"
                        class="form-control"
                        value={data.wholesale_value}
                        onChange={(e) => {
                          const val = { ...data };
                          val.wholesale_value = e.target.value;
                          setData(val);
                        }}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Select a Category</td>
                  <td>
                    <div className="padding-0">
                      <Select
                        menuPosition="fixed"
                        menuShouldScrollIntoView
                        value={
                          primaryCategory?.firstLevel?.active
                            ? props.select
                            : primaryCategory?.firstLevel?.name
                            ? { name: primaryCategory?.firstLevel?.name, value: primaryCategory?.firstLevel?.value }
                            : ""
                        }
                        styles={customStyles}
                        className="basic-single "
                        classNamePrefix="select-search  "
                        placeholder={`Please Select`}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.value}
                        options={props.categoryDropdown}
                        name={"PrimayCategoryFirstLevel"}
                        onChange={(event) => {
                          const datas = { ...categoryId };
                          const val = { ...data };
                          val.category_id = event.value;
                          setData(val);
                          const filterData = props?.categoryDropDownData?.filter(
                            (ui) => ui?.category_id == event?.value,
                          );
                          if (filterData?.[0]?.subCategories?.length > 0) {
                            setPrimaryCategory({
                              firstLevel: {
                                name: filterData[0]?.description,
                                value: filterData[0]?.category_id,
                                subCategories: filterData[0]?.subCategories,
                              },
                            });
                            if (props?.select) {
                              props?.setSelect("");
                            }
                          } else {
                            datas.value = event;
                            props.setSelect(datas.value);
                            setPrimaryCategory({ firstLevel: { active: true } });
                          }
                        }}
                      />
                      {props?.errors?.firstLevel ? <p style={{ color: "red" }}>{props?.errors?.firstLevel} </p> : ""}
                    </div>
                  </td>
                </tr>
                {primaryCategory?.firstLevel?.subCategories?.length > 0 ? (
                  <tr>
                    <td>Category Level 2</td>
                    <td>
                      <div className="padding-0">
                        <Select
                          menuPosition="fixed"
                          menuShouldScrollIntoView
                          styles={customStyles}
                          value={
                            primaryCategory?.secondLevel?.active
                              ? { description: props?.select?.name, category_id: props?.select?.value }
                              : primaryCategory?.secondLevel?.name
                              ? {
                                  description: primaryCategory?.secondLevel?.name,
                                  category_id: primaryCategory?.secondLevel?.value,
                                }
                              : ""
                          }
                          className="basic-single  "
                          classNamePrefix="select-search "
                          placeholder={`Please Select`}
                          getOptionLabel={(option) => option.description}
                          getOptionValue={(option) => option.category_id}
                          options={primaryCategory?.firstLevel?.subCategories}
                          name={"PrimayCategorySecondLevel"}
                          onChange={(event) => {
                            const datas = { ...categoryId };
                            const val = { ...data };
                            val.category_id = event.category_id;
                            setData(val);
                            const filterData = primaryCategory?.firstLevel?.subCategories?.filter(
                              (ui) => ui?.category_id == event?.category_id,
                            );
                            if (filterData?.[0]?.subCategories?.length > 0) {
                              setPrimaryCategory({
                                firstLevel: { ...primaryCategory?.firstLevel },
                                secondLevel: {
                                  name: filterData[0]?.description,
                                  value: filterData[0]?.category_id,
                                  subCategories: filterData[0]?.subCategories,
                                },
                              });
                              if (props?.select) {
                                props?.setSelect("");
                              }
                            } else {
                              datas.value = event;
                              props.setSelect({ name: event.description, value: event.category_id });
                              setPrimaryCategory({
                                firstLevel: { ...primaryCategory?.firstLevel },
                                secondLevel: { active: true },
                              });
                            }
                          }}
                        />
                        {props?.errors?.secondLevel ? (
                          <p style={{ color: "red" }}>{props?.errors?.secondLevel} </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </td>
                  </tr>
                ) : (
                  ""
                )}
                {primaryCategory?.secondLevel?.subCategories?.length > 0 ? (
                  <tr>
                    <td>Category Level 3</td>
                    <td>
                      <div className="padding-0">
                        <Select
                          menuPosition="fixed"
                          menuShouldScrollIntoView
                          value={
                            primaryCategory?.thirdLevel?.active
                              ? { description: props?.select?.name, category_id: props?.select?.value }
                              : ""
                          }
                          className="basic-single  "
                          classNamePrefix="select-search  "
                          placeholder={`Please Select`}
                          styles={customStyles}
                          getOptionLabel={(option) => option.description}
                          getOptionValue={(option) => option.category_id}
                          options={primaryCategory?.secondLevel?.subCategories}
                          name={"PrimayCategoryThirdLevel"}
                          onChange={(event) => {
                            const datas = { ...categoryId };
                            const val = { ...data };
                            val.category_id = event.category_id;
                            setData(val);
                            datas.value = event;
                            props.setSelect({ name: event.description, value: event.category_id });
                            setPrimaryCategory({ ...primaryCategory, thirdLevel: { active: true } });
                          }}
                        />
                        {props?.errors?.thirdLevel ? <p style={{ color: "red" }}>{props?.errors?.thirdLevel} </p> : ""}
                      </div>
                    </td>
                  </tr>
                ) : (
                  ""
                )}
                <tr>
                  <td>Manufacturer</td>
                  <td>
                    <div className="padding-0">
                      <Select
                        className="basic-single "
                        classNamePrefix="select-search"
                        options={manufactureOptions}
                        value={manufactureValue}
                        menuPosition="fixed"
                        menuShouldScrollIntoView
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.value}
                        onChange={(e) => {
                          const val = { ...data };
                          val.mfr_id = e.value;
                          setData(val);
                          setManufactureValue(e);
                        }}
                        name="manufacturer"
                        placeholder={"Select Manufacturer"}
                        styles={customStyles}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Condition</td>
                  <td>
                    <div className="padding-0">
                      <Select
                        className="basic-single "
                        classNamePrefix="select-search"
                        options={conditionOptions}
                        value={conditionValue}
                        menuPosition="fixed"
                        menuShouldScrollIntoView
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.value}
                        onChange={(e) => {
                          const val = { ...data };
                          setTypeCondition(e.name);
                          if (e.name != "Others") {
                            val.conditionType = e.name;
                            setData(val);
                          }
                          if (e.name === "Others") {
                            val.conditionType = "";
                            setData(val);
                          }
                          setConditionValue(e);
                        }}
                        name="planePart"
                        placeholder="Select Condition"
                        styles={customStyles}
                      />
                    </div>
                  </td>
                </tr>
                {conditionValue.name === "Others" && (
                  <tr>
                    <td></td>
                    <td>
                      <div className="padding-0">
                        <input
                          type="text"
                          value={data.conditionType}
                          placeholder="Enter Condition Here"
                          className="form-control"
                          onChange={(e) => {
                            const val = { ...data };
                            val.conditionType = e.target.value;
                            setData(val);
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                )}
                <tr>
                  <td>Sale Type</td>
                  <td>
                    <div className="padding-0">
                      <Select
                        className="basic-single "
                        classNamePrefix="select-search"
                        options={auctionTypeOptions}
                        value={SaleTypeValue}
                        menuPosition="fixed"
                        menuShouldScrollIntoView
                        getOptionLabel={(option) => option.description}
                        getOptionValue={(option) => option.at_id}
                        onChange={(e) => {
                          const val = { ...data };
                          val.at_id = e.at_id;
                          setData(val);
                          setSaleTypeValue(e);
                        }}
                        name="planePart"
                        placeholder="Select Sale Type"
                        styles={customStyles}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>AI Description</td>
                  <td>
                    <div className="padding-0">
                      <textarea
                        type="text"
                        rows={4.5}
                        class="form-control"
                        value={data.ai_description}
                        onChange={(e) => {
                          const val = { ...data };
                          val.ai_description = e.target.value;
                          setData(val);
                        }}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>AI Manufacturer</td>
                  <td>
                    <div className="padding-0">
                      <textarea
                        type="text"
                        rows={4.5}
                        class="form-control"
                        value={data.mfr_description}
                        onChange={(e) => {
                          const val = { ...data };
                          val.mfr_description = e.target.value;
                          setData(val);
                        }}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>AI Carbon Footprint</td>
                  <td>
                    <div className="padding-0">
                      <textarea
                        type="text"
                        rows={4.5}
                        class="form-control"
                        value={data.co_description}
                        onChange={(e) => {
                          const val = { ...data };
                          val.co_description = e.target.value;
                          setData(val);
                        }}
                      />
                    </div>
                  </td>
                </tr>
              </table>
            </div>

            <div class="drc-right w-50">
              <table class="table modal-product-detail-table mb-0">
                <colgroup>
                  <col style={{ width: "30%" }} />
                  <col style={{ width: "70%" }} />
                </colgroup>
                <tr>
                  <td>Contact</td>
                  <td>
                    <div className="padding-0">
                      <input
                        type="text"
                        class="form-control"
                        value={data.c_name}
                        onChange={(e) => {
                          const val = { ...data };
                          val.c_name = e.target.value;
                          setData(val);
                        }}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Phone</td>
                  <td>
                    <div className="padding-0">
                      <input
                        type="text"
                        class="form-control"
                        value={data.c_phone}
                        onChange={(e) => {
                          const val = { ...data };
                          val.c_phone = e.target.value;
                          setData(val);
                        }}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td>
                    <div className="padding-0">
                      <input
                        type="text"
                        class="form-control"
                        value={data.c_email}
                        onChange={(e) => {
                          const val = { ...data };
                          val.c_email = e.target.value;
                          setData(val);
                        }}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Location Address</td>
                  <td>
                    <div className="padding-0">
                      <input
                        type="text"
                        class="form-control"
                        value={data.address_1}
                        onChange={(e) => {
                          const val = { ...data };
                          val.address_1 = e.target.value;
                          setData(val);
                        }}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Location City</td>
                  <td>
                    <div className="padding-0">
                      <input
                        type="text"
                        class="form-control"
                        value={data.city}
                        onChange={(e) => {
                          const val = { ...data };
                          val.city = e.target.value;
                          setData(val);
                        }}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Location Country</td>
                  <td>
                    <div className="padding-0">
                      <Select
                        className="basic-single "
                        classNamePrefix="select-search"
                        options={countryOptions}
                        value={countryValue}
                        menuPosition="fixed"
                        menuShouldScrollIntoView
                        getOptionLabel={(option) => option.description}
                        getOptionValue={(option) => option.old}
                        onChange={(e) => {
                          const val = { ...data };
                          val.country_id = e.old;
                          if (e.description != "United States") {
                            val.state_id = 1001;
                            setStateValue({
                              description: "International",
                              old: 1001,
                            });
                          } else {
                            val.state_id = 0;
                            setStateValue({
                              description: "Select State",
                              old: 0,
                            });
                          }
                          setData(val);
                          setCountryValue(e);
                        }}
                        name="planePart"
                        placeholder="Select Country"
                        styles={customStyles}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Location State</td>
                  <td>
                    <div className="padding-0">
                      <Select
                        className="basic-single "
                        classNamePrefix="select-search"
                        menuPosition="fixed"
                        menuShouldScrollIntoView
                        options={
                          countryValue?.description === "United States"
                            ? stateOptions.filter((val) => val.description != "International")
                            : [
                                {
                                  description: "International",
                                  old: "1001",
                                },
                              ]
                        }
                        value={stateValue}
                        getOptionLabel={(option) => option.description}
                        getOptionValue={(option) => option.old}
                        onChange={(e) => {
                          const val = { ...data };
                          val.state_id = e.old;
                          setData(val);
                          setStateValue(e);
                        }}
                        name="planePart"
                        placeholder="Select State"
                        styles={customStyles}
                      />
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>Location Security : </td>
                  <td>
                    <div class="page-widget-body mt-3">
                      <div class="pl-flex-row">
                        <div class="pl-flex-head text-center " style={{ background: "#47ad1d", color: "#fff" }}>
                          {locSec === 0 ? (
                            props?.locationDetails?.location1
                          ) : locSec === 1 ? (
                            props?.locationDetails?.location2
                          ) : locSec === 2 ? (
                            props?.locationDetails?.location3
                          ) : locSec === 3 ? (
                            <div dangerouslySetInnerHTML={{ __html: props?.locationDetails?.location4?.toString() }} />
                          ) : (
                            "Loading..."
                          )}
                        </div>
                        <div class="table-responsive">
                          <table class="table align-middle product-table">
                            <colgroup>
                              <col />
                            </colgroup>
                            <tbody>
                              <tr>
                                <td>
                                  <div class="td-radio-group">
                                    <div class="form-check radio">
                                      <input
                                        class="form-check-input cursor-pointer"
                                        type="radio"
                                        name="flexRadioDefault11"
                                        id="continent1"
                                        checked={locSec === 0 ? true : false}
                                        onChange={(e) => {
                                          setLocSec(0);
                                          handleLocationChange(0);
                                        }}
                                      />
                                      <label class="form-check-label" for="continent1">
                                        Continent
                                      </label>
                                    </div>
                                    <div class="form-check radio">
                                      <input
                                        class="form-check-input cursor-pointer"
                                        type="radio"
                                        name="flexRadioDefault11"
                                        id="region1"
                                        checked={locSec === 1 ? true : false}
                                        onChange={(e) => {
                                          setLocSec(1);
                                          handleLocationChange(1);
                                        }}
                                      />
                                      <label class="form-check-label" for="region1">
                                        Region
                                      </label>
                                    </div>
                                    <div class="form-check radio">
                                      <input
                                        class="form-check-input cursor-pointer"
                                        type="radio"
                                        name="flexRadioDefault11"
                                        id="cityStateCountry1"
                                        checked={locSec === 2 ? true : false}
                                        onChange={(e) => {
                                          setLocSec(2);
                                          handleLocationChange(2);
                                        }}
                                      />
                                      <label class="form-check-label" for="cityStateCountry1">
                                        City, State/Country
                                      </label>
                                    </div>
                                    <div class="form-check radio">
                                      <input
                                        class="form-check-input cursor-pointer"
                                        type="radio"
                                        name="flexRadioDefault11"
                                        id="exact1"
                                        checked={locSec === 3 ? true : false}
                                        onChange={(e) => {
                                          setLocSec(3);
                                          handleLocationChange(3);
                                        }}
                                      />
                                      <label class="form-check-label" for="exact1">
                                        Exact
                                      </label>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td> Description</td>
                  <td>
                    <div className="padding-0 ck_content_max_width">
                      <Editor
                        name="overall_description"
                        className={`ck-content `}
                        onChange={(e) => {
                          let val = { ...data };
                          val.description = e;
                          setData(val);
                        }}
                        editorLoaded={true}
                        value={data?.description}
                      />
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </Modal.Body>
        <div class="custom-modal-dividor"></div>
        <div className="custom-modal-footer d-grid gap-2 d-md-flex align-items-center justify-content-md-end">
          <button
            class="btn btn-link me-md-2 global-cancel-button"
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => props.onHide()}
          >
            Cancel
          </button>
          <NextButton
            label="Save"
            classData="btn btn-default"
            handleSubmit={props.handleUpdate}
            loading={props.loading}
          />
        </div>
      </div>
    </Modal>
  );
};

export default OverallDetailModal;
