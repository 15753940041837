import React, { useRef } from "react";
import { Accordion, Offcanvas } from "react-bootstrap";
import close from "../../../include/images/close.svg";
import trash from "../../../include/images/trash-24x24.svg";
import LoadingBar from "react-top-loading-bar";
import { useState } from "react";
import { toast } from "react-toastify";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import AlertError from "../../../common/alerts/alertError";
import { updateRegionPreference } from "../../../store/preferences";
import emptyChat from "../../../include/images/chat-empty.svg";

import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";

const RegionPreferences = ({ selectedRegion, setSelectedRegion, ...props }) => {
  const ref = useRef();
  const [selectAll, setSelectAll] = useState({});

  const handleSaveRegion = () => {
    if (selectedRegion?.length) {
      props.setLoading(true);
      ref?.current?.continuousStart();
      const id = props.selectedId?.user_id || props?.selectedId;
      const a = selectedRegion.map((item) => item.split("*")?.[0]);
      const data = {
        region_preferences: a,
      };
      props.updateRegionPreference(id, data, (res) => {
        if (res && res.status == 200) {
          props.setLoading(false);
          ref?.current?.complete();
          props.setRegionPrefData([]);
          props.onHide();
          props?.setBuyer(false);
          toast(<AlertSuccess message={"Information Saved"} />);
        } else {
          toast(
            <AlertError message={res && res.data && res.data.message ? res.data?.message : "Something Went Wrong"} />,
          );
          props.setLoading(false);
          ref?.current?.complete();
        }
      });
    }
  };

  const americaData = [];

  const a =
    props.data &&
    props.data.length > 0 &&
    props.data
      .filter(
        (item) =>
          item.geoRegion == "Caribbean" || item.geoRegion == "South America" || item.geoRegion == "Central America",
      )
      .map((item1) => americaData.push(...item1.subRegion));

  return (
    <>
      <LoadingBar height={5} color="#47AD1D" ref={ref} />
      <Offcanvas
        className="offcanvas offcanvas-end xl-offcanvas border-0"
        id="buyerPreferences"
        placement="end"
        backdropClassName="upload-documnets-backdrop"
        show={props.show}
        onHide={() => {
          props?.setBuyer(false);
          props.onHide();
        }}
        enforceFocus={false}
      >
        <Offcanvas.Header className="header-buyer-pref">
          <h5 class="offcanvas-title">Buyer Region Preferences</h5>

          {!props.loadingSkeleton && (
            <a
              href="javascript:void(0)"
              class="btn btn-link me-md-2 global-cancel-button cancel-user-pref"
              onClick={() => {
                props?.setBuyer(false);
                props.onHide();
              }}
            >
              {" "}
              Cancel{" "}
            </a>
          )}
          {!props.loadingSkeleton && (
            <a
              href="javascript:void(0)"
              class={`btn btn-border-green ${
                props.loadingSkeleton || (selectedRegion?.length === 0 && "disabledRegion")
              }`}
              onClick={() => handleSaveRegion()}
            >
              {" "}
              Save{" "}
            </a>
          )}
        </Offcanvas.Header>

        <Offcanvas.Body className="offcanvas-body position-relative p-4">
          <div class="dashboard-widget-row-container w-100 d-lg-flex flex-lg-wrap">
            <div class="dwrc-left">
              <h3 class="widget-title text-black">Add/Edit Region</h3>
              <Accordion className="accordion left-arrow-accordion small-checkbox">
                {props.loadingSkeleton ? (
                  props.data &&
                  props.data.length > 0 &&
                  props.data.map((item, i) => (
                    <>
                      <div>
                        <Skeleton height="20px" width="75%" className="mb-3" />
                      </div>
                    </>
                  ))
                ) : (
                  <>
                    {props.data &&
                      props.data.length > 0 &&
                      props.data.map((item, i) => (
                        <>
                          {item.geoRegion == "North America" && (
                            <Accordion.Item className="accordion-item" eventKey={i}>
                              <Accordion.Header className="accordion-header">United States</Accordion.Header>

                              <Accordion.Body className="accordion-body">
                                <div class="form-check d-flex">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    checked={selectAll["US"] ? true : false}
                                    id={`region${i}`}
                                  />
                                  <label
                                    onClick={() => {
                                      const data = { ...selectAll };
                                      const data1 = [...selectedRegion];
                                      data["US"] = selectAll["US"] ? !selectAll["US"] : true;
                                      if (selectAll["US"]) {
                                        item.subRegion &&
                                          item.subRegion.length > 0 &&
                                          item.subRegion.map((s) => {
                                            data1.splice(
                                              data1.indexOf(`${s.region_id}*United States*${s.description}`),
                                              1,
                                            );
                                          });
                                        setSelectedRegion(data1);
                                      }
                                      if (!selectAll["US"]) {
                                        item.subRegion &&
                                          item.subRegion.length > 0 &&
                                          item.subRegion.map((s) => {
                                            if (!data1.includes(`${s.region_id}*United States*${s.description}`)) {
                                              data1.push(`${s.region_id}*United States*${s.description}`);
                                              setSelectedRegion(data1);
                                            }
                                          });
                                      }
                                      setSelectAll(data);
                                    }}
                                    class="form-check-label"
                                    for={`region${i}`}
                                  >
                                    Select All
                                  </label>
                                </div>
                                {item &&
                                  item.subRegion &&
                                  item.subRegion.length > 0 &&
                                  item.subRegion.map((state, i) => (
                                    <div class="form-check d-flex">
                                      <input
                                        class="form-check-input"
                                        type="checkbox"
                                        id={`${state.region_id}${i}`}
                                        checked={selectedRegion.includes(
                                          `${state.region_id}*United States*${state.description}`,
                                        )}
                                      />
                                      <label
                                        class="form-check-label"
                                        for={`${state.region_id}${i}`}
                                        onClick={(e) => {
                                          const value = [...selectedRegion];
                                          if (
                                            !selectedRegion.includes(
                                              `${state.region_id}*United States*${state.description}`,
                                            )
                                          ) {
                                            value.push(`${state.region_id}*United States*${state.description}`);
                                            setSelectedRegion(value);
                                          } else {
                                            const a = selectedRegion.filter(
                                              (item) => item != `${state.region_id}*United States*${state.description}`,
                                            );
                                            const b = { ...selectAll };
                                            b["US"] = false;
                                            setSelectAll(b);
                                            setSelectedRegion(a);
                                          }
                                        }}
                                      >
                                        {state.description}
                                      </label>
                                    </div>
                                  ))}
                              </Accordion.Body>
                            </Accordion.Item>
                          )}
                        </>
                      ))}

                    <Accordion.Item className="accordion-item" eventKey={"78"}>
                      <Accordion.Header className="accordion-header">Americas</Accordion.Header>

                      <Accordion.Body className="accordion-body">
                        <div class="form-check d-flex">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            id={`americaSelectAll`}
                            checked={selectAll["america"] ? true : false}
                          />
                          <label
                            onClick={() => {
                              const data = { ...selectAll };
                              const data1 = [...selectedRegion];
                              data["america"] = selectAll["america"] ? !selectAll["america"] : true;
                              if (selectAll["america"]) {
                                americaData &&
                                  americaData.length > 0 &&
                                  americaData.map((s) => {
                                    data1.splice(data1.indexOf(`${s.region_id}*International*${s.description}`), 1);
                                  });
                                setSelectedRegion(data1);
                              }
                              if (!selectAll["america"]) {
                                americaData &&
                                  americaData.length > 0 &&
                                  americaData.map((s) => {
                                    if (!data1.includes(`${s.region_id}*International*${s.description}`)) {
                                      data1.push(`${s.region_id}*International*${s.description}`);
                                      setSelectedRegion(data1);
                                    }
                                  });
                              }
                              setSelectAll(data);
                            }}
                            class="form-check-label"
                            for={`americaSelectAll`}
                          >
                            Select All
                          </label>
                        </div>
                        {americaData &&
                          americaData.length > 0 &&
                          americaData.map((item, i) => (
                            <div class="form-check d-flex">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                id={`${item.description}${i}`}
                                checked={selectedRegion.includes(`${item.region_id}*International*${item.description}`)}
                              />
                              <label
                                class="form-check-label"
                                for={`${item.description}${i}`}
                                onClick={(e) => {
                                  const value = [...selectedRegion];
                                  const data = { ...selectAll };
                                  if (!selectedRegion.includes(`${item.region_id}*International*${item.description}`)) {
                                    value.push(`${item.region_id}*International*${item.description}`);
                                    setSelectedRegion(value);
                                  } else {
                                    const n = selectedRegion.filter(
                                      (it) => it != `${item.region_id}*International*${item.description}`,
                                    );
                                    const b = { ...selectAll };
                                    b["america"] = false;
                                    setSelectAll(b);
                                    setSelectedRegion(n);
                                  }
                                }}
                              >
                                {item.description}
                              </label>
                            </div>
                          ))}
                      </Accordion.Body>
                    </Accordion.Item>

                    {props.data &&
                      props.data.length > 0 &&
                      props.data.map((item, i) => (
                        <>
                          {item.geoRegion != "Caribbean" &&
                            item.geoRegion != "North America" &&
                            item.geoRegion != "South America" &&
                            item.geoRegion != "Central America" && (
                              <Accordion.Item className="accordion-item" eventKey={i}>
                                <Accordion.Header className="accordion-header">{item.geoRegion}</Accordion.Header>

                                <Accordion.Body className="accordion-body">
                                  <div class="form-check d-flex">
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      id={`${item.geoRegion}all`}
                                      checked={selectAll[item.geoRegion]}
                                    />
                                    <label
                                      onClick={() => {
                                        const data = { ...selectAll };
                                        const data1 = [...selectedRegion];
                                        data[item.geoRegion] = selectAll[item.geoRegion]
                                          ? !selectAll[item.geoRegion]
                                          : true;
                                        if (selectAll[item.geoRegion]) {
                                          item &&
                                            item.subRegion &&
                                            item.subRegion.length > 0 &&
                                            item.subRegion.map((s) => {
                                              data1.splice(
                                                data1.indexOf(`${s.region_id}*International*${s.description}`),
                                                1,
                                              );
                                            });
                                          setSelectedRegion(data1);
                                        }
                                        if (!selectAll[item.geoRegion]) {
                                          item &&
                                            item.subRegion &&
                                            item.subRegion.length > 0 &&
                                            item.subRegion.map((s) => {
                                              if (!data1.includes(`${s.region_id}*International*${s.description}`)) {
                                                data1.push(`${s.region_id}*International*${s.description}`);
                                                setSelectedRegion(data1);
                                              }
                                            });
                                        }
                                        setSelectAll(data);
                                      }}
                                      class="form-check-label"
                                      s
                                      for={`${item.geoRegion}all`}
                                    >
                                      Select All
                                    </label>
                                  </div>
                                  {item &&
                                    item.subRegion &&
                                    item.subRegion.length > 0 &&
                                    item.subRegion.map((state, i) => (
                                      <div class="form-check d-flex">
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          id={`${state.description}${i}`}
                                          checked={selectedRegion.includes(
                                            `${state.region_id}*International*${state.description}`,
                                          )}
                                        />
                                        <label
                                          class="form-check-label"
                                          for={`${state.description}${i}`}
                                          onClick={(e) => {
                                            const value = [...selectedRegion];
                                            if (
                                              !selectedRegion.includes(
                                                `${state.region_id}*International*${state.description}`,
                                              )
                                            ) {
                                              value.push(`${state.region_id}*International*${state.description}`);
                                              setSelectedRegion(value);
                                            } else {
                                              const a = selectedRegion.filter(
                                                (ite) => ite != `${state.region_id}*International*${state.description}`,
                                              );
                                              const b = { ...selectAll };
                                              b[item.geoRegion] = false;
                                              setSelectAll(b);
                                              setSelectedRegion(a);
                                            }
                                          }}
                                        >
                                          {state.description}
                                        </label>
                                      </div>
                                    ))}
                                </Accordion.Body>
                              </Accordion.Item>
                            )}
                        </>
                      ))}
                  </>
                )}
              </Accordion>
            </div>
            <div class="dwrc-right d-flex flex-column">
              <h3 class="widget-title text-black">My Current Selection</h3>
              <div class="table-responsive mb-30">
                <table class="table table-border-radious table-bordered table-fit-content table-wrap align-middle table-large">
                  <tbody>
                    {props.loadingSkeleton ? (
                      [1, 2, 3, 4, 5].map((item) => (
                        <tr>
                          <td>
                            <Skeleton />
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                          <td>
                            <div class="td-delete-icon">
                              <Skeleton height="30px" />
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : selectedRegion && selectedRegion.length > 0 ? (
                      selectedRegion.map((t, i) => {
                        const cat = t.split("*")[1];
                        const subCat = t.split("*")[2];
                        return (
                          <tr>
                            <td>{cat == "United States" ? "United States" : "International"}</td>
                            <td>{subCat}</td>
                            <td>
                              <div
                                class="td-delete-icon"
                                onClick={() => {
                                  const data = [...selectedRegion];
                                  const b = { ...selectAll };
                                  const a = data.filter((item) => item != t);
                                  b[cat] = false;
                                  setSelectAll(b);
                                  setSelectedRegion(a);
                                }}
                              >
                                <img src={trash} alt="" />
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td>
                          <div class="pannel-body d-flex align-items-center justify-content-center mt-5 mb-5">
                            <div class="empty-pannel-box">
                              <img src={emptyChat} alt="" />
                              <h4>No Regions Selected!</h4>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div class="widgets-btn text-end mt-auto">
                {!props.loadingSkeleton && (
                  <a
                    href="javascript:void(0)"
                    class={`btn btn-default btn-lg ${
                      props.loadingSkeleton || (selectedRegion?.length === 0 && "disabledRegion")
                    }`}
                    onClick={() => handleSaveRegion()}
                  >
                    Save
                  </a>
                )}
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateRegionPreference: (id, data, callback) => dispatch(updateRegionPreference(id, data, callback)),
});

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(RegionPreferences));
