import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";

const slice = createSlice({
  name: "contract",
  initialState: {
    contract: {},
    businessUnit: {},
    enterprise: {},
    enterpriseListings: {},
    buListings: {},
    listingContracts: {},
    loading: false,
    enterpriseContracts: {},
    enterpriseContractDetail: {},
    count: {},
  },
  reducers: {
    contractRequested: (misc, action) => {
      misc.loading = true;
    },
    contractReceived: (misc, action) => {
      misc.contract = action.payload;
      misc.loading = false;
    },
    contractRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    countRequested: (misc, action) => {
      misc.loading = false;
    },
    countReceived: (misc, action) => {
      misc.count = action.payload;
    },
    countRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    enterpriseContractsRequested: (misc, action) => {
      misc.loading = true;
    },
    enterpriseContractsReceived: (misc, action) => {
      misc.enterpriseContracts = action.payload;
      misc.loading = false;
    },
    enterpriseContractsRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    enterpriseContractDetailRequested: (misc, action) => {
      misc.loading = true;
    },
    enterpriseContractDetailReceived: (misc, action) => {
      misc.enterpriseContractDetail = action.payload;
      misc.loading = false;
    },
    enterpriseContractDetailRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    businessUnitRequested: (misc, action) => {
      misc.loading = true;
    },
    businessUnitReceived: (misc, action) => {
      misc.businessUnit = action.payload;
      misc.loading = false;
    },
    businessUnitRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    buRequested: (misc, action) => {
      misc.loading = true;
    },
    buReceived: (misc, action) => {
      misc.buListings = action.payload;
      misc.loading = false;
    },
    buRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    enterpriseRequested: (misc, action) => {
      misc.loading = true;
    },
    enterpriseReceived: (misc, action) => {
      misc.enterprise = action.payload;
      misc.loading = false;
    },
    enterpriseRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    enterpriseListingsRequested: (misc, action) => {
      misc.loading = true;
    },
    enterpriseListingsReceived: (misc, action) => {
      misc.enterpriseListings = action.payload;
      misc.loading = false;
    },
    enterpriseListingsRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    listingContractsRequested: (misc, action) => {
      misc.loading = true;
    },
    listingContractsReceived: (misc, action) => {
      misc.listingContracts = action.payload;
      misc.loading = false;
    },
    listingContractsRequestFailed: (misc, action) => {
      misc.loading = false;
    },
  },
});

export const {
  contractRequested,
  contractReceived,
  contractRequestFailed,
  enterpriseRequested,
  enterpriseReceived,
  enterpriseRequestFailed,
  businessUnitRequested,
  businessUnitReceived,
  businessUnitRequestFailed,
  buRequested,
  buReceived,
  buRequestFailed,
  enterpriseListingsRequested,
  enterpriseListingsReceived,
  enterpriseListingsRequestFailed,
  listingContractsRequested,
  listingContractsReceived,
  listingContractsRequestFailed,
  enterpriseContractsRequested,
  enterpriseContractsReceived,
  enterpriseContractsRequestFailed,
  enterpriseContractDetailRequested,
  enterpriseContractDetailReceived,
  enterpriseContractDetailRequestFailed,
  countRequested,
  countReceived,
  countRequestFailed,
} = slice.actions;
export default slice.reducer;

// Action Creators
const baseUrl = "account/";

export const getContracts = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}contract`,
      method: "get",
      params,
      callback,
      onStart: contractRequested.type,
      onSuccess: contractReceived.type,
      onError: contractRequestFailed.type,
    }),
  );
};

export const getCounts = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}agreement/enterprise/count`,
      method: "get",
      callback,
      onStart: countRequested.type,
      onSuccess: countReceived.type,
      onError: countRequestFailed.type,
    }),
  );
};

export const getEnterpriseContracts = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}agreement/enterprise`,
      method: "get",
      params,
      callback,
      onStart: enterpriseContractsRequested.type,
      onSuccess: enterpriseContractsReceived.type,
      onError: enterpriseContractsRequestFailed.type,
    }),
  );
};

export const getEnterpriseContractDetail = (id, params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}agreement/enterprise/detail/${id}`,
      method: "get",
      params,
      callback,
      onStart: enterpriseContractDetailRequested.type,
      onSuccess: enterpriseContractDetailReceived.type,
      onError: enterpriseContractDetailRequestFailed.type,
    }),
  );
};

export const updateEnterpriseContractDetail = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}agreement/enterprise/detail/${id}`,
      method: "post",
      data,
      callback,
      onStart: enterpriseContractDetailRequested.type,
      onSuccess: enterpriseContractDetailReceived.type,
      onError: enterpriseContractDetailRequestFailed.type,
    }),
  );
};

export const addContracts = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}contract`,
      method: "post",
      data,
      callback,
    }),
  );
};

export const editContracts = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}contract/${id}`,
      method: "put",
      data,
      callback,
    }),
  );
};

export const uploadFile = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}contract/file/${id}`,
      method: "post",
      data,
      callback,
    }),
  );
};

export const deleteFile = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}contract/file/${id}`,
      method: "delete",
      data,
      callback,
    }),
  );
};

export const deleteContract = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}contract/${data}`,
      method: "delete",
      callback,
    }),
  );
};

export const getEnterprises = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}contract/enterprise`,
      method: "get",
      params,
      callback,
      onStart: enterpriseRequested.type,
      onSuccess: enterpriseReceived.type,
      onError: enterpriseRequestFailed.type,
    }),
  );
};

export const getListingContracts = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}contract/associated`,
      method: "get",
      params,
      callback,
      onStart: listingContractsRequested.type,
      onSuccess: listingContractsReceived.type,
      onError: listingContractsRequestFailed.type,
    }),
  );
};

export const getBusinessUnits = (id, params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}contract/businessUnit/${id}`,
      method: "get",
      params,
      callback,
      onStart: businessUnitRequested.type,
      onSuccess: businessUnitReceived.type,
      onError: businessUnitRequestFailed.type,
    }),
  );
};

export const getBUListings = (id, params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}contract/businessUnit/listing/${id}`,
      method: "get",
      params,
      callback,
      onStart: buRequested.type,
      onSuccess: buReceived.type,
      onError: buRequestFailed.type,
    }),
  );
};
export const getEnterpriseListings = (id, params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}contract/enterprise/listing/${id}`,
      method: "get",
      params,
      callback,
      onStart: enterpriseListingsRequested.type,
      onSuccess: enterpriseListingsReceived.type,
      onError: enterpriseListingsRequestFailed.type,
    }),
  );
};

export const getContractListing = createSelector(
  (state) => state.entities.contract,
  (contract) => contract,
);
