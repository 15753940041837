import React, { useRef, useState } from "react";

import closeIcon from "../../../include/images/close-12x12.svg";
import darkStarIcon from "../../../include/images/dark-star-icon.svg";
import darkEditIcon from "../../../include/images/dark-edit-icon.svg";
import selectedIcon from "../../../include/images/yellow-star-icon.svg";
import maxim from "../../../include/images/dark-maximize-icon.svg";
import scribble from "../../../include/images/Scribble.svg";
import crop from "../../../include/images/dark-crop-icon.svg";
import rotate from "../../../include/images/dark-rotate-cw-icon.svg";
import trash from "../../../include/images/trash-20x20.svg";
import save from "../../../include/images/save.svg";
import close from "../../../include/images/close-icon.svg";
import addBtn from "../../../include/images/add-button-image.png";
import cropNew from "../../../include/images/crop-new.svg";
import { Offcanvas, OverlayTrigger, Popover } from "react-bootstrap";
import _ from "lodash";
import DeleteModal from "../deleteModalPics";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const UploadImage = (props) => {
  const removeUploaded = (res) => {
    const a = props.imageUploaded.filter((item) => item.name !== res.name);
    props.setImageUploaded(a);
  };

  return (
    <Offcanvas
      placement="end"
      show={props.show}
      onHide={props.onHide}
      className={`offcanvas offcanvas-end xl-offcanvas border-0`}
      id="uploadImageOffcanvas"
      enforceFocus={false}
      // backdrop={props.uploadImageModal ? true : false}
      backdropClassName={`upload-images-backdrop`}
    >
      <Offcanvas.Header className="offcanvas-header">
        <h5 class="offcanvas-title">Upload Image</h5>
        <button
          type="button"
          class="offcanvas-close-btn d-flex align-items-center justify-content-center"
          data-bs-dismiss="offcanvas"
          onClick={props.onHide}
        >
          <img src={closeIcon} alt="" />
        </button>
      </Offcanvas.Header>
      <div class="offcanvas-body p-0 position-relative">
        {props.progress === "uploading" ? (
          <div class="progress-row d-flex align-items-center justify-content-between">
            <div class="row-progress-label" role="progressbar">
              Please wait while image is being uploaded
            </div>
          </div>
        ) : props.progress === "completed" ? (
          <div class="progress-row d-flex align-items-center justify-content-between">
            <div class="row-progress-label" role="progressbar">
              Images have been uploaded successfully
            </div>
          </div>
        ) : (
          ""
        )}
        <div class="offcanvas-widget-row">
          <div class="owr-column">
            {props.loadingMain ? (
              <Skeleton height="100px" width="100%" />
            ) : (
              <div class="large-upload-box position-relative d-flex flex-column align-items-center justify-content-center">
                <label for="formFileSm">+ Upload Image</label>
                <div class="supported-file-format">Supported file format : Jpeg, Jpg, Png</div>
                <input
                  class="form-control position-absolute"
                  id="formFileSm"
                  type="file"
                  multiple={props?.type == "aviation" ? true : false}
                  accept=".jpg,.jpeg,.png"
                  onChange={(e) => {
                    props.setImageUploaded([...e.target.files]);
                  }}
                  onClick={(e) => (e.target.value = null)}
                />
              </div>
            )}
          </div>
          <div class="owr-column">
            {props.imageUploaded && props.imageUploaded.length > 0 ? (
              <div className="selected-image-parent d-flex flex-wrap">
                {props.imageUploaded.map((item, i) => (
                  <>
                    <div class="selected-image">
                      <img src={`${URL.createObjectURL(props.imageUploaded[i])}`} alt="" />
                      <a
                        style={{ cursor: "pointer" }}
                        class="selected-image-closed"
                        onClick={() => {
                          if (props?.type == "aviation") {
                            removeUploaded(props.imageUploaded[i]);
                          } else {
                            props.setImageUploaded([]);
                          }
                        }}
                      >
                        <img src={close} alt="" />
                      </a>
                    </div>
                  </>
                ))}
              </div>
            ) : (
              ""
            )}
          </div>

          <div class="owr-column-button d-grid gap-2 d-md-flex justify-content-md-end mt-40">
            {props.loadingMain ? (
              <Skeleton width="140px" height="40px" />
            ) : (
              <button
                type="button"
                class={`btn btn-default ${props.loadingUpload ? "btn-disabled" : ""}`}
                onClick={() => !props.loadingUpload && props.handleImageUploader()}
              >
                {props.loadingUpload ? "Please Wait" : "Save"}
              </button>
            )}
          </div>
        </div>
        {!props?.type == "aviation" ? (
          <div class="offcanvas-widget-row">
            <div class="page-title mb-3">Image</div>
            <ul class="imageCard-list d-flex flex-wrap">
              {props?.locationDetails?.location_photo && (
                <li class="imageCard-item">
                  <div class="imageCard-box w-100 h-100 d-flex flex-column">
                    <div class="imageCard-image-box position-relative">
                      <img
                        id="myImage"
                        class="imageCard-image image"
                        src={props?.locationDetails?.location_photo}
                        alt=""
                      />
                    </div>
                    <div class="imageCard-icon-list d-flex flex-wrap align-items-center justify-content-center">
                      <div class="imageCard-icon-item">
                        <div class="imageCard-icon d-flex position-relative align-items-center justify-content-center">
                          <img src={trash} alt="" onClick={props.handleDeleteImage} />
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              )}
            </ul>
          </div>
        ) : (
          ""
        )}
        <div
          class={`popover-overlay ${props.optionType === "edit" || props.optionType === "delete" ? "open " : ""}`}
        ></div>
      </div>
    </Offcanvas>
  );
};

export default UploadImage;
