import React, { useEffect, useState } from "react";
import NextButton from "../../../common/form/nextButton";
import {
  addMessage,
  buyerInterest,
  callEmailClicker,
  changeUnsubscribe,
  deleteMessage,
  emailClickersDetail,
  getAllPushEmailClickers,
  getPushEmailClickers,
  getRecipient,
  getUserDetail,
  quickEdit,
} from "../../../store/emailClickers";
import { toast } from "react-toastify";
import AlertError from "../../../common/alerts/alertError";
import { connect } from "react-redux";
import greenEmail from "../../../include/images/green-mail.svg";
import notVerified from "../../../include/images/mail-not-verified.svg";
import invalid from "../../../include/images/mail-invalid.svg";
import spamEmail from "../../../include/images/mail-spammed-blocked.svg";
import purpleEmail from "../../../include/images/mail-purple.svg";
import DateFormat from "../../../common/DateFormat/dateFormat";
import Skeleton from "react-loading-skeleton";
import { ref } from "joi";
import extLink from "../../../include/images/external-link.svg";
import MoreModal from "../../../layouts/modals/pushEmailClickers/moreModal";
import UserContactInformationModal from "../../../layouts/modals/pushEmailClickers/userContactInformationModal";
import { getCountry, getState } from "../../../store/users";
import msgSqr from "../../../include/images/message-square.svg";
const PushEmailClickersNew = (props) => {
  const [showTable, setShowTable] = useState(false);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [listingData, setListingData] = useState([]);
  const [showMoreModal, setShowMoreModal] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [recipientList, setRecipientList] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [quickEditLoading, setQuickEditLoading] = useState(false);
  const [order, setOrder] = useState("");
  const [sort, setSort] = useState("");
  const [enable, setEnable] = useState(false);
  const [contactInformation, setContactInformation] = useState({
    customer_name: "",
    customer_city: "",
    customer_email: "",
    customer_phone: "",
    customer_cell: "",
    linkedin_url: "",
    state_id: 0,
    country_id: 0,
  });
  const [viewAll, setViewAll] = useState(false);

  const count = props?.count?.pushEmailClicker;
  useEffect(() => {
    if (showContactModal) {
      props.getCountry((res) => {
        if (res.status === 200) {
          setCountryOptions(res.data);
        }
      });
      props.getState((res) => {
        if (res.status === 200) {
          setStateOptions(res.data);
        }
      });
    }
  }, [showContactModal]);

  const userDetailData = props?.emailClickersDetail?.user?.data;

  const toggleMoreModal = () => {
    setShowMoreModal(!showMoreModal);
  };

  //Toggle User Contact Information Modal
  const toggleContactInformationModal = () => {
    setShowMoreModal(!showMoreModal);
    setShowContactModal(!showContactModal);
  };

  const Count =
    props &&
    props.emailClickersDetail &&
    props.emailClickersDetail.count &&
    props.emailClickersDetail.count.data &&
    props.emailClickersDetail.count.data.ct;

  const fetchEmailClickers = () => {
    const payload = {
      page: page,
      limit: 50,
    };
    if (sort) {
      payload.sort = sort;
    }
    if (order) {
      payload.order = order;
    }
    setLoading(true);
    // props.refStart();
    props.getPushEmailClickers(props.auctionId, payload, (res) => {
      if (res.status === 200) {
        setListingData(res?.data?.data);
        setLoading(false);
        // props.refStop();
        setShowTable(true);
      } else {
        setLoading(false);
        // props.refStop();
        toast(<AlertError message={res?.data?.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const fetchAllEmailClickers = () => {
    setLoading(true);
    // props.refStart();
    props.getAllPushEmailClickers(props.auctionId, (res) => {
      if (res.status === 200) {
        setListingData(res?.data?.data);
        setLoading(false);
        // props.refStop();
        setShowTable(true);
      } else {
        setLoading(false);
        // props.refStop();
        toast(<AlertError message={res?.data?.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const handleBuyingInterest = (userId) => {
    const payload = {
      page: page,
      limit: 50,
    };
    if (sort) {
      payload.sort = sort;
    }
    if (order) {
      payload.order = order;
    }
    props.refStart();
    props.buyerInterest(props.auctionId, userId, (res) => {
      if (res.status === 200) {
        props.getPushEmailClickers(props.auctionId, payload, (res) => {
          if (res.status === 200) {
            props.refStop();
            setListingData(res?.data?.data);
          } else {
            props.refStop();
            toast(<AlertError message="Something Went Wrong" />);
          }
        });
      } else {
        props.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  useEffect(() => {
    if (props?.auctionId) {
      fetchEmailClickers();
    }
  }, [page, props?.auctionId]);

  useEffect(() => {
    if (enable) {
      const payload = viewAll
        ? {}
        : {
            page: 1,
            limit: 50,
          };
      if (sort) {
        payload.sort = sort;
      }
      if (order) {
        payload.order = order;
      }
      setPage(1);
      setLoading(true);
      // props.refStart();
      props.getPushEmailClickers(props.auctionId, payload, (res) => {
        if (res.status === 200) {
          setListingData(res?.data?.data);
          setLoading(false);
          // props.refStop();
          setShowTable(true);
        } else {
          setLoading(false);
          // props.refStop();
          toast(<AlertError message={res?.data?.message ? res.data.message : "Something Went Wrong"} />);
        }
      });
    }
  }, [sort, order]);

  const handleCallEmailClicker = (userId) => {
    const payload = {
      page: page,
      limit: 50,
    };
    if (sort) {
      payload.sort = sort;
    }
    if (order) {
      payload.order = order;
    }
    props.refStart();
    props.callEmailClicker(props.auctionId, userId, (res) => {
      if (res.status === 200) {
        props.getPushEmailClickers(props.auctionId, payload, (res) => {
          if (res.status === 200) {
            props.refStop();
            setListingData(res?.data?.data);
          } else {
            props.refStop();
            toast(<AlertError message="Something Went Wrong" />);
          }
        });
      } else {
        props.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const fetchUserDetail = (userId) => {
    props.refStart();
    props.getUserDetail(userId, (res) => {
      if (res.status === 200) {
        // setUserDetailData(res.data.data);
        props.getRecipient((res) => {
          if (res.status === 200) {
            setRecipientList(res.data.data);
            props.refStop();
            toggleMoreModal();
          } else {
            toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
            props.refStop();
            toggleMoreModal();
          }
        });
      } else {
        toast(<AlertError message={res.data.message ? res.data.messge : "Something Went Wrong"} />);
      }
    });
  };

  //Quick Edit
  const quickEditHandle = (userId) => {
    props.refStart();
    setQuickEditLoading(true);
    props.quickEdit(userId, contactInformation, (res) => {
      if (res.status === 200) {
        props.getUserDetail(userId, (res) => {
          if (res.status === 200) {
            props.refStop();
            setQuickEditLoading(false);
            toggleContactInformationModal();
          } else {
            props.refStop();
            setQuickEditLoading(false);
            toggleContactInformationModal();
          }
        });
      } else {
        props.refStop();
        setQuickEditLoading(false);
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  return (
    <div class="details-page-widget-block" id={props.id}>
      <div class="page-widget-head d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center space-between w-100" style={{ justifyContent: "space-between" }}>
          <div class="d-flex align-items-center ">
            <div class={"page-widget-label text-capitalize cursor-pointer"}>
              Push Email Clickers{" "}
              {!props.loadingMain && count > 0 ? `(${count})` : props.loadingMain ? "" : `(${count ? count : "0"})`}{" "}
            </div>
          </div>
          <div className="d-flex align-items-center">
            {!props?.loadingMain && !loading && !viewAll && count > 0 ? (
              <div class="btn-block  ">
                <a
                  class="btn btn-primary btn-sm"
                  onClick={() => {
                    setViewAll(true);
                    fetchAllEmailClickers();
                  }}
                >
                  View All
                </a>
              </div>
            ) : (
              ""
            )}
            {!props?.loadingMain && !loading && count > 0 ? (
              <div class="btn-block  ">
                <a
                  style={{ backgroundColor: "#47ad1d", border: "solid 1px #47ad1d" }}
                  href={
                    process.env.REACT_APP_MODE == "development"
                      ? `https://admin-dev.salvex.com/admin/_listings/views/all_participants_email.cfm?aucID=${props?.auctionId}&email_clickers=1`
                      : `https://admin.salvex.com/admin/_listings/views/all_participants_email.cfm?aucID=${props?.auctionId}&email_clickers=1`
                  }
                  target="_blank"
                  class="btn btn-primary btn-sm"
                >
                  Send Message To All
                </a>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {loading ? (
        <div class="table-responsive">
          <table class="large-table">
            <colgroup>
              <col style={{ width: "20%", minWidth: "20%", maxWidth: "20%" }} />
              <col style={{ width: "15%", minWidth: "15%", maxWidth: "15%" }} />
              <col style={{ width: "20%", minWidth: "20%", maxWidth: "20%" }} />
              <col style={{ width: "15%", minWidth: "15%", maxWidth: "15%" }} />

              <col style={{ width: "30%", minWidth: "30%", maxWidth: "30%" }} />
            </colgroup>
            <thead>
              <tr>
                <th>
                  <Skeleton />
                </th>
                <th>
                  <Skeleton />
                </th>
                <th>
                  <Skeleton />
                </th>
                <th>
                  <Skeleton />
                </th>
                <th>
                  <Skeleton />
                </th>
              </tr>
            </thead>
            <tbody>
              {[1, 2, 3].map(() => (
                <tr>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div class={listingData?.length > 0 && "page-widget-body mt-3"}>
          <div class={listingData?.length > 0 && "table-responsive position-relative mt-3"}>
            {listingData.length > 0 && (
              <table class="large-table">
                <colgroup>
                  <col style={{ width: "20%", minWidth: "20%", maxWidth: "20%" }} />
                  <col style={{ width: "15%", minWidth: "15%", maxWidth: "15%" }} />
                  <col style={{ width: "20%", minWidth: "20%", maxWidth: "20%" }} />
                  <col style={{ width: "15%", minWidth: "15%", maxWidth: "15%" }} />

                  <col style={{ width: "30%", minWidth: "30%", maxWidth: "30%" }} />
                </colgroup>
                <thead>
                  <tr>
                    <th>
                      <a href="javascript:void(0)" onClick={() => setSort("customer_name")} class="sort-by">
                        Buyer
                        <div
                          class={
                            sort === "customer_name" && order === "asc" ? "ascending-icon active" : "ascending-icon"
                          }
                          onClick={() => {
                            setEnable(true);
                            setOrder("asc");
                          }}
                        ></div>
                        <div
                          class={
                            sort === "customer_name" && order === "desc" ? "descending-icon active" : "descending-icon"
                          }
                          onClick={() => {
                            setEnable(true);
                            setOrder("desc");
                          }}
                        ></div>
                      </a>
                    </th>
                    <th>
                      <a href="javascript:void(0)" class="sort-by">
                        Contact Info
                      </a>
                    </th>
                    <th>
                      <a href="javascript:void(0)" onClick={() => setSort("customer_company")} class="sort-by">
                        Company
                        <div
                          class={
                            sort === "customer_company" && order === "asc" ? "ascending-icon active" : "ascending-icon"
                          }
                          onClick={() => {
                            setEnable(true);
                            setOrder("asc");
                          }}
                        ></div>
                        <div
                          class={
                            sort === "customer_company" && order === "desc"
                              ? "descending-icon active"
                              : "descending-icon"
                          }
                          onClick={() => {
                            setEnable(true);
                            setOrder("desc");
                          }}
                        ></div>
                      </a>
                    </th>
                    <th>
                      <a href="javascript:void(0)" onClick={() => setSort("profitBuying")} class="sort-by">
                        Profit
                        <div
                          class={
                            sort === "profitBuying" && order === "asc" ? "ascending-icon active" : "ascending-icon"
                          }
                          onClick={() => {
                            setEnable(true);
                            setOrder("asc");
                          }}
                        ></div>
                        <div
                          class={
                            sort === "profitBuying" && order === "desc" ? "descending-icon active" : "descending-icon"
                          }
                          onClick={() => {
                            setEnable(true);
                            setOrder("desc");
                          }}
                        ></div>
                      </a>
                    </th>
                    <th>
                      <a href="javascript:void(0)" class="sort-by"></a>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {listingData.map((val) => (
                    <tr>
                      <td>
                        <div
                          class={`  ${val?.customer?.customer_name ? "cursor-pointer" : ""}`}
                          onClick={() => {
                            if (val?.customer?.customer_name) {
                              if (process.env.REACT_APP_MODE == "development") {
                                window.open(
                                  `https://admin-dev.salvex.com/admin/_user/_edit.cfm?user_id=${val?.customer?.user_id}`,
                                );
                              } else {
                                window.open(
                                  `https://admin.salvex.com/admin/_user/_edit.cfm?user_id=${val?.customer?.user_id}`,
                                );
                              }
                            }
                          }}
                        >
                          <span
                            class={` fw-medium green-text  ${val?.customer?.customer_name ? "cursor-pointer" : ""}`}
                          >
                            {" "}
                            {val?.customer?.customer_name ? val?.customer?.customer_name : ""}
                          </span>

                          <div className="d-block">
                            {val?.customer?.customer_state === "International" ||
                            val?.customer?.customer_state === null ? (
                              <>
                                <div>
                                  {val?.customer?.customer_city && val?.customer?.customer_country
                                    ? `${val?.customer?.customer_city}, `
                                    : val?.customer?.customer_city
                                    ? val?.customer?.customer_city
                                    : ""}
                                  {val?.customer?.customer_country ? val?.customer?.customer_country : ""}
                                </div>
                              </>
                            ) : (
                              <>
                                <div>
                                  {val?.customer?.customer_city && val?.customer?.customer_state
                                    ? `${val?.customer?.customer_city}, `
                                    : val?.customer?.customer_city
                                    ? val?.customer?.customer_city
                                    : ""}
                                  {val?.customer?.customer_state ? val?.customer?.customer_state : ""}
                                </div>
                              </>
                            )}
                          </div>
                          <div className="text-danger">
                            {val?.phoned && new Date(val?.phoned) !== "Invalid Date" && (
                              <div className="position-relative d-inline-block">
                                <img
                                  className="align-middle"
                                  src={msgSqr}
                                  alt=""
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title="Chat Initiated"
                                />
                              </div>
                            )}
                            {val?.customer?.credit > 0 &&
                              `($${new Intl.NumberFormat("en-US", {
                                style: "decimal",
                                minimumFractionDigits: 2,
                              }).format(val?.customer?.credit)})`}
                          </div>
                        </div>
                      </td>
                      <td style={{ textAlign: "start" }}>
                        <div className="d-flex align-items-center">
                          <a className="green-text cursor-pointer" href={`mailto:${val?.customer?.customer_email}`}>
                            {val?.customer?.customer_email ? val?.customer?.customer_email : "N/A"}
                          </a>
                          {val?.customer?.customer_email && (
                            <div className="d-text-item " style={{ width: "24px" }}>
                              {val?.customer?.emailstatuscode === 0 ? (
                                <img src={greenEmail} alt="" title="Email OK" className="img-fluid" />
                              ) : val?.customer?.emailstatuscode === 1 ? (
                                <img
                                  src={purpleEmail}
                                  alt=""
                                  title="Email Temporarily Suspended/Unreachable or Undetermined (Soft Bounce)"
                                  className="img-fluid"
                                />
                              ) : val?.customer?.emailstatuscode === 2 ? (
                                <img src={invalid} alt="" title="Email Invalid" className="img-fluid" />
                              ) : val?.customer?.emailstatuscode === 3 ? (
                                <img
                                  src={spamEmail}
                                  alt=""
                                  title="Email Blocked by Spam or Firewall"
                                  className="img-fluid"
                                />
                              ) : (
                                <img src={notVerified} alt="" title="Email Not Verified" className="img-fluid" />
                              )}
                            </div>
                          )}
                        </div>
                        <div className="d-block">
                          {val?.customer?.customer_phone ? val?.customer?.customer_phone : "N/A"}
                        </div>
                      </td>
                      <td style={{ textAlign: "start" }}>
                        <div
                          style={{ maxWidth: "300px" }}
                          className="d-block mb-2 text-wrap"
                          onClick={() => {
                            if (process.env.REACT_APP_MODE == "development") {
                              window.open(`https://admin-dev.salvex.com/admin/_user/_edit.cfm?user_id=${val.user_id}`);
                            } else {
                              window.open(`https://admin.salvex.com/admin/_user/_edit.cfm?user_id=${val.user_id}`);
                            }
                          }}
                        >
                          {val?.customer?.customer_company ? val?.customer?.customer_company : "N/A"}
                        </div>
                        <div className="fw-medium">
                          <span className="green-text">
                            {val?.customer?.buyerFeedback?.good === 0
                              ? "(0%)"
                              : `(${Math.round(
                                  (val?.customer?.buyerFeedback?.good / val?.customer?.buyerFeedback?.total) * 100,
                                )}%)`}
                          </span>
                          <span className="grey-color">
                            {val?.customer?.buyerFeedback?.good === 0
                              ? "(0%)"
                              : `(${Math.round(
                                  (val?.customer?.buyerFeedback?.neutral / val?.customer?.buyerFeedback?.total) * 100,
                                )}%)`}
                          </span>
                          <span className="text-danger">
                            {val?.customer?.buyerFeedback?.good === 0
                              ? "(0%)"
                              : `(${Math.round(
                                  (val?.customer?.buyerFeedback?.bad / val?.customer?.buyerFeedback?.total) * 100,
                                )}%)`}
                          </span>
                        </div>
                      </td>

                      <td class="text-start">
                        <div class="fw-medium ">
                          {val?.customer?.profitBuying
                            ? "$" +
                              new Intl.NumberFormat("en-US", {
                                style: "decimal",
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }).format(val?.customer?.profitBuying)
                            : "$0.00"}
                        </div>
                      </td>
                      <td>
                        {!val?.phoned ? (
                          <button
                            className="btn btn-primary btn-xs-size btn-auto"
                            onClick={() => handleCallEmailClicker(val.user_id)}
                          >
                            Call
                          </button>
                        ) : (
                          ""
                        )}
                        {/* {!val?.buyer_intrest ? (
                          <button
                            className="btn btn-secondary btn-other btn-xs-size btn-auto"
                            onClick={() => handleBuyingInterest(val?.user_id)}
                          >
                            Buyer Interest
                          </button>
                        ) : (
                          ""
                        )} */}
                        <a
                          href={
                            process.env.REACT_APP_MODE == "development"
                              ? `https://admin-dev.salvex.com/admin/_listings/views/all_participants_email.cfm?aucID=${props?.auctionId}&email_clickers=1&to_email=${val?.customer?.customer_email}`
                              : `https://admin.salvex.com/admin/_listings/views/all_participants_email.cfm?aucID=${props?.auctionId}&email_clickers=1&to_email=${val?.customer?.customer_email}`
                          }
                          target="_blank"
                          className="btn btn-primary  btn-other  btn-xs-size btn-auto"
                          style={{ marginLeft: "0px" }}
                        >
                          Send Email
                        </a>
                        {props.auctionId > 0 && val?.phoned ? (
                          <div class="d-block text-wrap mb-2 fw-medium" style={{ maxWidth: "220px" }}>
                            Call at: <DateFormat date={val.phoned} />
                          </div>
                        ) : (
                          ""
                        )}

                        {/* {val?.buyer_intrest ? (
                          <div style={{ maxWidth: "220px" }} className="d-block mb-2 text-wrap fw-medium">
                            Buyer Interest added at: <DateFormat date={val?.buyer_intrest} />
                          </div>
                        ) : (
                          ""
                        )} */}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
          {!viewAll && listingData.length > 0 && (
            <div class="table-pagination mt-5">
              <ul class="pagination  justify-content-end">
                <li class="page-item">
                  <a
                    class={`page-link   ${page === 1 ? "disabled" : "cursor-pointer"}`}
                    aria-label="Previous"
                    onClick={() => {
                      if (page !== 1) {
                        setPage(page - 1);
                      }
                    }}
                  >
                    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7 13L1 7L7 1" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </a>
                </li>
                <li class="page-item active">
                  <a class="page-link" href="#">
                    {page}
                  </a>
                </li>
                <li class="page-item">
                  {page < (Count % 10 === 0 ? Math.floor(Count / 10) : Math.floor(Count / 10) + 1) && (
                    <a
                      class={`page-link ${
                        page >= (Count % 10 === 0 ? Math.floor(Count / 10) : Math.floor(Count / 10) + 1)
                          ? "disabled"
                          : "cursor-pointer"
                      }`}
                      onClick={() => setPage(page + 1)}
                    >
                      {page + 1}
                    </a>
                  )}
                </li>
                <li class="page-item">
                  {page < (Count % 10 === 0 ? Math.floor(Count / 10) : Math.floor(Count / 10) + 1) && (
                    <a
                      class={`page-link ${
                        page >= (Count % 10 === 0 ? Math.floor(Count / 10) : Math.floor(Count / 10) + 1)
                          ? "disabled"
                          : "cursor-pointer"
                      }`}
                      aria-label="Next"
                      onClick={() => setPage(page + 1)}
                    >
                      <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 13L7 7L1 1" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </a>
                  )}
                </li>
              </ul>
            </div>
          )}
        </div>
      )}
      <MoreModal
        show={showMoreModal}
        onHide={toggleMoreModal}
        userDetailData={userDetailData}
        recipientList={recipientList}
        refStart={props.refStart}
        refStop={props.refStop}
        addMessage={props.addMessage}
        deleteMessage={props.deleteMessage}
        getUserDetail={props.getUserDetail}
        changeUnsubscribe={props.changeUnsubscribe}
        auctionId={props.auctionId}
        toggleContactInformationModal={toggleContactInformationModal}
      />

      <UserContactInformationModal
        show={showContactModal}
        onHide={toggleContactInformationModal}
        userDetailData={userDetailData}
        contactInformation={contactInformation}
        setContactInformation={setContactInformation}
        stateOptions={stateOptions}
        countryOptions={countryOptions}
        loading={quickEditLoading}
        quickEditHandle={quickEditHandle}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getPushEmailClickers: (id, params, callback) => dispatch(getPushEmailClickers(id, params, callback)),
  getAllPushEmailClickers: (params, callback) => dispatch(getAllPushEmailClickers(params, callback)),

  getCountry: (callback) => dispatch(getCountry(callback)),
  getState: (callback) => dispatch(getState(callback)),
  getUserDetail: (userId, callback) => dispatch(getUserDetail(userId, callback)),
  buyerInterest: (params, userId, callback) => dispatch(buyerInterest(params, userId, callback)),
  callEmailClicker: (params, userId, callback) => dispatch(callEmailClicker(params, userId, callback)),
  getRecipient: (callback) => dispatch(getRecipient(callback)),
  addMessage: (params, data, callback) => dispatch(addMessage(params, data, callback)),
  deleteMessage: (params, callback) => dispatch(deleteMessage(params, callback)),
  quickEdit: (params, data, callback) => dispatch(quickEdit(params, data, callback)),
  changeUnsubscribe: (params, data, callback) => dispatch(changeUnsubscribe(params, data, callback)),
});
const mapStateToProps = (state) => ({
  emailClickersDetail: emailClickersDetail(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(PushEmailClickersNew));
