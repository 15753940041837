import React from "react";
import Sidebar from "../layouts/sidebar";
import Header from "../layouts/header";
import errorImg from "../include/images/404.svg";
import { useState } from "react";

const Error404 = () => {
  //For SideNav
  const [showMenu, setShowMenu] = useState(false);
  const [menuList, setMenuList] = useState("");

  return (
    <>
      <div className={`page-wrapper-outer grey-bg `}>
        <Header />
        <div class="dashboard-insider position-relative d-lg-flex w-100 ">
          <Sidebar
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            menuList={menuList}
            setMenuList={setMenuList}
          />
          <div
            className={
              showMenu ? ` main-menu-expanded ` : "main-menu-compressed"
            }
          >
            <div class="error-page-container d-flex align-items-center justify-content-center">
              <div class="error-content text-center d-flex align-items-center justify-content-between">
                <div class="error-content-box">
                  <h6>Error</h6>
                  <div class="four-zero-four-img">
                    <img src={errorImg} alt="Not Found" />
                  </div>
                  <p>Page Not Found!</p>
                  <a href="/" class="btn btn-primary">
                    Go back to home
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Error404;
