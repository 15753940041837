import React, { useEffect, useState } from "react";
import { Accordion, Modal } from "react-bootstrap";
import close from "../../../include/images/close.svg";
import NextButton from "../../../common/form/nextButton";
import Select from "react-select";
import { customStyles } from "../../../common/customStyles/reactSelectStyle";

const CategoryPreferenacesModal = ({ primaryCategory, setPrimaryCategory, ...props }) => {
  const categoryId =
    props.categoryDropdown && props.categoryDropdown.length > 0 && props.categoryDropdown.map((val) => val);

  const [value, setValues] = useState();
  useEffect(() => {
    if (props.select && props.select.value) {
      props.subCat &&
        props.subCat.length > 0 &&
        props.subCat.map((val) => {
          if (val.value === props.select.value) {
            setValues(val.subCategories);
          }
        }, []);
    }
  });
  return (
    <Modal
      className="modal fade custom-modal"
      id="categoryRef"
      dialogClassName="modal-dialog modal-dialog-centered modal-lg"
      show={props.show}
      onHide={() => props.onHide()}
      enforceFocus={false}
    >
      <div class="modal-content d-flex flex-column">
        <div class="custom-modal-header d-flex align-items-center justify-content-between">
          <h5 class="mb-0 text-capitalize">Category Preferences</h5>
          <button
            type="button"
            class="close-btn d-flex align-items-center justify-content-center"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => props.onHide()}
          >
            <img src={close} alt="" />
          </button>
        </div>
        <Modal.Body className="custom-modal-body flex-grow-1 w-100 overflow-hidden" style={{ paddingBottom: "10px" }}>
          <div class="row-details-row hide-scrollbar" style={{ height: "400px", overflowY: "scroll" }}>
            <div class="rdr-item d-flex fle x-nowrap" style={{ marginTop: "10px" }}>
              <div class="rdr-label-box align-self-start">Select a Category</div>
              <div class="rdr-form-box">
                <Select
                  value={
                    primaryCategory?.firstLevel?.active
                      ? props.select
                      : primaryCategory?.firstLevel?.name
                      ? { name: primaryCategory?.firstLevel?.name, value: primaryCategory?.firstLevel?.value }
                      : ""
                  }
                  className="basic-single"
                  classNamePrefix="select-search"
                  placeholder={`Please Select`}
                  styles={customStyles}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.value}
                  options={props.categoryDropdown}
                  name={"PrimayCategoryFirstLevel"}
                  onChange={(event) => {
                    const data = { ...categoryId };
                    const filterData = props?.categoryDropDownData?.filter((ui) => ui?.category_id == event?.value);
                    if (filterData?.[0]?.subCategories?.length > 0) {
                      setPrimaryCategory({
                        firstLevel: {
                          name: filterData[0]?.description,
                          value: filterData[0]?.category_id,
                          subCategories: filterData[0]?.subCategories,
                        },
                      });
                      if (props?.select) {
                        props?.setSelect("");
                      }
                    } else {
                      data.value = event;
                      props.setSelect(data.value);
                      setPrimaryCategory({ firstLevel: { active: true } });
                    }
                  }}
                />
              </div>
            </div>
            {primaryCategory?.firstLevel?.subCategories?.length > 0 ? (
              <div class="rdr-item d-flex fle x-nowrap" style={{ marginTop: "30px" }}>
                <div class="rdr-label-box align-self-start">Category Level 2</div>
                <div class="rdr-form-box">
                  <Select
                    value={
                      primaryCategory?.secondLevel?.active
                        ? { description: props?.select?.name, category_id: props?.select?.value }
                        : primaryCategory?.secondLevel?.name
                        ? {
                            description: primaryCategory?.secondLevel?.name,
                            category_id: primaryCategory?.secondLevel?.value,
                          }
                        : ""
                    }
                    className="basic-single"
                    classNamePrefix="select-search"
                    placeholder={`Please Select`}
                    styles={customStyles}
                    getOptionLabel={(option) => option.description}
                    getOptionValue={(option) => option.category_id}
                    options={primaryCategory?.firstLevel?.subCategories}
                    name={"PrimayCategorySecondLevel"}
                    onChange={(event) => {
                      const data = { ...categoryId };
                      const filterData = primaryCategory?.firstLevel?.subCategories?.filter(
                        (ui) => ui?.category_id == event?.category_id,
                      );
                      if (filterData?.[0]?.subCategories?.length > 0) {
                        setPrimaryCategory({
                          firstLevel: { ...primaryCategory?.firstLevel },
                          secondLevel: {
                            name: filterData[0]?.description,
                            value: filterData[0]?.category_id,
                            subCategories: filterData[0]?.subCategories,
                          },
                        });
                        if (props?.select) {
                          props?.setSelect("");
                        }
                      } else {
                        data.value = event;
                        props.setSelect({ name: event.description, value: event.category_id });
                        setPrimaryCategory({
                          firstLevel: { ...primaryCategory?.firstLevel },
                          secondLevel: { active: true },
                        });
                      }
                    }}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            {primaryCategory?.secondLevel?.subCategories?.length > 0 ? (
              <div class="rdr-item d-flex fle x-nowrap" style={{ marginTop: "30px" }}>
                <div class="rdr-label-box align-self-start">Category Level 3</div>
                <div class="rdr-form-box">
                  <Select
                    value={
                      primaryCategory?.thirdLevel?.active
                        ? { description: props?.select?.name, category_id: props?.select?.value }
                        : ""
                    }
                    className="basic-single"
                    classNamePrefix="select-search"
                    placeholder={`Please Select`}
                    styles={customStyles}
                    getOptionLabel={(option) => option.description}
                    getOptionValue={(option) => option.category_id}
                    options={primaryCategory?.secondLevel?.subCategories}
                    name={"PrimayCategoryThirdLevel"}
                    onChange={(event) => {
                      const data = { ...categoryId };

                      data.value = event;
                      props.setSelect({ name: event.description, value: event.category_id });
                      setPrimaryCategory({ ...primaryCategory, thirdLevel: { active: true } });
                    }}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </Modal.Body>
        <div class="custom-modal-dividor"></div>
        <div class="custom-modal-footer d-grid gap-2 d-md-flex align-items-center justify-content-md-end">
          <button class="btn btn-link me-md-2 global-cancel-button" type="button" onClick={() => props.onHide()}>
            Cancel
          </button>
          <NextButton
            label="Save"
            classData="btn btn-default"
            handleSubmit={props.handleSubmit}
            loading={props.loadingCategory}
            disable={!props?.select?.name}
          />
        </div>
      </div>
    </Modal>
  );
};

export default React.memo(CategoryPreferenacesModal);
