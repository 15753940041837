import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import NextButton from "../../../common/form/nextButton";
import close from "../../../include/images/close.svg";
// import DatePicker from "react-datepicker";
import moment from "moment";
import DateFormat from "../../../common/DateFormat/dateFormat";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextField } from "@mui/material";

const SellerMotivationModal = (props) => {
  const data = props.data;

  const sixtyDaysFromToday = new Date();
  sixtyDaysFromToday.setDate(sixtyDaysFromToday.getDate() + 60);
  return (
    <Modal
      className="modal fade custom-modal"
      id="editQuickSummary"
      show={props.show}
      onHide={() => props.onHide()}
      dialogClassName="modal-dialog modal-dialog-centered modal-lg"
    >
      <div class="modal-content h-100 d-flex flex-column">
        <div class="custom-modal-header d-flex align-items-center justify-content-between">
          <h5 class="mb-0 text-capitalize">Edit Seller Motivation</h5>
          <button
            type="button"
            class="close-btn d-flex align-items-center justify-content-center"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => props.onHide()}
          >
            <img src={close} alt="" />
          </button>
        </div>
        <Modal.Body className="custom-modal-body flex-grow-1 hide-scrollbar padding-bottom-0">
          <div className="page-widget-label text-capitalize">Ownership Investigation</div>
          <div>
            {!(
              data?.variables?.find((item) => item.type === "checkbox_2")?.motivation == 1 ||
              data?.variables?.find((item) => item.type === "checkbox_3")?.motivation == 1 ||
              data?.variables?.find((item) => item.type === "checkbox_26")?.motivation == 1 ||
              data?.variables?.find((item) => item.type === "checkbox_28")?.motivation == 1 ||
              data?.variables?.find((item) => item.type === "checkbox_29")?.motivation == 1 ||
              data?.variables?.find((item) => item.type === "checkbox_30")?.motivation == 1 ||
              data?.variables?.find((item) => item.type === "checkbox_66")?.motivation == 1 ||
              data?.variables?.find((item) => item.type === "checkbox_49")?.motivation == 1
            ) && (
              <div class="form-check d-flex align-items-center mt-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="checkbox_1"
                  value="checkbox_1"
                  id="checkbox_1"
                  checked
                  onChange={(e) => {
                    const data = { ...props.updateCheckbox };
                    data.dontName = "checkbox_1";
                    if (e.target.checked) {
                      data.value = e.target.value;
                      data.check = 1;
                    } else {
                      data.value = e.target.value;
                      data.check = 0;
                    }
                    props.setUpdateCheckbox(data);
                  }}
                />
                <label class="form-check-label fw-medium" for="checkbox_1"></label>
                <span>Don't Know</span>
              </div>
            )}
            <div class="form-check d-flex align-items-center mt-2">
              <input
                class="form-check-input"
                type="checkbox"
                name="checkbox_2"
                value="checkbox_2"
                defaultChecked={data?.variables?.find((item) => item.type === "checkbox_2")?.motivation == 1}
                id="checkbox_2"
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  data.dontName = "checkbox_1";
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label class="form-check-label fw-medium" for="checkbox_2"></label>
              <span>Owner (Asset Disposal)</span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                class="form-check-input"
                type="checkbox"
                name="checkbox_26"
                value="checkbox_26"
                defaultChecked={data?.variables?.find((item) => item.type === "checkbox_26")?.motivation == 1}
                id="checkbox_26"
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  data.dontName = "checkbox_1";
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label class="form-check-label fw-medium" for="checkbox_26"></label>
              <span>Owner (Stockist)</span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                class="form-check-input"
                type="checkbox"
                name="checkbox_3"
                value="checkbox_3"
                defaultChecked={data?.variables?.find((item) => item.type === "checkbox_3")?.motivation == 1}
                id="checkbox_3"
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  data.dontName = "checkbox_1";
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label class="form-check-label fw-medium" for="checkbox_3"></label>
              <span>No Ownership (Seller Broker)</span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                class="form-check-input"
                type="checkbox"
                name="checkbox_28"
                value="checkbox_28"
                defaultChecked={data?.variables?.find((item) => item.type === "checkbox_28")?.motivation == 1}
                id="checkbox_28"
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  data.dontName = "checkbox_1";
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label class="form-check-label fw-medium" for="checkbox_28"></label>
              <span>Insurance Representative (Salvage)</span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                class="form-check-input"
                type="checkbox"
                name="checkbox_29"
                value="checkbox_29"
                defaultChecked={data?.variables?.find((item) => item.type === "checkbox_29")?.motivation == 1}
                id="checkbox_29"
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  data.dontName = "checkbox_1";
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label class="form-check-label fw-medium" for="checkbox_29"></label>
              <span>Owner (Salvage)</span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                class="form-check-input"
                type="checkbox"
                name="checkbox_30"
                value="checkbox_30"
                defaultChecked={data?.variables?.find((item) => item.type === "checkbox_30")?.motivation == 1}
                id="checkbox_30"
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  data.dontName = "checkbox_1";
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label class="form-check-label fw-medium" for="checkbox_30"></label>
              <span>Warehouse/Location (Abandoned Cargo)</span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                class="form-check-input"
                type="checkbox"
                name="checkbox_66"
                value="checkbox_66"
                defaultChecked={data?.variables?.find((item) => item.type === "checkbox_66")?.motivation == 1}
                id="checkbox_66"
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  data.dontName = "checkbox_1";
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label class="form-check-label fw-medium" for="checkbox_66"></label>
              <span>Freight Forwarder (Abandoned / Damaged Cargo)</span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                class="form-check-input"
                type="checkbox"
                name="checkbox_49"
                value="checkbox_49"
                defaultChecked={data?.variables?.find((item) => item.type === "checkbox_49")?.motivation == 1}
                id="checkbox_49"
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  data.dontName = "checkbox_1";
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label class="form-check-label fw-medium" for="checkbox_49"></label>
              <span>Owner (Manufacturer)</span>
            </div>
            <div className="d-flex align-items-center mt-2">
              <span className="fw-medium">Completed By: </span>
              <span>
                {props?.log1?.value && props?.log1?.value[0]?.completedby}
                {"  "}{" "}
                {props?.log1?.value && props?.log1?.value[0]?.updated_at && (
                  <DateFormat date={props?.log1?.value[0]?.updated_at} />
                )}
              </span>
            </div>
            <div className="d-flex align-items-center mt-2">
              <textarea
                type="text"
                min="0"
                step="0.001"
                class="form-control"
                value={props.values && props.values.information_text_1}
                onChange={(e) => {
                  const values = { ...props.values };
                  values.information_text_1 = e.target.value;
                  props.setValues(values);
                }}
              />
            </div>
          </div>

          <div className="dividerTable mt-3"></div>
          <div className="page-widget-label text-capitalize">Timeline for Sale Investigation</div>
          <div>
            <button
              className="btn btn-auto btn-xs-size btn-primary"
              onClick={() => {
                const data = { ...props.updateCheckbox };
                data.value = "timeline_followup";
                data.check = "";
                data.len = "";
                data.dontName = "";
                data.date = "";
                props.setUpdateCheckbox(data);
              }}
            >
              No Change
            </button>
            {!(
              data?.variables?.find((item) => item.type === "checkbox_5")?.motivation == 1 ||
              data?.variables?.find((item) => item.type === "checkbox_6")?.motivation == 1 ||
              data?.variables?.find((item) => item.type === "checkbox_7")?.motivation == 1 ||
              data?.variables?.find((item) => item.type === "checkbox_8")?.motivation == 1 ||
              data?.variables?.find((item) => item.type === "checkbox_65")?.motivation == 1
            ) && (
              <div class="form-check d-flex align-items-center mt-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="checkbox_4"
                  value="checkbox_4"
                  id="checkbox_4"
                  checked
                  onChange={(e) => {
                    const data = { ...props.updateCheckbox };
                    data.dontName = "checkbox_4";
                    if (e.target.checked) {
                      data.value = e.target.value;
                      data.check = 1;
                    } else {
                      data.value = e.target.value;
                      data.check = 0;
                    }
                    props.setUpdateCheckbox(data);
                  }}
                />
                <label class="form-check-label fw-medium" for="checkbox_4"></label>
                <span>Don't Know</span>
              </div>
            )}
            <div class="form-check d-flex align-items-center mt-2">
              <input
                className="form-check-input"
                type="checkbox"
                name="checkbox_5"
                value="checkbox_5"
                id="checkbox_5"
                defaultChecked={data?.variables?.find((item) => item.type === "checkbox_5")?.motivation == 1}
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  data.dontName = "checkbox_4";
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label class="form-check-label fw-medium" for="checkbox_5"></label>
              <span>Immediately</span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                className="form-check-input"
                type="checkbox"
                name="checkbox_6"
                value="checkbox_6"
                id="checkbox_6"
                defaultChecked={data?.variables?.find((item) => item.type === "checkbox_6")?.motivation == 1}
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  data.dontName = "checkbox_4";
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label class="form-check-label fw-medium" for="checkbox_6"></label>
              <span>Less Than 1 Month</span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                className="form-check-input"
                type="checkbox"
                name="checkbox_7"
                value="checkbox_7"
                id="checkbox_7"
                defaultChecked={data?.variables?.find((item) => item.type === "checkbox_7")?.motivation == 1}
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  data.dontName = "checkbox_4";
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label class="form-check-label fw-medium" for="checkbox_7"></label>
              <span>Less Than 3 Months</span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                className="form-check-input"
                type="checkbox"
                name="checkbox_8"
                value="checkbox_8"
                id="checkbox_8"
                defaultChecked={data?.variables?.find((item) => item.type === "checkbox_8")?.motivation == 1}
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  data.dontName = "checkbox_4";
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label class="form-check-label fw-medium" for="checkbox_8"></label>
              <span>No Timeline</span>
            </div>
            <div className="d-flex align-items-center mt-2">
              <span>TimeLine Follow Up Date</span>
              <div style={{ width: "150px" }} className="ms-2">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    value={
                      props.values && props.values.timeline_followup
                        ? props.values.timeline_followup
                        : sixtyDaysFromToday
                    }
                    inputFormat="dd-MM-yyyy"
                    minDate={new Date()}
                    maxDate={sixtyDaysFromToday}
                    onChange={(date) => {
                      const data = { ...props.values };
                      data.timeline_followup = date;
                      props.setValues(data);
                    }}
                    renderInput={(params) => <TextField {...params} className="form-control" />}
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div className="d-flex align-items-center mt-2">
              <span>Date</span>
              <div style={{ width: "150px" }} className="ms-2">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    value={props?.date?.date}
                    inputFormat="dd-MM-yyyy"
                    onChange={(date) => {
                      const data = { ...props.date };
                      data.date = date;
                      props.setDate(data);
                    }}
                    renderInput={(params) => <TextField {...params} className="form-control" />}
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div className="d-flex align-items-center mt-2">
              <span className="fw-medium">Completed By:</span>
              <span>
                {props?.log2?.value && props?.log2?.value[0]?.completedby}
                {"  "}{" "}
                {props?.log2?.value && props?.log2?.value[0]?.updated_at && (
                  <DateFormat date={props?.log2?.value[0]?.updated_at} />
                )}
              </span>
            </div>
            <div className="d-flex align-items-center mt-2">
              <textarea
                type="text"
                min="0"
                step="0.001"
                class="form-control"
                value={props.values && props.values.information_text_2}
                onChange={(e) => {
                  const values = { ...props.values };
                  values.information_text_2 = e.target.value;
                  props.setValues(values);
                }}
              />
            </div>
          </div>

          <div className="dividerTable mt-3"></div>
          <div className="page-widget-label text-capitalize">Inventory Investigation</div>
          <div>
            <button
              className="btn btn-auto btn-xs-size btn-primary"
              onClick={() => {
                const data = { ...props.updateCheckbox };
                data.value = "inventory_followup";
                data.check = "";
                data.len = "";
                data.dontName = "";
                data.date = "";
                props.setUpdateCheckbox(data);
              }}
            >
              No Change
            </button>

            {!(
              data?.variables?.find((item) => item.type === "checkbox_10")?.motivation == 1 ||
              data?.variables?.find((item) => item.type === "checkbox_11")?.motivation == 1 ||
              data?.variables?.find((item) => item.type === "checkbox_12")?.motivation == 1 ||
              data?.variables?.find((item) => item.type === "checkbox_53")?.motivation == 1
            ) && (
              <div class="form-check d-flex align-items-center mt-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="checkbox_9"
                  value="checkbox_9"
                  id="checkbox_9"
                  checked
                  onChange={(e) => {
                    const data = { ...props.updateCheckbox };
                    data.dontName = "checkbox_9";
                    if (e.target.checked) {
                      data.value = e.target.value;
                      data.check = 1;
                    } else {
                      data.value = e.target.value;
                      data.check = 0;
                    }
                    props.setUpdateCheckbox(data);
                  }}
                />
                <label class="form-check-label fw-medium" for="checkbox_9"></label>
                <span>Don't Know</span>
              </div>
            )}
            <div class="form-check d-flex align-items-center mt-2">
              <input
                className="form-check-input"
                type="checkbox"
                name="checkbox_10"
                value="checkbox_10"
                id="checkbox_10"
                defaultChecked={data?.variables?.find((item) => item.type === "checkbox_10")?.motivation == 1}
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  data.dontName = "checkbox_9";
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label class="form-check-label fw-medium" for="checkbox_10"></label>
              <span>Pilot Listing Given Only</span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                className="form-check-input"
                type="checkbox"
                name="checkbox_11"
                value="checkbox_11"
                id="checkbox_11"
                defaultChecked={data?.variables?.find((item) => item.type === "checkbox_11")?.motivation == 1}
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  data.dontName = "checkbox_9";
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label class="form-check-label fw-medium" for="checkbox_11"></label>
              <span>Partial Inventory Given</span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                className="form-check-input"
                type="checkbox"
                name="checkbox_12"
                value="checkbox_12"
                id="checkbox_12"
                defaultChecked={data?.variables?.find((item) => item.type === "checkbox_12")?.motivation == 1}
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  data.dontName = "checkbox_9";
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label class="form-check-label fw-medium" for="checkbox_12"></label>
              <span>All Inventory Given</span>
            </div>
            <div className="d-flex align-items-center mt-2">
              <span>Inventory Follow Up Date</span>
              <div style={{ width: "150px" }} className="ms-2">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    value={props.values && props.values.inventory_followup}
                    inputFormat="dd-MM-yyyy"
                    minDate={new Date()}
                    maxDate={sixtyDaysFromToday}
                    onChange={(date) => {
                      const data = { ...props.values };
                      data.inventory_followup = date;
                      props.setValues(data);
                    }}
                    renderInput={(params) => <TextField {...params} className="form-control" />}
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div className="d-flex align-items-center mt-2">
              <span className="fw-medium">Completed By:</span>
              <span>
                {props?.log3?.value && props?.log3?.value[0]?.completedby}
                {"  "}{" "}
                {props?.log3?.value && props?.log3?.value[0]?.updated_at && (
                  <DateFormat date={props?.log3?.value[0]?.updated_at} />
                )}
              </span>
            </div>
            <div className="d-flex align-items-center mt-2">
              <textarea
                type="text"
                min="0"
                step="0.001"
                class="form-control"
                value={props.values && props.values.information_text_3}
                onChange={(e) => {
                  const values = { ...props.values };
                  values.information_text_3 = e.target.value;
                  props.setValues(values);
                }}
              />
            </div>
          </div>

          <div className="dividerTable mt-3"></div>
          <div className="page-widget-label text-capitalize">Salvex Control of Sale</div>
          <div>
            {!(
              // data?.variables?.find((item) => item.type === "checkbox_67")?.motivation == 1 ||
              (
                data?.variables?.find((item) => item.type === "checkbox_68")?.motivation == 1 ||
                data?.variables?.find((item) => item.type === "checkbox_69")?.motivation == 1 ||
                data?.variables?.find((item) => item.type === "checkbox_70")?.motivation == 1 ||
                data?.variables?.find((item) => item.type === "checkbox_71")?.motivation == 1 ||
                data?.variables?.find((item) => item.type === "checkbox_72")?.motivation == 1 ||
                data?.variables?.find((item) => item.type === "checkbox_73")?.motivation == 1
              )
            ) && (
              <div class="form-check d-flex align-items-center mt-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="checkbox_67"
                  value="checkbox_67"
                  id="checkbox_67"
                  checked
                  onChange={(e) => {
                    const data = { ...props.updateCheckbox };
                    data.dontName = "checkbox_67";
                    if (e.target.checked) {
                      data.value = e.target.value;
                      data.check = 1;
                    } else {
                      data.value = e.target.value;
                      data.check = 0;
                    }
                    props.setUpdateCheckbox(data);
                  }}
                />
                <label class="form-check-label fw-medium" for="checkbox_67"></label>
                <span>Don't Know</span>
              </div>
            )}
            <div class="form-check d-flex align-items-center mt-2">
              <input
                className="form-check-input"
                type="checkbox"
                name="checkbox_68"
                value="checkbox_68"
                id="checkbox_68"
                defaultChecked={data?.variables?.find((item) => item.type === "checkbox_68")?.motivation == 1}
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  data.dontName = "checkbox_67";
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label class="form-check-label fw-medium" for="checkbox_68"></label>
              <span>Quick Sale</span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                className="form-check-input"
                type="checkbox"
                name="checkbox_69"
                value="checkbox_69"
                id="checkbox_69"
                defaultChecked={data?.variables?.find((item) => item.type === "checkbox_69")?.motivation == 1}
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  data.dontName = "checkbox_67";
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label class="form-check-label fw-medium" for="checkbox_69"></label>
              <span>Open Marketplace Buyer Control</span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                className="form-check-input"
                type="checkbox"
                name="checkbox_69"
                value="checkbox_69"
                id="checkbox_69"
                defaultChecked={data?.variables?.find((item) => item.type === "checkbox_69")?.motivation == 1}
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  data.dontName = "checkbox_67";
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label class="form-check-label fw-medium" for="checkbox_69"></label>
              <span>Tender Software Buyer Control</span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                className="form-check-input"
                type="checkbox"
                name="checkbox_70"
                value="checkbox_70"
                id="checkbox_70"
                defaultChecked={data?.variables?.find((item) => item.type === "checkbox_70")?.motivation == 1}
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  data.dontName = "checkbox_67";
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label class="form-check-label fw-medium" for="checkbox_70"></label>
              <span>Exclusive Contract Buyer Control</span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                className="form-check-input"
                type="checkbox"
                name="checkbox_71"
                value="checkbox_71"
                id="checkbox_71"
                defaultChecked={data?.variables?.find((item) => item.type === "checkbox_71")?.motivation == 1}
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  data.dontName = "checkbox_67";
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label class="form-check-label fw-medium" for="checkbox_71"></label>
              <span>No Buyer Control</span>
            </div>
            <div className="d-flex align-items-center mt-2">
              <span className="fw-medium">Completed By:</span>
              <span>
                {props?.log4?.value && props?.log4?.value[0]?.completedby}
                {"  "}{" "}
                {props?.log4?.value && props?.log4?.value[0]?.updated_at && (
                  <DateFormat date={props?.log4?.value[0]?.updated_at} />
                )}
              </span>
            </div>
            <div className="d-flex align-items-center mt-2">
              <textarea
                type="text"
                min="0"
                step="0.001"
                class="form-control"
                value={props.values && props.values.information_text_4}
                onChange={(e) => {
                  const values = { ...props.values };
                  values.information_text_4 = e.target.value;
                  props.setValues(values);
                }}
              />
            </div>
          </div>

          <div className="dividerTable mt-3"></div>
          <div className="page-widget-label text-capitalize">Seller Communications</div>
          <div>
            {!(
              data?.variables?.find((item) => item.type === "checkbox_31")?.motivation == 1 ||
              data?.variables?.find((item) => item.type === "checkbox_32")?.motivation == 1 ||
              data?.variables?.find((item) => item.type === "checkbox_33")?.motivation == 1 ||
              data?.variables?.find((item) => item.type === "checkbox_61")?.motivation == 1 ||
              data?.variables?.find((item) => item.type === "checkbox_62")?.motivation == 1 ||
              data?.variables?.find((item) => item.type === "checkbox_63")?.motivation == 1 ||
              data?.variables?.find((item) => item.type === "checkbox_64")?.motivation == 1
            ) && (
              <div class="form-check d-flex align-items-center mt-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="checkbox_31"
                  value="checkbox_31"
                  id="checkbox_31"
                  checked
                  onChange={(e) => {
                    const data = { ...props.updateCheckbox };
                    data.dontName = "checkbox_31";
                    data.value = e.target.value;
                    data.check = 1;
                    props.setUpdateCheckbox(data);
                  }}
                />
                <label class="form-check-label fw-medium" for="checkbox_31"></label>
                <span>Don't Know</span>
              </div>
            )}
            <div class="form-check d-flex align-items-center mt-2">
              <input
                className="form-check-input"
                type="checkbox"
                name="checkbox_61"
                value="checkbox_61"
                id="checkbox_61"
                defaultChecked={data?.variables?.find((item) => item.type === "checkbox_61")?.motivation == 1}
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  data.dontName = "checkbox_31";
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label class="form-check-label fw-medium" for="checkbox_61"></label>
              <span>Priority Seller</span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                className="form-check-input"
                type="checkbox"
                name="checkbox_32"
                value="checkbox_32"
                id="checkbox_32"
                defaultChecked={data?.variables?.find((item) => item.type === "checkbox_32")?.motivation == 1}
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  data.dontName = "checkbox_31";
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label class="form-check-label fw-medium" for="checkbox_32"></label>
              <span>Good Communication</span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                className="form-check-input"
                type="checkbox"
                name="checkbox_62"
                value="checkbox_62"
                id="checkbox_62"
                defaultChecked={data?.variables?.find((item) => item.type === "checkbox_62")?.motivation == 1}
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  data.dontName = "checkbox_31";
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label class="form-check-label fw-medium" for="checkbox_62"></label>
              <span>Phone Preference</span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                className="form-check-input"
                type="checkbox"
                name="checkbox_63"
                value="checkbox_63"
                id="checkbox_63"
                defaultChecked={data?.variables?.find((item) => item.type === "checkbox_63")?.motivation == 1}
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  data.dontName = "checkbox_31";
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label class="form-check-label fw-medium" for="checkbox_63"></label>
              <span>Email Preference</span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                className="form-check-input"
                type="checkbox"
                name="checkbox_64"
                value="checkbox_64"
                id="checkbox_64"
                defaultChecked={data?.variables?.find((item) => item.type === "checkbox_64")?.motivation == 1}
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  data.dontName = "checkbox_31";
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label class="form-check-label fw-medium" for="checkbox_64"></label>
              <span>Text Preferenc</span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                className="form-check-input"
                type="checkbox"
                name="checkbox_33"
                value="checkbox_33"
                id="checkbox_33"
                defaultChecked={data?.variables?.find((item) => item.type === "checkbox_33")?.motivation == 1}
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  data.dontName = "checkbox_31";
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label class="form-check-label fw-medium" for="checkbox_33"></label>
              <span>No Communication</span>
            </div>
            <div className="d-flex align-items-center mt-2">
              <span className="fw-medium">Completed By:</span>
              <span>
                {props?.log5?.value && props?.log5?.value[0]?.completedby}
                {"  "}{" "}
                {props?.log5?.value && props?.log5?.value[0]?.updated_at && (
                  <DateFormat date={props?.log5?.value[0]?.updated_at} />
                )}
              </span>
            </div>
            <div className="d-flex align-items-center mt-2">
              <textarea
                type="text"
                min="0"
                step="0.001"
                class="form-control"
                value={props.values && props.values.information_text_10}
                onChange={(e) => {
                  const values = { ...props.values };
                  values.information_text_10 = e.target.value;
                  props.setValues(values);
                }}
              />
            </div>
          </div>

          <div className="dividerTable mt-3"></div>
          <div className="page-widget-label text-capitalize">Competition</div>
          <div>
            {!(
              data?.variables?.find((item) => item.type === "checkbox_42")?.motivation == 1 ||
              data?.variables?.find((item) => item.type === "checkbox_43")?.motivation == 1 ||
              data?.variables?.find((item) => item.type === "checkbox_44")?.motivation == 1 ||
              data?.variables?.find((item) => item.type === "checkbox_45")?.motivation == 1
            ) && (
              <div class="form-check d-flex align-items-center mt-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="checkbox_41"
                  value="checkbox_41"
                  id="checkbox_41"
                  checked
                  onChange={(e) => {
                    const data = { ...props.updateCheckbox };
                    data.dontName = "checkbox_41";
                    if (e.target.checked) {
                      data.value = e.target.value;
                      data.check = 1;
                    } else {
                      data.value = e.target.value;
                      data.check = 0;
                    }
                    props.setUpdateCheckbox(data);
                  }}
                />
                <label class="form-check-label fw-medium" for="checkbox_41"></label>
                <span>Don't Know</span>
              </div>
            )}
            <div class="form-check d-flex align-items-center mt-2">
              <input
                className="form-check-input"
                type="checkbox"
                name="checkbox_42"
                value="checkbox_42"
                id="checkbox_42"
                defaultChecked={data?.variables?.find((item) => item.type === "checkbox_42")?.motivation == 1}
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  data.dontName = "checkbox_41";
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label class="form-check-label fw-medium" for="checkbox_42"></label>
              <span>Insured Bidding</span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                className="form-check-input"
                type="checkbox"
                name="checkbox_43"
                value="checkbox_43"
                id="checkbox_43"
                defaultChecked={data?.variables?.find((item) => item.type === "checkbox_43")?.motivation == 1}
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  data.dontName = "checkbox_41";
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label class="form-check-label fw-medium" for="checkbox_43"></label>
              <span>Insured Not Bidding</span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                className="form-check-input"
                type="checkbox"
                name="checkbox_44"
                value="checkbox_44"
                id="checkbox_44"
                defaultChecked={data?.variables?.find((item) => item.type === "checkbox_44")?.motivation == 1}
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  data.dontName = "checkbox_41";
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label class="form-check-label fw-medium" for="checkbox_44"></label>
              <span>Distribution List</span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                className="form-check-input"
                type="checkbox"
                name="checkbox_45"
                value="checkbox_45"
                id="checkbox_45"
                defaultChecked={data?.variables?.find((item) => item.type === "checkbox_45")?.motivation == 1}
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  data.dontName = "checkbox_41";
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label class="form-check-label fw-medium" for="checkbox_45"></label>
              <span>Other Websites</span>
            </div>
            <div className="d-flex align-items-center mt-2">
              <span className="fw-medium">Completed By:</span>
              <span>
                {props?.log6?.value && props?.log6?.value[0]?.completedby}
                {"  "}{" "}
                {props?.log6?.value && props?.log6?.value[0]?.updated_at && (
                  <DateFormat date={props?.log6?.value[0]?.updated_at} />
                )}
              </span>
            </div>
            <div className="d-flex align-items-center mt-2">
              <textarea
                type="text"
                min="0"
                step="0.001"
                class="form-control"
                value={props.values && props.values.information_text_9}
                onChange={(e) => {
                  const values = { ...props.values };
                  values.information_text_9 = e.target.value;
                  props.setValues(values);
                }}
              />
            </div>
          </div>
          <div className="dividerTable mt-3"></div>
          <div className="page-widget-label text-capitalize">Seller Legal & Contracts</div>
          <div>
            {!(
              data?.variables?.find((item) => item.type === "checkbox_27")?.motivation == 1 ||
              data?.variables?.find((item) => item.type === "checkbox_47")?.motivation == 1 ||
              data?.variables?.find((item) => item.type === "checkbox_48")?.motivation == 1 ||
              data?.variables?.find((item) => item.type === "checkbox_57")?.motivation == 1 ||
              data?.variables?.find((item) => item.type === "checkbox_58")?.motivation == 1 ||
              data?.variables?.find((item) => item.type === "checkbox_59")?.motivation == 1 ||
              data?.variables?.find((item) => item.type === "checkbox_60")?.motivation == 1
            ) && (
              <div class="form-check d-flex align-items-center mt-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="checkbox_46"
                  value="checkbox_46"
                  id="checkbox_46"
                  checked
                  onChange={(e) => {
                    const data = { ...props.updateCheckbox };
                    data.dontName = "checkbox_46";
                    if (e.target.checked) {
                      data.value = e.target.value;
                      data.check = 1;
                    } else {
                      data.value = e.target.value;
                      data.check = 0;
                    }
                    props.setUpdateCheckbox(data);
                  }}
                />
                <label class="form-check-label fw-medium" for="checkbox_46"></label>
                <span>Don't Know</span>
              </div>
            )}
            <div class="form-check d-flex align-items-center mt-2">
              <input
                className="form-check-input"
                type="checkbox"
                name="checkbox_27"
                value="checkbox_27"
                id="checkbox_27"
                defaultChecked={data?.variables?.find((item) => item.type === "checkbox_27")?.motivation == 1}
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  data.dontName = "checkbox_46";
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label class="form-check-label fw-medium" for="checkbox_27"></label>
              <span>Tender</span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                className="form-check-input"
                type="checkbox"
                name="checkbox_47"
                value="checkbox_47"
                id="checkbox_47"
                defaultChecked={data?.variables?.find((item) => item.type === "checkbox_47")?.motivation == 1}
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  data.dontName = "checkbox_46";
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label class="form-check-label fw-medium" for="checkbox_47"></label>
              <span>Vendor Agreement Required</span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                className="form-check-input"
                type="checkbox"
                name="checkbox_57"
                value="checkbox_57"
                id="checkbox_57"
                defaultChecked={data?.variables?.find((item) => item.type === "checkbox_57")?.motivation == 1}
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  data.dontName = "checkbox_46";
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label class="form-check-label fw-medium" for="checkbox_57"></label>
              <span>Vendor Agreement Signed</span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                className="form-check-input"
                type="checkbox"
                name="checkbox_48"
                value="checkbox_48"
                id="checkbox_48"
                defaultChecked={data?.variables?.find((item) => item.type === "checkbox_48")?.motivation == 1}
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  data.dontName = "checkbox_46";
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label class="form-check-label fw-medium" for="checkbox_48"></label>
              <span>Service Contract Required</span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                className="form-check-input"
                type="checkbox"
                name="checkbox_58"
                value="checkbox_58"
                id="checkbox_58"
                defaultChecked={data?.variables?.find((item) => item.type === "checkbox_58")?.motivation == 1}
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  data.dontName = "checkbox_46";
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label class="form-check-label fw-medium" for="checkbox_58"></label>
              <span>Service Contract Signed</span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                className="form-check-input"
                type="checkbox"
                name="checkbox_59"
                value="checkbox_59"
                id="checkbox_59"
                defaultChecked={data?.variables?.find((item) => item.type === "checkbox_59")?.motivation == 1}
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  data.dontName = "checkbox_46";
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label class="form-check-label fw-medium" for="checkbox_59"></label>
              <span>Seller Verification Required</span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                className="form-check-input"
                type="checkbox"
                name="checkbox_60"
                value="checkbox_60"
                id="checkbox_60"
                defaultChecked={data?.variables?.find((item) => item.type === "checkbox_60")?.motivation == 1}
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  data.dontName = "checkbox_46";
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label class="form-check-label fw-medium" for="checkbox_60"></label>
              <span>Seller Verification Complete</span>
            </div>
            <div className="d-flex align-items-center mt-2">
              <span className="fw-medium">Completed By:</span>
              <span>
                {props?.log7?.value && props?.log7?.value[0]?.completedby}
                {"  "}{" "}
                {props?.log7?.value && props?.log7?.value[0]?.updated_at && (
                  <DateFormat date={props?.log7?.value[0]?.updated_at} />
                )}
              </span>
            </div>
            <div className="d-flex align-items-center mt-2">
              <textarea
                type="text"
                min="0"
                step="0.001"
                class="form-control"
                value={props.values && props.values.information_text_8}
                onChange={(e) => {
                  const values = { ...props.values };
                  values.information_text_8 = e.target.value;
                  props.setValues(values);
                }}
              />
            </div>
          </div>

          <div className="dividerTable mt-3"></div>
          <div className="page-widget-label text-capitalize">Geographic Market Sale Options</div>
          <div>
            {!(
              data?.variables?.find((item) => item.type === "checkbox_25")?.motivation == 1 ||
              data?.variables?.find((item) => item.type === "checkbox_14")?.motivation == 1 ||
              data?.variables?.find((item) => item.type === "checkbox_15")?.motivation == 1 ||
              data?.variables?.find((item) => item.type === "checkbox_16")?.motivation == 1
            ) && (
              <div class="form-check d-flex align-items-center mt-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="checkbox_13"
                  value="checkbox_13"
                  id="checkbox_13"
                  checked
                  onChange={(e) => {
                    const data = { ...props.updateCheckbox };
                    data.dontName = "checkbox_13";
                    if (e.target.checked) {
                      data.value = e.target.value;
                      data.check = 1;
                    } else {
                      data.value = e.target.value;
                      data.check = 0;
                    }
                    props.setUpdateCheckbox(data);
                  }}
                />
                <label class="form-check-label fw-medium" for="checkbox_13"></label>
                <span>Don't Know</span>
              </div>
            )}
            <div class="form-check d-flex align-items-center mt-2">
              <input
                className="form-check-input"
                type="checkbox"
                name="checkbox_25"
                value="checkbox_25"
                id="checkbox_25"
                defaultChecked={data?.variables?.find((item) => item.type === "checkbox_25")?.motivation == 1}
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  data.dontName = "checkbox_13";
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label class="form-check-label fw-medium" for="checkbox_25"></label>
              <span>All Locations</span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                className="form-check-input"
                type="checkbox"
                name="checkbox_14"
                value="checkbox_14"
                id="checkbox_14"
                defaultChecked={data?.variables?.find((item) => item.type === "checkbox_14")?.motivation == 1}
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  data.dontName = "checkbox_13";
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label class="form-check-label fw-medium" for="checkbox_14"></label>
              <span>All Locations Some Restrictions</span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                className="form-check-input"
                type="checkbox"
                name="checkbox_15"
                value="checkbox_15"
                id="checkbox_15"
                defaultChecked={data?.variables?.find((item) => item.type === "checkbox_15")?.motivation == 1}
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  data.dontName = "checkbox_13";
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label class="form-check-label fw-medium" for="checkbox_15"></label>
              <span>Local Only</span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                className="form-check-input"
                type="checkbox"
                name="checkbox_16"
                value="checkbox_16"
                id="checkbox_16"
                defaultChecked={data?.variables?.find((item) => item.type === "checkbox_16")?.motivation == 1}
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  data.dontName = "checkbox_13";
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label class="form-check-label fw-medium" for="checkbox_16"></label>
              <span>Export Only</span>
            </div>
            <div className="d-flex align-items-center mt-2">
              <span className="fw-medium">Completed By:</span>
              <span>
                {props?.log8?.value && props?.log8?.value[0]?.completedby}
                {"  "}{" "}
                {props?.log8?.value && props?.log8?.value[0]?.updated_at && (
                  <DateFormat date={props?.log8?.value[0]?.updated_at} />
                )}
              </span>
            </div>
            <div className="d-flex align-items-center mt-2">
              <textarea
                type="text"
                min="0"
                step="0.001"
                class="form-control"
                value={props.values && props.values.information_text_4}
                onChange={(e) => {
                  const values = { ...props.values };
                  values.information_text_4 = e.target.value;
                  props.setValues(values);
                }}
              />
            </div>
          </div>

          <div className="dividerTable mt-3"></div>
          <div className="page-widget-label text-capitalize">Location, Loading and Logistics</div>
          <div>
            {!(
              data?.variables?.find((item) => item.type === "checkbox_18")?.motivation == 1 ||
              data?.variables?.find((item) => item.type === "checkbox_19")?.motivation == 1 ||
              data?.variables?.find((item) => item.type === "checkbox_20")?.motivation == 1 ||
              data?.variables?.find((item) => item.type === "checkbox_21")?.motivation == 1 ||
              data?.variables?.find((item) => item.type === "checkbox_54")?.motivation == 1 ||
              data?.variables?.find((item) => item.type === "checkbox_55")?.motivation == 1 ||
              data?.variables?.find((item) => item.type === "checkbox_56")?.motivation == 1
            ) && (
              <div class="form-check d-flex align-items-center mt-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="checkbox_17"
                  value="checkbox_17"
                  id="checkbox_17"
                  checked
                  onChange={(e) => {
                    const data = { ...props.updateCheckbox };
                    data.dontName = "checkbox_17";
                    if (e.target.checked) {
                      data.value = e.target.value;
                      data.check = 1;
                    } else {
                      data.value = e.target.value;
                      data.check = 0;
                    }
                    props.setUpdateCheckbox(data);
                  }}
                />
                <label class="form-check-label fw-medium" for="checkbox_17"></label>
                <span>Don't Know</span>
              </div>
            )}
            <div class="form-check d-flex align-items-center mt-2">
              <input
                className="form-check-input"
                type="checkbox"
                name="checkbox_18"
                value="checkbox_18"
                id="checkbox_18"
                defaultChecked={data?.variables?.find((item) => item.type === "checkbox_18")?.motivation == 1}
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  data.dontName = "checkbox_17";
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label class="form-check-label fw-medium" for="checkbox_18"></label>
              <span>Ex Works</span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                className="form-check-input"
                type="checkbox"
                name="checkbox_54"
                value="checkbox_54"
                id="checkbox_54"
                defaultChecked={data?.variables?.find((item) => item.type === "checkbox_54")?.motivation == 1}
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  data.dontName = "checkbox_17";
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label class="form-check-label fw-medium" for="checkbox_54"></label>
              <span>Location Loading (By Seller)</span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                className="form-check-input"
                type="checkbox"
                name="checkbox_19"
                value="checkbox_19"
                id="checkbox_19"
                defaultChecked={data?.variables?.find((item) => item.type === "checkbox_19")?.motivation == 1}
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  data.dontName = "checkbox_17";
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label class="form-check-label fw-medium" for="checkbox_19"></label>
              <span>Location Loading (Buyer Cost)</span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                className="form-check-input"
                type="checkbox"
                name="checkbox_20"
                value="checkbox_20"
                id="checkbox_20"
                defaultChecked={data?.variables?.find((item) => item.type === "checkbox_20")?.motivation == 1}
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  data.dontName = "checkbox_17";
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label class="form-check-label fw-medium" for="checkbox_20"></label>
              <span>No Location Loading Support</span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                className="form-check-input"
                type="checkbox"
                name="checkbox_55"
                value="checkbox_55"
                id="checkbox_55"
                defaultChecked={data?.variables?.find((item) => item.type === "checkbox_55")?.motivation == 1}
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  data.dontName = "checkbox_17";
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label class="form-check-label fw-medium" for="checkbox_55"></label>
              <span>Trucking (By Seller)</span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                className="form-check-input"
                type="checkbox"
                name="checkbox_56"
                value="checkbox_56"
                id="checkbox_56"
                defaultChecked={data?.variables?.find((item) => item.type === "checkbox_56")?.motivation == 1}
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  data.dontName = "checkbox_17";
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label class="form-check-label fw-medium" for="checkbox_56"></label>
              <span>Customs Clearance (By Seller)</span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                className="form-check-input"
                type="checkbox"
                name="checkbox_21"
                value="checkbox_21"
                id="checkbox_21"
                defaultChecked={data?.variables?.find((item) => item.type === "checkbox_21")?.motivation == 1}
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  data.dontName = "checkbox_17";
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label class="form-check-label fw-medium" for="checkbox_21"></label>
              <span>Multiple Locations</span>
            </div>
            <div className="d-flex align-items-center mt-2">
              <span className="fw-medium">Completed By:</span>
              <span>
                {props?.log9?.value && props?.log9?.value[0]?.completedby}
                {"  "}{" "}
                {props?.log9?.value && props?.log9?.value[0]?.updated_at && (
                  <DateFormat date={props?.log9?.value[0]?.updated_at} />
                )}
              </span>
            </div>
            <div className="d-flex align-items-center mt-2">
              <textarea
                type="text"
                class="form-control"
                value={props.values && props.values.information_text_5}
                onChange={(e) => {
                  const values = { ...props.values };
                  values.information_text_5 = e.target.value;
                  props.setValues(values);
                }}
              />
            </div>
          </div>

          <div className="dividerTable mt-3"></div>
          <div className="page-widget-label text-capitalize">Product Markets Investigation </div>
          <div>
            {!(
              data?.variables?.find((item) => item.type === "checkbox_23")?.motivation == 1 ||
              data?.variables?.find((item) => item.type === "checkbox_24")?.motivation == 1 ||
              data?.variables?.find((item) => item.type === "checkbox_22")?.motivation == 1
            ) && (
              <div class="form-check d-flex align-items-center mt-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="checkbox_22"
                  value="checkbox_22"
                  id="checkbox_22"
                  checked
                  onChange={(e) => {
                    const data = { ...props.updateCheckbox };
                    data.dontName = "checkbox_22";
                    if (e.target.checked) {
                      data.value = e.target.value;
                      data.check = 1;
                    } else {
                      data.value = e.target.value;
                      data.check = 0;
                    }
                    props.setUpdateCheckbox(data);
                  }}
                />
                <label class="form-check-label fw-medium" for="checkbox_22"></label>
                <span>Don't Know</span>
              </div>
            )}
            <div class="form-check d-flex align-items-center mt-2">
              <input
                className="form-check-input"
                type="checkbox"
                name="checkbox_23"
                value="checkbox_23"
                id="checkbox_23"
                defaultChecked={data?.variables?.find((item) => item.type === "checkbox_23")?.motivation == 1}
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  data.dontName = "checkbox_22";
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label class="form-check-label fw-medium" for="checkbox_23"></label>
              <span>Original Use</span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                className="form-check-input"
                type="checkbox"
                name="checkbox_24"
                value="checkbox_24"
                id="checkbox_24"
                defaultChecked={data?.variables?.find((item) => item.type === "checkbox_24")?.motivation == 1}
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  data.dontName = "checkbox_22";
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label class="form-check-label fw-medium" for="checkbox_24"></label>
              <span>Repurposing Use</span>
            </div>
            <div className="d-flex align-items-center mt-2">
              <span className="fw-medium">Completed By:</span>
              <span>
                {props?.log10?.value && props?.log10?.value[0]?.completedby}
                {"  "}{" "}
                {props?.log10?.value && props?.log10?.value[0]?.updated_at && (
                  <DateFormat date={props?.log10?.value[0]?.updated_at} />
                )}
              </span>
            </div>
            <div className="d-flex align-items-center mt-2">
              <textarea
                type="text"
                min="0"
                step="0.001"
                class="form-control"
                value={props.values && props.values.information_text_6}
                onChange={(e) => {
                  const values = { ...props.values };
                  values.information_text_6 = e.target.value;
                  props.setValues(values);
                }}
              />
            </div>
          </div>

          <div className="dividerTable mt-3"></div>
          <div className="page-widget-label text-capitalize">Asset Pricing Discussion</div>
          <div>
            {!(
              data?.variables?.find((item) => item.type === "checkbox_35")?.motivation == 1 ||
              data?.variables?.find((item) => item.type === "checkbox_36")?.motivation == 1 ||
              data?.variables?.find((item) => item.type === "checkbox_37")?.motivation == 1 ||
              data?.variables?.find((item) => item.type === "checkbox_38")?.motivation == 1 ||
              data?.variables?.find((item) => item.type === "checkbox_39")?.motivation == 1 ||
              data?.variables?.find((item) => item.type === "checkbox_40")?.motivation == 1
            ) && (
              <div class="form-check d-flex align-items-center mt-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="checkbox_34"
                  value="checkbox_34"
                  id="checkbox_34"
                  checked
                  onChange={(e) => {
                    const data = { ...props.updateCheckbox };
                    data.dontName = "checkbox_34";
                    if (e.target.checked) {
                      data.value = e.target.value;
                      data.check = 1;
                    } else {
                      data.value = e.target.value;
                      data.check = 0;
                    }
                    props.setUpdateCheckbox(data);
                  }}
                />
                <label class="form-check-label fw-medium" for="checkbox_34"></label>
                <span>Don't Know</span>
              </div>
            )}
            <div class="form-check d-flex align-items-center mt-2">
              <input
                className="form-check-input"
                type="checkbox"
                name="checkbox_35"
                value="checkbox_35"
                id="checkbox_35"
                defaultChecked={data?.variables?.find((item) => item.type === "checkbox_35")?.motivation == 1}
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  data.dontName = "checkbox_34";
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label class="form-check-label fw-medium" for="checkbox_35"></label>
              <span>Less than 20% Wholesale Value</span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                className="form-check-input"
                type="checkbox"
                name="checkbox_36"
                value="checkbox_36"
                id="checkbox_36"
                defaultChecked={data?.variables?.find((item) => item.type === "checkbox_36")?.motivation == 1}
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  data.dontName = "checkbox_34";
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label class="form-check-label fw-medium" for="checkbox_36"></label>
              <span>20%-40% Wholesale Value</span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                className="form-check-input"
                type="checkbox"
                name="checkbox_37"
                value="checkbox_37"
                id="checkbox_37"
                defaultChecked={data?.variables?.find((item) => item.type === "checkbox_37")?.motivation == 1}
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  data.dontName = "checkbox_34";
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label class="form-check-label fw-medium" for="checkbox_37"></label>
              <span>40%-60% Wholesale Value</span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                className="form-check-input"
                type="checkbox"
                name="checkbox_38"
                value="checkbox_38"
                id="checkbox_38"
                defaultChecked={data?.variables?.find((item) => item.type === "checkbox_38")?.motivation == 1}
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  data.dontName = "checkbox_34";
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label class="form-check-label fw-medium" for="checkbox_38"></label>
              <span>60%-80% Wholesale Value</span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                className="form-check-input"
                type="checkbox"
                name="checkbox_39"
                value="checkbox_39"
                id="checkbox_39"
                defaultChecked={data?.variables?.find((item) => item.type === "checkbox_39")?.motivation == 1}
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  data.dontName = "checkbox_34";
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label class="form-check-label fw-medium" for="checkbox_39"></label>
              <span>80%-100% Wholesale Value</span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                className="form-check-input"
                type="checkbox"
                name="checkbox_40"
                value="checkbox_40"
                id="checkbox_40"
                defaultChecked={data?.variables?.find((item) => item.type === "checkbox_40")?.motivation == 1}
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  data.dontName = "checkbox_34";
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label class="form-check-label fw-medium" for="checkbox_40"></label>
              <span>100% + Wholesale Value</span>
            </div>
            <div class="form-check d-flex align-items-center mt-2">
              <input
                className="form-check-input"
                type="checkbox"
                name="checkbox_50"
                value="checkbox_50"
                id="checkbox_50"
                defaultChecked={data?.variables?.find((item) => item.type === "checkbox_50")?.motivation == 1}
                onChange={(e) => {
                  const data = { ...props.updateCheckbox };
                  data.dontName = "checkbox_34";
                  if (e.target.checked) {
                    data.value = e.target.value;
                    data.check = 1;
                  } else {
                    data.value = e.target.value;
                    data.check = 0;
                  }
                  props.setUpdateCheckbox(data);
                }}
              />
              <label class="form-check-label fw-medium" for="checkbox_50"></label>
              <span>No Pricing</span>
            </div>
            <div className="d-flex align-items-center mt-2">
              <span className="fw-medium">Completed By:</span>
              <span>
                {props?.log11?.value && props?.log11?.value[0]?.completedby}
                {"  "}{" "}
                {props?.log11?.value && props?.log11?.value[0]?.updated_at && (
                  <DateFormat date={props?.log11?.value[0]?.updated_at} />
                )}
              </span>
            </div>
            <div className="d-flex align-items-center mt-2">
              <textarea
                type="text"
                min="0"
                step="0.001"
                class="form-control"
                value={props.values && props.values.information_text_7}
                onChange={(e) => {
                  const values = { ...props.values };
                  values.information_text_7 = e.target.value;
                  props.setValues(values);
                }}
              />
            </div>
          </div>

          <div className="dividerTable mt-3"></div>
          <div className="page-widget-label text-capitalize">Other Information</div>
          <div className="pb-2">
            <div className="d-flex align-items-center mt-2">
              <textarea
                type="text"
                min="0"
                step="0.001"
                rows={8}
                class="form-control"
                value={props.values && props.values.information_text_11}
                onChange={(e) => {
                  const values = { ...props.values };
                  values.information_text_11 = e.target.value;
                  props.setValues(values);
                }}
              />
            </div>
          </div>
        </Modal.Body>
        <div class="custom-modal-dividor"></div>
        <div className="custom-modal-footer d-grid gap-2 d-md-flex align-items-center justify-content-md-end">
          <button
            class="btn btn-link me-md-2 global-cancel-button"
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => props.onHide()}
          >
            Cancel
          </button>
          <NextButton
            label="Save"
            classData="btn btn-default"
            handleSubmit={props.handleSubmit}
            loading={props.loadingQuickSummary}
          />
        </div>
      </div>
    </Modal>
  );
};

export default SellerMotivationModal;
