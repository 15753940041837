import React, { useState } from "react";
import Header from "../../layouts/header";
import Sidebar from "../../layouts/sidebar";
import successIcon from "../../include/images/success-icon.png";

const IndividualPushSuccess = () => {
  //For SideNav
  const [showMenu, setShowMenu] = useState(false);
  const [menuList, setMenuList] = useState("");

  return (
    <div className={`page-wrapper-outer grey-bg `}>
      <Header />
      <div class="dashboard-insider position-relative d-lg-flex w-100 ">
        <Sidebar showMenu={showMenu} setShowMenu={setShowMenu} menuList={menuList} setMenuList={setMenuList} />
        <div className={showMenu ? ` main-menu-expanded ` : "main-menu-compressed"}>
          <div class="error-page-container d-flex align-items-center justify-content-center">
            <div class="error-content text-center d-flex align-items-center justify-content-between">
              <div class="error-content-box">
                <h6>Success</h6>
                <div class="four-zero-four-img">
                  <img src={successIcon} alt="Not Found" />
                </div>
                <p>The email was successfully sent, and the user has been created in our system.</p>
                {/* <a href="/" class="btn btn-primary">
                Click here to edit the profile 
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndividualPushSuccess;
