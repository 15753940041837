import { combineReducers } from "redux";
import usersReducer from "./users";
import listingsReducer from "./listings";
import listingMediaReducer from "./listingsMedia";
import listingProductReducer from "./listingProduct";
import miscReducer from "./misc";
import strategicTeamReducer from "./strategicTeam";
import productLocationReducer from "./productLocation";
import CommentsAndNotesReducer from "./commentsAndNotes";
import optimizationSiteReducer from "./optimizationSites";
import otherListingsReducers from "./otherListings";
import questionReducer from "./questions";
import emailClickerReducers from "./emailClickers";
import tasksReducer from "./tasks";
import userlistingReducer from "./userList";
import invoiceReducer from "./invoices";
import chatsReducer from "./chats";
import payableReducer from "./payables";
import preferencesReducer from "./preferences";
import bidsReducer from "./bids";
import buyerInterestsReducer from "./buyerInterests";
import aviationReducer from "./aviation";
import partsReducer from './parts';
import investmentsReducer from "./investments";
// import settingsReducer from "./settings";
import latestActivityReducer from "./latestActivity";
import primarySellerReducer from "./primarySeller";
import commissionReducer from "./commission";
import contractReducer from "./contract";
import dashboardReducer from "./dashBoard";
import brokerReducer from './broker';
import researchAssignmentReducer from "./researchAssignment";
import modalReducer from "./modal";
import eiReducer from "./ei";

export default combineReducers({
  users: usersReducer,
  listings: listingsReducer,
  listingMedia: listingMediaReducer,
  listingProduct: listingProductReducer,
  misc: miscReducer,
  strategicTeam: strategicTeamReducer,
  tasks: tasksReducer,
  emailClickers: emailClickerReducers,
  productLocation: productLocationReducer,
  commentsAndNotes: CommentsAndNotesReducer,
  optimizationSite: optimizationSiteReducer,
  otherListings: otherListingsReducers,
  questions: questionReducer,
  questions: questionReducer,
  userlisting: userlistingReducer,
  invoices: invoiceReducer,
  chats: chatsReducer,
  payables: payableReducer,
  preferences: preferencesReducer,
  bids: bidsReducer,
  buyerInterests: buyerInterestsReducer,
  aviation: aviationReducer,
  parts: partsReducer,
  investments: investmentsReducer,
  // settings: settingsReducer,
  latestActivity: latestActivityReducer,
  primarySeller: primarySellerReducer,
  primarySeller: primarySellerReducer,
  comission: commissionReducer,
  commission: commissionReducer,
  contract: contractReducer,
  dashboard: dashboardReducer,
  broker: brokerReducer,
  researchAssignment: researchAssignmentReducer,
  modal: modalReducer,
  ei: eiReducer,
});
