import React, { useEffect, useRef, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import Select from "react-select";
import PaymentTypeOne from "./paymentTypeOne";
import PaymentTypeTwo from "./paymentTypeTwo";
import PaymentTypeThree from "./paymentTypeThree";
import PaymentTypeFour from "./paymentTypeFour";
import { toast } from "react-toastify";
import AlertError from "../../../common/alerts/alertError";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import {
  getPaymentIfo,
  getPaymentIform,
  getUser,
  paymentInfoReceived,
  savePaymentIfo,
  verifyPaymentIfo,
} from "../../../store/users";
import { connect } from "react-redux";
import { getProfile } from "../../../utils.js/localStorageServices";
import close from "../../../include/images/close.svg";
import LoadingBar from "react-top-loading-bar";
import Skeleton from "react-loading-skeleton";

const PaymentInfo = (props) => {
  const ref = useRef(null);
  const [selectedOption, setSelectedOption] = useState("1");
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(false);

  const [error1, setError1] = useState({});
  const [error2, setError2] = useState({});
  const [error3, setError3] = useState({});
  const [error4, setError4] = useState({});
  const [initiaMethod, setInitialMethod] = useState("");
  const [initialDWR, setInitialDWR] = useState("");
  const [selectedData, setSelectedData] = useState(null);
  const [verified, setVerified] = useState(false);

  const [option1Data, setOption1Data] = useState({
    account_name: "",
    account_address: "",
    bank_name: "",
    account_iban_no: "",
    ach_routing_no: "",
    additional_info: "",
  });

  const [option2Data, setOption2Data] = useState({
    other_email: "",
    dwr_type: "",
    account_name: "",
    account_address: "",
    account_iban_no: "",
    aba_swift_no: "",
    bank_name: "",
    bank_address: "",
    ach_routing_no: "",
    preferred_ref_no: 0,
    intermediary_bank_info: "",
    additional_info: "",
  });

  const [option3Data, setOption3Data] = useState({
    other_email: "",
    account_name: "",
    account_address: "",
    account_iban_no: "",
    aba_swift_no: "",
    bank_name: "",
    bank_address: "",
    preferred_ref_no: "1",
    intermediary_bank_info: "",
    additional_info: "",
  });

  const [option4Data, setOption4Data] = useState({
    checkOption: "",
    check_payable: "",
    check_attention: "",
    check_address: "",
    preferred_ref_no: "1",
    additional_info: "",
  });

  useEffect(() => {
    if (props.getUser.paymentInfo && props.getUser.paymentInfo.detail) {
      setSelectedData(props.getUser.paymentInfo.detail);
    }
  }, [props.getUser.paymentInfo && props.getUser.paymentInfo.detail]);

  useEffect(() => {
    if (!props.show) {
      setSelectedData(null);
    }
  }, [props.show]);

  useEffect(() => {
    if (props.show) {
      setInitialLoading(true);
      ref && ref.current && ref.current.continuousStart();
      props.getPaymentInfo(props.id, (res) => {
        if (res && res.status === 200) {
          setVerified(res.data.detail ? res.data.detail.verify : false);
          setInitialLoading(false);
          ref && ref.current && ref.current.complete();
          setSelectedData(res.data.detail ? res.data.detail : {});
          const method = res.data.detail?.method ? res.data.detail?.method : "";
          setSelectedOption(method);
          setInitialMethod(method);
          if (method == 1) {
            const data = {
              method: 1,
              other_email: res.data.detail.other_email ? res.data.detail.other_email : "",
              account_name: res.data.detail.account_name ? res.data.detail.account_name : "",
              account_address: res.data.detail.account_address ? res.data.detail.account_address : "",
              bank_name: res.data.detail.bank_name ? res.data.detail.bank_name : "",
              account_iban_no: res.data.detail.account_iban_no ? res.data.detail.account_iban_no : "",
              ach_routing_no: res.data.detail.ach_routing_no ? res.data.detail.ach_routing_no : "",
              additional_info: res.data.detail.additional_info ? res.data.detail.additional_info : "",
            };
            setOption1Data(data);
          } else if (method == 2) {
            const data = {
              method: 2,
              other_email: res.data.detail.other_email ? res.data.detail.other_email : "",
              dwr_type: res.data.detail.dwr_type ? +res.data.detail.dwr_type : "",
              account_name: res.data.detail.account_name ? res.data.detail.account_name : "",
              account_address: res.data.detail.account_address ? res.data.detail.account_address : "",
              account_iban_no: res.data.detail.account_iban_no ? res.data.detail.account_iban_no : "",
              aba_swift_no: res.data.detail.aba_swift_no ? res.data.detail.aba_swift_no : "",
              bank_name: res.data.detail.bank_name ? res.data.detail.bank_name : "",
              bank_address: res.data.detail.bank_address ? res.data.detail.bank_address : "",
              ach_routing_no: res.data.detail.ach_routing_no ? res.data.detail.ach_routing_no : "",
              preferred_ref_no: res.data.detail.preferred_ref_no ? +res.data.detail.preferred_ref_no : 0,
              intermediary_bank_info: res.data.detail.intermediary_bank_info
                ? res.data.detail.intermediary_bank_info
                : "",
              additional_info: res.data.detail.additional_info ? res.data.detail.additional_info : "",
            };
            setOption2Data(data);
            setInitialDWR(res.data.detail.dwr_type ? +res.data.detail.dwr_type : "");
          } else if (method == 3) {
            const data = {
              other_email: res.data.detail?.other_email ? res.data.detail.other_email : "",
              method: 3,
              account_name: res.data.detail?.account_name ? res.data.detail.account_name : "",
              account_address: res.data.detail?.account_address ? res.data.detail.account_address : "",
              account_iban_no: res.data.detail?.account_iban_no ? res.data.detail.account_iban_no : "",
              aba_swift_no: res.data.detail?.aba_swift_no ? res.data.detail.aba_swift_no : "",
              bank_name: res.data.detail?.bank_name ? res.data.detail.bank_name : "",
              bank_address: res.data.detail?.bank_address ? res.data.detail.bank_address : "",
              preferred_ref_no: res.data.detail?.preferred_ref_no ? +res.data.detail.preferred_ref_no : 0,
              intermediary_bank_info: res.data.detail?.intermediary_bank_info
                ? res.data.detail.intermediary_bank_info
                : "",
              additional_info: res.data.detail?.additional_info ? res.data.detail.additional_info : "",
            };
            setOption3Data(data);
          } else if (method == 4) {
            const data = {
              other_email: res.data.detail?.other_email ? res.data.detail.other_email : "",
              method: 4,
              check_payable: res.data.detail?.check_payable ? res.data.detail.check_payable : "",
              checkOption: res.data.detail?.check_payable ? "1" : "",
              check_attention: res.data.detail?.check_attention ? res.data.detail.check_attention : "",
              check_address: res.data.detail?.check_address ? res.data.detail.check_address : "",
              preferred_ref_no: res.data.detail?.preferred_ref_no ? +res.data.detail.preferred_ref_no : 0,
              additional_info: res.data.detail?.additional_info ? res.data.detail.additional_info : "",
            };
            setOption4Data(data);
          }
        }
      });
    }
  }, [props.show]);

  useEffect(() => {
    if (selectedOption == initiaMethod && props.show) {
      const res = { data: {} };
      res.data.detail = { ...selectedData };
      const method = res.data.detail.method;
      setSelectedOption(method);
      setInitialMethod(method);
      if (method == 1) {
        const data = {
          method: 1,
          other_email: res.data.detail.other_email ? res.data.detail.other_email : "",
          account_name: res.data.detail?.account_name ? res.data.detail.account_name : "",
          account_address: res.data.detail?.account_address ? res.data.detail.account_address : "",
          bank_name: res.data.detail?.bank_name ? res.data.detail.bank_name : "",
          account_iban_no: res.data.detail?.account_iban_no ? res.data.detail.account_iban_no : "",
          ach_routing_no: res.data.detail?.ach_routing_no ? res.data.detail.ach_routing_no : "",
          additional_info: res.data.detail?.additional_info ? res.data.detail.additional_info : "",
        };
        setOption1Data(data);
      } else if (method == 2) {
        const data = {
          method: 2,
          other_email: res.data.detail?.other_email ? res.data.detail.other_email : "",
          dwr_type: res.data.detail?.dwr_type ? +res.data.detail.dwr_type : "",
          account_name: res.data.detail?.account_name ? res.data.detail.account_name : "",
          account_address: res.data.detail?.account_address ? res.data.detail.account_address : "",
          account_iban_no: res.data.detail?.account_iban_no ? res.data.detail.account_iban_no : "",
          aba_swift_no: res.data.detail?.aba_swift_no ? res.data.detail.aba_swift_no : "",
          bank_name: res.data.detail?.bank_name ? res.data.detail.bank_name : "",
          bank_address: res.data.detail?.bank_address ? res.data.detail.bank_address : "",
          ach_routing_no: res.data.detail?.ach_routing_no ? res.data.detail.ach_routing_no : "",
          preferred_ref_no: res.data.detail?.preferred_ref_no ? +res.data.detail.preferred_ref_no : 0,
          intermediary_bank_info: res.data.detail?.intermediary_bank_info ? res.data.detail.intermediary_bank_info : "",
          additional_info: res.data.detail?.additional_info ? res.data.detail.additional_info : "",
        };
        setOption2Data(data);
        setInitialDWR(res.data.detail?.dwr_type ? +res.data.detail.dwr_type : "");
      } else if (method == 3) {
        const data = {
          other_email: res.data.detail?.other_email ? res.data.detail.other_email : "",
          method: 3,
          account_name: res.data.detail?.account_name ? res.data.detail.account_name : "",
          account_address: res.data.detail?.account_address ? res.data.detail.account_address : "",
          account_iban_no: res.data.detail?.account_iban_no ? res.data.detail.account_iban_no : "",
          aba_swift_no: res.data.detail?.aba_swift_no ? res.data.detail.aba_swift_no : "",
          bank_name: res.data.detail?.bank_name ? res.data.detail.bank_name : "",
          bank_address: res.data.detail?.bank_address ? res.data.detail.bank_address : "",
          preferred_ref_no: res.data.detail?.preferred_ref_no ? +res.data.detail.preferred_ref_no : 0,
          intermediary_bank_info: res.data.detail?.intermediary_bank_info ? res.data.detail.intermediary_bank_info : "",
          additional_info: res.data.detail?.additional_info ? res.data.detail.additional_info : "",
        };
        setOption3Data(data);
      } else if (method == 4) {
        const data = {
          other_email: res.data.detail?.other_email ? res.data.detail.other_email : "",
          method: 4,
          check_payable: res.data.detail?.check_payable ? res.data.detail.check_payable : "",
          checkOption: res.data.detail?.check_payable ? "1" : "",
          check_attention: res.data.detail?.check_attention ? res.data.detail.check_attention : "",
          check_address: res.data.detail?.check_address ? res.data.detail.check_address : "",
          preferred_ref_no: res.data.detail?.preferred_ref_no ? +res.data.detail.preferred_ref_no : 0,
          additional_info: res.data.detail?.additional_info ? res.data.detail.additional_info : "",
        };
        setOption4Data(data);
      }
    }
  }, [selectedOption]);

  const validate1 = () => {
    const data = { ...option1Data };
    const error = { ...error1 };
    if (!data.account_name) {
      error.account_name = "Account name is required";
    }
    if (!data.account_address) {
      error.account_address = "Account address is required";
    }
    if (!data.bank_name) {
      error.bank_name = "Bank name is required";
    }
    if (!data.account_iban_no) {
      error.account_iban_no = "Account number is required";
    }
    if (!data.ach_routing_no) {
      error.ach_routing_no = "ACH Routing Number is required";
    }
    const isEmpty = Object.values(error).every((x) => x === null || x === "");

    if (!isEmpty) {
      setError1(error);
      return false;
    } else {
      setError1(error);
      return true;
    }
  };

  const validate3 = () => {
    const data = { ...option3Data };
    const error = { ...error3 };
    if (!data.account_name) {
      error.account_name = "Beneficiary Name is required";
    }
    if (!data.account_address) {
      error.account_address = "Beneficiary Address is required";
    }
    if (!data.bank_name) {
      error.bank_name = "Beneficiary Bank Name is required";
    }
    if (!data.account_iban_no) {
      error.account_iban_no = "Beneficiary IBAN and/or Account Number is required";
    }
    if (!data.bank_address) {
      error.bank_address = "Beneficiary Bank Address is required";
    }
    if (!data.aba_swift_no) {
      error.aba_swift_no = "SWIFT Code is required";
    }
    const isEmpty = Object.values(error).every((x) => x === null || x === "");

    if (!isEmpty) {
      setError3(error);
      return false;
    } else {
      setError3(error);
      return true;
    }
  };

  const validate4 = () => {
    const data = { ...option4Data };
    const error = { ...error4 };
    if (!data.checkOption) {
      error.checkOption = "Please Select any option";
    } else {
      if (!data.check_payable) {
        error.check_payable = "Make check payable to is required";
      }
      if (!data.check_address) {
        error.check_address = " Mailing address for the check is required";
      }
      if (!data.preferred_ref_no) {
        error.preferred_ref_no = "Type of reference number is required";
      }
    }

    const isEmpty = Object.values(error).every((x) => x === null || x === "");
    if (isEmpty) {
      setError4(error);
      return true;
    } else {
      setError4(error);
      return false;
    }
  };

  const validate2 = () => {
    const data = { ...option2Data };
    const error = { ...error2 };
    if (data.dwr_type == "3") {
      return true;
    }

    if (!data.dwr_type) {
      error.dwr_type = "Please select any option to continue";
    }
    if (!data.account_name) {
      error.account_name = "Beneficiary Name is required";
    }
    if (!data.account_address) {
      error.account_address = "Beneficiary Address is required";
    }
    if (!data.bank_name) {
      error.bank_name = "Beneficiary Bank Name is required";
    }
    if (!data.account_iban_no) {
      error.account_iban_no = "Beneficiary IBAN and/or Account Number is required";
    }
    if (data.dwr_type != "2" && !data.bank_address) {
      error.bank_address = "Beneficiary Bank Address is required";
    }
    if (data.dwr_type != "2" && !data.aba_swift_no) {
      error.aba_swift_no = "SWIFT Code is required";
    }

    if (data.dwr_type != "1" && !data.ach_routing_no) {
      error.ach_routing_no = "ACH Routing Number is required";
    }
    const isEmpty = Object.values(error).every((x) => x === null || x === "");
    if (!isEmpty) {
      setError2(error);
      return false;
    } else {
      setError2(error);
      return true;
    }
  };

  const handleSubmit = () => {
    var payload = {};
    if (selectedOption == "1") {
      if (validate1()) {
        setLoading(true);
        setInitialMethod("1");
        ref && ref.current && ref.current.continuousStart();
        const value = { ...option1Data };
        payload = {
          other_email: value?.other_email ? value.other_email : "",
          method: 1,
          account_name: value?.account_name ? value.account_name : "",
          account_address: value?.account_address ? value.account_address : "",
          bank_name: value?.bank_name ? value.bank_name : "",
          account_iban_no: value?.account_iban_no ? value.account_iban_no : "",
          ach_routing_no: value?.ach_routing_no ? value.ach_routing_no : "",
          additional_info: value?.additional_info ? value.additional_info : "",
        };
        props.savePaymentInfo(props.id, payload, (res) => callback(res));
      }
    } else if (selectedOption == "2") {
      if (validate2()) {
        setLoading(true);
        setInitialMethod("2");
        setInitialDWR(option2Data?.dwr_type);
        ref && ref.current && ref.current.continuousStart();
        const value = { ...option2Data };
        payload = {
          other_email: value?.other_email ? value.other_email : "",
          method: 2,
          dwr_type: value?.dwr_type ? +value.dwr_type : "",
          account_name: value?.account_name ? value.account_name : "",
          account_address: value?.account_address ? value.account_address : "",
          account_iban_no: value?.account_iban_no ? value.account_iban_no : "",
          aba_swift_no: value?.aba_swift_no ? value.aba_swift_no : "",
          bank_name: value?.bank_name ? value.bank_name : "",
          bank_address: value?.bank_address ? value.bank_address : "",
          ach_routing_no: value?.ach_routing_no ? value.ach_routing_no : "",
          preferred_ref_no: value?.preferred_ref_no ? +value.preferred_ref_no : 0,
          intermediary_bank_info: value?.intermediary_bank_info ? value.intermediary_bank_info : "",
          additional_info: value?.additional_info ? value.additional_info : "",
        };
        props.savePaymentInfo(props.id, payload, (res) => callback(res));
      }
    } else if (selectedOption == "3") {
      if (validate3()) {
        setLoading(true);
        setInitialMethod("3");
        ref && ref.current && ref.current.continuousStart();
        const value = { ...option3Data };
        payload = {
          other_email: value?.other_email ? value.other_email : "",
          method: 3,
          account_name: value?.account_name ? value.account_name : "",
          account_address: value?.account_address ? value.account_address : "",
          account_iban_no: value?.account_iban_no ? value.account_iban_no : "",
          aba_swift_no: value?.aba_swift_no ? value.aba_swift_no : "",
          bank_name: value?.bank_name ? value.bank_name : "",
          bank_address: value?.bank_address ? value.bank_address : "",
          preferred_ref_no: value?.preferred_ref_no ? +value.preferred_ref_no : 0,
          intermediary_bank_info: value?.intermediary_bank_info ? value.intermediary_bank_info : "",
          additional_info: value?.additional_info ? value.additional_info : "",
        };
        props.savePaymentInfo(props.id, payload, (res) => callback(res));
      }
    } else if (selectedOption == "4") {
      if (validate4()) {
        setLoading(true);
        setInitialMethod("4");
        ref && ref.current && ref.current.continuousStart();
        const value = { ...option4Data };
        payload = {
          other_email: value?.other_email ? value.other_email : "",
          method: 4,
          check_payable: value?.check_payable ? value.check_payable : "",
          check_attention: value?.check_attention ? value.check_attention : "",
          check_address: value?.check_address ? value.check_address : "",
          preferred_ref_no: value?.preferred_ref_no ? +value.preferred_ref_no : 0,
          additional_info: value?.additional_info ? value.additional_info : "",
        };
        props.savePaymentInfo(props.id, payload, (res) => callback(res));
      }
    }
  };

  const callback = (res) => {
    if (res && res.status === 200) {
      props.onHide();
      setLoading(false);
      ref && ref.current && ref.current.complete();
      props.getPaymentInfo(props.id, (res) => {});
      toast(<AlertSuccess message={"Information Saved"} />);
    } else {
      setLoading(false);
      ref && ref.current && ref.current.complete();
      toast(
        <AlertError
          message={
            res && res.data.detail && res.data.detail.message ? res.data.detail.message : "Sommething Went Wrong"
          }
        />,
      );
    }
  };

  useEffect(() => {
    if (selectedOption == 2) {
      if (option2Data?.dwr_type == initialDWR) {
        const res = { data: {} };
        res.data.detail = { ...selectedData };
        const method = 2;
        setSelectedOption(method);
        setInitialMethod(method);

        if (method == 2) {
          const data = {
            method: 2,
            other_email: res.data.detail?.other_email ? res.data.detail.other_email : "",
            dwr_type: res.data.detail?.dwr_type ? +res.data.detail.dwr_type : "",
            account_name: res.data.detail?.account_name ? res.data.detail.account_name : "",
            account_address: res.data.detail?.account_address ? res.data.detail.account_address : "",
            account_iban_no: res.data.detail?.account_iban_no ? res.data.detail.account_iban_no : "",
            aba_swift_no: res.data.detail?.aba_swift_no ? res.data.detail.aba_swift_no : "",
            bank_name: res.data.detail?.bank_name ? res.data.detail.bank_name : "",
            bank_address: res.data.detail?.bank_address ? res.data.detail.bank_address : "",
            ach_routing_no: res.data.detail?.ach_routing_no ? res.data.detail.ach_routing_no : "",
            preferred_ref_no: res.data.detail?.preferred_ref_no ? +res.data.detail.preferred_ref_no : 0,
            intermediary_bank_info: res.data.detail?.intermediary_bank_info
              ? res.data.detail.intermediary_bank_info
              : "",
            additional_info: res.data.detail?.additional_info ? res.data.detail.additional_info : "",
          };
          setOption2Data(data);
        }
      }
    }
  }, [option2Data && option2Data.dwr_type]);

  const member = getProfile();

  const handleVerify = () => {
    setLoading(true);
    ref && ref.current && ref.current.continuousStart();
    props.verifyPaymentIfo(props.id, (res) => {
      if (res && res.status == 200) {
        props.onHide();
        setLoading(false);
        ref && ref.current && ref.current.complete();
        toast(<AlertSuccess message={"Information Saved"} />);
      } else {
        setLoading(false);
        ref && ref.current && ref.current.complete();
        toast(<AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  return (
    <>
      <LoadingBar height={5} color="#47AD1D" ref={ref} />
      <Offcanvas
        placement="end"
        backdropClassName="upload-documnets-backdrop"
        show={props.show}
        onHide={() => props.onHide()}
        className="offcanvas offcanvas-end border-0"
        id="uploadDocumentOffcanvas"
        enforceFocus={false}
      >
        <Offcanvas.Header>
          <h5 class="offcanvas-title">Payment Info</h5>
          <button
            type="button"
            class="offcanvas-close-btn d-flex align-items-center justify-content-center"
            onClick={() => props.onHide()}
          >
            <img src={close} alt="" />
          </button>
        </Offcanvas.Header>
        <Offcanvas.Body className="offcanvas-body p-0">
          {initialLoading ? (
            <div className="offcanvas-widget-row pt-0">
              <p>
                <Skeleton height="30px" />
              </p>
              <div class="four-column-radio-group fw-medium">
                <div class="fcrg-item payment-info-main-option">
                  <Skeleton height="15px" width="50%" />
                </div>
                <div class="fcrg-item payment-info-main-option">
                  <Skeleton height="15px" width="50%" />
                </div>
                <div class="fcrg-item payment-info-main-option">
                  <Skeleton height="15px" width="50%" />
                </div>
                <div class="fcrg-item payment-info-main-option">
                  <Skeleton height="15px" width="50%" />
                </div>
              </div>
            </div>
          ) : (
            <>
              {/* ========================Uncomment if needed=================== */}
              {/* <div className="offcanvas-widget-row fieldset pt-0">
                <label class="form-check-label mb-3">
                  <strong style={{ fontWeight: "500" }}>Verified</strong>
                </label>
                <div class="four-column-radio-group  gap-user ">
                  <div class="fcrg-item ">
                    <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
                      <input
                        class="form-check-input cursor-pointer"
                        type="radio"
                        name="WTflexRadioDefaultpp"
                        id="WTflexRadio__121"
                        checked={verified}
                        // disabled={verified}
                        onChange={(e) => {
                          if (verified) {
                            return;
                          } else {
                            handleVerify();
                          }
                        }}
                      />
                      <label class="form-check-label cursor-pointer" for="WTflexRadio__121">
                        Yes
                      </label>
                    </div>
                  </div>
                  <div class="fcrg-item  ">
                    <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
                      <input
                        class="form-check-input cursor-pointer"
                        type="radio"
                        name="WTflexRadioDefaultpp"
                        id="WTflexRadio__211"
                        checked={!verified ? true : false}
                        // disabled={verified}
                      />

                      <label class="form-check-label cursor-pointer" for="WTflexRadio__211">
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="offcanvas-widget-row ">
                <p>
                  <strong style={{ fontWeight: "500" }}>
                    What is your preferred method of payment?
                    <sup style={{ color: "red" }}>*</sup>
                  </strong>
                </p>
                <div class="four-column-radio-group fw-medium">
                  <div class="fcrg-item payment-info-main-option">
                    <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
                      <input
                        class="form-check-input cursor-pointer"
                        type="radio"
                        name="radionFlexDefault"
                        id="flexRadio__1"
                        checked={selectedOption == 1}
                        onChange={() => {
                          setError1({});
                          setOption1Data({});
                          setSelectedOption("1");
                        }}
                      />
                      <label class="form-check-label" htmlFor="flexRadio__1">
                        ACH/EFT (U.S. Only)
                      </label>
                    </div>
                  </div>
                  <div class="fcrg-item payment-info-main-option">
                    <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
                      <input
                        class="form-check-input cursor-pointer"
                        type="radio"
                        name="radionFlexDefault"
                        id="flexRadio__2"
                        checked={selectedOption == 2}
                        onChange={() => {
                          setError2({});
                          setOption2Data({});
                          setSelectedOption("2");
                        }}
                      />
                      <label class="form-check-label" htmlFor="flexRadio__2">
                        Domestic Wire Transfer (U.S. Only)
                      </label>
                    </div>
                  </div>
                  <div class="fcrg-item payment-info-main-option">
                    <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
                      <input
                        class="form-check-input cursor-pointer"
                        type="radio"
                        name="radionFlexDefault"
                        id="flexRadio__3"
                        checked={selectedOption == 3}
                        onChange={() => {
                          setError3({});
                          setOption3Data({});
                          setSelectedOption("3");
                        }}
                      />
                      <label class="form-check-label" htmlFor="flexRadio__3">
                        International Wire Transfer (Outside U.S.)
                      </label>
                    </div>
                  </div>
                  <div class="fcrg-item payment-info-main-option">
                    <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
                      <input
                        class="form-check-input cursor-pointer"
                        type="radio"
                        name="radionFlexDefault"
                        id="flexRadio__4"
                        checked={selectedOption == 4}
                        onChange={() => {
                          setError4({});
                          setOption4Data({});
                          setSelectedOption("4");
                        }}
                      />
                      <label class="form-check-label" htmlFor="flexRadio__4">
                        Check (U.S. Only)
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="offcanvas-widget-row field-set">
                {selectedOption == 1 && (
                  <PaymentTypeOne
                    setError1={setError1}
                    error1={error1}
                    option1Data={option1Data}
                    setOption1Data={setOption1Data}
                    member={member}
                    selectedOption={selectedOption}
                    setSelectedOption={setSelectedOption}
                  />
                )}
                {selectedOption == 2 && (
                  <PaymentTypeTwo
                    setError2={setError2}
                    error2={error2}
                    option2Data={option2Data}
                    setOption2Data={setOption2Data}
                    member={member}
                    selectedOption={selectedOption}
                    setSelectedOption={setSelectedOption}
                  />
                )}
                {selectedOption == 3 && (
                  <PaymentTypeThree
                    setError3={setError3}
                    error3={error3}
                    option3Data={option3Data}
                    setOption3Data={setOption3Data}
                    member={member}
                    selectedOption={selectedOption}
                    setSelectedOption={setSelectedOption}
                  />
                )}
                {selectedOption == 4 && (
                  <PaymentTypeFour
                    setError4={setError4}
                    error4={error4}
                    option4Data={option4Data}
                    setOption4Data={setOption4Data}
                    member={member}
                    selectedOption={selectedOption}
                    setSelectedOption={setSelectedOption}
                  />
                )}
              </div>
            </>
          )}
        </Offcanvas.Body>
        <div class="offcanvas-footer">
          {!initialLoading && (
            <a
              href="javascript:void(0)"
              class={`btn w-100 btn-default payment-verify-btn ${loading && "disabled"}`}
              onClick={() => handleSubmit()}
            >
              Submit
            </a>
          )}
        </div>
      </Offcanvas>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getPaymentInfo: (params, callback) => dispatch(getPaymentIform(params, callback)),
  savePaymentInfo: (params, data, callback) => dispatch(savePaymentIfo(params, data, callback)),
  verifyPaymentIfo: (params, data, callback) => dispatch(verifyPaymentIfo(params, data, callback)),

  //Global state for redux
  paymentInfoReceived: (data) => dispatch(paymentInfoReceived(data)),
});

const mapStateToProps = (state) => ({
  getUser: getUser(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(PaymentInfo));
