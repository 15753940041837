import React, { useRef } from "react";
import LoadingBar from "react-top-loading-bar";
import Header from "../../layouts/header";
import { updateCondition } from "../../store/aviation";
import { useState } from "react";
import { useEffect } from "react";
import AddAviationCondition from "../../components/settings/aviationConditions/modals/addAviationCondition";
import AlertSuccess from "../../common/alerts/alertSuccess";
import AlertError from "../../common/alerts/alertError";
import FilterModal from "../../components/settings/modals/filterModal";
import { toast } from "react-toastify";
import { addPartCondition, getPartCondition, getPartsDetails } from "../../store/parts";
import PartConditionTable from "../../components/parts/partConditionTable";
import { connect } from "react-redux";

const PartCondition = (props) => {
  const ref = useRef(null);
  const [active, setActive] = useState(true);
  const [selectedCondition, setSelectedCondition] = useState(null);
  const [loadingCondition, setLoadingCondition] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [page, setPage] = useState(1);
  const [showFilter, setShowFilter] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [response, setResponse] = useState({
    res: false,
    keyword: "",
  });
  const [editData, setEditData] = useState({});
  const [selectedId, setSelectedId] = useState("");
  const [addData, setAddData] = useState({
    title: "",
    code: "",
  });
  const [sort, setSort] = useState("title");
  const [order, setOrder] = useState("asc");

  useEffect(() => {
    setAddData({
      title: "",
      code: "",
    });
  }, [showAddModal]);

  const addAviationCondition = () => {
    ref?.current?.continuousStart();
    setLoadingCondition(true);
    props.addPartCondition(addData, (res) => {
      if (res && res.status === 200) {
        setPage(1);
        setResponse({
          res: false,
          keyword: "",
        });
        props?.getPartCondition({ isActive: active, page: 1, keyword: "", sort, order }, (res) => {});
        ref?.current?.complete();
        setLoadingCondition(false);
        toast(<AlertSuccess message={"Information Saved"} />);
        toggleAddModal();
      } else {
        ref?.current?.complete();
        setLoadingCondition(false);
        toast(
          <AlertError
            message={
              res.data.message
                ? res.data.message === "Plane condition already exists"
                  ? "Aviation condition already exists"
                  : res.data.message
                : "Something Went Wrong"
            }
          />,
        );
      }
    });
  };
  const handleEditAviationCondition = () => {
    ref?.current?.continuousStart();
    setLoadingCondition(true);
    const id = editData?._id;
    const payload = {
      title: editData?.title,
      code: editData?.code,
    };
    props.updateCondition(id, payload, (res) => {
      if (res && res.status === 200) {
        setResponse({
          res: false,
          keyword: "",
        });
        setPage(1);
        props?.getPartCondition({ isActive: active, page: 1, keyword: "", sort, order }, (res) => {});
        ref?.current?.complete();
        setLoadingCondition(false);
        toast(<AlertSuccess message={"Information Saved"} />);
        toggleAddModal();
      } else {
        ref?.current?.complete();
        setLoadingCondition(false);
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const toggleAddModal = () => {
    setShowAddModal(!showAddModal);
  };

  useEffect(() => {
    const payload = { isActive: active, page: page };
    ref?.current?.continuousStart();
    setLoadingCondition(true);
    props.getPartCondition(payload, (res) => {
      if (res && res.status === 200) {
        ref?.current?.complete();
        setLoadingCondition(false);
      } else {
        ref?.current?.complete();
        setLoadingCondition(false);
      }
    });
  }, [page]);

  useEffect(() => {
    ref?.current?.continuousStart();
    setLoadingCondition(true);
    setPage(1);
    props.getPartCondition({ isActive: active, page: 1, keyword: response.keyword, sort, order }, (res) => {
      if (res && res.status === 200) {
        ref?.current?.complete();
        setLoadingCondition(false);
      } else {
        ref?.current?.complete();
        setLoadingCondition(false);
      }
    });
  }, [sort, order]);

  const handleFilter = () => {
    ref?.current?.continuousStart();
    setLoadingCondition(true);
    props.getPartCondition({ isActive: active, page: 1, keyword: keyword, sort, order }, (res) => {
      setPage(1);
      if (res && res.status === 200) {
        const data = { ...response };
        data.res = true;
        data.keyword = keyword;
        setResponse(data);
        ref?.current?.complete();
        setLoadingCondition(false);
        toggleFilter();
      } else {
        const data = { ...response };
        data.res = false;
        data.keyword = "";
        setResponse(data);
        ref?.current?.complete();
        setLoadingCondition(false);
      }
    });
  };
  const clearFilter = () => {
    setPage(1);
    ref?.current?.continuousStart();
    setLoadingCondition(true);
    props.getPartCondition({ isActive: active, page: 1, keyword: "", sort, order }, (res) => {
      if (res && res.status === 200) {
        const data = { ...response };
        data.res = false;
        data.keyword = "";
        setResponse(data);
        ref?.current?.complete();
        setLoadingCondition(false);
      } else {
        const data = { ...response };
        data.res = true;
        data.keyword = keyword;
        setResponse(data);
        ref?.current?.complete();
        setLoadingCondition(false);
      }
    });
  };

  const conditionData = props?.getPartsDetails?.partCondition?.data;

  const totalCount =
    props.getPartsDetails &&
    props.getPartsDetails?.partCondition &&
    props.getPartsDetails?.partCondition?.pagination?.totalCount;

  const filteredCount =
    props.getPartsDetails &&
    props.getPartsDetails?.partCondition &&
    props.getPartsDetails?.partCondition.pagination?.filteredCount;

  const totalPages = Math.ceil(totalCount / 20);

  const toggleFilter = () => {
    setKeyword(response.keyword);
    setShowFilter(!showFilter);
  };

  return (
    <>
      <LoadingBar height={5} color="#47AD1D" ref={ref} />
      <Header />
      <PartConditionTable
        active={active}
        setActive={setActive}
        data={conditionData}
        loading={loadingCondition}
        setSelectedModel={setSelectedCondition}
        aviationType={selectedCondition}
        toggleAddModal={toggleAddModal}
        editData={editData}
        setEditData={setEditData}
        setSelectedId={setSelectedId}
        page={page}
        setPage={setPage}
        totalCount={totalCount}
        filteredCount={filteredCount}
        totalPages={totalPages}
        toggleFilter={toggleFilter}
        keyword={keyword}
        setKeyword={setKeyword}
        handleFilter={handleFilter}
        clearFilter={clearFilter}
        response={response}
        sort={sort}
        setSort={setSort}
        order={order}
        setOrder={setOrder}
      />
      <AddAviationCondition
        show={showAddModal}
        onHide={toggleAddModal}
        setSelectedModel={setSelectedCondition}
        aviationType={selectedCondition}
        editData={editData}
        setEditData={setEditData}
        active={active}
        setActive={setActive}
        handleFilter={handleFilter}
        addAviationCondition={addAviationCondition}
        handleEditAviationCondition={handleEditAviationCondition}
        loading={loadingCondition}
        addData={addData}
        setAddData={setAddData}
      />
      <FilterModal
        show={showFilter}
        onHide={toggleFilter}
        loading={loadingCondition}
        handleFilter={handleFilter}
        keyword={keyword}
        setKeyword={setKeyword}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getPartCondition: (params, callback) => dispatch(getPartCondition(params, callback)),
  addPartCondition: (data, callback) => dispatch(addPartCondition(data, callback)),
  updateCondition: (id, data, callback) => dispatch(updateCondition(id, data, callback)),
});

const mapStateToProps = (state) => ({
  getPartsDetails: getPartsDetails(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(PartCondition));
