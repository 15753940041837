import React, { useEffect, useState } from "react";
import AnimalModal from "../../../layouts/modals/animal/animalModal";
import Skeleton from "react-loading-skeleton";
import { connect } from "react-redux";
import {
  ei,
  getAuctionSpecies,
  getCategory,
  getCountry,
  getRegion,
  getSpecies,
  getState,
  saveActionSpecies,
} from "../../../store/ei";
import AlertError from "../../../common/alerts/alertError";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import { toast } from "react-toastify";
import { productLocationDetails } from "../../../store/productLocation";
import { capitalizeFirstLetter } from "../../../utils.js/conversion";

const Animal = (props) => {
  const speciesData = props?.ei?.auctionSpecies?.data;
  const productLocation = props?.ei?.auctionSpecies?.data?.productLocation;
  const savedSpecies = props?.ei?.auctionSpecies?.savedSpecies;
  const region = props?.ei?.region?.data ? props?.ei?.region?.data : [];
  const category = props?.ei?.category?.data ? props?.ei?.category?.data : [];
  const species = props?.ei?.species?.data;

  const mainRegions = speciesData?.regions?.find((val) => val?._id === savedSpecies?.region);
  const locations = productLocation?.location2?.split(", ");
  const mainRegion = region?.find(
    (val) =>
      val?.countryName ===
      (locations && locations.length > 1 ? locations[1] : locations && locations.length > 0 ? locations[0] : undefined),
  );
  const subRegion = region?.find((val) => val?.state === (locations && locations.length > 0 ? locations[0] : ""));

  const mainCategory = category?.find((val) => val?._id === savedSpecies?.category);
  const locationDetails =
    props &&
    props.productLocationDetails &&
    props.productLocationDetails.productLocation &&
    props.productLocationDetails.productLocation.data;

  const [animalModal, setAnimalModal] = useState(false);
  const [loadingOptions, setLoadingOptions] = useState(false);
  const [errors, setErrors] = useState({});
  const [image, setImage] = useState("");
  const [regionSelect, setRegionSelect] = useState({
    countryName: "Please Select Region",
    _id: "",
  });
  const [subRegionSelect, setSubRegionSelect] = useState({
    name: "Please Select Sub-region",
    _id: "",
  });
  const [categorySelect, setCategorySelect] = useState({
    name: "Please Select Category",
    _id: "",
  });
  const [speciesSelect, setSpeciesSelect] = useState({
    name: "Please Select Animal",
    _id: "",
  });
  const [data, setData] = useState({
    region: "",
    subRegion: "",
    catgeory: "",
    animal: "",
  });
  const [loading, setLoading] = useState(false);

  const toggleAnimalModal = () => {
    setAnimalModal(!animalModal);
  };

  const validate = () => {
    let valid = true;
    setErrors({});
    let error = {};
    if (regionSelect?.countryName) {
      if (regionSelect?.countryName === "United States") {
        if (subRegionSelect?._id) {
        } else {
          error.subRegion = "Please Select Sub-region";
          valid = false;
        }
      } else {
        if (!regionSelect?._id) {
          error.region = "Please Select Region";
          valid = false;
        }
      }
    } else {
      error.region = "Please Select Region";
      valid = false;
    }

    if (!speciesSelect?._id) {
      error.animal = "Please Select Animal";
      valid = false;
    }
    setErrors(error);
    return valid;
  };

  const handleSave = () => {
    if (validate()) {
      setLoading(true);
      props?.refStart();
      const payload = {
        category: categorySelect?._id,
        region: regionSelect?.countryName === "United States" ? subRegionSelect?._id : regionSelect?._id,
        species: speciesSelect?._id,
      };
      props?.saveActionSpecies(props?.auctionId, payload, (res) => {
        if (res.status == 200) {
          setLoading(false);
          props?.getAuctionSpecies(props?.auctionId, (res) => {});
          props?.refStop();
          toast(<AlertSuccess message="Information Saved" />);
          toggleAnimalModal();
        } else {
          setLoading(false);
          props?.refStop();
          toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        }
      });
    }
  };

  useEffect(() => {
    props?.getRegion((res) => {});
    props?.getCategory((res) => {});
    if (mainRegions?.countryName === "United States" || mainRegion?.countryName === "United States") {
      setRegionSelect({
        countryName: mainRegions?.countryName
          ? mainRegions?.countryName
          : mainRegion?.countryName
          ? mainRegion?.countryName
          : "Please Select Region",
        _id: mainRegions?.countryName ? "0" : mainRegion?.countryName ? "0" : "",
      });
      setSubRegionSelect({
        name: mainRegions?.state
          ? mainRegions?.state
          : subRegion?.state
          ? subRegion?.state
          : "Please Select Sub-region",
        _id: mainRegions?._id ? mainRegions?._id : subRegion?._id ? subRegion?._id : "",
      });
    } else {
      setRegionSelect({
        countryName: mainRegions?.countryName
          ? mainRegions?.countryName
          : mainRegion?.countryName
          ? mainRegion?.countryName
          : "Please Select Region",
        _id: mainRegions?._id ? mainRegions?._id : mainRegion?._id ? mainRegion?._id : "",
      });
      setSubRegionSelect({
        name: "Please Select Sub-region",
        _id: "",
      });
    }
    setCategorySelect({
      name: speciesData?.speciesCategoryName
        ? speciesData?.speciesCategoryName?.charAt(0)?.toUpperCase() + speciesData?.speciesCategoryName?.slice(1)
        : "Please Select Category",
      _id: speciesData?.speciesCategory ? speciesData?.speciesCategory : "",
    });
    setErrors({});
  }, [animalModal, setRegionSelect]);

  const fetchSpecies = () => {
    setLoadingOptions(true);
    const params = {
      regions: regionSelect?.countryName === "United States" ? subRegionSelect?._id : regionSelect?._id,
      categories: categorySelect?._id,
    };
    props?.getSpecies(params, (res) => {
      if (res.status === 200) {
        setLoadingOptions(false);
      } else {
        setLoadingOptions(false);
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };
  useEffect(() => {
    if (animalModal) {
      fetchSpecies();
    }
  }, [regionSelect, subRegionSelect, categorySelect]);

  useEffect(() => {
    if (species?.some((val) => val?._id === speciesData?._id)) {
      setSpeciesSelect({
        name: speciesData?.name?.charAt(0).toUpperCase() + speciesData?.name.slice(1),
        _id: speciesData?._id,
      });
      setImage(speciesData?.coverImage?.medium);
    } else {
      setSpeciesSelect({
        name: "Please Select Animal",
        _id: "",
      });
      setImage("");
    }
  }, [species]);

  return (
    <>
      <div class="d-flex align-items-center justify-content-between">
        <div
          class="page-widget-label text-capitalize cursor-pointer"
          onClick={() => !props.loadingMain && toggleAnimalModal()}
        >
          Animal
        </div>
      </div>
      <div class="table-responsive mt-2">
        <table class="table mb-0  product-table">
          <colgroup>
            <col style={{ width: "35%" }} />
            <col />
          </colgroup>
          <tbody>
            {props.loadingMain ? (
              <>
                <tr>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                </tr>
              </>
            ) : (
              <>
                <tr>
                  <td>Region</td>
                  <td>
                    {mainRegions?.countryName
                      ? mainRegions?.countryName
                      : mainRegion?.countryName
                      ? mainRegion?.countryName
                      : "N/A"}
                  </td>
                </tr>
                {(mainRegions?.countryName === "United States" || mainRegion?.countryName === "United States") && (
                  <tr>
                    <td>Sub Region</td>
                    <td>{mainRegions?.state ? mainRegions?.state : subRegion?.state ? subRegion?.state : "N/A"}</td>
                  </tr>
                )}
                <tr>
                  <td>Category</td>
                  <td>
                    {speciesData?.speciesCategoryName
                      ? speciesData?.speciesCategoryName?.charAt(0)?.toUpperCase() +
                        speciesData?.speciesCategoryName?.slice(1)
                      : "N/A"}
                  </td>
                </tr>
                <tr>
                  <td>Animal</td>
                  <td>
                    <div className="d-block text-transfrom">
                      {speciesData?.name ? capitalizeFirstLetter(speciesData?.name) : "N/A"}
                    </div>
                    {speciesData?.coverImage?.medium && (
                      <div className="d-block">
                        <div className="env_box">
                          <img src={`${process.env.REACT_APP_EI_MEDIA}${speciesData?.coverImage?.medium}`} alt="" />
                        </div>
                      </div>
                    )}
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </table>
        <div class=" mt-2"></div>
      </div>
      <AnimalModal
        show={animalModal}
        onHide={toggleAnimalModal}
        data={data}
        errors={errors}
        region={region}
        category={category}
        species={species}
        handleSave={handleSave}
        regionSelect={regionSelect}
        setRegionSelect={setRegionSelect}
        subRegionSelect={subRegionSelect}
        setSubRegionSelect={setSubRegionSelect}
        categorySelect={categorySelect}
        setCategorySelect={setCategorySelect}
        speciesSelect={speciesSelect}
        setSpeciesSelect={setSpeciesSelect}
        loading={loadingOptions}
        image={image}
        setImage={setImage}
        picture={speciesData?.coverImage?.medium}
        loadingMain={loading}
        fetchSpecies={fetchSpecies}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getAuctionSpecies: (id, callback) => dispatch(getAuctionSpecies(id, callback)),
  getRegion: (callback) => dispatch(getRegion(callback)),
  getCountry: (callback) => dispatch(getCountry(callback)),
  getState: (callback) => dispatch(getState(callback)),
  getCategory: (callback) => dispatch(getCategory(callback)),
  getSpecies: (params, callback) => dispatch(getSpecies(params, callback)),
  saveActionSpecies: (id, data, callback) => dispatch(saveActionSpecies(id, data, callback)),
});

const mapStateToProps = (state) => ({
  ei: ei(state),
  productLocationDetails: productLocationDetails(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Animal);
