import React, { useState } from "react";
import close from "../../../include/images/close-icon.svg";
import More from "../../../include/images/more-icon.svg";
import reminder from "../../../include/images/reminder.svg";

import emptyFolder from "../../../include/images/folder.svg";
import moment from "moment/moment";
import Skeleton from "react-loading-skeleton";
import Sidebar from "../../../layouts/sidebar";
import OrderRequestFilter from "./orderRequestFilter";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import _ from "lodash";
const OrderRequestTable = (props) => {
  //For SideNav
  const [showMenu, setShowMenu] = useState(false);
  const [menuList, setMenuList] = useState("");
  let pages = _.range(
    props?.page - 5 < 0 ? 0 : props?.page - 5,
    props?.totalPages > 10 ? props?.page + 5 : props?.totalPages,
  );

  console.log(props.response?.isReminderSet?.value !== undefined, "are the response from the props..");

  return (
    <>
      <div class="dashboard-insider position-relative d-lg-flex w-100 ">
        <Sidebar
          showMenu={showMenu}
          setShowMenu={setShowMenu}
          menuList={menuList}
          setMenuList={setMenuList}
          page="aviation"
          pageCat="order-requests"
        />
        <div className={showMenu ? ` main-menu-expanded ` : "main-menu-compressed"}>
          <div class="filter-container d-flex align-items-center sticky-top">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12 d-flex align-items-center flex-wrap justify-content-between">
                  <div class="filter-title fw-medium text-capitalize">RFQ</div>
                  <div class="filter-widget-right d-flex align-items-center flex-wrap">
                    <div class="fw-item">
                      {props.loading ? (
                        <Skeleton width="50px" height="30px" />
                      ) : (
                        <div class="filter-row d-flex align-items-center" onClick={() => props.toggleFilter()}>
                          <svg viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M2.39767 16.5C2.07751 16.5 1.81796 16.2357 1.81796 15.9097V1.09032C1.81796 0.764295 2.07751 0.5 2.39767 0.5C2.71784 0.5 2.97738 0.764295 2.97738 1.09032V15.9097C2.97738 16.2357 2.71784 16.5 2.39767 16.5Z"
                              fill="currentColor"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M13.0226 15.9097C13.0226 16.2357 13.2822 16.5 13.6023 16.5C13.9225 16.5 14.182 16.2357 14.182 15.9097V1.09032C14.182 0.764295 13.9225 0.5 13.6023 0.5C13.2822 0.5 13.0226 0.764295 13.0226 1.09032V15.9097ZM0.57971 13.18H4.21565C4.53582 13.18 4.79536 12.9158 4.79536 12.5897C4.79536 12.2637 4.53582 11.9994 4.21565 11.9994H0.57971C0.259545 11.9994 0 12.2637 0 12.5897C0 12.9158 0.259545 13.18 0.57971 13.18Z"
                              fill="currentColor"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M7.42029 15.9097C7.42029 16.2357 7.67983 16.5 8 16.5C8.32016 16.5 8.57971 16.2357 8.57971 15.9097V1.09032C8.57971 0.764295 8.32016 0.5 8 0.5C7.67983 0.5 7.42029 0.764295 7.42029 1.09032V15.9097ZM11.7843 13.18H15.4203C15.7405 13.18 16 12.9158 16 12.5897C16 12.2637 15.7405 11.9994 15.4203 11.9994H11.7843C11.4642 11.9994 11.2046 12.2637 11.2046 12.5897C11.2046 12.9158 11.4642 13.18 11.7843 13.18Z"
                              fill="currentColor"
                            />
                            <path
                              d="M9.81798 5.00058H6.18204C5.86187 5.00058 5.60233 4.73629 5.60233 4.41027C5.60233 4.08424 5.86187 3.81995 6.18204 3.81995H9.81798C10.1381 3.81995 10.3977 4.08424 10.3977 4.41027C10.3977 4.73629 10.1381 5.00058 9.81798 5.00058Z"
                              fill="currentColor"
                            />
                          </svg>
                          <span>Filters</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="table-container">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12">
                  <div class="tb-filter-row d-flex align-items-start justify-content-between">
                    <div class="filter-tags d-flex align-items-start">
                      {!props.loading && props.applyFilter && (
                        <>
                          <div class="filter-tag-title">Filters Applied :</div>

                          <div class="tags">
                            {props.response && props.response?.orderId && (
                              <span class="badge">
                                Request ID : <span>{props.response && props.response.orderId}</span>
                                <img
                                  class="tag-close-icon"
                                  src={close}
                                  alt=""
                                  onClick={() => {
                                    const data = { ...props.response };
                                    data.orderId = "";
                                    data.res = false;
                                    props.setResponse(data);
                                    props?.setClearFilter(true);
                                  }}
                                />
                              </span>
                            )}
                            {props.response && props.response?.keyword && (
                              <span class="badge">
                                Keyword : <span>{props.response && props.response.keyword}</span>
                                <img
                                  class="tag-close-icon"
                                  src={close}
                                  alt=""
                                  onClick={() => {
                                    const data = { ...props.response };
                                    data.keyword = "";
                                    data.res = false;
                                    props.setResponse(data);
                                    props?.setClearFilter(true);
                                  }}
                                />
                              </span>
                            )}
                            {props.response && props?.response?.isReminderSet?.value !== undefined && (
                              <span class="badge">
                                Reminder :{" "}
                                <span>{props.response && props.response.isReminderSet?.value ? "Yes" : "No"}</span>
                                <img
                                  class="tag-close-icon"
                                  src={close}
                                  alt=""
                                  onClick={() => {
                                    const data = { ...props.response };
                                    data.isReminderSet = "";
                                    data.res = false;
                                    props.setResponse(data);
                                    props?.setClearFilter(true);
                                  }}
                                />
                              </span>
                            )}
                          </div>
                        </>
                      )}{" "}
                      {props.loading && (
                        <div class="filter-tags d-flex align-items-start">
                          <div class="filter-tag-title">
                            <div class="tags d-flex align-items-start">
                              <span class="badge">
                                <Skeleton />
                              </span>
                              <span class="badge">
                                <Skeleton />
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div class="selected-table-row d-flex justify-content-between"></div>
                  <div
                    class={`${
                      props.orderRequestData && props.orderRequestData.length > 0 ? "min-user-table-height" : ""
                    }`}
                  >
                    <table class="table align-middle table-collapse-table sticky-header-table">
                      <thead className="custom-thead">
                        <tr>
                          <th className="table-header" style={{ width: "4%" }}>
                            Actions
                          </th>
                          <th className="table-header" style={{ width: "6%" }}>
                            Request ID
                          </th>
                          <th className="table-header" style={{ width: "8%" }}>
                            <a className="sort-by"> Part Number </a>{" "}
                          </th>
                          <th className="table-header" style={{ width: "12%" }}>
                            <a className="sort-by">Listings</a>{" "}
                          </th>
                          <th className="table-header" style={{ width: "12%" }}>
                            <a className="d-flex justify-content-end">Price</a>
                          </th>
                          <th className="table-header" style={{ width: "6%" }}>
                            <span className="d-flex justify-content-end"> Quantity </span>
                          </th>
                          <th className="table-header" style={{ width: "12%" }}>
                            <a className="sort-by"> Trader </a>{" "}
                          </th>
                          <th className="table-header" style={{ width: "12%" }}>
                            <a className="sort-by"> Seller Info</a>{" "}
                          </th>
                          <th className="table-header" style={{ width: "12%" }}>
                            <a className="sort-by">Buyer Info </a>
                          </th>
                          <th className="table-header" style={{ width: "10%" }}>
                            <a className="sort-by">Notes </a>{" "}
                          </th>
                          <th className="table-header" style={{ width: "6%" }}>
                            <a className="sort-by"> Date </a>{" "}
                          </th>
                        </tr>
                      </thead>
                      <tbody className="table-body hide-scrollbar">
                        {props.loading
                          ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2, 3, 4].map((item) => (
                              <tr>
                                <td style={{ width: "5%" }}>
                                  <Skeleton />
                                </td>
                                <td style={{ width: "15%", minWidth: "133px" }}>
                                  <Skeleton />
                                </td>
                                <td style={{ width: "15%" }}>
                                  <Skeleton />
                                </td>
                                <td style={{ width: "15%" }}>
                                  <Skeleton />
                                </td>
                                <td style={{ width: "15%" }}>
                                  <Skeleton />
                                </td>
                                <td style={{ width: "15%" }}>
                                  <Skeleton />
                                </td>

                                <td style={{ width: "20%" }}>
                                  <Skeleton />
                                </td>
                                <td style={{ width: "20%" }}>
                                  <Skeleton />
                                </td>
                                <td style={{ width: "20%" }}>
                                  <Skeleton />
                                </td>
                                <td style={{ width: "15%" }}>
                                  <Skeleton />
                                </td>
                                <td style={{ width: "35%" }}>
                                  <Skeleton />
                                </td>
                              </tr>
                            ))
                          : props.orderRequestData &&
                            props.orderRequestData?.length > 0 &&
                            props.orderRequestData?.map((item) => {
                              return (
                                <tr>
                                  <td style={{ width: "4%" }}>
                                    <div class="td-action-btn-group d-flex align-items-center justify-content-between">
                                      <div class="dropdown">
                                        <a
                                          class="td-a-icon dropdown-toggle"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          <img src={More} alt="" />
                                        </a>
                                        <ul class="dropdown-menu ms-1">
                                          <li className="menu-item">
                                            <a
                                              class={`dropdown-item`}
                                              href="javascript:void(0)"
                                              onClick={() => {
                                                item?.isReminderSet
                                                  ? props?.handleDeleteReminder(item)
                                                  : props?.handleAddReminder(item);
                                              }}
                                            >
                                              {item?.isReminderSet ? "Remove Reminder" : "Set Reminder"}
                                            </a>
                                          </li>
                                          <li className="menu-item">
                                            <a
                                              class="dropdown-item"
                                              href="javascript:void(0)"
                                              onClick={() => {
                                                props?.setRfqId(item?._id);
                                                props?.toggleArchiveModal();
                                              }}
                                            >
                                              Archive
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </td>
                                  <td style={{ width: "6%" }}>
                                    {item?.isReminderSet && <img src={reminder} alt="" />}
                                    <button
                                      className="green-btn-text ms-1"
                                      onClick={() => {
                                        props.setTableData(item);
                                        props.toggleLogModal();
                                      }}
                                    >
                                      {item?.orderId ? item?.orderId : "N/A"}
                                    </button>
                                  </td>
                                  <td style={{ width: "8%" }}>
                                    <div className="text-wrapper">
                                      {item?.aviationPart?.partNumber ? item?.aviationPart?.partNumber : 0}
                                    </div>
                                  </td>
                                  <td style={{ width: "12%" }}>
                                    <div className="mb-2">
                                      Master Listing:{" "}
                                      {item.auctionId ? (
                                        <span
                                          className="green-btn-text cursor-pointer"
                                          onClick={() => window.open(`/listing-detail?id=${item.auctionId}`)}
                                        >
                                          {item?.auctionId}
                                        </span>
                                      ) : (
                                        "N/A"
                                      )}
                                    </div>
                                    <div>
                                      RFQ Listing:{" "}
                                      {item.rfqAuctionId ? (
                                        <span
                                          className="green-btn-text cursor-pointer"
                                          onClick={() => window.open(`/listing-detail?id=${item.rfqAuctionId}`)}
                                        >
                                          {item?.rfqAuctionId}
                                        </span>
                                      ) : (
                                        "N/A"
                                      )}
                                    </div>
                                  </td>
                                  <td align="right" style={{ width: "12%" }}>
                                    <div>
                                      WholeSale Value:{" "}
                                      {item?.trader?.orig_wholesale_value
                                        ? "$" +
                                          new Intl.NumberFormat("en-US", {
                                            style: "decimal",
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }).format(item?.trader?.orig_wholesale_value)
                                        : "$0.00"}
                                    </div>
                                    <div>
                                      Seller Asking Price:{" "}
                                      {item?.aviationPart?.unitPrice
                                        ? "$" +
                                          new Intl.NumberFormat("en-US", {
                                            style: "decimal",
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }).format(item?.aviationPart?.unitPrice)
                                        : "$0.00"}
                                    </div>
                                    <div>
                                      Buyer Offer:{" "}
                                      {item?.buyerOffer
                                        ? "$" +
                                          new Intl.NumberFormat("en-US", {
                                            style: "decimal",
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }).format(item?.buyerOffer)
                                        : "$0.00"}
                                    </div>
                                  </td>
                                  {/* <td>
                                    {item?.trader?.orig_wholesale_value
                                      ? "$" +
                                        new Intl.NumberFormat("en-US", {
                                          style: "decimal",
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        }).format(item?.trader?.orig_wholesale_value)
                                      : "$0.00"}
                                  </td>
                                  <td>
                                    {item?.aviationPart?.unitPrice
                                      ? "$" +
                                        new Intl.NumberFormat("en-US", {
                                          style: "decimal",
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        }).format(item?.aviationPart?.unitPrice)
                                      : "$0.00"}
                                  </td> */}
                                  <td align="right" style={{ width: "6%" }}>
                                    <span>{item.quantity ? item.quantity : 0}</span>
                                  </td>
                                  {/* <td style={{ minWidth: "180px" }}> {item?.buyerOffer ? item.buyerOffer : "0"}</td> */}
                                  <td style={{ width: "12%" }}>
                                    {item?.trader?.bc_id_name || item?.trader?.bc_id_email ? (
                                      <>
                                        <span
                                          className="green-btn-text cursor-pointer"
                                          onClick={() => {
                                            if (item?.trader?.bc_id_name) {
                                              if (process.env.REACT_APP_MODE == "development") {
                                                window.open(
                                                  `https://admin-dev.salvex.com/admin/_user/_edit.cfm?user_id=${item?.trader?.bc_user_id}`,
                                                );
                                              } else {
                                                window.open(
                                                  `https://admin.salvex.com/admin/_user/_edit.cfm?user_id=${item?.trader?.bc_user_id}`,
                                                );
                                              }
                                            }
                                          }}
                                        >
                                          {item?.trader?.bc_id_name}
                                        </span>
                                        <br />
                                        <a
                                          className="black-color-text text-wrapper"
                                          href={`mailto:${item?.trader?.bc_id_email}`}
                                        >
                                          {item?.trader?.bc_id_email}
                                        </a>
                                      </>
                                    ) : (
                                      "N/A"
                                    )}
                                  </td>
                                  <td
                                    style={{ width: "12%" }}
                                    className="cursor-pointer"
                                    onClick={() => {
                                      if (item?.seller?.user_id) {
                                        if (process.env.REACT_APP_MODE == "development") {
                                          window.open(
                                            `https://admin-dev.salvex.com/admin/_user/_edit.cfm?user_id=${item?.seller?.user_id}`,
                                          );
                                        } else {
                                          window.open(
                                            `https://admin.salvex.com/admin/_user/_edit.cfm?user_id=${item?.seller?.user_id}`,
                                          );
                                        }
                                      }
                                    }}
                                  >
                                    {item?.seller?.name01 || item?.seller.email ? (
                                      <>
                                        <span
                                          className="green-btn-text cursor-pointer"
                                          onClick={() => {
                                            if (item?.seller?.name01) {
                                              if (process.env.REACT_APP_MODE == "development") {
                                                window.open(
                                                  `https://admin-dev.salvex.com/admin/_user/_edit.cfm?user_id=${item?.seller?.user_id}`,
                                                );
                                              } else {
                                                window.open(
                                                  `https://admin.salvex.com/admin/_user/_edit.cfm?user_id=${item?.seller?.user_id}`,
                                                );
                                              }
                                            }
                                          }}
                                        >
                                          {item?.seller?.name01 ? item?.seller?.name01 : "N/A"}
                                        </span>
                                        <br />
                                        <a
                                          className="black-color-text text-wrapper"
                                          href={`mailto:${item?.seller?.email}`}
                                        >
                                          {item?.seller?.email}
                                        </a>
                                        <br />
                                        <span
                                          className="black-color-text"
                                          onClick={() => {
                                            if (item?.seller?.company_name) {
                                              if (process.env.REACT_APP_MODE == "development") {
                                                window.open(
                                                  `https://admin-dev.salvex.com/admin/_user/_edit.cfm?user_id=${item?.seller?.user_id}`,
                                                );
                                              } else {
                                                window.open(
                                                  `https://admin.salvex.com/admin/_user/_edit.cfm?user_id=${item?.seller?.user_id}`,
                                                );
                                              }
                                            }
                                          }}
                                        >
                                          {item?.seller?.company_name}
                                        </span>

                                        <OverlayTrigger
                                          placement="bottom"
                                          overlay={
                                            <Tooltip style={{ position: "fixed" }}>
                                              <div>
                                                <span>Key: {item?.seller?.key_seller ? "Yes" : "No"}</span>
                                              </div>
                                              <div>
                                                <span>Approved: {item?.seller?.approved_seller ? "Yes" : "No"}</span>
                                              </div>
                                              <div>
                                                <span>
                                                  Profit:{" "}
                                                  {item?.seller_stats?.profit
                                                    ? "$" +
                                                      new Intl.NumberFormat("en-US", {
                                                        style: "decimal",
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                      }).format(item?.seller_stats?.profit)
                                                    : "$0.00"}
                                                </span>
                                              </div>
                                              <div>
                                                <span>
                                                  Listings (Sold/Total):{" "}
                                                  {`${item?.seller_stats?.sold ? item?.seller_stats?.sold : "0"}/${
                                                    item?.seller_stats?.total ? item?.seller_stats?.total : "0"
                                                  }`}
                                                </span>
                                              </div>
                                              <div>
                                                <span>
                                                  Success Rate:{" "}
                                                  {item?.seller_stats?.success_rate
                                                    ? "$" +
                                                      new Intl.NumberFormat("en-US", {
                                                        style: "decimal",
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                      }).format(item?.seller_stats?.success_rate)
                                                    : "0.00%"}
                                                </span>
                                              </div>
                                            </Tooltip>
                                          }
                                        >
                                          <div class="d-text-list d-flex align-items-center justify-center">
                                            <div class="d-text-item fw-medium green-text-label">
                                              <span
                                                className="text-nowrap text-success"
                                                style={{ color: "#47AD1D !important" }}
                                              >
                                                {item?.seller_stats?.ratings?.all && item?.seller_stats?.ratings?.good
                                                  ? Math.round(
                                                      (item?.seller_stats?.ratings?.good /
                                                        item?.seller_stats?.ratings?.all) *
                                                        100,
                                                    )
                                                  : "0"}
                                                % (
                                                {item?.seller_stats?.ratings?.good
                                                  ? item?.seller_stats?.ratings?.good
                                                  : "0"}
                                                )
                                              </span>
                                            </div>
                                            <div class="d-text-item fw-medium green-text-label">
                                              <span className="text-nowrap text-secondary">
                                                {item?.seller_stats?.ratings?.all &&
                                                item?.seller_stats?.ratings?.neutral
                                                  ? Math.round(
                                                      (item?.seller_stats?.ratings?.neutral /
                                                        item?.seller_stats?.ratings?.all) *
                                                        100,
                                                    )
                                                  : "0"}
                                                % (
                                                {item?.seller_stats?.ratings?.neutral
                                                  ? item?.seller_stats?.ratings?.neutral
                                                  : "0"}
                                                )
                                              </span>
                                            </div>
                                            <div class="d-text-item fw-medium green-text-label">
                                              <span className="text-nowrap text-danger">
                                                {item?.seller_stats?.ratings?.all && item?.seller_stats?.ratings?.bad
                                                  ? Math.round(
                                                      (item?.seller_stats?.ratings?.bad /
                                                        item?.seller_stats?.ratings?.all) *
                                                        100,
                                                    )
                                                  : "0"}
                                                % (
                                                {item?.seller_stats?.ratings?.bad
                                                  ? item?.seller_stats?.ratings?.bad
                                                  : "0"}
                                                )
                                              </span>
                                            </div>
                                          </div>
                                        </OverlayTrigger>
                                      </>
                                    ) : (
                                      "N/A"
                                    )}
                                  </td>
                                  <td
                                    style={{ width: "12%" }}
                                    className="cursor-pointer"
                                    onClick={() => {
                                      if (item.userId) {
                                        if (process.env.REACT_APP_MODE == "development") {
                                          window.open(
                                            `https://admin-dev.salvex.com/admin/_user/_edit.cfm?user_id=${item.userId}`,
                                          );
                                        } else {
                                          window.open(
                                            `https://admin.salvex.com/admin/_user/_edit.cfm?user_id=${item.userId}`,
                                          );
                                        }
                                      }
                                    }}
                                  >
                                    <span
                                      className="green-btn-text cursor-pointer"
                                      onClick={() => {
                                        if (item.name) {
                                          if (process.env.REACT_APP_MODE == "development") {
                                            window.open(
                                              `https://admin-dev.salvex.com/admin/_user/_edit.cfm?user_id=${item.userId}`,
                                            );
                                          } else {
                                            window.open(
                                              `https://admin.salvex.com/admin/_user/_edit.cfm?user_id=${item.userId}`,
                                            );
                                          }
                                        }
                                      }}
                                    >
                                      {item?.name ? item?.name : "N/A"}
                                    </span>
                                    <br />
                                    <a className="black-color-tex text-wrapper" href={`mailto:${item?.email}`}>
                                      {item?.email}
                                    </a>
                                    <br />
                                    <span className="black-color-text">{item?.company}</span>
                                    <OverlayTrigger
                                      placement="bottom"
                                      overlay={
                                        <Tooltip style={{ position: "fixed" }}>
                                          <div>
                                            <span>
                                              Buyer Success Rate:{" "}
                                              {item?.buyer_stats?.success_rate
                                                ? new Intl.NumberFormat("en-US", {
                                                    style: "decimal",
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                  }).format(item?.buyer_stats?.success_rate) + "%"
                                                : "0.00%"}
                                            </span>
                                          </div>
                                          <div>
                                            <span>
                                              Active Bids:{" "}
                                              {item?.buyer_stats?.active_bid ? item?.buyer_stats?.active_bid : "0"}
                                            </span>
                                          </div>
                                          <div>
                                            <span>
                                              Total Bids:{" "}
                                              {item?.buyer_stats?.total_bid ? item?.buyer_stats?.total_bid : "0"}
                                            </span>
                                          </div>
                                          <div>
                                            <span>
                                              # of Sales: {item?.buyer_stats?.sales ? item?.buyer_stats?.sales : "0"}
                                            </span>
                                          </div>
                                          <div>
                                            <span>
                                              Profit:{" "}
                                              {item?.buyer_stats?.profit
                                                ? "$" +
                                                  new Intl.NumberFormat("en-US", {
                                                    style: "decimal",
                                                    minimumFractionDigits: 2,
                                                  }).format(item?.buyer_stats?.profit)
                                                : "$0.00"}
                                            </span>
                                          </div>
                                        </Tooltip>
                                      }
                                    >
                                      <div class="d-text-list d-flex align-items-center justify-center cursor-pointer">
                                        <div class="d-text-item fw-medium green-text-label">
                                          <span
                                            className="text-nowrap text-success"
                                            style={{ color: "#47AD1D !important" }}
                                          >
                                            {item?.buyer_stats?.ratings?.all && item?.buyer_stats?.ratings?.good
                                              ? Math.round(
                                                  (item?.buyer_stats?.ratings?.good / item?.buyer_stats?.ratings?.all) *
                                                    100,
                                                )
                                              : "0"}
                                            % (
                                            {item?.buyer_stats?.ratings?.good ? item?.buyer_stats?.ratings?.good : "0"})
                                          </span>
                                        </div>
                                        <div class="d-text-item fw-medium green-text-label">
                                          <span className="text-nowrap text-secondary">
                                            {item?.buyer_stats?.ratings?.all && item?.buyer_stats?.ratings?.neutral
                                              ? Math.round(
                                                  (item?.buyer_stats?.ratings?.neutral /
                                                    item?.buyer_stats?.ratings?.all) *
                                                    100,
                                                )
                                              : "0"}
                                            % (
                                            {item?.buyer_stats?.ratings?.neutral
                                              ? item?.buyer_stats?.ratings?.neutral
                                              : "0"}
                                            )
                                          </span>
                                        </div>
                                        <div class="d-text-item fw-medium green-text-label">
                                          <span className="text-nowrap text-danger">
                                            {item?.buyer_stats?.ratings?.all && item?.buyer_stats?.ratings?.bad
                                              ? Math.round(
                                                  (item?.buyer_stats?.ratings?.bad / item?.buyer_stats?.ratings?.all) *
                                                    100,
                                                )
                                              : "0"}
                                            % ({item?.buyer_stats?.ratings?.bad ? item?.buyer_stats?.ratings?.bad : "0"}
                                            )
                                          </span>
                                        </div>
                                      </div>
                                    </OverlayTrigger>
                                  </td>
                                  <td style={{ width: "10%" }}>
                                    {" "}
                                    {item?.note ? (
                                      <div>
                                        <span className="cursor-pointer" data-tip={item?.note}>
                                          {item?.note
                                            ? item?.note?.length > 20
                                              ? item?.note?.substring(0, 20) + "..."
                                              : item?.note
                                            : "N/A"}
                                        </span>
                                        <ReactTooltip
                                          effect="solid"
                                          place="left"
                                          type="dark"
                                          // offset={{ top: 10 }}
                                          border={true}
                                        />
                                      </div>
                                    ) : (
                                      "N/A"
                                    )}{" "}
                                  </td>
                                  <td style={{ width: "6%" }}>
                                    {" "}
                                    {item?.createdAt ? moment(item?.createdAt).format("MMM-DD-YYYY") : "N/A"}
                                  </td>
                                </tr>
                              );
                            })}
                      </tbody>
                    </table>
                  </div>
                  {!props.loading && props.orderRequestData && props.orderRequestData.length === 0 && (
                    <>
                      <div class="table-empty">
                        <div class="table-empty-inner">
                          <img src={emptyFolder} alt="" />
                          <p>No Items Found</p>
                        </div>
                      </div>
                    </>
                  )}
                  <div class="table-pagination d-flex justify-content-between sticky-pagination">
                    <div class="showing-result-text d-flex align-items-center">
                      {!props.loading && props.totalCount < 50 ? (
                        `Showing ${props.totalCount} of ${props.totalCount} total results`
                      ) : !props.loading && props.page == props.totalPages ? (
                        `Showing ${props.totalCount} of ${props.totalCount} total results`
                      ) : !props.loading && props?.page && props?.filteredCount ? (
                        `Showing ${50 * (props.page - 1) + props.filteredCount} of ${props.totalCount} total results`
                      ) : (
                        <Skeleton width="300px" height="25px" />
                      )}
                    </div>
                    <ul class="pagination">
                      <li class="page-item">
                        <a
                          class={`page-link ${props.page === 1 ? "disabled" : ""}`}
                          href="#"
                          aria-label="Previous"
                          onClick={() => {
                            if (props.page !== 1) {
                              props.setPage(props.page - 1);
                            }
                          }}
                        >
                          <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 13L1 7L7 1" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </a>
                      </li>
                      {props?.page + 1 > 6 && (
                        <li class={props?.page == 1 ? "page-item active" : "page-item"}>
                          <a class="page-link" href="javascript:void(0)" onClick={() => props.setPage(1)}>
                            1
                          </a>
                        </li>
                      )}
                      {props?.page + 1 > 7 && (
                        <li class={props?.page == 2 ? "page-item active" : "page-item"}>
                          <a class="page-link" href="javascript:void(0)" onClick={() => props.setPage(2)}>
                            2
                          </a>
                        </li>
                      )}
                      {props?.page + 1 > 8 && (
                        <li class={props?.page == 3 ? "page-item active" : "page-item"}>
                          <a class="page-link" href="javascript:void(0)" onClick={() => props.setPage(3)}>
                            3
                          </a>
                        </li>
                      )}
                      {props?.page > 5 ? <li className={"page-item"}>...</li> : ""}
                      {pages?.map(
                        (i) =>
                          i + 1 <= props?.totalPages && (
                            <li class={props?.page == i + 1 ? "page-item active" : "page-item"}>
                              <a class="page-link" href="javascript:void(0)" onClick={() => props.setPage(i + 1)}>
                                {i + 1}
                              </a>
                            </li>
                          ),
                      )}
                      {props?.page < props?.totalPages - 5 && props?.totalPages > 10 ? (
                        <li className={"page-item"}>...</li>
                      ) : (
                        ""
                      )}
                      {props?.page < props?.totalPages - 7 && props?.totalPages > 10 && (
                        <li class={props?.page == props?.totalPages - 2 ? "page-item active" : "page-item"}>
                          <a
                            class="page-link"
                            href="javascript:void(0)"
                            onClick={() => props.setPage(props?.totalPages - 2)}
                          >
                            {props?.totalPages - 2}
                          </a>
                        </li>
                      )}
                      {props?.page < props?.totalPages - 6 && props?.totalPages > 10 && (
                        <li class={props?.page == props?.totalPages - 1 ? "page-item active" : "page-item"}>
                          <a
                            class="page-link"
                            href="javascript:void(0)"
                            onClick={() => props.setPage(props?.totalPages - 1)}
                          >
                            {props?.totalPages - 1}
                          </a>
                        </li>
                      )}
                      {props?.page + 5 < props?.totalPages && props?.totalPages > 10 && (
                        <li class={props?.page == props?.totalPages ? "page-item active" : "page-item"}>
                          <a
                            class="page-link"
                            href="javascript:void(0)"
                            onClick={() => props.setPage(props?.totalPages)}
                          >
                            {props?.totalPages}
                          </a>
                        </li>
                      )}
                      <li class="page-item">
                        <a
                          class={`page-link ${props.page >= props.totalPages ? "disabled" : ""}`}
                          href="#"
                          aria-label="Next"
                          onClick={() => props.setPage(props.page + 1)}
                        >
                          <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 13L7 7L1 1" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <OrderRequestFilter
                    show={props.showFilter}
                    onHide={props.toggleFilter}
                    filteredValues={props.filteredValues}
                    setFilteredValues={props.setFilteredValues}
                    handleFilter={props.handleFilter}
                    loadingFilter={props.loading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(OrderRequestTable);
