import React from "react";
import { Modal } from "react-bootstrap";

const DeclineModal = (props) => {
  return (
    <Modal
      className=" fade custom-modal-popup"
      show={props.show}
      onHide={props.onHide}
      enforceFocus={true}
      backdropClassName={"delete-backdrop"}
      //   backdrop={uploadImageModal ? false : true}
      centered
    >
      <Modal.Body>
        <div class="modal-alert-box">
          <h4>Are you sure you want to decline this bid?</h4>
          <textarea
            class="form-control w-100"
            id="FormControlTextareaL"
            value={props?.declineData?.new_message}
            onChange={(e) => {
              const data = { ...props.declineData };
              data.new_message = e.target.value;
              props.setDeclineData(data);
            }}
          />
        </div>
        <div class="modal-action-btn">
          <a
            href="javascript:void(0)"
            class={`btn btn-default btn-lg  ${props.loading && "btn-disabled"}`}
            onClick={!props.loading && props.submitHandler}
          >
            Ok
          </a>
          <a
            href="javascript:void(0)"
            style={{ background: "#fff" }}
            class="btn btn-cancel global-cancel-button"
            onClick={props.onHide}
          >
            Cancel
          </a>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeclineModal;
