import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);
const ReportChart = (props) => {
  let allData = { ...props?.data };
  if (allData.hasOwnProperty("undefined")) {
    Object.defineProperty(
      allData,
      "N/A",
      Object.getOwnPropertyDescriptor(allData, "undefined")
    );
    delete allData["undefined"];
  }

  if (props.section === "Month") {
    let convertedData = {};

    for (const key in allData) {
      if (allData.hasOwnProperty(key)) {
        const [month, year] = key.split("-");
        const monthName = new Date(`${year}-${month}-01`).toLocaleString(
          "en-US",
          { month: "long" }
        );
        convertedData[`${monthName} ${year}`] = allData[key];
      }
    }
    allData = convertedData;
  }
  const bgColor = [
    "#1f77b4",
    "#ff7f0e",
    "#2ca02c",
    "#d62728",
    "#9467bd",
    "#8c564b",
    "#e377c2",
    "#7f7f7f",
    "#bcbd22",
    "#17becf",
  ];

  const keys = allData ? Object.keys(allData) : [];
  const values = allData ? Object.values(allData) : [];

  const data = {
    labels: keys,
    datasets: [
      {
        data: values,
        backgroundColor: bgColor,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  return (
    <div>
      <Pie data={data} options={options} />
    </div>
  );
};

export default ReportChart;
