import React, { useEffect, useState } from "react";
import greenEmail from "../../../include/images/green-mail.svg";
import notVerified from "../../../include/images/mail-not-verified.svg";
import invalid from "../../../include/images/mail-invalid.svg";
import spamEmail from "../../../include/images/mail-spammed-blocked.svg";
import purpleEmail from "../../../include/images/mail-purple.svg";
import DateFormat from "../../../common/DateFormat/dateFormat";
import trash from "../../../include/images/trash18x18.svg";
import extLink from "../../../include/images/external-link.svg";
import Select from "react-select";
import NextButton from "../../../common/form/nextButton";
import { toast } from "react-toastify";
import AlertError from "../../../common/alerts/alertError";
import AlertSuccess from "../../../common/alerts/alertSuccess";

const SellerAssignments = (props) => {
  const customStyles1 = {
    indicatorSeparator: (styles) => ({ display: "none" }),
    option: (provided, state) => ({
      ...provided,
      "&:hover": {
        backgroundColor: "#47ad1d",
        color: "#fff",
      },
      display: "-webkit-box",
      WebkitLineClamp: 3,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      color: state.isSelected ? "#fff" : "black",
      backgroundColor: state.isSelected ? "#47ad1d" : provided.backgroundColor,
      // maxHeight: "84px",
    }),
    control: (base, state) => ({
      ...base,
      // height: 60,
      minHeight: 42,
      borderColor: state.isFocused ? "#47AD1D" : "#e4e4e4",
      boxShadow: state.isFocused ? "0 0 0 0.5px #47AD1D" : "0",
      border: state.isHovered ? "0" : "1px solid #e4e4e4",

      "&:hover": {
        borderColor: state.isFocused ? "#47AD1D" : "0",
        boxShadow: state.isFocused ? "0 0 0 0.5px #47AD1D" : "0",
      },
    }),
    multiValueRemove: (base, state) => ({
      ...base,
      "&:hover": {
        backgroundColor: "rgb(95, 50, 187, 10%)",
        color: "#6119c0",
      },
    }),
  };
  const [recipients, setRecipients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [recipientValue, setRecipientValue] = useState(null);
  const [additionalData, setAdditionalData] = useState({
    user_id: "",
    to_id: [],
    message: "",
    send_email: false,
    cc_emails_list: [],
  });
  const detailData = props.data;
  useEffect(() => {
    if (detailData.data && detailData.data.length) {
      props.getRecipient((res) => {
        if (res.status === 200) {
          setRecipients(res.data.data);
        }
      });
    }
  }, []);

  //Post Message
  const postMessage = (userId) => {
    props.refStart();
    setLoading(true);
    props.addMessage(additionalData.user_id, additionalData, (res) => {
      if (res.status === 200) {
        props.getSellerAssignment(props.empId, 1, (res) => {
          if (res.status === 200) {
            setRecipientValue(null);
            setAdditionalData({
              user_id: "",
              to_id: [],
              message: "",
              send_email: false,
              cc_emails_list: [],
            });
            props.refStop();
            setLoading(false);
          }
        });
      } else {
        props.refStop();
        setLoading(false);
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  //Delete Message
  const deleteMessage = (chatId) => {
    props.refStart();
    props.deleteMessage(chatId, (res) => {
      if (res.status === 200) {
        props.getSellerAssignment(props.empId, 1, (res) => {});
        props.refStop();
        toast(<AlertSuccess message={"Information Saved"} />);
      } else {
        props.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  //Handle Unsubscribe
  const handleUnsubscribe = (name, id, userId, checked) => {
    let payload;
    if (name === "company_id") {
      payload = {
        [name]: id,
        unsubscribe: checked ? 1 : 0,
      };
    } else if (name === "project_id") {
      payload = {
        [name]: id,
        unsubscribe: checked ? 1 : 0,
      };
    } else if (name === "user_id") {
      payload = {
        [name]: id,
        unsubscribe: checked ? 1 : 0,
      };
    }
    props.changeUnsubscribe(userId, payload, (res) => {
      if (res.status === 200) {
      } else {
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  return (
    <div class="details-page-widget-block">
      <div class="page-widget-head d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <div class="page-widget-label text-capitalize cursor-pointer">{props.title}</div>
        </div>
      </div>
      <div class="page-widget-body mt-3">
        <div class="table-responsive position-relative">
          <table class="table table-bordered custom-table mb-0">
            <colgroup></colgroup>
            <thead>
              <tr>
                <th style={{ minWidth: "250px", maxWidth: "350px" }}>Customer</th>
                <th style={{ minWidth: "250px", maxWidth: "350px" }}>Last 5 Bids</th>
                <th style={{ minWidth: "250px", maxWidth: "350px" }}>Last 5 Views</th>
                <th style={{ minWidth: "250px", maxWidth: "350px" }}>Last 5 Questions</th>
                <th style={{ minWidth: "250px", maxWidth: "350px" }}>Stats</th>
                <th style={{ minWidth: "400px" }}>Contacts</th>
                <th style={{ minWidth: "350px", maxWidth: "450px" }}>Notes</th>
              </tr>
            </thead>
            <tbody>
              {detailData?.data?.length > 0 &&
                detailData?.data?.map((val) => (
                  <tr>
                    <td>
                      <div>
                        <div className="mt-2">
                          {val?.customer?.customer_name && (
                            <a href="javascript:void(0)" className="green-btn-text">
                              <img src={extLink} alt="" />
                              {val?.customer?.customer_name}
                            </a>
                          )}
                        </div>
                        <div className="mt-2">
                          {val?.customer?.customer_company && <span>{val?.customer?.customer_company}</span>}
                        </div>
                        <div className="mt-2">
                          <span>
                            {val?.customer?.customer_city}
                            {val?.customer?.customer_state && ", "}
                            {val?.customer?.customer_state}
                          </span>
                        </div>
                        <div className="mt-2">
                          <span>{val?.customer?.customer_country}</span>
                        </div>
                        <div className="mt-2">
                          <span>W: {val?.customer?.customer_phone}</span>
                        </div>
                        <div>
                          <a href={`mailto:${val?.customer?.customer_email}`} className="green-btn-text">
                            {val?.customer?.customer_email}
                          </a>
                          <span class="d-text-item">
                            {val?.customer?.emailstatuscode === 0 ? (
                              <img src={greenEmail} alt="" title="Email OK" />
                            ) : val?.customer?.emailstatuscode === 1 ? (
                              <img
                                src={purpleEmail}
                                alt=""
                                title="Email Temporarily Suspended/Unreachable or Undetermined (Soft Bounce)"
                              />
                            ) : val?.customer?.emailstatuscode === 2 ? (
                              <img src={invalid} alt="" title="Email Invalid" />
                            ) : val?.customer?.emailstatuscode === 3 ? (
                              <img src={spamEmail} alt="" title="Email Blocked by Spam or Firewall" />
                            ) : (
                              <img src={notVerified} alt="" title="Email Not Verified" />
                            )}
                          </span>
                        </div>
                      </div>

                      <div class="td-button-group mt-2">
                        <img
                          src={`https://us-central1-salvex-mail.cloudfunctions.net/requestRecipientStats?email=${val?.customer?.customer_email}`}
                          alt=""
                        />
                      </div>

                      <div className="mt-3 mb-4">
                        <div>
                          <span>Create On: </span>
                          <span className="fw-medium">
                            <DateFormat date={val?.customer?.customer_date_created} />
                          </span>
                        </div>
                        <div className="mt-2">
                          <span>Created By: </span>
                          <span className="fw-medium">{val?.customer?.customer_created_by}</span>
                        </div>
                        <div class="page-widget-label text-capitalize mb-2">Unsubscribe</div>
                        {val?.customer?.proc_company_id > 0 && (
                          <div className="d-flex">
                            <div className="form-check tick-checkbox mb-3">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                name="company_id"
                                value={val?.customer?.proc_company_id}
                                defaultChecked={val?.customer?.proc_unsubscribe > 0}
                                id={val?.customer?.proc_company_id}
                                onChange={(e) => {
                                  handleUnsubscribe(e.target.name, e.target.value, val.user_id, e.target.checked);
                                }}
                              />
                              <label class="form-check-label fw-medium" for={val?.customer?.proc_company_id}></label>
                            </div>
                            <span>Enterprise</span>
                          </div>
                        )}

                        {val?.customer?.bu_project_id > 0 && (
                          <div className="d-flex">
                            <div className="form-check tick-checkbox mb-3">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                name="project_id"
                                value={val?.customer?.bu_project_id}
                                defaultChecked={val?.customer?.bu_unsubscribe > 0}
                                id={val?.customer?.bu_project_id}
                                onChange={(e) => {
                                  handleUnsubscribe(e.target.name, e.target.value, val.user_id, e.target.checked);
                                }}
                              />
                              <label class="form-check-label fw-medium" for={val?.customer?.bu_project_id}></label>
                            </div>
                            <span>Business Unit</span>
                          </div>
                        )}

                        <div className="d-flex">
                          <div className="form-check tick-checkbox mb-3">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              name="user_id"
                              value={val?.customer?.user_id}
                              defaultChecked={val?.customer?.unsubscribe > 0}
                              id={val?.customer?.user_id}
                              onChange={(e) => {
                                handleUnsubscribe(e.target.name, e.target.value, val.user_id, e.target.checked);
                              }}
                            />
                            <label class="form-check-label fw-medium" for={val?.customer?.user_id}></label>
                          </div>
                          <span>Individual</span>
                        </div>
                      </div>
                      <div class="page-widget-label text-capitalize">BD Followup</div>
                      <div className="mt-3">
                        {val?.customer?.bd_followup_date ? (
                          <DateFormat date={val?.customer?.bd_followup_date} />
                        ) : (
                          <span>None Specified</span>
                        )}
                      </div>
                    </td>
                    <td>
                      <ul>
                        {val?.customer?.active_bids &&
                          val?.customer?.active_bids?.length > 0 &&
                          val?.customer?.active_bids?.map((data) => (
                            <li className="mt-2">
                              <a href="javascript:void(0)" className="green-btn-text">
                                {data.title}
                              </a>
                              <div>
                                <span className="fw-medium">
                                  {data.total_bid
                                    ? "$" +
                                      new Intl.NumberFormat("en-US", {
                                        style: "decimal",
                                        minimumFractionDigits: 2,
                                      }).format(data.total_bid)
                                    : "$0.00"}
                                  -
                                </span>
                                <DateFormat data={data.bid_time} />
                              </div>
                            </li>
                          ))}
                      </ul>
                    </td>
                    <td>
                      <ul>
                        {val?.customer?.latest_views &&
                          val?.customer?.latest_views?.length > 0 &&
                          val?.customer?.latest_views?.map((data) => (
                            <li className="mt-2">
                              <a href="javascript:void(0)" className="green-btn-text">
                                {data.title}
                              </a>
                              <div>
                                <DateFormat data={data.last_view} />
                              </div>
                            </li>
                          ))}
                      </ul>
                    </td>
                    <td>
                      <ul>
                        {val?.customer?.latest_questions &&
                          val?.customer?.latest_questions?.length > 0 &&
                          val?.customer?.latest_questions?.map((data) => (
                            <li className="mt-2">
                              <a href="javascript:void(0)" className="green-btn-text">
                                {data.title}
                              </a>
                              <div>
                                <DateFormat data={data.last_view} />
                              </div>
                            </li>
                          ))}
                      </ul>
                    </td>
                    <td>
                      <div>
                        <div class="page-widget-label text-capitalize mb-2">BUYING STATS</div>
                        <div>
                          <span>Total Bids: </span> <span className="fw-medium">{val?.customer?.total_bids}</span>
                        </div>
                        <div>
                          <span>Profit: </span>{" "}
                          <span className="fw-medium">
                            {val?.customer?.profitBuying
                              ? "$" +
                                new Intl.NumberFormat("en-US", {
                                  style: "decimal",
                                  minimumFractionDigits: 2,
                                }).format(val?.customer?.profitBuying) +
                                "."
                              : "$0.00"}
                          </span>
                        </div>
                        {Object.keys(val?.customer?.lastBid).length > 0 && (
                          <div>
                            <span>Last Bid: </span>{" "}
                            <a href="javascript:void(0)" className="green-btn-text">
                              {val?.customer?.lastBid?.title}
                            </a>
                            {val?.customer?.lastBid?.date && (
                              <>
                                "on"
                                <span className="fw-medium">
                                  <DateFormat data={val?.customer?.lastBid?.date} />
                                </span>
                              </>
                            )}
                          </div>
                        )}
                        <div>
                          <span>Feedback:</span>
                          <span className="fw-medium text-green">{val?.customer?.buyerFeedback?.good}%</span>
                          <span className="fw-medium">{val?.customer?.buyerFeedback?.neutral}%</span>
                          <span className="fw-medium text-danger">{val?.customer?.buyerFeedback?.bad}%</span>
                        </div>
                      </div>

                      <div>
                        <div class="page-widget-label text-capitalize mt-4 mb-2">SELLING STATS</div>
                        <div>
                          <span>Total Profit:</span>
                          <span className="fw-medium">
                            {val?.customer?.profitSelling
                              ? "$" +
                                new Intl.NumberFormat("en-US", {
                                  style: "decimal",
                                  minimumFractionDigits: 2,
                                }).format(val?.customer?.profitSelling) +
                                "."
                              : "$0.00"}
                          </span>
                        </div>
                        <div>
                          <span>Average Profit:</span>
                          <span className="fw-medium">
                            {val?.customer?.profitSelling / val?.customer?.sellingCounts?.sold
                              ? "$" +
                                new Intl.NumberFormat("en-US", {
                                  style: "decimal",
                                  minimumFractionDigits: 2,
                                }).format(val?.customer?.profitSelling / val?.customer?.sellingCounts?.sold) +
                                "."
                              : "$0.00"}
                          </span>
                        </div>
                        <div>
                          <span>Success:</span>
                          <span className="fw-medium">{val?.customer?.sellingCounts?.sold}</span>sold /{" "}
                          <span className="fw-medium">{val?.customer?.sellingCounts?.all}</span>total ={" "}
                          <span className="fw-medium">
                            {val?.customer?.sellingCounts?.sold / val?.customer?.sellingCounts?.all
                              ? (val?.customer?.sellingCounts?.sold / val?.customer?.sellingCounts?.all) * 100 + "%"
                              : "0%"}
                          </span>
                        </div>
                        <div>
                          <span>Pending Listings:</span>
                          <span className="fw-medium">{val?.customer?.sellingCounts?.pending}</span>
                        </div>
                        <div>
                          <span>Closed Listings:</span>
                          <span className="fw-medium">{val?.customer?.sellingCounts?.closed}</span>
                        </div>
                        <div>
                          <span>Invoiced Listings:</span>
                          <span className="fw-medium">{val?.customer?.sellingCounts?.invoiced}</span>
                        </div>
                        <div>
                          <span>Archived Listings:</span>
                          <span className="fw-medium">{val?.customer?.sellingCounts?.archived}</span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <ul>
                        {val?.customer?.lastContact &&
                          val?.customer?.lastContact?.length > 0 &&
                          val?.customer?.lastContact?.map((data) => (
                            <li className="mb-3">
                              <div>
                                <span className="fw-medium">
                                  <DateFormat date={data.date_contacted} />:
                                </span>
                              </div>
                              <div>
                                contacted by {data.contacted_by} -{" "}
                                <div dangerouslySetInnerHTML={{ __html: data.notes }} />
                              </div>
                            </li>
                          ))}
                      </ul>
                    </td>
                    <td>
                      <div class="page-widget-body mt-3">
                        <div class="widget-form-widget">
                          <div class="wfw-label-row w-100 d-flex flex-wrap justify-content-between">
                            <div class="wfw-label w-50 pr-3">
                              <div class="wfw-txt-bold fw-medium">Additional Recipients</div>
                            </div>
                          </div>
                          <div class="form-group-15">
                            {/* <Select
                              className="basic-single"
                              isMulti
                              classNamePrefix="select-search "
                              value={recipientValue}
                              options={recipients}
                              placeholder="Please Select Options"
                              getOptionLabel={(option) => option.name01}
                              getOptionValue={(option) => option.member_id}
                              onChange={(e) => {
                                setRecipientValue(e);
                                const data = { ...additionalData };
                                data.to_id = e?.map((val) => val.member_id);
                                data.user_id = val.user_id;
                                setAdditionalData(data);
                              }}
                              styles={customStyles1}
                            /> */}
                            <select
                              name="admin_comments"
                              id="admin_comments"
                              multiple
                              style={{ height: "116px", width: "100%" }}
                              onChange={(e) => {
                                setRecipientValue(e);
                                const data = { ...additionalData };
                                data.to_id = e?.map((val) => val.member_id);
                                data.user_id = val.user_id;
                                setAdditionalData(data);
                              }}
                            >
                              {recipients?.map((val) => (
                                <option value={val?.member_id}>{val?.name01}</option>
                              ))}
                            </select>
                          </div>
                          <div class="form-group-30">
                            <input
                              type="text"
                              placeholder="cc"
                              value={additionalData.cc_emails_list}
                              onChange={(e) => {
                                const data = { ...additionalData };
                                data.cc_emails_list = e.target.value.split(" ");
                                data.user_id = val.user_id;
                                setAdditionalData(data);
                              }}
                              class="form-control white"
                            />
                          </div>
                          <div class="form-group-15">
                            <label for="" class="form-label fw-medium">
                              Message
                            </label>
                            <textarea
                              class="form-control white"
                              value={additionalData.message}
                              onChange={(e) => {
                                const data = { ...additionalData };
                                data.message = e.target.value;
                                data.user_id = val.user_id;
                                setAdditionalData(data);
                              }}
                              id="textarea130x130"
                            ></textarea>
                          </div>
                          <div class="form-checkbox-group-row d-flex flex-wrap">
                            <div class="form-checkbox-item">
                              <div class="form-check dark-tick-checkbox">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  checked={additionalData.send_email}
                                  value={additionalData.send_email}
                                  onChange={(e) => {
                                    const data = { ...additionalData };
                                    data.send_email = e.target.checked;
                                    data.user_id = val?.user_id;
                                    setAdditionalData(data);
                                  }}
                                  id="sendMail2"
                                />
                                <label class="form-check-label fw-medium text-nowrap" for="sendMail2">
                                  Send Email?
                                </label>
                              </div>
                            </div>
                          </div>
                          <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                            <NextButton
                              label="Post Message"
                              classData={"btn btn-default"}
                              handleSubmit={() => postMessage(val.user_id)}
                              loading={loading}
                            />
                          </div>
                        </div>
                        <div class="text-card-block-row mt-4">
                          {val?.customer?.chat && val?.customer?.chat?.length > 0 ? (
                            val?.customer?.chat?.map((data) => (
                              <div class="text-card-block-column">
                                <div class="tcbc-header d-flex flex-wrap align-items-center justify-content-between">
                                  <div class="tcbc-header-label align-items-center">
                                    <div class="fw-medium">{data.name01}</div>
                                    <DateFormat date={data.created} />
                                  </div>
                                  <div class="tcbc-right-box">
                                    <button
                                      type="button"
                                      onClick={() => deleteMessage(data.chat_id)}
                                      class="delete-button d-flex align-items-center"
                                    >
                                      <img src={trash} alt="" /> Delete
                                    </button>
                                  </div>
                                </div>
                                <div dangerouslySetInnerHTML={{ __html: data.message }} />
                              </div>
                            ))
                          ) : (
                            <div className="d-flex justify-content-center align-items-center">
                              <span className="fw-medium text-muted">No Messages.</span>
                            </div>
                          )}
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SellerAssignments;
