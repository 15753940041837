import React, { useRef } from "react";
import close from "../../../../include/images/close-12x12.svg";
import { Offcanvas } from "react-bootstrap";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { addUser, getUser } from "../../../../store/users";
import { connect } from "react-redux";
import LoadingBar from "react-top-loading-bar";
import _ from "lodash";
import { getUserList } from "../../../../store/userList";
import { customStyles } from "../../../../common/customStyles/reactSelectStyle";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const AddUserModal = (props) => {
  const ref = useRef(null);

  const onChange = (e) => {
    const value = { ...props.data };
    value[e.target.name] = e.target.value;
    props.setData(value);
  };
  const errors = { ...props.errors };

  return (
    <>
      <LoadingBar height={5} color="#47AD1D" ref={ref} />
      <Offcanvas
        placement="end"
        backdropClassName="upload-documnets-backdrop"
        show={props.show}
        onHide={props.onHide}
        className="offcanvas offcanvas-end border-0 width-950"
        id="verifyDocsOffcanvas"
        enforceFocus={false}
      >
        <Offcanvas.Header className="offcanvas-header">
          <h5 class="offcanvas-title">
            {props.selectedItem ? "Edit Contract" : "Add Contract"}
          </h5>
          <button
            type="button"
            class="offcanvas-close-btn d-flex align-items-center justify-content-center"
            data-bs-dismiss="offcanvas"
            onClick={props.onHide}
          >
            <img src={close} alt="" />
          </button>
        </Offcanvas.Header>
        <Offcanvas.Body className="offcanvas-body p-0">
          <div class="offcanvas-widget-row">
            <div class="table-responsive hide-scrollbar mb-3">
              <div className="d-flex add-user-flex">
                <div className="mb-4 add-user-input-width">
                  <label class="form-label" for="contractScope">
                    Contract Scope<sup style={{ color: "Red" }}>*</sup>
                  </label>
                  <Select
                    value={props.data.contractScope}
                    options={props.contractScopeOption}
                    className="basic-single country-margin select-background"
                    classNamePrefix="select-search country-margin select-background"
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.value}
                    onChange={(e) => {
                      const values = { ...props.data };
                      values.contractScope = e;
                      props.setData(values);
                    }}
                    name="contractScope"
                    placeholder="Select Contract Scope"
                    styles={customStyles}
                  />{" "}
                  {props.errors && props.errors?.contractScope ? (
                    <p className="error-text">{errors.contractScope}</p>
                  ) : (
                    ""
                  )}
                </div>
                <div className="mb-4 add-user-input-width">
                  <label class="form-label" for="contractType">
                    Contract Type<sup style={{ color: "Red" }}>*</sup>
                  </label>
                  <Select
                    value={props.data.contractType}
                    options={props.contractTypeOptions}
                    className="basic-single country-margin contract-type-dropdown  select-background"
                    classNamePrefix="select-search country-margin select-background"
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.value}
                    onChange={(e) => {
                      const values = { ...props.data };
                      values.contractType = e;
                      props.setData(values);
                    }}
                    name="contractType"
                    placeholder="Select Contract Type"
                    styles={customStyles}
                  />
                  {props.errors && props.errors?.contractType ? (
                    <p className="error-text">{errors.contractType}</p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="d-flex add-user-flex">
                <div className="mb-4 add-user-input-width">
                  <label class="form-label" for="contractScope">
                    Enterprise<sup style={{ color: "Red" }}>*</sup>
                  </label>
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    value={props.data?.enterpriseId}
                    className="basic-single country-margin select-background"
                    classNamePrefix="select-search country-margin select-background"
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    loadOptions={props.loadEnterpriseOptions}
                    name="enterprise"
                    placeholder="Select Enterprise"
                    isDisabled={props.selectedItem ? true : false}
                    styles={customStyles}
                    onChange={(e) => {
                      const d = { ...props.data };
                      d.enterpriseId = e;
                      props.setData(d);
                    }}
                  />
                  {props.errors && props.errors?.enterpriseId ? (
                    <p className="error-text">{errors.enterpriseId}</p>
                  ) : (
                    ""
                  )}
                </div>
                <div className="mb-4 add-user-input-width">
                  <label class="form-label" for="contractType">
                    Business Unit
                  </label>
                  <AsyncSelect
                    cacheOptions
                    defaultOptions={props.buOptions}
                    value={props.data?.buId}
                    className="basic-single country-margin contract-type-dropdown select-background"
                    classNamePrefix="select-search country-margin select-background"
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    loadOptions={props.loadBuOptions}
                    name="businessUnits"
                    placeholder="Select Business Unit"
                    isDisabled={
                      props.data?.enterpriseId?.id && !props.selectedItem
                        ? false
                        : true
                    }
                    styles={customStyles}
                    onChange={(e) => {
                      const d = { ...props.data };
                      d.buId = e;
                      props.setData(d);
                    }}
                  />
                </div>
              </div>
              <div className="d-flex add-user-flex">
                <div className="mb-4 add-user-input-width">
                  <label class="form-label" for="industry">
                    Contract Status<sup style={{ color: "Red" }}>*</sup>
                  </label>
                  <Select
                    options={props.contractStatusOptions}
                    value={props.data.contractStatus}
                    className="basic-single country-margin select-background"
                    classNamePrefix="select-search country-margin select-background"
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.value}
                    onChange={(e) => {
                      const values = { ...props.data };
                      values.contractStatus = e;
                      props.setData(values);
                    }}
                    name="contractStatus"
                    placeholder="Select Contract Status"
                    styles={customStyles}
                  />
                  {props.errors && props.errors?.contractStatus ? (
                    <p className="error-text">{errors.contractStatus}</p>
                  ) : (
                    ""
                  )}
                </div>
                <div className="mb-4 add-user-input-width add-contract-calendar">
                  <label class="form-label" for="industry">
                    Start Date<sup style={{ color: "Red" }}>*</sup>
                  </label>
                  <DatePicker
                    className="form-control"
                    selected={props.data?.startDate}
                    onChange={(date) => {
                      const data = { ...props.data };
                      data.startDate = Date.parse(date);
                      props.setData(data);
                    }}
                  />
                  {/* <span>
                    {props.data?.startDate ? (
                      <DateFormat date={props.data?.startDate} />
                    ) : (
                      ""
                    )}
                  </span> */}
                  {props.errors && props.errors?.startDate ? (
                    <p className="error-text">{errors.startDate}</p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="d-flex add-user-flex">
                <div className="mb-4 add-user-input-width add-contract-calendar">
                  <label class="form-label" for="industry">
                    Expiration Date<sup style={{ color: "Red" }}>*</sup>
                  </label>
                  <DatePicker
                    className="form-control "
                    selected={props.data?.expirationDate}
                    onChange={(date) => {
                      const data = { ...props.data };
                      data.expirationDate = Date.parse(date);
                      props.setData(data);
                    }}
                  />
                  {/* <span>
                    {props.data?.expirationDate ? (
                      <DateFormat date={props.data?.expirationDate} />
                    ) : (
                      ""
                    )}
                  </span> */}
                  {props.errors && props.errors?.expirationDate ? (
                    <p className="error-text">{errors.expirationDate}</p>
                  ) : (
                    ""
                  )}
                </div>{" "}
                <div style={{ width: "47%" }}>
                  <label class="fw-normal mb-14">
                    Automatic Renewal<sup style={{ color: "Red" }}>*</sup>
                  </label>
                  <div class="four-column-radio-group mb-4 gap-user ">
                    <div class="fcrg-item  payment-info-main-option">
                      <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
                        <input
                          class="form-check-input cursor-pointer"
                          type="radio"
                          name="WTflexRadioDefault1213"
                          id="WTflexRadio__12001"
                          checked={props.data?.automaticRenewal == "true"}
                          onChange={(e) => {
                            const value = { ...props.data };
                            value.automaticRenewal = "true";
                            props.setData(value);
                          }}
                        />
                        <label
                          class="form-check-label cursor-pointer"
                          for="WTflexRadio__12001"
                        >
                          Yes
                        </label>
                      </div>
                    </div>
                    <div class="fcrg-item  payment-info-main-option">
                      <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
                        <input
                          class="form-check-input cursor-pointer"
                          type="radio"
                          name="WTflexRadioDefault1213"
                          id="WTflexRadio__21001"
                          checked={props.data?.automaticRenewal == "false"}
                          onChange={(e) => {
                            const value = { ...props.data };
                            value.automaticRenewal = "false";
                            props.setData(value);
                          }}
                        />

                        <label
                          class="form-check-label cursor-pointer"
                          for="WTflexRadio__21001"
                        >
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                  {props.errors && props.errors?.automaticRenewal ? (
                    <p className="error-text contract-drpdown-error">
                      {errors.automaticRenewal}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="d-flex add-user-flex">
                <div className="mb-4 add-user-input-width">
                  <label class="form-label" for="termination">
                    Termination Notice<sup style={{ color: "Red" }}>*</sup>
                  </label>
                  <input
                    type="text"
                    name="terminationNotice"
                    value={props.data?.terminationNotice}
                    onChange={(e) => onChange(e)}
                    className="form-control"
                  />
                  {props.errors && props.errors?.terminationNotice ? (
                    <p className="error-text">{errors.terminationNotice}</p>
                  ) : (
                    ""
                  )}
                </div>{" "}
                <div style={{ width: "47%" }}>
                  <label class="fw-normal mb-14">
                    Exclusivity<sup style={{ color: "Red" }}>*</sup>
                  </label>
                  <div class="four-column-radio-group mb-4 gap-user ">
                    <div class="fcrg-item  payment-info-main-option">
                      <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
                        <input
                          class="form-check-input cursor-pointer"
                          type="radio"
                          name="WTflexRadioDefault110"
                          id="WTflexRadio__12121"
                          checked={props.data?.exclusivity == "true"}
                          onChange={(e) => {
                            const value = { ...props.data };
                            value.exclusivity = "true";
                            props.setData(value);
                          }}
                        />
                        <label
                          class="form-check-label cursor-pointer"
                          for="WTflexRadio__12121"
                        >
                          Yes
                        </label>
                      </div>
                    </div>
                    <div class="fcrg-item  payment-info-main-option">
                      <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
                        <input
                          class="form-check-input cursor-pointer"
                          type="radio"
                          name="WTflexRadioDefault110"
                          id="WTflexRadio__21211"
                          checked={props.data?.exclusivity == "false"}
                          onChange={(e) => {
                            const value = { ...props.data };
                            value.exclusivity = "false";
                            props.setData(value);
                          }}
                        />

                        <label
                          class="form-check-label cursor-pointer"
                          for="WTflexRadio__21211"
                        >
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                  {props.errors && props.errors?.exclusivity ? (
                    <p className="error-text contract-drpdown-error">
                      {errors.exclusivity}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="d-flex add-user-flex">
                <div className="mb-4 add-user-input-width">
                  <label class="form-label" for="termination">
                    Commission Rate<sup style={{ color: "Red" }}>*</sup>
                  </label>
                  <input
                    type="text"
                    name="commissionRate"
                    value={props.data?.commissionRate}
                    onChange={(e) => onChange(e)}
                    className="form-control"
                  />
                  {props.errors && props.errors?.commissionRate ? (
                    <p className="error-text">{errors.commissionRate}</p>
                  ) : (
                    ""
                  )}
                </div>{" "}
                <div style={{ width: "47%" }}>
                  <label class="fw-normal mb-14">
                    Buyer Preium / Seller Fee
                    <sup style={{ color: "Red" }}>*</sup>
                  </label>
                  <div class="four-column-radio-group mb-4 gap-user ">
                    <div class="fcrg-item  payment-info-main-option">
                      <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
                        <input
                          class="form-check-input cursor-pointer"
                          type="radio"
                          name="WTflexRadioDefault111"
                          id="WTflexRadio__13"
                          checked={
                            props.data?.buyerPremiumOrSellerFee == "true"
                          }
                          onChange={(e) => {
                            const value = { ...props.data };
                            value.buyerPremiumOrSellerFee = "true";
                            props.setData(value);
                          }}
                        />
                        <label
                          class="form-check-label cursor-pointer"
                          for="WTflexRadio__13"
                        >
                          Yes
                        </label>
                      </div>
                    </div>
                    <div class="fcrg-item  payment-info-main-option">
                      <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
                        <input
                          class="form-check-input cursor-pointer"
                          type="radio"
                          name="WTflexRadioDefault111"
                          id="WTflexRadio__31"
                          checked={
                            props.data?.buyerPremiumOrSellerFee == "false"
                          }
                          onChange={(e) => {
                            const value = { ...props.data };
                            value.buyerPremiumOrSellerFee = "false";
                            props.setData(value);
                          }}
                        />

                        <label
                          class="form-check-label cursor-pointer"
                          for="WTflexRadio__32"
                        >
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                  {props.errors && props.errors?.buyerPremiumOrSellerFee ? (
                    <p className="error-text contract-drpdown-error">
                      {errors.buyerPremiumOrSellerFee}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="d-flex add-user-flex">
                <div className="mb-4 add-user-input-width contract-type-dropdown">
                  <label class="form-label" for="termination">
                    Salvex Entity<sup style={{ color: "Red" }}>*</sup>
                  </label>
                  <Select
                    options={props.entityOptions}
                    value={props.data.salvexEntity}
                    className="basic-single country-margin contract-type-dropdown select-background"
                    classNamePrefix="select-search country-margin select-background"
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.value}
                    onChange={(e) => {
                      const values = { ...props.data };
                      values.salvexEntity = e;
                      props.setData(values);
                    }}
                    name="entity"
                    placeholder="Select Contract Scope"
                    styles={customStyles}
                  />
                  {props.errors && props.errors?.salvexEntity ? (
                    <p className="error-text">{errors.salvexEntity}</p>
                  ) : (
                    ""
                  )}
                </div>{" "}
                <div style={{ width: "47%" }}>
                  <label class="fw-normal mb-14">
                    Confidentiality<sup style={{ color: "Red" }}>*</sup>
                  </label>
                  <div class="four-column-radio-group mb-4 gap-user ">
                    <div class="fcrg-item  payment-info-main-option">
                      <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
                        <input
                          class="form-check-input cursor-pointer"
                          type="radio"
                          name="WTflexRadioDefault113"
                          id="WTflexRadio__14"
                          checked={props.data?.confidentiality == "true"}
                          onChange={(e) => {
                            const value = { ...props.data };
                            value.confidentiality = "true";
                            props.setData(value);
                          }}
                        />
                        <label
                          class="form-check-label cursor-pointer"
                          for="WTflexRadio__14"
                        >
                          Yes
                        </label>
                      </div>
                    </div>
                    <div class="fcrg-item  payment-info-main-option">
                      <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
                        <input
                          class="form-check-input cursor-pointer"
                          type="radio"
                          name="WTflexRadioDefault113"
                          id="WTflexRadio__41"
                          checked={props.data?.confidentiality == "false"}
                          onChange={(e) => {
                            const value = { ...props.data };
                            value.confidentiality = "false";
                            props.setData(value);
                          }}
                        />

                        <label
                          class="form-check-label cursor-pointer"
                          for="WTflexRadio__42"
                        >
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                  {props.errors && props.errors?.confidentiality ? (
                    <p className="error-text contract-drpdown-error">
                      {errors.confidentiality}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="d-flex add-user-flex">
                <div style={{ width: "47%" }}>
                  <label class="fw-normal mb-14">
                    Non-Circumvention<sup style={{ color: "Red" }}>*</sup>
                  </label>
                  <div class="four-column-radio-group mb-4 gap-user ">
                    <div class="fcrg-item  payment-info-main-option">
                      <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
                        <input
                          class="form-check-input cursor-pointer"
                          type="radio"
                          name="WTflexRadioDefault115"
                          id="WTflexRadio__15"
                          checked={props.data?.nonCircumvention == "true"}
                          onChange={(e) => {
                            const value = { ...props.data };
                            value.nonCircumvention = "true";
                            props.setData(value);
                          }}
                        />
                        <label
                          class="form-check-label cursor-pointer"
                          for="WTflexRadio__15"
                        >
                          Yes
                        </label>
                      </div>
                    </div>
                    <div class="fcrg-item  payment-info-main-option">
                      <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
                        <input
                          class="form-check-input cursor-pointer"
                          type="radio"
                          name="WTflexRadioDefault115"
                          id="WTflexRadio__51"
                          checked={props.data?.nonCircumvention == "false"}
                          onChange={(e) => {
                            const value = { ...props.data };
                            value.nonCircumvention = "false";
                            props.setData(value);
                          }}
                        />

                        <label
                          class="form-check-label cursor-pointer"
                          for="WTflexRadio__52"
                        >
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                  {props.errors && props.errors?.nonCircumvention ? (
                    <p className="error-text contract-drpdown-error">
                      {errors.nonCircumvention}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                <div className="mb-4 add-user-input-width">
                  <label class="form-label" for="mobile">
                    Governing Laws
                  </label>
                  <input
                    type="text"
                    name="governingLaws"
                    value={props.data?.governingLaws}
                    onChange={(e) => {
                      const value = { ...props.data };
                      value.governingLaws = e.target.value;
                      props.setData(value);
                    }}
                    className="form-control"
                  />
                  {props.errors && props.errors?.governingLaws ? (
                    <p className="error-text">{errors.governingLaws}</p>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="d-flex add-user-flex">
                <div className="mb-4 add-user-input-width">
                  <label class="form-label" for="email">
                    Arbitration
                  </label>
                  <input
                    name="arbitration"
                    type="text"
                    value={props.data?.arbitration}
                    onChange={(e) => {
                      const value = { ...props.data };
                      value.arbitration = e.target.value;
                      props.setData(value);
                    }}
                    className="form-control"
                  />
                  {props.errors && props.errors?.arbitration ? (
                    <p className="error-text">{errors.arbitration}</p>
                  ) : (
                    ""
                  )}
                </div>{" "}
                <div className="mb-4 add-user-input-width">
                  <label class="form-label" for="mobile">
                    Indemnification
                  </label>
                  <input
                    type="text"
                    name="indemnification"
                    value={props.data?.indemnification}
                    onChange={(e) => {
                      const value = { ...props.data };
                      value.indemnification = e.target.value;
                      props.setData(value);
                    }}
                    className="form-control"
                  />
                  {props.errors && props.errors?.indemnification ? (
                    <p className="error-text">{errors.indemnification}</p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="d-flex add-user-flex">
                <div className="mb-4 add-user-input-width">
                  <label class="form-label" for="email">
                    Contract Language
                  </label>
                  <input
                    name="contractLanguage"
                    type="text"
                    value={props.data?.contractLanguage}
                    onChange={(e) => {
                      const value = { ...props.data };
                      value.contractLanguage = e.target.value;
                      props.setData(value);
                    }}
                    className="form-control"
                  />
                  {props.errors && props.errors?.contractLanguage ? (
                    <p className="error-text">{errors.contractLanguage}</p>
                  ) : (
                    ""
                  )}
                </div>{" "}
                <div className="mb-4 add-user-input-width">
                  <label class="form-label" for="mobile">
                    Special Terms
                  </label>
                  <input
                    type="text"
                    name="indemnification"
                    value={props.data?.specialTerms}
                    onChange={(e) => {
                      const value = { ...props.data };
                      value.specialTerms = e.target.value;
                      props.setData(value);
                    }}
                    className="form-control"
                  />
                  {props.errors && props.errors?.specialTerms ? (
                    <p className="error-text">{errors.specialTerms}</p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="d-flex add-user-flex">
                <div style={{ width: "47%" }}>
                  <label class="fw-normal mb-14">
                    Registration as a Vendor
                    <sup style={{ color: "Red" }}>*</sup>
                  </label>
                  <div class="four-column-radio-group mb-4 gap-user ">
                    <div class="fcrg-item  payment-info-main-option">
                      <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
                        <input
                          class="form-check-input cursor-pointer"
                          type="radio"
                          name="WTflexRadioDefault"
                          id="WTflexRadio__16"
                          checked={props.data?.registrationAsVendor == "true"}
                          onChange={(e) => {
                            const value = { ...props.data };
                            value.registrationAsVendor = "true";
                            props.setData(value);
                          }}
                        />
                        <label
                          class="form-check-label cursor-pointer"
                          for="WTflexRadio__16"
                        >
                          Yes
                        </label>
                      </div>
                    </div>
                    <div class="fcrg-item  payment-info-main-option">
                      <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
                        <input
                          class="form-check-input cursor-pointer"
                          type="radio"
                          name="WTflexRadioDefault"
                          id="WTflexRadio__61"
                          checked={props.data?.registrationAsVendor == "false"}
                          onChange={(e) => {
                            const value = { ...props.data };
                            value.registrationAsVendor = "false";
                            props.setData(value);
                          }}
                        />

                        <label
                          class="form-check-label cursor-pointer"
                          for="WTflexRadio__62"
                        >
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                  {props.errors && props.errors?.registrationAsVendor ? (
                    <p className="error-text contract-drpdown-error">
                      {errors.registrationAsVendor}
                    </p>
                  ) : (
                    ""
                  )}
                </div>

                {props.data?.registrationAsVendor === "true" && (
                  <div className="mb-4 add-user-input-width">
                    <label class="form-label" for="contractScope">
                      Registration Status<sup style={{ color: "Red" }}>*</sup>
                    </label>
                    <Select
                      value={props.data.registrationStatus}
                      options={props.registrationStatusOptions}
                      className="basic-single country-margin select-background"
                      classNamePrefix="select-search country-margin select-background"
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.value}
                      onChange={(e) => {
                        const values = { ...props.data };
                        values.registrationStatus = e;
                        props.setData(values);
                      }}
                      name="registrationStatus"
                      placeholder="Select Registration Status"
                      styles={customStyles}
                    />{" "}
                    {props.errors && props.errors?.registrationStatus ? (
                      <p className="error-text">{errors.registrationStatus}</p>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </div>
              {props.data?.registrationAsVendor === "true" && (
                <div className="d-flex add-user-flex">
                  <div className="mb-4 add-user-input-width">
                    <label class="form-label" for="email">
                      Registration User Name
                      <sup style={{ color: "Red" }}>*</sup>
                    </label>
                    <input
                      name="registrationUsername"
                      type="text"
                      value={props.data?.registrationUsername}
                      onChange={(e) => {
                        const value = { ...props.data };
                        value.registrationUsername = e.target.value;
                        props.setData(value);
                      }}
                      className="form-control"
                    />
                    {props.errors && props.errors?.registrationUsername ? (
                      <p className="error-text">
                        {errors.registrationUsername}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>{" "}
                  <div className="mb-4 add-user-input-width">
                    <label class="form-label" for="mobile">
                      Registration Password<sup style={{ color: "Red" }}>*</sup>
                    </label>
                    <input
                      type="text"
                      name="registrationPassword"
                      value={props.data?.registrationPassword}
                      onChange={(e) => {
                        const value = { ...props.data };
                        value.registrationPassword = e.target.value;
                        props.setData(value);
                      }}
                      className="form-control"
                    />
                    {props.errors && props.errors?.registrationPassword ? (
                      <p className="error-text">
                        {errors.registrationPassword}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              )}
              {props.data?.registrationAsVendor === "true" && (
                <div className="d-flex add-user-flex">
                  <div className="mb-4 add-user-input-width">
                    <label class="form-label" for="termination">
                      Registration URL<sup style={{ color: "Red" }}>*</sup>
                    </label>
                    <input
                      type="text"
                      name="registrationUrl"
                      value={props.data?.registrationUrl}
                      onChange={(e) => onChange(e)}
                      className="form-control"
                    />
                    {props.errors && props.errors?.registrationUrl ? (
                      <p className="error-text">{errors.registrationUrl}</p>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="mb-4 add-user-input-width">
                    <label class="form-label" for="mobile">
                      Registration Expiration Date
                    </label>
                    <DatePicker
                      className="form-control "
                      selected={props.data?.registrationExpirationDate}
                      onChange={(date) => {
                        const data = { ...props.data };
                        data.registrationExpirationDate = Date.parse(date);
                        props.setData(data);
                      }}
                    />
                    {/* <span>
                    {props.data?.registrationExpirationDate ? (
                      <DateFormat
                        date={props.data?.registrationExpirationDate}
                      />
                    ) : (
                      ""
                    )}
                  </span> */}
                    {props.errors &&
                    props.errors?.registrationExpirationDate ? (
                      <p className="error-text">
                        {errors.registrationExpirationDate}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </Offcanvas.Body>
        <div class="offcanvas-footer">
          <a
            href="javascript:void(0)"
            class={`btn btn-default btn-block ${
              props.loading ? "disabled" : ""
            }`}
            onClick={() => props.addContract()}
          >
            Submit
          </a>
        </div>
      </Offcanvas>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  addUser: (data, callback) => dispatch(addUser(data, callback)),
  getUserList: (params, callback) => dispatch(getUserList(params, callback)),
});
const mapStateToProps = (state) => ({
  getUser: getUser(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(AddUserModal));
