import React from "react";

const PaymentTypeThree = ({ setError3, error3, option3Data, setOption3Data, member }) => {
  return (
    <>
      <p>
        <strong>International Wire Transfer (Outside U.S.)</strong>
      </p>
      <div class="form-group mt-0">
        <div class="form-group-row d-block">
          <div class="form-group-column payment-info-text flex-fill">
            <label for="" class=" fw-medium">
              Beneficiary
            </label>
            <p>{option3Data.account_name}</p>
          </div>
          <div class="form-group-column flex-fill payment-info-text">
            <label for="" class=" fw-medium">
              Beneficiary Address (street, city, state and zip )
            </label>
            <p>{option3Data.account_address}</p>
          </div>
        </div>
      </div>
      <div class="form-group mt-0">
        <div class="form-group-row d-block">
          <div class="form-group-column flex-fill payment-info-text">
            <label for="" class=" fw-medium">
              Beneficiary IBAN and/or Account Number
            </label>
            <p>{option3Data.account_iban_no}</p>
          </div>
          <div class="form-group-column flex-fill payment-info-text">
            <label for="" class=" fw-medium">
              SWIFT Code (or ABA number for U.S. accounts)
            </label>
            <p>{option3Data.aba_swift_no}</p>
          </div>
        </div>
      </div>
      <div class="form-group mt-0">
        <div class="form-group-row d-block">
          <div class="form-group-column flex-fill payment-info-text">
            <label for="" class=" fw-medium">
              Beneficiary Bank Name
            </label>
            <p>{option3Data.bank_name}</p>
          </div>
          <div class="form-group-column flex-fill payment-info-text">
            <label for="" class=" fw-medium">
              Beneficiary Bank Address (including country)
            </label>
            <p>{option3Data.bank_address}</p>
          </div>
        </div>
      </div>
      <div class="form-group mt-0">
        <p style={{ marginBottom: " 0.125rem" }}>
          <strong>What type of reference number do you prefer?</strong>
        </p>
        <div class="four-column-radio-group fw-medium ">
          {option3Data.preferred_ref_no == "1" && (
            <div class="fcrg-item payment-info-main-option payment-info-text">
              <p class="form-check-label" for="RflowRadio2__1" style={{ fontWeight: "400" }}>
                Claim or File Number
              </p>
            </div>
          )}
          {option3Data.preferred_ref_no == "2" && (
            <div class="fcrg-item payment-info-main-option payment-info-text">
              <p class="form-check-label" for="RflowRadio2__2" style={{ fontWeight: "400" }}>
                Invoice Number
              </p>
            </div>
          )}
          {option3Data.preferred_ref_no == "3" && (
            <div class="fcrg-item payment-info-main-option payment-info-text">
              <p class="form-check-label" for="RflowRadio2__3" style={{ fontWeight: "400" }}>
                Shipping Container Number
              </p>
            </div>
          )}
          {option3Data.preferred_ref_no == "4" && (
            <div class="fcrg-item payment-info-main-option payment-info-text">
              <p class="form-check-label" for="RflowRadio2__4" style={{ fontWeight: "400" }}>
                Salvex Listing number is fine
              </p>
            </div>
          )}
        </div>
      </div>
      <div class="form-group mt-0 payment-info-text">
        <label>
          <strong>If you have intermediary bank information, please enter it here.</strong>
        </label>
        <p>{option3Data.intermediary_bank_info}</p>
      </div>
      <div class="form-group payment-info-text" style={{ marginTop: "0px" }}>
        <label for="" class=" fw-medium">
          Additional email address to be sent confirmation of payment.
        </label>
        <p>{option3Data.other_email}</p>
      </div>
      <div class="form-group mt-0 payment-info-text">
        <label for="" class=" fw-medium">
          Do you have any additional instructions?
        </label>
        <p>{option3Data.additional_info}</p>
      </div>
    </>
  );
};

export default PaymentTypeThree;
