import React, { useEffect, useState } from "react";
import ReportChart from "./reportChart";
import { connect } from "react-redux";
import {
  getBrowserReport,
  getCountryReport,
  getDayReport,
  getMonthReport,
  getOsReport,
  getOverallReport,
  getUser,
} from "../../../store/users";
import Select from "react-select";
import { customStyles } from "../../../common/customStyles/reactSelectStyle";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextField } from "@mui/material";
import NextButton from "../../../common/form/nextButton";
import { toast } from "react-toastify";
import AlertError from "../../../common/alerts/alertError";
import moment from "moment";

const UserLoginReport = (props) => {
  const [showCountryChart, setShowCountryChart] = useState(false);
  const [showBrowserChart, setShowBrowserChart] = useState(false);
  const [showOsChart, setShowOsChart] = useState(false);
  const [showOverallChart, setShowOverallChart] = useState(false);
  const [showMonthChart, setShowMonthChart] = useState(false);
  const [showDayChart, setShowDayChart] = useState(false);
  const [overallLoading, setOverallLoading] = useState(false);
  const [overallEmpty, setOverallEmpty] = useState(false);

  const [countryLoading, setCountryLoading] = useState(false);
  const [countryDayCount, setCountryDayCount] = useState();
  const [countryEmpty, setCountryEmpty] = useState(false);

  const [browserLoading, setBrowserLoading] = useState(false);
  const [browserDayCount, setBrowserDayCount] = useState();
  const [browserEmpty, setBrowserEmpty] = useState(false);

  const [osLoading, setOsLoading] = useState(false);
  const [osDayCount, setOsDayCount] = useState();
  const [osEmpty, setOsEmpty] = useState(false);

  const [monthLoading, setMonthLoading] = useState(false);
  const [fromYear, setFromYear] = useState();
  const [toYear, setToYear] = useState();
  const [monthEmpty, setMonthEmpty] = useState(false);

  const [dayLoading, setDayLoading] = useState(false);
  const [date, setDate] = useState(moment(Date.now()).format("YYYY-MM-DD"));
  const [dayEmpty, setDayEmpty] = useState(false);

  //Year Options
  const currentYear = new Date().getFullYear();
  const yearOptions = Array.from(
    { length: currentYear - 2000 + 1 },
    (_, index) => ({
      name: (2000 + index).toString(),
      value: 2000 + index,
    })
  );
  yearOptions.unshift({ name: "Please Select a Year", value: "" });

  //Day Options
  const dayOptions = [{ name: "Please Select Number of Days", value: "" }];
  for (let i = 1; i <= 30; i++) {
    dayOptions.push({ name: i.toString(), value: i });
  }

  //MaxDate
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  //Fetching Country Report
  const fetchCountryReport = () => {
    setCountryLoading(true);
    props.refStart && props?.refStart();
    props.getCountryReport(countryDayCount, (res) => {
      if (res.status === 200) {
        if (Object.keys(res.data).length === 0) {
          setShowCountryChart(false);
          setCountryEmpty(true);
          setCountryLoading(false);
          props.refStop && props?.refStop();
        } else {
          setShowCountryChart(true);
          setCountryEmpty(false);
          setCountryLoading(false);
          props.refStop && props?.refStop();
        }
      } else {
        setCountryEmpty(false);
        setShowCountryChart(false);
        setCountryLoading(false);
        props.refStop && props?.refStop();
        toast(
          <AlertError
            message={
              res.data.message ? res.data.message : "Something Went Wrong"
            }
          />
        );
      }
    });
  };

  //Fetching Browser Report
  const fetchBrowserReport = () => {
    setBrowserLoading(true);
    props.refStart && props?.refStart();
    props.getBrowserReport(browserDayCount, (res) => {
      if (res.status === 200) {
        if (Object.keys(res.data).length === 0) {
          setShowBrowserChart(false);
          setBrowserEmpty(true);
          setBrowserLoading(false);
          props.refStop && props?.refStop();
        } else {
          setShowBrowserChart(true);
          setBrowserEmpty(false);
          setBrowserLoading(false);
          props.refStop && props?.refStop();
        }
      } else {
        setBrowserEmpty(false);
        setShowBrowserChart(false);
        setBrowserLoading(false);
        props.refStop && props?.refStop();
        toast(
          <AlertError
            message={
              res.data.message ? res.data.message : "Something Went Wrong"
            }
          />
        );
      }
    });
  };

  //Os Report Fetching
  const fetchOsReport = () => {
    setOsLoading(true);
    props.refStart && props?.refStart();
    props.getOsReport(osDayCount, (res) => {
      if (res.status === 200) {
        if (Object.keys(res.data).length === 0) {
          setShowOsChart(false);
          setOsEmpty(true);
          setOsLoading(false);
          props.refStop && props?.refStop();
        } else {
          setShowOsChart(true);
          setOsEmpty(false);
          setOsLoading(false);
          props.refStop && props?.refStop();
        }
      } else {
        setShowOsChart(false);
        setOsEmpty(false);
        setOsLoading(false);
        props.refStop && props?.refStop();
        toast(
          <AlertError
            message={
              res.data.message ? res.data.message : "Something Went Wrong"
            }
          />
        );
      }
    });
  };

  //Overall Report Fetching
  const fetchOverallReport = () => {
    setOverallLoading(true);
    props.refStart && props?.refStart();
    props.getOverallReport((res) => {
      if (res.status === 200) {
        if (Object.keys(res.data).length === 0) {
          setShowOverallChart(false);
          setOverallEmpty(true);
          setOverallLoading(false);
          props.refStop && props?.refStop();
        } else {
          setShowOverallChart(true);
          setOverallEmpty(false);
          setOverallLoading(false);
          props.refStop && props?.refStop();
        }
      } else {
        setOverallEmpty(false);
        setShowOverallChart(false);
        setOverallLoading(false);
        props.refStop && props?.refStop();

        toast(
          <AlertError
            message={
              res.data.message ? res.data.message : "Something Went Wrong"
            }
          />
        );
      }
    });
  };

  //Month Report Fetching
  const fetchMonthReport = () => {
    setMonthLoading(true);
    props.refStart && props?.refStart();
    props.getMonthReport(fromYear, toYear, (res) => {
      if (res.status === 200) {
        if (Object.keys(res.data).length === 0) {
          setShowMonthChart(false);
          setMonthEmpty(true);
          setMonthLoading(false);
          props.refStop && props?.refStop();
        } else {
          setShowMonthChart(true);
          setMonthEmpty(false);
          setMonthLoading(false);
          props.refStop && props?.refStop();
        }
      } else {
        setMonthEmpty(false);
        setShowMonthChart(false);
        setMonthLoading(false);
        props.refStop && props?.refStop();

        if (fromYear > toYear) {
          toast(
            <AlertError
              message={`"To" Year must be greater than or equal to the "From" Year`}
            />
          );
        } else {
          toast(
            <AlertError
              message={
                res.data.message ? res.data.message : "Something Went Wrong"
              }
            />
          );
        }
      }
    });
  };

  //Day Report Fetching
  const fetchDayReport = () => {
    setDayLoading(true);
    props.refStart && props?.refStart();
    props.getDayReport(date, (res) => {
      if (res.status === 200) {
        const values = Object.values(res.data);
        const sum = values.reduce((acc, curr) => acc + curr, 0);
        if (sum === 0) {
          setShowDayChart(false);
          setDayEmpty(true);
          setDayLoading(false);
          props.refStop && props?.refStop();
        } else {
          setShowDayChart(true);
          setDayEmpty(false);
          setDayLoading(false);
          props.refStop && props?.refStop();
        }
      } else {
        setDayEmpty(false);
        setShowDayChart(false);
        setDayLoading(false);
        props.refStop && props?.refStop();

        toast(
          <AlertError
            message={
              res.data.message ? res.data.message : "Something Went Wrong"
            }
          />
        );
      }
    });
  };

  return (
    <>
      <div class="filter-container d-flex align-items-center sticky-top">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12 d-flex align-items-center flex-wrap justify-content-between">
              <div class="filter-title fw-medium text-capitalize">
                User Login Report
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="detail-page-container pb-5">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="dpc-col-row d-flex flex-wrap">
                <div class="dpc-col-column">
                  {/* Country Report Widget */}
                  <div class="details-page-widget-block">
                    <div class="page-widget-head d-flex align-items-center justify-content-between">
                      <div class="d-flex align-items-center">
                        <div class="page-widget-label text-capitalize">
                          Country Report
                        </div>
                        <div
                          class="tooltip-icon-box d-flex align-items-center justify-content-center"
                          data-bs-custom-class="custom-tooltip-popover"
                          data-bs-toggle="customTooltip"
                          data-bs-trigger="hover focus"
                          data-bs-content="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley"
                        >
                          <img src="include/images/help-circle.svg" alt="" />
                        </div>
                      </div>
                    </div>
                    <div
                      class="page-widget-body mt-3 position-relative w-100"
                      style={{ height: "500px" }}
                    >
                      <table class="table table-borderless align-middle table-left-align">
                        <colgroup>
                          <col />
                          <col />
                        </colgroup>
                        <tbody>
                          <tr>
                            <td>Days</td>
                            <td>
                              <Select
                                className="basic-single"
                                options={dayOptions}
                                classNamePrefix="select-search "
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.value}
                                name="days"
                                placeholder="Please Select Number of Days"
                                onChange={(e) => {
                                  setCountryDayCount(e.value);
                                }}
                                styles={customStyles}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div
                        className="d-flex justify-content-end me-2"
                        style={{ marginTop: "75px" }}
                      >
                        <NextButton
                          label="Submit"
                          classData={
                            typeof countryDayCount == "number"
                              ? "btn btn-default"
                              : "btn btn-default disabled btn-loading"
                          }
                          handleSubmit={fetchCountryReport}
                          loading={countryLoading}
                        />
                      </div>

                      {showCountryChart ? (
                        <div className="position-absolute d-flex justify-content-center w-100 h-100 mt-3">
                          <ReportChart
                            data={props?.getUser?.countryReport}
                            section="Country"
                          />
                        </div>
                      ) : (
                        <div
                          class="page-widget-empty-box text-capitalize d-flex align-items-center justify-content-center text-center mt-3"
                          style={{
                            height: "315px",
                            flexDirection: "column",
                            gap: "10px",
                          }}
                        >
                          <span>
                            {countryEmpty
                              ? "No Data Availabe"
                              : "Please Select Your Filters To Plot A Chart Here."}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Browser Report Widget */}
                  <div class="details-page-widget-block">
                    <div class="page-widget-head d-flex align-items-center justify-content-between">
                      <div class="d-flex align-items-center">
                        <div class="page-widget-label text-capitalize">
                          Browser Report
                        </div>
                        <div
                          class="tooltip-icon-box d-flex align-items-center justify-content-center"
                          data-bs-custom-class="custom-tooltip-popover"
                          data-bs-toggle="customTooltip"
                          data-bs-trigger="hover focus"
                          data-bs-content="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley"
                        >
                          <img src="include/images/help-circle.svg" alt="" />
                        </div>
                      </div>
                    </div>
                    <div
                      class="page-widget-body mt-3 position-relative w-100"
                      style={{ height: "500px" }}
                    >
                      <table class="table table-borderless align-middle table-left-align">
                        <colgroup>
                          <col />
                          <col />
                        </colgroup>
                        <tbody>
                          <tr>
                            <td>Days</td>
                            <td>
                              <Select
                                className="basic-single"
                                options={dayOptions}
                                classNamePrefix="select-search "
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.value}
                                name="days"
                                placeholder="Please Select Number of Days"
                                onChange={(e) => {
                                  setBrowserDayCount(e.value);
                                }}
                                styles={customStyles}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="d-flex justify-content-end me-2">
                        <NextButton
                          label="Submit"
                          classData={
                            typeof browserDayCount == "number"
                              ? "btn btn-default"
                              : "btn btn-default disabled btn-loading"
                          }
                          handleSubmit={fetchBrowserReport}
                          loading={browserLoading}
                        />
                      </div>

                      {showBrowserChart ? (
                        <div className="position-absolute d-flex justify-content-center w-100 h-100 mt-3">
                          <ReportChart
                            data={props?.getUser?.browserReport}
                            section="Browser"
                          />
                        </div>
                      ) : (
                        <div
                          class="page-widget-empty-box text-capitalize d-flex align-items-center justify-content-center text-center mt-3"
                          style={{
                            height: "370px",
                            flexDirection: "column",
                            gap: "10px",
                          }}
                        >
                          <span>
                            {browserEmpty
                              ? "No Data Available"
                              : "Please Select Your Filters To Plot A Chart Here."}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div class="dpc-col-column">
                  {/* Os Report Widget */}
                  <div class="details-page-widget-block">
                    <div class="page-widget-head d-flex align-items-center justify-content-between">
                      <div class="d-flex align-items-center">
                        <div class="page-widget-label text-capitalize">
                          OS Report
                        </div>
                        <div
                          class="tooltip-icon-box d-flex align-items-center justify-content-center"
                          data-bs-custom-class="custom-tooltip-popover"
                          data-bs-toggle="customTooltip"
                          data-bs-trigger="hover focus"
                          data-bs-content="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley"
                        >
                          <img src="include/images/help-circle.svg" alt="" />
                        </div>
                      </div>
                    </div>
                    <div
                      class="page-widget-body mt-3 position-relative w-100"
                      style={{ height: "500px" }}
                    >
                      <table class="table table-borderless align-middle table-left-align">
                        <colgroup>
                          <col />
                          <col />
                        </colgroup>
                        <tbody>
                          <tr>
                            <td>Days</td>
                            <td>
                              <Select
                                className="basic-single"
                                options={dayOptions}
                                classNamePrefix="select-search "
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.value}
                                name="days"
                                placeholder="Please Select Number of Days"
                                onChange={(e) => {
                                  setOsDayCount(e.value);
                                }}
                                styles={customStyles}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div
                        className="d-flex justify-content-end me-2"
                        style={{ marginTop: "75px" }}
                      >
                        <NextButton
                          label="Submit"
                          classData={
                            typeof osDayCount == "number"
                              ? "btn btn-default"
                              : "btn btn-default disabled btn-loading"
                          }
                          handleSubmit={fetchOsReport}
                          loading={osLoading}
                        />
                      </div>

                      {showOsChart ? (
                        <div className="position-absolute d-flex justify-content-center w-100 h-100 mt-3">
                          <ReportChart
                            section="OS"
                            data={props?.getUser?.osReport}
                          />
                        </div>
                      ) : (
                        <div
                          class="page-widget-empty-box text-capitalize d-flex align-items-center justify-content-center text-center mt-3"
                          style={{
                            height: "315px",
                            flexDirection: "column",
                            gap: "10px",
                          }}
                        >
                          <span>
                            {osEmpty
                              ? "No Data Available"
                              : "Please Select Your Filters To Plot A Chart Here."}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Overall Report Widget */}
                  <div class="details-page-widget-block">
                    <div class="page-widget-head d-flex align-items-center justify-content-between">
                      <div class="d-flex align-items-center">
                        <div class="page-widget-label text-capitalize">
                          Overall Report
                        </div>
                        <div
                          class="tooltip-icon-box d-flex align-items-center justify-content-center"
                          data-bs-custom-class="custom-tooltip-popover"
                          data-bs-toggle="customTooltip"
                          data-bs-trigger="hover focus"
                          data-bs-content="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley"
                        >
                          <img src="include/images/help-circle.svg" alt="" />
                        </div>
                      </div>
                    </div>
                    <div
                      class="page-widget-body mt-3 position-relative w-100"
                      style={{ height: "500px" }}
                    >
                      {showOverallChart ? (
                        <div
                          className="position-absolute d-flex justify-content-center w-100 h-100 mt-3"
                          style={{ paddingTop: "100px" }}
                        >
                          <ReportChart
                            section="Overall"
                            data={props?.getUser?.overallReport}
                          />
                        </div>
                      ) : (
                        <div
                          class="page-widget-empty-box text-capitalize d-flex align-items-center justify-content-center text-center"
                          style={{
                            height: "500px",
                            flexDirection: "column",
                            gap: "10px",
                          }}
                        >
                          <span>
                            {overallEmpty
                              ? "No Data Available"
                              : "Click Here To View Overall Report"}
                          </span>

                          <NextButton
                            label="View"
                            classData="btn btn-default"
                            handleSubmit={fetchOverallReport}
                            loading={overallLoading}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div class="dpc-col-column">
                  {/* Month Report Widget */}
                  <div class="details-page-widget-block">
                    <div class="page-widget-head d-flex align-items-center justify-content-between">
                      <div class="d-flex align-items-center">
                        <div class="page-widget-label text-capitalize">
                          Month Report
                        </div>
                        <div
                          class="tooltip-icon-box d-flex align-items-center justify-content-center"
                          data-bs-custom-class="custom-tooltip-popover"
                          data-bs-toggle="customTooltip"
                          data-bs-trigger="hover focus"
                          data-bs-content="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley"
                        >
                          <img src="include/images/help-circle.svg" alt="" />
                        </div>
                      </div>
                    </div>
                    <div
                      class="page-widget-body mt-3 position-relative w-100"
                      style={{ height: "500px" }}
                    >
                      <table class="table table-borderless align-middle table-left-align">
                        <colgroup>
                          <col />
                          <col />
                        </colgroup>
                        <tbody>
                          <tr>
                            <td>From</td>
                            <td>
                              <Select
                                className="basic-single"
                                options={yearOptions}
                                classNamePrefix="select-search "
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.value}
                                name="year"
                                placeholder="Please Select a Year"
                                onChange={(e) => {
                                  setFromYear(e.value);
                                }}
                                styles={customStyles}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>To</td>
                            <td>
                              <Select
                                className="basic-single"
                                classNamePrefix="select-search "
                                options={yearOptions}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.value}
                                name="year"
                                placeholder="Please Select a Year"
                                onChange={(e) => {
                                  setToYear(e.value);
                                }}
                                styles={customStyles}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="d-flex justify-content-end me-2">
                        <NextButton
                          label="Submit"
                          classData={
                            typeof fromYear == "number" &&
                            typeof toYear == "number"
                              ? "btn btn-default"
                              : "btn btn-default disabled btn-loading"
                          }
                          handleSubmit={fetchMonthReport}
                          loading={monthLoading}
                        />
                      </div>

                      {showMonthChart ? (
                        <div className="position-absolute d-flex justify-content-center w-100 h-100 mt-3">
                          <ReportChart
                            section="Month"
                            data={props?.getUser?.monthReport}
                          />
                        </div>
                      ) : (
                        <div
                          class="page-widget-empty-box text-capitalize d-flex align-items-center justify-content-center text-center mt-3"
                          style={{
                            height: "315px",
                            flexDirection: "column",
                            gap: "10px",
                          }}
                        >
                          <span>
                            {monthEmpty
                              ? "No Data Available"
                              : "Please Select Your Filters To Plot A Chart Here."}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Day Report Widget */}
                  <div class="details-page-widget-block">
                    <div class="page-widget-head d-flex align-items-center justify-content-between">
                      <div class="d-flex align-items-center">
                        <div class="page-widget-label text-capitalize">
                          Day Report
                        </div>
                        <div
                          class="tooltip-icon-box d-flex align-items-center justify-content-center"
                          data-bs-custom-class="custom-tooltip-popover"
                          data-bs-toggle="customTooltip"
                          data-bs-trigger="hover focus"
                          data-bs-content="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley"
                        >
                          <img src="include/images/help-circle.svg" alt="" />
                        </div>
                      </div>
                    </div>
                    <div
                      class="page-widget-body mt-3 position-relative w-100"
                      style={{ height: "500px" }}
                    >
                      <table class="table table-borderless align-middle table-left-align">
                        <colgroup>
                          <col />
                          <col />
                        </colgroup>
                        <tbody>
                          <tr>
                            <td>Date</td>
                            <td>
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                              >
                                <DatePicker
                                  disableHighlightToday={true}
                                  value={date}
                                  minDate={null}
                                  maxDate={currentDate}
                                  className="form-control"
                                  format="YYYY-MM-DD"
                                  onChange={(e) => {
                                    const formatted =
                                      moment(e).format("YYYY-MM-DD");
                                    setDate(formatted);
                                  }}
                                  renderInput={(params) => (
                                    <TextField {...params} error={false} />
                                  )}
                                  inputFormat="yyyy-MM-dd"
                                />
                              </LocalizationProvider>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="d-flex justify-content-end me-2">
                        <NextButton
                          label="Submit"
                          classData="btn btn-default"
                          handleSubmit={fetchDayReport}
                          loading={dayLoading}
                        />
                      </div>

                      {showDayChart ? (
                        <div className="position-absolute d-flex justify-content-center w-100 h-100 mt-3">
                          <ReportChart
                            section="Day"
                            data={props?.getUser?.dayReport}
                          />
                        </div>
                      ) : (
                        <div
                          class="page-widget-empty-box text-capitalize d-flex align-items-center justify-content-center text-center mt-3"
                          style={{
                            height: "360px",
                            flexDirection: "column",
                            gap: "10px",
                          }}
                        >
                          <span>
                            {dayEmpty
                              ? "No Data Available"
                              : "Please Select Your Filters To Plot A Chart Here."}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getCountryReport: (days, callback) =>
    dispatch(getCountryReport(days, callback)),
  getBrowserReport: (days, callback) =>
    dispatch(getBrowserReport(days, callback)),
  getOsReport: (days, callback) => dispatch(getOsReport(days, callback)),
  getOverallReport: (callback) => dispatch(getOverallReport(callback)),
  getMonthReport: (fromYear, toYear, callback) =>
    dispatch(getMonthReport(fromYear, toYear, callback)),
  getDayReport: (day, callback) => dispatch(getDayReport(day, callback)),
});
const mapStateToProps = (state) => ({
  getUser: getUser(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(UserLoginReport));
