import React, { useEffect, useState } from "react";
import DateFormat from "../../../common/DateFormat/dateFormat";
import { assignProject, dashboardData, otherAuctions, getAccountManagement } from "../../../store/dashBoard";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import AlertError from "../../../common/alerts/alertError";
import AssignModal from "../../../layouts/modals/dashboard/accountManagement/assignModal";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import EditModal from "../../../layouts/modals/dashboard/accountManagement/editModal";

const AccountManagement = (props) => {
  const detailData = props.data;

  const [page, setPage] = useState(1);
  const [action, setAction] = useState(false);
  const [loading, setLoading] = useState(false);
  const [assignModal, setAssignModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [title, setTitle] = useState("");
  const [assignData, setAssignData] = useState({
    title: "",
    project_id: "",
  });
  const [editData, setEditData] = useState({
    action: "edit_location_process",
    company_id: 0,
    project_id: 0,
    title: "",
    address_1: "",
    address_2: "",
    city: "",
    state_id: 0,
    zip_code: "",
    phone_1: "",
    phone_2: "",
    country_id: 0,
    description: "",
    headquarters: 0,
    unsubscribe: 0,
  });
  const toggleAssignModal = () => {
    setAssignModal(!assignModal);
  };

  const toggleEditModal = () => {
    setEditModal(!editModal);
  };

  const getEditModal = (id) => {
    const payload = {
      action: "edit_location_form",
      project_id: id,
    };
    props?.refStart();
    props?.otherAuctions(payload, (res) => {
      if (res.status === 200) {
        const val = res?.data?.data?.project;
        setTitle(val?.title ? val?.title : "");
        const data = { ...editData };
        data.company_id = val?.company_id ? val?.company_id : 0;
        data.project_id = val?.project_id ? val?.project_id : 0;
        data.title = val?.title ? val?.title : "";
        data.address_1 = val?.address_1 ? val?.address_1 : "";
        data.address_2 = val?.address_2 ? val?.address_2 : "";
        data.city = val?.city ? val?.city : "";
        data.state_id = val?.state_id ? val?.state_id : 0;
        data.zip_code = val?.zip_code ? val?.zip_code : "";
        data.phone_1 = val?.phone_1 ? val?.phone_1 : "";
        data.phone_2 = val?.phone_2 ? val?.phone_2 : "";
        data.country_id = val?.country_id ? val?.country_id : 0;
        data.description = val?.description ? val?.description : "";
        data.headquarters = val?.headquarters ? 1 : 0;
        data.unsubscribe = val?.unsubscribe ? 1 : 0;
        setEditData(data);
        setCountry(res?.data?.data?.countries);
        setState(res?.data?.data?.states);
        props?.refStop();
        toggleEditModal();
      } else {
        props?.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const handleEdit = () => {
    setLoading(true);
    props?.refStart();
    props?.otherAuctions(editData, (res) => {
      if (res.status === 200) {
        props?.getAccountManagement(props.empId, props.type, page, props.status, (res) => {});
        props?.refStop();
        setLoading(false);
        toast(<AlertSuccess message={"Information Saved"} />);
        toggleEditModal();
      } else {
        setLoading(false);
        props?.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const getDropdown = (item) => {
    const payload = {
      action: "assign_project_form",
      company_id: item.company_id,
      project_id: item.project_id,
    };
    props.refStart();
    props.assignProject(payload, (res) => {
      if (res.status === 200) {
        const projects = res?.data?.projects;
        const data = res?.data?.data;
        setAssignData(
          projects?.find((val) => val?.project_id === data[0]?.owner_id)
            ? projects?.find((val) => val?.project_id === data[0]?.owner_id)
            : { title: "-- No Assignement --", project_id: 0 },
        );
        setModalData(res.data);
        toggleAssignModal();
        props.refStop();
      } else {
        props.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const handleAssign = () => {
    const data = modalData?.data[0];
    const payload = {
      action: "assign_project_process",
      company_id: data?.company_id ? data?.company_id : 0,
      project_id: data?.project_id ? data?.project_id : 0,
      owner_id: assignData?.project_id ? assignData?.project_id : 0,
    };
    setLoading(true);
    props.refStart();
    props.assignProject(payload, (res) => {
      if (res.status === 200) {
        props.refStop();
        setLoading(false);
        toggleAssignModal();
        toast(<AlertSuccess message={"Information Saved"} />);
      } else {
        props.refStop();
        setLoading(false);
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  useEffect(() => {
    const pagination = () => {
      props.refStart();
      props.getAccountManagement(props.empId, props.type, page, props.status, (res) => {
        if (res.status === 200) {
          props.refStop();
        } else {
          props.refStop();
          toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        }
      });
    };
    if (action) pagination();
  }, [page]);

  return (
    <div class="details-page-widget-block">
      <div class="page-widget-head d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <div class="page-widget-label text-capitalize cursor-pointer">{props.title}</div>
        </div>
      </div>
      <div class="page-widget-body mt-3">
        <div class="table-responsive table-custom">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th style={{ width: "80px" }}>ENTERPRISE</th>
                <th>HEAD QUARTERS</th>
                <th>BUSINESS UNITS</th>
                <th>HQ</th>
                <th>PT</th>
                <th>BU Status</th>
                <th>BU Type</th>
                <th>
                  {props.title
                    ? props.title === "Inventory Sharing"
                      ? "Incomplete Inventory Sharing"
                      : props.title
                    : ""}
                </th>
                <th>Seller Status</th>
                <th>Last BU Note</th>
                <th>Last Asset Disposal Update</th>
                <th>Enterprise Sales</th>
                <th>BU Sales</th>
                <th>Last Buyer Interest</th>
                <th>INDUSTRY</th>
                <th>PHONE</th>
                <th>CONTACTS</th>
                <th>UNS</th>
                <th>TRADERS</th>
                <th colSpan={4}></th>
              </tr>
            </thead>
            <tbody>
              {detailData?.data?.map((val) => (
                <tr>
                  <td>
                    {val.company_name ? (
                      <a
                        target="_blank"
                        href={
                          process.env.REACT_APP_MODE === "development"
                            ? `https://admin-dev.salvex.com/admin/_proc/_company/_edit.cfm?cid=${val.company_id}`
                            : `https://admin.salvex.com/admin/_proc/_company/_edit.cfm?cid=${val.company_id}`
                        }
                      >
                        <span className="green-btn-text">{val.company_name}</span>
                      </a>
                    ) : (
                      ""
                    )}
                  </td>
                  <td>{val.headquarters_txt}</td>
                  <td>
                    {val.title ? (
                      <a
                        target="_blank"
                        href={
                          process.env.REACT_APP_MODE === "development"
                            ? `https://admin-dev.salvex.com/admin/_proc/_company/project_detail.cfm?project_id=${val.project_id}`
                            : `https://admin.salvex.com/admin/_proc/_company/project_detail.cfm?project_id=${val.project_id}`
                        }
                      >
                        <span className="green-btn-text">{val.title}</span>
                      </a>
                    ) : (
                      ""
                    )}
                  </td>
                  <td>{val.headquarters && "HQ"}</td>
                  <td>{val.publicly_traded && "PT"}</td>
                  <td>{val.bu_status}</td>
                  <td>{val.bu_type}</td>
                  <td>
                    {props.title === "Asset Disposal Update Required"
                      ? val?.asset_disposal_required?.split(",").length > 0
                        ? val?.asset_disposal_required?.split(",").map((val) => {
                            const splittedVal = val.split("=");
                            return splittedVal.length > 0 ? (
                              <a
                                target="_blank"
                                href={
                                  process.env.REACT_APP_MODE === "development"
                                    ? `https://admin-dev.salvex.com/admin/_user/_edit.cfm?user_id=${splittedVal[1]}`
                                    : `https://admin.salvex.com/admin/_user/_edit.cfm?user_id=${splittedVal[1]}`
                                }
                              >
                                <span className="green-btn-text">{splittedVal[0]}</span>
                              </a>
                            ) : (
                              <span className="green-btn-text">{val}</span>
                            );
                          })
                        : val.asset_disposal_required
                      : props.title === "Client Meeting Required"
                      ? val.client_meeting_required.split(",").length > 0
                        ? val.client_meeting_required.split(",").map((val) => {
                            const splittedVal = val.split("=");
                            return splittedVal.length > 0 ? (
                              <a
                                target="_blank"
                                href={
                                  process.env.REACT_APP_MODE === "development"
                                    ? `https://admin-dev.salvex.com/admin/_user/_edit.cfm?user_id=${splittedVal[1]}`
                                    : `https://admin.salvex.com/admin/_user/_edit.cfm?user_id=${splittedVal[1]}`
                                }
                              >
                                <span className="green-btn-text">{splittedVal[0]}</span>
                              </a>
                            ) : (
                              <span className="green-btn-text">{val}</span>
                            );
                          })
                        : val.client_meeting_required
                      : props.title === "Client Completed Meeting"
                      ? val.client_meeting_required.split(",").length > 0
                        ? val.client_meeting_required.split(",").map((val) => {
                            const splittedVal = val.split("=");
                            return splittedVal.length > 0 ? (
                              <a
                                target="_blank"
                                href={
                                  process.env.REACT_APP_MODE === "development"
                                    ? `https://admin-dev.salvex.com/admin/_user/_edit.cfm?user_id=${splittedVal[1]}`
                                    : `https://admin.salvex.com/admin/_user/_edit.cfm?user_id=${splittedVal[1]}`
                                }
                              >
                                <span className="green-btn-text">{splittedVal[0]}</span>
                              </a>
                            ) : (
                              <span className="green-btn-text">{val}</span>
                            );
                          })
                        : val.client_meeting_required
                      : props.title === "Inventory Sharing"
                      ? val?.inventory_sharing?.split(",")?.length > 0
                        ? val?.inventory_sharing?.split(",")?.map((val) => {
                            const splittedVal = val.split("=");
                            return splittedVal.length > 0 ? (
                              <a
                                target="_blank"
                                href={
                                  process.env.REACT_APP_MODE === "development"
                                    ? `https://admin-dev.salvex.com/admin/_user/_edit.cfm?user_id=${splittedVal[1]}`
                                    : `https://admin.salvex.com/admin/_user/_edit.cfm?user_id=${splittedVal[1]}`
                                }
                              >
                                <span className="green-btn-text">{splittedVal[0]}</span>
                              </a>
                            ) : (
                              <span className="green-btn-text">{val}</span>
                            );
                          })
                        : val.inventory_sharing
                      : ""}
                  </td>
                  <td>{val.seller_status}</td>
                  <td>{val.last_bu_note ? <DateFormat date={val.last_bu_note} /> : "N/A"}</td>
                  <td>{val.last_asset_update ? <DateFormat date={val.last_asset_update} /> : "N/A"}</td>
                  <td>
                    {val.totalsales
                      ? "$" +
                        new Intl.NumberFormat("en-US", {
                          style: "decimal",
                          minimumFractionDigits: 2,
                        }).format(val.totalsales) +
                        "."
                      : "$0.00"}
                  </td>
                  <td>
                    {val.sales
                      ? "$" +
                        new Intl.NumberFormat("en-US", {
                          style: "decimal",
                          minimumFractionDigits: 2,
                        }).format(val.sales) +
                        "."
                      : "$0.00"}
                  </td>
                  <td>{val.last_interest ? <DateFormat date={val.last_interest} /> : "N/A"}</td>
                  <td>{val.industry}</td>
                  <td>
                    {val.phone_1} {val.phone_2}
                  </td>
                  <td>{val.user_count}</td>
                  <td></td>
                  <td>{val.traders}</td>
                  <td style={{ minWidth: "110px" }}>
                    <a
                      className="btn btn-primary btn-xs-size btn-auto"
                      target="_blank"
                      href={
                        process.env.REACT_APP_MODE === "development"
                          ? `https://admin-dev.salvex.com/admin/_proc/_company/project_populate.cfm?project_id=${val.project_id}`
                          : `https://admin-dev.salvex.com/admin/_proc/_company/project_populate.cfm?project_id=${val.project_id}`
                      }
                    >
                      + POPULATE
                    </a>
                  </td>
                  <td>
                    <button className="btn btn-primary btn-xs-size btn-auto" onClick={() => getDropdown(val)}>
                      ASSIGN
                    </button>
                  </td>
                  <td>
                    <button
                      className="btn btn-primary btn-xs-size btn-auto"
                      onClick={() => getEditModal(val?.project_id)}
                    >
                      EDIT
                    </button>
                  </td>
                  <td>{val.user_count}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="d-flex justify-content-between mt-3">
          <div className="justify-content-start">
            Page {detailData?.page} of {detailData?.max_pages} ({detailData?.total_records} records)
          </div>
          {detailData?.data?.length > 0 && (
            <div class="table-pagination">
              <ul class="pagination">
                <li class="page-item">
                  <a
                    class={`page-link ${page === 1 ? "disabled" : "cursor-pointer"}`}
                    aria-label="Previous"
                    onClick={() => {
                      if (page !== 1) {
                        setAction(true);
                        setPage(page - 1);
                      }
                    }}
                  >
                    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7 13L1 7L7 1" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </a>
                </li>
                <li class="page-item active">
                  <a class="page-link" href="javascript:void(0)">
                    {page}
                  </a>
                </li>
                <li class="page-item">
                  {page < detailData?.max_pages && (
                    <a
                      class="page-link"
                      onClick={() => {
                        setAction(true);
                        setPage(page + 1);
                      }}
                    >
                      {page + 1}
                    </a>
                  )}
                </li>
                <li class="page-item">
                  {page < detailData?.max_pages && (
                    <a
                      class="page-link"
                      href="javascript:void(0)"
                      aria-label="Next"
                      onClick={() => {
                        setAction(true);
                        setPage(page + 1);
                      }}
                    >
                      <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 13L7 7L1 1" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </a>
                  )}
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      <AssignModal
        show={assignModal}
        onHide={toggleAssignModal}
        assignData={assignData}
        setAssignData={setAssignData}
        modalData={modalData}
        loading={loading}
        handleSubmit={handleAssign}
      />
      <EditModal
        show={editModal}
        onHide={toggleEditModal}
        title={title}
        editData={editData}
        setEditData={setEditData}
        loading={loading}
        country={country}
        state={state}
        handleEdit={handleEdit}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getAccountManagement: (empId, type, page, callback) => dispatch(getAccountManagement(empId, type, page, callback)),
  assignProject: (data, callback) => dispatch(assignProject(data, callback)),
  otherAuctions: (data, callback) => dispatch(otherAuctions(data, callback)),
});
const mapStateToProps = (state) => ({
  dashboardData: dashboardData(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(AccountManagement));
