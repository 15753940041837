import React from "react";
import { Modal } from "react-bootstrap";
import close from "../../../include/images/close.svg";
import radioIcon1 from "../../../include/images/add.svg";
import radioIcon2 from "../../../include/images/icon.svg";
import radioIcon3 from "../../../include/images/minus.svg";
import DateFormat from "../../../common/DateFormat/dateFormat";
import trash from "../../../include/images/trash-18x18.svg";

const Rating = (props) => {
  const data = props.rating.data;
  return (
    <Modal
      show={props.show}
      onHide={() => props.onHide()}
      className="modal fade custom-modal"
      id="viewRating"
      dialogClassName="modal-dialog modal-dialog-centered modal-xl"
    >
      <div class="modal-content d-flex flex-column">
        <div class="custom-modal-header d-flex align-items-center justify-content-between">
          {/* <h5 class="mb-0 text-capitalize">Rating</h5> */}
          <button
            type="button"
            class="close-btn d-flex align-items-center justify-content-center"
            onClick={props.onHide}
          >
            <img src={close} alt="" />
          </button>
        </div>
        <Modal.Body className="custom-modal-body flex-grow-1 w-100 overflow-hidden">
          <div className="table-responsive  table-custom">
            <table className="table table-bordered">
              <colgroup>
                <col />
                <col />
                <col />
                <col />
                <col />
                <col />
              </colgroup>
              <thead>
                <tr>
                  <th>Listing</th>
                  <th>Rating</th>
                  <th>Description</th>
                  <th>Posted By</th>
                  <th>Date</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data && data.length > 0
                  ? data.map((val) => {
                      return (
                        <tr>
                          <td>
                            <a href={`/listing-detail?id=${val.auction_id}`} target="_blank" className="green-btn-text">
                              {val.auction_id}
                            </a>
                            {" - "} {val.title}
                          </td>
                          <td>
                            {val.rating === 2 && <img src={radioIcon1} alt="" />}
                            {val.rating === 1 && <img src={radioIcon2} alt="" />}
                            {val.rating === 0 && <img src={radioIcon3} alt="" />}
                          </td>
                          <td>{val.rdescription}</td>
                          <td>{val.poster}</td>
                          <td>
                            <DateFormat date={val.posted} />
                          </td>
                          <td>
                            <button type="button">
                              <img src={trash} alt="Trash" />
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  : ""}
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <div class="custom-modal-dividor"></div>
        <div class="custom-modal-footer d-grid gap-2 d-md-flex align-items-center justify-content-md-end">
          <button class="btn btn-link global-cancel-button me-md-2" type="button" onClick={() => props.onHide()}>
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default Rating;
