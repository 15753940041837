import React, { useEffect, useState } from "react";
import helpIcon from "../../../include/images/help-circle.svg";
import { buyerInterestsData, getBuyerInterestList, getBuyerInterstList } from "../../../store/buyerInterests";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import AlertError from "../../../common/alerts/alertError";
import NextButton from "../../../common/form/nextButton";
import greenEmail from "../../../include/images/green-mail.svg";
import notVerified from "../../../include/images/mail-not-verified.svg";
import invalid from "../../../include/images/mail-invalid.svg";
import spamEmail from "../../../include/images/mail-spammed-blocked.svg";
import purpleEmail from "../../../include/images/mail-purple.svg";
import DateFormat from "../../../common/DateFormat/dateFormat";
import Skeleton from "react-loading-skeleton";
import BuyerInterestList from "../../../layouts/modals/buyerInterestsModal/buyerInterestList";
import {
  addMessage,
  buyerInterest,
  callEmailClicker,
  deleteMessage,
  emailClickersDetail,
  getRecipient,
  getUserDetail,
  quickEdit,
} from "../../../store/emailClickers";
import MoreModal from "../../../layouts/modals/pushEmailClickers/moreModal";
import UserContactInformationModal from "../../../layouts/modals/pushEmailClickers/userContactInformationModal";
import { getCountry, getState } from "../../../store/users";

const BuyerInterest = (props) => {
  const [showTable, setShowTable] = useState(false);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [empty, setEmpty] = useState(false);
  const [auctionId, setAuctionId] = useState("");
  const [showMoreModal, setShowMoreModal] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [recipientList, setRecipientList] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [quickEditLoading, setQuickEditLoading] = useState(false);
  const [contactInformation, setContactInformation] = useState({
    customer_name: "",
    customer_city: "",
    customer_email: "",
    customer_phone: "",
    customer_cell: "",
    linkedin_url: "",
    state_id: 0,
    country_id: 0,
  });

  useEffect(() => {
    if (showContactModal) {
      props.getCountry((res) => {
        if (res.status === 200) {
          setCountryOptions(res.data);
        }
      });
      props.getState((res) => {
        if (res.status === 200) {
          setStateOptions(res.data);
        }
      });
    }
  }, [showContactModal]);

  //ToggleMoreModal
  const toggleMoreModal = () => {
    setShowTable(!showTable);
    setShowMoreModal(!showMoreModal);
  };

  //Toggle User Contact Information Modal
  const toggleContactInformationModal = () => {
    setShowMoreModal(!showMoreModal);
    setShowContactModal(!showContactModal);
  };

  const userDetailData = props?.emailClickersDetail?.user?.data;

  const fetchUserDetail = (userId) => {
    props.refStart();
    props.getUserDetail(userId, (res) => {
      if (res.status === 200) {
        // setUserDetailData(res.data.data);
        props.getRecipient((res) => {
          if (res.status === 200) {
            setRecipientList(res.data.data);
            props.refStop();
            toggleMoreModal();
          } else {
            toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
            props.refStop();
            toggleMoreModal();
          }
        });
      } else {
        toast(<AlertError message={res.data.message ? res.data.messge : "Something Went Wrong"} />);
      }
    });
  };

  const handleCallEmailClicker = (userId) => {
    props.refStart();
    props.callEmailClicker(props.auctionId, userId, (res) => {
      if (res.status === 200) {
        props.getBuyerInterestList(props.auctionId, page, (res) => {
          if (res.status === 200) {
            props.refStop();
          } else {
            props.refStop();
            toast(<AlertError message="Something Went Wrong" />);
          }
        });
      } else {
        props.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const handleBuyingInterest = (userId) => {
    props.refStart();
    props.buyerInterest(props.auctionId, userId, (res) => {
      if (res.status === 200) {
        props.getBuyerInterestList(props.auctionId, page, (res) => {
          if (res.status === 200) {
            props.refStop();
          } else {
            props.refStop();
            toast(<AlertError message="Something Went Wrong" />);
          }
        });
      } else {
        props.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  //Quick Edit
  const quickEditHandle = (userId) => {
    props.refStart();
    setQuickEditLoading(true);
    props.quickEdit(userId, contactInformation, (res) => {
      if (res.status === 200) {
        props.getUserDetail(userId, (res) => {
          if (res.status === 200) {
            setRecipientList(res.data.data);
            props.refStop();
            setQuickEditLoading(false);
            toggleContactInformationModal();
          } else {
            props.refStop();
            setQuickEditLoading(false);
            toggleContactInformationModal();
          }
        });
      } else {
        props.refStop();
        setQuickEditLoading(false);
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const toggleShowTable = () => {
    setShowTable(!showTable);
  };
  const listingData = props?.buyerInterestsData?.buyerInterestList?.data;
  const Count = props?.buyerInterestsData?.buyerInterestList?.total_records;

  const fetchBuyerInterestList = () => {
    setLoading(true);
    props.refStart();
    props.getBuyerInterestList(props.auctionId, 1, (res) => {
      if (res.status === 200) {
        setAuctionId(props.auctionId);
        setLoading(false);
        props.refStop();
        if (res?.data?.data?.length > 0) {
          setShowTable(true);
        } else {
          setEmpty(true);
        }
      } else {
        setLoading(false);
        props.refStop();
        toast(<AlertError message={res?.data?.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };
  return (
    <div class="details-page-widget-block">
      <div class="page-widget-head d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <div class="page-widget-label text-capitalize">Buyer Interest</div>
         
        </div>
      </div>
      {props.loadingMain ? (
        <Skeleton height="239px" />
      ) : (
        <div class="page-widget-body mt-3">
          {empty ? (
            <div
              class="page-widget-empty-box text-capitalize d-flex align-items-center justify-content-center text-center"
              style={{
                height: "239px",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <span>No Records Found</span>
            </div>
          ) : (
            <div
              class="page-widget-empty-box text-capitalize d-flex align-items-center justify-content-center text-center"
              style={{
                height: "239px",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <span>
                {auctionId === props.auctionId && listingData && listingData.length > 0
                  ? "Content is hidden"
                  : "Click Here To View Buyer Interest"}
              </span>
              {auctionId === props.auctionId && listingData && listingData.length > 0 ? (
                <NextButton label="View" classData="btn btn-default" handleSubmit={toggleShowTable} loading={loading} />
              ) : (
                <NextButton
                  label="View"
                  classData="btn btn-default"
                  handleSubmit={fetchBuyerInterestList}
                  loading={loading}
                />
              )}
            </div>
          )}
        </div>
      )}
      <BuyerInterestList
        show={showTable}
        onHide={toggleShowTable}
        buyerInterestsData={props.buyerInterestsData}
        getBuyerInterestList={props.getBuyerInterestList}
        auctionId={props.auctionId}
        refStart={props.refStart}
        refStop={props.refStop}
        fetchUserDetail={fetchUserDetail}
        handleCallEmailClicker={handleCallEmailClicker}
        handleBuyingInterest={handleBuyingInterest}
      />
      <MoreModal
        show={showMoreModal}
        onHide={toggleMoreModal}
        userDetailData={userDetailData}
        recipientList={recipientList}
        refStart={props.refStart}
        refStop={props.refStop}
        addMessage={props.addMessage}
        deleteMessage={props.deleteMessage}
        getUserDetail={props.getUserDetail}
        auctionId={props.auctionId}
        toggleContactInformationModal={toggleContactInformationModal}
      />
      <UserContactInformationModal
        show={showContactModal}
        onHide={toggleContactInformationModal}
        userDetailData={userDetailData}
        contactInformation={contactInformation}
        setContactInformation={setContactInformation}
        stateOptions={stateOptions}
        countryOptions={countryOptions}
        loading={quickEditLoading}
        quickEditHandle={quickEditHandle}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getBuyerInterestList: (params, page, callback) => dispatch(getBuyerInterestList(params, page, callback)),

  getUserDetail: (userId, callback) => dispatch(getUserDetail(userId, callback)),
  getRecipient: (callback) => dispatch(getRecipient(callback)),
  getCountry: (callback) => dispatch(getCountry(callback)),
  getState: (callback) => dispatch(getState(callback)),
  addMessage: (params, data, callback) => dispatch(addMessage(params, data, callback)),
  deleteMessage: (chatId, callback) => dispatch(deleteMessage(chatId, callback)),
  quickEdit: (params, data, callback) => dispatch(quickEdit(params, data, callback)),
  callEmailClicker: (params, userId, callback) => dispatch(callEmailClicker(params, userId, callback)),
  buyerInterest: (params, userId, callback) => dispatch(buyerInterest(params, userId, callback)),
});
const mapStateToProps = (state) => ({
  buyerInterestsData: buyerInterestsData(state),
  emailClickersDetail: emailClickersDetail(state),
});
export default connect(mapStateToProps, mapDispatchToProps)(React.memo(BuyerInterest));
