import React, { useEffect, useRef, useState } from "react";
import Header from "../../layouts/header";
import { connect } from "react-redux";
import LoadingBar from "react-top-loading-bar";
import { getCommission, getCommissionData, getEmployee } from "../../store/commission";
import CommissionTable from "../../components/accounting/commission/commissionTable";
import { getProfile } from "../../utils.js/localStorageServices";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import ManufacturerAndModelTable from "../../components/settings/manufacturerAndModals/manufacturerAndModelTable";
import AddMfr from "../../components/settings/manufacturerAndModals/modals/addMfr";
import AddModel from "../../components/settings/manufacturerAndModals/modals/addModel";
import { getListings } from "../../store/listings";
import { getManufacturersFiltered, getManufacturersList, getMisc, getModelList } from "../../store/misc";
import { addMfr, addModel } from "../../store/listingProduct";
import { toast } from "react-toastify";
import AlertError from "../../common/alerts/alertError";
import AlertSuccess from "../../common/alerts/alertSuccess";

const ManufacturerAndModel = (props) => {
  const ref = useRef(null);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [loadingManufacturer, setLoadingManufacturer] = useState(false);
  const [loadingModel, setLoadingModel] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showAddMfr, setShowAddMfr] = useState(false);
  const [selectedMfr, setSelectedMfr] = useState(null);
  const [page, setPage] = useState(1);
  const [modelsPage, setModelsPage] = useState(1);
  const [value, setValue] = useState(null);
  const [modelName, setModelName] = useState(null);
  const [order, setOrder] = useState("asc");
  const [keyword, setKeyword] = useState("");

  const [filteredData, setFilteredData] = useState([]);

  const toggleAddMfr = () => {
    setShowAddMfr(!showAddMfr);
    setFilteredData([]);
  };

  const toggleAddModal = () => {
    setShowAddModal(!showAddModal);
  };

  useEffect(() => {
    ref?.current?.continuousStart();
    setLoadingManufacturer(true);
    setPage(1);
    props.getManufacturers({ page: 1, order, keyword: keyword ? keyword : "" }, (res) => {
      if (res && res.status === 200) {
        ref?.current?.complete();
        setLoadingManufacturer(false);
      } else {
        ref?.current?.complete();
        setLoadingManufacturer(false);
      }
    });
  }, [order]);
  useEffect(() => {
    ref?.current?.continuousStart();
    setLoadingManufacturer(true);
    props.getManufacturers({ page: page, order, keyword: keyword ? keyword : "" }, (res) => {
      if (res && res.status === 200) {
        ref?.current?.complete();
        setLoadingManufacturer(false);
      } else {
        ref?.current?.complete();
        setLoadingManufacturer(false);
      }
    });
  }, [page]);

  useEffect(() => {
    if (!keyword) {
      ref?.current?.continuousStart();
      setLoadingManufacturer(true);
      props.getManufacturers({ page, order }, (res) => {
        if (res && res.status === 200) {
          ref?.current?.complete();
          setLoadingManufacturer(false);
        } else {
          ref?.current?.complete();
          setLoadingManufacturer(false);
        }
      });
    }
  }, [keyword]);
  useEffect(() => {
    if (selectedMfr && (selectedMfr?.type == "view" || selectedMfr?.type == "editModel")) {
      const id = selectedMfr?.ID ? selectedMfr?.ID : selectedMfr?.item ? selectedMfr?.item.ID : "";
      ref?.current?.continuousStart();
      setLoadingModel(true);
      props.getModel({ mfr_id: id, order: "asc", sort: "model" }, (res) => {
        if (res && res.status === 200) {
          ref?.current?.complete();
          setLoadingModel(false);
        } else {
          ref?.current?.complete();
          setLoadingModel(false);
        }
      });
    }
  }, [selectedMfr, modelsPage]);

  useEffect(() => {
    if (!showAddMfr) {
      setSelectedMfr(null);
      setValue(null);
    } else {
      if (selectedMfr?.type !== "addMfr") {
        const a = selectedMfr?.item;
        setValue(a);
      }
    }
  }, [showAddMfr]);

  useEffect(() => {
    if (showAddMfr && selectedMfr?.type === "addMfr") {
      if (value?.Description) {
        props?.getManufacturersFiltered({ order: "asc", keyword: value?.Description }, (res) => {
          if (res.status === 200) {
            setFilteredData(res.data.data ? res.data.data : []);
          } else {
            setFilteredData([]);
            toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
          }
        });
      } else {
        setFilteredData([]);
      }
    }
  }, [value?.Description]);

  const mfrData = props?.getMisc?.manufacturersList?.data || [];

  const modelData = props?.getMisc?.modelList?.data || [];

  const totalCount = props?.getMisc?.manufacturersList?.total_records || 0;

  const filteredCount = props?.getMisc?.modelList?.per_page || 0;

  const totalPages = Math.ceil(totalCount / 20);

  const handleFilterMfr = () => {
    ref?.current?.continuousStart();
    setLoadingManufacturer(true);
    props.getManufacturers({ page: 1, order, keyword: value?.Description }, (res) => {
      if (res && res.status === 200) {
        setPage(1);
        setKeyword(value?.Description);
        ref?.current?.complete();
        setLoadingManufacturer(false);
        toggleAddMfr();
      } else {
        ref?.current?.complete();
        setLoadingManufacturer(false);
      }
    });
  };

  const handleAddMfr = () => {
    setLoading(true);
    ref?.current?.continuousStart();
    const payload = {
      description: value?.Description?.replace("/t", "")?.replace(" ", ""),
      mfrId: value?.ID ? `${value?.ID}` : "",
    };
    props?.addMfr(payload, (res) => {
      if (res && res?.status == 200) {
        props.getManufacturers({ page, order }, (res) => {
          if (res && res.status === 200) {
            ref?.current?.complete();
            setKeyword("");
            setLoading(false);
            ref?.current?.complete();
            toggleAddMfr();
            toast(<AlertSuccess message={"Information Saved"} />);
          } else {
            setLoading(false);
            ref?.current?.complete();
            toast(<AlertError message={res?.data?.message ? res?.data?.message : "Something went wrong"} />);
          }
        });
      } else {
        setLoading(false);
        ref?.current?.complete();
        toast(<AlertError message={res?.data?.message ? res?.data?.message : "Something went wrong"} />);
      }
    });
  };

  return (
    <>
      <LoadingBar height={5} color="#47AD1D" ref={ref} />
      <Header />

      <ManufacturerAndModelTable
        toggleAddMfr={toggleAddMfr}
        toggleAddModal={toggleAddModal}
        mfrData={mfrData}
        selectedMfr={selectedMfr}
        setSelectedMfr={setSelectedMfr}
        page={page}
        setPage={setPage}
        filteredCount={filteredCount}
        totalPages={totalPages}
        loading={loadingManufacturer}
        order={order}
        setOrder={setOrder}
        setKeyword={setKeyword}
        keyword={keyword}
        value={value}
        setValue={setValue}
        totalCount={totalCount}
      />
      <AddMfr
        show={showAddMfr}
        onHide={toggleAddMfr}
        data={value}
        filteredData={filteredData}
        setData={setValue}
        handleAddMfr={handleAddMfr}
        type={selectedMfr?.type}
        loading={loading}
        loadingFilter={loadingManufacturer}
        handleFilterMfr={handleFilterMfr}
      />
      <AddModel
        show={showAddModal}
        onHide={toggleAddModal}
        mfr={selectedMfr}
        data={modelData}
        loading={loadingModel}
        loadingAdd={loading}
        setValue={setValue}
        value={value}
        setModelName={setModelName}
        modelName={modelName}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getManufacturers: (data, callback) => dispatch(getManufacturersList(data, callback)),
  getManufacturersFiltered: (data, callback) => dispatch(getManufacturersFiltered(data, callback)),
  getModel: (data, callback) => dispatch(getModelList(data, callback)),
  addMfr: (data, callback) => dispatch(addMfr(data, callback)),
  addModel: (data, callback) => dispatch(addModel(data, callback)),
});

const mapStateToProps = (state) => ({
  getListings: getListings(state),
  getMisc: getMisc(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(ManufacturerAndModel));
