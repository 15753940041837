import React from "react";
import { Offcanvas } from "react-bootstrap";

const FilterListingAgreements = (props) => {
  return (
    <Offcanvas
      show={props.show}
      onHide={() => props.onHide()}
      placement="end"
      className="offcanvas offcanvas-end custom-offcanvas-sm "
      backdropClassName="upload-images-backdrop"
      tabindex="-1"
      id="userFilter"
      enforceFocus={false}
    >
      <Offcanvas.Header className="offcanvas-header aviation-filter-fix">
        <h5 class="offcanvas-title">Filters</h5>
        <button type="button" className="btn-close text-reset" onClick={() => props.onHide()}></button>
      </Offcanvas.Header>
      <Offcanvas.Body className="offcanvas-body co-body">
        <div class="form-group">
          <label for="keyword" class="form-label">
            Listing ID
          </label>
          <input
            type="number"
            class="form-control"
            id="auction-search"
            value={props.auctionId}
            onChange={(e) => {
              props.setAuctionId(e.target.value);
            }}
          />
        </div>
        <div class="form-group">
          <label for="keyword" class="form-label">
            Keyword
          </label>
          <input
            type="text"
            class="form-control"
            id="auction-search"
            value={props.keyword}
            onChange={(e) => {
              props.setKeyword(e.target.value);
            }}
          />
        </div>
      </Offcanvas.Body>
      <div class="offcanvas-footer">
        <a
          href="javascript:void(0)"
          class={`btn btn-default btn-block ${props.loading && "disabled"}`}
          onClick={() => props.handleFilter()}
        >
          Apply Filters
        </a>
      </div>
    </Offcanvas>
  );
};

export default FilterListingAgreements;
