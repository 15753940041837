import React from "react";
import { Offcanvas } from "react-bootstrap";
import Select from "react-select";
import { customStyles } from "../../../common/customStyles/reactSelectStyle";
const OrderRequestFilter = (props) => {
  return (
    <Offcanvas
      show={props.show}
      onHide={() => props.onHide()}
      placement="end"
      className="offcanvas offcanvas-end custom-offcanvas-sm "
      backdropClassName="upload-images-backdrop"
      tabindex="-1"
      id="userFilter"
      enforceFocus={false}
    >
      <Offcanvas.Header className="offcanvas-header aviation-filter-fix">
        <h5 class="offcanvas-title">Filters</h5>
        <button type="button" className="btn-close text-reset" onClick={() => props.onHide()}></button>
      </Offcanvas.Header>
      <Offcanvas.Body className="offcanvas-body co-body">
        {/* <div class="form-group">
          <label for="keyword" class="form-label">
            User Id
          </label>
          <input
            type="text"
            class="form-control"
            id="auction-search"
            value={props.filteredValues?.userId}
            onChange={(e) => {
              const data = { ...props.filteredValues };
              data.userId = e.target.value;
              props.setFilteredValues(data);
            }}
          />
        </div> */}
        {/* <div class="form-group">
          <label for="keyword" class="form-label">
            Keyword
          </label>
          <Select
            value={props.filteredValues?.keyword}
            options={[{ name: "Select Part of the Plane", value: "-1" }, ...props.part]}
            className="basic-single country-margin select-background "
            classNamePrefix="select-search country-margin select-background"
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.value}
            onChange={(e) => {
              const data = { ...props.filteredValues };
              data.aviationPart = e;
              props.setFilteredValues(data);
            }}
            name="planePart"
            placeholder="Select Part of the Plane"
            styles={customStyles}
          />
        </div> */}
        <div class="form-group">
          <label for="keyword" class="form-label">
            Keyword
          </label>
          <input
            type="text"
            class="form-control"
            id="auction-search"
            value={props.filteredValues?.keyword}
            onChange={(e) => {
              const data = { ...props.filteredValues };
              data.keyword = e.target.value;
              props.setFilteredValues(data);
            }}
          />
        </div>
      </Offcanvas.Body>
      <div class="offcanvas-footer">
        <a
          href="javascript:void(0)"
          class={`btn btn-default btn-block ${props.loadingFilter && "disabled"}`}
          onClick={() => props.handleFilter()}
        >
          Apply Filters
        </a>
      </div>
    </Offcanvas>
  );
};

export default React.memo(OrderRequestFilter);
