import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import close from "../../../include/images/close.svg";
import DateFormat from "../../../common/DateFormat/dateFormat";
import EditBuyerMotivationModal from "./editBuyerMotivationModal";

const BuyerMotivationModal = (props) => {
  const data = props.data;
  return (
    <Modal
      className="modal fade custom-modal"
      id="sellerMotivation"
      show={props.show}
      onHide={() => props.onHide()}
      dialogClassName="modal-dialog modal-dialog-centered modal-md"
    >
      <div class="modal-content h-100 d-flex flex-column">
        <div class="custom-modal-header d-flex align-items-center justify-content-between">
          <h5 class="mb-0 text-capitalize">Buyer Motivation</h5>
          <button
            className="ms-4 mb-2 btn btn-primary btn-xs-size btn-auto"
            onClick={props.toggle}
          >
            Edit
          </button>
        </div>
        <Modal.Body className="custom-modal-body flex-grow-1 hide-scrollbar padding-bottom-0">
          <table class="table modal-product-detail-table mb-0">
            <colgroup>
              <col style={{ width: "50%" }} />
              <col style={{ width: "50%" }} />
            </colgroup>
            <tr>
              <td className="fw-medium">Buyer Interest:</td>
              <td>
                {
                  data?.motivation?.find(
                    (item) => item.type === "Buying_Interest"
                  )?.motivation
                }
              </td>
            </tr>

            <tr>
              <td>
                {data?.motivation?.find((item) => item.type === "checkbox_01")
                  ?.motivation == 1 && (
                  <div class="form-check d-flex align-items-center">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="sentEmail"
                      value=""
                      checked
                    />
                    <label class="form-check-label fw-medium"></label>
                    <span className="ms-4">Don't Know</span>
                  </div>
                )}
                {data?.status === "purchased" && (
                  <div class="form-check d-flex align-items-center">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="sentEmail"
                      value=""
                      checked
                    />
                    <label class="form-check-label fw-medium"></label>
                    <span className="ms-4">Purchased</span>
                  </div>
                )}
                {data?.status === "bid" && (
                  <div class="form-check d-flex align-items-center">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="sentEmail"
                      value=""
                      checked
                    />
                    <label class="form-check-label fw-medium"></label>
                    <span className="ms-4">Bid</span>
                  </div>
                )}
                {data?.status === "other" && (
                  <div class="form-check d-flex align-items-center">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="sentEmail"
                      value=""
                      checked
                    />
                    <label class="form-check-label fw-medium"></label>
                    <span className="ms-4">Other Buyer Interest </span>
                  </div>
                )}
                {data?.motivation?.find((item) => item.type === "checkbox_05")
                  ?.motivation == 1 && (
                  <div class="form-check d-flex align-items-center">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="sentEmail"
                      value=""
                      checked
                    />
                    <label class="form-check-label fw-medium"></label>
                    <span className="ms-4">Interested in Surplus/Salvage</span>
                  </div>
                )}
                {data?.motivation?.find((item) => item.type === "checkbox_06")
                  ?.motivation == 1 && (
                  <div class="form-check d-flex align-items-center">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="sentEmail"
                      value=""
                      checked
                    />
                    <label class="form-check-label fw-medium"></label>
                    <span className="ms-4">No Interest in Buying</span>
                  </div>
                )}
                {data?.motivation?.find((item) => item.type === "checkbox_07")
                  ?.motivation == 1 && (
                  <div class="form-check d-flex align-items-center">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="sentEmail"
                      value=""
                      checked
                    />
                    <label class="form-check-label fw-medium"></label>
                    <span className="ms-4">Bad Salvex Experience</span>
                  </div>
                )}
              </td>
            </tr>
          </table>
          <div className="dividerTable"></div>

          <table class="table modal-product-detail-table mb-0">
            <tr>
              <td className="fw-medium">Control of Purchase:</td>
              <td>
                {
                  data?.motivation?.find(
                    (item) => item.type === "Purchase_Reason"
                  )?.motivation
                }
              </td>
            </tr>
            <tr>
              <td>
                {data?.motivation?.find((item) => item.type === "checkbox_018")
                  ?.motivation == 1 && (
                  <div class="form-check d-flex align-items-center">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      name="sentEmail"
                      value=""
                      checked
                    />
                    <label class="form-check-label fw-medium"></label>
                    <span className="ms-4">Don't Know</span>
                  </div>
                )}
                {data?.motivation?.find((item) => item.type === "checkbox_019")
                  ?.motivation == 1 && (
                  <div class="form-check d-flex align-items-center">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      name="sentEmail"
                      value=""
                      checked
                    />
                    <label class="form-check-label fw-medium"></label>
                    <span className="ms-4">Ownership (Operations Use)</span>
                  </div>
                )}
                {data?.motivation?.find((item) => item.type === "checkbox_020")
                  ?.motivation == 1 && (
                  <div class="form-check d-flex align-items-center">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      name="sentEmail"
                      value=""
                      checked
                    />
                    <label class="form-check-label fw-medium"></label>
                    <span className="ms-4">Ownership (Inventory)</span>
                  </div>
                )}
                {data?.motivation?.find((item) => item.type === "checkbox_021")
                  ?.motivation == 1 && (
                  <div class="form-check d-flex align-items-center">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      name="sentEmail"
                      value=""
                      checked
                    />
                    <label class="form-check-label fw-medium"></label>
                    <span className="ms-4">Ownership (Reseller)</span>
                  </div>
                )}
                {data?.motivation?.find((item) => item.type === "checkbox_022")
                  ?.motivation == 1 && (
                  <div class="form-check d-flex align-items-center">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      name="sentEmail"
                      value=""
                      checked
                    />
                    <label class="form-check-label fw-medium"></label>
                    <span className="ms-4">Non-Ownership (Buyer Broker) </span>
                  </div>
                )}
                {data?.motivation?.find((item) => item.type === "checkbox_023")
                  ?.motivation == 1 && (
                  <div class="form-check d-flex align-items-center">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      name="sentEmail"
                      value=""
                      checked
                    />
                    <label class="form-check-label fw-medium"></label>
                    <span className="ms-4">Non-Ownership (RFI/RFQ) </span>
                  </div>
                )}
                {data?.motivation?.find((item) => item.type === "checkbox_041")
                  ?.motivation == 1 && (
                  <div class="form-check d-flex align-items-center">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      name="sentEmail"
                      value=""
                      checked
                    />
                    <label class="form-check-label fw-medium"></label>
                    <span className="ms-4">
                      Non-Ownership (Contract with End User){" "}
                    </span>
                  </div>
                )}
              </td>
            </tr>
          </table>
            <div className="dividerTable"></div>
          <table class="table modal-product-detail-table mb-0">
            <tr>
              <td className="fw-medium">Timeline for Purchase:</td>
              <td>
                {
                  data?.motivation?.find(
                    (item) => item.type === "Timeline_for_Purchase"
                  )?.motivation
                }
              </td>
            </tr>
          </table>
          <div className="dividerTable"></div>
          <table class="table modal-product-detail-table mb-0">
            <tr>
              <td className="fw-medium">Product Condition:</td>
              <td>
                {
                  data?.motivation?.find(
                    (item) => item.type === "Product_Condition"
                  )?.motivation
                }
              </td>
            </tr>
            <tr>
              <td>
                {data?.motivation?.find((item) => item.type === "checkbox_08")
                  ?.motivation == 1 && (
                  <div class="form-check d-flex align-items-center">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      name="sentEmail"
                      value=""
                      checked
                    />
                    <label class="form-check-label fw-medium"></label>
                    <span className="ms-4">Prime</span>
                  </div>
                )}
                {data?.motivation?.find((item) => item.type === "checkbox_09")
                  ?.motivation == 1 && (
                  <div class="form-check d-flex align-items-center">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      name="sentEmail"
                      value=""
                      checked
                    />
                    <label class="form-check-label fw-medium"></label>
                    <span className="ms-4">Unused</span>
                  </div>
                )}
                {data?.motivation?.find((item) => item.type === "checkbox_010")
                  ?.motivation == 1 && (
                  <div class="form-check d-flex align-items-center">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      name="sentEmail"
                      value=""
                      checked
                    />
                    <label class="form-check-label fw-medium"></label>
                    <span className="ms-4">Used</span>
                  </div>
                )}
                {data?.motivation?.find((item) => item.type === "checkbox_011")
                  ?.motivation == 1 && (
                  <div class="form-check d-flex align-items-center">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      name="sentEmail"
                      value=""
                      checked
                    />
                    <label class="form-check-label fw-medium"></label>
                    <span className="ms-4">Damaged/Defect</span>
                  </div>
                )}
                {data?.motivation?.find((item) => item.type === "checkbox_012")
                  ?.motivation == 1 && (
                  <div class="form-check d-flex align-items-center">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      name="sentEmail"
                      value=""
                      checked
                    />
                    <label class="form-check-label fw-medium"></label>
                    <span className="ms-4">Scrap</span>
                  </div>
                )}
              </td>
            </tr>
          </table>
          <div className="dividerTable"></div>
          <table class="table modal-product-detail-table mb-0">
            <tr>
              <td className="fw-medium">Fund Transfer Preference:</td>
              <td>
                {
                  data?.motivation?.find(
                    (item) => item.type === "Fund_Transfer_Preference"
                  )?.motivation
                }
              </td>
            </tr>
            <tr>
              <td>
                {data?.motivation?.find((item) => item.type === "checkbox_024")
                  ?.motivation == "1" && (
                  <div class="form-check d-flex align-items-center">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      name="sentEmail"
                      value=""
                      checked
                    />
                    <label class="form-check-label fw-medium"></label>
                    <span className="ms-4">Don't Know</span>
                  </div>
                )}
                {data?.motivation?.find((item) => item.type === "checkbox_025")
                  ?.motivation == 1 && (
                  <div class="form-check d-flex align-items-center">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      name="sentEmail"
                      value=""
                    />
                    <label class="form-check-label fw-medium"></label>
                    <span className="ms-4">Wire Transfer</span>
                  </div>
                )}
                {data?.motivation?.find((item) => item.type === "checkbox_026")
                  ?.motivation == 1 && (
                  <div class="form-check d-flex align-items-center">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      name="sentEmail"
                      value=""
                    />
                    <label class="form-check-label fw-medium"></label>
                    <span className="ms-4">Net Payment Terms</span>
                  </div>
                )}
                {data?.motivation?.find((item) => item.type === "checkbox_027")
                  ?.motivation == 1 && (
                  <div class="form-check d-flex align-items-center">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      name="sentEmail"
                      value=""
                    />
                    <label class="form-check-label fw-medium"></label>
                    <span className="ms-4">Payment Upon Delivery </span>
                  </div>
                )}
                {data?.motivation?.find((item) => item.type === "checkbox_028")
                  ?.motivation == 1 && (
                  <div class="form-check d-flex align-items-center">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      name="sentEmail"
                      value=""
                    />
                    <label class="form-check-label fw-medium"></label>
                    <span className="ms-4">Letter of Credit </span>
                  </div>
                )}
              </td>
            </tr>
          </table>
          <div className="dividerTable"></div>
          <table class="table modal-product-detail-table mb-0">
            <tr>
              <td className="fw-medium">Max Budget Given:</td>
              <td>
                {
                  data?.motivation?.find(
                    (item) => item.type === "Max_Budget_Given"
                  )?.motivation
                }
              </td>
            </tr>
            <tr>
              <td>
                {data?.maxbudget == "" ||
                  (data?.maxbudget == null && (
                    <div class="form-check d-flex align-items-center">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        name="sentEmail"
                        value=""
                        checked
                      />
                      <label class="form-check-label fw-medium"></label>
                      <span className="ms-4">$0</span>
                    </div>
                  ))}
                {data.maxbudget &&
                  data?.maxbudget >= "0" &&
                  data?.maxbudget <= "20000" && (
                    <div class="form-check d-flex align-items-center">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        name="sentEmail"
                        value=""
                        checked
                      />
                      <label class="form-check-label fw-medium"></label>
                      <span className="ms-4">$0-$20,000</span>
                    </div>
                  )}
                {data.maxbudget &&
                  data?.maxbudget >= "20000" &&
                  data?.maxbudget <= "100000" && (
                    <div class="form-check d-flex align-items-center">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        name="sentEmail"
                        value=""
                        checked
                      />
                      <label class="form-check-label fw-medium"></label>
                      <span className="ms-4">$20,000-$100,000</span>
                    </div>
                  )}
                {data.maxbudget &&
                  data?.maxbudget >= "100000" &&
                  data?.maxbudget <= "500000" && (
                    <div class="form-check d-flex align-items-center">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        name="sentEmail"
                        value=""
                        checked
                      />
                      <label class="form-check-label fw-medium"></label>
                      <span className="ms-4">$100,000-$500,000</span>
                    </div>
                  )}
                {data.maxbudget &&
                  data?.maxbudget >= "500000" &&
                  data?.maxbudget <= "1000000" && (
                    <div class="form-check d-flex align-items-center">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        name="sentEmail"
                        value=""
                        checked
                      />
                      <label class="form-check-label fw-medium"></label>
                      <span className="ms-4">$500,000- $1m </span>
                    </div>
                  )}
                {data.maxbudget && data?.maxbudget > "1000000" && (
                  <div class="form-check d-flex align-items-center">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      name="sentEmail"
                      value=""
                      checked
                    />
                    <label class="form-check-label fw-medium"></label>
                    <span className="ms-4">$1m+</span>
                  </div>
                )}
              </td>
            </tr>
          </table>
          <div className="dividerTable"></div>
          <table class="table modal-product-detail-table mb-0">
            <tr>
              <td className="fw-medium">Cumulative Sales:</td>
              <td>
                {
                  data?.motivation?.find(
                    (item) => item.type === "Cumulative_Sales"
                  )?.motivation
                }
              </td>
            </tr>
            <tr>
              <td>
                {data?.maxcumulative == "" ||
                  (data?.maxcumulative == null && (
                    <div class="form-check d-flex align-items-center">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        name="sentEmail"
                        value=""
                        checked
                      />
                      <label class="form-check-label fw-medium"></label>
                      <span className="ms-4">$0</span>
                    </div>
                  ))}
                {data?.maxcumulative &&
                  data?.maxcumulative >= "0" &&
                  data?.maxcumulative <= "20000" && (
                    <div class="form-check d-flex align-items-center">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        name="sentEmail"
                        value=""
                        checked
                      />
                      <label class="form-check-label fw-medium"></label>
                      <span className="ms-4">$0-$20,000</span>
                    </div>
                  )}
                {data?.maxcumulative &&
                  data?.maxcumulative >= "20000" &&
                  data?.maxcumulative <= "100000" && (
                    <div class="form-check d-flex align-items-center">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        name="sentEmail"
                        value=""
                        checked
                      />
                      <label class="form-check-label fw-medium"></label>
                      <span className="ms-4">$20,000-$100,000</span>
                    </div>
                  )}
                {data?.maxcumulative &&
                  data?.maxcumulative >= "100000" &&
                  data?.maxcumulative <= "500000" && (
                    <div class="form-check d-flex align-items-center">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        name="sentEmail"
                        value=""
                        checked
                      />
                      <label class="form-check-label fw-medium"></label>
                      <span className="ms-4">$100,000-$500,000</span>
                    </div>
                  )}
                {data?.maxcumulative &&
                  data?.maxcumulative >= "500000" &&
                  data?.maxcumulative <= "1000000" && (
                    <div class="form-check d-flex align-items-center">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        name="sentEmail"
                        value=""
                        checked
                      />
                      <label class="form-check-label fw-medium"></label>
                      <span className="ms-4">$500,000- $1m </span>
                    </div>
                  )}
                {data?.maxcumulative && data?.maxcumulative > "1000000" && (
                  <div class="form-check d-flex align-items-center">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      name="sentEmail"
                      value=""
                      checked
                    />
                    <label class="form-check-label fw-medium"></label>
                    <span className="ms-4">$1m+</span>
                  </div>
                )}
              </td>
            </tr>
          </table>
          <div className="dividerTable"></div>
          <table class="table modal-product-detail-table mb-0">
            <tr>
              <td className="fw-medium">Buyer Communications:</td>
              <td>
                {
                  data?.motivation?.find(
                    (item) => item.type === "Buyer_Communications"
                  )?.motivation
                }
              </td>
            </tr>
          </table>
          <div className="dividerTable"></div>
          <table class="table modal-product-detail-table mb-0">
            <tr>
              <td className="fw-medium">Other Information:</td>
              <td>
                {
                  data?.motivation?.find(
                    (item) => item.type === "Buyer_Motivation_Other_Information"
                  )?.motivation
                }
              </td>
            </tr>
          </table>
          <div className="dividerTable"></div>
          <table class="table modal-product-detail-table mb-0">
            <tr>
              <td>Last Updated By</td>
              <td>{data?.buyerData?.completedby}</td>
            </tr>
          </table>
          <div className="dividerTable"></div>
          <table class="table modal-product-detail-table mb-0">
            <tr>
              <td>Last Updated Date</td>
              <td>
                <DateFormat date={data?.buyerData?.updated_at} />
              </td>
            </tr>
          </table>
          <div className="dividerTable"></div>
          <div className="fw-medium">Previous Seller Motivation Updates:</div>
          <table class="table modal-product-detail-table mb-0 mt-2">
            {data?.buyer?.map((val) => (
              <tr>
                <td>
                  <DateFormat data={val?.updatedAt} />
                </td>
                <td>{val?.updateBy}</td>
                <td>more</td>
              </tr>
            ))}
          </table>
        </Modal.Body>
        <div class="custom-modal-dividor"></div>
        <div className="custom-modal-footer d-grid gap-2 d-md-flex align-items-center justify-content-md-end">
          <button
            class="btn btn-link me-md-2 global-cancel-button"
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => props.onHide()}
          >
            Cancel
          </button>
          {/* <NextButton
            label="Save"
            classData="btn btn-default"
            // handleSubmit={props.handleSubmit}
            // loading={props.loadingQuickSummary}
          /> */}
        </div>
      </div>
    </Modal>
  );
};

export default BuyerMotivationModal;
