import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";

import { toast } from "react-toastify";
import AlertError from "../../../common/alerts/alertError";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import {
  addNewOptimization,
  getBidGrpah,
  getOptimizationList,
  getOptimizationSiteDetails,
  getOptimizationSites,
} from "../../../store/optimizationSites";
import Chart from "chart.js/auto";
import { Chart as ChartJs, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from "chart.js";
import OptimizationSiteModal from "../../../layouts/modals/optimizationSitesModal/optimizationSiteModal";
import { Line } from "react-chartjs-2";
import _ from "lodash";
ChartJs.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);
Chart.register(CategoryScale);

const OptimizationSites = (props) => {
  const [showOptSite, setShowOptSite] = useState(false);
  const [loadingOptimization, setLoadingOptimization] = useState(false);
  const [selectSites, setSelectedSites] = useState([]);
  const [show, setShow] = useState(false);
  const [showGraph, setShowGraph] = useState(false);

  const toggleOptSiteModal = () => {
    setShowOptSite(!showOptSite);
  };

  //Show Optimization Site
  const showOptimizationSites = () => {
    props.refStart();
    props.getOptimizationSiteDetails(props.auctionId, (res) => {
      if (res.status === 200) {
        props.refStop();
        setShow(true);
      } else {
        props.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const optSitesList =
    (props.getListingProductDetail &&
      props.getListingProductDetail.siteList &&
      props.getListingProductDetail.siteList.data) ||
    [];

  useEffect(() => {
    if (showOptSite) {
      const id = props.auctionId;
      props.getOptimizationList(id, (res) => {
        if (res && res.status === 200) {
        }
      });
      props.getBidGrpah(id, (res) => {
        if (res && res.status === 200) {
        }
      });
    }
  }, [showOptSite]);

  useEffect(() => {
    if (props.auctionId) {
      const id = props.auctionId;

      props.getBidGrpah(id, (res) => {
        if (res && res.status === 200) {
        }
      });
    }
  }, [props.auctionId]);

  const handleSubmit = () => {
    setLoadingOptimization(true);
    props.refStart();
    const id = props.auctionId;
    const data = {
      opt_site_id: selectSites,
    };
    props.addNewOptimization(id, data, (res) => {
      if (res.status === 200) {
        props.getOptimizationSiteDetails(props.auctionId, (res) => {
          if (res.status === 200) {
            setLoadingOptimization(false);
            toggleOptSiteModal();
            props.refStop();
            toast(<AlertSuccess message={"Information Saved"} />);
          } else {
            setLoadingOptimization(false);
            props.refStop();
            toast(
              <AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />,
            );
          }
        });
      } else {
        setLoadingOptimization(false);
        props.refStop();
        toast(<AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const graphData =
    props.getListingProductDetail && props.getListingProductDetail.bidData && props.getListingProductDetail.bidData;

  const monthData = graphData && graphData?.bids?.map((item) => item.date);
  const bidAmount = graphData && graphData?.bids?.map((item) => item.bidAmount);
  const reserveAmount = graphData && graphData?.bids?.map((item) => item.reserveAmount);

  const optSitesSelected =
    (props.getListingProductDetail &&
      props.getListingProductDetail.optimizationSite &&
      props.getListingProductDetail.optimizationSite.data &&
      props.getListingProductDetail.optimizationSite.data.list) ||
    [];

  const isPlanned =
    (props.getListingProductDetail &&
      props.getListingProductDetail.optimizationSite &&
      props.getListingProductDetail.optimizationSite.data &&
      props.getListingProductDetail.optimizationSite.data.planned) ||
    false;

  useEffect(() => {
    if (
      props.getListingProductDetail &&
      props.getListingProductDetail.optimizationSite &&
      props.getListingProductDetail.optimizationSite.data &&
      props.getListingProductDetail.optimizationSite.data.list
    ) {
      const a = optSitesSelected && optSitesSelected.length ? optSitesSelected.map((item) => item.id) : [];
      setSelectedSites(a);
    }
  }, [
    props.getListingProductDetail &&
      props.getListingProductDetail.optimizationSite &&
      props.getListingProductDetail.optimizationSite.data,
  ]);

  return (
    <>
      {/* <div class="details-page-widget-block" id={props.id}>
        <div class="page-widget-head d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <div class="d-flex align-items-center">
              <div
                class={
                  show && !isPlanned
                    ? "page-widget-label text-capitalize cursor-pointer"
                    : "page-widget-label text-capitalize cursor-pointer"
                }
                onClick={() => {
                  if (show && !isPlanned) {
                    toggleOptSiteModal();
                  } else if (!show) {
                    showOptimizationSites();
                  } else {
                    setShow(false);
                  }
                }}
              >{` Optimization Sites ${isPlanned ? "(Planned)" : ""} ${
                !props.loadingMain && optSitesSelected && optSitesSelected.length > 0
                  ? show
                    ? ""
                    : // ? `(${optSitesSelected.length})` -- Uncomment if needed
                      ""
                  : show
                  ? "(0)"
                  : ""
              }`}</div>
            </div>
            <li
              className="badge-item cursor-pointer"
              onClick={() => (show ? setShow(false) : showOptimizationSites())}
              style={{ marginLeft: "10px" }}
            >
              <div className="badge-box" style={{ background: "#DADEE0" }}>
                {show ? "Hide" : "Show"}
              </div>
            </li>
          </div>
         
        </div>
        {show && !props.loadingMain && optSitesSelected && optSitesSelected.length > 0 ? (
          <div className="page-widget-body mt-3">
            <div className="page-widget-label text-capitalize mb-3">
            </div>
            <div className="table-responsive  table-custom">
              <table className="table table-bordered">
                <colgroup>
                  <col style={{ width: "15%" }} />
                  <col />
                  <col />
                  <col />
                </colgroup>
                <thead>
                  <tr>
                    <th>Site</th>
                    <th>Buyers</th>
                    <th>Bought</th>
                  </tr>
                </thead>
                <tbody>
                  {props.loadingMain ? (
                    <>
                      <tr>
                        <td>
                          <Skeleton />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Skeleton />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Skeleton />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Skeleton />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Skeleton />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Skeleton />
                        </td>
                      </tr>
                    </>
                  ) : (
                    <>
                      {optSitesSelected &&
                        optSitesSelected.length > 0 &&
                        optSitesSelected.map((val) => {
                          return (
                            <>
                              {(val.buyers_all != 0 || val.buyers_bought != 0) && (
                                <tr>
                                  
                                  <td>{val.site_name}</td>
                                  <td>{val?.buyers_all}</td>
                                  <td>{val?.buyers_bought}</td>
                                </tr>
                              )}
                            </>
                          );
                        })}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          ""
        )}

        <OptimizationSiteModal
          show={showOptSite}
          onHide={toggleOptSiteModal}
          selectSites={selectSites}
          setSelectedSites={setSelectedSites}
          loading={loadingOptimization}
          optimizationList={optSitesList}
          handleSubmit={handleSubmit}
          isPlanned={isPlanned}
        />
      </div> */}
      {(graphData?.bids?.length > 0 && graphData?.reserve_price > 0) ||
      (graphData?.bids?.length > 0 && graphData?.hb > 0) ? (
        <div class="details-page-widget-block">
          <div class="d-flex align-items-center">
            <div class="d-flex align-items-center">
              <div class="page-widget-label text-capitalize cursor-pointer" onClick={() => setShowGraph(!showGraph)}>
                {graphData?.bids?.length > 0 && graphData?.reserve_price > 0
                  ? "Bid Graph Over Time"
                  : graphData?.bids?.length > 0 && graphData?.hb > 0 && "High Bid History"}
              </div>
            </div>
            <li
              className="badge-item cursor-pointer"
              onClick={() => setShowGraph(!showGraph)}
              style={{ marginLeft: "10px" }}
            >
              <div className="badge-box" style={{ background: "#DADEE0" }}>
                {showGraph ? "Hide" : "Show"}
              </div>
            </li>
          </div>
          {showGraph && graphData?.bids?.length > 0 && graphData?.reserve_price > 0 ? (
            <div className="page-widget-body" style={{ marginTop: "50px" }}>
              <div className="page-widget-label text-capitalize mb-3">
                {props?.loadingMain ? (
                  <Skeleton height="250px" />
                ) : (
                  <Line
                    data={{
                      labels: monthData,
                      datasets: [
                        {
                          label: "High Bid Price",
                          data: bidAmount,
                          fill: false,
                          borderColor: "rgb(209, 79, 94)",
                          // tension: 0.5,
                          backgroundColor: [
                            "rgba(255, 255, 255, 0.6)",
                            "rgba(255, 255, 255, 0.6)",
                            "rgba(255, 255, 255, 0.6)",
                          ],
                          borderWidth: 3,
                        },
                        {
                          label: "Seller Asking Price",
                          data: reserveAmount,
                          fill: false,
                          borderColor: "rgb(13, 14, 14)",
                          // tension: 0.5,
                          backgroundColor: [
                            "rgba(255, 255, 255, 0.6)",
                            "rgba(255, 255, 255, 0.6)",
                            "rgba(255, 255, 255, 0.6)",
                          ],
                          borderWidth: 3,
                        },
                      ],
                      options: {
                        scales: {
                          y: {
                            title: {
                              display: true,
                              text: "Value",
                            },

                            ticks: {
                              // forces step size to be 50 units
                              stepSize: 0.5,
                            },
                          },
                        },
                      },
                    }}
                  />
                )}
              </div>
            </div>
          ) : (
            showGraph &&
            graphData?.bids?.length > 0 &&
            graphData?.hb > 0 && (
              <div className="page-widget-body" style={{ marginTop: "50px" }}>
                <div className="page-widget-label text-capitalize mb-3">
                  {props?.loadingMain ? (
                    <Skeleton height="250px" />
                  ) : (
                    <Line
                      data={{
                        labels: monthData,
                        datasets: [
                          {
                            label: "High Bid Price",
                            data: bidAmount,
                            fill: false,
                            borderColor: "rgb(209, 79, 94)",
                            // tension: 0.5,
                            backgroundColor: [
                              "rgba(255, 255, 255, 0.6)",
                              "rgba(255, 255, 255, 0.6)",
                              "rgba(255, 255, 255, 0.6)",
                            ],
                            borderWidth: 3,
                          },
                        ],
                        options: {
                          scales: {
                            y: {
                              title: {
                                display: true,
                                text: "Value",
                              },

                              ticks: {
                                // forces step size to be 50 units
                                stepSize: 0.5,
                              },
                            },
                          },
                        },
                      }}
                    />
                  )}
                </div>
              </div>
            )
          )}
        </div>
      ) : (
        ""
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getOptimizationSiteDetails: (params, callback) => dispatch(getOptimizationSiteDetails(params, callback)),
  getOptimizationList: (params, callback) => dispatch(getOptimizationList(params, callback)),
  getBidGrpah: (params, callback) => dispatch(getBidGrpah(params, callback)),
  addNewOptimization: (params, data, callback) => dispatch(addNewOptimization(params, data, callback)),
});

const mapStateToProps = (state) => ({
  getListingProductDetail: getOptimizationSites(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(OptimizationSites));
