import HTMLReactParser from "html-react-parser";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import AlertError from "../../../common/alerts/alertError";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import DateFormat from "../../../common/DateFormat/dateFormat";
import MoreDetailsModal from "../../../layouts/modals/moreDetailsModal";
import ImageLargeModal from "../../../layouts/modals/imageGalleryModal";
import SellerMotivationModal from "../../../layouts/modals/sellerMotivationModal.jsx/sellerMotivationModal";
import {
  getListingProductDetail,
  getMoreDetails,
  moreDetailsReceived,
  updateMoreDetails,
} from "../../../store/listingProduct";
import edit from "../../../include/images/edit-18x18.svg";
import {
  detailListing,
  getListings,
  getSellerMotivation,
  updateQuickSummary,
  updateTitle,
} from "../../../store/listings";
import { getAuctionStatus, getAuctionType, getProjects } from "../../../store/misc";
import _ from "lodash";
import ListingTitle from "../../../layouts/modals/listingTitle/listingTitle";
import DeleteModal from "../../../layouts/modals/deleteModalPics";
import UploadImages from "../../../layouts/modals/uploadImages";

const ListingOverview = ({
  refStart,
  refStop,
  loadingMain,
  auctionId,
  listingDetail,
  getMediaData,
  componentRef2,
  primaryImage,
  setSelectedImage,
  setStartIndex,
  handlePrimaryPicture,
  popoverClick,
  setOptionType,
  optionType,
  loadingTitleUpdate,
  setTitle,
  setOptionTypeId,
  setUploadImageModal,
  newListingImages,
  toggleImageGalleryModal,
  uploadImageModal,
  categoryName,
  onChangeImageUpload,
  primaryPicture,
  handleSaveTitle,
  title,
  optionTypeId,
  handleImageUploader,
  imageUploaded,
  deleteHandler,
  progress,
  loadingUpload,
  setEditedFile,
  loadingDelete,
  imageGalleryModal,
  toggleUploadImageModal,
  imgData,
  selectedImage,
  handleEditImageUploader,
  setImageUploaded,
  setProgress,
  componentRef,
  startIndex,
  ...props
}) => {
  const data1 = props?.data;
  const [showQuickSummary, setShowQuickSummary] = useState(false);
  const [loadingQuickSummary, setLoadingQuickSummary] = useState(false);

  const [values, setValues] = useState({
    information_text_1: "",
    information_text_2: "",
    information_text_3: "",
    information_text_4: "",
    information_text_5: "",
    information_text_6: "",
    information_text_7: "",
    information_text_8: "",
    information_text_9: "",
    information_text_10: "",
    information_text_11: "",
  });

  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [auctionEndDate, setAcutionEndDate] = useState("");
  const [dueDateSeller, setDueDateSeller] = useState(null);
  const [auctionStatus, setAuctionStatus] = useState(null);
  const [auctionType, setAuctionType] = useState(null);
  const [holdDate, setHoldDate] = useState(null);
  const [error, setError] = useState(false);
  const [detailData, setDetailData] = useState({
    at_id: "",
    date: "",
    date_scheduler: "",
    override_scheduler: false,
    end_date_1: "",
    end_date_2: "",
    end_date_3: "",
    end_date_4: "AM",
    archive_reason: "",
    status_id: "",
    proc_proj_id: "",
    due_date_1: "",
    due_date_2: "",
    due_date_3: "",
    due_date_4: "AM",
    vessel_name: "",
    file_number: "",
    bigdeal: false,
    mega: false,
    ready_to_close: false,
    loc_sec: "",
    private: false,
    proxibid: null,
    proxibid_archive: false,
    proxibid_hidereport: false,
    hotlist: false,
    featured: false,
    pushready: null,
    exclusive: false,
    b_to_s: false,
    chk_hold: false,
    hold_end_date: "",
    available_view: false,
    motivation_post: false,
    motivation: "",
  });
  const [showTitle, setShowTitle] = useState(false);
  const [listingTitle, setListingTitle] = useState("");

  const toggleShowTitle = () => {
    setShowTitle(!showTitle);
  };

  const toggleShowDetailsModal = () => {
    setShowDetailsModal(!showDetailsModal);
  };

  useEffect(() => {
    if (showDetailsModal) {
      props.getAuctionStatus((res) => {
        if (res && res.status === 200) {
          const data =
            res.data &&
            res.data.data.length > 0 &&
            res.data.data.map((item) => ({
              name: item.description,
              value: item.as_id,
            }));
          setAuctionStatus(data);
        }
      });
      props.getAuctionType((res) => {
        if (res && res.status === 200) {
          const data =
            res.data &&
            res.data.data.length > 0 &&
            res.data.data.map((item) => ({
              name: item.description,
              value: item.at_id,
            }));
          setAuctionType(data);
        }
      });
      props.getProjects(props.auctionId, (res) => {
        if (res.status === 200) {
        }
      });
    }
  }, [showDetailsModal]);

  const details =
    (props.getListingProductDetail &&
      props.getListingProductDetail.moreDetails &&
      props.getListingProductDetail.moreDetails.data) ||
    {};

  const loactionSecurity = (data) => {
    var locn = {};
    if (data === 0) {
      locn = { name: "Continent", value: 0 };
      return locn;
    } else if (data === 1) {
      locn = { name: "Region", value: 1 };
      return locn;
    } else if (data === 2) {
      locn = { name: "City, State/Country", value: 2 };
      return locn;
    } else if (data === 3) {
      locn = { name: "Exact", value: 3 };
      return locn;
    }
  };

  const dateTime = (w) => {
    const d = new Date();
    let diff = d.getTimezoneOffset();

    const date = moment(w).add({ minute: diff }).format("L");
    const hour = moment(w).add({ minute: diff }).format("H");
    const min = moment(w).add({ minute: diff }).format("m");
    const type = moment(w).add({ minute: diff }).format("A");

    const data = {
      date,
      hour,
      min,
      type,
    };

    return data;
  };

  const handleSubmit = () => {
    if (detailData.proc_proj_id) {
      setLoading(true);
      props.refStart();
      const id = props.auctionId;
      var aEndDate;
      var dEndDate;
      var hEndDate;
      if (auctionEndDate) {
        aEndDate = dateTime(auctionEndDate);
      }
      if (dueDateSeller) {
        dEndDate = dateTime(dueDateSeller);
      }
      if (holdDate) {
        hEndDate = dateTime(holdDate);
      }
      const payload = {
        at_id: !_.isEmpty(detailData.at_id) && detailData.at_id.value ? detailData.at_id.value : "",
        date_scheduler: "",
        override_scheduler: false,
        end_date_1: auctionEndDate && aEndDate ? aEndDate.date : "",
        end_date_2: auctionEndDate && aEndDate ? aEndDate.hour : "",
        end_date_3: auctionEndDate && aEndDate ? aEndDate.min : "",
        end_date_4: auctionEndDate && aEndDate ? aEndDate.type : "",
        archive_reason: "",
        status_id: !_.isEmpty(detailData.status_id) && detailData.status_id.value ? detailData.status_id.value : 0,
        proc_proj_id:
          !_.isEmpty(detailData.proc_proj_id) && detailData.proc_proj_id.value ? detailData.proc_proj_id.value : 0,
        due_date_1: dueDateSeller && dEndDate ? dEndDate.date : "",
        due_date_2: dueDateSeller && dEndDate ? dEndDate.hour : "",
        due_date_3: dueDateSeller && dEndDate ? dEndDate.min : "",
        due_date_4: dueDateSeller && dEndDate ? dEndDate.type : "",
        vessel_name: detailData.vessel_name ? detailData.vessel_name : "",
        file_number: detailData.file_number ? detailData.file_number : "",
        bigdeal: detailData.bigdeal ? true : false,
        mega: detailData.mega ? true : false,
        ready_to_close: detailData.ready_to_close ? true : false,
        loc_sec: !_.isEmpty(detailData.loc_sec) && detailData.loc_sec.value ? detailData.loc_sec.value : 0,
        private: detailData.private ? detailData.private : false,
        proxibid: detailData.proxibid ? detailData.proxibid : 0,
        proxibid_archive: detailData.proxibid_archive ? detailData.proxibid_archive : false,
        proxibid_hidereport: detailData.proxibid_hidereport ? detailData.proxibid_hidereport : false,
        hotlist: detailData.hotlist ? detailData.hotlist : false,
        featured: detailData.featured ? detailData.featured : false,
        pushready: detailData.pushready ? 1 : 0,
        exclusive: detailData.pushready ? detailData.exclusive : false,
        b_to_s: detailData.b_to_s ? detailData.b_to_s : false,
        chk_hold: detailData.chk_hold ? detailData.chk_hold : false,
        hold_end_date: detailData.chk_hold && hEndDate ? hEndDate.date : "",
        available_view: detailData.available_view ? detailData.available_view : false,
        motivation_post: detailData.motivation && detailData.motivation.length > 0 ? true : false,
        motivation: detailData.motivation && detailData.motivation.length > 0 ? detailData.motivation : "",
      };
      props.updateMoreDetails(id, payload, (res) => {
        if (res && res.status === 200) {
          props.getMoreDetails(id, (res) => {
            if (res.status === 200) {
              setLoading(false);
              toggleShowDetailsModal();
              props.refStop();
              toast(<AlertSuccess message={"Information Saved"} />);
            } else {
              setLoading(false);
              props.refStop();
              toast(
                <AlertError
                  message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"}
                />,
              );
            }
          });
        } else {
          setLoading(false);
          props.refStop();
          toast(
            <AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />,
          );
        }
      });
    } else {
      toast(<AlertError message="Please Select Any Project to Continue" />);
    }
  };

  useEffect(() => {
    if (showDetailsModal) {
      const detail =
        (props.getListingProductDetail &&
          props.getListingProductDetail.moreDetails &&
          props.getListingProductDetail.moreDetails.data) ||
        {};
      const loc = detail.loc_sec ? loactionSecurity(detail.loc_sec) : "";
      const initialData = {
        at_id: {
          name: detail.at_description ? detail.at_description : "",
          value: detail.at_id ? detail.at_id : "",
        },
        date: "",
        date_scheduler: "",
        override_scheduler: false,
        end_date_1: "",
        end_date_2: "",
        end_date_3: "",
        end_date_4: "AM",
        archive_reason: "",
        status_id: {
          name: detail.status_description ? detail.status_description : "",
          value: detail.status_id ? detail.status_id : "",
        },
        proc_proj_id: {
          name: detail.project && detail.project.title ? detail.project.title : "",
          value: detail.project && detail.project.project_id ? detail.project.project_id : "",
        },
        due_date_1: "",
        due_date_2: "",
        due_date_3: "",
        due_date_4: "AM",
        vessel_name: detail.vessel_name ? detail.vessel_name : "",
        file_number: detail.file_number ? detail.file_number : "",
        bigdeal: detail.bigdeal ? detail.bigdeal : false,
        mega: detail.mega ? detail.mega : false,
        ready_to_close: detail.ready_to_close ? detail.ready_to_close : false,
        loc_sec: {
          name: loc ? loc.name : "",
          value: loc ? loc.value : "",
        },
        private: detail.private ? detail.private : false,
        proxibid: detail.proxibid ? detail.proxibid : 0,
        proxibid_archive: detail.proxibid_archive ? detail.proxibid_archive : false,
        proxibid_hidereport: detail.proxibid_hidereport ? detail.proxibid_hidereport : false,
        hotlist: detail.hotlist ? detail.hotlist : false,
        featured: detail.featured ? detail.featured : false,
        pushready: detail.pushready ? 1 : 0,
        exclusive: detail.exclusive ? detail.exclusive : false,
        b_to_s: detail.b_to_s ? detail.b_to_s : false,
        chk_hold: detail.hold_end_date ? true : false,
        hold_end_date: "",
        available_view: detail.available_view ? detail.available_view : false,
        motivation_post: detail.motivation_post ? detail.motivation_post : false,
        motivation: detail.motivation ? detail.motivation : false,
      };

      setDetailData(initialData);
      setAcutionEndDate(detail.end_date ? detail.end_date : "");
      setDueDateSeller(detail.due_date ? detail.due_date : "");

      setHoldDate(detail.hold_end_date ? detail.hold_end_date : "");
    }
  }, [showDetailsModal]);
  const projects =
    (props.getMisc &&
      props.getMisc.project &&
      props.getMisc.project.data &&
      props.getMisc.project.data.length > 0 &&
      props.getMisc.project.data.map((item) => ({
        name: item.title,
        value: item.project_id,
      }))) ||
    [];

  const detail =
    (props.getListings && props.getListings.sellerMotivation && props.getListings.sellerMotivation.data) || {};

  const data =
    (props.getListings && props.getListings.sellerMotivation && props.getListings.sellerMotivation.data) || {};

  const summary =
    (props.getListingProductDetail &&
      props.getListingProductDetail.moreDetails &&
      props.getListingProductDetail.moreDetails.data) ||
    {};

  const s_ID =
    props.getListings &&
    props.getListings.sellerMotivation &&
    props.getListings.sellerMotivation.data &&
    props.getListings.sellerMotivation.data.s_user_id;

  const toggleQuickSummaryModal = () => {
    setShowQuickSummary(!showQuickSummary);
  };

  const handleSubmit1 = () => {
    setLoadingQuickSummary(true);
    props.refStart();
    const id = s_ID;
    const data = { ...values };
    data.information_id = [
      "Ownership/Seller Broker",
      "Sale Deadlines/Timelines",
      "Product Line Discussion",
      "Geographic Market Sale Options",
      "Location Loading and Logistics",
      "Product Markets Discussion",
      "Asset Pricing Discussion",
      "Vendor/Exclusive Contracts",
      "Insured Bidding",
      "Seller Communications",
      "Other Information",
    ];
    props.updateQuickSummary(id, data, (res) => {
      if (res.status === 200) {
        const response = { response: data };
        props.getSellerMotivation(props.auctionId, (res) => {
          if (res.status === 200) {
            setLoadingQuickSummary(false);
            props.refStop();
            toast(<AlertSuccess message={"Information Saved"} />);
            toggleQuickSummaryModal();
          } else {
            setLoadingQuickSummary(false);
            props.refStop();
            toast(
              <AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />,
            );
          }
        });
      } else {
        setLoadingQuickSummary(false);
        props.refStop();
        toast(<AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  useEffect(() => {
    if (showQuickSummary) {
      const summaryValue = {};
      summaryValue.information_text_1 = data.information_text_1 ? data.information_text_1 : "";
      summaryValue.information_text_2 = data.information_text_2 ? data.information_text_2 : "";
      summaryValue.information_text_3 = data.information_text_3 ? data.information_text_3 : "";
      summaryValue.information_text_4 = data.information_text_4 ? data.information_text_4 : "";
      summaryValue.information_text_5 = data.information_text_5 ? data.information_text_5 : "";
      summaryValue.information_text_6 = data.information_text_6 ? data.information_text_6 : "";
      summaryValue.information_text_7 = data.information_text_7 ? data.information_text_7 : "";
      summaryValue.information_text_8 = data.information_text_8 ? data.information_text_8 : "";
      summaryValue.information_text_9 = data.information_text_9 ? data.information_text_9 : "";
      summaryValue.information_text_10 = data.information_text_10 ? data.information_text_10 : "";
      summaryValue.information_text_11 = data.information_text_11 ? data.information_text_11 : "";
      setValues({ ...summaryValue });
    }
  }, [showQuickSummary]);

  const imgValue = props.getListings && props.getListings.detail && props.getListings.detail.detail;

  const d = new Date();
  let diff = d.getTimezoneOffset();

  useEffect(() => {
    if (props.getListings?.detail?.detail?.title) {
      setListingTitle(props.getListings?.detail?.detail?.title);
    }
  }, [props.getListings?.detail?.detail?.title]);

  const handleChangeTitle = () => {
    setLoading(true);
    const id = auctionId;
    const data = {
      fld: "title",
      val: listingTitle,
    };
    refStart();
    props?.updateTitle(id, data, (res) => {
      if (res && res.status == 200) {
        props?.getDetail(id, (res) => {
          if (res && res.status == 200) {
            setLoading(false);
            toggleShowTitle();
            refStop();
          }
        });
      } else {
        setLoading(false);
        refStop();
        toast(<AlertError message={res?.data?.message ? res?.data?.message : "Something went wrong"} />);
      }
    });
  };

  return (
    <div className="col-row-item w-50" id={props.id}>
      <div className="col-row-box w-100 h-100 d-flex flex-column overflow-hidden">
        <div className="product-details-content">
          <div className="pdc-head d-flex">
            {loadingMain ? (
              <>
                <div style={{ width: "50%", minHeight: "100px" }}>
                  <Skeleton width="50%" height="100%" />
                </div>
                <div
                  className="mt-2"
                  style={{
                    width: "50%",
                    minHeight: "100px",
                    marginLeft: "-90px",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      minHeight: "20px",
                    }}
                  >
                    <Skeleton width="100%" height="100%" />
                  </div>
                  <div
                    style={{
                      width: "100%",
                      minHeight: "20px",
                    }}
                  >
                    <Skeleton width="100%" height="100%" />
                  </div>
                  <div
                    style={{
                      width: "100%",
                      minHeight: "20px",
                    }}
                  >
                    <Skeleton width="100%" height="100%" />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="pdc-head-left cursor-pointer">
                  <img
                    style={{ objectFit: "contain" }}
                    src={
                      imgValue?.primary_picture
                        ? `${imgValue.primary_picture}_mp${imgValue.jpeg ? ".jpeg" : ".jpg"}`
                        : "https://media.salvex.com/auction/p/1830012/183001141_641791_mp.jpg"
                    }
                    alt=""
                    onClick={() => toggleUploadImageModal()}
                  />
                </div>
                <div className="pdc-head-rights">
                  <h6>
                    {data && data.auction_id ? data.auction_id : ""} - (
                    {props && props.getListings?.detail?.detail?.title ? props.getListings?.detail?.detail?.title : ""})
                    <img
                      src={edit}
                      style={{ marginLeft: "10px" }}
                      onClick={() => toggleShowTitle()}
                      className="cursor-pointer"
                    />
                  </h6>
                </div>
              </>
            )}
          </div>
          {summary.motivation && (
            <div className="pdc-body" style={{ paddingTop: "0px" }}>
              <h6>{loadingMain ? <Skeleton width="50%" /> : "Listing Quick Summary"}</h6>
              <p>
                {loadingMain ? (
                  <Skeleton width="50%" />
                ) : summary.motivation ? (
                  HTMLReactParser(summary.motivation)
                ) : (
                  "N/A"
                )}
              </p>
            </div>
          )}

          <MoreDetailsModal
            show={showDetailsModal}
            onHide={toggleShowDetailsModal}
            setDetailData={setDetailData}
            detailData={detailData}
            setAcutionEndDate={setAcutionEndDate}
            auctionEndDate={auctionEndDate}
            dueDateSeller={dueDateSeller}
            holdDate={holdDate}
            setDueDateSeller={setDueDateSeller}
            setHoldDate={setHoldDate}
            loading={loading}
            auctionType={auctionType}
            auctionStatus={auctionStatus}
            handleSubmit={handleSubmit}
            projects={projects}
          />
          <ListingTitle
            show={showTitle}
            onHide={toggleShowTitle}
            setTitle={setListingTitle}
            title={listingTitle}
            handleChangeTitle={handleChangeTitle}
            loading={loading}
          />
          <UploadImages
            uploadImageModal={uploadImageModal}
            setUploadImageModal={setUploadImageModal}
            listingImages={data1}
            setSelectedImage={setSelectedImage}
            onChangeImageUpload={onChangeImageUpload}
            setOptionType={setOptionType}
            setOptionTypeId={setOptionTypeId}
            setTitle={setTitle}
            title={title}
            handleSaveTitle={handleSaveTitle}
            optionType={optionType}
            selectedImage={selectedImage}
            toggleUploadImageModal={toggleUploadImageModal}
            optionTypeId={optionTypeId}
            handleImageUploader={handleImageUploader}
            imageUploaded={imageUploaded}
            deleteHandler={deleteHandler}
            progress={progress}
            loadingMain={false}
            loadingUpload={loadingUpload}
            loadingDelete={loadingDelete}
            loadingTitleUpdate={loadingTitleUpdate}
            setEditedFile={setEditedFile}
            handleEditImageUploader={handleEditImageUploader}
            setImageUploaded={setImageUploaded}
            handlePrimaryPicture={handlePrimaryPicture}
            setProgress={setProgress}
            toggleImageGalleryModal={toggleImageGalleryModal}
            setStartIndex={setStartIndex}
            primaryPicture={primaryPicture}
            componentRef={componentRef}
          />
          <ImageLargeModal
            show={imageGalleryModal}
            onHide={toggleImageGalleryModal}
            items={imgData}
            startIndex={startIndex}
            title={listingDetail && listingDetail.detail && listingDetail.detail.title}
          />
          <DeleteModal
            optionType={optionType}
            selectedImage={selectedImage}
            setOptionType={setOptionType}
            deleteHandler={deleteHandler}
            uploadImageModal={uploadImageModal}
            loadingDelete={loadingDelete}
          />
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getSellerMotivation: (params, callback) => dispatch(getSellerMotivation(params, callback)),
  updateQuickSummary: (params, data, callback) => dispatch(updateQuickSummary(params, data, callback)),
  updateTitle: (id, data, callback) => dispatch(updateTitle(id, data, callback)),

  //global state for storing api value
  updateMoreDetails: (params, data, callback) => dispatch(updateMoreDetails(params, data, callback)),
  getAuctionStatus: (callback) => dispatch(getAuctionStatus(callback)),
  getAuctionType: (callback) => dispatch(getAuctionType(callback)),
  getProjects: (data, callback) => dispatch(getProjects(data, callback)),
  getMoreDetails: (data, callback) => dispatch(getMoreDetails(data, callback)),
  getDetail: (params, callback) => dispatch(detailListing(params, callback)),
  //global state managed
  moreDetailsReceived: (data) => dispatch(moreDetailsReceived(data)),
});

const mapStateToProps = (state) => ({
  getListingProductDetail: getListingProductDetail(state),
  getListings: getListings(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(ListingOverview));
