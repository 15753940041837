import React from "react";
import { Modal } from "react-bootstrap";
import NextButton from "../../common/form/nextButton";
import close from "../../include/images/close.svg";

const ExclusivityModal = (props) => {
  return (
    <Modal
      className="modal fade custom-modal"
      id="editExclusivity"
      show={props.show}
      onHide={() => props.onHide()}
      dialogClassName="modal-dialog w-900 modal-dialog-centered modal-lg"
    >
      <div class="modal-content h-100 d-flex flex-column">
        <div class="custom-modal-header d-flex align-items-center justify-content-between">
          <h5 class="mb-0 text-capitalize">Edit Exclusivity</h5>
          <button
            type="button"
            class="close-btn d-flex align-items-center justify-content-center"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => props.onHide()}
          >
            <img src={close} alt="" />
          </button>
        </div>
        <Modal.Body className="custom-modal-body flex-grow-1 hide-scrollbar">
          <table class="table modal-product-detail-table mb-0">
            <colgroup>
              <col style={{ width: "25%" }} />
              <col style={{ width: "75%" }} />
            </colgroup>
            <tr>
              <td>Exclusive ?</td>
              <td>
                <div class="form-check tick-checkbox">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    checked={props.values && props.values.exclusive}
                    onChange={(e) => {
                      const values = { ...props.values };
                      values.exclusive = e.target.checked;
                      props.setValues(values);
                    }}
                    id="tick1"
                  />
                  <label class="form-check-label fw-medium" for="tick1"></label>
                </div>
              </td>
            </tr>
            <tr>
              <td>Override Seller Bids ?</td>
              <td>
                <div class="form-check tick-checkbox">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    checked={props.values && props.values.override_bid}
                    onChange={(e) => {
                      const values = { ...props.values };
                      values.override_bid = e.target.checked;
                      props.setValues(values);
                    }}
                    value=""
                    id="tick2"
                  />
                  <label class="form-check-label fw-medium" for="tick2"></label>
                </div>
              </td>
            </tr>
            <tr>
              <td>Seller Commision :</td>
              <td>
                <div className="padding-0">
                  <input
                    type="number"
                    min="0"
                    step="0.01"
                    value={props.values && props.values.comm_seller}
                    class="form-control w-350"
                    onChange={(e) => {
                      const values = { ...props.values };
                      values.comm_seller = e.target.value;
                      props.setValues(values);
                    }}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>Buyer Premium :</td>
              <td>
                <div className="padding-0">
                  <input
                    type="number"
                    min="0"
                    step="0.1"
                    value={props.values && props.values.comm_buyer}
                    class="form-control w-350"
                    onChange={(e) => {
                      const values = { ...props.values };
                      values.comm_buyer = e.target.value;
                      props.setValues(values);
                    }}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>Public Bidding ?</td>
              <td>
                <div class="form-check tick-checkbox">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    checked={props.values && props.values.public_bidding}
                    onChange={(e) => {
                      const values = { ...props.values };
                      values.public_bidding = e.target.checked;
                      props.setValues(values);
                    }}
                    id="tick3"
                  />
                  <label class="form-check-label fw-medium" for="tick3"></label>
                </div>
              </td>
            </tr>
            <tr>
              <td>Seller Can See Names ?</td>
              <td>
                <div class="form-check tick-checkbox">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    checked={props.values && props.values.seller_can_see_names}
                    onChange={(e) => {
                      const values = { ...props.values };
                      values.seller_can_see_names = e.target.checked;
                      props.setValues(values);
                    }}
                    id="tick4"
                  />
                  <label class="form-check-label fw-medium" for="tick4"></label>
                </div>
              </td>
            </tr>
            <tr>
              <td>Show High Bid and Graph ?</td>
              <td>
                <div class="form-check tick-checkbox">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    checked={props.values && props.values.showbid}
                    onChange={(e) => {
                      const values = { ...props.values };
                      values.showbid = e.target.checked;
                      props.setValues(values);
                    }}
                    id="tick5"
                  />
                  <label class="form-check-label fw-medium" for="tick5"></label>
                </div>
              </td>
            </tr>
          </table>
        </Modal.Body>
        <div class="custom-modal-dividor"></div>
        <div class="custom-modal-footer d-grid gap-2 d-md-flex align-items-center justify-content-md-end">
          <button
            class="btn btn-link me-md-2 global-cancel-button"
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => props.onHide()}
          >
            Cancel
          </button>
          <NextButton
            label="Save"
            classData="btn btn-default"
            handleSubmit={props.handleSubmit}
            loading={props.loadingExclusivity}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ExclusivityModal;
