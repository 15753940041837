import React from "react";
import { Modal } from "react-bootstrap";
import imgNotFound from "../../include/images/salvex_enquiry_img.jpg";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";

const ImageLargeModal = ({ show, onHide, items, startIndex, title }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      className="modal fade"
      dialogClassName="modal-dialog modal-dialog-centered modal-fullscreen  modal-images-slider"
      contentClassName="mis-content"
      backdrop={true}
    >
      <div class="modal-content-header position-relative mis-head">
        <div class="modal-content-header-title">{title ? title : ""}</div>
        <div class="modal-content-header-subtitle"></div>
        <button type="button" class="btn-close" onClick={onHide}></button>
      </div>
      <Modal.Body bsPrefix="modal-content-body mis-body">
        <ImageGallery
          lazyLoad={true}
          items={items}
          showPlayButton={false}
          startIndex={startIndex}
          showThumbnails={true}
          thumbnailPosition={"bottom"}
          onImageError={(e) => {
            e.target.src = imgNotFound;
          }}
        />
      </Modal.Body>
    </Modal>
  );
};

export default React.memo(ImageLargeModal);
