import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import Skeleton from "react-loading-skeleton";
import LoadingBar from "react-top-loading-bar";
import { getAviationDetails, getAviationParts, getOrderRequests } from "../../../store/aviation";
import ViewDetails from "./viewDetails";
import { useRef } from "react";
import OrdersModal from "../../aviation/orderRequests/ordersModal";
import moment from "moment";

const AviationParts = (props) => {
  const ref = useRef();
  const [selectedValue, setSelectedValue] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [viewAllModal, setViewAllModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const toggleViewModal = () => {
    setViewModal(!viewModal);
  };

  const toggleViewAllModal = () => {
    setViewAllModal(!viewAllModal);
    setViewModal(!viewModal);
  };

  const orderData = props.getAviationDetails?.orderRequests?.data || [];

  useEffect(() => {
    if (!props.loadingMain && props.auctionId) {
      setLoading(true);
      props.getOrderRequests(
        {
          page: page,
          sort: "createdAt",
          order: "desc",
          auctionId: props.auctionId,
        },
        (res) => {
          if (res && res.status === 200) {
            setLoading(false);
          }
        },
      );
    }
  }, [page, props.auctionId]);

  const totalPage = props.getAviationDetails?.pagination?.totalPage || 1;

  return (
    <>
      <LoadingBar height={5} color="#47AD1D" ref={ref} />

      <div class="details-page-widget-block" id={props.id} style={{ marginTop: "10px" }}>
        <div class="page-widget-head d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <div class="page-widget-label text-capitalize">
              {" "}
              Aviation Part Order Requests {orderData?.length > 0 ? "" : !props.loadingMain && "(0)"}
            </div>
          </div>
        </div>
        <div class={orderData?.length > 0 && "page-widget-body mt-3"}>
          <div class="table-responsive">
            <table class="large-table">
              {orderData?.length > 0 && (
                <thead>
                  <tr>
                    <th style={{ width: "15%", minWidth: "133px" }}>
                      <a className="sort-by"> Order Id </a>{" "}
                    </th>

                    <th style={{ width: "20%", minWidth: "180px" }}>
                      <a className="sort-by"> Name </a>{" "}
                    </th>
                    <th style={{ width: "15%" }}>
                      <a className="sort-by">Email </a>
                    </th>
                    <th style={{ width: "15%", minWidth: "130px" }}>
                      <a className="sort-by"> Date </a>{" "}
                    </th>
                    <th style={{ width: "35%" }}>
                      <a className="sort-by">Notes </a>{" "}
                    </th>
                  </tr>
                </thead>
              )}
              <tbody>
                {props.loadingMain
                  ? [1, 2, 3, 4].map((item) => (
                      <tr>
                        <td style={{ width: "15%", minWidth: "133px" }}>
                          <Skeleton />
                        </td>
                        <td style={{ width: "15%", minWidth: "130px" }}>
                          <Skeleton />
                        </td>

                        <td style={{ width: "20%" }}>
                          <Skeleton />
                        </td>
                        <td style={{ width: "15%" }}>
                          <Skeleton />
                        </td>
                        <td style={{ width: "35%" }}>
                          <Skeleton />
                        </td>
                      </tr>
                    ))
                  : orderData &&
                    orderData.length > 0 &&
                    orderData.map((item) => {
                      return (
                        <tr>
                          <td style={{ minWidth: "133px" }}>
                            <span
                              className="aviation-auction link-item"
                              onClick={() => {
                                setTableData(item);
                                toggleViewModal();
                              }}
                            >
                              {" "}
                              {item.orderId ? item.orderId : "N/A"}
                            </span>
                          </td>
                          <td style={{ minWidth: "130px", textAlign: "left" }}>
                            <span
                              className="aviation-auction link-item"
                              onClick={() => {
                                setTableData(item);
                                toggleViewModal();
                              }}
                            >
                              {" "}
                              {item?.name ? item?.name : "N/A"}
                            </span>
                          </td>
                          <td> {item?.email ? item?.email : "N/A"}</td>

                          <td> {item?.createdAt ? moment(item?.createdAt).format("MMM-DD-YYYY") : "N/A"}</td>
                          <td> {item?.notes ? item?.notes : "N/A"}</td>
                        </tr>
                      );
                    })}
              </tbody>
            </table>
            {!props.loadingMain && orderData?.length > 0 && (
              <div class="table-pagination mt-3">
                <ul class="pagination  justify-content-end">
                  <li class="page-item">
                    <a
                      class={`page-link ${page === 1 ? "disabled" : ""}`}
                      href="#"
                      aria-label="Previous"
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        if (page != 1) {
                          setPage(page - 1);
                        }
                      }}
                    >
                      <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 13L1 7L7 1" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </a>
                  </li>
                  <li class="page-item active">
                    <a class="page-link" href="javascript:void(0)">
                      {page}
                    </a>
                  </li>
                  {totalPage > 1 && page + 1 <= totalPage && (
                    <li class="page-item">
                      <a
                        class="page-link"
                        href="#"
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          setPage(page + 1);
                        }}
                      >
                        {page + 1}
                      </a>
                    </li>
                  )}
                  <li class="page-item">
                    <a
                      class={`page-link ${page + 1 >= totalPage ? "disabled" : ""}`}
                      href="#"
                      aria-label="Next"
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        setPage(page + 1);
                      }}
                    >
                      <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 13L7 7L1 1" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
        <OrdersModal
          show={viewModal}
          onHide={toggleViewModal}
          tableData={tableData}
          viewAllModal={viewAllModal}
          toggleViewAllModal={toggleViewAllModal}
          setSelectedValue={setSelectedValue}
          selectedValue={selectedValue}
        />
        <ViewDetails show={viewAllModal} onHide={toggleViewAllModal} selectedValue={selectedValue?.aviationPart} />
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getOrderRequests: (params, callback) => dispatch(getOrderRequests(params, callback)),
});
const mapStateToProps = (state) => ({
  getAviationDetails: getAviationDetails(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(AviationParts));
