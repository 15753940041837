import queryString from "query-string";
import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import AlertError from "../../../common/alerts/alertError";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import ProductLocationModal from "../../../layouts/modals/productLocationModal/productLocationModal";
import trash from "../../../include/images/trash-18x18.svg";
import {
  deleteLocationImage,
  getCountries,
  getMapViaZip,
  getProductLocation,
  getStates,
  populateFromSeller,
  productLocationDetails,
  updateLocationSecurity,
  updateProductLocation,
  uploadLocationImage,
} from "../../../store/productLocation";
import HTMLReactParser from "html-react-parser";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import moment from "moment";
import DateFormat from "../../../common/DateFormat/dateFormat";
import { getMoreDetails, productDetails } from "../../../store/listingProduct";
import UploadImage from "../../../layouts/modals/productLocationModal/uploadImage";
import { Amplify, Storage } from "aws-amplify";
import DeleteModal from "../../../layouts/modals/deleteModal";
import { getModals, setLocationModal } from "../../../store/modal";
import { getAuctionSpecies } from "../../../store/ei";

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    region: "us-east-1",
  },
  Storage: {
    bucket: process.env.REACT_APP_S3_UPLOAD_BUCKET,
    region: "us-east-1",
  },
});

const ProductLocation = (props) => {
  const location = useLocation();
  const [loadingProductLocation, setLoadingProductLocation] = useState(false);
  const [showUploadImage, setShowUploadImage] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [imageUploaded, setImageUploaded] = useState([]);
  const [progress, setProgress] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [uploadDocuments, setUploadDocuments] = useState({});

  const [values, setValues] = useState({
    c_name: "",
    c_company: "",
    address_1: "",
    city: "",
    zip: "",
    map_zip: "",
    c_phone: "",
    c_fax: "",
    c_email: "",
    map_address: "",
    geo_long: 0,
    state_id: null,
    country_id: null,
    geo_lat: 0,
    storage_costs: null,
    storage_date: "",
    loading_costs: "",
    loading_costs_feature: false,
    storage_taxes_fees_feature: false,
    storage_taxes_fees: "",
    location_photo: "",
    location_type: "",
    location_requirement: "",
    org_bol_file: "",
    location_post: false,
    loading_costs_post: false,
  });
  const [showSections, setShowSections] = useState({ deatils: false, loadingCosts: false });
  const [locSec, setLocSec] = useState({});
  const [populatedData, setPopulatedData] = useState({});
  const [reload, setReload] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [generatePopulated, setGeneratePopulated] = useState(false);
  const [hideSections, setHideSections] = useState({ include: false, loading_costs: false, storage: false });

  const showProductLocationModal = props?.locationModal;

  useEffect(() => {
    if (location && location.search) {
      const id = queryString.parse(location.search).id;
      props.getLocSec(id, (res) => {
        if (res.status === 200) {
          setLocSec(res.data.data);
        }
      });
    }
  }, [reload]);

  const locationDetails =
    props &&
    props.productLocationDetails &&
    props.productLocationDetails.productLocation &&
    props.productLocationDetails.productLocation.data;

  const [region, setRegion] = useState({
    countryData: {
      description: locationDetails?.country,
      old: locationDetails?.country_id,
    },
    stateData: {
      description: locationDetails?.state,
      old: locationDetails?.state_id,
    },
  });
  const toggleModal = () => {
    props?.setShowProductLocationModal(!showProductLocationModal);
    setRefresh(!refresh);
    setGeneratePopulated(false);
  };

  useEffect(() => {
    if (showProductLocationModal) {
      const data = {};
      data.c_name = locationDetails.c_name ? locationDetails.c_name : "";
      data.c_company = locationDetails.c_company
        ? locationDetails.c_company === false || locationDetails?.c_company === "undefined"
          ? ""
          : locationDetails?.c_company
        : "";
      data.address_1 = locationDetails.address_1 ? locationDetails.address_1 : "";
      data.city = locationDetails.city ? locationDetails.city : "";
      data.zip = locationDetails.zip ? locationDetails.zip : "";
      data.map_zip = locationDetails.zip ? locationDetails.zip : "";
      data.c_phone = locationDetails.c_phone ? locationDetails.c_phone : "";
      data.c_fax = locationDetails.c_fax ? locationDetails.c_fax : "";
      data.c_email = locationDetails.c_email ? locationDetails.c_email : "";
      data.map_address = locationDetails.map_address ? locationDetails.map_address : "";
      data.geo_long = locationDetails.geo_long ? locationDetails.geo_long : 0;
      data.geo_lat = locationDetails.geo_lat ? locationDetails.geo_lat : 0;
      data.storage_costs = locationDetails.storage_costs ? locationDetails.storage_costs : "";
      data.storage_date = locationDetails.storage_date ? locationDetails.storage_date : "";
      data.loading_costs = locationDetails.loading_costs ? locationDetails.loading_costs : "";
      data.loading_costs_feature = locationDetails.loading_costs_feature
        ? locationDetails.loading_costs_feature
        : false;
      data.storage_taxes_fees_feature = locationDetails.storage_taxes_fees_feature
        ? locationDetails.storage_taxes_fees_feature
        : false;
      data.storage_taxes_fees = locationDetails.storage_taxes_fees ? locationDetails.storage_taxes_fees : "";
      data.state_id = locationDetails.state_id ? locationDetails.state_id : 0;
      data.country_id = locationDetails.country_id ? locationDetails.country_id : null;
      data.location_type = locationDetails.location_type ? locationDetails.location_type : "";
      data.location_requirement = locationDetails?.logistics_details
        ? JSON.parse(locationDetails?.logistics_details)
        : {};
      data.org_bol_file = locationDetails.org_bol_files ? locationDetails?.org_bol_files[0]?.filename : "";
      data.location_post = false;
      data.loading_costs_post = false;

      setValues({ ...data });
    }
  }, [showProductLocationModal]);

  const handleSubmit = () => {
    const file = uploadDocuments;
    setLoadingProductLocation(true);
    props.refStart();
    const fileName = "newSalvex/doc" + uuidv4() + ".pdf";
    Storage.put(fileName, file, {
      completeCallback: (event) => {},
      progressCallback: (progress) => {},
      errorCallback: (err) => {
        toast(<AlertError message={err?.message ? err?.message : "Something Went Wrong"} />);
      },
    })
      .then((result) => {
        const data = { ...values };

        data.org_bol_file = "public/" + result.key;
        setValues(data);
        const id = props.auctionId;
        setTimeout(() => {
          props.updateProductLocation(id, data, (res) => {
            if (res.status === 200) {
              props.getProductLocation(id, (res) => {
                if (res.status === 200) {
                  props.productDetails(id, (res) => {});
                  props?.getAuctionSpecies(id, (res) => {});
                  setLoadingProductLocation(false);
                  props.refStop();
                  toast(<AlertSuccess message={"Information Saved"} />);
                  toggleModal();
                } else {
                  setLoadingProductLocation(false);
                  props.refStop();
                  toast(
                    <AlertError
                      message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"}
                    />,
                  );
                }
              });
            } else {
              setLoadingProductLocation(false);
              props.refStop();
              toast(
                <AlertError
                  message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"}
                />,
              );
            }
          });
        }, 100);
      })
      .catch((err) => {
        toast(<AlertError message={err.message ? err.message : "Something Went Wrong"} />);
      });
  };

  const handleChange = (e) => {
    const values = { ...locSec };
    values.loc_sec = e.target.value;
    setLocSec(values);
    const data = { loc_sec: e.target.value };
    const id = props.auctionId;
    props.updateLocSec(id, data, (res) => {
      if (res.status === 200) {
        setReload(!reload);
      }
    });
  };

  useEffect(() => {
    if (showProductLocationModal && _.isEmpty(populatedData)) {
      if (location && location.search) {
        const id = queryString.parse(location.search).id;
        props.populateFromSeller(id, (res) => {
          if (res.status === 200) {
            setPopulatedData(res.data.data);
          }
        });
      }
    }
  }, [showProductLocationModal]);

  useEffect(() => {
    if (generatePopulated) {
      const data = {};
      data.stateData = {
        description: populatedData?.s_state ? populatedData?.s_state : null,
        old: populatedData?.s_state_id ? populatedData?.s_state_id : null,
      };
      data.countryData = {
        description: populatedData?.s_country ? populatedData?.s_country : null,
        old: populatedData?.s_country_id ? populatedData?.s_country_id : null,
      };
      setRegion({ ...data });
    }
  }, [generatePopulated]);

  function populateFromSeller() {
    const data = {};
    setGeneratePopulated(true);
    data.c_name = populatedData.s_name ? populatedData.s_name : "";
    data.c_company = populatedData.s_company ? populatedData.s_company : "";
    data.address_1 = populatedData.s_address_1 ? populatedData.s_address_1 : "";
    data.city = populatedData.s_city ? populatedData.s_city : "";
    data.zip = populatedData.s_zip ? populatedData.s_zip : "";
    data.map_zip = locationDetails.zip ? locationDetails.zip : "";
    data.c_phone = populatedData.s_phone ? populatedData.s_phone : "";
    data.c_fax = populatedData.s_fax ? populatedData.s_fax : "";
    data.c_email = populatedData.s_email ? populatedData.s_email : "";
    data.map_address = locationDetails.map_address ? locationDetails.map_address : "";
    data.geo_long = locationDetails.geo_long ? locationDetails.geo_long : 0;
    data.geo_lat = locationDetails.geo_lat ? locationDetails.geo_lat : 0;
    data.storage_costs = locationDetails.storage_costs ? locationDetails.storage_costs : "";
    data.storage_date = locationDetails.storage_date ? locationDetails.storage_date : "";
    data.loading_costs = locationDetails.loading_costs ? locationDetails.loading_costs : "";
    data.loading_costs_feature = locationDetails.loading_costs_feature ? locationDetails.loading_costs_feature : false;
    data.storage_taxes_fees_feature = locationDetails.storage_taxes_fees_feature
      ? locationDetails.storage_taxes_fees_feature
      : false;
    data.storage_taxes_fees = locationDetails.storage_taxes_fees ? locationDetails.storage_taxes_fees : "";
    data.state_id = populatedData.s_state_id ? populatedData.s_state_id : null;
    data.country_id = populatedData.s_country_id ? populatedData.s_country_id : null;
    data.location_type = locationDetails.location_type ? locationDetails.location_type : "";
    data.location_requirement = locationDetails?.logistics_details
      ? JSON.parse(locationDetails?.logistics_details)
      : {};
    data.org_bol_file = locationDetails.org_bol_files ? locationDetails?.org_bol_files[0]?.filename : "";
    data.location_post = false;
    data.loading_costs_post = false;
    setValues({ ...data });
  }

  const generateViaZip = (zip) => {
    if (location && location.search) {
      const id = queryString.parse(location.search).id;
      props.getMapViaZip(id, zip, (res) => {
        if (res.status === 200) {
          const data = {};
          data.c_name = locationDetails.c_name ? locationDetails.c_name : "";
          data.c_company = locationDetails.c_company ? locationDetails.c_company : "";
          data.address_1 = locationDetails.address_1 ? locationDetails.address_1 : "";
          data.city = locationDetails.city ? locationDetails.city : "";
          data.zip = locationDetails.zip ? locationDetails.zip : "";
          data.c_phone = locationDetails.c_phone ? locationDetails.c_phone : "";
          data.c_fax = locationDetails.c_fax ? locationDetails.c_fax : "";
          data.c_email = locationDetails.c_email ? locationDetails.c_email : "";
          data.map_address = locationDetails.map_address ? locationDetails.map_address : "";
          data.geo_long = res?.data?.data?.geo_long ? res?.data?.data?.geo_long : 0;
          data.geo_lat = res?.data?.data?.geo_lat ? res?.data?.data?.geo_lat : 0;
          data.storage_costs = locationDetails.storage_costs ? locationDetails.storage_costs : "";
          data.storage_date = locationDetails.storage_date ? locationDetails.storage_date : "";
          data.loading_costs = locationDetails.loading_costs ? locationDetails.loading_costs : "";
          data.loading_costs_feature = locationDetails.loading_costs_feature
            ? locationDetails.loading_costs_feature
            : false;
          data.storage_taxes_fees_feature = locationDetails.storage_taxes_fees_feature
            ? locationDetails.storage_taxes_fees_feature
            : false;
          data.state_id = locationDetails.state_id ? locationDetails.state_id : null;
          data.country_id = locationDetails.country_id ? locationDetails.country_id : null;
          data.location_type = locationDetails.location_type ? locationDetails.location_type : "";
          data.location_requirement = locationDetails?.logistics_details
            ? JSON.parse(locationDetails?.logistics_details)
            : {};
          data.org_bol_file = locationDetails.org_bol_files ? locationDetails?.org_bol_files[0]?.filename : "";
          data.location_post = false;
          data.loading_costs_post = false;
          setValues({ ...data });
        } else {
          toast(
            <AlertError
              message={res?.data?.message ? "Coordinates not available for the zip" : "Something Went Wrong!"}
            />,
          );
        }
      });
    }
  };

  const locationAddress = () => {
    if (locationDetails) {
      const data = [];
      if (locationDetails?.address_1) {
        data.push(locationDetails?.address_1);
      }
      if (locationDetails?.city) {
        data.push(locationDetails?.city);
      }
      if (locationDetails?.state) {
        data.push(locationDetails?.state);
      }
      if (locationDetails?.zip) {
        data.push(locationDetails?.zip);
      }
      if (locationDetails?.country) {
        data.push(locationDetails?.country);
      }
      if (locationDetails?.location2) {
        data.push(locationDetails?.location2);
      }
      return data.length > 0 ? data.map((item) => item).join(", ") : "";
    }
  };

  const locationType = (data) => {
    if (data == "TYPE1") {
      return "Port (Air, Sea)";
    } else if (data == "TYPE2") {
      return "Warehouse (manned with equipment & crew)";
    } else if (data == "TYPE3") {
      return "Warehouse (private with NO equipment or crew)";
    } else if (data == "TYPE4") {
      return "Yard/Field/Outdoor Space";
    } else if (data == "TYPE5") {
      return "Retail Storefront/Office/Residence/Private Property";
    }
  };

  //Image Uploading Function
  const handleImageUploader = () => {
    const e = imageUploaded;
    if (imageUploaded.length) {
      setLoadingUpload(true);
      setProgress("uploading");
      props.refStart();
    }
    for (let i = 0; i < imageUploaded.length; i++) {
      const file = imageUploaded[i];

      const fSize = Math.round(file.size / 1048576);
      const fType = file.type;
      const ext = file.name.split(".").pop();
      if (fSize > 25) {
        return (
          toast(<AlertError message="Image size exceeds maximum allowable size. Maximum allowable size is 25MB." />),
          props.refStop(),
          setLoadingUpload(false),
          setProgress("error")
        );
      } else if (!["image/jpeg", "image/jpg", "image/png"].includes(fType)) {
        return (
          toast(
            <AlertError message="Image is not of correct format and hence cannot be uploaded. Valid image formats are jpeg, jpg, and png." />,
          ),
          props.refStop(),
          setLoadingUpload(false),
          setProgress("error")
        );
      } else {
        const fileName = "newSalvex/" + uuidv4() + "." + ext;
        Storage.put(fileName, file, {
          completeCallback: (event) => {},
          progressCallback: (progress) => {},
          errorCallback: (err) => {
            setLoadingUpload(false);

            toast(<AlertError message={err && err.message ? err.message : "Something went wrong"} />);
          },
        }).then((result) => {
          const data = {
            image: "public/" + result.key,
          };
          props.uploadLocationImage(props.auctionId, data, (res) => {
            if (res.status === 200) {
              props.getProductLocation(props.auctionId, (res) => {});
              setShowUploadImage(false);
              // reloadImages();
              if (i > 0 && i === imageUploaded.length - 1 && res.status === 200) {
                return (
                  props.refStop(),
                  setLoadingUpload(false),
                  setProgress("completed"),
                  setImageUploaded([]),
                  setTimeout(() => setProgress(""), 800),
                  toast(<AlertSuccess message={"Information Saved"} />)
                );
              } else if (i == 0 && imageUploaded.length === 1) {
                return (
                  props.refStop(),
                  setLoadingUpload(false),
                  setProgress("completed"),
                  setImageUploaded([]),
                  setTimeout(() => setProgress(""), 800),
                  toast(<AlertSuccess message={"Information Saved"} />)
                );
              }
            } else {
              return (
                setLoadingUpload(false),
                setProgress("error"),
                props.refStop(),
                toast(
                  <AlertError
                    message={res && res.data && res.data.message ? res.data.message : "Something went wrong"}
                  />,
                )
              );
            }
          });
        });
      }
    }
  };

  //Toggle Delete Modal
  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  //Handle Delete Image
  const handleDeleteImage = () => {
    setLoadingDelete(true);
    props.refStart();
    props.deleteLocationImage(props.auctionId, (res) => {
      if (res.status === 200) {
        props.getProductLocation(props.auctionId, (res) => {});
        props.refStop();
        setLoadingDelete(false);
        toast(<AlertSuccess message={"Information Saved"} />);
        setShowDeleteModal(false);
      } else {
        props.refStop();
        setLoadingDelete(false);
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const logisticDetails = locationDetails?.logistics_details ? JSON.parse(locationDetails?.logistics_details) : "";

  return (
    <div className="details-page-widget-block" id={props.id}>
      <div class="page-widget-head d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <div class="page-widget-label text-capitalize cursor-pointer" onClick={() => toggleModal()}>
            Product Location
          </div>

          <div style={{ display: "none" }} id="popover">
            <div class="popover-inner" role="tooltip">
              <p>
                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer
                took a galley
              </p>
            </div>
          </div>
        </div>
        <div class="btn-block d-flex align-items-center">
          {props.loadingMain ? (
            <>
              <Skeleton height="30px" width="90px" />
              {/* <Skeleton height="30px" width="50px" /> */}
            </>
          ) : (
            <>
              <a href="javascript:void(0)" onClick={() => setShowUploadImage(true)} class="btn btn-primary btn-sm">
                Upload Photo
              </a>
              {/* <a href="javascript:void(0)" onClick={() => toggleModal()} class="btn btn-primary btn-sm">
                Edit
              </a> */}
            </>
          )}
        </div>
      </div>
      <div class="page-widget-body mt-3">
        {props.loadingMain ? (
          <div>
            <Skeleton height="180px" width="100%" />
          </div>
        ) : (
          <div class="pl-flex-row">
            <div class="pl-flex-head text-center " style={{ background: "#47ad1d", color: "#fff" }}>
              {locSec.loc_sec === 0 ? (
                locationDetails?.location1
              ) : locSec.loc_sec === 1 ? (
                locationDetails?.location2
              ) : locSec.loc_sec === 2 ? (
                locationDetails?.location3
              ) : locSec.loc_sec === 3 ? (
                <div dangerouslySetInnerHTML={{ __html: locationDetails?.location4?.toString() }} />
              ) : (
                "Loading..."
              )}
            </div>
            <div class="table-responsive">
              <table class="table align-middle product-table">
                <colgroup>
                  <col style={{ width: "36%" }} />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <td>
                      <span>Location Security </span>
                    </td>
                    <td>
                      <div class="td-radio-group">
                        <div class="form-check radio">
                          <input
                            class="form-check-input cursor-pointer"
                            type="radio"
                            name="flexRadioDefault"
                            id="continent"
                            value={0}
                            checked={locSec.loc_sec === 0}
                            onChange={handleChange}
                          />
                          <label class="form-check-label" for="continent">
                            Continent
                          </label>
                        </div>
                        <div class="form-check radio">
                          <input
                            class="form-check-input cursor-pointer"
                            type="radio"
                            name="flexRadioDefault"
                            id="region"
                            value={1}
                            checked={locSec.loc_sec === 1}
                            onChange={handleChange}
                          />
                          <label class="form-check-label" for="region">
                            Region
                          </label>
                        </div>
                        <div class="form-check radio">
                          <input
                            class="form-check-input cursor-pointer"
                            type="radio"
                            name="flexRadioDefault"
                            id="cityStateCountry"
                            value={2}
                            checked={locSec.loc_sec === 2}
                            onChange={handleChange}
                          />
                          <label class="form-check-label" for="cityStateCountry">
                            City, State/Country
                          </label>
                        </div>
                        <div class="form-check radio">
                          <input
                            class="form-check-input cursor-pointer"
                            type="radio"
                            name="flexRadioDefault"
                            id="exact"
                            value={3}
                            checked={locSec.loc_sec === 3}
                            onChange={handleChange}
                          />
                          <label class="form-check-label" for="exact">
                            Exact
                          </label>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
      <div className="page-widget-block-inside mt-3">
        <div
          className="page-widget-block-inside-head   cursor-pointer"
          style={{ marginBottom: "10px", backgroundColor: "#47AD1D" }}
          onClick={() => {
            const data = { ...showSections };
            if (showSections?.loadingCosts) {
              data.loadingCosts = false;
              setShowSections(data);
            } else {
              data.loadingCosts = true;
              setShowSections(data);
            }
          }}
        >
          <h4>{showSections?.loadingCosts ? "Loading Costs" : "View Loading Costs"}</h4>
        </div>
        {showSections?.loadingCosts ? (
          <div className="page-widget-block-inside-body">
            <div className="pwbi-content-box">
              <div className="table-responsive">
                <table className="table product-table mb-4">
                  <colgroup>
                    <col style={{ width: "35%" }} />
                    <col />
                  </colgroup>
                  <tbody>
                    {props.loadingMain ? (
                      <>
                        <tr>
                          <td>
                            <Skeleton />
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Skeleton />
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                        </tr>
                      </>
                    ) : (
                      <>
                        {locationDetails?.loading_costs_feature && (
                          <tr>
                            <td>Loading Costs Featured</td>
                            <td>{locationDetails?.loading_costs_feature ? "Yes" : "No"}</td>
                          </tr>
                        )}
                        <tr>
                          <td>Loading Costs</td>
                          <td>{locationDetails?.loading_costs ? locationDetails?.loading_costs : "N/A"}</td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
                <table className="table product-table mb-4">
                  <colgroup>
                    <col style={{ width: "35%" }} />
                    <col />
                  </colgroup>
                  <tbody>
                    {props.loadingMain ? (
                      <>
                        <tr>
                          <td>
                            <Skeleton />
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Skeleton />
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Skeleton />
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Skeleton />
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Skeleton />
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Skeleton />
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Skeleton />
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                        </tr>
                      </>
                    ) : (
                      <>
                        <tr>
                          <td>Contact</td>
                          <td>
                            {locationDetails?.c_name && locationDetails?.c_name.split("").length === 0
                              ? "N/A"
                              : locationDetails?.c_name}
                          </td>
                        </tr>
                        <tr>
                          <td>Company</td>
                          <td>
                            {locationDetails?.c_company && locationDetails?.c_company.split("").length === 0
                              ? "N/A"
                              : locationDetails?.c_company}
                          </td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>
                            {locationDetails?.address_1}
                            {locationDetails?.address_1 && <br />}
                            {locationDetails?.city}, {locationDetails?.s_des} {locationDetails?.zip}{" "}
                            {(locationDetails?.city || locationDetails?.s_des || locationDetails?.zip) && <br />}
                            {locationDetails?.c_des}
                          </td>
                        </tr>

                        <tr>
                          <td>Phone</td>
                          <td>
                            {locationDetails?.c_phone && locationDetails?.c_phone.split("").length === 0
                              ? "N/A"
                              : locationDetails?.c_phone}
                          </td>
                        </tr>
                        <tr>
                          <td>Fax</td>
                          <td>
                            {locationDetails?.c_fax && locationDetails?.c_fax.split("").length === 0
                              ? "N/A"
                              : locationDetails?.c_fax}
                          </td>
                        </tr>
                        <tr>
                          <td>Email</td>
                          <td>
                            <a href={`mailto:${locationDetails?.c_email}`}>{locationDetails?.c_email}</a>
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <div
          className="page-widget-block-inside-head d-flex space-between  cursor-pointer"
          style={{ backgroundColor: "#47AD1D" }}
          onClick={() => {
            const data = { ...showSections };
            if (showSections?.deatils) {
              data.deatils = false;
              setShowSections(data);
            } else {
              data.deatils = true;
              setShowSections(data);
            }
          }}
        >
          <h4>{showSections?.deatils ? "Logistics and Release Details" : "View Logistics and Release Details"}</h4>
        </div>
        {showSections?.deatils ? (
          <div className="page-widget-block-inside-body">
            <div className="page-widget-label text-capitalize mb-3">Include in Product Release ?</div>
            <table className="table product-table mb-4">
              <colgroup>
                <col style={{ width: "35%" }} />
                <col />
              </colgroup>
              <tbody>
                {
                  <>
                    <tr>
                      <td>Location Type</td>
                      <td>{locationType(locationDetails?.location_type)}</td>
                    </tr>
                    {logisticDetails["truck_free"] && logisticDetails["truck_free"]?.VALUE ? (
                      <tr>
                        <td>Can load buyer's truck free</td>
                        <td>
                          {logisticDetails?.["truck_free"]
                            ? logisticDetails?.["truck_free"]?.VALUE === "yes"
                              ? "Yes"
                              : logisticDetails?.["truck_free"]?.VALUE === "no"
                              ? "No"
                              : logisticDetails?.["truck_free"]?.VALUE
                            : "N/A"}
                        </td>
                      </tr>
                    ) : (
                      ""
                    )}
                    {logisticDetails["truck_fee"] && logisticDetails["truck_fee"]?.VALUE ? (
                      <tr>
                        <td>Can load buyer's truck for a fee</td>
                        <td>{logisticDetails?.["truck_fee"] ? logisticDetails?.["truck_fee"]?.VALUE : "N/A"}</td>
                      </tr>
                    ) : (
                      ""
                    )}
                    {logisticDetails["inspection_allowed"] && logisticDetails["inspection_allowed"]?.VALUE ? (
                      <tr>
                        <td>Inspections Allowed :</td>
                        <td>
                          {logisticDetails?.["inspection_allowed"]
                            ? logisticDetails?.["inspection_allowed"]?.VALUE === "yes"
                              ? "Yes"
                              : logisticDetails?.["inspection_allowed"]?.VALUE === "no"
                              ? "No"
                              : logisticDetails?.["inspection_allowed"]?.VALUE
                            : "N/A"}
                        </td>
                      </tr>
                    ) : (
                      ""
                    )}
                    {logisticDetails["uiia_certified"] && logisticDetails["uiia_certified"]?.VALUE ? (
                      <tr>
                        <td>UIIA Certified Driver Required for Entry Into Location:</td>
                        <td>
                          {logisticDetails?.["uiia_certified"]
                            ? logisticDetails?.["uiia_certified"]?.VALUE === "yes"
                              ? "Yes"
                              : logisticDetails?.["uiia_certified"]?.VALUE === "no"
                              ? "No"
                              : logisticDetails?.["uiia_certified"]?.VALUE
                            : "N/A"}
                        </td>
                      </tr>
                    ) : (
                      ""
                    )}
                    {logisticDetails["shipped"] && logisticDetails["shipped"]?.VALUE ? (
                      <tr>
                        <td>Can be shipped in current containers :</td>
                        <td>
                          {logisticDetails?.["shipped"]
                            ? logisticDetails?.["shipped"]?.VALUE === "yes"
                              ? "Yes"
                              : logisticDetails?.["shipped"]?.VALUE === "no"
                              ? "No"
                              : logisticDetails?.["shipped"]?.VALUE
                            : "N/A"}
                        </td>
                      </tr>
                    ) : (
                      ""
                    )}
                    {logisticDetails["empty_containers"] && logisticDetails["empty_containers"]?.VALUE ? (
                      <tr>
                        <td>Empty containers must be returned to original pick up depot :</td>
                        <td>
                          {logisticDetails["empty_containers"]
                            ? logisticDetails["empty_containers"]?.VALUE === "yes"
                              ? "Yes"
                              : logisticDetails["empty_containers"]?.VALUE === "no"
                              ? "No"
                              : logisticDetails["empty_containers"]?.VALUE
                            : "N/A"}
                        </td>
                      </tr>
                    ) : (
                      ""
                    )}
                    {logisticDetails["pick_up"] && logisticDetails["pick_up"]?.VALUE ? (
                      <tr>
                        <td>Pick Up / Product Removal Deadline : </td>
                        <td>{logisticDetails?.["pick_up"] ? logisticDetails?.["pick_up"]?.VALUE : "N/A"}</td>
                      </tr>
                    ) : (
                      ""
                    )}
                    {logisticDetails["storage_buyer_cost"] && logisticDetails["storage_buyer_cost"]?.VALUE ? (
                      <tr>
                        <td>Storage Costs to Buyer : </td>
                        <td>
                          {logisticDetails?.["storage_buyer_cost"]
                            ? logisticDetails?.["storage_buyer_cost"]?.VALUE
                            : "N/A"}
                        </td>
                      </tr>
                    ) : (
                      ""
                    )}
                    {logisticDetails["storage_cost_date"] && logisticDetails["storage_cost_date"]?.VALUE ? (
                      <tr>
                        <td>Storage Cost Start Date : </td>
                        <td>
                          {logisticDetails?.["storage_cost_date"] ? (
                            <DateFormat data={logisticDetails?.["storage_cost_date"]?.VALUE} />
                          ) : (
                            "N/A"
                          )}
                        </td>
                      </tr>
                    ) : (
                      ""
                    )}
                    {logisticDetails["demurrage"] && logisticDetails["demurrage"]?.VALUE ? (
                      <tr>
                        <td>Demurrage : </td>
                        <td>
                          {logisticDetails?.["demurrage"]
                            ? logisticDetails?.["demurrage"]?.VALUE == "none"
                              ? "None / Not Applicable"
                              : logisticDetails?.["demurrage"]?.VALUE == "cleared"
                              ? "Cleared / Seller will pay before release"
                              : logisticDetails?.["demurrage"]?.VALUE == "expects"
                              ? "Seller expects buyer to pay"
                              : ""
                            : "N/A"}
                        </td>
                      </tr>
                    ) : (
                      ""
                    )}
                    {logisticDetails["customs"] && logisticDetails["customs"]?.VALUE ? (
                      <tr>
                        <td>Customs Cleared </td>
                        <td>
                          {logisticDetails["customs"]
                            ? logisticDetails["customs"]?.VALUE === "yes"
                              ? "Yes"
                              : logisticDetails["customs"]?.VALUE === "no"
                              ? "No"
                              : logisticDetails["customs"]?.VALUE
                            : "N/A"}
                        </td>
                      </tr>
                    ) : (
                      ""
                    )}
                    {logisticDetails["c_picking_up"] && logisticDetails["c_picking_up"]?.VALUE ? (
                      <tr>
                        <td>Trucking Company Picking Up Product : </td>
                        <td>{logisticDetails?.["c_picking_up"] ? logisticDetails?.["c_picking_up"]?.VALUE : "N/A"}</td>
                      </tr>
                    ) : (
                      ""
                    )}
                    {logisticDetails["scac_code"] && logisticDetails["scac_code"]?.VALUE ? (
                      <tr>
                        <td>Trucking Company SCAC Code :</td>
                        <td>{logisticDetails?.["scac_code"] ? logisticDetails?.["scac_code"]?.VALUE : "N/A"}</td>
                      </tr>
                    ) : (
                      ""
                    )}
                    {logisticDetails["container_number"] && logisticDetails["container_number"]?.VALUE ? (
                      <tr>
                        <td>Container Number(s) :</td>
                        <td>
                          {logisticDetails?.["container_number"] ? logisticDetails?.["container_number"]?.VALUE : "N/A"}
                        </td>
                      </tr>
                    ) : (
                      ""
                    )}
                    {locationDetails?.org_bol_files?.length > 0 ? (
                      <tr>
                        <td>Original BOL (redacted) :</td>
                        <td>
                          <a href={locationDetails?.org_bol_files[0]?.filename} target="_blank">
                            Original BOL Document
                          </a>
                        </td>
                      </tr>
                    ) : (
                      ""
                    )}
                  </>
                }
              </tbody>
            </table>

            <div className="page-widget-label text-capitalize mb-3">Storage</div>
            <table className="table product-table mb-4">
              <colgroup>
                <col style={{ width: "35%" }} />
                <col />
              </colgroup>
              <tbody>
                {props.loadingMain ? (
                  <>
                    <tr>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                    </tr>
                  </>
                ) : (
                  <>
                    <tr>
                      <td>Storage Costs</td>
                      <td>{locationDetails?.storage_costs ? locationDetails?.storage_costs : "N/A"}</td>
                    </tr>
                    <tr>
                      <td>Storage Date</td>
                      <td>
                        {locationDetails?.storage_date ? <DateFormat date={locationDetails.storage_date} /> : "N/A"}
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
            <div className="page-widget-label text-capitalize mb-3">Listing Map</div>
            <table className="table mb-0 product-table">
              <colgroup>
                <col style={{ width: "35%" }} />
                <col />
              </colgroup>
              <tbody>
                {props.loadingMain ? (
                  <>
                    <tr>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                    </tr>
                  </>
                ) : (
                  <>
                    <tr>
                      <td>Address</td>
                      <td>{locationDetails?.map_address ? locationDetails?.map_address : "N/A"}</td>
                    </tr>
                    <tr>
                      <td>Longitude</td>
                      <td>{locationDetails?.geo_long ? parseFloat(locationDetails?.geo_long).toFixed(6) : "0"}</td>
                    </tr>
                    <tr>
                      <td>Latitude</td>
                      <td>{locationDetails?.geo_lat ? parseFloat(locationDetails?.geo_lat).toFixed(6) : "0"}</td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
            {locationDetails?.location_photo && (
              <div className="d-flex justify-content-around align-items-center mt-3">
                <a href={locationDetails?.location_photo} download style={{ maxWidth: "350px", maxHeight: "250px" }}>
                  <img src={locationDetails?.location_photo} alt="" style={{ maxWidth: "350px", maxHeight: "250px" }} />
                </a>
                <button
                  type="button"
                  onClick={toggleDeleteModal}
                  className="delete-button d-flex align-items-center me-2"
                >
                  <img src={trash} alt="delete" />
                  Delete
                </button>
              </div>
            )}
          </div>
        ) : (
          ""
        )}
      </div>
      <ProductLocationModal
        values={values}
        region={region}
        setRegion={setRegion}
        setValues={setValues}
        show={showProductLocationModal}
        locationDetails={locationDetails}
        setShowProductLocationModal={props?.setShowProductLocationModal}
        onHide={toggleModal}
        handleSubmit={handleSubmit}
        populateFromSeller={populateFromSeller}
        generateViaZip={generateViaZip}
        loadingProductLocation={loadingProductLocation}
        setUploadDocuments={setUploadDocuments}
        storageDate={values.storage_date}
        getCountries={props.getCountries}
        getStates={props.getStates}
      />
      <UploadImage
        show={showUploadImage}
        onHide={() => setShowUploadImage(false)}
        imageUploaded={imageUploaded}
        setImageUploaded={setImageUploaded}
        loadingUpload={loadingUpload}
        handleImageUploader={handleImageUploader}
        handleDeleteImage={handleDeleteImage}
        locationDetails={locationDetails}
        progress={progress}
      />
      <DeleteModal
        show={showDeleteModal}
        onHide={toggleDeleteModal}
        loadingDelete={loadingDelete}
        deleteHandler={handleDeleteImage}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateProductLocation: (params, data, callback) => dispatch(updateProductLocation(params, data, callback)),

  getProductLocation: (params, callback) => dispatch(getProductLocation(params, callback)),
  getMapViaZip: (params, zip, callback) => dispatch(getMapViaZip(params, zip, callback)),

  getStates: (callback) => dispatch(getStates(callback)),
  getCountries: (callback) => dispatch(getCountries(callback)),
  productDetails: (params, callback) => dispatch(productDetails(params, callback)),
  populateFromSeller: (params, callback) => dispatch(populateFromSeller(params, callback)),
  getLocSec: (params, callback) => dispatch(getMoreDetails(params, callback)),
  updateLocSec: (params, data, callback) => dispatch(updateLocationSecurity(params, data, callback)),
  uploadLocationImage: (params, data, callback) => dispatch(uploadLocationImage(params, data, callback)),
  deleteLocationImage: (params, callback) => dispatch(deleteLocationImage(params, callback)),
  setShowProductLocationModal: (data) => dispatch(setLocationModal(data)),
  getAuctionSpecies: (params, callback) => dispatch(getAuctionSpecies(params, callback)),
});
const mapStateToProps = (state) => ({
  productLocationDetails: productLocationDetails(state),
  locationModal: getModals(state)?.locationModal,
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(ProductLocation));
