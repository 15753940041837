import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import SustainabilityModal from "../../../layouts/modals/sustainabilityModal";
import {
  getEnvironmentalOrganization,
  getListingProductDetail,
  sustainabilityReportReceived,
  updateSustainabilityReport,
} from "../../../store/listingProduct";
import { toast } from "react-toastify";
import AlertError from "../../../common/alerts/alertError";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import ComingSoonCard from "../../../layouts/comingSoonCard";
import Skeleton from "react-loading-skeleton";
import { ei } from "../../../store/ei";
import Animal from "../animals/animal";

const SustainabilityReport = (props) => {
  const savedSpecies = props?.ei?.auctionSpecies?.savedSpecies;
  const envOrgs = props?.getListingProductDetail?.envOrganization?.data;
  const [showSustainabilityModal, setShowSustainabilityModal] = useState(false);
  const [loadingSustainabilty, setLoadingSustainability] = useState(false);
  const [envOrganization, setEnvOrganization] = useState([]);
  const [values, setValues] = useState({
    raw_materials_pct: 0,
    transportation_pct: 0,
    assembly_pct: 0,
    co2e_rate: 0,
    co2e_rate_unit: "",
    co2e_qty: 0,
    co2e_total: 0,
    env_org: "",
  });

  const toggleSustainabilityModal = () => {
    setShowSustainabilityModal(!showSustainabilityModal);
  };

  //Get Environmental Organization
  useEffect(() => {
    if (savedSpecies?.species) {
      props.getEnvironmentalOrganization({ species: savedSpecies?.species }, (res) => {
        if (res.status === 200) {
          setEnvOrganization(res.data.data);
        } else {
          toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        }
      });
    }
  }, [savedSpecies]);

  const handleSubmit = () => {
    setLoadingSustainability(true);
    props.refStart();
    const id = props.auctionId;
    props.updateSustainabilityReport(id, values, (res) => {
      if (res.status === 200) {
        setLoadingSustainability(false);
        props.refStop();
        const detail = { detail: values };
        props.sustainabilityReportReceived(detail);
        toggleSustainabilityModal();
        toast(<AlertSuccess message={"Information Saved"} />);
      } else {
        setLoadingSustainability(false);
        props.refStop();
        toast(<AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const details =
    (props.getListingProductDetail &&
      props.getListingProductDetail.sustainabilityReport &&
      props.getListingProductDetail.sustainabilityReport.detail) ||
    {};

  const envOrg =
    (props.getListingProductDetail &&
      props.getListingProductDetail.sustainabilityReport &&
      props.getListingProductDetail.sustainabilityReport.environmentalOrganization) ||
    [];

  const envOrganizationList =
    (props?.getListingProductDetail &&
      props?.getListingProductDetail?.sustainabilityReport &&
      props?.getListingProductDetail?.sustainabilityReport?.auctionEnvironmentalOrganization?.map(
        (item) => item.enterprise_id,
      )) ||
    [];

  useEffect(() => {
    if (showSustainabilityModal) {
      const data = {};
      data.raw_materials_pct = details.raw_materials_pct ? details.raw_materials_pct : 0;
      data.transportation_pct = details.transportation_pct ? details.transportation_pct : 0;
      data.assembly_pct = details.assembly_pct ? details.assembly_pct : 0;
      data.co2e_qty = details.co2e_qty ? details.co2e_qty : 0;
      data.co2e_rate = details.co2e_rate ? details.co2e_rate : 0;
      data.co2e_rate_unit = details.co2e_rate_unit ? details.co2e_rate_unit : "";
      data.co2e_total = details.co2e_total ? details.co2e_total : 0;
      data.env_org = envOrganizationList ? envOrganizationList.toString() : "";
      setValues({ ...data });
    }
  }, [showSustainabilityModal]);

  return (
    <div class="details-page-widget-block" id={props.id}>
      <div class="page-widget-head d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <div
            class="page-widget-label text-capitalize cursor-pointer"
            onClick={() => !props.loadingMain && toggleSustainabilityModal()}
          >
            Sustainability Report
          </div>
        </div>
        <div class="btn-block d-flex align-items-center">
          {props.loadingMain ? (
            <Skeleton />
          ) : (
            <a
              href={`/report/sustainability-report?id=${props?.auctionId}`}
              target="_blank"
              class="btn btn-primary btn-sm"
            >
              Sustainability Report
            </a>
          )}
        </div>
      </div>
      <div class="page-widget-body mt-3">
        <div class="table-responsive">
          <table class="table mb-0  product-table">
            <colgroup>
              <col style={{ width: "35%" }} />
              <col />
            </colgroup>
            <tbody>
              {props.loadingMain ? (
                <>
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                </>
              ) : (
                <>
                  <tr>
                    <td>Raw Materials Pct</td>
                    <td>{details.raw_materials_pct ? details.raw_materials_pct + "%" : "N/A"}</td>
                  </tr>
                  <tr>
                    <td>Transportation Pct</td>
                    <td>{details.transportation_pct ? details.transportation_pct + "%" : "N/A"}</td>
                  </tr>
                  <tr>
                    <td>Assembly Pct</td>
                    <td>{details.assembly_pct ? details.assembly_pct + "%" : "N/A"}</td>
                  </tr>
                  <tr>
                    <td>CO2e Rate</td>
                    <td>{details.co2e_rate ? details.co2e_rate : "N/A"}</td>
                  </tr>
                  <tr>
                    <td>CO2e Rate Unit</td>
                    <td>{details.co2e_rate_unit ? details.co2e_rate_unit : "N/A"}</td>
                  </tr>
                  <tr>
                    <td>CO2e Qty</td>
                    <td>{details.co2e_qty ? details.co2e_qty : "N/A"}</td>
                  </tr>
                  <tr>
                    <td>CO2e Total</td>
                    <td>{details.co2e_total ? details.co2e_total : "N/A"}</td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </div>
        <div class="dividor"></div>
        <Animal
          auctionId={props?.auctionId}
          loadingMain={props?.loadingSpecies}
          refStart={props?.refStart}
          refStop={props?.refStop}
          id={"animalRef"}
        />
        <div class="dividor"></div>
        <div class="d-flex align-items-center justify-content-between">
          <div class="page-widget-label text-capitalize cursor-pointer">Environmental Organizations</div>
        </div>
        <div class="page-widget-body mt-3">
          <div class="grid-image-list-block mb-3">
            <div class="grid-image-list d-flex flex-wrap">
              {envOrgs &&
                envOrgs?.length > 0 &&
                envOrgs?.map((val) => (
                  <div class="grid-image-item">
                    <div class="grid-image-box w-100 h-100 overflow-hidden position-relative">
                      <img
                        class="grid-images"
                        src={`${process.env.REACT_APP_EI_MEDIA}${val?.coverImage?.original}`}
                        alt=""
                      />
                      <div class="grid-hover d-flex flex-column align-items-center justify-content-center">
                        <div class="d-inline-block">
                          <div class="grid-hover-label">{val?.name}</div>
                          {/* <div class="grid-hover-list d-flex flex-wrap align-items-center justify-content-between">
                            <div class="grid-hover-item">
                              <div class="d-flex-img d-flex flex-wrap align-items-center justify-content-center overflow-hidden">
                                <img src="../../../include/images/star.svg" alt="" />
                              </div>
                            </div>
                            <div class="grid-hover-item">
                              <div class="d-flex-img d-flex flex-wrap align-items-center justify-content-center overflow-hidden">
                                <img src="../../../include/images/edit-3.svg" alt="" />
                              </div>
                            </div>
                            <div class="grid-hover-item">
                              <div class="d-flex-img d-flex flex-wrap align-items-center justify-content-center overflow-hidden">
                                <img src="../../../include/images/maximize-2.svg" alt="" />
                              </div>
                            </div>
                            <div class="grid-hover-item">
                              <div class="d-flex-img d-flex flex-wrap align-items-center justify-content-center overflow-hidden">
                                <img src="../../../include/images/crop.svg" alt="" />
                              </div>
                            </div>
                            <div class="grid-hover-item">
                              <div class="d-flex-img d-flex flex-wrap align-items-center justify-content-center overflow-hidden">
                                <img src="../../../include/images/rotate-cw.svg" alt="" />
                              </div>
                            </div>
                            <div class="grid-hover-item">
                              <div class="d-flex-img d-flex flex-wrap align-items-center justify-content-center overflow-hidden">
                                <img src="../../../include/images/trash-13x13.svg" alt="" />
                              </div>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/* <div class=" mt-2">
          <div className="env_box">
            {[1, 2, 3, 4, 5, 6, 7, 7, 8, 9].map((item) => (
              <div>
                <img src={`${process.env.REACT_APP_MEDIA_URL}/co/logos/${item?.proc_logo}`} alt="" />

                <p>{item?.name}</p>
              </div>
            ))}
          </div>
        </div> */}
        {/* <div className="d-flex justify-content-center mt-3">
          <div class="d-title">Environmental Organizations</div>
        </div>
        <div>
          <img src="" alt="" />
        </div> */}
      </div>
      <SustainabilityModal
        show={showSustainabilityModal}
        onHide={toggleSustainabilityModal}
        envOrganization={envOrganization}
        envOrganizationList={envOrganizationList}
        loadingSustainabilty={loadingSustainabilty}
        values={values}
        setValues={setValues}
        handleSubmit={handleSubmit}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  //global state for storing api value
  updateSustainabilityReport: (params, data, callback) => dispatch(updateSustainabilityReport(params, data, callback)),
  getEnvironmentalOrganization: (params, callback) => dispatch(getEnvironmentalOrganization(params, callback)),

  //global state managed
  sustainabilityReportReceived: (data) => dispatch(sustainabilityReportReceived(data)),
});

const mapStateToProps = (state) => ({
  getListingProductDetail: getListingProductDetail(state),
  ei: ei(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(SustainabilityReport));
