import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import close from "../../../include/images/close.svg";
import emptyFolder from "../../../include/images/folder.svg";
import imageFill from "../../../include/images/image.svg";
import imageOutLine from "../../../include/images/Image-outline.svg";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import { twoDecimals } from "../../../utils.js/conversion";
import ImageLargeModal from "../../../layouts/modals/imageGalleryModal";
import DateFormat from "../../../common/DateFormat/dateFormat";

const LogModal = (props) => {
  return (
    <Modal
      className="modal fade custom-modal"
      id="editProductDescription"
      dialogClassName="modal-dialog modal-dialog-centered modal-xxl modal-wide"
      show={props.show}
      onHide={() => props.onHide()}
    >
      <div class="modal-content d-flex flex-column">
        <div
          class="custom-modal-header d-flex align-items-center justify-content-between"
          style={{ paddingBottom: "0px" }}
        >
          <h5 class="mb-0 text-capitalize" style={{ marginLeft: "30px", lineHeight: "1.2" }}>
            {props?.log ? (
              <span className="mb-3"> Log Id : {props.tableData?.logId ? props.tableData?.logId : ""}</span>
            ) : (
              <span className="mb-3"> Request Id : {props.tableData?.orderId ? props.tableData?.orderId : ""}</span>
            )}
            <br />
            <span className="gray-color-text gray-color-text-xl mt-3">
              {props.tableData?.name ? props.tableData?.name : ""}
            </span>
            <br />
            <span className="gray-color-text gray-color-text-xl mt-3">
              {props.tableData?.email ? props.tableData?.email : ""}
            </span>
            <br />
            <span className="gray-color-text gray-color-text-xl mt-3">
              {props?.tableData?.createdAt ? moment(props?.tableData?.createdAt).format("MMM-DD-YYYY") : ""}
            </span>
          </h5>
          <button
            type="button"
            class="close-btn d-flex align-items-center justify-content-center"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => props.onHide()}
          >
            <img src={close} alt="" />
          </button>
        </div>
        <Modal.Body className="custom-modal-body flex-grow-1 w-100 overflow-hidden">
          <div class="table-container">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12">
                  <div class="selected-table-row d-flex justify-content-between"></div>
                  <div
                    class={`table-responsive position-relative ${
                      props.tableData && props.tableData?.logs?.length > 0 ? "min-user-table-height" : ""
                    }`}
                  >
                    <div class="table-selected-count d-none align-items-center justify-content-between">
                      <div class="tsc-check-label d-flex align-items-center">
                        <div class="tsc-checkbox">
                          <div class="form-check tick-checkbox">
                            <input class="form-check-input" type="checkbox" value="" id="checkAll" />
                            <label class="form-check-label" for="checkAll"></label>
                          </div>
                        </div>
                        <div class="link-label fw-medium">2 Selected</div>
                        <div class="link-clear fw-medium">Clear all</div>
                      </div>
                    </div>
                    <div class="table-responsive table-custom">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th style={{ width: "9%", minWidth: "133px" }}>
                              <a className={`sort-by`}> Part Number </a>{" "}
                            </th>
                            {!props?.procurement && (
                              <th style={{ width: "9%", minWidth: "180px" }}>
                                <a className={`sort-by`}> Part Description </a>{" "}
                              </th>
                            )}
                            <th style={{ width: "9%" }}>
                              <a className={`sort-by`}>Condition </a>{" "}
                            </th>
                            <th style={{ width: "9%", minWidth: "60px" }}>
                              <span className={`d-flex justify-content-end`}>QTY </span>
                            </th>
                            {props?.procurement && (
                              <th style={{ width: "9%" }}>
                                <a className={`sort-by`}>Date </a>
                              </th>
                            )}
                            {!props?.procurement && (
                              <th style={{ width: "9%", minWidth: "130px" }}>
                                <span className={`d-flex justify-content-end`}>
                                  Original Unit
                                  <br /> Price{" "}
                                </span>
                              </th>
                            )}
                            {!props?.procurement && (
                              <th style={{ width: "9%", minWidth: "130px" }}>
                                <span className={`d-flex justify-content-end`}>
                                  Public Unit
                                  <br /> Price{" "}
                                </span>
                              </th>
                            )}
                            {!props?.procurement && (
                              <th style={{ width: "9%" }}>
                                <a className={`sort-by`}>Location </a>
                              </th>
                            )}
                            {!props?.procurement && (
                              <th style={{ width: "5%" }}>
                                <a className={`sort-by`}>Certificate </a>{" "}
                              </th>
                            )}
                            {!props?.procurement && (
                              <th style={{ width: "5%" }}>
                                <a className={`sort-by`}>Part Type</a>{" "}
                              </th>
                            )}
                            {!props?.procurement && (
                              <th style={{ width: "5%" }}>
                                <a className={`sort-by`}>Part Manufacturer</a>{" "}
                              </th>
                            )}
                            {!props?.procurement && (
                              <th style={{ width: "5%" }}>
                                <a className={`sort-by`}>Plane Model</a>{" "}
                              </th>
                            )}
                            {!props?.procurement && (
                              <th style={{ width: "5%" }}>
                                <a className={`sort-by`}>Plane Manufacturer</a>{" "}
                              </th>
                            )}

                            {!props?.procurement && (
                              <th style={{ width: "9%", maxWidth: "80px" }}>
                                <a className="sort-by"> Images </a>{" "}
                              </th>
                            )}
                            {!props?.procurement && (
                              <th style={{ width: "9%", minWidth: "100px" }}>
                                <a className="sort-by"> Listing ID </a>{" "}
                              </th>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {props.loading ? (
                            [1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2, 3, 4].map((item) => (
                              <tr>
                                {!props?.procurement && (
                                  <td style={{ width: "9%", minWidth: "133px" }}>
                                    <Skeleton />
                                  </td>
                                )}
                                <td style={{ width: "9%" }}>
                                  <Skeleton />
                                </td>
                                <td style={{ width: "9%" }}>
                                  <Skeleton />
                                </td>
                                <td style={{ width: "9%" }}>
                                  <Skeleton />
                                </td>
                                <td style={{ width: "9%" }}>
                                  <Skeleton />
                                </td>
                                {!props?.procurement && (
                                  <td style={{ width: "9%" }}>
                                    <Skeleton />
                                  </td>
                                )}
                                {!props?.procurement && (
                                  <td style={{ width: "9%" }}>
                                    <Skeleton />
                                  </td>
                                )}
                                {!props?.procurement && (
                                  <td style={{ width: "9%" }}>
                                    <Skeleton />
                                  </td>
                                )}
                                {!props?.procurement && (
                                  <td style={{ width: "9%" }}>
                                    <Skeleton />
                                  </td>
                                )}
                                {!props?.procurement && (
                                  <td style={{ width: "9%" }}>
                                    <Skeleton />
                                  </td>
                                )}
                                {!props?.procurement && (
                                  <td style={{ width: "9%" }}>
                                    <Skeleton />
                                  </td>
                                )}
                                {!props?.procurement && (
                                  <td style={{ width: "9%" }}>
                                    <Skeleton />
                                  </td>
                                )}
                                {!props?.procurement && (
                                  <td style={{ width: "9%" }}>
                                    <Skeleton />
                                  </td>
                                )}
                                {!props?.procurement && (
                                  <td style={{ width: "9%" }}>
                                    <Skeleton />
                                  </td>
                                )}
                              </tr>
                            ))
                          ) : props?.log ? (
                            props.data &&
                            props.data?.length > 0 &&
                            props.data?.map((item) => {
                              return (
                                <tr>
                                  <td style={{ minWidth: "130px" }}>
                                    <span
                                      className="aviation-auction link-item"
                                      onClick={() => {
                                        props.setSelectedValue(item);
                                        props.toggleViewAllModal();
                                      }}
                                    >
                                      {" "}
                                      {item?.aviationPart?.partNumber ? item?.aviationPart?.partNumber : ""}
                                    </span>
                                  </td>
                                  <td>
                                    {" "}
                                    {item?.aviationPart?.partDescription ? item?.aviationPart?.partDescription : ""}
                                  </td>
                                  <td>
                                    {" "}
                                    {item?.aviationPart?.condition
                                      ? `(${item?.aviationPart?.condition}) ${item?.aviationPart?.conditionTitle}`
                                      : ""}
                                  </td>
                                  <td align="right">
                                    {" "}
                                    {item?.aviationPart?.quantity ? item?.aviationPart?.quantity : ""}
                                  </td>
                                  <td align="right">
                                    {" "}
                                    {item?.aviationPart?.unitPrice ? twoDecimals(item?.aviationPart?.unitPrice) : ""}
                                  </td>
                                  <td align="right">
                                    {" "}
                                    {item?.aviationPart?.unitPrice
                                      ? twoDecimals(item?.aviationPart?.unitPrice * 1.2)
                                      : ""}
                                  </td>
                                  <td> {item?.aviationPart?.location ? item?.aviationPart?.location : ""}</td>
                                  <td>
                                    {" "}
                                    {item?.aviationPart?.certificate === true
                                      ? "Yes"
                                      : item?.aviationPart?.certificate === false
                                      ? "No"
                                      : ""}
                                  </td>
                                  <td> {item?.aviationPart?.partType ? item?.aviationPart?.partType : ""}</td>
                                  <td>
                                    {" "}
                                    {item?.aviationPart?.partManufacturer ? item?.aviationPart?.partManufacturer : ""}
                                  </td>
                                  <td>
                                    {" "}
                                    {item?.aviationPart?.planeModels?.length > 0
                                      ? item?.aviationPart?.planeModels?.map((val) => val).join(", ")
                                      : item?.aviationPart?.planeModels?.length != 0
                                      ? item?.aviationPart?.planeModels
                                      : ""}
                                  </td>
                                  <td>
                                    {" "}
                                    {item?.aviationPart?.planeManufacturer ? item?.aviationPart?.planeManufacturer : ""}
                                  </td>

                                  <td
                                    align="center"
                                    className={`aviation-auction ${
                                      item?.aviationPart?.images?.length > 0 ? "link-item" : ""
                                    } `}
                                    onClick={() => {
                                      if (item?.aviationPart?.images?.length > 0) {
                                        const a = [];
                                        item?.aviationPart?.images?.map((de) => {
                                          props?.checkIfImageExists(
                                            `https://s3.amazonaws.com/uploads-dev.salvex.com/public/aviation-parts/images/${item?.aviationPart?.auctionId}/${de}`,
                                            (exists) => {
                                              if (exists) {
                                                a.push({
                                                  original: `https://s3.amazonaws.com/uploads-dev.salvex.com/public/aviation-parts/images/${item?.aviationPart?.auctionId}/${de}`,
                                                  thumbnail: `https://s3.amazonaws.com/uploads-dev.salvex.com/public/aviation-parts/images/${item?.aviationPart?.auctionId}/${de}`,
                                                });
                                              } else {
                                                a.push({
                                                  original:
                                                    "https://media.salvex.com/auction/p/1830012/183001141_641791_mp.jpg",
                                                  thumbnail:
                                                    "https://media.salvex.com/auction/p/1830012/183001141_641791_mp.jpg",
                                                });
                                              }
                                            },
                                          );
                                        });
                                        props?.setSelecetedImage(a);
                                        setTimeout(() => {
                                          props?.toggleImageGalleryModal();
                                        }, 700);
                                      }
                                    }}
                                  >
                                    {item?.aviationPart?.images?.length > 0 ? (
                                      <img src={imageFill} alt="Image Exists" />
                                    ) : (
                                      <img src={imageOutLine} alt="Image not exists" />
                                    )}
                                  </td>
                                  <td>
                                    {item?.aviationPart.auctionId ? (
                                      <a
                                        className="aviation-auction link-item"
                                        href={`/listing-detail?id=${item?.aviationPart.auctionId}`}
                                        target="_blank"
                                      >
                                        {item?.aviationPart?.auctionId}
                                      </a>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                </tr>
                              );
                            })
                          ) : props?.orderRequest ? (
                            <tr>
                              <td style={{ minWidth: "130px" }}>
                                <span
                                  className="aviation-auction link-item"
                                  onClick={() => {
                                    props.setSelectedValue(props?.tableData);
                                    props.toggleViewAllModal();
                                  }}
                                >
                                  {" "}
                                  {props?.tableData?.aviationPart?.partNumber
                                    ? props?.tableData?.aviationPart?.partNumber
                                    : ""}
                                </span>
                              </td>
                              <td>
                                {" "}
                                {props?.tableData?.aviationPart?.partDescription
                                  ? props?.tableData?.aviationPart?.partDescription
                                  : ""}
                              </td>
                              <td>
                                {" "}
                                {props?.tableData?.aviationPart?.condition
                                  ? `(${props?.tableData?.aviationPart?.condition}) ${props?.tableData?.aviationPart?.conditionTitle}`
                                  : ""}
                              </td>
                              <td align="right">
                                {" "}
                                {props?.tableData?.aviationPart?.quantity
                                  ? props?.tableData?.aviationPart?.quantity
                                  : ""}
                              </td>
                              <td align="right">
                                {" "}
                                {props?.tableData?.aviationPart?.unitPrice
                                  ? twoDecimals(props?.tableData?.aviationPart?.unitPrice)
                                  : ""}
                              </td>
                              <td align="right">
                                {" "}
                                {props?.tableData?.aviationPart?.unitPrice
                                  ? twoDecimals(props?.tableData?.aviationPart?.unitPrice * 1.2)
                                  : ""}
                              </td>
                              <td>
                                {" "}
                                {props?.tableData?.aviationPart?.location
                                  ? props?.tableData?.aviationPart?.location
                                  : ""}
                              </td>
                              <td>
                                {" "}
                                {props?.tableData?.aviationPart?.certificate === true
                                  ? "Yes"
                                  : props?.tableData?.aviationPart?.certificate === false
                                  ? "No"
                                  : ""}
                              </td>
                              <td>
                                {" "}
                                {props?.tableData?.aviationPart?.partType
                                  ? props?.tableData?.aviationPart?.partType
                                  : ""}
                              </td>
                              <td>
                                {" "}
                                {props?.tableData?.aviationPart?.planeModels?.length > 0
                                  ? props?.tableData?.aviationPart?.planeModels?.map((val) => val).join(", ")
                                  : props?.tableData?.aviationPart?.planeModels?.length != 0
                                  ? props?.tableData?.aviationPart?.planeModels
                                  : ""}
                              </td>
                              <td>
                                {" "}
                                {props?.tableData?.aviationPart?.planeManufacturer
                                  ? props?.tableData?.aviationPart?.planeManufacturer
                                  : ""}
                              </td>
                              <td>
                                {" "}
                                {props?.tableData?.aviationPart?.partManufacturer
                                  ? props?.tableData?.aviationPart?.partManufacturer
                                  : ""}
                              </td>
                              <td
                                align="center"
                                className={`aviation-auction ${
                                  props?.tableData?.aviationPart?.images?.length > 0 ? "link-item" : ""
                                } `}
                                onClick={() => {
                                  if (props?.tableData?.aviationPart?.images?.length > 0) {
                                    const a = [];
                                    props?.tableData?.aviationPart?.images?.map((de) => {
                                      props?.checkIfImageExists(
                                        `https://s3.amazonaws.com/uploads-dev.salvex.com/public/aviation-parts/images/${props?.tableData?.aviationPart?.auctionId}/${de}`,
                                        (exists) => {
                                          if (exists) {
                                            a.push({
                                              original: `https://s3.amazonaws.com/uploads-dev.salvex.com/public/aviation-parts/images/${props?.tableData?.aviationPart?.auctionId}/${de}`,
                                              thumbnail: `https://s3.amazonaws.com/uploads-dev.salvex.com/public/aviation-parts/images/${props?.tableData?.aviationPart?.auctionId}/${de}`,
                                            });
                                          } else {
                                            a.push({
                                              original:
                                                "https://media.salvex.com/auction/p/1830012/183001141_641791_mp.jpg",
                                              thumbnail:
                                                "https://media.salvex.com/auction/p/1830012/183001141_641791_mp.jpg",
                                            });
                                          }
                                        },
                                      );
                                    });
                                    props?.setSelecetedImage(a);
                                    setTimeout(() => {
                                      props?.toggleImageGalleryModal();
                                    }, 700);
                                  }
                                }}
                              >
                                {props?.tableData?.aviationPart?.images?.length > 0 ? (
                                  <img src={imageFill} alt="Image Exists" />
                                ) : (
                                  <img src={imageOutLine} alt="Image not exists" />
                                )}
                              </td>
                              <td>
                                {props?.tableData?.aviationPart?.auctionId ? (
                                  <a
                                    className="aviation-auction link-item"
                                    href={`/listing-detail?id=${props?.tableData?.aviationPart?.auctionId}`}
                                    target="_blank"
                                  >
                                    {props?.tableData?.aviationPart?.auctionId}
                                  </a>
                                ) : (
                                  ""
                                )}
                              </td>
                            </tr>
                          ) : (
                            props.tableData &&
                            props.tableData?.aviationParts?.length > 0 &&
                            props.tableData?.aviationParts?.map((item) => {
                              return (
                                <tr>
                                  <td style={{ minWidth: "130px" }}>
                                    <span> {item?.partNumber ? item?.partNumber : ""}</span>
                                  </td>
                                  {!props?.procurement && (
                                    <td> {item?.partDescription ? item?.partDescription : ""}</td>
                                  )}
                                  <td> {item?.condition ? `${item?.condition}` : ""}</td>
                                  <td align="right"> {item?.quantity ? item?.quantity : ""}</td>
                                  {props?.procurement && (
                                    <td>
                                      {" "}
                                      {props?.tableData?.createdAt ? (
                                        <DateFormat date={props?.tableData?.createdAt} />
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                  )}
                                  {!props?.procurement && (
                                    <td> {item?.unitPrice ? twoDecimals(item?.unitPrice) : ""}</td>
                                  )}
                                  {!props?.procurement && (
                                    <td> {item?.unitPrice ? twoDecimals(item?.unitPrice * 1.2) : ""}</td>
                                  )}
                                  {!props?.procurement && <td> {item?.location ? item?.location : ""}</td>}
                                  {!props?.procurement && <td> {item?.certificate ? "Yes" : "No"}</td>}
                                  {!props?.procurement && <td> {item?.engineType ? item?.engineType : ""}</td>}
                                  {!props?.procurement && (
                                    <td> {item?.planeManufacturer ? item?.planeManufacturer : ""}</td>
                                  )}
                                  {!props?.procurement && <td> {item?.aircraftType ? item?.aircraftType : ""}</td>}
                                  {!props?.procurement && (
                                    <td> {item?.partManufacturer ? item?.partManufacturer : ""}</td>
                                  )}
                                  {!props?.procurement && (
                                    <td
                                      align="center"
                                      className={`aviation-auction ${item?.images?.length > 0 ? "link-item" : ""} `}
                                      onClick={() => {
                                        if (item?.images?.length > 0) {
                                          const a = [];
                                          item?.images?.map((de) => {
                                            props?.checkIfImageExists(
                                              `https://s3.amazonaws.com/uploads-dev.salvex.com/public/aviation-parts/images/${item?.auctionId}/${de}`,
                                              (exists) => {
                                                if (exists) {
                                                  a.push({
                                                    original: `https://s3.amazonaws.com/uploads-dev.salvex.com/public/aviation-parts/images/${item?.auctionId}/${de}`,
                                                    thumbnail: `https://s3.amazonaws.com/uploads-dev.salvex.com/public/aviation-parts/images/${item?.auctionId}/${de}`,
                                                  });
                                                } else {
                                                  a.push({
                                                    original:
                                                      "https://media.salvex.com/auction/p/1830012/183001141_641791_mp.jpg",
                                                    thumbnail:
                                                      "https://media.salvex.com/auction/p/1830012/183001141_641791_mp.jpg",
                                                  });
                                                }
                                              },
                                            );
                                          });
                                          props?.setSelecetedImage(a);
                                          setTimeout(() => {
                                            props?.toggleImageGalleryModal();
                                          }, 700);
                                        }
                                      }}
                                    >
                                      {item?.images?.length > 0 ? (
                                        <img src={imageFill} alt="Image Exists" />
                                      ) : (
                                        <img src={imageOutLine} alt="Image not exists" />
                                      )}
                                    </td>
                                  )}
                                  {!props?.procurement && (
                                    <td>
                                      {" "}
                                      {item?.auctionId ? (
                                        <a
                                          className="aviation-auction link-item"
                                          href={`/listing-detail?id=${item?.auctionId}`}
                                          target="_blank"
                                        >
                                          {item?.auctionId}
                                        </a>
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                  )}
                                </tr>
                              );
                            })
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {!props.loading && props.tableData?.logs && props.tableData?.logs?.length === 0 && (
                    <>
                      <div class="table-empty">
                        <div class="table-empty-inner">
                          <img src={emptyFolder} alt="" />
                          <p>No Items Found</p>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default LogModal;
