import React, { useEffect, useRef, useState } from "react";
import LoadingBar from "react-top-loading-bar";
import Header from "../../layouts/header";
import { connect } from "react-redux";
import ListingAgreementsTable from "../../components/legal/listingAgreements/listingAgreementsTable";
import { dashboardData, getListingAgreement } from "../../store/dashBoard";
import FilterListingAgreements from "../../components/legal/listingAgreements/modal/filterListingAgreements";
import AlertError from "../../common/alerts/alertError";
import { toast } from "react-toastify";

const ListingAgreements = (props) => {
  const ref = useRef(null);

  const [loading, setLoading] = useState(false);

  //params state
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState("createdAt");
  const [order, setOrder] = useState("desc");
  const [showFilter, setShowFilter] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [auctionId, setAuctionId] = useState("");
  const [response, setResponse] = useState({
    auctionId: "",
    keyword: "",
  });

  const toggleFilter = () => {
    setKeyword(response?.keyword);
    setAuctionId(response?.auctionId);
    setShowFilter(!showFilter);
  };

  const data = props?.dashboardData?.listingAgreement?.data;
  const totalCount = props.dashboardData?.listingAgreement?.total_records;
  useEffect(() => {
    ref.current.continuousStart();
    setLoading(true);
    const params = {
      page: page,
      sort,
      order,
      keyword: response?.keyword,
      auctionId: response?.auctionId,
    };
    props.getListingAgreement(params, (res) => {
      if (res && res.status === 200) {
        ref.current.complete();
        setLoading(false);
      } else {
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        ref.current.complete();
        setLoading(false);
      }
    });
  }, [page]);

  //Sorting
  useEffect(() => {
    ref.current.continuousStart();
    setLoading(true);
    setPage(1);
    const params = {
      page: 1,
      sort,
      order,
      keyword: response?.keyword,
      auctionId: response?.auctionId,
    };
    props.getListingAgreement(params, (res) => {
      if (res && res.status === 200) {
        ref.current.complete();
        setLoading(false);
      } else {
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        ref.current.complete();
        setLoading(false);
      }
    });
  }, [sort, order, response]);

  const handleFilter = () => {
    ref?.current?.continuousStart();
    setLoading(true);
    setPage(1);
    const params = {
      page: 1,
      sort,
      order,
      keyword,
      auctionId,
    };
    props.getListingAgreement(params, (res) => {
      if (res && res.status === 200) {
        const data = { ...response };
        data.auctionId = auctionId;
        data.keyword = keyword;
        setResponse(data);
        ref.current.complete();
        setLoading(false);
        toggleFilter();
      } else {
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        ref.current.complete();
        setLoading(false);
      }
    });
  };
  return (
    <>
      <LoadingBar height={5} color="#47AD1D" ref={ref} />
      <Header />
      <ListingAgreementsTable
        loading={loading}
        data={data}
        sort={sort}
        setSort={setSort}
        order={order}
        setOrder={setOrder}
        page={page}
        setPage={setPage}
        totalCount={totalCount}
        toggleFilter={toggleFilter}
        response={response}
        setResponse={setResponse}
      />
      <FilterListingAgreements
        show={showFilter}
        onHide={toggleFilter}
        keyword={keyword}
        setKeyword={setKeyword}
        auctionId={auctionId}
        setAuctionId={setAuctionId}
        loading={loading}
        handleFilter={handleFilter}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getListingAgreement: (params, callback) => dispatch(getListingAgreement(params, callback)),
});

const mapStateToProps = (state) => ({
  dashboardData: dashboardData(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(ListingAgreements));
