import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { connect } from "react-redux";
import { flatten } from "lodash";
import { customStyles } from "../../common/customStyles/reactSelectStyle";
import {
  getCategories,
  getIndustry,
  getUser,
  getUserReport,
} from "../../store/users";
import {
  Chart as ChartJs,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar, Chart } from "react-chartjs-2";
import NextButton from "../../common/form/nextButton";
import { toast } from "react-toastify";
import AlertError from "../../common/alerts/alertError";
ChartJs.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const UserReport = (props) => {
  const ref = useRef(null);
  const [paramsData, setParamsData] = useState({});
  const [storingData, setStoringData] = useState({});
  const [reportData, setReportData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showGraph, setShowGraph] = useState(false);
  useEffect(() => {
    props.getIndustry((res) => {});
    props.getCategories((res) => {});
  }, []);

  const monthOptions = [
    { name: "January", value: "1" },
    { name: "February", value: "2" },
    { name: "March", value: "3" },
    { name: "April", value: "4" },
    { name: "May", value: "5" },
    { name: "June", value: "6" },
    { name: "July", value: "7" },
    { name: "August", value: "8" },
    { name: "September", value: "9" },
    { name: "October", value: "10" },
    { name: "November", value: "11" },
    { name: "December", value: "12" },
  ];
  const industryOption = props?.getUser?.industries?.map((industry) => ({
    value: industry.industry_id,
    name: industry.description,
  }));
  industryOption.unshift({
    name: "Please Select an Industry",
    value: "",
  });
  const categoryOptions = flatten(props?.getUser?.categories?.data)?.map(
    (category) => ({
      value: category?.category_id,
      name: category?.description,
    })
  );
  categoryOptions.unshift({
    name: "Please Select a Category",
    value: "",
  });

  const currentYear = new Date().getFullYear();
  const yearOptions = Array.from(
    { length: currentYear - 2000 + 1 },
    (_, index) => ({
      name: (2000 + index).toString(),
      value: 2000 + index,
    })
  );

  const [defaultYear, setDefaultYear] = useState({
    name: "Please Select a Year",
    value: -1,
  });
  const [defaultMonth, setDefaultMonth] = useState({
    name: "Please Select a Month",
    value: -1,
  });
  const [defaultCategory, setDefaultCategory] = useState({
    name: "Please Select a Category",
    value: -1,
  });
  const [defaultIndustry, setDefaultIndustry] = useState({
    name: "Please Select an Industry",
    value: -1,
  });

  let monthCounts = [];
  for (let i = 1; i <= 31; i++) {
    monthCounts.push(i);
  }

  const generateReport = () => {
    props.refStart && props.refStart();
    setLoading(true);
    let params = "";
    paramsData.year && (params += `&year=${paramsData.year}`);
    paramsData.month && (params += `&month=${paramsData.month}`);
    paramsData.category && (params += `&categoryId=${paramsData.category}`);
    paramsData.industry && (params += `&industryId=${paramsData.industry}`);
    if (paramsData.year && paramsData.month) {
      props.getUserReport(params, (res) => {
        if (res.status === 200) {
          setReportData(res.data.list);
          setStoringData(paramsData);
          setShowGraph(true);
          props.refStop && props.refStop();
          setLoading(false);
        } else {
          props.refStop && props.refStop();
          setLoading(false);
          toast(
            <AlertError
              message={
                res.data.message ? res.data.message : "Something Went Wrong"
              }
            />
          );
        }
      });
    } else {
      props.refStop && props.refStop();
      setLoading(false);
      toast(<AlertError message={"Year and Month are Mandatory"} />);
    }
  };

  const data = {
    labels: monthOptions.map((val) => val.name),
    datasets: [
      {
        label: "No of registrations",
        data: monthOptions.map((val) => reportData[val.value]?.length),
        backgroundColor: "green",
        borderColor: "black",
        borderWidth: 1,
      },
    ],
  };
  const datasets = {
    labels: monthCounts.map((val) => val),
    datasets: [
      {
        label: "No of registrations",
        data: monthCounts.map((val) => reportData[val]?.length),
        backgroundColor: "green",
        borderColor: "black",
        borderWidth: 1,
      },
    ],
  };

  const options = {};

  return (
    <>
      <div class="filter-container d-flex align-items-center sticky-top">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12 d-flex align-items-center flex-wrap justify-content-between">
              <div class="filter-title fw-medium text-capitalize">
                User Registration Report
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="detail-page-container pb-5">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="dpc-col-row d-flex flex-wrap">
                <div class="dpc-col-column">
                  <div
                    class="details-page-widget-block"
                    style={{ minHeight: "520px" }}
                  >
                    <div class="page-widget-body mt-3">
                      <table class="table table-borderless align-middle table-left-align">
                        <colgroup>
                          <col style={{ width: "32%" }} />
                          <col />
                        </colgroup>
                        <tbody>
                          <tr>
                            <td>Year</td>
                            <td>
                              <Select
                                className="basic-single"
                                classNamePrefix="select-search"
                                value={defaultYear}
                                placeholder="Please Select a Year"
                                options={yearOptions}
                                isDisabled={loading ? true : false}
                                styles={customStyles}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.value}
                                onChange={(e) => {
                                  const values = { ...paramsData };
                                  values.year = e.value;
                                  setParamsData(values);
                                  setDefaultYear({
                                    name: e.name,
                                    value: e.value,
                                  });
                                }}
                                name="Year"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Month</td>
                            <td>
                              <Select
                                className="basic-single"
                                classNamePrefix="select-search"
                                value={defaultMonth}
                                placeholder="Please Select a Month"
                                options={monthOptions}
                                isDisabled={loading ? true : false}
                                styles={customStyles}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.value}
                                onChange={(e) => {
                                  const values = { ...paramsData };
                                  values.month = e.value;
                                  setParamsData(values);
                                  setDefaultMonth({
                                    name: e.name,
                                    value: e.value,
                                  });
                                }}
                                name="Month"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Category</td>
                            <td>
                              <Select
                                className="basic-single"
                                classNamePrefix="select-search"
                                value={defaultCategory}
                                placeholder="Please Select a Category"
                                options={categoryOptions}
                                isDisabled={loading ? true : false}
                                styles={customStyles}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.value}
                                onChange={(e) => {
                                  const values = { ...paramsData };
                                  values.category = e.value;
                                  setParamsData(values);
                                  setDefaultCategory({
                                    name: e.name,
                                    value: e.value,
                                  });
                                }}
                                name="Category"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Industry</td>
                            <td>
                              <Select
                                className="basic-single"
                                classNamePrefix="select-search"
                                value={defaultIndustry}
                                placeholder="Please Select an Industry"
                                options={industryOption}
                                isDisabled={loading ? true : false}
                                styles={customStyles}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.value}
                                onChange={(e) => {
                                  const values = { ...paramsData };
                                  values.industry = e.value;
                                  setParamsData(values);
                                  setDefaultIndustry({
                                    name: e.name,
                                    value: e.value,
                                  });
                                }}
                                name="Industry"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div class="d-flex justify-content-end me-2">
                        <NextButton
                          label="Submit"
                          classData="btn btn-default"
                          handleSubmit={generateReport}
                          loading={loading}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="" style={{ padding: "6px", width: "66.67%" }}>
                  <div
                    class="details-page-widget-block"
                    style={{ minHeight: "520px", maxHeight: "520px" }}
                  >
                    <div
                      class="page-widget-body mt-3"
                      style={{ minHeight: "480px", maxHeight: "480px" }}
                    >
                      {showGraph ? (
                        <Bar
                          data={storingData.month ? datasets : data}
                          options={options}
                        />
                      ) : (
                        <div
                          class="page-widget-empty-box text-capitalize d-flex align-items-center justify-content-center text-center"
                          style={{
                            height: "450px",
                            flexDirection: "column",
                            gap: "10px",
                          }}
                        >
                          <span>
                            Please select your filters to plot a chart here.
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getIndustry: (callback) => dispatch(getIndustry(callback)),
  getCategories: (callback) => dispatch(getCategories(callback)),
  getUserReport: (params, callback) =>
    dispatch(getUserReport(params, callback)),
});
const mapStateToProps = (state) => ({
  getUser: getUser(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(UserReport));
