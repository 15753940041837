import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import Header from "../../layouts/header";
import { connect } from "react-redux";
import LoadingBar from "react-top-loading-bar";
import { toast } from "react-toastify";
import AlertError from "../../common/alerts/alertError";
import { getAviationDetails, getProcurementRequests } from "../../store/aviation";
import ProcurementTable from "../../components/aviation/procurementRequest/procurementTable";
import LogModal from "../../components/aviation/logs/logModal";

const Procurement = (props) => {
  const ref = useRef(null);
  const [loading, setLoading] = useState(false);

  //params state
  const [page, setPage] = useState(1);

  const [sort, setSort] = useState("createdAt");
  const [order, setOrder] = useState("desc");
  const [applyFilter, setApplyFilter] = useState(false);
  const [showLogModal, setShowLogModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [viewModal, setViewModal] = useState(false);
  const [selectedValue, setSelectedValue] = useState({});

  //filter
  const [showFilter, setShowFilter] = useState(false);
  const [filteredValues, setFilteredValues] = useState({
    userId: "",
    keyword: "",
  });
  const [response, setResponse] = useState({
    res: false,
    keyword: "",
    userId: "",
  });

  const toggleFilter = () => {
    const data = { ...filteredValues };
    data.userId = response?.userId;
    data.keyword = response?.keyword;
    setFilteredValues(data);
    setShowFilter(!showFilter);
  };
  const toggleLogModal = () => {
    setShowLogModal(!showLogModal);
  };
  const toggleViewModal = () => {
    setViewModal(!viewModal);
    setShowLogModal(!showLogModal);
  };

  useEffect(() => {
    ref.current.continuousStart();
    setLoading(true);
    const params = {
      page: 1,
      sort,
      order,
    };
    props.getProcurementRequests(params, (res) => {
      if (res && res.status === 200) {
        ref.current.complete();
        setLoading(false);
      } else {
        ref.current.complete();
        setLoading(false);
      }
    });
  }, []);

  useEffect(() => {
    ref.current.continuousStart();
    setLoading(true);
    const params = {
      page: page,
      sort,
      order,
    };
    if (response?.userId) {
      params.userId = response?.userId;
    }
    if (response?.keyword) {
      params.keyword = response?.keyword;
    }
    props.getProcurementRequests(params, (res) => {
      if (res && res.status === 200) {
        ref.current.complete();
        setLoading(false);
      } else {
        ref.current.complete();
        setLoading(false);
      }
    });
  }, [page]);

  const getSortedData = (sortBy, orderBy) => {
    setSort(sortBy);
    setOrder(orderBy);
    ref.current.continuousStart();
    setLoading(true);
    const params = {
      page: 1,
      sort: sortBy,
      order: orderBy,
    };
    if (response?.userId) {
      params.userId = response?.userId;
    }
    if (response?.keyword) {
      params.keyword = response?.keyword;
    }
    setPage(1);
    props.getProcurementRequests(params, (res) => {
      if (res && res.status === 200) {
        ref.current.complete();
        setLoading(false);
      } else {
        ref.current.complete();
        setLoading(false);
      }
    });
  };

  const procurementData = props.getAviation && props.getAviation.procurement && props.getAviation.procurement.data;

  const totalCount =
    props.getAviation && props.getAviation.procurement && props.getAviation.procurement?.pagination?.totalCount;

  const filteredCount =
    props.getAviation && props.getAviation.procurement && props.getAviation.procurement.pagination?.filteredCount;

  const totalPages = Math.ceil(totalCount / 20);

  const handleFilter = () => {
    ref.current.continuousStart();
    setLoading(true);
    const params = {
      page: 1,
      sort,
      order,
    };
    if (filteredValues?.userId) {
      params.userId = filteredValues?.userId;
    }
    if (filteredValues?.keyword) {
      params.keyword = filteredValues?.keyword;
    }
    if (params.keyword || params.userId) {
      setApplyFilter(true);
    }
    props.getProcurementRequests(params, (res) => {
      setPage(1);
      if (res && res.status === 200) {
        const data = { ...response };
        data.res = true;
        data.keyword = filteredValues?.keyword;
        data.userId = filteredValues?.userId;
        setResponse(data);
        if (showFilter) {
          toggleFilter();
        }

        ref.current.complete();
        setLoading(false);
      } else {
        ref.current.complete();
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    if (!showFilter && applyFilter && !response?.res) {
      if (!response?.userId && !response?.keyword) {
        setApplyFilter(false);
      }
      handleFilter();
    }
  }, [response?.res]);

  return (
    <>
      <LoadingBar height={5} color="#47AD1D" ref={ref} />
      <Header />
      <ProcurementTable
        loading={loading}
        procurementData={procurementData}
        filteredCount={filteredCount}
        totalCount={totalCount}
        totalPages={totalPages}
        page={page}
        setPage={setPage}
        toggleFilter={toggleFilter}
        filteredValues={filteredValues}
        setFilteredValues={setFilteredValues}
        showFilter={showFilter}
        handleFilter={handleFilter}
        applyFilter={applyFilter}
        getSortedData={getSortedData}
        sort={sort}
        order={order}
        setTableData={setTableData}
        toggleLogModal={toggleLogModal}
        response={response}
        setResponse={setResponse}
      />
      <LogModal
        show={showLogModal}
        onHide={toggleLogModal}
        loading={loading}
        tableData={tableData}
        viewAllModal={viewModal}
        toggleViewAllModal={toggleViewModal}
        setSelectedValue={setSelectedValue}
        procurement={true}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getProcurementRequests: (params, callback) => dispatch(getProcurementRequests(params, callback)),
});

const mapStateToProps = (state) => ({
  getAviation: getAviationDetails(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Procurement));
