import React from "react";
import close from "../../../include/images/close.svg";
import { Offcanvas } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import NextButton from "../../../common/form/nextButton";
import DateFormat from "../../../common/DateFormat/dateFormat";
import moment from "moment";

const EmailReportLog = (props) => {
  return (
    <Offcanvas
      placement="end"
      backdropClassName="upload-documnets-backdrop"
      show={props.show}
      onHide={() => props.onHide()}
      className=" right-collapse fade modal-lg  offcanvas-pannel-container"
      dialogClassName="modal-dialog"
    >
      <Offcanvas.Header className="offcanvas-header">
        <h5 class="offcanvas-title">Seller ARR History</h5>
        <button
          type="button"
          class="offcanvas-close-btn d-flex align-items-center justify-content-center"
          onClick={() => props.onHide()}
        >
          <img src={close} alt="" />
        </button>
      </Offcanvas.Header>
      <Offcanvas.Body className="custom-modal-body flex-grow-1 w-100 overflow-scroll" data-simplebar>
        <div className="table-responsive  table-custom">
          <table className="table table-bordered">
            <colgroup>
              <col />
              <col />
            </colgroup>
            <thead>
              <tr>
                <th>Sent By</th>
                <th>Sent On</th>
              </tr>
            </thead>
            {props?.loading ? (
              <tbody>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]?.map(() => (
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <tbody>
                {props?.data?.length > 0 ? (
                  props?.data?.map((item) => (
                    <tr>
                      <td>{item?.sender?.name ? item?.sender?.name : "N/A"}</td>
                      <td>{item?.updatedAt ? moment(item?.updatedAt).format("MM-DD-yyyy") : "N/A"}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={2}>
                      <div className="d-flex justify-content-center align-items-center">
                        <span>No record found</span>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            )}
          </table>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default EmailReportLog;
