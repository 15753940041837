import React from "react";
import { Offcanvas } from "react-bootstrap";
const FilterPipeLog = (props) => {
  return (
    <Offcanvas
      show={props.show}
      onHide={() => props.onHide()}
      placement="end"
      className="offcanvas offcanvas-end custom-offcanvas-sm "
      backdropClassName="upload-images-backdrop"
      tabindex="-1"
      id="userFilter"
      enforceFocus={false}
    >
      <Offcanvas.Header className="offcanvas-header ">
        <h5 class="offcanvas-title">Filters</h5>
        <button
          type="button"
          className="btn-close text-reset"
          onClick={() => props.onHide()}
        ></button>
      </Offcanvas.Header>
      <Offcanvas.Body className="offcanvas-body co-body">
        <div class="form-group">
          <label for="keyword" class="form-label">
            Auction Id
          </label>
          <input
            type="number"
            class="form-control"
            id="auction-search"
            value={props.selectedFilter?.auctionId}
            onChange={(e) => {
              const data = { ...props.selectedFilter };
              data.auctionId = e.target.value;
              props.setSelectedFilter(data);
            }}
          />
        </div>
        <div class="form-group">
          <label for="type" class="form-label">
            User Id
          </label>
          <input
            type="number"
            class="form-control"
            id="user-search"
            value={props.selectedFilter?.userId}
            onChange={(e) => {
              const data = { ...props.selectedFilter };
              data.userId = e.target.value;
              props.setSelectedFilter(data);
            }}
          />
        </div>
      </Offcanvas.Body>
      <div class="offcanvas-footer">
        <a
          href="javascript:void(0)"
          class={`btn btn-default btn-block ${
            props.loadingFilter && "disabled"
          }`}
          onClick={() => props.handleFilter()}
        >
          Apply Filters
        </a>
      </div>
    </Offcanvas>
  );
};

export default React.memo(FilterPipeLog);
