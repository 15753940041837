import { createSlice, current } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";

const slice = createSlice({
  name: "latestActivity",
  initialState: {
    loading: false,
    latestBids: [],
    priceInfo: [],
    bidLog: [],
    revisitSellerBid: {},
  },
  reducers: {
    latestBidsRequested: (misc, action) => {
      misc.loading = true;
    },
    latestBidsReceived: (misc, action) => {
      misc.latestBids = action.payload;
      misc.loading = false;
    },
    latestBidsRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    priceInfoRequested: (misc, action) => {
      misc.loading = true;
    },
    priceInfoReceived: (misc, action) => {
      misc.priceInfo = action.payload;
      misc.loading = false;
    },
    priceInfoRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    bidLogRequested: (misc, action) => {
      misc.loading = true;
    },
    bidLogReceived: (misc, action) => {
      misc.bidLog = action.payload;
      misc.loading = false;
    },
    bidLogFailed: (misc, action) => {
      misc.loading = false;
    },
    revisitSellerBidRequested: (misc, action) => {
      misc.loading = true;
    },
    revisitSellerBidReceived: (misc, action) => {
      misc.revisitSellerBid = action.payload;
      misc.loading = false;
    },
    revisitSellerBidFailed: (misc, action) => {
      misc.loading = false;
    },
  },
});

export const {
  latestBidsRequested,
  latestBidsReceived,
  latestBidsRequestFailed,
  priceInfoRequested,
  priceInfoReceived,
  priceInfoRequestFailed,
  bidLogRequested,
  bidLogReceived,
  bidLogFailed,
  revisitSellerBidRequested,
  revisitSellerBidReceived,
  revisitSellerBidFailed,
} = slice.actions;
export default slice.reducer;

// Action Creators
const baseUrl = "auctionOther/latestActivity/";

export const getLatestBids = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: baseUrl + "latestBids/" + params,
      method: "get",
      callback,
      onStart: latestBidsRequested.type,
      onSuccess: latestBidsReceived.type,
      onError: latestBidsRequestFailed.type,
    })
  );
};

export const getPriceInfo = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: baseUrl + "pricesInfo/" + params,
      method: "get",
      callback,
      onStart: priceInfoRequested.type,
      onSuccess: priceInfoReceived.type,
      onError: priceInfoRequestFailed.type,
    })
  );
};

export const setMaxBid = (params, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: baseUrl + "maxBid/" + params,
      method: "put",
      data,
      callback,
    })
  );
};

export const deactivateBid = (params, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: baseUrl + "deactivateBid/" + params,
      method: "put",
      data,
      callback,
    })
  );
};

export const priceFeature = (params, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: baseUrl + "priceFeature/" + params,
      method: "put",
      data,
      callback,
    })
  );
};

//Get Bid Log
export const getBidLog = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: baseUrl + "bidListingLog/" + params,
      method: 'get',
      callback,
      onStart: bidLogRequested.type,
      onSuccess: bidLogReceived.type,
      onError: bidLogFailed.type,
    })
  )
}

//Save Bid Log
export const saveBidLog = (params, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: baseUrl + "bidListingLog/" + params,
      method: 'post',
      data,
      callback,
    })
  )
}

//Update Price Feature
export const updatePriceFeature = (params, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: baseUrl + "priceFeature/" + params,
      method: "put",
      data,
      callback,
    })
  )
}

//Get Revisit Seller Bid
export const getRevisitSellerBid = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: baseUrl + "revisitSellerBid/" + params,
      method: "get",
      callback,
      onStart: revisitSellerBidRequested.type,
      onSuccess: revisitSellerBidReceived.type,
      onError: revisitSellerBidFailed.type,
    })
  )
}

//Quantity Confirmation
export const quantityConfirmation = (params, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `auctionOther/latestActivity/qtyConfirmation/${params}`,
      method: "post",
      data,
      callback,
    })
  )
};


//Send Mail To Manager
export const sendMailToManager = (params, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `auctionOther/latestActivity/sendMailToManager/${params}`,
      method: "post",
      data,
      callback,
    })
  )
}
export const getLatestActivity = createSelector(
  (state) => state.entities.latestActivity,
  (latestActivity) => latestActivity
);
