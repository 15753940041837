import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Close from "../../../include/images/close.svg";
import Trash from "../../../include/images/trash18x18.svg";
import HTMLReactParser from "html-react-parser";
import DeleteModal from "../deleteModal";
import NextButton from "../../../common/form/nextButton";
import { getProfile } from "../../../utils.js/localStorageServices";

const QuestionModal = (props) => {
  const member = getProfile();
  const data = props && props.question;
  const buyer = `${data?.name}<br>${data?.company}<br><a href=mailto:${data?.email == "undefined" ? "" : data?.email}>${
    data?.email == "undefined" ? "" : data?.email
  }</a><br>${data?.phone}`;
  return (
    <Modal
      className="modal fade custom-modal"
      show={props.show}
      onHide={props.onHide}
      dialogClassName="modal-dialog modal-dialog-centered modal-xxl"
    >
      <div class="modal-content d-flex flex-column">
        <div class="custom-modal-header d-flex align-items-center justify-content-between">
          <h5 class="mb-0 text-capitalize">Question</h5>
          <button
            type="button"
            class="close-btn d-flex align-items-center justify-content-center"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={props.onHide}
          >
            <img src={Close} alt="" />
          </button>
        </div>
        <Modal.Body class="custom-modal-body flex-grow-1 w-100 overflow-hidden pb-0">
          {/* <div className="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <div class="d-item">
                <a
                  href="javascript:void(0)"
                  onClick={props.forwardToSeller}
                  class="btn btn-primary btn-xs-size btn-auto"
                >
                  ➡ Forward to Seller
                </a>
              </div>
              <div class="d-item">
                <a href="javascript:void(0)" onClick={props.markAnswered} class="btn btn-default btn-xs-size btn-auto">
                  ✔ Mark Answered
                </a>
              </div>
            </div>
            <div>
              <button
                className="delete-button d-flex align-items-center cursor-pointer"
                onClick={() => {
                  props.setQuestionId(data?.q_id);
                  props.toggleDeleteModal();
                }}
              >
                <img src={Trash} alt="" />
                Delete
              </button>
            </div>
          </div> */}
          {/* <div class="page-widget-label text-capitalize mt-3">Question</div> */}
          <table class="table table-borderless align-middle table-left-align">
            <colgroup>
              <col style={{ width: "180px" }} />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <td className="align-top">Buyer</td>
                <td>{HTMLReactParser(buyer)}</td>
              </tr>
              <tr>
                <td className="align-top">Question</td>
                <td>
                  <p>{data?.message}</p>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="dividerTable"></div>
          <div className="d-flex justify-content-between">
            <div class="page-widget-label text-capitalize">Response From Salvex</div>
          </div>
          <table class="table table-borderless align-middle table-left-align mt-3">
            <colgroup>
              <col style={{ width: "60px" }} />
              <col />
              <col style={{ width: "60px" }} />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <td className="align-center">From</td>
                <td>
                  <input
                    disabled
                    value={member?.name01}
                    // onChange={(e) => {
                    //   const values = { ...props.question };
                    //   values.name01 = e.target.value;
                    //   props.setQuestion(values);
                    // }}
                    type="text"
                    class="form-control"
                  />
                </td>
                <td className="align-center">Email</td>
                <td>
                  <input
                    disabled
                    value={member?.email}
                    // onChange={(e) => {
                    //   const values = { ...props.question };
                    //   values.a_email = e.target.value;
                    //   props.setQuestion(values);
                    // }}
                    type="email"
                    class="form-control"
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <table class="table table-borderless align-middle table-left-align">
            <colgroup>
              <col style={{ width: "60px" }} />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <td className="align-top">Answer</td>
                <td>
                  <textarea
                    class="form-control"
                    name=""
                    onChange={(e) => {
                      const values = { ...props.question };
                      values.response = e.target.value;
                      props.setQuestion(values);
                    }}
                    id="textarea-160"
                  ></textarea>
                </td>
              </tr>
            </tbody>
          </table>
          {/* <div class="dividerTable"></div> */}
          {/* <div className="d-flex justify-content-between">
            <div class="page-widget-label text-capitalize">Seller Response</div>
            <a
              href="javascript:void(0)"
              onClick={props.sendResponseSeller}
              class="btn btn-default btn-xs-size btn-auto"
            >
              Send
            </a>
          </div>
          <table class="table table-borderless align-middle table-left-align">
            <colgroup>
              <col style={{ width: "180px" }} />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <td>Message</td>
                <td>
                  <textarea
                    class="form-control"
                    name=""
                    onChange={(e) => {
                      const values = { ...props.question };
                      values.response01 = e.target.value;
                      props.setQuestion(values);
                    }}
                    id="textarea-160"
                  ></textarea>
                </td>
              </tr>
            </tbody>
          </table> */}
        </Modal.Body>
        <div class="custom-modal-dividor"></div>
        <div class="custom-modal-footer d-grid gap-2 d-md-flex align-items-center justify-content-md-end">
          <button
            class="btn btn-link me-md-2"
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={props.onHide}
          >
            Cancel
          </button>
          <NextButton
            classData="btn btn-default"
            handleSubmit={props.sendResponse}
            label={"Send"}
            loading={props.loading}
          />
        </div>
      </div>
      <DeleteModal
        show={props.showDeleteModal}
        onHide={props.toggleDeleteModal}
        loadingDelete={props.loadingDelete}
        deleteHandler={props.deleteHandler}
      />
    </Modal>
  );
};

export default QuestionModal;
