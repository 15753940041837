import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { dashboardData, getEmpChart, getEmpDetail, getMyActivity } from "../../../store/dashBoard";
import Chart from "chart.js/auto";
import { Chart as ChartJs, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from "chart.js";
import { Bar, Line } from "react-chartjs-2";
import Skeleton from "react-loading-skeleton";
ChartJs.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);
Chart.register(CategoryScale);

const PendingListings = ({ empId, showOnly, pl, pt, ...props }) => {
  const [showContent, setShowContent] = useState(false);
  useEffect(() => {
    if (showContent) {
      props.getMyActivity({ empId, showOnly, pl, pt }, (res) => {
        if (res && res.status === 200) {
        }
      });
      props.getEmpDetail({ empId }, (res) => {
        if (res && res.status === 200) {
        }
      });
      props.getEmpChart((res) => {
        if (res && res.status === 200) {
        }
      });
    }
  }, [empId, showContent]);

  const myActivityData = props.dashboardData?.myActivity?.data || [];

  const eventCountData = myActivityData && myActivityData.map((item) => item.event_count);
  const sessionLengthData = myActivityData && myActivityData.map((item) => item.session_length);

  const monthData = myActivityData && myActivityData.map((item) => item.date);

  const empDetailData = props.dashboardData?.empDetail?.data;

  const empChartData = props.dashboardData?.empChart?.data || [];

  return (
    <div class="details-page-widget-block">
      <div class="page-widget-head d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center" onClick={() => setShowContent(!showContent)}>
          <div class="page-widget-label text-capitalize cursor-pointer">Charts</div>
        </div>
      </div>
      {showContent ? (
        <div class="page-widget-body mt-3">
          {empDetailData && (
            <div className="activity-emp-details">
              {empDetailData?.employeeName}
              <br />
              <span style={{ fontWeight: "400" }}>
                {empDetailData?.email} | {empDetailData?.phone}
              </span>
              <br />
              <span style={{ fontWeight: "400" }}>{empDetailData?.language_spoken}</span>
            </div>
          )}
          <div className="d-flex  align-items-center">
            <div className="profile-activity-width">
              <div class="page-widget-label text-capitalize  text-green" style={{ marginLeft: "40px" }}>
                All Profit Month to Date
              </div>
              <Bar
                data={{
                  labels: empChartData && empChartData.length > 0 ? empChartData?.map((item) => item.name01) : [],
                  datasets: [
                    {
                      label: "Profits",
                      data: empChartData && empChartData.length > 0 ? empChartData?.map((item) => item.profit) : [],
                      fill: true,
                      // tension: 0.5,
                      backgroundColor: ["#47ad1d"],
                    },
                  ],

                  options: {
                    scales: {
                      yAxes: [
                        {
                          ticks: {
                            beginAtZero: true,
                            callback: function (value) {
                              return `£${value}k`;
                            },
                          },
                        },
                      ],
                    },
                  },
                }}
              />
            </div>
            <div className="profile-activity-width " style={{ marginLeft: "20px" }}>
              <div class="page-widget-label text-capitalize text-green" style={{ marginLeft: "40px" }}>
                My Activity Log
              </div>
              <Line
                data={{
                  labels: monthData,
                  datasets: [
                    {
                      label: "Events",
                      data: eventCountData,
                      fill: false,
                      borderColor: "rgb(75, 192, 192)",
                      // tension: 0.5,
                      backgroundColor: [
                        "rgba(255, 255, 255, 0.6)",
                        "rgba(255, 255, 255, 0.6)",
                        "rgba(255, 255, 255, 0.6)",
                      ],
                      borderWidth: 3,
                    },
                    {
                      label: "Session Length",
                      data: sessionLengthData,
                      fill: false,
                      borderColor: "rgb( 192, 192,72)",
                      // tension: 0.5,
                      backgroundColor: [
                        "rgba(255, 255, 255, 0.6)",
                        "rgba(255, 255, 255, 0.6)",
                        "rgba(255, 255, 255, 0.6)",
                      ],
                      borderWidth: 3,
                    },
                  ],
                  options: {
                    scales: {
                      y: {
                        title: {
                          display: true,
                          text: "Value",
                        },

                        ticks: {
                          // forces step size to be 50 units
                          stepSize: 0.5,
                        },
                      },
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>
      ) : !showContent && !props.loadingMain ? (
        <div
          class="page-widget-empty-box text-capitalize mt-3 d-flex align-items-center justify-content-center text-center"
          style={{
            height: "50px",
            flexDirection: "column",
            gap: "10px",
          }}
          onClick={() => setShowContent(true)}
        >
          <span>Content Not Loaded</span>
        </div>
      ) : (
        props.loadingMain && <Skeleton height="50px" />
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getMyActivity: (params, callback) => dispatch(getMyActivity(params, callback)),
  getEmpDetail: (params, callback) => dispatch(getEmpDetail(params, callback)),
  getEmpChart: (callback) => dispatch(getEmpChart(callback)),
});
const mapStateToProps = (state) => ({
  dashboardData: dashboardData(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(PendingListings));
