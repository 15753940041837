import React from "react";
import { Modal } from "react-bootstrap";

const RevisitModal = (props) => {
  return (
    <Modal
      className=" fade custom-modal-popup"
      show={props.show}
      onHide={() => props.onHide()}
      enforceFocus={true}
      backdropClassName={"delete-backdrop"}
      //   backdrop={uploadImageModal ? false : true}
      centered
    >
      <Modal.Body>
        <div class="modal-alert-box">
          <h4>Alert! </h4>
          {!props.empty ? <p>You must pick a date!</p> : <p>Are you sure you want to revisit this task?</p>}
        </div>
        <div class="modal-action-btn">
          {!props.empty ? (
            <button className="btn btn-default" onClick={props.onHide}>
              OK
            </button>
          ) : (
            <a
              href="javascript:void(0)"
              class={`btn btn-default btn-lg  ${props.loadingDelete && "btn-disabled"}`}
              onClick={() => !props.loadingDelete && props.submitHandler()}
            >
              Ok
            </a>
          )}
          <a
            href="javascript:void(0)"
            style={{ background: "#fff" }}
            class="btn btn-cancel global-cancel-button"
            onClick={() => props.onHide()}
          >
            Cancel
          </a>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RevisitModal;
