import React, { useEffect, useRef, useState } from "react";
import NextButton from "../../common/form/nextButton";
import Sidebar from "../../layouts/sidebar";
import Header from "../../layouts/header";
import LoadingBar from "react-top-loading-bar";
import { connect } from "react-redux";
import {
  getResearchAssignment,
  getResearchAssignmentDetails,
  getResearchAssignmentDomain,
  getResearchAssignmentPeople,
  updateResearchAssignmentDetails,
} from "../../store/researchAssignment";
import moment from "moment";
import UploadFile from "../../components/researchAssignment/uploadFile";
import ViewParts from "../../layouts/modals/productDescription/viewParts";
import AlertSuccess from "../../common/alerts/alertSuccess";
import AlertError from "../../common/alerts/alertError";
import { toast } from "react-toastify";
import _, { filter } from "lodash";
import HTMLReactParser from "html-react-parser";
import purpleMail from "../../include/images/mail-purple.svg";
import spamMail from "../../include/images/mail-spammed-blocked.svg";
import notVerifiedMail from "../../include/images/mail-not-verified.svg";
import deliveredMail from "../../include/images/green-mail.svg";
import invalidMail from "../../include/images/mail-invalid.svg";
import marketingMail from "../../include/images/marketingMail.svg";
import search from "../../include/images/search.svg";
import linkedin from "../../include/images/Linkedin.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import queryString from "query-string";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const ResearchAssignments = (props) => {
  const location = useLocation();
  const ref = useRef();
  const [showMenu, setShowMenu] = useState(false);
  const [menuList, setMenuList] = useState("");
  const [step, setStep] = useState(1);
  const [showUpload, setShowUpload] = useState(false);
  const [showView, setShowView] = useState(false);
  const [fileData, setFileData] = useState(null);
  const [domainData, setDomainData] = useState([]);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [section, setSection] = useState("customers");
  const [peopleSelector, setPeopleSelector] = useState({
    new: true,
    bidders: false,
    watchers: false,
    clickers: false,
  });
  const [filterData, setFilterData] = useState("");
  const [selectedId, setSelectedId] = useState({ selectAll: false, details: [] });
  const [selectAllListing, setSelectAllListing] = useState({ selectAll: false, auctionId: [] });
  const [assId, setAssId] = useState("");

  useEffect(() => {
    const id = queryString.parse(location.search).assignment_id;
    if (id) {
      setAssId(id);
    }
  }, [assId]);

  const toggleView = () => {
    setShowView(!showView);
  };

  const toggleUpload = () => {
    setShowUpload(!showUpload);
  };

  useEffect(() => {
    if (!showUpload && !showView) {
      setFile(null);
    }
  }, [showUpload]);

  useEffect(() => {
    if (!showView) {
      setStep(1);
    }
  }, [step]);

  useEffect(() => {
    if (step === 2) {
      toggleUpload();
      toggleView();
    }
  }, [step]);

  useEffect(() => {
    if (assId) {
      props.getResearchAssignmentDetails(assId, (res) => {
        if (res && res.status == 200) {
        }
      });
      if (section == "domains") {
        props.getResearchAssignmentDomain(assId, (res) => {
          if (res && res.status == 200) {
          }
        });
      }
    }
  }, [assId]);

  useEffect(() => {
    const data = props.getResearchAssignment?.domain?.data?.data.map((it) => it?.values);
    setDomainData(data);
  }, [props.getResearchAssignment?.domain]);

  const peopleDetails = props.getResearchAssignment?.people?.data || {};
  const customersArray = filterData
    ? props.getResearchAssignment?.people?.data?.data.filter((item) => item?.values?.email.includes(filterData))
    : props.getResearchAssignment?.people?.data?.data || [];

  useEffect(() => {
    if (section === "customers" && assId) {
      const a = [];

      if (peopleSelector?.new) {
        a.push("new-research");
      }
      if (peopleSelector?.bidders) {
        a.push("auction-bidders");
      }
      if (peopleSelector?.watchers) {
        a.push("auction-watchers");
      }
      if (peopleSelector?.clickers) {
        a.push("auction-clickers");
      }
      const params = {
        assignmentId: assId,
        listTypes: a.map((it) => it).join(","),
        excludeEnterpriseId: 276429,
        spreadSheetType: 0,
        reSubscribeAll: 0,
      };
      setLoading(true);
      props.getResearchAssignmentPeople(params, (res) => {
        if (res && res.status === 200) {
          setLoading(false);
        }
      });
    }
  }, [section, peopleSelector, assId]);

  const assDetails = props.getResearchAssignment?.researchAssignmentDetails?.data?.getAuctions || [];

  const researchDetails = props.getResearchAssignment?.researchAssignmentDetails?.data || {};

  const updateResearchAssignmentDetails = (data) => {
    ref?.current?.continuousStart();
    if (data == "update") {
      const payload = {
        action: "update",
        assignedTo: researchDetails?.assigned_to,
        assignmentType: researchDetails?.assignment_type,
        categoryId: researchDetails?.category_ids,
        regionId: researchDetails?.region_ids,
        mfrId: researchDetails?.mfr_id,
        notes: researchDetails?.notes,
      };
      setLoading(true);
      props.updateResearchAssignmentDetails(assId, payload, (res) => {
        if (res && res.status === 200) {
          setLoading(false);
          ref?.current?.complete();
          toast(<AlertSuccess message={"Information Saved"} />);
        } else {
          setLoading(false);
          ref?.current?.complete();
          toast(<AlertError message={res?.data?.message ? res?.data?.message : "Something went wrong"} />);
        }
      });
    } else if (data == "pushAll") {
      const payload = {
        action: "mark_complete",
      };
      setLoading(true);
      props.updateResearchAssignmentDetails(assId, payload, (res) => {
        if (res && res.status === 200) {
          setLoading(false);
          ref?.current?.complete();
          toast(<AlertSuccess message={"Information Saved"} />);
        } else {
          setLoading(false);
          ref?.current?.complete();
          toast(<AlertError message={res?.data?.message ? res?.data?.message : "Something went wrong"} />);
        }
      });
    }
  };

  const mailMerge = () => {
    const payload = {
      action: "mail_merge",
      userId: selectedId?.details?.map((item) => item.id).join(","),
    };
    ref?.current?.continuousStart();
    props.updateResearchAssignmentDetails(assId, payload, (res) => {
      if (res && res.status === 200) {
        const a = ["Name1, Name2, Company, Email\n"];
        const data = res?.data?.data?.map((item) => a.push(`${item?.[0]},${item?.[1]},${item?.[2]},${item?.[3]}\n`));
        const dummyData = a.map((item) => item).join("");

        const csvContent = `data:text/csv;charset=utf-8,${dummyData}`;
        const encodedURI = encodeURI(csvContent);
        window.open(encodedURI);
        ref?.current?.complete();
      }
    });
  };

  const categoryArray = researchDetails?.categories?.split(",");
  return (
    <>
      <LoadingBar height={5} color="#47AD1D" ref={ref} />
      <Header />

      <div class="dashboard-insider position-relative d-lg-flex w-100 ">
        <Sidebar showMenu={showMenu} setShowMenu={setShowMenu} menuList={menuList} setMenuList={setMenuList} />
        <div
          className={showMenu ? ` main-menu-expanded ` : "main-menu-compressed"}
          style={{ backgroundColor: "#F5F5F5" }}
        >
          <div class="filter-container d-flex align-items-center sticky-top">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12 d-flex align-items-center flex-wrap justify-content-between">
                  <div class="filter-title fw-medium text-capitalize investment-title-left">Research Assignments</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-row-container">
            <div className="container-fluid listing-container-investment">
              <div className="row">
                <div className="col-md-12">
                  <div className="col-row-list d-flex flex-wrap">
                    <div class="col-row-item w-40" style={{ maxHeight: "725px" }}>
                      <div class="col-row-box w-100 h-100 d-flex flex-column overflow-hidden details-page-widget-block">
                        <div class="page-widget-block-inside mt-3">
                          <div class="page-widget-block-inside-head">
                            <h4>Assignment Details</h4>
                          </div>
                          <div class="page-widget-block-inside-body">
                            <div class="pwbi-content-box">
                              <div class="table-responsive">
                                <table class="table product-table mb-4">
                                  <colgroup>
                                    <col style={{ width: "35%" }} />
                                    <col />
                                  </colgroup>
                                  <tbody>
                                    <tr>
                                      <td>Assigned To</td>
                                      <td>
                                        {" "}
                                        {researchDetails?.assigned_to_name ? researchDetails?.assigned_to_name : ""}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Assigned By</td>
                                      <td>
                                        {researchDetails?.assigned_by_name ? researchDetails?.assigned_by_name : ""}
                                      </td>
                                    </tr>
                                    {categoryArray &&
                                      categoryArray.length > 0 &&
                                      categoryArray.map((it, i) => (
                                        <tr>
                                          <td>Category</td>
                                          <td>
                                            {it}{" "}
                                            <span className="bold ml-4">
                                              {" "}
                                              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Strategy :{" "}
                                            </span>
                                          </td>
                                        </tr>
                                      ))}

                                    <tr>
                                      <td>Region</td>
                                      <td>{researchDetails?.regions ? researchDetails?.regions : ""}</td>
                                    </tr>
                                    <tr>
                                      <td>Assignment Notes</td>
                                      <td> {researchDetails?.notes ? researchDetails?.notes : ""}</td>
                                    </tr>
                                    <tr>
                                      <td># of New Users</td>
                                      <td>{researchDetails?.new_users ? researchDetails?.new_users : "0"}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div class="form-check tick-checkbox d-flex align-items-center">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  checked={researchDetails?.called ? true : false}
                                  id="checkOne"
                                />
                                <label class="form-check-label" for="checkOne" style={{ paddingTop: "3px" }}>
                                  {" "}
                                  Called
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="page-widget-block-inside mt-3">
                          <div class="page-widget-block-inside-head">
                            <h4>Listing Information</h4>
                          </div>
                          <div class="page-widget-block-inside-body">
                            <div class="pwbi-content-box">
                              <div class="table-responsive">
                                <table class="table product-table mb-4">
                                  <colgroup>
                                    <col style={{ width: "35%" }} />
                                    <col />
                                  </colgroup>
                                  <tbody>
                                    <tr>
                                      <td>Listing</td>
                                      <td>
                                        {" "}
                                        <a href={`/listing-detail?id=${researchDetails?.auction_id}`}>
                                          {researchDetails?.auction ? researchDetails?.auction : ""}
                                        </a>{" "}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Location</td>
                                      <td>
                                        {" "}
                                        {researchDetails?.city ? researchDetails?.city : ""}
                                        {", "}
                                        {researchDetails?.country_description
                                          ? researchDetails?.country_description
                                          : ""}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                          <div class="page-widget-block-inside-body">
                            <a
                              href="javascript:void(0)"
                              class="btn btn-auto btn-xs-size btn-primary"
                              onClick={() => updateResearchAssignmentDetails("pushAll")}
                            >
                              Push to All
                            </a>
                            <a href="javascript:void(0)" class="btn btn-auto btn-xs-size btn-primary">
                              Push Compact View
                            </a>
                            <a href="javascript:void(0)" class="btn btn-auto btn-xs-size btn-primary">
                              Resubscribe All
                            </a>
                            <a
                              href="javascript:void(0)"
                              class={`btn btn-auto btn-xs-size btn-primary ${
                                researchDetails?.completed ? "disabled" : ""
                              }`}
                              onClick={() => updateResearchAssignmentDetails("update")}
                            >
                              {researchDetails?.completed
                                ? `COMPLETED ON ` + moment(researchDetails?.completed).format("MM-DD-YYYY")
                                : "Mark Complete"}
                            </a>
                            <a
                              href="javascript:void(0)"
                              class="btn btn-auto btn-xs-size btn-primary"
                              onClick={() => toggleUpload()}
                            >
                              Upload File
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-row-item w-60" style={{ maxHeight: "725px", overflow: "auto" }}>
                      <div class="col-row-box w-100 h-100 d-flex flex-column overflow-hidden details-page-widget-block">
                        <div class="page-widget-block-inside mt-3">
                          <div class="page-widget-block-inside-head">
                            <h4>Other Listings</h4>
                          </div>
                          <div class="page-widget-block-inside-body">
                            <div class="pwbi-content-box">
                              <div
                                class="table-responsive position-relative mt-3"
                                style={{
                                  maxHeight: "610px",
                                  overflow: "auto",
                                }}
                              >
                                <table class="table table-bordered custom-table mb-0">
                                  <thead>
                                    <tr>
                                      <th style={{ maxWidth: "7%" }}>
                                        <div class="form-check tick-checkbox d-flex align-items-center">
                                          <input
                                            class="form-check-input"
                                            type="checkBox"
                                            value={selectAllListing?.selectAll}
                                            id="otherListingSelectAll"
                                            onChange={() => {
                                              if (selectAllListing.selectAll) {
                                                setSelectAllListing({ selectAll: false, auctionId: [] });
                                              } else {
                                                const a =
                                                  assDetails &&
                                                  assDetails?.length > 0 &&
                                                  assDetails?.map((iy) => iy?.auction_id);
                                                setSelectAllListing({ selectAll: true, auctionId: a });
                                              }
                                            }}
                                          />
                                          <label class="form-check-label" for="otherListingSelectAll"></label>
                                        </div>
                                      </th>
                                      <th style={{ minWidth: "80px" }}>
                                        <a href="javascript:void(0)" class="sort-by"></a>
                                      </th>
                                      <th style={{ maxWidth: "15%" }}>
                                        <a href="javascript:void(0)" class="sort-by">
                                          Listing
                                        </a>
                                      </th>
                                      <th style={{ maxWidth: "15%" }}>
                                        <a href="javascript:void(0)" class="sort-by">
                                          Asking Price
                                        </a>
                                      </th>
                                      <th style={{ maxWidth: "115px" }}>
                                        <a href="javascript:void(0)" class="sort-by">
                                          Manufacturer
                                        </a>
                                      </th>
                                      <th style={{ maxWidth: "15%" }}>
                                        <a href="javascript:void(0)" class="sort-by">
                                          Location
                                        </a>
                                      </th>
                                      <th style={{ maxWidth: "15%" }}>
                                        <a href="javascript:void(0)" class="sort-by">
                                          End Time
                                        </a>
                                      </th>
                                      <th style={{ maxWidth: "15%" }}>
                                        <a href="javascript:void(0)" class="sort-by">
                                          Trader
                                        </a>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td class="bg-major-listing-green" colSpan="8">
                                        Major Listings
                                      </td>
                                    </tr>
                                    {assDetails &&
                                      assDetails.length > 0 &&
                                      assDetails.map((item, j) => {
                                        var date = item.end_date && moment(item.end_date);
                                        var days;

                                        var now = moment();

                                        if (item.status_id === 5) {
                                          days = "Closed";
                                        } else if (item.at_id === 2) {
                                          days = "Bid ASAP";
                                        } else {
                                          // date is future
                                          var day = item.days && item.days > 0 ? item.days + " Days " : "";
                                          var hours = item.hours && item.hours > 0 ? item.hours + " Hours " : "";
                                          var minutes =
                                            item.minutes && item.minutes > 0 ? item.minutes + " minutes " : "";
                                          days = [day, hours, minutes].map((loc) => loc).join(" ");
                                        }
                                        return (
                                          <tr>
                                            <td>
                                              <div class="d-flex align-items-center">
                                                <div class="form-check tick-checkbox">
                                                  <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value={
                                                      selectAllListing?.auctionId?.includes(item?.auction_id)
                                                        ? true
                                                        : false
                                                    }
                                                    id={`otherListing${j}`}
                                                    onChange={() => {
                                                      if (selectAllListing?.auctionId?.includes(item?.auction_id)) {
                                                        const a = selectAllListing?.auctionId?.filter(
                                                          (it) => it?.auction_id != item?.auction_id,
                                                        );
                                                        setSelectAllListing({
                                                          selectAll: selectAllListing?.selectAll,
                                                          auctionId: a,
                                                        });
                                                      } else {
                                                        const data = [...selectAllListing?.auctionId];
                                                        data.push(item?.auction_id);
                                                        setSelectAllListing({
                                                          selectAll: selectAllListing?.selectAll,
                                                          auctionId: data,
                                                        });
                                                      }
                                                    }}
                                                  />
                                                  <label class="form-check-label" for={`otherListing${j}`}></label>
                                                </div>
                                              </div>
                                            </td>
                                            <td className="cursor-pointer">
                                              <a href={`/listing-detail?id=${item?.auction_id}`} target="_blank">
                                                <img
                                                  src={`${
                                                    process.env.REACT_APP_MEDIA_URL +
                                                    item.primary_s_path +
                                                    item.primary_s_fn +
                                                    "_mp.jpg"
                                                  }`}
                                                  alt=""
                                                />
                                              </a>
                                            </td>
                                            <td>
                                              <div class="fw-medium green-text cursor-pointer">
                                                <a href={`/listing-detail?id=${item?.auction_id}`} target="_blank"></a>
                                                {item?.title}
                                              </div>
                                              <p>
                                                {item?.quantity} {item?.unit}
                                              </p>
                                            </td>
                                            <td>
                                              {item?.p_value && item.feature
                                                ? "$" + new Intl.NumberFormat("en-US").format(item.p_value)
                                                : ""}
                                            </td>
                                            <td>
                                              <p>{item?.manufacturer ? item?.manufacturer : ""}</p>
                                            </td>
                                            <td>
                                              {item?.city ? item?.city : ""}
                                              {", "}
                                              {item?.c_des ? item?.c_des : ""}
                                            </td>
                                            <td class="text-end">
                                              {days && days.trim().length > 0 ? (
                                                days === "Closed" ? (
                                                  <p style={{ color: "red" }}>Closed</p>
                                                ) : (
                                                  days
                                                )
                                              ) : (
                                                ""
                                              )}
                                            </td>
                                            <td class="text-end">
                                              {item?.name01 ? item.name01 : ""}
                                              <br />
                                              <a href={`tel:${item.phone}`}>{item?.phone ? item.phone : ""}</a>

                                              <br />
                                              <a href={`mailto:${item.email}`}>{item?.email ? item.email : ""}</a>
                                            </td>
                                          </tr>
                                        );
                                      })}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sticky-menu-wrapper sticky-top">
            <div className="menu-wrapper d-flex align-items-center">
              <div className="container-fluid listing-container">
                <div className="row">
                  <div className="col-md-12">
                    <ul className="menu-list d-flex flex-nowrap text-nowrap align-items-center text-white hide-scrollbar">
                      <li
                        className={`ml-item ${section == "customers" ? "active" : ""}`}
                        style={{ marginLeft: "13px" }}
                      >
                        <div className="ml-link d-flex align-items-center justify-content-center">
                          <button type="button" className="add-new-btn" onClick={() => setSection("customers")}>
                            Customers
                          </button>
                        </div>
                      </li>
                      <li className={`ml-item ${section == "domains" ? "active" : ""}`}>
                        <div className="ml-link d-flex align-items-center justify-content-center">
                          <button type="button" className="add-new-btn" onClick={() => setSection("domains")}>
                            Domains
                          </button>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {section == "customers" && (
            <div className="col-row-container">
              <div className="container-fluid listing-container-investment">
                <div className="row">
                  <div className="col-md-12">
                    <div class="details-page-widget-block">
                      <div className="page-widget-body ">
                        <div class="filter-title fw-medium text-capitalize mb-3">Legend</div>
                        <div className="d-flex align-items-center ">
                          <div class="form-check tick-checkbox d-flex align-items-center mr-35">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              checked={peopleSelector?.new ? true : false}
                              id="newResearch"
                              onClick={() => {
                                const data = { ...peopleSelector };
                                data.new = !peopleSelector?.new;
                                setPeopleSelector(data);
                              }}
                            />
                            <label class="form-check-label" for="newResearch" style={{ paddingTop: "3px" }}>
                              {" "}
                              New Research
                            </label>
                          </div>
                          <div class="form-check tick-checkbox d-flex align-items-center mr-35">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              checked={peopleSelector?.bidders ? true : false}
                              id="auctionBidders"
                              onClick={() => {
                                const data = { ...peopleSelector };
                                data.bidders = !peopleSelector?.bidders;
                                setPeopleSelector(data);
                              }}
                            />
                            <label class="form-check-label" for="auctionBidders" style={{ paddingTop: "3px" }}>
                              {" "}
                              Auction Bidders
                            </label>
                          </div>
                          <div class="form-check tick-checkbox d-flex align-items-center mr-35">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              checked={peopleSelector?.watchers ? true : false}
                              id="auctionWatchers"
                              onClick={() => {
                                const data = { ...peopleSelector };
                                data.watchers = !peopleSelector?.watchers;
                                setPeopleSelector(data);
                              }}
                            />
                            <label class="form-check-label" for="auctionWatchers" style={{ paddingTop: "3px" }}>
                              {" "}
                              Auction Watchers
                            </label>
                          </div>
                          <div class="form-check tick-checkbox d-flex align-items-center mr-35">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              checked={peopleSelector?.clickers ? true : false}
                              id="auctionClickers"
                              onClick={() => {
                                const data = { ...peopleSelector };
                                data.clickers = !peopleSelector?.clickers;
                                setPeopleSelector(data);
                              }}
                            />
                            <label class="form-check-label" for="auctionClickers" style={{ paddingTop: "3px" }}>
                              {" "}
                              Auction Clickers
                            </label>
                          </div>
                        </div>
                        <div className="row mt-4 mb-4 customer_grid_loaded">
                          <div className="col-md-12">
                            Grid Loaded from JSON : {peopleDetails && peopleDetails?.data?.length} row(s)
                          </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mb-5">
                          <div className="filter-customers">
                            <p
                              style={{
                                marginRight: "40px",
                                marginBottom: "0px",
                              }}
                            >
                              Filters :
                            </p>
                            <div>
                              <input
                                type="text"
                                className="form-control"
                                value={filterData}
                                onChange={(e) => setFilterData(e.target.value)}
                              />
                            </div>
                          </div>
                          <div>
                            <a
                              href="javascript:void(0)"
                              class="btn btn-auto btn-xs-size btn-primary"
                              // onClick={()=>{
                              //   const data ={

                              //   }
                              // }}
                            >
                              Add New Row
                            </a>
                          </div>
                        </div>
                        <div class="table-responsive table-custom">
                          <table class="table table-bordered">
                            <colgroup>
                              <col />
                              <col />
                              <col />
                              <col />
                              <col />
                              <col />
                              <col />
                              <col />
                              <col />
                              <col />
                              <col />
                              <col />
                              <col />
                              <col />
                              <col />
                              <col />
                              <col />
                              <col />
                              <col />
                              <col />
                              <col />
                              <col />
                              <col />
                              <col />
                              <col />
                              <col />
                              <col />
                              <col />
                              <col />
                              <col />
                              <col />
                              <col />
                              <col />
                              <col />
                              <col />
                              <col />
                              <col />
                            </colgroup>
                            <thead>
                              <tr>
                                <td>
                                  <div class="form-check tick-checkbox d-flex align-items-center ">
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      checked={selectedId?.selectAll ? true : false}
                                      id="allCustomers"
                                      onChange={(e) => {
                                        if (selectedId?.selectAll) {
                                          setSelectedId({ selectAll: false, details: [] });
                                        } else {
                                          setSelectedId({ selectAll: true, details: [...customersArray] });
                                        }
                                      }}
                                    />
                                    <label class="form-check-label" for="allCustomers" style={{ paddingTop: "3px" }}>
                                      {" "}
                                    </label>
                                  </div>
                                </td>
                                <td>Email</td>
                                <td>Alt. Email</td>
                                <td style={{ minWidth: "40px" }}></td>
                                <td></td>
                                <td style={{ minWidth: "40px" }}></td>
                                <td style={{ minWidth: "40px" }}></td>
                                <td>Name</td>
                                <td>Title</td>
                                <td>City</td>
                                <td>State</td>
                                <td>Company</td>
                                <td>Phone</td>
                                <td>Cell</td>
                                <td>Website</td>
                                <td style={{ minWidth: "40px" }}>LinkedIn</td>
                                <td>Notes</td>
                                <td>Enterprise</td>
                                <td>Business Unit</td>
                                <td>Last Contacted</td>
                                <td>Purchases</td>
                                <td>Logins</td>
                                <td>Categories</td>
                                <td>Regions</td>
                                <td>Manufacturers</td>
                                <td>Response</td>
                                <td>Last Login</td>
                                <td>Last Click</td>
                                <td>Last Bid</td>
                                <td>Last Bought</td>
                                <td>All Contacts</td>
                                <td>Created By</td>
                                <td></td>
                                <td>Is Deactive</td>
                                <td></td>
                              </tr>
                            </thead>
                            <tbody>
                              {loading ? (
                                <tr>
                                  <td colSpan={"34"}>Loading...</td>
                                </tr>
                              ) : (
                                customersArray &&
                                customersArray?.length > 0 &&
                                customersArray?.map((item, i) => {
                                  const emailStatus = item?.values?.emailstatus
                                    ? HTMLReactParser(item?.values?.emailstatus)?.props?.title
                                    : "";

                                  return (
                                    <tr>
                                      <td>
                                        <div class="form-check tick-checkbox d-flex align-items-center ">
                                          <input
                                            class="form-check-input"
                                            type="checkbox"
                                            checked={
                                              selectedId?.details?.some((it) => it?.id == item?.id) ? true : false
                                            }
                                            id={`itemCustomer${i}`}
                                            onChange={() => {
                                              if (
                                                selectedId?.details?.some((it) => it?.id == item?.id) ? true : false
                                              ) {
                                                const data = [...selectedId?.details];
                                                const a =
                                                  data && data.length > 0 && data.filter((it) => it.id != item.id);

                                                setSelectedId({ selectAll: selectedId?.selectAll, details: [...a] });
                                              } else {
                                                const data = [...selectedId?.details];
                                                data.push(item);

                                                setSelectedId({ selectAll: selectedId?.selectAll, details: [...data] });
                                              }
                                            }}
                                          />
                                          <label
                                            class="form-check-label"
                                            for={`itemCustomer${i}`}
                                            style={{ paddingTop: "3px" }}
                                          >
                                            {" "}
                                          </label>
                                        </div>
                                      </td>

                                      <td>
                                        {item?.values?.email}
                                        <br />
                                        <a href="javascript:void(0)" class="btn btn-auto btn-xs-size btn-primary">
                                          Deactivate
                                        </a>
                                      </td>
                                      <td>{item?.values?.email_alt}</td>
                                      <td className="cursor-pointer">
                                        {/* <OverlayTrigger
                                          placement="left"
                                          overlay={
                                            <Tooltip style={{ position: "fixed" }}>
                                              <span>{emailStatus === "delivered" ? "Delivered" : ""}</span>
                                            </Tooltip>
                                          }
                                        >
                                          <img
                                            className="mail-icon-svg"
                                            src={emailStatus === "delivered" ? deliveredMail : ""}
                                            alt="delivered"
                                          />
                                        </OverlayTrigger> */}
                                      </td>
                                      <td> </td>
                                      <td className="cursor-pointer">
                                        {" "}
                                        <OverlayTrigger
                                          placement="left"
                                          overlay={
                                            <Tooltip style={{ position: "fixed" }}>
                                              <span>Marketing Mail</span>
                                            </Tooltip>
                                          }
                                        >
                                          <img className="mail-icon-svg" src={marketingMail} alt="Marketing Mail" />
                                        </OverlayTrigger>
                                      </td>
                                      <td className="cursor-pointer">
                                        <OverlayTrigger
                                          placement="left"
                                          overlay={
                                            <Tooltip style={{ position: "fixed" }}>
                                              <span>View Profile</span>
                                            </Tooltip>
                                          }
                                        >
                                          <img className="mail-icon-svg" src={search} alt="Marketing Mail" />
                                        </OverlayTrigger>
                                      </td>
                                      <td>{item?.values?.name}</td>
                                      <td>{item?.values?.title}</td>
                                      <td>{item?.values?.city}</td>
                                      <td>{item?.values?.state}</td>
                                      <td>{item?.values?.company_name}</td>
                                      <td>{item?.values?.phone}</td>
                                      <td>{item?.values?.cell}</td>
                                      <td>{item?.values?.web_site}</td>
                                      <td>
                                        {item?.values?.linkedin ? (
                                          <a className="cursor-pointer" href={item?.values?.linkedin} target="_blank">
                                            <img
                                              className="mail-icon-svg"
                                              src={linkedin}
                                              alt={item?.values?.linkedin}
                                            />
                                          </a>
                                        ) : (
                                          ""
                                        )}
                                      </td>
                                      <td>{item?.values?.last_chat_message}</td>
                                      <td>{item?.values?.enterprise}</td>
                                      <td>{item?.values?.business_unit}</td>
                                      <td>
                                        {item?.values?.last_contact
                                          ? moment(item?.values?.last_contact).format("MMM-DD-YYYY")
                                          : ""}
                                      </td>
                                      <td className="bold">{item?.values?.purchase_count}</td>
                                      <td className="bold">{item?.values?.login_ct}</td>
                                      <td className="bold">{item?.values?.category_count}</td>
                                      <td className="bold">{item?.values?.region_count}</td>
                                      <td className="bold">{item?.values?.mfr_count}</td>
                                      <td>{item?.values?.response}</td>
                                      <td>
                                        {item?.values?.last_login
                                          ? moment(item?.values?.last_login).format("MMM-DD-YYYY")
                                          : ""}
                                      </td>
                                      <td>
                                        {item?.values?.last_click
                                          ? moment(item?.values?.last_click).format("MMM-DD-YYYY")
                                          : ""}
                                      </td>
                                      <td>
                                        {item?.values?.last_bid
                                          ? moment(item?.values?.last_bid).format("MMM-DD-YYYY")
                                          : ""}
                                      </td>
                                      <td>
                                        {item?.values?.last_bought
                                          ? moment(item?.values?.last_bought).format("MMM-DD-YYYY")
                                          : ""}
                                      </td>
                                      <td className="bold">{item?.values?.contact_count}</td>
                                      <td>{item?.values?.created_by}</td>
                                      <td>
                                        {" "}
                                        <a href="javascript:void(0)" class="btn btn-auto btn-xs-size btn-primary">
                                          Remove
                                        </a>
                                      </td>
                                      <td>{item?.values?.deactivate ? "1" : "0"}</td>
                                      <td>
                                        <a href="javascript:void(0)" class="btn btn-auto btn-xs-size btn-primary">
                                          Delete
                                        </a>
                                      </td>
                                    </tr>
                                  );
                                })
                              )}
                            </tbody>
                          </table>
                        </div>
                        <a
                          href="javascript:void(0)"
                          class="btn btn-auto btn-xs-size btn-primary mt-5"
                          onClick={() => mailMerge()}
                        >
                          Mail Merge
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {section == "domains" && (
            <div className="col-row-container">
              <div className="container-fluid listing-container-investment">
                <div className="row">
                  <div className="col-md-12">
                    <div class="details-page-widget-block">
                      <div class="page-widget-head d-flex align-items-center justify-content-end mb-3">
                        <div class="d-flex align-items-center"></div>

                        <NextButton
                          classData="btn btn-default "
                          label={"Add New Row"}
                          handleSubmit={() => {
                            const newItem = {
                              city: "",
                              company_name: "",
                              country: "",
                              muurl: "",
                              phone: "",
                              state: "",
                              status: "new",
                              zip_code: "",
                            };
                            setDomainData((prevData) => [newItem, ...prevData]);
                          }}
                        />
                        <NextButton classData="btn btn-default " label={"Save All"} />
                      </div>

                      <div class="table-responsive table-custom">
                        <table class="table table-bordered">
                          <colgroup>
                            <col />
                            <col />
                            <col />
                            <col />
                            <col />
                            <col />
                            <col />
                            <col />
                          </colgroup>
                          <thead>
                            <tr>
                              <th>Company</th>
                              <th>Domain</th>
                              <th>City</th>
                              <th>State</th>
                              <th>Country</th>
                              <th>Zip</th>
                              <th>Phone</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {domainData &&
                              domainData.length > 0 &&
                              domainData.map((item, i) => (
                                <tr>
                                  <td>
                                    {" "}
                                    <input
                                      type="text"
                                      className="form-control"
                                      defaultValue={item?.company_name}
                                      onChange={(e) => {
                                        const data = [...domainData];
                                        const ele = data.filter((it, j) => i == j)[0];
                                        let clone = Object.assign({}, ele);
                                        clone.company_name = e.target.value;

                                        const findIndex = domainData.findIndex((it, j) => i == j);
                                        data[findIndex] = clone;
                                        setDomainData(data);
                                      }}
                                      value={item?.company_name}
                                    />{" "}
                                  </td>
                                  <td>
                                    {" "}
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={item?.muurl}
                                      defaultValue={item?.muurl}
                                      onChange={(e) => {
                                        const data = [...domainData];
                                        const ele = data.filter((it, j) => i == j)[0];
                                        let clone = Object.assign({}, ele);
                                        clone.muurl = e.target.value;

                                        const findIndex = domainData.findIndex((it, j) => i == j);
                                        data[findIndex] = clone;
                                        setDomainData(data);
                                      }}
                                    />{" "}
                                  </td>
                                  <td>
                                    {" "}
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={item?.city}
                                      defaultValue={item?.city}
                                      onChange={(e) => {
                                        const data = [...domainData];
                                        const ele = data.filter((it, j) => i == j)[0];
                                        let clone = Object.assign({}, ele);
                                        clone.city = e.target.value;

                                        const findIndex = domainData.findIndex((it, j) => i == j);
                                        data[findIndex] = clone;
                                        setDomainData(data);
                                      }}
                                    />{" "}
                                  </td>
                                  <td>
                                    {" "}
                                    <input
                                      type="text"
                                      className="form-control"
                                      // value={item?.state}
                                      defaultValue={item?.state}
                                    />{" "}
                                  </td>
                                  <td>
                                    {" "}
                                    <input
                                      type="text"
                                      className="form-control"
                                      // value={item?.country}
                                      defaultValue={item?.country}
                                    />{" "}
                                  </td>
                                  <td>
                                    {" "}
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={item?.zip_code}
                                      defaultValue={item?.zip_code}
                                      onChange={(e) => {
                                        const data = [...domainData];
                                        const ele = data.filter((it, j) => i == j)[0];
                                        let clone = Object.assign({}, ele);
                                        clone.zip_code = e.target.value;

                                        const findIndex = domainData.findIndex((it, j) => i == j);
                                        data[findIndex] = clone;
                                        setDomainData(data);
                                      }}
                                    />{" "}
                                  </td>
                                  <td>
                                    {" "}
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={item?.phone}
                                      defaultValue={item?.phone}
                                      onChange={(e) => {
                                        const data = [...domainData];
                                        const ele = data.filter((it, j) => i == j)[0];
                                        let clone = Object.assign({}, ele);
                                        clone.phone = e.target.value;

                                        const findIndex = domainData.findIndex((it, j) => i == j);
                                        data[findIndex] = clone;
                                        setDomainData(data);
                                      }}
                                    />{" "}
                                  </td>
                                  <td>
                                    {" "}
                                    <input
                                      type="text"
                                      className="form-control"
                                      readOnly
                                      defaultValue={item?.status}
                                    />{" "}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <UploadFile
            show={showUpload}
            onHide={toggleUpload}
            step={step}
            setStep={setStep}
            setFileData={setFileData}
            file={file}
            setFile={setFile}
            assId={assId}
          />
          <ViewParts
            show={showView}
            onHide={toggleView}
            fileData={fileData}
            file={file}
            setFileData={setFileData}
            setFile={setFile}
            assId={assId}
          />
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getResearchAssignmentDetails: (params, callback) => dispatch(getResearchAssignmentDetails(params, callback)),
  getResearchAssignmentDomain: (params, callback) => dispatch(getResearchAssignmentDomain(params, callback)),
  updateResearchAssignmentDetails: (params, data, callback) =>
    dispatch(updateResearchAssignmentDetails(params, data, callback)),
  getResearchAssignmentPeople: (params, callback) => dispatch(getResearchAssignmentPeople(params, callback)),
});

const mapStateToProps = (state) => ({
  getResearchAssignment: getResearchAssignment(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(ResearchAssignments));
