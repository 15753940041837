export const setToken = (refresh, auth) => {
  if (refresh) localStorage.setItem("x-refresh-token", refresh);
  localStorage.setItem("x-auth-token", auth);
};

export const getToken = (type) => {
  if (type === "refresh") return localStorage.getItem("x-refresh-token");
  if (type === "auth") return localStorage.getItem("x-auth-token");
  return false;
};

export const clearAllToken = () => {
  localStorage.removeItem("x-refresh-token");
  localStorage.removeItem("x-auth-token");
  localStorage.removeItem("data");
};

export const setProfile = (data) => {
  localStorage.setItem("data", JSON.stringify(data));
};
export const getProfile = () => {
  if (localStorage.getItem("data")) {
    return JSON.parse(localStorage.getItem("data"));
  } else {
    clearAllToken();
    setRedirectUrl(window.location.href);
    window.location.href = "/signin";
  }
};

export const setRedirectUrl = (data) => {
  localStorage.clear();
  localStorage.setItem("redirectUrl", data);
};

export const getRedirectUrl = () => {
  return localStorage.getItem("redirectUrl");
};

export const setLogOutRedirect = (data) => {
  localStorage.clear();
  localStorage.setItem("logOutRedirect", data);
};

export const getLogOutRedirect = () => {
  return localStorage.getItem("logOutRedirect");
};

export const checkToken = () => {
  const token = localStorage.getItem("x-auth-token");
  if (token) {
    return true;
  } else {
    return false;
  }
};

export const removeKey = (keyName) => {
  localStorage.removeItem(keyName);
};

export function encode(str) {
  return str?.replace(/./g, function (c) {
    return ("00" + c.charCodeAt(0)).slice(-3);
  });
}
