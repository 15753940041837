import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
import misc from "./misc";

const slice = createSlice({
  name: "tasks",
  initialState: {
    loading: false,
    taskData: {},
    assignTo: {},
    assignToForEdit: {},
    statusList: [],
    completedTasks: [],
    pendingTasks: [],
    taskDetail: {},
  },
  reducers: {
    taskDataRequested: (misc, action) => {
      misc.loading = true;
    },
    taskDataReceived: (misc, action) => {
      misc.taskData = action.payload;
      misc.loading = false;
    },
    taskDataFailed: (misc, action) => {
      misc.loading = false;
    },

    assignToDataRequested: (misc, action) => {
      misc.loading = true;
    },
    assignToDataReceived: (misc, action) => {
      misc.assignTo = action.payload;
      misc.loading = false;
    },
    assignToDataFailed: (misc, action) => {
      misc.loading = false;
    },

    assignToEditRequested: (misc, action) => {
      misc.loading = true;
    },
    assignToEditReceived: (misc, action) => {
      misc.assignToForEdit = action.payload;
      misc.loading = false;
    },
    assignToEditFailed: (misc, action) => {
      misc.loading = false;
    },
    taskStatusRequested: (misc, action) => {
      misc.loading = true;
    },
    taskStatusRecieved: (misc, action) => {
      misc.statusList = action.payload;
      misc.loading = false;
    },
    taskStatusFailed: (misc, action) => {
      misc.loading = false;
    },
    completedTaskRequested: (misc, action) => {
      misc.loading = true;
    },
    completedTaskRecieved: (misc, action) => {
      misc.completedTasks = action.payload;
      misc.loading = false;
    },
    completedTaskFailed: (misc, action) => {
      misc.loading = false;
    },
    pendingTasksRequested: (misc, action) => {
      misc.loading = true;
    },
    pendingTasksReceived: (misc, action) => {
      misc.pendingTasks = action.payload;
      misc.loading = false;
    },
    pendingTasksFailed: (misc, action) => {
      misc.loading = false;
    },
    taskDetailRequested: (misc, action) => {
      misc.loading = true;
    },
    taskDetailReceived: (misc, action) => {
      misc.taskDetail = action.payload;
      misc.loading = false;
    },
    taskDetailFailed: (misc, action) => {
      misc.loading = false;
    },
  },
});

export const {
  taskDataRequested,
  taskDataReceived,
  taskDataFailed,
  assignToDataRequested,
  assignToDataReceived,
  assignToDataFailed,
  assignToEditRequested,
  assignToEditReceived,
  assignToEditFailed,
  taskStatusRequested,
  taskStatusRecieved,
  taskStatusFailed,
  completedTaskRequested,
  completedTaskRecieved,
  completedTaskFailed,
  pendingTasksRequested,
  pendingTasksReceived,
  pendingTasksFailed,
  taskDetailRequested,
  taskDetailReceived,
  taskDetailFailed,
} = slice.actions;
export default slice.reducer;

// Action Creators
const baseUrl = "auctionOther";
const commonUrl = "common";

export const getTask = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/other/task/${data}`,
      method: "get",
      callback,
      onStart: taskDataRequested.type,
      onSuccess: taskDataReceived.type,
      onError: taskDataFailed.type,
    })
  );
};

export const addNewTask = (params, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/other/task/${params}`,
      method: "POST",
      data,
      callback,
    })
  );
};

export const getAssignTo = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${commonUrl}/assignTo?task_id=${0}`,
      method: "get",
      callback,
      onStart: assignToDataRequested.type,
      onSuccess: assignToDataReceived.type,
      onError: assignToDataFailed.type,
    })
  );
};

export const getAssignToForEdit = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${commonUrl}/assignTo?task_id=${1}`,
      method: "get",
      callback,
      onStart: assignToEditRequested.type,
      onSuccess: assignToEditReceived.type,
      onError: assignToEditFailed.type,
    })
  );
};

export const updateTask = (params, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/other/task/${params.auction}?task_id=${params.task_id}`,
      method: "PUT",
      data,
      callback,
    })
  );
};

//Delete Task
export const deleteTask = (params, taskId, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/other/task/${params}?taskId=${taskId}`,
      method: "DELETE",
      taskId,
      callback,
    })
  );
};

//Get Task Status
export const getTaskStatus = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${commonUrl}/taskStatus`,
      method: "get",
      callback,
      onStart: taskStatusRequested.type,
      onSuccess: taskStatusRecieved.type,
      onError: taskStatusFailed.type,
    })
  );
};

//Get Completed Task
export const getCompletedTasks = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/other/completedTask/${params}`,
      method: "get",
      callback,
      onStart: completedTaskRequested.type,
      onSuccess: completedTaskRecieved.type,
      onError: completedTaskFailed.type,
    })
  );
};

//Get Pending Tasks
export const getPendingTasks = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/other/pendingTask/${params}`,
      method: "get",
      callback,
      onStart: pendingTasksRequested.type,
      onSuccess: pendingTasksReceived.type,
      onError: pendingTasksFailed.type,
    })
  );
};

//Update Task Status
export const updateTaskStatus = (params, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/other/updateTaskStatus/${params}`,
      method: "put",
      data,
      callback,
    })
  );
};

//Get Task Detail
export const getTaskDetail = (params, taskId, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/other/taskDetail/${params}?task_id=${taskId}`,
      method: 'get',
      callback,
      onStart: taskDetailRequested.type,
      onSuccess: taskDetailReceived.type,
      onError: taskDetailFailed.type,
    })
  )
}

export const getTasks = createSelector(
  (state) => state.entities.tasks,
  (tasks) => tasks
);
