import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";

const slice = createSlice({
  name: "preferences",
  initialState: {
    loading: false,
    regionlistings: {},
    regionSelected: {},
    manufacturer: [],
    manufacturePreference: [],
  },
  reducers: {
    regionlistingsRequested: (misc, action) => {
      misc.loading = true;
    },
    regionlistingsReceived: (misc, action) => {
      misc.regionlistings = action.payload;
      misc.loading = false;
    },
    regionlistingsRequestFailed: (misc, action) => {
      misc.loading = true;
    },
    regionSelectedRequested: (misc, action) => {
      misc.loading = true;
    },
    regionSelectedReceived: (misc, action) => {
      misc.regionSelected = action.payload;
      misc.loading = false;
    },
    regionSelectedRequestFailed: (misc, action) => {
      misc.loading = true;
    },
    manufactureRequested: (misc, action) => {
      misc.loading = true;
    },
    manufactureRecieved: (misc, action) => {
      misc.manufacturer = action.payload;
      misc.loading = false;
    },
    manufactureFailed: (misc, action) => {
      misc.loading = false;
    },
    manufacturePreferenceRequested: (misc, action) => {
      misc.loading = true;
    },
    manufacturePreferenceRecieved: (misc, action) => {
      misc.manufacturePreference = action.payload;
      misc.loading = false;
    },
    manufacturePreferenceFailed: (misc, action) => {
      misc.loading = false;
    },
  },
});

export const {
  regionlistingsRequested,
  regionlistingsReceived,
  regionlistingsRequestFailed,
  regionSelectedRequested,
  regionSelectedReceived,
  regionSelectedRequestFailed,
  manufactureRequested,
  manufactureRecieved,
  manufactureFailed,
  manufacturePreferenceRequested,
  manufacturePreferenceRecieved,
  manufacturePreferenceFailed,
} = slice.actions;
export default slice.reducer;

const commonUrl = "common";
const onboarding = "onboarding";

// Action Creators

export const getRegionPreference = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${commonUrl}/regionForPref`,
      method: "get",
      onStart: regionlistingsRequested.type,
      onSuccess: regionlistingsReceived.type,
      onError: regionlistingsRequestFailed.type,
      callback,
    })
  );
};

export const getSelectedRegion = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${onboarding}/regionPreference/${params}`,
      method: "get",
      onStart: regionSelectedRequested.type,
      onSuccess: regionSelectedReceived.type,
      onError: regionSelectedRequestFailed.type,
      callback,
    })
  );
};

export const updateRegionPreference =
  (params, data, callback) => (dispatch) => {
    return dispatch(
      apiCallBegan({
        url: `${onboarding}/regionPreference/${params}`,
        method: "post",
        data,
        callback,
      })
    );
  };


//Get Manufacturer 
export const getManufacturer = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${commonUrl}/manufacturerForPref`,
      method: 'get',
      callback,
      onStart: manufactureRequested.type,
      onSuccess: manufactureRecieved.type,
      onError: manufactureFailed.type,
    })
  )
};

//Get Manufacture Preferences
export const getManufacturerPreference = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${onboarding}/manufacturerPreference/${params}`,
      method: 'get',
      callback,
      onStart: manufacturePreferenceRequested.type,
      onSuccess: manufacturePreferenceRecieved.type,
      onError: manufacturePreferenceFailed.type,
    })
  )
};

//Add Manufacture Preferences
export const addManufacturePreference = (params, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${onboarding}/manufacturerPreference/${params}`,
      method: 'post',
      data,
      callback,

    })
  )
}
export const getPreferences = createSelector(
  (state) => state.entities.preferences,
  (preferences) => preferences
);
