import React from "react";
import { Modal } from "react-bootstrap";
import close from "../../../include/images/close.svg";
import NextButton from "../../../common/form/nextButton";

const UpdateAviationModal = (props) => {
  return (
    <Modal
      className="modal fade custom-modal"
      id="optimizationSite"
      dialogClassName="modal-dialog modal-dialog-centered  aviation-upload-modal"
      show={props.show}
      onHide={() => props.onHide()}
      enforceFocus={false}
    >
      <div class="modal-content d-flex flex-column">
        <div class="custom-modal-header d-flex align-items-center justify-content-between">
          <h5 class="mb-0 text-capitalize">Update Aviation Parts</h5>
          <button
            type="button"
            class="close-btn d-flex align-items-center justify-content-center"
            onClick={() => props.onHide()}
          >
            <img src={close} alt="" />
          </button>
        </div>
        <Modal.Body className="custom-modal-body flex-grow-1 w-100 overflow-hidden">
          <div class="two-row-column d-flex flex-wrap">
            <div class="tr-column  position-relative">
              <div class="tr-column-content w-100 h-100 d-flex flex-column">
                <p>
                  Select the column names you want to update. Selected Column names along with Part Number will be
                  exported to CSV. You can upload the edited file again.{" "}
                </p>
                <table class="table table-borderless align-middle table-left-align">
                  <colgroup>
                    <col />
                    <col />
                    <col />
                  </colgroup>
                  <tbody>
                    <tr className="d-flex" style={{ flexWrap: "wrap" }}>
                      <tr style={{ width: "33%" }}>
                        <td>
                          <div class="form-check tick-checkbox cursor-pointer">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="unitPriceUpdate"
                              name="unitPriceUpdate"
                              checked={props?.checkedValues?.includes("unitPrice")}
                              onClick={() => {
                                const data = [...props?.checkedValues];
                                const isChecked = data?.includes("unitPrice");
                                if (isChecked) {
                                  const a = data?.filter((ch) => ch != "unitPrice");
                                  props?.setCheckedValues(a);
                                } else {
                                  data.push("unitPrice");
                                  props?.setCheckedValues(data);
                                }
                              }}
                            />
                            <label class="form-check-label fw-medium" for="unitPriceUpdate"></label>
                          </div>
                        </td>
                        <td>Unit Price</td>
                      </tr>
                      <tr style={{ width: "33%" }}>
                        <td>
                          <div class="form-check tick-checkbox cursor-pointer">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="locationUpdate"
                              name="locationUpdate"
                              checked={props?.checkedValues?.includes("location")}
                              onClick={() => {
                                const data = [...props?.checkedValues];
                                const isChecked = data?.includes("location");
                                if (isChecked) {
                                  const a = data?.filter((ch) => ch != "location");
                                  props?.setCheckedValues(a);
                                } else {
                                  data.push("location");
                                  props?.setCheckedValues(data);
                                }
                              }}
                            />
                            <label class="form-check-label fw-medium" for="locationUpdate"></label>
                          </div>
                        </td>
                        <td>Location</td>
                      </tr>

                      <tr style={{ width: "33%" }}>
                        <td>
                          <div class="form-check tick-checkbox cursor-pointer">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="certificateUpdate"
                              name="certificateUpdate"
                              checked={props?.checkedValues?.includes("certificate")}
                              onClick={() => {
                                const data = [...props?.checkedValues];
                                const isChecked = data?.includes("certificate");
                                if (isChecked) {
                                  const a = data?.filter((ch) => ch != "certificate");
                                  props?.setCheckedValues(a);
                                } else {
                                  data.push("certificate");
                                  props?.setCheckedValues(data);
                                }
                              }}
                            />
                            <label class="form-check-label fw-medium" for="certificateUpdate"></label>
                          </div>
                        </td>
                        <td>Certificate</td>
                      </tr>
                      <tr style={{ width: "33%" }}>
                        <td>
                          <div class="form-check tick-checkbox cursor-pointer">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="partTypeUpdate"
                              name="partTypeUpdate"
                              checked={props?.checkedValues?.includes("partType")}
                              onClick={() => {
                                const data = [...props?.checkedValues];
                                const isChecked = data?.includes("partType");
                                if (isChecked) {
                                  const a = data?.filter((ch) => ch != "partType");
                                  props?.setCheckedValues(a);
                                } else {
                                  data.push("partType");
                                  props?.setCheckedValues(data);
                                }
                              }}
                            />
                            <label class="form-check-label fw-medium" for="partTypeUpdate"></label>
                          </div>
                        </td>
                        <td>Part Type</td>
                      </tr>
                      <tr style={{ width: "33%" }}>
                        <td>
                          <div class="form-check tick-checkbox cursor-pointer">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="mfrUpdate1"
                              name="mfrUpdate1"
                              checked={props?.checkedValues?.includes("planeManufacturer")}
                            />
                            <label
                              class="form-check-label fw-medium "
                              name="mfrUpdate1"
                              onClick={() => {
                                const data = [...props?.checkedValues];
                                const isChecked = data?.includes("planeManufacturer");
                                if (isChecked) {
                                  const a = data?.filter((ch) => ch != "planeManufacturer");
                                  props?.setCheckedValues(a);
                                } else {
                                  data.push("planeManufacturer");
                                  props?.setCheckedValues(data);
                                }
                              }}
                            ></label>
                          </div>
                        </td>
                        <td>Plane Manufacturer</td>
                      </tr>

                      <tr style={{ width: "33%" }}>
                        <td>
                          <div class="form-check tick-checkbox cursor-pointer">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="planeModelUpdate"
                              name="planeModelUpdate"
                              checked={props?.checkedValues?.includes("planeModels")}
                              onClick={() => {
                                const data = [...props?.checkedValues];
                                const isChecked = data?.includes("planeModels");
                                if (isChecked) {
                                  const a = data?.filter((ch) => ch != "planeModels");
                                  props?.setCheckedValues(a);
                                } else {
                                  data.push("planeModels");
                                  props?.setCheckedValues(data);
                                }
                              }}
                            />
                            <label class="form-check-label fw-medium" for="planeModelUpdate"></label>
                          </div>
                        </td>
                        <td>Plane Model</td>
                      </tr>
                      <tr style={{ width: "33%" }}>
                        <td>
                          <div class="form-check tick-checkbox cursor-pointer">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="partMfr"
                              name="partMfr"
                              checked={props?.checkedValues?.includes("partManufacturer")}
                              onClick={() => {
                                const data = [...props?.checkedValues];
                                const isChecked = data?.includes("partManufacturer");
                                if (isChecked) {
                                  const a = data?.filter((ch) => ch != "partManufacturer");
                                  props?.setCheckedValues(a);
                                } else {
                                  data.push("partManufacturer");
                                  props?.setCheckedValues(data);
                                }
                              }}
                            />
                            <label class="form-check-label fw-medium" for="partMfr"></label>
                          </div>
                        </td>
                        <td>Part Manufacturer</td>
                      </tr>
                      <tr style={{ width: "33%" }}>
                        <td>
                          <div class="form-check tick-checkbox cursor-pointer">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="imagesUpdate"
                              name="imagesUpdate"
                              checked={props?.checkedValues?.includes("images")}
                              onClick={() => {
                                const data = [...props?.checkedValues];
                                const isChecked = data?.includes("images");
                                if (isChecked) {
                                  const a = data?.filter((ch) => ch != "images");
                                  props?.setCheckedValues(a);
                                } else {
                                  data.push("images");
                                  props?.setCheckedValues(data);
                                }
                              }}
                            />
                            <label class="form-check-label fw-medium" for="imagesUpdate"></label>
                          </div>
                        </td>
                        <td>Images</td>
                      </tr>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Modal.Body>
        <div class="custom-modal-dividor"></div>
        <div class="custom-modal-footer d-grid gap-2 d-md-flex align-items-center justify-content-md-end">
          <button class="btn btn-link me-md-2 global-cancel-button" type="button" onClick={() => props.onHide()}>
            Cancel
          </button>
          <NextButton
            label="Export to CSV"
            classData="btn btn-default"
            handleSubmit={props.downloadCSV}
            loading={props.loading}
            disable={props?.checkedValues?.length == 0 ? true : false}
          />
          <NextButton
            classData="btn btn-default mr-40"
            handleSubmit={(e) => {
              props?.onHide();
              props?.toggleUploadPartModal();
            }}
            label={"Next"}
            disable={props?.type == "upload" ? false : true}
          />
        </div>
      </div>
    </Modal>
  );
};

export default UpdateAviationModal;
