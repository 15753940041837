import React, { useEffect, useState } from "react";
import { customStyles } from "../../../common/customStyles/reactSelectStyle";
import Select from "react-select";
import { connect } from "react-redux";
import { dashboardData, getAllListings, getSortedAllListings, getSortedOtherListings } from "../../../store/dashBoard";
import { toast } from "react-toastify";
import AlertError from "../../../common/alerts/alertError";
import greenEmail from "../../../include/images/green-mail.svg";
import notVerified from "../../../include/images/mail-not-verified.svg";
import invalid from "../../../include/images/mail-invalid.svg";
import spamEmail from "../../../include/images/mail-spammed-blocked.svg";
import purpleEmail from "../../../include/images/mail-purple.svg";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import icon1 from "../../../include/images/icon___1.svg";
import icon2 from "../../../include/images/icon__2.svg";
import icon3 from "../../../include/images/red-hand.png";
import { payableDetails } from "../../../store/payables";
import { commentsAndNotesDetails } from "../../../store/commentsAndNotes";
import { Accordion, OverlayTrigger, Tooltip } from "react-bootstrap";
import { getListings } from "../../../store/listings";
import MoreButton from "../../../common/buttons/moreButton";

const AllListings = ({ empId, ...props }) => {
  const [loadedContent, setLoadedContent] = useState(false);
  const [noContent, setNoContent] = useState(true);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState("newest");
  const [enable, setEnable] = useState(false);
  const [contentLoaded, setContentLoaded] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [auctionId, setAuctionId] = useState("");
  const [id, setId] = useState("");
  const [sortedData, setSortedData] = useState("");
  const data = props.dashboardData?.allListings?.data;
  const sortingOptions = [
    { name: "-- Sort --", value: null },
    { name: "Newest", value: "newest" },
    { name: "Oldest", value: "oldest" },
    { name: "Profit (high to low)", value: "profitdesc" },
    { name: "Profit (low to high)", value: "profitasc" },
    {
      name: "Wholesale Value (high to low)",
      value: "wholesaledesc",
    },
    {
      name: "Wholesale Value (low to high)",
      value: "wholesaleasc",
    },
    { name: "Notes (newest)", value: "notesasc" },
    { name: "Notes (oldest)", value: "notesdesc" },
    { name: "Invoiced (newest)", value: "invoicedasc" },
    { name: "Invoiced (oldest)", value: "invoiceddesc" },
    { name: "Buyer Paid", value: "bpaiddesc" },
    { name: "Seller Paid", value: "spaiddesc" },
    { name: "Deposit Paid", value: "dpaiddesc" },
    { name: "Picked Up", value: "pickedupdesc" },
  ];

  const [sortedOption, setSortedOption] = useState({
    name: "Newest",
    value: "newest",
  });
  const [pageOption, setPageOption] = useState({ name: "1", value: "1" });

  const pageNumOption = Array.from({ length: props.dashboardData?.allListings?.max_pages }, (_, index) => ({
    name: index + 1,
    value: index + 1,
  }));

  useEffect(() => {
    if (props.viewContent) {
      setContentLoaded(false);
      setLoadedContent(false);
    }
  }, [props.refresh]);
  //Sorting
  useEffect(() => {
    if (enable && props.sortBy == 0) {
      props?.refStart();
      const payload = {
        page: 1,
        aType: 9,
        listType: 9,
        employeeId: empId,
        showOnly: 0,
        pl: props.pl,
        pt: props.pt,
        listingSort: sort,
        sortBy: props.sortBy,
        viewType: props.viewType,
        pushYesNo: props.pushYesNo,
      };
      props.getAllListings(payload, (res) => {
        if (res.status === 200) {
          props?.refStop();
        } else {
          props?.refStop();
          toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        }
      });
    }
  }, [sort]);
  //Pagination
  useEffect(() => {
    if (enable && props.sortBy == 0) {
      props?.refStart();
      const payload = {
        page: page,
        aType: 9,
        listType: 9,
        employeeId: empId,
        showOnly: 0,
        pl: props.pl,
        pt: props.pt,
        listingSort: sort,
        sortBy: props.sortBy,
        viewType: props.viewType,
        pushYesNo: props.pushYesNo,
      };
      props.getAllListings(payload, (res) => {
        if (res.status === 200) {
          props?.refStop();
        } else {
          props?.refStop();
          toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        }
      });
    }
  }, [page]);

  const fetchMore = (aucId) => {
    if (auctionId === aucId) {
      setAuctionId(null);
      setShowMore(false);
    } else {
      setAuctionId(aucId);
      setShowMore(true);
    }
  };
  const fetchData = () => {
    if (!contentLoaded) {
      props?.refStart();
      setLoading(true);
      const payload = {
        page: 1,
        aType: 9,
        listType: 9,
        employeeId: empId,
        showOnly: 0,
        pl: props.pl,
        pt: props.pt,
        listingSort: sort,
        sortBy: props.sortBy,
        viewType: props.viewType,
        pushYesNo: props.pushYesNo,
      };
      props.getAllListings(payload, (res) => {
        if (res.status === 200) {
          setEnable(true);
          setLoading(false);
          props?.refStop();
          setLoadedContent(!loadedContent);
          setContentLoaded(true);
        } else {
          setLoading(false);
          props?.refStop();
          toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        }
      });
    } else {
      setNoContent(false);
      setLoadedContent(!loadedContent);
    }
  };
  const fetchSorted = (id) => {
    const payload = {
      page: 1,
      aType: props.sortBy == 2 || props.sortBy == 3 || props.sortBy == 7 ? 17 : props.sortBy == 4 ? 30 : 9,
      listType: 9,
      employeeId: empId,
      showOnly: 0,
      pl: props.pl,
      pt: props.pt,
      listingSort: sort,
      sortBy: props.sortBy,
      viewType: props.viewType,
      pushYesNo: props.pushYesNo,
    };
    if (props.sortBy == 1 || props.sortBy == 9) {
      payload.industryId = id;
    } else if (props.sortBy == 2 || props.sortBy == 3 || props.sortBy == 7) {
      payload.categoryId = id;
    } else if (props.sortBy == 4) {
      payload.regionId = id;
    } else if (props.sortBy == 5) {
      payload.mfrid = id;
    } else if (props.sortBy == 6) {
      payload.enterpriseId = id;
    } else if (props.sortBy == 8) {
      payload.geoRegionID = id;
    }
    setId(id);
    props?.refStart();
    setLoading(true);
    if (props.sortBy == 2 || props.sortBy == 3 || props.sortBy == 7 || props.sortBy == 4) {
      props.getSortedOtherListings(payload, (res) => {
        if (res.status === 200) {
          setEnable(true);
          setSortedData(res.data.data);
          setLoading(false);
          props?.refStop();
        } else {
          setLoading(false);
          setEnable(true);
          props?.refStop();
          toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        }
      });
    } else {
      props.getSortedAllListings(payload, (res) => {
        if (res.status === 200) {
          setEnable(true);
          setSortedData(res.data.data);
          setLoading(false);
          props?.refStop();
        } else {
          setLoading(false);
          setEnable(true);
          props?.refStop();
          toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        }
      });
    }
  };

  //Sorting
  useEffect(() => {
    if (enable && props.sortBy != 0) {
      props?.refStart();
      const payload = {
        page: 1,
        aType: props.sortBy == 2 || props.sortBy == 3 || props.sortBy == 7 ? 17 : props.sortBy == 4 ? 30 : 9,
        listType: 9,
        employeeId: empId,
        showOnly: 0,
        pl: props.pl,
        pt: props.pt,
        listingSort: sort,
        sortBy: props.sortBy,
        viewType: props.viewType,
        pushYesNo: props.pushYesNo,
      };
      if (props.sortBy == 1 || props.sortBy == 9) {
        payload.industryId = id;
      } else if (props.sortBy == 2 || props.sortBy == 3 || props.sortBy == 7) {
        payload.categoryId = id;
      } else if (props.sortBy == 4) {
        payload.regionId = id;
      } else if (props.sortBy == 5) {
        payload.mfrid = id;
      } else if (props.sortBy == 6) {
        payload.enterpriseId = id;
      } else if (props.sortBy == 8) {
        payload.geoRegionID = id;
      }
      props.getSortedAllListings(payload, (res) => {
        if (res.status === 200) {
          props?.refStop();
        } else {
          props?.refStop();
          toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        }
      });
    }
  }, [sort]);
  //Pagination
  useEffect(() => {
    if (enable && props.sortBy != 0) {
      props?.refStart();
      const payload = {
        page: page,
        aType: 9,
        listType: 9,
        employeeId: empId,
        showOnly: 0,
        pl: props.pl,
        pt: props.pt,
        listingSort: sort,
        sortBy: props.sortBy,
        viewType: props.viewType,
        pushYesNo: props.pushYesNo,
      };
      if (props.sortBy == 1 || props.sortBy == 9) {
        payload.industryId = id;
      } else if (props.sortBy == 2 || props.sortBy == 3 || props.sortBy == 7) {
        payload.categoryId = id;
      } else if (props.sortBy == 4) {
        payload.regionId = id;
      } else if (props.sortBy == 5) {
        payload.mfrid = id;
      } else if (props.sortBy == 6) {
        payload.enterpriseId = id;
      } else if (props.sortBy == 8) {
        payload.geoRegionID = id;
      }
      props.getSortedAllListings(payload, (res) => {
        if (res.status === 200) {
          props?.refStop();
        } else {
          props?.refStop();
          toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        }
      });
    }
  }, [page]);
  return (
    <div class="details-page-widget-block">
      <div class="page-widget-head d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <div class="page-widget-label text-capitalize cursor-pointer" onClick={() => fetchData()}>
            All Listings
            {!props.loadingMain && `(${props.count ? props.count : "0"})`}
          </div>
        </div>
      </div>
      <div class="page-widget-body mt-3">
        {loadedContent ? (
          props.sortBy == 0 ? (
            <>
              <div
                className="mt-2 mb-2"
                style={{
                  width: "30%",
                  display: "flex",
                }}
              >
                <Select
                  className="basic-single "
                  classNamePrefix="select-search"
                  value={sortedOption}
                  options={sortingOptions}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.value}
                  name="filter"
                  placeholder="--sort--"
                  styles={customStyles}
                  onChange={(e) => {
                    const data = { ...sortedOption };
                    data.name = e.name;
                    data.value = e.value;
                    setSortedOption(data);
                    setSort(e.value);
                  }}
                />
                {/* <button className="btn btn-default ms-2">SELECT ALL</button> */}
              </div>
              <div class="table-responsive position-relative mt-3">
                <table class="table table-bordered custom-table mb-0">
                  <thead>
                    <tr>
                      {/* <th></th> */}
                      <th>
                        <a href="javascript:void(0)" class="sort-by">
                          Photo
                        </a>
                      </th>
                      <th>
                        <a href="javascript:void(0)" class="sort-by">
                          Enterprise
                        </a>
                      </th>
                      <th>
                        <a href="javascript:void(0)" class="sort-by">
                          Status
                        </a>
                      </th>
                      <th></th>
                      <th style={{ width: "70px" }}>
                        <a href="javascript:void(0)" class="sort-by">
                          Title
                        </a>
                      </th>
                      <th style={{ minWidth: "120px", maxWidth: "150px" }}>
                        <a href="javascript:void(0)" class="sort-by">
                          Seller
                        </a>
                      </th>
                      <th style={{ minWidth: "175px", maxWidth: "200px" }}>
                        <a href="javascript:void(0)" class="sort-by">
                          Internal People
                        </a>
                      </th>
                      <th style={{ minWidth: "175px", maxWidth: "180px" }}>High Bidder</th>
                      <th>Bids</th>
                      <th>
                        <a href="javascript:void(0)" class="sort-by">
                          Potential Profit
                        </a>
                      </th>
                      <th style={{ minWidth: "100px", width: "100px" }}>
                        <a href="javascript:void(0)" class="sort-by">
                          Bid Submitted
                        </a>
                      </th>
                      <th style={{ minWidth: "100px", width: "100px" }}>
                        <a href="javascript:void(0)" class="sort-by">
                          Pricing
                        </a>
                      </th>
                      <th style={{ minWidth: "100px", width: "100px" }}>
                        <a href="javascript:void(0)" class="sort-by">
                          % Recovery
                        </a>
                      </th>
                      <th style={{ minWidth: "100px", width: "100px" }}>
                        <a href="javascript:void(0)" class="sort-by">
                          Location
                        </a>
                      </th>
                      <th style={{ minWidth: "100px", width: "100px" }}>
                        <a href="javascript:void(0)" class="sort-by">
                          Main Category
                        </a>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((val, i) => (
                      <React.Fragment key={i}>
                        <tr>
                          {/* <td>
                        <div class="d-flex align-items-center">
                          <div class="form-check tick-checkbox">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id={`checkTwo` + i}
                            />
                            <label
                              class="form-check-label"
                              for={`checkTwo` + i}
                            ></label>
                          </div>
                        </div>
                      </td> */}
                          <td>
                            <div class="td-image-td">
                              <a href={`/listing-detail?id=${val?.auction_id}`} target="_blank">
                                <img src={val?.auction?.primary_picture} alt="" />
                              </a>
                            </div>
                          </td>
                          <td></td>
                          <td>
                            <div class="fw-medium">
                              <em>{val?.auction?.status_description}</em>
                            </div>
                            {val?.auction?.bigdeal && <div class="td-tag">BD</div>}
                            {val?.auction?.hotlist && <div class="td-tag">PL</div>}
                          </td>
                          <td>
                            <a
                              href="javascript:void(0)"
                              class="btn btn-primary btn-sm"
                              onClick={() => fetchMore(val?.auction_id)}
                            >
                              {auctionId === val?.auction_id ? "Less" : "More"}
                            </a>
                          </td>
                          <td>
                            <div class="fw-medium green-text">
                              <a href={`/listing-detail?id=${val?.auction_id}`} target="_blank">
                                {val?.auction?.proxibid > 0 && (
                                  <span class="tag-label blue" style={{ marginRight: "5px" }}>
                                    PB
                                  </span>
                                )}
                                {val?.auction?.title}
                              </a>
                            </div>
                            <p>
                              {val?.auction?.quantity?.toLocaleString()} {val?.auction?.unit}
                            </p>
                          </td>
                          <td>
                            <div
                              class="fw-medium green-text cursor-pointer"
                              onClick={() => {
                                if (process.env.REACT_APP_MODE == "development") {
                                  window.open(
                                    `https://admin-dev.salvex.com/admin/_user/_edit.cfm?user_id=${val?.auction?.s_user_id}`,
                                  );
                                } else {
                                  window.open(
                                    `https://admin.salvex.com/admin/_user/_edit.cfm?user_id=${val?.auction?.s_user_id}`,
                                  );
                                }
                              }}
                            >
                              {val?.auction?.seller_name}
                            </div>
                            <div
                              className="cursor-pointer"
                              onClick={() => {
                                if (process.env.REACT_APP_MODE == "development") {
                                  window.open(
                                    `https://admin-dev.salvex.com/admin/_user/_edit.cfm?user_id=${val?.auction?.s_user_id}`,
                                  );
                                } else {
                                  window.open(
                                    `https://admin.salvex.com/admin/_user/_edit.cfm?user_id=${val?.auction?.s_user_id}`,
                                  );
                                }
                              }}
                            >
                              {val?.auction?.seller_company}
                            </div>
                            <div class="d-text-list d-flex flex-wrap">
                              <div class="d-text-item me-1">
                                <em>{val?.auction?.success_rate?.toFixed(2)}%</em>
                              </div>
                              <div class="d-text-item me-1">
                                <em>
                                  {val?.auction?.rate1}/{val?.auction?.rate2}
                                </em>
                              </div>
                              <div class="d-text-item">
                                {val?.auction?.emailStatusCode === 0 ? (
                                  <img src={greenEmail} alt="" title="Email OK" />
                                ) : val?.auction?.emailStatusCode === 1 ? (
                                  <img
                                    src={purpleEmail}
                                    alt=""
                                    title="Email Temporarily Suspended/Unreachable or Undetermined (Soft Bounce)"
                                  />
                                ) : val?.auction?.emailStatusCode === 2 ? (
                                  <img src={invalid} alt="" title="Email Invalid" />
                                ) : val?.auction?.emailStatusCode === 3 ? (
                                  <img src={spamEmail} alt="" title="Email Blocked by Spam or Firewall" />
                                ) : (
                                  <img src={notVerified} alt="" title="Email Not Verified" />
                                )}
                              </div>
                            </div>
                            <div class="td-button-group d-flex">
                              <img
                                src={`https://us-central1-salvex-mail.cloudfunctions.net/requestRecipientStats?email=${val?.auction?.seller_email}`}
                                alt=""
                              />
                            </div>
                            <div>{val?.auction?.industry_description}</div>
                            <div class="td-icon-list d-flex flex-wrap align-items-center">
                              {val?.auction?.seller_dhs_approved ? (
                                <div class="td-icon-item">
                                  <div class="td-icon-box">
                                    <img src={icon1} alt="" />
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                              {val?.auction?.seller_okay ? (
                                <div class="td-icon-item">
                                  <div class="td-icon-box">
                                    <img src={icon2} alt="" />
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                              {val?.auction?.seller_stop_working ? (
                                <div class="td-icon-item">
                                  <div class="td-icon-box">
                                    <img src={icon3} alt="" width="30px" height="30px" />
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </td>
                          <td>
                            {val?.auction?.trader_name ? (
                              <div className="d-flex">
                                <span className="fw-medium">T: </span>
                                <span className="fw-medium ms-2">{val?.auction?.trader_name}</span>
                              </div>
                            ) : (
                              ""
                            )}
                            {val?.auction?.internal_referral ? (
                              <div className="d-flex">
                                <span className="fw-medium">BD: </span>
                                <span className="fw-medium ms-2">{val?.auction?.internal_referral}</span>
                              </div>
                            ) : (
                              ""
                            )}
                            {val?.auction?.mgr_name ? (
                              <div className="d-flex">
                                <span className="fw-medium">TM: </span>
                                <span className="fw-medium ms-2">{val?.auction?.mgr_name}</span>
                              </div>
                            ) : (
                              ""
                            )}
                            {val?.auction?.admin_name ? (
                              <div className="d-flex">
                                <span className="fw-medium">BDM: </span>
                                <span className="fw-medium ms-2">{val?.auction?.admin_name}</span>
                              </div>
                            ) : (
                              ""
                            )}
                          </td>
                          <td>
                            {val?.auction?.topbidders[0]?.name ? (
                              <>
                                <div class="td-icon-list d-flex flex-wrap align-items-center">
                                  {val?.auction?.topbidders[0]?.dhs_approved ? (
                                    <div class="td-icon-item">
                                      <div class="td-icon-box">
                                        <img src={icon1} alt="" />
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  {val?.auction?.topbidders[0]?.okay ? (
                                    <div class="td-icon-item">
                                      <div class="td-icon-box">
                                        <img src={icon2} alt="" />
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  {val?.auction?.topbidders[0]?.stop_working ? (
                                    <div class="td-icon-item">
                                      <div class="td-icon-box">
                                        <img src={icon3} alt="" width="30px" height="30px" />
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div
                                  class="fw-medium green-text cursor-pointer"
                                  onClick={() => {
                                    if (process.env.REACT_APP_MODE == "development") {
                                      window.open(
                                        `https://admin-dev.salvex.com/admin/_user/_edit.cfm?user_id=${val?.auction?.topbidders[0]?.b_user_id}`,
                                      );
                                    } else {
                                      window.open(
                                        `https://admin.salvex.com/admin/_user/_edit.cfm?user_id=${val?.auction?.topbidders[0]?.b_user_id}`,
                                      );
                                    }
                                  }}
                                >
                                  {val?.auction?.topbidders[0]?.name}
                                </div>
                                {val?.auction?.topbidders[0]?.buyer_ranking ? (
                                  <div>
                                    <b>Customer Ranking - {val?.auction?.topbidders[0]?.buyer_ranking}</b>
                                  </div>
                                ) : (
                                  ""
                                )}
                                <div class="d-text-list d-flex align-items-center">
                                  <div class="d-text-item fw-medium green-text-label">
                                    {val?.auction?.topbidders[0].ct2 && val?.auction?.topbidders[0]?.ct3
                                      ? Math.round(
                                          (val?.auction?.topbidders[0]?.ct2 / val?.auction?.topbidders[0]?.ct3) * 100,
                                        )
                                      : "0"}
                                    % ({val?.auction?.topbidders[0]?.ct2 ? val?.auction?.topbidders[0]?.ct2 : "0"})
                                  </div>
                                  <div class="d-text-item fw-medium grey-text-label">
                                    {val?.auction?.topbidders[0]?.ct1 && val?.auction?.topbidders[0]?.ct3
                                      ? Math.round(
                                          (val?.auction?.topbidders[0]?.ct1 / val?.auction?.topbidders[0]?.ct3) * 100,
                                        )
                                      : "0"}
                                    % ({val?.auction?.topbidders[0]?.ct1 ? val?.auction?.topbidders[0]?.ct1 : "0"})
                                  </div>
                                  <div class="d-text-item fw-medium red-text-label">
                                    {val?.auction?.topbidders[0]?.ct0 && val?.auction?.topbidders[0]?.ct3
                                      ? Math.round(
                                          (val?.auction?.topbidders[0]?.ct0 / val?.auction?.topbidders[0]?.ct3) * 100,
                                        )
                                      : "0"}
                                    % ({val?.auction?.topbidders[0]?.ct0 ? val?.auction?.topbidders[0]?.ct0 : "0"})
                                  </div>
                                  <div>
                                    {val?.auction?.topbidders[0]?.emailStatusCode === 0 ? (
                                      <img src={greenEmail} alt="" title="Email OK" />
                                    ) : val?.auction?.topbidders[0]?.emailStatusCode === 1 ? (
                                      <img
                                        src={purpleEmail}
                                        alt=""
                                        title="Email Temporarily Suspended/Unreachable or Undetermined (Soft Bounce)"
                                      />
                                    ) : val?.auction?.topbidders[0]?.emailStatusCode === 2 ? (
                                      <img src={invalid} alt="" title="Email Invalid" />
                                    ) : val?.auction?.topbidders[0]?.emailStatusCode === 3 ? (
                                      <img src={spamEmail} alt="" title="Email Blocked by Spam or Firewall" />
                                    ) : (
                                      <img src={notVerified} alt="" title="Email Not Verified" />
                                    )}
                                  </div>
                                </div>

                                <div class="td-button-group d-flex">
                                  <img
                                    src={`https://us-central1-salvex-mail.cloudfunctions.net/requestRecipientStats?email=${val?.auction?.topbidders[0]?.email}`}
                                    alt=""
                                  />
                                </div>
                                <div>{val?.auction?.topbidders[0]?.location}</div>
                              </>
                            ) : (
                              "N/A"
                            )}
                          </td>
                          <td class="text-end">{val?.auction?.bid_count}</td>
                          <td class="text-end">
                            {val?.auction?.topbidders.length > 0 &&
                            val?.auction?.topbidders[0].total_bid > val?.auction?.seller_reserve
                              ? "$" +
                                new Intl.NumberFormat("en-US", {
                                  style: "decimal",
                                  minimumFractionDigits: 2,
                                }).format(val?.auction?.topbidders[0].total_bid - val?.auction?.seller_reserve)
                              : "N/A"}
                          </td>
                          <td>
                            <div class="text-end">
                              {val?.auction?.seller_bid_date ? (
                                <>
                                  <div class="fw-medium">
                                    {"$" +
                                      new Intl.NumberFormat("en-US", {
                                        style: "decimal",
                                        minimumFractionDigits: 2,
                                      }).format(val?.auction?.seller_bid)}
                                  </div>
                                  <div class="td-light-text">
                                    {moment(val?.auction?.seller_bid_date).format("MM/DD/YYYY")}
                                  </div>
                                </>
                              ) : (
                                "N/A"
                              )}
                            </div>
                          </td>
                          <td>
                            <div class="flex-table-row">
                              {val?.auction?.topbidders[0]?.total_bid ? (
                                <div class="flex-table-item d-flex flex-nowrap align-items-start">
                                  <div class="ft-txt-start">HB</div>
                                  <div class="ft-dot">:</div>
                                  <div class="ft-txt-end">
                                    <div class="text-end w-100 d-flex flex-column">
                                      <div class="fw-medium">
                                        {val?.auction?.topbidders[0]?.total_bid
                                          ? "$" +
                                            new Intl.NumberFormat("en-US", {
                                              style: "decimal",
                                              minimumFractionDigits: 2,
                                            }).format(val?.auction?.topbidders[0]?.total_bid)
                                          : "N/A"}
                                      </div>
                                      <div class="td-light-text">
                                        {val?.auction?.topbidders[0]?.total_bid
                                          ? moment(val?.auction?.topbidders[0]?.bid_time).format("MM/DD/YYYY")
                                          : ""}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                              {val?.auction?.seller_reserve ? (
                                <div class="flex-table-item d-flex flex-nowrap align-items-start">
                                  <div class="ft-txt-start">SR</div>
                                  <div class="ft-dot">:</div>
                                  <div class="ft-txt-end">
                                    <div class="text-end w-100 d-flex flex-column">
                                      <div class="fw-medium">
                                        {val?.auction?.seller_reserve
                                          ? "$" +
                                            new Intl.NumberFormat("en-US", {
                                              style: "decimal",
                                              minimumFractionDigits: 2,
                                            }).format(val?.auction?.seller_reserve)
                                          : "N/A"}
                                      </div>
                                      <div class="td-light-text">
                                        {val?.auction?.due_date
                                          ? moment(val?.auction?.due_date).format("MM/DD/YYYY")
                                          : ""}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                              {val?.auction?.orig_wholesale_value ? (
                                <div class="flex-table-item d-flex flex-nowrap align-items-start">
                                  <div class="ft-txt-start">WV</div>
                                  <div class="ft-dot">:</div>
                                  <div class="ft-txt-end">
                                    <div class="text-end w-100 d-flex flex-column">
                                      <div class="fw-medium">
                                        {val?.auction?.orig_wholesale_value
                                          ? "$" +
                                            new Intl.NumberFormat("en-US", {
                                              style: "decimal",
                                              minimumFractionDigits: 2,
                                            }).format(val?.auction?.orig_wholesale_value)
                                          : "N/A"}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                              {val?.auction?.public_reserve ? (
                                <div class="flex-table-item d-flex flex-nowrap align-items-start">
                                  <div class="ft-txt-start">AP</div>
                                  <div class="ft-dot">:</div>
                                  <div class="ft-txt-end">
                                    <div class="text-end w-100 d-flex flex-column">
                                      <div class="fw-medium">
                                        {val?.auction?.public_reserve
                                          ? "$" +
                                            new Intl.NumberFormat("en-US", {
                                              style: "decimal",
                                              minimumFractionDigits: 2,
                                            }).format(val?.auction?.public_reserve)
                                          : "N/A"}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                              {val?.auction?.recycle_value ? (
                                <div class="flex-table-item d-flex flex-nowrap align-items-start">
                                  <div class="ft-txt-start">RV</div>
                                  <div class="ft-dot">:</div>
                                  <div class="ft-txt-end">
                                    <div class="text-end w-100 d-flex flex-column">
                                      <div class="fw-medium">
                                        {val?.auction?.recycle_value
                                          ? "$" +
                                            new Intl.NumberFormat("en-US", {
                                              style: "decimal",
                                              minimumFractionDigits: 2,
                                            }).format(val?.auction?.recycle_value)
                                          : "N/A"}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </td>
                          <td class="text-end">
                            {val?.auction?.seller_reserve > 0
                              ? `${Math.round(val?.auction?.seller_bid / val?.auction?.seller_reserve)}%`
                              : "N/A"}
                          </td>
                          <td>
                            {val?.auction?.auction_state == "International" ||
                            val?.auction?.auction_country != "United States" ? (
                              <>
                                <div class="fw-medium">
                                  {val?.auction?.auction_city ? val.auction.auction_city : ""}
                                </div>
                                <a class="td-light-text green-btn-text">{val?.auction?.auction_country}</a>
                              </>
                            ) : (
                              <>
                                <div class="fw-medium">{val?.auction?.auction_city}</div>
                                <a class="td-light-text green-btn-text">{val?.auction?.auction_state}</a>
                              </>
                            )}
                          </td>
                          <td>
                            <div class="fw-medium">{val?.auction?.category_description}</div>
                          </td>
                        </tr>

                        {auctionId === val?.auction_id && (
                          <MoreButton
                            parentprops={props}
                            showMore={showMore}
                            auctionId={auctionId}
                            refStart={props.refStart}
                            refStop={props.refStop}
                          />
                        )}
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-between">
                <div className="d-flex justify-content-start mt-3">
                  <span className="mt-2 me-3">
                    Page {props.dashboardData?.allListings?.page} of {props.dashboardData?.allListings?.max_pages} (
                    {props.dashboardData?.allListings?.total_records} records)
                  </span>
                  <span className="mt-2 ms-4 me-2">Go to:</span>
                  <div style={{ width: "80px" }}>
                    <Select
                      className="basic-single "
                      classNamePrefix="select-search"
                      value={pageOption}
                      options={pageNumOption}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.value}
                      name="filter"
                      placeholder="1"
                      styles={customStyles}
                      onChange={(e) => {
                        const data = { ...pageOption };
                        data.name = e.name;
                        data.value = e.value;
                        setPageOption(data);
                        setPage(e.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <Accordion className="accordion new-custom-accor" id="accorParentElementTwo">
              {data?.map((val, i) => {
                const values = Object.values(val)?.length > 0 ? Object.values(val) : [];
                const show =
                  values[values.length - 1]?.length > 0
                    ? values[values.length - 1][0]?.ct > 0
                    : values[values.length - 1]?.ct > 0;
                return (
                  show && (
                    <Accordion.Item
                      className="accordion-item"
                      eventKey={values[0]}
                      onClick={() => fetchSorted(values[0])}
                    >
                      <Accordion.Button class="accordion-button ">
                        <div class="accordion-button-insider">
                          <h3>
                            {values[1]}
                            {`(${
                              values[values.length - 1]?.length > 0
                                ? values[values.length - 1][0]?.ct
                                : values[values.length - 1]?.ct
                            })`}
                          </h3>
                        </div>
                      </Accordion.Button>
                      <Accordion.Body onClick={(e) => e.stopPropagation()}>
                        {loading ? (
                          <span>Loading...</span>
                        ) : (
                          <>
                            <div
                              className="mt-2 mb-2"
                              style={{
                                width: "30%",
                                display: "flex",
                              }}
                            >
                              <Select
                                className="basic-single "
                                classNamePrefix="select-search"
                                value={sortedOption}
                                options={sortingOptions}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.value}
                                name="filter"
                                placeholder="--sort--"
                                styles={customStyles}
                                onChange={(e) => {
                                  const data = { ...sortedOption };
                                  data.name = e.name;
                                  data.value = e.value;
                                  setSortedOption(data);
                                  setSort(e.value);
                                }}
                              />
                              {/* <button className="btn btn-default ms-2">SELECT ALL</button> */}
                            </div>
                            <div class="table-responsive position-relative mt-3">
                              <table class="table table-bordered custom-table mb-0">
                                <tbody>
                                  {sortedData?.length > 0 &&
                                    sortedData?.map((val, i) => (
                                      <React.Fragment key={i}>
                                        <tr>
                                          {/* <td>
                        <div class="d-flex align-items-center">
                          <div class="form-check tick-checkbox">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id={`checkTwo` + i}
                            />
                            <label
                              class="form-check-label"
                              for={`checkTwo` + i}
                            ></label>
                          </div>
                        </div>
                      </td> */}
                                          <td>
                                            <div class="td-image-td">
                                              <a href={`/listing-detail?id=${val?.auction_id}`} target="_blank">
                                                <img src={val?.auction?.primary_picture} alt="" />
                                              </a>
                                            </div>
                                          </td>
                                          <td></td>
                                          <td>
                                            <div class="fw-medium">
                                              <em>{val?.auction?.status_description}</em>
                                            </div>
                                            {val?.auction?.bigdeal && <div class="td-tag">BD</div>}
                                            {val?.auction?.hotlist && <div class="td-tag">PL</div>}
                                          </td>
                                          <td>
                                            <a
                                              href="javascript:void(0)"
                                              class="btn btn-primary btn-sm"
                                              onClick={() => fetchMore(val?.auction_id)}
                                            >
                                              {auctionId === val?.auction_id ? "Less" : "More"}
                                            </a>
                                          </td>
                                          <td>
                                            <div class="fw-medium green-text">
                                              <a href={`/listing-detail?id=${val?.auction_id}`} target="_blank">
                                                {val?.auction?.proxibid > 0 && (
                                                  <span class="tag-label blue" style={{ marginRight: "5px" }}>
                                                    PB
                                                  </span>
                                                )}
                                                {val?.auction?.title}
                                              </a>
                                            </div>
                                            <p>
                                              {val?.auction?.quantity?.toLocaleString()} {val?.auction?.unit}
                                            </p>
                                          </td>
                                          <td>
                                            <div
                                              class="fw-medium green-text cursor-pointer"
                                              onClick={() => {
                                                if (process.env.REACT_APP_MODE == "development") {
                                                  window.open(
                                                    `https://admin-dev.salvex.com/admin/_user/_edit.cfm?user_id=${val?.auction?.s_user_id}`,
                                                  );
                                                } else {
                                                  window.open(
                                                    `https://admin.salvex.com/admin/_user/_edit.cfm?user_id=${val?.auction?.s_user_id}`,
                                                  );
                                                }
                                              }}
                                            >
                                              {val?.auction?.seller_name}
                                            </div>
                                            <div
                                              className="cursor-pointer"
                                              onClick={() => {
                                                if (process.env.REACT_APP_MODE == "development") {
                                                  window.open(
                                                    `https://admin-dev.salvex.com/admin/_user/_edit.cfm?user_id=${val?.auction?.s_user_id}`,
                                                  );
                                                } else {
                                                  window.open(
                                                    `https://admin.salvex.com/admin/_user/_edit.cfm?user_id=${val?.auction?.s_user_id}`,
                                                  );
                                                }
                                              }}
                                            >
                                              {val?.auction?.seller_company}
                                            </div>
                                            <div class="d-text-list d-flex flex-wrap">
                                              <div class="d-text-item me-1">
                                                <em>{val?.auction?.success_rate?.toFixed(2)}%</em>
                                              </div>
                                              <div class="d-text-item me-1">
                                                <em>
                                                  {val?.auction?.rate1}/{val?.auction?.rate2}
                                                </em>
                                              </div>
                                              <div class="d-text-item">
                                                {val?.auction?.emailStatusCode === 0 ? (
                                                  <img src={greenEmail} alt="" title="Email OK" />
                                                ) : val?.auction?.emailStatusCode === 1 ? (
                                                  <img
                                                    src={purpleEmail}
                                                    alt=""
                                                    title="Email Temporarily Suspended/Unreachable or Undetermined (Soft Bounce)"
                                                  />
                                                ) : val?.auction?.emailStatusCode === 2 ? (
                                                  <img src={invalid} alt="" title="Email Invalid" />
                                                ) : val?.auction?.emailStatusCode === 3 ? (
                                                  <img
                                                    src={spamEmail}
                                                    alt=""
                                                    title="Email Blocked by Spam or Firewall"
                                                  />
                                                ) : (
                                                  <img src={notVerified} alt="" title="Email Not Verified" />
                                                )}
                                              </div>
                                            </div>
                                            <div class="td-button-group d-flex">
                                              <img
                                                src={`https://us-central1-salvex-mail.cloudfunctions.net/requestRecipientStats?email=${val?.auction?.seller_email}`}
                                                alt=""
                                              />
                                            </div>
                                            <div>{val?.auction?.industry_description}</div>
                                            <div class="td-icon-list d-flex flex-wrap align-items-center">
                                              {val?.auction?.seller_dhs_approved ? (
                                                <div class="td-icon-item">
                                                  <div class="td-icon-box">
                                                    <img src={icon1} alt="" />
                                                  </div>
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                              {val?.auction?.seller_okay ? (
                                                <div class="td-icon-item">
                                                  <div class="td-icon-box">
                                                    <img src={icon2} alt="" />
                                                  </div>
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                              {val?.auction?.seller_stop_working ? (
                                                <div class="td-icon-item">
                                                  <div class="td-icon-box">
                                                    <img src={icon3} alt="" width="30px" height="30px" />
                                                  </div>
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </td>
                                          <td>
                                            {val?.auction?.trader_name ? (
                                              <div className="d-flex">
                                                <span className="fw-medium">T: </span>
                                                <span className="fw-medium ms-2">{val?.auction?.trader_name}</span>
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                            {val?.auction?.internal_referral ? (
                                              <div className="d-flex">
                                                <span className="fw-medium">BD: </span>
                                                <span className="fw-medium ms-2">
                                                  {val?.auction?.internal_referral}
                                                </span>
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                            {val?.auction?.mgr_name ? (
                                              <div className="d-flex">
                                                <span className="fw-medium">TM: </span>
                                                <span className="fw-medium ms-2">{val?.auction?.mgr_name}</span>
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                            {val?.auction?.admin_name ? (
                                              <div className="d-flex">
                                                <span className="fw-medium">BDM: </span>
                                                <span className="fw-medium ms-2">{val?.auction?.admin_name}</span>
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </td>
                                          <td>
                                            {val?.auction?.topbidders[0]?.name ? (
                                              <>
                                                <div class="td-icon-list d-flex flex-wrap align-items-center">
                                                  {val?.auction?.topbidders[0]?.dhs_approved ? (
                                                    <div class="td-icon-item">
                                                      <div class="td-icon-box">
                                                        <img src={icon1} alt="" />
                                                      </div>
                                                    </div>
                                                  ) : (
                                                    ""
                                                  )}
                                                  {val?.auction?.topbidders[0]?.okay ? (
                                                    <div class="td-icon-item">
                                                      <div class="td-icon-box">
                                                        <img src={icon2} alt="" />
                                                      </div>
                                                    </div>
                                                  ) : (
                                                    ""
                                                  )}
                                                  {val?.auction?.topbidders[0]?.stop_working ? (
                                                    <div class="td-icon-item">
                                                      <div class="td-icon-box">
                                                        <img src={icon3} alt="" width="30px" height="30px" />
                                                      </div>
                                                    </div>
                                                  ) : (
                                                    ""
                                                  )}
                                                </div>
                                                <div
                                                  class="fw-medium green-text cursor-pointer"
                                                  onClick={() => {
                                                    if (process.env.REACT_APP_MODE == "development") {
                                                      window.open(
                                                        `https://admin-dev.salvex.com/admin/_user/_edit.cfm?user_id=${val?.auction?.topbidders[0]?.b_user_id}`,
                                                      );
                                                    } else {
                                                      window.open(
                                                        `https://admin.salvex.com/admin/_user/_edit.cfm?user_id=${val?.auction?.topbidders[0]?.b_user_id}`,
                                                      );
                                                    }
                                                  }}
                                                >
                                                  {val?.auction?.topbidders[0]?.name}
                                                </div>
                                                <div class="d-text-list d-flex align-items-center">
                                                  <div class="d-text-item fw-medium green-text-label">
                                                    {val?.auction?.topbidders[0].ct2 && val?.auction?.topbidders[0]?.ct3
                                                      ? Math.round(
                                                          (val?.auction?.topbidders[0]?.ct2 /
                                                            val?.auction?.topbidders[0]?.ct3) *
                                                            100,
                                                        )
                                                      : "0"}
                                                    % (
                                                    {val?.auction?.topbidders[0]?.ct2
                                                      ? val?.auction?.topbidders[0]?.ct2
                                                      : "0"}
                                                    )
                                                  </div>
                                                  <div class="d-text-item fw-medium grey-text-label">
                                                    {val?.auction?.topbidders[0]?.ct1 &&
                                                    val?.auction?.topbidders[0]?.ct3
                                                      ? Math.round(
                                                          (val?.auction?.topbidders[0]?.ct1 /
                                                            val?.auction?.topbidders[0]?.ct3) *
                                                            100,
                                                        )
                                                      : "0"}
                                                    % (
                                                    {val?.auction?.topbidders[0]?.ct1
                                                      ? val?.auction?.topbidders[0]?.ct1
                                                      : "0"}
                                                    )
                                                  </div>
                                                  <div class="d-text-item fw-medium red-text-label">
                                                    {val?.auction?.topbidders[0]?.ct0 &&
                                                    val?.auction?.topbidders[0]?.ct3
                                                      ? Math.round(
                                                          (val?.auction?.topbidders[0]?.ct0 /
                                                            val?.auction?.topbidders[0]?.ct3) *
                                                            100,
                                                        )
                                                      : "0"}
                                                    % (
                                                    {val?.auction?.topbidders[0]?.ct0
                                                      ? val?.auction?.topbidders[0]?.ct0
                                                      : "0"}
                                                    )
                                                  </div>
                                                  <div>
                                                    {val?.auction?.topbidders[0]?.emailStatusCode === 0 ? (
                                                      <img src={greenEmail} alt="" title="Email OK" />
                                                    ) : val?.auction?.topbidders[0]?.emailStatusCode === 1 ? (
                                                      <img
                                                        src={purpleEmail}
                                                        alt=""
                                                        title="Email Temporarily Suspended/Unreachable or Undetermined (Soft Bounce)"
                                                      />
                                                    ) : val?.auction?.topbidders[0]?.emailStatusCode === 2 ? (
                                                      <img src={invalid} alt="" title="Email Invalid" />
                                                    ) : val?.auction?.topbidders[0]?.emailStatusCode === 3 ? (
                                                      <img
                                                        src={spamEmail}
                                                        alt=""
                                                        title="Email Blocked by Spam or Firewall"
                                                      />
                                                    ) : (
                                                      <img src={notVerified} alt="" title="Email Not Verified" />
                                                    )}
                                                  </div>
                                                </div>

                                                <div class="td-button-group d-flex">
                                                  <img
                                                    src={`https://us-central1-salvex-mail.cloudfunctions.net/requestRecipientStats?email=${val?.auction?.topbidders[0]?.email}`}
                                                    alt=""
                                                  />
                                                </div>
                                                <div>{val?.auction?.topbidders[0]?.location}</div>
                                              </>
                                            ) : (
                                              "N/A"
                                            )}
                                          </td>
                                          <td class="text-end">{val?.auction?.bid_count}</td>
                                          <td class="text-end">
                                            {val?.auction?.topbidders.length > 0 &&
                                            val?.auction?.topbidders[0].total_bid > val?.auction?.seller_reserve
                                              ? "$" +
                                                new Intl.NumberFormat("en-US", {
                                                  style: "decimal",
                                                  minimumFractionDigits: 2,
                                                }).format(
                                                  val?.auction?.topbidders[0].total_bid - val?.auction?.seller_reserve,
                                                )
                                              : "N/A"}
                                          </td>
                                          <td>
                                            <div class="text-end">
                                              {val?.auction?.seller_bid_date ? (
                                                <>
                                                  <div class="fw-medium">
                                                    {"$" +
                                                      new Intl.NumberFormat("en-US", {
                                                        style: "decimal",
                                                        minimumFractionDigits: 2,
                                                      }).format(val?.auction?.seller_bid)}
                                                  </div>
                                                  <div class="td-light-text">
                                                    {moment(val?.auction?.seller_bid_date).format("MM/DD/YYYY")}
                                                  </div>
                                                </>
                                              ) : (
                                                "N/A"
                                              )}
                                            </div>
                                          </td>
                                          <td>
                                            <div class="flex-table-row">
                                              {val?.auction?.topbidders[0]?.total_bid ? (
                                                <div class="flex-table-item d-flex flex-nowrap align-items-start">
                                                  <div class="ft-txt-start">HB</div>
                                                  <div class="ft-dot">:</div>
                                                  <div class="ft-txt-end">
                                                    <div class="text-end w-100 d-flex flex-column">
                                                      <div class="fw-medium">
                                                        {val?.auction?.topbidders[0]?.total_bid
                                                          ? "$" +
                                                            new Intl.NumberFormat("en-US", {
                                                              style: "decimal",
                                                              minimumFractionDigits: 2,
                                                            }).format(val?.auction?.topbidders[0]?.total_bid)
                                                          : "N/A"}
                                                      </div>
                                                      <div class="td-light-text">
                                                        {val?.auction?.topbidders[0]?.total_bid
                                                          ? moment(val?.auction?.topbidders[0]?.bid_time).format(
                                                              "MM/DD/YYYY",
                                                            )
                                                          : ""}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                              {val?.auction?.seller_reserve ? (
                                                <div class="flex-table-item d-flex flex-nowrap align-items-start">
                                                  <div class="ft-txt-start">SR</div>
                                                  <div class="ft-dot">:</div>
                                                  <div class="ft-txt-end">
                                                    <div class="text-end w-100 d-flex flex-column">
                                                      <div class="fw-medium">
                                                        {val?.auction?.seller_reserve
                                                          ? "$" +
                                                            new Intl.NumberFormat("en-US", {
                                                              style: "decimal",
                                                              minimumFractionDigits: 2,
                                                            }).format(val?.auction?.seller_reserve)
                                                          : "N/A"}
                                                      </div>
                                                      <div class="td-light-text">
                                                        {val?.auction?.due_date
                                                          ? moment(val?.auction?.due_date).format("MM/DD/YYYY")
                                                          : ""}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                              {val?.auction?.orig_wholesale_value ? (
                                                <div class="flex-table-item d-flex flex-nowrap align-items-start">
                                                  <div class="ft-txt-start">WV</div>
                                                  <div class="ft-dot">:</div>
                                                  <div class="ft-txt-end">
                                                    <div class="text-end w-100 d-flex flex-column">
                                                      <div class="fw-medium">
                                                        {val?.auction?.orig_wholesale_value
                                                          ? "$" +
                                                            new Intl.NumberFormat("en-US", {
                                                              style: "decimal",
                                                              minimumFractionDigits: 2,
                                                            }).format(val?.auction?.orig_wholesale_value)
                                                          : "N/A"}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                              {val?.auction?.public_reserve ? (
                                                <div class="flex-table-item d-flex flex-nowrap align-items-start">
                                                  <div class="ft-txt-start">AP</div>
                                                  <div class="ft-dot">:</div>
                                                  <div class="ft-txt-end">
                                                    <div class="text-end w-100 d-flex flex-column">
                                                      <div class="fw-medium">
                                                        {val?.auction?.public_reserve
                                                          ? "$" +
                                                            new Intl.NumberFormat("en-US", {
                                                              style: "decimal",
                                                              minimumFractionDigits: 2,
                                                            }).format(val?.auction?.public_reserve)
                                                          : "N/A"}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                              {val?.auction?.recycle_value ? (
                                                <div class="flex-table-item d-flex flex-nowrap align-items-start">
                                                  <div class="ft-txt-start">RV</div>
                                                  <div class="ft-dot">:</div>
                                                  <div class="ft-txt-end">
                                                    <div class="text-end w-100 d-flex flex-column">
                                                      <div class="fw-medium">
                                                        {val?.auction?.recycle_value
                                                          ? "$" +
                                                            new Intl.NumberFormat("en-US", {
                                                              style: "decimal",
                                                              minimumFractionDigits: 2,
                                                            }).format(val?.auction?.recycle_value)
                                                          : "N/A"}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </td>
                                          <td class="text-end">
                                            {val?.auction?.seller_reserve > 0
                                              ? `${Math.round(
                                                  val?.auction?.seller_bid / val?.auction?.seller_reserve,
                                                )}%`
                                              : "N/A"}
                                          </td>
                                          <td>
                                            {val?.auction?.auction_state == "International" ||
                                            val?.auction?.auction_country != "United States" ? (
                                              <>
                                                <div class="fw-medium">
                                                  {val?.auction?.auction_city ? val.auction.auction_city : ""}
                                                </div>
                                                <a class="td-light-text green-btn-text">
                                                  {val?.auction?.auction_country}
                                                </a>
                                              </>
                                            ) : (
                                              <>
                                                <div class="fw-medium">{val?.auction?.auction_city}</div>
                                                <a class="td-light-text green-btn-text">
                                                  {val?.auction?.auction_state}
                                                </a>
                                              </>
                                            )}
                                          </td>
                                          <td>
                                            <div class="fw-medium">{val?.auction?.category_description}</div>
                                          </td>
                                        </tr>

                                        {auctionId === val?.auction_id && (
                                          <MoreButton
                                            parentprops={props}
                                            showMore={showMore}
                                            auctionId={auctionId}
                                            refStart={props.refStart}
                                            refStop={props.refStop}
                                          />
                                        )}
                                      </React.Fragment>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                            <div className="d-flex justify-content-between">
                              <div className="d-flex justify-content-start mt-3">
                                <span className="mt-2 me-3">
                                  Page {props.dashboardData?.allListings?.page} of{" "}
                                  {props.dashboardData?.allListings?.max_pages} (
                                  {props.dashboardData?.allListings?.total_records} records)
                                </span>
                                <span className="mt-2 ms-4 me-2">Go to:</span>
                                <div style={{ width: "80px" }} onClick={(e) => e.stopPropagation()}>
                                  <Select
                                    className="basic-single "
                                    classNamePrefix="select-search"
                                    value={pageOption}
                                    options={pageNumOption}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.value}
                                    name="filter"
                                    placeholder="1"
                                    styles={customStyles}
                                    onChange={(e) => {
                                      const data = { ...pageOption };
                                      data.name = e.name;
                                      data.value = e.value;
                                      setPageOption(data);
                                      setPage(e.value);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                  )
                );
              })}
            </Accordion>
          )
        ) : (
          (!contentLoaded || noContent) &&
          (props.loadingMain ? (
            <Skeleton height="50px" />
          ) : (
            <div
              class="page-widget-empty-box text-capitalize d-flex align-items-center justify-content-center text-center"
              style={{
                height: "50px",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <span>{loading ? "Content Loading..." : "Content not loaded"}</span>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getAllListings: (params, callback) => dispatch(getAllListings(params, callback)),
  getSortedAllListings: (params, callback) => dispatch(getSortedAllListings(params, callback)),
  getSortedOtherListings: (params, callback) => dispatch(getSortedOtherListings(params, callback)),
});
const mapStateToProps = (state) => ({
  dashboardData: dashboardData(state),
  payableDetails: payableDetails(state),
  commentsAndNotesDetails: commentsAndNotesDetails(state),
  getListings: getListings(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(AllListings));
