import React, { useEffect, useState } from "react";
import Select from "react-select";
import { payableDetails } from "../../../store/payables";
import { connect } from "react-redux";
import { getInternalPeople, updateInternalPeople } from "../../../store/dashBoard";
import { detailListing } from "../../../store/listings";
import AlertError from "../../../common/alerts/alertError";
import { toast } from "react-toastify";

const customStyles1 = {
  indicatorSeparator: (styles) => ({ display: "none" }),
  option: (provided, state) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "#47ad1d",
      color: "#fff",
    },
    display: "-webkit-box",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    color: state.isSelected ? "#fff" : "black",
    backgroundColor: state.isSelected ? "#47ad1d" : provided.backgroundColor,
    // maxHeight: "84px",
  }),
  control: (base, state) => ({
    ...base,
    // height: 60,
    minHeight: 42,
    borderColor: state.isFocused ? "#47AD1D" : "#e4e4e4",
    boxShadow: state.isFocused ? "0 0 0 0.5px #47AD1D" : "0",
    border: state.isHovered ? "0" : "1px solid #e4e4e4",

    "&:hover": {
      borderColor: state.isFocused ? "#47AD1D" : "0",
      boxShadow: state.isFocused ? "0 0 0 0.5px #47AD1D" : "0",
    },
    width: "200px",
  }),
  multiValueRemove: (base, state) => ({
    ...base,
    "&:hover": {
      backgroundColor: "rgb(95, 50, 187, 10%)",
      color: "#6119c0",
    },
  }),
};
const EditInternalPeaople = (props) => {
  const [detail, setDetail] = useState(null);
  const [methodOriginOptions, setMethodOriginOptions] = useState([
    { value: "Trader Residual Business", label: "Trader Residual Business" },
    { value: "Select Method Origin", label: "Select Method Origin" },
    { value: "Salvex Residual Business", label: "Salvex Residual Business" },
    { value: "Residual Business", label: "Residual Business" },
    { value: "Regional Marketing", label: "Regional Marketing" },
    { value: "Referral", label: "Referral" },
    { value: "Procurement Request", label: "Procurement Request" },
    { value: "Posted By Seller", label: "Posted By Seller" },
    { value: "Portfolio Expansion", label: "Portfolio Expansion" },
    { value: "Mass Marketing", label: "Mass Marketing" },
    { value: "Managed Account", label: "Managed Account" },
    { value: "Mail Merge", label: "Mail Merge" },
    { value: "LinkedIn", label: "LinkedIn" },
    { value: "Inbound Call", label: "Inbound Call" },
    { value: "Google", label: "Google" },
    { value: "Facebook", label: "Facebook" },
    { value: "Face to Face Meeting", label: "Face to Face Meeting" },
    { value: "Direct Calls", label: "Direct Calls" },
    { value: "Contract", label: "Contract" },
    { value: "Conference", label: "Conference" },
    { value: "Called into the Office", label: "Called into the Office" },
    { value: "Advertising", label: "Advertising" },
  ]);

  const [creatorOptions, setCreatorOptions] = useState(null);
  const [createdBy, setCreatedBy] = useState("");
  const [outsideAgentOptions, setOutsideAgentOptions] = useState(null);
  const [methodOrigin, setMethodOrigin] = useState(null);
  const [isNewSectionVisible, setNewSectionVisible] = useState(false);
  const [newOptionText, setNewOptionText] = useState("");
  useEffect(() => {
    const fetchData = () => {
      props.refStart();
      props.getInternalPeople(props.auctionId, (res) => {
        setCreatorOptions(res.data.createdBy);
        setOutsideAgentOptions(res.data.outsideAgent);
        setDetail({
          method_origin: { value: res.data.data.method_origin, label: res.data.data.method_origin },
          individual_origin: {
            name01: res.data.data.individual_origin_name || "",
            member_id: findMemberIdByUserId(res.data.data.individual_origin, props.requestors) || 0,
          },
          sc_id: {
            name01: res.data.data.sc_id_name || "",
            member_id: findMemberIdByUserId(res.data.data.sc_id, props.requestors) || 0,
          },
          seller_commission:
            res.data.data.seller_commission === 0 ? "0.0" : (res.data.data.seller_commission * 100).toFixed(1),
          bc_id: {
            name01: res.data.data.bc_id_name || "",
            member_id: findMemberIdByUserId(res.data.data.bc_id, props.requestors) || 0,
          },
          buyer_commission:
            res.data.data.buyer_commission != 0 ? (res.data.data.buyer_commission * 100).toFixed(1) : "0.0",
          dc_id: {
            name01: res.data.data.dc_id_name || "",
            member_id: findMemberIdByUserId(res.data.data.dc_id, props.requestors) || 0,
          },
          deal_commission:
            res.data.data.deal_commission != 0 ? (res.data.data.deal_commission * 100).toFixed(1) : "0.0",
          dc_id_1: {
            name01: res.data.data.dc_id_1_name || "",
            member_id: findMemberIdByUserId(res.data.data.dc_id_1, props.requestors) || 0,
          },
          deal_commission_1:
            res.data.data.deal_commission_1 != 0 ? (res.data.data.deal_commission_1 * 100).toFixed(1) : "0.0",
          dc_id_2: {
            name01: res.data.data.dc_id_2_name || "",
            member_id: findMemberIdByUserId(res.data.data.dc_id_2, props.requestors) || 0,
          },
          deal_commission_2:
            res.data.data.deal_commission_2 != 0 ? (res.data.data.deal_commission_2 * 100).toFixed(1) : "0.0",
          dc_id_3: {
            name01: res.data.data.dc_id_3_name || "",
            member_id: findMemberIdByUserId(res.data.data.dc_id_3, props.requestors) || 0,
          },
          deal_commission_3:
            res.data.data.deal_commission_3 != 0 ? (res.data.data.deal_commission_3 * 100).toFixed(1) : "0.0",
          dc_id_4: {
            name01: res.data.data.dc_id_4_name || "",
            member_id: findMemberIdByUserId(res.data.data.dc_id_4, props.requestors) || 0,
          },
          deal_commission_4:
            res.data.data.deal_commission_4 != 0 ? (res.data.data.deal_commission_4 * 100).toFixed(1) : "0.0",
          author: { name01: res.data.data.aothor_name || "", member_id: res.data.data.author || 0 },
          mgr_id: {
            name01: res.data.data.mgr_id_name || "",
            member_id: findMemberIdByUserId(res.data.data.mgr_id, props.requestors) || 0,
          },
          mgr_commission: res.data.data.mgr_commission != 0 ? (res.data.data.mgr_commission * 100).toFixed(1) : "0.0",
          admin_id: {
            name01: res.data.data.admin_id_name || "",
            member_id: findMemberIdByUserId(res.data.data.admin_id, props.requestors) || 0,
          },
          admin_commission:
            res.data.data.admin_commission != 0 ? (res.data.data.admin_commission * 100).toFixed(1) : "0.0",
          outside_agent: {
            name01: res.data.data.outside_agent_name || "",
            member_id: res.data.data.outside_agent || 0,
          },
          outside_agent_commission:
            res.data.data.outside_agent_commission != 0
              ? (res.data.data.outside_agent_commission * 100).toFixed(1)
              : "0.0",
        });
        setMethodOrigin({ value: res.data.data.method_origin, label: res.data.data.method_origin });
        props.refStop();
      });
    };
    fetchData();
  }, []);
  const toggleNewSection = () => {
    setNewSectionVisible(!isNewSectionVisible);
  };
  const addNewOption = () => {
    if (newOptionText.trim() !== "") {
      const newOption = {
        value: newOptionText,
        label: newOptionText,
      };

      setMethodOriginOptions([...methodOriginOptions, newOption]);
      const data = { ...detail };
      data.method_origin = newOption;
      setDetail(data);
      setNewOptionText("");
      setNewSectionVisible(false);
    }
  };
  function findUserIdByMemberId(memberId, data) {
    for (let i = 0; i < data.length; i++) {
      if (data[i].member_id === memberId) {
        return data[i].user_id;
      }
    }
    return 0; // Member not found
  }
  function findMemberIdByUserId(userId, data) {
    for (let i = 0; i < data.length; i++) {
      if (data[i].user_id === userId) {
        return data[i].member_id;
      }
    }
    return null; // User not found
  }
  const saveInternalPeaople = () => {
    props.refStart();
    const payload = {
      method_origin: detail.method_origin.value
        ? detail.method_origin.value === "Select Method Origin"
          ? ""
          : detail.method_origin.value
        : "",
      individual_origin: findUserIdByMemberId(detail.individual_origin.member_id, props.requestors),
      sc_id: findUserIdByMemberId(detail.sc_id.member_id, props.requestors),
      seller_commission: Number(detail.seller_commission),
      bc_id: findUserIdByMemberId(detail.bc_id.member_id, props.requestors),
      buyer_commission: Number(detail.buyer_commission),
      dc_id: findUserIdByMemberId(detail.dc_id.member_id, props.requestors),
      deal_commission: Number(detail.deal_commission),
      dc_id_1: findUserIdByMemberId(detail.dc_id_1.member_id, props.requestors),
      deal_commission_1: Number(detail.deal_commission_1),
      dc_id_2: findUserIdByMemberId(detail.dc_id_2.member_id, props.requestors),
      deal_commission_2: Number(detail.deal_commission_2),
      dc_id_3: findUserIdByMemberId(detail.dc_id_3.member_id, props.requestors),
      deal_commission_3: Number(detail.deal_commission_3),
      dc_id_4: findUserIdByMemberId(detail.dc_id_4.member_id, props.requestors),
      deal_commission_4: Number(detail.deal_commission_4),
      author: detail.author.member_id,
      mgr_id: findUserIdByMemberId(detail.mgr_id.member_id, props.requestors),
      mgr_commission: Number(detail.mgr_commission),
      admin_id: findUserIdByMemberId(detail.admin_id.member_id, props.requestors),
      admin_commission: Number(detail.admin_commission),
      outside_agent: detail.outside_agent.member_id,
      outside_agent_commission: Number(detail.outside_agent_commission),
    };
    props.updateInternalPeople(props.auctionId, payload, (res) => {
      if (res.status === 200) {
        props.refStop();
      } else {
        props.refStop();
        toast(<AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
    props.openEditInternal();
  };
  const handleBlur = (e) => {
    const [value, name] = e.target;
    const floatValue = parseFloat(value);
    if (!isNaN(floatValue)) {
      const updatedValue = (floatValue / 100).toFixed(1);
      setDetail((prevDetail) => ({ ...prevDetail, [name]: updatedValue }));
    }
  };
  return (
    <table className="table mb-0 product-table">
      {detail ? (
        <tbody id="tblExpenses">
          <tr className="highlight">
            <td align="right">Created By:</td>
            <td colSpan="2" className="form-group-15">
              <Select
                styles={customStyles1}
                options={creatorOptions}
                //value={creatorOptions.find(option => option.member_id === detail.author.member_id) || ""}
                onChange={(e) => {
                  const data = { ...detail };
                  data.author = e;
                  setDetail(data);
                }}
                isDisabled={true}
                getOptionLabel={(option) => option.name01}
                getOptionValue={(option) => option.member_id}
                placeholder=""
              />
            </td>
            <td colSpan="2"></td>
          </tr>
          <tr className="highlight">
            <td align="right">Method Origin:</td>
            <td colSpan="2">
              <Select
                styles={customStyles1}
                options={methodOriginOptions}
                value={detail.method_origin}
                onChange={(e) => {
                  const data = { ...detail };
                  data.method_origin = e;
                  setDetail(data);
                }}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
              />
              <div>
                {isNewSectionVisible ? (
                  <div className="add_new_div">
                    <input
                      className="mt-2"
                      type="text"
                      value={newOptionText}
                      onChange={(e) => setNewOptionText(e.target.value)}
                    />
                    <button type="button" className="btn btn-primary btn-xs-size btn-auto mt-2" onClick={addNewOption}>
                      SAVE
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary btn-xs-size btn-auto mt-2"
                      onClick={toggleNewSection}
                    >
                      CANCEL
                    </button>
                  </div>
                ) : (
                  <button
                    className="btn btn-primary btn-xs-size btn-auto mt-2"
                    type="button"
                    onClick={toggleNewSection}
                  >
                    NEW
                  </button>
                )}
              </div>
            </td>
            <td colSpan="2"></td>
          </tr>
          <tr className="highlight">
            <td align="right">Individual Origin:</td>
            <td colSpan="2" className="form-group-15">
              <Select
                name="individual_origin"
                styles={customStyles1}
                options={props.requestors}
                value={props.requestors.find((option) => option.member_id === detail.individual_origin.member_id) || ""}
                onChange={(e) => {
                  const data = { ...detail };
                  data.individual_origin = e;
                  setDetail(data);
                }}
                getOptionLabel={(option) => option.name01}
                getOptionValue={(option) => option.member_id}
              />
            </td>
            <td colSpan="2"></td>
          </tr>
          <tr className="highlisht">
            <td width="" align="right">
              Trader:
            </td>
            <td colSpan="2" className="form-group-15">
              <Select
                name="trader"
                styles={customStyles1}
                options={props.requestors}
                value={props.requestors.find((option) => option.member_id === detail.bc_id.member_id) || ""}
                onChange={(e) => {
                  const data = { ...detail };
                  data.bc_id = e;
                  setDetail(data);
                }}
                getOptionLabel={(option) => option.name01}
                getOptionValue={(option) => option.member_id}
              />
            </td>
            <td>+</td>
            <td width="100" align="right">
              <input
                name="buyer_commission"
                style={{ textAlign: "right" }}
                type="text"
                value={detail.buyer_commission}
                onBlur={handleBlur}
                onChange={(e) => {
                  setDetail((prevDetail) => ({ ...prevDetail, buyer_commission: e.target.value }));
                }}
              />
            </td>
          </tr>
          <tr className="highlisht">
            <td width="" align="right">
              Int. Ref.:
            </td>
            <td colSpan="2" className="form-group-15">
              <Select
                name="Int_ref"
                styles={customStyles1}
                options={props.requestors}
                value={props.requestors.find((option) => option.member_id === detail.sc_id.member_id) || ""}
                onChange={(a) => {
                  const data = { ...detail };
                  data.sc_id = a;
                  setDetail(data);
                }}
                getOptionLabel={(option) => option.name01}
                getOptionValue={(option) => option.member_id}
              />
            </td>
            <td>+</td>
            <td width="100" align="right">
              <input
                name="seller_commission"
                style={{ textAlign: "right" }}
                type="text"
                value={detail.seller_commission}
                onBlur={handleBlur}
                onChange={(e) => {
                  setDetail((prevState) => ({ ...prevState, seller_commission: e.target.value }));
                }}
              />
            </td>
          </tr>
          <tr className="highlisht">
            <td width="" align="right">
              Admin 1:
            </td>
            <td colSpan="2" className="form-group-15">
              <Select
                name="admin1"
                styles={customStyles1}
                options={props.requestors}
                value={props.requestors.find((option) => option.member_id === detail.dc_id.member_id) || ""}
                onChange={(a) => {
                  const data = { ...detail };
                  data.dc_id = a;
                  setDetail(data);
                }}
                getOptionLabel={(option) => option.name01}
                getOptionValue={(option) => option.member_id}
              />
            </td>
            <td>+</td>
            <td width="100" align="right">
              <input
                style={{ textAlign: "right" }}
                type="text"
                name="deal_commission"
                value={detail.deal_commission}
                onBlur={handleBlur}
                onChange={(e) => {
                  setDetail((prevState) => ({ ...prevState, deal_commission: e.target.value }));
                }}
              />
            </td>
          </tr>
          <tr className="highlisht">
            <td width="" align="right">
              Admin 2:
            </td>
            <td colSpan="2" className="form-group-15">
              <Select
                name="admin2"
                styles={customStyles1}
                options={props.requestors}
                value={props.requestors.find((option) => option.member_id === detail.dc_id_1.member_id) || ""}
                onChange={(a) => {
                  const data = { ...detail };
                  data.dc_id_1 = a;
                  setDetail(data);
                }}
                getOptionLabel={(option) => option.name01}
                getOptionValue={(option) => option.member_id}
              />
            </td>
            <td>+</td>
            <td width="100" align="right">
              <input
                name="deal_commission_1"
                style={{ textAlign: "right" }}
                type="text"
                value={detail.deal_commission_1}
                onBlur={handleBlur}
                onChange={(e) => {
                  const data = { ...detail };
                  data.deal_commission_1 = e.target.value;
                  setDetail(data);
                }}
              />
            </td>
          </tr>
          <tr className="highlisht">
            <td width="" align="right">
              Mid Manager:
            </td>
            <td colSpan="2" className="form-group-15">
              <Select
                styles={customStyles1}
                options={props.requestors}
                value={props.requestors.find((option) => option.member_id === detail.dc_id_2.member_id) || ""}
                onChange={(a) => {
                  const data = { ...detail };
                  data.dc_id_2 = a;
                  setDetail(data);
                }}
                name="midManager"
                getOptionLabel={(option) => option.name01}
                getOptionValue={(option) => option.member_id}
              />
            </td>
            <td>+</td>
            <td width="100" align="right">
              <input
                name="deal_commission_2"
                style={{ textAlign: "right" }}
                type="text"
                value={detail.deal_commission_2}
                onChange={(e) => {
                  const data = { ...detail };
                  data.deal_commission_2 = e.target.value;
                  setDetail(data);
                }}
                onBlur={handleBlur}
              />
            </td>
          </tr>
          <tr className="highlisht">
            <td width="" align="right">
              Optimizer:
            </td>
            <td colSpan="2" className="form-group-15">
              <Select
                name="optimizer"
                styles={customStyles1}
                options={props.requestors}
                value={props.requestors.find((option) => option.member_id === detail.dc_id_3.member_id) || ""}
                onChange={(a) => {
                  const data = { ...detail };
                  data.dc_id_3 = a;
                  setDetail(data);
                }}
                getOptionLabel={(option) => option.name01}
                getOptionValue={(option) => option.member_id}
              />
            </td>
            <td>+</td>
            <td width="100" align="right">
              <input
                name="deal_commission_3"
                style={{ textAlign: "right" }}
                type="text"
                value={detail.deal_commission_3}
                onChange={(e) => {
                  const data = { ...detail };
                  data.deal_commission_3 = e.target.value;
                  setDetail(data);
                }}
                onBlur={handleBlur}
              />
            </td>
          </tr>
          <tr className="highlisht">
            <td width="" align="right">
              Manager:
            </td>
            <td colSpan="2" className="form-group-15">
              <Select
                name="tradeManager"
                styles={customStyles1}
                options={props.requestors}
                value={props.requestors.find((option) => option.member_id === detail.mgr_id.member_id) || ""}
                onChange={(a) => {
                  const data = { ...detail };
                  data.mgr_id = a;
                  setDetail(data);
                }}
                getOptionLabel={(option) => option.name01}
                getOptionValue={(option) => option.member_id}
              />
            </td>
            <td>+</td>
            <td width="100" align="right">
              <input
                name="mgr_commission"
                style={{ textAlign: "right" }}
                type="text"
                value={detail.mgr_commission}
                onChange={(e) => {
                  const data = { ...detail };
                  data.mgr_commission = e.target.value;
                  setDetail(data);
                }}
                onBlur={handleBlur}
              />
            </td>
          </tr>
          <tr className="highlisht">
            <td width="" align="right">
              Active Administrator:
            </td>
            <td colSpan="2" className="form-group-15">
              <Select
                name="bdManager"
                styles={customStyles1}
                options={props.requestors}
                value={props.requestors.find((option) => option.member_id === detail.admin_id.member_id) || ""}
                onChange={(a) => {
                  const data = { ...detail };
                  data.admin_id = a;
                  setDetail(data);
                }}
                getOptionLabel={(option) => option.name01}
                getOptionValue={(option) => option.member_id}
              />
            </td>
            <td>+</td>
            <td width="100" align="right">
              <input
                style={{ textAlign: "right" }}
                type="text"
                name="admin_commission"
                value={detail.admin_commission}
                onChange={(e) => {
                  const data = { ...detail };
                  data.admin_commission = e.target.value;
                  setDetail(data);
                }}
                onBlur={handleBlur}
              />
            </td>
          </tr>
          <tr className="highlisht">
            <td width="" align="right">
              Outside Agent:
            </td>
            <td colSpan="2" className="form-group-15">
              <Select
                name="outsideAgent"
                styles={customStyles1}
                options={outsideAgentOptions}
                value={outsideAgentOptions.find((option) => option.member_id === detail.outside_agent.member_id) || ""}
                onChange={(a) => {
                  const data = { ...detail };
                  data.outside_agent = a;
                  setDetail(data);
                }}
                getOptionLabel={(option) => option.name01}
                getOptionValue={(option) => option.member_id}
              />
            </td>
            <td>+</td>
            <td width="100" align="right">
              <input
                name="outside_agent_commission"
                style={{ textAlign: "right" }}
                type="text"
                value={detail.outside_agent_commission}
                onChange={(e) => {
                  const data = { ...detail };
                  data.outside_agent_commission = e.target.value;
                  setDetail(data);
                }}
                onBlur={handleBlur}
              />
            </td>
          </tr>
          <tr>
            <td colSpan="5">
              <hr />
            </td>
          </tr>
          <tr>
            <td width="" align="right">
              Ext. Referral:
            </td>
            <td colSpan="2">
              <input
                id="extrefname"
                className="editText"
                style={{ width: "145px" }}
                type="text"
                disabled="disabled"
                onChange={() => {}}
              />
              <input id="dc_id_4" name="dc_id_4" type="hidden" value="4" />
            </td>
            <td>+</td>
            <td width="100" align="right">
              <input
                name="deal_commission_4"
                style={{ textAlign: "right" }}
                type="text"
                value={detail.deal_commission_4}
                onChange={(e) => {
                  const data = { ...detail };
                  data.deal_commission_4 = e.target.value;
                  setDetail(data);
                }}
                onBlur={handleBlur}
              />
            </td>
          </tr>
          <tr>
            <td colSpan="5">
              <hr />
            </td>
          </tr>
          <tr>
            <td colSpan="5">
              <b>
                <b>
                  <button className="btn btn-primary btn-xs-size btn-auto mt-3 mb-3" onClick={saveInternalPeaople}>
                    SAVE INTERNAL PEOPLE
                  </button>
                </b>
              </b>
            </td>
          </tr>
        </tbody>
      ) : (
        <div className="d-flex justify-content-center align-items-center min-vh-100">
          <div className="progress" style={{ width: "100%" }}>
            <div
              className="progress-bar progress-bar-striped progress-bar-animated"
              role="progressbar"
              style={{ width: "100%", backgroundColor: "#47AD1D" }} // Set background color to white
            >
              Loading Content...
            </div>
          </div>
        </div>
      )}
    </table>
  );
};
const mapDispatchToProps = (dispatch) => ({
  getInternalPeople: (params, callback) => dispatch(getInternalPeople(params, callback)),
  updateInternalPeople: (params, data, callback) => {
    dispatch(updateInternalPeople(params, data, callback));
  },
});
const mapStateToProps = (state) => ({
  payableDetails: payableDetails(state),
});
export default connect(mapStateToProps, mapDispatchToProps)(React.memo(EditInternalPeaople));
