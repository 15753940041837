import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";

const slice = createSlice({
  name: "optimizationSite",
  initialState: {
    siteList: {},
    optimizationSite: {},
    bidData: {},
    loading: false,
  },
  reducers: {
    optimizationListFetching: (misc, action) => {
      misc.loading = true;
    },
    optimizationListFetched: (misc, action) => {
      misc.siteList = action.payload;
      misc.loading = false;
    },
    optimizationListFailed: (misc, action) => {
      misc.loading = false;
    },
    bidGraphFetching: (misc, action) => {
      misc.loading = true;
    },
    bidGraphFetched: (misc, action) => {
      misc.bidData = action.payload;
      misc.loading = false;
    },
    bidGraphFailed: (misc, action) => {
      misc.loading = false;
    },
    optimizationSiteDetailsFetching: (misc, action) => {
      misc.loading = true;
    },
    optimizationSiteDetailsFetched: (misc, action) => {
      misc.optimizationSite = action.payload;
      misc.loading = false;
    },
    optimizationSiteDetailsFailed: (misc, action) => {
      misc.loading = false;
    },
  },
});

export const {
  optimizationSiteDetailsFetching,
  optimizationSiteDetailsFetched,
  optimizationSiteDetailsFailed,
  optimizationListFetching,
  optimizationListFetched,
  optimizationListFailed,
  bidGraphFetched,
  bidGraphFetching,
  bidGraphFailed,
} = slice.actions;
export default slice.reducer;

// Action Creators
const baseUrl = "optimizationSite";

export const getBidGrpah = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/graph/${params}`,
      method: "get",
      callback,
      onStart: bidGraphFetching.type,
      onSuccess: bidGraphFetched.type,
      onError: bidGraphFailed.type,
    }),
  );
};

export const getOptimizationSiteDetails = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/${params}`,
      method: "get",
      callback,
      onStart: optimizationSiteDetailsFetching.type,
      onSuccess: optimizationSiteDetailsFetched.type,
      onError: optimizationSiteDetailsFailed.type,
    }),
  );
};

export const getOptimizationList = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/list/${params}`,
      method: "get",
      callback,
      onStart: optimizationListFetching.type,
      onSuccess: optimizationListFetched.type,
      onError: optimizationListFailed.type,
    }),
  );
};

export const addNewOptimization = (params, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/${params}`,
      method: "POST",
      data,
      callback,
    }),
  );
};

export const getOptimizationSites = createSelector(
  (state) => state.entities.optimizationSite,
  (optimizationSite) => optimizationSite,
);
