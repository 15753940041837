import { createSlice, current } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";

const slice = createSlice({
  name: "investments",
  initialState: {
    loading: false,
    investment: [],
    employee: [],
  },
  reducers: {
    investmentRequested: (misc, action) => {
      misc.loading = true;
    },
    investmentReceived: (misc, action) => {
      misc.investment = action.payload;
      misc.loading = false;
    },
    investmentRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    employeeRequested: (misc, action) => {
      misc.loading = true;
    },
    employeeReceived: (misc, action) => {
      misc.employee = action.payload;
      misc.loading = false;
    },
    employeeRequestFailed: (misc, action) => {
      misc.loading = false;
    },
  },
});

export const {
  investmentRequested,
  investmentReceived,
  investmentRequestFailed,
  employeeRequested,
  employeeReceived,
  employeeRequestFailed,
} = slice.actions;
export default slice.reducer;

// Action Creators

const baseUrl = "account";

export const getInvestments = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: baseUrl + "/investment",
      method: "get",
      params,
      callback,
      onStart: investmentRequested.type,
      onSuccess: investmentReceived.type,
      onError: investmentRequestFailed.type,
    })
  );
};
export const getEmployee = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: baseUrl + "/investment/employee",
      method: "get",
      callback,
      onStart: employeeRequested.type,
      onSuccess: employeeReceived.type,
      onError: employeeRequestFailed.type,
    })
  );
};

export const updateInvestments = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: baseUrl + "/investment",
      method: "post",
      data,
      callback,
    })
  );
};

export const getInv = createSelector(
  (state) => state.entities.investments,
  (investments) => investments
);
