import React, { useEffect, useState } from "react";
import { brokerData, getBrokerActivity } from "../../../store/broker";
import { connect } from "react-redux";
import DateFormat from "../../../common/DateFormat/dateFormat";
import { toast } from "react-toastify";
import AlertError from "../../../common/alerts/alertError";
import FilterModal from "../../../layouts/modals/broker/filterModal";
import close from "../../../include/images/close-icon.svg";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";

const Brokers = (props) => {
  const data = props?.brokerData?.brokerActivity?.data;
  const totalPages = props?.brokerData?.brokerActivity?.max_pages;
  const [show, setShow] = useState(false);
  const [enable, setEnable] = useState(false);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState("createdAt");
  const [order, setOrder] = useState("desc");
  const [keyword, setKeyword] = useState("");
  const [action, setAction] = useState("");
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState({
    keyword: "",
    action: "",
  });
  const [showFilter, setShowFilter] = useState(false);
  const toggleFilter = () => {
    setShowFilter(!showFilter);
    setKeyword(response.keyword);
    setAction(response.action);
  };

  const showBroker = () => {
    props?.refStart();
    setLoading(true);
    const payload = {
      auctionId: props?.auctionId,
      page: page,
      sort,
      order,
      keyword: response.keyword,
      action: response.action,
    };
    const param = _.pickBy(payload, function (value, key) {
      return !(value === "" || value == {} || value === undefined || value === null);
    });
    props?.getBrokerActivity(param, (res) => {
      if (res.status === 200) {
        props?.refStop();
        setLoading(false);
        setEnable(true);
        setShow(true);
      } else {
        props?.refStop();
        setLoading(false);
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        setEnable(false);
        setShow(false);
      }
    });
  };

  useEffect(() => {
    if (enable) {
      props?.refStart();
      setLoading(true);
      const payload = {
        auctionId: props?.auctionId,
        page: page,
        sort,
        order,
        keyword: response.keyword,
        action: response.action,
      };
      const param = _.pickBy(payload, function (value, key) {
        return !(value === "" || value == {} || value === undefined || value === null);
      });
      props?.getBrokerActivity(param, (res) => {
        if (res.status === 200) {
          props?.refStop();
          setLoading(false);
          setShow(true);
        } else {
          props?.refStop();
          setLoading(false);
          toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
          setShow(false);
        }
      });
    }
  }, [page]);

  useEffect(() => {
    if (enable) {
      props?.refStart();
      setLoading(true);
      const payload = {
        auctionId: props?.auctionId,
        page: 1,
        sort,
        order,
        keyword: response.keyword,
        action: response.action,
      };
      const param = _.pickBy(payload, function (value, key) {
        return !(value === "" || value == {} || value === undefined || value === null);
      });
      props?.getBrokerActivity(param, (res) => {
        setPage(1);
        if (res.status === 200) {
          props?.refStop();
          setLoading(false);
        } else {
          props?.refStop();
          setLoading(false);
          toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        }
      });
    }
  }, [sort, order]);

  const handleFilter = () => {
    props?.refStart();
    setLoading(true);
    const payload = { auctionId: props?.auctionId, page: 1, sort, order, keyword: keyword, action: action };
    const param = _.pickBy(payload, function (value, key) {
      return !(value === "" || value == {} || value === undefined || value === null);
    });
    props?.getBrokerActivity(param, (res) => {
      setPage(1);
      if (res.status === 200) {
        props?.refStop();
        setLoading(false);
        setResponse({
          keyword: keyword,
          action: action,
        });
        toggleFilter();
      } else {
        props?.refStop();
        setLoading(false);
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };
  const clearFilter = (filter) => {
    props?.refStart();
    setLoading(true);
    const payload = {
      auctionId: props?.auctionId,
      page: 1,
      sort,
      order,
      keyword: filter === "keyword" ? "" : response?.keyword,
      action: filter === "action" ? "" : response?.action,
    };
    const param = _.pickBy(payload, function (value, key) {
      return !(value === "" || value == {} || value === undefined || value === null);
    });
    props?.getBrokerActivity(param, (res) => {
      setPage(1);
      if (res.status === 200) {
        props?.refStop();
        setLoading(false);
      } else {
        props?.refStop();
        setLoading(false);
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  return (
    <div class="details-page-widget-block" id={props.id}>
      <div class="page-widget-head d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <div class="d-flex align-items-center">
            <div
              class="page-widget-label text-capitalize cursor-pointer"
              onClick={() => (show ? setShow(false) : showBroker())}
            >
              Broker Referrals (
              {show
                ? data?.length > 0
                  ? data?.length
                  : "0"
                : props?.count?.brokerActivity
                ? props?.count?.brokerActivity
                : "0"}
              )
            </div>
          </div>
          <li
            className="badge-item cursor-pointer"
            onClick={() => (show ? setShow(false) : showBroker())}
            style={{ marginLeft: "10px" }}
          >
            <div className="badge-box" style={{ background: "#DADEE0" }}>
              {show ? "Hide" : "Show"}
            </div>
          </li>
        </div>
        {show && (
          <div
            class="filter-row d-flex align-items-center"
            onClick={() => {
              toggleFilter();
            }}
          >
            <svg viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M2.39767 16.5C2.07751 16.5 1.81796 16.2357 1.81796 15.9097V1.09032C1.81796 0.764295 2.07751 0.5 2.39767 0.5C2.71784 0.5 2.97738 0.764295 2.97738 1.09032V15.9097C2.97738 16.2357 2.71784 16.5 2.39767 16.5Z"
                fill="currentColor"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M13.0226 15.9097C13.0226 16.2357 13.2822 16.5 13.6023 16.5C13.9225 16.5 14.182 16.2357 14.182 15.9097V1.09032C14.182 0.764295 13.9225 0.5 13.6023 0.5C13.2822 0.5 13.0226 0.764295 13.0226 1.09032V15.9097ZM0.57971 13.18H4.21565C4.53582 13.18 4.79536 12.9158 4.79536 12.5897C4.79536 12.2637 4.53582 11.9994 4.21565 11.9994H0.57971C0.259545 11.9994 0 12.2637 0 12.5897C0 12.9158 0.259545 13.18 0.57971 13.18Z"
                fill="currentColor"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.42029 15.9097C7.42029 16.2357 7.67983 16.5 8 16.5C8.32016 16.5 8.57971 16.2357 8.57971 15.9097V1.09032C8.57971 0.764295 8.32016 0.5 8 0.5C7.67983 0.5 7.42029 0.764295 7.42029 1.09032V15.9097ZM11.7843 13.18H15.4203C15.7405 13.18 16 12.9158 16 12.5897C16 12.2637 15.7405 11.9994 15.4203 11.9994H11.7843C11.4642 11.9994 11.2046 12.2637 11.2046 12.5897C11.2046 12.9158 11.4642 13.18 11.7843 13.18Z"
                fill="currentColor"
              />
              <path
                d="M9.81798 5.00058H6.18204C5.86187 5.00058 5.60233 4.73629 5.60233 4.41027C5.60233 4.08424 5.86187 3.81995 6.18204 3.81995H9.81798C10.1381 3.81995 10.3977 4.08424 10.3977 4.41027C10.3977 4.73629 10.1381 5.00058 9.81798 5.00058Z"
                fill="currentColor"
              />
            </svg>
            <span>Filters</span>
          </div>
        )}
      </div>
      {show &&
        (response?.action || response?.keyword) &&
        (loading ? (
          <div class="filter-tags d-flex align-items-start">
            <div class="filter-tag-title">
              <div class="tags d-flex align-items-start">
                <span class="badge">
                  <Skeleton />
                </span>
                <span class="badge">
                  <Skeleton />
                </span>
              </div>
            </div>
          </div>
        ) : (
          <div class="filter-tags d-flex align-items-start mt-2">
            <div class="filter-tag-title">Filters Applied :</div>

            <div class="tags">
              {response?.keyword && (
                <span class="badge cursor-pointer" onClick={() => toggleFilter()}>
                  Keyword : <span>{response?.keyword}</span>
                  <img
                    src={close}
                    alt=""
                    onClick={(e) => {
                      e.stopPropagation();
                      const data = { ...response };
                      data.keyword = "";
                      setResponse(data);
                      clearFilter("keyword");
                    }}
                  />
                </span>
              )}
              {response?.action && (
                <span class="badge cursor-pointer" onClick={() => toggleFilter()}>
                  Action : <span>{response?.action}</span>
                  <img
                    src={close}
                    alt=""
                    onClick={(e) => {
                      e.stopPropagation();
                      const data = { ...response };
                      data.action = "";
                      setResponse(data);
                      clearFilter("action");
                    }}
                  />
                </span>
              )}
            </div>
          </div>
        ))}
      {show && loading && (
        <div class={"page-widget-body mt-3"}>
          <div class="table-responsive table-custom">
            <table class="table table-bordered">
              <colgroup>
                <col />
                <col />
                <col />
                <col />
                <col />
                <col />
                <col />
                <col />
              </colgroup>
              <thead>
                <tr>
                  <th>
                    <Skeleton />
                  </th>
                  <th>
                    <Skeleton />
                  </th>
                  <th>
                    <Skeleton />
                  </th>
                  <th>
                    <Skeleton />
                  </th>
                  <th>
                    <Skeleton />
                  </th>
                  <th>
                    <Skeleton />
                  </th>
                  <th>
                    <Skeleton />
                  </th>
                  <th>
                    <Skeleton />
                  </th>
                </tr>
              </thead>
              <tbody>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((val) => (
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {show && data?.length > 0 && !loading && (
        <div class={"page-widget-body mt-3"}>
          <div class="table-responsive table-custom">
            <table class="table table-bordered">
              <colgroup>
                <col />
                <col />
                <col />
                <col />
                <col />
                <col />
                <col />
                <col />
              </colgroup>
              <thead>
                <tr>
                  <th>Broker</th>
                  <th style={{ minWidth: "165px" }}>
                    <a
                      href="javascript:void(0)"
                      class="sort-by"
                      onClick={() => {
                        setSort("buyerName");
                      }}
                    >
                      Referral Name
                      <div
                        class={sort === "buyerName" && order === "asc" ? "ascending-icon active" : "ascending-icon"}
                        onClick={() => setOrder("asc")}
                      ></div>
                      <div
                        class={sort === "buyerName" && order === "desc" ? "descending-icon active" : "descending-icon"}
                        onClick={() => setOrder("desc")}
                      ></div>
                    </a>
                  </th>
                  <th>Referral Email</th>
                  <th style={{ minWidth: "141px" }}>Referral Phone</th>
                  <th style={{ minWidth: "120px" }}>Location</th>
                  <th>Category</th>
                  <th>Action</th>
                  <th>
                    <a
                      href="javascript:void(0)"
                      class="sort-by"
                      onClick={() => {
                        setSort("createdAt");
                      }}
                    >
                      Created At
                      <div
                        class={sort === "createdAt" && order === "asc" ? "ascending-icon active" : "ascending-icon"}
                        onClick={() => setOrder("asc")}
                      ></div>
                      <div
                        class={sort === "createdAt" && order === "desc" ? "descending-icon active" : "descending-icon"}
                        onClick={() => setOrder("desc")}
                      ></div>
                    </a>
                  </th>
                </tr>
              </thead>

              <tbody>
                {data?.map((val, i) => (
                  <tr key={i}>
                    <td>
                      {val.name01 ? (
                        <>
                          <span
                            className="green-btn-text cursor-pointer"
                            onClick={() => {
                              if (process.env.REACT_APP_MODE == "development") {
                                window.open(
                                  `https://admin-dev.salvex.com/admin/_user/_edit.cfm?user_id=${val.user_id}`,
                                );
                              } else {
                                window.open(`https://admin.salvex.com/admin/_user/_edit.cfm?user_id=${val.user_id}`);
                              }
                            }}
                          >
                            {val?.name01}
                          </span>
                          <br />
                          {val?.email && (
                            <a className="gray-color-text" href={`mailto:${val?.email}`}>
                              {val?.email}
                            </a>
                          )}
                          <br />
                          {val?.phone && <span className="gray-color-text">{val?.phone}</span>}
                        </>
                      ) : (
                        "N/A"
                      )}
                    </td>
                    <td style={{ textAlign: "left" }}>
                      {val.buyer_name ? (
                        <span
                          className="green-btn-text cursor-pointer"
                          onClick={() => {
                            if (process.env.REACT_APP_MODE == "development") {
                              window.open(`https://admin-dev.salvex.com/admin/_user/_edit.cfm?user_id=${val.buyer_id}`);
                            } else {
                              window.open(`https://admin.salvex.com/admin/_user/_edit.cfm?user_id=${val.buyer_id}`);
                            }
                          }}
                        >
                          {val.buyer_name}
                        </span>
                      ) : (
                        "N/A"
                      )}
                    </td>
                    <td>{val.buyer_email ? <a href={`mailto:${val.buyer_email}`}>{val.buyer_email}</a> : "N/A"}</td>
                    <td>{val.buyer_phone ? val.buyer_phone : "N/A"}</td>
                    <td>{val.location ? val.location : "N/A"}</td>
                    <td>
                      {val.categories?.length > 0 ? val.categories?.map((val) => val.description).join(", ") : "N/A"}
                    </td>
                    <td>
                      {val.action
                        ? val.action === "clicked"
                          ? "Clicked"
                          : val?.action === "shared"
                          ? "Shared"
                          : val?.action
                        : "N/A"}
                    </td>
                    <td>{val.created ? <DateFormat date={val.created} /> : "N/A"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div class="table-pagination">
              <ul class="pagination  justify-content-end">
                <li class="page-item">
                  <a
                    class={`page-link ${page === 1 ? "disabled" : ""}`}
                    href="javascript:void(0)"
                    aria-label="Previous"
                    onClick={() => {
                      if (page !== 1) {
                        setPage(page - 1);
                      }
                    }}
                  >
                    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7 13L1 7L7 1" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </a>
                </li>
                <li class="page-item active">
                  <a class="page-link" href="javascript:void(0)">
                    {page}
                  </a>
                </li>
                {totalPages > 1 && page + 1 <= totalPages && (
                  <li class="page-item">
                    <a class="page-link" href="javascript:void(0)" onClick={() => setPage(page + 1)}>
                      {page + 1}
                    </a>
                  </li>
                )}
                <li class="page-item">
                  <a
                    class={`page-link ${page + 1 >= totalPages ? "disabled" : ""}`}
                    href="javascript:void(0)"
                    aria-label="Next"
                    onClick={() => setPage(page + 1)}
                  >
                    <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 13L7 7L1 1" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
      <FilterModal
        show={showFilter}
        onHide={toggleFilter}
        handleFilter={handleFilter}
        loading={loading}
        keyword={keyword}
        setKeyword={setKeyword}
        action={action}
        setAction={setAction}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getBrokerActivity: (params, callback) => dispatch(getBrokerActivity(params, callback)),
});
const mapStateToProps = (state) => ({
  brokerData: brokerData(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Brokers));
