import React, { useState } from "react";
import DateFormat from "../../../common/DateFormat/dateFormat";
import { dashboardData, getAllOtherListings, updateMultipleTaskStatus } from "../../../store/dashBoard";
import closeButton from "../../../include/images/close.svg";
import { connect } from "react-redux";
import RevisitModal from "../../../layouts/modals/taskModal/revisitModal";
import InProgressModal from "../../../layouts/modals/taskModal/inProgressModal";
import CompleteTaskModal from "../../../layouts/modals/taskModal/completeTaskModal";
import DeleteModal from "../../../layouts/modals/deleteModal";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextField } from "@mui/material";
import moment from "moment";
import { toast } from "react-toastify";
import AlertError from "../../../common/alerts/alertError";

const AllOtherListings = (props) => {
  const detailData = props?.data?.data;

  const [selected, setSelected] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [showProgressModal, setShowProgressModal] = useState(false);
  const [showRevisitModal, setShowRevisitModal] = useState(false);
  const [showDate, setShowDate] = useState(false);
  const [date, setDate] = useState("");

  //Togle Delete Modal
  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  //Toggle Complete modal
  const toggleCompleteModal = () => {
    setShowCompleteModal(!showCompleteModal);
  };

  //Toggle Progress Modal
  const toggleProgressModal = () => {
    setShowProgressModal(!showProgressModal);
  };

  //Toggle Revisit Modal
  const toggleRevisitModal = () => {
    setShowRevisitModal(!showRevisitModal);
  };

  //Toggle Revisit Date
  const toggleRevisitDate = () => {
    setShowDate(!showDate);
  };

  const updateTaskStatus = (action) => {
    setLoading(true);
    props.refStart();
    const payload = {
      task_ids: selected,
      status_id:
        action === "delete"
          ? "delete"
          : action === "complete"
          ? "3"
          : action === "progress"
          ? "4"
          : action === "revisit"
          ? "5"
          : "",
    };
    const revisitPayload = {
      task_ids: selected,
      status_id: "5",
      revisit_date: date,
    };
    props.updateMultipleTaskStatus(action === "revisit" ? revisitPayload : payload, (res) => {
      if (res.status === 200) {
        props.getAllOtherListings(props.empId, props.pl, props.pt, props.taskType, (res) => {
          if (res.status === 200) {
            setLoading(false);
            props.refStop();
            setSelected([]);
            setSelectAll(false);
            setDate("");
            setShowDate(false);
            setShowCompleteModal(false);
            setShowDeleteModal(false);
            setShowProgressModal(false);
            setShowRevisitModal(false);
          } else {
            setLoading(false);
            props.refStop();
            toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
          }
        });
      } else {
        setLoading(false);
        props.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };
  return (
    <div class="details-page-widget-block">
      <div class="page-widget-head d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <div class="page-widget-label text-capitalize cursor-pointer">{props.title}</div>
        </div>
      </div>
      <div class="page-widget-body mt-3">
        <div className="d-flex mb-2">
          <div>
            <button
              className={`btn btn-auto btn-xs-size btn-primary me-2 ${!selected?.length > 0 && "btn-disabled"}`}
              onClick={() => {
                toggleProgressModal();
              }}
            >
              In Progress
            </button>
          </div>
          <div className={showDate && "d-flex justify-content-center"} style={{ maxHeight: "28px" }}>
            <button
              className={`btn btn-auto btn-xs-size btn-primary me-2 ${!selected?.length > 0 && "btn-disabled"}`}
              onClick={() => {
                if (showDate) {
                  toggleRevisitDate();
                } else {
                  setShowDate(true);
                }
              }}
            >
              Revisit
            </button>
            {showDate && (
              <div className="d-flex">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    disableHighlightToday={true}
                    className="form-control"
                    value={date}
                    format="MM/DD/YYYY"
                    onChange={(e) => {
                      const formatted = moment(e).format("MM/DD/YYYY");
                      setDate(formatted);
                    }}
                    renderInput={(params) => <TextField {...params} error={false} />}
                    inputFormat="MM/dd/yyyy"
                  />
                </LocalizationProvider>
                <button className="btn btn-auto btn-xs-size btn-primary ms-2 me-2" onClick={toggleRevisitModal}>
                  Save
                </button>
              </div>
            )}
          </div>
          <div>
            <button
              className={`btn btn-auto btn-xs-size btn-primary me-2 ${!selected?.length > 0 && "btn-disabled"}`}
              onClick={() => {
                toggleCompleteModal();
              }}
            >
              Complete
            </button>
          </div>
          <div>
            <button
              className={`btn btn-auto btn-xs-size btn-primary me-2 ${!selected?.length > 0 && "btn-disabled"}`}
              onClick={() => {
                toggleDeleteModal();
              }}
            >
              Delete
            </button>
          </div>
        </div>
        <div class={`table-responsive table-custom`}>
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>
                  <div class="form-check tick-checkbox">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="select-all-bulk-delete"
                      checked={selectAll}
                      onChange={(e) => {
                        if (e.target.checked) {
                          const data = detailData?.map((val) => val.task_id);
                          setSelected(data);
                          setSelectAll(true);
                        } else {
                          setSelected([]);
                          setSelectAll(false);
                        }
                      }}
                      name="bulk-delete"
                    />
                    <label class="form-check-label" htmlFor="select-all-bulk-delete"></label>
                  </div>
                </th>
                <th>Task</th>
                <th>Listing</th>
                <th>Posted By</th>
                <th>Assigned To</th>
                <th>Date</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {detailData &&
                detailData?.length > 0 &&
                detailData.map((val) => (
                  <tr>
                    <td>
                      <div class="form-check tick-checkbox">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id={`bulk-delete-${val.task_id}`}
                          checked={selected?.some((item) => item === val.task_id)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              const data = [...selected];
                              data?.push(val.task_id);
                              setSelected(data);
                            } else {
                              setSelected(selected?.filter((item) => item != val.task_id));
                            }
                          }}
                          name="bulk-delete"
                        />
                        <label class="form-check-label" htmlFor={`bulk-delete-${val.task_id}`}></label>
                      </div>
                    </td>
                    <td className={val?.status_id === 4 ? "allOther-td" : ""}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: `${
                            val?.description?.split("<p>")?.length >= 2
                              ? val?.description?.split("<p>")[0]
                              : val.description?.substring(0, 70)
                          }${
                            val?.description?.split("<p>").length < 2 && val?.description?.split("")?.length > 70
                              ? "..."
                              : ""
                          }`,
                        }}
                      />
                    </td>
                    <td>
                      <div class="fw-medium green-text">
                        <a href={`/listing-detail?id=${val?.auction_id}`} target="_blank">
                          {val?.listing}
                        </a>
                      </div>
                    </td>
                    <td className={val?.status_id === 4 ? "allOther-td" : ""}>{val.posted_by}</td>
                    <td className={val?.status_id === 4 ? "allOther-td" : ""}>{val.assigned_to}</td>
                    <td className={val?.status_id === 4 ? "allOther-td" : ""}>
                      {val.date_submitted ? <DateFormat date={val.date_submitted} /> : "N/A"}
                    </td>
                    <td>
                      <a
                        className="btn btn-auto btn-xs-size btn-primary"
                        target="_blank"
                        href={
                          process.env.REACT_APP_MODE === "development"
                            ? `https://admin-dev.salvex.com/admin/_tasks/_edit.cfm?task_id=${val.task_id}`
                            : `https://admin.salvex.com/admin/_tasks/_edit.cfm?task_id=${val.task_id}`
                        }
                      >
                        EDIT
                      </a>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <DeleteModal
        show={showDeleteModal}
        onHide={toggleDeleteModal}
        loadingDelete={loading}
        deleteHandler={() => updateTaskStatus("delete")}
      />
      <CompleteTaskModal
        show={showCompleteModal}
        onHide={toggleCompleteModal}
        loadingDelete={loading}
        submitHandler={() => updateTaskStatus("complete")}
      />
      <InProgressModal
        show={showProgressModal}
        onHide={toggleProgressModal}
        loadingDelete={loading}
        submitHandler={() => updateTaskStatus("progress")}
      />
      <RevisitModal
        show={showRevisitModal}
        onHide={toggleRevisitModal}
        loadingDelete={loading}
        empty={date}
        submitHandler={() => updateTaskStatus("revisit")}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getAllOtherListings: (empId, pl, pt, taskType, callback) =>
    dispatch(getAllOtherListings(empId, pl, pt, taskType, callback)),
  updateMultipleTaskStatus: (data, callback) => dispatch(updateMultipleTaskStatus(data, callback)),
});
const mapStateToProps = (state) => ({
  dashboardData: dashboardData(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(AllOtherListings));
