import React, { useEffect, useRef, useState } from "react";
import { getActiveListing, getListings } from "../../../store/listings";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import AlertError from "../../../common/alerts/alertError";
import Skeleton from "react-loading-skeleton";

const ActiveListing = (props) => {
  const [data, setData] = useState();
  const [showTable, setShowTable] = useState(false);
  const tableRef = useRef(null);
  const toggleTableVisibility = () => {
    setShowTable(!showTable);
  };
  const traderCellHeight = showTable ? "auto" : "60px";
  const scrollOffset = 100;
  useEffect(() => {
    const fetchData = () => {
      props.refStart();
      props.getActiveListing(props.auctionId, (res) => {
        if (res.status === 200) {
          props.refStop();
          setData(res.data.data);
        } else {
          props.refStop();
          toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        }
      });
    };
    fetchData();
  }, []);
  useEffect(() => {
    if (!showTable) {
      window.scrollTo({
        top: tableRef.current.offsetTop - scrollOffset,
        behavior: "smooth",
      });
    }
  }, [showTable]);

  return (
    <div ref={tableRef}>
      {!props.loadingMain ? (
        data?.length > 0 && (
          <div class="details-page-widget-block" id={props.auctionId}>
            <div class="page-widget-head d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <div class="page-widget-label text-capitalize">Other Active {props.size}" Listings</div>
              </div>
            </div>
            <div className="table-responsive" style={{ overflowX: "auto" }}>
              <table className="table table-bordered custom-table mb-0">
                <thead>
                  <tr>
                    <td className="highlight-header">ID</td>
                    <td className="highlight-header">Listing</td>
                    <td className="highlight-header">Asking Price</td>
                    <td className="highlight-header">Location</td>
                    <td className="highlight-header" style={{ maxWidth: traderCellHeight }}>
                      Trader
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {showTable &&
                    data &&
                    data.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <a href={`/listing-detail?id=${item.auction_id}`} target="_blank" rel="noreferrer">
                            {item.auction_id}
                          </a>
                        </td>
                        <td>
                          <a href={`/listing-detail?id=${item.auction_id}`} target="_blank" rel="noreferrer">
                            {item.title || "N/A"}
                          </a>
                          <br />
                          <i>
                            {item.quantity}
                            {item.unit}
                          </i>
                        </td>
                        <td>
                          {item.asking_price_feature
                            ? item.asking_price
                              ? "$" +
                                new Intl.NumberFormat("en-US", {
                                  style: "decimal",
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }).format(item.asking_price)
                              : ""
                            : ""}
                        </td>
                        <td>{item.location || "N/A"}</td>
                        <td style={{ maxWidth: traderCellHeight }}>
                          {item.name01 || "N/A"}
                          <br />
                          {item.phone || "N/A"}
                          <br />
                          <a href="javascript:void(0)">{item.email || "N/A"}</a>
                        </td>
                      </tr>
                    ))}
                  <tr>
                    <td colSpan="5" className="text-center">
                      <button className="btn btn-primary btn-sm" onClick={toggleTableVisibility}>
                        {showTable ? "Hide Listing" : `View ${data ? data.length : ""} Listing`}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )
      ) : (
        <Skeleton />
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getActiveListing: (params, callback) => dispatch(getActiveListing(params, callback)),
});
const mapStateToProps = (state) => ({
  getListings: getListings(state),
});
export default connect(mapStateToProps, mapDispatchToProps)(React.memo(ActiveListing));
